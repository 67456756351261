import { switchFilter } from '@Model/reservations/actions';
import { getFilters } from '@Model/reservations/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import { IFiltersFromDispatch, IFiltersFromState } from './Filters';
import Filters from './Filters.component';

const mapStateToProps = (state: _Store.IState): IFiltersFromState => {
  return {
    availableHappenings: getFilters(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IFiltersFromDispatch => {
  return {
    switchFilter: (happeningId: number, spaceId?: number) =>
      dispatch(switchFilter(happeningId, spaceId)),
  };
};

export default connect<
  IFiltersFromState,
  IFiltersFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Filters);
