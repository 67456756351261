import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { signInWithFirebase } from '@Model/authorization/actions';
import { get } from '@Model/authorization/selectors';
import { edit, mounted, unmount } from '@Model/happening/actions';
import { getHappening } from '@Model/happening/selectors';
import { allPermissions, HAPPENING } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';

import Login from './Login.component';
import { ILoginFromDispatch, ILoginFromState } from './Login.types';

const mapStateToProps = (state: _Store.IState): ILoginFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    happening: getHappening(state),
    isLoading: getLoading(HAPPENING)(state),
    permissionHappeningsWrite: permissions.includes(
      allPermissions.access_happenings_write
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ILoginFromDispatch => {
  return {
    mounted: () => {
      dispatch(mounted());
    },
    save: (happening) => {
      dispatch(edit(happening));
    },
    signIn: (payload) => {
      dispatch(signInWithFirebase.request(payload));
    },
    unmount: () => {
      dispatch(unmount());
    },
  };
};

export default connect<ILoginFromState, ILoginFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Login);
