import React, { useEffect, useRef, useState } from 'react';

import routes from '@/routes/routes';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  ListItem,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';

import ReportModal from './components/ReportModal';
import styles from './List.module.scss';
import { IListProps } from './List.types';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    paddingRight: theme.spacing(3),
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
    },
  },
  buttonFirst: {
    marginRight: theme.spacing(2),
  },

  buttonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
  },

  card: {
    borderRadius: 16,
    height: '100%',
    minHeight: 392,
  },

  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      alignItems: 'normal',
      flexDirection: 'column',
    },
  },

  item: {
    marginBottom: 0,
    marginTop: 0,
  },

  listItem: {
    paddingBottom: theme.spacing(1.5),
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(1.5),
  },

  space: {
    marginBottom: theme.spacing(2),
  },
}));

const Happenings = ({
  happenings,
  isLoading,
  mounted,
  refreshSmartporterList,
  remove,
  permissionHappeningsView,
  permissionApartmentsView,
  permissionWrite,
  history,
}: IListProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'happenings.List',
  });

  useEffect(() => {
    mounted();
  }, []);
  const classes = useStyles();
  const [showReportModal, handleReportModal] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [search, setSearch] = useState('');

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const catchHandleSearch = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.currentTarget;
    setSearch(value);
  };

  const renderHeader = () => {
    return (
      <div className={classes.container}>
        <TextField
          variant="standard"
          name="search"
          helperText={t('search')}
          value={search}
          onChange={catchHandleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="secondary" />
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.buttonsContainer}>
          {permissionWrite && (
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              startIcon={<AddIcon />}
              className={cn(classes.button, classes.buttonFirst)}
              onClick={() => history.push(routes.addHappening)}
            >
              {t('add')}
            </Button>
          )}
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            startIcon={<ArrowDropDownIcon />}
            className={classes.button}
            ref={anchorRef}
            onClick={handleToggle}
          >
            {t('action')}
          </Button>
          <Popper
            open={open}
            transition={true}
            disablePortal={true}
            anchorEl={anchorRef.current}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      {permissionApartmentsView && (
                        <MenuItem
                          className={classes.item}
                          onClick={refreshSmartporterList}
                        >
                          {t('refreshSmartporterList')}
                        </MenuItem>
                      )}
                      <MenuItem
                        className={classes.item}
                        onClick={() => {
                          handleReportModal(!showReportModal);
                        }}
                      >
                        {t('printDailyReport')}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </div>
    );
  };
  if (isLoading) {
    return (
      <GoingPaper title={t('title')} disableBottomDivider={true}>
        <div className={styles.loader}>
          <Loader />
        </div>
      </GoingPaper>
    );
  }

  const filteredHappenings = happenings.filter((happening) => {
    return (
      happening.metadata.title.toLowerCase().indexOf(search.toLowerCase()) !==
      -1
    );
  });

  return (
    <GoingPaper
      title={t('title')}
      header={renderHeader()}
      disableBottomDivider={true}
    >
      <Grid container={true} spacing={4} data-testid="happeningsList">
        {filteredHappenings.map((happening) => (
          <Grid key={happening.id} item={true} xs={12} sm={6} md={6} lg={4}>
            <Card className={classes.card}>
              <CardHeader
                title={happening.metadata.title}
                action={
                  <>
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        history.push(
                          fillUrlWithValues(
                            routes.editHappening,
                            ':slug',
                            happening.metadata.slug
                          )
                        )
                      }
                      size="large"
                    >
                      <SettingsIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        if (confirm(t('confirmRemove'))) {
                          remove(happening.metadata.slug);
                        }
                      }}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </>
                }
              />
              <CardContent>
                <Typography variant="body1" className={classes.space}>
                  {t('spaces')}
                </Typography>
                {happening.spaces.map((space) => (
                  <ListItem className={classes.listItem} key={space.id}>
                    <Typography variant="body1" color="textSecondary">
                      {space.metadata.title}
                    </Typography>
                  </ListItem>
                ))}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <ReportModal
        close={() => handleReportModal(false)}
        open={showReportModal}
      />
    </GoingPaper>
  );
};

export default Happenings;
