import _Store from '@Store';
import { createSelector } from 'reselect';
import { IReservationAddReducer, ITimeSlot } from './../types';
import getAdd from './getAdd';

const getSelectedSlot = createSelector<
  _Store.IState,
  IReservationAddReducer,
  ITimeSlot | null
>(
  getAdd,
  (add) => add.selectedSlot,
);

export default getSelectedSlot;
