import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    height: 20,
    marginRight: 5,
    width: 20,
  },
  closeIconColor: {
    color: theme.palette.error.light,
  },
  container: {
    display: 'flex',
  },
  disabledIconColor: {
    color: theme.palette.text.disabled,
  },
  doneIcon: {
    height: 20,
    marginRight: 5,
    width: 20,
  },
  doneIconColor: {
    color: theme.palette.success.light,
  },
}));

export default useStyles;
