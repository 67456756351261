import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  defaultTimeInput: {
    '&>input:focus': {
      color: `${theme.palette.text.primary}!important`,
    },
    '&>input:not([value])': {
      color: 'transparent',
    },
    '&>input[value=""]': {
      color: 'transparent',
    },
  },
  time: {
    '&>input::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)',
    },
  },
}));

export default useStyles;
