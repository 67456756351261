import React from 'react';

import Grid from '@Compo/layout/Grid';
import Reports from '@Compo/Reports';

const ReportsPage = () => (
  <Grid>
    <Reports />
  </Grid>
);

export default ReportsPage;
