import { createSelector } from 'reselect';

import _Store from '@Store';

import { ISeatsIoChart } from '@Services/$seats-io-api/types';

import { ISeatsIoReducer } from './../types';
import get from './get';

const getData = createSelector<_Store.IState, ISeatsIoReducer, ISeatsIoChart[]>(
  [get],
  (seats) => seats.data
);

export default getData;
