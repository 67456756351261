import { LOCATION_CHANGE } from 'connected-react-router';
import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR, TYPE_SUCCESS } from '@Model/toasts/constants/constants';

import { getApartments, mounted, refreshList } from '../actions';
import { IGetApartmentsSuccessPayload } from '../types';

const REFRESH_DONE_TEXT = 'Lista została odświeżona';
const REFRESH_ERROR_TEXT = 'Błąd w odświeżaniu listy';

export const refreshListWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { smarporterApi }
) => {
  return action$.pipe(
    filter$(isActionOf(refreshList)),
    mergeMap$(() => {
      return from$(smarporterApi.refreshList()).pipe(
        map$(() => {
          return addToast(REFRESH_DONE_TEXT, TYPE_SUCCESS);
        }),
        catchError$((error: Error) => {
          return of$(addToast(REFRESH_ERROR_TEXT, TYPE_ERROR));
        })
      );
    })
  );
};

export const requestGetApartmentsWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    mergeMap$(() => {
      return of$(getApartments.request());
    })
  );
};

export const fetchApartmentsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { smarporterApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getApartments.request)),
    mergeMap$(() => {
      return from$(smarporterApi.getApartments()).pipe(
        map$((data: IGetApartmentsSuccessPayload) => {
          return getApartments.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => smarporterApi.cancelApartments())
          )
        ),
        catchError$((error: Error) => {
          return of$(getApartments.failure(error));
        })
      );
    })
  );
};
