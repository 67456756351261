import { createSelector } from 'reselect';

import _Store from '@Store';

import { IMeta } from '@Services/$going-rundate-api/types';

import { IPlacesReducer } from '../types';
import getPlaces from './getPlaces';

const getPlacesMeta = createSelector<_Store.IState, IPlacesReducer, IMeta>(
  [getPlaces],
  (places) => places.meta
);

export default getPlacesMeta;
