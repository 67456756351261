import React from 'react';

import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { StaticDatePicker } from '@mui/x-date-pickers';
import cn from 'classnames';
import moment from 'moment';

import colors from '@Compo/App/colors';

import { IDatePickerProps } from './DatePicker';
import styles from './DatePicker.module.scss';

const useStyles = makeStyles((theme) => ({
  calendar: {
    backgroundColor: colors.background.paper,
    borderRadius: 8,
    boxShadow: theme.shadows[8],
  },
}));

const Calendar = ({
  isDateSelectionAvailable,
  selectedDate,
  setDate,
}: IDatePickerProps) => {
  const classes = useStyles();
  return (
    <div
      className={cn(
        styles.datePicker,
        !isDateSelectionAvailable && styles.disabled
      )}
    >
      <StaticDatePicker
        value={moment(selectedDate || new Date()).toDate()}
        onChange={(date) => {
          if (date) {
            setDate(moment(date).toDate());
          }
        }}
        renderInput={(params) => <TextField {...params} />}
        className={classes.calendar}
        disablePast={true}
        openTo="day"
        showToolbar={false}
        displayStaticWrapperAs="desktop"
      />
    </div>
  );
};

export default Calendar;
