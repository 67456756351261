import { isJsonString } from '@Misc/helpers/strings/isJsonString';
import { IEvent } from '@Model/iframe/types';
import { IApiAction, IAvailableFunctions, IFrameAction } from './types';

const TYPE_GOING = 'GOING';

export class IframeProvider {
  public static addEventListenerMethod(
    messageEvent: MessageEvent,
    callBack: (event: IEvent) => void,
  ) {
    if (messageEvent.data && isJsonString(messageEvent.data)) {
      const event = JSON.parse(messageEvent.data);
      if (event.type === TYPE_GOING) {
        callBack(event.action);
      }
    }
  }
  public runRedirectParentMethod(url: string) {
    const FUNCTION_NAME: IAvailableFunctions = 'redirect';
    const action: IApiAction = {
      payload: url,
      type: FUNCTION_NAME,
    };

    this.sendMessage(action);
  }

  public runRedirectBuyingMethod(url: string) {
    const FUNCTION_NAME: IAvailableFunctions = 'BUYING_REDIRECT';
    const action: IApiAction = {
      payload: url,
      type: FUNCTION_NAME,
    };

    this.sendMessage(action);
  }

  public runResizeMethod(size: string) {
    const FUNCTION_NAME: IAvailableFunctions = 'resize';
    const action: IApiAction = {
      payload: size,
      type: FUNCTION_NAME,
    };

    this.sendMessage(action);
  }

  public runRefreshPage() {
    const FUNCTION_NAME: IAvailableFunctions = 'REFRESH_PAGE';
    const action: IApiAction = {
      type: FUNCTION_NAME,
    };

    this.sendMessage(action);
  }

  public setIframeSession() {
    const FUNCTION_NAME: IAvailableFunctions = 'SET_SESSION';
    const action: IApiAction = {
      type: FUNCTION_NAME,
    };

    this.sendMessage(action);
  }

  public pingMethod() {
    const FUNCTION_NAME: IAvailableFunctions = 'PING';
    const action: IApiAction = {
      type: FUNCTION_NAME,
    };

    this.sendMessage(action);
  }

  private sendMessage = (action: IApiAction) => {
    const targetOrigin = '*';
    const iframeAction: IFrameAction = {
      action,
      type: 'GOING',
    };
    window.parent.postMessage(JSON.stringify(iframeAction), targetOrigin);
  };
}

export default new IframeProvider();
