import { getType } from 'typesafe-actions';

import {
  getEmpikReservationDetails,
  resetEmpikReservationDetails,
} from '@Model/reservations/actions';
import { IAction, IEmpikReservationsDetailsReducer } from '../types';

const initialState: IEmpikReservationsDetailsReducer = {
  billId: null,
  bookingIdentifier: null,
  details: {
    firstName: '',
    lastName: '',
  },
  invoice: null,
  paymenType: '',
  revenue: 0,
  serviceFee: 0,
  shipment: null,
  shipmentPrice: 0,
  status: '',
  transactionItems: [],
  transactionProducts: [],
  transactionUuid: '',
  user: {
    userEmail: '',
    userFirstName: '',
    userLastName: '',
  },
};

const empikReservationDetailsReducer = (
  state: IEmpikReservationsDetailsReducer = initialState,
  action: IAction,
): IEmpikReservationsDetailsReducer => {
  switch (action.type) {
    case getType(getEmpikReservationDetails.success):
      return action.payload;
    case getType(resetEmpikReservationDetails):
      return initialState;

    default:
      return state;
  }
};

export default empikReservationDetailsReducer;
