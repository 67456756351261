import React, { useCallback, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  Typography,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { Form, setUser } from 'react-formio';
import { useTranslation } from 'react-i18next';

import config from '@Config';
import { ISingleUser } from '@Model/formio/types';
import { INewSubmission } from '@Services/$formio-api/types';

import UsersList from '../UsersList';
import useStyles from './FormIo.styles';
import { IFormLayoutProps } from './FormIo.types';
import './styles/Bootstrap.module.scss';

const FormIo = ({
  addUser,
  availableUsers,
  submission,
  formIoUser,
  email,
  setEmail,
  setFirstName,
  firstName,
  lastName,
  setLastName,
  getFormIOUsers,
  searchUser,
  formSmsUrl,
  oldDataUser,
  resetOldUserData,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Calendar.components.FormLayout.components.FormIo',
  });

  const [tokenText, setTokenText] = useState('');
  const [scrollerVisible, setScrollerVisible] = useState(false);
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const search = useCallback(
    debounce(() => getFormIOUsers(), 2000),
    []
  );

  useEffect(() => {
    return () => {
      if (search) {
        search.cancel();
      }
    };
  }, []);

  useEffect(() => {
    return () => search.cancel();
  }, []);

  useEffect(() => {
    setTokenText(submission.data.text);
  }, [submission]);

  useEffect(() => {
    const element: HTMLElement = document.getElementsByClassName(
      'btn-wizard-nav-next'
    )[0] as HTMLElement;

    if (oldDataUser && element) {
      element.click();
    }
  }, [oldDataUser]);

  if (formIoUser && formIoUser._id) {
    setUser(formIoUser.data);
  }

  if (!tokenText.length) {
    return null;
  }

  const catchHandleSearchByEmail = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;

    setEmail(value);
    if (!config.cms.showNewEnterList) {
      search();
    }
  };

  const catchHandleSearchByFirstName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;

    setFirstName(value);
    if (!config.cms.showNewEnterList) {
      search();
    }
  };

  const catchHandleSearchByLastName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;

    setLastName(value);
    if (!config.cms.showNewEnterList) {
      search();
    }
  };

  const catchAddUser = (user: ISingleUser) => {
    addUser(user);
  };

  const onSubmitDone = (data: INewSubmission) => {
    resetOldUserData();
  };

  const submitSearch = () => {
    searchUser();
    setOpen(false);
  };

  const onChange = (data: INewSubmission) => {
    const { page2Enterform } = data.data;
    if (page2Enterform && page2Enterform.data.autocomplete === 'true') {
      const submitElement: HTMLElement = document.getElementsByClassName(
        'btn-wizard-nav-submit'
      )[0] as HTMLElement;
      if (submitElement) {
        submitElement.click();
      }
    }
  };

  const catchHandleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container={true} spacing={1}>
      <Grid item={true} xs={12}>
        <Typography>{t('entry')}</Typography>
      </Grid>
      <Grid item={true} xs={12}>
        <TextField
          variant="standard"
          id="entryToken"
          label={t('entryTokenLabel')}
          value={tokenText}
          name="entryToken"
          fullWidth={true}
          disabled={true}
        />
      </Grid>
      {!config.cms.showNewEnterList && (
        <>
          <Grid item={true} xs={12}>
            <TextField
              variant="standard"
              id="search"
              onFocus={() => {
                if (!config.cms.showNewEnterList) {
                  setScrollerVisible(true);
                }
              }}
              label={t('searchEmail')}
              helperText={t('searchEmailHelper')}
              value={email}
              onChange={catchHandleSearchByEmail}
              name="search"
              fullWidth={true}
              autoComplete="off"
            />
          </Grid>
          <Grid item={true} xs={12}>
            <TextField
              variant="standard"
              id="search"
              onFocus={() => {
                if (!config.cms.showNewEnterList) {
                  setScrollerVisible(true);
                }
              }}
              label={t('searchFirstName')}
              helperText={t('searchFirstNameHelper')}
              value={firstName}
              onChange={catchHandleSearchByFirstName}
              name="search"
              fullWidth={true}
              autoComplete="off"
            />
          </Grid>
          {!config.cms.showNewEnterList && (
            <Grid item={true} xs={12}>
              <TextField
                variant="standard"
                onFocus={() => {
                  if (!config.cms.showNewEnterList) {
                    setScrollerVisible(true);
                  }
                }}
                id="search"
                label={t('searchLastName')}
                helperText={t('searchLastNameHelper')}
                value={lastName}
                onChange={catchHandleSearchByLastName}
                name="search"
                fullWidth={true}
                autoComplete="off"
              />
            </Grid>
          )}
        </>
      )}
      {scrollerVisible && (
        <Grid item={true} xs={12}>
          <List
            subheader={
              <ListSubheader className={classes.item} component="div">
                {t('usersList')}
              </ListSubheader>
            }
          >
            {availableUsers.map((user, index) => (
              <ListItem
                button={true}
                onClick={() => catchAddUser(user)}
                data-value={`${user.email} ${user.firstName} ${user.lastName}`}
                key={index}
                className={classes.item}
              >
                <ListItemText
                  primary={`${user.email} ${user.firstName} ${user.lastName}`}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
      <Grid item={true} xs={12}>
        <UsersList setOpen={setOpen} />
      </Grid>

      {!!oldDataUser && (
        <div>
          <Form
            id="smsformio"
            src={formSmsUrl}
            submission={oldDataUser || undefined}
            onChange={onChange}
            onSubmitDone={onSubmitDone}
          />
        </div>
      )}
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={() => setOpen(false)}
        scroll="paper"
      >
        <DialogTitle>{t('addPersonTitle')}</DialogTitle>
        <DialogContent>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={12}>
              <TextField
                variant="standard"
                id="search"
                label={t('searchEmail')}
                helperText={t('searchEmailHelper')}
                value={email}
                onChange={catchHandleSearchByEmail}
                name="search"
                fullWidth={true}
                autoComplete="off"
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                variant="standard"
                id="search"
                label={t('searchFirstName')}
                helperText={t('searchFirstNameHelper')}
                value={firstName}
                onChange={catchHandleSearchByFirstName}
                name="search"
                fullWidth={true}
                autoComplete="off"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            onClick={catchHandleClose}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            onClick={submitSearch}
          >
            {t('add')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default FormIo;
