import React from 'react';

import Discounts from '@Compo/Discounts';
import Grid from '@Compo/layout/Grid';

const DiscountsPage = () => (
  <Grid>
    <Discounts />
  </Grid>
);

export default DiscountsPage;
