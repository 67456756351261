import makeStyles from '@mui/styles/makeStyles';

import colors from '@Compo/App/colors';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    fontWeight: 500,
    marginLeft: '0.625rem',
  },
  startIcon: {
    color: colors.text.secondary,
    [theme.breakpoints.down('lg')]: {
      marginRight: 22,
    },
  },
}));

export default useStyles;
