import { createSelector } from 'reselect';

import _Store from '@Store';

import { ICategory } from '@Services/$seats-io-api/types';

import { ISeatsIoReducer } from './../types';
import get from './get';

const getCategories = createSelector<
  _Store.IState,
  ISeatsIoReducer,
  ICategory[]
>([get], (seats) => seats.categories);

export default getCategories;
