import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import MetadataModal from '@Compo/reusable/MetadataModal/MetadataModal.component';
import {
  IMetadataModalFromDispatch,
  IMetadataModalOwnProps,
} from '@Compo/reusable/MetadataModal/MetadataModal.types';
import { changeIsFilterByActivePartner } from '@Model/metadata/actions';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IMetadataModalFromDispatch => ({
  changeIsFilterByActivePartner: (filterByActivePartner) =>
    dispatch(changeIsFilterByActivePartner(filterByActivePartner)),
});

export default connect<
  {},
  IMetadataModalFromDispatch,
  IMetadataModalOwnProps,
  _Store.IState
>(
  null,
  mapDispatchToProps
)(MetadataModal);
