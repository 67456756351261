import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IPoolsFormValues } from '@Compo/Rundates/Pools/components/PoolsForm/PoolsForm.types';
import { IFormValues } from '@Compo/Rundates/TicketsList/components/FormLayout/components/PreviewTicket/Form/Form.types';
import { ITicketValues } from '@Compo/Rundates/TicketsList/components/FormLayout/components/TicketForm/TicketForm.types';
import { ITicketsListValues } from '@Compo/Rundates/TicketsList/components/TicketsListForm/TicketsListForm.types';
import {
  ICheckExternalResponse,
  IEvent,
  IGetRundatesSuccessPayload,
  IGetSingleRundateSuccessPayload,
  IMonitorType,
  ISaleReport,
  ITickets,
} from '@Services/$going-rundate-api/types';
import { IFileType } from '@Services/$tickets-api/types';

import {
  _CHANGE_MONITOR_TYPE_FAILURE,
  _CHANGE_MONITOR_TYPE_REQUEST,
  _CHANGE_MONITOR_TYPE_SUCCESS,
  _CHANGE_RUNDATE_SALE_STATUS_FAILURE,
  _CHANGE_RUNDATE_SALE_STATUS_REQUEST,
  _CHANGE_RUNDATE_SALE_STATUS_SUCCESS,
  _CHANGE_RUNDATE_VISIBILITY_STATUS_FAILURE,
  _CHANGE_RUNDATE_VISIBILITY_STATUS_REQUEST,
  _CHANGE_RUNDATE_VISIBILITY_STATUS_SUCCESS,
  _CHECK_EXTERNAL_TICKETS_LIST_FAILURE,
  _CHECK_EXTERNAL_TICKETS_LIST_REQUEST,
  _CHECK_EXTERNAL_TICKETS_LIST_SUCCESS,
  _DELETE_MULTIPLE_TICKETS_FAILURE,
  _DELETE_MULTIPLE_TICKETS_REQUEST,
  _DELETE_MULTIPLE_TICKETS_SUCCESS,
  _DELETE_RUNDATE_POOL_FAILURE,
  _DELETE_RUNDATE_POOL_REQUEST,
  _DELETE_RUNDATE_POOL_SUCCESS,
  _DELETE_TICKET_FAILURE,
  _DELETE_TICKET_REQUEST,
  _DELETE_TICKET_SUCCESS,
  _GET_EXAMPLE_TICKET_FAILURE,
  _GET_EXAMPLE_TICKET_REQUEST,
  _GET_EXAMPLE_TICKET_SUCCESS,
  _GET_FAILURE,
  _GET_MONITOR_TYPES_FAILURE,
  _GET_MONITOR_TYPES_REQUEST,
  _GET_MONITOR_TYPES_SUCCESS,
  _GET_POOL_DESCRIPTION_FAILURE,
  _GET_POOL_DESCRIPTION_REQUEST,
  _GET_POOL_DESCRIPTION_SUCCESS,
  _GET_POOLS_FAILURE,
  _GET_POOLS_REQUEST,
  _GET_POOLS_SUCCESS,
  _GET_REQUEST,
  _GET_RUNDATE_REPORT_FAILURE,
  _GET_RUNDATE_REPORT_REQUEST,
  _GET_RUNDATE_REPORT_SUCCESS,
  _GET_RUNDATE_STATS_FAILURE,
  _GET_RUNDATE_STATS_REQUEST,
  _GET_RUNDATE_STATS_SUCCESS,
  _GET_SINGLE_EVENT_FAILURE,
  _GET_SINGLE_EVENT_REQUEST,
  _GET_SINGLE_EVENT_SUCCESS,
  _GET_SINGLE_POOL_FAILURE,
  _GET_SINGLE_POOL_REQUEST,
  _GET_SINGLE_POOL_SUCCESS,
  _GET_SINGLE_TICKET_FAILURE,
  _GET_SINGLE_TICKET_REQUEST,
  _GET_SINGLE_TICKET_SUCCESS,
  _GET_SUCCESS,
  _GET_TICKET_FAILURE,
  _GET_TICKET_REQUEST,
  _GET_TICKET_SUCCESS,
  _GET_TICKETS_FAILURE,
  _GET_TICKETS_LIST_FAILURE,
  _GET_TICKETS_LIST_REQUEST,
  _GET_TICKETS_LIST_SUCCESS,
  _GET_TICKETS_REQUEST,
  _GET_TICKETS_SUCCESS,
  _REORDER_POOLS_FAILURE,
  _REORDER_POOLS_REQUEST,
  _REORDER_POOLS_SUCCESS,
  _REVERT_TICKET_FAILURE,
  _REVERT_TICKET_REQUEST,
  _REVERT_TICKET_SUCCESS,
  _SAVE_CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE_FAILURE,
  _SAVE_CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE_REQUEST,
  _SAVE_CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE_SUCCESS,
  _SAVE_EXTERNAL_TICKETS_LIST_FAILURE,
  _SAVE_EXTERNAL_TICKETS_LIST_REQUEST,
  _SAVE_EXTERNAL_TICKETS_LIST_SUCCESS,
  _SAVE_RUNDATE_POOL_FAILURE,
  _SAVE_RUNDATE_POOL_REQUEST,
  _SAVE_RUNDATE_POOL_SUCCESS,
  _SAVE_RUNDATE_TICKET_FAILURE,
  _SAVE_RUNDATE_TICKET_LIST_FAILURE,
  _SAVE_RUNDATE_TICKET_LIST_REQUEST,
  _SAVE_RUNDATE_TICKET_LIST_SUCCESS,
  _SAVE_RUNDATE_TICKET_REQUEST,
  _SAVE_RUNDATE_TICKET_SUCCESS,
  _SINGLE_GET_FAILURE,
  _SINGLE_GET_REQUEST,
  _SINGLE_GET_SUCCESS,
  _UPDATE_TICKET_FAILURE,
  _UPDATE_TICKET_REQUEST,
  _UPDATE_TICKET_SUCCESS,
  CATCH_WHEN_PAGE_CHANGED,
  CHANGE_FILTER,
  CHANGE_MONITOR_TYPE,
  CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE,
  CHANGE_RUNDATE_SALE,
  CHANGE_RUNDATE_VISIBILITY,
  CHECK_EXTERNAL_TICKETS,
  CLEAR_FILTER,
  DELETE_MULTIPLE_TICKETS,
  DELETE_POOL,
  DELETE_SINGLE_TICKET,
  EDIT,
  EDIT_TICKET_LIST,
  FETCH_POOL_DESCRIPTION,
  FETCH_REORDER_POOLS,
  FETCH_RUNDATE_REPORT,
  FETCH_SINGLE_POOL,
  FETCH_SINGLE_TICKET,
  FETCH_TICKETS,
  GENERATE_EXAMPLE_TICKET,
  MOUNTED,
  RESET_ACTIVE_RUNDATE,
  RESET_EXTERNAL_TICKETS,
  REVERT_SINGLE_TICKET,
  SAVE,
  SAVE_EXTERNAL_TICKETS,
  SAVE_POOL,
  SAVE_TICKET,
  SAVE_TICKET_LIST,
  SEARCH,
  SEARCH_TICKETS,
  SELECT_POOL,
  SELECT_SINGLE_TICKET,
  SELECT_TICKET,
  SELECT_TICKET_LIST,
  UPDATE_TICKET,
} from '../constants/actions';
import {
  IEditRundatePayload,
  IExampleTicketPayload,
  IExternalTicketsListPayload,
  IFetchTicketsPayload,
  IGetRundatesChangeFilterPayload,
  IGetRundatesChangePageOrSizePayload,
  IGetRundatesPayload,
  IPoolDescriptionsSuccessPayload,
  IPoolsSuccessPayload,
  ITicketsListSuccessPayload,
  ITicketsSuccessPayload,
} from '../types';

export const getRundates = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE
)<IGetRundatesPayload | undefined, IGetRundatesSuccessPayload, Error>();

export const getRundate = createAsyncAction(
  _SINGLE_GET_REQUEST,
  _SINGLE_GET_SUCCESS,
  _SINGLE_GET_FAILURE
)<undefined, IGetSingleRundateSuccessPayload, Error>();

export const getRundatePools = createAsyncAction(
  _GET_POOLS_REQUEST,
  _GET_POOLS_SUCCESS,
  _GET_POOLS_FAILURE
)<undefined, IPoolsSuccessPayload, Error>();

export const getSinglePool = createAsyncAction(
  _GET_SINGLE_POOL_REQUEST,
  _GET_SINGLE_POOL_SUCCESS,
  _GET_SINGLE_POOL_FAILURE
)<number, IPoolsSuccessPayload, Error>();

export const getSingleEvent = createAsyncAction(
  _GET_SINGLE_EVENT_REQUEST,
  _GET_SINGLE_EVENT_SUCCESS,
  _GET_SINGLE_EVENT_FAILURE
)<number, IEvent, Error>();

export const getPoolDescriptions = createAsyncAction(
  _GET_POOL_DESCRIPTION_REQUEST,
  _GET_POOL_DESCRIPTION_SUCCESS,
  _GET_POOL_DESCRIPTION_FAILURE
)<string, IPoolDescriptionsSuccessPayload, Error>();

export const deleteRundatePool = createAsyncAction(
  _DELETE_RUNDATE_POOL_REQUEST,
  _DELETE_RUNDATE_POOL_SUCCESS,
  _DELETE_RUNDATE_POOL_FAILURE
)<number, [], Error>();

export const saveRundatePool = createAsyncAction(
  _SAVE_RUNDATE_POOL_REQUEST,
  _SAVE_RUNDATE_POOL_SUCCESS,
  _SAVE_RUNDATE_POOL_FAILURE
)<IPoolsFormValues, [], Error>();

export const saveRundateTicketList = createAsyncAction(
  _SAVE_RUNDATE_TICKET_LIST_REQUEST,
  _SAVE_RUNDATE_TICKET_LIST_SUCCESS,
  _SAVE_RUNDATE_TICKET_LIST_FAILURE
)<ITicketsListValues, [], Error>();

export const saveRundateTicket = createAsyncAction(
  _SAVE_RUNDATE_TICKET_REQUEST,
  _SAVE_RUNDATE_TICKET_SUCCESS,
  _SAVE_RUNDATE_TICKET_FAILURE
)<ITicketValues, [], Error>();

export const getExampleTicket = createAsyncAction(
  _GET_EXAMPLE_TICKET_REQUEST,
  _GET_EXAMPLE_TICKET_SUCCESS,
  _GET_EXAMPLE_TICKET_FAILURE
)<IExampleTicketPayload, BlobPart, Error>();

export const getTicketsList = createAsyncAction(
  _GET_TICKETS_LIST_REQUEST,
  _GET_TICKETS_LIST_SUCCESS,
  _GET_TICKETS_LIST_FAILURE
)<number, ITicketsListSuccessPayload, Error>();

export const getSingleTicket = createAsyncAction(
  _GET_SINGLE_TICKET_REQUEST,
  _GET_SINGLE_TICKET_SUCCESS,
  _GET_SINGLE_TICKET_FAILURE
)<number, IPoolDescriptionsSuccessPayload, Error>();

export const getTickets = createAsyncAction(
  _GET_TICKETS_REQUEST,
  _GET_TICKETS_SUCCESS,
  _GET_TICKETS_FAILURE
)<IFetchTicketsPayload, ITicketsSuccessPayload, Error>();

export const getTicket = createAsyncAction(
  _GET_TICKET_REQUEST,
  _GET_TICKET_SUCCESS,
  _GET_TICKET_FAILURE
)<number, ITickets, Error>();

export const deleteTicket = createAsyncAction(
  _DELETE_TICKET_REQUEST,
  _DELETE_TICKET_SUCCESS,
  _DELETE_TICKET_FAILURE
)<number, [], Error>();

export const deleteTickets = createAsyncAction(
  _DELETE_MULTIPLE_TICKETS_REQUEST,
  _DELETE_MULTIPLE_TICKETS_SUCCESS,
  _DELETE_MULTIPLE_TICKETS_FAILURE
)<number[], [], Error>();

export const revertTicket = createAsyncAction(
  _REVERT_TICKET_REQUEST,
  _REVERT_TICKET_SUCCESS,
  _REVERT_TICKET_FAILURE
)<number, [], Error>();

export const reorderPools = createAsyncAction(
  _REORDER_POOLS_REQUEST,
  _REORDER_POOLS_SUCCESS,
  _REORDER_POOLS_FAILURE
)<number[], [], Error>();
export const updateTicket = createAsyncAction(
  _UPDATE_TICKET_REQUEST,
  _UPDATE_TICKET_SUCCESS,
  _UPDATE_TICKET_FAILURE
)<IFormValues, [], Error>();
export const changeRundateSaleStatus = createAsyncAction(
  _CHANGE_RUNDATE_SALE_STATUS_REQUEST,
  _CHANGE_RUNDATE_SALE_STATUS_SUCCESS,
  _CHANGE_RUNDATE_SALE_STATUS_FAILURE
)<boolean, [], Error>();
export const changeRundateVisibilityStatus = createAsyncAction(
  _CHANGE_RUNDATE_VISIBILITY_STATUS_REQUEST,
  _CHANGE_RUNDATE_VISIBILITY_STATUS_SUCCESS,
  _CHANGE_RUNDATE_VISIBILITY_STATUS_FAILURE
)<boolean, [], Error>();
export const saveChangeRundateHasExternalImage = createAsyncAction(
  _SAVE_CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE_REQUEST,
  _SAVE_CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE_SUCCESS,
  _SAVE_CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE_FAILURE
)<boolean, [], Error>();
export const getRundateReport = createAsyncAction(
  _GET_RUNDATE_REPORT_REQUEST,
  _GET_RUNDATE_REPORT_SUCCESS,
  _GET_RUNDATE_REPORT_FAILURE
)<IFileType, any, Error>();

export const getRundateStats = createAsyncAction(
  _GET_RUNDATE_STATS_REQUEST,
  _GET_RUNDATE_STATS_SUCCESS,
  _GET_RUNDATE_STATS_FAILURE
)<undefined, ISaleReport, Error>();

export const changeRundateMonitorTypeStatus = createAsyncAction(
  _CHANGE_MONITOR_TYPE_REQUEST,
  _CHANGE_MONITOR_TYPE_SUCCESS,
  _CHANGE_MONITOR_TYPE_FAILURE
)<number | null, [], Error>();

export const getMonitorTypes = createAsyncAction(
  _GET_MONITOR_TYPES_REQUEST,
  _GET_MONITOR_TYPES_SUCCESS,
  _GET_MONITOR_TYPES_FAILURE
)<undefined, IMonitorType[], Error>();

export const checkExternalTicketsList = createAsyncAction(
  _CHECK_EXTERNAL_TICKETS_LIST_REQUEST,
  _CHECK_EXTERNAL_TICKETS_LIST_SUCCESS,
  _CHECK_EXTERNAL_TICKETS_LIST_FAILURE
)<IExternalTicketsListPayload, ICheckExternalResponse, Error>();

export const saveExternalTicketsList = createAsyncAction(
  _SAVE_EXTERNAL_TICKETS_LIST_REQUEST,
  _SAVE_EXTERNAL_TICKETS_LIST_SUCCESS,
  _SAVE_EXTERNAL_TICKETS_LIST_FAILURE
)<IExternalTicketsListPayload, [], Error>();

export const changePageOrSize = createStandardAction(
  CATCH_WHEN_PAGE_CHANGED
)<IGetRundatesChangePageOrSizePayload>();

export const search =
  createStandardAction(SEARCH)<IGetRundatesChangeFilterPayload>();

export const changeFilter =
  createStandardAction(CHANGE_FILTER)<IGetRundatesChangeFilterPayload>();

export const clearFilter = createStandardAction(CLEAR_FILTER)();

export const mounted = createStandardAction(MOUNTED)();

export const edit = createStandardAction(EDIT)<IEditRundatePayload>();

export const deletePool = createStandardAction(DELETE_POOL)<number>();

export const selectPool = createStandardAction(
  SELECT_POOL
)<IPoolsFormValues | null>();

export const fetchSinglePool =
  createStandardAction(FETCH_SINGLE_POOL)<number>();

export const savePool = createStandardAction(SAVE_POOL)<IPoolsFormValues>();

export const fetchPoolDescriptions = createStandardAction(
  FETCH_POOL_DESCRIPTION
)<string>();

export const generateExampleTicket = createStandardAction(
  GENERATE_EXAMPLE_TICKET
)<IExampleTicketPayload>();

export const selectTicketList = createStandardAction(
  SELECT_TICKET_LIST
)<ITicketsListValues | null>();

export const selectTicket = createStandardAction(
  SELECT_TICKET
)<ITicketValues | null>();

export const editTicketList = createStandardAction(EDIT_TICKET_LIST)<number>();

export const saveTicketList =
  createStandardAction(SAVE_TICKET_LIST)<ITicketsListValues>();

export const saveTicket = createStandardAction(SAVE_TICKET)<ITicketValues>();

export const fetchTickets =
  createStandardAction(FETCH_TICKETS)<IFetchTicketsPayload>();

export const fetchSingleTicket =
  createStandardAction(FETCH_SINGLE_TICKET)<number>();

export const selectSingleTicket = createStandardAction(
  SELECT_SINGLE_TICKET
)<IFormValues | null>();

export const deleteSingleTicket =
  createStandardAction(DELETE_SINGLE_TICKET)<number>();
export const deleteMultipleTickets = createStandardAction(
  DELETE_MULTIPLE_TICKETS
)<number[]>();
export const revertSingleTicket =
  createStandardAction(REVERT_SINGLE_TICKET)<number>();
export const searchTickets = createStandardAction(SEARCH_TICKETS)<string>();
export const fetchReorderPools =
  createStandardAction(FETCH_REORDER_POOLS)<number[]>();
export const updateSingleTicket =
  createStandardAction(UPDATE_TICKET)<IFormValues>();
export const changeRundateSale =
  createStandardAction(CHANGE_RUNDATE_SALE)<boolean>();
export const changeRundateVisibility = createStandardAction(
  CHANGE_RUNDATE_VISIBILITY
)<boolean>();
export const changeRundateHasExternalImage = createStandardAction(
  CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE
)<boolean>();
export const fetchRundateReport =
  createStandardAction(FETCH_RUNDATE_REPORT)<IFileType>();
export const resetActiveRundate = createStandardAction(RESET_ACTIVE_RUNDATE)();
export const save = createStandardAction(SAVE)<IEditRundatePayload>();
export const changeRundateMonitorType = createStandardAction(
  CHANGE_MONITOR_TYPE
)<number | null>();
export const checkExternalTickets = createStandardAction(
  CHECK_EXTERNAL_TICKETS
)<IExternalTicketsListPayload>();
export const saveExternalTickets = createStandardAction(
  SAVE_EXTERNAL_TICKETS
)<IExternalTicketsListPayload>();
export const resetExternalTickets = createStandardAction(
  RESET_EXTERNAL_TICKETS
)();
