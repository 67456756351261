import { View } from 'react-big-calendar';

const DAY_TEXT = 'Dzień';
const NEXT_TEXT = 'Następny';
const PREVIOUS_TEXT = 'Poprzedni';
const TODAY_TEXT = 'Dzisiaj';
const WEEK_TEXT = 'Tydzień';

export const MESSAGES = {
  day: DAY_TEXT,
  next: NEXT_TEXT,
  previous: PREVIOUS_TEXT,
  today: TODAY_TEXT,
  week: WEEK_TEXT,
};

export const VIEWS = { week: true, day: true };

export const WEEK: View = 'week';
export const DAY: View = 'day';
