import { connect } from 'react-redux';

import _Store from '@Store';

import {
  getHappening,
  getSpaceIsScopeView,
  isOverbookingAllowed,
} from '@Model/happening/selectors';
import {
  getGroupedTimeSlots,
  getSelectedSpaceIsScopeView,
  getTimeSlots,
  getUpSellValue,
} from '@Model/reservation/selectors';
import { HAPPENING } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import { ITimeSlotsFromState } from './TimeSlots';
import TimeSlots from './TimeSlots.component';

const mapStateToProps = (state: _Store.IState): ITimeSlotsFromState => {
  return {
    groupedTimeSlots: getGroupedTimeSlots(state),
    happening: getHappening(state),
    isLoading: getLoading(HAPPENING)(state),
    isOverbooked: isOverbookingAllowed(state),
    isScopeView:
      getSelectedSpaceIsScopeView(state) || getSpaceIsScopeView(state),
    timeSlots: getTimeSlots(state),
    upSellValue: getUpSellValue(state),
  };
};

export default connect<ITimeSlotsFromState, null, {}, _Store.IState>(
  mapStateToProps
)(TimeSlots);
