import { IActionSizes } from '@Model/app/types';
import { createStandardAction } from 'typesafe-actions';
import {
  CAPTURE_EVENT_FROM_IFRAME,
  REDIRECT_BUYING_CONTINUE,
  REFRESH_PAGE,
  SET_INNER_SIZES,
  SET_SESSION,
} from '../constants/actions';
import { IEvent } from '../types';

export const captureIframeEvent = createStandardAction(
  CAPTURE_EVENT_FROM_IFRAME,
)<IEvent>();

export const setInnerSizes = createStandardAction(SET_INNER_SIZES)<
  IActionSizes
>();

export const redirectBuyingContinue = createStandardAction(
  REDIRECT_BUYING_CONTINUE,
)();

export const refreshPage = createStandardAction(REFRESH_PAGE)();
export const setIframeSession = createStandardAction(SET_SESSION)();
