import {
  IShipmentFee,
  IShipmentFeeFromValuesProps,
} from '@Model/products/types';

export const prepareInitialValues = (
  shipmentFee: IShipmentFee | null
): IShipmentFeeFromValuesProps => {
  const defaultValues: IShipmentFeeFromValuesProps = {
    name: '',
    price: 0,
  };

  if (shipmentFee) {
    const { name, price, id } = shipmentFee;

    return {
      ...defaultValues,
      id,
      name,
      price,
    };
  }

  return defaultValues;
};
