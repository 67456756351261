import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { get } from '@Model/authorization/selectors';
import { getReport } from '@Model/reports/actions';
import { allPermissions } from '@Model/state/constants';
import _Store from '@Store';
import Reports from './Reports.component';
import { ISummaryFromDispatch, ISummaryFromState } from './Reports.types';

const mapStateToProps = (state: _Store.IState): ISummaryFromState => {
  const {
    userInfo: { permissions },
  } = get(state);
  return {
    permissionReportsRead: permissions.includes(
      allPermissions.access_reports_read
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISummaryFromDispatch => ({
  getReport: () => dispatch(getReport()),
});

export default connect<
  ISummaryFromState,
  ISummaryFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Reports);
