import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TypographyVariant } from '@Constants/Variants/Typography';
import translateDefaultPriceType from '@Misc/helpers/strings/translateDefaultPrice';

import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = ({
  handleSubmit,
  priceTypes,
  handleChange,
  handleBlur,
  values,
  submitForm,
  closeModal,
  errors,
  touched,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'BetterManager.NewDefinedConfigurations.components.PriceRule.AddNewPriceTypeModal.FormLayout',
  });

  const classes = useStyles();
  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <FormControl variant="standard" fullWidth={true}>
                <InputLabel>{t('priceType')}</InputLabel>
                <Select
                  name="type"
                  id="type"
                  value={values.type}
                  onChange={handleChange}
                  fullWidth={true}
                  defaultValue={values.type}
                  onBlur={handleBlur}
                >
                  <MenuItem value="-">-</MenuItem>
                  {priceTypes.map((price) => (
                    <MenuItem key={price?.type} value={price?.type}>
                      {translateDefaultPriceType(price?.title)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                id="value"
                name="value"
                label={(touched.value && errors.value) || t('value')}
                helperText={t('valueHelper')}
                onChange={handleChange}
                fullWidth={true}
                defaultValue={values.value}
                onBlur={handleBlur}
                error={!!(touched.value && errors.value)}
              />
            </Grid>
            <Grid
              container={true}
              spacing={3}
              item={true}
              xs={12}
              className={classes.switchContainer}
            >
              <Grid item={true} xs={12}>
                <TextField
                  id="counter"
                  name="counter"
                  label={
                    (touched.counter && errors.counter) || t('sliderValue')
                  }
                  helperText={t('sliderValueHelper')}
                  onChange={handleChange}
                  value={values.counter}
                  onBlur={handleBlur}
                  type="number"
                  fullWidth={true}
                  error={!!(touched.counter && errors.counter)}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Typography variant={TypographyVariant.body1}>
                  {t('ticketAvailability')}
                </Typography>
              </Grid>
              <Grid
                container={true}
                spacing={3}
                item={true}
                xs={12}
                className={classes.switchContainer}
              >
                <Grid item={true} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.online}
                        onChange={handleChange}
                        name="online"
                        color="primary"
                      />
                    }
                    label={t('online')}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.onsite}
                        onChange={handleChange}
                        name="onsite"
                        color="primary"
                      />
                    }
                    label={t('onsite')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
