import { object, string } from 'yup';

export const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';

const configuration = {
  firmware: string()
    .required(REQUIRED_VALIDATOR_TEXT)
    .nullable(),
  printerId: string().required(REQUIRED_VALIDATOR_TEXT),
  saleUrl: string().required(REQUIRED_VALIDATOR_TEXT),
};

export const PrinterSchema = object().shape(configuration);
