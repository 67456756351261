import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { selectPool } from '@Model/events/actions';
import {
  getAvailablePools,
  getSelectedEvent,
  getSelectedPool,
  poolHasSeatsIo,
} from '@Model/events/selectors';
import { EVENTS_POOLS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import { _Store } from '@Store';
import EventsPools from './EventsPools.component';
import {
  IEventsPoolsFromDispatch,
  IEventsPoolsFromState,
} from './EventsPools.types';

const mapStateToProps = (state: _Store.IState): IEventsPoolsFromState => ({
  isLoading: getLoading(EVENTS_POOLS)(state),
  poolHasSeatsIo: poolHasSeatsIo(state),
  pools: getAvailablePools(state),
  selectedEvent: getSelectedEvent(state),
  selectedPool: getSelectedPool(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IEventsPoolsFromDispatch => ({
  selectPool: (pool) => dispatch(selectPool(pool)),
});

export default connect<
  IEventsPoolsFromState,
  IEventsPoolsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(EventsPools);
