import { IDiscount } from '@Model/discount/types';
import _Store from '@Store';
import { createSelector } from 'reselect';
import { IAvailabilitiesReducer } from '../../happening/types';
import getAvailabilities from './../../happening/selectors/getAvailabilities';
import { ISelectedHappeningSpace } from './../../happenings/types/index';
import { ITimeSlot } from './../types';
import getSelectedDiscount from './getSelectedDiscount';
import getSelectedPeopleCount from './getSelectedPeopleCount';
import getSelectedSlot from './getSelectedSlot';
import getSelectedSpace from './getSelectedSpace';
import isPricePerPersonCalculating from './isPricePerPersonCalculating';

const getSelectedSpacePrice = createSelector<
  _Store.IState,
  ITimeSlot | null,
  ISelectedHappeningSpace | null,
  IAvailabilitiesReducer,
  number | null,
  boolean,
  IDiscount | null,
  number
>(
  [
    getSelectedSlot,
    getSelectedSpace,
    getAvailabilities,
    getSelectedPeopleCount,
    isPricePerPersonCalculating,
    getSelectedDiscount,
  ],
  (
    selectedSlot,
    selectedSpace,
    availabilities,
    selectedPeopleCount,
    calculatePricePerPerson,
    selectedDiscount,
  ) => {
    let result = 0;
    if (selectedSlot === null || selectedSpace === null) {
      return 0;
    }

    const { startTime } = selectedSlot;
    const { id } = selectedSpace;

    let mergedDays = availabilities.currentDay;

    if (availabilities.otherDays) {
      mergedDays = {
        ...availabilities.currentDay,
        ...availabilities.otherDays,
      };
    }

    if (mergedDays[startTime]) {
      const found = mergedDays[startTime].find(
        (availability) => availability.spaceId === id,
      );

      if (found) {
        result = found.price;
      }
    }

    if (
      calculatePricePerPerson &&
      selectedPeopleCount &&
      selectedPeopleCount > 0
    ) {
      result = result * selectedPeopleCount;
    }
    if (selectedDiscount) {
      if (selectedDiscount.percentageValue) {
        result = result - result * selectedDiscount.percentageValue * 0.01;
      } else if (selectedDiscount && selectedDiscount.value) {
        result = result - selectedDiscount.value;
      }
    }
    if (result < 0) {
      return 0;
    }

    return result;
  },
);

export default getSelectedSpacePrice;
