import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  catchSaveSeatChart,
  changeMetaData,
  mounted,
} from '@Model/seatsIo/actions';
import { getData, getMeta, getPageMeta } from '@Model/seatsIo/selectors';
import { SEATS_IO_CHARTS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import AssignModal from './AssignModal.component';
import {
  IAssignModalFromDispatch,
  IAssignModalFromState,
  IAssignModalOwnProps,
} from './AssignModal.types';

const mapStateToProps = (state: _Store.IState): IAssignModalFromState => ({
  data: getData(state),
  isLoading: getLoading(SEATS_IO_CHARTS)(state),
  meta: getPageMeta(state),
  selectedMeta: getMeta(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IAssignModalFromDispatch => ({
  changeMetaData: (meta) => dispatch(changeMetaData(meta)),
  mounted: () => dispatch(mounted()),
  save: (key) => dispatch(catchSaveSeatChart(key)),
});

export default connect<
  IAssignModalFromState,
  IAssignModalFromDispatch,
  IAssignModalOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(AssignModal);
