import _Store from '@Store';
import { createSelector } from 'reselect';
import { IAuthorizationReducer } from '../types';
import get from './get';

const getNonfiscalUrlUrl = createSelector<
  _Store.IState,
  IAuthorizationReducer,
  string
>(
  get,
  (add) => add.casherInfo.printerView.nonfiscalUrl,
);

export default getNonfiscalUrlUrl;
