import React from 'react';

import DoneIcon from '@mui/icons-material/Done';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import ProductsView from '@Compo/Summary/components/ProductsView';
import TransactionView from '@Compo/Summary/components/TransactionView';

import useStyles from './SummaryMix.styles';
import { ISummaryMixProps } from './SummaryMix.types';

const SummaryMix = ({
  reservationPrintData,
  mixPayment,
  continueBuying,
  completeAdvancePayment,
}: ISummaryMixProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'SummaryMix',
  });

  const classes = useStyles();

  const handleCompleteAdvancePaymentClick = () => {
    completeAdvancePayment(reservationPrintData.transactionUuid);
  };

  const checkIsFinalized = () => {
    return reservationPrintData.status === 'finalized';
  };

  const Buttons = () => (
    <Grid container={true} justifyContent="flex-end" spacing={2}>
      <Grid md={12} lg="auto" item={true}>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={continueBuying}
          fullWidth={true}
        >
          {!checkIsFinalized() ? t('saveAsAdvance') : t('continueBuying')}
        </Button>
      </Grid>
      {!checkIsFinalized() && (
        <Grid md={12} lg="auto" item={true}>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={handleCompleteAdvancePaymentClick}
            fullWidth={true}
          >
            {t('completePayment')}
          </Button>
        </Grid>
      )}
    </Grid>
  );

  return (
    <GoingPaper title={t('title')} buttons={Buttons()}>
      <Grid container={true} spacing={6}>
        <Grid item={true} xs={12}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <Grid container={true} direction="row" alignItems="center">
                <DoneIcon className={classes.icon} />
                <Typography variant="body1">
                  {t('bookingSuccessful')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item={true} xs={12}>
              <Grid
                container={true}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Grid item={true}>
                  <Typography variant="body1" color="textPrimary">
                    {t('summary')}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {t('id')}
                  </Typography>
                  <Typography component="span" variant="h5" color="textPrimary">
                    {reservationPrintData.transactionUuid}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <Typography variant="body1" color="textPrimary">
                {t('summaryMix')}
              </Typography>
            </Grid>

            <Grid item={true} xs={12} className={classes.summaryContainer}>
              <Grid container={true} spacing={1}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {t('payment')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textPrimary" align="right">
                    {mixPayment.total} {t('localCurrency')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container={true} spacing={1}>
                <Grid item={true} xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    {t('cash')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                  >
                    {mixPayment.inCash} {t('localCurrency')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container={true} spacing={1}>
                <Grid item={true} xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    {t('card')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                  >
                    {mixPayment.byCard} {t('localCurrency')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container={true} alignItems="center" spacing={1}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="primary">
                    {t('leftToPay')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="h6" color="primary" align="right">
                    {mixPayment.leftToPay} {t('localCurrency')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <TransactionView
            transactionItems={reservationPrintData.transactionItems}
            details={reservationPrintData.details}
          />
          <ProductsView
            transactionProducts={reservationPrintData.transactionProducts}
          />
        </Grid>
      </Grid>
    </GoingPaper>
  );
};

export default SummaryMix;
