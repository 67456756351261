import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { transactionReset } from '@Model/reservation/actions';
import { getTransactionDetails } from '@Model/reservation/selectors';
import _Store from '@Store';
import Checking from './Checking.component';
import { ICheckingFromDispatch, ICheckingFromState } from './Checking.types';

const mapStateToProps = (state: _Store.IState): ICheckingFromState => {
  return {
    transaction: getTransactionDetails(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): ICheckingFromDispatch => {
  return {
    transactionReset: () => {
      dispatch(transactionReset());
    },
  };
};

export default connect<
  ICheckingFromState,
  ICheckingFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Checking);
