import { createSelector } from 'reselect';

import _Store from '@Store';

import { IMeta } from '@Services/$refunds-registration-api/types';

import { IRefundsRegistrationReducer } from './../types';
import get from './get';

const getRefundsRegistrationsMeta = createSelector<
  _Store.IState,
  IRefundsRegistrationReducer,
  IMeta
>(
  [get],
  (refundsRegistration) => refundsRegistration.refundsRegistrations.meta
);

export default getRefundsRegistrationsMeta;
