import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { get } from '@Model/authorization/selectors';
import { mounted, remove } from '@Model/happenings/actions';
import { getHappenings } from '@Model/happenings/selectors';
import { refreshList } from '@Model/smartporter/actions';
import { allPermissions, HAPPENINGS } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import Happenings from './List.component';
import { IListFromDispatch, IListFromState } from './List.types';

const mapStateToProps = (state: _Store.IState): IListFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    happenings: getHappenings(state),
    isLoading: getLoading(HAPPENINGS)(state),
    permissionApartmentsView: permissions.includes(
      allPermissions.access_apartments_read
    ),
    permissionHappeningsView: permissions.includes(
      allPermissions.access_happenings_read
    ),
    permissionWrite: permissions.includes(
      allPermissions.access_apartments_write
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IListFromDispatch => {
  return {
    mounted: () => {
      dispatch(mounted());
    },
    refreshSmartporterList: () => {
      dispatch(refreshList());
    },
    remove: (slug) => {
      dispatch(remove({ slug }));
    },
  };
};

export default withRouter(
  connect<IListFromState, IListFromDispatch, {}, _Store.IState>(
    mapStateToProps,
    mapDispatchToProps
  )(Happenings)
);
