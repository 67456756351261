import React from 'react';

import FindTickets from '@Compo/FindTickets';
import Grid from '@Compo/layout/Grid';

const FindTicketPage = () => (
  <Grid>
    <FindTickets />
  </Grid>
);

export default FindTicketPage;
