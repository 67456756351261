import { Font, StyleSheet } from '@react-pdf/renderer';

Font.register({
  family: 'NoirPro',
  fonts: [
    {
      src: require('./../../../../misc/styles/fonts/NoirPro/NoirPro-Regular.otf'),
    },
    {
      fontWeight: 500,
      src: require('./../../../../misc/styles/fonts/NoirPro/NoirPro-Medium.otf'),
    },
    {
      fontWeight: 600,
      src: require('./../../../../misc/styles/fonts/NoirPro/NoirPro-SemiBold.otf'),
    },
  ],
  format: 'opentype',
});

const styles = StyleSheet.create({
  container: {
    borderColor: '#000',
    borderRadius: 16,
    borderWidth: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
  flexGrow: {
    flexGrow: 1,
  },
  page: {
    marginTop: 64,
    paddingHorizontal: 32,
  },
  pageContainer: {
    fontFamily: 'NoirPro',
    fontSize: 12,
  },
  section: {
    marginBottom: 36,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 400,
    marginRight: 5,
  },
  title: {
    color: '#1A1E27',
    fontSize: 20,
    fontWeight: 600,
  },
  titleContainer: {
    marginBottom: 22,
  },
  total: {
    fontSize: 16,
    fontWeight: 500,
  },
});

export default styles;
