import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { RRule } from 'rrule';

import Date from './components/Date';
import { IPreviewProps } from './Preview.types';

const HOW_MANY_REPEATS = 10;

const Preview: FC<IPreviewProps> = ({ rrule }) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'BetterManager.AddingConfiguration.components.Preview.components.Date',
  });

  const ruleForExamples = rrule + `;COUNT=${HOW_MANY_REPEATS}`;

  try {
    const forExamples = RRule.fromString(ruleForExamples);

    return (
      <div>
        {forExamples.all().map((rule: Date, index: number) => {
          return <Date date={rule} key={index} />;
        })}
      </div>
    );
  } catch (e) {
    return <Typography variant="body1">{t('error')}</Typography>;
  }
};

export default Preview;
