import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { handleActiveCategory, saveCategory } from '@Model/products/actions';
import { getActiveCategory } from '@Model/products/selectors';
import _Store from '@Store';
import CategoryModal from './CategoryModal.component';
import { prepareInitialValues } from './CategoryModal.helpers';
import {
  ICategoryModalFromDispatch,
  ICategoryModalFromState,
} from './CategoryModal.types';

const mapStateToProps = (state: _Store.IState): ICategoryModalFromState => {
  return {
    initialValues: prepareInitialValues(getActiveCategory(state)),
    partnerId: getHappeningPartnerId(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ICategoryModalFromDispatch => {
  return {
    closeModal: () => dispatch(handleActiveCategory(null)),
    saveCategory: (body) => dispatch(saveCategory(body)),
  };
};

export default connect<
  ICategoryModalFromState,
  ICategoryModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(CategoryModal);
