import { getHappening } from '@Model/happening/selectors';
import _Store from '@Store';
import { createSelector } from 'reselect';
import { IHappeningReducer } from '../../happening/types';

const isPricePerPersonCalculating = createSelector<
  _Store.IState,
  IHappeningReducer,
  boolean
>(
  [getHappening],
  (happening) => {
    if (happening) {
      return happening.calculatePricePerPerson || false;
    }
    return false;
  },
);

export default isPricePerPersonCalculating;
