import React, { isValidElement, useEffect } from 'react';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Checkbox,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  useMediaQuery,
} from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import BooleanLabel from '@Compo/reusable/BooleanLabel';

import { SortableBody, SortableItem } from './components/Sortable';
import useStyles from './TableUi.styles';
import { ITableUiProps } from './TableUi.types';

const TableUi = ({
  rows,
  columns,
  total,
  rowsPerPage,
  handleChangePage,
  activePage,
  handleChangeRowsPerPage,
  disableCheckBox,
  onRowClick,
  singleSelect,
  setOwnSelected,
  isSortable,
  onSortEnd,
  disableSelect,
  hidePagination,
  disableHover,
  selectedItems = [],
  isLoading,
  disableOnRowClick,
  forceCheckboxSelection,
  useColor,
  forceMobileLayout,
  hideInformation,
  enableSameFieldNameCheckbox,
}: ITableUiProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reusable.TableUi',
  });

  const matches =
    useMediaQuery((theme: Theme) => theme.breakpoints.down('sm')) ||
    forceMobileLayout;
  const [selected, setSelected] = React.useState<any[]>(selectedItems);
  const expandRef = React.useRef<HTMLTableRowElement | null>(null);
  const numSelected = selected.length;
  const rowCount = rows.length;

  const classes = useStyles();

  useEffect(() => {
    if (setOwnSelected) {
      setOwnSelected(selected);
    }
  }, [selected]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelected(rows);
      return;
    }
    setSelected([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown, MouseEvent>,
    name: any
  ) => {
    event.stopPropagation();
    if (!forceCheckboxSelection) {
      handleCheckBoxClick(name);
    }

    if (onRowClick && !disableOnRowClick) {
      onRowClick(name.id);
    }
  };

  const handleCheckBoxClick = (name: any) => {
    if (!disableCheckBox && !singleSelect) {
      const selectedIndex = selected.findIndex(
        (select: any) => select.id === name.id
      );
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    } else if (singleSelect) {
      const selectedIndex = selected.indexOf(name);
      let newSelected: string[] = [];
      if (selectedIndex === 0) {
        newSelected = [];
      } else if (selectedIndex === -1) {
        newSelected = [name];
      }
      setSelected(newSelected);
    }
  };

  const isSelected = (id: string) =>
    selected.some((select) => id === select.id);

  const formatRow = (row: any, colId: string) => {
    let value = row[colId];
    const objectParts = colId.split('.');
    if (objectParts.length > 1) {
      value = objectParts.reduce((obj, prop) => {
        return obj && obj[prop];
      }, row);
    }
    if (typeof value === 'boolean') {
      return (
        <BooleanLabel isTrue={row[colId]} isColorDisabled={row.isDisabled} />
      );
    } else if (
      typeof value === 'undefined' ||
      value === null ||
      value === 'Invalid date'
    ) {
      return t('undefined');
    } else {
      return value;
    }
  };

  const handleCheckBox = (
    row: any,
    event: React.MouseEvent<unknown, MouseEvent>
  ) => {
    event.stopPropagation();
    handleCheckBoxClick(row);
  };
  const onMouseEnter = () => {
    if (expandRef && expandRef.current) {
      expandRef.current.previousElementSibling?.classList.add('active');
    }
  };

  const onMouseLeave = () => {
    if (expandRef && expandRef.current) {
      expandRef.current.previousElementSibling?.classList.remove('active');
    }
  };

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <Loader />
      </div>
    );
  }

  const allRowsSameFieldNameValue = () => {
    if (!enableSameFieldNameCheckbox?.length) {
      return true;
    }

    const objectParts = enableSameFieldNameCheckbox.split('.');

    return rows.every((value) => {
      let objectValue = value;
      let rowValue = rows[0];

      if (objectParts.length > 1) {
        objectValue = objectParts.reduce((obj, prop) => {
          return obj && obj[prop];
        }, value);
        rowValue = objectParts.reduce((obj, prop) => {
          return obj && obj[prop];
        }, rows[0]);
      }
      return rowValue === objectValue;
    });
  };

  return (
    <>
      <TableContainer
        data-testid="tableUi"
        className={cn(
          (disableSelect || isSortable) && classes.disableSelect,
          !(!hidePagination && handleChangePage) &&
            classes.tableWithoutPagination
        )}
      >
        <Table className={cn(matches && classes.tableContainer)} size="medium">
          {!matches && (
            <TableHead>
              <TableRow>
                {isSortable && <TableCell />}
                {!disableCheckBox && (
                  <TableCell padding="checkbox">
                    {!singleSelect && allRowsSameFieldNameValue() && (
                      <Checkbox
                        indeterminate={
                          numSelected > 0 && numSelected < rowCount
                        }
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={handleSelectAllClick}
                      />
                    )}
                  </TableCell>
                )}
                {columns.map(
                  (col) =>
                    !col.hided && (
                      <TableCell
                        key={col.id}
                        align={col.align || col.numeric ? 'right' : 'left'}
                      >
                        {col.label}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
          )}
          {isSortable ? (
            <SortableBody
              onSortEnd={onSortEnd}
              lockAxis="y"
              helperClass={'moving'}
              distance={10}
              className={cn(matches && classes.tableBody)}
            >
              {!!rows.length ? (
                rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const hideLastBorder =
                    !(!hidePagination && handleChangePage) &&
                    index === rows.length - 1;

                  return (
                    <SortableItem
                      key={row.id}
                      index={index}
                      onClick={(event) => handleClick(event, row)}
                      isItemSelected={isItemSelected}
                      className={cn(matches && classes.tableRow)}
                    >
                      <TableCell
                        className={cn(
                          matches && classes.tableCheckbox,
                          hideLastBorder && classes.hideBorder
                        )}
                        padding="checkbox"
                      >
                        <DragIndicatorIcon color="disabled" />
                      </TableCell>
                      {!disableCheckBox && (
                        <TableCell
                          className={cn(hideLastBorder && classes.hideBorder)}
                          padding="checkbox"
                        >
                          {singleSelect ? (
                            <Radio checked={isItemSelected} />
                          ) : (
                            <Checkbox checked={isItemSelected} />
                          )}
                        </TableCell>
                      )}
                      {columns.map((col) => {
                        if (matches) {
                          return (
                            !col.hided && (
                              <TableCell
                                className={cn(
                                  hideLastBorder && classes.hideBorder,
                                  isValidElement(row[col.id]) &&
                                    classes.buttonsCell
                                )}
                                key={col.id}
                                align={col.align}
                              >
                                <Grid container={true}>
                                  <Grid
                                    item={true}
                                    xs={12}
                                    sm={4}
                                    className={cn(
                                      classes.label,
                                      isValidElement(row[col.id]) &&
                                        classes.actions
                                    )}
                                  >
                                    {col.label}
                                  </Grid>
                                  <Grid item={true} xs={12} sm={8}>
                                    {formatRow(row, col.id)}
                                  </Grid>
                                </Grid>
                              </TableCell>
                            )
                          );
                        }
                        return (
                          !col.hided && (
                            <TableCell
                              className={cn(
                                hideLastBorder && classes.hideBorder,
                                isValidElement(row[col.id]) &&
                                  classes.buttonsCell
                              )}
                              key={col.id}
                              align={col.align}
                            >
                              {formatRow(row, col.id)}
                            </TableCell>
                          )
                        );
                      })}
                    </SortableItem>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    className={classes.hideBorder}
                    align="center"
                    colSpan={columns.length}
                  >
                    {t('empty')}
                  </TableCell>
                </TableRow>
              )}
            </SortableBody>
          ) : (
            <TableBody className={cn(matches && classes.tableBody)}>
              {!!rows.length ? (
                rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const hideLastBorder =
                    !(!hidePagination && handleChangePage) &&
                    index === rows.length - 1;

                  const disableSelectSameField =
                    !!enableSameFieldNameCheckbox?.length &&
                    selected.some((value) => {
                      const objectParts =
                        enableSameFieldNameCheckbox.split('.');
                      let objectValue = value;
                      let rowValue = row;
                      if (objectParts.length > 1) {
                        objectValue = objectParts.reduce((obj, prop) => {
                          return obj && obj[prop];
                        }, value);
                        rowValue = objectParts.reduce((obj, prop) => {
                          return obj && obj[prop];
                        }, row);
                      }

                      return rowValue !== objectValue;
                    });

                  return (
                    <>
                      <TableRow
                        key={row.id}
                        hover={!disableHover}
                        role="checkbox"
                        selected={isItemSelected}
                        tabIndex={-1}
                        className={cn(
                          hidePagination && classes.disableLastElementBorder,
                          disableOnRowClick && classes.disableOnRowClick,
                          row.expand && isItemSelected && classes.expand,
                          matches && classes.tableRow,
                          disableSelectSameField &&
                            classes.disableSelectSameField
                        )}
                        style={{
                          backgroundColor: useColor ? row.color : undefined,
                        }}
                        onClick={(event) => handleClick(event, row)}
                      >
                        {!disableCheckBox && (
                          <TableCell
                            padding="checkbox"
                            className={cn(
                              matches && classes.tableCheckbox,
                              hideLastBorder && classes.hideBorder
                            )}
                          >
                            {singleSelect ? (
                              <Radio
                                onClick={(e) => handleCheckBox(row, e)}
                                checked={isItemSelected}
                              />
                            ) : (
                              <Checkbox
                                onClick={(e) => handleCheckBox(row, e)}
                                checked={isItemSelected}
                                disabled={disableSelectSameField}
                              />
                            )}
                          </TableCell>
                        )}
                        {columns.map((col) => {
                          if (matches) {
                            return (
                              !col.hided && (
                                <TableCell
                                  className={cn(
                                    isValidElement(row[col.id]) &&
                                      classes.buttonsCell,
                                    row.isDisabled && classes.disabled,
                                    hideLastBorder && classes.hideBorder
                                  )}
                                  key={col.id}
                                  align={col.align}
                                  component={col.component}
                                  style={
                                    col.width
                                      ? {
                                          wordWrap: 'break-word',
                                        }
                                      : undefined
                                  }
                                >
                                  <Grid container={true}>
                                    <Grid
                                      item={true}
                                      xs={12}
                                      sm={4}
                                      className={cn(
                                        classes.label,
                                        isValidElement(row[col.id]) &&
                                          classes.actions
                                      )}
                                    >
                                      {col.label}
                                    </Grid>
                                    <Grid item={true} xs={12} sm={8}>
                                      {formatRow(row, col.id)}
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              )
                            );
                          }
                          return (
                            !col.hided && (
                              <TableCell
                                className={cn(
                                  isValidElement(row[col.id]) &&
                                    classes.buttonsCell,
                                  row.isDisabled && classes.disabled,
                                  hideLastBorder && classes.hideBorder
                                )}
                                key={col.id}
                                align={col.align}
                                component={col.component}
                                style={
                                  col.width
                                    ? {
                                        width: col.width,
                                        wordWrap: 'break-word',
                                      }
                                    : undefined
                                }
                              >
                                {formatRow(row, col.id)}
                              </TableCell>
                            )
                          );
                        })}
                      </TableRow>
                      {row.expand && isItemSelected && (
                        <TableRow
                          hover={!disableHover}
                          role="checkbox"
                          selected={isItemSelected}
                          ref={expandRef}
                          onMouseEnter={onMouseEnter}
                          onMouseLeave={onMouseLeave}
                        >
                          <TableCell colSpan={columns.length + 1}>
                            {row.expand}
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  );
                })
              ) : (
                <TableRow className={cn(matches && classes.tableRow)}>
                  <TableCell
                    align="center"
                    className={classes.hideBorder}
                    colSpan={
                      !disableCheckBox ? columns.length + 1 : columns.length
                    }
                  >
                    {t('empty')}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {!hidePagination && handleChangePage && (
        <TablePagination
          rowsPerPageOptions={handleChangeRowsPerPage ? [5, 10, 20] : []}
          component="div"
          count={total || 0}
          rowsPerPage={rowsPerPage || 0}
          page={activePage || 0}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          labelDisplayedRows={({ from, to, count }) =>
            hideInformation
              ? ''
              : `${from}–${to} ${t('of')} ${
                  count !== -1 ? count : `${t('moreThan')} ${to}`
                }`
          }
          labelRowsPerPage={`${t('rowsPerPage')}:`}
        />
      )}
    </>
  );
};

export default TableUi;
