import { createSelector } from 'reselect';

import _Store from '@Store';
import { IActiveFilter, ISettingsReducer } from './../types';
import get from './get';

const getActiveFilter = createSelector<
  _Store.IState,
  ISettingsReducer,
  IActiveFilter
>([get], (settings) => settings.activeFilter);

export default getActiveFilter;
