import React, { useEffect } from 'react';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Button, Hidden, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import colors from '@Compo/App/colors';

import SessionModal from './components/SessionModal';
import { ISessionPanelProps } from './SessionPanel.types';

const useStyles = makeStyles((theme) => ({
  sessionButton: {
    fontWeight: 500,
  },
  startIcon: {
    color: colors.text.secondary,
    [theme.breakpoints.down('lg')]: {
      marginRight: 22,
    },
  },
}));

const SessionPanel = ({
  mounted,
  showModal,
  isModalOpen,
  isSession,
  endSession,
  permissionWrite,
  closeModal,
  isSaleWithoutSession,
}: ISessionPanelProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'SessionPanel',
  });

  const classes = useStyles();
  useEffect(() => {
    mounted();
  }, []);

  const handleCloseModal = () => {
    closeModal();
    document.body.style.overflow = 'visible';
  };
  if (isSaleWithoutSession) {
    return null;
  }

  if (permissionWrite) {
    return (
      <>
        <SessionModal open={isModalOpen} onClose={handleCloseModal} />;
        <Hidden lgDown={true}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={isSession ? endSession : showModal}
            startIcon={<AttachMoneyIcon className={classes.startIcon} />}
            className={classes.sessionButton}
          >
            {isSession ? t('endSession') : t('startSession')}
          </Button>
        </Hidden>
        <Hidden only={['md', 'lg', 'xl']}>
          <IconButton
            size="small"
            font-size="large"
            color="secondary"
            className={classes.startIcon}
            onClick={isSession ? endSession : showModal}
          >
            <AttachMoneyIcon />
          </IconButton>
        </Hidden>
      </>
    );
  }

  return null;
};

export default SessionPanel;
