import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { handleActiveZohoProduct, saveProduct } from '@Model/products/actions';
import { getActiveZohoProduct } from '@Model/products/selectors';
import _Store from '@Store';
import ProductsModal from './ZohoModal.component';
import { prepareInitialValues } from './ZohoModal.helpers';
import { IZohoModalFromDispatch, IZohoModalFromState } from './ZohoModal.types';

const mapStateToProps = (state: _Store.IState): IZohoModalFromState => {
  return {
    initialValues: prepareInitialValues(getActiveZohoProduct(state)),
    partnerId: getHappeningPartnerId(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IZohoModalFromDispatch => {
  return {
    closeModal: () => dispatch(handleActiveZohoProduct(null)),
    saveProduct: (body) => dispatch(saveProduct(body)),
  };
};

export default connect<
  IZohoModalFromState,
  IZohoModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ProductsModal);
