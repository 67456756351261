import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  ICategory,
  ISeatsIoChartsResponse,
} from '@Services/$seats-io-api/types';

import {
  _DELETE_SEAT_CHART_FAILURE,
  _DELETE_SEAT_CHART_REQUEST,
  _DELETE_SEAT_CHART_SUCCESS,
  _GET_SEATS_CATEGORIES_FAILURE,
  _GET_SEATS_CATEGORIES_REQUEST,
  _GET_SEATS_CATEGORIES_SUCCESS,
  _GET_SEATS_CHARTS_FAILURE,
  _GET_SEATS_CHARTS_REQUEST,
  _GET_SEATS_CHARTS_SUCCESS,
  _SAVE_SEAT_CHART_FAILURE,
  _SAVE_SEAT_CHART_REQUEST,
  _SAVE_SEAT_CHART_SUCCESS,
  CHANGE_META_DATA,
  DELETE_CHART_KEY,
  FETCH_CATEGORIES,
  MOUNTED,
  SAVE_CHART_KEY,
} from './../constants/actions';
import { IMetaPayload } from './../types';

export const mounted = createStandardAction(MOUNTED)();
export const catchSaveSeatChart =
  createStandardAction(SAVE_CHART_KEY)<string>();
export const catchDeleteSeatChart = createStandardAction(DELETE_CHART_KEY)();
export const changeMetaData =
  createStandardAction(CHANGE_META_DATA)<IMetaPayload>();
export const fetchCategories = createStandardAction(FETCH_CATEGORIES)<string>();

export const getSeatsCharts = createAsyncAction(
  _GET_SEATS_CHARTS_REQUEST,
  _GET_SEATS_CHARTS_SUCCESS,
  _GET_SEATS_CHARTS_FAILURE
)<undefined, ISeatsIoChartsResponse, Error>();

export const getSeatsCategories = createAsyncAction(
  _GET_SEATS_CATEGORIES_REQUEST,
  _GET_SEATS_CATEGORIES_SUCCESS,
  _GET_SEATS_CATEGORIES_FAILURE
)<string, ICategory[], Error>();

export const saveSeatChart = createAsyncAction(
  _SAVE_SEAT_CHART_REQUEST,
  _SAVE_SEAT_CHART_SUCCESS,
  _SAVE_SEAT_CHART_FAILURE
)<string, [], Error>();

export const deleteSeatChart = createAsyncAction(
  _DELETE_SEAT_CHART_REQUEST,
  _DELETE_SEAT_CHART_SUCCESS,
  _DELETE_SEAT_CHART_FAILURE
)<undefined, [], Error>();
