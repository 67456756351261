import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    textTransform: 'uppercase',
  },
}));

export default useStyles;
