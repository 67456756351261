import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import SessionForm from './../SessionForm';
import { ISessionModalProps } from './SessionModal.types';

const SessionModal = ({ open, onClose }: ISessionModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'SessionPanel.components.SessionModal',
  });

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={onClose}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <SessionForm />
    </Dialog>
  );
};
export default SessionModal;
