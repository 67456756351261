import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { selectEventMail } from '@Model/mailing/actions';
import { getSelectedEventMail } from '@Model/mailing/selectors';
import _Store from '@Store';
import MailModal from './MailModal.component';
import { IMailModalFromDispatch, IMailModalFromState } from './MailModal.types';

const mapStateToProps = (state: _Store.IState): IMailModalFromState => ({
  showModal: !!getSelectedEventMail(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IMailModalFromDispatch => ({
  closeModal: () => dispatch(selectEventMail(null)),
});

export default connect<
  IMailModalFromState,
  IMailModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(MailModal);
