import React from 'react';

import Edit from '@Compo/happenings/Edit';
import Grid from '@Compo/layout/Grid';

const HappeningEditPage = () => (
  <Grid>
    <Edit />
  </Grid>
);

export default HappeningEditPage;
