import React, { MouseEvent } from 'react';

import { IScrollerProps } from './Scroller';
import styles from './Scroller.module.scss';

const Scroller = ({ header, values, onSelect }: IScrollerProps) => {
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const { value } = event.currentTarget.dataset;
    onSelect(value || '');
  };

  return (
    <div className={styles.scroller}>
      <h4 className={styles.title}>{header}</h4>
      <ul>
        {values.map((value, index) => (
          <li key={index}>
            <button type="button" onClick={handleClick} data-value={value}>
              {value}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Scroller;
