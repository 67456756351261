import _Store from '@Store';
import { createSelector } from 'reselect';
import { IHappeningSpace, IHappeningsReducer } from './../types';
import get from './get';

const getAllSpaces = createSelector<
  _Store.IState,
  IHappeningsReducer,
  IHappeningSpace[]
>([get], (happenings) => {
  const spaces: IHappeningSpace[] = [];

  happenings.items?.forEach((happening) =>
    happening.spaces.forEach((space) => spaces.push(space))
  );

  return spaces;
});

export default getAllSpaces;
