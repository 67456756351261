import { EMPTY, of as of$ } from 'rxjs';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  clearFilters,
  clearFiltersRequest,
  getGroupedReservations,
  getReservations,
  selectAllFilters,
  setFilters,
  switchFilter,
} from '@Model/reservations/actions';
import getFilters from '@Model/reservations/selectors/getFilters';
import _Store from '@Store';

export const unselectAllWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(clearFiltersRequest)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const filters = getFilters(state);

      const unselectedFilters = filters.map((filter) => {
        const unselectedSpaces = filter.spaces.map((space) => {
          return {
            ...space,
            selected: false,
          };
        });

        return {
          ...filter,
          spaces: unselectedSpaces,
        };
      });

      return of$(
        clearFilters(unselectedFilters),
        getGroupedReservations.request()
      );
    })
  );
};
export const selectAllWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(selectAllFilters)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const filters = getFilters(state);

      const unselectedFilters = filters.map((filter) => {
        const unselectedSpaces = filter.spaces.map((space) => {
          return {
            ...space,
            selected: true,
          };
        });

        return {
          ...filter,
          spaces: unselectedSpaces,
        };
      });

      return of$(
        clearFilters(unselectedFilters),
        getGroupedReservations.request()
      );
    })
  );
};

export const setFiltersWhenFilterSwitched: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(switchFilter)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { happeningId, spaceId } = action.payload;
      const filters = [...getFilters(state)];

      const filterToUpdate = filters.find(
        (filter) => filter.id === happeningId
      );

      if (filterToUpdate) {
        const spaceToUpdate = filterToUpdate.spaces.find(
          (space) => space.id === spaceId
        );

        if (spaceToUpdate) {
          spaceToUpdate.selected = !spaceToUpdate.selected;

          return of$(setFilters(filters), getGroupedReservations.request());
        } else if (!spaceId) {
          const spaceIndex = filters.findIndex(
            (filter) => filter.id === happeningId
          );

          if (spaceIndex !== -1) {
            const allIsSelected = filters[spaceIndex].spaces.every((space) => {
              if (typeof space.selected === 'undefined') {
                return true;
              }
              return !!space.selected;
            });

            filters[spaceIndex].spaces = filters[spaceIndex].spaces.map(
              (space) => {
                return { ...space, selected: !allIsSelected };
              }
            );

            return of$(setFilters(filters), getGroupedReservations.request());
          }
        }
      }

      return EMPTY;
    })
  );
};
