import { combineReducers } from 'redux';

import deviceReducer from './device';
import sizesReducer from './sizes';
import tokenReducer from './token';

const reducer = combineReducers({
  device: deviceReducer,
  sizes: sizesReducer,
  token: tokenReducer,
});

export default reducer;
