import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { catchGetRuleType } from '@Model/priceTypes/actions';
import { getPriceTypesWithPrice } from '@Model/reservation/selectors';
import _Store from '@Store';
import PricesTable from './PricesTable.component';
import {
  IPricesTableFromDispatch,
  IPricesTableFromState,
} from './PricesTable.types';

const mapStateToProps = (state: _Store.IState): IPricesTableFromState => ({
  prices: getPriceTypesWithPrice(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPricesTableFromDispatch => ({
  mounted: () => dispatch(catchGetRuleType()),
});

export default connect<
  IPricesTableFromState,
  IPricesTableFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PricesTable);
