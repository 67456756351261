import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getHappeningPartnerId } from '@Model/happenings/selectors';
import {
  getAllGroups,
  getAllPartners,
  handleActiveUser,
} from '@Model/users/actions';
import { getGroups, getPartners } from '@Model/users/selectors';

import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => ({
  groups: getGroups(state),
  partners: getPartners(state),
  selectedPartner: getHappeningPartnerId(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormLayoutFromDispatch => ({
  getAllGroups: (partners) => dispatch(getAllGroups(partners)),
  getAllPartners: () => dispatch(getAllPartners()),
  handleClose: () => dispatch(handleActiveUser(null)),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
