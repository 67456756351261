import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  IEventsPartnerResponse,
  IMetadataTypes,
  IPartnersResponse,
  IPoolsPartnerResponse,
  IRundatesPartnerResponse,
} from '@Services/$metadata-api/types';

import {
  _GET_EVENTS_PARTNER_FAILURE,
  _GET_EVENTS_PARTNER_REQUEST,
  _GET_EVENTS_PARTNER_SUCCESS,
  _GET_PARTNERS_FAILURE,
  _GET_PARTNERS_REQUEST,
  _GET_PARTNERS_SUCCESS,
  _GET_POOLS_PARTNER_FAILURE,
  _GET_POOLS_PARTNER_REQUEST,
  _GET_POOLS_PARTNER_SUCCESS,
  _GET_RUNDATES_PARTNER_FAILURE,
  _GET_RUNDATES_PARTNER_REQUEST,
  _GET_RUNDATES_PARTNER_SUCCESS,
  _RESET_STATE,
  CATCH_VALUES,
  CHANGE_IS_FILTER_BY_ACTIVE_PARTNER,
  HANDLE_ACTIVE_PAGE,
} from '../constants/actions';
import { IHandlePagePayload } from '../types';

export const resetState = createStandardAction(_RESET_STATE)();

export const getPartners = createAsyncAction(
  _GET_PARTNERS_REQUEST,
  _GET_PARTNERS_SUCCESS,
  _GET_PARTNERS_FAILURE
)<undefined, IPartnersResponse, Error>();

export const getEventsPartner = createAsyncAction(
  _GET_EVENTS_PARTNER_REQUEST,
  _GET_EVENTS_PARTNER_SUCCESS,
  _GET_EVENTS_PARTNER_FAILURE
)<undefined, IEventsPartnerResponse, Error>();

export const getRundatesPartner = createAsyncAction(
  _GET_RUNDATES_PARTNER_REQUEST,
  _GET_RUNDATES_PARTNER_SUCCESS,
  _GET_RUNDATES_PARTNER_FAILURE
)<undefined, IRundatesPartnerResponse, Error>();

export const getPoolsPartner = createAsyncAction(
  _GET_POOLS_PARTNER_REQUEST,
  _GET_POOLS_PARTNER_SUCCESS,
  _GET_POOLS_PARTNER_FAILURE
)<undefined, IPoolsPartnerResponse, Error>();

export const catchValues = createStandardAction(CATCH_VALUES)<IMetadataTypes>();

export const handleActivePage =
  createStandardAction(HANDLE_ACTIVE_PAGE)<IHandlePagePayload>();

export const changeIsFilterByActivePartner = createStandardAction(
  CHANGE_IS_FILTER_BY_ACTIVE_PARTNER
)<boolean>();
