import React from 'react';

import Grid from '@Compo/layout/Grid';
import PoolDescriptions from '@Compo/PoolDescriptions';

const PoolDescriptionsPage = () => (
  <Grid>
    <PoolDescriptions />
  </Grid>
);

export default PoolDescriptionsPage;
