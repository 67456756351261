import { Selector } from 'reselect';

import { IAvailablePoolsReducer } from './../types';

import _Store from '@Store';

const getAvailablePools: Selector<_Store.IState, IAvailablePoolsReducer> = (
  state
) => state.events.availablePools;

export default getAvailablePools;
