import { setInnerSizes, setSizes } from '@Model/app/actions';
import { getSizes } from '@Model/app/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Resizer from './Resizer.component';
import { IResizerFromDispatch, IResizerFromStore } from './Resizer.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IResizerFromDispatch => {
  return {
    handleInnerSize: (width: number, height: number) =>
      dispatch(
        setInnerSizes({
          height,
          width,
        }),
      ),
    handleSetSizes: (width: number, height: number) =>
      dispatch(
        setSizes({
          height,
          width,
        }),
      ),
  };
};

function mapStateToProps(state: _Store.IState): IResizerFromStore {
  return {
    sizes: getSizes(state),
  };
}

export default connect<
  IResizerFromStore,
  IResizerFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Resizer);
