import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPermissionsSegments } from '@Services/$users-api/types';

import { IUsersReducer } from '../types';
import get from './get';

const getPermissions = createSelector<
  _Store.IState,
  IUsersReducer,
  IPermissionsSegments[]
>([get], (users) => users.permissions);

export default getPermissions;
