import {
  getHappening,
  getSpaceMaxNumberOfPeople,
} from '@Model/happening/selectors';
import { getAdd } from '@Model/reservation/selectors/index';
import { createSelector } from 'reselect';
import getSelectedPeopleCount from './getSelectedPeopleCount';
import getSelectedSlot from './getSelectedSlot';

const getSelectedSlotOverbooking = createSelector(
  [
    getSelectedSlot,
    getSpaceMaxNumberOfPeople,
    getSelectedPeopleCount,
    getAdd,
    getHappening,
  ],
  (selectedSlot, maxNumberOfPeople, peopleCount, add, happening) => {
    if (!add.spaceId) {
      return 0;
    }

    if (happening) {
      if (!happening.allowNextSlotWhenOverbooked) {
        return 0;
      }
    }

    if (
      selectedSlot === null ||
      maxNumberOfPeople === null ||
      peopleCount === null
    ) {
      return 0;
    }

    // when overbooked
    if (maxNumberOfPeople < peopleCount) {
      return Math.ceil(peopleCount / maxNumberOfPeople) - 1;
    }

    return 0;
  },
);

export default getSelectedSlotOverbooking;
