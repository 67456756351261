import React from 'react';

import Grid from '@Compo/layout/Grid';
import Mail from '@Compo/Mail';

const MailPage = () => (
  <Grid>
    <Mail />
  </Grid>
);

export default MailPage;
