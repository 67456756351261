import React, { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import Color from 'color';
import globalize from 'globalize';
import 'globalize/lib/cultures/globalize.culture.pl';
import moment from 'moment';
import {
  Calendar as BigCalendar,
  globalizeLocalizer,
  View,
} from 'react-big-calendar';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import { IColumns } from '@Compo/reusable/TableUi/TableUi.types';
import weekEndDate from '@Misc/helpers/weekEndDate';
import weekStartDate from '@Misc/helpers/weekStartDate';
import { IReservationFormatted } from '@Model/reservations/types';

import { ICalendarProps } from './Calendar';
import { DAY, MESSAGES, VIEWS, WEEK } from './Calendar.constants';
import styles from './Calendar.module.scss';
import CustomToolbar from './components/CustomToolbar';
import DetailsModal from './components/DetailsModal';
import ListModal from './components/ListModal';

const defaultCulture = 'pl';
const localizer = globalizeLocalizer(globalize);

const Calendar = ({
  clearFilters,
  selectAllFilters,
  dateChanged,
  isLoading,
  isReservationListSelected,
  isReservationSelected,
  mounted,
  range,
  reservations,
  selectReservationList,
  setFilters,
  isModalLoading,
}: ICalendarProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Calendar',
  });

  const [currentView, setCurrentView] = useState(DAY as View);
  const calendarRef = useRef<any>(null);

  useEffect(() => {
    mounted();

    if (localStorage) {
      const filters = localStorage.getItem('filters');
      if (filters && filters.length) {
        setFilters(JSON.parse(filters));
      }
    }
  }, []);

  useEffect(() => {
    if (
      calendarRef &&
      calendarRef.current &&
      ReactDOM &&
      ReactDOM.findDOMNode &&
      !isLoading
    ) {
      const myScroll = document.getElementsByClassName('rbc-time-content');
      const list = document.getElementsByClassName('rbc-label');
      const currentTime = new Date().setMinutes(0);

      const currentTimeAsString = moment(currentTime).format('HH:mm');

      if (list && list.length) {
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < list.length; i++) {
          if (list[i].textContent === currentTimeAsString) {
            if (list[i] instanceof HTMLElement && list[i] !== null) {
              if (myScroll && myScroll.length) {
                myScroll[0].scrollTop = (list[i] as HTMLElement).offsetTop;
              }
            }
          }
        }
      }
    }
  }, [isLoading, currentView]);

  const handleNavigate = (date: Date, view: View) => {
    dateChanged({
      endDate: view === WEEK ? weekEndDate(date) : date,
      startDate: view === WEEK ? weekStartDate(date) : date,
    });
  };

  const handleEventProp = (event: IReservationFormatted) => {
    if (event.color) {
      const colorFormatter = Color(event.color);

      return {
        style: {
          background: event.color,
          borderColor: colorFormatter.darken(0.2).hex(),
          color: colorFormatter.isLight() ? '#000' : '#fff',
        },
      };
    }

    return {};
  };

  const handleDoubleClick = (reservation: IReservationFormatted) => {
    selectReservationList({
      duration: reservation.duration,
      end: moment(reservation.end).format('YYYY-MM-DD[T]HH:mm:ss'),
      happeningSlug: reservation.happeningSlug,
      spaceSlug: reservation.spaceSlug,
      start: moment(reservation.start).format('YYYY-MM-DD[T]HH:mm:ss'),
    });
  };

  const handleViewChange = (view: View) => {
    const today = new Date();

    setCurrentView(view);
    dateChanged({
      endDate: view === WEEK ? weekEndDate(today) : today,
      startDate: view === WEEK ? weekStartDate(today) : today,
    });
  };

  const columns: IColumns[] = [
    { id: 'name', label: '' },
    { id: 'capacityLeft', label: 'Wolne', width: 160 },
    { id: 'capacityLive', label: 'Obłożenie', width: 160 },
  ];

  const capacityMax = 1000;
  // TODO: mock
  const rows = [
    {
      capacityLeft: 0,
      capacityLive: `${1000}/${capacityMax}`,
      name: 'Park świateł',
    },
    {
      capacityLeft: 0,
      capacityLive: `${1000}/${capacityMax}`,
      name: 'Park świateł',
    },
  ];

  return (
    <section>
      {(isReservationListSelected || isModalLoading) && (
        <ListModal isLoading={isModalLoading} />
      )}

      <DetailsModal isReservationSelected={isReservationSelected} />

      {isLoading && (
        <GoingPaper>
          <div className={styles.loader}>
            <Loader />
          </div>
        </GoingPaper>
      )}

      {!isLoading && (
        <GoingPaper title={t('title')} description={t('description')}>
          {/* TODO: uncomment this when ready */}
          {/* <GenericTable>
            <TableUi
              rows={rows}
              columns={columns}
              hidePagination={true}
              disableCheckBox={true}
              disableHover={true}
            />
          </GenericTable> */}
          <div
            className={cn(
              styles.wrapper,
              currentView === WEEK && styles.hideToday
            )}
          >
            <BigCalendar
              dayLayoutAlgorithm={
                currentView === DAY ? 'no-overlap' : 'overlap'
              }
              culture={defaultCulture}
              components={{
                toolbar: (props) => (
                  <CustomToolbar
                    {...props}
                    clearFilters={clearFilters}
                    selectAllFilters={selectAllFilters}
                  />
                ),
              }}
              date={moment(range ? range.startDate : new Date()).toDate()}
              events={reservations}
              eventPropGetter={handleEventProp}
              localizer={localizer}
              messages={MESSAGES}
              step={15}
              timeslots={1}
              view={currentView}
              views={VIEWS}
              onDoubleClickEvent={handleDoubleClick}
              onNavigate={handleNavigate}
              onView={handleViewChange}
              ref={calendarRef}
              showMultiDayTimes={true}
            />
          </div>
        </GoingPaper>
      )}
    </section>
  );
};

export default Calendar;
