import { object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';

const configuration = {
  description: string().nullable(),
  name: string().required(REQUIRED_VALIDATOR_TEXT),
};

export const TicketListSchema = object().shape(configuration);
