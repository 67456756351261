import _Store from '@Store';
import { createSelector } from 'reselect';
import { IApartment, ISmarporterReducer } from '../types';
import get from './get';

const getApartments = createSelector<
  _Store.IState,
  ISmarporterReducer,
  IApartment[]
>(
  [get],
  (smartporter) => [
    {
      apartmentExternalId: 0,
      id: 0,
      name: 'Wejście bez blokady',
    },
    ...smartporter.apartments.sort((a, b) => a.name.localeCompare(b.name)),
  ],
);

export default getApartments;
