import React from 'react';

import NewAddingConfiguration from '@Compo/BetterManager/NewAddingConfiguration';
import Grid from '@Compo/layout/Grid';

const NewRules = () => (
  <Grid>
    <NewAddingConfiguration />
  </Grid>
);

export default NewRules;
