import { EMPTY as EMPTY$ } from 'rxjs';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import { getGroups, getUsers, saveUser } from '@Model/users/actions';

import { resetError, resetLoading, setLoading } from './../actions';
import { USERS } from './../constants/constants';

export const setUpStateWhenEventsUsersFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([getUsers.request, getGroups.request])),
    mergeMap$(() => {
      return [resetError(USERS), setLoading(USERS)];
    })
  );
};

export const setLoaderWhenEventsUsersFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          getUsers.success,
          getUsers.failure,
          getGroups.success,
          getGroups.failure,
        ],
        action
      )
    ),
    map$(() => resetLoading(USERS))
  );
};

export const setErrorOnEventsUsersFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([getUsers.failure, getGroups.failure, saveUser.failure])
    ),
    mergeMap$((action) => {
      if (action.payload.statusCode !== 426) {
        return [addToast(action.payload.message, TYPE_ERROR)];
      }
      return EMPTY$;
    })
  );
};
