import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  cardContent: {
    alignItems: 'center',
    display: 'flex',
    height: 256,
    justifyContent: 'center',
    width: '100%',
  },
  media: {
    height: 0,
    paddingTop: '86.42%',
    width: '100%',
  },
}));

export default useStyles;
