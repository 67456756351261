import config from '@Config';
import debounce from 'lodash.debounce';
import { Component } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { IResizer } from './Resizer.types';

class Resizer extends Component<IResizer> {
  private readonly actionType: string = 'resize';

  private readonly isBrowser: boolean;

  private readonly debouncedResizeFunction: () => void;

  constructor(props: IResizer) {
    super(props);

    // never change
    this.isBrowser = typeof window !== 'undefined';

    this.debouncedResizeFunction = debounce(
      this.resize.bind(this),
      config.resizer.debounceTime,
    );

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.props.handleInnerSize(
          entry.contentRect.width,
          entry.contentRect.height,
        );
      }
    });
    resizeObserver.observe(document.body);
  }

  public componentDidMount() {
    if (this.isBrowser) {
      window.addEventListener(this.actionType, this.debouncedResizeFunction);

      this.resize();
    }
  }

  public componentWillUnmount() {
    if (this.isBrowser) {
      window.removeEventListener(this.actionType, this.debouncedResizeFunction);
    }
  }

  public render() {
    return null;
  }

  private resize() {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    if (
      this.props.sizes.width !== width ||
      this.props.sizes.height !== height
    ) {
      this.props.handleSetSizes(width, height);
    }
  }
}

export default Resizer;
