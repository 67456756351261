import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { sendTest } from '@Model/mailing/actions';

import TestEmailModal from './TestEmailModal.component';
import { ITestEmailModalFromDispatch } from './TestEmailModal.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITestEmailModalFromDispatch => ({
  sendTestMailing: (payload) => dispatch(sendTest(payload)),
});

export default connect<{}, ITestEmailModalFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(TestEmailModal);
