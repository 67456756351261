import React, { ChangeEvent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '@Compo/reusable/ConfirmModal';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';

import UsersModal from './components/UsersModal';
import useStyles from './UsersTable.styles';
import { IUsersTableProps, UserStatus } from './UsersTable.types';

const UsersTable = ({
  addNewUser,
  mounted,
  users,
  changeUserStatus,
  edit,
  meta,
  handleActivePage,
  handleChangeRowsPerPage,
  search,
  searchText,
  isLoading,
}: IUsersTableProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Users.components.UsersTable',
  });

  const [searchParam, setSearchParam] = useState(searchText);
  const [searchTimeOut, setSearchTimeOut] = useState<NodeJS.Timeout | null>(
    null
  );
  const [showDisableUserModal, setShowDisableUserModal] = useState(false);
  const [disableUserId, setDisableUserId] = useState<number>();

  const classes = useStyles();

  const columns = [
    { id: 'firstName', label: t('name') },
    { id: 'lastName', label: t('lastName') },
    { id: 'groups', label: t('groupsName') },
    { id: 'email', label: t('email') },
    { id: 'actions', label: t('actions') },
  ];

  const onPageChanged = (event: unknown, page: number) => {
    handleActivePage(page + 1);
  };

  const catchChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChangeRowsPerPage(Number(event.target.value));
  };

  const handleStatusChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
    status: string
  ) => {
    event.stopPropagation();

    if (status === UserStatus.disable) {
      setDisableUserId(id);
      setShowDisableUserModal(true);
    } else {
      changeUserStatus(id, status);
    }
  };

  const handleCloseDisableUserModal = () => {
    setShowDisableUserModal(false);
    setDisableUserId(undefined);
  };

  const handleConfirmDisableUserModal = () => {
    handleCloseDisableUserModal();

    if (disableUserId) {
      changeUserStatus(disableUserId, UserStatus.disable);
    }
  };

  const catchEdit = (id: string) => {
    const foundedUser = users.find((user) => user.id === Number(id));
    if (foundedUser?.active) {
      edit(Number(id));
    }
  };

  const catchHandleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchParam(e.target.value);
    searchFunc(e.target.value);
  };

  const searchFunc = (text: string) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        search(text);
      }, 1000)
    );
  };

  const normalizedUsers = users.map((user) => ({
    ...user,
    actions: (
      <Grid container={true} spacing={2}>
        <Grid item={true}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            disabled={!user.active}
          >
            {t('edit')}
          </Button>
        </Grid>
        <Grid item={true}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={(e) =>
              handleStatusChange(
                e,
                user.id,
                user.active ? UserStatus.disable : UserStatus.enable
              )
            }
          >
            {user.active ? t('deactivate') : t('activate')}
          </Button>
        </Grid>
      </Grid>
    ),
    groups: user.groups?.map((group) => group.name).join(', ') || undefined,
    isDisabled: !user.active,
  }));

  useEffect(() => {
    mounted();
  }, []);

  return (
    <>
      <ConfirmModal
        open={showDisableUserModal}
        title={t('deactivateModalTitle')}
        question={t('deactivateModalDescription')}
        handleClose={handleCloseDisableUserModal}
        onCancel={handleCloseDisableUserModal}
        onConfirm={handleConfirmDisableUserModal}
      />
      <GenericTable>
        <UsersModal />
        <TableActions>
          <TextField
            name="search"
            helperText={t('search')}
            value={searchParam}
            onChange={catchHandleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.buttonsContainer}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              startIcon={<AddIcon />}
              className={cn(classes.button, classes.buttonFirst)}
              onClick={addNewUser}
            >
              {t('add')}
            </Button>
          </div>
        </TableActions>
        <TableUi
          rows={normalizedUsers}
          columns={columns}
          total={meta.total}
          activePage={meta.page - 1}
          rowsPerPage={meta.size}
          handleChangePage={onPageChanged}
          handleChangeRowsPerPage={catchChangeRowsPerPage}
          disableCheckBox={true}
          onRowClick={catchEdit}
          isLoading={isLoading}
        />
      </GenericTable>
    </>
  );
};

export default UsersTable;
