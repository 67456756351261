import { getHappening, saveHappening } from '@Model/happening/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetError, resetLoading, setLoading } from './../actions';
import { HAPPENING } from './../constants/constants';

export const setUpStateWhenSingleHappeningFetchRequested: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$(isActionOf([getHappening.request, saveHappening.request])),
    mergeMap$(() => [resetError(HAPPENING), setLoading(HAPPENING)]),
  );
};

export const setLoaderWhenSingleHappeningFetchFinished: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          getHappening.success,
          getHappening.failure,
          saveHappening.success,
          saveHappening.failure,
        ],
        action,
      ),
    ),
    map$(() => resetLoading(HAPPENING)),
  );
};

export const setErrorOnSingleHappeningFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getHappening.failure, saveHappening.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR)),
  );
};
