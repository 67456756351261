import React from 'react';

import Login from '@Compo/Login';

const HappeningEditPage = () => (
  <>
    <Login />
  </>
);

export default HappeningEditPage;
