import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PrinterTable from './PrinterTable';
import { IDetailsModalProps } from './ReportModal.types';

const ReportModal = ({ open, close }: IDetailsModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'happenings.List.components.ReportModal',
  });

  return (
    <Dialog open={open} maxWidth="md" fullWidth={true} onClose={close}>
      <DialogTitle>{t('title')}</DialogTitle>
      <PrinterTable close={close} />
    </Dialog>
  );
};

export default ReportModal;
