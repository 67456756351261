import { createSelector } from 'reselect';

import _Store from '@Store';
import {
  IPartnerReportFiltersParamsResponse,
  ISettingsReducer,
} from './../types';
import get from './get';

const getReportParameters = createSelector<
  _Store.IState,
  ISettingsReducer,
  IPartnerReportFiltersParamsResponse | null
>([get], (settings) => settings.reportParameters);

export default getReportParameters;
