import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IPlacesResponse } from '@Services/$places-api/types';

import {
  _GET_PLACES_FAILURE,
  _GET_PLACES_REQUEST,
  _GET_PLACES_SUCCESS,
  MOUNTED,
  RESET_SEARCH_PLACE,
  SEARCH_PLACE,
} from './../constants/actions';

export const getPlaces = createAsyncAction(
  _GET_PLACES_REQUEST,
  _GET_PLACES_SUCCESS,
  _GET_PLACES_FAILURE
)<undefined, IPlacesResponse, Error>();

export const mounted = createStandardAction(MOUNTED)();
export const searchPlace = createStandardAction(SEARCH_PLACE)<string>();
export const resetSearch = createStandardAction(RESET_SEARCH_PLACE)();
