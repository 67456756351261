// Private
export const _GET_REQUEST = 'Zagrywki/rundates/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/rundates/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/rundates/_GET_FAILURE';

export const _SINGLE_GET_REQUEST = 'Zagrywki/rundates/_SINGLE_GET_REQUEST';
export const _SINGLE_GET_SUCCESS = 'Zagrywki/rundates/_SINGLE_GET_SUCCESS';
export const _SINGLE_GET_FAILURE = 'Zagrywki/rundates/_SINGLE_GET_FAILURE';

export const _GET_POOLS_REQUEST = 'Zagrywki/rundates/_GET_POOLS_REQUEST';
export const _GET_POOLS_SUCCESS = 'Zagrywki/rundates/_GET_POOLS_SUCCESS';
export const _GET_POOLS_FAILURE = 'Zagrywki/rundates/_GET_POOLS_FAILURE';

export const _GET_RUNDATE_STATS_REQUEST =
  'Zagrywki/rundates/_GET_RUNDATE_STATS_REQUEST';
export const _GET_RUNDATE_STATS_SUCCESS =
  'Zagrywki/rundates/_GET_RUNDATE_STATS_SUCCESS';
export const _GET_RUNDATE_STATS_FAILURE =
  'Zagrywki/rundates/_GET_RUNDATE_STATS_FAILURE';

export const _GET_SINGLE_POOL_REQUEST =
  'Zagrywki/rundates/_GET_SINGLE_POOL_REQUEST';
export const _GET_SINGLE_POOL_SUCCESS =
  'Zagrywki/rundates/_GET_SINGLE_POOL_SUCCESS';
export const _GET_SINGLE_POOL_FAILURE =
  'Zagrywki/rundates/_GET_SINGLE_POOL_FAILURE';

export const _GET_SINGLE_EVENT_REQUEST =
  'Zagrywki/rundates/_GET_SINGLE_EVENT_REQUEST';
export const _GET_SINGLE_EVENT_SUCCESS =
  'Zagrywki/rundates/_GET_SINGLE_EVENT_SUCCESS';
export const _GET_SINGLE_EVENT_FAILURE =
  'Zagrywki/rundates/_GET_SINGLE_EVENT_FAILURE';

export const _GET_POOL_DESCRIPTION_REQUEST =
  'Zagrywki/rundates/_GET_POOL_DESCRIPTION_REQUEST';
export const _GET_POOL_DESCRIPTION_SUCCESS =
  'Zagrywki/rundates/_GET_POOL_DESCRIPTION_SUCCESS';
export const _GET_POOL_DESCRIPTION_FAILURE =
  'Zagrywki/rundates/_GET_POOL_DESCRIPTION_FAILURE';

export const _GET_EXAMPLE_TICKET_REQUEST =
  'Zagrywki/rundates/_GET_EXAMPLE_TICKET_REQUEST';
export const _GET_EXAMPLE_TICKET_SUCCESS =
  'Zagrywki/rundates/_GET_EXAMPLE_TICKET_SUCCESS';
export const _GET_EXAMPLE_TICKET_FAILURE =
  'Zagrywki/rundates/_GET_EXAMPLE_TICKET_FAILURE';

export const _DELETE_RUNDATE_POOL_REQUEST =
  'Zagrywki/rundates/_DELETE_RUNDATE_POOL_REQUEST';
export const _DELETE_RUNDATE_POOL_SUCCESS =
  'Zagrywki/rundates/_DELETE_RUNDATE_POOL_SUCCESS';
export const _DELETE_RUNDATE_POOL_FAILURE =
  'Zagrywki/rundates/_DELETE_RUNDATE_POOL_FAILURE';

export const _SAVE_RUNDATE_POOL_REQUEST =
  'Zagrywki/rundates/_SAVE_RUNDATE_POOL_REQUEST';
export const _SAVE_RUNDATE_POOL_SUCCESS =
  'Zagrywki/rundates/_SAVE_RUNDATE_POOL_SUCCESS';
export const _SAVE_RUNDATE_POOL_FAILURE =
  'Zagrywki/rundates/_SAVE_RUNDATE_POOL_FAILURE';

export const _SAVE_RUNDATE_TICKET_LIST_REQUEST =
  'Zagrywki/rundates/_SAVE_RUNDATE_TICKET_LIST_REQUEST';
export const _SAVE_RUNDATE_TICKET_LIST_SUCCESS =
  'Zagrywki/rundates/_SAVE_RUNDATE_TICKET_LIST_SUCCESS';
export const _SAVE_RUNDATE_TICKET_LIST_FAILURE =
  'Zagrywki/rundates/_SAVE_RUNDATE_TICKET_LIST_FAILURE';

export const _GET_TICKETS_LIST_REQUEST =
  'Zagrywki/rundates/_GET_TICKETS_LIST_REQUEST';
export const _GET_TICKETS_LIST_SUCCESS =
  'Zagrywki/rundates/_GET_TICKETS_LIST_SUCCESS';
export const _GET_TICKETS_LIST_FAILURE =
  'Zagrywki/rundates/_GET_TICKETS_LIST_FAILURE';

export const _GET_SINGLE_TICKET_REQUEST =
  'Zagrywki/rundates/_GET_SINGLE_TICKET_REQUEST';
export const _GET_SINGLE_TICKET_SUCCESS =
  'Zagrywki/rundates/_GET_SINGLE_TICKET_SUCCESS';
export const _GET_SINGLE_TICKET_FAILURE =
  'Zagrywki/rundates/_GET_SINGLE_TICKET_FAILURE';

export const _SAVE_RUNDATE_TICKET_REQUEST =
  'Zagrywki/rundates/_SAVE_RUNDATE_TICKET_REQUEST';
export const _SAVE_RUNDATE_TICKET_SUCCESS =
  'Zagrywki/rundates/_SAVE_RUNDATE_TICKET_SUCCESS';
export const _SAVE_RUNDATE_TICKET_FAILURE =
  'Zagrywki/rundates/_SAVE_RUNDATE_TICKET_FAILURE';

export const _GET_TICKETS_REQUEST = 'Zagrywki/rundates/_GET_TICKETS_REQUEST';
export const _GET_TICKETS_SUCCESS = 'Zagrywki/rundates/_GET_TICKETS_SUCCESS';
export const _GET_TICKETS_FAILURE = 'Zagrywki/rundates/_GET_TICKETS_FAILURE';

export const _GET_TICKET_REQUEST = 'Zagrywki/rundates/_GET_TICKET_REQUEST';
export const _GET_TICKET_SUCCESS = 'Zagrywki/rundates/_GET_TICKET_SUCCESS';
export const _GET_TICKET_FAILURE = 'Zagrywki/rundates/_GET_TICKET_FAILURE';

export const _UPDATE_TICKET_REQUEST =
  'Zagrywki/rundates/_UPDATE_TICKET_REQUEST';
export const _UPDATE_TICKET_SUCCESS =
  'Zagrywki/rundates/_UPDATE_TICKET_SUCCESS';
export const _UPDATE_TICKET_FAILURE =
  'Zagrywki/rundates/_UPDATE_TICKET_FAILURE';

export const _DELETE_TICKET_REQUEST =
  'Zagrywki/rundates/_DELETE_TICKET_REQUEST';
export const _DELETE_TICKET_SUCCESS =
  'Zagrywki/rundates/_DELETE_TICKET_SUCCESS';
export const _DELETE_TICKET_FAILURE =
  'Zagrywki/rundates/_DELETE_TICKET_FAILURE';

export const _DELETE_MULTIPLE_TICKETS_REQUEST =
  'Zagrywki/rundates/_DELETE_MULTIPLE_TICKETS_REQUEST';
export const _DELETE_MULTIPLE_TICKETS_SUCCESS =
  'Zagrywki/rundates/_DELETE_MULTIPLE_TICKETS_SUCCESS';
export const _DELETE_MULTIPLE_TICKETS_FAILURE =
  'Zagrywki/rundates/_DELETE_MULTIPLE_TICKETS_FAILURE';

export const _REVERT_TICKET_REQUEST =
  'Zagrywki/rundates/_REVERT_TICKET_REQUEST';
export const _REVERT_TICKET_SUCCESS =
  'Zagrywki/rundates/_REVERT_TICKET_SUCCESS';
export const _REVERT_TICKET_FAILURE =
  'Zagrywki/rundates/_REVERT_TICKET_FAILURE';

export const _REORDER_POOLS_REQUEST =
  'Zagrywki/rundates/_REORDER_POOLS_REQUEST';
export const _REORDER_POOLS_SUCCESS =
  'Zagrywki/rundates/_REORDER_POOLS_SUCCESS';
export const _REORDER_POOLS_FAILURE =
  'Zagrywki/rundates/_REORDER_POOLS_FAILURE';

export const _CHANGE_RUNDATE_SALE_STATUS_REQUEST =
  'Zagrywki/rundates/_CHANGE_RUNDATE_SALE_STATUS_REQUEST';
export const _CHANGE_RUNDATE_SALE_STATUS_SUCCESS =
  'Zagrywki/rundates/_CHANGE_RUNDATE_SALE_STATUS_SUCCESS';
export const _CHANGE_RUNDATE_SALE_STATUS_FAILURE =
  'Zagrywki/rundates/_CHANGE_RUNDATE_SALE_STATUS_FAILURE';

export const _CHANGE_RUNDATE_VISIBILITY_STATUS_REQUEST =
  'Zagrywki/rundates/_CHANGE_RUNDATE_VISIBILITY_STATUS_REQUEST';
export const _CHANGE_RUNDATE_VISIBILITY_STATUS_SUCCESS =
  'Zagrywki/rundates/_CHANGE_RUNDATE_VISIBILITY_STATUS_SUCCESS';
export const _CHANGE_RUNDATE_VISIBILITY_STATUS_FAILURE =
  'Zagrywki/rundates/_CHANGE_RUNDATE_VISIBILITY_STATUS_FAILURE';

export const _SAVE_CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE_REQUEST =
  'Zagrywki/rundates/_SAVE_CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE_REQUEST';
export const _SAVE_CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE_SUCCESS =
  'Zagrywki/rundates/_SAVE_CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE_SUCCESS';
export const _SAVE_CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE_FAILURE =
  'Zagrywki/rundates/_SAVE_CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE_FAILURE';

export const _GET_RUNDATE_REPORT_REQUEST =
  'Zagrywki/rundates/_GET_RUNDATE_REPORT_REQUEST';
export const _GET_RUNDATE_REPORT_SUCCESS =
  'Zagrywki/rundates/_GET_RUNDATE_REPORT_SUCCESS';
export const _GET_RUNDATE_REPORT_FAILURE =
  'Zagrywki/rundates/_GET_RUNDATE_REPORT_FAILURE';

export const _CHANGE_MONITOR_TYPE_REQUEST =
  'Zagrywki/rundates/_CHANGE_MONITOR_TYPE_REQUEST';
export const _CHANGE_MONITOR_TYPE_SUCCESS =
  'Zagrywki/rundates/_CHANGE_MONITOR_TYPE_SUCCESS';
export const _CHANGE_MONITOR_TYPE_FAILURE =
  'Zagrywki/rundates/_CHANGE_MONITOR_TYPE_FAILURE';

export const _GET_MONITOR_TYPES_REQUEST =
  'Zagrywki/rundates/_GET_MONITOR_TYPES_REQUEST';
export const _GET_MONITOR_TYPES_SUCCESS =
  'Zagrywki/rundates/_GET_MONITOR_TYPES_SUCCESS';
export const _GET_MONITOR_TYPES_FAILURE =
  'Zagrywki/rundates/_GET_MONITOR_TYPES_FAILURE';

export const _CHECK_EXTERNAL_TICKETS_LIST_REQUEST =
  'Zagrywki/rundates/_CHECK_EXTERNAL_TICKETS_LIST_REQUEST';
export const _CHECK_EXTERNAL_TICKETS_LIST_SUCCESS =
  'Zagrywki/rundates/_CHECK_EXTERNAL_TICKETS_LIST_SUCCESS';
export const _CHECK_EXTERNAL_TICKETS_LIST_FAILURE =
  'Zagrywki/rundates/_CHECK_EXTERNAL_TICKETS_LIST_FAILURE';

export const _SAVE_EXTERNAL_TICKETS_LIST_REQUEST =
  'Zagrywki/rundates/_SAVE_EXTERNAL_TICKETS_LIST_REQUEST';
export const _SAVE_EXTERNAL_TICKETS_LIST_SUCCESS =
  'Zagrywki/rundates/_SAVE_EXTERNAL_TICKETS_LIST_SUCCESS';
export const _SAVE_EXTERNAL_TICKETS_LIST_FAILURE =
  'Zagrywki/rundates/_SAVE_EXTERNAL_TICKETS_LIST_FAILURE';

// Public
export const MOUNTED = 'Zagrywki/rundates/MOUNTED';
export const EDIT = 'Zagrywki/rundates/EDIT';
export const SAVE = 'Zagrywki/rundates/SAVE';
export const CATCH_WHEN_PAGE_CHANGED =
  'Zagrywki/rundates/CATCH_WHEN_PAGE_CHANGED';
export const SEARCH = 'Zagrywki/rundates/SEARCH';
export const CHANGE_FILTER = 'Zagrywki/rundates/CHANGE_FILTER';
export const CLEAR_FILTER = 'Zagrywki/rundates/CLEAR_FILTER';
export const DELETE_POOL = 'Zagrywki/rundates/DELETE_POOL';
export const SELECT_POOL = 'Zagrywki/rundates/SELECT_POOL';
export const FETCH_SINGLE_POOL = 'Zagrywki/rundates/FETCH_SINGLE_POOL';
export const SAVE_POOL = 'Zagrywki/rundates/SAVE_POOL';
export const FETCH_POOL_DESCRIPTION =
  'Zagrywki/rundates/FETCH_POOL_DESCRIPTION';
export const GENERATE_EXAMPLE_TICKET =
  'Zagrywki/rundates/GENERATE_EXAMPLE_TICKET';
export const SELECT_TICKET_LIST = 'Zagrywki/rundates/SELECT_TICKET_LIST';
export const EDIT_TICKET_LIST = 'Zagrywki/rundates/EDIT_TICKET_LIST';
export const SAVE_TICKET_LIST = 'Zagrywki/rundates/SAVE_TICKET_LIST';
export const SELECT_TICKET = 'Zagrywki/rundates/SELECT_TICKET';
export const SAVE_TICKET = 'Zagrywki/rundates/SAVE_TICKET';
export const FETCH_TICKETS = 'Zagrywki/rundates/FETCH_TICKETS';
export const FETCH_SINGLE_TICKET = 'Zagrywki/rundates/FETCH_SINGLE_TICKET';
export const SELECT_SINGLE_TICKET = 'Zagrywki/rundates/SELECT_SINGLE_TICKET';
export const DELETE_SINGLE_TICKET = 'Zagrywki/rundates/DELETE_SINGLE_TICKET';
export const REVERT_SINGLE_TICKET = 'Zagrywki/rundates/REVERT_SINGLE_TICKET';
export const SEARCH_TICKETS = 'Zagrywki/rundates/SEARCH_TICKETS';
export const FETCH_REORDER_POOLS = 'Zagrywki/rundates/FETCH_REORDER_POOLS';
export const UPDATE_TICKET = 'Zagrywki/rundates/UPDATE_TICKET';
export const CHANGE_RUNDATE_SALE = 'Zagrywki/rundates/CHANGE_RUNDATE_SALE';
export const CHANGE_RUNDATE_VISIBILITY =
  'Zagrywki/rundates/CHANGE_RUNDATE_VISIBILITY';
export const CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE =
  'Zagrywki/rundates/CHANGE_RUNDATE_HAS_EXTERNAL_IMAGE';
export const FETCH_RUNDATE_REPORT = 'Zagrywki/rundates/FETCH_RUNDATE_REPORT';
export const DELETE_MULTIPLE_TICKETS =
  'Zagrywki/rundates/DELETE_MULTIPLE_TICKETS';
export const RESET_ACTIVE_RUNDATE = 'Zagrywki/rundates/RESET_ACTIVE_RUNDATE';
export const CHANGE_MONITOR_TYPE = 'Zagrywki/rundates/CHANGE_MONITOR_TYPE';
export const CHECK_EXTERNAL_TICKETS =
  'Zagrywki/rundates/CHECK_EXTERNAL_TICKETS';
export const SAVE_EXTERNAL_TICKETS = 'Zagrywki/rundates/SAVE_EXTERNAL_TICKETS';
export const RESET_EXTERNAL_TICKETS =
  'Zagrywki/rundates/RESET_EXTERNAL_TICKETS';
