import _Store from '@Store';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { get as getConfigurations } from '@Model/configurations/selectors';
import { setFormRulesData } from '@Model/formRules/actions';
import { getFormRules } from '@Model/formRules/selectors';
import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { catchGetRuleType } from '@Model/priceTypes/actions';
import { getPriceTypes } from '@Model/priceTypes/selectors';
import PriceRule from './PriceRule.component';
import { prepareInitialValues } from './PriceRule.helpers';
import { IPriceRuleFromDispatch, IPriceRuleFromState } from './PriceRule.types';

const mapStateToProps = (state: _Store.IState): IPriceRuleFromState => {
  const { activeConfiguration } = getConfigurations(state);
  const formValues = getFormRules(state);

  return {
    initialValues: prepareInitialValues(activeConfiguration || formValues),
    partnerId: getHappeningPartnerId(state),
    priceTypes: getPriceTypes(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IPriceRuleFromDispatch => ({
  mounted: () => {
    dispatch(catchGetRuleType());
  },
  setFormRulesData: (values) => {
    dispatch(setFormRulesData(values));
  },
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(PriceRule);
