import { getType } from 'typesafe-actions';

import { getToken } from '../actions';
import { IAction } from '../types';

const initialState: string = '';

const usersReducer = (
  state: string = initialState,
  action: IAction,
): string => {
  switch (action.type) {
    case getType(getToken.success):
      return action.payload;
    default:
      return state;
  }
};

export default usersReducer;
