import React from 'react';

import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';

import styles from './PrinterLoader.module.scss';
import { IPrinterLoaderProps } from './PrinterLoader.types';

const PrinterLoader = ({ isLoading }: IPrinterLoaderProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reusable.PrinterLoader',
  });

  if (!isLoading) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      <Loader />
      <div className={styles.title}>{t('communicationInProgress')}</div>
    </div>
  );
};

export default PrinterLoader;
