import { LOCATION_CHANGE } from 'connected-react-router';
import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import mapInitialValues from '@Compo/PoolDescriptions/components/AddPoolDescriptionModal/AddPoolDescriptionModal.helpers';
import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { showSuccessModal } from '@Model/modal/actions';
import { IPoolDescriptionBody } from '@Services/$pool-descriptions-api/types';

import {
  catchGetSinglePoolDescription,
  catchSavePoolDescription,
  getPoolDescriptions,
  getSinglePoolDescription,
  handleActivePage,
  handleChangeRowsPerPage,
  handleSearch,
  mounted,
  savePoolDescription,
  setPoolDescription,
} from './../actions';
import { getMeta, getSearchText } from './../selectors';

export const catchGetPoolDescriptionsWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        mounted,
        handleActivePage,
        handleChangeRowsPerPage,
        handleSearch,
      ])
    ),
    mergeMap$(() => {
      return of$(getPoolDescriptions.request());
    })
  );
};

export const catchGetSinglePoolDescriptionWhenAction: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(catchGetSinglePoolDescription)),
    mergeMap$((action) => {
      return of$(getSinglePoolDescription.request(action.payload));
    })
  );
};

export const getSinglePoolDescriptionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { poolDescriptionsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSinglePoolDescription.request)),
    mergeMap$((action) => {
      return from$(
        poolDescriptionsApi.getSinglePoolDescription(action.payload)
      ).pipe(
        mergeMap$((data) => {
          return of$(
            getSinglePoolDescription.success(data),
            setPoolDescription(mapInitialValues(data))
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => poolDescriptionsApi.cancelPoolDescriptions())
          )
        ),
        catchError$((error: Error) =>
          of$(getSinglePoolDescription.failure(error))
        )
      );
    })
  );
};

export const getPoolDescriptionsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { poolDescriptionsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPoolDescriptions.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const meta = getMeta(state);
      const searchText = getSearchText(state);
      const partnerId = getHappeningPartnerId(state);

      return from$(
        poolDescriptionsApi.getPoolDescriptions(
          meta.page,
          meta.size,
          Number(partnerId),
          searchText
        )
      ).pipe(
        map$((data) => {
          return getPoolDescriptions.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => poolDescriptionsApi.cancelPoolDescriptions())
          )
        ),
        catchError$((error: Error) => of$(getPoolDescriptions.failure(error)))
      );
    })
  );
};

export const catchSavePoolDescriptionsWhenRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(catchSavePoolDescription)),
    mergeMap$((action) => {
      return of$(savePoolDescription.request(action.payload));
    })
  );
};

export const savePoolDescriptionsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { poolDescriptionsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(savePoolDescription.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      const { id, descriptionPL, titlePL, ticketInfo } = action.payload;
      const body: IPoolDescriptionBody = {
        descriptionPL,
        id,
        partnerId: Number(partnerId),
        ticketInfo,
        titlePL,
      };

      return from$(poolDescriptionsApi.savePoolDescriptions(body)).pipe(
        mergeMap$((data) => {
          return of$(
            savePoolDescription.success(data),
            setPoolDescription(null),
            getPoolDescriptions.request(),
            showSuccessModal()
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => poolDescriptionsApi.cancelPoolDescriptions())
          )
        ),
        catchError$((error: Error) => of$(savePoolDescription.failure(error)))
      );
    })
  );
};
