import React, { FC, useEffect } from 'react';

import Check from '@mui/icons-material/Check';
import { Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { IStep } from '../FormLayout';
import styles from './Stepper.module.scss';

const useQontoStepIconStyles = makeStyles({
  active: {
    color: '#784af4',
  },
  circle: {
    backgroundColor: 'currentColor',
    borderRadius: '50%',
    height: 8,
    width: 8,
  },
  completed: {
    color: '#784af4',
    fontSize: 18,
    zIndex: 1,
  },
  root: {
    alignItems: 'flex-start',
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
  },
});

function QontoStepIcon(props: any) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  active: {
    alignItems: 'center',
    backgroundColor: '#FF6641',
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    height: 20,
    justifyContent: 'center',
    width: 20,
    zIndex: 1,
  },
  circle: {
    backgroundColor: '#474250',
    borderRadius: '50%',
    height: 8,
    width: 8,
  },
  completed: {
    backgroundColor: '#FF6641',
    borderRadius: '50%',
    height: 20,
    width: 20,
  },
  customMessage: {
    background: 'transparent',
    border: '2px solid #FF6641',
    borderRadius: '3px',
    bottom: '100%',
    display: 'block',
    fontSize: '12px',
    height: '33px',
    left: '50%',
    padding: '5px 10px',
    position: 'absolute',
    textAlign: 'center',
    top: '-53px',
    transform: 'translate(-50%, 0)',
    width: 'max-content',

    '&::after': {
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent ',
      borderTop: 'solid #262A35 6px',
      bottom: '-6px',
      content: '""',
      height: 0,
      left: 'calc(50% + 7px)',
      marginLeft: '-13px',
      position: 'absolute',
      width: 0,
    },

    '&::before': {
      borderColor: '#FF6641 transparent transparent transparent',
      borderStyle: 'solid',
      borderWidth: '9px',
      bottom: '-20px',
      content: '""',
      display: 'block',
      height: '20px',
      left: 'calc(50% + 7px)',
      marginLeft: '-16px',
      position: 'absolute',
    },
  },
  root: {
    alignItems: 'center',
    backgroundColor: '#FF6641',
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    height: 20,
    justifyContent: 'center',
    width: 20,
    zIndex: 1,
  },

  pointer: {
    background: 'ivory',
    borderRadius: '0 0 12px 0',
    height: '40px',
    left: 'calc(0.5vw - 50px)',
    margin: '0 auto',
    marginTop: '-22px',
    position: 'relative',
    transform: 'rotate(45deg)',
    width: '40px',
  },
  reletive: {
    position: 'relative',
  },
});

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    marginTop: theme.spacing(2),
  },
  instructions: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  nextButton: {
    borderRadius: '20px',
    marginRight: theme.spacing(1.25),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    textTransform: 'uppercase',

    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  root: {
    backgroundColor: 'transparent',
    color: 'white',
    width: '100%',
  },

  prevButton: {
    borderRadius: '20px',
    marginRight: theme.spacing(1.25),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(1),
    textTransform: 'uppercase',

    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2),
      width: '100%',
    },
  },
  stepper: {
    backgroundColor: 'transparent',
    paddingBottom: '10px',
    paddingLeft: 0,
    paddingTop: '40px',
  },

  typography: {
    marginBottom: theme.spacing(2),
  },
}));

export const CustomizedSteppers: FC<{
  activeStep: number;
  setActiveStep: (page: number) => void;
  steps: IStep[];
  hideStepper?: boolean;
  onDone?: () => void;
  canBeDonne?: boolean;
  showButtons?: boolean;
  buttonText?: string;
  onBack?: () => void;
  nextRef?: React.MutableRefObject<HTMLDivElement | null>;
}> = ({
  setActiveStep,
  activeStep,
  steps,
  children,
  hideStepper,
  onBack,
  onDone,
  canBeDonne,
  showButtons,
  buttonText,
  nextRef,
}) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Add.components.FormLayout.Stepper',
  });

  const classes = useStyles();

  const currentStep = steps[activeStep];

  const handleNext = () => {
    if (activeStep + 1 < steps.length) {
      setActiveStep(activeStep + 1);
      window.scrollTo(0, 0);
    }
  };

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else if (activeStep + 1 > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const isTheLastStep = activeStep + 1 === steps.length;

  const handleContinue = (event: React.FormEvent<HTMLButtonElement>) => {
    if (isTheLastStep && onDone && canBeDonne) {
      onDone();
    } else {
      event.preventDefault();
      if (currentStep.onNext) {
        currentStep.onNext();
      }
      handleNext();
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <div className={styles.header}>
        <Typography variant="h5" className={classes.typography}>
          {currentStep.title}
        </Typography>
        <Typography variant="body1" className={classes.typography}>
          {currentStep.description}
        </Typography>
        <Divider />
      </div>
      <div className={styles.childrenWrapper}>{children}</div>
      <Divider />
      <div ref={nextRef}>
        <Grid
          container={true}
          className={cn(
            hideStepper && !showButtons && styles.hide,
            classes.buttonWrapper
          )}
          justifyContent="flex-end"
        >
          {(showButtons || !hideStepper) && (
            <>
              {(steps.length > 1 || onBack) && (
                <Button
                  variant="outlined"
                  size="large"
                  color="secondary"
                  onClick={handleBack}
                  className={classes.prevButton}
                >
                  {t('prev')}
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                data-testid="onStepperNext"
                onClick={handleContinue}
                className={cn(
                  classes.nextButton,
                  isTheLastStep && !canBeDonne && styles.disabled
                )}
              >
                {isTheLastStep
                  ? buttonText
                    ? buttonText
                    : t('addToBasket')
                  : t('next')}
              </Button>
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default CustomizedSteppers;
