import { createSelector } from 'reselect';

import _Store from '@Store';

import { ITicketValues } from '@Compo/Rundates/TicketsList/components/FormLayout/components/TicketForm/TicketForm.types';

import { ITicketsReducer } from '../types';
import getTickets from './getTickets';

const getSelectedTicket = createSelector<
  _Store.IState,
  ITicketsReducer,
  ITicketValues | null
>([getTickets], (tickets) => tickets.selectedTicket);

export default getSelectedTicket;
