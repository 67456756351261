import React, { ChangeEvent, useEffect, useState } from 'react';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import ConfirmModal from '@Compo/reusable/ConfirmModal';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import { TypographyVariant } from '@Constants/Variants/Typography';

import MailModal from './components/MailModal';
import { getColorFromStatus } from './Mail.helpers';
import useStyles from './Mail.styles';
import { IMailProps } from './Mail.types';

const Mail = ({
  mounted,
  mails,
  meta,
  edit,
  deleteMail,
  sendMail,
  handleActivePage,
  handleSearchText,
  searchText,
  isLoading,
  permissionEventSend,
  addMailing,
  permissionEventWrite,
}: IMailProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Mail',
  });

  const [timeOut, setTimeOut] = useState<NodeJS.Timeout | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [mailId, setMailId] = useState(0);
  const [showSendModal, setShowSendModal] = useState(false);

  const classes = useStyles();

  const catchHandleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.currentTarget;
    handleSearchText(value);

    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(() => {
        mounted();
      }, 1000)
    );
  };

  const catchEdit = (id: string) => {
    edit(Number(id));
  };

  const catchDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => {
    event.stopPropagation();

    setMailId(id);
    setShowDeleteModal(true);
  };

  const catchSend = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => {
    event.stopPropagation();

    setMailId(id);
    setShowSendModal(true);
  };

  const columns = [
    { id: 'id', label: t('idMail') },
    { id: 'title', label: t('title') },
    { id: 'status', label: t('status') },
    { id: 'createdAt', label: t('createdAt') },
    { id: 'sentAt', label: t('sentAt') },
    { id: 'actions', label: t('action') },
  ];

  const normalizedMails = mails.map((mail) => ({
    ...mail,
    actions: (
      <Grid container={true} spacing={2}>
        <Grid item={true}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            disabled={
              mail.status !== 'roboczy' ||
              !mail.dependency.length ||
              !permissionEventSend
            }
            onClick={(e) => {
              catchSend(e, mail.id);
            }}
          >
            {t('sendAction')}
          </Button>
        </Grid>
        <Grid item={true}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={(e) => catchDelete(e, mail.id)}
          >
            {t('deleteAction')}
          </Button>
        </Grid>
      </Grid>
    ),
    createdAt: moment(mail.createdAt).format('DD.MM.YYYY'),
    isDisabled: mail.status === 'roboczy',
    sentAt: moment(mail.sentAt).format('DD.MM.YYYY'),
    status: (
      <Grid container={true} spacing={1} alignItems="center">
        <Grid item={true} className={classes.iconContainer}>
          <FiberManualRecordIcon
            fontSize="inherit"
            htmlColor={getColorFromStatus(mail.status)}
          />
        </Grid>
        <Grid item={true}>
          <Typography variant={TypographyVariant.body2}>
            {mail.status}
          </Typography>
        </Grid>
      </Grid>
    ),
  }));

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setMailId(0);
  };

  const handleConfirmSendModal = () => {
    handleCloseSendModal();
    sendMail(mailId);
  };

  const handleCloseSendModal = () => {
    setShowSendModal(false);
    setMailId(0);
  };

  const handleConfirmDeleteModal = () => {
    handleCloseDeleteModal();
    deleteMail(mailId);
  };

  const changePage = (event: unknown, page: number) => {
    handleActivePage(page + 1);
  };

  useEffect(() => {
    mounted();
  }, []);

  return (
    <GoingPaper>
      <ConfirmModal
        open={showDeleteModal}
        title={t('deleteModalTitle')}
        question={t('deleteModalDescription')}
        handleClose={handleCloseDeleteModal}
        onCancel={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteModal}
      />

      <ConfirmModal
        open={showSendModal}
        title={t('sendModalTitle')}
        question={t('sendModalDescription')}
        handleClose={handleCloseSendModal}
        onCancel={handleCloseSendModal}
        onConfirm={handleConfirmSendModal}
      />

      <MailModal />
      <GenericTable>
        <TableActions>
          <TextField
            variant="standard"
            name="search"
            helperText={t('search')}
            value={searchText}
            onChange={catchHandleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.buttonsContainer}>
            <Grid container={true} spacing={2} alignItems="center">
              {permissionEventWrite && (
                <Grid item={true}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    className={cn(classes.button, classes.buttonFirst)}
                    onClick={addMailing}
                  >
                    {t('add')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </TableActions>

        <TableUi
          rows={normalizedMails}
          columns={columns}
          total={meta.total}
          activePage={meta.page - 1}
          rowsPerPage={meta.size}
          handleChangePage={changePage}
          disableCheckBox={true}
          onRowClick={catchEdit}
          isLoading={isLoading}
        />
      </GenericTable>
    </GoingPaper>
  );
};

export default Mail;
