import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import PrinterForm from '../PrinterForm';
import { IPrintersModalProps } from './PrintersModal.types';

const PrintersModal = ({ closeModal, showModal }: IPrintersModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'PrinterAdmin.components.PrinterModal',
  });

  return (
    <Dialog
      open={showModal}
      maxWidth="md"
      fullWidth={true}
      onClose={() => closeModal()}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <PrinterForm />
    </Dialog>
  );
};
export default PrintersModal;
