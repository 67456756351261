import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
  catchSaveConfiguration,
  handleActiveConfiguration,
} from '@Model/configurations/actions';
import { get as getConfigurations } from '@Model/configurations/selectors';
import { IConfiguration } from '@Model/configurations/types';
import _Store from '@Store';
import ConfigurationModal from './ConfigurationModal.component';
import { prepareInitialValues } from './ConfigurationModal.helpers';
import {
  IConfigurationModalFromDispatch,
  IConfigurationModalFromState,
} from './ConfigurationModal.types';

const mapStateToProps = (
  state: _Store.IState
): IConfigurationModalFromState => {
  const { activeConfiguration } = getConfigurations(state);
  return {
    activeConfiguration,
    initialValues: prepareInitialValues(activeConfiguration),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IConfigurationModalFromDispatch => {
  return {
    catchSaveConfiguration: (configurations: IConfiguration) => {
      dispatch(catchSaveConfiguration(configurations));
    },
    handleActiveConfiguration: (configurations: IConfiguration | null) => {
      dispatch(handleActiveConfiguration(configurations));
    },
  };
};

export default connect<
  IConfigurationModalFromState,
  IConfigurationModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ConfigurationModal);
