const ONE_PERSON_TEXT = 'osoba';
const TWO_TO_FOUR_PEOPLE_TEXT = 'osoby';
const MORE_THAN_FOUR_PEOPLE_TEXT = 'osób';

export const getSpellNumberOfPeople = (count: number): string => {
  if (count === 1) {
    return ONE_PERSON_TEXT;
  } else if (count > 1 && count < 5) {
    return TWO_TO_FOUR_PEOPLE_TEXT;
  } else {
    return MORE_THAN_FOUR_PEOPLE_TEXT;
  }
};
