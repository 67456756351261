import _Store from '@Store';
import { createSelector } from 'reselect';
import { IPrinter, IPrinterReducer } from '../types';
import get from './get';

const getPrinters = createSelector<_Store.IState, IPrinterReducer, IPrinter[]>(
  [get],
  (state) => state.printers,
);

export default getPrinters;
