import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPlace } from '@Services/$places-api/types';

import { IPlacesReducer } from '../types';
import getPlaces from './getPlaces';

const getPlacesData = createSelector<_Store.IState, IPlacesReducer, IPlace[]>(
  [getPlaces],
  (places) => places.data
);

export default getPlacesData;
