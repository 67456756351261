import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import checkIsEndAfterMidnight from '@/misc/helpers/checkIsEndAfterMidnight';
import { Formik } from 'formik';
import moment from 'moment';

import {
  makeName,
  makeRRule,
} from '@Compo/BetterManager/AddingConfiguration/components/ConfigurationModal/Components/FormLayout/FormLayout.helpes';
import * as helpers from '@Compo/BetterManager/NewAddingConfiguration/NewAddingConfiguration.helpers';
import {
  IConfiguration,
  IFormValuesProps,
  IUpsellExtended,
} from '@Model/configurations/types';

import FormLayout from './FormLayout';
import { IPriceSettingsProps, IPriceSettingsRef } from './PriceSettings.types';

const PriceSettings = forwardRef<IPriceSettingsRef, IPriceSettingsProps>(
  (props, ref) => {
    const formRef = useRef<any>();

    const {
      initialValues,
      setFormRulesData,
      partnerId,
      catchSaveConfiguration,
      formValues,
      setStepSlug,
    } = props;

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        if (formRef.current) {
          formRef.current.validateForm().then((res: any) => {
            const fields = Object.keys(res);
            fields.forEach((field) =>
              formRef.current?.setFieldTouched(field, true)
            );

            if (Object.keys(res).length === 0) {
              setFormRulesData({
                ...formRef.current.state.values,
                isPeopleCount: formRef.current.state.values.isPeopleCount,
                isUpsell: formRef.current.state.values.isUpsell,
              });

              formRef.current
                ?.submitForm()
                .then(() => setStepSlug('rule-list'));
            }
          });
        }
      },
    }));

    const catchHandleSubmit = (values: IFormValuesProps) => {
      setFormRulesData({ ...formRef.current.state.values });
      const {
        id,
        price,
        durationToString,
        durationAdvanced,
        duration,
        advanced,
        title,
        isUpsell,
        closed,
        upsellValue,
        isPeopleCount,
        validTo,
        validFrom,
        ruleType,
        capacity,
        prices,
        cyclic,
        until,
        upsellExtended,
      } = formValues;

      const getDuration = () => {
        if (!advanced) {
          return {
            duration: durationNewValue || duration,
          };
        }
        return {
          duration: durationAdvanced,
        };
      };

      const getTitle = (): { title: string } => {
        if (title && title.length > 0) {
          return {
            title,
          };
        }

        return {
          title: makeName(formValues, makeRRule(formValues).toUpperCase()),
        };
      };

      const getPeopleCount = () => {
        const {
          fromNumberOfPeople,
          perPersonValue,
          perPersonType,
          priceGoesUp,
        } = formValues;

        const getValue = () => {
          if (perPersonType === 'value') {
            return {
              percentageValue: null,
              value: Number(perPersonValue),
            };
          } else {
            return {
              percentageValue: Number(perPersonValue),
              value: null,
            };
          }
        };

        if (isPeopleCount) {
          return {
            fromNumberOfPeopleModifier: {
              fromNumberOfPeople: Number(fromNumberOfPeople),
              priceGoesUp: priceGoesUp === 'up',
              ...getValue(),
            },
          };
        }
        return {};
      };

      const durationNewValue = helpers.makeTimeFromDuration(durationToString);

      const getPropsByPriceRule = () => {
        if (ruleType === 'price') {
          const getUpsalle = () => {
            const value = upsellValue || 0;

            if (isUpsell) {
              if (values.upsellType === 0) {
                return {
                  upsell: {
                    percentageValue: null,
                    value,
                  },
                };
              }
              return {
                upsell: {
                  percentageValue: value,
                  value: null,
                },
              };
            }

            return {};
          };

          return {
            prices,
            ...getUpsalle(),
            ...getPeopleCount(),
          };
        }
        return {};
      };

      const getPropsByTimeRule = () => {
        if (ruleType === 'time') {
          return {
            closed,
          };
        }
        return {};
      };

      const getPropsByCapacityRule = () => {
        if (ruleType === 'capacity') {
          return {
            capacity,
          };
        }
        return {};
      };

      const rrule = makeRRule(values);

      const answer = {
        id: id || undefined,
        partnerId:
          partnerId && Number(partnerId) !== -1 && Number(partnerId) !== 0
            ? Number(partnerId)
            : null,
        rrule: makeRRule(formValues).toUpperCase(),
        upsellExtended: upsellExtended.map((upsell: IUpsellExtended) => ({
          ...upsell,
          percentageValue: upsell.percentageValue
            ? Number(upsell.percentageValue)
            : null,
          slotIndex: Number(upsell.slotIndex),
          value: upsell.value ? Number(upsell.value) : null,
        })),
        validFrom,
        validTo:
          checkIsEndAfterMidnight(
            rrule,
            duration,
            validTo,
            initialValues.validTo
          ) && !cyclic
            ? initialValues.until
              ? moment(until).add(1, 'day')
              : moment(validTo).add(1, 'day')
            : validTo,
        ...getPropsByPriceRule(),
        ...getPropsByTimeRule(),
        ...getDuration(),
        ...getPropsByCapacityRule(),
        ...getTitle(),
      };

      if (values.closed) {
        answer.closed = true;
      }

      catchSaveConfiguration({
        configuration: answer as IConfiguration,
        type: 'price',
      });
    };

    return (
      <Formik
        ref={formRef}
        initialValues={initialValues}
        onSubmit={catchHandleSubmit}
        component={FormLayout}
      />
    );
  }
);

export default PriceSettings;
