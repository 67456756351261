import React from 'react';

import AddIcon from '@mui/icons-material/AddCircle';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import { IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';

import { IAddSubtractProps } from './AddSubtract.types';

const useStyles = makeStyles(() => ({
  count: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minWidth: 38,
  },
  withoutSidePadding: {
    padding: 0,
  },
}));

const AddSubtract = ({
  count,
  subtract,
  add,
  unit,
  variant,
  size,
  fontSize,
}: IAddSubtractProps) => {
  const classes = useStyles();
  return (
    <>
      <IconButton
        size={size}
        color="secondary"
        onClick={subtract}
        className={cn(
          variant === 'withoutSidePaddings' && classes.withoutSidePadding
        )}
      >
        <RemoveIcon fontSize={fontSize} />
      </IconButton>
      <Typography
        variant={fontSize === 'large' ? 'body1' : 'body2'}
        className={classes.count}
      >
        {unit ? `${count} ${unit}` : count}
      </Typography>
      <IconButton
        size="small"
        color="secondary"
        onClick={add}
        className={cn(
          variant === 'withoutSidePaddings' && classes.withoutSidePadding
        )}
      >
        <AddIcon fontSize={fontSize} />
      </IconButton>
    </>
  );
};

export default AddSubtract;
