import { combineReducers } from 'redux';

import availablePoolsReducer from './availablePools';
import eventsReducer from './events';
import selectedSeatsReducer from './seats';
import selectedReducer from './selected';

const reducer = combineReducers({
  availablePools: availablePoolsReducer,
  events: eventsReducer,
  selected: selectedReducer,
  selectedSeats: selectedSeatsReducer,
});

export default reducer;
