import React, { useRef, useState } from 'react';

import routes from '@/routes/routes';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  Grow,
  InputLabel,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Card } from 'tabler-react';

import Loader from '@Compo/layout/Loader/Loader';
import DatePicker from '@Compo/reservations/Add/components/DatePicker/';
import BooleanLabel from '@Compo/reusable/BooleanLabel';
import config from '@Config';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import translateDefaultPriceType from '@Misc/helpers/strings/translateDefaultPrice';
import { ITimeSlot } from '@Model/reservation/types';

import FormIo from './components/FormIo';
import { IFormLayoutProps } from './FormLayout';
import styles from './FormLayout.module.scss';
import useStyles from './FromLayout.styles';

const FormLayout = ({
  availabilityMessage,
  availabilities,
  cancel,
  cancelAutoTransaction,
  cancelTransaction,
  checkDate,
  errors,
  handleSubmit,
  handleChange,
  isChecking,
  isDateAvailable,
  isLoading,
  isValidated,
  permissionRefundsWrite,
  permissionReservationWrite,
  priceMessage,
  setFieldValue,
  touched,
  values,
  timeSlots,
  changeReservationDate,
  permissionFormIoAccessListRead,
  resentTicket,
  getTicket,
  handleBlur,
  submitForm,
  attachEntryGroup,
  showEntryGroupInput,
  history,
  reservationDetails,
  empikReservationDetails,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Calendar.components.FormLayout',
  });

  const classes = useStyles();
  const [editDate, setEditDate] = useState(false);
  const [editedEdited, setDateEdited] = useState(false);
  const [open, setOpen] = useState(false);
  const [bindingCode, setBindingCode] = useState('');

  const anchorRef = useRef<HTMLDivElement>(null);

  const start = values.startDate ? moment(values.startDate).toDate() : null;
  const end = values.endDate ? moment(values.endDate) : null;
  const canBeSaved =
    isDateAvailable &&
    Object.entries(errors).filter(([_, value]) => value !== undefined)
      .length === 0;

  const handleDateChange = (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => {
    let { startTime, startDate } = values;

    if (field === 'startDate') {
      startDate = moment(value as Date).format('yyyy-MM-DD');
      value = moment(value as Date).format('yyyy-MM-DD');
    } else if (field === 'startTime') {
      startTime = value;
    }

    const date = new Date(`${startDate}T${startTime}`);

    if (field === 'startDate') {
      changeReservationDate(date);
      setDateEdited(true);
    }

    checkDate(
      startTime &&
        availabilities &&
        availabilities.otherDays &&
        availabilities.otherDays[startTime]
        ? moment(date).add('days', 1).toDate()
        : date
    );
    setFieldValue(field as keyof typeof values, value, shouldValidate);
  };

  const handleClickDateChange = () => {
    setDateEdited(true);
    setEditDate(true);
  };

  const confirmModal = (
    event: React.FormEvent,
    autoCashBack: boolean = false
  ) => {
    event.preventDefault();

    const id = values && values.id ? values.id.toString() : null;

    if (window.confirm(t('areYouSure'))) {
      if (autoCashBack) {
        cancelAutoTransaction(id);
      } else {
        cancelTransaction(id);
      }
    }
  };

  const times = (): ITimeSlot[] => {
    if (values && values.startTime) {
      const hasTimeValue = timeSlots.some(
        (timeSlot) => timeSlot.startTime === values.startTime
      );
      if (hasTimeValue) {
        return timeSlots;
      } else {
        return [{ startTime: values.startTime }, ...timeSlots];
      }
    } else {
      return timeSlots;
    }
  };

  const getStartTime = (): string => {
    if (values && values.startTime) {
      const parts = values.startTime.split(':');
      if (parts.length) {
        if (isNaN(Number(parts[1]))) {
          return `${parts[0]}:00`;
        }
        return `${parts[0]}:${parts[1]}`;
      }
    }

    return '';
  };

  const catchResentTicket = () => {
    if (values.id) {
      resentTicket(values.id.toString());
    }
  };

  const catchGetTicket = () => {
    if (values.uuid) {
      getTicket(values.uuid.toString());
    }
  };

  const printAgain = () => {
    if (reservationDetails && typeof reservationDetails !== 'number') {
      if (reservationDetails.transactionUUID) {
        history.push(
          fillUrlWithValues(
            routes.summarySuccess,
            ':id',
            reservationDetails.transactionUUID
          )
        );
        history.go(0);
      }
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleBindingCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBindingCode(e.target.value);
  };

  const handleEntryGroupSubmit = () => {
    attachEntryGroup(bindingCode);
    setBindingCode('');
  };

  const { paymentMethod, discountCode } = values;
  const showAutoReturnButton = paymentMethod && paymentMethod === 3;
  const isPrintReceiptAvailable =
    empikReservationDetails.user.userEmail === 'sprzedaz@goingapp.pl' &&
    !empikReservationDetails.billId;

  return (
    <>
      <form className={cn(styles.form, classes.form)} onSubmit={handleSubmit}>
        <DialogContent>
          {isLoading && (
            <div className={styles.loaderWrapper}>
              <Loader />
            </div>
          )}
          {!isLoading && (
            <>
              <Grid container={true} className={classes.line}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {t('id')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="body1">{values.id}</Typography>
                </Grid>
              </Grid>

              <Grid container={true} className={classes.line}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {t('happening')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="body1">
                    {values.happeningTitle}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container={true} className={classes.line}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {t('space')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="body1">{values.spaceTitle}</Typography>
                </Grid>
              </Grid>

              <Grid container={true} className={classes.line}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {t('firstName')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="body1">{values.firstName}</Typography>
                </Grid>
              </Grid>

              <Grid container={true} className={classes.line}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {t('lastName')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="body1">{values.lastName}</Typography>
                </Grid>
              </Grid>

              <Grid container={true} className={classes.line}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {t('email')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="body1">{values.email}</Typography>
                </Grid>
              </Grid>

              <Grid container={true} className={classes.line}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {t('phone')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="body1">{values.phone}</Typography>
                </Grid>
              </Grid>

              <Grid container={true} className={classes.line}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {t('numberOfPeople')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="body1">
                    {values.numberOfPeople}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container={true} className={classes.line}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {t('date')}
                  </Typography>
                </Grid>
                {editDate ? (
                  <div className={styles.calendar}>
                    <DatePicker
                      isDateSelectionAvailable={true}
                      setDate={(event) => handleDateChange('startDate', event)}
                      selectedDate={start}
                    />
                    {editedEdited && (
                      <FormControl variant="standard" fullWidth={true}>
                        <InputLabel>{t('time')}</InputLabel>
                        <Select
                          variant="standard"
                          name="startTime"
                          value={values.startTime}
                          onChange={(event) => {
                            handleDateChange('startTime', event.target.value);
                          }}
                          fullWidth={true}
                        >
                          {times().map((timeSlot) =>
                            !timeSlot.isDisabled ? (
                              <MenuItem
                                key={timeSlot.startTime}
                                value={timeSlot.startTime}
                              >
                                {timeSlot.startTime}
                              </MenuItem>
                            ) : null
                          )}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                ) : (
                  <Grid item={true} xs={6}>
                    <Grid container={true}>
                      <Grid item={true} xs={6}>
                        <Typography variant="body1">
                          {moment(start || new Date()).format('DD.MM.YYYY')}{' '}
                          {getStartTime()}
                        </Typography>
                      </Grid>
                      {permissionReservationWrite && (
                        <Grid item={true} xs={6}>
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            onClick={handleClickDateChange}
                          >
                            {t('change')}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {!editedEdited && (
                <Grid container={true} className={classes.line}>
                  <Grid item={true} xs={6}>
                    <Typography variant="body1" color="textSecondary">
                      {t('dateEnd')}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <Typography variant="body1">
                      {moment(end).format('DD.MM.YYYY')} {values.endTime}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Grid container={true} className={classes.line}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {t('price')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="body1">{values.price}</Typography>
                </Grid>
              </Grid>

              <Grid container={true} className={classes.line}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {t('discount')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant="body1">{discountCode || '-'}</Typography>
                </Grid>
              </Grid>

              <Grid container={true} className={classes.line}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {t('validatedTicket')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <BooleanLabel isTrue={!!isValidated} />
                </Grid>
              </Grid>

              <Grid container={true} className={classes.line}>
                <Grid item={true} xs={6}>
                  <Typography variant="body1" color="textSecondary">
                    {t('validatedReceipt')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <BooleanLabel isTrue={!!empikReservationDetails.billId} />
                </Grid>
              </Grid>

              {values.priceType && (
                <Grid container={true} className={classes.line}>
                  <Grid item={true} xs={6}>
                    <Typography variant="body1" color="textSecondary">
                      {t('priceType')}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <Typography variant="body1">
                      {translateDefaultPriceType(values.priceType)}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {values.lockCode && (
                <Grid container={true} className={classes.line}>
                  <Grid item={true} xs={6}>
                    <Typography variant="body1" color="textSecondary">
                      {t('lockCode')}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <Typography variant="body1">{values.lockCode}</Typography>
                  </Grid>
                </Grid>
              )}

              {!!values.transactionProducts.length && (
                <Grid container={true} className={classes.line}>
                  <Grid item={true} xs={6}>
                    <Typography variant="body1" color="textSecondary">
                      {t('products')}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={6}>
                    {values.transactionProducts.map((product) => (
                      <Typography variant="body1" key={product.productName}>
                        {`${product.productName} x ${product.quantity}`}
                      </Typography>
                    ))}
                  </Grid>
                </Grid>
              )}

              <Grid container={true} className={classes.container}>
                <TextField
                  variant="standard"
                  id="description"
                  label={
                    (touched.description && errors.description) ||
                    t('description')
                  }
                  helperText={t('descriptionHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  name="description"
                  error={!!(touched.description && errors.description)}
                  fullWidth={true}
                />
              </Grid>

              {config.cms.showNewEnterList && showEntryGroupInput && (
                <Grid
                  container={true}
                  spacing={1}
                  className={classes.container}
                >
                  <Grid item={true} xs={12}>
                    <TextField
                      variant="standard"
                      id="bindingCode"
                      label={t('binding')}
                      helperText={t('bindingHelper')}
                      onChange={handleBindingCodeChange}
                      value={bindingCode}
                      name="bindingCode"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleEntryGroupSubmit}
                    >
                      {t('connect')}
                    </Button>
                  </Grid>
                </Grid>
              )}

              {permissionFormIoAccessListRead && (
                <Grid container={true} className={classes.container}>
                  <FormIo />
                </Grid>
              )}
            </>
          )}
          {isChecking && (
            <Card className={cn('mb-5', styles.checker)}>
              <Loader />
            </Card>
          )}
          {!isChecking && availabilityMessage.length > 0 && (
            <Card.Alert className="mb-5" color="danger">
              {availabilityMessage}
            </Card.Alert>
          )}
          {!isChecking && priceMessage.length > 0 && (
            <Card.Alert className="mb-5" color="danger">
              {priceMessage}
            </Card.Alert>
          )}
          {editDate && values.linkedTo && !values.linkedTo.master && (
            <Card.Alert className="mb-5" color="danger">
              {t('dependentHappening')}
            </Card.Alert>
          )}
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={cancel}
        >
          {t('cancel')}
        </Button>
        <ButtonGroup
          variant="contained"
          color="primary"
          size="large"
          ref={anchorRef}
        >
          <Button
            type="submit"
            disabled={!canBeSaved || isLoading}
            className={classes.saveButton}
            onClick={submitForm}
          >
            {t('reservationSave')}
          </Button>
          <Button
            color="primary"
            size="small"
            onClick={handleToggle}
            className={classes.saveButton}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          transition={true}
          disablePortal={true}
          anchorEl={anchorRef.current}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {permissionRefundsWrite && (
                      <MenuItem
                        className={classes.menuItem}
                        onClick={confirmModal}
                      >
                        {t('cancelReservation')}
                      </MenuItem>
                    )}
                    <MenuItem
                      className={classes.menuItem}
                      onClick={catchResentTicket}
                    >
                      {t('resentTicket')}
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={catchGetTicket}
                    >
                      {t('getTicket')}
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      disabled={!isPrintReceiptAvailable}
                      onClick={printAgain}
                    >
                      {t('printReceiptAgain')}
                    </MenuItem>
                    {permissionRefundsWrite && showAutoReturnButton && (
                      <MenuItem
                        className={classes.menuItem}
                        onClick={(event: React.FormEvent) =>
                          confirmModal(event, true)
                        }
                      >
                        {t('cancelAutoReservation')}
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </DialogActions>
    </>
  );
};

export default FormLayout;
