import { getType } from 'typesafe-actions';

import { getTickets, search } from '../actions';
import { IAction, ITicketsReducer } from '../types';

const initialState: ITicketsReducer = {
  data: [],
  meta: {
    page: 0,
    size: 0,
    total: 0,
  },
  searchText: '',
};

const ticketsReducer = (
  state: ITicketsReducer = initialState,
  action: IAction
): ITicketsReducer => {
  switch (action.type) {
    case getType(getTickets.success):
      return {
        ...state,
        data: action.payload.data,
        meta: { ...action.payload.meta, page: action.payload.meta.page },
      };

    case getType(search):
      return {
        ...initialState,
        meta: { ...state.meta, page: action.payload.page || 0 },
        searchText: action.payload.search || '',
      };

    default:
      return state;
  }
};

export default ticketsReducer;
