import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { catchChangeUserPassword } from '@Model/users/actions';
import { getActiveUser } from '@Model/users/selectors';

import ChangePasswordModal from './ChangePasswordModal.component';
import {
  IChangePasswordModalFromDispatch,
  IChangePasswordModalFromState,
} from './ChangePasswordModal.types';

const mapStateToProps = (
  state: _Store.IState
): IChangePasswordModalFromState => ({
  activeUser: getActiveUser(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IChangePasswordModalFromDispatch => ({
  submit: (values) => dispatch(catchChangeUserPassword(values)),
});

export default connect<
  IChangePasswordModalFromState,
  IChangePasswordModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordModal);
