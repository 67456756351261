import { createSelector } from 'reselect';

import _Store from '@Store';

import { ITicketsReducer } from '../types';
import getTickets from './getTickets';

const getSearchTicketText = createSelector<
  _Store.IState,
  ITicketsReducer,
  string
>([getTickets], (tickets) => tickets.ticketsSearchText);

export default getSearchTicketText;
