import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import MuiDatePicker from '@Compo/reusable/MuiDatePicker';

import { IFormLayoutProps } from './../../FormLayout.types';
import useStyles from './FormMailShipment.styles';

const FormMailShipment = ({
  values,
  handleChange,
  touched,
  errors,
  setFieldValue,
  handleBlur,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Mail.components.FormLayout.components.FormMailShipment',
  });

  const classes = useStyles();

  return (
    <Grid item={true} xs={12}>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.mailShipment}
                onChange={handleChange}
                name="mailShipment"
                color="primary"
                size="small"
                disabled={values.isSent}
              />
            }
            label={
              <Typography variant="body1" color="textPrimary">
                {t('mailShipment')}
              </Typography>
            }
          />
        </Grid>

        {values.mailShipment && (
          <Grid item={true} xs={12}>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12} md={6}>
                <LabelContentContainer>
                  <MuiDatePicker
                    name="startDate"
                    disableToolbar={true}
                    fullWidth={true}
                    variant="standard"
                    format="DD.MM.yyyy"
                    label={
                      (touched.startDate && errors.startDate) || t('startDate')
                    }
                    error={!!(touched.startDate && errors.startDate)}
                    helperText={t('startDateHelper')}
                    value={values.startDate}
                    onChange={(date) => setFieldValue('startDate', date)}
                    onBlur={handleBlur}
                  />
                </LabelContentContainer>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <LabelContentContainer>
                  <TextField
                    variant="standard"
                    type="time"
                    id="startTime"
                    label={
                      (touched.startTime && errors.startTime) || t('startTime')
                    }
                    helperText={t('startTimeHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.startTime}
                    name="startTime"
                    error={!!(touched.startTime && errors.startTime)}
                    fullWidth={true}
                    InputProps={{
                      className: cn(classes.defaultTimeInput, classes.time),
                    }}
                  />
                </LabelContentContainer>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FormMailShipment;
