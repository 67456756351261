import React from 'react';

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import screamingTriangle from '@Misc/images/screaming_triangle.svg';

import GoingPaper from '../layout/Paper/GoingPaper';
import Version from './components/Version';
import useStyles from './Login.styles';
import { ILoginProps, ILoginValues } from './Login.types';
import { LoginSchema } from './Login.validation';

const Login = ({ signIn }: ILoginProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Login',
  });

  const classes = useStyles();
  const catchHandleSubmit = ({
    email,
    password,
    rememberMe,
    oldLoginMethod,
  }: ILoginValues) => {
    signIn({
      email,
      oldLoginMethod,
      password,
      rememberMe,
    });
  };

  const initialValues: ILoginValues = {
    email: '',
    oldLoginMethod: false,
    password: '',
    rememberMe: true,
  };

  return (
    <Grid container={true} justifyContent="center">
      <Version />
      <Grid item={true} className={classes.container}>
        <Typography className={classes.title} variant="h4" color="textPrimary">
          {t('title')}
        </Typography>
        <GoingPaper transparent={true}>
          <Formik
            initialValues={initialValues}
            onSubmit={catchHandleSubmit}
            validationSchema={LoginSchema}
            enableReinitialize={true}
            render={({
              values: { email, password, rememberMe, oldLoginMethod },
              errors,
              touched,
              handleSubmit,
              handleBlur,
              handleChange,
              setFieldValue,
            }: FormikProps<ILoginValues>) => {
              const handleChangeLoginType = () => {
                setFieldValue('oldLoginMethod', !oldLoginMethod);
              };

              const getLoginLabel = () => {
                if (touched.email && errors.email) {
                  return errors.email;
                }

                return oldLoginMethod ? t('name') : t('email');
              };

              return (
                <form onSubmit={handleSubmit}>
                  <Paper className={classes.paper}>
                    <TextField
                      id="email"
                      label={getLoginLabel()}
                      helperText={
                        oldLoginMethod ? t('nameHelper') : t('emailHelper')
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={email}
                      name="email"
                      error={!!(touched.email && errors.email)}
                      fullWidth={true}
                      variant="outlined"
                      className={classes.input}
                    />
                    <TextField
                      id="password"
                      label={
                        (touched.password && errors.password) || t('password')
                      }
                      helperText={t('passwordHelper')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={password}
                      name="password"
                      error={!!(touched.password && errors.password)}
                      fullWidth={true}
                      type="password"
                      variant="outlined"
                      className={classes.input}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rememberMe}
                          onChange={handleChange}
                          name="rememberMe"
                          color="primary"
                          size="small"
                        />
                      }
                      label={
                        <Typography variant="body1">
                          {t('rememberMe')}
                        </Typography>
                      }
                      className={classes.input}
                    />

                    <Grid container={true} spacing={1}>
                      <Grid item={true} xs={12}>
                        <Button
                          type="submit"
                          size="large"
                          variant="contained"
                          color="primary"
                          fullWidth={true}
                        >
                          {t('login')}
                        </Button>
                      </Grid>

                      <Grid item={true} xs={12}>
                        <Button
                          size="medium"
                          variant="text"
                          color="primary"
                          fullWidth={true}
                          onClick={handleChangeLoginType}
                        >
                          {oldLoginMethod ? t('newLogin') : t('oldLogin')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </form>
              );
            }}
          />
        </GoingPaper>
        <img className={classes.screamingTriangle} src={screamingTriangle} />
      </Grid>
    </Grid>
  );
};

export default Login;
