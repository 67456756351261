import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';

import Check from './../Check';
import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = ({
  handleSubmit,
  values,
  handleBlur,
  handleChange,
  touched,
  errors,
  submitForm,
  closeModal,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'Rundates.TicketsList.components.ImportListModal.components.FormLayout',
  });

  const classes = useStyles();
  return (
    <>
      <Check values={values} close={closeModal} />
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <TextField
                  id="name"
                  label={(touched.name && errors.name) || t('name')}
                  helperText={t('nameHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  name="name"
                  error={!!(touched.name && errors.name)}
                  fullWidth={true}
                />
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <TextField
                  id="entryTokens"
                  label={
                    (touched.entryTokens && errors.entryTokens) || t('codeList')
                  }
                  helperText={t('codeListHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.entryTokens}
                  name="entryTokens"
                  error={!!(touched.entryTokens && errors.entryTokens)}
                  fullWidth={true}
                  variant="outlined"
                  multiline={true}
                  minRows={10}
                  maxRows={10}
                />
              </LabelContentContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('next')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
