import React from 'react';

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './Filters.styles';
import { IFiltersProps } from './Filters.types';

export const Filters = ({
  handleSubmit,
  values,
  clearFilter,
  handleChange,
}: IFiltersProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates.components.Filters',
  });

  const classes = useStyles();

  return (
    <Grid container={true} className={classes.filtersContainer}>
      <Grid item={true} xs={12}>
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container={true} spacing={4}>
            <Grid item={true} container={true} spacing={4}>
              <Grid item={true} xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.withCalendarEvents}
                      onChange={handleChange}
                      name="withCalendarEvents"
                      color="primary"
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body1" color="textPrimary">
                      {t('withCalendarEvents')}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.withExpired}
                      onChange={handleChange}
                      name="withExpired"
                      color="primary"
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="body1" color="textPrimary">
                      {t('withExpired')}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            <Grid item={true} container={true} spacing={1}>
              <Grid item={true} xs={12}>
                <Divider />
              </Grid>
              <Grid item={true} xs={12}>
                <Grid container={true} justifyContent="flex-end">
                  <Button
                    onClick={clearFilter}
                    type="button"
                    size="large"
                    variant="text"
                    color="primary"
                  >
                    {t('clear')}
                  </Button>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    {t('submit')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default Filters;
