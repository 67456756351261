import React, { useEffect } from 'react';

import GenericTable from '@Compo/reusable/GenericTable';
import config from '@Config';
import loadDOMScript from '@Misc/helpers/loadDOMScript';

import { ISeatsIoProps } from './SeatsIo.types';

const SEATS_CONTAINER_ID = 'seats-container';

const SeatsIo = ({
  seatsIoPublicKey,
  rundateId,
  onSeatsSelected,
  onSeatsDeselected,
  mode,
  isReport,
  categoryKey,
}: ISeatsIoProps) => {
  let seatingChart: any;

  const objectColor = (object: any, defaultColor: string, extraConfig: any) => {
    if (extraConfig.isReport) {
      if (
        extraConfig.categoryKey &&
        object.category.key !== Number(extraConfig.categoryKey)
      ) {
        return 'white';
      }
      if (object.status === 'booked' || object.status === 'reserved') {
        return 'red';
      }
      if (
        object.category.label === 'unavailable' ||
        object.status === 'invitation'
      ) {
        return '#888';
      }

      return 'green';
    }
    return defaultColor;
  };

  const loadChart = () => {
    seatingChart = new window.seatsio.SeatingChart({
      availableCategories: isReport ? [`${categoryKey}`] : undefined,
      divId: categoryKey
        ? `${SEATS_CONTAINER_ID}-${categoryKey}`
        : SEATS_CONTAINER_ID,
      event: rundateId,
      extraConfig: {
        categoryKey,
        isReport,
      },
      language: 'pl',
      mode,
      objectColor,
      onChartRendered: (chart: any) => {
        if (categoryKey) {
          chart.selectCategories([`${categoryKey}`]);
          chart.zoomToSelectedObjects();
          chart.deselectCategories([`${categoryKey}`]);
        }
      },
      onObjectDeselected: onSeatsDeselected,
      onObjectSelected: onSeatsSelected,
      publicKey: seatsIoPublicKey,
    }).render();
  };

  useEffect(() => {
    if (!window.seatsio) {
      loadDOMScript(config.seats.url).then(loadChart);
    } else {
      loadChart();
    }

    return () => {
      seatingChart?.destroy();
    };
  }, [seatsIoPublicKey]);

  return (
    <GenericTable>
      <div
        id={
          categoryKey
            ? `${SEATS_CONTAINER_ID}-${categoryKey}`
            : SEATS_CONTAINER_ID
        }
      />
    </GenericTable>
  );
};

export default SeatsIo;
