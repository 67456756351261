import axios, { CancelTokenSource } from 'axios';

import config from '@Config';

import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IGetPriceTypesSuccessPayload } from '@Model/priceTypes/types';

import { ISavePriceTypeBody } from './types';

class RuleTypesApi {
  private static getPriceTypesUrl(): string {
    return `${config.api.baseUrlV2}admin/price-types`;
  }
  private static getDeletePriceTypeUrl(id: number) {
    return `${config.api.baseUrlV2}admin/price-types/${id}/delete`;
  }

  private cancelTokenPriceTypes?: CancelTokenSource;

  public getPriceTypes<T extends IGetPriceTypesSuccessPayload>(
    partnerId?: string,
    page?: number,
    count?: number
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenPriceTypes = axios.CancelToken.source();

      axios
        .get(RuleTypesApi.getPriceTypesUrl(), {
          cancelToken: this.cancelTokenPriceTypes.token,
          params: {
            count,
            page,
            'partnerIds[]': partnerId,
          },
        })
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public savePriceType(
    body: ISavePriceTypeBody,
    partnerId?: number
  ): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenPriceTypes = axios.CancelToken.source();
      if (body.id) {
        axios
          .put(RuleTypesApi.getPriceTypesUrl(), {
            ...body,
            default: body.title === 'default' || undefined,
            partnerId,
          })
          .then(getData)
          .then((response) => resolve(response))
          .catch((error) => reject(catchHttpError(error)));
      } else {
        axios
          .post(RuleTypesApi.getPriceTypesUrl(), { ...body, partnerId })
          .then(getData)
          .then((response) => resolve(response))
          .catch((error) => reject(catchHttpError(error)));
      }
    });
  }

  public deletePriceType(id: number): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenPriceTypes = axios.CancelToken.source();

      axios
        .post(RuleTypesApi.getDeletePriceTypeUrl(id))
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelProductsTypes() {
    if (this.cancelTokenPriceTypes) {
      this.cancelTokenPriceTypes.cancel();
      this.cancelTokenPriceTypes = undefined;
    }
  }
}

export default new RuleTypesApi();
