import { getType } from 'typesafe-actions';

import { getEntryFormsFormIo, resetState } from '../actions';
import { IAction, ISubmission } from '../types';

const initialState: ISubmission = {
  data: {
    text: '',
    uczestnicy: [],
  },
  modified: '',
};

const usersReducer = (
  state: ISubmission = initialState,
  action: IAction,
): ISubmission => {
  switch (action.type) {
    case getType(getEntryFormsFormIo.success):
      return action.payload;
    case getType(resetState):
      return initialState;
    default:
      return state;
  }
};

export default usersReducer;
