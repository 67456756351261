import { getHappenings } from '@Model/happenings/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetError, resetLoading, setLoading } from './../actions';
import { HAPPENINGS } from './../constants/constants';

export const setUpStateWhenHappeningsFetchRequested: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$(isActionOf(getHappenings.request)),
    mergeMap$(() => [resetError(HAPPENINGS), setLoading(HAPPENINGS)]),
  );
};

export const setLoaderWhenHappeningsFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getHappenings.success, getHappenings.failure], action),
    ),
    map$(() => resetLoading(HAPPENINGS)),
  );
};

export const setErrorOnHappeningsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getHappenings.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR)),
  );
};
