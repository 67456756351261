import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: 320,
  },
  cardContent: {
    flexGrow: 1,
  },
  container: {
    height: 'min-content',
    minHeight: 320,
  },
}));

export default useStyles;
