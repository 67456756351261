import { getType } from 'typesafe-actions';

import {
  IRefundsRegistrationFilters,
  RefundStatus,
} from '@Services/$refunds-registration-api/types';

import {
  changeRefundsRegistrationsFilter,
  changeRefundsRegistrationsSearch,
  clearRefundsRegistrationsFilter,
  getRefundsRegistrationMetadata,
  getRefundsRegistrations,
  handleActiveRefundRegistration,
} from '../actions';
import { IAction, IRefundsRegistrationReducer } from '../types';

const emptyStateFilter: IRefundsRegistrationFilters = {
  refundMaxTime: [],
  refundType: [],
  status: [],
};

const initialStateFilter: IRefundsRegistrationFilters = {
  refundMaxTime: [],
  refundType: [],
  status: Object.keys(RefundStatus).filter(
    (status) => status !== RefundStatus.refundCompleted
  ),
};

const initialState: IRefundsRegistrationReducer = {
  filter: {
    refundMaxTime: initialStateFilter.refundMaxTime.slice(),
    refundType: initialStateFilter.refundType.slice(),
    status: initialStateFilter.status.slice(),
  },
  metadata: {
    refundMaxTimes: [],
    refundStatusTypes: [],
    refundTypes: [],
  },
  refundsRegistrations: {
    data: [],
    meta: {
      page: 1,
      size: 20,
      total: 0,
    },
  },
  singleRefundsRegistration: null,
};

const settlementsReducer = (
  state: IRefundsRegistrationReducer = initialState,
  action: IAction
): IRefundsRegistrationReducer => {
  switch (action.type) {
    case getType(clearRefundsRegistrationsFilter):
      return {
        ...state,
        filter: {
          refundMaxTime: emptyStateFilter.refundMaxTime.slice(),
          refundType: emptyStateFilter.refundType.slice(),
          status: emptyStateFilter.status.slice(),
        },
      };
    case getType(changeRefundsRegistrationsFilter):
      return {
        ...state,
        filter: action.payload,
      };
    case getType(changeRefundsRegistrationsSearch):
      return {
        ...state,
        filter: action.payload,
      };
    case getType(getRefundsRegistrations.success):
      return {
        ...state,
        refundsRegistrations: action.payload,
      };
    case getType(getRefundsRegistrationMetadata.success):
      return {
        ...state,
        metadata: action.payload,
      };
    case getType(handleActiveRefundRegistration):
      return {
        ...state,
        singleRefundsRegistration: action.payload,
      };
    default:
      return state;
  }
};

export default settlementsReducer;
