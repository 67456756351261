import { getType } from 'typesafe-actions';

import { uniqueUsers } from '@Misc/helpers/removeDuplicatesUsers';

import { addUser, detachUser, getUsers, resetState } from './../actions';
import { IAction, IFormIoUsersReducer } from './../types';

const initialState: IFormIoUsersReducer = {
  totalCount: 0,
  users: [],
};

const usersReducer = (
  state: IFormIoUsersReducer = initialState,
  action: IAction
): IFormIoUsersReducer => {
  switch (action.type) {
    case getType(getUsers.success):
      return {
        totalCount: uniqueUsers([...state.users, ...action.payload.users])
          .length,
        users: uniqueUsers([...state.users, ...action.payload.users]),
      };
    case getType(addUser):
      return {
        totalCount: state.totalCount + 1,
        users: [...state.users, action.payload],
      };
    case getType(detachUser.success):
      return {
        totalCount: state.totalCount - 1,
        users: state.users.filter((user) => user.id !== action.payload),
      };
    case getType(resetState):
      return initialState;
    default:
      return state;
  }
};

export default usersReducer;
