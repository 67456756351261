import React, { useEffect } from 'react';

import { Grid, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TypographyVariant } from '@Constants/Variants/Typography';
import translateDefaultPriceType from '@Misc/helpers/strings/translateDefaultPrice';

import useStyles from './PricesTable.styles';
import { IPricesTableProps } from './PricesTable.types';

const PricesTable = ({ mounted, prices }: IPricesTableProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Add.components.FormLayout.PricesTable',
  });

  const classes = useStyles();

  useEffect(() => {
    mounted();
  }, []);

  return (
    <>
      {prices.map((price) => (
        <ListItem key={price.name} className={classes.item}>
          <Grid container={true}>
            <Grid item={true} xs={6}>
              <Typography variant={TypographyVariant.body1}>
                {translateDefaultPriceType(price.name)}
              </Typography>
            </Grid>
            <Grid item={true} xs={6}>
              <Typography align="right" variant={TypographyVariant.body1}>
                {price.price} {t('currency')}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </>
  );
};

export default PricesTable;
