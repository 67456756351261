import React from 'react';

import { Dialog } from '@mui/material';

import DiscountForm from './../DiscountForm';
import { IDiscountModalProps } from './DiscountModal.types';

const DiscountModal = ({ showModal, closeModal }: IDiscountModalProps) => {
  return (
    <Dialog
      open={showModal}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DiscountForm />
    </Dialog>
  );
};
export default DiscountModal;
