import { IReservationCheckReducer } from '@Model/reservations/types';
import _Store from '@Store';
import { createSelector } from 'reselect';
import getCheck from './getCheck';

const getCheckPriceMessage = createSelector<
  _Store.IState,
  IReservationCheckReducer,
  string
>(
  getCheck,
  (check) => check.price || '',
);

export default getCheckPriceMessage;
