import { createSelector } from 'reselect';

import _Store from '@Store';

import { IExtendedSlot, IReservationAddReducer } from './../types';
import getAdd from './getAdd';

const getSelectedExtendedDuration = createSelector<
  _Store.IState,
  IReservationAddReducer,
  IExtendedSlot | null
>(getAdd, (add) => add.extendedDuration);

export default getSelectedExtendedDuration;
