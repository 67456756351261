import { createAsyncAction } from 'typesafe-actions';

import PrintingError from '@Misc/classes/PrintingError';

import {
  _BOCA_PRINT_FAILURE,
  _BOCA_PRINT_REQUEST,
  _BOCA_PRINT_SUCCESS,
} from '../constants/actions';

export const printBoca = createAsyncAction(
  _BOCA_PRINT_REQUEST,
  _BOCA_PRINT_SUCCESS,
  _BOCA_PRINT_FAILURE
)<undefined, string | PrintingError, Error>();
