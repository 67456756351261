import { IHappeningReducer } from '@Model/happening/types';
import _Store from '@Store';
import { createSelector } from 'reselect';
import getHappening from './getHappening';

const getSpaceMaxNumberOfPeople = createSelector<
  _Store.IState,
  IHappeningReducer,
  number
>(
  [getHappening],
  (happening) => {
    if (happening && happening.spaces && happening.spaces.length > 0) {
      return happening.spaces[0].maxNumberOfPeople || 0;
    }

    return 0;
  },
);

export default getSpaceMaxNumberOfPeople;
