import React from 'react';

import { Button, Hidden, IconButton, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './DrawerOpen.styles';
import { IDrawerOpenProps } from './DrawerOpen.types';

const DrawerOpen = ({ pullDrawer, showButton }: IDrawerOpenProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'DrawerOpen',
  });

  const classes = useStyles();

  if (!showButton) {
    return null;
  }

  return (
    <>
      <Hidden lgDown={true}>
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          className={classes.button}
          onClick={pullDrawer}
          startIcon={
            <SvgIcon className={classes.startIcon}>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M21.8 16C22.4 16 23 16.6 23 17.3V20.8C23 21.4 22.4 22 21.7 22H16.2C15.6 22 15 21.4 15 20.7V17.2C15 16.6 15.6 16 16.2 16V13.5C16.2 12.1 17.6 11 19 11C20.4 11 21.8 12.1 21.8 13.5V14H20.5V13.5C20.5 12.7 19.8 12.2 19 12.2C18.2 12.2 17.5 12.7 17.5 13.5V16H21.8ZM2 6H20V9.1L19 9C18.18 9 17.41 9.2 16.73 9.54C16.28 9.18 16 8.62 16 8H6C6 9.11 5.11 10 4 10V14C5.11 14 6 14.9 6 16H13.04L13 16.5V18H2V6ZM11 9C12.66 9 14 10.34 14 12C14 13.66 12.66 15 11 15C9.34 15 8 13.66 8 12C8 10.34 9.34 9 11 9Z"
                fill="#E0E0E0"
              />
            </SvgIcon>
          }
        >
          {t('openDrawer')}
        </Button>
      </Hidden>
      <Hidden only={['md', 'lg', 'xl']}>
        <IconButton
          size="small"
          font-size="large"
          color="secondary"
          className={classes.startIcon}
          onClick={pullDrawer}
        >
          <SvgIcon>
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M21.8 16C22.4 16 23 16.6 23 17.3V20.8C23 21.4 22.4 22 21.7 22H16.2C15.6 22 15 21.4 15 20.7V17.2C15 16.6 15.6 16 16.2 16V13.5C16.2 12.1 17.6 11 19 11C20.4 11 21.8 12.1 21.8 13.5V14H20.5V13.5C20.5 12.7 19.8 12.2 19 12.2C18.2 12.2 17.5 12.7 17.5 13.5V16H21.8ZM2 6H20V9.1L19 9C18.18 9 17.41 9.2 16.73 9.54C16.28 9.18 16 8.62 16 8H6C6 9.11 5.11 10 4 10V14C5.11 14 6 14.9 6 16H13.04L13 16.5V18H2V6ZM11 9C12.66 9 14 10.34 14 12C14 13.66 12.66 15 11 15C9.34 15 8 13.66 8 12C8 10.34 9.34 9 11 9Z"
              fill="#E0E0E0"
            />
          </SvgIcon>
        </IconButton>
      </Hidden>
    </>
  );
};

export default DrawerOpen;
