import React, { useEffect } from 'react';

import ErrorIcon from '@mui/icons-material/ErrorOutline';
import { Button, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import colors from '@Compo/App/colors';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';

import { ISummaryCanceledProps } from './SummaryCanceled.types';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: colors.error.light,
    marginRight: theme.spacing(2),
  },
  successful: {
    marginBottom: theme.spacing(3),
  },
  summary: {
    marginBottom: theme.spacing(3),
  },
}));

const SummaryCanceled = ({
  cancelTransaction,
  continueBuying,
}: ISummaryCanceledProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'SummaryCanceled',
  });

  const classes = useStyles();
  useEffect(() => {
    cancelTransaction();
  }, []);

  const Buttons = () => (
    <Grid container={true} justifyContent="flex-end">
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        onClick={continueBuying}
      >
        {t('tryAgain')}
      </Button>
    </Grid>
  );

  return (
    <GoingPaper title={t('title')} buttons={Buttons()}>
      <Grid
        container={true}
        direction="row"
        alignItems="center"
        className={classes.successful}
      >
        <ErrorIcon className={classes.icon} />
        <Typography variant="body1">{t('text1')}</Typography>
      </Grid>
      <Typography component="p" variant="caption" className={classes.summary}>
        {t('text2')}
      </Typography>
    </GoingPaper>
  );
};

export default SummaryCanceled;
