import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import { TypographyVariant } from '@Constants/Variants/Typography';

import ImportListModal from './components/ImportListModal';
import { getInitialValues } from './components/TicketsListForm/TicketsListForm.helper';
import TicketsListModal from './components/TicketsListModal';
import useStyles from './TicketsList.styles';
import { ITicketsListProps } from './TicketsList.types';

const TicketsList = ({
  ticketsList,
  isLoading,
  selectTicketList,
  editTicketList,
}: ITicketsListProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates.TicketsList',
  });

  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const columns = [
    { id: 'id', label: t('id') },
    { id: 'name', label: t('name') },
    { id: 'scannable', label: t('scannable') },
    { id: 'countTickets', label: t('countTickets') },
  ];

  const catchEditTicket = (id: string) => {
    editTicketList(Number(id));
  };

  const addNewTicket = () => {
    selectTicketList(getInitialValues());
  };

  const closeModal = () => {
    setOpen(false);
  };
  const openModal = () => {
    setOpen(true);
  };

  return (
    <Grid container={true} spacing={3}>
      <TicketsListModal />
      <ImportListModal open={open} closeModal={closeModal} />
      <Grid item={true} xs={12}>
        <Typography variant={TypographyVariant.h6}>
          {t('ticketsListTitle')}
        </Typography>
      </Grid>
      <Grid item={true} xs={12}>
        <GenericTable>
          <TableActions>
            <div />

            <Grid container={true} justifyContent="flex-end" spacing={2}>
              <Grid item={true}>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  startIcon={<PlaylistAddIcon />}
                  className={classes.button}
                  onClick={openModal}
                >
                  {t('importList')}
                </Button>
              </Grid>
              <Grid item={true}>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  startIcon={<AddIcon />}
                  className={classes.button}
                  onClick={addNewTicket}
                >
                  {t('add')}
                </Button>
              </Grid>
            </Grid>
          </TableActions>
          <TableUi
            rows={ticketsList.data}
            columns={columns}
            disableCheckBox={true}
            onRowClick={catchEditTicket}
            isLoading={isLoading}
          />
        </GenericTable>
      </Grid>
    </Grid>
  );
};

export default TicketsList;
