import _Store from '@Store';
import { createSelector } from 'reselect';
import { IReservationAddReducer } from '../types';
import getAdd from './getAdd';

const getSelectedUpSell = createSelector<
  _Store.IState,
  IReservationAddReducer,
  boolean
>(
  getAdd,
  (add) => add.selectedUpSell,
);

export default getSelectedUpSell;
