import React, { useEffect, useState } from 'react';

import TuneIcon from '@mui/icons-material/Tune';
import { Button, Grid } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import { ITransactionsFilters } from '@Model/transactions/types';

import Filters from './components/Filters';
import TransactionModal from './components/TransactionModal';
import useStyles from './Transactions.styles';
import { ITransactionsProps } from './Transactions.types';

const Transactions = ({
  mounted,
  transactions,
  meta,
  filters,
  changeFilter,
  handleActivePage,
  changeSize,
  normalizedTransactions,
  setActiveTransaction,
  isLoading,
}: ITransactionsProps) => {
  const { t } = useTranslation('components', { keyPrefix: 'Transactions' });

  const [showFilters, setShowFilters] = useState(false);
  const [filtersActive, setFiltersActive] = useState(filters.billExist);

  const classes = useStyles();

  const columns = [
    { id: 'date', label: t('date') },
    { id: 'amount', label: t('amount') },
    { id: 'receiptPrintout', label: t('receiptPrintout') },
    { id: 'buyer', label: t('buyer') },
    { id: 'numberOfTickets', label: t('numberOfTickets') },
    { id: 'numberOfProducts', label: t('numberOfProducts') },
    { id: 'salesDocument', label: t('salesDocument') },
    { id: 'transactionId', label: t('transactionId') },
  ];

  const { total: totalCount, page: activePage, size: limit } = meta;

  const catchChangeActivePage = (e: unknown, page: number) => {
    handleActivePage(page + 1);
  };
  const catchChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    changeSize(Number(event.target.value));
  };
  const catchRowClicked = (id: string) => {
    const foundedTransaction = transactions.find(
      (transaction) => transaction.transactionId === Number(id)
    );
    if (foundedTransaction) {
      setActiveTransaction(foundedTransaction);
    }
  };
  const catchHandleFiltersSubmit = (filter: ITransactionsFilters) => {
    changeFilter(filter);
    actionForFilter(filter);
  };
  const actionForFilter = (filter: ITransactionsFilters) => {
    setFiltersActive(filter.billExist);

    setShowFilters(false);
  };

  useEffect(() => {
    mounted();
  }, []);

  return (
    <GoingPaper>
      <GenericTable>
        <TransactionModal />
        <TableActions>
          <Grid container={true} justifyContent="flex-end" spacing={2}>
            <Grid item={true}>
              <Button
                variant="outlined"
                size="small"
                color={filtersActive ? 'primary' : 'secondary'}
                startIcon={<TuneIcon />}
                className={classes.button}
                onClick={() => setShowFilters(!showFilters)}
              >
                {t('filters')}
              </Button>
            </Grid>
          </Grid>
        </TableActions>
        {showFilters && (
          <Grid
            container={true}
            justifyContent="flex-end"
            className={classes.filtersContainer}
          >
            <Formik
              component={Filters}
              onSubmit={catchHandleFiltersSubmit}
              initialValues={filters}
            />
          </Grid>
        )}
        <TableUi
          rows={normalizedTransactions}
          columns={columns}
          total={totalCount}
          activePage={activePage - 1}
          rowsPerPage={limit}
          handleChangePage={catchChangeActivePage}
          handleChangeRowsPerPage={catchChangeRowsPerPage}
          disableCheckBox={true}
          onRowClick={catchRowClicked}
          isLoading={isLoading}
        />
      </GenericTable>
    </GoingPaper>
  );
};

export default Transactions;
