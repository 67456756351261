import { Selector } from 'reselect';

import _Store from '@Store';

import { IRundateSingleReducer } from '../types';

const getSingleRundate: Selector<_Store.IState, IRundateSingleReducer> = (
  state
) => state.goingRundates.singleRundate;

export default getSingleRundate;
