import { getType } from 'typesafe-actions';

import {
  changeFilter,
  changePageOrSize,
  clearFilter,
  getRundates,
  search,
} from '../actions';
import { IAction, IRundatesReducer } from '../types';

const initialState: IRundatesReducer = {
  data: [],
  filter: {
    search: undefined,
    withCalendarEvents: false,
    withExpired: false,
    withRemoved: true,
  },
  meta: {
    page: 1,
    size: 20,
    total: 0,
  },
};

const goingRundatesReducer = (
  state: IRundatesReducer = initialState,
  action: IAction
): IRundatesReducer => {
  switch (action.type) {
    case getType(getRundates.success):
      return {
        ...state,
        ...action.payload,
      };
    case getType(changePageOrSize):
      return {
        ...state,
        meta: {
          ...state.meta,
          page: action.payload.page || state.meta.page,
          size: action.payload.size || state.meta.size,
        },
      };
    case getType(search):
    case getType(changeFilter):
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload.filter,
        },
        meta: {
          ...state.meta,
          page: initialState.meta.page,
          size: initialState.meta.size,
        },
      };
    case getType(clearFilter):
      return {
        ...state,
        filter: initialState.filter,
        meta: {
          ...state.meta,
          page: initialState.meta.page,
          size: initialState.meta.size,
        },
      };
    default:
      return state;
  }
};

export default goingRundatesReducer;
