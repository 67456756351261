import { getType } from 'typesafe-actions';

import { getPoolDescriptions, getRundatePools, selectPool } from '../actions';
import { IAction, IPoolsReducer } from '../types';

const initialState: IPoolsReducer = {
  poolDescriptions: [],
  pools: [],
  selectedPool: null,
};

const goingPoolsReducer = (
  state: IPoolsReducer = initialState,
  action: IAction
): IPoolsReducer => {
  switch (action.type) {
    case getType(getRundatePools.success):
      return { ...state, pools: action.payload };
    case getType(selectPool):
      return { ...state, selectedPool: action.payload };
    case getType(getPoolDescriptions.success):
      return { ...state, poolDescriptions: action.payload };
    default:
      return state;
  }
};

export default goingPoolsReducer;
