import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dispatch } from 'redux';

import _Store from '@Store';

import {
  changeFilter,
  changePageOrSize,
  mounted,
  resetActiveRundate,
  search,
} from '@Model/going/rundates/actions';
import {
  getRundatesData,
  getRundatesMeta,
  getRundatesSearchText,
} from '@Model/going/rundates/selectors';
import { GOING_RUNDATES } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import getRundatesFilter from '../../models/going/rundates/selectors/getRundatesFilter';
import Rundates from './Rundates.component';
import { IRundatesFromDispatch, IRundatesFromState } from './Rundates.types';

const mapStateToProps = (state: _Store.IState): IRundatesFromState => ({
  filterValues: getRundatesFilter(state),
  isLoading: getLoading(GOING_RUNDATES)(state),
  meta: getRundatesMeta(state),
  rundates: getRundatesData(state),
  searchText: getRundatesSearchText(state),
});

const mapDispatchToProps = (dispatch: Dispatch): IRundatesFromDispatch => {
  return {
    changeFilter: (filter) => dispatch(changeFilter({ filter })),
    changePageOrSize: (body) =>
      dispatch(
        changePageOrSize({
          page: body.page,
          size: body.size,
        })
      ),
    mounted: () => dispatch(mounted()),
    resetActiveRundate: () => dispatch(resetActiveRundate()),
    search: (filter) => dispatch(search({ filter })),
  };
};

export default withRouter(
  connect<IRundatesFromState, IRundatesFromDispatch, {}, _Store.IState>(
    mapStateToProps,
    mapDispatchToProps
  )(Rundates)
);
