import { Selector } from 'reselect';

import _Store from '@Store';

import { ITransactionsReducer } from '../types';

const get: Selector<_Store.IState, ITransactionsReducer> = (state) =>
  state.transactions;

export default get;
