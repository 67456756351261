import { ISingleTicketResponse } from '@Services/$going-rundate-api/types';

import { ITicketsListValues } from './TicketsListForm.types';

export const getInitialValues = (
  values?: ISingleTicketResponse
): ITicketsListValues => {
  if (values) {
    return {
      id: values.id,
      name: values.name,
      scannable: values.scannable,
      ticketInfo: values.ticketInfo,
    };
  }

  return {
    name: '',
    scannable: true,
    ticketInfo: null,
  };
};
