import { LOCATION_CHANGE } from 'connected-react-router';
import moment from 'moment';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  delay,
  filter as filter$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import {
  endSession,
  endUserSession,
  refreshToken,
  runSession,
} from '@Model/authorization/actions';
import { getUserInfo } from '@Model/authorization/selectors';
import {
  catchEndSession,
  catchSession,
  endPrinterSession,
  endPrinterSessionById,
  endPrinterSessionByIdV2,
  endPrinterSessionV2,
  endPrinterSessionWithOutCashState,
  endPrinterSessionWithOutCashStateById,
  getPaymentSession,
  getPrinters,
  getPrinterStatus,
  startPingingPrinter,
  startSession,
  startSessionV2,
} from '@Model/printer/actions';
import { getPinging } from '@Model/printer/selectors';
import { allPermissions } from '@Model/state/constants/permissions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR, TYPE_SUCCESS } from '@Model/toasts/constants/constants';
import { IRunSessionRequest } from '@Services/$authorization-api/types';
import _Store from '@Store';
import { getPrinters as getPrintersSelector } from '../selectors';
import getUserPrinter from '../selectors/getUserPrinter';
import { IStartPrinterSessionPayload } from '../types';

const PRINT_SESSION_ERROR_TEXT =
  'Błąd ustawienia sesji drukarki. Sprawdź czy drukarka i serwer są podłączone do zasilania.';
const END_SESSION_PRINT_ERROR_TEXT =
  'Sesja zakończona pomyślnie, lecz wystąpił błąd z wydrukowaniem raportu. Zapisz datę i godzinę wystąpienia błędu.';
const START_SESSION_PRINT_ERROR_TEXT =
  'Wystąpił błąd z wydrukowaniem raportu. Zapisz datę i godzinę wystąpienia błędu.';
const END_SESSION_TEXT = 'Sesja zakończona pomyślnie!';

export const catchStartSession: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(catchSession)),
    withLatestFrom$(state$),
    filter$(
      ([action, state]) =>
        getPrintersSelector(state).find(
          (item) => item.id === action.payload.printerId
        )?.firmware !== '2.0.0' &&
        getPrintersSelector(state).find(
          (item) => item.id === action.payload.printerId
        )?.firmware !== '2.0.1'
    ),
    mergeMap$(([action, state]) => {
      const printers = getPrintersSelector(state);

      const printer =
        printers.find((item) => item.id === action.payload.printerId) || null;

      return of$(
        endPrinterSession.request({
          actions: [],
          cashSummary: { startCash: 0, endCash: 0 },
          finishedAt: null,
          hardwareId: null,
          incomeActionsQuantity: 0,
          onStartEndSession: true,
          printer,
          startSessionPayload: action.payload,
          startedAt: null,
          userId: null,
          uuid: null,
        })
      );
    })
  );
};

export const startSessionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi }
) => {
  return action$.pipe(
    filter$(isActionOf(startSession.request)),
    withLatestFrom$(state$),

    mergeMap$(([action, state]) => {
      const { printerId } = action.payload;
      let { startCash } = action.payload;
      const { id, session } = getUserInfo(state);

      const sessionPayload: IRunSessionRequest = {
        printerId: Number(printerId),
        startCash: Number(startCash),
        userId: Number(id),
      };

      const printers = getPrintersSelector(state);
      const printer =
        printers.find((item) => item.id === sessionPayload.printerId) || null;

      if (!printer) {
        return EMPTY$;
      }

      const { saleUrl } = printer;

      const { first_name, last_name } = getUserInfo(state);

      if (startCash) {
        startCash = Math.round(startCash * 100);
      }

      const startSessionPayload: IStartPrinterSessionPayload = [
        {
          cmd: 'login',
          params: `na,${first_name} ${last_name}`,
        },
        { cmd: 'cash', params: `kw,${startCash}\nwp,T` },
        {
          cmd: 'shiftrep',
          params: 'sh,',
        },
      ];

      const { permissions } = getUserInfo(state);
      const pinging = getPinging(state);
      if (
        permissions &&
        permissions.includes(
          allPermissions.access_cashiersessions_write_without_printer
        )
      ) {
        return of$(runSession(sessionPayload), startSession.success());
      }

      return from$(
        printerApi.startSession(saleUrl, startSessionPayload, session)
      ).pipe(
        mergeMap$(() => {
          if (!pinging) {
            return of$(
              runSession(sessionPayload),
              startSession.success(),
              startPingingPrinter(sessionPayload)
            );
          }
          return of$(runSession(sessionPayload), startSession.success());
        }),

        catchError$((error: Error) => {
          return of$(
            addToast(PRINT_SESSION_ERROR_TEXT, TYPE_ERROR),
            startSession.failure(error)
          );
        })
      );
    })
  );
};

export const endSessionWithOutCashState: _Store.IEpic = (
  action$,
  state$,
  { printerApi }
) => {
  return action$.pipe(
    filter$(isActionOf(endPrinterSessionWithOutCashState.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const userPrinter = getUserPrinter(state);

      if (!userPrinter) {
        return EMPTY$;
      }

      const PENNY_MULTIPLIER = 100;

      const { payload } = action;

      const { saleUrl } = userPrinter;

      const { session } = getUserInfo(state);

      const getEndSessionPayload = () => {
        if (payload && payload.cashSummary) {
          const { startCash, endCash } = payload.cashSummary;
          const totalEndCash: number =
            payload.actions
              .filter((payActions) => payActions.paymentType !== 'cash')
              .reduce<number>((pV, cV) => pV + cV.incomeSum, 0) + endCash;

          return [
            { cmd: 'cash', params: `kw,0\nwp,N` },
            { cmd: 'shiftrep', params: 'sh,pierwsza\nzr,T' },

            { cmd: 'formstart', params: 'fn,200\nfh,48' },
            {
              cmd: 'formline',
              params: 'fn,200\nfl,474\ns1,Raport systemowej sesji',
            },
            {
              cmd: 'formline',
              params: 'fn,200\nfl,474\ns1,Poczatkowa kwota:',
            },
            {
              cmd: 'formline',
              params: `fn,200\nfl,113\ns1,${startCash / PENNY_MULTIPLIER}zl`,
            },

            {
              cmd: 'formline',
              params: 'fn,200\nfl,474\ns1,Koncowa kwota:',
            },
            {
              cmd: 'formline',
              params: `fn,200\nfl,113\ns1,${totalEndCash / PENNY_MULTIPLIER}zl`,
            },
            { cmd: 'formend', params: 'fn,200' },

            { cmd: 'logout' },
          ];
        }

        return [
          { cmd: 'cash', params: `kw,0\nwp,N` },
          { cmd: 'shiftrep', params: 'sh,pierwsza\nzr,T' },
          { cmd: 'logout' },
        ];
      };

      const endSessionPayload: IStartPrinterSessionPayload = getEndSessionPayload();
      return from$(
        printerApi.startSession(saleUrl, endSessionPayload, session)
      ).pipe(
        mergeMap$(() => {
          return [
            endPrinterSession.success(),
            endPrinterSessionWithOutCashState.success(),
            endUserSession(),
            refreshToken.request(),
          ];
        }),
        catchError$((error) => {
          return of$(
            addToast(PRINT_SESSION_ERROR_TEXT, TYPE_ERROR),
            endPrinterSession.failure(error),
            endPrinterSessionWithOutCashState.failure(error),
            refreshToken.request()
          );
        })
      );
    })
  );
};

export const endSessionByIdWithoutCasStateWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi }
) => {
  return action$.pipe(
    filter$(isActionOf(endPrinterSessionWithOutCashStateById.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const userPrinter = getPrintersSelector(state).find(
        (printer) => printer.id === action.payload
      );

      const { session } = getUserInfo(state);

      if (!userPrinter) {
        return EMPTY$;
      }

      const { saleUrl } = userPrinter;

      const endSessionPayload: IStartPrinterSessionPayload = [
        { cmd: 'cash', params: `kw,0\nwp,N` },
        { cmd: 'shiftrep', params: 'sh,pierwsza\nzr,T' },
        { cmd: 'logout' },
      ];

      return from$(
        printerApi.startSession(saleUrl, endSessionPayload, session)
      ).pipe(
        mergeMap$(() => {
          return of$(
            endPrinterSessionById.success(),
            endPrinterSessionWithOutCashStateById.success(),
            addToast(END_SESSION_TEXT, TYPE_SUCCESS),
            getPrinters.request()
          );
        }),
        catchError$(() => {
          return of$(
            addToast(PRINT_SESSION_ERROR_TEXT, TYPE_ERROR),
            endPrinterSessionById.failure(new Error(PRINT_SESSION_ERROR_TEXT)),
            endPrinterSessionWithOutCashStateById.failure(
              new Error(PRINT_SESSION_ERROR_TEXT)
            )
          );
        })
      );
    })
  );
};

export const catchEndSessionWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(catchEndSession)),
    withLatestFrom$(state$),
    mergeMap$(() => {
      return of$(endSession());
    })
  );
};

export const endSessionByIdWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi }
) => {
  return action$.pipe(
    filter$(isActionOf(endPrinterSessionById.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const userPrinter = getPrintersSelector(state).find(
        (printer) => printer.id === action.payload
      );

      const { session } = getUserInfo(state);

      if (!userPrinter) {
        return EMPTY$;
      }

      const { saleUrl } = userPrinter;

      const cashStatusPayload = [{ cmd: 'scashstate' }];

      return from$(
        printerApi.getCashStatus(saleUrl, cashStatusPayload, session)
      ).pipe(
        mergeMap$((data) => {
          if (
            data.ok &&
            data.hits.length &&
            data.hits[0] &&
            data.hits[0].results &&
            data.hits[0].results.length &&
            data.hits[0].results[0].cs
          ) {
            const cashBask = data.hits[0].results[0].cs;

            const endSessionPayload: IStartPrinterSessionPayload = [
              { cmd: 'cash', params: `kw,${cashBask}\nwp,N` },
              { cmd: 'shiftrep', params: 'sh,pierwsza\nzr,T' },
              { cmd: 'logout' },
            ];

            return from$(
              printerApi.startSession(saleUrl, endSessionPayload, session)
            ).pipe(
              mergeMap$(() => {
                return of$(
                  endPrinterSessionById.success(),
                  addToast(END_SESSION_TEXT, TYPE_SUCCESS),
                  getPrinters.request()
                );
              })
            );
          }
          return of$(
            endPrinterSessionById.failure(new Error(PRINT_SESSION_ERROR_TEXT))
          );
        }),

        catchError$(() => {
          const firmware = userPrinter.firmware || '1.0.0';

          if (firmware === '1.0.0') {
            return of$(
              endPrinterSessionWithOutCashStateById.request(action.payload)
            );
          }

          return of$(
            addToast(PRINT_SESSION_ERROR_TEXT, TYPE_ERROR),
            endPrinterSessionById.failure(new Error(PRINT_SESSION_ERROR_TEXT))
          );
        })
      );
    })
  );
};

export const startPingingPrinterWhenRequest: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(startPingingPrinter)),
    mergeMap$((action) => {
      return of$(getPrinterStatus.request(action.payload));
    })
  );
};

export const fetchPrinterStatusWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi }
) => {
  let status = false;
  return action$.pipe(
    filter$(isActionOf(getPrinterStatus.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { session } = getUserInfo(state);

      if (!action.payload.printerId || !session) {
        return EMPTY$;
      }
      const userPrinter = getPrintersSelector(state).find(
        (printer) => printer.id === action.payload.printerId
      );

      if (!userPrinter) {
        return EMPTY$;
      }

      const { saleUrl } = userPrinter;

      return from$(printerApi.getPrinterStatus(saleUrl)).pipe(
        mergeMap$(() => {
          if (status) {
            status = false;
            printerApi.reportBug(
              new Error(`${saleUrl} - up`),
              JSON.stringify(''),
              saleUrl,
              ''
            );
            return of$(getPrinterStatus.request(action.payload)).pipe(
              delay(15000)
            );
          }
          return of$(getPrinterStatus.request(action.payload)).pipe(
            delay(15000)
          );
        }),

        catchError$(() => {
          if (!status) {
            printerApi.reportBug(
              new Error(`${saleUrl} - down`),
              JSON.stringify(''),
              saleUrl,
              ''
            );
            status = true;
            return of$(getPrinterStatus.request(action.payload)).pipe(
              delay(15000)
            );
          }

          return of$(getPrinterStatus.request(action.payload)).pipe(
            delay(15000)
          );
        })
      );
    })
  );
};

export const catchStartSessionV2: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(catchSession)),
    withLatestFrom$(state$),
    filter$(
      ([action, state]) =>
        getPrintersSelector(state).find(
          (item) => item.id === action.payload.printerId
        )?.firmware === '2.0.0' ||
        getPrintersSelector(state).find(
          (item) => item.id === action.payload.printerId
        )?.firmware === '2.0.1'
    ),
    mergeMap$(([action, state]) => {
      const { session, id } = getUserInfo(state);
      if (session) {
        return EMPTY$;
      }

      return of$(
        runSession({
          printerId: action.payload.printerId,
          startCash: Number(action.payload.startCash),
          userId: Number(id),
        })
      );
    })
  );
};

export const startSessionV2WhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi }
) => {
  return action$.pipe(
    filter$(isActionOf(startSessionV2.request)),
    withLatestFrom$(state$),

    mergeMap$(([action, state]) => {
      const { printerId } = action.payload;
      const { startCash } = action.payload;
      const { id, session } = getUserInfo(state);
      const PENNY_MULTIPLIER = 100;

      const sessionPayload: IRunSessionRequest = {
        printerId: Number(printerId),
        startCash: Number(startCash),
        userId: Number(id),
      };

      const printers = getPrintersSelector(state);
      const printer =
        printers.find((item) => item.id === sessionPayload.printerId) || null;

      if (!printer) {
        printerApi.reportBug(
          new Error(
            `[START_SESSION]: Empty printer url, printerId: ${sessionPayload.printerId}`
          ),
          JSON.stringify(printers),
          `[START_SESSION]: Empty printer url, printerId: ${sessionPayload.printerId}`,
          JSON.stringify(sessionPayload)
        );
        return of$(
          addToast(START_SESSION_PRINT_ERROR_TEXT, TYPE_ERROR),
          startSession.failure(new Error(START_SESSION_PRINT_ERROR_TEXT))
        );
      }

      const { saleUrl } = printer;

      const { first_name, last_name, username } = getUserInfo(state);

      const startSessionPayload: IStartPrinterSessionPayload = [
        { cmd: 'formstart', params: 'fn,26\nfh,0' },
        {
          cmd: 'formline',
          params:
            first_name.length && last_name.length
              ? `fn,26\nfl,1\ns1,${first_name} ${last_name}`
              : `fn,26\nfl,1\ns1,${username}`,
        },
        {
          cmd: 'formline',
          params: `fn,26\nfl,7\ns1,${(
            Number(startCash) || 0 / PENNY_MULTIPLIER
          ).toFixed(2)}`,
        },
        {
          cmd: 'formline',
          params: `fn,26\nfl,23\ns1,${moment().format('DD-MM hh:mm')}`,
        },
        { cmd: 'formend', params: 'fn,26' },
      ];

      const { permissions } = getUserInfo(state);
      const pinging = getPinging(state);
      if (
        permissions &&
        permissions.includes(
          allPermissions.access_cashiersessions_write_without_printer
        )
      ) {
        return of$(startSession.success());
      }

      return from$(
        printerApi.startSession(saleUrl, startSessionPayload, session)
      ).pipe(
        mergeMap$(() => {
          if (!pinging) {
            return of$(
              startSession.success(),
              startPingingPrinter(sessionPayload)
            );
          }
          return of$(startSession.success());
        }),

        catchError$((error: Error) => {
          return of$(
            addToast(PRINT_SESSION_ERROR_TEXT, TYPE_ERROR),
            startSession.failure(error)
          );
        })
      );
    })
  );
};

export const endSessionV2WhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi }
) => {
  return action$.pipe(
    filter$(isActionOf(endPrinterSessionV2.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const userPrinter = getUserPrinter(state);
      const printers = getPrintersSelector(state);
      const {
        session,
        first_name,
        last_name,
        username,
        permissions,
      } = getUserInfo(state);

      if (!userPrinter) {
        printerApi.reportBug(
          new Error(
            `[END_SESSION]: Empty printer url, printerId: ${session?.printer_id}`
          ),
          JSON.stringify(printers),
          `[END_SESSION]: Empty printer url, printerId: ${session?.printer_id}`,
          JSON.stringify(session)
        );
        return of$(
          addToast(END_SESSION_PRINT_ERROR_TEXT, TYPE_ERROR),
          endPrinterSession.failure(new Error(END_SESSION_PRINT_ERROR_TEXT))
        );
      }

      if (
        permissions &&
        permissions.includes(
          allPermissions.access_cashiersessions_write_without_printer
        )
      ) {
        return of$(
          endSession(),
          endPrinterSession.success(),
          refreshToken.request()
        );
      }

      const PENNY_MULTIPLIER = 100;

      const { payload } = action;

      const { saleUrl } = userPrinter;

      const {
        finishedAt,
        startedAt,
        actions,
        cashSummary,
        incomeActionsQuantity,
      } = payload;

      const { startCash } = cashSummary;

      const totalIncome: number = actions.reduce<number>(
        (pV, cV) => pV + cV.incomeSum,
        0
      );

      const totalIncomeInCash: number = actions
        .filter((transactionAction) => transactionAction.paymentType === 'cash')
        .reduce((pV, cV) => pV + cV.incomeSum, 0);
      const totalIncomeCard: number = actions
        .filter((transactionAction) => transactionAction.paymentType === 'card')
        .reduce((pV, cV) => pV + cV.incomeSum, 0);
      const totalIncomeBankTransfer: number = actions
        .filter(
          (transactionAction) =>
            transactionAction.paymentType === 'bank_transfer'
        )
        .reduce((pV, cV) => pV + cV.incomeSum, 0);

      const endSessionPayload: IStartPrinterSessionPayload = [
        { cmd: 'formstart', params: 'fn,200\nfh,48' },
        {
          cmd: 'formline',
          params:
            first_name.length && last_name.length
              ? `fn,200\nfl,303\ns1,${first_name} ${last_name}`
              : `fn,200\nfl,303\ns1,${username}`,
        },
        {
          cmd: 'formline',
          params: `fn,200\nfl,304\ns1,${(
            totalIncome / PENNY_MULTIPLIER
          ).toFixed(2)}`,
        },
        {
          cmd: 'formline',
          params: `fn,200\nfl,305\ns1,${(
            totalIncomeInCash / PENNY_MULTIPLIER
          ).toFixed(2)}`,
        },
        {
          cmd: 'formline',
          params: `fn,200\nfl,306\ns1,\r${(
            Number(totalIncomeCard) / PENNY_MULTIPLIER
          ).toFixed(2)}`,
        },
        {
          cmd: 'formline',
          params: `fn,200\nfl,709\ns1,Przelew:\r                         ${(
            Number(totalIncomeBankTransfer) / PENNY_MULTIPLIER
          ).toFixed(2)}`,
        },
        {
          cmd: 'formline',
          params: `fn,200\nfl,309\ns1,${(startCash / PENNY_MULTIPLIER).toFixed(
            2
          )}`,
        },
        {
          cmd: 'formline',
          params: `fn,200\nfl,321\ns1,${(
            (totalIncomeInCash + startCash) /
            PENNY_MULTIPLIER
          ).toFixed(2)}`,
        },
        {
          cmd: 'formline',
          params: `fn,200\nfl,322\ns1,${incomeActionsQuantity}`,
        },
        {
          cmd: 'formline',
          params: `fn,200\nfl,325\ns1,${moment(startedAt).format(
            'DD-MM hh:mm'
          )}`,
        },
        {
          cmd: 'formline',
          params: `fn,200\nfl,326\ns1,,${moment(finishedAt).format(
            'DD-MM hh:mm'
          )}`,
        },
        { cmd: 'formend', params: 'fn,200' },
      ];
      return from$(
        printerApi.startSession(saleUrl, endSessionPayload, session)
      ).pipe(
        mergeMap$(() => {
          return [
            endPrinterSession.success(),
            endPrinterSessionWithOutCashState.success(),
            endUserSession(),
            getPrinters.request(),
            refreshToken.request(),
          ];
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() =>
              printerApi.startSession(saleUrl, endSessionPayload, session, true)
            )
          )
        ),
        catchError$((error) => {
          return of$(
            addToast(PRINT_SESSION_ERROR_TEXT, TYPE_ERROR),
            endPrinterSession.failure(error),
            endPrinterSessionWithOutCashState.failure(error),
            refreshToken.request()
          );
        })
      );
    })
  );
};

export const endSessionByPrinterIdV2WhenRequest: _Store.IEpic = (
  action$,
  state$,
  { printerApi }
) => {
  return action$.pipe(
    filter$(isActionOf(endPrinterSessionByIdV2.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const printers = getPrintersSelector(state);
      const userPrinter = printers.find(
        (printer) => printer.id === action.payload.printerId
      );
      const { permissions } = getUserInfo(state);
      const session = action.payload;

      const PENNY_MULTIPLIER = 100;

      const { payload } = action;

      const {
        finishedAt,
        startedAt,
        actions,
        cashSummary,
        incomeActionsQuantity,
        userId,
      } = payload;

      const { startCash } = cashSummary;

      const totalIncome: number = actions.reduce<number>(
        (pV, cV) => pV + cV.incomeSum,
        0
      );

      const totalIncomeInCash: number = actions
        .filter((transactionAction) => transactionAction.paymentType === 'cash')
        .reduce((pV, cV) => pV + cV.incomeSum, 0);
      const totalIncomeCard: number = actions
        .filter((transactionAction) => transactionAction.paymentType === 'card')
        .reduce((pV, cV) => pV + cV.incomeSum, 0);
      const totalIncomeBankTransfer: number = actions
        .filter(
          (transactionAction) =>
            transactionAction.paymentType === 'bank_transfer'
        )
        .reduce((pV, cV) => pV + cV.incomeSum, 0);

      const endSessionPayload: IStartPrinterSessionPayload = [
        { cmd: 'formstart', params: 'fn,26\nfh,0' },
        {
          cmd: 'formline',
          params: `fn,26\nfl,1\ns1,${userId}`,
        },
        {
          cmd: 'formline',
          params: `fn,26\nfl,2\ns1,${(totalIncome / PENNY_MULTIPLIER).toFixed(
            2
          )}`,
        },
        {
          cmd: 'formline',
          params: `fn,26\nfl,3\ns1,${(
            totalIncomeInCash / PENNY_MULTIPLIER
          ).toFixed(2)}`,
        },
        {
          cmd: 'formline',
          params: `fn,26\nfl,4\ns1,/ Przelew\r${(
            (Number(totalIncomeCard) + Number(totalIncomeBankTransfer)) /
            PENNY_MULTIPLIER
          ).toFixed(2)}`,
        },
        {
          cmd: 'formline',
          params: `fn,26\nfl,7\ns1,${(startCash / PENNY_MULTIPLIER).toFixed(
            2
          )}`,
        },
        {
          cmd: 'formline',
          params: `fn,26\nfl,19\ns1,${(
            (totalIncomeInCash + startCash) /
            PENNY_MULTIPLIER
          ).toFixed(2)}`,
        },
        {
          cmd: 'formline',
          params: `fn,26\nfl,20\ns1,${incomeActionsQuantity}`,
        },
        {
          cmd: 'formline',
          params: `fn,26\nfl,23\ns1,${moment(startedAt).format('DD-MM hh:mm')}`,
        },
        {
          cmd: 'formline',
          params: `fn,26\nfl,24\ns1,${moment(finishedAt).format(
            'DD-MM hh:mm'
          )}`,
        },
        { cmd: 'formend', params: 'fn,26' },
      ];

      if (!userPrinter) {
        printerApi.reportBug(
          new Error(
            `[END_SESSION]: Empty printer url, printerId: ${session?.printerId}`
          ),
          JSON.stringify(printers),
          `[END_SESSION]: Empty printer url, printerId: ${session?.printerId}`,
          JSON.stringify(session)
        );
        return of$(
          addToast(END_SESSION_PRINT_ERROR_TEXT, TYPE_ERROR),
          endPrinterSession.failure(new Error(END_SESSION_PRINT_ERROR_TEXT))
        );
      }

      const { saleUrl } = userPrinter;

      if (
        permissions &&
        permissions.includes(
          allPermissions.access_cashiersessions_write_without_printer
        )
      ) {
        return of$(
          endSession(),
          endPrinterSession.success(),
          refreshToken.request()
        );
      }

      return from$(printerApi.startSession(saleUrl, endSessionPayload)).pipe(
        mergeMap$(() => {
          return [
            endPrinterSession.success(),
            endPrinterSessionWithOutCashState.success(),
            endUserSession(),
            getPrinters.request(),
            refreshToken.request(),
          ];
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() =>
              printerApi.startSession(
                saleUrl,
                endSessionPayload,
                undefined,
                true
              )
            )
          )
        ),
        catchError$((error) => {
          return of$(
            addToast(PRINT_SESSION_ERROR_TEXT, TYPE_ERROR),
            endPrinterSession.failure(error),
            endPrinterSessionWithOutCashState.failure(error),
            refreshToken.request()
          );
        })
      );
    })
  );
};
