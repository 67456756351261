import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { fetchTickets, getTickets } from '@Model/going/rundates/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { resetError, resetLoading, setLoading } from '../actions';
import { GOING_TICKET_LIST_TICKETS } from '../constants/constants';

export const setUpStateWhenGoingTicketListTicketsFetchRequested: _Store.IEpic =
  (action$) => {
    return action$.pipe(
      filter$(isActionOf([getTickets.request, fetchTickets])),
      mergeMap$(() => [
        resetError(GOING_TICKET_LIST_TICKETS),
        setLoading(GOING_TICKET_LIST_TICKETS),
      ])
    );
  };

export const setLoaderWhenGoingTicketListTicketsFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getTickets.success, getTickets.failure], action)
    ),
    map$(() => resetLoading(GOING_TICKET_LIST_TICKETS))
  );
};

export const setErrorOnGoingTicketListTicketsFailure: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([getTickets.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
