import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPoolDescriptionsReducer } from './../types';
import get from './get';

const getSearchText = createSelector<
  _Store.IState,
  IPoolDescriptionsReducer,
  string
>([get], (poolDescription) => poolDescription.searchText);

export default getSearchText;
