import React from 'react';

import { Formik } from 'formik';

import FormLayout from './../FormLayout';
import { IFormProps } from './Form.types';

const Form = ({ initialValues, save }: IFormProps) => {
  if (initialValues) {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={save}
        component={FormLayout}
      />
    );
  }
  return null;
};

export default Form;
