import { checkReservation } from '@Model/reservations/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetError, resetLoading, setLoading } from './../actions';
import { RESERVATION_CHECK } from './../constants/constants';

export const setUpStateWhenReservationCheckFetchRequested: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$(isActionOf(checkReservation.request)),
    mergeMap$(() => [
      resetError(RESERVATION_CHECK),
      setLoading(RESERVATION_CHECK),
    ]),
  );
};

export const setLoaderWhenReservationCheckFetchFinished: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([checkReservation.success, checkReservation.failure], action),
    ),
    map$(() => resetLoading(RESERVATION_CHECK)),
  );
};

export const setErrorOnReservationCheckFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([checkReservation.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR)),
  );
};
