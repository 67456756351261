import React, { useEffect } from 'react';

import { TextField } from '@mui/material';
import GridUi from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { IFormLayoutProps } from './FormLayout';
import styles from './FormLayout.module.scss';
import InvoiceDataStepper from './InvoiceDataStepper';

const WITH_INVOICE_DATA_KEY = 'withInvoiceData';

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(3),
  },
}));

const ClientDataStepper = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Add.components.FormLayout.ClientDataStepper',
  });

  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    values,
    setShowBasket,
    transactionType,
  } = props;
  const classes = useStyles();

  useEffect(() => {
    setShowBasket(false);
    setFieldValue('withFromData', true);

    return () => {
      setFieldValue('withFromData', false);
      setShowBasket(true);
    };
  }, []);

  return (
    <GridUi container={true} className={styles.stepperReservation}>
      <TextField
        variant="standard"
        id="firstName"
        label={(touched.firstName && errors.firstName) || t('firstName')}
        helperText={t('firstNameHelper')}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.firstName}
        name="firstName"
        error={!!(touched.firstName && errors.firstName)}
        className={classes.input}
        fullWidth={true}
        autoComplete="nope"
      />

      <TextField
        variant="standard"
        id="lastName"
        label={(touched.lastName && errors.lastName) || t('lastName')}
        helperText={t('lastNameHelper')}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.lastName}
        name="lastName"
        error={!!(touched.lastName && errors.lastName)}
        className={classes.input}
        fullWidth={true}
        autoComplete="nope"
      />
      <TextField
        variant="standard"
        id="email"
        label={(touched.email && errors.email) || t('email')}
        helperText={t('emailHelper')}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        name="email"
        error={!!(touched.email && errors.email)}
        className={classes.input}
        fullWidth={true}
        autoComplete="nope"
      />
      <TextField
        variant="standard"
        id="phone"
        label={(touched.phone && errors.phone) || t('phone')}
        helperText={t('phoneHelper')}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.phone}
        name="phone"
        error={!!(touched.phone && errors.phone)}
        className={classes.input}
        fullWidth={true}
        autoComplete="nope"
      />
      {transactionType === WITH_INVOICE_DATA_KEY && (
        <InvoiceDataStepper {...props} />
      )}
    </GridUi>
  );
};

export default ClientDataStepper;
