import { createSelector } from 'reselect';

import _Store from '@Store';

import { ITransaction } from '@Model/reservations/types';

import { ITransactionsReducer } from '../types';
import get from './get';

const getTransactions = createSelector<
  _Store.IState,
  ITransactionsReducer,
  ITransaction[]
>([get], (transactions) => transactions.transactions);

export default getTransactions;
