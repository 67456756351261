import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import {
  catchGenerateGroupReport,
  generateGroupReport,
  getSettlements,
} from '@Model/settlements/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { resetError, resetLoading, setLoading } from './../actions';
import { SETTLEMENTS } from './../constants/constants';

export const setUpStateWhenSettlementsFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getSettlements.request,
        catchGenerateGroupReport,
        generateGroupReport.request,
      ])
    ),
    mergeMap$(() => [resetError(SETTLEMENTS), setLoading(SETTLEMENTS)])
  );
};

export const setLoaderWhenSettlementsFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          getSettlements.success,
          getSettlements.failure,
          generateGroupReport.success,
          generateGroupReport.failure,
        ],
        action
      )
    ),
    map$(() => resetLoading(SETTLEMENTS))
  );
};

export const setErrorOnSettlementsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getSettlements.failure, generateGroupReport.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
