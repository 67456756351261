import { TFunction } from 'i18next';

import { RefundStatus } from '@Services/$refunds-registration-api/types';

import { IAvailabilitiesStatusChange } from './RefundsRegistration.types';

export const availabilities: IAvailabilitiesStatusChange = {
  [RefundStatus.accepted]: [RefundStatus.toRefund],
  [RefundStatus.clarify]: [RefundStatus.manualRefund],
  [RefundStatus.manualRefund]: [RefundStatus.refundDone],
  [RefundStatus.voucherToGenerate]: [RefundStatus.voucherGenerated],
};

export const statuses: RefundStatus[] = [
  RefundStatus.toRefund,
  RefundStatus.manualRefund,
  RefundStatus.refundDone,
  RefundStatus.voucherGenerated,
];

export const getAvailabilityName = (
  status: RefundStatus,
  t: TFunction
): string => {
  switch (status) {
    case RefundStatus.toRefund:
      return t('toRefund');
    case RefundStatus.manualRefund:
      return t('manualRefund');
    case RefundStatus.refundDone:
      return t('refundDone');
    case RefundStatus.voucherGenerated:
      return t('voucherGenerated');
    default:
      return '';
  }
};
