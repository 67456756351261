import { createSelector } from 'reselect';

import _Store from '@Store';
import { ISettlement, ISettlementsReducer } from './../types';
import get from './get';

const getSettlementsList = createSelector<
  _Store.IState,
  ISettlementsReducer,
  ISettlement[]
>([get], (settlement) => settlement.settlements.data);

export default getSettlementsList;
