import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  MOUNTED,
  REFRESH_LIST,
} from '../constants/actions';
import { IGetApartmentsSuccessPayload } from '../types';

export const getApartments = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE,
)<undefined, IGetApartmentsSuccessPayload, Error>();

export const refreshList = createStandardAction(REFRESH_LIST)();
export const mounted = createStandardAction(MOUNTED)();
