import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import FormLayout from './../FormLayout';
import { prepareInitialValues } from './UsersModal.helpers';
import { IUsersModalProps, IUsersModalValues } from './UsersModal.types';
import { UsersSchema } from './UsersModal.validation';

const UsersModal = ({ activeUser, handleClose, submit }: IUsersModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Users.components.UsersTable.UsersModal',
  });

  const TITLE_TEXT = activeUser?.id ? t('titleEdit') : t('titleAdd');

  const catchHandleSubmit = (values: IUsersModalValues) => {
    submit(values);
  };

  const initialValues = prepareInitialValues(null);

  return (
    <Dialog
      open={!!activeUser}
      maxWidth="md"
      fullWidth={true}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitle>{TITLE_TEXT}</DialogTitle>
      <Formik
        initialValues={activeUser || initialValues}
        onSubmit={catchHandleSubmit}
        validationSchema={UsersSchema}
        component={FormLayout}
      />
    </Dialog>
  );
};

export default UsersModal;
