import makeStyles from '@mui/styles/makeStyles';

import colors from '@Compo/App/colors';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: colors.success.light,
    marginRight: theme.spacing(2),
  },
  summaryContainer: {
    backgroundColor: theme.palette.action.focus,
  },
}));

export default useStyles;
