import config from '@Config';
import catchHttpError, {
  catchNewErrorMessage,
} from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import {
  IAvailabilitiesResponse,
  IAvailabilitiesResponseDay,
} from '@Model/happening/types';
import { IHappeningBody } from '@Model/happenings/types';
import axios, { CancelTokenSource } from 'axios';
import endOfDay from 'date-fns/endOfDay';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import { IHappeningsResponse, ISingleHappeningResponse } from './types';

class HappeningsApi {
  private static getHappeningsUrl(partnerId?: string): string {
    if (partnerId && Number(partnerId) !== -1) {
      return `${config.api.baseUrl}admin/happenings?partnerIds[]=${partnerId}`;
    }
    return `${config.api.baseUrl}admin/happenings`;
  }

  private static getSingleHappeningUrl(slug: string): string {
    return `${config.api.baseUrl}admin/happenings/${slug}`;
  }

  private static getRemoveHappeningUrl(slug: string): string {
    return `${config.api.baseUrl}admin/happenings/${slug}/delete`;
  }

  private static getAvailabilitiesUrl(slug: string): string {
    if (config.cms.showNewRules) {
      return `${config.api.baseUrlV2}admin/happenings/${slug}/availability`;
    }
    return `${config.api.baseUrl}admin/happenings/${slug}/availability`;
  }

  private cancelTokenHappenings?: CancelTokenSource;
  private cancelTokenSingleHappening?: CancelTokenSource;
  private cancelTokenUpdateSingleHappening?: CancelTokenSource;
  private cancelTokenAvailabilities?: CancelTokenSource;

  public getHappenings(partnerId?: string): Promise<IHappeningsResponse> {
    return new Promise<IHappeningsResponse>((resolve, reject) => {
      this.cancelTokenHappenings = axios.CancelToken.source();

      axios
        .get(HappeningsApi.getHappeningsUrl(partnerId), {
          cancelToken: this.cancelTokenHappenings.token,
        })
        .then(getData)
        .then((response: IHappeningsResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public removeHappening(slug: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(HappeningsApi.getRemoveHappeningUrl(slug))
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public addSingleHappening(body: IHappeningBody): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = HappeningsApi.getHappeningsUrl();
      axios
        .post(url, JSON.stringify(body))
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getSingleHappening(slug: string): Promise<ISingleHappeningResponse> {
    return new Promise<ISingleHappeningResponse>((resolve, reject) => {
      this.cancelTokenSingleHappening = axios.CancelToken.source();
      axios
        .get(HappeningsApi.getSingleHappeningUrl(slug), {
          cancelToken: this.cancelTokenSingleHappening.token,
        })
        .then(getData)
        .then((response: ISingleHappeningResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public updateSingleHappening(
    body: IHappeningBody,
    slug: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = HappeningsApi.getSingleHappeningUrl(slug);
      axios
        .put(url, JSON.stringify(body))
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public getHappeningAvailabilities(
    happeningSlug: string,
    date: Date
  ): Promise<IAvailabilitiesResponse> {
    return new Promise<IAvailabilitiesResponse>((resolve, reject) => {
      this.cancelTokenAvailabilities = axios.CancelToken.source();

      const dateFrom = format(startOfDay(date), 'yyyy-MM-dd');
      const dateTo = format(endOfDay(date), 'yyyy-MM-dd');

      return axios
        .get(HappeningsApi.getAvailabilitiesUrl(happeningSlug), {
          cancelToken: this.cancelTokenAvailabilities.token,
          params: {
            dateFrom,
            dateTo,
          },
        })
        .then(getData)
        .then((data: IAvailabilitiesResponse) => {
          resolve(data);
        })
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public normalizeAvailabilities(
    response: IAvailabilitiesResponse,
    // IAvailabilitiesResponse
    date: string
  ): IAvailabilitiesResponseDay {
    // return response.items[date];
    const { items } = response;

    return items[date];
  }

  public cancelHappenings() {
    if (this.cancelTokenHappenings) {
      this.cancelTokenHappenings.cancel();
      this.cancelTokenHappenings = undefined;
    }
  }

  public cancelSingleHappening() {
    if (this.cancelTokenSingleHappening) {
      this.cancelTokenSingleHappening.cancel();
      this.cancelTokenSingleHappening = undefined;
    }
  }

  public cancelUpdateSingleHappening() {
    if (this.cancelTokenUpdateSingleHappening) {
      this.cancelTokenUpdateSingleHappening.cancel();
      this.cancelTokenUpdateSingleHappening = undefined;
    }
  }

  public cancelAvailabilities() {
    if (this.cancelTokenAvailabilities) {
      this.cancelTokenAvailabilities.cancel();
      this.cancelTokenAvailabilities = undefined;
    }
  }
}

export default new HappeningsApi();
