import { ISeatsIoReportReducer } from '@Model/going/reportSeatsIo/types';
import { IRundate } from '@Services/$going-rundate-api/types';

export interface IReportSeatsIoFromDispatch {
  mounted: () => void;
  rundateMounted: () => void;
}

export interface IReportSeatsIoFromState {
  rundate: IRundate;
  reports: ISeatsIoReportReducer;
  isLoading: boolean;
}

export type IReportSeatsIoProps = IReportSeatsIoFromDispatch &
  IReportSeatsIoFromState;

export enum SeatStatus {
  free = 'Wolne',
  freeDisabled = 'Wolne (wyłączone ze sprzedaży)',
  taken = 'Zajęte',
}
