import _Store from '@Store';
import { createSelector } from 'reselect';
import { ICalculateMixPayment, IReservationPrintDataReducer } from './../types';
import getReservationPrintData from './getReservationPrintData';

const calculateMixPayment = createSelector<
  _Store.IState,
  IReservationPrintDataReducer,
  ICalculateMixPayment
>(getReservationPrintData, (data) => {
  let inCash = 0;
  let byCard = 0;
  let total = 0;
  let leftToPay = 0;

  data.transactionPayments?.forEach((payment) => {
    if (payment.paymentType === 'cash') {
      inCash += payment.amount;
    } else {
      byCard += payment.amount;
    }
  });

  total = inCash + byCard;
  leftToPay = data.revenue - total;

  return { inCash, byCard, total, leftToPay };
});

export default calculateMixPayment;
