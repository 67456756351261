import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';

import { IPlacesResponse } from './types';

class PlacesApi {
  private static getPlacesUrl(): string {
    return `${config.api.cmsServices}places`;
  }

  private cancelTokenPlaces?: CancelTokenSource;

  public getPlaces(
    page: number,
    count: number,
    searchText?: string
  ): Promise<IPlacesResponse> {
    return new Promise<IPlacesResponse>((resolve, reject) => {
      this.cancelTokenPlaces = axios.CancelToken.source();

      axios
        .get(PlacesApi.getPlacesUrl(), {
          cancelToken: this.cancelTokenPlaces.token,
          params: {
            count,
            'filter[search]': searchText,
            page,
          },
        })
        .then(getData)
        .then((data) => resolve({ data: data.data, meta: data.meta }))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelPlaces() {
    if (this.cancelTokenPlaces) {
      this.cancelTokenPlaces.cancel();
      this.cancelTokenPlaces = undefined;
    }
  }
}

export default new PlacesApi();
