export const MOUNTED = 'Zagrywki/seatsIo/MOUNTED';
export const FETCH_CATEGORIES = 'Zagrywki/seatsIo/FETCH_CATEGORIES';
export const SAVE_CHART_KEY = 'Zagrywki/seatsIo/SAVE_CHART_KEY';
export const DELETE_CHART_KEY = 'Zagrywki/seatsIo/DELETE_CHART_KEY';
export const CHANGE_META_DATA = 'Zagrywki/seatsIo/CHANGE_META_DATA';

export const _GET_SEATS_CHARTS_REQUEST =
  'Zagrywki/seatsIo/_GET_SEATS_CHARTS_REQUEST';
export const _GET_SEATS_CHARTS_SUCCESS =
  'Zagrywki/seatsIo/_GET_SEATS_CHARTS_SUCCESS';
export const _GET_SEATS_CHARTS_FAILURE =
  'Zagrywki/seatsIo/_GET_SEATS_CHARTS_FAILURE';

export const _GET_SEATS_CATEGORIES_REQUEST =
  'Zagrywki/seatsIo/_GET_SEATS_CATEGORIES_REQUEST';
export const _GET_SEATS_CATEGORIES_SUCCESS =
  'Zagrywki/seatsIo/_GET_SEATS_CATEGORIES_SUCCESS';
export const _GET_SEATS_CATEGORIES_FAILURE =
  'Zagrywki/seatsIo/_GET_SEATS_CATEGORIES_FAILURE';

export const _SAVE_SEAT_CHART_REQUEST =
  'Zagrywki/seatsIo/_SAVE_SEAT_CHART_REQUEST';
export const _SAVE_SEAT_CHART_SUCCESS =
  'Zagrywki/seatsIo/_SAVE_SEAT_CHART_SUCCESS';
export const _SAVE_SEAT_CHART_FAILURE =
  'Zagrywki/seatsIo/_SAVE_SEAT_CHART_FAILURE';

export const _DELETE_SEAT_CHART_REQUEST =
  'Zagrywki/seatsIo/_DELETE_SEAT_CHART_REQUEST';
export const _DELETE_SEAT_CHART_SUCCESS =
  'Zagrywki/seatsIo/_DELETE_SEAT_CHART_SUCCESS';
export const _DELETE_SEAT_CHART_FAILURE =
  'Zagrywki/seatsIo/_DELETE_SEAT_CHART_FAILURE';
