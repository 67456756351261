import React, { useRef } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  daysToReturn,
  formatDate,
  PLZlotyLocale,
} from '@Compo/RefundsRegistration/RefundsRegistration.helpers';
import ListItemWithLabel from '@Compo/reusable/ListItemWithLabel';
import { IRefundsRegistration } from '@Services/$refunds-registration-api/types';

import FormLayout from './../FormLayout';
import { IRefundsRegistrationModalProps } from './RefundsRegistrationModal.types';

const TITLE_TEXT = 'Szczegóły zwrotu';

const TRANSACTION_ID_TEXT = 'ID transakcji';
const NAME_TEXT = 'Imię i nazwisko';
const EMAIL_TEXT = 'E-mail';
const EVENT_TEXT = 'Wydarzenie';
const ENTRY_TOKEN_TEXT = 'Kod biletu';
const AMOUNT_TEXT = 'Kwota';
const PURCHASE_DATE_TEXT = 'Data zamówienia';
const INIT_DATE_TEXT = 'Data zgłoszenia';
const DAYS_FOR_RETURN_TEXT = 'Dni na zwrot';
const ADDITIONAL_INFO_TEXT = 'Dodatkowe informacje';
const DAYS_TO_RETURN_TEXT = 'Dni do zwrotu';
const IS_USED_VOUCHER_TEXT = 'Użycie vouchera';
const IS_SPLIT_TEXT = 'Płatne przekazanie';
const PAYU_STATUS_TEXT = 'Status PayU';

const CANCEL_TEXT = 'Anuluj';
const SAVE_TEXT = 'Zapisz';

const RefundsRegistrationModal = ({
  activeRefundsRegistration,
  handleClose,
  submit,
}: IRefundsRegistrationModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'RefundsRegistration.components.RefundsRegistrationModal',
  });

  const formRef = useRef<Formik<IRefundsRegistration>>(null);

  if (!activeRefundsRegistration?.ticket) {
    return null;
  }

  const { initDate } = activeRefundsRegistration;

  const { transactionItem, firstname, lastname, email, entryToken } =
    activeRefundsRegistration.ticket;

  const changeMonitor =
    activeRefundsRegistration.changeMonitor ||
    transactionItem?.pool.rundate?.lastChangeMonitor;

  return (
    <Dialog
      open={!!activeRefundsRegistration}
      maxWidth="md"
      fullWidth={true}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <ListItemWithLabel
              label={t('transactionId')}
              value={transactionItem?.transaction.id}
            />
            <ListItemWithLabel
              label={t('name')}
              value={firstname + ' ' + lastname}
            />
            <ListItemWithLabel label={t('email')} value={email} />
            <ListItemWithLabel
              label={t('event')}
              value={[
                transactionItem?.pool.rundate.event.titlePL + ',',
                formatDate(transactionItem?.pool.rundate.rundate) + ',',
                transactionItem?.pool.rundate.event.place?.city?.name || '',
              ]}
            />
            <ListItemWithLabel label={t('entryToken')} value={entryToken} />
            <ListItemWithLabel
              label={t('amount')}
              value={
                transactionItem?.amount !== undefined &&
                transactionItem?.serviceFee !== undefined
                  ? PLZlotyLocale.format(transactionItem?.amount / 100) +
                    ' + ' +
                    PLZlotyLocale.format(transactionItem?.serviceFee / 100)
                  : null
              }
            />
            <ListItemWithLabel
              label={t('purchaseDate')}
              value={formatDate(
                transactionItem?.transaction.purchaseTime,
                true
              )}
            />
            <ListItemWithLabel
              label={t('initDate')}
              value={formatDate(initDate)}
            />
            <ListItemWithLabel
              label={t('daysForReturn')}
              value={changeMonitor?.refundMaxTime}
            />
            <ListItemWithLabel
              label={t('additionalInfo')}
              value={changeMonitor?.info}
            />
            <ListItemWithLabel
              label={t('daysToReturn')}
              value={
                activeRefundsRegistration &&
                daysToReturn(activeRefundsRegistration)
              }
            />
            <ListItemWithLabel
              label={t('isUsedVoucher')}
              value={transactionItem?.isUsedVoucher}
            />
            <ListItemWithLabel
              label={t('isSplit')}
              value={transactionItem?.isSplit}
            />
            <ListItemWithLabel
              label={t('payuStatus')}
              value={activeRefundsRegistration?.payuStatus}
            />
          </Grid>
          <Grid item={true} xs={12}>
            {!!activeRefundsRegistration && (
              <Formik
                initialValues={activeRefundsRegistration}
                onSubmit={(values) => submit(values)}
                component={FormLayout}
                ref={formRef}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="outlined"
          size="large"
          color="secondary"
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={() => formRef?.current?.submitForm()}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RefundsRegistrationModal;
