import { createSelector } from 'reselect';

import _Store from '@Store';

import { IConsentsList, ISingleUserResponse } from '../types';
import getFormIoConsentsList from './getFormIoConsentsList';

const getFormIoSelectedUser = createSelector<
  _Store.IState,
  IConsentsList,
  ISingleUserResponse | null
>([getFormIoConsentsList], (state) => state.selectedUser);

export default getFormIoSelectedUser;
