const api = {
  awsSearchUrl: process.env.REACT_APP_AMAZON_SEARCH_API_URL,
  baseEmpikTicketUrl: process.env.REACT_APP_EMPIK_API_URL,
  baseInvoiceUrl: process.env.REACT_APP_INVOICE_API_URL,
  baseUrl: process.env.REACT_APP_API_URL,
  baseUrlV2: process.env.REACT_APP_API_URL_V2,
  clientAppUrl: process.env.REACT_APP_CLIENT_APP_URL,
  cmsServices: process.env.REACT_APP_CMS_SERVICES_URL,
  formioAuthToken: process.env.REACT_APP_FORMIO_BACKEND_API_KEY,
  formioUrl: process.env.REACT_APP_FORMIO_URL,
  saleSessionApi: process.env.REACT_APP_SALE_SESSION_URL,
  saleSessionAuthToken: process.env.REACT_APP_SALE_SESSION_AUTH_TOKEN,
};

export default api;
