import axios, { AxiosResponse } from 'axios';

class RequestProvider {
  public setAuthorizationHeader(token: string) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  public removeAuthorizationHeader() {
    axios.defaults.headers.common.Authorization = null;
  }

  public checkAutorotation(): Promise<Error> {
    return new Promise<Error>((resolve, reject) => {
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          resolve(error);
          return Promise.reject(error);
        },
      );
    });
  }
}

export default new RequestProvider();
