import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = ({
  handleSubmit,
  handleBlur,
  handleChange,
  touched,
  errors,
  values,
  submitForm,
  handleClose,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'Users.components.UsersTable.ChangePasswordModal.components.FormLayout',
  });

  const classes = useStyles();

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={5}>
            <Grid item={true} xs={12}>
              <TextField
                id="password"
                label={(touched.password && errors.password) || t('password')}
                helperText={t('passwordHelper')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                name="password"
                type="password"
                error={!!(touched.password && errors.password)}
                fullWidth={true}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                id="repeatPassword"
                label={
                  (touched.repeatPassword && errors.repeatPassword) ||
                  t('repeatPassword')
                }
                helperText={t('repeatPasswordHelper')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.repeatPassword}
                name="repeatPassword"
                error={!!(touched.repeatPassword && errors.repeatPassword)}
                fullWidth={true}
                type="password"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
