import { object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const REQUIRED_PICTURE_VALIDATOR_TEXT = 'Grafika jest wymagana.';

const configuration = {
  buttonLink: string()
    .nullable()
    .when('buttonText', {
      is: (buttonText) => buttonText,
      otherwise: string().nullable(),
      then: string().required(REQUIRED_VALIDATOR_TEXT),
    }),
  buttonText: string()
    .nullable()
    .when('buttonLink', {
      is: (buttonLink) => buttonLink,
      otherwise: string().nullable(),
      then: string().required(REQUIRED_VALIDATOR_TEXT),
    }),
  description: string().required(REQUIRED_VALIDATOR_TEXT),
  picture: string().required(REQUIRED_PICTURE_VALIDATOR_TEXT),
  startDate: string()
    .nullable()
    .when('mailShipment', {
      is: true,
      then: string().required(REQUIRED_VALIDATOR_TEXT),
    }),
  startTime: string()
    .nullable()
    .when('mailShipment', {
      is: true,
      then: string().required(REQUIRED_VALIDATOR_TEXT),
    }),
  title: string().required(REQUIRED_VALIDATOR_TEXT),
};

export const MailSchema = object().shape(configuration, [
  ['buttonText', 'buttonLink'],
  ['buttonLink', 'buttonText'],
]);
