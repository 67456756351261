import { array, boolean, number, object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane.';

const settlement = {
  endDate: string()
    .nullable()
    .required(REQUIRED_VALIDATOR_TEXT),
  freeTickets: boolean(),
  limit: number(),
  rundateIds: array(number()),
  salesChannelId: number(),
  settlementTypeId: number().required(REQUIRED_VALIDATOR_TEXT),
  startDate: string()
    .nullable()
    .required(REQUIRED_VALIDATOR_TEXT),
  transactionType: string(),
};

export const SettlementSchema = object().shape(settlement);
