import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
  addNewConfiguration,
  catchDeleteConfiguration,
  getConfiguration,
  handleActiveConfiguration,
  handleConfigurationsActivePage,
  handleConfigurationsRowsPerPage,
  handleRuleTypeParam,
  handleSearchParam,
  mounted,
} from '@Model/configurations/actions';
import {
  get as getConfigurations,
  getNormalizedConfigurations,
} from '@Model/configurations/selectors';
import { IConfiguration, TRuleType } from '@Model/configurations/types';
import { CONFIGURATIONS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import DefinedConfigurations from './DefinedConfigurations.component';
import {
  IDefinedConfigurationsFromDispatch,
  IDefinedConfigurationsFromState,
} from './DefinedConfigurations.types';

const mapStateToProps = (
  state: _Store.IState
): IDefinedConfigurationsFromState => {
  const {
    activePage,
    perPage,
    searchParam,
    selectedRuleType,
    totalCount,
    items: configurations,
  } = getConfigurations(state);

  return {
    activePage,
    configurations,
    isLoading: getLoading(CONFIGURATIONS)(state),
    normalizedConfigurations: getNormalizedConfigurations(state),
    perPage,
    searchParam,
    selectedRuleType,
    totalCount,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IDefinedConfigurationsFromDispatch => {
  return {
    addNewConfiguration: () => {
      dispatch(addNewConfiguration());
    },
    getConfiguration: () => {
      dispatch(getConfiguration());
    },
    handleActiveConfiguration: (configurations: IConfiguration) => {
      dispatch(handleActiveConfiguration(configurations));
    },
    handleActivePage: (e, page) =>
      dispatch(handleConfigurationsActivePage(page)),
    handleDeleteConfiguration: (type, id) =>
      dispatch(catchDeleteConfiguration({ type, id })),
    handleRowsPerPage: (rowsPerPage) => {
      dispatch(handleConfigurationsRowsPerPage(rowsPerPage));
    },
    handleRuleTypeParam: (searchParam: TRuleType) => {
      dispatch(handleRuleTypeParam(searchParam));
    },

    handleSearchParam: (searchParam: string) => {
      dispatch(handleSearchParam(searchParam));
    },
    mounted: () => {
      dispatch(mounted());
    },
  };
};

export default connect<
  IDefinedConfigurationsFromState,
  IDefinedConfigurationsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(DefinedConfigurations);
