import { createSelector } from 'reselect';

import { IModalReducer } from '@Model/modal/types';
import _Store from '@Store';
import get from './get';

const getSuccessMessageModal = createSelector<
  _Store.IState,
  IModalReducer,
  string | undefined
>([get], (modal) => modal.message);

export default getSuccessMessageModal;
