import { createSelector } from 'reselect';

import _Store from '@Store';

import { ITicketsListSuccessPayload, ITicketsReducer } from '../types';
import getTickets from './getTickets';

const getTicketsList = createSelector<
  _Store.IState,
  ITicketsReducer,
  ITicketsListSuccessPayload
>([getTickets], (tickets) => tickets.ticketsList);

export default getTicketsList;
