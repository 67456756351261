import { object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';

const configuration = {
  description: string(),
  title: string().required(REQUIRED_VALIDATOR_TEXT),
};

export const AddPriceTypeSchema = object().shape(configuration);
