import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { isReservationDetailsSelected } from '@Model/reservations/selectors';
import { searchTicket } from '@Model/tickets/actions';
import _Store from '@Store';
import FindTickets from './FindTickets.component';
import {
  IFindTicketsFromDispatch,
  IFindTicketsFromState,
} from './FindTickets.types';

const mapStateToProps = (state: _Store.IState): IFindTicketsFromState => {
  return {
    isReservationSelected: isReservationDetailsSelected(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFindTicketsFromDispatch => {
  return {
    searchTicket: (code, deletionMode) =>
      dispatch(searchTicket({ code, deletionMode })),
  };
};

export default connect<
  IFindTicketsFromState,
  IFindTicketsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FindTickets);
