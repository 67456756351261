import React, { useEffect } from 'react';

import Routes from '@/routes';
import CssBaseline from '@mui/material/CssBaseline';
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import { ThemeProvider as ThemeProviderLegacy } from '@mui/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import 'moment/locale/pl';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-simple-keyboard/build/css/index.css';

import ElectronKeyboard from '@Compo/ElectronKeyboard';
import Resizer from '@Compo/functional/Resizer';
import SuccessModal from '@Compo/reusable/SuccessModal';
import Toasts from '@Compo/Toasts';
import '@Misc/styles/global.module.scss';
import FirebaseApi from '@Services/$firebase-api';

import { BagStore } from '../Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import LogoutDialog from './components/LogoutDialog';
import theme from './theme';

declare module '@mui/styles/defaultTheme' {
  // tslint:disable: no-empty-interface
  // tslint:disable: interface-name
  interface DefaultTheme extends Theme {}
}

moment.locale('pl');

const App = () => {
  useEffect(() => {
    FirebaseApi.initializeApp();
  }, []);

  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <ThemeProviderLegacy theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <BagStore>
              <LogoutDialog />
              <Resizer />
              <Toasts />
              <SuccessModal />
              <Routes />
              <CssBaseline />
              <ElectronKeyboard />
            </BagStore>
          </LocalizationProvider>
        </ThemeProviderLegacy>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
