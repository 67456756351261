import { ISeat } from '@Services/$tickets-api/types';

export interface ITicketFormFromState {
  initialValues: ITicketValues | null;
}

export interface ITicketFormFromDispatch {
  save: (values: ITicketValues) => void;
}

export interface ITicketValues {
  id?: number;
  ticketListId: number;
  firstname: string;
  lastname: string;
  email: string;
  ticketsNum: number | null;
  seats?: ISeat[];
  languageId: Languages;
  sendMail?: boolean;
  title?: string | null;
  mailTemplate?: string | null;
  pdfTemplate?: string | null;
  isSeats?: boolean;
  isMultiple?: boolean;
  invitationsNumber?: number | null;
}

export enum Languages {
  Polish = 1,
  English,
}

export type ITicketFormProps = ITicketFormFromDispatch & ITicketFormFromState;
