import { array, boolean, number, object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const PRICE_TO_LOW = 'Wartość za niska';
const DURATION_VALIDATOR_TEXT = 'Koniec musi być większy niż początek';
const MAX_VALIDATOR_TEXT = 'Maksymalna wartość dla tego pola to 100.';
const NOT_NUMBER_VALIDATOR_TEXT = 'Podaj liczbę';

const configuration = {
  addNewConfiguration: boolean(),
  closed: boolean(),
  duration: number().min(0, DURATION_VALIDATOR_TEXT),

  price: number().when('closed', {
    is: false,
    then: number().min(0, PRICE_TO_LOW).required(REQUIRED_VALIDATOR_TEXT),
  }),

  upsellValue: number().when('isUpsell', {
    is: true,
    then: number()
      .when('upsellType', {
        is: 1,
        then: number()
          .max(100, MAX_VALIDATOR_TEXT)
          .required(REQUIRED_VALIDATOR_TEXT),
      })
      .min(0, PRICE_TO_LOW)
      .required(REQUIRED_VALIDATOR_TEXT),
  }),

  isPeopleCount: boolean(),
  perPersonType: string(),
  priceGoesUp: string(),

  fromNumberOfPeople: number().when('isPeopleCount', {
    is: true,
    then: number().min(0, PRICE_TO_LOW).required(REQUIRED_VALIDATOR_TEXT),
  }),

  perPersonValue: number().when('isPeopleCount', {
    is: true,
    then: number()
      .when('perPersonType', {
        is: 'percentageValue',
        then: number()
          .max(100, MAX_VALIDATOR_TEXT)
          .required(REQUIRED_VALIDATOR_TEXT),
      })
      .min(0, PRICE_TO_LOW)
      .required(REQUIRED_VALIDATOR_TEXT),
  }),

  durationAdvanced: string().when('advanced', {
    is: true,
    then: string().required(REQUIRED_VALIDATOR_TEXT),
  }),
  ruleAdvanced: string().when('advanced', {
    is: true,
    then: string().required(REQUIRED_VALIDATOR_TEXT),
  }),

  upsellExtended: array().when('isExtendedUpsell', {
    is: true,
    then: array()
      .of(
        object().shape({
          percentageValue: number()
            .nullable()
            .min(0, PRICE_TO_LOW)
            .max(100, MAX_VALIDATOR_TEXT)
            .typeError(NOT_NUMBER_VALIDATOR_TEXT),
          slotIndex: number(),
          value: number()
            .nullable()
            .min(0, PRICE_TO_LOW)
            .typeError(NOT_NUMBER_VALIDATOR_TEXT),
        })
      )
      .required(REQUIRED_VALIDATOR_TEXT),
  }),
};

export const AddingConfigurationSchema = object().shape(configuration);
