import routes from '@/routes/routes';
import {
  createMatchSelector,
  getLocation,
  LOCATION_CHANGE,
  push,
  RouterRootState,
} from 'connected-react-router';
import moment from 'moment';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getInitialValues } from '@Compo/Rundates/Pools/components/PoolsForm/PoolsForm.helpers';
import { IPoolsFormValues } from '@Compo/Rundates/Pools/components/PoolsForm/PoolsForm.types';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { showSuccessModal } from '@Model/modal/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_SUCCESS } from '@Model/toasts/constants/constants';
import {
  IGetRundatesSuccessPayload,
  IGetSingleRundateSuccessPayload,
} from '@Services/$going-rundate-api/types';

import {
  changeFilter,
  changePageOrSize,
  changeRundateHasExternalImage,
  changeRundateMonitorType,
  changeRundateMonitorTypeStatus,
  changeRundateSale,
  changeRundateSaleStatus,
  changeRundateVisibility,
  changeRundateVisibilityStatus,
  checkExternalTickets,
  checkExternalTicketsList,
  clearFilter,
  deletePool,
  deleteRundatePool,
  edit,
  fetchPoolDescriptions,
  fetchReorderPools,
  fetchSinglePool,
  generateExampleTicket,
  getExampleTicket,
  getMonitorTypes,
  getPoolDescriptions,
  getRundate,
  getRundatePools,
  getRundates,
  getRundateStats,
  getSingleEvent,
  getSinglePool,
  getTicketsList,
  mounted,
  reorderPools,
  resetExternalTickets,
  save,
  saveChangeRundateHasExternalImage,
  saveExternalTickets,
  saveExternalTicketsList,
  savePool,
  saveRundatePool,
  search,
  selectPool,
} from '../actions';
import {
  getRundatesFilter,
  getRundatesPage,
  getRundatesSize,
  getSingleRundate,
} from '../selectors';
import { resetActiveRundate } from './../actions/index';

const RUNDATE_SAVED_TEXT = 'Zapisano zmiany';

export const fetchRundatesWhenMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf([mounted])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { eventId: string }
      >(routes.addRundate);
      const match = matchSelector(state);
      if (match) {
        const { eventId } = match.params;
        return of$(getSingleEvent.request(Number(eventId)));
      }
      return of$(getRundates.request(undefined), getMonitorTypes.request());
    })
  );
};

export const fetchRundatesWhenPayloadChanged: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([changePageOrSize, search, changeFilter, clearFilter])),
    mergeMap$((action) => {
      return of$(
        getRundates.request('payload' in action ? action.payload : undefined)
      );
    })
  );
};

export const fetchPoolsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf([mounted, getRundatePools.request])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;

        return from$(goingRundateApi.getRundatePools(Number(id))).pipe(
          map$((data) => {
            return getRundatePools.success(data);
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          ),
          catchError$((error: Error) => {
            return of$(getRundatePools.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const fetchSingleEventWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { eventsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSingleEvent.request)),
    mergeMap$((action) => {
      return from$(eventsApi.getSingleEvent(action.payload)).pipe(
        map$((data) => {
          return getSingleEvent.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => eventsApi.cancelEvents())
          )
        ),
        catchError$((error: Error) => {
          return of$(getSingleEvent.failure(error));
        })
      );
    })
  );
};

export const requestRundatesWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getRundates.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      const filter = action.payload?.filter || getRundatesFilter(state);
      const page = action.payload?.page || getRundatesPage(state);
      const size = action.payload?.size || getRundatesSize(state);

      if (partnerId) {
        return from$(
          goingRundateApi.getRundates(partnerId, filter, page, size)
        ).pipe(
          map$((data: IGetRundatesSuccessPayload) => {
            return getRundates.success(data);
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          ),
          catchError$((error: Error) => {
            return of$(getRundates.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const requestRundateWhenEditFormMounted: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf([mounted, getRundate.request])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >([routes.rundateEdit, routes.rundateSeatsIoReport]);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;

        return from$(goingRundateApi.getRundate(Number(id))).pipe(
          mergeMap$((data: IGetSingleRundateSuccessPayload) => {
            return of$(getRundate.success(data), getRundateStats.request());
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          ),
          catchError$((error: Error) => {
            return of$(getRundate.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const getRundateStatsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getRundateStats.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >([routes.rundateEdit]);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;

        return from$(goingRundateApi.getRundateStats(Number(id))).pipe(
          map$((data) => {
            return getRundateStats.success(data);
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          ),
          catchError$((error: Error) => {
            return of$(getRundateStats.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const editRundateWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(edit)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;

        return from$(
          goingRundateApi.editRundate(Number(id), action.payload)
        ).pipe(
          mergeMap$(() => [
            addToast(RUNDATE_SAVED_TEXT, TYPE_SUCCESS),
            getRundateStats.request(),
            getRundate.request(),
          ]),
          catchError$((error: Error) => {
            return of$(getRundate.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const saveRundateWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(save)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      if (partnerId) {
        return from$(
          goingRundateApi.saveRundate(action.payload, partnerId)
        ).pipe(
          mergeMap$((data) =>
            of$(
              addToast(RUNDATE_SAVED_TEXT, TYPE_SUCCESS),
              push(
                fillUrlWithValues(routes.rundateEdit, ':id', String(data.id))
              )
            )
          ),
          catchError$((error: Error) => {
            return of$(getRundate.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const deleteRundatePoolWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(deletePool)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;

        return from$(
          goingRundateApi.deleteRundatePool(Number(id), action.payload)
        ).pipe(
          mergeMap$(() => {
            return of$(
              addToast(RUNDATE_SAVED_TEXT, TYPE_SUCCESS),
              getRundatePools.request(),
              getRundateStats.request()
            );
          }),
          catchError$((error: Error) => {
            return of$(deleteRundatePool.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const fetchSinglePoolWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(fetchSinglePool)),
    mergeMap$((action) => {
      return of$(getSinglePool.request(action.payload));
    })
  );
};

export const getSinglePoolWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSinglePool.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;

        return from$(
          goingRundateApi.getSingleRundatePool(Number(id), action.payload)
        ).pipe(
          mergeMap$((response) => {
            return of$(selectPool(getInitialValues(response)));
          }),
          catchError$((error: Error) => {
            return of$(getSinglePool.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const savePoolWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(savePool)),
    mergeMap$((action) => {
      return of$(saveRundatePool.request(action.payload));
    })
  );
};

export const saveRundatePoolWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveRundatePool.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;

        const endDate =
          action.payload.endDate && action.payload.endTime
            ? moment(action.payload.endDate).format('YYYY-MM-DD') +
              moment(action.payload.endTime, 'HH:mm').format('HH:mm:ss')
            : action.payload.endDate
            ? moment(action.payload.endDate).format('YYYY-MM-DD')
            : null;

        const startDate =
          action.payload.startDate && action.payload.startTime
            ? moment(action.payload.startDate).format('YYYY-MM-DD') +
              moment(action.payload.startTime, 'HH:mm').format('HH:mm:ss')
            : action.payload.startDate
            ? moment(action.payload.startDate).format('YYYY-MM-DD')
            : null;

        const poolPayload: IPoolsFormValues = {
          ...action.payload,

          emissionSize: action.payload.emissionSize
            ? Number(action.payload.emissionSize)
            : null,
          endDate,
          parentId:
            action.payload.parentId !== -1 ? action.payload.parentId : null,
          poolDescriptionId:
            action.payload.poolDescriptionId !== -1
              ? action.payload.poolDescriptionId
              : null,
          poolGroupEN: action.payload.poolGroupEN
            ? action.payload.poolGroupEN
            : undefined,
          poolGroupPL: action.payload.poolGroupPL
            ? action.payload.poolGroupPL
            : undefined,
          price: Number(action.payload.price),
          seatsIoCategoryKey:
            action.payload.emissionSize && action.payload.seatsIoCategoryKey
              ? Number(action.payload.seatsIoCategoryKey)
              : null,
          startDate,
        };

        delete poolPayload.endTime;
        delete poolPayload.startTime;

        return from$(goingRundateApi.savePool(poolPayload, Number(id))).pipe(
          mergeMap$((response) => {
            return of$(
              saveRundatePool.success(response),
              showSuccessModal(),
              selectPool(null),
              getRundatePools.request(),
              getRundateStats.request()
            );
          }),
          catchError$((error: Error) => {
            return of$(saveRundatePool.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const fetchPoolDescriptionsWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(fetchPoolDescriptions)),
    mergeMap$((action) => {
      return of$(getPoolDescriptions.request(action.payload));
    })
  );
};

export const getPoolDescriptionsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPoolDescriptions.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);

      if (partnerId) {
        return from$(
          goingRundateApi.getPoolDescriptions(partnerId, action.payload)
        ).pipe(
          mergeMap$((response) => {
            return of$(getPoolDescriptions.success(response));
          }),
          catchError$((error: Error) => {
            return of$(getPoolDescriptions.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const generateExampleTicketWhenAction: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(generateExampleTicket)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      const { ticket, poolId } = action.payload;
      return from$(goingRundateApi.getExampleTicket(poolId, ticket)).pipe(
        mergeMap$((data) => {
          const file = new Blob([data.data]);
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = data.fileName;
          link.click();

          return of$(getExampleTicket.success(data.data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => goingRundateApi.cancelEvents())
          )
        ),
        catchError$((error: Error) => {
          return of$(getExampleTicket.failure(error));
        })
      );
    })
  );
};

export const reorderPoolsWhenAction: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(fetchReorderPools)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;

        return from$(
          goingRundateApi.reorderPools(Number(id), action.payload)
        ).pipe(
          mergeMap$((data) => {
            return of$(reorderPools.success(data), showSuccessModal());
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          ),
          catchError$((error: Error) => {
            return of$(reorderPools.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const changeRundateSaleWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(changeRundateSale)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;

        return from$(
          goingRundateApi.changeSaleStatus(Number(id), action.payload)
        ).pipe(
          mergeMap$((data) => {
            return of$(
              changeRundateSaleStatus.success(data),
              showSuccessModal(),
              mounted()
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          ),
          catchError$((error: Error) => {
            return of$(changeRundateSaleStatus.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const changeRundateVisibilityWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(changeRundateVisibility)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;

        return from$(
          goingRundateApi.changeVisibilityStatus(Number(id), action.payload)
        ).pipe(
          mergeMap$((data) => {
            return of$(
              changeRundateVisibilityStatus.success(data),
              showSuccessModal(),
              mounted()
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          ),
          catchError$((error: Error) => {
            return of$(changeRundateVisibilityStatus.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const changeRundateHasExternalImageWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(changeRundateHasExternalImage)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;

        return from$(
          goingRundateApi.changeRundateHasExternalImage(
            Number(id),
            action.payload
          )
        ).pipe(
          mergeMap$((data) => {
            return of$(saveChangeRundateHasExternalImage.success(data));
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          ),
          catchError$((error: Error) => {
            return of$(saveChangeRundateHasExternalImage.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const changeRundateMonitorTypeWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(changeRundateMonitorType)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;

        return from$(
          goingRundateApi.changeMonitorType(Number(id), action.payload)
        ).pipe(
          mergeMap$((data) => {
            return of$(
              changeRundateMonitorTypeStatus.success(data),
              showSuccessModal(),
              mounted()
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          ),
          catchError$((error: Error) => {
            return of$(changeRundateMonitorTypeStatus.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const fetchMonitorTypesWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getMonitorTypes.request)),
    withLatestFrom$(state$),
    mergeMap$(() => {
      return from$(goingRundateApi.getMonitorTypes()).pipe(
        mergeMap$((data) => {
          return of$(getMonitorTypes.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => goingRundateApi.cancelEvents())
          )
        ),
        catchError$((error: Error) => {
          return of$(getMonitorTypes.failure(error));
        })
      );
    })
  );
};

export const resetActiveRundateWhenLocationChange: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        getLocation(state).pathname.split('/')[1] !==
          routes.rundateEdit.split('/')[1] &&
        getSingleRundate(state).data.id !== 0
    ),
    map$(() => resetActiveRundate())
  );
};

export const submitCheckExternalTicketLists: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(checkExternalTickets)),
    map$((action) => checkExternalTicketsList.request(action.payload))
  );
};

export const checkExternalTicketListsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(checkExternalTicketsList.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);
      if (match && match.params) {
        const { id } = match.params;
        const { name, entryTokens } = action.payload;
        return from$(
          goingRundateApi.checkExternalTicketList(Number(id), name, entryTokens)
        ).pipe(
          mergeMap$((data) => {
            return of$(checkExternalTicketsList.success(data));
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          ),
          catchError$((error: Error) => {
            return of$(checkExternalTicketsList.failure(error));
          })
        );
      }
      return EMPTY$;
    })
  );
};

export const submitSaveExternalTicketLists: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(saveExternalTickets)),
    map$((action) => saveExternalTicketsList.request(action.payload))
  );
};

export const saveExternalTicketListsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveExternalTicketsList.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);
      if (match && match.params) {
        const { id } = match.params;
        const { name, entryTokens, force } = action.payload;
        return from$(
          goingRundateApi.saveExternalTicketList(
            Number(id),
            name,
            entryTokens,
            force
          )
        ).pipe(
          mergeMap$((data) => {
            return of$(
              saveExternalTicketsList.success(data),
              resetExternalTickets(),
              showSuccessModal(),
              getTicketsList.request(Number(id))
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          ),
          catchError$((error: Error) => {
            return of$(saveExternalTicketsList.failure(error));
          })
        );
      }
      return EMPTY$;
    })
  );
};
