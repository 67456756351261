import { getType } from 'typesafe-actions';

import {
  getGroups,
  getPartners,
  getPermissions,
  getUsers,
  handleActiveFilter,
  handleActiveGroup,
  handleActivePageGroups,
  handleActivePageUsers,
  handleActiveSizeGroups,
  handleActiveSizeUsers,
  handleActiveUser,
  handleSearchGroups,
  handleSearchUsers,
} from '../actions';
import { IAction, IUsersReducer } from '../types';

const initialState: IUsersReducer = {
  activeGroup: null,
  activeUser: null,
  groups: [],
  meta: {
    groups: {
      page: 1,
      size: 20,
      total: 0,
    },
    users: {
      page: 1,
      size: 20,
      total: 0,
    },
  },
  partners: [],
  permissions: [],
  search: {
    groups: '',
    users: '',
  },
  users: [],
};

const usersReducer = (
  state: IUsersReducer = initialState,
  action: IAction
): IUsersReducer => {
  switch (action.type) {
    case getType(handleActiveUser):
      return {
        ...state,
        activeUser: action.payload,
      };
    case getType(getUsers.success):
      return {
        ...state,
        meta: { ...state.meta, users: action.payload.meta },
        users: action.payload.data,
      };
    case getType(handleActivePageUsers):
      return {
        ...state,
        meta: {
          ...state.meta,
          users: {
            ...state.meta.users,
            page: action.payload,
          },
        },
      };
    case getType(handleActiveSizeUsers):
      return {
        ...state,
        meta: {
          ...state.meta,
          users: {
            ...state.meta.users,
            size: action.payload,
          },
        },
      };
    case getType(handleActiveGroup):
      return {
        ...state,
        activeGroup: action.payload,
      };
    case getType(getGroups.success):
      return {
        ...state,
        groups: action.payload.data,
        meta: { ...state.meta, groups: action.payload.meta },
      };
    case getType(getPartners.success):
      return {
        ...state,
        partners: action.payload,
      };
    case getType(handleActivePageGroups):
      return {
        ...state,
        meta: {
          ...state.meta,
          groups: {
            ...state.meta.groups,
            page: action.payload,
          },
        },
      };
    case getType(handleActiveSizeGroups):
      return {
        ...state,
        meta: {
          ...state.meta,
          groups: {
            ...state.meta.groups,
            size: action.payload,
          },
        },
      };
    case getType(handleSearchGroups):
      return {
        ...state,
        search: {
          ...state.search,
          groups: action.payload,
        },
      };
    case getType(handleSearchUsers):
      return {
        ...state,
        search: {
          ...state.search,
          users: action.payload,
        },
      };
    case getType(getPermissions.success):
      return {
        ...state,
        permissions: action.payload.data,
      };
    case getType(handleActiveFilter):
      return {
        ...state,
        activeFilter: action.payload,
      };
    default:
      return state;
  }
};

export default usersReducer;
