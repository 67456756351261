import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import Users from './Users.component';
import { IUsersFromDispatch, IUsersFromState } from './Users.types';

const mapStateToProps = (state: _Store.IState): IUsersFromState => ({});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IUsersFromDispatch => ({});

export default connect<IUsersFromState, IUsersFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Users);
