import { createSelector } from 'reselect';

import _Store from '@Store';

import { IUsersModalValues } from '@Compo/Users/components/UsersTable/components/UsersModal/UsersModal.types';

import { IUsersReducer } from '../types';
import get from './get';

const getActiveUser = createSelector<
  _Store.IState,
  IUsersReducer,
  IUsersModalValues | null
>([get], (users) => users.activeUser);

export default getActiveUser;
