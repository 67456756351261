import { IMeta } from '@Services/$tickets-api/types';
import _Store from '@Store';
import { createSelector } from 'reselect';
import { ITicketsReducer } from '../types';
import get from './get';

const getMeta = createSelector<_Store.IState, ITicketsReducer, IMeta>(
  [get],
  (tickets) => tickets.meta
);

export default getMeta;
