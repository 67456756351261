import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { Alert, Grid } from 'tabler-react';

import { ICheckingProps } from '@Compo/Checking/Checking.types';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';

import Loader from '../layout/Loader/Loader';
import styles from './Checking.module.scss';
import Form from './components/Form';

const TITLE_TEXT = 'Sprzedaż';

class Checking extends Component<ICheckingProps> {
  public componentWillUnmount() {
    this.props.transactionReset();
  }

  public render() {
    if (this.props.transaction.payment) {
      return <Form payment={this.props.transaction.payment} />;
    }

    if (this.props.transaction.expired || this.props.transaction.failed) {
      return (
        <Alert type="danger" icon="alert-triangle">
          Nie udało się potwierdzić transakcji. Status:
          <i className={styles.info}>
            {this.props.transaction.expired ? 'expired' : 'failed'}
          </i>
          .
        </Alert>
      );
    }

    return (
      <>
        <Grid.Row className="mb-5">
          <Grid.Col>
            <h3>{this.props.t('components:Checking.title')}</h3>
          </Grid.Col>
          <GoingPaper>
            <Loader />
          </GoingPaper>
        </Grid.Row>
      </>
    );
  }
}

export default withTranslation()(Checking);
