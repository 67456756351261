import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { catchValues, handleActivePage } from '@Model/metadata/actions';
import {
  getEventsPartner,
  getMeta,
  getPartners,
  getPoolsPartner,
  getRundatesPartner,
  getSearchText,
} from '@Model/metadata/selectors';
import { DEPENDENCIES } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';

import AttachModal from './AttachModal.component';
import {
  IAttachModalFromDispatch,
  IAttachModalFromState,
  IAttachModalOwnProps,
} from './AttachModal.types';

const mapStateToProps = (state: _Store.IState): IAttachModalFromState => ({
  dependencies: {
    event: getEventsPartner(state),
    partner: getPartners(state),
    pool: getPoolsPartner(state),
    rundate: getRundatesPartner(state),
  },
  isLoading: getLoading(DEPENDENCIES)(state),
  meta: getMeta(state),
  searchText: getSearchText(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IAttachModalFromDispatch => ({
  catchMetadataValues: (type) => dispatch(catchValues(type)),
  handleActivePage: (type, page, searchText) =>
    dispatch(handleActivePage({ type, page, searchText })),
});

export default connect<
  IAttachModalFromState,
  IAttachModalFromDispatch,
  IAttachModalOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(AttachModal);
