import React, { useState } from 'react';

import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import DiscountModal from '@Compo/Discounts/components/DiscountModal/';
import EventsDiscountTable from '@Compo/Discounts/components/EventsDiscountTable/';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';

import DiscountTable from './components/DiscountTable';
import useStyles from './Discounts.styles';
import { IDiscountsProps } from './Discounts.types';

const Discounts = ({
  showModal,
  permissionDiscountView,
  closeModal,
  permissionEventDiscountView,
  permissionHappeningDiscountView,
}: IDiscountsProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Discounts',
  });

  const [selectedDiscount, setSelectedDiscount] = useState(
    permissionHappeningDiscountView ? 'happenings' : 'events'
  );
  const classes = useStyles();

  const catchChangeDiscountType = (e: SelectChangeEvent<string>) => {
    setSelectedDiscount(e.target.value as string);
  };

  const renderDiscountListHeader = () => {
    return (
      <div className={classes.container}>
        <FormControl variant="standard" fullWidth={true}>
          <Select
            value={selectedDiscount}
            onChange={catchChangeDiscountType}
            variant="filled"
          >
            {permissionHappeningDiscountView && (
              <MenuItem value={'happenings'}>{t('happenings')}</MenuItem>
            )}
            {permissionEventDiscountView && (
              <MenuItem value={'events'}>{t('events')}</MenuItem>
            )}
          </Select>
          <FormHelperText>{t('discountSelectHelper')}</FormHelperText>
        </FormControl>
      </div>
    );
  };

  return (
    <>
      <DiscountModal showModal={showModal} closeModal={closeModal} />
      <section>
        {permissionDiscountView &&
          (permissionHappeningDiscountView || permissionEventDiscountView) && (
            <GoingPaper header={renderDiscountListHeader()}>
              {selectedDiscount === 'happenings' ? (
                <DiscountTable />
              ) : (
                <EventsDiscountTable />
              )}
            </GoingPaper>
          )}
      </section>
    </>
  );
};

export default Discounts;
