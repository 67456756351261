import React from 'react';

import Grid from '@Compo/layout/Grid';
import ReportSeatsIo from '@Compo/ReportSeatsIo';

const ReportSeatsIoPage = () => (
  <Grid>
    <ReportSeatsIo />
  </Grid>
);

export default ReportSeatsIoPage;
