import { createSelector } from 'reselect';

import config from '@Config';
import getHappening from '@Model/happening/selectors/getHappening';
import getSelectedConfigurationId from '@Model/happening/selectors/getSelectedConfigurationId';
import {
  IAvailabilitiesResponseSlot,
  IHappeningReducer,
} from '@Model/happening/types';
import _Store from '@Store';

const getPrices = createSelector<
  _Store.IState,
  IAvailabilitiesResponseSlot | null,
  IHappeningReducer,
  string[]
>(
  getSelectedConfigurationId,
  getHappening,
  (selectedConfigurationId, happening) => {
    if (
      config.cms.showTicketsTypes &&
      selectedConfigurationId &&
      selectedConfigurationId.prices &&
      selectedConfigurationId.prices.length
    ) {
      const spacePrices = happening?.spaces
        .find((space) => space.id === selectedConfigurationId.spaceId)
        ?.rulePrice?.find(
          (rule) => Number(rule.id) === selectedConfigurationId.rulePriceId
        )?.prices;

      if (spacePrices) {
        return spacePrices
          .filter((price) => price.onsite)
          .map((price) => price.type);
      }
      return selectedConfigurationId.prices.map((price) => price.type);
    }
    return [];
  }
);

export default getPrices;
