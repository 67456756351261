import { allPermissions } from '@/models/state/constants/permissions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { get } from '@Model/authorization/selectors';
import {
  deselectSeat,
  getEvents,
  getPools,
  handleSearch,
  mounted,
  selectEvent,
  selectPool,
  selectSeat,
  updateSelectedSeats,
} from '@Model/events/actions';
import { getSelectedEvent, getSelectedSeats } from '@Model/events/selectors';
import { getHappeningPartnerId } from '@Model/happenings/selectors';

export const getEventsWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    mergeMap$(() => {
      return of$(getEvents.request(undefined));
    })
  );
};

export const fetchEventsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { eventsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getEvents.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      const {
        userInfo: { permissions },
      } = get(state);

      const isEventsWithDateRange = permissions.includes(
        allPermissions.events_with_date_range
      );

      return from$(
        eventsApi.getEvents(partnerId, action.payload, isEventsWithDateRange)
      ).pipe(
        mergeMap$((data) => {
          return of$(getEvents.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => eventsApi.cancelEvents())
          )
        ),
        catchError$((error: Error) => {
          return of$(getEvents.failure(error));
        })
      );
    })
  );
};

export const getPoolsWhenSelectEvent: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(selectEvent)),
    mergeMap$((action) => {
      if (action.payload) {
        return of$(getPools.request(), selectPool(null));
      }
      return of$(selectPool(null), getPools.success(null));
    })
  );
};

export const fetchPoolsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { poolsApi, goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPools.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedEvent = getSelectedEvent(state);
      if (selectedEvent) {
        const { eventSlug, rundateSlug, id } = selectedEvent;
        return from$(goingRundateApi.getRundatePools(Number(id))).pipe(
          mergeMap$((rundateData) => {
            return from$(poolsApi.getPools(eventSlug, rundateSlug)).pipe(
              mergeMap$((data) => {
                return of$(
                  getPools.success({
                    ...data,
                    pools: data.pools.map((pool) => {
                      const foundRundateData = rundateData.find(
                        (rundatePool) => rundatePool.id === pool.id
                      );

                      return {
                        ...pool,
                        available:
                          foundRundateData &&
                          foundRundateData.emissionSize &&
                          foundRundateData.sold
                            ? foundRundateData.emissionSize -
                              foundRundateData.sold
                            : undefined,
                        emissionSize: rundateData.find(
                          (rundatePool) => rundatePool.id === pool.id
                        )?.emissionSize,
                      };
                    }),
                  })
                );
              }),
              takeUntil$(
                action$.pipe(
                  filter$(isOfType(LOCATION_CHANGE)),
                  tap$(() => poolsApi.cancelPools())
                )
              ),
              catchError$((error: Error) => {
                return of$(getPools.failure(error));
              })
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          ),
          catchError$((error: Error) => {
            return of$(getPools.failure(error));
          })
        );
      }
      return EMPTY$;
    })
  );
};

export const updateSelectedSeatsWhenSeatSelected: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(selectSeat)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedSeats = getSelectedSeats(state);
      const { payload: addedSeats } = action;

      return of$(updateSelectedSeats([...selectedSeats, addedSeats]));
    })
  );
};

export const updateSelectedSeatsWhenSeatDeselected: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(deselectSeat)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedSeats = [...getSelectedSeats(state)];

      const indexOfObjectToDelete = selectedSeats.findIndex(
        (seat) => seat.id === action.payload.id
      );

      if (indexOfObjectToDelete >= 0) {
        selectedSeats.splice(indexOfObjectToDelete, 1);

        return of$(updateSelectedSeats(selectedSeats));
      }

      return EMPTY$;
    })
  );
};

export const fetchEventsWhenSearchTextChanged: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(handleSearch)),
    map$((action) => {
      return getEvents.request(action.payload);
    })
  );
};
