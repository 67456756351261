import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { handleActiveShipmentFee, saveProduct } from '@Model/products/actions';
import { getActiveShipmentFee } from '@Model/products/selectors';
import _Store from '@Store';
import ShipmentFeeModal from './ShipmentFeeModal.component';
import { prepareInitialValues } from './ShipmentFeeModal.helpers';
import {
  IShipmentFeeModalFromDispatch,
  IShipmentFeeModalFromState,
} from './ShipmentFeeModal.types';

const mapStateToProps = (state: _Store.IState): IShipmentFeeModalFromState => {
  return {
    initialValues: prepareInitialValues(getActiveShipmentFee(state)),
    partnerId: getHappeningPartnerId(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IShipmentFeeModalFromDispatch => {
  return {
    closeModal: () => dispatch(handleActiveShipmentFee(null)),
    saveShipmentFee: (body) => dispatch(saveProduct(body)),
  };
};

export default connect<
  IShipmentFeeModalFromState,
  IShipmentFeeModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ShipmentFeeModal);
