import _Store from '@Store';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { handleActiveShipmentFee } from '@Model/products/actions';
import FormLayout from './FormLayout.component';
import { IFormLayoutFromDispatch } from './FormLayout.types';

const mapDispatchToProps = (dispatch: Dispatch): IFormLayoutFromDispatch => ({
  closeModal: () => dispatch(handleActiveShipmentFee(null)),
});

export default connect<null, IFormLayoutFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(FormLayout);
