import { boolean, number, object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const NOT_NUMBER_VALIDATOR_TEXT = 'Podaj liczbę';

const configuration = {
  emissionSize: number().typeError(NOT_NUMBER_VALIDATOR_TEXT).nullable(),
  endDate: string().nullable(),
  isActive: boolean().required(REQUIRED_VALIDATOR_TEXT),
  parentId: number().nullable(),
  poolDescriptionId: number().nullable().required(REQUIRED_VALIDATOR_TEXT),
  price: number()
    .typeError(NOT_NUMBER_VALIDATOR_TEXT)
    .required(REQUIRED_VALIDATOR_TEXT),
  seatsIoCategoryKey: number().typeError(NOT_NUMBER_VALIDATOR_TEXT).nullable(),
  startDate: string().nullable(),
  vat: number().typeError(NOT_NUMBER_VALIDATOR_TEXT).nullable(),
};

export const PoolsSchema = object().shape(configuration);
