import React, { forwardRef, useImperativeHandle, useRef } from 'react';

import { Formik } from 'formik';
import moment from 'moment';

import * as helpers from '@Compo/BetterManager/NewAddingConfiguration/NewAddingConfiguration.helpers';
import { IRuleAvailableSteps } from '@Compo/reusable/Stepper/Stepper.types';
import checkIsEndAfterMidnight from '@Misc/helpers/checkIsEndAfterMidnight';
import {
  IConfiguration,
  IFormValuesProps,
  TRuleType,
} from '@Model/configurations/types';

import FormLayout from './Components/FormLayout';
import { makeName, makeRRule } from './Components/FormLayout/FormLayout.helpes';
import { ITimeRuleProps, ITimeRuleRef } from './TimeRule.types';
import { AddingConfigurationSchema } from './TimeRule.validation';

const TimeRule = forwardRef<ITimeRuleRef, ITimeRuleProps>((props, ref) => {
  const formRef = useRef<any>();

  const {
    initialValues,
    catchSaveConfiguration,
    setStepSlug,
    setFormRulesData,
    partnerId,
    isClosed,
  } = props;

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (formRef.current) {
        formRef.current.validateForm().then((res: any) => {
          const fields = Object.keys(res);
          fields.forEach((field) =>
            formRef.current?.setFieldTouched(field, true)
          );

          if (Object.keys(res).length === 0) {
            formRef.current?.submitForm().then(() => setStepSlug('rule-list'));
          }
        });
      }
    },
    validateAndSaveToState: (
      slug: IRuleAvailableSteps,
      ruleType: TRuleType
    ) => {
      if (formRef.current) {
        formRef.current.validateForm().then((res: any) => {
          const fields = Object.keys(res);
          fields.forEach((field) =>
            formRef.current?.setFieldTouched(field, true)
          );

          if (Object.keys(res).length === 0) {
            setFormRulesData({
              ...formRef.current.state.values,
              partnerId,
              rrule: makeRRule(formRef.current.state.values).toUpperCase(),
              ruleType,
            });
            setStepSlug(slug);
          }
        });
      }
    },
  }));

  const catchHandleSubmit = (values: IFormValuesProps) => {
    const {
      id,
      price,
      durationToString,
      durationAdvanced,
      duration,
      advanced,
      title,
      isUpsell,
      closed,
      upsellValue,
      isPeopleCount,
      validTo,
      validFrom,
      ruleType,
      capacity,
      prices,
      cyclic,
      until,
    } = values;

    const getDuration = () => {
      if (!advanced) {
        return {
          duration: durationNewValue || duration,
        };
      }
      return {
        duration: durationAdvanced,
      };
    };

    const getTitle = (): { title: string } => {
      if (title && title.length > 0) {
        return {
          title,
        };
      }

      return {
        title: makeName(values, makeRRule(values).toUpperCase()),
      };
    };

    const getPeopleCount = () => {
      const { fromNumberOfPeople, perPersonValue, perPersonType, priceGoesUp } =
        values;

      const getValue = () => {
        if (perPersonType === 'value') {
          return {
            percentageValue: null,
            value: Number(perPersonValue),
          };
        } else {
          return {
            percentageValue: Number(perPersonValue),
            value: null,
          };
        }
      };

      if (isPeopleCount) {
        return {
          fromNumberOfPeopleModifier: {
            fromNumberOfPeople: Number(fromNumberOfPeople),
            priceGoesUp: priceGoesUp === 'up',
            ...getValue(),
          },
        };
      }
      return {};
    };

    const durationNewValue = helpers.makeTimeFromDuration(durationToString);

    const getPropsByPriceRule = () => {
      if (ruleType === 'price') {
        const getUpsalle = () => {
          const value = upsellValue || 0;

          if (isUpsell) {
            if (values.upsellType === 0) {
              return {
                upsell: {
                  percentageValue: null,
                  value,
                },
              };
            }
            return {
              upsell: {
                percentageValue: value,
                value: null,
              },
            };
          }

          return {};
        };

        return {
          prices,
          ...getUpsalle(),
          ...getPeopleCount(),
        };
      }
      return {};
    };

    const getPropsByTimeRule = () => {
      if (ruleType === 'time') {
        return {
          closed,
        };
      }
      return {};
    };

    const getPropsByCapacityRule = () => {
      if (props.capacity) {
        return {
          capacity,
        };
      }
      return {};
    };

    const rrule = makeRRule(values);

    const answer = {
      closed: !!isClosed,
      id: id || undefined,
      partnerId:
        partnerId && Number(partnerId) !== -1 && Number(partnerId) !== 0
          ? Number(partnerId)
          : null,
      rrule: makeRRule(values).toUpperCase(),
      validFrom,
      validTo:
        checkIsEndAfterMidnight(
          rrule,
          duration,
          validTo,
          initialValues.validTo
        ) && !cyclic
          ? initialValues.until
            ? moment(until).add(1, 'day')
            : moment(validTo).add(1, 'day')
          : validTo,
      ...getPropsByPriceRule(),
      ...getPropsByTimeRule(),
      ...getDuration(),
      ...getPropsByCapacityRule(),
      ...getTitle(),
    };

    if (values.closed) {
      answer.closed = true;
    }

    catchSaveConfiguration({
      configuration: answer as IConfiguration,
      type: props.capacity ? 'capacity' : 'time',
    });
  };

  return (
    <Formik
      ref={formRef}
      initialValues={initialValues}
      onSubmit={catchHandleSubmit}
      validationSchema={AddingConfigurationSchema}
      children={(childProps) => (
        <FormLayout {...childProps} isCapacity={props.capacity} />
      )}
    />
  );
});

export default TimeRule;
