import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  catchGetSinglePoolDescription,
  handleActivePage,
  handleChangeRowsPerPage,
  handleSearch,
  mounted,
  setPoolDescription,
} from '@Model/poolDescriptions/actions';
import {
  getMeta,
  getPoolDescriptions,
} from '@Model/poolDescriptions/selectors';
import { POOL_DESCRIPTIONS } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';

import PoolDescriptions from './PoolDescriptions.component';
import {
  IPoolDescriptionsFromDispatch,
  IPoolDescriptionsFromState,
} from './PoolDescriptions.types';

const mapStateToProps = (state: _Store.IState): IPoolDescriptionsFromState => ({
  isLoading: getLoading(POOL_DESCRIPTIONS)(state),
  meta: getMeta(state),
  poolDescriptions: getPoolDescriptions(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPoolDescriptionsFromDispatch => ({
  catchGetSinglePoolDescription: (id: number) =>
    dispatch(catchGetSinglePoolDescription(id)),
  handleActivePage: (page) => dispatch(handleActivePage(page)),
  handleChangeRowsPerPage: (size) => dispatch(handleChangeRowsPerPage(size)),
  handleSearch: (text) => dispatch(handleSearch(text)),
  mounted: () => dispatch(mounted()),
  setActivePoolDescription: (values) => dispatch(setPoolDescription(values)),
});

export default connect<
  IPoolDescriptionsFromState,
  IPoolDescriptionsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PoolDescriptions);
