import React from 'react';

import { ListItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import getTimeAsString from '@Misc/helpers/getTimeAsString';

const useStyles = makeStyles(() => ({
  container: {
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const Date = ({ date }: { date: Date }) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'BetterManager.AddingConfiguration.components.Preview.components.Date',
  });

  const days = [
    t('sunday'),
    t('monday'),
    t('tuesday'),
    t('wednesday'),
    t('thursday'),
    t('friday'),
    t('saturday'),
  ];

  const months = [
    t('january'),
    t('february'),
    t('march'),
    t('april'),
    t('may'),
    t('june'),
    t('july'),
    t('august'),
    t('september'),
    t('october'),
    t('november'),
    t('december'),
  ];

  const classes = useStyles();

  return (
    <ListItem className={classes.container}>
      <Typography variant="body1">{`${date.getUTCDate()} ${
        months[date.getUTCMonth()]
      } ${date.getUTCFullYear()}, ${days[date.getUTCDay()]}`}</Typography>
      <Typography variant="body2" component="p" color="textSecondary">
        {getTimeAsString(date.getUTCHours(), date.getUTCMinutes())}
      </Typography>
    </ListItem>
  );
};

export default Date;
