import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';

class GoingCloudinaryApi {
  private static getCloudinaryBaseUrl(): string {
    return `${config.api.cmsServices}cloudinary`;
  }

  private static getCloudinaryDeleteUrl(slug: string): string {
    return `${GoingCloudinaryApi.getCloudinaryBaseUrl()}/by-tag/${slug}`;
  }

  private cancelTokenEvents?: CancelTokenSource;

  public deleteAssets(slug: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = GoingCloudinaryApi.getCloudinaryDeleteUrl(slug);
      axios
        .delete(url)
        .then(getData)
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelEvents() {
    if (this.cancelTokenEvents) {
      this.cancelTokenEvents.cancel();
      this.cancelTokenEvents = undefined;
    }
  }
}

export default new GoingCloudinaryApi();
