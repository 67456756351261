import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 20,
    boxShadow: theme.shadows[4],
    height: '100%',
    minHeight: 128,
    padding: theme.spacing(4),
    width: '100%',
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;
