import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import {
  getAllCategories,
  getAllInvoiceProducts,
  getAllLocalProducts,
  getAllProductsWithCategories,
  getAllShipmentFee,
  getAllZohoProducts,
  getProductsByCategory,
} from '@Model/products/actions';

import { resetError, resetLoading, setLoading } from './../actions';
import { PRODUCTS } from './../constants/constants';

export const setUpStateWhenProductsFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getAllLocalProducts.request,
        getAllCategories.request,
        getAllShipmentFee.request,
        getAllZohoProducts.request,
        getAllProductsWithCategories.request,
        getProductsByCategory.request,
        getAllInvoiceProducts.request,
      ])
    ),
    mergeMap$(() => [resetError(PRODUCTS), setLoading(PRODUCTS)])
  );
};

export const setLoaderWhenProductsFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          getAllLocalProducts.success,
          getAllLocalProducts.failure,
          getAllCategories.success,
          getAllCategories.failure,
          getAllShipmentFee.success,
          getAllShipmentFee.failure,
          getAllZohoProducts.success,
          getAllZohoProducts.failure,
          getAllInvoiceProducts.success,
          getAllInvoiceProducts.failure,
          getAllProductsWithCategories.failure,
          getProductsByCategory.success,
          getProductsByCategory.failure,
        ],
        action
      )
    ),
    map$(() => resetLoading(PRODUCTS))
  );
};
