import { date, object, string } from 'yup';
import { IDetailsModalValidators } from './DetailsModal';

const REQUIRED_TEXT = 'Pole wymagane';

const configuration: IDetailsModalValidators = {
  description: string().nullable(),
  startDate: date()
    .nullable()
    .required(REQUIRED_TEXT),
  startTime: string()
    .nullable()
    .required(REQUIRED_TEXT),
};

export const DetailsModalSchema = object().shape(configuration);
