import React, { ChangeEvent, useEffect, useState } from 'react';

import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import { IColumns } from '@Compo/reusable/TableUi/TableUi.types';
import { ISeatsIoChart } from '@Services/$seats-io-api/types';

import useStyles from './AssignModal.styles';
import { IAssignModalProps } from './AssignModal.types';

const AssignModal = ({
  mounted,
  data,
  open,
  handleClose,
  save,
  changeMetaData,
  meta,
  selectedMeta,
  isLoading,
}: IAssignModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates.SeatsIo.components.AssignModal',
  });

  const classes = useStyles();
  const [localSelected, setLocalSelected] = useState<ISeatsIoChart[]>([]);
  const [searchTimeOut, setSearchTimeOut] = useState<NodeJS.Timeout | null>(
    null
  );
  const [searchParam, setSearchParam] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [openTooltip, setOpenTooltip] = useState<boolean[]>([]);

  const columns: IColumns[] = [
    { id: 'name', label: '' },
    { id: 'actions', label: '', align: 'right' },
  ];

  const closeTooltip = (id: number) => {
    setOpenTooltip((oldState) =>
      oldState.map((tooltip, index) => {
        if (index === id) {
          return false;
        }
        return tooltip;
      })
    );
  };

  const normalizedData = data.map((value, i) => ({
    ...value,
    actions: (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => closeTooltip(i)}
      >
        <Tooltip
          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
          title={
            <img
              className={classes.image}
              src={value.publishedVersionThumbnailUrl}
            />
          }
          placement="bottom"
          arrow={true}
          open={openTooltip[i]}
        >
          <Button
            size="small"
            variant="text"
            color="primary"
            startIcon={<RemoveRedEyeIcon />}
            onClick={(e) => {
              e.stopPropagation();

              setOpenTooltip((oldState) =>
                oldState.map((v, index) => i === index && !v)
              );
            }}
          >
            {t('preview')}
          </Button>
        </Tooltip>
      </ClickAwayListener>
    ),
  }));

  const catchActivePageChange = (event: unknown, page: number) => {
    changeMetaData({
      count: selectedMeta.count,
      nextPageStartsAfter: meta.nextPageStartsAfter,
      previousPageEndsBefore: meta.previousPageEndsBefore,
      search: searchParam,
    });
    setCurrentPage(page);
  };

  const submit = () => {
    save(localSelected[0].key);
    handleClose();
  };

  const setOwnSelected = (selected: ISeatsIoChart[]) => {
    setLocalSelected(selected);
  };

  const catchHandleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchParam(e.target.value);
    searchFunc(e.target.value);
  };

  const searchFunc = (text: string) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        changeMetaData({ search: text, count: selectedMeta.count });
      }, 1000)
    );
  };

  useEffect(() => {
    setOpenTooltip(data.map(() => false));
  }, [data]);

  useEffect(() => {
    mounted();
  }, []);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <GenericTable>
          <TableActions>
            <TextField
              variant="standard"
              name="search"
              helperText={t('search')}
              value={searchParam}
              onChange={catchHandleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
            />
          </TableActions>

          <TableUi
            rows={normalizedData}
            columns={columns}
            total={0}
            activePage={currentPage}
            rowsPerPage={0}
            singleSelect={true}
            handleChangePage={catchActivePageChange}
            setOwnSelected={setOwnSelected}
            selectedItems={localSelected}
            hideInformation={true}
            isLoading={isLoading}
          />
        </GenericTable>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submit}
        >
          {t('assign')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignModal;
