import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Button, ButtonGroup, Grid, Hidden, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import colors from '@Compo/App/colors';

import { MESSAGES } from './../../Calendar.constants';
import Filters from './../Filters';
import { ICustomToolbarProps } from './CustomToolbar.types';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    backgroundColor: colors.primary.contrastText,
  },
  clearButton: {
    marginRight: theme.spacing(2),
  },
  container: {
    marginBottom: theme.spacing(2),
  },
  drawer: {
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      flexBasis: '100%',
      flexGrow: 1,
      justifyContent: 'flex-end',
      marginTop: theme.spacing(1),
    },
  },
  smallContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexBasis: '100%',
      justifyContent: 'center',
      marginTop: theme.spacing(1),

      '&:first-child': {
        marginTop: 0,
      },
    },
  },
  spacing1: {
    marginTop: theme.spacing(1),
  },
  spacingBottom1: {
    marginBottom: theme.spacing(1),
  },
}));

const CustomToolbar = ({
  onNavigate,
  onView,
  label,
  view,
  clearFilters,
  selectAllFilters,
}: ICustomToolbarProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Calendar.components.CustomToolbar',
  });

  const classes = useStyles();
  const [showFilters, setShowFilters] = useState(false);
  return (
    <>
      <Grid
        container={true}
        justifyContent="space-between"
        className={cn(classes.container, classes.spacing1)}
      >
        <div className={classes.smallContainer}>
          <ButtonGroup
            variant="outlined"
            size="small"
            color="primary"
            className={classes.buttonsContainer}
          >
            {view === 'day' && (
              <Button onClick={() => onNavigate('TODAY')}>
                {MESSAGES.today}
              </Button>
            )}
            <Button onClick={() => onNavigate('PREV')}>
              {MESSAGES.previous}
            </Button>
            <Button onClick={() => onNavigate('NEXT')}>{MESSAGES.next}</Button>
          </ButtonGroup>
        </div>
        <Hidden mdDown={true}>
          <div className={classes.smallContainer}>
            <Typography variant="body1">{label}</Typography>
          </div>
        </Hidden>
        <div className={classes.smallContainer}>
          <ButtonGroup
            variant="outlined"
            size="small"
            color="primary"
            className={classes.buttonsContainer}
          >
            <Button onClick={() => onView('week')}>{MESSAGES.week}</Button>
            <Button onClick={() => onView('day')}>{MESSAGES.day}</Button>
            <Button onClick={() => setShowFilters(!showFilters)}>
              {t('filters')}
            </Button>
          </ButtonGroup>
        </div>
        <Hidden only={['sm', 'md', 'lg', 'xl']}>
          <div className={classes.smallContainer}>
            <Typography variant="body1">{label}</Typography>
          </div>
        </Hidden>
      </Grid>
      {showFilters && (
        <Grid
          container={true}
          justifyContent="flex-end"
          className={classes.spacing1}
        >
          <div className={classes.spacingBottom1}>
            <Button
              variant="text"
              color="primary"
              startIcon={<CloseIcon />}
              onClick={clearFilters}
              className={classes.clearButton}
              size="small"
            >
              {t('clearFilters')}
            </Button>
            <Button
              variant="text"
              color="primary"
              startIcon={<DoneIcon />}
              onClick={selectAllFilters}
              size="small"
            >
              {t('setAllFilters')}
            </Button>
          </div>
          <Filters />
        </Grid>
      )}
      <Grid className={classes.spacing1} />
    </>
  );
};

export default CustomToolbar;
