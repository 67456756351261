import { createSelector } from 'reselect';

import _Store from '@Store';
import { IProductsReducer, IShipmentFee } from '../types';
import get from './get';

const getActiveShipmentFee = createSelector<
  _Store.IState,
  IProductsReducer,
  IShipmentFee | null
>([get], (products) => products.activeShipmentFee);

export default getActiveShipmentFee;
