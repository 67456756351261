import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { getUserInfo } from '@Model/authorization/selectors';
import { resetState } from '@Model/discount/actions';
import getActiveDiscount from '@Model/discount/selectors/getActiveDiscount';
import { getHappenings } from '@Model/happenings/selectors';
import { getPriceTypes } from '@Model/priceTypes/selectors';
import _Store from '@Store';
import FormLayout from './FormLayout.component';
import {
  IDiscountLayoutFromDispatch,
  IDiscountLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IDiscountLayoutFromState => {
  return {
    activeDiscount: getActiveDiscount(state),
    happenings: getHappenings(state),
    partners: getUserInfo(state).partners,
    priceTypes: getPriceTypes(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IDiscountLayoutFromDispatch => {
  return {
    closeModal: () => {
      dispatch(resetState());
    },
  };
};

export default connect<
  IDiscountLayoutFromState,
  IDiscountLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
