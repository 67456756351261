import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IDiscount } from '@Model/discount/types';

import { IGetSingleEventDiscountResponse } from './types';

class DiscountApi {
  private static getDiscountsUrl(): string {
    return `${config.api.baseUrl}admin/discounts`;
  }

  private static getUpdateSingleDiscountUrl(slug: string): string {
    return `${config.api.baseUrl}admin/discounts/${slug}`;
  }

  private static getRemoveDiscountsUrl(slug: string): string {
    return `${config.api.baseUrl}admin/discounts/${encodeURI(
      slug.toLocaleLowerCase()
    )}/delete`;
  }

  private static getSingleEventDiscountUrl(id: number) {
    return `${config.api.cmsServices}price-reduction-product/discount/${id}`;
  }

  private cancelTokenDiscount?: CancelTokenSource;

  public addSingleDiscount(body: IDiscount): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cancelTokenDiscount = axios.CancelToken.source();
      if (body.id) {
        const url = DiscountApi.getUpdateSingleDiscountUrl(body.id.toString());
        axios
          .put(url, JSON.stringify(body), {
            cancelToken: this.cancelTokenDiscount.token,
          })
          .then(getData)
          .then(() => {
            resolve();
          });
      } else {
        const url = DiscountApi.getDiscountsUrl();
        axios
          .post(url, JSON.stringify(body))
          .then(() => {
            resolve();
          })
          .catch((error) => reject(catchHttpError(error)));
      }
    });
  }

  public getSingleEventDiscount(
    id: number
  ): Promise<IGetSingleEventDiscountResponse> {
    return new Promise<IGetSingleEventDiscountResponse>((resolve, reject) => {
      this.cancelTokenDiscount = axios.CancelToken.source();
      axios
        .get(DiscountApi.getSingleEventDiscountUrl(id), {
          cancelToken: this.cancelTokenDiscount.token,
        })
        .then(getData)
        .then(getData)
        .then((data) => resolve(data[0]))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public removeDiscount(slug: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cancelTokenDiscount = axios.CancelToken.source();

      axios
        .post(
          DiscountApi.getRemoveDiscountsUrl(slug),
          {},
          { cancelToken: this.cancelTokenDiscount.token }
        )
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelDiscount() {
    if (this.cancelTokenDiscount) {
      this.cancelTokenDiscount.cancel();
      this.cancelTokenDiscount = undefined;
    }
  }
}

export default new DiscountApi();
