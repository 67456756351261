import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  IChangePasswordModalProps,
  IChangePasswordModalValues,
} from './ChangePasswordModal.types';
import { ChangePasswordSchema } from './ChangePasswordModal.validation';
import FormLayout from './components/FormLayout';

const ChangePasswordModal = ({
  open,
  setOpen,
  activeUser,
  submit,
}: IChangePasswordModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Users.components.UsersTable.ChangePasswordModal',
  });

  const handleClose = () => {
    setOpen(false);
  };

  const catchHandleSubmit = (values: IChangePasswordModalValues) => {
    handleClose();
    submit(values);
  };

  const initialValues: IChangePasswordModalValues = {
    id: activeUser?.id,
    password: '',
    repeatPassword: '',
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={catchHandleSubmit}
        validationSchema={ChangePasswordSchema}
        children={(props) => (
          <FormLayout {...props} handleClose={handleClose} />
        )}
      />
    </Dialog>
  );
};

export default ChangePasswordModal;
