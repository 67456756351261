import React, { ChangeEvent, useEffect, useState } from 'react';

import routes from '@/routes/routes';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import cn from 'classnames';
import { Formik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import { IColumns } from '@Compo/reusable/TableUi/TableUi.types';
import Filters from '@Compo/Rundates/components/Filters';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import { IFilter } from '@Services/$going-rundate-api/types';

import useStyles from './Rundates.styles';
import { IRundateRowTable, IRundatesProps } from './Rundates.types';

const Rundates = ({
  rundates,
  mounted,
  meta,
  isLoading,
  changePageOrSize,
  history,
  resetActiveRundate,
  searchText,
  search,
  filterValues,
  changeFilter,
}: IRundatesProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates',
  });

  const classes = useStyles();
  const [searchTimeOut, setSearchTimeOut] = useState<NodeJS.Timeout | null>(
    null
  );
  const [inputText, setInputText] = useState(searchText);
  const [showFilters, setShowFilters] = useState(false);
  const [filtersActive, setFiltersActive] = useState(false);

  useEffect(() => {
    mounted();
  }, []);

  useEffect(() => {
    setInputText(searchText);
  }, [searchText]);

  useEffect(() => {
    actionForFilter(filterValues);
  }, [filterValues]);

  const addEvent = () => {
    resetActiveRundate();
    history.push(routes.addEvent);
  };

  const changePage = (e: unknown, selectedPage: number) => {
    changePageOrSize({
      page: selectedPage + 1,
      size: meta.size,
      total: meta.total,
    });
  };

  const changeSize = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    changePageOrSize({
      page: meta.page,
      size: Number(e.target.value),
      total: meta.total,
    });
  };

  const catchRowClicked = (id: string) => {
    history.push(fillUrlWithValues(routes.rundateEdit, ':id', id.toString()));
  };

  const searchFunc = (text: string) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        search({ ...filterValues, search: text || undefined });
      }, 1000)
    );
  };

  const catchHandleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.currentTarget.value);
    searchFunc(e.currentTarget.value);
  };

  const catchHandleFiltersSubmit = (filter: IFilter) => {
    changeFilter(filter);
    actionForFilter(filter);
  };

  const actionForFilter = (filter: IFilter) => {
    setFiltersActive(filter.withExpired || filter.withCalendarEvents);

    setInputText(filter.search);

    setShowFilters(false);
  };

  const columns: IColumns[] = [
    { id: 'id', label: t('id') },
    { id: 'titlePL', label: t('titlePL') },
    { id: 'cityName', label: t('city') },
    { id: 'placeName', label: t('place') },
    { id: 'rundateDate', label: t('rundateDate') },
    { id: 'rundateTime', label: t('rundateTime') },
    { id: 'isVisible', label: t('isPublished') },
    { id: 'isForSale', label: t('isOnSale') },
  ];

  const normalizedRows: IRundateRowTable[] = rundates.map((rundate) => {
    const rundateDate = moment(rundate.rundate);

    return {
      cityName:
        rundate.place?.city?.name || rundate.event?.place?.city?.name || '-',
      id: rundate.id,
      isDisabled: rundateDate.isValid() && rundateDate.isBefore(moment()),
      isForSale: rundate?.isForSale || false,
      isVisible: rundate.event?.isVisible || false,
      placeName: rundate.place?.name || rundate.event?.place?.name || '-',
      rundateDate: rundateDate.isValid()
        ? rundateDate.format('DD.MM.YYYY')
        : '-',
      rundateTime: rundateDate.isValid() ? rundateDate.format('HH:mm') : '-',
      titlePL:
        (rundate.rundateDescription
          ? rundate.event.titlePL + ' - ' + rundate.rundateDescription
          : rundate.event.titlePL) || '-',
    };
  });

  return (
    <GoingPaper>
      {isLoading ? (
        <Loader className={classes.loader} />
      ) : (
        <GenericTable>
          <TableActions>
            <TextField
              variant="standard"
              name="search"
              helperText={t('search')}
              value={inputText}
              onChange={catchHandleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
            />
            <Grid
              container={true}
              justifyContent="flex-end"
              spacing={2}
              className={classes.buttonsContainer}
            >
              <Grid item={true} xs={12} sm={'auto'} order={{ xs: 2, sm: 1 }}>
                <Button
                  variant={'outlined'}
                  size={'small'}
                  fullWidth={true}
                  color={filtersActive ? 'primary' : 'secondary'}
                  startIcon={<TuneIcon />}
                  className={classes.button}
                  onClick={() => setShowFilters(!showFilters)}
                >
                  {t('filter')}
                </Button>
              </Grid>
              <Grid item={true} xs={12} sm={'auto'} order={{ xs: 1, sm: 2 }}>
                <Button
                  variant={'outlined'}
                  size={'small'}
                  fullWidth={true}
                  color={'secondary'}
                  startIcon={<AddIcon />}
                  className={cn(classes.button, classes.buttonFirst)}
                  onClick={addEvent}
                >
                  {t('add')}
                </Button>
              </Grid>
            </Grid>
          </TableActions>
          {showFilters && (
            <Formik
              component={Filters}
              onSubmit={catchHandleFiltersSubmit}
              initialValues={filterValues}
            />
          )}
          <TableUi
            rows={normalizedRows}
            columns={columns}
            disableCheckBox={true}
            total={meta.total}
            activePage={meta.page - 1}
            rowsPerPage={meta.size}
            handleChangePage={changePage}
            handleChangeRowsPerPage={changeSize}
            onRowClick={catchRowClicked}
          />
        </GenericTable>
      )}
    </GoingPaper>
  );
};

export default Rundates;
