import { Selector } from 'reselect';

import { IEventsReducer } from './../types';

import _Store from '@Store';

const getEvents: Selector<_Store.IState, IEventsReducer> = (state) =>
  state.events.events;

export default getEvents;
