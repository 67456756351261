import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
  completeAdvancePayment,
  selectAdvanceTransaction,
} from '@Model/reservation/actions';
import { getReservationPrintData } from '@Model/reservation/selectors';
import {
  catchCancelDelayedTransaction,
  catchDelayedTransactions,
  handleActivePage,
  handleRowsPerPage,
  handleTransactionType,
} from '@Model/reservations/actions';
import {
  getDelayedTransactions,
  getTransactionsType,
} from '@Model/reservations/selectors';
import {
  DELAYED_TRANSACTIONS,
  TRANSACTION_DETAILS,
} from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import TransactionDelayed from './TransactionsDelayed.component';
import {
  IConsentsListFromDispatch,
  IConsentsListFromState,
} from './TransactionsDelayed.types';

const mapStateToProps = (state: _Store.IState): IConsentsListFromState => {
  const {
    perPage,
    totalCount,
    items: delayedTransactions,
    page,
  } = getDelayedTransactions(state);

  const showPagination = totalCount > perPage;

  return {
    activePage: page,
    delayedTransactions,
    isLoading: getLoading(DELAYED_TRANSACTIONS)(state),
    isTransactionDetailsLoading: getLoading(TRANSACTION_DETAILS)(state),
    limit: perPage,
    reservationPrintData: getReservationPrintData(state),
    showPagination,
    totalCount,
    transactionType: getTransactionsType(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IConsentsListFromDispatch => {
  return {
    cancelTransaction: (UUId: string) =>
      dispatch(catchCancelDelayedTransaction(UUId)),
    completeAdvancePayment: (id) => dispatch(completeAdvancePayment(id)),
    handleActivePage: (e, page) => dispatch(handleActivePage(page)),
    handleRowsPerPage: (rowsPerPage) =>
      dispatch(handleRowsPerPage(rowsPerPage)),
    handleTransactionType: (type) => dispatch(handleTransactionType(type)),
    mounted: () => dispatch(catchDelayedTransactions()),
    selectAdvanceTransaction: (id) => dispatch(selectAdvanceTransaction(id)),
  };
};

export default connect<
  IConsentsListFromState,
  IConsentsListFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(TransactionDelayed);
