export enum TypographyVariant {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  helperText = 'helperText',
  subtitle1 = 'subtitle1',
  subtitle2 = 'subtitle2',
  overline = 'overline',
  body1 = 'body1',
  body2 = 'body2',
  caption = 'caption',
  buttonLarge = 'buttonLarge',
  buttonMedium = 'buttonMedium',
  buttonSmall = 'buttonSmall',
  underline = 'underline',
  button = 'button',
}
