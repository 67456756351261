import React from 'react';

import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import getCurrencyFromCode from '@Misc/helpers/getCurrencyFromCode';

import useStyles from './TransactionView.styles';
import { ISummaryProps } from './TransactionView.types';

const TransactionView = ({ transactionItems, details }: ISummaryProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Summary.components.TransactionView',
  });

  const classes = useStyles();

  return (
    <>
      {transactionItems.map(
        ({
          productName,
          bookingIdentifier,
          startDate,
          endDate,
          entryToken,
          quantity,
          price,
          lockCode,
          currency,
        }) => {
          const returnDate = () => {
            if (startDate?.length && endDate?.length) {
              const timezoneStartDate = moment
                .utc(startDate)
                .format('YYYY-MM-DD HH:mm:ss');

              const timezoneEndDate = moment
                .utc(endDate)
                .format('YYYY-MM-DD HH:mm:ss');

              const formattedStartDate =
                moment(timezoneStartDate).format('DD.MM.YYYY');
              const formattedEndDate =
                moment(timezoneEndDate).format('DD.MM.YYYY');

              let date;

              if (formattedStartDate === formattedEndDate) {
                date = formattedStartDate;
              } else {
                date = `${formattedStartDate} - ${formattedEndDate}`;
              }

              return date;
            }
            return '';
          };

          const returnTime = () => {
            if (startDate?.length && endDate?.length) {
              const timezoneStartDate = moment
                .utc(startDate)
                .format('YYYY-MM-DD HH:mm:ss');

              const timezoneEndDate = moment
                .utc(endDate)
                .format('YYYY-MM-DD HH:mm:ss');

              const formattedStartDate =
                moment(timezoneStartDate).format('HH:mm');
              const formattedEndDate = moment(timezoneEndDate).format('HH:mm');
              const time = `${formattedStartDate} - ${formattedEndDate}`;

              return time;
            }
            return '';
          };

          return (
            <Grid
              container={true}
              key={bookingIdentifier}
              direction="column"
              className={classes.container}
              spacing={2}
            >
              <Grid xs={12} item={true} className={classes.flexBasis}>
                <Typography variant="h6">{productName}</Typography>
              </Grid>
              <Grid xs={12} item={true} className={classes.flexBasis}>
                <Grid container={true} spacing={1}>
                  <Grid item={true} xs={6}>
                    <Typography
                      component="p"
                      variant="body1"
                      color="textSecondary"
                    >
                      {t('numberOfPeople')}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <Typography component="p" variant="body1">
                      {quantity}
                    </Typography>
                  </Grid>

                  {bookingIdentifier && (
                    <>
                      <Grid item={true} xs={6}>
                        <Typography
                          component="p"
                          variant="body1"
                          color="textSecondary"
                        >
                          {t('orderNumber')}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography component="p" variant="body1">
                          {bookingIdentifier}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {!!returnDate().length && (
                    <>
                      <Grid item={true} xs={6}>
                        <Typography
                          component="p"
                          variant="body1"
                          color="textSecondary"
                        >
                          {t('date')}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography component="p" variant="body1">
                          {returnDate()}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  {!!returnTime().length && (
                    <>
                      <Grid item={true} xs={6}>
                        <Typography
                          component="p"
                          variant="body1"
                          color="textSecondary"
                        >
                          {t('hour')}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography component="p" variant="body1">
                          {returnTime()}
                        </Typography>
                      </Grid>
                    </>
                  )}

                  <Grid item={true} xs={6}>
                    <Typography
                      component="p"
                      variant="body1"
                      color="textSecondary"
                    >
                      {t('price')}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <Typography component="p" variant="body1">
                      {price.toFixed(2)} {getCurrencyFromCode(currency)}
                    </Typography>
                  </Grid>
                  {entryToken && (
                    <>
                      <Grid item={true} xs={6}>
                        <Typography
                          component="p"
                          variant="body1"
                          color="textSecondary"
                        >
                          {t('ticketCode')}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography component="p" variant="body1">
                          {entryToken}
                        </Typography>
                      </Grid>
                    </>
                  )}
                  {lockCode && (
                    <>
                      <Grid item={true} xs={6}>
                        <Typography
                          component="p"
                          variant="body1"
                          color="textSecondary"
                        >
                          {t('lockCode')}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography component="p" variant="body1">
                          {lockCode}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        }
      )}
    </>
  );
};

export default TransactionView;
