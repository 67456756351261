import { combineReducers } from 'redux';

import {
  AUTHORIZATION,
  COMPANY_DATA,
  CONFIGURATIONS,
  DELAYED_TRANSACTIONS,
  DEPENDENCIES,
  DISCOUNT,
  DISCOUNTS,
  EMPIK_DETAILS,
  EVENTS,
  EVENTS_POOLS,
  FORM_IO,
  GOING_PLACES,
  GOING_POOLS,
  GOING_RUNDATES,
  GOING_TICKET_LIST_TICKETS,
  GOING_TICKETS,
  HAPPENING,
  HAPPENING_AVAILABILITY,
  HAPPENINGS,
  MAILING,
  PERMISSIONS,
  POOL_DESCRIPTIONS,
  POST_RESERVATION,
  PRICE,
  PRICE_TYPES,
  PRINTER,
  PRODUCTS,
  REFUNDS_REGISTRATION,
  REPORTS,
  RESERVATION,
  RESERVATION_CHECK,
  RESERVATIONS,
  RESERVATIONS_LIST_MODAL,
  SEATS_IO_CHARTS,
  SEATS_IO_REPORT,
  SETTINGS,
  SETTLEMENTS,
  SETTLEMENTS_CREATE,
  SETTLEMENTS_GENERATE,
  SETTLEMENTS_RUNDATES,
  SUMMARY,
  TICKETS,
  TRANSACTION_DETAILS,
  TRANSACTIONS,
  USER,
  USERS,
} from '@Model/state/constants';

import createSubStateReducer from './createSubStateReducer';

export const reducers = {
  [CONFIGURATIONS]: createSubStateReducer(CONFIGURATIONS),
  [DISCOUNT]: createSubStateReducer(DISCOUNT),
  [DISCOUNTS]: createSubStateReducer(DISCOUNTS),
  [FORM_IO]: createSubStateReducer(FORM_IO),
  [HAPPENING]: createSubStateReducer(HAPPENING),
  [HAPPENING_AVAILABILITY]: createSubStateReducer(HAPPENING_AVAILABILITY),
  [HAPPENINGS]: createSubStateReducer(HAPPENINGS),
  [RESERVATION]: createSubStateReducer(RESERVATION),
  [RESERVATION_CHECK]: createSubStateReducer(RESERVATION_CHECK),
  [RESERVATIONS]: createSubStateReducer(RESERVATIONS),
  [AUTHORIZATION]: createSubStateReducer(AUTHORIZATION),
  [USER]: createSubStateReducer(USER),
  [PRINTER]: createSubStateReducer(PRINTER),
  [REPORTS]: createSubStateReducer(REPORTS),
  [COMPANY_DATA]: createSubStateReducer(COMPANY_DATA),
  [DELAYED_TRANSACTIONS]: createSubStateReducer(DELAYED_TRANSACTIONS),
  [TICKETS]: createSubStateReducer(TICKETS),
  [POST_RESERVATION]: createSubStateReducer(POST_RESERVATION),
  [SUMMARY]: createSubStateReducer(SUMMARY),
  [GOING_RUNDATES]: createSubStateReducer(GOING_RUNDATES),
  [PRODUCTS]: createSubStateReducer(PRODUCTS),
  [TRANSACTION_DETAILS]: createSubStateReducer(TRANSACTION_DETAILS),
  [RESERVATIONS_LIST_MODAL]: createSubStateReducer(RESERVATIONS_LIST_MODAL),
  [PRICE]: createSubStateReducer(PRICE),
  [PRICE_TYPES]: createSubStateReducer(PRICE_TYPES),
  [EVENTS_POOLS]: createSubStateReducer(EVENTS_POOLS),
  [EVENTS]: createSubStateReducer(EVENTS),
  [SETTINGS]: createSubStateReducer(SETTINGS),
  [SETTLEMENTS]: createSubStateReducer(SETTLEMENTS),
  [SETTLEMENTS_RUNDATES]: createSubStateReducer(SETTLEMENTS_RUNDATES),
  [SETTLEMENTS_GENERATE]: createSubStateReducer(SETTLEMENTS_GENERATE),
  [SETTLEMENTS_CREATE]: createSubStateReducer(SETTLEMENTS_CREATE),
  [DEPENDENCIES]: createSubStateReducer(DEPENDENCIES),
  [MAILING]: createSubStateReducer(MAILING),
  [GOING_TICKETS]: createSubStateReducer(GOING_TICKETS),
  [GOING_TICKET_LIST_TICKETS]: createSubStateReducer(GOING_TICKET_LIST_TICKETS),
  [GOING_PLACES]: createSubStateReducer(GOING_PLACES),
  [GOING_POOLS]: createSubStateReducer(GOING_POOLS),
  [SEATS_IO_REPORT]: createSubStateReducer(SEATS_IO_REPORT),
  [POOL_DESCRIPTIONS]: createSubStateReducer(POOL_DESCRIPTIONS),
  [USERS]: createSubStateReducer(USERS),
  [SEATS_IO_CHARTS]: createSubStateReducer(SEATS_IO_CHARTS),
  [PERMISSIONS]: createSubStateReducer(PERMISSIONS),
  [REFUNDS_REGISTRATION]: createSubStateReducer(REFUNDS_REGISTRATION),
  [EMPIK_DETAILS]: createSubStateReducer(EMPIK_DETAILS),
  [TRANSACTIONS]: createSubStateReducer(TRANSACTIONS),
};

export default combineReducers(reducers);
