import { IPriceTicket } from '@Services/$price-api/types';
import _Store from '@Store';
import { createSelector } from 'reselect';
import { IPriceReducer } from './../types';
import get from './get';

const getTickets = createSelector<
  _Store.IState,
  IPriceReducer,
  IPriceTicket[] | null
>([get], (price) => price && price.tickets);

export default getTickets;
