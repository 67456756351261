import React from 'react';

import { Formik } from 'formik';

import Loader from '@Compo/layout/Loader/Loader';
import FormLayout from '../FormLayout';
import { IPrinterFormProps } from './SessionForm.types';
import { SessionSchema } from './SessionForm.validation';

import styles from './SessionForm.module.scss';

const SessionForm = ({
  initialValues,
  startSession,
  isLoading,
}: IPrinterFormProps) => {
  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SessionSchema}
      onSubmit={startSession}
      component={FormLayout}
    />
  );
};

export default SessionForm;
