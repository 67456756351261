import React from 'react';

import routes from '@/routes/routes';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import ListItemWithLabel from '@Compo/reusable/ListItemWithLabel';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import i18next from '@Translations/i18n';

import { normalizeSingleTransaction } from '../../Transactions.helpers';
import useStyles from './TransactionModal.styles';
import { ITransactionModalProps } from './TransactionModal.types';

const TransactionModal = ({
  closeModal,
  activeTransaction,
  history,
}: ITransactionModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Transactions.components.TransactionModal',
  });

  const classes = useStyles();

  if (!activeTransaction) {
    return null;
  }

  const normalizedTransaction = normalizeSingleTransaction(
    activeTransaction,
    i18next.t
  );

  const printAgain = () => {
    history.push(
      fillUrlWithValues(
        routes.summarySuccess,
        ':id',
        activeTransaction.transactionUUID
      )
    );
    history.go(0);
  };

  return (
    <Dialog
      open={activeTransaction ? true : false}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={12}>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={12}>
                <ListItemWithLabel
                  label={t('transactionId')}
                  value={normalizedTransaction.transactionId}
                />
                <ListItemWithLabel
                  label={t('date')}
                  value={normalizedTransaction.date}
                />
                <ListItemWithLabel
                  label={t('buyer')}
                  value={normalizedTransaction.buyer}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <ListItemWithLabel
                  label={t('salesDocument')}
                  value={normalizedTransaction.salesDocument}
                />
                {normalizedTransaction.receiptPrintout !== '-' && (
                  <ListItemWithLabel
                    label={t('receiptPrintout')}
                    value={normalizedTransaction.receiptPrintout}
                    valueElement={
                      !normalizedTransaction.receiptPrintout ? (
                        <Button
                          variant={'outlined'}
                          color={'secondary'}
                          size={'small'}
                          onClick={printAgain}
                          className={classes.button}
                        >
                          {t('print')}
                        </Button>
                      ) : undefined
                    }
                  />
                )}
                <ListItemWithLabel
                  label={t('amount')}
                  value={normalizedTransaction.amount}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={closeModal}
        >
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransactionModal;
