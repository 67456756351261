import { ISinglePoolDescriptionResponse } from '@Services/$pool-descriptions-api/types';

import { IAddPoolDescriptionModalValues } from './AddPoolDescriptionModal.types';

const mapInitialValues = (
  data: ISinglePoolDescriptionResponse | null
): IAddPoolDescriptionModalValues => {
  if (data) {
    return {
      descriptionPL: data.descriptionPL || '',
      id: data.id,
      ticketInfo: data.ticketInfo || '',
      titlePL: data.titlePL,
    };
  }

  return {
    descriptionPL: '',
    ticketInfo: '',
    titlePL: '',
  };
};

export default mapInitialValues;
