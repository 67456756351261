// Public
export const GET_REPORT = 'Zagrywki/report/GET_REPORT';
export const SET_REPORT_KEYS = 'Zagrywki/report/SET_REPORT_KEYS';
export const SET_SELECTED_KEYS = 'Zagrywki/report/SET_SELECTED_KEYS';
export const CATCH_SELECTED_KEYS = 'Zagrywki/report/CATCH_SELECTED_KEYS';
export const HANDLE_ACTIVE_PAGE = 'Zagrywki/report/HANDLE_ACTIVE_PAGE';
export const SET_FILTERS = 'Zagrywki/report/SET_FILTERS';
export const SET_REPORT_TYPE = 'Zagrywki/report/SET_REPORT_TYPE';
export const SET_COUNTS = 'Zagrywki/report/SET_COUNTS';
export const SET_TOTAL_COUNT = 'Zagrywki/report/SET_TOTAL_COUNT';
export const SET_AVAILABLE_REPORTS = 'Zagrywki/report/SET_AVAILABLE_REPORTS';
export const ADD_NEW_ACTIVE_INPUT_TO_GROUP =
  'Zagrywki/report/ADD_NEW_ACTIVE_INPUT_TO_GROUP';

export const _CAPTURE_REPORT_REQUEST =
  'Zagrywki/report/_CAPTURE_REPORT_REQUEST';
export const _CAPTURE_REPORT_SUCCESS =
  'Zagrywki/report/_CAPTURE_REPORT_SUCCESS';
export const _CAPTURE_REPORT_FAILURE =
  'Zagrywki/report/_CAPTURE_REPORT_FAILURE';
