import React, { useEffect, useState } from 'react';

import routes from '@/routes/routes';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { useIsMainWindow } from '@Misc/hooks';

const LogoutDialog = () => {
  const isMainWindow = useIsMainWindow();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation('components', {
    keyPrefix: 'App.components.LogoutDialog',
  });

  useEffect(() => {
    if (location.pathname === routes.login) {
      setOpen(isMainWindow);
    }
  }, [isMainWindow]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('description')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={'outlined'}
          size={'large'}
          color={'secondary'}
          onClick={handleClose}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialog;
