import { IAddPriceTypeInitialValues } from './AddPriceTypeModal.types';

const mapInitialValues = (
  data: IAddPriceTypeInitialValues | null
): IAddPriceTypeInitialValues => {
  if (data) {
    return data;
  }

  return {
    title: '',
    translation: false,
  };
};

export default mapInitialValues;
