const compareTimes = (timeA: string, timeB: string) => {
  const partsA = timeA.split(':');
  const partsB = timeB.split(':');

  const hourA = parseInt(partsA[0], 10);
  const hourB = parseInt(partsB[0], 10);

  if (hourA > hourB) {
    return 1;
  } else if (hourA < hourB) {
    return -1;
  } else {
    const minutesA = parseInt(partsA[1], 10);
    const minutesB = parseInt(partsB[1], 10);

    if (minutesA > minutesB) {
      return 1;
    } else if (minutesA < minutesB) {
      return -1;
    }
  }

  // times are equal
  return 0;
};

export default compareTimes;
