import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPlacesReducer } from '../types';
import getPlaces from './getPlaces';

const getPlacesSearchText = createSelector<
  _Store.IState,
  IPlacesReducer,
  string
>([getPlaces], (places) => places.searchText);

export default getPlacesSearchText;
