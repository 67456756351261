import { getType } from 'typesafe-actions';

import { setCompanyData } from '../actions';
import { IAction, ICompanyDataReducer } from '../types';

const initialState: ICompanyDataReducer = {
  city: '',
  companyName: '',
  facture: false,
  houseNumber: '',
  nip: '',
  propertyNumber: '',
  street: '',
  zipCode: '',
};

const companyDataFromReduce = (
  state: ICompanyDataReducer = initialState,
  action: IAction,
): ICompanyDataReducer => {
  switch (action.type) {
    case getType(setCompanyData):
      return action.payload;
    default:
      return state;
  }
};

export default companyDataFromReduce;
