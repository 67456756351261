import React, { useEffect, useRef } from 'react';

import GoingPaper from '@Compo/layout/Paper/GoingPaper';

import useStyles from './ReportBug.styles';

const ReportBug = () => {
  const myRef = useRef<HTMLDivElement | null>(null);
  const classes = useStyles();

  const appendForm = () => {
    const button = document.getElementById('atlwdg-trigger');

    button?.click();
    const element = document.getElementById('atlwdg-frame');
    document.body.style.overflow = 'visible';
    if (element) {
      myRef.current?.append(element);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      appendForm();
    }, 1000);
  }, [myRef]);

  return (
    <GoingPaper>
      <div className={classes.container} ref={myRef} />
    </GoingPaper>
  );
};

export default ReportBug;
