import makeStyles from '@mui/styles/makeStyles';

import colors from '@Compo/App/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
  },
  form: {
    overflowY: 'auto',
  },
  line: {
    marginBottom: theme.spacing(1),
  },
  menuItem: {
    backgroundColor: colors.other.backdrop,
    marginBottom: 0,
    marginTop: 0,
  },
  saveButton: {
    borderRadius: 4,
  },
}));

export default useStyles;
