import { createSelector } from 'reselect';

import _Store from '@Store';

import { IUsersReducer } from '../types';
import get from './get';

const getSearchTextGroups = createSelector<
  _Store.IState,
  IUsersReducer,
  string
>([get], (users) => users.search.groups);

export default getSearchTextGroups;
