import moment from 'moment';
import uuid from 'uuid';

import { IEventMailing } from '@Services/$email-api/types';

import { IMailFormValues } from './MailForm.types';

export const getInitialValues = (
  values?: IEventMailing,
  eventId?: number,
  rundateId?: number
): IMailFormValues => {
  if (values) {
    return {
      buttonLink: values.buttonLink,
      buttonText: values.buttonText,
      cloudinaryId: values.cloudinaryId || uuid(),
      dependencies: values.dependency,
      description: values.description,
      id: values.id,
      isDraft: values.status === 'roboczy',
      isSent: values.status !== 'roboczy',
      mailShipment: !!values.toSendAt,
      marketing: values.marketing,
      picture: values.picture,
      saveAndSend: false,
      startDate: moment(values.toSendAt),
      startTime: moment(values.toSendAt).format('HH:mm'),
      templateId: values.templateId,
      title: values.title,
    };
  }

  return {
    buttonLink: '',
    buttonText: '',
    cloudinaryId: uuid(),
    dependencies: [],
    description: '',
    eventId,
    isDraft: false,
    isSent: false,
    mailShipment: false,
    marketing: true,
    picture: '',
    rundateId,
    saveAndSend: false,
    startDate: moment(),
    startTime: '',
    templateId: null,
    title: '',
  };
};
