import { resetSelection, saveReservation } from '@Model/reservations/actions';
import {
  getDetails,
  getEmpikReservationDetails,
  getReservations,
} from '@Model/reservations/selectors';
import _Store from '@Store';
import format from 'date-fns/format';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import {
  IDetailsModalFromDispatch,
  IDetailsModalFromState,
  IDetailsModalValues,
} from './DetailsModal';
import DetailsModal from './DetailsModal.component';

const defaultValues: IDetailsModalValues = {
  description: null,
  discountCode: null,
  discountId: null,
  duration: null,
  email: null,
  endDate: null,
  endTime: null,
  firstName: null,
  happeningTitle: null,
  id: null,
  lastName: null,
  linkedTo: null,
  lockCode: null,
  numberOfPeople: null,
  paymentMethod: null,
  phone: null,
  price: null,
  priceType: '',
  spaceTitle: null,
  startDate: null,
  startTime: null,
  transactionProducts: [],
  uuid: null,
};

const mapStateToProps = (state: _Store.IState): IDetailsModalFromState => {
  const reservationDetails = getDetails(state);
  const reservations = getReservations(state);
  const { transactionProducts } = getEmpikReservationDetails(state);

  if (reservationDetails && typeof reservationDetails !== 'number') {
    const selectedReservation = reservations?.find(
      (reservation) => reservation.id === reservationDetails?.id
    );
    const timezoneStartDate = moment
      .utc(reservationDetails.start)
      .format('YYYY-MM-DD HH:mm:ss');
    const timezoneEndDate = moment
      .utc(reservationDetails.end)
      .format('YYYY-MM-DD HH:mm:ss');

    const startDate = moment(timezoneStartDate).toDate();

    const endDate = moment(timezoneEndDate).toDate();

    return {
      initialValues: {
        description: reservationDetails.details.description,
        discountCode: reservationDetails.details.discount
          ? reservationDetails.details.discount.code
          : '',
        discountId: reservationDetails.discountId || '',
        duration: reservationDetails.duration,
        email: reservationDetails.details.email,
        endDate: format(endDate, 'yyyy-MM-dd'),
        endTime: format(endDate, 'HH:mm'),
        firstName: reservationDetails.details.firstName,
        happeningTitle: reservationDetails.details.happening.metadata[0].title,
        id: reservationDetails.id,
        lastName: reservationDetails.details.lastName,
        linkedTo: selectedReservation?.linkedTo || null,
        lockCode: reservationDetails.lockCode,
        numberOfPeople: reservationDetails.details.numberOfPeople,
        paymentMethod: reservationDetails.details.paymentMethod || -1,
        phone: reservationDetails.details.phone,
        price: reservationDetails.details.price,
        priceType: reservationDetails.priceType,
        spaceTitle: reservationDetails.details.space.metadata[0].title,
        startDate: format(startDate, 'yyyy-MM-dd'),
        startTime: format(startDate, 'HH:mm:ss'),
        transactionProducts: transactionProducts || [],
        uuid: reservationDetails.uuid,
      },
    };
  }

  return {
    initialValues: defaultValues,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IDetailsModalFromDispatch => ({
  deselectReservation: () => dispatch(resetSelection()),
  saveReservation: (values) => dispatch(saveReservation(values)),
});

export default connect<
  IDetailsModalFromState,
  IDetailsModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(DetailsModal);
