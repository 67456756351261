import React, { ChangeEvent, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';

import { IAcceptSettlementModalProps } from './AcceptSettlementModal.types';

const AcceptSettlementModal = ({
  open,
  closeModal,
  acceptSettlement,
}: IAcceptSettlementModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'Settlements.SingleSettlementModal.components.AcceptSettlementModal',
  });

  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInvoiceNumber(event.target.value);
    if (errorMessage.length && invoiceNumber.length) {
      setErrorMessage('');
    }
  };

  const handleSubmit = () => {
    if (invoiceNumber.length) {
      acceptSettlement(invoiceNumber);
      closeModal();
      setInvoiceNumber('');
    } else {
      setErrorMessage(t('requiredField'));
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <LabelContentContainer>
          <TextField
            label={errorMessage || t('numberInvoice')}
            helperText={t('numberInvoiceHelper')}
            onChange={handleChange}
            value={invoiceNumber}
            error={!!errorMessage.length}
            fullWidth={true}
          />
        </LabelContentContainer>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={handleSubmit}
        >
          {t('acceptSettlement')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptSettlementModal;
