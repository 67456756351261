import React, { useEffect, useRef, useState } from 'react';

import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import GridUi from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import AvailabilitiesCards from '@Compo/reusable/AvailabilitiesCards';
import { IAvailabilities } from '@Compo/reusable/AvailabilitiesCards/AvailabilitiesCards.types';
import Stepper from '@Compo/reusable/Stepper';
import {
  IRuleAvailableSteps,
  IRuleStepper,
  IStepperSlugs,
} from '@Compo/reusable/Stepper/Stepper.types';
import { TRuleType } from '@Model/configurations/types';

import PriceRule from './components/PriceRule';
import PriceSettings from './components/PriceSettings';
import RuleList from './components/RuleList';
import TimeRule from './components/TimeRule';
import styles from './NewDefinedConfigurations.module.scss';
import { IDefinedConfigurationsProps } from './NewDefinedConfigurations.types';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    width: 226,
  },

  label: {
    left: 12,
    top: 9,
  },
}));

const DefinedConfigurations = ({
  mounted,
  resetFormRules,
  handleRuleTypeParam,
  getConfiguration,
  selectedRuleType,
}: IDefinedConfigurationsProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'BetterManager.NewDefinedConfigurations',
  });

  useEffect(() => {
    mounted();
  }, []);

  const classes = useStyles();

  const [stepSlug, setStepSlug] = useState<IRuleAvailableSteps>('rule-list');
  const [prevStepSlug, setPrevStepSlug] = useState<
    IRuleAvailableSteps | undefined
  >(undefined);

  const childRef = useRef<any>(null);
  const priceSettingsRef = useRef<any>(null);
  const priceRuleRef = useRef<any>(null);

  useEffect(() => {
    if (steps[stepSlug].activeStep === 0) {
      resetFormRules();
    }
  }, [stepSlug]);

  const catchRuleType = (event: SelectChangeEvent<TRuleType>) => {
    const { value } = event.target;

    handleRuleTypeParam(value as TRuleType);
    getConfiguration();
  };

  const renderFirstStep = () => {
    const availabilities: IAvailabilities[] = [
      {
        description:
          'Określa godziny otwarcia i zamknięcia przestrzeni. Każda przestrzeń musi posiadać przynajmniej jedną regułę otwarcia.',
        slug: 'time-rule',
        title: 'Reguła otwarcia',
      },
      {
        description:
          'Pozwala na określenie godzin zamknięcia danej przestrzeni.',
        slug: 'close-time-rule',
        title: 'Reguła zamknięcia',
      },
      {
        description:
          'Pozwala na ustalenie cen za rezerwację danej przestrzeni. Czas obowiązywania reguły (lub reguł cenowych) musi pokrywać się z godzinami otwarcia przestrzeni.',
        slug: 'price-rule',
        title: 'Reguła ceny',
      },
      {
        description:
          'Reguła opcjonalna. Pozwala na zmiany pojemności przestrzeni, zależnie od godzin. Brak ustawienia reguł/y pojemności spowoduje przydzielenie wszystkim slotom domyślnej pojemności ustawionej dla przestrzeni.',
        optional: true,
        slug: 'capacity-rule',
        title: 'Reguła pojemności',
      },
    ];

    return (
      <AvailabilitiesCards
        availabilities={availabilities}
        onClick={handleChangeSlug}
      />
    );
  };

  const renderRuleListHeader = () => {
    return (
      <div className={classes.container}>
        <FormControl variant="standard" fullWidth={true}>
          <Select
            value={selectedRuleType}
            onChange={catchRuleType}
            variant="filled"
          >
            <MenuItem value={'price'}>{t('priceRule')}</MenuItem>
            <MenuItem value={'time'}>{t('openTimeRule')}</MenuItem>
            <MenuItem value={'close'}>{t('closeTimeRule')}</MenuItem>
            <MenuItem value={'capacity'}>{t('capacityRule')}</MenuItem>
          </Select>
          <FormHelperText>{t('ruleSelectHelper')}</FormHelperText>
        </FormControl>
      </div>
    );
  };

  const steps: IRuleStepper = {
    ['rule-list']: {
      activeStep: 0,
      nextText: 'Dodaj regułę',
      onNext: () => handleChangeSlug('rule'),
      render: () => <RuleList />,
      renderHeader: renderRuleListHeader(),
      showStepper: false,
      stepName: 'Dodaj regułę',
      withTopMargin: true,
    },
    ['rule']: {
      activeStep: 1,
      description:
        'Określ reguły, od których zależeć będą godziny otwarcia, ceny poszczególnych slotów oraz pojemność danej przestrzeni.',
      render: () => renderFirstStep(),
      showStepper: false,
      stepName: 'Dodaj regułę',
      title: 'Dodaj regułę',
      withTopMargin: true,
    },
    ['time-rule']: {
      activeStep: 2,
      description:
        'Uzupełnij nazwę i parametry dotyczące czasu obowiązywania reguły. Pola wymagane zostały oznaczone gwiazdką.',
      maxSteps: 2,
      nextText: 'Zapisz',
      onNext: () => {
        if (childRef && childRef.current) {
          childRef.current.submitForm();
        }
      },
      prevText: 'Wróć',
      render: () => <TimeRule ref={childRef} setStepSlug={setStepSlug} />,
      showStepper: true,
      stepName: 'Ustawienia podstawowe',
      title: 'Ustawienia podstawowe',
    },
    ['close-time-rule']: {
      activeStep: 2,
      description:
        'Uzupełnij nazwę i parametry dotyczące czasu obowiązywania reguły. Pola wymagane zostały oznaczone gwiazdką.',
      maxSteps: 2,
      nextText: 'Zapisz',
      onNext: () => {
        if (childRef && childRef.current) {
          childRef.current.submitForm();
        }
      },
      prevText: 'Wróć',
      render: () => (
        <TimeRule ref={childRef} setStepSlug={setStepSlug} isClosed={true} />
      ),
      showStepper: true,
      stepName: 'Ustawienia podstawowe',
      title: 'Ustawienia podstawowe',
    },
    ['price-rule']: {
      activeStep: 2,
      description:
        'Uzupełnij nazwę i parametry dotyczące czasu obowiązywania reguły. Pola wymagane zostały oznaczone gwiazdką.',
      maxSteps: 4,
      nextText: 'Dalej',
      onNext: () => {
        if (childRef && childRef.current) {
          childRef.current.validateAndSaveToState('price-types', 'price');
        }
      },
      prevText: 'Wróć',
      render: () => <TimeRule ref={childRef} setStepSlug={handleChangeSlug} />,
      showStepper: true,
      stepName: 'Ustawienia podstawowe',
      title: 'Ustawienia podstawowe',
    },
    ['price-types']: {
      activeStep: 3,
      description:
        'Określ domyślną cenę slotu i (opcjonalnie) ustaw ceny dla innych typów biletów, w ramach obowiązywania tej reguły.',
      maxSteps: 4,
      nextText: 'Dalej',
      onNext: () => {
        if (priceRuleRef?.current) {
          priceRuleRef?.current.submitForm('price-settings');
        }
      },
      prevText: 'Wróć',
      render: () => (
        <PriceRule ref={priceRuleRef} setStepSlug={handleChangeSlug} />
      ),
      showStepper: true,
      stepName: 'Cena i rodzaje biletów',
      title: 'Cena i rodzaje biletów',
    },
    ['price-settings']: {
      activeStep: 4,
      description:
        'Jeśli chcesz, określ zasady dotyczące upsell oraz biletów grupowych. Pamiętaj, że dotyczą one jedynie ceny domyślnej.',
      maxSteps: 4,
      nextText: 'Zapisz',
      onNext: () => {
        if (priceSettingsRef && priceSettingsRef.current) {
          priceSettingsRef.current.submitForm();
        }
      },
      prevText: 'Wróć',
      render: () => (
        <PriceSettings ref={priceSettingsRef} setStepSlug={handleChangeSlug} />
      ),
      showStepper: true,
      stepName: 'Dodatkowe ustawienia',
      title: 'Dodatkowe ustawienia',
    },
    ['capacity-rule']: {
      activeStep: 2,
      description:
        'Uzupełnij nazwę i parametry dotyczące czasu obowiązywania reguły. Pola wymagane zostały oznaczone gwiazdką.',
      maxSteps: 2,
      nextText: 'Zapisz',
      onNext: () => {
        if (childRef && childRef.current) {
          childRef.current.submitForm();
        }
      },
      prevText: 'Wróć',
      render: () => (
        <TimeRule
          ref={childRef}
          setStepSlug={handleChangeSlug}
          capacity={true}
        />
      ),
      showStepper: true,
      stepName: 'Ustawienia podstawowe',
      title: 'Ustawienia podstawowe',
    },
  };

  const handleChangeSlug = (slug: IStepperSlugs) => {
    setPrevStepSlug(stepSlug);
    setStepSlug(slug);
  };

  return (
    <GridUi
      container={true}
      className={cn(styles.stepperReservation, styles.BitTop)}
    >
      <Stepper
        activeStep={steps[stepSlug].activeStep}
        stepSlug={stepSlug}
        steps={steps}
        hideStepper={!steps[stepSlug].showStepper}
        setStepSlug={handleChangeSlug}
        prevSlug={prevStepSlug}
        onNext={steps[stepSlug].onNext}
        nextText={steps[stepSlug].nextText}
        prevText={steps[stepSlug].prevText}
        renderHeader={steps[stepSlug].renderHeader}
      >
        {steps[stepSlug].render()}
      </Stepper>
    </GridUi>
  );
};

export default DefinedConfigurations;
