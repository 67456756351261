import React, { FC } from 'react';

import { View } from '@react-pdf/renderer';

import styles from './TableBody.styles';

const TableBody: FC = ({ children }) => {
  return <View style={styles.table}>{children}</View>;
};

export default TableBody;
