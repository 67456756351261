import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IMailFormValues } from '@Compo/Mail/components/MailForm/MailForm.types';
import {
  IEventMailing,
  IEventSaveSuccessPayload,
} from '@Services/$email-api/types';

import {
  _DELETE_EVENT_MAILING_FAILURE,
  _DELETE_EVENT_MAILING_REQUEST,
  _DELETE_EVENT_MAILING_SUCCESS,
  _GET_EVENT_MAILING_FAILURE,
  _GET_EVENT_MAILING_REQUEST,
  _GET_EVENT_MAILING_SUCCESS,
  _GET_SINGLE_EVENT_MAILING_FAILURE,
  _GET_SINGLE_EVENT_MAILING_REQUEST,
  _GET_SINGLE_EVENT_MAILING_SUCCESS,
  _SAVE_EVENT_MAILING_FAILURE,
  _SAVE_EVENT_MAILING_REQUEST,
  _SAVE_EVENT_MAILING_SUCCESS,
  _SEND_EVENT_MAILING_FAILURE,
  _SEND_EVENT_MAILING_REQUEST,
  _SEND_EVENT_MAILING_SUCCESS,
  _SEND_EVENT_TEST_MAILING_FAILURE,
  _SEND_EVENT_TEST_MAILING_REQUEST,
  _SEND_EVENT_TEST_MAILING_SUCCESS,
  DELETE_EVENT_MAIL,
  EDIT_EVENT_MAIL,
  HANDLE_ACTIVE_PAGE,
  HANDLE_SEARCH_TEXT,
  MOUNTED,
  SAVE_EVENT_MAIL,
  SELECT_EVENT_MAIL,
  SEND_EVENT_MAIL,
  SEND_EVENT_TEST_MAIL,
} from './../constants/actions';
import { IEventMailingSuccessPayload, IMailTestPayload } from './../types';

export const getEventMailing = createAsyncAction(
  _GET_EVENT_MAILING_REQUEST,
  _GET_EVENT_MAILING_SUCCESS,
  _GET_EVENT_MAILING_FAILURE
)<undefined, IEventMailingSuccessPayload, Error>();

export const saveMail = createAsyncAction(
  _SAVE_EVENT_MAILING_REQUEST,
  _SAVE_EVENT_MAILING_SUCCESS,
  _SAVE_EVENT_MAILING_FAILURE
)<IMailFormValues, IEventSaveSuccessPayload, Error>();

export const getSingleEventMailing = createAsyncAction(
  _GET_SINGLE_EVENT_MAILING_REQUEST,
  _GET_SINGLE_EVENT_MAILING_SUCCESS,
  _GET_SINGLE_EVENT_MAILING_FAILURE
)<number, IEventMailing, Error>();

export const deleteEventMailing = createAsyncAction(
  _DELETE_EVENT_MAILING_REQUEST,
  _DELETE_EVENT_MAILING_SUCCESS,
  _DELETE_EVENT_MAILING_FAILURE
)<number, [], Error>();

export const sendEventMailing = createAsyncAction(
  _SEND_EVENT_MAILING_REQUEST,
  _SEND_EVENT_MAILING_SUCCESS,
  _SEND_EVENT_MAILING_FAILURE
)<number, [], Error>();

export const sendEventTestMailing = createAsyncAction(
  _SEND_EVENT_TEST_MAILING_REQUEST,
  _SEND_EVENT_TEST_MAILING_SUCCESS,
  _SEND_EVENT_TEST_MAILING_FAILURE
)<IMailTestPayload, [], Error>();

export const selectEventMail = createStandardAction(
  SELECT_EVENT_MAIL
)<IMailFormValues | null>();
export const save = createStandardAction(SAVE_EVENT_MAIL)<IMailFormValues>();
export const mounted = createStandardAction(MOUNTED)();
export const edit = createStandardAction(EDIT_EVENT_MAIL)<number>();
export const deleteMail = createStandardAction(DELETE_EVENT_MAIL)<number>();
export const send = createStandardAction(SEND_EVENT_MAIL)<number>();
export const sendTest =
  createStandardAction(SEND_EVENT_TEST_MAIL)<IMailTestPayload>();
export const handleActivePage =
  createStandardAction(HANDLE_ACTIVE_PAGE)<number>();
export const handleSearchText =
  createStandardAction(HANDLE_SEARCH_TEXT)<string>();
