import React from 'react';

import { Dialog } from '@mui/material';

import TicketsListForm from './../TicketsListForm';
import { ITicketsListModalProps } from './TicketsListModal.types';

const TicketsListModal = ({
  showModal,
  closeModal,
}: ITicketsListModalProps) => {
  return (
    <Dialog
      open={showModal}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <TicketsListForm />
    </Dialog>
  );
};
export default TicketsListModal;
