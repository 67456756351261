import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getPlaces } from '@Model/going/places/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { resetError, resetLoading, setLoading } from './../actions';
import { GOING_PLACES } from './../constants/constants';

export const setUpStateWhenEventsPlacesFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([getPlaces.request])),
    mergeMap$(() => {
      return [resetError(GOING_PLACES), setLoading(GOING_PLACES)];
    })
  );
};

export const setLoaderWhenEventsPlacesFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getPlaces.success, getPlaces.failure], action)
    ),
    map$(() => resetLoading(GOING_PLACES))
  );
};

export const setErrorOnEventsPlacesFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getPlaces.failure)),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
