import _Store from '@Store';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { get } from '@Model/authorization/selectors';
import { handleActiveConfiguration } from '@Model/configurations/actions';
import { get as getConfigurations } from '@Model/configurations/selectors';
import { IConfiguration } from '@Model/configurations/types';
import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => ({
  activeConfiguration: getConfigurations(state).activeConfiguration,
  partners: get(state).userInfo.partners,
});

const mapDispatchToProps = (dispatch: Dispatch): IFormLayoutFromDispatch => ({
  handleActiveConfiguration: (configurations: IConfiguration | null) => {
    dispatch(handleActiveConfiguration(configurations));
  },
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
