import { createSelector } from 'reselect';

import _Store from '@Store';

import { IRefundsRegistrationFilters } from '@Services/$refunds-registration-api/types';

import { IRefundsRegistrationReducer } from './../types';
import get from './get';

const getRefundsRegistrationsFilter = createSelector<
  _Store.IState,
  IRefundsRegistrationReducer,
  IRefundsRegistrationFilters
>([get], (refundsRegistration) => refundsRegistration.filter);

export default getRefundsRegistrationsFilter;
