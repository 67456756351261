import { createSelector } from 'reselect';

import _Store from '@Store';

import { IRuleTypesReducer } from '../types';

import get from './get';

const getPage = createSelector<_Store.IState, IRuleTypesReducer, number>(
  [get],
  (products) => products.page
);

export default getPage;
