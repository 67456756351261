import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import { getPartnerId, getUserInfo } from '@Model/authorization/selectors';
import { get } from '@Model/happenings/selectors';
import { allPermissions } from '@Model/state/constants/permissions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_SUCCESS } from '@Model/toasts/constants/constants';
import { IHappeningsResponse } from '@Services/$happenings-api/types';

import {
  getHappenings,
  mounted,
  remove,
  resetState,
  setPartnerID,
} from './../actions';

export const requestHappeningsWhenListMounted: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    withLatestFrom(state$),
    mergeMap$(([_, state]) => {
      const partnerId = getPartnerId(state);
      if (!get(state).partnerId && partnerId) {
        return of$(
          resetState(),
          setPartnerID(partnerId),
          getHappenings.request()
        );
      }
      return of$(resetState(), getHappenings.request());
    })
  );
};

export const fetchHappeningsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { happeningsApi }
) => {
  return action$.pipe(
    filter$(isActionOf([getHappenings.request, setPartnerID])),
    withLatestFrom(state$),
    mergeMap$(([_, state]) => {
      const { partnerId } = get(state);
      const { permissions } = getUserInfo(state);
      if (!permissions.includes(allPermissions.access_happenings_read)) {
        return EMPTY$;
      }

      return from$(happeningsApi.getHappenings(partnerId)).pipe(
        map$((data: IHappeningsResponse) => {
          return getHappenings.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => happeningsApi.cancelHappenings())
          )
        ),
        catchError$((error: Error) => {
          return of$(getHappenings.failure(error));
        })
      );
    })
  );
};

/*
 * @TODO: Change this flow to routine.
 */
export const removeHappeningWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { happeningsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(remove)),
    withLatestFrom(state$),
    mergeMap$(([action, state]) => {
      return from$(happeningsApi.removeHappening(action.payload.slug)).pipe(
        mergeMap$(() => {
          const happenings = get(state);

          let name = '';

          if (happenings && happenings.items && happenings.totalCount) {
            const items = happenings.items.filter((item) => {
              const metadata = getMetadataForLanguage(item.metadata);

              const shouldRemove = metadata.slug === action.payload.slug;

              if (shouldRemove) {
                name = metadata.title;
              }

              return !shouldRemove;
            });

            return [
              getHappenings.success({
                items,
                totalCount: happenings.totalCount - 1,
              }),
              addToast(`Usunięto ${name}`, TYPE_SUCCESS),
            ];
          }

          return EMPTY$;
        }),
        catchError$((error: Error) => {
          return of$(getHappenings.failure(error));
        })
      );
    })
  );
};
