import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  table: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default styles;
