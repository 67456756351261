import { LOCATION_CHANGE } from 'connected-react-router';
import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { showSuccessModal } from '@Model/modal/actions';
import { getRefundsRegistrationsFilter } from '@Model/refundsRegistration/selectors';

import {
  catchEditMultipleRefundRegistrationStatuses,
  catchEditRefundRegistration,
  catchGetSingleRefundRegistration,
  changeRefundsRegistrationsFilter,
  changeRefundsRegistrationsPage,
  changeRefundsRegistrationsSearch,
  clearRefundsRegistrationsFilter,
  editMultipleRefundRegistrationStatuses,
  editRefundRegistration,
  getRefundsRegistrationMetadata,
  getRefundsRegistrations,
  getSingleRefundsRegistration,
  handleActiveRefundRegistration,
  mounted,
} from '../actions';

export const requestRefundsRegistrationsWhenMounted: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    mergeMap$(() => {
      return of$(
        getRefundsRegistrations.request(1),
        getRefundsRegistrationMetadata.request()
      );
    })
  );
};

export const fetchRefundsRegistrationsWhenPageChanged: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(changeRefundsRegistrationsPage)),
    mergeMap$((action) => {
      return of$(getRefundsRegistrations.request(action.payload));
    })
  );
};

export const fetchRefundsRegistrationsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { refundsRegistrationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getRefundsRegistrations.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const filter = getRefundsRegistrationsFilter(state);

      return from$(
        refundsRegistrationApi.getRefundsRegistrations(action.payload, filter)
      ).pipe(
        map$((data) => {
          return getRefundsRegistrations.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => refundsRegistrationApi.cancelRefundsRegistration())
          )
        ),
        catchError$((error: Error) => {
          return of$(getRefundsRegistrations.failure(error));
        })
      );
    })
  );
};

export const fetchRefundsRegistrationsMetadataWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { refundsRegistrationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getRefundsRegistrationMetadata.request)),
    mergeMap$(() => {
      return from$(
        refundsRegistrationApi.getRefundsRegistrationsMetadata()
      ).pipe(
        mergeMap$((data) => {
          return of$(getRefundsRegistrationMetadata.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => refundsRegistrationApi.cancelRefundsRegistration())
          )
        ),
        catchError$((error: Error) => {
          return of$(getRefundsRegistrationMetadata.failure(error));
        })
      );
    })
  );
};

export const changeRefundsRegistrationsFiltersWhenRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(
      isActionOf([
        changeRefundsRegistrationsFilter,
        clearRefundsRegistrationsFilter,
        changeRefundsRegistrationsSearch,
      ])
    ),
    mergeMap$(() => {
      return of$(getRefundsRegistrations.request(1));
    })
  );
};

export const catchGetSingleRefundsRegistrationWhenRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(catchGetSingleRefundRegistration)),
    mergeMap$((action) => {
      return of$(getSingleRefundsRegistration.request(action.payload));
    })
  );
};

export const getSingleRefundsRegistrationWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { refundsRegistrationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSingleRefundsRegistration.request)),
    mergeMap$((action) => {
      return from$(
        refundsRegistrationApi.getSingleRefundsRegistration(action.payload)
      ).pipe(
        mergeMap$((data) => {
          return of$(
            getSingleRefundsRegistration.success(data),
            handleActiveRefundRegistration(data)
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => refundsRegistrationApi.cancelRefundsRegistration())
          )
        ),
        catchError$((error: Error) =>
          of$(getSingleRefundsRegistration.failure(error))
        )
      );
    })
  );
};

export const catchEditRefundsRegistrationWhenRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(catchEditRefundRegistration)),
    mergeMap$((action) => {
      return of$(editRefundRegistration.request(action.payload));
    })
  );
};

export const editRefundRegistrationWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { refundsRegistrationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(editRefundRegistration.request)),
    mergeMap$((action) => {
      return from$(
        refundsRegistrationApi.editRefundsRegistration(action.payload)
      ).pipe(
        mergeMap$((data) => {
          return of$(
            showSuccessModal(),
            getRefundsRegistrations.request(1),
            editRefundRegistration.success(data),
            handleActiveRefundRegistration(null)
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => refundsRegistrationApi.cancelRefundsRegistration())
          )
        ),
        catchError$((error: Error) =>
          of$(editRefundRegistration.failure(error))
        )
      );
    })
  );
};

export const catchEditMultipleRefundsRegistrationStatusesWhenRequested: _Store.IEpic =
  (action$) => {
    return action$.pipe(
      filter$(isActionOf(catchEditMultipleRefundRegistrationStatuses)),
      mergeMap$((action) => {
        return of$(
          editMultipleRefundRegistrationStatuses.request(action.payload)
        );
      })
    );
  };

export const editMultipleRefundRegistrationStatusesWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { refundsRegistrationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(editMultipleRefundRegistrationStatuses.request)),
    mergeMap$((action) => {
      return from$(
        refundsRegistrationApi.editMultipleStatues(action.payload)
      ).pipe(
        mergeMap$((data) => {
          return of$(
            showSuccessModal(),
            getRefundsRegistrations.request(1),
            editMultipleRefundRegistrationStatuses.success(data)
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => refundsRegistrationApi.cancelRefundsRegistration())
          )
        ),
        catchError$((error: Error) =>
          of$(editRefundRegistration.failure(error))
        )
      );
    })
  );
};
