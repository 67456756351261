import React, { FC } from 'react';

import { Button } from '@mui/material';
import cn from 'classnames';

import useStyles from './BigButton.styles';
import { IBigButtonProps } from './BigButton.types';

const BigButton: FC<IBigButtonProps> = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={cn(classes.button, props.selected && classes.selected)}
    >
      {props.children}
    </Button>
  );
};

export default BigButton;
