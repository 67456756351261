import { createSelector } from 'reselect';

import _Store from '@Store';

import { IMeta } from '@Services/$metadata-api/types';

import { IMetadataReducer } from './../types';
import get from './get';

const getMeta = createSelector<_Store.IState, IMetadataReducer, IMeta>(
  [get],
  (metadata) => {
    return metadata.meta;
  }
);

export default getMeta;
