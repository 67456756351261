import React from 'react';

import AddingConfiguration from '@Compo/BetterManager/AddingConfiguration';
import Grid from '@Compo/layout/Grid';

const RulesPage = () => (
  <Grid>
    <AddingConfiguration />
  </Grid>
);

export default RulesPage;
