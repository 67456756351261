import color from './icons/colorize.svg';
import center from './icons/format-align-center.svg';
import justify from './icons/format-align-justify.svg';
import left from './icons/format-align-left.svg';
import right from './icons/format-align-right.svg';
import bold from './icons/format-bold.svg';
import outdent from './icons/format-indent-decrease.svg';
import indent from './icons/format-indent-increase.svg';
import italic from './icons/format-italic.svg';
import unordered from './icons/format-list-bulleted.svg';
import ordered from './icons/format-list-numbered.svg';
import strikethrough from './icons/format-strikethrough.svg';
import underline from './icons/format-underlined.svg';
import link from './icons/insert-link.svg';
import unlink from './icons/link-off.svg';
import image from './icons/photo-size-select-actual.svg';
import redo from './icons/redo.svg';
import undo from './icons/undo.svg';

const config = {
  colorPicker: {
    icon: color,
    popupClassName: 'colorPopup',
  },
  history: {
    redo: { icon: redo },
    undo: { icon: undo },
  },
  image: {
    alignmentEnabled: false,
    icon: image,
    popupClassName: 'imagePopup',
  },
  inline: {
    bold: {
      icon: bold,
    },
    italic: {
      icon: italic,
    },
    options: ['bold', 'italic', 'underline', 'strikethrough'],
    strikethrough: {
      icon: strikethrough,
    },
    underline: {
      icon: underline,
    },
  },
  link: {
    link: { icon: link },
    popupClassName: 'linkPopup',
    unlink: { icon: unlink },
  },
  list: {
    indent: { icon: indent },
    options: ['indent', 'outdent', 'unordered', 'ordered'],
    ordered: { icon: ordered },
    outdent: { icon: outdent },
    unordered: { icon: unordered },
  },
  options: [
    'fontFamily',
    'fontSize',
    'inline',
    'textAlign',
    'list',
    'link',
    'colorPicker',
    'image',
    'history',
  ] as const,
  textAlign: {
    center: { icon: center },
    justify: { icon: justify },
    left: { icon: left },
    right: { icon: right },
  },
};

export default config;
