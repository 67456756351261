import React from 'react';

import { FormControl, FormLabel, Grid, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { IFormLayoutProps } from '../FormLayout.types';

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(2),
  },
}));

export const CapacityField = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'BetterManager.NewAddingConfiguration.components.ConfigurationModal.Components.FormLayout.Components.CapacityField',
  });

  const classes = useStyles();
  const { values, touched, errors, handleChange, handleBlur } = props;
  return (
    <Grid item={true} xs={12}>
      <FormControl variant="standard" component="fieldset" fullWidth={true}>
        <FormLabel component="legend" className={classes.label}>
          {t('ruleCapacityLabel')}
        </FormLabel>
        <TextField
          variant="standard"
          id="capacity"
          label={(touched.capacity && errors.capacity) || t('ruleCapacity')}
          helperText={t('ruleCapacityHelper')}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.capacity}
          name="capacity"
          error={!!(touched.capacity && errors.capacity)}
          type="number"
          fullWidth={true}
        />
      </FormControl>
    </Grid>
  );
};
