import React, { useCallback, useEffect } from 'react';

import {
  Button,
  ButtonGroup,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GenericTable from '@Compo/reusable/GenericTable';
import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import TableActions from '@Compo/reusable/TableActions';
import { Colors } from '@Constants/Colors';
import { TypographyVariant } from '@Constants/Variants/Typography';

import PermissionSegment from './../PermissionSegment';
import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = ({
  handleSubmit,
  handleChange,
  handleBlur,
  errors,
  touched,
  values,
  submitForm,
  setFieldValue,
  handleClose,
  permissions,
  mounted,
  closeHandler,
  isCustomAccess,
  activeFilter,
  handleActiveFilter,
  isLoading,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Users.components.GroupsTable.components.FormLayout',
  });

  const filters = [
    { name: t('all'), value: undefined },
    { name: t('places'), value: 'places' },
    { name: t('going'), value: 'going' },
  ];

  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('md'));

  const handleAddAccess = useCallback(
    (id: number) => {
      if (values.permissionsIds.includes(id)) {
        setFieldValue(
          'permissionsIds',
          values.permissionsIds.filter((value) => value !== id)
        );
      } else {
        setFieldValue('permissionsIds', [...values.permissionsIds, id]);
      }
    },
    [values.permissionsIds]
  );

  useEffect(() => {
    mounted();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={3}>
            {!isCustomAccess && (
              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <TextField
                    id="name"
                    label={(touched.name && errors.name) || t('name')}
                    helperText={t('nameHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    name="name"
                    error={!!(touched.name && errors.name)}
                    fullWidth={true}
                  />
                </LabelContentContainer>
              </Grid>
            )}
            <Grid item={true} xs={12}>
              <Typography
                variant={TypographyVariant.body1}
                color={Colors.textSecondary}
              >
                {!isCustomAccess ? t('assignAccess') : t('customAccess')}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <GenericTable>
                <TableActions>
                  <Grid
                    container={true}
                    spacing={1}
                    justifyContent="space-between"
                  >
                    <Grid item={true}>
                      <Typography variant={TypographyVariant.body1}>
                        {t('access')}
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <ButtonGroup
                        color="primary"
                        size="small"
                        orientation={isMobile ? 'vertical' : 'horizontal'}
                      >
                        {filters.map((filter) => (
                          <Button
                            key={filter.name}
                            className={cn(
                              activeFilter === filter.value && classes.active
                            )}
                            onClick={() => handleActiveFilter(filter.value)}
                          >
                            {filter.name}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </TableActions>
                <Divider />
                {isLoading ? (
                  <div className={classes.loader}>
                    <Loader />
                  </div>
                ) : (
                  <Grid container={true} spacing={2}>
                    <Grid item={true} xs={12}>
                      {permissions.map((value, index) => (
                        <PermissionSegment
                          key={value.id}
                          data={value}
                          handleAddAccess={handleAddAccess}
                          values={values}
                          isLastElement={index + 1 === permissions.length}
                        />
                      ))}
                    </Grid>
                  </Grid>
                )}
              </GenericTable>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeHandler ? closeHandler : handleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
