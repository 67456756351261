import React, { ChangeEvent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import {
  ICategory,
  ILocalProduct,
  IShipmentFee,
  IViews,
} from '@Model/products/types';

import CategoryModal from './CategoryModal';
import InvoiceModal from './InvoiceModal';
import { IProductsProps, ISelectViews } from './Products.types';
import ProductsModal from './ProductsModal';
import ShipmentFeeModal from './ShipmentFeeModal';
import ZohoModal from './ZohoModal';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    paddingRight: theme.spacing(3),
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
    },
  },
  buttonFirst: {
    marginRight: theme.spacing(2),
  },

  buttonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
  },

  buttonWithoutPadding: {
    paddingRight: theme.spacing(1.25),
  },

  container: {
    marginBottom: theme.spacing(2),
    width: 220,
  },

  item: {
    marginBottom: 0,
    marginTop: 0,
  },

  label: {
    left: 12,
    top: 9,
  },
}));

// TODO: translate

const views: ISelectViews[] = [
  { view: 'products', name: 'Produkty' },
  { view: 'categories', name: 'Kategorie' },
  { view: 'courier', name: 'Opłaty kurierskie' },
  { view: 'zohoProducts', name: 'Produkty ZOHO' },
  { view: 'invoiceProducts', name: 'Fakturownia' },
];

const Products = ({
  isLoading,
  mounted,
  productsViews,
  setProductsView,
  selectedView,
  addCategory,
  activeCategory,
  activeLocalProduct,
  addLocalProduct,
  activeShipmentFee,
  activeZohoProduct,
  activeInvoiceProduct,
  addShipmentFee,
  meta,
  handleActivePage,
  handleActiveCategory,
  handleActiveLocalProduct,
  handleActiveShipmentFee,
  handleActiveZohoProduct,
  handleActiveInvoiceProduct,
  removeCategory,
  removeProduct,
}: IProductsProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Products',
  });

  const classes = useStyles();
  const [page, setPage] = useState(meta.page);
  const [count, setCount] = useState(meta.size);

  const keys = Object.keys(productsViews) as IViews[];
  keys.forEach((key) => {
    productsViews[key].forEach(
      (product: ILocalProduct | ICategory | IShipmentFee) => {
        Object.assign(product, {
          actions: (
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={cn(
                classes.button,
                classes.buttonFirst,
                classes.buttonWithoutPadding
              )}
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(product.id || -1);
              }}
            >
              {t('delete')}
            </Button>
          ),
        });
      }
    );
  });

  useEffect(() => {
    setProductsView('products');
    mounted();
  }, []);

  useEffect(() => {
    setCount(meta.size);
  }, [meta]);

  const getSelectedItem = (name: string) => {
    switch (selectedView) {
      case 'categories':
        return (
          productsViews.categories.find(
            (category) => category.id === Number(name)
          ) || null
        );
      case 'products':
        return (
          productsViews.products.find(
            (product) => product.id === Number(name)
          ) || null
        );
      case 'courier':
        return (
          productsViews.courier.find(
            (courier) => courier.id === Number(name)
          ) || null
        );
      case 'zohoProducts':
        return productsViews.zohoProducts.find(
          (product) => product.id === Number(name)
        );
      case 'invoiceProducts':
        return productsViews.invoiceProducts.find(
          (product) => product.id === Number(name)
        );
      default:
        return null;
    }
  };

  const handleEdit = (name: string) => {
    switch (selectedView) {
      case 'categories':
        return handleActiveCategory(
          (getSelectedItem(name) as ICategory) || null
        );
      case 'products':
        return handleActiveLocalProduct(
          (getSelectedItem(name) as ILocalProduct) || null
        );
      case 'courier':
        return handleActiveShipmentFee(
          (getSelectedItem(name) as IShipmentFee) || null
        );
      case 'zohoProducts':
        return handleActiveZohoProduct(
          (getSelectedItem(name) as ILocalProduct) || null
        );
      case 'invoiceProducts':
        return handleActiveInvoiceProduct(Number(name));
      default:
        return;
    }
  };

  const handleDelete = (id: number) => {
    switch (selectedView) {
      case 'categories':
        return removeCategory(id);
      case 'products':
        return removeProduct(id);
      case 'courier':
        return removeProduct(id);
      default:
        return;
    }
  };

  const changeView = (e: SelectChangeEvent<IViews>) => {
    setProductsView(e.target.value as IViews);
  };

  const addAction = () => {
    switch (selectedView) {
      case 'categories':
        return addCategory();
      case 'products':
        return addLocalProduct();
      case 'courier':
        return addShipmentFee();
      default:
        return;
    }
  };

  const onPageChanged = (currentPage: number, currentCount: number) => {
    setPage(currentPage);
    setCount(currentCount);
    handleActivePage(currentPage, currentCount);
  };

  const renderHeader = () => {
    return (
      <div className={classes.container}>
        <FormControl variant="standard" fullWidth={true}>
          <InputLabel className={classes.label}>{t('view')}</InputLabel>
          <Select value={selectedView} onChange={changeView} variant="filled">
            {views.map((view) => (
              <MenuItem key={view.view} value={view.view}>
                {view.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{t('viewHelper')}</FormHelperText>
        </FormControl>
      </div>
    );
  };

  const columns = {
    allProducts: [],
    categories: [
      { id: 'name', label: t('name') },
      { id: 'id', label: t('id') },
      { id: 'colorName', label: t('color') },
      { id: 'actions', label: t('action') },
    ],
    courier: [
      { id: 'name', label: t('name') },
      { id: 'id', label: t('id') },
      { id: 'price', label: t('price') },
      { id: 'actions', label: t('action') },
    ],
    invoiceProducts: [
      { id: 'name', label: t('name') },
      { id: 'id', label: t('id') },
      { id: 'price', label: t('price') },
      { id: 'categoryName', label: t('category') },
      { id: 'description', label: t('description') },
      { id: 'emailConfirmation', label: t('emailConfirmation') },
      { id: 'autocomplete', label: t('autocomplete') },
      { id: 'vat', label: t('vat') },
      { id: 'fiscal', label: t('fiscal') },
      { id: 'slug', label: t('slug') },
    ],
    products: [
      { id: 'name', label: t('name') },
      { id: 'id', label: t('id') },
      { id: 'availableToSale', label: t('availability') },
      { id: 'price', label: t('price') },
      { id: 'categoryName', label: t('category') },
      { id: 'description', label: t('description') },
      { id: 'emailConfirmation', label: t('emailConfirmation') },
      { id: 'emailTemplate', label: t('emailTemplate') },
      { id: 'autocomplete', label: t('autocomplete') },
      { id: 'vat', label: t('vat') },
      { id: 'fiscal', label: t('fiscal') },
      { id: 'slug', label: t('slug') },
      { id: 'actions', label: t('action') },
    ],
    zohoProducts: [
      { id: 'name', label: t('name') },
      { id: 'id', label: t('id') },
      { id: 'price', label: t('price') },
      { id: 'categoryName', label: t('category') },
      { id: 'description', label: t('description') },
      { id: 'emailConfirmation', label: t('emailConfirmation') },
      { id: 'autocomplete', label: t('autocomplete') },
      { id: 'vat', label: t('vat') },
      { id: 'fiscal', label: t('fiscal') },
      { id: 'slug', label: t('slug') },
    ],
  };

  if (isLoading) {
    return (
      <GoingPaper header={renderHeader()}>
        <Loader />
      </GoingPaper>
    );
  }
  return (
    <GoingPaper header={renderHeader()}>
      <ProductsModal
        open={!!activeLocalProduct}
        isEditing={!!activeLocalProduct?.id}
      />
      <ZohoModal
        open={!!activeZohoProduct}
        isEditing={!!activeZohoProduct?.id}
      />
      <InvoiceModal
        open={!!activeInvoiceProduct}
        isEditing={!!activeInvoiceProduct?.id}
      />
      <CategoryModal open={!!activeCategory} isEditing={!!activeCategory?.id} />
      <ShipmentFeeModal
        open={!!activeShipmentFee}
        isEditing={!!activeShipmentFee?.id}
      />

      <GenericTable>
        <TableActions>
          <div />
          {selectedView !== 'zohoProducts' &&
            selectedView !== 'invoiceProducts' && (
              <div className={classes.buttonsContainer}>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  startIcon={<AddIcon />}
                  className={cn(classes.button, classes.buttonFirst)}
                  onClick={() => addAction()}
                >
                  {t('add')}
                </Button>
              </div>
            )}
        </TableActions>
        <TableUi
          rows={productsViews[selectedView]}
          columns={columns[selectedView]}
          total={meta.total}
          activePage={page - 1}
          rowsPerPage={count}
          handleChangePage={(e, p) => {
            onPageChanged(p + 1, count);
          }}
          handleChangeRowsPerPage={(e) => {
            onPageChanged(1, Number(e.target.value));
          }}
          disableCheckBox={true}
          onRowClick={handleEdit}
        />
      </GenericTable>
    </GoingPaper>
  );
};

export default Products;
