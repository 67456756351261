import React, { useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import { IColumns } from '@Compo/reusable/TableUi/TableUi.types';
import translateDefaultPriceType from '@Misc/helpers/strings/translateDefaultPrice';

import AddPriceTypeModal from './AddPriceTypeModal';
import useStyles from './PriceTypes.styles';
import { IPriceTypesProps } from './PriceTypes.types';

const PriceTypes = ({
  mounted,
  priceTypes,
  selectPriceType,
  isLoading,
  totalCount,
  page,
  count,
  changePageOrCount,
  deletePriceType,
}: IPriceTypesProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'PriceTypes',
  });

  const classes = useStyles();

  useEffect(() => {
    mounted();
  }, []);

  const addPriceModal = () => {
    selectPriceType({ title: '', description: '', translation: false });
  };

  // TODO:
  const columns: IColumns[] = [
    { id: 'id', label: '', hided: true },
    { id: 'title', label: 'Nazwa' },
    { id: 'description', label: 'Opis' },
    { id: 'actions', label: 'Akcje', width: 184 },
  ];

  const actionsButtons = (id?: number) => (
    <Grid container={true} spacing={3}>
      <Grid item={true}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={cn(classes.button, classes.buttonWithoutPadding)}
        >
          {t('edit')}
        </Button>
      </Grid>
      <Grid item={true}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={cn(classes.button, classes.buttonWithoutPadding)}
          onClick={(e) => {
            e.stopPropagation();
            if (id) {
              deletePriceType(id);
            }
          }}
        >
          {t('delete')}
        </Button>
      </Grid>
    </Grid>
  );

  const catchEdit = (name: string) => {
    const foundedPriceType = priceTypes.find(
      (type) => Number(name) === type.id
    );

    if (foundedPriceType) {
      const { title, description, id, titleEN, descriptionEN } =
        foundedPriceType;
      const body = {
        description,
        descriptionEN,
        id,
        title: title || '',
        titleEN,
        translation: !!(titleEN?.length || descriptionEN?.length),
      };

      selectPriceType(body);
    }
  };

  const changePage = (e: unknown, selectedPage: number) => {
    changePageOrCount({ page: selectedPage + 1, count });
  };

  const changeCount = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    changePageOrCount({ page, count: Number(e.target.value) });
  };

  const normalizedPriceTypes = priceTypes.map((type) => ({
    actions: actionsButtons(type.id),
    description: type.description || '-',
    id: type.id,
    title: translateDefaultPriceType(type.title),
  }));

  if (isLoading) {
    return (
      <GoingPaper>
        <Loader />
      </GoingPaper>
    );
  }

  return (
    <GoingPaper>
      <AddPriceTypeModal />
      <GenericTable>
        <TableActions>
          <div />
          <div className={classes.buttonsContainer}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              startIcon={<AddIcon />}
              className={cn(classes.button, classes.buttonFirst)}
              onClick={addPriceModal}
            >
              {t('add')}
            </Button>
          </div>
        </TableActions>
        <TableUi
          rows={normalizedPriceTypes}
          columns={columns}
          total={totalCount}
          activePage={page - 1}
          rowsPerPage={count}
          handleChangePage={changePage}
          disableCheckBox={true}
          onRowClick={catchEdit}
          handleChangeRowsPerPage={changeCount}
        />
      </GenericTable>
    </GoingPaper>
  );
};

export default PriceTypes;
