import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import _Store from '@Store';

import { get } from '@Model/authorization/selectors';
import { catchSavePartner, catchSavePixel } from '@Model/settings/actions';
import { getFbPixel } from '@Model/settings/selectors';
import getPartner from '@Model/settings/selectors/getPartner';
import { allPermissions } from '@Model/state/constants';

import Settings from './Settings.component';
import { prepareInitialValues } from './Settings.helpers';
import { ISettingsFromDispatch, ISettingsFromState } from './Settings.types';

const mapStateToProps = (state: _Store.IState): ISettingsFromState => {
  const fbPixel = getFbPixel(state);
  const partner = getPartner(state);
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    initialValues: prepareInitialValues({
      fbPixel: fbPixel?.pixelId,
      gtmId: partner?.gtmId,
      tokenPixel: fbPixel?.token,
    }),
    permissionPartnerWrite: permissions.includes(
      allPermissions.access_partner_write
    ),
    permissionPixelWrite: permissions.includes(
      allPermissions.access_pixel_write
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ISettingsFromDispatch => ({
  savePartner: (gtmId) => dispatch(catchSavePartner({ gtmId })),
  savePixel: (pixelId, token) => dispatch(catchSavePixel({ pixelId, token })),
});

export default connect<
  ISettingsFromState,
  ISettingsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
