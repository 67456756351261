import _Store from '@Store';
import { createSelector } from 'reselect';
import { IConsentsList } from '../types';
import getFormIoConsentsList from './getFormIoConsentsList';

const getFormConsentsListEntryTokens = createSelector<
  _Store.IState,
  IConsentsList,
  string[]
>(
  [getFormIoConsentsList],
  (state) => state.entryTokens,
);

export default getFormConsentsListEntryTokens;
