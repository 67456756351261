import { getType } from 'typesafe-actions';
import { resetSelection, selectEvent, selectPool } from './../actions';
import { IAction, ISelectedReducer } from './../types';

const initialState: ISelectedReducer = { event: null, pool: null };

const availabilitiesReducer = (
  state: ISelectedReducer = initialState,
  action: IAction
): ISelectedReducer => {
  switch (action.type) {
    case getType(selectEvent):
      return {
        ...state,
        event: action.payload,
      };
    case getType(selectPool):
      return {
        ...state,
        pool: action.payload,
      };
    case getType(resetSelection):
      return initialState;
    default:
      return state;
  }
};

export default availabilitiesReducer;
