import { createSelector } from 'reselect';

import _Store from '@Store';

import { IRundatesReducer } from '../types';

import getRundates from './getRundates';

const getRundatesSearchText = createSelector<
  _Store.IState,
  IRundatesReducer,
  string | undefined
>([getRundates], (rundates) => rundates.filter?.search);

export default getRundatesSearchText;
