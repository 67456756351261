import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  form: {
    width: '100%',
  },
}));

export default useStyles;
