import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import { Colors } from '@Constants/Colors';
import { TypographyVariant } from '@Constants/Variants/Typography';

import TicketsTable from './components/TicketsTable';
import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = ({
  handleSubmit,
  values,
  handleBlur,
  handleChange,
  touched,
  errors,
  submitForm,
  closeModal,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates.TicketsList.components.FormLayout',
  });

  const classes = useStyles();
  return (
    <>
      <DialogTitle>{values.id ? t('editTitle') : t('addTitle')}</DialogTitle>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <TextField
                  id="name"
                  label={(touched.name && errors.name) || t('name')}
                  helperText={t('nameHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  name="name"
                  error={!!(touched.name && errors.name)}
                  fullWidth={true}
                />
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <TextField
                  id="ticketInfo"
                  label={
                    (touched.ticketInfo && errors.ticketInfo) ||
                    t('description')
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ticketInfo}
                  name="ticketInfo"
                  error={!!(touched.ticketInfo && errors.ticketInfo)}
                  fullWidth={true}
                  variant="outlined"
                  multiline={true}
                  minRows={10}
                  maxRows={10}
                />
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.scannable}
                      onChange={handleChange}
                      color="primary"
                      size="medium"
                      name="scannable"
                    />
                  }
                  label={
                    <>
                      <Typography variant={TypographyVariant.body1}>
                        {t('scannable')}
                      </Typography>
                      <Typography
                        variant={TypographyVariant.caption}
                        color={Colors.textSecondary}
                      >
                        {t('scannableHelper')}
                      </Typography>
                    </>
                  }
                />
              </LabelContentContainer>
            </Grid>
            {values.id && (
              <Grid item={true} xs={12}>
                <TicketsTable ticketListId={values.id} />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {values.id ? t('save') : t('addList')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
