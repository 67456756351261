import { createSelector } from 'reselect';

import _Store from '@Store';

import { IUser } from '@Services/$users-api/types';

import { IUsersReducer } from '../types';
import get from './get';

const getUsers = createSelector<_Store.IState, IUsersReducer, IUser[]>(
  [get],
  (users) => users.users
);

export default getUsers;
