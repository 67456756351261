import _Store from '@Store';
import { createSelector } from 'reselect';
import { IAuthorizationReducer } from '../types';
import get from './get';

const getFormioAccess = createSelector<
  _Store.IState,
  IAuthorizationReducer,
  boolean
>([get], (add) => {
  const { parameters } = add.userInfo;
  if (parameters) {
    const entryListUrl = parameters.some((param) =>
      param.hasOwnProperty('form_io_entry_list_url_v2')
    );
    const entryListId = parameters.some((param) =>
      param.hasOwnProperty('form_io_entry_list_id_v2')
    );
    const distinctDatabase = parameters.some((param) =>
      param.hasOwnProperty('form_io_distinct_database_id')
    );
    return distinctDatabase && entryListId && entryListUrl;
  }

  return false;
});

export default getFormioAccess;
