import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import { Currency } from '@Constants/Currency';
import { IPoolDescriptions } from '@Services/$going-rundate-api/types';

import { IFormLayoutProps } from './../../FormLayout.types';

const GeneralSettings = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  poolDescriptions,
  setFieldValue,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'Rundates.Pools.components.FormLayout.components.GeneralSettings',
  });

  const [description, setDescription] = useState<
    IPoolDescriptions | undefined
  >();

  useEffect(() => {
    const selectedDescription = poolDescriptions.find(
      (desc) => desc.id === values.poolDescriptionId
    );
    setDescription(selectedDescription);
  }, [poolDescriptions, values.poolDescriptionId]);

  return (
    <Grid item={true} xs={12}>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <Grid container={true} spacing={4}>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  error={
                    !!(touched.poolDescriptionId && errors.poolDescriptionId)
                  }
                >
                  <Autocomplete
                    disablePortal={false}
                    onBlur={handleBlur}
                    onChange={(e, value) =>
                      setFieldValue('poolDescriptionId', value?.id)
                    }
                    id="poolDescriptionId"
                    options={poolDescriptions}
                    value={description || null}
                    getOptionLabel={(option) => option.titlePL}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        {...params}
                        error={
                          !!(
                            touched.poolDescriptionId &&
                            errors.poolDescriptionId
                          )
                        }
                        label={
                          (touched.poolDescriptionId &&
                            errors.poolDescriptionId) ||
                          t('poolDescription')
                        }
                      />
                    )}
                  />
                  <FormHelperText>{t('poolDescriptionHelper')}</FormHelperText>
                </FormControl>
              </LabelContentContainer>
            </Grid>

            <Grid item={true} xs={12} md={6}>
              <LabelContentContainer>
                <TextField
                  variant="standard"
                  id="emissionSize"
                  label={
                    (touched.emissionSize && errors.emissionSize) ||
                    t('emissionSize')
                  }
                  helperText={t('emissionSizeHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.emissionSize}
                  name="emissionSize"
                  error={!!(touched.emissionSize && errors.emissionSize)}
                  fullWidth={true}
                />
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <LabelContentContainer>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  error={!!(touched.currency && errors.currency)}
                >
                  <InputLabel>
                    {(touched.currency && errors.currency) || t('currency')}
                  </InputLabel>
                  <Select
                    variant="standard"
                    value={values.currency}
                    onChange={handleChange}
                    defaultValue={Currency.PLN}
                    fullWidth={true}
                    name="currency"
                    onBlur={handleBlur}
                  >
                    {Object.values(Currency).map((currency: Currency) => (
                      <MenuItem value={currency} key={currency}>
                        {t('currency' + currency)}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{t('currencyHelper')}</FormHelperText>
                </FormControl>
              </LabelContentContainer>
            </Grid>

            <Grid item={true} xs={12} md={6}>
              <LabelContentContainer>
                <TextField
                  variant="standard"
                  id="price"
                  label={(touched.price && errors.price) || t('price')}
                  helperText={t('priceHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.price}
                  name="price"
                  error={!!(touched.price && errors.price)}
                  fullWidth={true}
                />
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <LabelContentContainer>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  error={!!(touched.vat && errors.vat)}
                >
                  <InputLabel>
                    {(touched.vat && errors.vat) || t('vat')}
                  </InputLabel>
                  <Select
                    variant="standard"
                    value={values.vat}
                    onChange={handleChange}
                    defaultValue={8}
                    fullWidth={true}
                    name="vat"
                    onBlur={handleBlur}
                  >
                    <MenuItem value={0}>0%</MenuItem>
                    <MenuItem value={5}>5%</MenuItem>
                    <MenuItem value={8}>8%</MenuItem>
                    <MenuItem value={23}>23%</MenuItem>
                  </Select>
                  <FormHelperText>{t('vatHelper')}</FormHelperText>
                </FormControl>
              </LabelContentContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneralSettings;
