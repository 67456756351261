import { createSelector } from 'reselect';

import { IHappeningReducer } from '@Model/happening/types';
import _Store from '@Store';
import getHappening from './getHappening';

const getSpaceIsScopeView = createSelector<
  _Store.IState,
  IHappeningReducer,
  boolean
>([getHappening], (happening) => {
  if (happening && happening.spaces && happening.spaces.length > 0) {
    return happening.spaces[0].isScopeView || false;
  }

  return false;
});

export default getSpaceIsScopeView;
