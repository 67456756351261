import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { save } from '@Model/printer/actions';
import getActivePrinter from '@Model/printer/selectors/getActivePrinter';
import { IPrinter } from '@Model/printer/types/index';

import PrinterForm from './PrinterForm.component';
import {
  IPrinterFormDispatch,
  IPrinterFormFromState,
} from './PrinterForm.types';

const defaultValues: IPrinter = {
  drawer: false,
  firmware: null,
  id: null,
  openSession: false,
  partnerId: null,
  printerId: '',
  reservationDetails: false,
  saleUrl: '',
};

const mapStateToProps = (state: _Store.IState): IPrinterFormFromState => {
  const activePrinter = getActivePrinter(state);

  if (activePrinter) {
    return {
      initialValues: activePrinter,
    };
  }

  return {
    initialValues: defaultValues,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPrinterFormDispatch => {
  return {
    save: (Printer: IPrinter) => {
      dispatch(save(Printer));
    },
  };
};

export default connect<
  IPrinterFormFromState,
  IPrinterFormDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PrinterForm);
