import { boolean, number, object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const VALUE_TO_LOW = 'Wartość za niska';

const configuration = {
  counter: number().min(1, VALUE_TO_LOW),
  online: boolean(),
  onsite: boolean(),
  type: string().required(REQUIRED_VALIDATOR_TEXT),
  value: number()
    .min(0, VALUE_TO_LOW)
    .required(REQUIRED_VALIDATOR_TEXT),
};

export const AddingPriceTypeSchema = object().shape(configuration);
