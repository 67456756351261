import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { catchNewErrorMessage } from '@Misc/helpers/api/catchHttpError';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR, TYPE_SUCCESS } from '@Model/toasts/constants/constants';
import _Store from '@Store';

import { deleteAssets } from '../actions';

const RUNDATE_ASSETS_DELETED_TEXT = 'Usunięto zdjęcia';

export const deleteAssetsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingCloudinaryApi }
) => {
  return action$.pipe(
    filter$(isActionOf(deleteAssets)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      return from$(goingCloudinaryApi.deleteAssets(action.payload)).pipe(
        mergeMap$(() => [addToast(RUNDATE_ASSETS_DELETED_TEXT, TYPE_SUCCESS)]),
        catchError$((error: Error) => {
          const { message } = catchNewErrorMessage(error);

          return of$(addToast(message, TYPE_ERROR));
        })
      );
    })
  );
};
