import { createSelector } from 'reselect';

import _Store from '@Store';
import { IActiveSettlement, ISettlementsReducer } from './../types';
import get from './get';

const getActiveSettlement = createSelector<
  _Store.IState,
  ISettlementsReducer,
  IActiveSettlement
>([get], (settlement) => settlement.activeSettlement);

export default getActiveSettlement;
