import { Dispatch } from 'react';

import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import { handleActivePage } from '@Model/reports/actions';
import { getReports } from '@Model/reports/selectors';
import { REPORTS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import ReportsTable from './ReportsTable.component';
import {
  IReportsTableFromDispatch,
  IReportsTableFromState,
} from './ReportsTable.types';

const mapStateToProps = (state: _Store.IState): IReportsTableFromState => {
  const {
    counts,
    keys,
    items: reports,
    selectedKeys,
    selectedReport,
    activePage,
    totalCount,
    perPage,
  } = getReports(state);
  return {
    activePage,
    counts,
    isLoading: getLoading(REPORTS)(state),
    keys,
    perPage,
    reports,
    selectedKeys,
    selectedReport,
    totalCount,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IReportsTableFromDispatch => ({
  handleActivePage: (page: number) => dispatch(handleActivePage(page)),
});

export default connect<
  IReportsTableFromState,
  IReportsTableFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ReportsTable);
