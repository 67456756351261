import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    paddingRight: theme.spacing(3),
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
    },
  },
  buttonFirst: {
    marginRight: theme.spacing(2),
  },

  buttonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
  rulesSelect: {
    marginBottom: theme.spacing(2),
  },

  item: {
    marginBottom: 0,
    marginTop: 0,
  },

  buttonWithoutPadding: {
    paddingRight: theme.spacing(1.25),
  },
}));

export default useStyles;
