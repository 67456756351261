import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { get as getConfigurationsSelector } from '@Model/configurations/selectors';
import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { showSuccessModal } from '@Model/modal/actions';
import { IConfigurationsResponse } from '@Services/$configurations-api/types';

import {
  catchDeleteConfiguration,
  catchSaveConfiguration,
  catchSaveConfigurationV2,
  deleteConfiguration,
  getConfiguration,
  getConfigurations,
  handleActiveConfiguration,
  handleConfigurationsActivePage,
  handleConfigurationsRowsPerPage,
  mounted,
  resetState,
  saveConfiguration,
} from './../actions';

export const requestConfigurationsWhenMounted: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const configurations = getConfigurationsSelector(state);
      if (
        configurations &&
        configurations.items &&
        configurations.items.length
      ) {
        return EMPTY$;
      }
      return of$(resetState(), getConfigurations.request());
    })
  );
};

export const requestToGetConfigurationsWhenAction: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getConfiguration)),
    mergeMap$(() => {
      return of$(getConfigurations.request());
    })
  );
};

export const requestToDeleteConfigurationsWhenAction: _Store.IEpic = (
  action$,
  state$,
  { configurationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchDeleteConfiguration)),
    mergeMap$((action) => {
      return from$(
        configurationsApi.deleteConfiguration(
          action.payload.type,
          action.payload.id
        )
      ).pipe(
        mergeMap$(() => {
          return [
            deleteConfiguration.success(),
            showSuccessModal(),
            handleActiveConfiguration(null),
          ];
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => configurationsApi.cancelConfigurations())
          )
        ),
        catchError$((error: Error) => {
          return of$(deleteConfiguration.failure(error));
        })
      );
    })
  );
};

export const fetchConfigurationsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { configurationsApi }
) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getConfigurations.request,
        handleConfigurationsActivePage,
        handleConfigurationsRowsPerPage,
        deleteConfiguration.success,
      ])
    ),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { activePage, perPage, searchParam, selectedRuleType } =
        getConfigurationsSelector(state);

      const partnerId = getHappeningPartnerId(state);

      return from$(
        configurationsApi.getConfigurations(
          activePage + 1,
          perPage,
          searchParam,
          selectedRuleType,
          partnerId
        )
      ).pipe(
        map$((data: IConfigurationsResponse) => {
          return getConfigurations.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => configurationsApi.cancelConfigurations())
          )
        ),
        catchError$((error: Error) => {
          return of$(getConfigurations.failure(error));
        })
      );
    })
  );
};

export const saveNewConfigurationWhenAction: _Store.IEpic = (
  action$,
  state$,
  { configurationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchSaveConfiguration)),
    mergeMap$((action) => {
      return from$(configurationsApi.saveConfiguration(action.payload)).pipe(
        mergeMap$(() => {
          return of$(
            saveConfiguration.success(),
            getConfigurations.request(),
            showSuccessModal()
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => configurationsApi.cancelConfigurations())
          )
        ),
        catchError$((error: Error) => {
          return of$(saveConfiguration.failure(error));
        })
      );
    })
  );
};
export const saveNewConfigurationWhenActionV2: _Store.IEpic = (
  action$,
  state$,
  { configurationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchSaveConfigurationV2)),
    mergeMap$((action) => {
      return from$(
        configurationsApi.saveConfiguration(
          action.payload.configuration,
          action.payload.type
        )
      ).pipe(
        mergeMap$(() => {
          return of$(
            saveConfiguration.success(),
            getConfigurations.request(),
            showSuccessModal()
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => configurationsApi.cancelConfigurations())
          )
        ),
        catchError$((error: Error) => {
          return of$(saveConfiguration.failure(error));
        })
      );
    })
  );
};
