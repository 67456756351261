import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getEmpikReservationDetails } from '@Model/reservations/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { resetError, resetLoading, setLoading } from '../actions';
import { EMPIK_DETAILS } from '../constants/constants';

export const setUpStateWhenEmpikDetailsFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([getEmpikReservationDetails.request])),
    mergeMap$(() => [resetError(EMPIK_DETAILS), setLoading(EMPIK_DETAILS)])
  );
};

export const setLoaderWhenEmpikDetailsFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          getEmpikReservationDetails.success,
          getEmpikReservationDetails.failure,
        ],
        action
      )
    ),
    map$(() => resetLoading(EMPIK_DETAILS))
  );
};

export const setErrorOnEmpikDetailsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getEmpikReservationDetails.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
