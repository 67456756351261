import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { handleActiveFilter } from '@Model/settings/actions';
import { getReportParameters } from '@Model/settings/selectors';
import _Store from '@Store';

import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => ({
  reportParameters: getReportParameters(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormLayoutFromDispatch => ({
  handleActiveReportFilter: (filter) => dispatch(handleActiveFilter(filter)),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
