import { Selector } from 'reselect';

import _Store from '@Store';

import { IRundatesReducer } from '../types';

const getRundates: Selector<_Store.IState, IRundatesReducer> = (state) =>
  state.goingRundates.rundates;

export default getRundates;
