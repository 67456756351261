import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError, {
  catchNewErrorMessage,
} from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IDiscounts } from '@Model/discounts/types';

import {
  IDiscountsResponse,
  IEventDiscountBody,
  IEventsDiscountsResponse,
  IEventsPartnerResponse,
  IPartnersResponse,
  IPoolsPartnerResponse,
  IRundatesPartnerResponse,
} from './types';

class DiscountsApi {
  private static getActivePrefix(active?: string): string {
    if (!active || active === '-1') {
      return '';
    } else if (active === '1') {
      return '&active=1';
    }
    return '&active=0';
  }
  private static getSearchPrefix(searchText?: string): string {
    if (searchText && searchText.length) {
      return `&filter[code]=${searchText}`;
    }
    return '';
  }

  private static getDiscountsUrl(
    page: number,
    count: number,
    partnerId?: string,
    active?: string,
    searchText?: string
  ): string {
    const activePrefix = this.getActivePrefix(active);
    const searchPrefix = this.getSearchPrefix(searchText);

    if (partnerId) {
      return `${config.api.baseUrl}admin/discounts?page=${page}&count=${count}&partnerIds[]=${partnerId}${activePrefix}${searchPrefix}`;
    }
    return `${config.api.baseUrl}admin/discounts?page=${page}&count=${count}${activePrefix}${searchPrefix}`;
  }

  private static getEventsDiscountsUrl(): string {
    return `${config.api.cmsServices}price-reduction-product/discount`;
  }

  private static getPartnersUrl(): string {
    return `${config.api.cmsServices}partners`;
  }

  private static getEventsPartnerUrl(): string {
    return `${config.api.cmsServices}events-partner`;
  }

  private static getRundatesPartnerUrl(): string {
    return `${config.api.cmsServices}rundates-partner`;
  }

  private static getPoolsPartnerUrl(): string {
    return `${config.api.cmsServices}pools-partner`;
  }

  private cancelTokenDiscounts?: CancelTokenSource;

  public getDiscounts(
    page: number,
    count: number = 20,
    partnerId?: string,
    active?: string,
    searchText?: string
  ): Promise<IDiscountsResponse> {
    return new Promise<IDiscountsResponse>((resolve, reject) => {
      this.cancelTokenDiscounts = axios.CancelToken.source();
      axios
        .get(
          DiscountsApi.getDiscountsUrl(
            page,
            count,
            partnerId,
            active,
            searchText
          ),
          {
            cancelToken: this.cancelTokenDiscounts.token,
          }
        )
        .then(getData)
        .then((response: IDiscountsResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getEventsDiscounts(
    page: number,
    count: number,
    partnerId?: string,
    searchText?: string
  ): Promise<IEventsDiscountsResponse> {
    return new Promise<IEventsDiscountsResponse>((resolve, reject) => {
      this.cancelTokenDiscounts = axios.CancelToken.source();
      axios
        .get(DiscountsApi.getEventsDiscountsUrl(), {
          cancelToken: this.cancelTokenDiscounts.token,
          params: {
            code: searchText?.length ? searchText : undefined,
            count,
            page,
            partnerId,
          },
        })
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public saveEventDiscount(body: IEventDiscountBody): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenDiscounts = axios.CancelToken.source();
      if (body.id) {
        axios
          .put(
            DiscountsApi.getEventsDiscountsUrl(),
            { ...body },
            { cancelToken: this.cancelTokenDiscounts.token }
          )
          .then(getData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(catchNewErrorMessage(error)));
      } else {
        axios
          .post(
            DiscountsApi.getEventsDiscountsUrl(),
            { ...body },
            { cancelToken: this.cancelTokenDiscounts.token }
          )
          .then(getData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(catchNewErrorMessage(error)));
      }
    });
  }

  public getPartners(
    page: number,
    count: number,
    searchText?: string
  ): Promise<IPartnersResponse> {
    return new Promise<IPartnersResponse>((resolve, reject) => {
      this.cancelTokenDiscounts = axios.CancelToken.source();
      axios
        .get(DiscountsApi.getPartnersUrl(), {
          cancelToken: this.cancelTokenDiscounts.token,
          params: {
            count,
            'filter[all]': searchText,
            page,
          },
        })
        .then(getData)
        .then((response) => {
          resolve({ data: response.data, meta: response.meta });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getEventsPartner(
    page: number,
    count: number,
    searchText?: string
  ): Promise<IEventsPartnerResponse> {
    return new Promise<IEventsPartnerResponse>((resolve, reject) => {
      this.cancelTokenDiscounts = axios.CancelToken.source();
      axios
        .get(DiscountsApi.getEventsPartnerUrl(), {
          cancelToken: this.cancelTokenDiscounts.token,
          params: {
            count,
            'filter[all]': searchText,
            page,
          },
        })
        .then(getData)
        .then((response) => {
          resolve({ data: response.data, meta: response.meta });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getRundatesPartner(
    page: number,
    count: number,
    searchText?: string
  ): Promise<IRundatesPartnerResponse> {
    return new Promise<IRundatesPartnerResponse>((resolve, reject) => {
      this.cancelTokenDiscounts = axios.CancelToken.source();
      axios
        .get(DiscountsApi.getRundatesPartnerUrl(), {
          cancelToken: this.cancelTokenDiscounts.token,
          params: {
            count,
            'filter[all]': searchText || undefined,
            page,
          },
        })
        .then(getData)
        .then((response) => {
          resolve({ data: response.data, meta: response.meta });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getPoolsPartner(
    page: number,
    count: number,
    searchText?: string
  ): Promise<IPoolsPartnerResponse> {
    return new Promise<IPoolsPartnerResponse>((resolve, reject) => {
      this.cancelTokenDiscounts = axios.CancelToken.source();
      axios
        .get(DiscountsApi.getPoolsPartnerUrl(), {
          cancelToken: this.cancelTokenDiscounts.token,
          params: {
            count,
            'filter[all]': searchText,
            page,
          },
        })
        .then(getData)
        .then((response) => {
          resolve({ data: response.data, meta: response.meta });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public normalizeDiscounts(response: IDiscountsResponse): IDiscounts {
    return {
      items: response.items,
      totalCount: response.totalCount,
    };
  }

  public cancelDiscounts() {
    if (this.cancelTokenDiscounts) {
      this.cancelTokenDiscounts.cancel();
      this.cancelTokenDiscounts = undefined;
    }
  }
}

export default new DiscountsApi();
