import api from './api';
import cloudinary from './cloudinary';
import cms from './cms';
import firebase from './firebase';
import resizer from './resizer';
import seats from './seats';

const config = {
  api,
  cloudinary,
  cms,
  firebase,
  resizer,
  seats,
};

export default config;
