import { createSelector } from 'reselect';

import _Store from '@Store';

import { IMetadataReducer } from './../types';
import get from './get';

const getSearchText = createSelector<_Store.IState, IMetadataReducer, string>(
  [get],
  (metadata) => {
    return metadata.searchText;
  }
);

export default getSearchText;
