import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '@Compo/reusable/ConfirmModal';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import { ITickets } from '@Services/$going-rundate-api/types';

import PreviewTicketModal from './../PreviewTicket/Modal';
import TicketModal from './../TicketModal';
import useStyles from './TicketsTable.styles';
import { ITicketsTableProps, ITicketsTableTypes } from './TicketsTable.types';

const TicketsTable = ({
  addTicket,
  ticketListId,
  tickets,
  fetchTickets,
  isLoading,
  meta,
  fetchSingleTicket,
  deleteTicket,
  revertTicket,
  handleSearchText,
  searchText,
  deleteMultipleTickets,
}: ITicketsTableProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'Rundates.TicketsList.components.FormLayout.components.TicketsTable',
  });

  const totalCount = meta.total;
  const activePage = meta.page - 1;
  const perPage = meta.size;

  const classes = useStyles();
  const [type, setType] = useState<ITicketsTableTypes>('active');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState<number | null>(null);
  const [timeOut, setTimeOut] = useState<NodeJS.Timeout | null>(null);
  const [isSeats, setIsSeats] = useState(false);
  const [localSelected, setLocalSelected] = useState<ITickets[]>([]);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleActivePage = (event: unknown, page: number) => {
    fetchTickets({
      id: ticketListId,
      isDeleted: !isButtonActive,
      page: page + 1,
      size: perPage,
    });
  };
  const catchHandleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.currentTarget;
    handleSearchText(value);

    if (timeOut) {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(() => {
        fetchTickets({
          id: ticketListId,
          isDeleted: !isButtonActive,
          page: 1,
          size: perPage,
        });
      }, 1000)
    );
  };
  const catchChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    fetchTickets({
      id: ticketListId,
      isDeleted: !isButtonActive,
      page: 1,
      size: Number(event.target.value),
    });
  };
  const catchPreviewTicket = (id: string) => {
    if (type === 'active') {
      fetchSingleTicket(Number(id));
    }
  };
  const addNewTicket = () => {
    addTicket(ticketListId);
  };
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const openDeleteModal = () => setShowDeleteModal(true);
  const handleConfirmDeleteModal = () => {
    if (selectedTicketId) {
      deleteTicket(selectedTicketId);
      setShowDeleteModal(false);
    }
  };

  const isButtonActive = type === 'active';

  const columns = [
    { id: 'id', label: t('id') },
    { id: 'firstname', label: t('name') },
    { id: 'lastname', label: t('lastName') },
    { id: 'entryToken', label: t('ticketCode') },
    { id: 'ticketsNum', label: t('count') },
    { id: 'email', label: t('email') },
    { id: 'validated', label: t('isValidated') },
    { id: 'deliveryStatus', label: t('sendStatus') },
    { id: 'actions', label: '' },
  ];

  const handleActionButtonClick = (id: number) => {
    if (type === 'active') {
      openDeleteModal();
      setSelectedTicketId(id);
    } else {
      revertTicket(id);
    }
  };

  const handleDeleteOrRevertButton = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number,
    isInvitationSeatsIo: boolean
  ) => {
    event.stopPropagation();
    handleActionButtonClick(id);
    if (isInvitationSeatsIo) {
      setIsSeats(true);
    } else {
      setIsSeats(false);
    }
  };

  const setOwnSelected = (selected: ITickets[]) => {
    setLocalSelected(selected);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleMultipleDelete = () => {
    deleteMultipleTickets(localSelected.map((selected) => selected.id));
  };

  const normalizedTickets = tickets.map((ticket) => ({
    ...ticket,
    actions: (
      <Button
        variant="outlined"
        size="small"
        color="primary"
        className={classes.buttonWithoutPadding}
        disabled={ticket.isInvitationSeatsIo && type === 'deleted'}
        onClick={(e) =>
          handleDeleteOrRevertButton(e, ticket.id, ticket.isInvitationSeatsIo)
        }
      >
        {type === 'active' ? t('delete') : t('revert')}
      </Button>
    ),
  }));

  useEffect(() => {
    handleSearchText('');
    fetchTickets({
      id: ticketListId,
      isDeleted: !isButtonActive,
      page: 1,
      size: perPage,
    });
  }, [isButtonActive]);

  return (
    <Grid container={true} spacing={2}>
      <TicketModal />
      <PreviewTicketModal />
      <ConfirmModal
        open={showDeleteModal}
        title={t('deleteModalTitle')}
        question={
          isSeats
            ? t('deleteModalDescriptionSeats')
            : t('deleteModalDescription')
        }
        handleClose={handleCloseDeleteModal}
        onCancel={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteModal}
      />
      <Grid item={true} xs={12} container={true} justifyContent="center">
        <ButtonGroup variant="outlined" color="primary" size="large">
          <Button
            className={cn(isButtonActive && classes.activeButton)}
            onClick={() => setType('active')}
          >
            {t('activeTickets')}
          </Button>
          <Button
            className={cn(!isButtonActive && classes.activeButton)}
            onClick={() => setType('deleted')}
          >
            {t('deleteTickets')}
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid item={true} xs={12}>
        <GenericTable>
          <TableActions>
            <TextField
              variant="standard"
              name="search"
              helperText={t('search')}
              value={searchText}
              onChange={catchHandleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="secondary" />
                  </InputAdornment>
                ),
              }} />
            {isButtonActive && (
              <div className={classes.buttonsContainer}>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  startIcon={<ArrowDropDownIcon />}
                  className={cn(classes.button, classes.buttonFirst)}
                  onClick={handleToggle}
                  ref={anchorRef}
                >
                  {t('action')}
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  startIcon={<AddIcon />}
                  className={cn(classes.button, classes.buttonFirst)}
                  onClick={addNewTicket}
                >
                  {t('add')}
                </Button>
              </div>
            )}
          </TableActions>

          <Popper
            open={open}
            transition={true}
            disablePortal={true}
            anchorEl={anchorRef.current}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      <MenuItem
                        className={classes.item}
                        onClick={handleMultipleDelete}
                      >
                        {t('delete')}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <TableUi
            rows={normalizedTickets}
            columns={columns}
            total={totalCount}
            activePage={activePage}
            rowsPerPage={perPage}
            handleChangePage={handleActivePage}
            handleChangeRowsPerPage={catchChangeRowsPerPage}
            onRowClick={catchPreviewTicket}
            isLoading={isLoading}
            setOwnSelected={setOwnSelected}
            selectedItems={localSelected}
            forceCheckboxSelection={true}
          />
        </GenericTable>
      </Grid>
    </Grid>
  );
};

export default TicketsTable;
