import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import {
  ICategory,
  IGetCategoriesSuccessPayload,
  IGetLocalProductsSuccessPayload,
  IGetProductsPayload,
  IGetProductsPayloadSuccess,
  IGetShipmentFeesSuccessPayload,
  ILocalProduct,
  IProductBody,
} from '@Model/products/types';

import { IGetSingleProductsResponse, INormalizedProducts } from './types';

class ProductsApi {
  private static getProductsUrl(productPayload: IGetProductsPayload): string {
    return `${config.api.baseUrl}spaces/${productPayload.spaceId}/products?dateTime=${productPayload.dateTime}`;
  }
  private static getAllProductsUrl(): string {
    return `${config.api.baseUrl}admin/products`;
  }

  private static getAllLocalProductsUrl(): string {
    return `${config.api.cmsServices}proxy-products/api/v1/products`;
  }
  private static getAllCategoriesUrl(): string {
    return `${config.api.cmsServices}proxy-products/api/v1/item-categories`;
  }

  private static getRemoveProductUrl(id: number): string {
    return `${config.api.cmsServices}proxy-products/api/v1/products/${id}/delete`;
  }
  private static getRemoveCategoryUrl(id: number): string {
    return `${config.api.cmsServices}proxy-products/api/v1/item-categories/${id}/delete`;
  }
  private static getSingleProductUrl(id: number): string {
    return `${config.api.cmsServices}proxy-products/api/v1/products/${id}`;
  }

  private cancelTokenProducts?: CancelTokenSource;

  public getProducts<T extends IGetProductsPayloadSuccess>(
    productPayload: IGetProductsPayload
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenProducts = axios.CancelToken.source();

      axios
        .get(ProductsApi.getProductsUrl(productPayload), {
          cancelToken: this.cancelTokenProducts.token,
        })
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }
  public getAllProducts<T extends IGetProductsPayloadSuccess>(
    partnerId?: string
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenProducts = axios.CancelToken.source();

      axios
        .get(ProductsApi.getAllProductsUrl(), {
          cancelToken: this.cancelTokenProducts.token,
          params: {
            partnerId,
          },
        })
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getLocalProducts(
    count: number,
    page: number,
    partnerId?: string,
    withoutProvider?: boolean,
    name?: string
  ): Promise<IGetLocalProductsSuccessPayload> {
    return new Promise<IGetLocalProductsSuccessPayload>((resolve, reject) => {
      this.cancelTokenProducts = axios.CancelToken.source();
      axios
        .get(ProductsApi.getAllLocalProductsUrl(), {
          cancelToken: this.cancelTokenProducts.token,
          params: {
            count,
            name,
            page,
            partnerId,
            provider: withoutProvider ? undefined : 'local',
            type: 'default',
          },
        })
        .then(getData)
        .then((response: IGetLocalProductsSuccessPayload) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getCategories(
    count: number,
    page: number,
    partnerId?: string
  ): Promise<IGetCategoriesSuccessPayload> {
    return new Promise<IGetCategoriesSuccessPayload>((resolve, reject) => {
      this.cancelTokenProducts = axios.CancelToken.source();
      axios
        .get(ProductsApi.getAllCategoriesUrl(), {
          cancelToken: this.cancelTokenProducts.token,
          params: {
            count,
            page,
            'partnerIds[]': partnerId,
          },
        })
        .then(getData)
        .then((response: IGetCategoriesSuccessPayload) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getShipmentFees(
    count: number,
    page: number,
    partnerId?: string
  ): Promise<IGetShipmentFeesSuccessPayload> {
    return new Promise<IGetShipmentFeesSuccessPayload>((resolve, reject) => {
      this.cancelTokenProducts = axios.CancelToken.source();
      axios
        .get(ProductsApi.getAllLocalProductsUrl(), {
          cancelToken: this.cancelTokenProducts.token,
          params: {
            count,
            page,
            partnerId,
            type: 'transport',
          },
        })
        .then(getData)
        .then((response: IGetShipmentFeesSuccessPayload) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getZohoProducts(
    count: number,
    page: number,
    partnerId?: string
  ): Promise<IGetLocalProductsSuccessPayload> {
    return new Promise<IGetLocalProductsSuccessPayload>((resolve, reject) => {
      this.cancelTokenProducts = axios.CancelToken.source();
      axios
        .get(ProductsApi.getAllLocalProductsUrl(), {
          cancelToken: this.cancelTokenProducts.token,
          params: {
            count,
            page,
            partnerId,
            provider: 'zoho',
          },
        })
        .then(getData)
        .then((response: IGetLocalProductsSuccessPayload) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getInvoiceProducts(
    count: number,
    page: number,
    partnerId?: string
  ): Promise<IGetLocalProductsSuccessPayload> {
    return new Promise<IGetLocalProductsSuccessPayload>((resolve, reject) => {
      this.cancelTokenProducts = axios.CancelToken.source();
      axios
        .get(ProductsApi.getAllLocalProductsUrl(), {
          cancelToken: this.cancelTokenProducts.token,
          params: {
            count,
            page,
            partnerId,
            provider: 'fakturownia',
          },
        })
        .then(getData)
        .then((response: IGetLocalProductsSuccessPayload) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getSingleProduct(id: number): Promise<ILocalProduct> {
    return new Promise<ILocalProduct>((resolve, reject) => {
      this.cancelTokenProducts = axios.CancelToken.source();

      axios
        .get(ProductsApi.getSingleProductUrl(id), {
          cancelToken: this.cancelTokenProducts.token,
        })
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(this.normalizeSingleProduct(response[0]));
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public addSingleCategory(body: ICategory): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = ProductsApi.getAllCategoriesUrl();
      if (body.id) {
        axios
          .put(url, JSON.stringify(body))
          .then(getData)
          .then(() => {
            resolve();
          });
      } else {
        axios
          .post(url, JSON.stringify(body))
          .then(() => {
            resolve();
          })
          .catch((error) => reject(catchHttpError(error)));
      }
    });
  }

  public addSingleProduct(body: IProductBody): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = ProductsApi.getAllLocalProductsUrl();
      if (body.id) {
        axios
          .put(url, JSON.stringify(body))
          .then(getData)
          .then(() => {
            resolve();
          });
      } else {
        axios
          .post(url, JSON.stringify(body))
          .then(() => {
            resolve();
          })
          .catch((error) => reject(catchHttpError(error)));
      }
    });
  }

  public removeSingleProduct(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = ProductsApi.getRemoveProductUrl(id);
      axios
        .post(url, {})
        .then(getData)
        .then(() => {
          resolve();
        });
    });
  }

  public removeSingleCategory(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = ProductsApi.getRemoveCategoryUrl(id);
      axios
        .post(url, {})
        .then(getData)
        .then(() => {
          resolve();
        });
    });
  }

  public getProductsByCategory(
    categoryId?: number,
    partnerId?: string
  ): Promise<IGetLocalProductsSuccessPayload> {
    return new Promise<IGetLocalProductsSuccessPayload>((resolve, reject) => {
      this.cancelTokenProducts = axios.CancelToken.source();
      axios
        .get(ProductsApi.getAllLocalProductsUrl(), {
          cancelToken: this.cancelTokenProducts.token,
          params: {
            categoryId,
            count: 200,
            partnerId,
          },
        })
        .then(getData)
        .then((response: IGetLocalProductsSuccessPayload) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public normalizeProducts(
    response: IGetProductsPayloadSuccess
  ): INormalizedProducts {
    return {
      count: response.count,
      items: response.items,
    };
  }

  public normalizeSingleProduct(
    response: IGetSingleProductsResponse
  ): ILocalProduct {
    const {
      id,
      name,
      availableToSale,
      price,
      category,
      description,
      emailConfirmation,
      autocomplete,
      vat,
      fiscal,
      slug,
      imageUrl,
      stock,
    } = response;

    return {
      autocomplete,
      availableToSale,
      category,
      categoryId: category?.id || 0,
      categoryName: category?.name,
      description,
      emailConfirmation,
      emailTemplateId: 0,
      fiscal,
      id,
      imageUrl,
      isAvailabilityChecked: !!availableToSale,
      name,
      price,
      slug,
      stock,
      vat,
    };
  }

  public cancelProducts() {
    if (this.cancelTokenProducts) {
      this.cancelTokenProducts.cancel();
      this.cancelTokenProducts = undefined;
    }
  }
}

export default new ProductsApi();
