import { getType } from 'typesafe-actions';

import {
  getPartner,
  getPartnerReportFilters,
  getPartnerReportFiltersParameters,
  getPixel,
  handleActiveFilter,
} from '../actions';
import { IAction, ISettingsReducer } from '../types';

const initialState: ISettingsReducer = {
  activeFilter: null,
  partner: null,
  pixel: null,
  reportFilters: null,
  reportParameters: null,
};

const settingsReducer = (
  state: ISettingsReducer = initialState,
  action: IAction
): ISettingsReducer => {
  switch (action.type) {
    case getType(getPartnerReportFilters.success):
      return {
        ...state,
        reportFilters: action.payload,
      };
    case getType(getPixel.success):
      return {
        ...state,
        pixel: action.payload,
      };
    case getType(getPartner.success):
      return {
        ...state,
        partner: action.payload,
      };
    case getType(handleActiveFilter):
      return {
        ...state,
        activeFilter: action.payload,
      };
    case getType(getPartnerReportFiltersParameters.success):
      return {
        ...state,
        reportParameters: action.payload,
      };
    default:
      return state;
  }
};

export default settingsReducer;
