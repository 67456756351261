import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  getTickets,
  ticketStatus,
  ticketStatusDeletion,
} from '@Model/tickets/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import { resetError, resetLoading, setLoading } from '../actions';
import { TICKETS } from '../constants/constants';

export const setUpStateWhenTicketsFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getTickets.request)),
    mergeMap$(() => [resetError(TICKETS), setLoading(TICKETS)])
  );
};

export const setLoaderWhenTicketsFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getTickets.success, getTickets.failure], action)
    ),
    map$(() => resetLoading(TICKETS))
  );
};

export const setErrorOnTicketsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getTickets.failure,
        ticketStatus.failure,
        ticketStatusDeletion.failure,
      ])
    ),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
