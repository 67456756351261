import { IFormValues, IFormValuesProps } from './Settings.types';

export const prepareInitialValues = (
  settings: IFormValues | null
): IFormValuesProps => {
  const defaultValues: IFormValuesProps = {
    fbPixel: '',
    gtmId: '',
    tokenPixel: '',
  };

  if (settings) {
    const { fbPixel, tokenPixel, gtmId } = settings;

    return {
      fbPixel: fbPixel || defaultValues.fbPixel,
      gtmId: gtmId || defaultValues.gtmId,
      tokenPixel: tokenPixel || defaultValues.tokenPixel,
    };
  }

  return defaultValues;
};
