import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { clearFilterSettlement } from '@Model/settlements/actions';
import { getSettlementMetadata } from '@Model/settlements/selectors';

import Filters from './Filters.component';
import { IFiltersFromDispatch, IFiltersFromState } from './Filters.types';

const mapStateToProps = (state: _Store.IState): IFiltersFromState => {
  return {
    settlementMetadata: getSettlementMetadata(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFiltersFromDispatch => ({
  clearFilterSettlement: () => dispatch(clearFilterSettlement()),
});

export default connect<
  IFiltersFromState,
  IFiltersFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Filters);
