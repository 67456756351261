import { createSelector } from 'reselect';

import _Store from '@Store';

import { ISettlementEdit, ISettlementsReducer } from './../types';
import get from './get';

const getSingleSettlementToEdit = createSelector<
  _Store.IState,
  ISettlementsReducer,
  ISettlementEdit
>([get], (settlement) => ({
  name: settlement.singleSettlement?.name,
  uuid: settlement.singleSettlement?.uuid || '',
}));

export default getSingleSettlementToEdit;
