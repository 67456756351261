import { closeModal } from '@Model/authorization/actions';
import getPrinters from '@Model/printer/selectors/getPrinters';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => {
  return {
    printers: getPrinters(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IFormLayoutFromDispatch => {
  return {
    closeModal: () => {
      dispatch(closeModal());
    },
  };
};

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(FormLayout);
