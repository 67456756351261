import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import routes from '@/routes/routes';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EventIcon from '@mui/icons-material/Event';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Autocomplete,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  FormHelperText,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import MailModal from '@Compo/Mail/components/MailModal';
import CloudinaryUploadWidget from '@Compo/reusable/CloudinaryUploadWidget';
import ConfirmModal from '@Compo/reusable/ConfirmModal';
import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import MuiDatePicker from '@Compo/reusable/MuiDatePicker';
import TextEditor from '@Compo/reusable/TextEditor';
import config from '@Config';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import { useCloudinaryApi } from '@Misc/hooks';
import { IPlace } from '@Services/$going-rundate-api/types';

import Pools from './../Pools';
import SalesReport from './../SalesReport';
import SeatsIo from './../SeatsIo';
import TicketsList from './../TicketsList';
import useStyles from './FormLayout.styles';
import {
  IFormLayoutProps,
  IPopover,
  IPopoverRef,
  IPopoverState,
  PopoverOptions,
} from './FormLayout.types';

const ASSET_TYPE = 'event';

const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates.FormLayout',
  });

  const {
    handleSubmit,
    submitForm,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    rundate,
    history,
    deleteAssets,
    permissionAssetsDelete,
    addMailing,
    setFieldValue,
    places,
    searchPlace,
    selectedPlace,
    isPlacesLoading,
    changeRundateHasExternalImage,
    changeSaleStatus,
    changeVisibilityStatus,
    fetchRundateReport,
    generatePdf,
    pools,
    initialValues,
    addEvent,
    addRundate,
    isReportLoading,
    isReportSeatsLoading,
    permissionSeatsIoKeyWrite,
    changeRundateMonitorType,
    monitorTypes,
    permissionEventWrite,
    setFieldTouched,
  } = props;

  const PAGE_TITLE_TEXT = addEvent
    ? 'Dodaj wydarzenie'
    : addRundate
    ? 'Dodaj rundate'
    : 'Edytuj wydarzenie';
  const SAVE_TEXT = addEvent || addRundate ? 'Dodaj' : 'Zapisz';

  const classes = useStyles();

  const [cloudinaryImagesUrl, setCloudinaryImagesUrl] = useState<string>('');
  const [cloudinaryImagesSlug, setCloudinaryImagesSlug] = useState<string>('');
  const [searchTimeOut, setSearchTimeOut] = useState<NodeJS.Timeout | null>(
    null
  );
  const [popoverOpen, setPopoverOpen] = useState<IPopoverState>({
    changeStatus: false,
    eventPreview: false,
    seatsIo: false,
    ticketList: false,
  });
  const [modalOpen, setModalOpen] = useState({
    change: false,
    hideEvent: false,
    pauseSale: false,
    startSale: false,
  });
  const [confirmCallback, setConfirmCallback] = useState<() => void>(
    () => undefined
  );
  const [cancelCallback, setCancelCallback] = useState<() => void>(
    () => undefined
  );

  const popoverRef = useRef<IPopoverRef>({
    changeStatus: null,
    eventPreview: null,
    seatsIo: null,
    ticketList: null,
  });

  const popoverButtons: IPopover = {
    changeStatus: {
      isActive: !!rundate.changeMonitorType,
      name: rundate.changeMonitorType
        ? `${t('status')}${rundate.changeMonitorType.name}`
        : t('changeStatus'),
      options: [
        {
          name: 'Brak',
          onClick: () => {
            changeRundateMonitorType(null);
            handlePopoverClose(PopoverOptions.CHANGE_STATUS);
          },
          value: 'null',
        },
        ...monitorTypes.map((type) => ({
          name: type.name,
          onClick: () => {
            changeRundateMonitorType(type.id);
            handlePopoverClose(PopoverOptions.CHANGE_STATUS);
          },
          value: String(type.id),
        })),
      ],
    },
    ticketList: {
      isLoading: isReportLoading,
      name: t('generateTicketList'),
      options: [
        {
          name: 'PDF',
          onClick: () => {
            fetchRundateReport('pdf');
            handlePopoverClose(PopoverOptions.TICKET_LIST);
          },
          value: 'pdf',
        },
        {
          name: 'CSV',
          onClick: () => {
            fetchRundateReport('csv');
            handlePopoverClose(PopoverOptions.TICKET_LIST);
          },
          value: 'csv',
        },
        {
          name: 'TXT',
          onClick: () => {
            fetchRundateReport('txt');
            handlePopoverClose(PopoverOptions.TICKET_LIST);
          },
          value: 'txt',
        },
      ],
    },
    // tslint:disable-next-line: object-literal-sort-keys
    seatsIo: {
      hide: !rundate.hasSeatsIo,
      isLoading: isReportSeatsLoading,
      name: t('reportSeats'),
      options: [
        {
          name: 'Generuj raport',
          onClick: () =>
            history.push(
              fillUrlWithValues(
                routes.rundateSeatsIoReport,
                [':id', ':poolId'],
                [String(rundate.id), '']
              )
            ),
          value: 'seatsReportPage',
        },
        {
          name: 'Generuj raport PDF',
          onClick: () => {
            generatePdf(rundate.id);
            handlePopoverClose(PopoverOptions.SEATS_IO);
          },
          value: 'seatsReport',
        },
      ],
    },
    eventPreview: {
      name: t('previewEvent'),
      options: [
        {
          name: 'Podgląd w Going.',
          onClick: () => redirectToEventPreview(config.cms.goingUrl),
          value: 'going',
        },
        {
          name: 'Podgląd w Empik Bilety',
          onClick: () => redirectToEventPreview(config.cms.empikUrl),
          value: 'empik',
        },
      ],
    },
  };

  const slug = rundate.event?.slug || '';
  const assetsFolderName = rundate.event?.partner?.name || '';

  const { cloudinaryImagesUrls } = useCloudinaryApi(slug, ASSET_TYPE);

  const handleDeleteAssets = () => {
    deleteAssets(cloudinaryImagesSlug);
    setCloudinaryImagesUrl('');
  };

  const handleAddMailing = () => {
    addMailing(rundate.event.id, rundate.id);
  };

  const catchHandleSearch = (event: ChangeEvent<{}>, value: string) => {
    searchFunc(value);
  };

  const searchFunc = (text: string) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        searchPlace(text);
      }, 1000)
    );
  };

  const handlePopoverClose = (type: keyof typeof popoverOpen) => {
    setPopoverOpen({ ...popoverOpen, [type]: false });
  };

  const handleToggle = (type: keyof typeof popoverOpen) => {
    setPopoverOpen({ ...popoverOpen, [type]: true });
  };

  const handleCloseModal = (type: keyof typeof modalOpen) => {
    setModalOpen({ ...modalOpen, [type]: false });
  };

  const handleOpenModal = (type: keyof typeof modalOpen) => {
    if (rundate.id) {
      setModalOpen({ ...modalOpen, [type]: true });
    }
  };

  const handlePauseSale = () => {
    changeSaleStatus(false);
    handleCloseModal('pauseSale');
  };

  const handleStartSale = () => {
    changeSaleStatus(true);
    handleCloseModal('startSale');
  };

  const handleVisibilityChange = () => {
    handleCloseModal('hideEvent');
    changeVisibilityStatus(!rundate.event.isVisible);
  };

  const handleChangeDate = (date: MaterialUiPickersDate) => {
    if (pools.some((pool) => !!pool.sold)) {
      handleOpenModal('change');
      setConfirmCallback(() => () => setFieldValue('rundate', date));
    } else {
      setFieldValue('rundate', date);
    }
  };

  const handleChangePlace = (
    event: React.ChangeEvent<{}>,
    value: IPlace | null
  ) => {
    if (pools.some((pool) => !!pool.sold)) {
      handleOpenModal('change');
      setConfirmCallback(() => () => {
        setFieldValue('placeId', value?.id);
        setFieldValue('place', value);
      });
    } else {
      setFieldValue('placeId', value?.id);
      setFieldValue('place', value);
    }
  };

  const handleTimeBlur = (e: React.FocusEvent<any>) => {
    if (
      pools.some((pool) => !!pool.sold) &&
      initialValues.runtime !== values.runtime
    ) {
      handleOpenModal('change');
      setCancelCallback(() => () => {
        setFieldValue('runtime', initialValues.runtime);
      });
    }
  };

  const closeChange = () => {
    handleCloseModal('change');
    cancelCallback();
  };

  const handleConfirmChange = () => {
    handleCloseModal('change');
    confirmCallback();
  };

  const redirectToEventPreview = (url?: string) => {
    if (rundate.event.slug && url) {
      window
        .open(
          `${url}wydarzenie/${rundate.event.slug}/${rundate.slug}`,
          '_blank'
        )
        ?.focus();
    }
  };

  const handleAddRundate = () => {
    history.push(
      fillUrlWithValues(routes.addRundate, ':eventId', String(rundate.event.id))
    );
  };

  useEffect(() => {
    if (slug && slug.length) {
      setCloudinaryImagesUrl(cloudinaryImagesUrls[0] || '');

      setCloudinaryImagesSlug(`${ASSET_TYPE}/${slug}`);
    }
  }, [cloudinaryImagesUrls[0], slug]);

  useEffect(() => {
    changeRundateHasExternalImage(!!cloudinaryImagesUrl);
  }, [cloudinaryImagesUrl]);

  useEffect(() => {
    if (!addRundate) {
      setFieldValue('event.id', undefined);
    }
  }, [addRundate]);

  const Buttons = () => (
    <Grid container={true} justifyContent="flex-end">
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        className={classes.backButton}
        onClick={() => history.goBack()}
      >
        {t('back')}
      </Button>
      <Button
        variant="contained"
        size="large"
        color="primary"
        onClick={() => submitForm()}
      >
        {SAVE_TEXT}
      </Button>
    </Grid>
  );

  return (
    <>
      <MailModal isRundateEdit={true} />
      <ConfirmModal
        open={modalOpen.pauseSale}
        title={t('pauseEvent')}
        question={t('pauseEventDescription')}
        handleClose={() => handleCloseModal('pauseSale')}
        onCancel={() => handleCloseModal('pauseSale')}
        onConfirm={handlePauseSale}
      />
      <ConfirmModal
        open={modalOpen.startSale}
        title={t('startEvent')}
        question={t('startEventDescription')}
        handleClose={() => handleCloseModal('startSale')}
        onCancel={() => handleCloseModal('startSale')}
        onConfirm={handleStartSale}
      />
      <ConfirmModal
        open={modalOpen.hideEvent}
        title={rundate.event.isVisible ? t('hideEvent') : t('showEvent')}
        question={
          rundate.event.isVisible
            ? t('hideEventDescription')
            : t('showEventDescription')
        }
        handleClose={() => handleCloseModal('hideEvent')}
        onCancel={() => handleCloseModal('hideEvent')}
        onConfirm={handleVisibilityChange}
      />
      <ConfirmModal
        open={modalOpen.change}
        title={t('change')}
        question={t('changeDescription')}
        handleClose={closeChange}
        onCancel={closeChange}
        onConfirm={handleConfirmChange}
      />
      <Grid container={true} spacing={2} direction={'row-reverse'}>
        <Grid item={true} xs={12} sm={4} lg={3}>
          <Grid container={true} spacing={2}>
            {!addEvent && !addRundate && (
              <>
                <Grid item={true} xs={12}>
                  <Card>
                    {cloudinaryImagesUrl ? (
                      <CardMedia
                        image={cloudinaryImagesUrl}
                        className={classes.media}
                      />
                    ) : (
                      <CardContent className={classes.cardContent}>
                        <Typography variant="body1">
                          {t('imagePlaceholder')}
                        </Typography>
                      </CardContent>
                    )}
                    {cloudinaryImagesSlug && (
                      <CardActions>
                        <Grid
                          container={true}
                          item={true}
                          xs={3}
                          justifyContent={'flex-end'}
                        >
                          <CloudinaryUploadWidget
                            setCloudinaryImagesUrl={setCloudinaryImagesUrl}
                            assetType={ASSET_TYPE}
                            assetFolderName={assetsFolderName}
                            slug={slug}
                          />
                        </Grid>
                      </CardActions>
                    )}
                  </Card>
                </Grid>

                {rundate.isForSale ? (
                  <Grid item={true} xs={12}>
                    <Button
                      variant="contained"
                      size="medium"
                      startIcon={<PauseIcon />}
                      color="primary"
                      fullWidth={true}
                      className={classes.button}
                      onClick={() => handleOpenModal('pauseSale')}
                    >
                      <span className={classes.buttonText}>
                        {t('stopSale')}
                      </span>
                    </Button>
                  </Grid>
                ) : (
                  <Grid item={true} xs={12}>
                    <Button
                      variant="contained"
                      size="medium"
                      startIcon={<PlayArrowIcon />}
                      color="primary"
                      fullWidth={true}
                      className={cn(classes.button, classes.startSaleButton)}
                      onClick={() => handleOpenModal('startSale')}
                    >
                      <span className={classes.buttonText}>
                        {t('startSale')}
                      </span>
                    </Button>
                  </Grid>
                )}

                <Grid item={true} xs={12}>
                  <Button
                    variant="outlined"
                    size="medium"
                    startIcon={<VisibilityOffIcon />}
                    color="secondary"
                    fullWidth={true}
                    className={classes.button}
                    onClick={() => handleOpenModal('hideEvent')}
                  >
                    <span className={classes.buttonText}>
                      {rundate.event.isVisible
                        ? t('hideEventButton')
                        : t('showEventButton')}
                    </span>
                  </Button>
                </Grid>

                {config.cms.showAddNewRundate && (
                  <Grid item={true} xs={12}>
                    <Button
                      variant="outlined"
                      size="medium"
                      startIcon={<EventIcon />}
                      color="secondary"
                      fullWidth={true}
                      className={classes.button}
                      onClick={handleAddRundate}
                    >
                      <span className={classes.buttonText}>
                        {t('addNewRundate')}
                      </span>
                    </Button>
                  </Grid>
                )}

                {permissionEventWrite && (
                  <Grid item={true} xs={12}>
                    <Button
                      variant="outlined"
                      size="medium"
                      startIcon={<MailOutlineIcon />}
                      color="secondary"
                      fullWidth={true}
                      className={classes.button}
                      onClick={handleAddMailing}
                    >
                      <span className={classes.buttonText}>
                        {t('eventMailing')}
                      </span>
                    </Button>
                  </Grid>
                )}

                {(
                  Object.keys(popoverButtons) as Array<keyof typeof popoverOpen>
                ).map((key, index) => {
                  return (
                    <Grid
                      key={key}
                      item={true}
                      xs={12}
                      className={classes.popperContainer}
                    >
                      <ButtonGroup
                        fullWidth={true}
                        disabled={
                          popoverButtons[key].hide ||
                          popoverButtons[key].isLoading
                        }
                      >
                        <Button
                          variant="outlined"
                          size="medium"
                          color={
                            popoverButtons[key].isActive
                              ? 'primary'
                              : 'secondary'
                          }
                          className={cn(classes.button, classes.center)}
                          ref={(r: HTMLButtonElement | null) =>
                            (popoverRef.current[key] = r)
                          }
                          onClick={() => handleToggle(key)}
                          fullWidth={true}
                          startIcon={
                            popoverButtons[key].isLoading && (
                              <CircularProgress
                                className={classes.loading}
                                disableShrink={true}
                              />
                            )
                          }
                        >
                          {popoverButtons[key].name}
                        </Button>
                        <Button
                          size="medium"
                          color={
                            popoverButtons[key].isActive
                              ? 'primary'
                              : 'secondary'
                          }
                          variant="outlined"
                          className={cn(classes.button, classes.secondButton)}
                          onClick={() => handleToggle(key)}
                        >
                          <ArrowDropDownIcon />
                        </Button>
                      </ButtonGroup>
                      <Popper
                        open={popoverOpen[key]}
                        transition={true}
                        disablePortal={true}
                        anchorEl={popoverRef.current[key]}
                        placement="bottom-start"
                        className={classes.popper}
                      >
                        {({ TransitionProps }) => (
                          <Grow {...TransitionProps}>
                            <Paper>
                              <ClickAwayListener
                                onClickAway={() => handlePopoverClose(key)}
                              >
                                <MenuList disablePadding={true}>
                                  {popoverButtons[key].options.map((option) => (
                                    <MenuItem
                                      key={option.name}
                                      className={classes.menuItem}
                                      onClick={option.onClick}
                                    >
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>
        </Grid>

        <Grid item={true} xs={12} sm={8} lg={9}>
          <GoingPaper title={PAGE_TITLE_TEXT} buttons={Buttons()}>
            <form onSubmit={handleSubmit}>
              <Grid container={true} spacing={4}>
                <Grid item={true} xs={12}>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12}>
                      <Grid container={true} spacing={4}>
                        <Grid item={true} xs={true}>
                          <LabelContentContainer>
                            <TextField
                              variant="standard"
                              id="event[titlePL]"
                              label={
                                (touched.event?.titlePL &&
                                  errors.event?.titlePL) ||
                                t('eventTitlePL')
                              }
                              helperText={t('eventTitlePLHelper')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.event?.titlePL}
                              name="event[titlePL]"
                              error={
                                !!(
                                  touched.event?.titlePL &&
                                  errors.event?.titlePL
                                )
                              }
                              fullWidth={true}
                              type="text"
                            />
                          </LabelContentContainer>
                        </Grid>
                        {!addEvent && !addRundate && (
                          <Grid item={true}>
                            <LabelContentContainer>
                              <TextField
                                label={t('rundateId')}
                                variant="outlined"
                                disabled={true}
                                value={rundate.id}
                                fullWidth={true}
                                className={classes.rundateId}
                                type="text"
                              />
                            </LabelContentContainer>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                          <LabelContentContainer>
                            <MuiDatePicker
                              name="rundate"
                              disableToolbar={true}
                              fullWidth={true}
                              variant="standard"
                              format="DD.MM.yyyy"
                              error={!!(touched.rundate && errors.rundate)}
                              label={
                                (touched.rundate && errors.rundate) ||
                                t('rundate')
                              }
                              helperText={t('rundateHelper')}
                              value={values.rundate}
                              onChange={handleChangeDate}
                              onBlur={handleBlur}
                            />
                          </LabelContentContainer>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                          <LabelContentContainer>
                            <TextField
                              variant="standard"
                              type="time"
                              id="runtime"
                              label={
                                (touched.runtime && errors.runtime) ||
                                t('runtime')
                              }
                              helperText={t('runtimeHelper')}
                              onChange={handleChange}
                              onBlur={handleTimeBlur}
                              value={values.runtime}
                              name="runtime"
                              error={!!(touched.runtime && errors.runtime)}
                              fullWidth={true}
                              InputProps={{
                                className: cn(
                                  classes.defaultTimeInput,
                                  classes.time
                                ),
                              }}
                            />
                          </LabelContentContainer>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <LabelContentContainer>
                        <TextField
                          variant="standard"
                          type="time"
                          id="startTime"
                          label={
                            (touched.startTime && errors.startTime) ||
                            t('startTime')
                          }
                          helperText={t('startTimeHelper')}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.startTime}
                          name="startTime"
                          error={!!(touched.startTime && errors.startTime)}
                          fullWidth={true}
                          InputProps={{
                            className: cn(
                              classes.defaultTimeInput,
                              classes.time
                            ),
                          }}
                        />
                      </LabelContentContainer>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <Grid container={true} spacing={4}>
                        <Grid item={true} xs={12} md={6}>
                          <LabelContentContainer>
                            <MuiDatePicker
                              name="endDate"
                              disableToolbar={true}
                              fullWidth={true}
                              variant="standard"
                              format="DD.MM.yyyy"
                              error={!!(touched.endDate && errors.endDate)}
                              label={
                                (touched.endDate && errors.endDate) ||
                                t('endDate')
                              }
                              helperText={t('endDateHelper')}
                              value={values.endDate}
                              onChange={(date) =>
                                setFieldValue('endDate', date)
                              }
                              onBlur={handleBlur}
                            />
                          </LabelContentContainer>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                          <LabelContentContainer>
                            <TextField
                              variant="standard"
                              type="time"
                              id="endTime"
                              label={
                                (touched.endTime && errors.endTime) ||
                                t('endTime')
                              }
                              helperText={t('endTimeHelper')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.endTime}
                              name="endTime"
                              error={!!(touched.endTime && errors.endTime)}
                              fullWidth={true}
                              InputProps={{
                                className: cn(
                                  classes.defaultTimeInput,
                                  classes.time
                                ),
                              }}
                            />
                          </LabelContentContainer>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <LabelContentContainer>
                        <FormControl
                          variant="standard"
                          fullWidth={true}
                          error={!!(touched.placeId && errors.placeId)}
                        >
                          <Autocomplete
                            disablePortal={false}
                            onChange={handleChangePlace}
                            loading={isPlacesLoading}
                            onInputChange={catchHandleSearch}
                            id="placeId"
                            options={places}
                            defaultValue={selectedPlace}
                            value={values.place}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                              <TextField
                                variant="standard"
                                {...params}
                                name="placeId"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={
                                  (touched.placeId && errors.placeId) ||
                                  t('place')
                                }
                                error={!!(touched.placeId && errors.placeId)}
                              />
                            )}
                          />
                          <FormHelperText>{t('placeHelper')}</FormHelperText>
                        </FormControl>
                      </LabelContentContainer>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <LabelContentContainer>
                        {config.cms.showNewEditorText ? (
                          <TextEditor
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            value={values.event?.descriptionPL}
                            name="event[descriptionPL]"
                            label={
                              (touched.event?.descriptionPL &&
                                errors.event?.descriptionPL) ||
                              t('eventDescriptionPL')
                            }
                            error={
                              !!(
                                touched.event?.descriptionPL &&
                                errors.event?.descriptionPL
                              )
                            }
                            color="#22262F"
                          />
                        ) : (
                          <TextField
                            id="event[descriptionPL]"
                            label={
                              (touched.event?.descriptionPL &&
                                errors.event?.descriptionPL) ||
                              t('eventDescriptionPL')
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.event?.descriptionPL}
                            name="event[descriptionPL]"
                            error={
                              !!(
                                touched.event?.descriptionPL &&
                                errors.event?.descriptionPL
                              )
                            }
                            fullWidth={true}
                            type="text"
                            multiline={true}
                            minRows={10}
                            maxRows={10}
                            variant="outlined"
                          />
                        )}
                      </LabelContentContainer>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <LabelContentContainer>
                        <TextField
                          variant="standard"
                          id="emissionSize"
                          label={
                            (touched.emissionSize && errors.emissionSize) ||
                            t('emissionSize')
                          }
                          helperText={t('emissionSizeHelper')}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.emissionSize}
                          name="emissionSize"
                          error={
                            !!(touched.emissionSize && errors.emissionSize)
                          }
                          fullWidth={true}
                        />
                      </LabelContentContainer>
                    </Grid>

                    {!addEvent && !addRundate && (
                      <>
                        {permissionSeatsIoKeyWrite && (
                          <Grid item={true} xs={12}>
                            <SeatsIo />
                          </Grid>
                        )}

                        <Grid item={true} xs={12}>
                          <SalesReport />
                        </Grid>

                        <Grid item={true} xs={12}>
                          <Pools />
                        </Grid>

                        <Grid item={true} xs={12}>
                          <TicketsList />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </GoingPaper>
        </Grid>
      </Grid>
    </>
  );
};

export default FormLayout;
