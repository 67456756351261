export const HANDLE_ACTIVE_SETTLEMENT =
  'Zagrywki/settlements/HANDLE_ACTIVE_SETTLEMENT';
export const MOUNTED = 'Zagrywki/settlements/MOUNTED';
export const CATCH_SAVE_SETTLEMENT =
  'Zagrywki/settlements/CATCH_SAVE_SETTLEMENT';
export const CATCH_EDIT_SETTLEMENT =
  'Zagrywki/settlements/CATCH_EDIT_SETTLEMENT';
export const CATCH_CHANGE_PAGE_SETTLEMENTS =
  'Zagrywki/settlements/CATCH_CHANGE_PAGE_SETTLEMENTS';
export const FETCH_SINGLE_SETTLEMENT =
  'Zagrywki/settlements/FETCH_SINGLE_SETTLEMENT';
export const RESET_SINGLE_SETTLEMENT =
  'Zagrywki/settlements/RESET_SINGLE_SETTLEMENT';
export const CATCH_DELETE_SETTLEMENT =
  'Zagrywki/settlements/CATCH_DELETE_SETTLEMENT';
export const CATCH_SETTLEMENT_STATUS_CHANGE =
  'Zagrywki/settlements/CATCH_SETTLEMENT_STATUS_CHANGE';
export const CATCH_GENERATE_REPORT =
  'Zagrywki/settlements/CATCH_GENERATE_REPORT';
export const CATCH_GENERATE_GROUP_REPORT =
  'Zagrywki/settlements/CATCH_GENERATE_GROUP_REPORT';
export const CATCH_GENERATE_REPORT_FILE =
  'Zagrywki/settlements/CATCH_GENERATE_REPORT_FILE';
export const FETCH_SETTLEMENT_RUNDATES =
  'Zagrywki/settlements/FETCH_SETTLEMENT_RUNDATES';
export const CHANGE_FILTER_SETTLEMENT =
  'Zagrywki/settlements/CHANGE_FILTER_SETTLEMENT';
export const CLEAR_FILTER_SETTLEMENT =
  'Zagrywki/settlements/CLEAR_FILTER_SETTLEMENT';
export const SEARCH_SETTLEMENT = 'Zagrywki/settlements/SEARCH_SETTLEMENT';

export const _SAVE_SETTLEMENT_REQUEST =
  'Zagrywki/settlements/_SAVE_SETTLEMENT_REQUEST';
export const _SAVE_SETTLEMENT_FAILURE =
  'Zagrywki/settlements/_SAVE_SETTLEMENT_FAILURE';
export const _SAVE_SETTLEMENT_SUCCESS =
  'Zagrywki/settlements/_SAVE_SETTLEMENT_SUCCESS';

export const _EDIT_SETTLEMENT_REQUEST =
  'Zagrywki/settlements/_EDIT_SETTLEMENT_REQUEST';
export const _EDIT_SETTLEMENT_FAILURE =
  'Zagrywki/settlements/_EDIT_SETTLEMENT_FAILURE';
export const _EDIT_SETTLEMENT_SUCCESS =
  'Zagrywki/settlements/_EDIT_SETTLEMENT_SUCCESS';

export const _GET_SETTLEMENTS_REQUEST =
  'Zagrywki/settlements/_GET_SETTLEMENTS_REQUEST';
export const _GET_SETTLEMENTS_FAILURE =
  'Zagrywki/settlements/_GET_SETTLEMENTS_FAILURE';
export const _GET_SETTLEMENTS_SUCCESS =
  'Zagrywki/settlements/_GET_SETTLEMENTS_SUCCESS';

export const _GET_SINGLE_SETTLEMENT_REQUEST =
  'Zagrywki/settlements/_GET_SINGLE_SETTLEMENT_REQUEST';
export const _GET_SINGLE_SETTLEMENT_FAILURE =
  'Zagrywki/settlements/_GET_SINGLE_SETTLEMENT_FAILURE';
export const _GET_SINGLE_SETTLEMENT_SUCCESS =
  'Zagrywki/settlements/_GET_SINGLE_SETTLEMENT_SUCCESS';

export const _DELETE_SETTLEMENT_REQUEST =
  'Zagrywki/settlements/_DELETE_SETTLEMENT_REQUEST';
export const _DELETE_SETTLEMENT_FAILURE =
  'Zagrywki/settlements/_DELETE_SETTLEMENT_FAILURE';
export const _DELETE_SETTLEMENT_SUCCESS =
  'Zagrywki/settlements/_DELETE_SETTLEMENT_SUCCESS';

export const _UPDATE_SETTLEMENT_STATUS_REQUEST =
  'Zagrywki/settlements/_UPDATE_SETTLEMENT_STATUS_REQUEST';
export const _UPDATE_SETTLEMENT_STATUS_FAILURE =
  'Zagrywki/settlements/_UPDATE_SETTLEMENT_STATUS_FAILURE';
export const _UPDATE_SETTLEMENT_STATUS_SUCCESS =
  'Zagrywki/settlements/_UPDATE_SETTLEMENT_STATUS_SUCCESS';

export const _GENERATE_REPORT_REQUEST =
  'Zagrywki/settlements/_GENERATE_REPORT_REQUEST';
export const _GENERATE_REPORT_FAILURE =
  'Zagrywki/settlements/_GENERATE_REPORT_FAILURE';
export const _GENERATE_REPORT_SUCCESS =
  'Zagrywki/settlements/_GENERATE_REPORT_STATUS_SUCCESS';

export const _GENERATE_GROUP_REPORT_REQUEST =
  'Zagrywki/settlements/_GENERATE_GROUP_REPORT_REQUEST';
export const _GENERATE_GROUP_REPORT_FAILURE =
  'Zagrywki/settlements/_GENERATE_GROUP_REPORT_FAILURE';
export const _GENERATE_GROUP_REPORT_SUCCESS =
  'Zagrywki/settlements/_GENERATE_GROUP_REPORT_SUCCESS';

export const _GENERATE_REPORT_FILE_REQUEST =
  'Zagrywki/settlements/_GENERATE_REPORT_FILE_REQUEST';
export const _GENERATE_REPORT_FILE_FAILURE =
  'Zagrywki/settlements/_GENERATE_REPORT_FILE_FAILURE';
export const _GENERATE_REPORT_FILE_SUCCESS =
  'Zagrywki/settlements/_GENERATE_REPORT_FILE_SUCCESS';

export const _GET_SETTLEMENT_METADATA_REQUEST =
  'Zagrywki/settlements/_GET_SETTLEMENT_METADATA_REQUEST';
export const _GET_SETTLEMENT_METADATA_FAILURE =
  'Zagrywki/settlements/_GET_SETTLEMENT_METADATA_FAILURE';
export const _GET_SETTLEMENT_METADATA_SUCCESS =
  'Zagrywki/settlements/_GET_SETTLEMENT_METADATA_SUCCESS';

export const _GET_SETTLEMENTS_RUNDATES_REQUEST =
  'Zagrywki/settlements/_GET_SETTLEMENTS_RUNDATES_REQUEST';
export const _GET_SETTLEMENTS_RUNDATES_FAILURE =
  'Zagrywki/settlements/_GET_SETTLEMENTS_RUNDATES_FAILURE';
export const _GET_SETTLEMENTS_RUNDATES_SUCCESS =
  'Zagrywki/settlements/_GET_SETTLEMENTS_RUNDATES_SUCCESS';
