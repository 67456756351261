import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import {
  IPartnerReportFiltersBody,
  IPartnerSavePayload,
} from '@Model/settings/types';

import {
  IPartnerReportFiltersParamsSuccessPayload,
  IPartnerReportFiltersSuccessPayload,
  IPartnerReportSaveFiltersBody,
  IPartnerSuccessPayload,
  IPixelBody,
  IPixelSuccessPayload,
} from './types';

class SettingsApi {
  private static getPartnerSettledForUrl() {
    return `${config.api.cmsServices}partner-settled-for`;
  }

  private static getPartnerSettledForDeleteUrl(id: number) {
    return `${config.api.cmsServices}partner-settled-for/${id}`;
  }

  private static getPartnerSettledForUpdateUrl(id: number) {
    return `${config.api.cmsServices}partner-settled-for/${id}`;
  }

  private static getPartnerSettledForParametersUrl() {
    return `${config.api.cmsServices}partner-settled-for/parameters`;
  }

  private static getPixelUrl() {
    return `${config.api.cmsServices}proxy-pixel/api/v1/admin/pixel`;
  }

  private static getPartnerUrl(id: number) {
    return `${config.api.cmsServices}partners/${id}`;
  }

  private cancelTokenSettings?: CancelTokenSource;

  public getPixel(partnerId: string) {
    return new Promise<IPixelSuccessPayload>((resolve, reject) => {
      this.cancelTokenSettings = axios.CancelToken.source();
      axios
        .get(SettingsApi.getPixelUrl(), {
          cancelToken: this.cancelTokenSettings.token,
          params: {
            partnerId,
          },
        })
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public savePixel(body: IPixelBody, partnerId: string) {
    return new Promise<IPixelSuccessPayload>((resolve, reject) => {
      this.cancelTokenSettings = axios.CancelToken.source();
      if (body.id) {
        axios
          .put(SettingsApi.getPixelUrl(), {
            ...body,
            partnerId: Number(partnerId),
          })
          .then(getData)
          .then((response) => resolve(response))
          .catch((error) => reject(catchHttpError(error)));
      } else {
        axios
          .post(SettingsApi.getPixelUrl(), {
            ...body,
            partnerId: Number(partnerId),
          })
          .then(getData)
          .then((response) => resolve(response))
          .catch((error) => reject(catchHttpError(error)));
      }
    });
  }

  public getPartner(partnerId: string) {
    return new Promise<IPartnerSuccessPayload>((resolve, reject) => {
      this.cancelTokenSettings = axios.CancelToken.source();
      axios
        .get(SettingsApi.getPartnerUrl(Number(partnerId)), {
          cancelToken: this.cancelTokenSettings.token,
        })
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public savePartner(body: IPartnerSavePayload, partnerId: string) {
    return new Promise<IPixelSuccessPayload>((resolve, reject) => {
      this.cancelTokenSettings = axios.CancelToken.source();
      axios
        .put(SettingsApi.getPartnerUrl(Number(partnerId)), body)
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getPartnerSettledFor(
    body: IPartnerReportFiltersBody
  ): Promise<IPartnerReportFiltersSuccessPayload> {
    return new Promise<IPartnerReportFiltersSuccessPayload>(
      (resolve, reject) => {
        this.cancelTokenSettings = axios.CancelToken.source();
        axios
          .get(SettingsApi.getPartnerSettledForUrl(), {
            cancelToken: this.cancelTokenSettings.token,
            params: {
              'filter[partnerIds]': body.filter.partnerIds,
              page: body.page,
            },
          })
          .then(getData)
          .then((response: IPartnerReportFiltersSuccessPayload) => {
            resolve({ data: response.data, meta: response.meta });
          })
          .catch((error) => reject(catchHttpError(error)));
      }
    );
  }

  public getPartnerSettledForParameters(
    partnerId: string
  ): Promise<IPartnerReportFiltersParamsSuccessPayload> {
    return new Promise<IPartnerReportFiltersParamsSuccessPayload>(
      (resolve, reject) => {
        this.cancelTokenSettings = axios.CancelToken.source();
        axios
          .get(SettingsApi.getPartnerSettledForParametersUrl(), {
            cancelToken: this.cancelTokenSettings.token,
            params: { 'filter[partnerId]': Number(partnerId) },
          })
          .then(getData)
          .then(getData)
          .then((response: IPartnerReportFiltersParamsSuccessPayload[]) => {
            resolve(response[0]);
          })
          .catch((error) => reject(catchHttpError(error)));
      }
    );
  }

  public savePartnerSettledFor(
    body: IPartnerReportSaveFiltersBody,
    partnerId: string
  ): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenSettings = axios.CancelToken.source();

      if (body.id) {
        axios
          .put(SettingsApi.getPartnerSettledForUpdateUrl(body.id), {
            cancelToken: this.cancelTokenSettings.token,
            ...body,
            partnerId: Number(partnerId),
          })
          .then(getData)
          .then((response) => resolve(response))
          .catch((error) => reject(catchHttpError(error)));
      } else {
        axios
          .post(SettingsApi.getPartnerSettledForUrl(), {
            ...body,
            partnerId: Number(partnerId),
          })
          .then(getData)
          .then((response) => resolve(response))
          .catch((error) => reject(catchHttpError(error)));
      }
    });
  }

  public deletePartnerSettledFor(id: number): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenSettings = axios.CancelToken.source();

      axios
        .delete(SettingsApi.getPartnerSettledForDeleteUrl(id), {
          cancelToken: this.cancelTokenSettings.token,
        })
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelSettings() {
    if (this.cancelTokenSettings) {
      this.cancelTokenSettings.cancel();
      this.cancelTokenSettings = undefined;
    }
  }
}

export default new SettingsApi();
