import * as fs from 'fs';
import * as _ from 'lodash';
import { transliterate as tr } from 'transliteration';

import printerTypes from './../enums/printerTypes';
import {
  IParameters,
  IThermalPrinter,
  ITicket,
  ITicketConfig,
  ITicketConfigItem,
} from './../types';
import BocaThermalPrinter from './BocaThermalPrinter';

class TicketGenerator {
  public static generateTicket(
    ticketConfig: ITicket,
    parameters: IParameters,
    cut = true,
    attachment: string
  ) {
    const { config } = ticketConfig;
    const ticket = this.getThermalPrinter(config);

    if (ticket) {
      config.items.forEach((item) => {
        this.addItem(ticket, item, parameters, attachment);
      });
      if (cut) {
        ticket.cut();
      } else {
        ticket.nextPage();
      }
    }

    return ticket;
  }

  public static getThermalPrinter(config: ITicketConfig) {
    switch (config.printer) {
      case printerTypes.boca:
        return new BocaThermalPrinter();
      default:
        return null;
    }
  }

  public static addItem(
    ticket: IThermalPrinter,
    item: ITicketConfigItem,
    parameters: IParameters,
    attachment: string
  ) {
    switch (item.type) {
      case 'base64':
        if (item.content) {
          ticket.append(atob(item.content));
        }
        break;
      case 'raw':
        if (item.content) {
          ticket.append(item.content);
        }
        break;
      case 'bmp':
      case 'pcx':
        if (item.path) {
          ticket.printImage(
            fs.readFileSync(item.path),
            item.type,
            item.position.x || 0,
            item.position.y || 0
          );
        }
        break;
      case 'attachment':
        ticket.printImage(
          Buffer.from(attachment, 'base64'),
          item.extension || '',
          item.position.x || 0,
          item.position.y || 0
        );
        break;
      case 'qr':
        if (item.content) {
          ticket.printQR(
            this.parseContent(item.content, parameters),
            item.position.x,
            item.position.y,
            item.options
          );
        }
        break;
      case 'text':
        if (item.content) {
          ticket.printText(
            tr(this.parseContent(item.content, parameters)),
            item.position.x,
            item.position.y,
            item.rotation,
            item.font
          );
        }
        break;

      default:
        break;
    }
  }

  public static parseContent(content: string, parameters: IParameters): string {
    const compiled = _.template(content);

    return compiled(parameters);
  }
}

export default TicketGenerator;
