import React from 'react';

import Grid from '@Compo/layout/Grid';
import Statics from '@Compo/Statics';

const StaticsPage = () => (
  <Grid>
    <Statics />
  </Grid>
);

export default StaticsPage;
