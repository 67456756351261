import React from 'react';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutline';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelWithIcon from '@Compo/reusable/LabelWithIcon';

import { ICheckProps } from './Check.types';

const Check = ({
  open,
  externalWarnings,
  closeModal,
  values,
  save,
  close,
}: ICheckProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'Rundates.TicketsList.components.ImportListModal.components.Check',
  });

  const theme = useTheme();

  if (!externalWarnings) {
    return null;
  }

  const numberOfConflict =
    Object.keys(externalWarnings.duplicates).length +
    externalWarnings.existTickets.length;

  const submit = () => {
    const { name, entryTokens } = values;
    if (numberOfConflict) {
      save({ name, entryTokens: entryTokens.split(/\r|\n/), force: true });
    } else {
      save({ name, entryTokens: entryTokens.split(/\r|\n/) });
    }
    close();
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <LabelWithIcon
              label={t('verified')}
              icon={
                <CheckCircleOutlinedIcon fontSize={'small'} color={'success'} />
              }
              number={externalWarnings.validTicketCodesCount}
            />
          </Grid>
          {!!numberOfConflict && (
            <Grid item={true} xs={12}>
              <LabelWithIcon
                label={t('conflicted')}
                icon={
                  <ReportProblemOutlinedIcon
                    fontSize={'small'}
                    color={'warning'}
                  />
                }
                number={numberOfConflict}
              />
            </Grid>
          )}
          {Object.keys(externalWarnings.duplicates).map((code) => (
            <Grid item={true} xs={12} key={code}>
              <LabelWithIcon
                label={`${t('warningCode')} “${code}” ${t(
                  'warningDuplicated'
                )}`}
                icon={<ReportProblemOutlinedIcon fontSize={'small'} />}
                backgroundColor={theme.palette.warning.light}
              />
            </Grid>
          ))}
          {externalWarnings.existTickets.map((ticket) => (
            <Grid item={true} xs={12} key={ticket}>
              <LabelWithIcon
                label={`${t('warningCode')} “${ticket}” ${t('warningExist')}`}
                icon={<ReportProblemOutlinedIcon fontSize={'small'} />}
                backgroundColor={theme.palette.warning.light}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant={'outlined'}
          size={'large'}
          color={'secondary'}
          onClick={closeModal}
        >
          {t('back')}
        </Button>
        <Button
          variant={'contained'}
          size={'large'}
          color={'primary'}
          onClick={submit}
        >
          {t('import')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Check;
