import React from 'react';

import DefinedConfigurations from '@Compo/BetterManager/DefinedConfigurations';
import ConfigurationModal from './components/ConfigurationModal/';

const AddingConfiguration = () => {
  return (
    <>
      <ConfigurationModal />
      <DefinedConfigurations />
    </>
  );
};

export default AddingConfiguration;
