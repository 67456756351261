import { IFormValuesProps, ILocalProduct } from '@Model/products/types';

export const prepareInitialValues = (
  product: ILocalProduct | null
): IFormValuesProps => {
  const defaultValues: IFormValuesProps = {
    autocomplete: 0,
    availableToSale: undefined,
    categoryId: 0,
    description: '',
    emailConfirmation: false,
    emailTemplateId: -1,
    fiscal: 1,
    id: undefined,
    imageUrl: '',
    isAvailabilityChecked: false,
    name: '',
    price: 0,
    slug: undefined,
    vat: 0,
  };

  if (product) {
    const {
      id,
      name,
      availableToSale,
      price,
      categoryId,
      emailConfirmation,
      emailTemplateId,
      autocomplete,
      description,
      fiscal,
      slug,
      vat,
      imageUrl,
    } = product;

    return {
      ...defaultValues,
      autocomplete: autocomplete ? 1 : 0,
      availableToSale,
      categoryId,
      description,
      emailConfirmation,
      emailTemplateId,
      fiscal: fiscal ? 1 : 0,
      id,
      imageUrl,
      isAvailabilityChecked: !!availableToSale,
      name,
      price,
      slug,
      vat,
    };
  }

  return defaultValues;
};
