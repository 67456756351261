import { number, object, string } from 'yup';

export const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';

const configuration = {
  printerId: string()
    .required(REQUIRED_VALIDATOR_TEXT)
    .nullable(),
  startCash: number()
    .required(REQUIRED_VALIDATOR_TEXT)
    .nullable(),
};

export const SessionSchema = object().shape(configuration);
