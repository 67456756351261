import { getType } from 'typesafe-actions';

import {
  captureReport,
  handleActivePage,
  setAvailableReports,
  setCounts,
  setFilters,
  setReportKeys,
  setReportType,
  setSelectedKeys,
  setTotalCount,
} from '../actions';
import { IAction, IReportReducer } from '../types';
import { reportsData } from './reportsSettings';

const ACTIVE_PAGE_DEFAULT_VALUE = 0;

const initialState: IReportReducer = {
  activePage: ACTIVE_PAGE_DEFAULT_VALUE,
  availableReports: reportsData,
  counts: {},
  filters: [],
  items: [],
  keys: [],
  perPage: 20,
  selectedKeys: [],
  selectedReport: reportsData[0],
  totalCount: 0,
};

const reportsReducer = (
  state: IReportReducer = initialState,
  action: IAction,
): IReportReducer => {
  switch (action.type) {
    case getType(captureReport.success):
      return {
        ...state,
        items: action.payload,
      };
    case getType(setReportKeys):
      return {
        ...state,
        keys: action.payload,
        selectedKeys: action.payload,
      };
    case getType(setSelectedKeys):
      return {
        ...state,
        selectedKeys: action.payload,
      };
    case getType(handleActivePage):
      return {
        ...state,
        activePage: action.payload,
      };
    case getType(setFilters):
      return {
        ...state,
        activePage: ACTIVE_PAGE_DEFAULT_VALUE,
        filters: action.payload,
      };
    case getType(setReportType):
      return {
        ...state,
        activePage: ACTIVE_PAGE_DEFAULT_VALUE,
        filters: [],
        selectedReport: action.payload,
      };
    case getType(setCounts):
      return {
        ...state,
        counts: action.payload,
      };
    case getType(setTotalCount):
      return {
        ...state,
        totalCount: action.payload,
      };
    case getType(setAvailableReports):
      return {
        ...state,
        availableReports: action.payload,
      };
    default:
      return state;
  }
};

export default reportsReducer;
