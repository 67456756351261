import _Store from '@Store';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { handleActiveCategory } from '@Model/products/actions';
import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => ({});

const mapDispatchToProps = (dispatch: Dispatch): IFormLayoutFromDispatch => ({
  closeModal: () => dispatch(handleActiveCategory(null)),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
