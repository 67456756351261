import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  MOUNTED,
} from '../constants/actions';
import { IGetStaticsSuccessPayload } from '../types';

export const getStatics = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE,
)<undefined, IGetStaticsSuccessPayload, Error>();

export const mounted = createStandardAction(MOUNTED)();
