import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { saveTicket } from '@Model/going/rundates/actions';
import { getSelectedTicket } from '@Model/going/rundates/selectors';

import TicketForm from './TicketForm.component';
import {
  ITicketFormFromDispatch,
  ITicketFormFromState,
} from './TicketForm.types';

const mapStateToProps = (state: _Store.IState): ITicketFormFromState => ({
  initialValues: getSelectedTicket(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITicketFormFromDispatch => ({
  save: (values) => dispatch(saveTicket(values)),
});

export default connect<
  ITicketFormFromState,
  ITicketFormFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(TicketForm);
