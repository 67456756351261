export const _GET_POOLS_FAILURE = 'Zagrywki/events/_GET_POOLS_FAILURE';
export const _GET_POOLS_REQUEST = 'Zagrywki/events/_GET_POOLS_REQUEST';
export const _GET_POOLS_SUCCESS = 'Zagrywki/events/_GET_POOLS_SUCCESS';

export const _GET_EVENTS_FAILURE = 'Zagrywki/events/_GET_EVENTS_FAILURE';
export const _GET_EVENTS_REQUEST = 'Zagrywki/events/_GET_EVENTS_REQUEST';
export const _GET_EVENTS_SUCCESS = 'Zagrywki/events/_GET_EVENTS_SUCCESS';

export const SELECT_EVENT = 'Zagrywki/events/SELECT_EVENT';
export const SEARCH_EVENT = 'Zagrywki/events/SEARCH_EVENT';
export const SELECT_POOL = 'Zagrywki/events/SELECT_POOL';
export const RESET_SELECTION = 'Zagrywki/events/RESET_SELECTION';
export const SELECT_SEAT = 'Zagrywki/events/SELECT_SEAT';
export const DESELECT_SEAT = 'Zagrywki/events/DESELECT_SEAT';
export const _UPDATE_SELECTED_SEATS = 'Zagrywki/events/_UPDATE_SELECTED_SEATS';
export const MOUNTED = 'Zagrywki/events/MOUNTED';
