import { IGroup } from '@Services/$users-api/types';

import { IGroupsModalValues } from './GroupsModal.types';

export const prepareInitialValues = (
  configuration: IGroup | null
): IGroupsModalValues => {
  let defaultValues: IGroupsModalValues = {
    name: '',
    permissionsIds: [],
  };

  if (configuration) {
    const { id, name, permissions } = configuration;
    defaultValues = {
      id,
      name,
      permissionsIds: permissions.map((permission) => permission.id),
    };
  }

  return defaultValues;
};
