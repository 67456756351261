import { redirectBuyingContinue } from '@Model/iframe/actions';
import { cancelTransaction } from '@Model/reservation/actions';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import SummaryFail from './SummaryFail.component';
import { ISummaryFromDispatch } from './SummaryFail.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISummaryFromDispatch => ({
  cancelTransaction: () => dispatch(cancelTransaction(null)),
  continueBuying: () => dispatch(redirectBuyingContinue()),
});

export default connect<{}, ISummaryFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(SummaryFail);
