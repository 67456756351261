import moment from 'moment';

import _Store from '@Store';

import { IEditRundatePayload } from '@Model/going/rundates/types';
import { IRundate } from '@Services/$going-rundate-api/types';

import { IRundateValues } from '../FormLayout/FormLayout.types';

export const defaultValues: IRundateValues = {
  emissionSize: null,
  endDate: null,
  endTime: '',
  event: {
    descriptionPL: '',
    titlePL: '',
  },
  place: undefined,
  placeId: null,
  rundate: moment(),
  runtime: '',
  startTime: '',
};

export const prepareInitialValues = (
  activeRundate: IRundate
): IRundateValues => {
  if (activeRundate.id || activeRundate.event.id) {
    return {
      emissionSize: activeRundate.emissionSize,
      endDate: activeRundate.endDate ? moment(activeRundate.endDate) : null,
      endTime: activeRundate.endDate
        ? moment(activeRundate.endDate).format('HH:mm')
        : '',
      event: {
        descriptionPL: activeRundate.event.descriptionPL,
        id: activeRundate.event.id,
        titlePL: activeRundate.event.titlePL,
      },
      place: activeRundate.place || activeRundate.event.place,
      placeId: activeRundate.place?.id || activeRundate.event.place?.id || null,
      rundate: activeRundate.rundate ? moment(activeRundate.rundate) : moment(),
      runtime: activeRundate.rundate
        ? moment(activeRundate.rundate).format('HH:mm')
        : '',
      startTime: activeRundate.startDate
        ? moment(activeRundate.startDate).format('HH:mm')
        : '',
    };
  }

  return defaultValues;
};

export const prepareObjectAfterSave = (
  values: IRundateValues
): IEditRundatePayload => {
  return {
    emissionSize: Number(values.emissionSize),
    endDate: values.endDate
      ? `${moment(values.endDate).format('YYYY-MM-DD')} ${values.endTime}:00`
      : undefined,
    event: {
      descriptionPL: values.event.descriptionPL,
      id: values.event.id,
      titlePL: values.event.titlePL,
    },
    placeId: values.placeId,
    rundate: `${moment(values.rundate).format('YYYY-MM-DD')} ${
      values.runtime
    }:00`,
    startDate: values.startTime
      ? `${moment(values.rundate).format('YYYY-MM-DD')} ${values.startTime}:00`
      : undefined,
  };
};
