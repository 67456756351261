import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  cell: {
    boxSizing: 'border-box',
    flex: 1,
    padding: 5,
  },
  header: {
    fontFamily: 'NoirPro',
    fontWeight: 500,
  },
  text: {
    fontSize: 8,
  },
});

export default styles;
