import HttpError from '@Misc/classes/HttpError';

const FORBIDDEN_TEXT =
  'Nie masz uprawnień do tej funkcjonalności, skontaktuj się z Administratorem.';

export interface IError {
  statusCode?: number;
  message: string;
  response?: {
    data?: {
      message: string;
    };
    status?: number;
    statusCode?: number;
  };
}

const catchHttpError = (error: any) => {
  if ('request' in error && error.request && 'status' in error.request) {
    switch (error.request.status) {
      case 403:
        return new HttpError(FORBIDDEN_TEXT, error.request.status);
      default:
        return new HttpError('Http Error', error.request.status);
    }
  }

  return error;
};

export const catchNewErrorMessage = (
  error: IError,
  errorCustomMessage?: string
) => {
  if (errorCustomMessage) {
    return {
      message: errorCustomMessage,
    };
  }

  if (error.response && error.response.data && error.response.data.message) {
    return {
      message: error.response.data.message,
      statusCode: error.response.status,
    };
  }

  if (error.response) {
    return {
      message: '',
      statusCode: error.response.status,
    };
  }

  if (error.message && error.statusCode) {
    return {
      message: error.message,
      statusCode: error.statusCode,
    };
  }

  return error;
};

export default catchHttpError;
