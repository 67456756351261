import { IUser } from '@Model/user/types';
import _Store from '@Store';
import { createSelector } from 'reselect';
import { IAuthorizationReducer } from '../types';
import get from './get';

const getCasherInfo = createSelector<
  _Store.IState,
  IAuthorizationReducer,
  IUser
>(
  get,
  (add) => add.casherInfo,
);

export default getCasherInfo;
