import moment from 'moment';

import {
  IEventDiscountDependencyBody,
  IEventsDiscounts,
} from '@Services/$discounts-api/types';

import { IEventDiscountValues } from './EventDiscountForm.types';

export const getInitialValues = (
  values?: IEventsDiscounts
): IEventDiscountValues => {
  if (values) {
    const getDependencies = (): IEventDiscountDependencyBody[] => {
      const returnValue: IEventDiscountDependencyBody[] = [
        { type: 'event', ids: [] },
        { type: 'partner', ids: [] },
        { type: 'rundate', ids: [] },
        { type: 'pool', ids: [] },
      ];

      values.dependencies.forEach((dependency) => {
        const elementToPush = returnValue.find(
          (value) => value.type === dependency.type
        );
        if (elementToPush) {
          elementToPush.ids.push({
            event: dependency.meta.event,
            id: dependency.externalId,
            isCalendarEvent: dependency.meta.isCalendarEvent,
            name: dependency.meta.name,
            rundate: dependency.meta.rundate,
            rundateDescription: dependency.meta.rundateDescription,
            titlePL: dependency.meta.titlePL,
          });
        }
      });

      return returnValue;
    };

    return {
      code: values.code,
      dependencies: getDependencies(),
      discountType: !!values.valuePercentage ? 1 : 0,
      expireDate: values.expireDate,
      expireTime: values.expireDate
        ? moment(values.expireDate).format('HH:mm')
        : null,
      id: values.id,
      itemLimit: values.itemLimit,
      refundType: values.refundType,
      startDate: values.startDate,
      startTime: values.startDate
        ? moment(values.startDate).format('HH:mm')
        : null,
      ticketLimit: values.ticketLimit,
      value: values.value,
      valuePercentage: values.valuePercentage,
    };
  }

  return {
    code: '',
    dependencies: [],
    discountType: 0,
    expireDate: null,
    expireTime: null,
    itemLimit: null,
    refundType: null,
    startDate: null,
    startTime: null,
    ticketLimit: null,
    value: 0,
    valuePercentage: 0,
  };
};
