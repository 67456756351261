import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPoolDescriptions } from '@Services/$going-rundate-api/types';

import { IPoolsReducer } from '../types';
import getPools from './getPools';

const getPoolDescriptions = createSelector<
  _Store.IState,
  IPoolsReducer,
  IPoolDescriptions[]
>([getPools], (pools) => pools.poolDescriptions);

export default getPoolDescriptions;
