import { createSelector } from 'reselect';

import _Store from '@Store';
import { ICategory, IProductsReducer } from '../types';
import get from './get';

const getAllProducts = createSelector<
  _Store.IState,
  IProductsReducer,
  ICategory[]
>([get], (products) => products.categories);

export default getAllProducts;
