export const MOUNTED = 'Zagrywki/refundsRegistration/MOUNTED';

export const CATCH_CHANGE_REFUNDS_REGISTRATION_PAGE =
  'Zagrywki/refundsRegistration/CATCH_CHANGE_REFUNDS_REGISTRATION_PAGE';
export const CHANGE_CHANGE_REFUNDS_REGISTRATION_SEARCH =
  'Zagrywki/refundsRegistration/CHANGE_CHANGE_REFUNDS_REGISTRATION_SEARCH';
export const CHANGE_CHANGE_REFUNDS_REGISTRATION_FILTER =
  'Zagrywki/refundsRegistration/CHANGE_CHANGE_REFUNDS_REGISTRATION_FILTER';
export const CLEAR_REFUNDS_REGISTRATION_FILTER =
  'Zagrywki/refundsRegistration/CLEAR_REFUNDS_REGISTRATION_FILTER';
export const CATCH_GET_SINGLE_REFUNDS_REGISTRATION =
  'Zagrywki/refundsRegistration/CATCH_GET_SINGLE_REFUNDS_REGISTRATION';
export const HANDLE_ACTIVE_REFUNDS_REGISTRATION =
  'Zagrywki/refundsRegistration/HANDLE_ACTIVE_REFUNDS_REGISTRATION';
export const CATCH_EDIT_REFUNDS_REGISTRATION =
  'Zagrywki/refundsRegistration/CATCH_EDIT_REFUNDS_REGISTRATION';
export const CATCH_EDIT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES =
  'Zagrywki/refundsRegistration/CATCH_EDIT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES';

export const _GET_REFUNDS_REGISTRATION_REQUEST =
  'Zagrywki/refundsRegistration/_GET_REFUNDS_REGISTRATION_REQUEST';
export const _GET_REFUNDS_REGISTRATION_FAILURE =
  'Zagrywki/refundsRegistration/_GET_REFUNDS_REGISTRATION_FAILURE';
export const _GET_REFUNDS_REGISTRATION_SUCCESS =
  'Zagrywki/refundsRegistration/_GET_REFUNDS_REGISTRATION_SUCCESS';

export const _GET_REFUNDS_REGISTRATION_METADATA_REQUEST =
  'Zagrywki/refundsRegistration/_GET_REFUNDS_REGISTRATION_METADATA_REQUEST';
export const _GET_REFUNDS_REGISTRATION_METADATA_FAILURE =
  'Zagrywki/refundsRegistration/_GET_REFUNDS_REGISTRATION_METADATA_FAILURE';
export const _GET_REFUNDS_REGISTRATION_METADATA_SUCCESS =
  'Zagrywki/refundsRegistration/_GET_REFUNDS_REGISTRATION_METADATA_SUCCESS';

export const _GET_SINGLE_REFUNDS_REGISTRATION_REQUEST =
  'Zagrywki/refundsRegistration/_GET_SINGLE_REFUNDS_REGISTRATION_REQUEST';
export const _GET_SINGLE_REFUNDS_REGISTRATION_SUCCESS =
  'Zagrywki/refundsRegistration/_GET_SINGLE_REFUNDS_REGISTRATION_SUCCESS';
export const _GET_SINGLE_REFUNDS_REGISTRATION_FAILURE =
  'Zagrywki/refundsRegistration/_GET_SINGLE_REFUNDS_REGISTRATION_FAILURE';

export const _PUT_REFUNDS_REGISTRATION_REQUEST =
  'Zagrywki/refundsRegistration/_PUT_REFUNDS_REGISTRATION_REQUEST';
export const _PUT_REFUNDS_REGISTRATION_SUCCESS =
  'Zagrywki/refundsRegistration/_PUT_REFUNDS_REGISTRATION_SUCCESS';
export const _PUT_REFUNDS_REGISTRATION_FAILURE =
  'Zagrywki/refundsRegistration/_PUT_REFUNDS_REGISTRATION_FAILURE';

export const _PUT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES_REQUEST =
  'Zagrywki/refundsRegistration/_PUT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES_REQUEST';
export const _PUT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES_SUCCESS =
  'Zagrywki/refundsRegistration/_PUT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES_SUCCESS';
export const _PUT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES_FAILURE =
  'Zagrywki/refundsRegistration/_PUT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES_FAILURE';
