import { createSelector } from 'reselect';

import _Store from '@Store';

import { IRuleTypesReducer } from '../types';

import get from './get';

const getCount = createSelector<_Store.IState, IRuleTypesReducer, number>(
  [get],
  (products) => products.count
);

export default getCount;
