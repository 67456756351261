import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'PriceTypes.AddPriceTypeModal.FormLayout',
  });

  const {
    touched,
    errors,
    values,
    handleBlur,
    handleChange,
    closeModal,
    submitForm,
  } = props;

  const classes = useStyles();

  return (
    <>
      <DialogContent className={classes.content}>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12}>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <TextField
                  id="title"
                  label={(touched.title && errors.title) || t('priceTypeName')}
                  helperText={t('priceTypeNameHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  name="title"
                  error={!!(touched.title && errors.title)}
                  fullWidth={true}
                  disabled={!!(values.id && values.title === 'default')}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <TextField
                  id="description"
                  label={
                    (touched.description && errors.description) ||
                    t('description')
                  }
                  helperText={t('descriptionHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                  name="description"
                  error={!!(touched.description && errors.description)}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.translation}
                    onChange={handleChange}
                    name="translation"
                    color="primary"
                  />
                }
                label={t('translation')}
              />
            </Grid>
            {values.translation && (
              <>
                <Grid item={true} xs={12}>
                  <TextField
                    id="titleEN"
                    label={
                      (touched.titleEN && errors.titleEN) ||
                      t('priceTypeEnName')
                    }
                    helperText={t('priceTypeEnNameHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.titleEN}
                    name="titleEN"
                    error={!!(touched.titleEN && errors.titleEN)}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    id="descriptionEN"
                    label={
                      (touched.descriptionEN && errors.descriptionEN) ||
                      t('descriptionEn')
                    }
                    helperText={t('descriptionEnHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.descriptionEN}
                    name="descriptionEN"
                    error={!!(touched.descriptionEN && errors.descriptionEN)}
                    fullWidth={true}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('add')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
