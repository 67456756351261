import { getType } from 'typesafe-actions';

import {
  changeFilter,
  changeSize,
  getTransactions,
  handleActivePage,
  setActiveTransaction,
} from '../actions';
import { IAction, ITransactionsReducer } from '../types';

const initialState: ITransactionsReducer = {
  activeTransaction: null,
  filters: {
    billExist: false,
  },
  meta: {
    page: 1,
    size: 20,
    total: 0,
  },
  transactions: [],
};

const transactionsReducer = (
  state: ITransactionsReducer = initialState,
  action: IAction
): ITransactionsReducer => {
  switch (action.type) {
    case getType(getTransactions.success):
      return {
        ...state,
        meta: action.payload.meta,
        transactions: action.payload.data,
      };
    case getType(changeFilter):
      return {
        ...state,
        filters: action.payload,
        meta: {
          ...state.meta,
          page: 1,
        },
      };
    case getType(handleActivePage):
      return {
        ...state,
        meta: {
          ...state.meta,
          page: action.payload,
        },
      };
    case getType(changeSize):
      return {
        ...state,
        meta: {
          ...state.meta,
          size: action.payload,
        },
      };
    case getType(setActiveTransaction):
      return {
        ...state,
        activeTransaction: action.payload,
      };
    default:
      return state;
  }
};

export default transactionsReducer;
