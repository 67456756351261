import makeStyles from '@mui/styles/makeStyles';

import colors from '@Compo/App/colors';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: colors.primary.main,
    marginTop: '-0.67em',
  },
  button: {
    borderRadius: 20,
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(1),
    },
  },
  selected: {
    backgroundColor: `${colors.secondary.dark}!important`,
  },
  tooltip: {
    backgroundColor: colors.primary.main,
    color: colors.primary.contrastText,
    top: -15,
  },
}));

export default useStyles;
