import React from 'react';

import { Formik } from 'formik';

import FormLayout from './../FormLayout';
import { IMailFormProps } from './MailForm.types';
import { MailSchema } from './MailForm.validation';

const MailForm = ({ initialValues, save, isRundateEdit }: IMailFormProps) => {
  if (initialValues) {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={MailSchema}
        onSubmit={save}
        children={(propsFormik) => (
          <FormLayout {...propsFormik} isRundateEdit={isRundateEdit} />
        )}
      />
    );
  }
  return null;
};

export default MailForm;
