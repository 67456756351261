const ID_TEXT = 'ID';
const NAME_TEXT = 'Name';
const PARTNER_TEXT = 'Partner';

const EVENT_NAME_TEXT = 'Wydarzenie';

const POOL_ID_TEXT = 'ID Puli';
const POOL_NAME_TEXT = 'Nazwa Puli';
const POOL_PRICE_TEXT = 'Cena Puli';

const RUNDATE_ID_TEXT = 'Rundate ID';

const CITY_TEXT = 'Miasto';
const PLACE_TEXT = 'Miejsce';
const DATE_TEXT = 'Data';
const TIME_TEXT = 'Godzina';

export const dialogTitles = {
  event: 'Dodaj wydarzenia',
  partner: 'Dodaj partnera',
  pool: 'Dodaj pulę',
  rundate: 'Dodaj rundate',
};

export const columns = {
  event: [
    { id: 'id', label: ID_TEXT },
    { id: 'titlePL', label: EVENT_NAME_TEXT },
  ],
  partner: [
    { id: 'id', label: ID_TEXT },
    { id: 'name', label: PARTNER_TEXT },
  ],
  pool: [
    { id: 'id', label: POOL_ID_TEXT },
    { id: 'poolDescription.titlePLFormatted', label: POOL_NAME_TEXT },
    { id: 'priceFormatted', label: POOL_PRICE_TEXT },
    { id: 'rundate.event.titlePL', label: EVENT_NAME_TEXT },
    { id: 'rundate.id', label: RUNDATE_ID_TEXT },
  ],
  rundate: [
    { id: 'id', label: RUNDATE_ID_TEXT },
    { id: 'event.titlePL', label: NAME_TEXT },
    { id: 'event.place.city.name', label: CITY_TEXT },
    { id: 'event.place.name', label: PLACE_TEXT },
    { id: 'date', label: DATE_TEXT },
    { id: 'time', label: TIME_TEXT },
  ],
};
