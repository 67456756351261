import React from 'react';

import { Button, Tooltip } from '@mui/material';
import GridUi from '@mui/material/Grid';
import cn from 'classnames';

import getShortTime from '@Misc/helpers/getShortTime';
import { ITimeSlot } from '@Model/reservation/types';

import { ITimeSlotsProps } from './TimeSlots';
import styles from './TimeSlots.module.scss';
import useStyles from './TimeSlots.styles';

const TimeSlots = ({
  setSlot,
  timeSlots,
  isUpSell,
  handleUpSell,
  isUpSellSelected,
  upSellValue,
  slotRef,
  isScopeView,
  isOverbooked,
  groupedTimeSlots,
  isLoading,
  happening,
}: ITimeSlotsProps) => {
  const classes = useStyles();

  const checkIsUpSellAvailableForNexSlot = (key: number): boolean => {
    if (isUpSell && !happening?.multipleSlotReservation) {
      const selectedIndex = timeSlots.findIndex(
        (timeSlot) => timeSlot.isSelected
      );

      if (selectedIndex !== -1) {
        const isLast = selectedIndex + 1 === timeSlots.length;

        return !isLast && key - 1 === selectedIndex;
      }
    }
    return false;
  };

  const catchEventPreventSubmitForm = (slot: ITimeSlot, key: number) => {
    const isSomeIsSelected = timeSlots.some((timeSlot) => timeSlot.isSelected);
    if (isSomeIsSelected && checkIsUpSellAvailableForNexSlot(key)) {
      handleUpSell();
    } else {
      setSlot(slot);
      if (isUpSell && isUpSellSelected && !happening?.multipleSlotReservation) {
        handleUpSell();
      }
    }
  };
  if (isLoading) {
    return null;
  }

  return (
    <GridUi
      container={true}
      spacing={1}
      className={styles.container}
      data-testid="timeSlots"
    >
      {isOverbooked
        ? groupedTimeSlots.map((row) =>
            row.slots.map((slot, key) => (
              <GridUi
                item={true}
                xs={12}
                md={isScopeView ? 6 : 3}
                key={slot.startTime + key}
              >
                <Tooltip
                  open={checkIsUpSellAvailableForNexSlot(key)}
                  title={upSellValue || ''}
                  arrow={true}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    onClick={() => catchEventPreventSubmitForm(slot, key)}
                    disabled={
                      slot.isDisabled ||
                      (isUpSell &&
                        !checkIsUpSellAvailableForNexSlot(key) &&
                        !happening?.multipleSlotReservation &&
                        !slot.isSelected)
                    }
                    ref={slot.isSelected ? slotRef : null}
                    className={cn(
                      classes.button,
                      (slot.isSelected ||
                        (isUpSellSelected &&
                          checkIsUpSellAvailableForNexSlot(key))) &&
                        classes.selected
                    )}
                  >
                    {isScopeView
                      ? `${getShortTime(slot.startTime)} - ${slot.duration}`
                      : getShortTime(slot.startTime)}
                  </Button>
                </Tooltip>
              </GridUi>
            ))
          )
        : timeSlots.map((slot, key) => (
            <GridUi
              item={true}
              xs={12}
              md={isScopeView ? 6 : 3}
              key={slot.startTime + key}
            >
              <Tooltip
                open={checkIsUpSellAvailableForNexSlot(key)}
                title={upSellValue || ''}
                arrow={true}
                placement="bottom"
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
              >
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  onClick={() => catchEventPreventSubmitForm(slot, key)}
                  disabled={
                    slot.isDisabled ||
                    (isUpSell &&
                      !checkIsUpSellAvailableForNexSlot(key) &&
                      !happening?.multipleSlotReservation &&
                      !slot.isSelected)
                  }
                  ref={slot.isSelected ? slotRef : null}
                  className={cn(
                    classes.button,
                    (slot.isSelected ||
                      (isUpSellSelected &&
                        checkIsUpSellAvailableForNexSlot(key))) &&
                      classes.selected
                  )}
                >
                  {isScopeView
                    ? `${getShortTime(slot.startTime)} - ${slot.duration}`
                    : getShortTime(slot.startTime)}
                </Button>
              </Tooltip>
            </GridUi>
          ))}
    </GridUi>
  );
};

export default TimeSlots;
