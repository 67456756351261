import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  _GET_ALL_CATEGORIES_FAILURE,
  _GET_ALL_CATEGORIES_REQUEST,
  _GET_ALL_CATEGORIES_SUCCESS,
  _GET_ALL_FAILURE,
  _GET_ALL_INVOICE_PRODUCTS_FAILURE,
  _GET_ALL_INVOICE_PRODUCTS_REQUEST,
  _GET_ALL_INVOICE_PRODUCTS_SUCCESS,
  _GET_ALL_LOCAL_PRODUCTS_FAILURE,
  _GET_ALL_LOCAL_PRODUCTS_REQUEST,
  _GET_ALL_LOCAL_PRODUCTS_SUCCESS,
  _GET_ALL_PRODUCTS_BY_CATEGORY_FAILURE,
  _GET_ALL_PRODUCTS_BY_CATEGORY_REQUEST,
  _GET_ALL_PRODUCTS_BY_CATEGORY_SUCCESS,
  _GET_ALL_PRODUCTS_WITH_CATEGORIES_FAILURE,
  _GET_ALL_PRODUCTS_WITH_CATEGORIES_REQUEST,
  _GET_ALL_PRODUCTS_WITH_CATEGORIES_SUCCESS,
  _GET_ALL_REQUEST,
  _GET_ALL_SHIPMENT_FEE_FAILURE,
  _GET_ALL_SHIPMENT_FEE_REQUEST,
  _GET_ALL_SHIPMENT_FEE_SUCCESS,
  _GET_ALL_SUCCESS,
  _GET_ALL_ZOHO_PRODUCTS_FAILURE,
  _GET_ALL_ZOHO_PRODUCTS_REQUEST,
  _GET_ALL_ZOHO_PRODUCTS_SUCCESS,
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SINGLE_PRODUCT_FAILURE,
  _GET_SINGLE_PRODUCT_REQUEST,
  _GET_SINGLE_PRODUCT_SUCCESS,
  _GET_SUCCESS,
  ADD_CATEGORY,
  ADD_LOCAL_PRODUCT,
  ADD_PRODUCT,
  ADD_SHIPMENT_FEE,
  HANDLE_ACTIVE_CATEGORY,
  HANDLE_ACTIVE_INVOICE_PRODUCT,
  HANDLE_ACTIVE_LOCAL_PRODUCT,
  HANDLE_ACTIVE_PAGE,
  HANDLE_ACTIVE_SHIPMENT_FEE,
  HANDLE_ACTIVE_ZOHO_PRODUCT,
  MOUNTED,
  PRODUCTS_LIST_MOUNTED,
  REMOVE_CATEGORY,
  REMOVE_LOCAL_PRODUCT,
  REMOVE_PRODUCT,
  SAVE_CATEGORY,
  SAVE_PRODUCT,
  SELECT_PRODUCT,
  SET_PRODUCT_COUNT,
  SET_PRODUCT_STORAGE,
  SET_PRODUCTS_VIEW,
  UN_SELECT_PRODUCT,
  UPDATE_LIST,
} from '../constants/actions';
import {
  ICategory,
  IGetCategoriesSuccessPayload,
  IGetLocalProductsPayload,
  IGetLocalProductsSuccessPayload,
  IGetProductsByCategorySuccessPayload,
  IGetProductsPayload,
  IGetShipmentFeesSuccessPayload,
  ILocalProduct,
  IProduct,
  IProductBody,
  IShipmentFee,
  IViews,
} from '../types';

export const getProducts = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE
)<IGetProductsPayload, IProduct[], Error>();

export const getAllProducts = createAsyncAction(
  _GET_ALL_REQUEST,
  _GET_ALL_SUCCESS,
  _GET_ALL_FAILURE
)<undefined, IProduct[], Error>();

export const getAllLocalProducts = createAsyncAction(
  _GET_ALL_LOCAL_PRODUCTS_REQUEST,
  _GET_ALL_LOCAL_PRODUCTS_SUCCESS,
  _GET_ALL_LOCAL_PRODUCTS_FAILURE
)<IGetLocalProductsPayload, IGetLocalProductsSuccessPayload, Error>();

export const getAllCategories = createAsyncAction(
  _GET_ALL_CATEGORIES_REQUEST,
  _GET_ALL_CATEGORIES_SUCCESS,
  _GET_ALL_CATEGORIES_FAILURE
)<IGetLocalProductsPayload, IGetCategoriesSuccessPayload, Error>();

export const getAllShipmentFee = createAsyncAction(
  _GET_ALL_SHIPMENT_FEE_REQUEST,
  _GET_ALL_SHIPMENT_FEE_SUCCESS,
  _GET_ALL_SHIPMENT_FEE_FAILURE
)<IGetLocalProductsPayload, IGetShipmentFeesSuccessPayload, Error>();

export const getAllZohoProducts = createAsyncAction(
  _GET_ALL_ZOHO_PRODUCTS_REQUEST,
  _GET_ALL_ZOHO_PRODUCTS_SUCCESS,
  _GET_ALL_ZOHO_PRODUCTS_FAILURE
)<IGetLocalProductsPayload, IGetLocalProductsSuccessPayload, Error>();

export const getAllInvoiceProducts = createAsyncAction(
  _GET_ALL_INVOICE_PRODUCTS_REQUEST,
  _GET_ALL_INVOICE_PRODUCTS_SUCCESS,
  _GET_ALL_INVOICE_PRODUCTS_FAILURE
)<IGetLocalProductsPayload, IGetLocalProductsSuccessPayload, Error>();

export const getAllProductsWithCategories = createAsyncAction(
  _GET_ALL_PRODUCTS_WITH_CATEGORIES_REQUEST,
  _GET_ALL_PRODUCTS_WITH_CATEGORIES_SUCCESS,
  _GET_ALL_PRODUCTS_WITH_CATEGORIES_FAILURE
)<IGetLocalProductsPayload, IGetCategoriesSuccessPayload, Error>();
export const getProductsByCategory = createAsyncAction(
  _GET_ALL_PRODUCTS_BY_CATEGORY_REQUEST,
  _GET_ALL_PRODUCTS_BY_CATEGORY_SUCCESS,
  _GET_ALL_PRODUCTS_BY_CATEGORY_FAILURE
)<IGetLocalProductsPayload, IGetProductsByCategorySuccessPayload, Error>();
export const getSingleProduct = createAsyncAction(
  _GET_SINGLE_PRODUCT_REQUEST,
  _GET_SINGLE_PRODUCT_SUCCESS,
  _GET_SINGLE_PRODUCT_FAILURE
)<number, ILocalProduct | null, Error>();

export const saveCategory = createStandardAction(SAVE_CATEGORY)<ICategory>();
export const saveProduct = createStandardAction(SAVE_PRODUCT)<IProductBody>();

export const addProduct = createStandardAction(ADD_PRODUCT)<IProduct>();
export const removeProduct = createStandardAction(REMOVE_PRODUCT)<IProduct>();
export const selectProduct = createStandardAction(SELECT_PRODUCT)<IProduct>();
export const unSelectProduct =
  createStandardAction(UN_SELECT_PRODUCT)<IProduct>();
export const setProductView = createStandardAction(SET_PRODUCTS_VIEW)<IViews>();

export const mountedProducts = createStandardAction(MOUNTED)();

export const productsListMounted = createStandardAction(
  PRODUCTS_LIST_MOUNTED
)();

export const setProductCount = createStandardAction(SET_PRODUCT_COUNT)<{
  product: IProduct;
  count: number;
}>();

export const setProductStorage = createStandardAction(SET_PRODUCT_STORAGE)<{
  product: IProduct;
  storageHouseId?: number;
}>();
export const updateList = createStandardAction(UPDATE_LIST)<IProduct[]>();

export const addCategory = createStandardAction(ADD_CATEGORY)();
export const handleActiveCategory = createStandardAction(
  HANDLE_ACTIVE_CATEGORY
)<ICategory | null>();
export const addLocalProduct = createStandardAction(ADD_LOCAL_PRODUCT)();
export const handleActiveLocalProduct = createStandardAction(
  HANDLE_ACTIVE_LOCAL_PRODUCT
)<ILocalProduct | null>();
export const handleActiveInvoiceProduct = createStandardAction(
  HANDLE_ACTIVE_INVOICE_PRODUCT
)<number | null>();
export const addShipmentFee = createStandardAction(ADD_SHIPMENT_FEE)();
export const handleActiveShipmentFee = createStandardAction(
  HANDLE_ACTIVE_SHIPMENT_FEE
)<IShipmentFee | null>();

export const handleActiveZohoProduct = createStandardAction(
  HANDLE_ACTIVE_ZOHO_PRODUCT
)<ILocalProduct | null>();

export const handleActivePage =
  createStandardAction(HANDLE_ACTIVE_PAGE)<IGetLocalProductsPayload>();

export const removeLocalProduct =
  createStandardAction(REMOVE_LOCAL_PRODUCT)<number>();
export const removeCategory = createStandardAction(REMOVE_CATEGORY)<number>();
