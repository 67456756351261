import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { redirectBuyingContinue } from '@Model/iframe/actions';
import { completeAdvancePayment } from '@Model/reservation/actions';
import {
  calculateMixPayment,
  getReservationPrintData,
} from '@Model/reservation/selectors';
import { SUMMARY } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import SummaryMix from './SummaryMix.component';
import {
  ISummaryMixFromDispatch,
  ISummaryMixFromState,
} from './SummaryMix.types';

const mapStateToProps = (state: _Store.IState): ISummaryMixFromState => {
  return {
    isLoading: getLoading(SUMMARY)(state),
    mixPayment: calculateMixPayment(state),
    reservationPrintData: getReservationPrintData(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISummaryMixFromDispatch => ({
  completeAdvancePayment: (id) => dispatch(completeAdvancePayment(id)),
  continueBuying: () => dispatch(redirectBuyingContinue()),
});

export default connect<
  ISummaryMixFromState,
  ISummaryMixFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(SummaryMix);
