import { createSelector } from 'reselect';

import { IMailFormValues } from '@Compo/Mail/components/MailForm/MailForm.types';
import _Store from '@Store';
import { IMailingReducer } from './../types';
import get from './get';

const getSelectedEventMail = createSelector<
  _Store.IState,
  IMailingReducer,
  IMailFormValues | null
>([get], (mailing) => mailing.selectedMail);

export default getSelectedEventMail;
