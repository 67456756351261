import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './FormLayout.module.scss';
import { IFormLayoutProps } from './FormLayout.types';

export const DROP_DOWN_INPUT_DEFAULT_VALUE = -1;

const useStyles = makeStyles((theme) => ({
  form: {
    overflowY: 'auto',
  },
  input: {
    marginTop: theme.spacing(8),
  },
}));

const FormLayout = ({
  handleSubmit,
  values,
  touched,
  errors,
  handleChange,
  printers,
  handleBlur,
  closeModal,
  submitForm,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'SessionPanel.components.FormLayout',
  });

  const classes = useStyles();

  const handleCloseModal = () => {
    closeModal();
    document.body.style.overflow = 'visible';
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={cn(styles.form, classes.form)}>
        <DialogContent>
          <TextField
            id="startCash"
            label={(touched.startCash && errors.startCash) || t('startCash')}
            helperText={t('startCashHelper')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.startCash}
            name="startCash"
            error={!!(touched.startCash && errors.startCash)}
            fullWidth={true}
          />
          <FormControl
            variant="standard"
            fullWidth={true}
            className={classes.input}
          >
            <InputLabel>
              {(touched.printerId && errors.printerId) || t('printer')}
            </InputLabel>
            <Select
              value={values.printerId || -1}
              onChange={handleChange}
              defaultValue={-1}
              fullWidth={true}
              name="printerId"
              onBlur={handleBlur}
            >
              <MenuItem value={-1}>-</MenuItem>
              {printers &&
                printers.map((printer) =>
                  !printer.openSession ? (
                    <MenuItem
                      value={printer.id || printer.saleUrl}
                      key={printer.id || printer.saleUrl}
                    >
                      {printer.printerId}
                    </MenuItem>
                  ) : null
                )}
            </Select>
            <FormHelperText>{t('printerHelper')}</FormHelperText>
          </FormControl>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={handleCloseModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
