import React, { useContext, useEffect, useState } from 'react';

import { IReservationSaveBody } from '@/services/$reservations-api/types';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { clearContext } from '@Compo/Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import AddSubtract from '@Compo/reusable/AddSubtract';
import config from '@Config';
import { Colors } from '@Constants/Colors';
import { TypographyVariant } from '@Constants/Variants/Typography';
import { getSpellNumberOfPeople } from '@Misc/helpers/strings/peopleCount';
import translateDefaultPriceType from '@Misc/helpers/strings/translateDefaultPrice';
import { IZagrywkiBasket } from '@Model/basket/types';
import { IProduct } from '@Model/products/types';

import styles from './Basket.module.scss';
import useStyles from './Basket.styles';
import { IBasketProps } from './Basket.types';

const WITHOUT_DATA_KEY = 'withoutData';
const WITH_DATA_KEY = 'withData';
const WITH_INVOICE_DATA_KEY = 'withInvoiceData';
const WITH_SHORTENED_INVOICE_DATA_KEY = 'withShortenedInvoiceDataKey';

const Basket = ({
  checkDiscount,
  discount,
  isSession,
  products,
  removeProduct,
  saveReservation,
  setDiscountCode,
  setTransactionType,
  prepaidCard,
  setPrepaidCard,
  valueAfterDiscount,
  setProductCount,
  getPrice,
  totalPrice,
  reservations,
  tickets,
  setDelayedTransaction,
  setMixPayment,
  clearPrintData,
  isLoading,
  handleChange,
  handleBlur,
  values,
  isSaleWithoutSession,
  isMdagUser,
  setProductStorage,
  errors,
  touched,
  setFieldTouched,
  setFieldValue,
}: IBasketProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Add.components.FormLayout.Basket',
  });
  const state = useContext(clearContext);
  const isDefaultCurrency = state?.basketItems?.length
    ? state?.basketItems?.[0]?.currency === config.cms.defaultCurrency
    : true;

  const dropDownItems = [
    { name: t('withoutData'), key: WITHOUT_DATA_KEY },
    { name: t('withData'), key: WITH_DATA_KEY },
    {
      disabled: totalPrice ? totalPrice > 450 : false || !isDefaultCurrency,
      key: WITH_SHORTENED_INVOICE_DATA_KEY,
      name: t('shortenedInvoiceData'),
    },
    {
      disabled: !isDefaultCurrency,
      key: WITH_INVOICE_DATA_KEY,
      name: t('withInvoiceData'),
    },
  ];

  const [selectedItem, setSelectedItem] = useState(dropDownItems[0].key);
  const [showSessionInfo, setShowSessionInfo] = useState(false);
  const classes = useStyles();
  const [timeOut, setTimeOut] = useState<NodeJS.Timeout | number>(0);

  if (!state) {
    return null;
  }

  const getTime = (dateTime: string | null): string => {
    if (dateTime) {
      const timezoneDate = moment.utc(dateTime).format('YYYY-MM-DD HH:mm:ss');
      return moment(timezoneDate).format('DD.MM.YYYY HH:mm:ss');
    }
    return '';
  };

  // TODO: get correct data when will be ready

  const body = {
    basketItems: state && state.basketItems ? state.basketItems : [],
    // date,
    // emptyReservation,
    idempotencyKey: state && state.idempotencyKey ? state.idempotencyKey : '',
    // startTime: slot && slot.startTime ? slot.startTime : null,

    delayedTransaction: false,
    // description: formValues.description
    //   ? formValues.description
    //   : undefined,
    discount,
    discountCode: values.discountCode,
    // happeningId: Number(formValues.happeningId),
    // numberOfPeople: formValues.numberOfPeople,
    onDone: state ? state.clearBasket : () => null,
    paymentOperator: 2,
    prepaidCard,
    shortenedInvoice:
      selectedItem === WITH_SHORTENED_INVOICE_DATA_KEY
        ? values.shortenedInvoice
        : undefined,
    // reservationCheckbox,
    // showDiscountForm,
    // spaceId: Number(formValues.spaceId),
    // upsell,
    // ...getUser(),
    // ...prepareFactureData(),
  };

  const catchSave = () => {
    if (selectedItem === WITHOUT_DATA_KEY) {
      saveReservation(body as IReservationSaveBody);
    } else if (selectedItem === WITH_SHORTENED_INVOICE_DATA_KEY) {
      setFieldTouched('shortenedInvoice', true);
      if (!errors.shortenedInvoice) {
        saveReservation(body as IReservationSaveBody);
      }
    } else {
      setTransactionType(selectedItem);
    }
  };

  const catchMixPayment = () => {
    if (selectedItem === WITHOUT_DATA_KEY) {
      setMixPayment();
      clearPrintData();
    } else if (selectedItem === WITH_SHORTENED_INVOICE_DATA_KEY) {
      setFieldTouched('shortenedInvoice', true);
      if (!errors.shortenedInvoice) {
        setMixPayment();
        clearPrintData();
      }
    } else {
      setTransactionType(selectedItem);
      setMixPayment();
      clearPrintData();
    }
  };

  const catchDelay = () => {
    if (selectedItem === WITHOUT_DATA_KEY) {
      saveReservation({
        ...(body as IReservationSaveBody),
        delayedTransaction: true,
      });
    } else if (selectedItem === WITH_SHORTENED_INVOICE_DATA_KEY) {
      setFieldTouched('shortenedInvoice', true);
      if (!errors.shortenedInvoice) {
        saveReservation({
          ...(body as IReservationSaveBody),
          delayedTransaction: true,
        });
      }
    } else {
      setTransactionType(selectedItem);
      setDelayedTransaction();
    }
  };

  const getItemPrice = (discountCode?: string) => {
    getPrice({
      basketItems: state && state.basketItems ? state.basketItems : [],
      discountCode: discountCode || discount,
    });
  };

  const renderItems = () => {
    const handleRemove = (id: number) => {
      if (state) {
        state.removeFromBag(id);
      }
    };

    const getProductPrice = (product: IProduct): number => {
      if (product.count) {
        return product.price * product.count;
      }
      return product.price;
    };

    const addPeopleCount = (item: IZagrywkiBasket) => {
      if (
        item.maxNumberOfPeople &&
        item.numberOfPeople < item.maxNumberOfPeople &&
        !item.isEvent
      ) {
        state.updateItem({
          ...item,
          numberOfPeople: item.numberOfPeople + 1,
          selectedPrices: [...item.selectedPrices, item.priceType],
        });
      } else if (
        item.isEvent &&
        item.maxNumberOfPeople &&
        item.numberOfPeople < item.maxNumberOfPeople
      ) {
        state.updateItem({
          ...item,
          numberOfPeople: item.numberOfPeople + 1,
        });
      } else if (item.isEvent && isMdagUser) {
        state.updateItem({
          ...item,
          numberOfPeople: item.numberOfPeople + 1,
        });
      }
    };

    const subtractPeopleCount = (item: IZagrywkiBasket) => {
      if (item.numberOfPeople > 1 && !item.isEvent) {
        state.updateItem({
          ...item,
          numberOfPeople: item.numberOfPeople - 1,
          selectedPrices: item.selectedPrices.slice(0, -1),
        });
      } else if (item.numberOfPeople > 1 && item.isEvent) {
        state.updateItem({ ...item, numberOfPeople: item.numberOfPeople - 1 });
      } else {
        state.removeFromBag(item.id);
      }
    };

    const catchUpSellInBasketItem = (item: IZagrywkiBasket) => {
      if (item.isUpSellSelected && state) {
        const updatedItem: IZagrywkiBasket = {
          ...item,
          isUpSellSelected: false,
        };
        state.updateItem(updatedItem);
      }
    };

    const addProductCount = (product: IProduct) => {
      setProductCount(product, (product.count || 0) + 1);
    };

    const subtractProductCount = (product: IProduct) => {
      if (product.count && product.count > 1) {
        setProductCount(product, product.count - 1);
      } else {
        removeProduct(product);
      }
    };

    const getBasketItemSummaryPrice = (
      item: IZagrywkiBasket,
      reservationIndex: number,
      ticketIndex: number
    ) => {
      if (tickets && tickets[ticketIndex] && item.isEvent) {
        return tickets[ticketIndex].totalPrice.toFixed(2);
      }
      if (reservations && reservations[reservationIndex] && !item.isEvent) {
        return reservations[reservationIndex].totalPrice.toFixed(2);
      }
      if (item.calculatePricePerPerson) {
        if (item.isUpSellSelected) {
          return (
            item.price * item.numberOfPeople +
            item.upSellPice * item.numberOfPeople
          ).toFixed(2);
        }
        return (item.price * item.numberOfPeople).toFixed(2);
      }
      if (item.isUpSellSelected) {
        return (item.price + item.upSellPice).toFixed(2);
      }
      return item.price.toFixed(2);
    };

    const getBasketItemFee = (
      item: IZagrywkiBasket,
      reservationIndex: number,
      ticketIndex: number
    ): string | 0 => {
      if (tickets && tickets[ticketIndex] && item.isEvent) {
        return tickets[ticketIndex].fee.toFixed(2);
      }
      if (!item.isEvent && reservations && reservations[reservationIndex]) {
        return reservations[reservationIndex].fee.toFixed(2);
      }

      return 0;
    };

    const getFormattedItems = () => {
      let reservationIndex = -1;
      let ticketIndex = -1;
      return state.basketItems.map((item) => {
        if (item.isEvent) ticketIndex++;
        if (!item.isEvent) reservationIndex++;

        return {
          ...item,
          fee: getBasketItemFee(item, reservationIndex, ticketIndex),
          totalPrice: getBasketItemSummaryPrice(
            item,
            reservationIndex,
            ticketIndex
          ),
        };
      });
    };

    return (
      <div className={styles.basketWrapper}>
        {!isEmpty && (
          <>
            <Typography
              className={classes.title}
              variant={TypographyVariant.h5}
            >
              {t('basket')}
            </Typography>
            <Divider className={classes.divider} />
          </>
        )}
        <div className={classes.itemContainer}>
          {getFormattedItems().map((basketItem, index) => {
            return (
              <>
                <ListItem className={classes.item}>
                  <Grid
                    container={true}
                    justifyContent="space-between"
                    alignItems="center"
                    className={cn(
                      classes.addSubtractContainer,
                      basketItem.calculatePricePerPerson &&
                        classes.addSubtractContainerPerRoom
                    )}
                  >
                    <Grid
                      item={true}
                      lg={7}
                      xs={basketItem.calculatePricePerPerson ? 12 : 7}
                    >
                      <Typography variant={TypographyVariant.body1}>
                        {basketItem.calculatePricePerPerson
                          ? `${basketItem.title} (
                        ${basketItem.numberOfPeople} ${getSpellNumberOfPeople(
                              basketItem.numberOfPeople
                            )})`
                          : basketItem.title}
                      </Typography>
                      <Typography
                        variant={TypographyVariant.body2}
                        color={Colors.textSecondary}
                      >
                        {basketItem.timeRange}
                      </Typography>
                      <Typography
                        variant={TypographyVariant.body2}
                        color={Colors.textSecondary}
                      >
                        {basketItem.space}
                      </Typography>
                      <Typography
                        variant={TypographyVariant.body2}
                        color={Colors.textSecondary}
                      >
                        {translateDefaultPriceType(basketItem.priceTypeName)}
                      </Typography>
                      {basketItem.isUpSellSelected && (
                        <Typography
                          variant={TypographyVariant.body2}
                          color={Colors.textSecondary}
                        >
                          {`${t('addTime')} (${basketItem.upSellPice} ${t(
                            'localCurrency'
                          )})`}
                        </Typography>
                      )}
                    </Grid>

                    {basketItem.calculatePricePerPerson ? (
                      <Grid item={true} lg={5} xs={12}>
                        <Grid
                          container={true}
                          alignItems="center"
                          justifyContent="center"
                          className={classes.container}
                        >
                          <AddSubtract
                            count={basketItem.numberOfPeople}
                            add={() => addPeopleCount(basketItem)}
                            subtract={() => subtractPeopleCount(basketItem)}
                            unit={t('addSubtractUnit')}
                            variant="withoutSidePaddings"
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <>
                        <Grid item={true} xs={5}>
                          <Grid container={true} justifyContent="flex-end">
                            <IconButton
                              size="small"
                              color="secondary"
                              onClick={() => handleRemove(basketItem.id)}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Grid container={true} xs={12}>
                      <Grid item={true} xs={true}>
                        <Typography
                          variant={TypographyVariant.body2}
                          color={Colors.textSecondary}
                        >
                          {t('fee')}
                        </Typography>
                      </Grid>
                      <Grid item={true}>
                        <Typography
                          variant={TypographyVariant.body2}
                          color={Colors.textSecondary}
                        >
                          {`${basketItem.fee} ${
                            basketItem.currency || t('localCurrency')
                          }`}
                        </Typography>
                      </Grid>
                    </Grid>
                    {basketItem.seats && (
                      <>
                        <Grid container={true} xs={12}>
                          <Grid item={true} xs={true}>
                            <Typography
                              variant={TypographyVariant.body2}
                              color={Colors.textSecondary}
                            >
                              {t('row')} {basketItem.seats[0].split('-')[0]}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container={true} xs={12}>
                          <Grid item={true} xs={true}>
                            <Typography
                              variant={TypographyVariant.body2}
                              color={Colors.textSecondary}
                            >
                              {t('place')} {basketItem.seats[0].split('-')[1]}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </ListItem>
                <Grid
                  container={true}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Typography variant={TypographyVariant.body1}>{`${t(
                    'summary'
                  )} ${basketItem.totalPrice} ${
                    basketItem.currency || t('localCurrency')
                  }`}</Typography>
                </Grid>
              </>
            );
          })}

          {products.map((product) => (
            <>
              <ListItem className={classes.item}>
                <Grid
                  container={true}
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.addSubtractContainer}
                >
                  <Grid item={true} lg={7} xs={12}>
                    <Typography variant={TypographyVariant.body1}>
                      {product.name}
                    </Typography>
                    <Typography
                      variant={TypographyVariant.body2}
                      color={Colors.textSecondary}
                    >
                      {product.price} {t('currencyByCount')}
                    </Typography>
                    <Typography
                      variant={TypographyVariant.body2}
                      color={Colors.textSecondary}
                    >
                      {t('quantity')} {product.count}
                    </Typography>
                  </Grid>
                  <Grid item={true} lg={5} xs={12}>
                    <Grid
                      container={true}
                      alignItems="center"
                      justifyContent="center"
                      className={classes.container}
                    >
                      <AddSubtract
                        count={product.count || 0}
                        add={() => addProductCount(product)}
                        subtract={() => subtractProductCount(product)}
                        variant="withoutSidePaddings"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
              {!!product.stock.length && (
                <Grid>
                  <FormControl variant="standard" className={classes.select}>
                    <Select
                      variant="standard"
                      value={String(product.storageHouseId)}
                      onChange={(e) =>
                        setProductStorage(
                          product,
                          e.target.value !== 'undefined'
                            ? Number(e.target.value)
                            : undefined
                        )
                      }
                      defaultValue={String(product.storageHouseId)}
                      className={classes.select}
                    >
                      <MenuItem value={'undefined'}>
                        {t('storageEmpty')}
                      </MenuItem>
                      {product.stock.map((stock) => (
                        <MenuItem
                          key={stock.storageHouseId}
                          value={stock.storageHouseId}
                        >
                          {stock.storageHouseName}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{t('choseStorageHelper')}</FormHelperText>
                  </FormControl>
                </Grid>
              )}
              <Grid
                container={true}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography variant={TypographyVariant.body1}>{`${t(
                  'summary'
                )} ${getProductPrice(product)?.toFixed(2)} ${t(
                  'localCurrency'
                )}`}</Typography>
              </Grid>
            </>
          ))}
        </div>
      </div>
    );
  };

  const isEmpty = !state.basketItems.length && !products.length;
  const isAnyPoolHasDependency = state.basketItems.some(
    (item) => item.hasDependency
  );
  const isAnyItemHasExtendedUpsell = state.basketItems.some(
    (item) => item.extendedDuration
  );

  const getSummaryPrice = (): { totalPrice: number; price: number } => {
    // Get base price
    // let price = state.getSummedPrice(state.basketItems);
    let price = 0;

    // Add upSell price
    state.basketItems.forEach((basketItem) => {
      if (basketItem.isUpSellSelected && basketItem.calculatePricePerPerson) {
        price +=
          basketItem.price * basketItem.numberOfPeople +
          basketItem.upSellPice * basketItem.numberOfPeople;
      } else if (
        basketItem.isUpSellSelected &&
        !basketItem.calculatePricePerPerson
      ) {
        price += basketItem.price + basketItem.upSellPice;
      } else if (basketItem.calculatePricePerPerson) {
        price +=
          basketItem.price *
          basketItem.numberOfPeople *
          (basketItem.selectedExtendedSlot?.priceMultiplier || 1);
      } else {
        price += basketItem.price;
      }
    });

    // Add products price
    products.forEach((product) => {
      price += product.price * (product.count || 1);
    });

    return {
      price,
      totalPrice: totalPrice || price,
    };
  };

  const getDiscountValue = (): string | null => {
    if (valueAfterDiscount) {
      return (
        Number(getSummaryPrice().totalPrice) - valueAfterDiscount
      ).toFixed(2);
    }
    return null;
  };

  const catchDiscountCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountCode(event.target.value);
    event.persist();
    if (timeOut && typeof timeOut === 'number') {
      clearTimeout(timeOut);
    }
    setTimeOut(
      setTimeout(() => {
        getItemPrice(event.target.value);
      }, 1000)
    );
  };

  const catchPrepaidCard = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPrepaidCard(event.currentTarget.value);
  };

  const catchChangeSelectedItem = (e: SelectChangeEvent<string>) => {
    const type = String(e.target.value);
    setSelectedItem(type);
    if (type === WITH_SHORTENED_INVOICE_DATA_KEY) {
      setFieldValue('isShortenedInvoice', true);
    } else {
      setFieldValue('isShortenedInvoice', false);
    }
  };

  useEffect(() => {
    getItemPrice();
  }, [...state.basketItems.map((item) => item.numberOfPeople), products]);

  useEffect(() => {
    if (!isDefaultCurrency) {
      setDiscountCode('');
      setPrepaidCard('');
    }
  }, [isDefaultCurrency]);

  useEffect(() => {
    if (
      totalPrice &&
      totalPrice > 450 &&
      selectedItem === WITH_SHORTENED_INVOICE_DATA_KEY
    ) {
      setSelectedItem(WITHOUT_DATA_KEY);
    }
  }, [totalPrice]);

  return (
    <div
      className={cn(styles.summaryBox, isLoading && classes.loading)}
      onMouseEnter={() => {
        if (!isSession && !isEmpty && !isSaleWithoutSession) {
          setShowSessionInfo(true);
        }
      }}
      onMouseLeave={() => setShowSessionInfo(false)}
    >
      {isEmpty && (
        <div className={styles.info}>
          <div>
            {t('emptyBasket1')}
            <b>{t('emptyBasket2')}</b>
            <br />
            <b> {t('emptyBasket3')}</b>
            {t('emptyBasket4')}
          </div>
          <div className={styles.emptyBasket} />
        </div>
      )}

      {renderItems()}

      {isAnyPoolHasDependency && (
        <div className={classes.itemContainer}>
          <TextField
            variant="standard"
            id="discountCode"
            name="discountCode"
            label={t('dependencyCode')}
            helperText={t('dependencyCodeHelper')}
            autoFocus={true}
            onChange={handleChange}
            value={values.discountCode}
            fullWidth={true}
          />
        </div>
      )}

      {!isEmpty && (
        <>
          <Grid
            container={true}
            justifyContent="space-between"
            alignItems="center"
            className={classes.summary}
          >
            {isAnyItemHasExtendedUpsell && (
              <>
                <Grid item={true} xs={12}>
                  <Grid
                    container={true}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item={true}>
                      <Typography
                        variant={TypographyVariant.body2}
                        color={Colors.textPrimary}
                      >
                        {t('normalPrice')}
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textPrimary}
                        className={classes.normalPrice}
                      >
                        {getSummaryPrice().price.toFixed(2)}{' '}
                        {t('localCurrency')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item={true} xs={12}>
                  <Grid
                    container={true}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid item={true}>
                      <Typography
                        variant={TypographyVariant.body2}
                        color={Colors.textPrimary}
                      >
                        {t('discountValue')}
                      </Typography>
                    </Grid>
                    <Grid item={true}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textPrimary}
                      >
                        {(
                          getSummaryPrice().price - getSummaryPrice().totalPrice
                        ).toFixed(2)}{' '}
                        {t('localCurrency')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid item={true} xs={12}>
              <Grid
                container={true}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item={true}>
                  <Typography
                    variant={TypographyVariant.body2}
                    color={Colors.textPrimary}
                  >
                    {t('summary')}
                  </Typography>
                </Grid>

                <Grid item={true}>
                  {!isLoading && (
                    <Typography
                      variant={TypographyVariant.h4}
                      color={Colors.primary}
                    >
                      {`${getSummaryPrice().totalPrice.toFixed(2)} ${
                        state.basketItems[0]?.currency || t('localCurrency')
                      }`}
                    </Typography>
                  )}
                  {isLoading && <CircularProgress disableShrink={true} />}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {getDiscountValue() && (
            <div className={styles.summary}>
              <div className={styles.left}>{t('discount')}</div>
              <div className={styles.right}>
                {`- ${getDiscountValue()} ${t('localCurrency')}`}
              </div>
            </div>
          )}
        </>
      )}

      <div
        className={cn(
          styles.inputsContainer,
          (isEmpty || !isSession) && !isSaleWithoutSession && styles.disabled
        )}
      >
        <div className={classes.buttonsWrapper}>
          <Tooltip
            title={t('notificationSession') as string}
            open={showSessionInfo}
            placement="top"
            arrow={true}
          >
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={catchSave}
              className={classes.button}
              disabled={isEmpty}
            >
              {isMdagUser ? t('printTickets') : t('pay')}
            </Button>
          </Tooltip>
          {!isMdagUser && (
            <>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                onClick={catchMixPayment}
                className={classes.button}
              >
                {t('mixPayment')}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                onClick={catchDelay}
                className={classes.button}
              >
                {t('shareBasket')}
              </Button>
            </>
          )}
        </div>
        {!isMdagUser && (
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <FormControl variant="standard" className={classes.select}>
                <Select
                  value={selectedItem}
                  onChange={catchChangeSelectedItem}
                  defaultValue={dropDownItems[0].key}
                  className={classes.select}
                >
                  {dropDownItems.map((item) => (
                    <MenuItem
                      key={item.key}
                      value={item.key}
                      disabled={item.disabled}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{t('transactionType')}</FormHelperText>
              </FormControl>
            </Grid>

            {selectedItem === WITH_SHORTENED_INVOICE_DATA_KEY && (
              <Grid item={true} xs={12}>
                <TextField
                  id="shortenedInvoice"
                  variant="standard"
                  name="shortenedInvoice"
                  label={
                    (touched.shortenedInvoice && errors.shortenedInvoice) ||
                    t('shortenedInvoice')
                  }
                  helperText={t('shortenedInvoiceHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.shortenedInvoice}
                  fullWidth={true}
                  error={
                    !!(errors.shortenedInvoice && touched.shortenedInvoice)
                  }
                />
              </Grid>
            )}

            <Grid item={true} xs={12}>
              <TextField
                id="discount"
                variant="standard"
                label={t('inputDiscountCode')}
                helperText={t('discountCodeHelper')}
                onChange={catchDiscountCode}
                value={discount}
                fullWidth={true}
                disabled={!isDefaultCurrency}
              />
            </Grid>

            <Grid item={true} xs={12}>
              <TextField
                id="prepaid"
                variant="standard"
                label={t('inputPrepaidCard')}
                helperText={t('prepaidCard')}
                onChange={catchPrepaidCard}
                value={prepaidCard}
                fullWidth={true}
                disabled={!isDefaultCurrency}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};

export default Basket;
