import { createSelector } from 'reselect';

import _Store from '@Store';
import { ILocalProduct, IProductsReducer } from '../types';
import get from './get';

const getAllLocalProducts = createSelector<
  _Store.IState,
  IProductsReducer,
  ILocalProduct[]
>([get], (products) => products.localProducts);

export default getAllLocalProducts;
