import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: 4,
    textTransform: 'uppercase',
  },
  tooltipIcon: {
    fontSize: '1rem',
    transform: 'translate(0.33rem, 0.2rem)',
  },
}));

export default useStyles;
