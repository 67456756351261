import { FirebaseError } from '@firebase/util';
import { getApp, initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import config from '@Config';
import { ISignInPayload } from '@Services/$authorization-api/types';
import { printErrorByCode } from '@Services/$firebase-api/helpers';

class FirebaseApi {
  public initializeApp() {
    initializeApp(config.firebase);
  }

  public signIn(payload: ISignInPayload): Promise<string> {
    const { email, password } = payload;
    const app = getApp();
    const auth = getAuth(app);

    return new Promise<string>((resolve, reject) => {
      signInWithEmailAndPassword(auth, email, password)
        .then((data) => resolve(data.user.getIdToken()))
        .catch((error: FirebaseError) => reject(printErrorByCode(error)));
    });
  }
}

export default new FirebaseApi();
