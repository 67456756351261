import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import FlipToBackIcon from '@mui/icons-material/FlipToBack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SearchIcon from '@mui/icons-material/Search';
import SettingsBackupRestoreOutlinedIcon from '@mui/icons-material/SettingsBackupRestoreOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import Filters from '@Compo/RefundsRegistration/components/Filters';
import RefundsRegistrationModal from '@Compo/RefundsRegistration/components/RefundsRegistrationModal';
import {
  daysToReturn,
  formatDate,
  PLZlotyLocale,
} from '@Compo/RefundsRegistration/RefundsRegistration.helpers';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import {
  IEnum,
  IRefundsRegistration,
  IRefundsRegistrationFilters,
  RefundStatus,
} from '@Services/$refunds-registration-api/types';

import {
  availabilities,
  getAvailabilityName,
  statuses,
} from './availabilitiesStatusChange';
import useStyles from './RefundsRegistration.styles';
import { IRefundsRegistrationsProps } from './RefundsRegistration.types';

const RefundsRegistration = ({
  handleChangePage,
  mounted,
  refundsRegistrations,
  meta,
  isLoading,
  changeRefundsRegistrationsSearch,
  changeRefundsRegistrationsFilter,
  filterValues,
  edit,
  editMultipleStatuses,
}: IRefundsRegistrationsProps) => {
  const { t: tAvailabilities } = useTranslation('components', {
    keyPrefix: 'RefundsRegistration.availabilitiesStatusChange',
  });

  const { t } = useTranslation('components', {
    keyPrefix: 'RefundsRegistration',
  });

  const classes = useStyles();
  const [showFilters, setShowFilters] = useState(false);
  const [filtersActive, setFiltersActive] = useState(false);
  const [searchTimeOut, setSearchTimeOut] = useState<NodeJS.Timeout | null>(
    null
  );
  const [inputText, setInputText] = useState(filterValues.search);
  const [open, setOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<IRefundsRegistration[]>(
    []
  );
  const anchorRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    mounted();
  }, []);

  useEffect(() => {
    actionForFilter(filterValues);
  }, [filterValues]);

  const catchChangePage = (event: unknown, page: number) => {
    handleChangePage(page + 1);
  };

  const searchFunc = (text: string) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }

    setSearchTimeOut(
      setTimeout(() => {
        changeRefundsRegistrationsSearch({
          ...filterValues,
          search: text || undefined,
        });
      }, 1000)
    );
  };

  const catchHandleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.currentTarget.value);
    searchFunc(e.currentTarget.value);
  };

  const catchHandleFiltersSubmit = (filter: IRefundsRegistrationFilters) => {
    changeRefundsRegistrationsFilter(filter);
    actionForFilter(filter);
  };

  const actionForFilter = (filter: IRefundsRegistrationFilters) => {
    setFiltersActive(
      filter.status?.length !== 0 ||
        filter.refundType?.length !== 0 ||
        filter.refundMaxTime?.length !== 0
    );

    setInputText(filter.search);

    setShowFilters(false);
  };

  const formatStatus = (status?: IEnum) => {
    if (status === undefined) {
      return status;
    }

    const name = (
      <Typography component="span" variant="body2" color="textPrimary">
        {status.name}
      </Typography>
    );
    let icon;

    switch (status.code) {
      case RefundStatus.init:
        icon = (
          <ScheduleIcon className={cn(classes.icons, classes.iconSchedule)} />
        );
        break;

      case RefundStatus.accepted:
        icon = (
          <CheckCircleOutlinedIcon
            className={cn(classes.icons, classes.iconCheck)}
          />
        );
        break;

      case RefundStatus.rejectedTicket:
      case RefundStatus.rejectedEvent:
        icon = (
          <NotInterestedIcon className={cn(classes.icons, classes.iconClose)} />
        );
        break;

      case RefundStatus.toRefund:
        icon = (
          <SettingsBackupRestoreOutlinedIcon
            className={cn(classes.icons, classes.iconSchedule)}
          />
        );
        break;

      case RefundStatus.clarify:
        icon = (
          <HelpOutlineIcon className={cn(classes.icons, classes.iconError)} />
        );
        break;

      case RefundStatus.manualRefund:
        icon = (
          <RotateLeftIcon className={cn(classes.icons, classes.iconSchedule)} />
        );
        break;

      case RefundStatus.refundDone:
        icon = (
          <CheckCircleOutlinedIcon
            className={cn(classes.icons, classes.iconCheck)}
          />
        );
        break;

      case RefundStatus.voucherToGenerate:
        icon = (
          <FlipToBackIcon className={cn(classes.icons, classes.iconSchedule)} />
        );
        break;

      case RefundStatus.voucherGenerated:
        icon = (
          <LibraryAddCheckOutlinedIcon
            className={cn(classes.icons, classes.iconCheck)}
          />
        );
        break;

      case RefundStatus.refundCompleted:
        icon = (
          <HowToRegOutlinedIcon
            className={cn(classes.icons, classes.iconCheck)}
          />
        );
        break;

      default:
        break;
    }

    return (
      <Grid container={true}>
        {icon}
        {name}
      </Grid>
    );
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const setOwnSelected = (items: IRefundsRegistration[]) => {
    setSelectedItems(items);
  };

  const changeStatuses = (status: RefundStatus) => {
    editMultipleStatuses({
      refundRegistrations: selectedItems.map((item) => ({
        id: item.id,
        status,
      })),
    });
    setSelectedItems([]);
  };

  const columns = [
    { id: 'id', label: t('id'), hided: true },
    { id: 'ticket.transactionItem.transaction.id', label: t('transactionId') },
    { id: 'ticket.name', label: t('userName') },
    { id: 'ticket.email', label: t('userEmail') },
    {
      id: 'ticket.transactionItem.pool.rundate.event.titleFormatted',
      label: t('eventName'),
    },
    { id: 'ticket.entryToken', label: t('ticketEntryToken') },
    { id: 'ticket.transactionItem.amountFormatted', label: t('ticketAmount') },
    {
      id: 'ticket.transactionItem.transaction.purchaseTimeFormatted',
      label: t('transactionPurchaseTime'),
    },
    { id: 'initDateFormatted', label: t('initDate') },
    { id: 'statusFormatted', label: t('status') },
    { id: 'refundType.name', label: t('refundType') },
    { id: 'refundMaxTime', label: t('daysForReturn') },
    { id: 'daysToReturn', label: t('daysToReturn') },
  ];

  const normalizedRefundsRegistrations = refundsRegistrations.map(
    (refundsRegistration) => {
      const ticket = refundsRegistration.ticket;
      const transactionItem = ticket?.transactionItem;
      const rundate = transactionItem?.pool.rundate;
      const event = rundate?.event;
      const changeMonitor =
        refundsRegistration.changeMonitor || rundate?.lastChangeMonitor;

      return {
        ...refundsRegistration,
        daysToReturn: daysToReturn(refundsRegistration),
        initDateFormatted: formatDate(refundsRegistration.initDate),
        refundMaxTime: changeMonitor?.refundMaxTime,
        statusFormatted: formatStatus(refundsRegistration.status),
        ticket: {
          ...ticket,
          name: `${ticket?.firstname} ${ticket?.lastname}`,
          transactionItem: {
            ...transactionItem,
            amountFormatted:
              transactionItem?.amount !== undefined &&
              transactionItem?.serviceFee !== undefined
                ? PLZlotyLocale.format(transactionItem?.amount / 100) +
                  ' + ' +
                  PLZlotyLocale.format(transactionItem?.serviceFee / 100)
                : null,
            pool: {
              rundate: {
                event: {
                  titleFormatted: `${event?.titlePL}, ${formatDate(
                    rundate?.rundate
                  )}, ${event?.place?.city?.name}`,
                },
              },
            },
            transaction: {
              ...transactionItem?.transaction,
              purchaseTimeFormatted: formatDate(
                transactionItem?.transaction.purchaseTime,
                true
              ),
            },
          },
        },
      };
    }
  );

  return (
    <GoingPaper>
      {isLoading ? (
        <Loader className={classes.loader} />
      ) : (
        <GenericTable>
          <RefundsRegistrationModal />
          <TableActions>
            <TextField
              variant="standard"
              name="search"
              className={classes.search}
              helperText={t('search')}
              value={inputText}
              onChange={catchHandleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
            />
            <Grid container={true} justifyContent="flex-end" spacing={2}>
              <Grid item={true}>
                <Button
                  variant="outlined"
                  size="small"
                  color={filtersActive ? 'primary' : 'secondary'}
                  startIcon={<TuneIcon />}
                  className={classes.button}
                  onClick={() => setShowFilters(!showFilters)}
                >
                  {t('filter')}
                </Button>
              </Grid>
              <Grid item={true}>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  startIcon={<ArrowDropDownIcon />}
                  className={classes.button}
                  onClick={handleToggle}
                  ref={anchorRef}
                >
                  {t('changeStatus')}
                </Button>
              </Grid>
            </Grid>
          </TableActions>

          <Popper
            open={open}
            transition={true}
            disablePortal={true}
            anchorEl={anchorRef.current}
            className={classes.popper}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      {statuses.map((status) => (
                        <MenuItem
                          key={status}
                          className={classes.item}
                          disabled={
                            !availabilities[
                              selectedItems[0]?.status.code
                            ]?.includes(status)
                          }
                          onClick={() => changeStatuses(status)}
                        >
                          {getAvailabilityName(status, tAvailabilities)}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          {showFilters && (
            <Grid
              container={true}
              justifyContent="flex-end"
              className={classes.filtersContainer}
            >
              <Formik
                component={Filters}
                onSubmit={catchHandleFiltersSubmit}
                initialValues={filterValues}
              />
            </Grid>
          )}
          <TableUi
            rows={normalizedRefundsRegistrations}
            handleChangePage={catchChangePage}
            columns={columns}
            total={meta.total}
            activePage={meta.page - 1}
            rowsPerPage={meta.size}
            isLoading={isLoading}
            onRowClick={(id) => edit(Number(id))}
            forceCheckboxSelection={true}
            enableSameFieldNameCheckbox="status.code"
            selectedItems={selectedItems}
            setOwnSelected={setOwnSelected}
          />
        </GenericTable>
      )}
    </GoingPaper>
  );
};

export default RefundsRegistration;
