import axios, { CancelTokenSource } from 'axios';
import path from 'path';
import qs from 'qs';

import { IExampleTicket } from '@Compo/Rundates/Pools/components/FormLayout/components/Tickets/Tickets.types';
import config from '@Config';
import catchHttpError, {
  catchNewErrorMessage,
} from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IEditRundatePayload } from '@Model/going/rundates/types';

import {
  ICheckExternalResponse,
  IExampleTicketResponse,
  IFilter,
  IGetRundatesSuccessPayload,
  IGetSingleRundateSuccessPayload,
  IMonitorType,
  IPoolBody,
  IPoolDescriptions,
  IPoolsResponse,
  ISaleReport,
  ISaveRundateResponse,
  ISinglePool,
  ISingleTicketResponse,
  ITicketsListBody,
  ITicketsListResponse,
  ITicketsListTicketsResponse,
} from './types';

const MAX_COUNT = 999;

class GoingRundateApi {
  private static getRundatesUrl(): string {
    return `${config.api.cmsServices}rundate`;
  }

  private static getRundateUrl(id: number): string {
    return `${config.api.cmsServices}rundate/${id}`;
  }

  private static getRundatePoolsUrl(id: number): string {
    return `${config.api.cmsServices}rundate/${id}/pools`;
  }

  private static getDeleteRundatePoolUrl(
    rundateId: number,
    poolId: number
  ): string {
    return `${config.api.cmsServices}rundate/${rundateId}/pools/${poolId}`;
  }

  private static getSinglePoolUrl(rundateId: number, poolId: number): string {
    return `${config.api.cmsServices}rundate/${rundateId}/pools/${poolId}`;
  }

  private static getPoolSaveUrl(rundateId: number): string {
    return `${config.api.cmsServices}rundate/${rundateId}/pools`;
  }

  private static getPoolEditUrl(rundateId: number, poolId: number): string {
    return `${config.api.cmsServices}rundate/${rundateId}/pools/${poolId}`;
  }

  private static getPoolDescriptionsUrl(): string {
    return `${config.api.cmsServices}dependent/pool-descriptions`;
  }

  private static getExampleTicketUrl(poolId: number) {
    return `${config.api.cmsServices}tickets/example/${poolId}`;
  }

  private static getTicketsListUrl(rundateId: number) {
    return `${config.api.cmsServices}rundate/${rundateId}/ticket-lists`;
  }

  private static getTicketsListExternalUrl(rundateId: number) {
    return `${config.api.cmsServices}rundate/${rundateId}/ticket-lists/external`;
  }

  private static getTicketsListExternalCheckUrl(rundateId: number) {
    return `${config.api.cmsServices}rundate/${rundateId}/ticket-lists/external/check`;
  }

  private static getSingleTicketUrl(rundateId: number, id: number) {
    return `${config.api.cmsServices}rundate/${rundateId}/ticket-lists/${id}`;
  }

  private static getTicketsListSaveUrl(rundateId: number, id?: number) {
    if (id) {
      return `${config.api.cmsServices}rundate/${rundateId}/ticket-lists/${id}`;
    }
    return `${config.api.cmsServices}rundate/${rundateId}/ticket-lists`;
  }

  private static getTicketsListTicketsUrl(rundateId: number, id: number) {
    return `${config.api.cmsServices}rundate/${rundateId}/ticket-lists/${id}/tickets`;
  }

  private static getPoolsReorderUrl(rundateId: number) {
    return `${config.api.cmsServices}rundate/${rundateId}/pools/reorder`;
  }

  private static getChangeSaleUrl(rundateId: number) {
    return `${config.api.cmsServices}rundate/${rundateId}/sale`;
  }

  private static getChangeVisibilityUrl(rundateId: number) {
    return `${config.api.cmsServices}rundate/${rundateId}/visibility`;
  }

  private static getChangeRundateHasExternalImageUrl(rundateId: number) {
    return `${config.api.cmsServices}rundate/${rundateId}/external-image`;
  }

  private static getStatsUrl(rundateId: number) {
    return `${config.api.cmsServices}rundate/${rundateId}/stats`;
  }

  private static getChangeMonitorTypeUrl(rundateId: number) {
    return `${config.api.cmsServices}rundate/${rundateId}/change-monitor-type`;
  }

  private static getMonitorTypesUrl() {
    return `${config.api.cmsServices}rundate-change-monitor/types`;
  }

  private cancelTokenEvents?: CancelTokenSource;

  public getRundates(
    partnerId: string,
    allFilters: IFilter,
    page: number = 1,
    count: number = 20
  ): Promise<IGetRundatesSuccessPayload> {
    const { withCalendarEvents, withExpired, withRemoved, ...filter } =
      allFilters;

    return new Promise<IGetRundatesSuccessPayload>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      axios
        .get(GoingRundateApi.getRundatesUrl(), {
          cancelToken: this.cancelTokenEvents.token,
          params: {
            count,
            page,
            partnerIds: [Number(partnerId)],
            ...(filter && { filter }),
            withCalendarEvents,
            withExpired,
            withRemoved,
          },
          paramsSerializer(params) {
            return qs.stringify(params, { encode: false });
          },
        })
        .then(getData)
        .then((response: IGetRundatesSuccessPayload) => {
          resolve({ data: response.data, meta: response.meta });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getRundate(id: number): Promise<IGetSingleRundateSuccessPayload> {
    return new Promise<IGetSingleRundateSuccessPayload>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      axios
        .get(GoingRundateApi.getRundateUrl(id), {
          cancelToken: this.cancelTokenEvents.token,
        })
        .then(getData)
        .then((response: IGetRundatesSuccessPayload) => {
          resolve({ data: response.data[0] });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public editRundate(id: number, body: IEditRundatePayload): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = GoingRundateApi.getRundateUrl(id);
      axios
        .put(url, JSON.stringify(body))
        .then(getData)
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public saveRundate(
    body: IEditRundatePayload,
    partnerId: string
  ): Promise<ISaveRundateResponse> {
    return new Promise<ISaveRundateResponse>((resolve, reject) => {
      const url = GoingRundateApi.getRundatesUrl();
      axios
        .post(
          url,
          JSON.stringify({
            ...body,
            event: { ...body.event, partnerId: Number(partnerId) },
          })
        )
        .then(getData)
        .then(getData)
        .then((data) => {
          resolve(data[0]);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getRundatePools(id: number): Promise<IPoolsResponse[]> {
    return new Promise<IPoolsResponse[]>((resolve, reject) => {
      const url = GoingRundateApi.getRundatePoolsUrl(id);
      this.cancelTokenEvents = axios.CancelToken.source();

      axios
        .get(url, { cancelToken: this.cancelTokenEvents.token })
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public deleteRundatePool(rundateId: number, poolId: number): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      const url = GoingRundateApi.getDeleteRundatePoolUrl(rundateId, poolId);
      this.cancelTokenEvents = axios.CancelToken.source();

      axios
        .delete(url, { cancelToken: this.cancelTokenEvents.token })
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public getSingleRundatePool(
    rundateId: number,
    poolId: number
  ): Promise<ISinglePool> {
    return new Promise<ISinglePool>((resolve, reject) => {
      const url = GoingRundateApi.getSinglePoolUrl(rundateId, poolId);
      this.cancelTokenEvents = axios.CancelToken.source();

      axios
        .get(url, { cancelToken: this.cancelTokenEvents.token })
        .then(getData)
        .then(getData)
        .then((response) => resolve(response[0]))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public savePool(body: IPoolBody, rundateId: number): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();

      if (body.id) {
        axios
          .put(
            GoingRundateApi.getPoolEditUrl(rundateId, body.id),
            { ...body },
            { cancelToken: this.cancelTokenEvents.token }
          )
          .then(getData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(catchHttpError(error)));
      } else {
        axios
          .post(
            GoingRundateApi.getPoolSaveUrl(rundateId),
            { ...body },
            { cancelToken: this.cancelTokenEvents.token }
          )
          .then(getData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(catchHttpError(error)));
      }
    });
  }

  public getPoolDescriptions(
    partnerId: string,
    search: string
  ): Promise<IPoolDescriptions[]> {
    return new Promise<IPoolDescriptions[]>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url = GoingRundateApi.getPoolDescriptionsUrl();

      axios
        .get(url, {
          params: {
            count: MAX_COUNT,
            'filter[search]': search,
            partnerIds: [partnerId],
          },
        })
        .then(getData)
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getExampleTicket(
    poolId: number,
    ticket: IExampleTicket
  ): Promise<IExampleTicketResponse> {
    return new Promise<IExampleTicketResponse>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url = GoingRundateApi.getExampleTicketUrl(poolId);

      const { languageId, salesChannelId, isBoxOffice } = ticket;

      axios
        .get(url, {
          headers: {
            'Access-Control-Expose-Headers': 'Content-Disposition',
          },
          params: {
            isBoxOffice,
            languageId,
            salesChannelId,
          },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          const filename = response.headers['content-disposition']
            .split('filename=')[1]
            .split('.')[0];
          const extension = path
            .extname(response.headers['content-disposition'])
            .split(';')[0];

          resolve({
            data: response.data,
            fileName: `${filename}.${extension}`,
          });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getTicketsList(rundateId: number): Promise<ITicketsListResponse> {
    return new Promise<ITicketsListResponse>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url = GoingRundateApi.getTicketsListUrl(rundateId);

      axios
        .get(url, {
          params: {
            count: MAX_COUNT,
          },
        })
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getSingleTicket(
    rundateId: number,
    id: number
  ): Promise<ISingleTicketResponse> {
    return new Promise<ISingleTicketResponse>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url = GoingRundateApi.getSingleTicketUrl(rundateId, id);

      axios
        .get(url)
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(response[0]);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getRundateStats(rundateId: number): Promise<ISaleReport> {
    return new Promise<ISaleReport>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url = GoingRundateApi.getStatsUrl(rundateId);

      axios
        .get(url)
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(response[0]);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public saveTicketsList(
    rundateId: number,
    body: ITicketsListBody
  ): Promise<[]> {
    return new Promise<[]>((resolve) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url = GoingRundateApi.getTicketsListSaveUrl(rundateId, body.id);

      if (body.id) {
        axios
          .put(url, body)
          .then(getData)
          .then(resolve)
          .catch((error) => catchHttpError(error));
      } else {
        axios
          .post(url, body)
          .then(getData)
          .then(resolve)
          .catch((error) => catchHttpError(error));
      }
    });
  }

  public getTicketsListTickets(
    rundateId: number,
    id: number,
    page?: number,
    count?: number,
    isDeleted?: boolean,
    searchText?: string
  ): Promise<ITicketsListTicketsResponse> {
    return new Promise<ITicketsListTicketsResponse>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url = GoingRundateApi.getTicketsListTicketsUrl(rundateId, id);

      axios
        .get(url, {
          cancelToken: this.cancelTokenEvents.token,
          params: {
            count,
            'filter[isDeleted]': isDeleted,
            'filter[search]': searchText,
            page,
          },
        })
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public reorderPools(
    rundateId: number,
    orderOfPoolIds: number[]
  ): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url = GoingRundateApi.getPoolsReorderUrl(rundateId);

      axios
        .put(
          url,
          { orderOfPoolIds },
          { cancelToken: this.cancelTokenEvents.token }
        )
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public changeSaleStatus(rundateId: number, forSale: boolean): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url = GoingRundateApi.getChangeSaleUrl(rundateId);

      axios
        .put(
          url,
          {},
          { cancelToken: this.cancelTokenEvents.token, params: { forSale } }
        )
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public changeVisibilityStatus(
    rundateId: number,
    visible: boolean
  ): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url = GoingRundateApi.getChangeVisibilityUrl(rundateId);

      axios
        .put(
          url,
          {},
          { cancelToken: this.cancelTokenEvents.token, params: { visible } }
        )
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getMonitorTypes(): Promise<IMonitorType[]> {
    return new Promise<IMonitorType[]>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url = GoingRundateApi.getMonitorTypesUrl();

      axios
        .get(url, {
          cancelToken: this.cancelTokenEvents.token,
        })
        .then(getData)
        .then(getData)
        .then((response) => resolve(response[0].changeMonitorTypes))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public changeMonitorType(
    rundateId: number,
    changeMonitorTypeId: number | null
  ): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url = GoingRundateApi.getChangeMonitorTypeUrl(rundateId);

      axios
        .put(
          url,
          { changeMonitorTypeId },
          {
            cancelToken: this.cancelTokenEvents.token,
          }
        )
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public changeRundateHasExternalImage(
    rundateId: number,
    hasExternalImage: boolean
  ): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url =
        GoingRundateApi.getChangeRundateHasExternalImageUrl(rundateId);

      axios
        .put(
          url,
          {},
          {
            cancelToken: this.cancelTokenEvents.token,
            params: { setUp: hasExternalImage },
          }
        )
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public checkExternalTicketList(
    rundateId: number,
    name: string,
    entryTokens: string[]
  ): Promise<ICheckExternalResponse> {
    return new Promise<ICheckExternalResponse>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url = GoingRundateApi.getTicketsListExternalCheckUrl(rundateId);

      axios
        .post(
          url,
          {
            entryTokens,
            name,
          },
          {
            cancelToken: this.cancelTokenEvents.token,
          }
        )
        .then(getData)
        .then(getData)
        .then((response) => resolve(response[0]))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public saveExternalTicketList(
    rundateId: number,
    name: string,
    entryTokens: string[],
    force?:boolean
  ): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const url = GoingRundateApi.getTicketsListExternalUrl(rundateId);

      axios
        .post(
          url,
          {
            entryTokens,
            name,
          },
          {
            cancelToken: this.cancelTokenEvents.token,
            params:{
              force
            }
          }
        )
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelEvents() {
    if (this.cancelTokenEvents) {
      this.cancelTokenEvents.cancel();
      this.cancelTokenEvents = undefined;
    }
  }
}

export default new GoingRundateApi();
