import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  tooltipIcon: {
    fontSize: '1rem',
    transform: 'translate(0.33rem, 0.2rem)',
  },
}));

export default useStyles;
