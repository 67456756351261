import { object, ref, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const MIN_PASSWORD_VALIDATOR_TEXT =
  'Podane hasło musi zawierać co najmniej 7 znaków.';
const MATCH_PASSWORD_VALIDATOR_TEXT = 'Podane hasło nie jest takie samo.';

const configuration = {
  password: string()
    .min(7, MIN_PASSWORD_VALIDATOR_TEXT)
    .required(REQUIRED_VALIDATOR_TEXT),
  repeatPassword: string()
    .required(REQUIRED_VALIDATOR_TEXT)
    .oneOf([ref('password')], MATCH_PASSWORD_VALIDATOR_TEXT),
};

export const ChangePasswordSchema = object().shape(configuration);
