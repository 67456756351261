import { getType } from 'typesafe-actions';
import { getHappening, resetState } from './../actions';
import { IAction, IHappeningReducer } from './../types';

const initialState: IHappeningReducer = null;

const happeningReducer = (
  state: IHappeningReducer = initialState,
  action: IAction,
): IHappeningReducer => {
  switch (action.type) {
    case getType(getHappening.success):
      return action.payload;
    case getType(resetState):
      return initialState;
    default:
      return state;
  }
};

export default happeningReducer;
