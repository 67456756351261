import { connect } from 'react-redux';

import { PRINTER } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import PrinterLoader from './PrinterLoader.component';
import { IPrinterLoaderFromState } from './PrinterLoader.types';

const mapStateToProps = (state: _Store.IState): IPrinterLoaderFromState => {
  return {
    isLoading: getLoading(PRINTER)(state),
  };
};

export default connect<IPrinterLoaderFromState, {}, {}, _Store.IState>(
  mapStateToProps,
  {},
)(PrinterLoader);
