import i18next from 'i18next';

import components_en from '@Translations/en/components.json';
import components_pl from '@Translations/pl/components.json';

i18next.init({
  interpolation: { escapeValue: false },
  lng: localStorage.getItem('GOING_LANGUAGE') || 'pl',
  resources: {
    en: {
      components: components_en,
    },
    pl: {
      components: components_pl,
    },
  },
});

export default i18next;
