import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { get, getFormioAccess } from '@Model/authorization/selectors';
import { attachEntryGroup } from '@Model/formio/actions';
import { redirectBuyingContinue } from '@Model/iframe/actions';
import { catchPrintReceipt } from '@Model/printer/actions';
import { getPrinterData } from '@Model/printer/selectors';
import { retryBocaPrint, summaryMounted } from '@Model/reservation/actions';
import {
  getReservationDetails,
  getReservationPrintData,
} from '@Model/reservation/selectors';
import { SUMMARY } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import Summary from './Summary.component';
import { ISummaryFromDispatch, ISummaryFromState } from './Summary.types';

const mapStateToProps = (state: _Store.IState): ISummaryFromState => {
  const {
    userInfo: { session },
  } = get(state);
  const { printingReceiptHasError } = getPrinterData(state);
  return {
    isLoading: getLoading(SUMMARY)(state),
    isSession: !!(session && session.session_id),
    printingReceiptHasError,
    reservationDetails: getReservationDetails(state),
    reservationPrintData: getReservationPrintData(state),
    showEntryGroupInput: getFormioAccess(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISummaryFromDispatch => ({
  attachEntryGroup: (groupSlug) => dispatch(attachEntryGroup(groupSlug)),
  continueBuying: () => dispatch(redirectBuyingContinue()),
  mounted: () => dispatch(summaryMounted()),
  printReceipt: () => dispatch(catchPrintReceipt()),
  retryBocaPrint: () => dispatch(retryBocaPrint()),
});

export default connect<
  ISummaryFromState,
  ISummaryFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Summary);
