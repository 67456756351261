import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import FormLayout from '../FormLayout';
import { IDetailsModalProps } from './DetailsModal';
import { DetailsModalSchema } from './DetailsModal.validators';

const DetailsModal = ({
  initialValues,
  saveReservation,
  isReservationSelected,
  deselectReservation,
}: IDetailsModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Calendar.components.DetailsModal',
  });

  return (
    <Dialog
      open={isReservationSelected}
      maxWidth="md"
      fullWidth={true}
      onClose={deselectReservation}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <Formik
        component={FormLayout}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={saveReservation}
        validationSchema={DetailsModalSchema}
      />
    </Dialog>
  );
};

export default DetailsModal;
