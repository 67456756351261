import React from 'react';

import moment from 'moment';

import {
  IRefundsRegistration,
  RefundStatus,
} from '@Services/$refunds-registration-api/types';

export const PLZlotyLocale = Intl.NumberFormat('pl', {
  currency: 'PLN',
  style: 'currency',
});

export const formatDate = (
  date?: string,
  withTime: boolean = false
): string | undefined => {
  if (date === undefined) {
    return date;
  }

  const dateMoment = moment(date);

  return withTime
    ? dateMoment.format('DD.MM.YYYY HH:mm')
    : dateMoment.format('DD.MM.YYYY');
};

export const daysToReturn = (refundsRegistration: IRefundsRegistration) =>
  refundsRegistration.changeMonitor &&
  ![
    RefundStatus.accepted.toString(),
    RefundStatus.rejectedTicket.toString(),
    RefundStatus.rejectedEvent.toString(),
  ].includes(refundsRegistration.status.code)
    ? moment(refundsRegistration.initDate)
        .set({ h: 1, m: 0, s: 0 })
        .add(refundsRegistration.changeMonitor.refundMaxTime, 'days')
        .diff(moment().set({ h: 0, m: 0, s: 0 }), 'days')
    : '-';
