import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  editTicketList,
  selectTicketList,
} from '@Model/going/rundates/actions';
import { getTicketsList } from '@Model/going/rundates/selectors';
import { GOING_TICKETS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import TicketsList from './TicketsList.component';
import {
  ITicketsListFromDispatch,
  ITicketsListFromState,
} from './TicketsList.types';

const mapStateToProps = (state: _Store.IState): ITicketsListFromState => ({
  isLoading: getLoading(GOING_TICKETS)(state),
  ticketsList: getTicketsList(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITicketsListFromDispatch => ({
  editTicketList: (id) => dispatch(editTicketList(id)),
  selectTicketList: (values) => dispatch(selectTicketList(values)),
});

export default connect<
  ITicketsListFromState,
  ITicketsListFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(TicketsList);
