import React from 'react';

import { Grid, Typography } from '@mui/material';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import { TypographyVariant } from '@Constants/Variants/Typography';

import useStyles from './LabelWithIcon.styles';
import { ILabelWithIconProps } from './LabelWithIcon.types';

const LabelWithIcon = ({
  label,
  icon,
  number,
  backgroundColor,
}: ILabelWithIconProps) => {
  const classes = useStyles();

  return (
    <LabelContentContainer backgroundColor={backgroundColor}>
      <Grid container={true} alignItems={'center'} flexWrap={'nowrap'}>
        <Grid item={true} className={classes.icon}>
          {icon}
        </Grid>
        <Grid item={true}>
          <Typography variant={TypographyVariant.body2}>{label}</Typography>
        </Grid>
        {number !== undefined && (
          <Grid item={true} xs={true} className={classes.number}>
            <Typography variant={TypographyVariant.body2}>{number}</Typography>
          </Grid>
        )}
      </Grid>
    </LabelContentContainer>
  );
};

export default LabelWithIcon;
