import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    minHeight: 150,
    position: 'relative',
  },
  lowercase: {
    textTransform: 'none',
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
  },
  tooltip: {
    maxWidth: 'none',
  },
}));

export default useStyles;
