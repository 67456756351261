import React, { FC } from 'react';

import { Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import colors from '@Compo/App/colors';

import CustomMenuButton from './CustomMenuButton/CustomMenuButton';
import getMenuItems from './getMenuItems';
import styles from './VerticalMenu.module.scss';
import { IVerticalMenuProps } from './VerticalMenu.types';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: colors.other.backdrop,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down('lg')]: {
      maxHeight: 'none',
    },
  },
  wrapper: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
}));

const VerticalMenu: FC<IVerticalMenuProps> = ({
  permissions,
  permissionConsentListView,
  mobileView,
}) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'layout.VerticalMenu.getMenuItems',
  });

  const classes = useStyles();

  return (
    <Container
      className={cn(
        styles.wrapper,
        mobileView && styles.withoutRadius,
        classes.root
      )}
    >
      {getMenuItems(permissions, permissionConsentListView, t).map((item) => {
        if (item.access) {
          return <CustomMenuButton {...item} key={item.link} />;
        }
        return null;
      })}
    </Container>
  );
};

export default VerticalMenu;
