import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { setExtendedSlot } from '@Model/reservation/actions';
import {
  getExtendedSlots,
  getSelectedExtendedDuration,
} from '@Model/reservation/selectors';

import ExtendedSlots from './ExtendedSlots.component';
import {
  IExtendedSlotsFromDispatch,
  IExtendedSlotsFromState,
} from './ExtendedSlots.types';

const mapStateToProps = (state: _Store.IState): IExtendedSlotsFromState => ({
  extendedSlots: getExtendedSlots(state),
  selectedExtendedSlot: getSelectedExtendedDuration(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IExtendedSlotsFromDispatch => ({
  selectExtendedSlot: (slot) => dispatch(setExtendedSlot(slot)),
});

export default connect<
  IExtendedSlotsFromState,
  IExtendedSlotsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ExtendedSlots);
