// TODO: clean up this file
import React, { useContext, useRef, useState } from 'react';

import { Typography } from '@mui/material';
import GridUi from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Card } from 'tabler-react';

import colors from '@Compo/App/colors';
import { clearContext } from '@Compo/Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import Filters from '@Compo/happenings/List/components/Filters';
import Loader from '@Compo/layout/Loader/Loader';
import { IZagrywkiBasket } from '@Model/basket/types';
import { ITimeSlot } from '@Model/reservation/types';
import { IReservationSaveBody } from '@Services/$reservations-api/types';

import DatePicker from '../DatePicker';
import NumberBox from '../NumberBox/NumberBox.component';
import SpacesSlots from '../SpacesSlots';
import TimeSlots from '../TimeSlots';
import ClientDataStepper from './ClientDataStepper';
import ExtendedSlots from './ExtendedSlots';
import { IFormLayoutProps, IStep } from './FormLayout';
import styles from './FormLayout.module.scss';
import PricesTable from './PricesTable';
import Stepper from './Stepper/Stepper';
import TicketTypeStepper from './TicketTypeStepper/TicketTypeStepper';

const WITH_DATA_KEY = 'withData';
const WITH_INVOICE_DATA_KEY = 'withInvoiceData';

const useStyles = makeStyles((theme) => ({
  spacing2: {
    marginTop: theme.spacing(2),
  },
  subtitle: {
    color: colors.text.secondary,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}));

const ReservationStepper = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Add.components.FormLayout.ReservationStepper',
  });

  const BagState = useContext(clearContext);
  const [activeStep, setActiveStep] = useState<number>(1);

  const slotRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);

  const {
    errors,
    isLoading,
    controls,
    selectDate,
    selectHappening,
    selectPeopleCount,
    selectSlot,
    setFieldValue,
    touched,
    values,
    selectSpace,
    isCompanyDataLoading,
    partnerId,
    minDate,
    selectedDate,
    basketData,
    handleReset,
    resetState,
    selectUpSell,
    onDone,
    transactionType,
    saveReservation,
    discount,
    setTransactionType,
    addToast,
    isSession,
    setShowMonit,
    pricesAsObjects,
    selectedSlot,
    delayed,
    priceTypes,
    extendedSlots,
    selectedExtendedSlot,
  } = props;

  const {
    nip,
    street,
    zipCode,
    city,
    houseNumber,
    companyName,
    email,
    emptyReservation,
    slot,
    numberOfPeople,
    spaceId,
    happeningId,
  } = values;

  const { isHappeningSelected, selectedSpaceId } = controls;

  const classes = useStyles();

  const prepareInvoiceData = () => {
    if (
      transactionType === WITH_INVOICE_DATA_KEY &&
      nip &&
      companyName &&
      street &&
      zipCode &&
      city
    ) {
      const address = `${street} ${houseNumber}`;

      return {
        invoice: {
          address,
          city,
          email,
          name: companyName,
          nip,
          post: zipCode,
        },
      };
    } else {
      return {};
    }
  };

  const getUser = () => {
    if (
      transactionType === WITH_DATA_KEY ||
      transactionType === WITH_INVOICE_DATA_KEY
    ) {
      return {
        user: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          terms: true,
        },
      };
    } else {
      return {
        user: {
          email: 'sprzedaz@goingapp.pl',
          firstName: '-',
          lastName: '-',
          phone: '-',
          terms: true,
        },
      };
    }
  };

  const body = {
    basketItems: BagState && BagState.basketItems ? BagState.basketItems : [],
    date: values.date,
    emptyReservation,
    happeningId,
    idempotencyKey:
      BagState && BagState.idempotencyKey ? BagState.idempotencyKey : '',
    numberOfPeople,
    spaceId,
    startTime: slot && slot.startTime ? slot.startTime : null,

    delayedTransaction: delayed,
    discount,
    onDone: BagState ? BagState.clearBasket : () => null,
    paymentOperator: 2,
    shortenedInvoice: values.shortenedInvoice || undefined,
    ...getUser(),
    ...prepareInvoiceData(),
  };

  const executeScroll = () =>
    nextRef?.current?.scrollIntoView({ behavior: 'smooth' });

  const handleSpaceChange = (id: number) => {
    setFieldValue('spaceId', id);

    if (id) {
      selectSpace(id);
      executeScroll();
    } else {
      selectSpace(null);
    }
  };

  const handleNumberOfPeopleChange = (value: number) => {
    setFieldValue('numberOfPeople', value);
    selectPeopleCount(value);
  };

  const handleUpSellChange = () => {
    setFieldValue('upsell', !values.upsell);
    selectUpSell(!values.upsell);
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setFieldValue('date', date);
      selectDate(date);
    }
  };

  const handleSlotSelection = (selectedTimeSlot: ITimeSlot) => {
    setFieldValue('slot', selectedTimeSlot);

    selectSlot(selectedTimeSlot);
  };

  const steps: IStep[] = [
    {
      description: 'default',
      render: () => undefined,
      stepName: 'default',
      title: 'default',
    },
    {
      description: t('reservationDetailsDesc'),
      render: () => renderReservationFirstStep(),
      stepName: 'Szczegóły rezerwacji',
      title: t('reservationDetailsTitle'),
    },
  ];

  if (!emptyReservation) {
    steps.push({
      description: t('ticketTypesDesc'),
      render: () => renderReservationSecondStep(),
      stepName: t('ticketTypesStepName'),
      title: t('ticketTypesTitle'),
    });
  }

  const catchCandleActiveStep = (step: number) => {
    // If fest step back to prev view
    if (step === 0) {
      onBack();
    } else if (step === 2) {
      if (values.slot) {
        if (selectedSpaceId) {
          setActiveStep(step);
        } else {
          addToast(t('selectSpace'));
        }
      } else {
        addToast(t('selectTime'));
      }
    } else {
      setActiveStep(step);
    }
  };

  const showLoader = isLoading && isCompanyDataLoading;

  const getNameByType = (type: string) => {
    return priceTypes?.find((price) => price.type === type)?.title;
  };

  const handleContinueBuying = () => {
    if (BagState && basketData && basketData.id) {
      if (emptyReservation) {
        saveReservation({
          ...body,
          basketItems: [basketData],
        } as IReservationSaveBody);
      } else {
        const obj: { [key: string]: IZagrywkiBasket } = {};
        basketData.selectedPrices.forEach((priceType: string) => {
          obj[priceType] = {
            ...basketData,
            selectedPrices: obj[priceType]
              ? [...obj[priceType].selectedPrices, priceType]
              : [priceType],
          };
        });
        Object.keys(obj).forEach((key) => {
          const priceValue = pricesAsObjects.find(
            (price) => price.type === key
          )?.value;
          if (priceValue) {
            BagState.addToBag({
              ...obj[key],
              id: Math.random(),
              numberOfPeople: obj[key].calculatePricePerPerson
                ? obj[key].selectedPrices.length
                : obj[key].numberOfPeople,
              price: priceValue,
              priceType: key,
              priceTypeName: getNameByType(key),
              selectedExtendedSlot,
            });
          }
        });

        setShowMonit(true);
      }
    }

    if (BagState) {
      BagState.handleUserData(values);
      handleReset();
      resetState();
    }
    if (onDone) {
      onDone(''); // TODO: set message
    }
    selectHappening(null);
  };

  const onBack = () => {
    selectHappening(null);

    if (BagState) {
      handleReset();
      resetState();
    }
    if (onDone) {
      onDone(''); // TODO: set message
    }
  };

  const renderReservationFirstStep = () => {
    if (!!partnerId) {
      return (
        <>
          <GridUi
            container={true}
            spacing={2}
            className={cn(
              styles.stepperReservation,
              !controls.isPaymentForPerson && classes.spacing2
            )}
          >
            {controls.isPaymentForPerson && (
              <GridUi item={true} xs={12} className={styles.steeperGrid}>
                <Typography variant="body1" className={classes.subtitle}>
                  {t('peopleCount')}
                </Typography>

                <NumberBox
                  value={values.numberOfPeople}
                  onChange={handleNumberOfPeopleChange}
                  max={controls.sliderLength}
                  min={1}
                />
              </GridUi>
            )}

            <GridUi item={true} xs={12} md={6} className={styles.steeperGrid}>
              <div className="customDatePicker">
                <DatePicker
                  minDate={minDate}
                  selectedDate={selectedDate || values.date}
                  isDateSelectionAvailable={true}
                  setDate={handleDateChange}
                />
              </div>
            </GridUi>
            <GridUi item={true} xs={12} md={6} className={styles.steeperGrid}>
              <TimeSlots
                isInvalid={isHappeningSelected && touched.slot && errors.slot}
                isSlotSelectionAvailable={isHappeningSelected}
                setSlot={handleSlotSelection}
                isUpSell={controls.isUpsell}
                handleUpSell={handleUpSellChange}
                isUpSellSelected={values.upsell}
                slotRef={slotRef}
              />
            </GridUi>

            {selectedSlot && (
              <>
                {!!extendedSlots.length && (
                  <GridUi item={true} xs={12} className={styles.steeperGrid}>
                    <Typography variant="body1" className={classes.subtitle}>
                      {t('extendGame')}
                    </Typography>
                    <ExtendedSlots />
                  </GridUi>
                )}

                <GridUi item={true} xs={12} className={styles.steeperGrid}>
                  <Typography variant="body1" className={classes.subtitle}>
                    {t('space')}
                  </Typography>
                  <SpacesSlots
                    selectedSpaceId={controls.selectedSpaceId}
                    onChange={handleSpaceChange}
                  />
                </GridUi>
              </>
            )}

            {selectedSpaceId && (
              <GridUi item={true} xs={12} className={styles.steeperGrid}>
                <Typography variant="body1" className={classes.subtitle}>
                  {t('price')}
                </Typography>
                <PricesTable />
              </GridUi>
            )}
          </GridUi>
        </>
      );
    }
  };

  const renderReservationSecondStep = () => {
    if (!!partnerId) {
      return (
        <>
          <Typography variant="body1" className={classes.subtitle}>
            {t('summary')}
          </Typography>
          <TicketTypeStepper {...props} />
        </>
      );
    }
  };

  const renderFormData = () => {
    if (transactionType.length > 0) {
      return <ClientDataStepper {...props} />;
    }
  };

  const clientDataSteps: IStep[] = [
    {
      description: t('clientDataDesc'),
      render: () => undefined,
      stepName: '',
      title: t('clientDataTitle'),
    },
  ];

  return (
    <div className={styles.stepperWrapper}>
      <div className={styles.wrapper}>
        {showLoader && (
          <Card
            body={
              <div className={styles.loader}>
                <Loader />
              </div>
            }
          />
        )}

        <Filters autoSet={true} />

        {transactionType.length > 0 ? (
          <Stepper
            steps={clientDataSteps}
            activeStep={0}
            setActiveStep={() => null}
            hideStepper={true}
            onDone={() => saveReservation(body as IReservationSaveBody)}
            showButtons={true}
            buttonText={t('clientDataDone')}
            canBeDonne={true}
            onBack={() => setTransactionType('')}
            nextRef={nextRef}
          >
            {renderFormData()}
          </Stepper>
        ) : (
          <Stepper
            steps={steps}
            activeStep={activeStep}
            setActiveStep={catchCandleActiveStep}
            onDone={handleContinueBuying}
            canBeDonne={!!controls.selectedSpaceId}
            buttonText={emptyReservation && t('addReservation')}
            nextRef={nextRef}
          >
            {steps[activeStep].render()}
          </Stepper>
        )}
      </div>
    </div>
  );
};

export default ReservationStepper;
