import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { Loader } from 'tabler-react';

import GoingPaper from '@Compo/layout/Paper/GoingPaper';

import PrintersModal from './components/PrinterModal';
import PrinterTable from './components/PrinterTable';
import { IPrinterAdminProps } from './PrinterAdmin.types';

class PrinterAdmin extends Component<IPrinterAdminProps> {
  public componentDidMount = () => {
    this.props.printersMounted();
  };

  public render() {
    const {
      closeModal,
      showModal,
      isLoading,
      permissionPrintersRead,
      permissionPrintersView,
    } = this.props;

    return (
      <div style={{ color: 'white' }}>
        <PrintersModal showModal={showModal} closeModal={closeModal} />
        {permissionPrintersRead && (
          <section>
            {isLoading && (
              <GoingPaper title={this.props.t('components:PrinterAdmin.title')}>
                <Loader />
              </GoingPaper>
            )}

            {!isLoading && permissionPrintersView && (
              <GoingPaper title={this.props.t('components:PrinterAdmin.title')}>
                <PrinterTable />
              </GoingPaper>
            )}
          </section>
        )}
      </div>
    );
  }
}

export default withTranslation()(PrinterAdmin);
