import React from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Grid } from 'tabler-react';

import { IFiltersProps } from './Filters.types';

const Filters = ({
  setPartnerID,
  partners,
  partnerId,
  autoSet,
}: IFiltersProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'happenings.List.components.Filters',
  });

  const catchPartnerId = (partner: React.FormEvent<HTMLSelectElement>) => {
    const { value } = partner.currentTarget;
    setPartnerID(value);
  };

  if (autoSet) {
    return null;
  }

  return (
    <>
      <Grid.Row>
        <Grid.Col>
          <Form.Select
            label={t('partner')}
            onChange={catchPartnerId}
            value={partnerId || ''}
            name="partnerId"
          >
            <option value={-1}>-</option>

            {partners &&
              partners.map((partner) => (
                <option key={partner.id} value={partner.id}>
                  {partner.name || ''}
                </option>
              ))}
          </Form.Select>
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

export default Filters;
