import { printingTypes } from '@Services/$boca-printer/enums/printingTypes';

const cms = {
  baseUrl: process.env.REACT_APP_CMS_URL,
  environment: process.env.REACT_APP_ENVIRONMENT,
  newBaseUrl: process.env.REACT_APP_NEW_CMS_URL,
  newCms: process.env.REACT_APP_NEW_CMS
    ? process.env.REACT_APP_NEW_CMS === 'true'
    : false,
  transactionProductUrl: process.env.REACT_APP_TRANSACTION_PRODUCT_URL,

  empikUrl: process.env.REACT_APP_EMPIK_URL,
  goingUrl: process.env.REACT_APP_GOING_URL,

  showUnfinishedFeatures: process.env.REACT_APP_UNFINISHED_FEATURE
    ? process.env.REACT_APP_UNFINISHED_FEATURE === 'true'
    : false,

  showAddNewRundate: false,
  showEmailTemplate: false,
  showGenerateTickets: false,
  showGoingDiscounts: false,
  showGoingRundates: true,
  showLanguageSwitch: false,
  showNewEditorText: true,
  showNewEnterList: true,
  showNewRules: true,
  showResendTicketTicketList: false,
  showTicketsTypes: true,

  printers: {
    default: process.env.PRINTER_DEFAULT_CUPS_NAME,
    rawDevice: process.env.REACT_APP_PRINTER_RAW_DEVICE_PATH,
  },
  printingType: process.env.PRINTER_DRIVER || printingTypes.raw,

  defaultCurrency: 'zł',
  maxExtendedSlots: 6,
  superPartnerId: 5,
};

export default cms;
