export const MOUNTED = 'Zagrywki/poolDescriptions/MOUNTED';
export const SET_POOL_DESCRIPTION =
  'Zagrywki/poolDescriptions/SET_POOL_DESCRIPTION';
export const CATCH_SAVE_POOL_DESCRIPTION =
  'Zagrywki/poolDescriptions/CATCH_SAVE_POOL_DESCRIPTION';
export const HANDLE_ACTIVE_PAGE =
  'Zagrywki/poolDescriptions/HANDLE_ACTIVE_PAGE';
export const HANDLE_SEARCH = 'Zagrywki/poolDescriptions/HANDLE_SEARCH';
export const HANDLE_CHANGE_ROWS_PER_PAGE =
  'Zagrywki/poolDescriptions/HANDLE_CHANGE_ROWS_PER_PAGE';
export const CATCH_GET_SINGLE_POOL_DESCRIPTION =
  'Zagrywki/poolDescriptions/CATCH_GET_SINGLE_POOL_DESCRIPTION';

export const _GET_POOL_DESCRIPTIONS_REQUEST =
  'Zagrywki/poolDescriptions/_GET_POOL_DESCRIPTIONS_REQUEST';
export const _GET_POOL_DESCRIPTIONS_SUCCESS =
  'Zagrywki/poolDescriptions/_GET_POOL_DESCRIPTIONS_SUCCESS';
export const _GET_POOL_DESCRIPTIONS_FAILURE =
  'Zagrywki/poolDescriptions/_GET_POOL_DESCRIPTIONS_FAILURE';

export const _GET_SINGLE_POOL_DESCRIPTIONS_REQUEST =
  'Zagrywki/poolDescriptions/_GET_SINGLE_POOL_DESCRIPTIONS_REQUEST';
export const _GET_SINGLE_POOL_DESCRIPTIONS_SUCCESS =
  'Zagrywki/poolDescriptions/_GET_SINGLE_POOL_DESCRIPTIONS_SUCCESS';
export const _GET_SINGLE_POOL_DESCRIPTIONS_FAILURE =
  'Zagrywki/poolDescriptions/_GET_SINGLE_POOL_DESCRIPTIONS_FAILURE';

export const _SAVE_POOL_DESCRIPTIONS_REQUEST =
  'Zagrywki/poolDescriptions/_SAVE_POOL_DESCRIPTIONS_REQUEST';
export const _SAVE_POOL_DESCRIPTIONS_SUCCESS =
  'Zagrywki/poolDescriptions/_SAVE_POOL_DESCRIPTIONS_SUCCESS';
export const _SAVE_POOL_DESCRIPTIONS_FAILURE =
  'Zagrywki/poolDescriptions/_SAVE_POOL_DESCRIPTIONS_FAILURE';
