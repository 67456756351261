import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { catchSaveUser, handleActiveUser } from '@Model/users/actions';
import { getActiveUser } from '@Model/users/selectors';

import UsersModal from './UsersModal.component';
import {
  IUsersModalFromDispatch,
  IUsersModalFromState,
} from './UsersModal.types';

const mapStateToProps = (state: _Store.IState): IUsersModalFromState => ({
  activeUser: getActiveUser(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IUsersModalFromDispatch => ({
  handleClose: () => dispatch(handleActiveUser(null)),
  submit: (values) => dispatch(catchSaveUser(values)),
});

export default connect<
  IUsersModalFromState,
  IUsersModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(UsersModal);
