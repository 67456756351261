import React from 'react';

import Grid from '@Compo/layout/Grid';
import Products from '@Compo/Products';

const ProductsPage = () => {
  return (
    <Grid>
      <Products />
    </Grid>
  );
};

export default ProductsPage;
