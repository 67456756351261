import { createSelector } from 'reselect';

import { IMeta } from '@Services/$email-api/types';
import _Store from '@Store';
import { IMailingReducer } from './../types';
import get from './get';

const getMeta = createSelector<_Store.IState, IMailingReducer, IMeta>(
  [get],
  (mailing) => mailing.meta
);

export default getMeta;
