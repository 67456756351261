import React, { FC } from 'react';

import cn from 'classnames';

import { isElectron } from '@Misc/helpers/isElectron';

import Toast from './components/Toast';
import { IToastsProps } from './Toast.types';
import styles from './Toasts.module.scss';

const Toasts: FC<IToastsProps> = ({ toasts, remove, dispatch }) => {
  if (!toasts.length) {
    return null;
  }

  return (
    <div className={cn(styles.toast, isElectron() && styles.electronToast)}>
      {toasts.map((toast) => {
        return (
          <Toast
            key={toast.id}
            data={toast}
            handleRemove={remove}
            handleDispatch={dispatch}
          />
        );
      })}
    </div>
  );
};

export default Toasts;
