import { createSelector } from 'reselect';

import { IEventPartner } from '@Services/$discounts-api/types';
import _Store from '@Store';
import { IDiscountReducer } from './../types';
import get from './get';

const getEventsPartner = createSelector<
  _Store.IState,
  IDiscountReducer,
  IEventPartner[]
>([get], (discount) => {
  return discount.events;
});

export default getEventsPartner;
