import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  paper: {
    background: '#272b35',
    borderRadius: '1rem',
    boxShadow: `0rem 0.6875rem 0.9375rem -0.4375rem rgba(0, 0, 0, 0.2),
    0rem 1.5rem 2.375rem 0.1875rem rgba(0, 0, 0, 0.14),
    0rem 0.5625rem 2.875rem 0.5rem rgba(0, 0, 0, 0.12)`,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    minHeight: '18.75rem',
    padding: '2rem',
    position: 'relative',
    width: '100%',

    [theme.breakpoints.down('lg')]: {
      padding: '1rem',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
