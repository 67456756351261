import Color from 'color';
import moment from 'moment-timezone';
import { createSelector } from 'reselect';

import getRandomFromRange from '@Misc/helpers/getRandomFromRange';
import { get as getHappenings } from '@Model/happenings/selectors';
import { IHappeningsReducer } from '@Model/happenings/types';
import _Store from '@Store';
import {
  IGroupedReservationsReducer,
  IReservationFormatted,
  IReservationsFiltersReducer,
  ISpaceRandom,
} from './../types';
import getFilters from './getFilters';
import getGroupedReservations from './getGroupedReservations';

const getGroupedReservationsFormatted = createSelector<
  _Store.IState,
  IGroupedReservationsReducer,
  IReservationsFiltersReducer,
  IHappeningsReducer,
  IReservationFormatted[]
>(
  [getGroupedReservations, getFilters, getHappenings],
  (reservations, filters, happenings) => {
    if (reservations === null) {
      return [];
    }

    const getName = (happeningId: number, spaceId: number): string => {
      if (happenings.items && happenings.items.length) {
        const { items } = happenings;
        const selectedHappening = items.find(
          (happening) => happening.id === happeningId
        );
        if (selectedHappening) {
          const selectedSpace = selectedHappening.spaces.find(
            (space) => space.id === spaceId
          );
          if (selectedSpace && selectedSpace.metadata.length) {
            return selectedSpace.metadata[0].title;
          }
        }
      }
      return '';
    };

    const getSlugs = (happeningId: number, spaceId: number) => {
      if (happenings.items && happenings.items.length) {
        const { items } = happenings;
        const selectedHappening = items.find(
          (happening) => happening.id === happeningId
        );
        if (selectedHappening) {
          const selectedSpace = selectedHappening.spaces.find(
            (space) => space.id === spaceId
          );
          if (selectedSpace && selectedSpace.metadata.length) {
            return {
              happeningSlug: selectedHappening.metadata[0].slug,
              spaceSlug: selectedSpace.metadata[0].slug,
            };
          }
        }
      }
      return { happeningSlug: '', spaceSlug: '' };
    };

    const spaceRandoms: ISpaceRandom[] = [];
    const spaceIds = Array.from(
      new Set(reservations.map((item) => item.spaceId))
    );

    spaceIds.forEach((spaceId) =>
      spaceRandoms.push({
        random: getRandomFromRange(25, 75) / 100,
        spaceId,
      })
    );

    const data: IReservationFormatted[] = [];

    reservations.forEach((reservation, key) => {
      const timezoneStartDate = moment
        .utc(reservation.start)
        .format('YYYY-MM-DD HH:mm:ss');

      const timezoneEndDate = moment
        .utc(reservation.end)
        .format('YYYY-MM-DD HH:mm:ss');

      const start = moment(timezoneStartDate)
        .utc()
        .toDate();
      const end = moment(timezoneEndDate)
        .utc()
        .toDate();

      const matchingFilter = filters.find(
        (filter) => filter.id === reservation.happeningId
      );

      let reservationColor;
      if (matchingFilter && matchingFilter.color) {
        const colorFormatter = Color(matchingFilter.color);

        let random = 0;
        const spaceRandom = spaceRandoms.find(
          (item) => item.spaceId === reservation.spaceId
        );
        if (spaceRandom) {
          random = spaceRandom.random;
        }

        if (colorFormatter.isDark()) {
          reservationColor = colorFormatter.lighten(random).hex();
        } else {
          reservationColor = colorFormatter.darken(random).hex();
        }
      }

      if (moment(start).isSame(end, 'day')) {
        data.push({
          color: reservationColor,
          duration: reservation.duration,
          end,
          happeningId: reservation.happeningId,
          numberOfPeople: reservation.numberOfPeople,
          numberOfReservations: reservation.numberOfReservations,
          spaceId: reservation.spaceId,
          start,
          title: `${reservation.firstName} ${reservation.lastName} (${reservation.numberOfPeople})`,
          ...getSlugs(reservation.happeningId, reservation.spaceId),
        });
      } else {
        data.push({
          color: reservationColor,
          duration: reservation.duration,
          end,
          happeningId: reservation.happeningId,
          numberOfPeople: reservation.numberOfPeople,
          numberOfReservations: reservation.numberOfReservations,
          spaceId: reservation.spaceId,
          start,
          title: `${reservation.firstName} ${reservation.lastName} (${reservation.numberOfPeople})`,
          ...getSlugs(reservation.happeningId, reservation.spaceId),
        });
      }
    });

    data.map((reservation) => {
      if (
        reservation.numberOfReservations &&
        reservation.numberOfReservations > 1
      ) {
        reservation.title = `${getName(
          reservation.happeningId,
          reservation.spaceId
        )} (${reservation.numberOfPeople})`;
      }
      return reservation;
    });

    return data;
  }
);

export default getGroupedReservationsFormatted;
