import _Store from '@Store';
import { createSelector } from 'reselect';
import { IPrinterReducer } from '../types';
import get from './get';

const getPinging = createSelector<_Store.IState, IPrinterReducer, boolean>(
  [get],
  (state) => state.pinging,
);

export default getPinging;
