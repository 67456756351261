import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getToken } from '@Model/app/selectors';
import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { selectReservation } from '@Model/reservations/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_SUCCESS } from '@Model/toasts/constants/constants';
import { IGetTicketsSuccessPayload } from '@Services/$tickets-api/types';

import {
  getTickets,
  mounted,
  search,
  searchTicket,
  ticketStatus,
  ticketStatusDeletion,
} from '../actions';

export const requestTicketsWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    mergeMap$(() => {
      return of$(getTickets.request(undefined));
    })
  );
};

export const requestTicketsWhenSearch: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(search)),
    mergeMap$((action) => {
      return of$(getTickets.request(action.payload));
    })
  );
};

export const requestTicketsStatusWhenSearch: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(searchTicket)),
    mergeMap$((action) => {
      const { code, deletionMode } = action.payload;
      if (!deletionMode) {
        return of$(ticketStatus.request(code));
      }

      return of$(ticketStatusDeletion.request(code));
    })
  );
};

export const fetchTicketsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getTickets.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const token = getToken(state);
      const partnerId = getHappeningPartnerId(state);
      if (token) {
        return from$(
          ticketsApi.getTickets(
            token,
            partnerId,
            action.payload?.search,
            action.payload?.page
          )
        ).pipe(
          map$((data: IGetTicketsSuccessPayload) => {
            return getTickets.success(data);
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => ticketsApi.cancelTickets())
            )
          ),
          catchError$((error: Error) => {
            return of$(getTickets.failure(error));
          })
        );
      }
      return EMPTY$;
    })
  );
};

export const fetchTicketStatusWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(ticketStatus.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(ticketsApi.ticketStatus(action.payload)).pipe(
        mergeMap$((data) => {
          return of$(
            selectReservation(data.data[0].reservationId),
            ticketStatus.success(data),
            addToast(data.message, TYPE_SUCCESS)
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => ticketsApi.cancelTickets())
          )
        ),
        catchError$((error) => {
          return of$(
            selectReservation(error.data[0].reservationId),
            ticketStatus.failure(error)
          );
        })
      );
    })
  );
};

export const fetchTicketStatusDeletionWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(ticketStatusDeletion.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      if (action.payload.length) {
        return from$(ticketsApi.ticketStatusDeletion(action.payload)).pipe(
          mergeMap$((data) => {
            return of$(
              selectReservation(data.data[0].reservationId),
              ticketStatusDeletion.success(data),
              addToast(data.message, TYPE_SUCCESS)
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => ticketsApi.cancelTickets())
            )
          ),
          catchError$((error) => {
            return of$(
              selectReservation(error.data[0].reservationId),
              ticketStatusDeletion.failure(error)
            );
          })
        );
      }

      return EMPTY$;
    })
  );
};
