import React from 'react';

import cn from 'classnames';

import { Loader as TablerLoader } from 'tabler-react';

import styles from './Loader.module.scss';
import { ILoaderOwnProps } from './Loader.types';

const Loader = ({ className }: ILoaderOwnProps) => (
  <TablerLoader className={cn(styles.loader, className)} />
);

export default Loader;
