import React from 'react';

import Grid from '@Compo/layout/Grid';
import Settings from '@Compo/Settings';

const SettingsPage = () => (
  <Grid>
    <Settings />
  </Grid>
);

export default SettingsPage;
