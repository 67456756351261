import routes from '@/routes/routes';
import {
  createMatchSelector,
  LOCATION_CHANGE,
  push,
} from 'connected-react-router';
import moment from 'moment';
import { EMPTY, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { saveConfiguration } from '@Model/configurations/actions';
import { getHappenings } from '@Model/happenings/actions';
import {
  getHappeningPartnerId,
  getHappenings as selectHappenings,
} from '@Model/happenings/selectors';
import { showSuccessModal } from '@Model/modal/actions';
import { setDate } from '@Model/reservation/actions';
import { getSelectedHappeningId } from '@Model/reservation/selectors';
import { addToast } from '@Model/toasts/actions';
import { TYPE_SUCCESS } from '@Model/toasts/constants/constants';

import {
  add,
  edit,
  getAvailabilities,
  getHappening,
  mounted,
  resetState,
  unmount,
} from './../actions';
import {
  IAvailabilitiesReducer,
  IGetHappeningSuccessPayload,
} from './../types';

const HAPPENING_SAVED_TEXT = 'Zapisano zmiany';

export const addHappeningWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { happeningsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(add)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      if (partnerId) {
        return from$(
          happeningsApi.addSingleHappening({
            ...action.payload,
            partnerId: Number(partnerId),
          })
        ).pipe(
          mergeMap$(() => [
            push('/'),
            addToast(HAPPENING_SAVED_TEXT, TYPE_SUCCESS),
            showSuccessModal(),
          ]),
          catchError$((error: Error) => {
            return of$(getHappenings.failure(error));
          })
        );
      }
      return EMPTY;
    })
  );
};

export const requestHappeningWhenEditFormMounted: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const matchSelector = createMatchSelector(routes.editHappening);
      const match = matchSelector(state);

      if (match && match.params) {
        const { slug } = match.params as any;

        return of$(getHappening.request(slug));
      }

      return EMPTY;
    })
  );
};

export const requestHappeningWhenRuleHasBeenEdited: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(saveConfiguration.success)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const matchSelector = createMatchSelector(routes.editHappening);
      const match = matchSelector(state);

      if (match && match.params) {
        const { slug } = match.params as any;

        return of$(getHappening.request(slug));
      }

      return EMPTY;
    })
  );
};

export const resetStateWhenEditFormUnmount: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(unmount)),
    map$(() => resetState())
  );
};

export const fetchHappeningWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { happeningsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getHappening.request)),
    mergeMap$((action) => {
      return from$(happeningsApi.getSingleHappening(action.payload)).pipe(
        map$((data: IGetHappeningSuccessPayload) => {
          return getHappening.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => happeningsApi.cancelSingleHappening())
          )
        ),
        catchError$((error: Error) => of$(getHappening.failure(error)))
      );
    })
  );
};

export const editHappeningWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { happeningsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(edit)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector(routes.editHappening);
      const match = matchSelector(state);
      const partnerId = getHappeningPartnerId(state);

      if (match && match.params && partnerId) {
        const { slug } = match.params as any;

        return from$(
          happeningsApi.updateSingleHappening(
            { ...action.payload, partnerId: Number(partnerId) },
            slug
          )
        ).pipe(
          mergeMap$(() => [
            addToast(HAPPENING_SAVED_TEXT, TYPE_SUCCESS),
            showSuccessModal(),
          ]),
          catchError$((error: Error) => {
            return of$(getHappenings.failure(error));
          })
        );
      }

      return EMPTY;
    })
  );
};

export const fetchAvailabilitiesWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { happeningsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getAvailabilities.request)),
    mergeMap$((action) => {
      const { date, slug } = action.payload;

      return from$(happeningsApi.getHappeningAvailabilities(slug, date)).pipe(
        map$((data) => {
          const dateAsString = moment(date).format('yyyy-MM-DD');
          const normalizedData = happeningsApi.normalizeAvailabilities(
            data,
            dateAsString
          );

          const otherDays: any = {};

          const dayAsString = moment(action.payload.date || Date.now()).format(
            'YYYY-MM-DD'
          );

          Object.keys(data.items).map((key) => {
            if (key !== dayAsString && data.items[key]) {
              Object.keys(data.items[key]).forEach((key2) => {
                if (data.items[key][key2]) {
                  otherDays[key2] = data.items[key][key2];
                }
              });
            }
          });

          const available: IAvailabilitiesReducer = {
            currentDay: normalizedData,
            otherDays,
          };

          return getAvailabilities.success(available);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => happeningsApi.cancelSingleHappening())
          )
        ),
        catchError$((error: Error) => of$(getHappening.failure(error)))
      );
    })
  );
};

export const requestAvailabilitiesWhenDateSelectionChanges: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(setDate)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const happeningId = getSelectedHappeningId(state);
      const happenings = selectHappenings(state);

      if (happenings && happeningId) {
        const happening = happenings.find((item) => item.id === happeningId);

        if (happening) {
          return of$(
            getAvailabilities.request({
              date: action.payload,
              slug: happening.metadata.slug,
            })
          );
        }
      }

      return EMPTY;
    })
  );
};
