import moment from 'moment';
import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPoolPartner, MetadataType } from '@Services/$metadata-api/types';

import { IMetadataReducer } from './../types';
import get from './get';

const getPoolsPartners = createSelector<
  _Store.IState,
  IMetadataReducer,
  IPoolPartner[]
>([get], (metadata) => {
  return metadata.pools.map((pool) => ({
    ...pool,
    nameInterface: MetadataType.Pool,
    rundate: {
      ...pool.rundate,
      nameInterface: MetadataType.Rundate,
      rundate: moment(pool.rundate?.rundate).format('DD.MM.YYYY HH:mm'),
    },
  }));
});

export default getPoolsPartners;
