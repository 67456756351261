import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  buttonsContainer: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  buttonsContainerSmallMobile: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  item: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  saveDraft: {
    marginRight: theme.spacing(2),
  },
}));

export default useStyles;
