import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { closeModal, showModal } from '@Model/authorization/actions';
import { get } from '@Model/authorization/selectors';
import { catchEndSession, mounted } from '@Model/printer/actions';
import { getReservationDetails } from '@Model/reservation/selectors';
import { allPermissions } from '@Model/state/constants';

import SessionPanel from './SessionPanel.component';
import {
  ISessionPanelFromDispatch,
  ISessionPanelFromState,
} from './SessionPanel.types';

const mapStateToProps = (state: _Store.IState): ISessionPanelFromState => {
  const {
    showModal: isModalOpen,
    userInfo: { session, permissions },
  } = get(state);
  return {
    isModalOpen,
    isSaleWithoutSession: permissions.includes(
      allPermissions.access_sale_without_opened_session
    ),
    isSession: !!(session && session.session_id),
    permissionWrite: permissions.includes(
      allPermissions.access_cashiersessions_write
    ),
    reservationDetails: getReservationDetails(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISessionPanelFromDispatch => ({
  closeModal: () => dispatch(closeModal()),
  endSession: () => dispatch(catchEndSession()),
  mounted: () => dispatch(mounted()),
  showModal: () => dispatch(showModal()),
});

export default connect<
  ISessionPanelFromState,
  ISessionPanelFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(SessionPanel);
