import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { addToast } from '@Model/toasts/actions';

import CloudinaryUploadWidget from './CloudinaryUploadWidget.component';
import { ICloudinaryUploadWidgetDispatch } from './CloudinaryUploadWidget.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ICloudinaryUploadWidgetDispatch => {
  return {
    addToast: (text, type) => dispatch(addToast(text, type)),
  };
};

export default connect<{}, ICloudinaryUploadWidgetDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(CloudinaryUploadWidget);
