import React, { FC } from 'react';

import { MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import styles from './CustomMenuButton.module.scss';

interface ICustomMenuButtonProps {
  icon: string;
  link: string;
  name: string;
}

const useStyles = makeStyles((theme) => ({
  item: {
    marginBottom: 0,
    marginTop: 0,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

const CustomMenuButton: FC<ICustomMenuButtonProps> = ({ icon, link, name }) => {
  const classes = useStyles();
  return (
    <NavLink to={link} className={styles.name}>
      <MenuItem className={classes.item}>
        <div className={cn(styles.icon, icon)} />
        <Typography variant="body1">{name}</Typography>
      </MenuItem>
    </NavLink>
  );
};

export default CustomMenuButton;
