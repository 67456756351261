import { createSelector } from 'reselect';

import _Store from '@Store';

import { IRefundsRegistration } from '@Services/$refunds-registration-api/types';

import { IRefundsRegistrationReducer } from './../types';
import get from './get';

const getRefundsRegistrationSingle = createSelector<
  _Store.IState,
  IRefundsRegistrationReducer,
  IRefundsRegistration | null
>(
  [get],
  (refundsRegistration) => refundsRegistration.singleRefundsRegistration
);

export default getRefundsRegistrationSingle;
