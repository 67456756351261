import { getType } from 'typesafe-actions';

import {
  getDiscounts,
  getEventsDiscounts,
  handleActivePage,
  resetState,
  setActiveFilter,
  updateSearchEventText,
  updateSearchText,
} from '../actions';
import { IAction, IDiscountsReducer } from '../types';

const initialState: IDiscountsReducer = {
  activePage: 0,
  eventsDiscounts: [],
  items: [],
  perPage: 15,
  searchText: '',
  totalCount: 0,
};

const happeningsReducer = (
  state: IDiscountsReducer = initialState,
  action: IAction
): IDiscountsReducer => {
  switch (action.type) {
    case getType(getDiscounts.success):
      return {
        ...state,
        ...action.payload,
      };
    case getType(resetState):
      return initialState;

    case getType(handleActivePage):
      return {
        ...state,
        activePage: action.payload.page,
      };

    case getType(updateSearchText):
      return {
        ...state,
        searchText: action.payload,
      };
    case getType(updateSearchEventText):
      return {
        ...state,
        searchText: action.payload,
      };

    case getType(setActiveFilter):
      return {
        ...state,
        active: action.payload,
      };

    case getType(getEventsDiscounts.success):
      return {
        ...state,
        eventsDiscounts: action.payload.data,
        totalCount: action.payload.meta.total,
      };

    default:
      return state;
  }
};

export default happeningsReducer;
