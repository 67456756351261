import { createSelector } from 'reselect';

import _Store from '@Store';

import { IFormValues } from '@Compo/Rundates/TicketsList/components/FormLayout/components/PreviewTicket/Form/Form.types';

import { ITicketsReducer } from '../types';
import getTickets from './getTickets';

const getSelectedSingleTicket = createSelector<
  _Store.IState,
  ITicketsReducer,
  IFormValues | null
>([getTickets], (tickets) => tickets.selectedSingleTicket);

export default getSelectedSingleTicket;
