import { getType } from 'typesafe-actions';

import { checkPrice } from '../actions';
import { IAction, IPriceReducer } from '../types';

const initialState: IPriceReducer = null;

const priceReducer = (
  state: IPriceReducer = initialState,
  action: IAction
): IPriceReducer => {
  switch (action.type) {
    case getType(checkPrice.success):
      return action.payload;
    default:
      return state;
  }
};

export default priceReducer;
