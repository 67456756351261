import React, { useRef, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Grow,
  ListItem,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import { Formik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GenericTable from '@Compo/reusable/GenericTable';
import TableUi from '@Compo/reusable/TableUi';
import { Colors } from '@Constants/Colors';
import { TypographyVariant } from '@Constants/Variants/Typography';
import { ISettlementEdit } from '@Model/settlements/types';
import { IReport } from '@Services/$settlements-api/types';

import AcceptSettlementModal from './components/AcceptSettlementModal';
import DeleteConfirmModal from './components/DeleteConfirmModal';
import EditFormLayout from './components/EditFormLayout';
import { SettlementEditSchema } from './components/EditFormLayout/EditFormLayout.validation';
import RemoveAcceptSettlementModal from './components/RemoveAcceptSettlementModal';
import useStyles from './SingleSettlementModal.styles';
import { ISingleSettlementModalProps } from './SingleSettlementModal.types';

const SingleSettlementModal = ({
  catchDeleteSettlement,
  catchGenerateReportFile,
  catchSettlementStatusChange,
  resetSingleSettlement,
  singleSettlement,
  generateReport,
  isLoading,
  editSettlement,
  catchEditSettlement,
}: ISingleSettlementModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Settlements.SingleSettlementModal',
  });

  const classes = useStyles();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const formRef = useRef<Formik<ISettlementEdit>>(null);

  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openAcceptSettlement, setOpenAcceptSettlement] = useState(false);
  const [openRemoveAcceptSettlement, setOpenRemoveAcceptSettlement] =
    useState(false);

  const closeModal = () => {
    resetSingleSettlement();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  if (!singleSettlement) {
    return null;
  }

  const columns = [
    { id: 'id', label: t('id') },
    { id: 'fileName', label: t('fileName') },
    { id: 'createdBy', label: t('createdBy') },
    { id: 'actions', label: '' },
  ];

  const handleGenerateReportFile = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    report: IReport
  ) => {
    e.stopPropagation();
    catchGenerateReportFile(String(report.id), report.fileKey);
  };

  const generatedReports = singleSettlement.reports.map((report) => ({
    actions: (
      <div className={classes.buttonsContainer}>
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={cn(
            classes.button,
            classes.buttonFirst,
            classes.buttonWithoutPadding
          )}
          onClick={(e) => {
            handleGenerateReportFile(e, report);
          }}
        >
          {t('downloadReport')}
        </Button>
      </div>
    ),
    fileName: report.fileKey,
    id: report.id,
  }));

  const deleteSettlement = () => {
    catchDeleteSettlement(singleSettlement.uuid);
    setOpenDeleteConfirm(false);
  };

  const closeDeleteConfirmModal = () => {
    setOpenDeleteConfirm(false);
  };

  const closeAcceptSettlementModal = () => {
    setOpenAcceptSettlement(false);
  };

  const closeRemoveAcceptSettlementModal = () => {
    setOpenRemoveAcceptSettlement(false);
  };

  const openDeleteConfirmModal = () => {
    setOpenDeleteConfirm(true);
  };

  const openAcceptSettlementModal = () => {
    setOpenAcceptSettlement(true);
  };

  const openRemoveAcceptSettlementModal = () => {
    setOpenRemoveAcceptSettlement(true);
  };

  const acceptSettlement = (invoiceNumber: string) => {
    catchSettlementStatusChange({
      id: singleSettlement.uuid,
      invoice: invoiceNumber,
      isAccepted: true,
    });
    closeAcceptSettlementModal();
  };

  const removeAcceptSettlement = () => {
    catchSettlementStatusChange({
      id: singleSettlement.uuid,
      isAccepted: false,
    });
    closeRemoveAcceptSettlementModal();
  };

  const handleEditSettlement = (values: ISettlementEdit) => {
    catchEditSettlement({ ...values, ...SettlementEditSchema.cast(values) });
  };

  return (
    <Dialog
      open={!!singleSettlement}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DeleteConfirmModal
        deleteSettlement={deleteSettlement}
        closeModal={closeDeleteConfirmModal}
        open={openDeleteConfirm}
      />

      <AcceptSettlementModal
        acceptSettlement={acceptSettlement}
        closeModal={closeAcceptSettlementModal}
        open={openAcceptSettlement}
      />

      <RemoveAcceptSettlementModal
        removeAccept={removeAcceptSettlement}
        closeModal={closeRemoveAcceptSettlementModal}
        open={openRemoveAcceptSettlement}
      />

      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <Grid container={true} spacing={8}>
          <Grid item={true} xs={12}>
            <Grid container={true} spacing={3}>
              {singleSettlement.isAccepted && (
                <>
                  <Grid item={true}>
                    <DoneIcon className={classes.doneIcon} />
                  </Grid>
                  <Grid item={true} xs={true}>
                    <Typography
                      variant={TypographyVariant.body1}
                      color={Colors.textSecondary}
                    >
                      {t('acceptedSettlement')}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item={true} xs={12}>
                <ListItem className={classes.item}>
                  <Grid container={true}>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('id')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography variant={TypographyVariant.body1}>
                        {singleSettlement.uuid}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>
              <Grid item={true} xs={12}>
                <Formik
                  initialValues={editSettlement}
                  onSubmit={handleEditSettlement}
                  component={EditFormLayout}
                  validationSchema={SettlementEditSchema}
                  ref={formRef}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <ListItem className={classes.item}>
                  <Grid container={true}>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('startDate')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography variant={TypographyVariant.body1}>
                        {moment(singleSettlement.startDate).format(
                          'DD.MM.YYYY'
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>

                <ListItem className={classes.item}>
                  <Grid container={true}>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('endDate')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography variant={TypographyVariant.body1}>
                        {moment(singleSettlement.endDate).format('DD.MM.YYYY')}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>

                <ListItem className={classes.item}>
                  <Grid container={true}>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('transactionType')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography variant={TypographyVariant.body1}>
                        {singleSettlement.transactionType.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>

                <ListItem className={classes.item}>
                  <Grid container={true}>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('salesChannel')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography variant={TypographyVariant.body1}>
                        {singleSettlement.salesChannel.name || t('all')}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>

                <ListItem className={classes.item}>
                  <Grid container={true}>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('limit')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography variant={TypographyVariant.body1}>
                        {singleSettlement.amountLimit}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>

                <ListItem className={classes.item}>
                  <Grid container={true}>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('freeTickets')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        className={cn(
                          !singleSettlement.freeTickets &&
                            classes.typographyDisabled
                        )}
                      >
                        {singleSettlement.freeTickets ? t('yes') : t('no')}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>

                <ListItem className={classes.item}>
                  <Grid container={true}>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('createdBy')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography variant={TypographyVariant.body1}>
                        {singleSettlement.createdBy}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>

                <ListItem className={classes.item}>
                  <Grid container={true}>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('acceptedBy')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography variant={TypographyVariant.body1}>
                        {singleSettlement.acceptedBy}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>

              <Grid item={true} xs={12}>
                <ListItem className={classes.item}>
                  <Grid container={true}>
                    <Grid item={true} xs={12}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('events')}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
                {singleSettlement.rundates.map((event) => (
                  <ListItem key={event.id} className={classes.item}>
                    <Grid container={true}>
                      <Grid item={true} xs={12}>
                        <Typography variant={TypographyVariant.body1}>
                          {event.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            <Grid container={true} spacing={1}>
              <Grid item={true} xs={12} md={6}>
                <ButtonGroup>
                  <Button
                    variant="outlined"
                    size="small"
                    color="secondary"
                    className={classes.button}
                    ref={anchorRef}
                    onClick={handleToggle}
                  >
                    {t('generateButton')}
                  </Button>
                  <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    className={classes.button}
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  open={open}
                  transition={true}
                  disablePortal={true}
                  anchorEl={anchorRef.current}
                  placement="bottom-start"
                >
                  {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList>
                            {singleSettlement.availableReportTypes.map(
                              (type) => (
                                <MenuItem
                                  key={type.id}
                                  onClick={() => generateReport(type.id)}
                                >
                                  {type.name}
                                </MenuItem>
                              )
                            )}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>

              <Grid item={true} xs={12} md={6}>
                <Grid container={true} spacing={1} justifyContent="flex-end">
                  <Grid item={true} xs={12} md={true}>
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      startIcon={<RemoveIcon />}
                      className={cn(classes.button, classes.marginRemoveButton)}
                      onClick={openDeleteConfirmModal}
                      fullWidth={true}
                    >
                      {t('removeButton')}
                    </Button>
                  </Grid>

                  {!singleSettlement.isAccepted ? (
                    <Grid item={true} xs={12} md={true}>
                      <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        startIcon={<DoneIcon />}
                        className={classes.button}
                        onClick={openAcceptSettlementModal}
                        fullWidth={true}
                      >
                        {t('acceptButton')}
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item={true} xs={12} md={true}>
                      <Button
                        size="small"
                        color="primary"
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        className={classes.button}
                        onClick={openRemoveAcceptSettlementModal}
                        fullWidth={true}
                      >
                        {t('removeAcceptButton')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item={true} xs={12}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Typography variant={TypographyVariant.body1}>
                  {t('generatedReports')}
                </Typography>
              </Grid>
              <Grid item={true} xs={12}>
                {isLoading ? (
                  <Loader className={classes.loader} />
                ) : (
                  <GenericTable>
                    <TableUi
                      rows={generatedReports}
                      columns={columns}
                      disableCheckBox={true}
                    />
                  </GenericTable>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('close')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={() => formRef?.current?.submitForm()}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SingleSettlementModal;
