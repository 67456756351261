import { createSelector } from 'reselect';

import _Store from '@Store';
import { IEvent, IPartnerReportFiltersParamsResponse } from './../types';
import getReportParameters from './getReportParameters';

const getEventsParameter = createSelector<
  _Store.IState,
  IPartnerReportFiltersParamsResponse | null,
  IEvent[]
>([getReportParameters], (params) => (params ? params.events : []));

export default getEventsParameter;
