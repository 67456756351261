import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { handleActivePage } from '@Model/products/actions';
import { getAllLocalProducts, getMeta } from '@Model/products/selectors';
import { PRODUCTS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import AddProductModal from './AddProductModal.component';
import {
  IAddProductModalFromDispatch,
  IAddProductModalFromState,
  IAddProductModalOwnProps,
} from './AddProductModal.types';

const mapStateToProps = (state: _Store.IState): IAddProductModalFromState => ({
  allProducts: getAllLocalProducts(state),
  isLoading: getLoading(PRODUCTS)(state),
  meta: getMeta(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IAddProductModalFromDispatch => ({
  handleActivePage: (page, count, text) =>
    dispatch(handleActivePage({ page, count, text })),
});

export default connect<
  IAddProductModalFromState,
  IAddProductModalFromDispatch,
  IAddProductModalOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(AddProductModal);
