import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      alignItems: 'normal',
      flexDirection: 'column',
      paddingBottom: theme.spacing(3),
    },
  },
}));

export default useStyles;
