import React from 'react';

import Grid from '@Compo/layout/Grid';
import SummaryCanceled from '@Compo/SummaryCanceled';

const SummaryFailPage = () => (
  <Grid>
    <SummaryCanceled />
  </Grid>
);

export default SummaryFailPage;
