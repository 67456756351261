import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
  catchSaveSettlement,
  handleActiveSettlement,
} from '@Model/settlements/actions';
import { getActiveSettlement } from '@Model/settlements/selectors';

import _Store from '@Store';
import SettlementsAddModal from './SettlementsAddModal.component';
import { prepareInitialValues } from './SettlementsAddModal.helpers';
import {
  ISettlementsAddModalFromDispatch,
  ISettlementsAddModalFromState,
} from './SettlementsAddModal.types';

const mapStateToProps = (
  state: _Store.IState
): ISettlementsAddModalFromState => {
  const settlement = getActiveSettlement(state);
  return {
    initialValues: prepareInitialValues(),
    settlement,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISettlementsAddModalFromDispatch => ({
  catchSaveSettlement: (body) => dispatch(catchSaveSettlement(body)),
  handleActiveSettlement: (settlement) =>
    dispatch(handleActiveSettlement(settlement)),
});

export default connect<
  ISettlementsAddModalFromState,
  ISettlementsAddModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(SettlementsAddModal);
