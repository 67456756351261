export const _GET_EVENT_MAILING_REQUEST =
  'Zagrywki/mailing/_GET_EVENT_MAILING_REQUEST';
export const _GET_EVENT_MAILING_SUCCESS =
  'Zagrywki/mailing/_GET_EVENT_MAILING_SUCCESS';
export const _GET_EVENT_MAILING_FAILURE =
  'Zagrywki/mailing/_GET_EVENT_MAILING_FAILURE';

export const _SAVE_EVENT_MAILING_REQUEST =
  'Zagrywki/mailing/_SAVE_EVENT_MAILING_REQUEST';
export const _SAVE_EVENT_MAILING_SUCCESS =
  'Zagrywki/mailing/_SAVE_EVENT_MAILING_SUCCESS';
export const _SAVE_EVENT_MAILING_FAILURE =
  'Zagrywki/mailing/_SAVE_EVENT_MAILING_FAILURE';

export const _GET_SINGLE_EVENT_MAILING_REQUEST =
  'Zagrywki/mailing/_GET_SINGLE_EVENT_MAILING_REQUEST';
export const _GET_SINGLE_EVENT_MAILING_SUCCESS =
  'Zagrywki/mailing/_GET_SINGLE_EVENT_MAILING_SUCCESS';
export const _GET_SINGLE_EVENT_MAILING_FAILURE =
  'Zagrywki/mailing/_GET_SINGLE_EVENT_MAILING_FAILURE';

export const _DELETE_EVENT_MAILING_REQUEST =
  'Zagrywki/mailing/_DELETE_EVENT_MAILING_REQUEST';
export const _DELETE_EVENT_MAILING_SUCCESS =
  'Zagrywki/mailing/_DELETE_EVENT_MAILING_SUCCESS';
export const _DELETE_EVENT_MAILING_FAILURE =
  'Zagrywki/mailing/_DELETE_EVENT_MAILING_FAILURE';

export const _SEND_EVENT_MAILING_REQUEST =
  'Zagrywki/mailing/_SEND_EVENT_MAILING_REQUEST';
export const _SEND_EVENT_MAILING_SUCCESS =
  'Zagrywki/mailing/_SEND_EVENT_MAILING_SUCCESS';
export const _SEND_EVENT_MAILING_FAILURE =
  'Zagrywki/mailing/_SEND_EVENT_MAILING_FAILURE';

export const _SEND_EVENT_TEST_MAILING_REQUEST =
  'Zagrywki/mailing/_SEND_EVENT_TEST_MAILING_REQUEST';
export const _SEND_EVENT_TEST_MAILING_SUCCESS =
  'Zagrywki/mailing/_SEND_EVENT_TEST_MAILING_SUCCESS';
export const _SEND_EVENT_TEST_MAILING_FAILURE =
  'Zagrywki/mailing/_SEND_EVENT_TEST_MAILING_FAILURE';

export const MOUNTED = 'Zagrywki/mailing/MOUNTED';
export const SELECT_EVENT_MAIL = 'Zagrywki/mailing/SELECT_EVENT_MAIL';
export const SAVE_EVENT_MAIL = 'Zagrywki/mailing/SAVE_EVENT_MAIL';
export const EDIT_EVENT_MAIL = 'Zagrywki/mailing/EDIT_EVENT_MAIL';
export const DELETE_EVENT_MAIL = 'Zagrywki/mailing/DELETE_EVENT_MAIL';
export const SEND_EVENT_MAIL = 'Zagrywki/mailing/SEND_EVENT_MAIL';
export const SEND_EVENT_TEST_MAIL = 'Zagrywki/mailing/SEND_EVENT_TEST_MAIL';
export const HANDLE_ACTIVE_PAGE = 'Zagrywki/mailing/HANDLE_ACTIVE_PAGE';
export const HANDLE_SEARCH_TEXT = 'Zagrywki/mailing/HANDLE_SEARCH_TEXT';
