import { getType } from 'typesafe-actions';
import { setSizes } from './../../actions';
import { DESKTOP_HEIGHT, DESKTOP_WIDTH } from './../../constants/constants';
import { IAction, IReducerSizes } from './../../types';

const initialState: IReducerSizes = {
  height: DESKTOP_HEIGHT,
  width: DESKTOP_WIDTH,
};

const reducer = (
  state: IReducerSizes = initialState,
  action: IAction,
): IReducerSizes => {
  switch (action.type) {
    case getType(setSizes):
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
