import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { USERS } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';
import {
  catchDeleteGroup,
  catchGetSingleGroup,
  handleActiveGroup,
  handleActivePageGroups,
  handleActiveSizeGroups,
  handleSearchGroups,
  mountedGroups,
} from '@Model/users/actions';
import {
  getGroups,
  getMetaGroups,
  getSearchTextGroups,
} from '@Model/users/selectors';

import { prepareInitialValues } from './components/GroupsModal/GroupsModal.helpers';
import GroupsTable from './GroupsTable.component';
import {
  IGroupsTableFromDispatch,
  IGroupsTableFromState,
} from './GroupsTable.types';

const mapStateToProps = (state: _Store.IState): IGroupsTableFromState => ({
  groups: getGroups(state),
  isLoading: getLoading(USERS)(state),
  meta: getMetaGroups(state),
  searchText: getSearchTextGroups(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IGroupsTableFromDispatch => ({
  addNewGroup: () => dispatch(handleActiveGroup(prepareInitialValues(null))),
  deleteGroup: (id) => dispatch(catchDeleteGroup(id)),
  edit: (id) => dispatch(catchGetSingleGroup(id)),
  handleActivePage: (page) => dispatch(handleActivePageGroups(page)),
  handleChangeRowsPerPage: (size) => dispatch(handleActiveSizeGroups(size)),
  mounted: () => dispatch(mountedGroups()),
  search: (text) => dispatch(handleSearchGroups(text)),
});

export default connect<
  IGroupsTableFromState,
  IGroupsTableFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(GroupsTable);
