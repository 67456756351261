import _Store from '@Store';
import { createSelector } from 'reselect';
import { IPool, ISelectedReducer } from './../types';
import getSelected from './getSelected';

const getSelectedPool = createSelector<
  _Store.IState,
  ISelectedReducer,
  IPool | null
>([getSelected], (selected) => selected.pool);

export default getSelectedPool;
