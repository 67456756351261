import { createSelector } from 'reselect';

import _Store from '@Store';
import { ISettlementsReducer, ISingleSettlement } from './../types';
import get from './get';

const getSingleSettlement = createSelector<
  _Store.IState,
  ISettlementsReducer,
  ISingleSettlement
>([get], (settlement) => settlement.singleSettlement);

export default getSingleSettlement;
