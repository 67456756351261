import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getUserInfo } from '@Model/authorization/selectors';
import { selectTicket } from '@Model/going/rundates/actions';
import { getSingleRundateData } from '@Model/going/rundates/selectors';

import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => {
  const { parameters } = getUserInfo(state);
  const seatsIoPublicKey = parameters?.find((param) =>
    param.hasOwnProperty('seats_io.public_key')
  );
  return {
    rundate: getSingleRundateData(state),
    seatsPublicKey: seatsIoPublicKey?.['seats_io.public_key'],
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormLayoutFromDispatch => ({
  closeModal: () => dispatch(selectTicket(null)),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
