import moment from 'moment';

import getTimeAsString from '@Misc/helpers/getTimeAsString';
import { IFormValuesProps } from '@Model/configurations/types';
import * as helpers from '../../../../AddingConfiguration.helpers';

const UNKNOWN_RULE = 'Unknown rule';

export const makeName = (values: IFormValuesProps, rrule: string) => {
  const {
    advanced,
    ruleAdvanced,
    fromTime,
    duration,
    durationToString,
  } = values;
  let name;

  if (advanced) {
    name = helpers.getName(ruleAdvanced);

    return name || UNKNOWN_RULE;
  }

  name = helpers.getName(rrule);

  if (!name) {
    return UNKNOWN_RULE;
  }

  const parts = fromTime.split(':');

  const getTime = (): string => {
    if (!Number.isNaN(Number(parts[0])) && Number.isNaN(Number(parts[1]))) {
      return ` from ${getTimeAsString(
        parseInt(parts[0], 10),
        parseInt(parts[1], 10)
      )}`;
    }
    return '';
  };

  const durationName = helpers.parseDurationToHuman(
    durationToString || duration.toString()
  );

  return `${name}, ${getTime()} for ${durationName}`;
};

export const makeRRule = (values: IFormValuesProps): string => {
  const { advanced, cyclic, until, days, ruleAdvanced, fromTime } = values;

  if (advanced) {
    return ruleAdvanced;
  }

  let rrule = 'RRULE:WKST=MO;BYSECOND=0;';

  if (!cyclic) {
    const date = until ? moment(until).toDate() : new Date();

    const month = date.getUTCMonth() + 1;
    const day = date.getDate();

    rrule += `FREQ=YEARLY;BYMONTHDAY=${day};BYMONTH=${month};`;
  } else {
    if (days.length > 0) {
      rrule += `FREQ=WEEKLY;BYDAY=${days};`;
    }
  }

  const times = fromTime.split(':');

  rrule += `BYHOUR=${parseInt(times[0], 10)};BYMINUTE=${parseInt(
    times[1],
    10
  )};`;

  return rrule.slice(0, -1);
};
