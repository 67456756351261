import config from '@Config';
import PrintingError from '@Misc/classes/PrintingError';

import { printerStatus } from './../enums/printerStatus';
import { IPrinting } from './../types';

class CupsPrinting implements IPrinting {
  private static printer: any = null;

  private printersConfig = config.cms.printers;

  constructor() {
    if (!CupsPrinting.printer) {
      try {
        CupsPrinting.printer = require('printer') as any;
      } catch (error) {}
    }
  }

  public getPrinterName() {
    return (
      this.printersConfig.default ||
      CupsPrinting.printer?.getDefaultPrinterName()
    );
  }

  public isPrinterOnline() {
    const name = this.getPrinterName();

    if (name === undefined) {
      return false;
    }

    const printerObject = CupsPrinting.printer?.getPrinter(name);
    return !(
      printerObject.status === 'IDLE' &&
      printerObject.options['printer-state-reasons'] === 'offline-report'
    );
  }

  public getStatus(): string {
    return this.isPrinterOnline()
      ? printerStatus.active
      : printerStatus.inactive;
  }

  public print(data: any): Promise<string | PrintingError> {
    return new Promise((resolve, reject) => {
      if (this.isPrinterOnline() === false) {
        return reject(
          new PrintingError(
            'Wystąpił błąd - drukarka niedostępna. Sprawdź połączenie.'
          )
        );
      }

      return CupsPrinting.printer?.printDirect({
        data,
        error: (error: Error) => {
          reject(new PrintingError(`Wystąpił błąd - szczegóły: ${error}`));
        },
        printer: this.getPrinterName(),
        success: (jobID: string) => {
          const message = `Job z ID: ${jobID} wysłany do drukarki...`;

          resolve(message);
        },
        type: 'RAW',
      });
    });
  }
}

export default CupsPrinting;
