import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  _END_SESSION_BY_ID_FAILURE,
  _END_SESSION_BY_ID_REQUEST,
  _END_SESSION_BY_ID_SUCCESS,
  _END_SESSION_BY_ID_V2_FAILURE,
  _END_SESSION_BY_ID_V2_REQUEST,
  _END_SESSION_BY_ID_V2_SUCCESS,
  _END_SESSION_FAILURE,
  _END_SESSION_REQUEST,
  _END_SESSION_SUCCESS,
  _END_SESSION_V2_FAILURE,
  _END_SESSION_V2_REQUEST,
  _END_SESSION_V2_SUCCESS,
  _END_SESSION_WITH_OUT_CASH_STATE_BY_ID_FAILURE,
  _END_SESSION_WITH_OUT_CASH_STATE_BY_ID_REQUEST,
  _END_SESSION_WITH_OUT_CASH_STATE_BY_ID_SUCCESS,
  _END_SESSION_WITH_OUT_CASH_STATE_FAILURE,
  _END_SESSION_WITH_OUT_CASH_STATE_REQUEST,
  _END_SESSION_WITH_OUT_CASH_STATE_SUCCESS,
  _GET_FAILURE,
  _GET_PAYMENT_SESSION_FAILURE,
  _GET_PAYMENT_SESSION_REQUEST,
  _GET_PAYMENT_SESSION_SUCCESS,
  _GET_PINGING_FAILURE,
  _GET_PINGING_REQUEST,
  _GET_PINGING_SUCCESS,
  _GET_REQUEST,
  _GET_SUCCESS,
  _PRINT_RECEIPT_FAILURE,
  _PRINT_RECEIPT_REQUEST,
  _PRINT_RECEIPT_SUCCESS,
  _PRINTER_PAYMENT_FAILURE,
  _PRINTER_PAYMENT_REQUEST,
  _PRINTER_PAYMENT_SUCCESS,
  _PRINTER_STATUS_FAILURE,
  _PRINTER_STATUS_REQUEST,
  _PRINTER_STATUS_SUCCESS,
  _RESET_STATE,
  _SAVE_FAILURE,
  _SAVE_REQUEST,
  _SAVE_SUCCESS,
  _START_SESSION_FAILURE,
  _START_SESSION_REQUEST,
  _START_SESSION_SUCCESS,
  _START_SESSION_V2_FAILURE,
  _START_SESSION_V2_REQUEST,
  _START_SESSION_V2_SUCCESS,
  ADD,
  CATCH_GET_SESSION,
  CATCH_PRINT_RECEIPT,
  CLEAR_PRINTER_CASH,
  EDIT,
  END_SESSION,
  END_SESSION_BY_ID,
  MOUNTED,
  PRINT_REPORT,
  PULL_DRAWER,
  REMOVE,
  SAVE,
  START_PINGING_PRINTER,
  START_SESSION,
  STOP_PINGING_PRINTER,
} from '@Model/printer/constants/actions';
import {
  IPaymentSessionResponse,
  IPrinterPaymentData,
  IRunSessionRequest,
  ISessionData,
} from '@Services/$authorization-api/types';
import {
  IEndSessionByPrinterIdPayload,
  IGetPrintersSuccessPayload,
  IPrinter,
  IPrinterEndSessionPayload,
} from '../types';

export const resetState = createStandardAction(_RESET_STATE)();

export const catchPrintReceipt = createStandardAction(CATCH_PRINT_RECEIPT)();

export const savePrinter = createAsyncAction(
  _SAVE_REQUEST,
  _SAVE_SUCCESS,
  _SAVE_FAILURE
)<IPrinter, void, Error>();

export const getPrinters = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE
)<undefined, IGetPrintersSuccessPayload, Error>();

export const getPrintersPinging = createAsyncAction(
  _GET_PINGING_REQUEST,
  _GET_PINGING_SUCCESS,
  _GET_PINGING_FAILURE
)<undefined, IGetPrintersSuccessPayload, Error>();

export const printReceipt = createAsyncAction(
  _PRINT_RECEIPT_REQUEST,
  _PRINT_RECEIPT_SUCCESS,
  _PRINT_RECEIPT_FAILURE
)<undefined, undefined, Error>();

export const startSession = createAsyncAction(
  _START_SESSION_REQUEST,
  _START_SESSION_SUCCESS,
  _START_SESSION_FAILURE
)<ISessionData, undefined, Error>();

export const printerPayment = createAsyncAction(
  _PRINTER_PAYMENT_REQUEST,
  _PRINTER_PAYMENT_SUCCESS,
  _PRINTER_PAYMENT_FAILURE
)<IPrinterPaymentData, undefined, Error>();

export const addPrinter = createStandardAction(ADD)();
export const editPrinter = createStandardAction(EDIT)<IPrinter>();
export const remove = createStandardAction(REMOVE)<number>();
export const save = createStandardAction(SAVE)<IPrinter>();
export const pullDrawer = createStandardAction(PULL_DRAWER)();
export const mounted = createStandardAction(MOUNTED)();
export const catchSession = createStandardAction(START_SESSION)<ISessionData>();
export const catchPrinterPayment = createStandardAction(START_SESSION)<
  IPrinterPaymentData
>();

export const catchEndSession = createStandardAction(END_SESSION)();
// export const catchGetSession = createStandardAction(CATCH_GET_SESSION)();

export const endPrinterSession = createAsyncAction(
  _END_SESSION_REQUEST,
  _END_SESSION_SUCCESS,
  _END_SESSION_FAILURE
)<IPrinterEndSessionPayload, undefined, Error>();

export const endPrinterSessionWithOutCashState = createAsyncAction(
  _END_SESSION_WITH_OUT_CASH_STATE_SUCCESS,
  _END_SESSION_WITH_OUT_CASH_STATE_REQUEST,
  _END_SESSION_WITH_OUT_CASH_STATE_FAILURE
)<undefined | IPaymentSessionResponse, undefined, Error>();

export const endPrinterSessionWithOutCashStateById = createAsyncAction(
  _END_SESSION_WITH_OUT_CASH_STATE_BY_ID_SUCCESS,
  _END_SESSION_WITH_OUT_CASH_STATE_BY_ID_REQUEST,
  _END_SESSION_WITH_OUT_CASH_STATE_BY_ID_FAILURE
)<number, undefined, Error>();

export const endPrinterSessionById = createAsyncAction(
  _END_SESSION_BY_ID_REQUEST,
  _END_SESSION_BY_ID_SUCCESS,
  _END_SESSION_BY_ID_FAILURE
)<number, undefined, Error>();

export const endPrinterSessionByIdV2 = createAsyncAction(
  _END_SESSION_BY_ID_V2_REQUEST,
  _END_SESSION_BY_ID_V2_SUCCESS,
  _END_SESSION_BY_ID_V2_FAILURE
)<IEndSessionByPrinterIdPayload, undefined, Error>();

export const getPaymentSession = createAsyncAction(
  _GET_PAYMENT_SESSION_REQUEST,
  _GET_PAYMENT_SESSION_SUCCESS,
  _GET_PAYMENT_SESSION_FAILURE
)<undefined, undefined, Error>();

export const getPrinterStatus = createAsyncAction(
  _PRINTER_STATUS_REQUEST,
  _PRINTER_STATUS_SUCCESS,
  _PRINTER_STATUS_FAILURE
)<IRunSessionRequest, undefined, Error>();

export const printReport = createStandardAction(PRINT_REPORT)<IPrinter>();
export const clearPrinterCash = createStandardAction(CLEAR_PRINTER_CASH)<
  IPrinter
>();

export const updatePaymentApi = createStandardAction(CLEAR_PRINTER_CASH)<
  IPrinter
>();
export const startPingingPrinter = createStandardAction(START_PINGING_PRINTER)<
  IRunSessionRequest
>();

export const stopPingingPrinter = createStandardAction(STOP_PINGING_PRINTER)();

export const startSessionV2 = createAsyncAction(
  _START_SESSION_V2_REQUEST,
  _START_SESSION_V2_SUCCESS,
  _START_SESSION_V2_FAILURE
)<ISessionData, undefined, Error>();

export const endPrinterSessionV2 = createAsyncAction(
  _END_SESSION_V2_REQUEST,
  _END_SESSION_V2_SUCCESS,
  _END_SESSION_V2_FAILURE
)<IPrinterEndSessionPayload, undefined, Error>();
