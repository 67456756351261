import { createSelector } from 'reselect';

import _Store from '@Store';

import { ITickets } from '@Services/$going-rundate-api/types';

import { ITicketsReducer } from '../types';
import getTickets from './getTickets';

const getSingleTicket = createSelector<
  _Store.IState,
  ITicketsReducer,
  ITickets | null
>([getTickets], (tickets) => tickets.singleTicket);

export default getSingleTicket;
