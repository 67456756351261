import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
    },
  },

  buttonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
  },

  buttonWithoutPadding: {
    paddingRight: theme.spacing(1.25),
  },
  loader: {
    zIndex: 0,
  },

  icons: {
    height: 20,
    marginRight: 5,
    width: 20,
  },

  iconError: {
    color: theme.palette.warning.light,
  },

  iconSchedule: {
    color: theme.palette.info.light,
  },

  iconClose: {
    color: theme.palette.error.light,
  },

  iconCheck: {
    color: theme.palette.success.light,
  },

  filtersContainer: {
    backgroundColor: theme.palette.action.focus,
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },

  popper: {
    zIndex: 1050,
  },

  item: {
    marginBottom: 0,
    marginTop: 0,
  },

  search: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(3),
    },
  },
}));

export default useStyles;
