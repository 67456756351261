import { Selector } from 'reselect';

import _Store from '@Store';

import { IPoolsReducer } from '../types';

const getPools: Selector<_Store.IState, IPoolsReducer> = (state) =>
  state.goingRundates.pools;

export default getPools;
