import React from 'react';

import routes from '@/routes/routes';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';

import { IFormLayoutProps } from './../../FormLayout.types';
import useStyles from './Seats.styles';

const Seats = ({
  touched,
  values,
  errors,
  handleChange,
  handleBlur,
  history,
  selectedPool,
  rundate,
  generatePdf,
  closeModal,
  permissionSeatsIoKeyWrite,
  categories,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates.Pools.components.FormLayout.components.Seats',
  });

  const classes = useStyles();

  const redirectToSeatsReport = () => {
    closeModal();
    history.push(
      fillUrlWithValues(
        routes.rundateSeatsIoReport,
        [':id', ':poolId?'],
        [String(rundate.id), String(selectedPool?.id)]
      )
    );
  };

  const downloadSeatsPdf = () => {
    generatePdf(rundate.id, selectedPool?.id);
  };

  return (
    <Grid item={true} xs={12}>
      <Grid container={true} spacing={2} alignItems="center">
        {permissionSeatsIoKeyWrite && (
          <Grid item={true} xs={12} sm={values.id ? true : 12}>
            <LabelContentContainer>
              <FormControl
                variant="standard"
                fullWidth={true}
                error={
                  !!(touched.seatsIoCategoryKey && errors.seatsIoCategoryKey)
                }
              >
                <InputLabel>
                  {(touched.seatsIoCategoryKey && errors.seatsIoCategoryKey) ||
                    t('seats')}
                </InputLabel>
                <Select
                  variant="standard"
                  value={values.seatsIoCategoryKey || -1}
                  onChange={handleChange}
                  defaultValue={-1}
                  fullWidth={true}
                  name="seatsIoCategoryKey"
                  onBlur={handleBlur}
                >
                  <MenuItem value={-1}>-</MenuItem>
                  {categories.map((category) => (
                    <MenuItem key={category.key} value={category.key}>
                      {category.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{t('seatsHelper')}</FormHelperText>
              </FormControl>
            </LabelContentContainer>
          </Grid>
        )}
        {values.id && rundate.hasSeatsIo && (
          <>
            <Grid item={true}>
              <Button
                variant="outlined"
                size="medium"
                color="secondary"
                className={classes.button}
                onClick={redirectToSeatsReport}
              >
                {t('seatsReport')}
              </Button>
            </Grid>
            <Grid item={true}>
              <Button
                variant="outlined"
                size="medium"
                color="secondary"
                className={classes.button}
                onClick={downloadSeatsPdf}
              >
                {t('pdfReport')}
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Seats;
