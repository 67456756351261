import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import { isElectron } from '@Misc/helpers/isElectron';

import colors from './colors';

const electronStyles = isElectron()
  ? {
      '::-webkit-scrollbar': {
        width: 20,
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
    }
  : {};

const theme = createTheme({
  loader: {
    minHeight: 300,
  },

  breakpoints: {
    // tslint:disable object-literal-sort-keys
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
    // tslint:enable
  },

  components: {
    MuiButton: {
      styleOverrides: {
        containedSecondary: {},
        outlinedPrimary: {
          borderColor: colors.primary.border,
          borderRadius: 4,

          '&:hover': {
            backgroundColor: colors.secondary.dark,
            borderColor: colors.primary.border,
          },
        },
        outlinedSecondary: {
          borderColor: colors.primary.border,

          '&:hover': {
            backgroundColor: colors.secondary.dark,
            borderColor: colors.primary.border,
          },
        },
        root: {
          '&:focus': {
            outline: 'none',
          },

          borderRadius: 20,
        },
        sizeLarge: {
          fontSize: 15,
        },
        sizeSmall: {
          fontSize: 13,
          fontWeight: 500,
          lineHeight: '22px',
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.other.divider,
        },
      },
    },

    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.other.backdrop,
          borderRadius: '0px 64px 64px 0px',
          paddingBottom: 32,
          paddingTop: 32,
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        underline: {
          '&::before': {
            borderColor: colors.text.secondary,
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',

          '&.Mui-focused': {
            width: 'auto',
          },
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: colors.other.stroke,
        },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: {
          minWidth: '100%',
          width: 'max-content',
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colors.primary.background,
          },

          '&.MuiTableRow-hover': {
            '&:hover': {
              backgroundColor: `${colors.primary.background}!important`,

              '& .MuiButton-outlinedPrimary:not(.Mui-disabled)': {
                backgroundColor: `${colors.primary.background}`,
                border: `1px solid ${colors.primary.border}`,
              },
            },
            cursor: 'pointer',
          },
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        button: {
          '&:hover': {
            backgroundColor: colors.secondary.dark,
          },
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        icon: {
          color: colors.text.secondary,
        },
      },
    },

    MuiToolbar: {
      styleOverrides: {
        regular: {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 16,
        },
        spacing: {
          '& > :not(:first-child)': {
            marginLeft: 16,
          },
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowX: 'hidden',
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          color: colors.secondary.main,

          '&.Mui-disabled': {
            color: colors.text.secondary,
          },

          '&:focus': {
            outline: 'none',
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          marginBottom: 16,
          marginTop: 16,

          '&:hover': {
            backgroundColor: colors.secondary.dark,
          },
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginBottom: 0,
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.background.paper,
        },
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.other.backdrop,
        },
      },
    },

    MuiTableContainer: {
      styleOverrides: {
        root: {
          flexGrow: 1,
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        popper: {
          zIndex: 9999,
        },
      },
    },

    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& .MuiPickersDay-root': {
            '&:focus': {
              outline: 'none',
            },
          },
        },
      },
    },

    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          '& .MuiPickersDay-root': {
            '&:focus': {
              outline: 'none',
            },
          },
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        '#atlwdg-blanket, #atlwdg-container, .cancel, #atlwdg-trigger': {
          display: 'none!important',
        },
        '.hg-button': {
          color: 'black',
        },
        '.simple-keyboard': {
          bottom: 0,
          position: 'fixed',
          zIndex: 99999,
        },
        body: {
          width: '100%',

          '& p': {
            marginBottom: 0,
          },
        },
        ...electronStyles,
      },
    },
  },

  palette: {
    action: {
      disabled: colors.action.disabled,
      disabledBackground: colors.action.disabledBg,
      focus: colors.action.focus,
      hoverOpacity: 0.6,
      selected: colors.primary.border,
    },
    background: {
      paper: colors.background.paper,
    },
    other: {
      stroke: colors.other.stroke,
    },
    primary: {
      contrastText: colors.primary.contrastText,
      dark: colors.primary.dark,
      light: colors.primary.light,
      main: colors.primary.main,
    },
    secondary: {
      contrastText: colors.secondary.contrastText,
      dark: colors.secondary.dark,
      light: colors.secondary.light,
      main: colors.secondary.main,
    },
    text: {
      disabled: colors.text.disabled,
      primary: colors.text.primary,
      secondary: colors.text.secondary,
    },
  },

  typography: {
    body1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: '0.15px',
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: '0.15px',
      lineHeight: '20px',
    },
    button: {
      fontSize: 15,
      fontWeight: 700,
      letterSpacing: '-0.4px',
      lineHeight: '26px',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: '0.4px',
      lineHeight: '20px',
    },
    fontFamily: 'NoirPro',
    fontSize: 16,
    h1: {
      fontSize: 96,
      fontWeight: 300,
      letterSpacing: '-1.5px',
      lineHeight: '112px',
    },
    h2: {
      fontSize: 60,
      fontWeight: 600,
      letterSpacing: '-0.5px',
      lineHeight: '72px',
    },
    h3: {
      fontSize: 48,
      fontWeight: 700,
      lineHeight: '56px',
    },
    h4: {
      fontSize: 34,
      fontWeight: 700,
      letterSpacing: '0.25px',
      lineHeight: '42px',
    },
    h5: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: '32px',
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: '0.15px',
      lineHeight: '32px',
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: '1px',
      lineHeight: '32px',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: '0.15px',
      lineHeight: '28px',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '0.1px',
      lineHeight: '22px',
    },
  },
});

export default theme;
