import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import { removeUser } from '@Model/formio/actions';
import { getFormIoUsers } from '@Model/formio/selectors';
import _Store from '@Store';
import UsersList from './UsersList.component';
import { IUsersListFromDispatch, IUsersListFromState } from './UsersList.types';

const mapStateToProps = (state: _Store.IState): IUsersListFromState => {
  return {
    users: getFormIoUsers(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IUsersListFromDispatch => ({
  removeUser: (userId) => dispatch(removeUser(userId)),
});

export default connect<
  IUsersListFromState,
  IUsersListFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(UsersList);
