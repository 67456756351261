import { createSelector } from 'reselect';

import _Store from '@Store';
import { IMeta, ISettlementsReducer } from './../types';
import get from './get';

const getSettlementsMeta = createSelector<
  _Store.IState,
  ISettlementsReducer,
  IMeta
>([get], (settlement) => settlement.settlements.meta);

export default getSettlementsMeta;
