import { createSelector } from 'reselect';

import _Store from '@Store';

import { IAddPriceType, IRuleTypesReducer } from '../types';

import get from './get';

const getSelectedPriceType = createSelector<
  _Store.IState,
  IRuleTypesReducer,
  IAddPriceType | null
>([get], (products) => products.selectedPriceType);

export default getSelectedPriceType;
