import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(24),
    marginTop: theme.spacing(24),
    maxWidth: 564,
    position: 'relative',
    width: '100%',
  },
  input: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    borderRadius: 16,
    boxShadow: theme.shadows[2],
    display: 'flex',
    flexDirection: 'column',
    height: 500,
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
  screamingTriangle: {
    bottom: -90,
    height: 164,
    position: 'absolute',
    right: -36,
    width: 112,
    [theme.breakpoints.down('xs')]: {
      right: 0,
    },
  },
  title: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
}));

export default useStyles;
