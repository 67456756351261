import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import ConfirmModal from '@Compo/reusable/ConfirmModal';
import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import { ISelectedItems } from '@Compo/reusable/MetadataModal/components/AttachModal/AttachModal.types';
import MetadataModal from '@Compo/reusable/MetadataModal/MetadataModal.container';
import TextEditor from '@Compo/reusable/TextEditor';
import {
  IDependencyTypes,
  IEventMailingDependency,
  IMetaTypes,
} from '@Services/$email-api/types';
import { MetadataType } from '@Services/$metadata-api/types';

import FormActions from './components/FormActions';
import FormImageUpload from './components/FormImageUpload';
import FormMailShipment from './components/FormMailShipment';
import FormTitle from './components/FormTitle';
import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Mail.components.FormLayout',
  });

  const {
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    isLoading,
    rundate,
    isRundateEdit,
  } = props;

  const classes = useStyles();
  const [openMarketingConfirmation, setOpenMarketingConfirmation] =
    useState(false);
  const [selectedItems, setSelectedItems] = useState<ISelectedItems>({
    event: [],
    partner: [],
    pool: [],
    rundate: [],
  });

  const setFieldValueDependencies = (
    items: ISelectedItems,
    type: IDependencyTypes
  ) => {
    const dependencies: IEventMailingDependency[] = [];

    items[type].forEach((item: IMetaTypes) => {
      if (item.id) {
        dependencies.push({
          id: item.id,
          meta: item,
          type,
        });
      }
    });

    return dependencies;
  };

  const marketingChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (!checked) {
      setOpenMarketingConfirmation(true);
    } else {
      setFieldValue('marketing', true);
    }
  };

  const closeConfirmation = () => {
    setOpenMarketingConfirmation(false);
  };

  const confirmMarketing = () => {
    setFieldValue('marketing', false);
    setOpenMarketingConfirmation(false);
  };

  useEffect(() => {
    if (!values.mailShipment) {
      setFieldValue('startDate', null);
      setFieldValue('startTime', null);
    }
  }, [values.mailShipment]);

  useEffect(() => {
    values.dependencies.forEach(({ meta }) => {
      switch (meta?.nameInterface) {
        case MetadataType.Rundate:
          selectedItems.rundate.push(meta);
          break;
        case MetadataType.Pool:
          selectedItems.pool.push(meta);
          break;
        default:
          break;
      }
    });

    if (isRundateEdit) {
      selectedItems.rundate.push({
        event: {
          ...rundate.event,
          nameInterface: MetadataType.Event,
        },
        id: rundate.id,
        nameInterface: MetadataType.Rundate,
        rundate: rundate.rundate,
      });
    }

    setSelectedItems(selectedItems);
  }, []);

  useEffect(() => {
    const dependencies: IEventMailingDependency[] = [
      ...setFieldValueDependencies(selectedItems, MetadataType.Rundate),
      ...setFieldValueDependencies(selectedItems, MetadataType.Pool),
    ];

    setFieldValue('dependencies', dependencies);
  }, [selectedItems]);

  return (
    <>
      <ConfirmModal
        open={openMarketingConfirmation}
        onCancel={closeConfirmation}
        handleClose={closeConfirmation}
        title={t('marketingConfirmationTitle')}
        question={t('marketingConfirmationDesc')}
        onConfirm={confirmMarketing}
      />
      <FormTitle {...props} />
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          {isLoading ? (
            <Loader />
          ) : (
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <TextField
                    id="title"
                    label={(touched.title && errors.title) || t('emailTitle')}
                    helperText={t('emailTitleHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                    name="title"
                    error={!!(touched.title && errors.title)}
                    fullWidth={true}
                    disabled={values.isSent}
                  />
                </LabelContentContainer>
              </Grid>
              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <TextEditor
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    value={values.description}
                    name="description"
                    label={
                      (touched.description && errors.description) ||
                      t('description')
                    }
                    disabled={values.isSent}
                    error={!!(touched.description && errors.description)}
                  />
                </LabelContentContainer>
              </Grid>

              <FormImageUpload {...props} />

              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <TextField
                    id="buttonText"
                    label={
                      (touched.buttonText && errors.buttonText) || t('button')
                    }
                    helperText={t('buttonHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.buttonText}
                    name="buttonText"
                    error={!!(touched.buttonText && errors.buttonText)}
                    fullWidth={true}
                    disabled={values.isSent}
                  />
                </LabelContentContainer>
              </Grid>

              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <TextField
                    id="buttonLink"
                    label={
                      (touched.buttonLink && errors.buttonLink) ||
                      t('buttonLink')
                    }
                    helperText={t('buttonLinkHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.buttonLink}
                    name="buttonLink"
                    error={!!(touched.buttonLink && errors.buttonLink)}
                    fullWidth={true}
                    disabled={values.isSent}
                  />
                </LabelContentContainer>
              </Grid>

              <Grid item={true} xs={12}>
                <Grid container={true} spacing={1}>
                  <MetadataModal
                    getSelectedItems={(items: ISelectedItems) =>
                      setSelectedItems(items)
                    }
                    selectedItems={selectedItems}
                    usingMetadata={{ rundate: true, pool: true }}
                    isFilterByActivePartner={true}
                    buttonDisabled={values.isSent}
                  />
                </Grid>
              </Grid>

              <Grid item={true} xs={12}>
                <Grid container={true}>
                  <Grid item={true} xs={12}>
                    <Grid container={true} spacing={1}>
                      <Grid item={true} xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.marketing}
                              onChange={marketingChange}
                              name="marketing"
                              color="primary"
                              size="small"
                              disabled={values.isSent}
                            />
                          }
                          label={
                            <>
                              <Typography variant="body1" color="textPrimary">
                                {t('marketing')}
                              </Typography>
                              <Typography
                                component="p"
                                variant="caption"
                                color="textSecondary"
                              >
                                {t('marketingHelper')}
                              </Typography>
                            </>
                          }
                        />
                      </Grid>
                    </Grid>

                    <FormMailShipment {...props} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </form>
      {!isLoading && <FormActions {...props} />}
    </>
  );
};

export default FormLayout;
