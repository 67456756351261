import axios from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IReportRequest } from '@Model/reports/types';
import { IReportsResponse } from './types';

class ReportsApi {
  private static gerReportUrl(reportSlug: string): string {
    return `${config.api.baseUrl}admin/report/${reportSlug}`;
  }

  public getReport(
    request: IReportRequest,
    reportSlug: string,
  ): Promise<IReportsResponse> {
    return new Promise<IReportsResponse>((resolve, reject) => {
      return axios
        .get(ReportsApi.gerReportUrl(reportSlug), {
          params: request,
        })
        .then(getData)
        .then((response: IReportsResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }
}

export default new ReportsApi();
