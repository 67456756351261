import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';

import config from '@Config';
import catchHttpError, {
  catchNewErrorMessage,
} from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IEvent } from '@Services/$going-rundate-api/types';

import {
  IEventHit,
  IEventResponse,
  INormalizedEvent,
  ISingleEventResponse,
} from './types';

class EventsApi {
  private static getSearchUrl(): string {
    return `${config.api.awsSearchUrl}`;
  }
  private static getSingleEventUrl(id: number): string {
    return `${config.api.cmsServices}event/${id}`;
  }

  private cancelTokenEvents?: CancelTokenSource;

  public getEvents(
    partnerId?: string,
    search?: string,
    isEventsWithDateRange?: boolean
  ): Promise<INormalizedEvent[]> {
    return new Promise((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();
      const instance = axios.create({});
      delete instance.defaults.headers.common.Authorization;
      const startDate = moment.utc(moment(new Date())).startOf('day').format();
      const endDate = moment
        .utc(moment(new Date()).add(1, 'day'))
        .endOf('day')
        .format();

      const fieldsArray = [
        'rundate_slug',
        'event_slug',
        'title_pl',
        'rundate_rundate',
        'has_pools',
        'rundate_not_for_sale',
        'place_name',
      ];
      const fields = fieldsArray.join(',');

      const searchText = search && !!search.length ? search + '*' : '*:*';

      const checkIsEventsWithDateRange = isEventsWithDateRange
        ? `${endDate}]`
        : '*}';

      const pattern = `${searchText} AND rundate_rundate:[${startDate} TO ${checkIsEventsWithDateRange} AND rundate_not_for_sale:0 AND has_pools:1 AND partner_id:${partnerId}`;

      return instance
        .get(EventsApi.getSearchUrl(), {
          cancelToken: this.cancelTokenEvents.token,
          params: {
            q: pattern,
            'q.parser': 'lucene',
            return: fields,
            size: 100,
            sort: 'rundate_rundate asc',
          },
        })
        .then(getData)
        .then((data: IEventResponse) => {
          resolve(data.hits.hit.map(this.normalizeEvent));
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getSingleEvent(id: number): Promise<IEvent> {
    return new Promise<IEvent>((resolve, reject) => {
      this.cancelTokenEvents = axios.CancelToken.source();

      axios
        .get(EventsApi.getSingleEventUrl(id), {
          cancelToken: this.cancelTokenEvents.token,
        })
        .then(getData)
        .then(getData)
        .then((data) => resolve(this.normalizeSingleEvent(data[0])))
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public normalizeSingleEvent(event: ISingleEventResponse): IEvent {
    const { id, titlePL, descriptionPL, place, isVisible } = event;

    return {
      descriptionPL,
      id,
      isVisible,
      place,
      titlePL,
    };
  }

  public normalizeEvent(event: IEventHit): INormalizedEvent {
    const { id } = event;
    const { event_slug, rundate_rundate, rundate_slug, title_pl, place_name } =
      event.fields;

    return {
      eventSlug: event_slug,
      id,
      placeName: place_name,
      rundateSlug: rundate_slug,
      startDate: moment.utc(rundate_rundate).format('DD.MM.YYYY'),
      titlePl: title_pl,
    };
  }

  public cancelEvents() {
    if (this.cancelTokenEvents) {
      this.cancelTokenEvents.cancel();
      this.cancelTokenEvents = undefined;
    }
  }
}

export default new EventsApi();
