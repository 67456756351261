import React from 'react';

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TypographyVariant } from '@Constants/Variants/Typography';
import { IRefundsRegistrationFilters } from '@Services/$refunds-registration-api/types';

import useStyles from './Filters.styles';
import { IFiltersProps } from './Filters.types';

export const Filters = ({
  handleSubmit,
  values,
  setFieldValue,
  metadata,
  clearRefundsRegistrationsFilter,
}: IFiltersProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'RefundsRegistration.components.Filters',
  });

  const classes = useStyles();

  const handleMultipleCheckboxChange = (
    name: keyof IRefundsRegistrationFilters,
    value: string
  ) => {
    if (values[name] && Array.isArray(values[name])) {
      const field = values[name] as any;
      const index = field.indexOf(value);

      if (index === -1) {
        field.push(value);
      } else {
        field.splice(index, 1);
      }

      setFieldValue(name, field);
    }
  };

  const checkMultipleCheckbox = (
    name: keyof IRefundsRegistrationFilters,
    searchValue: string | number
  ) => {
    if (name !== 'search') {
      return values[name]?.some((filter: string | number) => {
        if (name === 'refundMaxTime') {
          searchValue = searchValue.toString();
        }

        return filter === searchValue;
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12} md={4}>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={12}>
              <Typography
                variant={TypographyVariant.body1}
                color={'textSecondary'}
              >
                {t('filterStatus')}
              </Typography>
            </Grid>
            {metadata.refundStatusTypes?.map((refundStatusType) => (
              <Grid item={true} xs={12} lg={6} key={refundStatusType.code}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) =>
                        handleMultipleCheckboxChange('status', e.target.value)
                      }
                      name="refundStatusTypes"
                      value={refundStatusType.code}
                      color="primary"
                      size="small"
                      defaultChecked={checkMultipleCheckbox(
                        'status',
                        refundStatusType.code
                      )}
                    />
                  }
                  label={
                    <Typography
                      variant={TypographyVariant.body1}
                      color="textPrimary"
                    >
                      {refundStatusType.name}
                    </Typography>
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={12}>
              <Typography
                variant={TypographyVariant.body1}
                color={'textSecondary'}
              >
                {t('filterRefundType')}
              </Typography>
            </Grid>
            {metadata.refundTypes?.map((refundType) => (
              <Grid item={true} xs={12} key={refundType.code}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) =>
                        handleMultipleCheckboxChange(
                          'refundType',
                          e.target.value
                        )
                      }
                      name="refundTypes"
                      value={refundType.code}
                      color="primary"
                      size="small"
                      defaultChecked={checkMultipleCheckbox(
                        'refundType',
                        refundType.code
                      )}
                    />
                  }
                  label={
                    <Typography
                      variant={TypographyVariant.body1}
                      color="textPrimary"
                    >
                      {refundType.name}
                    </Typography>
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item={true} xs={12} md={4}>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={12}>
              <Typography
                variant={TypographyVariant.body1}
                color={'textSecondary'}
              >
                {t('filterRefundMaxtime')}
              </Typography>
            </Grid>
            {metadata.refundMaxTimes?.map((refundMaxTime) => (
              <Grid item={true} xs={6} key={refundMaxTime}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) =>
                        handleMultipleCheckboxChange(
                          'refundMaxTime',
                          e.target.value
                        )
                      }
                      name="refundMaxTimes"
                      value={refundMaxTime}
                      color="primary"
                      size="small"
                      defaultChecked={checkMultipleCheckbox(
                        'refundMaxTime',
                        refundMaxTime
                      )}
                    />
                  }
                  label={
                    <Typography
                      variant={TypographyVariant.body1}
                      color="textPrimary"
                    >
                      {refundMaxTime}
                    </Typography>
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item={true} xs={12}>
            <Grid container={true} justifyContent="flex-end">
              <Button
                onClick={clearRefundsRegistrationsFilter}
                type="button"
                size="large"
                variant="text"
                color="primary"
              >
                {t('clear')}
              </Button>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
              >
                {t('submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Filters;
