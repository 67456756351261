import { TFunction } from 'i18next';
import moment from 'moment';

import config from '@Config';
import { ITransaction } from '@Model/reservations/types';

import { ITransactionsTable } from './Transactions.types';

export const normalizeTransactionsToTable = (
  transactions: ITransaction[],
  t: TFunction
): ITransactionsTable[] => {
  return transactions.map((transaction) =>
    normalizeSingleTransaction(transaction, t)
  );
};

export const normalizeSingleTransaction = (
  transaction: ITransaction,
  t: TFunction
): ITransactionsTable => {
  const isOnSiteSale = transaction.user.email === 'sprzedaz@goingapp.pl';

  return {
    amount: `${transaction.revenue} ${config.cms.defaultCurrency}`,
    buyer: !isOnSiteSale
      ? `${transaction.user.firstName} ${transaction.user.lastName}`
      : t('components:Transactions.onSite'),
    date: moment(transaction.purchaseTime).format('DD.MM.YYYY, HH:mm'),
    id: transaction.transactionId,
    numberOfProducts: transaction.products.length,
    numberOfTickets: transaction.items.length,
    receiptPrintout: isOnSiteSale ? transaction.wasTransactionBill : '-',
    salesDocument: transaction.wasInvoice
      ? t('components:Transactions.invoice')
      : t('components:Transactions.receipt'),
    transactionId: transaction.transactionUUID,
  };
};
