import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  IAuthorizationResponse,
  ICasherInfoResponse,
  IPaymentSessionResponse,
  IRunSessionRequest,
  ISignInPayload,
  ISignInResponse,
} from '@Services/$authorization-api/types';

import {
  _CHECK_AUTHORIZATION_FAILURE,
  _CHECK_AUTHORIZATION_REQUEST,
  _CHECK_AUTHORIZATION_SUCCESS,
  _END_SESSION_FAILURE,
  _END_SESSION_REQUEST,
  _END_SESSION_SUCCESS,
  _FETCH_AUTHORIZATION_CASHER_INFO_FAILURE,
  _FETCH_AUTHORIZATION_CASHER_INFO_REQUEST,
  _FETCH_AUTHORIZATION_CASHER_INFO_SUCCESS,
  _FETCH_SESSION_FAILURE,
  _FETCH_SESSION_REQUEST,
  _FETCH_SESSION_SUCCESS,
  _GET_TOKEN_FAILURE,
  _GET_TOKEN_REQUEST,
  _GET_TOKEN_SUCCESS,
  _REFRESH_TOKEN_FAILURE,
  _REFRESH_TOKEN_REQUEST,
  _REFRESH_TOKEN_SUCCESS,
  _SET_SESSION_FAILURE,
  _SET_SESSION_REQUEST,
  _SET_SESSION_SUCCESS,
  _SIGN_IN_FAILURE,
  _SIGN_IN_REQUEST,
  _SIGN_IN_SUCCESS,
  _SIGN_IN_WITH_FIREBASE_FAILURE,
  _SIGN_IN_WITH_FIREBASE_REQUEST,
  _SIGN_IN_WITH_FIREBASE_SUCCESS,
  AUTHORIZATION_FAIL,
  CLOSE_MODAL,
  ENCODE_JWT_TOKEN,
  END_SESSION,
  END_SESSION_BY_PRINTER_ID,
  END_USER_SESSION,
  HANDLE_PAYMENT_MODAL,
  HANDLE_USER_PERMISSIONS,
  LOGOUT,
  MOUNTED,
  REMOVE_AUTHORIZATION_HEADER,
  RUN_SESSION,
  SET_AUTHORIZATION_HEADER,
  SET_AUTHORIZATION_STATE,
  SET_PARAMS,
  SHOW_MODAL,
} from '../constants/actions';
import { IGetSessionPayload, IParameter, IUserInfo } from '../types';

export const mounted = createStandardAction(MOUNTED)();
export const logout = createStandardAction(LOGOUT)();
export const authorizationFail = createStandardAction(AUTHORIZATION_FAIL)();
export const runSession =
  createStandardAction(RUN_SESSION)<IRunSessionRequest>();

export const showModal = createStandardAction(CLOSE_MODAL)();
export const handlePaymentModal =
  createStandardAction(HANDLE_PAYMENT_MODAL)<boolean>();
export const closeModal = createStandardAction(SHOW_MODAL)();
export const endSession = createStandardAction(END_SESSION)();

export const setAuthorizationHeader = createStandardAction(
  SET_AUTHORIZATION_HEADER
)<string>();
export const removeAuthorizationHeader = createStandardAction(
  REMOVE_AUTHORIZATION_HEADER
)();

export const encodeJwtToken = createStandardAction(ENCODE_JWT_TOKEN)<string>();
export const setAuthorizationState = createStandardAction(
  SET_AUTHORIZATION_STATE
)<IUserInfo>();
export const handleUsersPermissions = createStandardAction(
  HANDLE_USER_PERMISSIONS
)<IUserInfo>();

export const getToken = createAsyncAction(
  _GET_TOKEN_REQUEST,
  _GET_TOKEN_SUCCESS,
  _GET_TOKEN_FAILURE
)<string, IAuthorizationResponse, Error>();

export const fetchAuthorizationCasherInfo = createAsyncAction(
  _FETCH_AUTHORIZATION_CASHER_INFO_REQUEST,
  _FETCH_AUTHORIZATION_CASHER_INFO_SUCCESS,
  _FETCH_AUTHORIZATION_CASHER_INFO_FAILURE
)<number, ICasherInfoResponse, Error>();

export const signIn = createAsyncAction(
  _SIGN_IN_REQUEST,
  _SIGN_IN_SUCCESS,
  _SIGN_IN_FAILURE
)<ISignInPayload, ISignInResponse, Error>();

export const signInWithFirebase = createAsyncAction(
  _SIGN_IN_WITH_FIREBASE_REQUEST,
  _SIGN_IN_WITH_FIREBASE_SUCCESS,
  _SIGN_IN_WITH_FIREBASE_FAILURE
)<ISignInPayload, ISignInPayload, Error>();

export const checkAuthorization = createAsyncAction(
  _CHECK_AUTHORIZATION_REQUEST,
  _CHECK_AUTHORIZATION_SUCCESS,
  _CHECK_AUTHORIZATION_FAILURE
)<undefined, Error, Error>();

export const clearSession = createAsyncAction(
  _END_SESSION_REQUEST,
  _END_SESSION_SUCCESS,
  _END_SESSION_FAILURE
)<undefined, undefined, Error>();

export const clearSessionByPrinterId = createAsyncAction(
  _END_SESSION_REQUEST,
  _END_SESSION_SUCCESS,
  _END_SESSION_FAILURE
)<number, undefined, Error>();

export const refreshToken = createAsyncAction(
  _REFRESH_TOKEN_REQUEST,
  _REFRESH_TOKEN_SUCCESS,
  _REFRESH_TOKEN_FAILURE
)<undefined, IAuthorizationResponse, Error>();

// Printers actions
export const setSession = createAsyncAction(
  _SET_SESSION_REQUEST,
  _SET_SESSION_SUCCESS,
  _SET_SESSION_FAILURE
)<undefined, undefined, Error>();

export const fetchSession = createAsyncAction(
  _FETCH_SESSION_REQUEST,
  _FETCH_SESSION_SUCCESS,
  _FETCH_SESSION_FAILURE
)<IGetSessionPayload, IPaymentSessionResponse, Error>();

export const endSessionByPrinterId = createStandardAction(
  END_SESSION_BY_PRINTER_ID
)<number>();

export const endUserSession = createStandardAction(END_USER_SESSION)();
export const setParams = createStandardAction(SET_PARAMS)<IParameter[]>();
