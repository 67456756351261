import { createSelector } from 'reselect';

import _Store from '@Store';
import { IFBPixel, ISettingsReducer } from './../types';
import get from './get';

const getFbPixel = createSelector<_Store.IState, ISettingsReducer, IFBPixel>(
  [get],
  (settings) => settings.pixel
);

export default getFbPixel;
