import { LOCATION_CHANGE } from 'connected-react-router';
import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getHappeningPartnerId } from '@Model/happenings/selectors';

import {
  changeFilter,
  changeSize,
  getTransactions,
  handleActivePage,
  mounted,
} from '../actions';
import { getFilters, getMeta } from '../selectors';

export const requestTransactionsWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([mounted, changeFilter, handleActivePage, changeSize])),
    mergeMap$(() => {
      return of$(getTransactions.request());
    })
  );
};

export const fetchTransactionsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { transactionsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getTransactions.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const partnerId = getHappeningPartnerId(state);
      const filters = getFilters(state);
      const meta = getMeta(state);

      return from$(
        transactionsApi.getTransactions(
          meta.page,
          meta.size,
          filters,
          partnerId
        )
      ).pipe(
        map$((data) => {
          return getTransactions.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => transactionsApi.cancelTransaction())
          )
        ),
        catchError$((error: Error) => {
          return of$(getTransactions.failure(error));
        })
      );
    })
  );
};
