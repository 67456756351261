import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { _Store } from '@Store';

import { mounted, selectEvent } from '@Model/events/actions';
import { getEvents, getSelectedEvent } from '@Model/events/selectors';
import { EVENTS } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';

import EventsList from './EventsList.component';
import {
  IEventsListFromDispatch,
  IEventsListFromState,
} from './EventsList.types';

const mapStateToProps = (state: _Store.IState): IEventsListFromState => ({
  events: getEvents(state),
  isLoading: getLoading(EVENTS)(state),
  selectedEvent: getSelectedEvent(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IEventsListFromDispatch => ({
  mounted: () => dispatch(mounted()),
  selectEvent: (event) => dispatch(selectEvent(event)),
});

export default connect<
  IEventsListFromState,
  IEventsListFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(EventsList);
