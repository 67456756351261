import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  fetchSettlementRundates,
  getSettlementRundates,
} from '@Model/settlements/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import { resetError, resetLoading, setLoading } from './../actions';
import { SETTLEMENTS_RUNDATES } from './../constants/constants';

export const setUpStateWhenSettlementsRundatesFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(
      isActionOf([fetchSettlementRundates, getSettlementRundates.request])
    ),
    mergeMap$(() => [
      resetError(SETTLEMENTS_RUNDATES),
      setLoading(SETTLEMENTS_RUNDATES),
    ])
  );
};

export const setLoaderWhenSettlementsRundatesFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [getSettlementRundates.success, getSettlementRundates.failure],
        action
      )
    ),
    map$(() => resetLoading(SETTLEMENTS_RUNDATES))
  );
};

export const setErrorOnSettlementsRundatesFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getSettlementRundates.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
