import { getType } from 'typesafe-actions';

import { hideSuccessModal, showSuccessModal } from './../actions';
import { IAction, IModalReducer } from './../types';

const initialState: IModalReducer = {
  message: undefined,
  onConfirm: undefined,
  showSuccessModal: false,
  title: undefined,
};

const modalReducer = (
  state: IModalReducer = initialState,
  action: IAction
): IModalReducer => {
  switch (action.type) {
    case getType(showSuccessModal):
      if (action.payload instanceof Object) {
        return {
          ...state,
          message: action.payload?.message,
          onConfirm: action.payload?.onConfirm,
          showSuccessModal: true,
          title: action.payload?.title,
        };
      }

      return {
        ...initialState,
        showSuccessModal: true,
      };
    case getType(hideSuccessModal):
      return {
        ...state,
        message: undefined,
        onConfirm: undefined,
        showSuccessModal: false,
        title: undefined,
      };
    default:
      return state;
  }
};

export default modalReducer;
