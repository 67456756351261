export const MOUNTED = 'Zagrywki/settings/MOUNTED';
export const HANDLE_ACTIVE_FILTER = 'Zagrywki/settings/HANDLE_ACTIVE_FILTER';
export const CATCH_SAVE_FILTER = 'Zagrywki/settings/CATCH_SAVE_FILTER';
export const CATCH_SAVE_PIXEL = 'Zagrywki/settings/CATCH_SAVE_PIXEL';
export const CATCH_SAVE_PARTNER = 'Zagrywki/settings/CATCH_SAVE_PARTNER';
export const CATCH_DELETE_FILTER = 'Zagrywki/settings/CATCH_DELETE_FILTER';
export const CATCH_CHANGE_PAGE_FILTER =
  'Zagrywki/settings/CATCH_CHANGE_PAGE_FILTER';

export const _GET_PARTNER_FILTERS_REPORT_FAILURE =
  'Zagrywki/settings/_GET_PARTNER_FILTERS_REPORT_FAILURE';
export const _GET_PARTNER_FILTERS_REPORT_REQUEST =
  'Zagrywki/settings/_GET_PARTNER_FILTERS_REPORT_REQUEST';
export const _GET_PARTNER_FILTERS_REPORT_SUCCESS =
  'Zagrywki/settings/_GET_PARTNER_FILTERS_REPORT_SUCCESS';

export const _GET_PIXEL_FAILURE = 'Zagrywki/settings/_GET_PIXEL_FAILURE';
export const _GET_PIXEL_REQUEST = 'Zagrywki/settings/_GET_PIXEL_REQUEST';
export const _GET_PIXEL_SUCCESS = 'Zagrywki/settings/_GET_PIXEL_SUCCESS';

export const _GET_PARTNER_FAILURE = 'Zagrywki/settings/_GET_PARTNER_FAILURE';
export const _GET_PARTNER_REQUEST = 'Zagrywki/settings/_GET_PARTNER_REQUEST';
export const _GET_PARTNER_SUCCESS = 'Zagrywki/settings/_GET_PARTNER_SUCCESS';

export const _SAVE_PARTNER_FAILURE = 'Zagrywki/settings/_SAVE_PARTNER_FAILURE';
export const _SAVE_PARTNER_REQUEST = 'Zagrywki/settings/_SAVE_PARTNER_REQUEST';
export const _SAVE_PARTNER_SUCCESS = 'Zagrywki/settings/_SAVE_PARTNER_SUCCESS';

export const _SAVE_FILTER_FAILURE = 'Zagrywki/settings/_SAVE_FILTER_FAILURE';
export const _SAVE_FILTER_REQUEST = 'Zagrywki/settings/_SAVE_FILTER_REQUEST';
export const _SAVE_FILTER_SUCCESS = 'Zagrywki/settings/_SAVE_FILTER_SUCCESS';

export const _DELETE_FILTER_FAILURE =
  'Zagrywki/settings/_DELETE_FILTER_FAILURE';
export const _DELETE_FILTER_REQUEST =
  'Zagrywki/settings/_DELETE_FILTER_REQUEST';
export const _DELETE_FILTER_SUCCESS =
  'Zagrywki/settings/_DELETE_FILTER_SUCCESS';

export const _SAVE_PIXEL_FAILURE = 'Zagrywki/settings/_SAVE_PIXEL_FAILURE';
export const _SAVE_PIXEL_REQUEST = 'Zagrywki/settings/_SAVE_PIXEL_REQUEST';
export const _SAVE_PIXEL_SUCCESS = 'Zagrywki/settings/_SAVE_PIXEL_SUCCESS';

export const _GET_PARTNER_FILTERS_REPORT_PARAMETERS_FAILURE =
  'Zagrywki/settings/_GET_PARTNER_FILTERS_REPORT_PARAMETERS_FAILURE';
export const _GET_PARTNER_FILTERS_REPORT_PARAMETERS_REQUEST =
  'Zagrywki/settings/_GET_PARTNER_FILTERS_REPORT_PARAMETERS_REQUEST';
export const _GET_PARTNER_FILTERS_REPORT_PARAMETERS_SUCCESS =
  'Zagrywki/settings/_GET_PARTNER_FILTERS_REPORT_PARAMETERS_SUCCESS';
