import { AnyAction } from 'redux';
import { createSelector } from 'reselect';

import _Store from '@Store';

import { IModalReducer } from '@Model/modal/types';

import get from './get';

const getSuccessOnConfirmModal = createSelector<
  _Store.IState,
  IModalReducer,
  AnyAction | undefined
>([get], (modal) => modal.onConfirm);

export default getSuccessOnConfirmModal;
