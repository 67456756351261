import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { handleActiveLocalProduct, saveProduct } from '@Model/products/actions';
import { getActiveLocalProduct } from '@Model/products/selectors';
import _Store from '@Store';
import ProductsModal from './ProductsModal.component';
import { prepareInitialValues } from './ProductsModal.helpers';
import {
  IProductsModalFromDispatch,
  IProductsModalFromState,
} from './ProductsModal.types';

const mapStateToProps = (state: _Store.IState): IProductsModalFromState => {
  return {
    initialValues: prepareInitialValues(getActiveLocalProduct(state)),
    partnerId: getHappeningPartnerId(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IProductsModalFromDispatch => {
  return {
    closeModal: () => dispatch(handleActiveLocalProduct(null)),
    saveProduct: (body) => dispatch(saveProduct(body)),
  };
};

export default connect<
  IProductsModalFromState,
  IProductsModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ProductsModal);
