import React from 'react';

import Grid from '@Compo/layout/Grid';
import Tickets from '@Compo/Tickets';

const TicketsPage = () => (
  <Grid>
    <Tickets />
  </Grid>
);

export default TicketsPage;
