import React, { useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor, RawDraftContentState } from 'react-draft-wysiwyg';

import config from './TextEditor.config';
import useStyles from './TextEditor.styles';
import { ITextEditorProps } from './TextEditor.types';

const TextEditor = ({
  setFieldTouched,
  setFieldValue,
  value,
  name,
  label,
  disabled,
  error,
  disabledOptions = ['image', 'colorPicker', 'list', 'fontSize', 'fontFamily'],
  color,
}: ITextEditorProps) => {
  const classes = useStyles({ label, error, color });
  const [editorState, setEditorState] = useState<EditorState | undefined>();
  const [focused, setFocused] = useState(false);

  const toolbar = useMemo(
    () => ({
      ...config,
      options: config.options.filter(
        (option) => !disabledOptions?.includes(option)
      ),
    }),
    [disabledOptions]
  );

  const onEditorStateChange = (editor: EditorState) => {
    setEditorState(editor);
    if (name) {
      setFieldValue(
        name,
        draftToHtml(convertToRaw(editor.getCurrentContent()))
      );
    }
  };

  const onContentStateChange = (contentState: RawDraftContentState) => {
    if (
      contentState.blocks.length === 1 &&
      !contentState.blocks[0].text.length &&
      name
    ) {
      setFieldValue(name, '');
    }
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
    if (name) {
      setFieldTouched(name, true);
    }
  };

  useEffect(() => {
    const contentBlock = htmlToDraft(value);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, []);

  return (
    <>
      <Editor
        toolbar={toolbar}
        wrapperClassName={cn(
          classes.wrapper,
          focused && classes.focused,
          disabled && classes.disabled
        )}
        editorClassName={cn(classes.editor, focused && classes.labelFocused)}
        toolbarClassName={cn(classes.toolbar, focused && classes.focused)}
        editorState={editorState}
        localization={{
          locale: 'pl',
        }}
        onEditorStateChange={onEditorStateChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onContentStateChange={onContentStateChange}
      />
    </>
  );
};

export default TextEditor;
