import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import FormLayout from './../FormLayout';
import { IConsentsListModalOwnProps } from './ConsentsListModal.types';

const ConsentsListModal = ({ onClose, open }: IConsentsListModalOwnProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'ConsentsList.components.ConsentsListModal',
  });

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={onClose}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <FormLayout />
    </Dialog>
  );
};
export default ConsentsListModal;
