import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { get } from '@Model/authorization/selectors';
import { generatePdf } from '@Model/going/reportSeatsIo/actions';
import {
  fetchPoolDescriptions,
  generateExampleTicket,
  selectPool,
} from '@Model/going/rundates/actions';
import {
  getPoolDescriptions,
  getSelectedPool,
  getSingleRundateData,
  getTablePools,
} from '@Model/going/rundates/selectors';
import { fetchCategories } from '@Model/seatsIo/actions';
import { getCategories } from '@Model/seatsIo/selectors';
import { allPermissions } from '@Model/state/constants';

import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => {
  const {
    userInfo: { permissions },
  } = get(state);
  return {
    categories: getCategories(state),
    permissionSeatsIoKeyWrite: permissions.includes(
      allPermissions.access_seatsio_key_write
    ),
    poolDescriptions: getPoolDescriptions(state),
    pools: getTablePools(state),
    rundate: getSingleRundateData(state),
    selectedPool: getSelectedPool(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormLayoutFromDispatch => ({
  closeModal: () => dispatch(selectPool(null)),
  fetchCategories: (key) => dispatch(fetchCategories(key)),
  fetchPoolDescriptions: (text) => dispatch(fetchPoolDescriptions(text)),
  generateExampleTicket: (payload) => dispatch(generateExampleTicket(payload)),
  generatePdf: (rundateId, poolId) =>
    dispatch(generatePdf({ rundateId, poolId, download: true })),
});

export default withRouter(
  connect<IFormLayoutFromState, IFormLayoutFromDispatch, {}, _Store.IState>(
    mapStateToProps,
    mapDispatchToProps
  )(FormLayout)
);
