import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import { catchNewErrorMessage } from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { ITransactionsFilters } from '@Model/transactions/types';

import { ITransactionsResponse } from './types';

class TransactionsApi {
  private static getTransactionsUrl(): string {
    return `${config.api.cmsServices}transactions`;
  }

  private cancelTransactions?: CancelTokenSource;

  public getTransactions(
    page: number,
    perPage: number,
    filters: ITransactionsFilters,
    partnerId?: string
  ): Promise<ITransactionsResponse> {
    return new Promise<ITransactionsResponse>((resolve, reject) => {
      this.cancelTransactions = axios.CancelToken.source();

      axios
        .get(TransactionsApi.getTransactionsUrl(), {
          cancelToken: this.cancelTransactions.token,
          params: {
            billExist: filters.billExist ? false : null,
            count: perPage,
            page,
            partnerIds: [partnerId],
          },
        })
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public cancelTransaction() {
    if (this.cancelTransactions) {
      this.cancelTransactions.cancel();
      this.cancelTransactions = undefined;
    }
  }
}

export default new TransactionsApi();
