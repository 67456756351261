const priceFormatter = (price: number | null): string => {
  if (!price || price === null) {
    return '0';
  }
  if (Number.isInteger(price)) {
    return String(price);
  }

  return price.toFixed(2);
};

export default priceFormatter;
