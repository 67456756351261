// State properties
export const _LOADING = 'loading';
export const _ERROR_MESSAGE = 'errorMessage';

// Modules
export const AUTHORIZATION = 'AUTHORIZATION';
export const CONFIGURATIONS = 'configurations';
export const DISCOUNT = 'discount';
export const DISCOUNTS = 'discounts';
export const FORM_IO = 'form_io';
export const HAPPENING = 'happening';
export const HAPPENING_AVAILABILITY = 'happening_availability';
export const HAPPENINGS = 'happenings';
export const RESERVATION = 'reservation';
export const RESERVATION_CHECK = 'reservation_check';
export const RESERVATIONS = 'reservations';
export const USER = 'user';
export const PRINTER = 'printer';
export const REPORTS = 'reports';
export const COMPANY_DATA = 'company_data';
export const DELAYED_TRANSACTIONS = 'delayed_transactions';
export const TICKETS = 'tickets';
export const POST_RESERVATION = 'post_reservation';
export const SUMMARY = 'summary';
export const PRODUCTS = 'products';
export const TRANSACTION_DETAILS = 'transaction_details';
export const RESERVATIONS_LIST_MODAL = 'reservations_list_modal';
export const PRICE = 'price';
export const PRICE_TYPES = 'price_types';
export const EVENTS_POOLS = 'events_pools';
export const EVENTS = 'events';
export const SETTINGS = 'settings';
export const SETTLEMENTS = 'settlements';
export const SETTLEMENTS_RUNDATES = 'settlements_rundates';
export const SETTLEMENTS_GENERATE = 'settlements_generate';
export const SETTLEMENTS_CREATE = 'settlements_create';
export const GOING_RUNDATES = 'going_rundates';
export const DEPENDENCIES = 'dependencies';
export const MAILING = 'mailing';
export const GOING_TICKETS = 'going_tickets';
export const GOING_TICKET_LIST_TICKETS = 'going_ticket_list_tickets';
export const GOING_PLACES = 'going_places';
export const GOING_POOLS = 'going_pools';
export const SEATS_IO_REPORT = 'seats_io_report';
export const SEATS_IO_CHARTS = 'seats_io_charts';
export const POOL_DESCRIPTIONS = 'pool_descriptions';
export const USERS = 'users';
export const PERMISSIONS = 'permissions';
export const REFUNDS_REGISTRATION = 'refunds_registration';
export const EMPIK_DETAILS = 'empik_details';
export const TRANSACTIONS = 'transactions';
