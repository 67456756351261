import { postReservation } from '@Model/reservation/actions';
import _Store from '@Store';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetError, resetLoading, setLoading } from './../actions';
import { POST_RESERVATION } from './../constants/constants';

export const setUpStateWhenReservationPostFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(postReservation.request)),
    mergeMap$(() => [
      resetError(POST_RESERVATION),
      setLoading(POST_RESERVATION),
    ])
  );
};

export const setLoaderWhenReservationPostFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([postReservation.success, postReservation.failure], action)
    ),
    map$(() => resetLoading(POST_RESERVATION))
  );
};
