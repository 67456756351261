import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import TextEditor from '@Compo/reusable/TextEditor';
import config from '@Config';

import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = ({
  handleSubmit,
  submitForm,
  touched,
  errors,
  handleBlur,
  handleChange,
  values,
  closeModal,
  setFieldValue,
  setFieldTouched,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'PoolDescriptions.components.FormLayout',
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));
  const TICKET_INFO_TEXT = isMobile ? t('ticketInfoMobile') : t('ticketInfo');

  const classes = useStyles();

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <TextField
                  id="titlePL"
                  label={(touched.titlePL && errors.titlePL) || t('name')}
                  helperText={t('nameHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.titlePL}
                  name="titlePL"
                  error={!!(touched.titlePL && errors.titlePL)}
                  fullWidth={true}
                />
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <TextField
                  id="descriptionPL"
                  label={
                    (touched.descriptionPL && errors.descriptionPL) || t('desc')
                  }
                  helperText={t('descHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.descriptionPL}
                  name="descriptionPL"
                  error={!!(touched.descriptionPL && errors.descriptionPL)}
                  fullWidth={true}
                  variant="outlined"
                />
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                {config.cms.showNewEditorText ? (
                  <TextEditor
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    value={values.ticketInfo}
                    name="ticketInfo"
                    label={
                      (touched.ticketInfo && errors.ticketInfo) ||
                      TICKET_INFO_TEXT
                    }
                    error={!!(touched.ticketInfo && errors.ticketInfo)}
                  />
                ) : (
                  <TextField
                    id="ticketInfo"
                    label={
                      (touched.ticketInfo && errors.ticketInfo) ||
                      TICKET_INFO_TEXT
                    }
                    helperText={t('ticketInfoHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.ticketInfo}
                    name="ticketInfo"
                    error={!!(touched.ticketInfo && errors.ticketInfo)}
                    fullWidth={true}
                    variant="outlined"
                  />
                )}
              </LabelContentContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {values.id ? t('save') : t('add')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
