import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { get } from '@Model/authorization/selectors';
import { setPartnerID } from '@Model/happenings/actions';
import { get as getHappenings } from '@Model/happenings/selectors';
import _Store from '@Store';
import Filters from './Filters.component';
import { IFiltersFromDispatch, IFiltersFromState } from './Filters.types';

const mapStateToProps = (state: _Store.IState): IFiltersFromState => {
  const {
    userInfo: { partners },
  } = get(state);

  const { partnerId } = getHappenings(state);

  return {
    partnerId,
    partners,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IFiltersFromDispatch => {
  return {
    setPartnerID: (partnerId: string) => {
      dispatch(setPartnerID(partnerId));
    },
  };
};

export default connect<
  IFiltersFromState,
  IFiltersFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(Filters);
