import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import FormLayout from './../FormLayout';
import {
  IAddPoolDescriptionModalProps,
  IAddPoolDescriptionModalValues,
} from './AddPoolDescriptionModal.types';
import { AddPoolDescriptionSchema } from './AddPoolDescriptionModal.validation';

const AddPoolDescriptionModal = ({
  activePoolDescription,
  closeModal,
  catchSavePoolDescription,
}: IAddPoolDescriptionModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'PoolDescriptions.components.AddPoolDescriptionModal',
  });

  const TITLE_TEXT = activePoolDescription?.id ? t('editTitle') : t('addTitle');

  const catchHandleSubmit = (values: IAddPoolDescriptionModalValues) => {
    catchSavePoolDescription(values);
  };

  if (!activePoolDescription) {
    return null;
  }

  return (
    <Dialog
      open={!!activePoolDescription}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle>{TITLE_TEXT}</DialogTitle>
      <Formik
        initialValues={activePoolDescription}
        onSubmit={catchHandleSubmit}
        validationSchema={AddPoolDescriptionSchema}
        component={FormLayout}
      />
    </Dialog>
  );
};

export default AddPoolDescriptionModal;
