import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import _Store from '@Store';

import { clearRefundsRegistrationsFilter } from '@Model/refundsRegistration/actions';
import { getRefundsRegistrationsMetadata } from '@Model/refundsRegistration/selectors';

import Filters from './Filters.component';
import { IFiltersFromDispatch, IFiltersFromState } from './Filters.types';

const mapStateToProps = (state: _Store.IState): IFiltersFromState => ({
  metadata: getRefundsRegistrationsMetadata(state),
});

const mapDispatchToProps = (dispatch: Dispatch): IFiltersFromDispatch => ({
  clearRefundsRegistrationsFilter: () =>
    dispatch(clearRefundsRegistrationsFilter()),
});

export default connect<
  IFiltersFromState,
  IFiltersFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Filters);
