import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
  getFormIOUsers,
  mounted,
  setDate,
  setEmail,
  setFirstName,
  setLastName,
} from '@Model/formio/actions';
import { getFormIoConsentsList } from '@Model/formio/selectors';
import { FORM_IO } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import ConsentsList from './ConsentsList.component';
import {
  IConsentsListFromDispatch,
  IConsentsListFromState,
} from './ConsentsList.types';

const mapStateToProps = (state: _Store.IState): IConsentsListFromState => {
  const { perPage, email, lastName, firstName, date } = getFormIoConsentsList(
    state,
  );

  return {
    date,
    email,
    firstName,
    isLoading: getLoading(FORM_IO)(state),
    lastName,
    limit: perPage,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IConsentsListFromDispatch => {
  return {
    getFormIOUsers: () => dispatch(getFormIOUsers()),
    mounted: (limit) => dispatch(mounted(limit)),
    setDate: (date) => dispatch(setDate(date)),
    setEmail: (email) => dispatch(setEmail(email)),
    setFirstName: (firstName) => dispatch(setFirstName(firstName)),
    setLastName: (lastName) => dispatch(setLastName(lastName)),
  };
};

export default connect<
  IConsentsListFromState,
  IConsentsListFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(ConsentsList);
