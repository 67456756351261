import { createSelector } from 'reselect';

import _Store from '@Store';
import { IProduct, IProductsReducer } from '../types';
import get from './get';

const getItems = createSelector<_Store.IState, IProductsReducer, IProduct[]>(
  [get],
  (products) => products.items
);

export default getItems;
