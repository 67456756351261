import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  catchGenerateGroupReport,
  changeFilterSettlement,
  changeSettlementsPage,
  fetchSingleSettlement,
  handleActiveSettlement,
  mounted,
  searchSettlement,
} from '@Model/settlements/actions';
import {
  getSettlementsList,
  getSettlementsMeta,
} from '@Model/settlements/selectors';
import getFilterSettlement from '@Model/settlements/selectors/getFiltersSettlement';
import { SETTLEMENTS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import { prepareInitialValues } from './Filters/Filters.helpers';
import Settlements from './Settlements.component';
import {
  ISettlementsFromDispatch,
  ISettlementsFromState,
} from './Settlements.types';

const mapStateToProps = (state: _Store.IState): ISettlementsFromState => ({
  filterInitialValues: prepareInitialValues(),
  filterValues: getFilterSettlement(state),
  isLoading: getLoading(SETTLEMENTS)(state),
  meta: getSettlementsMeta(state),
  settlements: getSettlementsList(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISettlementsFromDispatch => ({
  catchGenerateGroupReport: (payload) =>
    dispatch(catchGenerateGroupReport(payload)),
  changeFilterSettlement: (filter) => dispatch(changeFilterSettlement(filter)),
  fetchSettlement: (id) => dispatch(fetchSingleSettlement(id)),
  handleActiveSettlement: (settlement) =>
    dispatch(handleActiveSettlement(settlement)),
  handleChangePage: (page) => dispatch(changeSettlementsPage(page)),
  mounted: () => dispatch(mounted()),
  searchSettlement: (filter) => dispatch(searchSettlement(filter)),
});

export default connect<
  ISettlementsFromState,
  ISettlementsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Settlements);
