import { getType } from 'typesafe-actions';

import { clearAdvancePayment, completeAdvancePayment } from '../actions';
import { IAction } from '../types';

const initialState: string | null = null;

const advancePayment = (
  state: string | null = initialState,
  action: IAction
): string | null => {
  switch (action.type) {
    case getType(completeAdvancePayment):
      return action.payload;
    case getType(clearAdvancePayment):
      return null;
    default:
      return state;
  }
};

export default advancePayment;
