import _Store from '@Store';
import { Selector } from 'reselect';
import { IPrinter } from '../types';

const getUserPrinter: Selector<_Store.IState, IPrinter | null> = (state) => {
  if (
    state.authorization.userInfo.session &&
    state.authorization.userInfo.session.printer_id
  ) {
    const { printer_id } = state.authorization.userInfo.session;
    const { printers } = state.printer;

    return printers.find((printer) => printer.id === printer_id) || null;
  }
  return null;
};

export default getUserPrinter;
