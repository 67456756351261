import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import _Store from '@Store';
import { createSelector } from 'reselect';
import {
  IHappening,
  IHappeningMetadata,
  IHappeningSpace,
  IHappeningsReducer,
  ISelectedHappening,
  ISelectedHappeningSpace,
} from './../types';
import get from './get';

const defaultMetadata: IHappeningMetadata = {
  description: 'Brak opisu',
  id: 0,
  language: 'PL',
  slug: '-',
  title: 'Brak nazwy',
};

const normalizeSpace = (space: IHappeningSpace): ISelectedHappeningSpace => {
  const { id, metadata, url } = space;

  return {
    id,
    metadata:
      metadata.length > 0 ? getMetadataForLanguage(metadata) : defaultMetadata,
    url,
  };
};

const normalizeHappening = (happening: IHappening): ISelectedHappening => {
  const {
    color,
    endDate,
    id,
    isPublic,
    startDate,
    metadata,
    spaces,
  } = happening;

  return {
    color,
    endDate,
    id,
    isPublic,
    metadata:
      metadata.length > 0 ? getMetadataForLanguage(metadata) : defaultMetadata,
    spaces: spaces.map(normalizeSpace),
    startDate,
  };
};

const getHappenings = createSelector<
  _Store.IState,
  IHappeningsReducer,
  ISelectedHappening[]
>(
  [get],
  (happenings) =>
    happenings && happenings.items
      ? happenings.items.map(normalizeHappening)
      : [],
);

export default getHappenings;
