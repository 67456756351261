import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  activeButton: {
    background: `${theme.palette.secondary.dark}!important`,
  },
  value: {
    color: theme.palette.text.secondary,
    marginLeft: 8,
  },
}));

export default useStyles;
