import React, { useEffect } from 'react';

import { Grid } from '@mui/material';
import { Formik } from 'formik';

import Loader from '@Compo/layout/Loader/Loader';

import FormLayout from '../FormLayout';
import { IRundateValues } from '../FormLayout/FormLayout.types';
import { prepareObjectAfterSave } from './Edit.helpers';
import { IRundateProps } from './Edit.types';
import { RundateSchema } from './Edit.validation';

const Edit = ({
  initialValues,
  mounted,
  edit,
  mountedPlaces,
  addEvent,
  addRundate,
  save,
  isLoading,
}: IRundateProps) => {
  useEffect(() => {
    mounted();
    mountedPlaces();
  }, []);

  const catchHandleSubmit = (values: IRundateValues) => {
    addEvent || addRundate
      ? save(prepareObjectAfterSave(values))
      : edit(prepareObjectAfterSave(values));
  };

  if (isLoading) {
    return (
      <Grid item={true} xs={12}>
        <Loader />
      </Grid>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={catchHandleSubmit}
      validationSchema={RundateSchema}
      enableReinitialize={true}
      children={(props) => (
        <FormLayout {...props} addEvent={addEvent} addRundate={addRundate} />
      )}
    />
  );
};

export default Edit;
