import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import _Store from '@Store';

import { clearFilter } from '@Model/going/rundates/actions';

import Filters from './Filters.component';
import { IFiltersFromDispatch } from './Filters.types';

const mapDispatchToProps = (dispatch: Dispatch): IFiltersFromDispatch => ({
  clearFilter: () => dispatch(clearFilter()),
});

export default connect<{}, IFiltersFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(Filters);
