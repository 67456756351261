import { get } from '@Model/authorization/selectors';
import { resetState } from '@Model/printer/actions';
import { allPermissions } from '@Model/state/constants';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import FormLayout from './FormLayout.component';
import { IFormLayoutFromDispatch, IFromState } from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFromState => {
  const {
    userInfo: { permissions, partners },
  } = get(state);

  return {
    partners,
    permissionPrintersWrite: permissions.includes(
      allPermissions.access_printers_write,
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IFormLayoutFromDispatch => {
  return {
    closeModal: () => {
      dispatch(resetState());
    },
  };
};

export default connect<IFromState, IFormLayoutFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps,
)(FormLayout);
