import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  delay,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import config from '@Config';
import { getUserInfo } from '@Model/authorization/selectors';
import { showSuccessModal } from '@Model/modal/actions';
import { getReservationPrintData } from '@Model/reservation/selectors';
import {
  getEmpikReservationDetails,
  mounted as reservationsMounted,
  resetEmpikReservationDetails,
} from '@Model/reservations/actions';
import {
  getEmpikReservationDetails as getEmpikReservationDetailsSelector,
  getEmpikReservationEntryCode,
} from '@Model/reservations/selectors';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR, TYPE_SUCCESS } from '@Model/toasts/constants/constants';
import {
  IFormIoLoginResponse,
  IOldSubmission,
} from '@Services/$formio-api/types';

import {
  addEntryCode,
  addEntryCodeRequest,
  addOldUser,
  addUser,
  attachEntryGroup,
  attachEntryGroupUser,
  catchSaveUser,
  checkUser,
  closeModal,
  detachUser,
  editUserData,
  getAvailableUsers,
  getEntryFormsFormIo,
  getEntryTokens,
  getFormioUser,
  getFormIOUsers,
  getReservationUsers,
  getSingleUser,
  getToken,
  getUsers,
  handleActivePageConsentsList,
  loginFormIo,
  mounted,
  removeUser,
  resetState,
  saveUserReservation,
  searchUser,
  setEntryIdEdit,
  setUser,
  updateSingleUser,
  updateUser,
} from '../actions';
import {
  getFormIoAdminUser,
  getFormIoAvailableUsers,
  getFormIoConsentsList,
  getFormIoEntryForm,
  getFormIoSelectedUser,
  getFormIoToken,
  getFormIoUsers,
} from '../selectors';
import {
  IFormIoSaveUsersResponse,
  IGetUsersSuccessPayload,
  ISingleUser,
} from '../types';

const ADD_CODE_SUCCESS_TEXT = 'Dodano kod biletu.';
const REMOVE_USER_SUCCESS_TEXT = 'Pomyślnie usunięto użytkownika z listy';
const ADD_GROUP_TO_TICKET_SUCCESS_TEXT = 'Pomyślnie powiązano grupę z biletem';
const REMOVE_USER_FAILURE_TEXT = 'Wystąpił błąd.';

export const loginFormIoWhenSelectReservation: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(loginFormIo)),
    withLatestFrom$(state$),
    mergeMap$(([, state]) => {
      const { parameters } = getUserInfo(state);

      if (parameters && parameters.length) {
        const loginObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_login')
        );
        const passObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_pass')
        );
        const loginFormUrlObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_login_url')
        );

        if (loginObject && passObject && loginFormUrlObject) {
          const login = loginObject.form_io_transaction_form_login;
          const pass = passObject.form_io_transaction_form_pass;
          const formUrl = loginFormUrlObject.form_io_transaction_form_login_url;

          return from$(formIoApi.loginFormIo(formUrl, login, pass)).pipe(
            mergeMap$((data: IFormIoLoginResponse) => {
              return [getToken.success(data.token), setUser(data.formIoUser)];
            }),

            catchError$((error: Error) => {
              return of$(getToken.failure(error));
            })
          );
        }
      }

      return EMPTY$;
    })
  );
};

export const resetStateWhenSelectReservation: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getEmpikReservationDetails.success)),
    mergeMap$(() => {
      return of$(resetState());
    })
  );
};

export const resetStateWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    mergeMap$(() => {
      return of$(
        resetEmpikReservationDetails(),
        resetState(),
        getAvailableUsers.request()
      );
    })
  );
};

export const getFormAvailableUsersByEmail: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(getFormIOUsers)),
    withLatestFrom$(state$),
    mergeMap$(() => {
      return of$(getAvailableUsers.request());
    })
  );
};

export const getFormAvailableUsersWhenPageChange: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(handleActivePageConsentsList)),
    mergeMap$(() => {
      return of$(getAvailableUsers.request());
    })
  );
};

export const addEntryCodeWhenRequest: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(addEntryCode)),
    map$((action) => {
      return addEntryCodeRequest.request(action.payload);
    })
  );
};

export const saveUserWhenEntryCodeAdded: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(addEntryCodeRequest.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { parameters } = getUserInfo(state);
      const selectedUser = getFormIoSelectedUser(state);
      if (parameters && parameters.length) {
        const formDistinctDatabase = parameters.find((param) =>
          param.hasOwnProperty('form_io_entry_list_id_v2')
        );
        if (formDistinctDatabase && selectedUser) {
          const formId = formDistinctDatabase.form_io_entry_list_id_v2;

          return from$(
            formIoApi.updateUserEntryId(
              formId,
              Number(selectedUser.user.id),
              action.payload
            )
          ).pipe(
            mergeMap$(() => {
              return of$(
                addEntryCodeRequest.success(action.payload),
                addToast(ADD_CODE_SUCCESS_TEXT, TYPE_SUCCESS),
                getSingleUser.request(Number(selectedUser.user.id))
              );
            }),
            catchError$((error: Error) => {
              return of$(addEntryCodeRequest.failure(error));
            })
          );
        }
      }
      return EMPTY$;
    })
  );
};

export const catchSaveUserWhenRequest: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchSaveUser)),
    map$((action) => {
      return editUserData.request(action.payload);
    })
  );
};

export const getEnterFormUserTokensWhenRequest: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(setEntryIdEdit)),
    map$((action) => {
      return getSingleUser.request(action.payload);
    })
  );
};

export const getSingleUserWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSingleUser.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { parameters } = getUserInfo(state);

      if (parameters && parameters.length) {
        const formDistinctDatabase = parameters.find((param) =>
          param.hasOwnProperty('form_io_entry_list_id_v2')
        );
        if (formDistinctDatabase) {
          const formId = formDistinctDatabase.form_io_entry_list_id_v2;
          return from$(formIoApi.getSingleUser(formId, action.payload)).pipe(
            map$((response) => {
              return getSingleUser.success(response);
            }),
            catchError$((error: Error) => {
              return of$(getSingleUser.failure(error));
            })
          );
        }
      }
      return EMPTY$;
    })
  );
};

export const updateSingleUserWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(updateUser)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const userId = getFormIoSelectedUser(state)?.user.id;
      const oldData = getFormIoSelectedUser(state)?.lastSubmissionData;
      const { parameters } = getUserInfo(state);
      const token = getFormIoToken(state);
      if (parameters && parameters.length) {
        const formUrlObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_entry_list_url_v2')
        );
        if (userId && formUrlObject && oldData) {
          const formUrl = formUrlObject.form_io_entry_list_url_v2;

          const payload: ISingleUser = {
            email: action.payload.email,
            firstName: action.payload.firstName,
            id: action.payload.id,
            lastName: action.payload.lastName,
          };

          return from$(
            formIoApi.updateSingleUser(Number(userId), payload)
          ).pipe(
            mergeMap$((data) => {
              return from$(
                formIoApi.updateSingleSubmission(
                  formUrl,
                  token,
                  action.payload,
                  oldData
                )
              ).pipe(
                mergeMap$(() => {
                  return of$(
                    updateSingleUser.success(data),
                    closeModal(),
                    showSuccessModal(),
                    getAvailableUsers.request()
                  );
                })
              );
            }),
            catchError$((error: Error) => {
              return of$(updateSingleUser.failure(error));
            })
          );
        }

        return EMPTY$;
      }
      return EMPTY$;
    })
  );
};

export const getEnterFormUserTokens: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getEntryTokens.request)),
    withLatestFrom$(state$),
    mergeMap$(([, state]) => {
      const { parameters } = getUserInfo(state);
      const token = getFormIoToken(state);
      const email = getFormIoSelectedUser(state)?.user.email;
      if (parameters && parameters.length) {
        const tokenObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_token')
        );
        const formUrlObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_sms_form_url')
        );
        if (tokenObject && formUrlObject && email) {
          const formUrl = formUrlObject.form_io_sms_form_url;
          return from$(formIoApi.getAllEntryTokens(formUrl, token, email)).pipe(
            map$((data) => {
              return getEntryTokens.success(data);
            }),
            catchError$((error: Error) => {
              return of$(getEntryTokens.failure(error));
            })
          );
        }
      }
      return EMPTY$;
    })
  );
};

export const getFormAvailableUsers: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getAvailableUsers.request)),
    withLatestFrom$(state$),
    mergeMap$(([, state]) => {
      const { parameters } = getUserInfo(state);

      if (parameters && parameters.length) {
        const tokenObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_token')
        );
        const formUrlObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_user_url')
        );

        const formUrlObjectDefault = parameters.find((param) =>
          param.hasOwnProperty('form_io_default_url')
        );

        const formDistinctDatabase = parameters.find((param) =>
          param.hasOwnProperty('form_io_entry_list_id_v2')
        );

        if (tokenObject && formUrlObject) {
          const {
            perPage: limit,
            email,
            lastName,
            firstName,
            activePage: page,
          } = getFormIoConsentsList(state);

          if (formUrlObjectDefault && tokenObject) {
            if (formDistinctDatabase) {
              const formId = formDistinctDatabase.form_io_entry_list_id_v2;

              return from$(
                formIoApi.getAvailableUsersSearch(
                  formId,
                  email,
                  limit,
                  firstName,
                  lastName,
                  page
                )
              ).pipe(
                map$((data) => {
                  return getAvailableUsers.success(data);
                }),
                catchError$((error: Error) => {
                  return of$(getAvailableUsers.failure(error));
                })
              );
            }
          }
          return EMPTY$;
        }
      }
      return of$(getAvailableUsers.request()).pipe(delay(1000));
    })
  );
};

export const getEntryFormWhenGotToken: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getToken.success)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { parameters } = getUserInfo(state);
      const token = getFormIoToken(state);
      const { transactionItems } = getEmpikReservationDetailsSelector(state);

      if (
        transactionItems &&
        transactionItems.length &&
        parameters &&
        parameters.length
      ) {
        const tokenObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_token')
        );
        const formUrlObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_url')
        );

        const { entryToken } = transactionItems[0];

        if (tokenObject && formUrlObject && entryToken) {
          const formUrl = formUrlObject.form_io_transaction_form_url;

          return from$(formIoApi.getEntryForm(entryToken, formUrl, token)).pipe(
            mergeMap$((data) => {
              if (
                data &&
                data[0] &&
                data[0].data &&
                data[0].data.text &&
                data[0].data.uczestnicy
              ) {
                return of$(
                  getEntryFormsFormIo.success(data[0]),
                  getReservationUsers()
                );
              }

              return of$(getReservationUsers());
            }),

            catchError$((error: Error) => {
              return of$(getEntryFormsFormIo.failure(error));
            })
          );
        }
      }

      return EMPTY$;
    })
  );
};

export const catchUsersFromFormIoWhenGetUserSuccess: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getReservationUsers)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { parameters } = getUserInfo(state);
      const token = getFormIoToken(state);
      const { transactionItems } = getEmpikReservationDetailsSelector(state);

      if (
        transactionItems &&
        transactionItems.length &&
        parameters &&
        parameters.length
      ) {
        const tokenObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_transaction_form_token')
        );
        const formUrlObject = parameters.find((param) =>
          param.hasOwnProperty('form_io_sms_form_url')
        );

        const { entryToken } = transactionItems[0];

        if (entryToken && tokenObject && formUrlObject) {
          const formUrl = formUrlObject.form_io_sms_form_url;
          return from$(formIoApi.getUsers(entryToken, formUrl, token)).pipe(
            map$((data: IGetUsersSuccessPayload) => {
              return getUsers.success(data);
            }),

            catchError$((error: Error) => {
              return of$(getUsers.failure(error));
            })
          );
        }
      }

      return EMPTY$;
    })
  );
};

export const catchUsersFromFormioBackend: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getEmpikReservationDetails.success,
        mounted,
        reservationsMounted,
        attachEntryGroupUser.success,
      ])
    ),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { transactionItems } = getEmpikReservationDetailsSelector(state);
      const { parameters } = getUserInfo(state);

      const getFormId = (): string => {
        if (parameters && parameters.length) {
          const formUrlObject = parameters.find((param) =>
            param.hasOwnProperty('form_io_entry_list_id_v2')
          );
          if (formUrlObject) {
            return formUrlObject.form_io_entry_list_id_v2;
          }
        }
        return '';
      };
      if (transactionItems && transactionItems.length) {
        const { entryToken } = transactionItems[0];

        if (!config.cms.showNewEnterList) {
          return of$(loginFormIo());
        }

        if (entryToken && getFormId().length) {
          return from$(
            formIoApi.getFormIoSubmissions(entryToken, getFormId())
          ).pipe(
            map$((data: IGetUsersSuccessPayload) => {
              return getUsers.success({
                totalCount: data.totalCount,
                users: data.users,
              });
            }),

            catchError$((error: any) => {
              if (error.status === 404) {
                return of$(loginFormIo());
              }
              return of$(getUsers.failure(error));
            })
          );
        }
      }

      return EMPTY$;
    })
  );
};

export const detachUserSubmissionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(removeUser)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { transactionItems } = getEmpikReservationDetailsSelector(state);

      if (transactionItems && transactionItems.length) {
        const { entryToken } = transactionItems[0];

        if (!config.cms.showNewEnterList) {
          return of$(detachUser.success(action.payload));
        }

        if (entryToken) {
          return from$(
            formIoApi.deleteSubmission(entryToken, action.payload)
          ).pipe(
            mergeMap$(() => {
              return of$(
                detachUser.success(action.payload),
                addToast(REMOVE_USER_SUCCESS_TEXT, TYPE_SUCCESS)
              );
            }),

            catchError$(() => {
              return of$(addToast(REMOVE_USER_FAILURE_TEXT, TYPE_ERROR));
            })
          );
        }
      }

      return EMPTY$;
    })
  );
};

export const attachEntryGroupWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(attachEntryGroup)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { transactionItems } = getEmpikReservationDetailsSelector(state);
      const printData = getReservationPrintData(state);

      const getTransactionEntryToken = () => {
        if (transactionItems && transactionItems.length) {
          const { entryToken } = transactionItems[0];
          return entryToken;
        }

        if (printData.transactionItems.length) {
          const { entryToken } = printData.transactionItems[0];
          return entryToken;
        }

        return '';
      };

      if (getTransactionEntryToken().length) {
        return from$(
          formIoApi.attachGroupToTicketCode(
            getTransactionEntryToken(),
            action.payload
          )
        ).pipe(
          mergeMap$(() => {
            return of$(
              attachEntryGroupUser.success(action.payload),
              addToast(ADD_GROUP_TO_TICKET_SUCCESS_TEXT, TYPE_SUCCESS)
            );
          }),

          catchError$(() => {
            return of$(addToast(REMOVE_USER_FAILURE_TEXT, TYPE_ERROR));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const saveUsersReservationWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveUserReservation.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const text = getEmpikReservationEntryCode(state) || '';
      const { parameters } = getUserInfo(state);

      const getFormUrl = (): string => {
        if (parameters && parameters.length) {
          const formUrlObject = parameters.find((param) =>
            param.hasOwnProperty('form_io_transaction_form_url')
          );
          if (formUrlObject) {
            return formUrlObject.form_io_transaction_form_url;
          }
        }
        return '';
      };

      const users = getFormIoUsers(state);
      const entryForm = getFormIoEntryForm(state);
      const formIoUser = getFormIoAdminUser(state);

      const getSubmissionData = () => {
        if (users && users.length) {
          return {
            availableUsers: getFormIoAvailableUsers(state),
            formIoUser,
            formUrl: getFormUrl(),
            submission: {
              data: {
                text: entryForm.data.text || text,
                uczestnicy: users,
              },
              modified: entryForm.modified,
            },
            token: getFormIoToken(state),
          };
        }

        return {
          availableUsers: getFormIoAvailableUsers(state),
          formIoUser,
          formUrl: getFormUrl(),
          submission: {
            data: {
              text,
              uczestnicy: [],
            },
            modified: '',
          },
          token: getFormIoToken(state),
        };
      };
      if (!getSubmissionData().submission.data.text.length) {
        return EMPTY$;
      }

      return from$(
        formIoApi.saveUsersReservation(
          getSubmissionData().formUrl,
          getSubmissionData().submission,
          getSubmissionData().token
        )
      ).pipe(
        map$((data: IFormIoSaveUsersResponse) => {
          return saveUserReservation.success(data);
        }),

        catchError$((error: Error) => {
          return of$(saveUserReservation.failure(error));
        })
      );
    })
  );
};

export const catchUserFromFormioBackend: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getFormioUser)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { parameters } = getUserInfo(state);
      const entryForm = getFormIoConsentsList(state);

      const getFormId = (): string => {
        if (parameters && parameters.length) {
          const formUrlObject = parameters.find((param) =>
            param.hasOwnProperty('form_io_entry_list_id_v2')
          );
          if (formUrlObject) {
            return formUrlObject.form_io_entry_list_id_v2;
          }
        }
        return '';
      };
      return from$(
        formIoApi.getFormIoUserWhenExist(
          getFormId(),
          entryForm.email,
          entryForm.firstName
        )
      ).pipe(
        mergeMap$((data) => {
          return of$(searchUser.success(data), addUser(data));
        }),

        catchError$((error) => {
          if (error.response.status === 404) {
            return of$(checkUser.request());
          }
          return of$(searchUser.failure(error));
        })
      );
    })
  );
};

export const checkUserExistWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(checkUser.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { parameters } = getUserInfo(state);
      const entryForm = getFormIoConsentsList(state);

      const getOldEntryFormUrl = (): string => {
        if (parameters && parameters.length) {
          const formUrlObject = parameters.find((param) =>
            param.hasOwnProperty('form_io_sms_form_url')
          );
          if (formUrlObject) {
            return formUrlObject.form_io_sms_form_url;
          }
        }
        return '';
      };
      return from$(
        formIoApi.checkUserExist(
          getOldEntryFormUrl(),
          entryForm.email,
          entryForm.firstName
        )
      ).pipe(
        mergeMap$((data: string) => {
          return of$(checkUser.success(data));
        }),
        catchError$((err) => {
          return of$(checkUser.failure(err));
        })
      );
    })
  );
};

export const getOldDataUserWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(checkUser.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { parameters } = getUserInfo(state);

      const getOldEntryFormUrl = (): string => {
        if (parameters && parameters.length) {
          const formUrlObject = parameters.find((param) =>
            param.hasOwnProperty('form_io_sms_form_url')
          );
          if (formUrlObject) {
            return formUrlObject.form_io_sms_form_url;
          }
        }
        return '';
      };
      return from$(
        formIoApi.getOldUserData(getOldEntryFormUrl(), action.payload)
      ).pipe(
        mergeMap$((data: IOldSubmission) => {
          return of$(addOldUser(data));
        }),
        catchError$((err) => {
          return of$(checkUser.failure(err));
        })
      );
    })
  );
};

export const saveUsersAttachWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { formIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveUserReservation.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const text = getEmpikReservationEntryCode(state) || '';
      const { parameters } = getUserInfo(state);

      const getFormId = (): string => {
        if (parameters && parameters.length) {
          const formUrlObject = parameters.find((param) =>
            param.hasOwnProperty('form_io_entry_list_id_v2')
          );
          if (formUrlObject) {
            return formUrlObject.form_io_entry_list_id_v2;
          }
        }
        return '';
      };
      const users = getFormIoUsers(state);

      const submissionsPromises: Array<Promise<IFormIoSaveUsersResponse>> = [];

      if (!text.length || !config.cms.showNewEnterList) {
        return EMPTY$;
      }

      users.forEach((user) => {
        submissionsPromises.push(
          formIoApi.saveSubmissionsAttach(text, getFormId(), user.id)
        );
      });
      return from$(Promise.all(submissionsPromises)).pipe(
        map$((data: IFormIoSaveUsersResponse[]) => {
          return saveUserReservation.success(data[0]);
        }),

        catchError$((error: Error) => {
          return of$(saveUserReservation.failure(error));
        })
      );
    })
  );
};
