import config from '@Config';
import PrintingError from '@Misc/classes/PrintingError';

import { printerStatus } from './../enums/printerStatus';
import { printingTypes } from './../enums/printingTypes';
import { IPrinting } from './../types';
import CupsPrinting from './CupsPrinting';
import RawPrinting from './RawPrinting';

class Printer implements IPrinting {
  private printing: IPrinting | null;

  constructor() {
    switch (config.cms.printingType) {
      case printingTypes.cups:
        this.printing = new CupsPrinting();
        break;
      case printingTypes.raw:
        this.printing = new RawPrinting();
        break;
      default:
        this.printing = null;
        break;
    }
  }

  public getStatus(): string {
    return this.printing ? this.printing.getStatus() : printerStatus.inactive;
  }

  public print(data: any) {
    try {
      if (this.printing) {
        return this.printing.print(data);
      }

      throw new PrintingError(
        'Wystąpił błąd - drukarka niedostępna. Sprawdź połączenie.'
      );
    } catch (error) {
      throw new PrintingError(`Błąd drukowania - szczegóły: ${error.message}`);
    }
  }
}

export default Printer;
