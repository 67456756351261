import { object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';

const category = {
  color: string().required(REQUIRED_VALIDATOR_TEXT),
  name: string().required(REQUIRED_VALIDATOR_TEXT),
};

export const CategorySchema = object().shape(category);
