import { createSelector } from 'reselect';

import _Store from '@Store';

import { IRefundsRegistrationMetadata } from '@Services/$refunds-registration-api/types';

import { IRefundsRegistrationReducer } from './../types';
import get from './get';

const getRefundsRegistrationsMetadata = createSelector<
  _Store.IState,
  IRefundsRegistrationReducer,
  IRefundsRegistrationMetadata
>([get], (refundsRegistration) => refundsRegistration.metadata);

export default getRefundsRegistrationsMetadata;
