import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { endSessionByPrinterId } from '@Model/authorization/actions';
import { get } from '@Model/authorization/selectors';
import { addPrinter, editPrinter, remove } from '@Model/printer/actions';
import getPrinters from '@Model/printer/selectors/getPrinters';
import { allPermissions } from '@Model/state/constants';
import _Store from '@Store';
import PrinterTable from './PrinterTable.component';
import {
  IPrinterTableFromDispatch,
  IPrinterTableFromState,
} from './PrinterTable.types';

const mapStateToProps = (state: _Store.IState): IPrinterTableFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    Printers: getPrinters(state),
    permissionPrintersWrite: permissions.includes(
      allPermissions.access_printers_write
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPrinterTableFromDispatch => {
  return {
    addPrinter: () => {
      dispatch(addPrinter());
    },
    edit: (Printer) => {
      dispatch(editPrinter(Printer));
    },
    endSession: (printerId) => dispatch(endSessionByPrinterId(printerId)),
    remove: (Printer) => {
      dispatch(remove(Printer));
    },
  };
};

export default connect<
  IPrinterTableFromState,
  IPrinterTableFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PrinterTable);
