import { getType } from 'typesafe-actions';

import {
  addNewConfiguration,
  getConfigurations,
  handleActiveConfiguration,
  handleConfigurationsActivePage,
  handleConfigurationsRowsPerPage,
  handleRuleTypeParam,
  handleSearchParam,
  resetState,
  saveConfiguration,
} from './../actions';
import { IAction, IConfigurationsReducer } from './../types';

const initialState: IConfigurationsReducer = {
  activeConfiguration: null,
  activePage: 0,
  defaultConfiguration: {
    closed: false,
    duration: 3600,
    fromNumberOfPeople: null,
    partnerId: 0,
    prices: [],
    rrule: '',
    title: '',
    upsell: {},
    validFrom: null,
    validTo: null,
  },
  items: [],
  perPage: 20,
  searchParam: '',
  selectedRuleType: 'price',
  totalCount: 0,
};

const configurationsReducer = (
  state: IConfigurationsReducer = initialState,
  action: IAction
): IConfigurationsReducer => {
  switch (action.type) {
    case getType(getConfigurations.success):
      return {
        ...state,
        ...action.payload,
      };

    case getType(resetState):
      return initialState;

    case getType(handleActiveConfiguration):
      return {
        ...state,
        activeConfiguration: action.payload,
      };

    case getType(addNewConfiguration):
      return {
        ...state,
        activeConfiguration: state.defaultConfiguration,
      };

    case getType(saveConfiguration.success):
      return {
        ...state,
        activeConfiguration: null,
      };
    case getType(handleConfigurationsActivePage):
      return {
        ...state,
        activePage: action.payload,
      };
    case getType(handleConfigurationsRowsPerPage):
      return {
        ...state,
        perPage: action.payload,
      };
    case getType(handleSearchParam):
      return {
        ...state,
        searchParam: action.payload,
      };
    case getType(handleRuleTypeParam):
      return {
        ...state,
        selectedRuleType: action.payload,
      };

    default:
      return state;
  }
};

export default configurationsReducer;
