import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  addEntryCode,
  closeModal,
  updateUser,
} from '@Model/formio/actions';
import {
  getFormIoSelectedUser,
} from '@Model/formio/selectors';

import FormLayout from './FormLayout.component';
import { IFormLayoutFromDispatch, IFromState } from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFromState => {
  return {
    user: getFormIoSelectedUser(state),
  };
};
const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormLayoutFromDispatch => {
  return {
    addEntryCode: (code) => {
      dispatch(addEntryCode(code));
    },
    closeModal: () => {
      dispatch(closeModal());
    },
    updateUser: (data) => dispatch(updateUser(data)),
  };
};

export default connect<IFromState, IFormLayoutFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
