import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getSingleRundateData } from '@Model/going/rundates/selectors';
import { catchDeleteSeatChart } from '@Model/seatsIo/actions';

import SeatsIo from './SeatsIo.component';
import { ISeatsIoFromDispatch, ISeatsIoFromState } from './SeatsIo.types';

const mapStateToProps = (state: _Store.IState): ISeatsIoFromState => ({
  rundate: getSingleRundateData(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISeatsIoFromDispatch => ({
  catchDeleteSeatChart: () => dispatch(catchDeleteSeatChart()),
});

export default connect<
  ISeatsIoFromState,
  ISeatsIoFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(SeatsIo);
