import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { get as getFromAuthorization } from '@Model/authorization/selectors';
import { resetState } from '@Model/discount/actions';
import get from '@Model/discount/selectors/get';
import { updateSearchText } from '@Model/discounts/actions';
import getDiscounts from '@Model/discounts/selectors/get';
import { allPermissions } from '@Model/state/constants';

import Discounts from './Discounts.component';
import { IDiscountsFromDispatch, IDiscountsFromState } from './Discounts.types';

const mapStateToProps = (state: _Store.IState): IDiscountsFromState => {
  const { showModal } = get(state);
  const { searchText } = getDiscounts(state);

  const {
    userInfo: { permissions },
  } = getFromAuthorization(state);

  return {
    permissionDiscountView: permissions.includes(
      allPermissions.access_discounts_read
    ),
    permissionEventDiscountView: permissions.includes(
      allPermissions.access_event_discounts_read
    ),
    permissionHappeningDiscountView: permissions.includes(
      allPermissions.access_happening_discounts_read
    ),
    showModal,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IDiscountsFromDispatch => {
  return {
    closeModal: () => dispatch(resetState()),
    updateSearchText: (search: string) => {
      dispatch(updateSearchText(search));
    },
  };
};

export default connect<
  IDiscountsFromState,
  IDiscountsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Discounts);
