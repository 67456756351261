import { ISettlementFilters } from '@Model/settlements/types';

export const defaultValues: ISettlementFilters = {
  accepted: 'undefined',
  acceptedBy: [],
  createdAtFrom: null,
  createdAtTo: null,
  createdBy: [],
  endDate: null,
  rundates: [],
  rundatesIds: [],
  salesChannel: 0,
  settlementType: 'undefined',
  startDate: null,
};

export const prepareInitialValues = (): ISettlementFilters => {
  return defaultValues;
};
