import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPartner } from '@Model/authorization/types';

import { IUsersReducer } from '../types';
import get from './get';

const getPartners = createSelector<_Store.IState, IUsersReducer, IPartner[]>(
  [get],
  (users) => users.partners
);

export default getPartners;
