import { createSelector } from 'reselect';

import { IDiscount } from '@Model/discount/types';
import _Store from '@Store';
import { IDiscountsReducer } from '../types';
import get from './get';

const getDiscounts = createSelector<
  _Store.IState,
  IDiscountsReducer,
  IDiscount[]
>(
  [get],
  (discounts) => (discounts && discounts.items ? discounts.items : []),
);

export default getDiscounts;
