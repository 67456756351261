import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  getDelayedTransactions,
  handleActivePage,
  handleRowsPerPage,
  handleTransactionType,
} from '@Model/reservations/actions/index';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import { resetError, resetLoading, setLoading } from '../actions';
import { DELAYED_TRANSACTIONS } from '../constants/constants';

export const setUpStateWhenDelayedTransactionsFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getDelayedTransactions.request,
        handleActivePage,
        handleRowsPerPage,
        handleTransactionType,
      ])
    ),
    mergeMap$(() => [
      resetError(DELAYED_TRANSACTIONS),
      setLoading(DELAYED_TRANSACTIONS),
    ])
  );
};

export const setLoaderWhenDelayedTransactionsFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [getDelayedTransactions.success, getDelayedTransactions.failure],
        action
      )
    ),
    map$(() => resetLoading(DELAYED_TRANSACTIONS))
  );
};

export const setErrorOnDelayedTransactionsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getDelayedTransactions.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
