import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getUserInfo } from '@Model/authorization/selectors';
import getDiscountsSelector from '@Model/discounts/selectors/get';
import { getHappenings, setPartnerID } from '@Model/happenings/actions';
import { get as getHappeningsSelector } from '@Model/happenings/selectors';
import { setFilters } from '@Model/reservations/actions/index';
import { allPermissions } from '@Model/state/constants';
import { IDiscountsResponse } from '@Services/$discounts-api/types';

import {
  getDiscounts,
  getEventsDiscounts,
  handleActivePage,
  mounted,
  mountedEventsDiscounts,
  resetState,
  setActiveFilter,
  updateSearchEventText,
  updateSearchText,
} from '../actions';

export const getDiscountsWhenSetFilter: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf([setPartnerID, setActiveFilter, updateSearchText])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { permissions } = getUserInfo(state);
      if (!permissions.includes(allPermissions.access_discounts_read)) {
        return of$(setFilters([]));
      }
      return of$(
        handleActivePage({ type: 'happening', page: 0 }),
        setFilters([])
      );
    })
  );
};

export const getDiscountsWhenSearchEventTextChanged: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf([updateSearchEventText])),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      return of$(handleActivePage({ type: 'event', page: 0 }));
    })
  );
};

export const requestDiscountsWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    mergeMap$(() => {
      return of$(resetState(), getDiscounts.request(), getHappenings.request());
    })
  );
};

export const requestEventsDiscountsWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(mountedEventsDiscounts)),
    mergeMap$(() => {
      return of$(resetState(), getEventsDiscounts.request());
    })
  );
};

export const fetchDiscountsWhenPageChanged: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(handleActivePage)),
    mergeMap$((action) => {
      if (action.payload.type === 'event') {
        return of$(getEventsDiscounts.request());
      }

      return of$(getDiscounts.request());
    })
  );
};

export const fetchDiscountsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { discountsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getDiscounts.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { activePage, perPage, searchText, active } =
        getDiscountsSelector(state);
      const { partnerId } = getHappeningsSelector(state);
      const { permissions } = getUserInfo(state);

      if (!permissions.includes(allPermissions.access_discounts_read)) {
        return EMPTY$;
      }

      return from$(
        discountsApi.getDiscounts(
          activePage + 1,
          perPage,
          partnerId,
          active,
          searchText
        )
      ).pipe(
        map$((data: IDiscountsResponse) => {
          const normalizedData = discountsApi.normalizeDiscounts(data);

          return getDiscounts.success(normalizedData);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => discountsApi.cancelDiscounts())
          )
        ),
        catchError$((error: Error) => {
          return of$(getDiscounts.failure(error));
        })
      );
    })
  );
};

export const fetchEventsDiscountsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { discountsApi }
) => {
  return action$.pipe(
    filter$(isActionOf([getEventsDiscounts.request])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { activePage, perPage, searchText } = getDiscountsSelector(state);
      const { partnerId } = getHappeningsSelector(state);

      return from$(
        discountsApi.getEventsDiscounts(
          activePage + 1,
          perPage,
          partnerId,
          searchText
        )
      ).pipe(
        map$((data) => {
          return getEventsDiscounts.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => discountsApi.cancelDiscounts())
          )
        ),
        catchError$((error: Error) => {
          return of$(getDiscounts.failure(error));
        })
      );
    })
  );
};
