import { number, object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const PRICE_TO_LOW_TEXT = 'Opłata musi być wartością dodatnią';

const shipmentFee = {
  name: string().required(REQUIRED_VALIDATOR_TEXT),
  price: number()
    .min(0, PRICE_TO_LOW_TEXT)
    .required(REQUIRED_VALIDATOR_TEXT),
};

export const ShipmentFeeSchema = object().shape(shipmentFee);
