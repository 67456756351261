import routes from '@/routes/routes';
import {
  getLocation,
  getSearch,
  LOCATION_CHANGE,
  push,
} from 'connected-react-router';
import queryString from 'query-string';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getFirstRoute } from '@Compo/layout/VerticalMenu/getMenuItems';
import config from '@Config';
import { catchNewErrorMessage } from '@Misc/helpers/api/catchHttpError';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';
import { isElectron } from '@Misc/helpers/isElectron';
import { setToken } from '@Model/app/actions';
import {
  authorizationFail,
  checkAuthorization,
  closeModal,
  encodeJwtToken,
  endSession,
  endSessionByPrinterId,
  fetchAuthorizationCasherInfo,
  fetchSession,
  getToken,
  handleUsersPermissions,
  logout,
  mounted,
  refreshToken,
  removeAuthorizationHeader,
  runSession,
  setAuthorizationHeader,
  setAuthorizationState,
  setParams,
  signIn,
  signInWithFirebase,
} from '@Model/authorization/actions';
import getAppToken from '@Model/discount/selectors/getAppToken';
import { loginFormIo } from '@Model/formio/actions';
import { clearState, setPartnerID } from '@Model/happenings/actions';
import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { refreshPage, setIframeSession } from '@Model/iframe/actions/index';
import {
  catchPrinterPayment,
  endPrinterSession,
  endPrinterSessionById,
  endPrinterSessionByIdV2,
  endPrinterSessionV2,
  getPaymentSession,
  getPrinters,
  getPrintersPinging,
  printerPayment,
  startSessionV2,
} from '@Model/printer/actions';
import {
  getPrinters as getPrintersSelector,
  getUserPrinter,
} from '@Model/printer/selectors';
import { addToast } from '@Model/toasts/actions/index';
import { TYPE_ERROR, TYPE_SUCCESS } from '@Model/toasts/constants/constants';
import { getUser } from '@Model/user/actions';
import {
  IAuthorizationResponse,
  ICasherInfoResponse,
  IPaymentSessionResponse,
  IRunSessionRequest,
  ISignInResponse,
} from '@Services/$authorization-api/types';

import { getUserInfo } from '../selectors';
import { IPaymentSessionPayload, ISearch } from './../types';

const PLEASE_REFRESH_TEXT = 'Proszę odświeżyć stronę';
const END_SESSION_TEXT = 'Sesja zakończona';
const START_SESSION_TEXT = 'Sesja rozpoczęta';
const SOMETHING_WENT_WRONG_TEXT =
  'Coś poszło nie tak, proszę spróbuj jeszcze raz.';
const LOGIN_WRONG_CREDENTIALS = 'Błędne dane logowania!';

const END_SESSION_PRINT_ERROR_TEXT =
  'Sesja zakończona pomyślnie, lecz wystąpił błąd z wydrukowaniem raportu. Zapisz datę i godzinę wystąpienia błędu.';

export const setStartStateWhenAppMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const search = queryString.parse(getSearch(state));
      if (search.token && typeof search.token === 'string') {
        const { token } = search;
        return of$(getToken.request(token));
      }

      return of$(removeAuthorizationHeader(), authorizationFail());
    })
  );
};

export const getTokenWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getToken.request)),
    mergeMap$((action) => {
      return from$(authorizationApi.getSessionToken(action.payload)).pipe(
        map$((data: IAuthorizationResponse) => {
          return getToken.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => authorizationApi.cancelAuthorization())
          )
        ),
        catchError$((error: Error) => {
          return of$(getToken.failure(error));
        })
      );
    })
  );
};

export const setTokenAndRedirectWhenGetTokenSuccess: _Store.IEpic = (
  action$,
  state$,
  { linksProvider }
) => {
  return action$.pipe(
    filter$(isActionOf(getToken.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const search: ISearch = queryString.parse(getSearch(state));
      let redirectUrl = routes.index;
      if (search.transactionId && typeof search.transactionId === 'string') {
        const { transactionId } = search;
        redirectUrl = linksProvider.buildSummaryLink(transactionId);
        if (
          search.error &&
          (search.error === '500' || search.error === '501')
        ) {
          redirectUrl = linksProvider.buildSummaryFailLink(transactionId);
        } else if (
          search.error &&
          (search.error === '400' ||
            search.error === '401' ||
            search.error === '404')
        ) {
          redirectUrl = linksProvider.buildSummaryCanceledLink(transactionId);
        }
      } else if (
        search.reservationId &&
        typeof search.reservationId === 'string'
      ) {
        const { reservationId } = search;
        redirectUrl = linksProvider.buildCalendalPopUpLink(reservationId);
      } else {
        redirectUrl = routes.index;
      }
      redirectUrl = '/login';

      const { token } = action.payload;

      return of$(
        setAuthorizationHeader(token),
        encodeJwtToken(token),
        setToken(token),
        push(redirectUrl)
      );
    })
  );
};

export const getTokenRefreshWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(refreshToken.request)),
    mergeMap$((action) => {
      return from$(authorizationApi.getRefreshToken()).pipe(
        map$((data: IAuthorizationResponse) => {
          return refreshToken.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => authorizationApi.cancelAuthorization())
          )
        ),
        catchError$((error: Error) => {
          return of$(refreshToken.failure(error));
        })
      );
    })
  );
};

export const setTokenWhenRefreshTokenSuccess: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(refreshToken.success)),
    mergeMap$((action) => {
      const { token } = action.payload;

      localStorage.setItem('GOING_TOKEN', token);

      document.body.style.overflow = 'visible';

      return of$(
        setAuthorizationHeader(token),
        encodeJwtToken(token),
        setToken(token),
        closeModal()
      );
    })
  );
};

export const encodeJwtTokenAndSetUserStateWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(encodeJwtToken)),
    mergeMap$((action) => {
      const userData = authorizationApi.encodeSessionToken(action.payload);
      if (userData.session && userData.session.session_id) {
        return of$(
          setIframeSession(),
          setAuthorizationState(userData),
          handleUsersPermissions(userData),
          getPrintersPinging.request(),
          loginFormIo(),
          getUser.request()
        );
      }
      return of$(
        setAuthorizationState(userData),
        handleUsersPermissions(userData),
        loginFormIo(),
        getUser.request()
      );
    })
  );
};

export const encodeJwtTokenAndSetPartnerIdWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(encodeJwtToken)),
    mergeMap$((action) => {
      const userData = authorizationApi.encodeSessionToken(action.payload);
      const localStoragePartner = localStorage.getItem('GOING_PARTNER');

      if (userData.partners) {
        const hasPartner = userData.partners.some(
          (partner) => String(partner.id) === localStoragePartner
        );

        if (localStoragePartner && hasPartner) {
          return of$(setPartnerID(localStoragePartner));
        }

        localStorage.setItem('GOING_PARTNER', userData.partners[0].id);
        return of$(setPartnerID(String(userData.partners[0].id)));
      }

      return EMPTY$;
    })
  );
};

export const handleUsersPermissionsWhenRequest: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(handleUsersPermissions)),
    mergeMap$((action) => {
      return of$(checkAuthorization.request());
    })
  );
};

export const logoutWhenRequest: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(logout)),
    mergeMap$((action) => {
      const localStorageToken = localStorage.getItem('GOING_TOKEN');
      if (localStorageToken) {
        localStorage.removeItem('GOING_TOKEN');
        localStorage.removeItem('GOING_LANGUAGE');
      }
      window.location.reload(false);
      if (isElectron()) {
        window.location.href = window.location.href.split('?')[0];
      }
      return of$(setToken(''), push(routes.login), clearState());
    })
  );
};

export const signInWithFirebaseWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { firebaseApi }
) => {
  return action$.pipe(
    filter$(isActionOf(signInWithFirebase.request)),
    mergeMap$((action) => {
      if (!action.payload) {
        return EMPTY$;
      }

      if (action.payload.oldLoginMethod) {
        return of$(signInWithFirebase.success(action.payload));
      }

      return from$(firebaseApi.signIn(action.payload)).pipe(
        map$((data) => {
          return signInWithFirebase.success({
            ...action.payload,
            firebaseToken: data,
          });
        }),
        catchError$((error) => {
          return of$(
            signInWithFirebase.failure(error),
            addToast(error.message, TYPE_ERROR)
          );
        })
      );
    })
  );
};

export const signInWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(signInWithFirebase.success)),
    mergeMap$((action) => {
      return from$(authorizationApi.signIn(action.payload)).pipe(
        map$((data: ISignInResponse) => {
          return signIn.success(data);
        }),
        catchError$((error) => {
          if (error && error.status === 401) {
            return of$(
              signIn.failure(error),
              addToast(LOGIN_WRONG_CREDENTIALS, TYPE_ERROR)
            );
          }
          return of$(
            signIn.failure(error),
            addToast(error.message, TYPE_ERROR)
          );
        })
      );
    })
  );
};

export const setTokenWhenSigInSuccess: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(signIn.success)),
    mergeMap$((action) => {
      if (!action.payload) {
        return EMPTY$;
      }

      const { token } = action.payload;
      localStorage.setItem('GOING_TOKEN', token);

      return of$(
        encodeJwtToken(token),
        setToken(token),
        setAuthorizationHeader(token)
      );
    })
  );
};

export const setPartnerParamsWhenSignInSuccess: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi }
) => {
  return action$.pipe(
    filter$(isActionOf([setPartnerID, setAuthorizationHeader])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      if (partnerId) {
        return from$(authorizationApi.getPartnerParams(partnerId)).pipe(
          mergeMap$((response) => {
            return of$(setParams(response?.parameters), loginFormIo());
          }),
          catchError$((error) => {
            return of$(addToast(error.message, TYPE_ERROR));
          })
        );
      }
      return EMPTY$;
    })
  );
};

export const redirectToIndexWhenSigInSuccess: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(encodeJwtToken)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const search: ISearch = isElectron()
        ? queryString.parse(window.location.search)
        : queryString.parse(getSearch(state));
      const userData = authorizationApi.encodeSessionToken(action.payload);
      const location = getLocation(state);

      const LOGIN_PARAM = 'form_io_transaction_form_login';
      const PASS_PARAM = 'form_io_transaction_form_pass';
      const LOGIN_FORM_PARAM = 'form_io_transaction_form_login_url';

      let route = routes.index;

      if (userData.permissions) {
        const loginObject = userData.parameters?.find((param) =>
          param.hasOwnProperty(LOGIN_PARAM)
        );
        const passObject = userData.parameters?.find((param) =>
          param.hasOwnProperty(PASS_PARAM)
        );
        const loginFormUrlObject = userData.parameters?.find((param) =>
          param.hasOwnProperty(LOGIN_FORM_PARAM)
        );

        const permissionConsentListView = !!(
          loginObject &&
          passObject &&
          loginFormUrlObject
        );
        const firstRoute = getFirstRoute(
          userData.permissions,
          permissionConsentListView
        );
        if (firstRoute) {
          if (location.pathname === routes.login) {
            route = firstRoute;
          } else {
            route = location.pathname;
          }
        }
      }

      if (!action.payload) {
        return EMPTY$;
      }

      if (search.transactionId) {
        if (!search.error) {
          return of$(
            push(
              fillUrlWithValues(
                routes.summarySuccess,
                ':id',
                search.transactionId
              )
            )
          );
        } else if (search.error === '401') {
          return of$(
            push(
              fillUrlWithValues(routes.summaryFail, ':id', search.transactionId)
            )
          );
        } else if (search.error === '500') {
          return of$(
            push(
              fillUrlWithValues(
                routes.summaryCanceled,
                ':id',
                search.transactionId
              )
            )
          );
        }
      }

      return of$(push(route));
    })
  );
};

export const fetchAuthorisationCasherInfoWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(fetchAuthorizationCasherInfo.request)),
    mergeMap$((action) => {
      if (!action.payload) {
        return EMPTY$;
      }
      return from$(
        authorizationApi.fetchAuthorizationCasherInfo(action.payload)
      ).pipe(
        map$((data: ICasherInfoResponse) => {
          return fetchAuthorizationCasherInfo.success(data);
        }),
        catchError$((error: Error) => {
          return of$(fetchAuthorizationCasherInfo.failure(error));
        })
      );
    })
  );
};

export const setAuthorizationHeaderWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { requestProvider }
) => {
  return action$.pipe(
    filter$(isActionOf(setAuthorizationHeader)),
    withLatestFrom$(state$),
    tap$(([action]) => {
      requestProvider.setAuthorizationHeader(action.payload);
    }),
    mergeMap$(() => EMPTY$)
  );
};

export const checkSessionTokenInWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { requestProvider }
) => {
  return action$.pipe(
    filter$(isActionOf(checkAuthorization.request)),
    mergeMap$(() => {
      return from$(requestProvider.checkAutorotation()).pipe(
        map$((response: Error) => {
          return checkAuthorization.success(response);
        }),
        catchError$((error: Error) => {
          return of$(checkAuthorization.failure(error));
        })
      );
    })
  );
};

export const showRefreshMessageWhenSessionOf: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(checkAuthorization.success)),
    mergeMap$((action) => {
      const errorMessage = catchNewErrorMessage(action.payload);
      if (
        errorMessage &&
        errorMessage.statusCode &&
        errorMessage.statusCode === 401
      ) {
        localStorage.removeItem('GOING_TOKEN');
        localStorage.removeItem('GOING_LANGUAGE');

        return of$(
          addToast(PLEASE_REFRESH_TEXT, TYPE_ERROR),
          checkAuthorization.request(),
          setToken(''),
          push(routes.login)
        );
      } else if (
        errorMessage &&
        errorMessage.message &&
        errorMessage.message.length &&
        errorMessage.statusCode &&
        errorMessage.statusCode >= 400 &&
        errorMessage.statusCode !== 409 &&
        errorMessage.statusCode !== 426
      ) {
        return of$(
          addToast(errorMessage.message, TYPE_ERROR),
          checkAuthorization.request()
        );
      }
      return of$(checkAuthorization.request());
    })
  );
};

export const removeAuthorizationHeaderWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { requestProvider }
) => {
  return action$.pipe(
    filter$(isActionOf(removeAuthorizationHeader)),
    tap$(() => {
      requestProvider.removeAuthorizationHeader();
    }),
    mergeMap$(() => EMPTY$)
  );
};

export const checkTokenWhenLocationChanged: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    withLatestFrom$(state$),
    filter$(([_, state]) => getLocation(state).pathname !== routes.login),
    mergeMap$(([action, state]) => {
      const appToken = getAppToken(state);

      const localStorageToken = localStorage.getItem('GOING_TOKEN');

      if (!appToken) {
        if (localStorageToken) {
          return of$(
            signIn.success({
              token: localStorageToken,
            })
          );
        }
        return of$(push(routes.login));
      }

      if (appToken !== localStorageToken) {
        return of$(
          signIn.success({
            token: appToken,
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const runSessionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(runSession)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      const { userId, startCash, printerId } = action.payload;

      const request: IRunSessionRequest = {
        printerId,
        startCash,
        userId,
      };

      return from$(authorizationApi.runSession(request)).pipe(
        mergeMap$(() => {
          return of$(
            refreshPage(),
            refreshToken.request(),
            addToast(START_SESSION_TEXT, TYPE_SUCCESS),
            startSessionV2.request(request)
          );
        }),
        catchError$((error) => {
          const { message } = catchNewErrorMessage(error);
          return of$(addToast(message, TYPE_ERROR));
        })
      );
    })
  );
};

export const clearSessionByPrinterIdWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(endSessionByPrinterId)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const printers = getPrintersSelector(state);
      const foundedPrinterFirmware = printers.find(
        (printer) => printer.id === action.payload
      )?.firmware;

      return from$(
        authorizationApi.clearSessionByPrinterId(action.payload)
      ).pipe(
        mergeMap$((data) => {
          if (
            foundedPrinterFirmware === '2.0.1' ||
            foundedPrinterFirmware === '2.0.0'
          ) {
            return of$(
              fetchSession.request({
                printerId: action.payload,
                sessionExternalId: data.cashierSessionExternalId,
              })
            );
          }
          return of$(endPrinterSessionById.request(action.payload));
        }),
        catchError$((error) => {
          const { message } = catchNewErrorMessage(error);
          return of$(
            endPrinterSessionById.failure(new Error(message)),
            addToast(message, TYPE_ERROR)
          );
        })
      );
    })
  );
};

export const fetchSessionByPrinterIdWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(fetchSession.request)),
    mergeMap$((action) => {
      return from$(
        authorizationApi.getPaymentSession(action.payload.sessionExternalId)
      ).pipe(
        mergeMap$((data) => {
          return of$(
            endPrinterSessionByIdV2.request({
              ...data[0],
              printerId: action.payload.printerId,
            })
          );
        }),
        catchError$((error) => {
          const { message } = catchNewErrorMessage(error);
          return of$(
            endPrinterSessionById.failure(new Error(message)),
            addToast(message, TYPE_ERROR)
          );
        })
      );
    })
  );
};

export const clearSessionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi, printerApi }
) => {
  return action$.pipe(
    filter$(isActionOf(endSession)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);

      return from$(authorizationApi.clearSession()).pipe(
        mergeMap$(() => {
          return from$(printerApi.getPrinters(partnerId)).pipe(
            mergeMap$((data) => {
              return of$(
                getPrinters.success(data),
                refreshPage(),
                getPaymentSession.request(),
                addToast(END_SESSION_TEXT, TYPE_SUCCESS)
              );
            }),
            takeUntil$(
              action$.pipe(
                filter$(isOfType(LOCATION_CHANGE)),
                tap$(() => printerApi.cancelGetPrinters())
              )
            ),
            catchError$((error: Error) => {
              return of$(getPrinters.failure(error));
            })
          );
        }),
        catchError$((error) => {
          return of$(refreshToken.request());
        })
      );
    })
  );
};

export const getSessionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi, printerApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPaymentSession.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { session } = getUserInfo(state);
      const userPrinter = getUserPrinter(state);
      const printers = getPrintersSelector(state);

      if (!session || !userPrinter) {
        printerApi.reportBug(
          new Error(
            `[END_SESSION]: Empty printer url, printerId: ${session?.printer_id}`
          ),
          JSON.stringify(printers),
          `[END_SESSION]: Empty printer url, printerId: ${session?.printer_id}`,
          JSON.stringify(session)
        );
        return of$(
          addToast(END_SESSION_PRINT_ERROR_TEXT, TYPE_ERROR),
          printerPayment.failure(new Error(END_SESSION_PRINT_ERROR_TEXT))
        );
      }

      const { session_external_id } = session;

      return from$(
        authorizationApi.getPaymentSession(session_external_id)
      ).pipe(
        map$((response: IPaymentSessionResponse[]) => {
          if (response && response.length) {
            if (
              userPrinter.firmware === '2.0.0' ||
              userPrinter.firmware === '2.0.1'
            ) {
              return endPrinterSessionV2.request(response[0]);
            }
            return endPrinterSession.request(response[0]);
          }

          return printerPayment.failure(new Error(SOMETHING_WENT_WRONG_TEXT));
        }),
        catchError$((error: Error) => {
          const { message } = catchNewErrorMessage(error);

          return of$(
            printerPayment.failure(error),
            addToast(message, TYPE_ERROR)
          );
        })
      );
    })
  );
};

export const updatePaymentSessionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { authorizationApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchPrinterPayment)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { session } = getUserInfo(state);

      if (!action.payload || !action.payload.cash || !session) {
        return EMPTY$;
      }

      const { session_external_id: sessionUuid } = session;
      const { cash, action: actionType } = action.payload;

      const amount = Math.round(cash * 100);

      const paymentPayload: IPaymentSessionPayload = {
        actionType: actionType === 'income' ? 'income' : 'outcome',
        amount,
        sessionUuid,
      };

      return from$(authorizationApi.updatePaymentSession(paymentPayload)).pipe(
        map$(() => {
          return printerPayment.request(action.payload);
        }),
        catchError$((error: Error) => {
          return of$(printerPayment.failure(error));
        })
      );
    })
  );
};
