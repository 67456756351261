import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none',
  },
  item: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

export default useStyles;
