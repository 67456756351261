import React from 'react';

import { Grid, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';

const UserSettings = () => {
  const { t, i18n } = useTranslation('components', {
    keyPrefix: 'Settings.UserSettings',
  });

  const handleChange = () => {
    const language = i18n.language === 'en' ? 'pl' : 'en';
    i18n.changeLanguage(language);
    localStorage.setItem('GOING_LANGUAGE', language);
  };

  return (
    <LabelContentContainer>
      <Grid container={true} alignItems="center">
        <Grid item={true}>
          <Switch
            checked={i18n.language === 'en'}
            onChange={handleChange}
            color="primary"
            size="medium"
          />
        </Grid>
        <Grid item={true}>
          <Typography variant="body1">{t('switchLanguage')}</Typography>
          <Typography variant="caption" color="textSecondary">
            {t('switchLanguageHelper')}
          </Typography>
        </Grid>
      </Grid>
    </LabelContentContainer>
  );
};

export default UserSettings;
