import React, { useEffect } from 'react';

import ExitToApp from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Container,
  FormControl,
  FormHelperText,
  Hidden,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import colors from '@Compo/App/colors';
import DrawerOpen from '@Compo/DrawerOpen';
import SessionPanel from '@Compo/SessionPanel';

import { IAppBarBookedProps } from './AppBarBooked.types';

const useStyles = makeStyles((theme) => ({
  icon: {
    margin: '0 .625rem',
  },
  leftSide: {
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    padding: 0,
  },
  rightSide: {
    flexGrow: 0,
    minWidth: 'max-content',
    padding: 0,
    width: 'auto',
  },
  select: {
    minWidth: '13.75rem',
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
    },
  },
  toggle: {
    marginRight: 16,
  },
  toolbar: {
    backgroundColor: colors.other.backdrop,
    padding: '.5rem 1.5rem .5rem 1.5rem',
    [theme.breakpoints.down('lg')]: {
      padding: '.5rem 0.75rem .5rem 0.75rem',
    },
  },
}));

const AppBarBooked = ({
  logout,
  partners,
  partnerId,
  setPartnerID,
  toggleMenu,
}: IAppBarBookedProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'layout.AppBarBooked',
  });

  const classes = useStyles();

  const catchPartnerId = (partner: SelectChangeEvent<string>) => {
    const value = partner.target.value;
    setPartnerID(value);
    localStorage.setItem('GOING_PARTNER', value);
    window.location.reload(false);
  };

  return (
    <AppBar>
      <Toolbar className={classes.toolbar}>
        <Container className={classes.leftSide} maxWidth={false}>
          <Hidden only={['lg', 'xl']}>
            <IconButton
              size="small"
              color="secondary"
              onClick={toggleMenu}
              className={classes.toggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
          <FormControl variant="standard">
            {partnerId && partners && partners.length && (
              <Select
                variant="standard"
                className={classes.select}
                value={partnerId}
                onChange={catchPartnerId}
                defaultValue={partnerId || partners?.[0].id}
              >
                {partners?.map((partner) => (
                  <MenuItem key={partner.id} value={partner.id}>
                    {partner.name}
                  </MenuItem>
                ))}
              </Select>
            )}
            <FormHelperText>{t('partnerHelperText')}</FormHelperText>
          </FormControl>
        </Container>
        <Container className={classes.rightSide}>
          <SessionPanel />
          <DrawerOpen />
          <IconButton
            size="small"
            color="secondary"
            className={classes.icon}
            onClick={logout}
          >
            <ExitToApp />
          </IconButton>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarBooked;
