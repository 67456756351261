import React, { useContext, useEffect } from 'react';

import { Grid, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { clearContext } from '@Compo/Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import Loader from '@Compo/layout/Loader/Loader';
import BigButtonSalesTicket from '@Compo/reusable/BigButtonSalesTicket';
import { Colors } from '@Constants/Colors';
import { TypographyVariant } from '@Constants/Variants/Typography';
import { IZagrywkiBasket } from '@Model/basket/types';
import { IPoolResponse } from '@Services/$pools-api/types';

import useStyles from './EventsPools.styles';
import { IEventsPoolsProps } from './EventsPools.types';

const EventsPools = ({
  isLoading,
  pools,
  selectedPool,
  selectPool,
  poolHasSeatsIo,
  selectedEvent,
  setSeatsIo,
  poolRef,
}: IEventsPoolsProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Add.components.FormLayout.EventsPools',
  });

  const classes = useStyles();
  const BagState = useContext(clearContext);

  const isSameCurrency = (poolCurrency: string): boolean =>
    BagState?.basketItems?.length
      ? BagState?.basketItems?.[0]?.currency === poolCurrency
      : true;

  useEffect(() => {
    if (poolHasSeatsIo) {
      setSeatsIo(true);
    }
  }, [poolHasSeatsIo]);

  if (isLoading) {
    return (
      <Grid ref={poolRef} container={true} className={classes.loaderContainer}>
        <Loader />
      </Grid>
    );
  }

  if (!pools || poolHasSeatsIo) {
    return null;
  }

  const handleSelectPool = (pool: IPoolResponse) => {
    const foundedPool = BagState?.basketItems.find(
      (item) => item.poolId === pool.id
    );
    if (foundedPool) {
      BagState?.updateItem({
        ...foundedPool,
        numberOfPeople: foundedPool.numberOfPeople + 1,
      });
    } else if (selectedPool?.id === pool.id) {
      BagState?.addToBag({
        calculatePricePerPerson: true,
        currency: pool.currency,
        dateTime: pools.runDate,
        hasDependency: pool.hasDependency,
        id: Math.random(),
        isEvent: true,
        maxNumberOfPeople: pool.ticketsNumLimit,
        numberOfPeople: 1,
        poolId: pool.id,
        price: pool.price,
        priceTypeName: pool.poolDescription.titlePL,
        title: selectedEvent?.titlePl || '',
      } as IZagrywkiBasket);
    } else {
      selectPool(pool);
    }
  };

  return (
    <>
      <Typography
        variant={TypographyVariant.body2}
        color={Colors.textSecondary}
        className={classes.subtitle}
      >
        {t('pools')}
      </Typography>
      <Grid container={true} spacing={2} ref={poolRef}>
        {pools.pools.map((pool) => (
          <Grid xs={12} sm={6} md={4} item={true} key={pool.id}>
            <Tooltip
              title={t('clickAgain') as string}
              open={selectedPool?.id === pool.id}
              placement="top"
              classes={{ tooltip: classes.tooltip }}
            >
              <BigButtonSalesTicket
                variant="contained"
                size="large"
                color="secondary"
                selected={selectedPool?.id === pool.id}
                onClick={() => handleSelectPool(pool)}
                disabled={!isSameCurrency(pool.currency)}
              >
                <Grid
                  container={true}
                  spacing={1}
                  direction="column"
                  alignItems="flex-start"
                >
                  <Grid item={true} xs={12}>
                    <Typography
                      component={'div'}
                      variant={TypographyVariant.button}
                      align="left"
                    >
                      {pool.poolDescription.titlePL}
                    </Typography>
                    <Typography
                      component={'div'}
                      variant={TypographyVariant.button}
                      align="left"
                    >
                      {moment(pools.runDate).format('DD.MM.YYYY')}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Typography
                      component={'div'}
                      variant={TypographyVariant.caption}
                      className={classes.lowercase}
                      align="left"
                    >
                      {pool.poolDescription.descriptionPL}
                    </Typography>
                  </Grid>
                  {pool.available && (
                    <Grid item={true} xs={12}>
                      <Typography
                        component={'div'}
                        variant={TypographyVariant.caption}
                        className={classes.lowercase}
                        align="left"
                      >
                        {`${t('available')} ${pool.available}`}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item={true} xs={12}>
                    <Typography
                      variant={TypographyVariant.h6}
                      className={classes.lowercase}
                      align="left"
                    >
                      {pool.price} {pool.currency}
                    </Typography>
                  </Grid>
                </Grid>
              </BigButtonSalesTicket>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default EventsPools;
