import { postReservation } from '@Model/reservation/actions';
import {
  getReservationDetails,
  updateReservation,
} from '@Model/reservations/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetError, resetLoading, setLoading } from './../actions';
import { RESERVATION } from './../constants/constants';

export const setUpStateWhenReservationFetchRequested: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getReservationDetails.request,
        updateReservation.request,
        postReservation.request,
      ]),
    ),
    mergeMap$(() => [resetError(RESERVATION), setLoading(RESERVATION)]),
  );
};

export const setLoaderWhenReservationFetchFinished: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          getReservationDetails.success,
          getReservationDetails.failure,
          updateReservation.success,
          updateReservation.failure,
          postReservation.success,
          postReservation.failure,
        ],
        action,
      ),
    ),
    map$(() => resetLoading(RESERVATION)),
  );
};

export const setErrorOnReservationFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getReservationDetails.failure,
        updateReservation.failure,
        postReservation.failure,
      ]),
    ),
    map$((action) => addToast(action.payload.message, TYPE_ERROR)),
  );
};
