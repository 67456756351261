import React from 'react';

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import { RefundStatus } from '@Services/$refunds-registration-api/types';

import {
  availabilities,
  getAvailabilityName,
  statuses,
} from './../../availabilitiesStatusChange';
import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = ({
  handleSubmit,
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
  metadata,
  initialValues,
}: IFormLayoutProps) => {
  const { t: tAvailabilities } = useTranslation('components', {
    keyPrefix: 'RefundsRegistration.availabilitiesStatusChange',
  });

  const { t } = useTranslation('components', {
    keyPrefix: 'RefundsRegistration.components.FormLayout',
  });

  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <LabelContentContainer>
            <FormControl
              variant="standard"
              fullWidth={true}
              error={!!(touched.status?.code && errors.status?.code)}
            >
              <InputLabel>
                {(touched.status?.code && errors.status?.code) || t('status')}
              </InputLabel>
              <Select
                id="status"
                value={values.status?.code}
                onChange={handleChange}
                fullWidth={true}
                name="status[code]"
                onBlur={handleBlur}
              >
                {!statuses.includes(initialValues.status.code) && (
                  <MenuItem value={initialValues.status.code}>
                    {initialValues.status.name}
                  </MenuItem>
                )}
                {statuses.map((status) => (
                  <MenuItem
                    key={status}
                    disabled={
                      !availabilities[initialValues.status.code]?.includes(
                        status
                      ) && status !== initialValues.status.code
                    }
                    value={status}
                  >
                    {getAvailabilityName(status, tAvailabilities)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{t('statusHelper')}</FormHelperText>
            </FormControl>
          </LabelContentContainer>
        </Grid>
        <Grid item={true} xs={12}>
          <LabelContentContainer>
            <FormControl
              variant="standard"
              fullWidth={true}
              error={!!(touched.refundType?.code && errors.refundType?.code)}
            >
              <InputLabel>
                {(touched.refundType?.code && errors.refundType?.code) ||
                  t('refundType')}
              </InputLabel>
              <Select
                variant="standard"
                id="refundType"
                value={values.refundType?.code}
                onChange={handleChange}
                defaultValue={'transfer'}
                fullWidth={true}
                name="refundType[code]"
                onBlur={handleBlur}
                disabled={initialValues.status.code !== RefundStatus.accepted}
              >
                {metadata.refundTypes.map((refundType) => (
                  <MenuItem key={refundType.code} value={refundType.code}>
                    {refundType.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{t('refundTypeHelper')}</FormHelperText>
            </FormControl>
          </LabelContentContainer>
        </Grid>
        <Grid item={true} xs={12}>
          <LabelContentContainer>
            <TextField
              variant="standard"
              id="bankAccount"
              label={
                (touched.bankAccount && errors.bankAccount) || t('bankAccount')
              }
              helperText={t('bankAccountHelper')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.bankAccount || ''}
              name="bankAccount"
              error={!!(touched.bankAccount && errors.bankAccount)}
              fullWidth={true}
            />
          </LabelContentContainer>
        </Grid>
        <Grid item={true} xs={12}>
          <LabelContentContainer>
            <TextField
              id="supportInfo"
              label={
                (touched.supportInfo && errors.supportInfo) || t('supportInfo')
              }
              helperText={t('supportInfoHelper')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.supportInfo || ''}
              name="supportInfo"
              error={!!(touched.supportInfo && errors.supportInfo)}
              fullWidth={true}
              multiline={true}
              variant={'outlined'}
            />
          </LabelContentContainer>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormLayout;
