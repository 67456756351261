import React, { useCallback, useEffect } from 'react';

import { Grid, TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

import GoingPaper from '@Compo/layout/Paper/GoingPaper';

import Loader from '../layout/Loader/Loader';
import ConsentsListTable from './components/ConsentsListTable';
import styles from './ConsentsList.module.scss';
import { IConsentsListProps } from './ConsentsList.types';

const ConsentsList = ({
  mounted,
  isLoading,
  limit,
  getFormIOUsers,
  setEmail,
  setFirstName,
  setLastName,
  email,
  firstName,
  lastName,
  date,
  setDate,
}: IConsentsListProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'ConsentsList',
  });

  useEffect(() => {
    mounted(limit);

    return () => {
      search.cancel();
    };
  }, []);

  const search = useCallback(
    debounce(() => getFormIOUsers(), 2000),
    []
  );

  const catchHandleSearchByEmail = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;

    setEmail(value);
    search();
  };

  const catchHandleSearchByFirstName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;

    setFirstName(value);
    search();
  };

  const catchHandleSearchByLastName = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;

    setLastName(value);
    search();
  };

  return (
    <GoingPaper title={t('title')}>
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12}>
          <TextField
            variant="standard"
            id="search"
            label={t('searchEmail')}
            helperText={t('searchEmailHelper')}
            value={email}
            onChange={catchHandleSearchByEmail}
            name="search"
            fullWidth={true}
            autoComplete="off"
            data-testid="emailConsentList"
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant="standard"
            id="search"
            label={t('searchFirstName')}
            helperText={t('searchFirstNameHelper')}
            value={firstName}
            onChange={catchHandleSearchByFirstName}
            name="search"
            fullWidth={true}
            autoComplete="off"
          />
        </Grid>
        <Grid item={true} xs={12}>
          <TextField
            variant="standard"
            id="search"
            label={t('searchLastName')}
            helperText={t('searchLastNameHelper')}
            value={lastName}
            onChange={catchHandleSearchByLastName}
            name="search"
            fullWidth={true}
            autoComplete="off"
          />
        </Grid>
        <Grid item={true} xs={12}>
          {isLoading ? (
            <div className={styles.loader} data-testid="consentListLoader">
              <Loader />
            </div>
          ) : (
            <ConsentsListTable />
          )}
        </Grid>
      </Grid>
    </GoingPaper>
  );
};

export default ConsentsList;
