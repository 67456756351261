import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  mainContainer: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
