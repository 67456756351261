import React from 'react';

import { Dialog } from '@mui/material';

import Form from './../Form';
import { IModalProps } from './Modal.types';

const Modal = ({ showModal, closeModal }: IModalProps) => {
  return (
    <Dialog
      open={showModal}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <Form />
    </Dialog>
  );
};

export default Modal;
