import { getType } from 'typesafe-actions';

import { IAction, IRuleTypesReducer } from '../types';

import {
  changePageOrCount,
  getRuleTypes,
  selectPriceType,
} from '../actions/index';

const initialState: IRuleTypesReducer = {
  count: 20,
  items: [],
  page: 1,
  selectedPriceType: null,
  totalCount: 0,
};

const productsReducer = (
  state: IRuleTypesReducer = initialState,
  action: IAction
): IRuleTypesReducer => {
  switch (action.type) {
    case getType(getRuleTypes.success):
      return {
        ...state,
        items: action.payload.items,
        totalCount: action.payload.totalCount,
      };
    case getType(selectPriceType):
      return {
        ...state,
        selectedPriceType: action.payload,
      };
    case getType(changePageOrCount):
      return {
        ...state,
        count: action.payload.count,
        page: action.payload.page,
      };

    default:
      return state;
  }
};

export default productsReducer;
