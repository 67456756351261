import {
  boolean,
  BooleanSchema,
  date,
  DateSchema,
  number,
  NumberSchema,
  object,
  ObjectSchema,
  string,
  StringSchema,
} from 'yup';

import { IAddFormValidators } from './Add.types';

const REQUIRED_TEXT = 'Pole wymagane';
const EMAIL_ERROR_TEXT = 'Adres nieprawidłowy';
const COMPANY_NAME_IS_REQUIRED_VALIDATOR_TEXT = 'Nazwa firmy jest wymagana';
const ZIP_CODE_REQUIRED_VALIDATOR_TEXT = 'Kod pocztowy jest wymagany';
const CITY_IS_REQUIRED_VALIDATOR_TEXT = 'Miasto jest wymagane';
const NIP_INCORRECT_TEXT = 'Nip nieprawidłowy';
const ZIP_CODE_IS_INCORRECT_VALIDATOR_TEXT = 'Poprawny format to ##-###';
const PAYMENT_ADVANCE_AMOUNT_VALIDATOR_TEXT =
  'Ta kwota jest większa, niż pozostała do opłacenia';

const isValidNip = (nip: string): boolean => {
  if (typeof nip !== 'string') {
    return true;
  }

  nip = nip.replace(/[\ \-]/gi, '');

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  // tslint:disable-next-line: radix
  const controlNumber = parseInt(nip.substring(9, 10));
  const weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    // tslint:disable-next-line: radix
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }

  return sum % 11 === controlNumber;
};

const reZipCode = /^[0-9]{2}-[0-9]{3}$/;
export type IValidationSchema =
  | StringSchema<string>
  | DateSchema<Date>
  | BooleanSchema<boolean>
  | NumberSchema<number | null>
  | ObjectSchema<object | null>;

const invoiceGapsSchema = (func: IValidationSchema, error: string) => {
  return {
    is: true,
    otherwise: func,
    then: func.required(error),
  };
};

const configuration = (maxAdvanceAmount: number): IAddFormValidators => ({
  city: string().when(
    'invoiceCheckbox',
    invoiceGapsSchema(string(), CITY_IS_REQUIRED_VALIDATOR_TEXT)
  ),
  companyName: string().when(
    'invoiceCheckbox',
    invoiceGapsSchema(string(), COMPANY_NAME_IS_REQUIRED_VALIDATOR_TEXT)
  ),
  houseNumber: object().nullable(),
  nip: string().when('invoiceCheckbox', {
    is: true,
    otherwise: string(),
    then: string().test('is-nip', NIP_INCORRECT_TEXT, (value) =>
      isValidNip(value)
    ),
  }),
  street: string(),
  zipCode: string()
    .when(
      'invoiceCheckbox',
      invoiceGapsSchema(string(), ZIP_CODE_REQUIRED_VALIDATOR_TEXT)
    )
    .matches(reZipCode, ZIP_CODE_IS_INCORRECT_VALIDATOR_TEXT),

  date: date().when(
    'reservationCheckbox',
    invoiceGapsSchema(date(), REQUIRED_TEXT)
  ),
  discount: string(),
  discountCode: string().nullable(),

  email: string()
    .email(EMAIL_ERROR_TEXT)
    .when('withFromData', {
      is: true,
      otherwise: string(),
      then: string().required(REQUIRED_TEXT),
    }),
  firstName: string().when('withFromData', {
    is: true,
    otherwise: string(),
    then: string().required(REQUIRED_TEXT),
  }),
  lastName: string().when('withFromData', {
    is: true,
    otherwise: string(),
    then: string().required(REQUIRED_TEXT),
  }),

  happeningId: number()
    .nullable()
    .when(
      'reservationCheckbox',
      invoiceGapsSchema(number().nullable(), REQUIRED_TEXT)
    ),
  paymentAdvanceAmount: number()
    .nullable()
    .max(maxAdvanceAmount, PAYMENT_ADVANCE_AMOUNT_VALIDATOR_TEXT),

  shortenedInvoice: string().when('isShortenedInvoice', {
    is: true,
    otherwise: string(),
    then: string()
      .required(REQUIRED_TEXT)
      .test('is-nip', NIP_INCORRECT_TEXT, (value) => isValidNip(value)),
  }),

  slot: object()
    .nullable()
    .when(
      'reservationCheckbox',
      invoiceGapsSchema(object().nullable(), REQUIRED_TEXT)
    ),
  spaceId: number()
    .nullable()
    .when(
      'reservationCheckbox',
      invoiceGapsSchema(number().nullable(), REQUIRED_TEXT)
    ),
  upsell: boolean(),
});

export const AddFormSchema = (maxAdvanceAmount: number | null) =>
  object().shape(configuration(maxAdvanceAmount || 0));
