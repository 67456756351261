import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  active: {
    backgroundColor: `${theme.palette.secondary.dark}!important`,
  },
  content: {
    overflowY: 'hidden',
  },
  form: {
    overflowY: 'auto',
  },
  loader: {
    minHeight: 200,
    position: 'relative',
  },
}));

export default useStyles;
