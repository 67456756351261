import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { get } from '@Model/authorization/selectors';
import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { allPermissions } from '@Model/state/constants';
import { mounted } from '@Model/statics/actions';
import { get as getStaticsSelector } from '@Model/statics/selectors';

import Statics from './Statics.component';
import { IStaticFromState, IStaticsFromDispatch } from './Statics.types';

const mapStateToProps = (state: _Store.IState): IStaticFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    partnerId: getHappeningPartnerId(state),
    permissionStaticsView: permissions.includes(
      allPermissions.access_analytics_view
    ),
    quickSightUrl: getStaticsSelector(state).url,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IStaticsFromDispatch => ({
  mounted: () => dispatch(mounted()),
});

export default connect<
  IStaticFromState,
  IStaticsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Statics);
