import React from 'react';

import { routes } from '@/routes/routes';
import { pdf } from '@react-pdf/renderer';
import { createMatchSelector, LOCATION_CHANGE } from 'connected-react-router';
import moment from 'moment';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import PdfDocument from '@Compo/ReportSeatsIo/components/PdfDocument';
import { getSingleRundateData } from '@Model/going/rundates/selectors';

import { generatePdf, getSeatsIoReport, mounted } from '../actions';
import { IMatch } from '../types';

export const fetchSeatsIoReportWhenMounted: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const matchSelector = createMatchSelector([
        routes.rundateSeatsIoReport,
        routes.rundateEdit,
      ]);
      const match: IMatch | null = matchSelector(state);

      if (match && match.params && match.params.id) {
        return of$(
          getSeatsIoReport.request({
            poolId: Number(match.params.poolId),
            rundateId: Number(match.params.id),
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const generateSeatsIoReportWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(generatePdf)),
    map$((action) => getSeatsIoReport.request(action.payload))
  );
};

export const getSeatsIoReportWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { seatsIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSeatsIoReport.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const rundate = getSingleRundateData(state);

      return from$(
        seatsIoApi.getSeatsIoReport(
          action.payload.rundateId,
          action.payload.poolId
        )
      ).pipe(
        map$((data) => {
          if (action.payload.download) {
            pdf(<PdfDocument rundate={rundate} reports={data} />)
              .toBlob()
              .then((dataPdf) => {
                const fileURL = URL.createObjectURL(dataPdf);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = `${`Raport_${
                  rundate.event.titlePL
                }_${moment().format('YYYY-MM-DD_hh:mm:ss')}.pdf`}`;
                link.click();
              });
          }

          return getSeatsIoReport.success(data);
        }),
        catchError$((error) => of$(getSeatsIoReport.failure(error))),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => seatsIoApi.cancelSeatsIo())
          )
        )
      );
    })
  );
};
