import React from 'react';

import Grid from '@Compo/layout/Grid';
import Rundates from '@Compo/Rundates';

const RundatesPage = () => (
  <Grid>
    <Rundates />
  </Grid>
);

export default RundatesPage;
