import {
  IDelayedTransactionsReducer,
  TTransactionType,
} from '@Model/reservations/types';
import _Store from '@Store';
import { createSelector } from 'reselect';
import getDelayedTransactions from './getDelayedTransactions';

const getTransactionsType = createSelector<
  _Store.IState,
  IDelayedTransactionsReducer,
  TTransactionType
>(
  [getDelayedTransactions],
  (transaction) => transaction.selectedTransactionType
);

export default getTransactionsType;
