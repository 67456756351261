import { IRuleType } from '@Model/priceTypes/types';
import { IFormValuesProps } from './AddNewPriceTypeModal.types';

export const getInitialValues = (data?: IRuleType): IFormValuesProps => {
  if (data) {
    return {
      counter: data.counter || 1,
      online: data.online || false,
      onsite: data.onsite || false,
      type: data.type || '-',
      value: data.value || 0,
    };
  }

  return {
    counter: 1,
    online: true,
    onsite: true,
    type: '-',
    value: 0,
  };
};
