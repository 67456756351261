import { createSelector } from 'reselect';

import _Store from '@Store';

import { ITransaction } from '@Model/reservations/types';

import { ITransactionsReducer } from '../types';
import get from './get';

const getActiveTransaction = createSelector<
  _Store.IState,
  ITransactionsReducer,
  ITransaction | null
>([get], (transactions) => transactions.activeTransaction);

export default getActiveTransaction;
