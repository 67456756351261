import { setDevice, setSizes } from '@Model/app/actions';
import { IActionDevice } from '@Model/app/types';
import _Store from '@Store';
import { filter as filter$, map as map$ } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

export const setDeviceWhenSizesChangedEpic: _Store.IEpic = (
  action$,
  _,
  { deviceDetector },
) => {
  return action$.pipe(
    filter$(isActionOf(setSizes)),
    map$((action) => {
      return setDevice(deviceDetector.getDevice(
        action.payload,
      ) as IActionDevice);
    }),
  );
};
