import { IReservationsDetailsListReducer } from '@Model/reservations/types';
import _Store from '@Store';
import { createSelector } from 'reselect';
import getDetailsList from './getDetailsList';

const isReservationDetailsListSelected = createSelector<
  _Store.IState,
  IReservationsDetailsListReducer,
  boolean
>(
  getDetailsList,
  (details) => details !== null,
);

export default isReservationDetailsListSelected;
