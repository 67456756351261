import React from 'react';

import { Grid, TextField } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import MuiDatePicker from '@Compo/reusable/MuiDatePicker';

import { IFormLayoutProps } from './../../FormLayout.types';
import useStyles from './SalesAvailability.styles';

const SalesAvailability = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'Rundates.Pools.components.FormLayout.components.SalesAvailability',
  });

  const classes = useStyles();

  return (
    <Grid item={true} xs={12}>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <Grid container={true} spacing={4}>
            <Grid item={true} xs={12} md={6}>
              <LabelContentContainer>
                <MuiDatePicker
                  name="startDate"
                  disableToolbar={true}
                  fullWidth={true}
                  variant="standard"
                  format="DD.MM.yyyy"
                  label={
                    (touched.startDate && errors.startDate) || t('startDate')
                  }
                  helperText={t('startDateHelper')}
                  value={values.startDate}
                  onChange={(date) => setFieldValue('startDate', date)}
                  onBlur={handleBlur}

                />
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <LabelContentContainer>
                <TextField
                  variant="standard"
                  type="time"
                  id="startTime"
                  label={
                    (touched.startTime && errors.startTime) || t('startTime')
                  }
                  helperText={t('startTimeHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.startTime}
                  name="startTime"
                  error={!!(touched.startTime && errors.startTime)}
                  fullWidth={true}
                  InputProps={{
                    className: cn(classes.defaultTimeInput, classes.time),
                  }}
                />
              </LabelContentContainer>
            </Grid>
          </Grid>
        </Grid>

        <Grid item={true} xs={12}>
          <Grid container={true} spacing={4}>
            <Grid item={true} xs={12} md={6}>
              <LabelContentContainer>
                <MuiDatePicker
                  name="endDate"
                  disableToolbar={true}
                  fullWidth={true}
                  variant="standard"
                  format="DD.MM.yyyy"
                  label={(touched.endDate && errors.endDate) || t('endDate')}
                  helperText={t('endDateHelper')}
                  value={values.endDate}
                  onChange={(date) => setFieldValue('endDate', date)}
                  onBlur={handleBlur}

                />
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12} md={6}>
              <LabelContentContainer>
                <TextField
                  variant="standard"
                  type="time"
                  id="endTime"
                  label={(touched.endTime && errors.endTime) || t('endTime')}
                  helperText={t('endTimeHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.endTime}
                  name="endTime"
                  error={!!(touched.endTime && errors.endTime)}
                  fullWidth={true}
                  InputProps={{
                    className: cn(classes.defaultTimeInput, classes.time),
                  }}
                />
              </LabelContentContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SalesAvailability;
