import axios, { CancelTokenSource } from 'axios';
import { Region, SeatsioClient } from 'seatsio';

import config from '@Config';
import { catchNewErrorMessage } from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';

import {
  ICategory,
  ISeatsIoChartResponse,
  ISeatsIoChartsResponse,
  ISeatsIoEventResponse,
  ISeatsIoReportResponse,
} from './types';

const client = new SeatsioClient(Region.EU(), config.seats.secretKey);

class SeatsIoApi {
  private static getSeatsIoReportUrl(
    rundateId: number,
    poolId?: number
  ): string {
    return poolId
      ? `${config.api.cmsServices}seats-io/report/${rundateId}/${poolId}`
      : `${config.api.cmsServices}seats-io/report/${rundateId}`;
  }

  private static getSeatsIoChartsUrl(rundateId: number): string {
    return `${config.api.cmsServices}seats-io/charts/${rundateId}`;
  }

  private static getAssignSeatsIoUrl(rundateId: number): string {
    return `${config.api.cmsServices}seats-io/event/${rundateId}`;
  }

  private static getDeleteSeatsIoUrl(rundateId: number): string {
    return `${config.api.cmsServices}seats-io/event/${rundateId}`;
  }

  private cancelSeats?: CancelTokenSource;

  public getSeatsIoReport(
    rundateId: number,
    poolId?: number
  ): Promise<ISeatsIoReportResponse[]> {
    return new Promise<ISeatsIoReportResponse[]>((resolve, reject) => {
      this.cancelSeats = axios.CancelToken.source();
      axios
        .get(SeatsIoApi.getSeatsIoReportUrl(rundateId, poolId), {
          cancelToken: this.cancelSeats.token,
        })
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public getSeatsIoCharts(
    rundateId: number,
    count?: number,
    nextPageStartsAfter?: number,
    previousPageEndsBefore?: number,
    search?: string
  ): Promise<ISeatsIoChartsResponse> {
    return new Promise<ISeatsIoChartsResponse>((resolve, reject) => {
      this.cancelSeats = axios.CancelToken.source();
      axios
        .get(SeatsIoApi.getSeatsIoChartsUrl(rundateId), {
          cancelToken: this.cancelSeats.token,
          params: {
            count,
            'filter[nextPageStartsAfter]': nextPageStartsAfter,
            'filter[previousPageEndsBefore]': previousPageEndsBefore,
            'filter[search]': search?.length ? search : undefined,
          },
        })
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public saveSeatsIoEventChart(
    rundateId: number,
    chartKey: string
  ): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelSeats = axios.CancelToken.source();

      axios
        .post(
          SeatsIoApi.getAssignSeatsIoUrl(rundateId),
          { chartKey },
          {
            cancelToken: this.cancelSeats.token,
          }
        )
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public deleteSeatsIoEventChart(rundateId: number): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelSeats = axios.CancelToken.source();

      axios
        .delete(SeatsIoApi.getDeleteSeatsIoUrl(rundateId), {
          cancelToken: this.cancelSeats.token,
        })
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public fetchCategoryKeysByChart(key: string): Promise<ICategory[]> {
    return new Promise<ICategory[]>((resolve, reject) => {
      client.charts
        .retrieveWithEvents(key)
        .then((data: ISeatsIoChartResponse) =>
          client.events
            .retrieve(data.events[0]?.key)
            .then((response: ISeatsIoEventResponse) =>
              resolve(response.categories)
            )
        )
        .catch((error: any) => reject(catchNewErrorMessage(error)));
    });
  }

  public cancelSeatsIo() {
    if (this.cancelSeats) {
      this.cancelSeats.cancel();
      this.cancelSeats = undefined;
    }
  }
}

export default new SeatsIoApi();
