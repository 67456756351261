import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import KeyboardIcon from '@mui/icons-material/Keyboard';
import { IconButton } from '@mui/material';
import Keyboard from 'react-simple-keyboard';

import { Events } from '@Constants/Events';
import { isElectron } from '@Misc/helpers/isElectron';
import { useActiveElement, useOutsideClick } from '@Misc/hooks';

import useStyles from './ElectronKeyboard.styles';
import { IElectronKeyboardLayoutNames } from './ElectronKeyboard.types';
import layout from './keyboardLayouts';

const ElectronKeyboard = () => {
  const {
    active: focusedElement,
    lastActiveElement,
    setLastActiveElement,
    top,
    currentValue,
    isNumber,
  } = useActiveElement();
  const keyboard = useRef<any>(null);
  const keyboardButton = useRef<HTMLButtonElement | null>(null);
  const classes = useStyles();
  const [layoutName, setLayoutName] =
    useState<IElectronKeyboardLayoutNames>('default');
  const [showKeyboard, setShowKeyboard] = useState(false);

  const clickOutsideButton = () => setLastActiveElement(null);

  useOutsideClick(keyboardButton, clickOutsideButton);

  useEffect(() => {
    const keyboardElement =
      document.querySelector<HTMLElement>('.simple-keyboard');
    if (keyboardElement) {
      if (focusedElement && showKeyboard) {
        keyboard?.current?.setInput(currentValue);
        keyboardElement.style.display = 'block';
        window.scrollTo({ top: top - 71 });
      } else {
        setShowKeyboard(false);
        keyboardElement.style.display = 'none';
        if (showKeyboard) {
          setLastActiveElement(null);
        }
      }
    }
  }, [focusedElement, showKeyboard]);

  useEffect(() => {
    if (isNumber) {
      setLayoutName('number');
    } else {
      setLayoutName('default');
    }
  }, [isNumber]);

  const onChange = (input: string, event?: MouseEvent) => {
    event?.preventDefault();
    if (focusedElement) {
      const nativeInputValueSetter = Object?.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      )?.set;
      let text = input;
      if (layoutName === 'number') {
        if (input.includes('.') && !input.split('.')[1].length) {
          text = text + 0;
        }
      }

      nativeInputValueSetter?.call(focusedElement, text);
      const eventDispatch = new Event('input', { bubbles: true });
      focusedElement.dispatchEvent(eventDispatch);
    }
  };
  const onKeyPress = (button: string, event: ChangeEvent<HTMLInputElement>) => {
    if (button === '{enter}' && focusedElement) {
      document.body.dispatchEvent(
        new CustomEvent(Events.onElectronKeyboardEnterClicked)
      );
    }
    if (button !== '{enter}') {
      event.preventDefault();
      if (button === '{shift}') {
        setLayoutName(layoutName !== 'shift' ? 'shift' : 'default');
      }
    }
  };

  if (!isElectron()) {
    return null;
  }

  return (
    <>
      <Keyboard
        onChange={onChange}
        onKeyPress={onKeyPress}
        keyboardRef={(r: any) => (keyboard.current = r)}
        physicalKeyboardHighlight={true}
        layout={layout}
        layoutName={layoutName}
      />
      {(lastActiveElement || focusedElement) && !showKeyboard && (
        <IconButton
          color="primary"
          size="medium"
          className={classes.keyboardButton}
          ref={keyboardButton}
          onClick={() => {
            setShowKeyboard(true);
            lastActiveElement?.focus();
            setLastActiveElement(null);
          }}
        >
          <KeyboardIcon className={classes.keyboardIcon} fontSize="medium" />
        </IconButton>
      )}
    </>
  );
};

export default ElectronKeyboard;
