import { Selector } from 'reselect';

import _Store from '@Store';

const getEmpikReservationEntryCode: Selector<_Store.IState, string | null> = (
  state,
) => {
  if (
    state.reservations &&
    state.reservations.empikReservationDetails &&
    state.reservations.empikReservationDetails.transactionItems &&
    state.reservations.empikReservationDetails.transactionItems.length
  ) {
    return state.reservations.empikReservationDetails.transactionItems[0]
      .entryToken;
  }
  return null;
};

export default getEmpikReservationEntryCode;
