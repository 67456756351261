import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  content: {
    overflow: 'hidden',
  },
  form: {
    overflowY: 'auto',
  },
  infoContainer: {
    backgroundColor: theme.palette.action.focus,
    marginBottom: theme.spacing(8),
  },
  input: {
    marginBottom: theme.spacing(8),
  },
  leftItem: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      paddingRight: 0,
    },
  },
  rightItem: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
    },
  },
}));

export default useStyles;
