import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderColor: '#000',
    display: 'flex',
    flexDirection: 'column',
    paddingHorizontal: 16,
    paddingVertical: 28,
    width: '100%',
  },
  flex: {
    alignSelf: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 400,
    marginRight: 5,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
  },
});

export default styles;
