import React, { ChangeEvent, useState } from 'react';

import { FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import DetailsModal from '@Compo/reservations/Calendar/components/DetailsModal';

import { IFindTicketProps } from './FindTickets.types';

const FindTickets = ({
  searchTicket,
  isReservationSelected,
}: IFindTicketProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'FindTickets',
  });

  const [ticketCode, setTicketCode] = useState('');
  const [isDeletionCode, setDeletionCode] = useState(false);
  const [searchTimeOut, setSearchTimeOut] = useState<number>(0);

  const searchFunc = (text: string, deletion: boolean) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        searchTicket(text, deletion);
      }, 1000) as any
    );
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTicketCode(e.currentTarget.value);
    searchFunc(e.currentTarget.value, isDeletionCode);
  };

  const handleSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setDeletionCode(checked);
    searchFunc(ticketCode, checked);
  };

  return (
    <GoingPaper title={t('title')} disableBottomDivider={true}>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <TextField
            variant="standard"
            id="ticketCode"
            label={t('ticketCode')}
            helperText={t('ticketCodeHelper')}
            onChange={onChange}
            value={ticketCode}
            name="ticketCode"
            fullWidth={true}
            autoFocus={true}
            data-testid="ticketScanInput"
          />
        </Grid>
        <Grid item={true} xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={isDeletionCode}
                onChange={handleSwitchChange}
                name="deletion"
                color="primary"
              />
            }
            label={t('delectionSwitch')}
          />
        </Grid>
      </Grid>
      <DetailsModal isReservationSelected={isReservationSelected} />
    </GoingPaper>
  );
};

export default FindTickets;
