import { createSelector } from 'reselect';

import _Store from '@Store';

import { IGroup } from '@Services/$users-api/types';

import { IUsersReducer } from '../types';
import get from './get';

const getGroups = createSelector<_Store.IState, IUsersReducer, IGroup[]>(
  [get],
  (users) => users.groups
);

export default getGroups;
