import React from 'react';

import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import config from '@Config';

import { IFormLayoutProps } from './../../FormLayout.types';
import { exampleTicket } from './mock';
import useStyles from './Tickets.styles';

const Tickets = ({
  handleBlur,
  values,
  generateExampleTicket,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates.Pools.components.FormLayout.components.Tickets',
  });

  const classes = useStyles();

  const selectTicketPreview = (
    event: SelectChangeEvent<number | undefined>
  ) => {
    if (event.target.value && values.id) {
      const foundedTicket = exampleTicket.find(
        (ticket) => ticket.id === event.target.value
      );
      if (foundedTicket) {
        generateExampleTicket({ ticket: foundedTicket, poolId: values.id });
      }
    }
  };

  return (
    <Grid item={true} xs={12}>
      <Grid container={true} spacing={3}>
        {config.cms.showGenerateTickets && (
          <Grid item={true} xs={12}>
            <Grid container={true} spacing={4}>
              <Grid item={true} xs={6}>
                <Button
                  variant="outlined"
                  fullWidth={true}
                  size="medium"
                  color="secondary"
                  className={classes.button}
                >
                  {t('generateTicketsList')}
                </Button>
              </Grid>
              <Grid item={true} xs={6}>
                <Button
                  variant="outlined"
                  fullWidth={true}
                  size="medium"
                  color="secondary"
                  className={classes.button}
                >
                  {t('outsideTicketsList')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item={true} xs={12}>
          <LabelContentContainer>
            <FormControl variant="standard" fullWidth={true}>
              <InputLabel>{t('ticketPreview')}</InputLabel>
              <Select
                variant="standard"
                value={undefined}
                onChange={selectTicketPreview}
                defaultValue={undefined}
                fullWidth={true}
                onBlur={handleBlur}
              >
                <MenuItem value={undefined}>-</MenuItem>
                {exampleTicket.map((ticket) => (
                  <MenuItem key={ticket.id} value={ticket.id}>
                    {ticket.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{t('ticketPreviewHelper')}</FormHelperText>
            </FormControl>
          </LabelContentContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Tickets;
