import React, { useEffect, useState } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import SeatsIo from '@Compo/reusable/SeatsIo';
import config from '@Config';
import { Colors } from '@Constants/Colors';
import { TypographyVariant } from '@Constants/Variants/Typography';
import { IPoolsSelectedSeat } from '@Model/events/types';
import { ISeat } from '@Services/$tickets-api/types';

import { Languages } from '../TicketForm/TicketForm.types';
import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = ({
  handleSubmit,
  values,
  handleChange,
  setFieldValue,
  touched,
  errors,
  handleBlur,
  submitForm,
  closeModal,
  rundate,
  seatsPublicKey,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'Rundates.TicketsList.components.FormLayout.components.FormLayout',
  });

  const classes = useStyles();
  const [selectedSeats, setSelectedSeats] = useState<ISeat[]>([]);

  const toggleLanguage = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      setFieldValue('languageId', Languages.English);
    } else {
      setFieldValue('languageId', Languages.Polish);
    }
  };

  const Label = ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) => {
    return (
      <>
        <Typography variant={TypographyVariant.body1}>{title}</Typography>
        <Typography
          variant={TypographyVariant.caption}
          color={Colors.textSecondary}
        >
          {description}
        </Typography>
      </>
    );
  };

  useEffect(() => {
    if (values.isMultiple && values.isSeats) {
      setFieldValue('isSeats', false);
    }
    if (!values.isMultiple) {
      setFieldValue('invitationsNumber', null);
    }
  }, [values.isMultiple]);

  useEffect(() => {
    if (values.isMultiple && values.isSeats) {
      setFieldValue('isMultiple', false);
    }
    if (values.isSeats) {
      setFieldValue('invitationsNumber', null);
      setFieldValue('ticketsNum', null);
    }
  }, [values.isSeats]);

  const onSeatsSelected = (seat: IPoolsSelectedSeat) => {
    setSelectedSeats((oldState) => [
      ...oldState,
      { id: seat.id, objectType: seat.objectType },
    ]);
  };
  const onSeatsDeselected = (seat: IPoolsSelectedSeat) => {
    setSelectedSeats((oldState) =>
      oldState.filter((selectedSeat) => selectedSeat.id !== seat.id)
    );
  };

  useEffect(() => {
    setFieldValue('seats', selectedSeats);
  }, [selectedSeats]);

  return (
    <>
      <DialogTitle>{t('addNewTicket')}</DialogTitle>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={6}>
            <Grid item={true} xs={12}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isSeats}
                        onChange={handleChange}
                        color="primary"
                        size="medium"
                        name="isSeats"
                      />
                    }
                    label={
                      <Label
                        title={t('seats')}
                        description={t('seatsHelper')}
                      />
                    }
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isMultiple}
                        onChange={handleChange}
                        color="primary"
                        size="medium"
                        name="isMultiple"
                      />
                    }
                    label={
                      <Label
                        title={t('multiple')}
                        description={t('multipleHelper')}
                      />
                    }
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.languageId === Languages.English}
                        onChange={toggleLanguage}
                        color="primary"
                        size="medium"
                      />
                    }
                    label={
                      <Label
                        title={t('language')}
                        description={t('languageHelper')}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            {values.isSeats && (
              <Grid item={true} xs={12}>
                <SeatsIo
                  seatsIoPublicKey={seatsPublicKey || config.seats.publicKey}
                  rundateId={String(rundate.id)}
                  onSeatsSelected={onSeatsSelected}
                  onSeatsDeselected={onSeatsDeselected}
                />
              </Grid>
            )}
            <Grid item={true} xs={12}>
              <Grid container={true} spacing={3}>
                {!values.isSeats && (
                  <Grid item={true} xs={12}>
                    <Grid container={true} spacing={4}>
                      <Grid item={true} xs={12} md={values.isMultiple ? 6 : 12}>
                        <LabelContentContainer>
                          <TextField
                            id="ticketsNum"
                            label={
                              (touched.ticketsNum && errors.ticketsNum) ||
                              t('peopleCount')
                            }
                            helperText={t('peopleCountHelper')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.ticketsNum}
                            name="ticketsNum"
                            error={!!(touched.ticketsNum && errors.ticketsNum)}
                            fullWidth={true}
                          />
                        </LabelContentContainer>
                      </Grid>
                      {values.isMultiple && (
                        <Grid item={true} xs={12} md={6}>
                          <LabelContentContainer>
                            <TextField
                              id="invitationsNumber"
                              label={
                                (touched.invitationsNumber &&
                                  errors.invitationsNumber) ||
                                t('ticketsCount')
                              }
                              helperText={t('ticketCountHelper')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.invitationsNumber}
                              name="invitationsNumber"
                              error={
                                !!(
                                  touched.invitationsNumber &&
                                  errors.invitationsNumber
                                )
                              }
                              fullWidth={true}
                            />
                          </LabelContentContainer>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}

                <Grid item={true} xs={12}>
                  <Grid container={true} spacing={4}>
                    <Grid item={true} xs={12} md={6}>
                      <LabelContentContainer>
                        <TextField
                          id="firstname"
                          label={
                            (touched.firstname && errors.firstname) ||
                            t('firstName')
                          }
                          helperText={t('firstNameHelper')}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstname}
                          name="firstname"
                          error={!!(touched.firstname && errors.firstname)}
                          fullWidth={true}
                        />
                      </LabelContentContainer>
                    </Grid>
                    <Grid item={true} xs={12} md={6}>
                      <LabelContentContainer>
                        <TextField
                          id="lastname"
                          label={
                            (touched.lastname && errors.lastname) ||
                            t('lastName')
                          }
                          helperText={t('lastNameHelper')}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastname}
                          name="lastname"
                          error={!!(touched.lastname && errors.lastname)}
                          fullWidth={true}
                        />
                      </LabelContentContainer>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item={true} xs={12}>
                  <LabelContentContainer>
                    <TextField
                      id="email"
                      label={(touched.email && errors.email) || t('email')}
                      helperText={t('emailHelper')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      name="email"
                      error={!!(touched.email && errors.email)}
                      fullWidth={true}
                    />
                  </LabelContentContainer>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('add')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
