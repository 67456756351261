import _Store from '@Store';
import { createSelector } from 'reselect';
import { IPriceReducer, IPriceReservation } from './../types';
import get from './get';

const getReservations = createSelector<
  _Store.IState,
  IPriceReducer,
  IPriceReservation[] | null
>([get], (price) => price && price.reservations);

export default getReservations;
