import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  icon: {
    alignItems: 'center',
    display: 'flex',
    marginRight: 10,
  },
  number: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
