import React, { useState } from 'react';

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import ExtendedUpsell from '@Compo/BetterManager/ExtendedUpsell';

import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = (props: IFormLayoutProps) => {
  const { handleChange, values, setFieldValue, handleBlur } = props;

  const { t } = useTranslation('components', {
    keyPrefix:
      'BetterManager.NewDefinedConfigurations.components.PriceSettings.FormLayout',
  });

  const groupTicketsTypes = [
    { name: t('valuePrice'), key: 'value' },
    { name: t('percent'), key: 'percentageValue' },
  ];

  const [selectedGroupTicketType, setSelectedGroupTicketType] = useState(
    values.perPersonType || groupTicketsTypes[0].key
  );
  const [selectedUpSellType, setSelectedUpSellType] = useState(
    values.upsellType || groupTicketsTypes[0].key
  );
  const [open, setOpen] = useState(false);

  const handleUpsellType = (e: SelectChangeEvent<string>) => {
    setSelectedUpSellType(String(e.target.value));
    setFieldValue('upsellType', e.target.value === 'value' ? 0 : 1);
  };

  const handleGroupTicketType = (e: SelectChangeEvent<string>) => {
    setSelectedGroupTicketType(String(e.target.value));
    setFieldValue('perPersonType', String(e.target.value));
  };

  return (
    <>
      <Grid container={true}>
        <Grid item={true} xs={12} lg={6}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <Typography variant="body1" color="textSecondary">
                    {t('upsellTitle')}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {t('upsellDesc')}
                  </Typography>
                </Grid>
                <Grid
                  item={true}
                  onMouseEnter={() => {
                    setOpen(true);
                  }}
                  onMouseLeave={() => setOpen(false)}
                >
                  {values.isExtendedUpsell ? (
                    <Tooltip
                      title={t('disabledTooltip') as string}
                      placement="right"
                      open={open}
                    >
                      <Switch
                        checked={values.isUpsell}
                        onChange={handleChange}
                        color="primary"
                        size="medium"
                        name="isUpsell"
                        disabled={values.isExtendedUpsell}
                      />
                    </Tooltip>
                  ) : (
                    <Switch
                      checked={values.isUpsell}
                      onChange={handleChange}
                      color="primary"
                      size="medium"
                      name="isUpsell"
                      disabled={values.isExtendedUpsell}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {values.isUpsell && (
              <>
                <Grid item={true} xs={12}>
                  <FormControl variant="standard" fullWidth={true}>
                    <InputLabel>{t('discountType')}</InputLabel>
                    <Select
                      variant="standard"
                      value={String(selectedUpSellType)}
                      onChange={handleUpsellType}
                      fullWidth={true}
                      onBlur={handleBlur}
                    >
                      {groupTicketsTypes.map((type) => (
                        <MenuItem key={type.key} value={type.key}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="standard"
                    id="upsellValue"
                    label={t('discountValue')}
                    helperText={t('discountValueHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.upsellValue}
                    name="upsellValue"
                    fullWidth={true}
                    type="number"
                  />
                </Grid>
              </>
            )}

            <ExtendedUpsell {...props} />

            <Grid item={true} xs={12}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <Typography variant="body1" color="textSecondary">
                    {t('groupTicketsTitle')}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {t('groupTicketsDesc')}
                  </Typography>
                </Grid>
                <Grid item={true}>
                  <Switch
                    checked={values.isPeopleCount}
                    onChange={handleChange}
                    color="primary"
                    size="medium"
                    name="isPeopleCount"
                  />
                </Grid>
              </Grid>
            </Grid>
            {values.isPeopleCount && (
              <>
                <Grid item={true} xs={12}>
                  <FormControl variant="standard" fullWidth={true}>
                    <InputLabel>{t('discountType')}</InputLabel>
                    <Select
                      variant="standard"
                      value={selectedGroupTicketType}
                      onChange={handleGroupTicketType}
                      fullWidth={true}
                      onBlur={handleBlur}
                    >
                      {groupTicketsTypes.map((type) => (
                        <MenuItem key={type.key} value={type.key}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="standard"
                    id="perPersonValue"
                    label={t('discountValue')}
                    helperText={t('discountValueHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.perPersonValue}
                    name="perPersonValue"
                    fullWidth={true}
                    type="number"
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextField
                    variant="standard"
                    id="fromNumberOfPeople"
                    label={t('groupTicketsAmountPeople')}
                    helperText={t('groupTicketsAmountPeopleHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.fromNumberOfPeople}
                    name="fromNumberOfPeople"
                    fullWidth={true}
                    type="number"
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FormLayout;
