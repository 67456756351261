import { boolean, number, object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const EMAIL_VALIDATOR_TEXT = 'Błędny format maila';
const NOT_NUMBER_VALIDATOR_TEXT = 'Podaj liczbę';

const configuration = {
  email: string().email(EMAIL_VALIDATOR_TEXT).required(REQUIRED_VALIDATOR_TEXT),
  firstname: string().required(REQUIRED_VALIDATOR_TEXT),
  invitationsNumber: number()
    .nullable()
    .when('isMultiple', {
      is: true,
      then: number()
        .typeError(NOT_NUMBER_VALIDATOR_TEXT)
        .required(REQUIRED_VALIDATOR_TEXT),
    }),
  languageId: number(),
  lastname: string().required(REQUIRED_VALIDATOR_TEXT),
  mailTemplate: string().nullable(),
  pdfTemplate: string().nullable(),
  sendMail: boolean(),
  ticketListId: number().required(REQUIRED_VALIDATOR_TEXT),
  ticketsNum: number().typeError(NOT_NUMBER_VALIDATOR_TEXT).nullable(),
  title: string().nullable(),
};

export const TicketSchema = object().shape(configuration);
