import PrintingError from '@Misc/classes/PrintingError';
import { catchNewErrorMessage } from '@Misc/helpers/api/catchHttpError';
import { isElectron } from '@Misc/helpers/isElectron';

import { printerStatus } from './../enums/printerStatus';
import { IPrinting } from './../types';

const SUCCESS_MESSAGE_TEXT = 'Dane wysłane do urządzenia RAW';
const ERROR_MESSAGE_PRINTER_NOT_FOUND =
  'Wystąpił błąd - nie znaleziono drukarki';
const ERROR_MESSAGE = 'Wystąpił błąd - szczegóły:';

class RawPrinting implements IPrinting {
  private static printer: any = null;

  constructor() {
    if (!RawPrinting.printer && isElectron()) {
      import('@electron/remote').then((remote) => {
        try {
          RawPrinting.printer = remote.require('@thiagoelg/node-printer');
        } catch (error) {}
      });
    }
  }

  public getPrinterName() {
    return RawPrinting.printer?.getDefaultPrinterName();
  }

  public isPrinterOnline() {
    const name = this.getPrinterName();

    if (name === undefined) {
      return false;
    }

    const printerObject = RawPrinting.printer?.getPrinter(name);

    return !(
      printerObject.status === 'IDLE' &&
      printerObject.options['printer-state-reasons'] !== 'none'
    );
  }

  public getStatus(): string {
    return this.isPrinterOnline()
      ? printerStatus.active
      : printerStatus.inactive;
  }

  public print(data: any): Promise<string | PrintingError> {
    return new Promise((resolve, reject) => {
      if (this.getStatus() === printerStatus.inactive) {
        return reject(
          catchNewErrorMessage(new Error(ERROR_MESSAGE_PRINTER_NOT_FOUND))
        );
      }

      RawPrinting.printer?.printDirect({
        data,
        error: (error: any) => {
          return reject(new PrintingError(`${ERROR_MESSAGE} ${error.message}`));
        },
        success: () => {
          return resolve(SUCCESS_MESSAGE_TEXT);
        },
        type: 'RAW',
      });
    });
  }
}

export default RawPrinting;
