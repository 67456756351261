import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getColorNameByHex } from '@Misc/helpers/selectColors';
import {
  addCategory,
  addLocalProduct,
  addShipmentFee,
  handleActiveCategory,
  handleActiveInvoiceProduct,
  handleActiveLocalProduct,
  handleActivePage,
  handleActiveShipmentFee,
  handleActiveZohoProduct,
  mountedProducts,
  removeCategory,
  removeLocalProduct,
  setProductView,
} from '@Model/products/actions';
import {
  getActiveCategory,
  getActiveInvoiceProduct,
  getActiveLocalProduct,
  getActiveShipmentFee,
  getActiveZohoProduct,
  getAllLocalProducts,
  getCategories,
  getInvoiceProducts,
  getMeta,
  getShipmentFees,
  getView,
  getZohoProducts,
} from '@Model/products/selectors';
import { PRODUCTS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import Products from './Products.component';
import { IProductsFromDispatch, IProductsFromState } from './Products.types';

const mapStateToProps = (state: _Store.IState): IProductsFromState => {
  return {
    activeCategory: getActiveCategory(state),
    activeInvoiceProduct: getActiveInvoiceProduct(state),
    activeLocalProduct: getActiveLocalProduct(state),
    activeShipmentFee: getActiveShipmentFee(state),
    activeZohoProduct: getActiveZohoProduct(state),
    isLoading: getLoading(PRODUCTS)(state),
    meta: getMeta(state),
    productsViews: {
      allProducts: getAllLocalProducts(state),
      categories: getCategories(state).map((category) => ({
        ...category,
        colorName: getColorNameByHex(category.color),
      })),
      courier: getShipmentFees(state),
      invoiceProducts: getInvoiceProducts(state).map((product) => ({
        ...product,
        categoryId: product.category?.id || 0,
        categoryName: product.category?.name,
      })),
      products: getAllLocalProducts(state).map((product) => ({
        ...product,
        categoryId: product.category?.id || 0,
        categoryName: product.category?.name,
      })),
      zohoProducts: getZohoProducts(state).map((product) => ({
        ...product,
        categoryId: product.category?.id || 0,
        categoryName: product.category?.name,
      })),
    },
    selectedView: getView(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IProductsFromDispatch => {
  return {
    addCategory: () => dispatch(addCategory()),
    addLocalProduct: () => dispatch(addLocalProduct()),
    addShipmentFee: () => dispatch(addShipmentFee()),
    handleActiveCategory: (category) =>
      dispatch(handleActiveCategory(category)),
    handleActiveInvoiceProduct: (id) =>
      dispatch(handleActiveInvoiceProduct(id)),
    handleActiveLocalProduct: (product) =>
      dispatch(handleActiveLocalProduct(product)),
    handleActivePage: (page, count) =>
      dispatch(handleActivePage({ page, count })),
    handleActiveShipmentFee: (shipmentFee) =>
      dispatch(handleActiveShipmentFee(shipmentFee)),
    handleActiveZohoProduct: (product) =>
      dispatch(handleActiveZohoProduct(product)),
    mounted: () => dispatch(mountedProducts()),
    removeCategory: (id) => dispatch(removeCategory(id)),
    removeProduct: (id) => dispatch(removeLocalProduct(id)),
    setProductsView: (view) => dispatch(setProductView(view)),
  };
};

export default connect<
  IProductsFromState,
  IProductsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Products);
