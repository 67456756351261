import React, { useEffect, useRef, useState } from 'react';

import { Grid, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import EventsPools from '@Compo/reservations/Add/components/FormLayout/EventsPools';
import BigButton from '@Compo/reusable/BigButton';
import { TypographyVariant } from '@Constants/Variants/Typography';
import { INormalizedEvent } from '@Services/$events-api/types';

import SearchField from './components/SearchField';
import useStyles from './EventsList.styles';
import { IEventListProps } from './EventsList.types';

const EventsList = ({
  events,
  isLoading,
  mounted,
  selectedEvent,
  setSeatsIo,
  selectEvent,
}: IEventListProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Add.components.FormLayout.EventsList',
  });

  const classes = useStyles();
  const poolsListRef = useRef<HTMLDivElement>(null);
  const [searchParam, setSearchParam] = useState('');

  const scrollToNextStep = (event: INormalizedEvent) => {
    selectEvent(event);
    if (
      poolsListRef &&
      poolsListRef.current &&
      event.id === selectedEvent?.id
    ) {
      poolsListRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    mounted();
  }, []);

  if (isLoading) {
    return (
      <Grid container={true} className={classes.loaderContainer}>
        <Loader />
      </Grid>
    );
  }

  if (events.length === 0) {
    return (
      <Grid container={true} spacing={2}>
        <SearchField
          setSearchParam={setSearchParam}
          searchParam={searchParam}
        />
        <Grid item={true} xs={12}>
          <Typography variant={TypographyVariant.h5} align="center">
            {t('emptyEvents')}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container={true} spacing={2}>
      <SearchField setSearchParam={setSearchParam} searchParam={searchParam} />

      {events.map((event) => (
        <Grid item={true} xs={12} sm={6} md={4} key={event.id}>
          <Tooltip
            title={t('clickAgain') as string}
            open={event.id === selectedEvent?.id}
            placement="top"
            classes={{ tooltip: classes.tooltip }}
            key={event.titlePl}
          >
            <BigButton
              variant="contained"
              size="large"
              color="secondary"
              selected={event.id === selectedEvent?.id}
              onClick={() => scrollToNextStep(event)}
            >
              <Grid container={true}>
                <Grid item={true} xs={12}>
                  <Typography variant={TypographyVariant.body2}>
                    {event.startDate}
                  </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                  {event.titlePl}
                </Grid>
                <Grid item={true} xs={12}>
                  <Typography variant={TypographyVariant.body2}>
                    {event.placeName}
                  </Typography>
                </Grid>
              </Grid>
            </BigButton>
          </Tooltip>
        </Grid>
      ))}
      <Grid item={true} xs={12}>
        <EventsPools setSeatsIo={setSeatsIo} poolRef={poolsListRef} />
      </Grid>
    </Grid>
  );
};

export default EventsList;
