import { createSelector } from 'reselect';

import _Store from '@Store';

import { IRuleType, IRuleTypesReducer } from '../types';

import get from './get';

const getPriceTypes = createSelector<
  _Store.IState,
  IRuleTypesReducer,
  IRuleType[]
>(
  [get],
  (products) => products.items,
);

export default getPriceTypes;
