const goTrough = (
  count: number,
  start: number = 1,
  step: number = 1,
): number[] => {
  const result = [];

  for (let i = start; i <= count; i += step) {
    result.push(i);
  }

  return result;
};

export default goTrough;
