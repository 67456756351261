import { createSelector } from 'reselect';

import _Store from '@Store';

import { ISaleReport } from '@Services/$going-rundate-api/types';

import { IRundateSingleReducer } from '../types';
import getSingleRundate from './getSingleRundate';

const getRundateStats = createSelector<
  _Store.IState,
  IRundateSingleReducer,
  ISaleReport
>([getSingleRundate], (rundate) => rundate.saleReport);

export default getRundateStats;
