import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import {
  getPartner,
  getPartnerReportFilters,
  getPixel,
} from '@Model/settings/actions';
import { SETTINGS } from '@Model/state/constants';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { resetError, resetLoading, setLoading } from './../actions';

export const setUpStateWhenSettingsFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getPixel.request,
        getPartner.request,
        getPartnerReportFilters.request,
      ])
    ),
    mergeMap$(() => [resetError(SETTINGS), setLoading(SETTINGS)])
  );
};

export const setLoaderWhenSettingsFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          getPartnerReportFilters.success,
          getPartnerReportFilters.failure,
          getPixel.success,
          getPixel.failure,
          getPartner.success,
          getPartner.failure,
        ],
        action
      )
    ),
    map$(() => resetLoading(SETTINGS))
  );
};

export const setErrorOnSettingsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getPartnerReportFilters.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
