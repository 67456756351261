import React, { FC } from 'react';

import { Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { IPageTitleProps } from './PageTitle.types';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginTop: theme.spacing(4),
  },
  title: {
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(4),
  },
}));

const PageTitle: FC<IPageTitleProps> = ({ children, title }) => {
  const classes = useStyles();

  return (
    <section className={classes.container}>
      <Typography
        data-testid="pageTitle"
        variant="h4"
        color="textPrimary"
        className={classes.title}
      >
        {title}
      </Typography>

      {children}
    </section>
  );
};

export default PageTitle;
