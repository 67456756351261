import React, { FC } from 'react';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import useStyles from './AvailabilityCard.styles';
import { IAvailabilityCardProps } from './AvailabilityCard.types';

const AvailabilityCard: FC<IAvailabilityCardProps> = ({
  availability,
  onClick,
}) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reusable.AvailabilitiesCards.components.AvailabilityCard',
  });

  const classes = useStyles();

  return (
    <Grid
      item={true}
      xs={12}
      md={6}
      lg={4}
      xl={3}
      className={classes.container}
    >
      <Card className={classes.card}>
        <CardHeader title={availability.title} />
        <CardContent className={classes.cardContent}>
          <Typography variant="body2" color="textSecondary">
            {availability.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button variant="text" size="small" color="primary" onClick={onClick}>
            {t('add')}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default AvailabilityCard;
