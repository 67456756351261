import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import FormLayout from './../FormLayout';
import { prepareInitialValues } from './GroupsModal.helpers';
import { IGroupsModalProps, IGroupsModalValues } from './GroupsModal.types';
import { GroupsSchema } from './GroupsModal.validation';

const GroupsModal = ({
  activeGroup,
  handleClose,
  submit,
}: IGroupsModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Users.components.GroupsTable.components.GroupsModal',
  });

  const catchHandleSubmit = (values: IGroupsModalValues) => {
    submit(values);
  };

  const initialValues = prepareInitialValues(null);

  return (
    <Dialog
      open={!!activeGroup}
      maxWidth="md"
      fullWidth={true}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <Formik
        initialValues={activeGroup || initialValues}
        onSubmit={catchHandleSubmit}
        validationSchema={GroupsSchema}
        component={FormLayout}
      />
    </Dialog>
  );
};

export default GroupsModal;
