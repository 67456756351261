import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  content: {
    '&:first-child': {
      paddingTop: 0,
    },
  },
  form: {
    overflowY: 'auto',
  },
  input: {
    marginTop: theme.spacing(8),
  },
  switch: {
    marginLeft: 0,
  },
}));

export default useStyles;
