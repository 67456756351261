import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TypographyVariant } from '@Constants/Variants/Typography';

import { IDeleteConfirmModalProps } from './DeleteConfirmModal.types';

const DeleteConfirmModal = ({
  open,
  closeModal,
  deleteSettlement,
}: IDeleteConfirmModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'Settlements.SingleSettlementModal.components.DeleteConfirmModal',
  });

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <Typography variant={TypographyVariant.body1}>{t('desc')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('no')}
        </Button>
        <Button
          variant="contained"
          size="large"
          color="primary"
          onClick={deleteSettlement}
        >
          {t('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmModal;
