import { object, string } from 'yup';

const settlementEdit = {
  name: string()
    .nullable(true)
    .default(null)
    .transform((value, originalValue) => (!value ? null : originalValue)),
};

export const SettlementEditSchema = object().shape(settlementEdit);
