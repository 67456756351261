import React, { useEffect, useRef, useState } from 'react';

import { Button, Tooltip, Typography } from '@mui/material';
import GridUi from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import colors from '@Compo/App/colors';
import Loader from '@Compo/layout/Loader/Loader';
import { ILocalProduct } from '@Model/products/types';

import styles from './ProductsList.module.scss';
import { IProductsListProps } from './ProductsList.types';

const TOOLBAR_HEIGHT = 71;

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  count: {
    textAlign: 'center',
  },
  loader: {
    position: 'relative',
  },
  selected: {
    backgroundColor: colors.secondary.dark,
  },
  stepperItem: {
    borderRadius: 20,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    height: '100%',
    minHeight: 96,
    padding: theme.spacing(2),
    width: '100%',
  },
  stepperItemCategory: {
    borderRadius: 20,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    height: '100%',
    minHeight: 128,
    padding: theme.spacing(4),
    width: '100%',
  },
  subtitle: {
    color: colors.text.secondary,
  },

  tooltip: {
    maxWidth: 'none',
  },
}));

const ProductsList = ({
  allProducts,
  addProduct,
  smallMobile,
  categories,
  productsCategories,
  mounted,
  isLoading,
  defaultStorage,
}: IProductsListProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Add.components.FormLayout.ProductsList',
  });

  const [activeElement, handleActiveElement] = useState<number | null>(null);
  const [productCount, setProductCount] = useState(0);
  const categoriesRef = useRef<any[]>([]);

  const classes = useStyles();

  useEffect(() => {
    mounted();
  }, []);

  const catchHandleActiveElement = (product: ILocalProduct) => {
    const { id } = product;
    if (id) {
      if (id === activeElement && product.id) {
        const productHasDefaultStorage = product.stock.some(
          (stock) => stock.storageHouseId === defaultStorage
        );

        addProduct({
          assignedStorageHouseId: product.assignedStorageHouseId || null,
          autocomplete: product.autocomplete,
          count: productCount,
          description: product.description,
          id: product.id,
          name: product.name,
          price: product.price,
          stock: product.stock,
          storageHouseId: productHasDefaultStorage ? defaultStorage : undefined,
        });
        handleActiveElement(null);
        setProductCount(0);
      } else {
        handleActiveElement(id);
        setProductCount(0);
      }
    }
  };

  const myRef = useRef<any>(null);
  const selectRef = useRef<any>(null);

  const scrollRef = useRef<any>(null);

  const executeScroll = () =>
    window.scroll({
      behavior: 'smooth',
      top: scrollRef.current.offsetTop + TOOLBAR_HEIGHT,
    });

  const executeCategoryScroll = (id: number) => {
    window.scroll({
      behavior: 'smooth',
      top: categoriesRef.current[id].offsetTop + TOOLBAR_HEIGHT + 40,
    });
  };

  const handleClickOutside = (e: any) => {
    if (
      !myRef?.current?.contains(e.target) &&
      !selectRef.current?.contains(e.target)
    ) {
      handleActiveElement(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
  const isCategoryEmpty = (categoryId?: number) =>
    !!(categoryId && !productsCategories?.[categoryId]?.length);

  if (isLoading) {
    return (
      <GridUi
        container={true}
        justifyContent="center"
        alignContent="center"
        className={classes.loader}
      >
        <Loader />
      </GridUi>
    );
  }

  return (
    <>
      <GridUi
        container={true}
        spacing={2}
        className={styles.container}
        direction="column"
        ref={scrollRef}
      >
        <GridUi container={true} spacing={2}>
          {categories.map((category, index) => {
            if (isCategoryEmpty(category.id)) {
              return null;
            }
            return (
              <GridUi
                item={true}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={category.id}
                className={styles.steeperGrid}
              >
                <Tooltip
                  title={t('clickAgain') as string}
                  open={false}
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    className={cn(classes.stepperItemCategory)}
                    style={{ backgroundColor: category.color }}
                    onClick={() => executeCategoryScroll(category.id || 0)}
                  >
                    <span>{category.name}</span>
                  </Button>
                </Tooltip>
              </GridUi>
            );
          })}
          {!isCategoryEmpty(0) && (
            <GridUi
              item={true}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              className={styles.steeperGrid}
            >
              <Tooltip
                title={t('clickAgain') as string}
                open={false}
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  variant="contained"
                  size="large"
                  color="secondary"
                  className={cn(classes.stepperItemCategory)}
                  onClick={() => executeCategoryScroll(0)}
                >
                  <span>{t('withoutCategory')}</span>
                </Button>
              </Tooltip>
            </GridUi>
          )}
        </GridUi>
        {categories.map((category, index) => {
          if (!category.id || isCategoryEmpty(category.id)) {
            return null;
          }
          return (
            <>
              <GridUi
                container={true}
                key={`category-${category.id}`}
                justifyContent="space-between"
                className={classes.container}
                ref={(el) => (categoriesRef.current[category.id || 0] = el)}
              >
                <GridUi item={true}>
                  <Typography variant="body1" className={classes.subtitle}>
                    {category.name}
                  </Typography>
                </GridUi>
                <GridUi item={true}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={executeScroll}
                  >
                    {t('backToCategoryList')}
                  </Button>
                </GridUi>
              </GridUi>
              <GridUi container={true} spacing={2}>
                {category.id &&
                  productsCategories?.[category.id]?.map((product, idx) => (
                    <GridUi
                      item={true}
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      key={product.id}
                      className={styles.steeperGrid}
                    >
                      <Tooltip
                        title={t('clickAgain') as string}
                        open={activeElement === product.id}
                        placement="top"
                        classes={{ tooltip: classes.tooltip }}
                      >
                        <Button
                          variant="contained"
                          size="large"
                          color="secondary"
                          className={cn(classes.stepperItem)}
                          onClick={() => catchHandleActiveElement(product)}
                          ref={activeElement === product.id ? myRef : null}
                          style={{ backgroundColor: category.color }}
                        >
                          <span>{product.name}</span>
                        </Button>
                      </Tooltip>
                    </GridUi>
                  ))}
              </GridUi>
            </>
          );
        })}
        {!isCategoryEmpty(0) && (
          <GridUi container={true}>
            <GridUi
              container={true}
              justifyContent="space-between"
              className={classes.container}
              ref={(el) => (categoriesRef.current[0] = el)}
            >
              <GridUi item={true}>
                <Typography variant="body1" className={classes.subtitle}>
                  {t('withoutCategory')}
                </Typography>
              </GridUi>
              <GridUi item={true}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={executeScroll}
                >
                  {t('backToCategoryList')}
                </Button>
              </GridUi>
            </GridUi>
            <GridUi container={true} spacing={2}>
              {productsCategories?.[0].map((product, index) => {
                return (
                  <GridUi
                    item={true}
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={product.id}
                    className={styles.steeperGrid}
                  >
                    <Tooltip
                      title={t('clickAgain') as string}
                      open={activeElement === product.id}
                      placement="top"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        className={cn(
                          classes.stepperItem,
                          activeElement === product.id && classes.selected,
                          activeElement &&
                            activeElement !== product.id &&
                            styles.transparent
                        )}
                        onClick={() => catchHandleActiveElement(product)}
                        ref={activeElement === product.id ? myRef : null}
                      >
                        <span>{product.name}</span>
                      </Button>
                    </Tooltip>
                  </GridUi>
                );
              })}
            </GridUi>
          </GridUi>
        )}
      </GridUi>
    </>
  );
};

export default ProductsList;
