import { format } from 'date-fns';
import moment from 'moment';
import uuid from 'uuid';

import {
  IManagerState,
  IManagerValues,
} from '@Compo/BetterManager/Manager/Manager.types';
import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import { getRuleType } from '@Misc/helpers/getRuleType';
import {
  IConfiguration as IRuleConfiguration,
  TRuleType,
} from '@Model/configurations/types';
import {
  IConfiguration,
  IHappeningBody,
  IHappeningOwnSpace,
} from '@Model/happenings/types/index';

import {
  makeDurationFromTime,
  makeTimeFromDuration,
} from './../AddingConfiguration/AddingConfiguration.helpers';

const getConfigurations = (space: IHappeningOwnSpace) => {
  const getConfigurationProps = (configuration: IConfiguration) => {
    const { prices, upsell, id } = configuration;

    const configurationHash = configuration.hash || uuid();

    const getPrice = (): number => {
      if (prices && prices.length) {
        const defaultPriceKey = prices.findIndex(
          (price) => price.type === 'default'
        );

        if (defaultPriceKey !== -1) {
          return prices[defaultPriceKey].value;
        }

        return prices[0].value;
      }

      return 0;
    };

    const getUpSell = () => {
      if (upsell) {
        const { value, percentageValue } = upsell;
        return {
          upsell: {
            percentageValue: percentageValue ?? percentageValue,
            value: value ?? value,
          },
        };
      }

      return {
        upsell: {},
      };
    };

    return {
      ...configuration,
      closed: configuration.closed || false,
      duration: configuration.duration,
      durationToSting: makeDurationFromTime(configuration.duration),
      hash: configurationHash,
      id: id || configurationHash,
      maxNumberOfPeople: configuration.maxNumberOfPeople || null,
      name: configuration.title,
      partnerId: 0,
      price: getPrice(),
      prices: prices || [],
      rrule: configuration.rrule,
      shouldSave: false,
      title: '',
      validFrom: configuration.validFrom || null,
      validTo: configuration.validTo || null,
      ...getUpSell(),
    };
  };

  const normalConfigurations = (space.configurations || []).map(
    (configuration) => getConfigurationProps(configuration)
  );
  const capacityConfigurations = (space.ruleCapacity || []).map(
    (configuration) => getConfigurationProps(configuration)
  );

  const priceConfigurations = (
    space && space.rulePrice ? space.rulePrice : []
  ).map((configuration) => getConfigurationProps(configuration));
  const timeConfigurations = (space.ruleTime || []).map((configuration) =>
    getConfigurationProps(configuration)
  );

  return [
    ...normalConfigurations,
    ...capacityConfigurations,
    ...priceConfigurations,
    ...timeConfigurations,
  ];
};

const mapHappeningToStateAndInitialValues = (
  happening: IHappeningBody | undefined
) => {
  const initialValues: IManagerValues = {
    activityTimeInSlots: -1,
    allowNextSlotWhenOverbooked: false,
    canBeBookedMax: null,
    canBeBookedMin: null,
    canBeBookedMinutesMin: null,
    color: null,
    description: null,
    endDate: null,
    imageUrl: '',
    isPublic: false,
    multipleSlotReservation: null,
    multipleSlotReservationChecked: false,
    name: 'Gra bez nazwy',
    numberOfPeople: 10,
    partnerId: null,
    paymentType: 'person',
    sliderLength: 10,
    slotTime: '15m',
    spaceLinked: [],
    spaces: [],
    startDate: null,
    startShowingSlotsAt: '',
    startShowingSlotsAtActive: false,
    vat: 23,
  };

  if (!happening) {
    return initialValues;
  }

  const happeningMetadata = getMetadataForLanguage(happening.metadata);

  initialValues.name = happeningMetadata.title;

  initialValues.canBeBookedMax = happening.canBeBookedMax || null;
  initialValues.canBeBookedMin = happening.canBeBookedMin || null;
  initialValues.canBeBookedMinutesMin = happening.canBeBookedMinutesMin
    ? Number(happening.canBeBookedMinutesMin) / 60
    : null;
  initialValues.multipleSlotReservation =
    happening.multipleSlotReservation || null;
  initialValues.multipleSlotReservationChecked =
    !!happening.multipleSlotReservation;

  if (happening.color) {
    initialValues.color = happening.color;
  }

  if (happening.maxNumberOfPeople) {
    initialValues.sliderLength = happening.maxNumberOfPeople;
  }

  if (happening.spaces && happening.spaces.length > 0) {
    initialValues.numberOfPeople = happening.spaces[0].maxNumberOfPeople;
  }

  if (happening.timeSlot) {
    initialValues.slotTime = makeDurationFromTime(happening.timeSlot);
  }

  if (happening.startShowingSlotsAt) {
    initialValues.startShowingSlotsAt = happening.startShowingSlotsAt;
    initialValues.startShowingSlotsAtActive = true;
  }

  if (happening.activityTimeInSlots) {
    initialValues.activityTimeInSlots = happening.activityTimeInSlots;
  }

  if (happening.imageUrl) {
    initialValues.imageUrl = happening.imageUrl;
  }

  initialValues.allowNextSlotWhenOverbooked =
    happening.allowNextSlotWhenOverbooked;

  if (happeningMetadata.description) {
    initialValues.description = happeningMetadata.description;
  }

  if (happening.endDate) {
    initialValues.endDate = moment(happening.endDate).toDate();
  }

  if (happening.vat) {
    initialValues.vat = happening.vat;
  }

  initialValues.paymentType = happening.calculatePricePerPerson
    ? 'person'
    : 'room';

  initialValues.isPublic = happening.isPublic;

  if (happening.partner && happening.partner.id) {
    initialValues.partnerId = happening.partner.id;
  }

  if (happening.startDate) {
    initialValues.startDate = moment(happening.startDate).toDate();
  }

  if (initialValues.allowNextSlotWhenOverbooked) {
    const firstSpace = happening.spaces[0];

    initialValues.slotTime = makeDurationFromTime(firstSpace.timeSlot);
    initialValues.numberOfPeople = firstSpace.maxNumberOfPeople;
  }
  initialValues.spaces = happening.spaces.map((space) => {
    const spaceMetadata = getMetadataForLanguage(space.metadata);

    const hash = space.id.toString();

    return {
      apartmentId: space.apartmentId || 0,

      configurations: getConfigurations(space),
      description: spaceMetadata.description || '',
      hash,
      id: hash,
      isScopeView: space.isScopeView,
      maxCapacityLive: space.maxCapacityLive,
      name: spaceMetadata.title,
      numberOfPeople: space.maxNumberOfPeople,
      products: space.products,
      slotTime: makeDurationFromTime(space.timeSlot),
      spaceLinked: space.spaceLinked,
      timeBreak: space.timeBreak
        ? makeDurationFromTime(Number(space.timeBreak))
        : null,
      url: space.url || null,
    };
  });

  return initialValues;
};

const getSortedConfiguration = (configurations: IRuleConfiguration[]) => {
  const configurationIds: number[] = [];
  const ruleTimeIds: number[] = [];
  const ruleCapacityIds: number[] = [];
  const rulePriceIds: number[] = [];

  configurations.forEach((configuration) => {
    if (configuration.id) {
      const type: TRuleType | undefined = getRuleType(configuration);

      if (!type) {
        configurationIds.push(Number(configuration.id));
      } else if (type === 'capacity') {
        ruleCapacityIds.push(Number(configuration.id));
      } else if (type === 'price') {
        rulePriceIds.push(Number(configuration.id));
      } else if (type === 'time') {
        ruleTimeIds.push(Number(configuration.id));
      }
    }
  });

  return {
    configurationIds,
    ruleCapacityIds,
    rulePriceIds,
    ruleTimeIds,
  };
};
const returnTime = (time: string): string => {
  const parts = time.split(':');
  if (parts.length) {
    if (isNaN(Number(parts[1]))) {
      return `${parts[0]}:00:00`;
    }
    return `${parts[0]}:${parts[1]}:00`;
  }
  return '';
};

export const normalizeValuesToSend = (values: IManagerValues) => {
  const extra: {
    maxNumberOfPeople?: number;
    activityTimeInSlots?: number;
    imageUrl?: string;
  } = {};

  const overbook = !!values.allowNextSlotWhenOverbooked;
  const paymentPerPerson = values.paymentType === 'person';

  if (paymentPerPerson) {
    extra.maxNumberOfPeople = parseInt(values.sliderLength.toString(), 10);
  }

  if (values.activityTimeInSlots > 0) {
    extra.activityTimeInSlots = parseInt(
      values.activityTimeInSlots.toString(),
      10
    );
  }

  if (values.imageUrl) {
    extra.imageUrl = values.imageUrl;
  }

  const startShowingSlotsAt = returnTime(values.startShowingSlotsAt);

  const happeningSend = {
    ...extra,
    allowNextSlotWhenOverbooked: values.allowNextSlotWhenOverbooked,
    calculatePricePerPerson: values.paymentType === 'person',
    canBeBookedMax: values.canBeBookedMax
      ? Number(values.canBeBookedMax)
      : null,
    canBeBookedMin: values.canBeBookedMin
      ? Number(values.canBeBookedMin)
      : null,
    canBeBookedMinutesMin: values.canBeBookedMinutesMin
      ? Number(values.canBeBookedMinutesMin) * 60
      : null,
    color: values.color,
    endDate: values.endDate
      ? moment(values.endDate).format('DD-MM-YYYY')
      : null,
    isPublic: values.isPublic,
    metadata: [
      {
        description: values.description ? values.description : null,
        language: 'PL',
        title: values.name,
      },
    ],
    multipleSlotReservation: values.multipleSlotReservationChecked
      ? Number(values.multipleSlotReservation)
      : null,
    partnerId:
      values.partnerId &&
      Number(values.partnerId) !== -1 &&
      Number(values.partnerId) !== 0
        ? Number(values.partnerId)
        : null,
    startDate: values.startDate
      ? moment(values.startDate).format('DD-MM-YYYY')
      : null,
    startShowingSlotsAt,

    spaces: values.spaces.map((space) => {
      return {
        apartmentId: parseInt(space.apartmentId.toString(), 10) || null,

        hash: space.hash,
        id: space.id ? parseInt(space.id, 10) : null,
        isScopeView: space.isScopeView,
        maxCapacityLive: space.maxCapacityLive
          ? parseInt(space.maxCapacityLive.toString(), 10)
          : undefined,
        maxNumberOfPeople: overbook
          ? parseInt(values.numberOfPeople.toString(), 10)
          : parseInt(space.numberOfPeople.toString(), 10),
        metadata: [
          {
            description: space.description ? space.description : null,
            language: 'PL',
            title: space.name,
          },
        ],

        ...getSortedConfiguration(space.configurations),

        timeSlot: makeTimeFromDuration(
          overbook ? values.slotTime : space.slotTime
        ),

        timeBreak: space.timeBreak
          ? makeTimeFromDuration(space.timeBreak)
          : null,

        url: space.url || null,

        products:
          space.products && space.products.length
            ? space.products.map((product) => ({
                productExternalId: Number(product.id),
                storageHouseExternalId: product.assignedStorageHouseId,
              }))
            : [],
        spaceLinked: space.spaceLinked?.filter(
          (linked) => linked.slaveId !== -1
        ),
      };
    }),
    vat: parseInt(values.vat.toString(), 10),
  };

  return happeningSend;
};

export default mapHappeningToStateAndInitialValues;
