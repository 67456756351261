import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { ISettlementsAddModalValues } from '@Compo/Settlements/SettlementsAddModal/SettlementsAddModal.types';
import {
  IGenerateReportResponse,
  IRundatesResponse,
  ISettlementMetadataResponse,
  ISettlementSaveBody,
  ISettlementsResponse,
  ISingleSettlementResponse,
  IStatusBody,
} from '@Services/$settlements-api/types';

import {
  _DELETE_SETTLEMENT_FAILURE,
  _DELETE_SETTLEMENT_REQUEST,
  _DELETE_SETTLEMENT_SUCCESS,
  _EDIT_SETTLEMENT_FAILURE,
  _EDIT_SETTLEMENT_REQUEST,
  _EDIT_SETTLEMENT_SUCCESS,
  _GENERATE_GROUP_REPORT_FAILURE,
  _GENERATE_GROUP_REPORT_REQUEST,
  _GENERATE_GROUP_REPORT_SUCCESS,
  _GENERATE_REPORT_FAILURE,
  _GENERATE_REPORT_FILE_FAILURE,
  _GENERATE_REPORT_FILE_REQUEST,
  _GENERATE_REPORT_FILE_SUCCESS,
  _GENERATE_REPORT_REQUEST,
  _GENERATE_REPORT_SUCCESS,
  _GET_SETTLEMENT_METADATA_FAILURE,
  _GET_SETTLEMENT_METADATA_REQUEST,
  _GET_SETTLEMENT_METADATA_SUCCESS,
  _GET_SETTLEMENTS_FAILURE,
  _GET_SETTLEMENTS_REQUEST,
  _GET_SETTLEMENTS_RUNDATES_FAILURE,
  _GET_SETTLEMENTS_RUNDATES_REQUEST,
  _GET_SETTLEMENTS_RUNDATES_SUCCESS,
  _GET_SETTLEMENTS_SUCCESS,
  _GET_SINGLE_SETTLEMENT_FAILURE,
  _GET_SINGLE_SETTLEMENT_REQUEST,
  _GET_SINGLE_SETTLEMENT_SUCCESS,
  _SAVE_SETTLEMENT_FAILURE,
  _SAVE_SETTLEMENT_REQUEST,
  _SAVE_SETTLEMENT_SUCCESS,
  _UPDATE_SETTLEMENT_STATUS_FAILURE,
  _UPDATE_SETTLEMENT_STATUS_REQUEST,
  _UPDATE_SETTLEMENT_STATUS_SUCCESS,
  CATCH_CHANGE_PAGE_SETTLEMENTS,
  CATCH_DELETE_SETTLEMENT,
  CATCH_EDIT_SETTLEMENT,
  CATCH_GENERATE_GROUP_REPORT,
  CATCH_GENERATE_REPORT,
  CATCH_GENERATE_REPORT_FILE,
  CATCH_SAVE_SETTLEMENT,
  CATCH_SETTLEMENT_STATUS_CHANGE,
  CHANGE_FILTER_SETTLEMENT,
  CLEAR_FILTER_SETTLEMENT,
  FETCH_SETTLEMENT_RUNDATES,
  FETCH_SINGLE_SETTLEMENT,
  HANDLE_ACTIVE_SETTLEMENT,
  MOUNTED,
  RESET_SINGLE_SETTLEMENT,
  SEARCH_SETTLEMENT,
} from './../constants/actions';
import {
  IActiveSettlement,
  IGenerateGroupReportPayload,
  IReportFilePayload,
  ISettlementEdit,
  ISettlementFilters,
  ISettlementRundatesPayload,
} from './../types/index';

export const handleActiveSettlement = createStandardAction(
  HANDLE_ACTIVE_SETTLEMENT
)<IActiveSettlement>();
export const mounted = createStandardAction(MOUNTED)();
export const catchSaveSettlement = createStandardAction(
  CATCH_SAVE_SETTLEMENT
)<ISettlementSaveBody>();
export const changeSettlementsPage = createStandardAction(
  CATCH_CHANGE_PAGE_SETTLEMENTS
)<number>();
export const fetchSingleSettlement = createStandardAction(
  FETCH_SINGLE_SETTLEMENT
)<string>();
export const resetSingleSettlement = createStandardAction(
  RESET_SINGLE_SETTLEMENT
)();
export const catchDeleteSettlement = createStandardAction(
  CATCH_DELETE_SETTLEMENT
)<string>();

export const catchSettlementStatusChange = createStandardAction(
  CATCH_SETTLEMENT_STATUS_CHANGE
)<IStatusBody>();

export const catchGenerateReport = createStandardAction(
  CATCH_GENERATE_REPORT
)<number>();

export const catchGenerateGroupReport = createStandardAction(
  CATCH_GENERATE_GROUP_REPORT
)<IGenerateGroupReportPayload>();

export const catchGenerateReportFile = createStandardAction(
  CATCH_GENERATE_REPORT_FILE
)<IReportFilePayload>();

export const fetchSettlementRundates = createStandardAction(
  FETCH_SETTLEMENT_RUNDATES
)<ISettlementRundatesPayload>();

export const changeFilterSettlement = createStandardAction(
  CHANGE_FILTER_SETTLEMENT
)<ISettlementFilters>();

export const clearFilterSettlement = createStandardAction(
  CLEAR_FILTER_SETTLEMENT
)();

export const catchEditSettlement = createStandardAction(
  CATCH_EDIT_SETTLEMENT
)<ISettlementEdit>();

export const searchSettlement =
  createStandardAction(SEARCH_SETTLEMENT)<ISettlementFilters>();

export const saveSettlement = createAsyncAction(
  _SAVE_SETTLEMENT_REQUEST,
  _SAVE_SETTLEMENT_SUCCESS,
  _SAVE_SETTLEMENT_FAILURE
)<ISettlementsAddModalValues, undefined, Error>();

export const editSettlement = createAsyncAction(
  _EDIT_SETTLEMENT_REQUEST,
  _EDIT_SETTLEMENT_SUCCESS,
  _EDIT_SETTLEMENT_FAILURE
)<ISettlementEdit, undefined, Error>();

export const getSettlements = createAsyncAction(
  _GET_SETTLEMENTS_REQUEST,
  _GET_SETTLEMENTS_SUCCESS,
  _GET_SETTLEMENTS_FAILURE
)<number, ISettlementsResponse, Error>();

export const getSingleSettlement = createAsyncAction(
  _GET_SINGLE_SETTLEMENT_REQUEST,
  _GET_SINGLE_SETTLEMENT_SUCCESS,
  _GET_SINGLE_SETTLEMENT_FAILURE
)<string, ISingleSettlementResponse, Error>();

export const deleteSettlement = createAsyncAction(
  _DELETE_SETTLEMENT_REQUEST,
  _DELETE_SETTLEMENT_SUCCESS,
  _DELETE_SETTLEMENT_FAILURE
)<string, undefined, Error>();

export const changeSettlementStatus = createAsyncAction(
  _UPDATE_SETTLEMENT_STATUS_REQUEST,
  _UPDATE_SETTLEMENT_STATUS_SUCCESS,
  _UPDATE_SETTLEMENT_STATUS_FAILURE
)<IStatusBody, undefined, Error>();

export const generateReport = createAsyncAction(
  _GENERATE_REPORT_REQUEST,
  _GENERATE_REPORT_SUCCESS,
  _GENERATE_REPORT_FAILURE
)<number, IGenerateReportResponse, Error>();

export const generateGroupReport = createAsyncAction(
  _GENERATE_GROUP_REPORT_REQUEST,
  _GENERATE_GROUP_REPORT_SUCCESS,
  _GENERATE_GROUP_REPORT_FAILURE
)<IGenerateGroupReportPayload, IGenerateReportResponse, Error>();

export const generateReportFile = createAsyncAction(
  _GENERATE_REPORT_FILE_REQUEST,
  _GENERATE_REPORT_FILE_SUCCESS,
  _GENERATE_REPORT_FILE_FAILURE
)<IReportFilePayload, BlobPart, Error>();

export const getSettlementMetadata = createAsyncAction(
  _GET_SETTLEMENT_METADATA_REQUEST,
  _GET_SETTLEMENT_METADATA_SUCCESS,
  _GET_SETTLEMENT_METADATA_FAILURE
)<undefined, ISettlementMetadataResponse, Error>();

export const getSettlementRundates = createAsyncAction(
  _GET_SETTLEMENTS_RUNDATES_REQUEST,
  _GET_SETTLEMENTS_RUNDATES_SUCCESS,
  _GET_SETTLEMENTS_RUNDATES_FAILURE
)<ISettlementRundatesPayload, IRundatesResponse, Error>();
