import { createSelector } from 'reselect';

import _Store from '@Store';

import { IRundate } from '@Services/$going-rundate-api/types';

import { IRundateSingleReducer } from '../types';
import getSingleRundate from './getSingleRundate';

const getSingleRundateData = createSelector<
  _Store.IState,
  IRundateSingleReducer,
  IRundate
>([getSingleRundate], (rundate) => rundate.data);

export default getSingleRundateData;
