import _Store from '@Store';
import { createSelector } from 'reselect';
import { IHappeningReducer } from './../types';
import getHappening from './getHappening';

const isOverbookingAllowed = createSelector<
  _Store.IState,
  IHappeningReducer,
  boolean
>(
  getHappening,
  (happening) => {
    if (happening) {
      return happening.allowNextSlotWhenOverbooked;
    }

    return false;
  },
);

export default isOverbookingAllowed;
