import React, { FC } from 'react';

import { Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import { IReportRequest } from '@Model/reports/types';

import { GRID_DEFAULT_LOCALE_TEXT } from './../../../../lang/data-grid';
import styles from './ReportsTable.module.scss';
import { IReportsProps } from './ReportsTable.types';

const useStyles = makeStyles((theme) => ({
  container: {
    height: 640,
    marginBottom: theme.spacing(3),
    width: '100%',
  },
}));

const ReportsTable: FC<IReportsProps> = ({
  reports,
  keys,
  isLoading,
  handleActivePage,
  totalCount,
  activePage,
  perPage,
  selectedReport,
}) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Reports.components.ReportsTable',
  });

  const classes = useStyles();

  const columns: GridColDef[] = keys.map((key) => ({
    field: key === 'Id' ? 'id' : key,
    headerName: key,
  }));

  const rows: IReportRequest[] = reports.map(({ Id, ...report }) => ({
    ...report,
    id: Id,
  }));

  const isEmpty = !(reports && reports.length);

  if (isLoading) {
    return (
      <GoingPaper>
        <div className={styles.loader}>
          <Loader />
        </div>
      </GoingPaper>
    );
  }

  if (isEmpty) {
    return (
      <GoingPaper>
        <div className={styles.loader}>{t('emptyData')}</div>
      </GoingPaper>
    );
  }

  const jsonToCsv = () => {
    return reports.map((report) => {
      const newObject: IReportRequest = {};

      keys.forEach((key) => {
        newObject[key] = report[key];
      });

      return newObject;
    });
  };

  const Buttons = () => (
    <Grid container={true} justifyContent="flex-end">
      <CSVLink
        data={jsonToCsv()}
        filename={`${selectedReport.slug}${moment().format(
          'YYYY-MM-DD/HH:mm:ss'
        )}.csv`}
      >
        <Button variant="outlined" size="large" color="secondary">
          {t('csv')}
        </Button>
      </CSVLink>
    </Grid>
  );

  return (
    <GoingPaper
      title={t('title')}
      description={t('description')}
      buttons={Buttons()}
    >
      <div className={classes.container}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={perPage}
          page={activePage}
          checkboxSelection={false}
          paginationMode="server"
          onPageChange={(page) => handleActivePage(page)}
          rowCount={totalCount}
        />
      </div>
    </GoingPaper>
  );
};

export default ReportsTable;
