import { createSelector } from 'reselect';

import _Store from '@Store';

import { IGroupsModalValues } from '@Compo/Users/components/GroupsTable/components/GroupsModal/GroupsModal.types';

import { IUsersReducer } from '../types';
import get from './get';

const getActiveGroup = createSelector<
  _Store.IState,
  IUsersReducer,
  IGroupsModalValues | null
>([get], (users) => users.activeGroup);

export default getActiveGroup;
