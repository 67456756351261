import React from 'react';

import { Button, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IFormLayoutProps } from './../../FormLayout.types';

const FormActions = ({ closeModal, submitForm }: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates.Pools.components.FormLayout.components.FormActions',
  });

  return (
    <DialogActions>
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        onClick={closeModal}
      >
        {t('cancel')}
      </Button>
      <Button
        type="submit"
        variant="contained"
        size="large"
        color="primary"
        onClick={submitForm}
      >
        {t('savePool')}
      </Button>
    </DialogActions>
  );
};

export default FormActions;
