import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPlace, IRundate } from '@Services/$going-rundate-api/types';

import getSingleRundateData from './getSingleRundateData';

const getSingleRundatePlace = createSelector<
  _Store.IState,
  IRundate,
  IPlace | undefined
>([getSingleRundateData], (rundate) => rundate.place);

export default getSingleRundatePlace;
