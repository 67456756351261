import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.action.focus,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: 1,
    marginTop: theme.spacing(8),
  },
}));

export default useStyles;
