import {
  getGroupedReservations,
  getReservations,
} from '@Model/reservations/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetError, resetLoading, setLoading } from './../actions';
import { RESERVATIONS } from './../constants/constants';

export const setUpStateWhenReservationsFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(getGroupedReservations.request)),
    mergeMap$(() => [resetError(RESERVATIONS), setLoading(RESERVATIONS)])
  );
};

export const setLoaderWhenReservationsFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [getGroupedReservations.success, getGroupedReservations.failure],
        action
      )
    ),
    map$(() => resetLoading(RESERVATIONS))
  );
};

export const setErrorOnReservationsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getReservations.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
