import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { catchSavePriceType, selectPriceType } from '@Model/priceTypes/actions';
import { getSelectedPriceType } from '@Model/priceTypes/selectors';
import _Store from '@Store';
import AddPriceTypeModal from './AddPriceTypeModal.component';

import {
  IAddPriceTypeFromDispatch,
  IAddPriceTypeFromState,
} from './AddPriceTypeModal.types';

const mapStateToProps = (state: _Store.IState): IAddPriceTypeFromState => {
  return {
    selectedPriceType: getSelectedPriceType(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IAddPriceTypeFromDispatch => {
  return {
    savePriceType: (body) => dispatch(catchSavePriceType(body)),
    selectPriceType: (body) => dispatch(selectPriceType(body)),
  };
};

export default connect<
  IAddPriceTypeFromState,
  IAddPriceTypeFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(AddPriceTypeModal);
