import { Selector } from 'reselect';

import _Store from '@Store';
import { IDelayedTransactionsReducer } from '../types';

const getDelayedTransactions: Selector<
  _Store.IState,
  IDelayedTransactionsReducer
> = (state) => state.reservations.delayedTransactions;

export default getDelayedTransactions;
