import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { get } from '@Model/authorization/selectors';
import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { showSuccessModal } from '@Model/modal/actions';
import {
  catchDeleteFilter,
  catchSaveFilter,
  catchSavePartner,
  catchSavePixel,
  changeFilterPage,
  deleteFilter,
  getPartner,
  getPartnerReportFilters,
  getPartnerReportFiltersParameters,
  getPixel,
  handleActiveFilter,
  mounted,
  saveFilter,
  savePartner,
  savePixel,
} from '@Model/settings/actions';
import { allPermissions } from '@Model/state/constants';

import { IPartnerReportFiltersBody } from '../types';
import { getFbPixel } from './../selectors';

export const requestPartnerReportFiltersWhenMounted: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const partnerId = getHappeningPartnerId(state);

      if (partnerId) {
        const payload: IPartnerReportFiltersBody = {
          filter: {
            partnerIds: [Number(partnerId)],
          },
          page: 1,
        };

        return of$(
          getPartnerReportFiltersParameters.request(),
          getPartnerReportFilters.request(payload),
          getPixel.request(),
          getPartner.request()
        );
      }

      return EMPTY$;
    })
  );
};

export const fetchPixelWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { settingsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPixel.request)),
    withLatestFrom$(state$),
    filter$(([_, state]) => {
      const {
        userInfo: { permissions },
      } = get(state);
      return permissions.includes(allPermissions.access_pixel_read);
    }),
    mergeMap$(([_, state]) => {
      const partnerId = getHappeningPartnerId(state);
      if (partnerId) {
        return from$(settingsApi.getPixel(partnerId)).pipe(
          map$((data) => {
            return getPixel.success(data[0]);
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => settingsApi.cancelSettings())
            )
          ),
          catchError$((error: Error) => {
            return of$(getPixel.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const fetchPartnerReportFiltersWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { settingsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPartnerReportFilters.request)),
    withLatestFrom$(state$),
    filter$(([_, state]) => {
      const {
        userInfo: { permissions },
      } = get(state);
      return permissions.includes(allPermissions.access_settlements_read);
    }),
    mergeMap$(([action]) => {
      return from$(settingsApi.getPartnerSettledFor(action.payload)).pipe(
        map$((data) => {
          return getPartnerReportFilters.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => settingsApi.cancelSettings())
          )
        ),
        catchError$((error: Error) => {
          return of$(getPartnerReportFilters.failure(error));
        })
      );
    })
  );
};

export const fetchPartnerReportFiltersParametersWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { settingsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPartnerReportFiltersParameters.request)),
    withLatestFrom$(state$),
    filter$(([_, state]) => {
      const {
        userInfo: { permissions },
      } = get(state);
      return permissions.includes(allPermissions.access_settlements_read);
    }),
    mergeMap$(([_, state]) => {
      const partnerId = getHappeningPartnerId(state);
      if (partnerId) {
        return from$(
          settingsApi.getPartnerSettledForParameters(partnerId)
        ).pipe(
          map$((data) => {
            return getPartnerReportFiltersParameters.success(data);
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => settingsApi.cancelSettings())
            )
          ),
          catchError$((error: Error) => {
            return of$(getPartnerReportFiltersParameters.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const savePixelWhenAction: _Store.IEpic = (
  action$,
  state$,
  { settingsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchSavePixel)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      const body = {
        id: getFbPixel(state)?.id,
        pixelId: action.payload.pixelId,
        token: action.payload.token,
      };

      if (partnerId) {
        return from$(settingsApi.savePixel(body, partnerId)).pipe(
          mergeMap$(() => {
            return of$(
              savePixel.success(),
              getPixel.request(),
              showSuccessModal()
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => settingsApi.cancelSettings())
            )
          ),
          catchError$((error: Error) => {
            return of$(savePixel.failure(error));
          })
        );
      }
      return EMPTY$;
    })
  );
};

export const saveNewReportFilterWhenAction: _Store.IEpic = (
  action$,
  state$,
  { settingsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchSaveFilter)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      if (partnerId) {
        const payload: IPartnerReportFiltersBody = {
          filter: {
            partnerIds: [Number(partnerId)],
          },
          page: 1,
        };
        return from$(
          settingsApi.savePartnerSettledFor(action.payload, partnerId)
        ).pipe(
          mergeMap$(() => {
            return of$(
              saveFilter.success(),
              getPartnerReportFilters.request(payload),
              showSuccessModal(),
              handleActiveFilter(null)
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => settingsApi.cancelSettings())
            )
          ),
          catchError$((error: Error) => {
            return of$(saveFilter.failure(error));
          })
        );
      }
      return EMPTY$;
    })
  );
};

export const deleteReportFilterWhenAction: _Store.IEpic = (
  action$,
  state$,
  { settingsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchDeleteFilter)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      if (partnerId) {
        const payload: IPartnerReportFiltersBody = {
          filter: {
            partnerIds: [Number(partnerId)],
          },
          page: 1,
        };
        return from$(settingsApi.deletePartnerSettledFor(action.payload)).pipe(
          mergeMap$(() => {
            return of$(
              deleteFilter.success(),
              getPartnerReportFilters.request(payload),
              showSuccessModal()
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => settingsApi.cancelSettings())
            )
          ),
          catchError$((error: Error) => {
            return of$(deleteFilter.failure(error));
          })
        );
      }
      return EMPTY$;
    })
  );
};

export const fetchPartnerReportFiltersWhenPageChanged: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(changeFilterPage)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      if (partnerId) {
        const payload: IPartnerReportFiltersBody = {
          filter: {
            partnerIds: [Number(partnerId)],
          },
          page: action.payload + 1,
        };
        return of$(getPartnerReportFilters.request(payload));
      }
      return EMPTY$;
    })
  );
};

export const fetchPartnerWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { settingsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPartner.request)),
    withLatestFrom$(state$),
    filter$(([_, state]) => {
      const {
        userInfo: { permissions },
      } = get(state);
      return permissions.includes(allPermissions.access_partner_read);
    }),
    mergeMap$(([_, state]) => {
      const partnerId = getHappeningPartnerId(state);

      if (partnerId) {
        return from$(settingsApi.getPartner(partnerId)).pipe(
          map$((data) => {
            return getPartner.success(data[0]);
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => settingsApi.cancelSettings())
            )
          ),
          catchError$((error: Error) => {
            return of$(getPartner.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const savePartnerWhenAction: _Store.IEpic = (
  action$,
  state$,
  { settingsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchSavePartner)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);

      if (partnerId) {
        return from$(settingsApi.savePartner(action.payload, partnerId)).pipe(
          mergeMap$(() => {
            return of$(
              savePartner.success(),
              getPartner.request(),
              showSuccessModal()
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => settingsApi.cancelSettings())
            )
          ),
          catchError$((error: Error) => {
            return of$(savePartner.failure(error));
          })
        );
      }
      return EMPTY$;
    })
  );
};
