import { createSelector } from 'reselect';

import _Store from '@Store';

import { IMetaPayload, ISeatsIoReducer } from './../types';
import get from './get';

const getMeta = createSelector<_Store.IState, ISeatsIoReducer, IMetaPayload>(
  [get],
  (seats) => seats.meta
);

export default getMeta;
