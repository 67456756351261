import moment from 'moment';
import { createSelector } from 'reselect';

import { IPoolPartner } from '@Services/$discounts-api/types';
import _Store from '@Store';
import { IDiscountReducer } from './../types';
import get from './get';

const getPoolsPartners = createSelector<
  _Store.IState,
  IDiscountReducer,
  IPoolPartner[]
>([get], (discount) => {
  return discount.pools.map((pool) => ({
    ...pool,
    rundate: {
      ...pool.rundate,
      rundate: moment(pool.rundate?.rundate).format('DD.MM.YYYY HH:mm'),
    },
  }));
});

export default getPoolsPartners;
