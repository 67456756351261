import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  fetchSettlementRundates,
  handleActiveSettlement,
} from '@Model/settlements/actions';
import {
  getSettlementMetadata,
  getSettlementRundates,
} from '@Model/settlements/selectors';
import {
  SETTLEMENTS_CREATE,
  SETTLEMENTS_RUNDATES,
} from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => ({
  isLoading: getLoading(SETTLEMENTS_RUNDATES)(state),
  isLoadingAfterCreate: getLoading(SETTLEMENTS_CREATE)(state),
  settlementMetadata: getSettlementMetadata(state),
  settlementRundates: getSettlementRundates(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormLayoutFromDispatch => ({
  fetchSettlementRundates: (data) => dispatch(fetchSettlementRundates(data)),
  handleActiveSettlement: (settlement) =>
    dispatch(handleActiveSettlement(settlement)),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
