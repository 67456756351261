import { createSelector } from 'reselect';

import _Store from '@Store';
import { IMailingReducer } from './../types';
import get from './get';

const getSearchText = createSelector<_Store.IState, IMailingReducer, string>(
  [get],
  (mailing) => mailing.searchText
);

export default getSearchText;
