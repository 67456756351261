import { getType } from 'typesafe-actions';

import { getAvailableUsers } from './../actions';
import { IAction, IFormIoAvailableUsersReducer } from './../types';

const initialState: IFormIoAvailableUsersReducer = {
  data: [],
  meta: {
    page: 1,
    size: 10,
    total: 0,
  },
};

const usersReducer = (
  state: IFormIoAvailableUsersReducer = initialState,
  action: IAction
): IFormIoAvailableUsersReducer => {
  switch (action.type) {
    case getType(getAvailableUsers.success):
      return action.payload;
    default:
      return state;
  }
};

export default usersReducer;
