import routes from '@/routes/routes';
import {
  createMatchSelector,
  LOCATION_CHANGE,
  RouterRootState,
} from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { mounted as rundateMounted } from '@Model/going/rundates/actions';
import { showSuccessModal } from '@Model/modal/actions';

import {
  catchDeleteSeatChart,
  catchSaveSeatChart,
  changeMetaData,
  deleteSeatChart,
  getSeatsCharts,
  mounted,
  saveSeatChart,
} from './../actions';
import { fetchCategories, getSeatsCategories } from './../actions/index';
import { getMeta } from './../selectors';

export const fetchSeatsChartsWhenMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf([mounted, changeMetaData])),
    mergeMap$(() => {
      return of$(getSeatsCharts.request());
    })
  );
};

export const getSeatsChartsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { seatsIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSeatsCharts.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { count, search, nextPageStartsAfter, previousPageEndsBefore } =
          getMeta(state);
        const { id } = match.params;
        return from$(
          seatsIoApi.getSeatsIoCharts(
            Number(id),
            count,
            nextPageStartsAfter,
            previousPageEndsBefore,
            search
          )
        ).pipe(
          mergeMap$((data) => {
            return of$(getSeatsCharts.success(data));
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => seatsIoApi.cancelSeatsIo())
            )
          ),
          catchError$((error: Error) => {
            return of$(getSeatsCharts.failure(error));
          })
        );
      }
      return EMPTY$;
    })
  );
};

export const catchSaveSeatChartWhenRequested: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(catchSaveSeatChart)),
    mergeMap$((action) => {
      return of$(saveSeatChart.request(action.payload));
    })
  );
};

export const saveSeatChartWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { seatsIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveSeatChart.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;
        return from$(
          seatsIoApi.saveSeatsIoEventChart(Number(id), action.payload)
        ).pipe(
          mergeMap$((data) => {
            return of$(
              saveSeatChart.success(data),
              showSuccessModal(),
              rundateMounted()
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => seatsIoApi.cancelSeatsIo())
            )
          ),
          catchError$((error: Error) => {
            return of$(saveSeatChart.failure(error));
          })
        );
      }
      return EMPTY$;
    })
  );
};

export const catchDeleteSeatChartWhenRequested: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(catchDeleteSeatChart)),
    mergeMap$(() => {
      return of$(deleteSeatChart.request());
    })
  );
};

export const deleteSeatChartWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { seatsIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(deleteSeatChart.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;
        return from$(seatsIoApi.deleteSeatsIoEventChart(Number(id))).pipe(
          mergeMap$((data) => {
            return of$(
              deleteSeatChart.success(data),
              showSuccessModal(),
              rundateMounted()
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => seatsIoApi.cancelSeatsIo())
            )
          ),
          catchError$((error: Error) => {
            return of$(deleteSeatChart.failure(error));
          })
        );
      }
      return EMPTY$;
    })
  );
};

export const catchFetchSeatCategoriesWhenRequested: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(fetchCategories)),
    mergeMap$((action) => {
      return of$(getSeatsCategories.request(action.payload));
    })
  );
};

export const getSeatCategoriesWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { seatsIoApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSeatsCategories.request)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      return from$(seatsIoApi.fetchCategoryKeysByChart(action.payload)).pipe(
        mergeMap$((data) => {
          return of$(getSeatsCategories.success(data));
        }),
        catchError$((error: Error) => {
          return of$(getSeatsCategories.failure(error));
        })
      );
    })
  );
};
