import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  basisFix: {
    flexBasis: 'auto',
  },
  button: {
    borderRadius: 4,
    paddingRight: theme.spacing(3),
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
    },
  },

  buttonFirst: {
    marginRight: theme.spacing(2),
  },
  buttonWithoutPadding: {
    paddingRight: theme.spacing(1.25),
  },
  buttonsContainer: {
    justifyContent: 'flex-end',
  },
  buttonsContainerRule: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    height: 200,
    justifyContent: 'center',
    width: '100%',
  },
  label: {
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 200,
    width: '100%',
  },
  ruleContainer: {
    width: '100%',
  },
  spacing1: {
    marginTop: theme.spacing(1),
  },
  spacing2: {
    marginTop: theme.spacing(2),
  },
  spacing5: {
    marginTop: theme.spacing(5),
  },
  spacing8: {
    marginTop: theme.spacing(8),
    width: '100%',
  },
  spacingBottom1: {
    marginBottom: theme.spacing(1),
  },
  spacingBottom2: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
