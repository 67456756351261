import { createSelector } from 'reselect';

import { getAvailablePools } from '@Model/events/selectors';
import _Store from '@Store';
import { IAvailablePoolsReducer } from './../types';

const poolHasSeatsIo = createSelector<
  _Store.IState,
  IAvailablePoolsReducer,
  boolean
>([getAvailablePools], (selectedPool) => {
  if (selectedPool?.seatsIoPublicKey) {
    return true;
  }

  return false;
});

export default poolHasSeatsIo;
