import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  form: {
    overflowY: 'auto',
  },
  switchContainer: {
    marginLeft: theme.spacing(2),
  },
}));

export default useStyles;
