import React, { useEffect, useRef } from 'react';

import { Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import Manager from '@Compo/BetterManager/Manager';
import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';

import { IEditProps } from './Edit.types';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
}));

const Edit = ({
  mounted,
  unmount,
  isLoading,
  happening,
  permissionHappeningsWrite,
  save,
  history,
}: IEditProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'happenings.Edit',
  });

  const classes = useStyles();
  const managerRef = useRef<any>(null);
  const Buttons = () => (
    <Grid container={true} justifyContent="flex-end">
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        className={classes.button}
        onClick={() => history.goBack()}
      >
        {t('back')}
      </Button>
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        onClick={() => managerRef?.current?.submitForm()}
      >
        {t('save')}
      </Button>
    </Grid>
  );

  useEffect(() => {
    mounted();

    return () => {
      unmount();
    };
  }, []);

  return (
    <GoingPaper title={t('title')} buttons={Buttons()}>
      {isLoading && <Loader />}

      {happening && (
        <Manager
          ref={managerRef}
          happening={happening}
          handleSave={save}
          hideSaveButton={!permissionHappeningsWrite}
        />
      )}
    </GoingPaper>
  );
};

export default Edit;
