import { boolean, object, string } from 'yup';

export const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const EMAIL_ADDRESS_IS_REQUIRED_VALIDATOR = 'Nazwa użytkownika jest wymagana';
const MIN_LENGTH_TEXT = 'Minimalna długość to 2 znaki';

const configuration = {
  email: string()
    .required(EMAIL_ADDRESS_IS_REQUIRED_VALIDATOR)
    .min(2, MIN_LENGTH_TEXT),
  password: string().required(REQUIRED_VALIDATOR_TEXT).min(2, MIN_LENGTH_TEXT),
  rememberMe: boolean(),
};

export const LoginSchema = object().shape(configuration);
