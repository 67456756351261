import React from 'react';

import { Dialog } from '@mui/material';

import PoolsForm from './../PoolsForm';
import { IPoolsModalProps } from './PoolsModal.types';

const PoolsModal = ({ showModal, closeModal }: IPoolsModalProps) => {
  return (
    <Dialog
      open={showModal}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <PoolsForm />
    </Dialog>
  );
};
export default PoolsModal;
