import React from 'react';

import Checking from '@Compo/Checking';
import Grid from '@Compo/layout/Grid';

const CheckingPage = () => (
  <Grid>
    <Checking />
  </Grid>
);

export default CheckingPage;
