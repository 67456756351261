import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { IFormValuesProps, IProductBody } from '@Model/products/types';

import FormLayout from './FormLayout';
import { IZohoModalProps } from './ZohoModal.types';

const ProductsModal = ({
  initialValues,
  open,
  isEditing,
  closeModal,
  saveProduct,
  partnerId,
}: IZohoModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Products.ZohoModal',
  });

  const catchHandleSubmit = (values: IFormValuesProps) => {
    const product: IProductBody = {
      autocomplete: !!values.autocomplete,
      availableToSale: values.isAvailabilityChecked
        ? values.availableToSale
        : undefined,
      categoryId: values.categoryId,
      cmsPartnerId: Number(partnerId),
      description: 'Brak opisu',
      emailConfirmation: values.emailConfirmation,
      fiscal: !!values.fiscal,
      id: values.id,
      imageUrl: values.imageUrl,
      name: values.name,
      price: values.price,
      type: 'default',
      vat: values.vat,
    };
    saveProduct(product);
  };
  const TITLE_TEXT = isEditing ? t('editTitle') : t('addTitle');
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle>{TITLE_TEXT}</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={catchHandleSubmit}
        component={FormLayout}
      />
    </Dialog>
  );
};

export default ProductsModal;
