import { createSelector } from 'reselect';

import _Store from '@Store';
import { IProductsReducer, IProductsWithCategories } from '../types';
import get from './get';

const getProductsCategories = createSelector<
  _Store.IState,
  IProductsReducer,
  IProductsWithCategories | null
>([get], (products) => products.productsCategories);

export default getProductsCategories;
