import { getAvailabilities, getHappening } from '@Model/happening/selectors';
import { getHappenings } from '@Model/happenings/selectors';
import { ISelectedHappeningSpace } from '@Model/happenings/types';
import { createSelector } from 'reselect';
import getAdd from './getAdd';

const getAvailable = (spaces: ISelectedHappeningSpace[], ids: number[]) => {
  return spaces.filter((_space) => ids.indexOf(_space.id) >= 0);
};

const getSpacesOfSelectedHappening = createSelector(
  [getHappenings, getHappening, getAdd, getAvailabilities],
  (happenings, selectedHappening, add, availabilities) => {
    const happening = happenings.find((item) => item.id === add.happeningId);

    if (!happening) {
      return [];
    }

    let availabilitieIds: number[] = [];

    let mergedDays = availabilities.currentDay;

    if (availabilities.otherDays) {
      mergedDays = {
        ...availabilities.currentDay,
        ...availabilities.otherDays,
      };
    }

    if (add.selectedSlot) {
      const localSpaces = mergedDays[add.selectedSlot.startTime];

      if (!localSpaces) {
        return [];
      }

      availabilitieIds = localSpaces
        .filter((_space) => _space.available)
        .map((_space) => _space.spaceId);
    }

    if (availabilitieIds.length < 1) {
      return [];
    }

    if (selectedHappening && selectedHappening.allowNextSlotWhenOverbooked) {
      return getAvailable(happening.spaces, availabilitieIds);
    }

    const spaceIds = (
      (selectedHappening &&
        selectedHappening.spaces.filter(
          (_space) =>
            _space.maxNumberOfPeople >= (add.selectedPeopleCount || 1),
        )) ||
      []
    ).map((_space) => _space.id);

    const spaces = happening.spaces.filter(
      (_space) => spaceIds.indexOf(_space.id) >= 0,
    );

    return getAvailable(spaces, availabilitieIds);
  },
);

export default getSpacesOfSelectedHappening;
