import { Notifier } from '@airbrake/browser';
import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import { setUser } from 'react-formio';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import {
  IData,
  IFormIoSaveUsersResponse,
  IFormIoUser,
  IGetEntryFormSuccessPayload,
  IGetUsersFromOwnDatabaseSuccess,
  IGetUsersSmsEnterFormSuccessPayload,
  IGetUsersSuccessPayload,
  IGetUsersSuccessResponse,
  ISingleUser,
  ISingleUserResponse,
  ISubmission,
} from '@Model/formio/types';

import {
  IFormIoLoginResponse,
  IFormIoSubmissionsResponse,
  IFormIoUserAdmin,
  IFormIoUserWhenExistResponse,
  INewSubmission,
  IOldSubmission,
  ISingleSubmission,
} from './types';

class FormIoApi {
  private static getLoinUrl(url: string, login: string, pass: string): string {
    return `${url}/submission?data.email=${login}&data.password=${pass}`;
  }

  private static updateUserEntryIdUrl(
    url: string,
    submissionId?: string
  ): string {
    if (submissionId) {
      return `${url}/submission/${submissionId}`;
    }
    return `${url}/submission`;
  }

  private static getUsersUrl(url: string, entryId: string): string {
    return `${url}/submission?data.page1Kodrezerwacji=${entryId}`;
  }

  private static getEntryFormUrl(url: string, entryId: string): string {
    return `${url}/submission?data.text=${entryId}`;
  }

  private static getAllEntryTokensUrl(url: string, email: string): string {
    return `${url}/submission?data.page1Email=${email}`;
  }

  private static submitReservation(url: string): string {
    return `${url}/submission`;
  }

  private static getSubmissionsUrl = () => `${config.api.formioUrl}submissions`;

  private static getAvailableUsersUrl(
    url: string,
    searchText: string,
    limit: number,
    page: number = 0,
    filterType: string = 'email'
  ): string {
    return `${url}/submission?limit=${limit}&skip=${
      page * limit
    }&data.${filterType}__regex=${searchText}&sort=-created`;
  }

  private static getAvailableUsersSearch(formHash: string): string {
    return `${config.api.formioUrl}admin/users-submissions/${formHash}`;
  }

  private static getSingleUserUrl(formHash: string, id: number): string {
    return `${config.api.formioUrl}admin/users-submissions/${formHash}/${id}`;
  }

  private static addEntryCodeUrl(): string {
    return `${config.api.formioUrl}admin/users-submissions`;
  }

  private static updateSingleUserUrl(id: number): string {
    return `${config.api.formioUrl}admin/users/${id}`;
  }

  private static checkOldBaseUrl(
    url: string,
    email: string,
    firstname: string
  ): string {
    return `${url}/exists?data.page1Email=${email}&data.page1Imi=${firstname}`;
  }

  private static getSingleSubmissionUrl(
    url: string,
    submissionId: string
  ): string {
    return `${url}/submission/${submissionId}`;
  }

  private static getSubmissionsAttachUrl = () =>
    `${config.api.formioUrl}submissions-attach`;
  private static getFormIoUserWhenExistUrl = () =>
    `${config.api.formioUrl}submissions-user-forms`;

  private static getDeleteSubmissionUrl(): string {
    return `${config.api.formioUrl}submissions-detach`;
  }

  private static getEntryGroupLinkUrl = (): string =>
    `${config.api.formioUrl}entry-group-link`;

  private cancelTokenUsers?: CancelTokenSource;

  public loginFormIo(
    formUrl: string,
    email: string,
    password: string
  ): Promise<IFormIoLoginResponse> {
    return new Promise<IFormIoLoginResponse>((resolve, reject) => {
      this.cancelTokenUsers = axios.CancelToken.source();

      const loginData = {
        data: {
          email,
          password,
          submit: true,
        },
        state: 'submitted',
      };

      axios
        .post(formUrl, loginData)
        .then((data) => {
          const formIoUser: IFormIoUserAdmin = getData(data);

          setUser(formIoUser);

          if (data.headers && data.headers['x-jwt-token']) {
            const body: IFormIoLoginResponse = {
              formIoUser,
              token: data.headers['x-jwt-token'],
            };
            resolve(body);
          }
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public getUsers(
    entryId: string,
    formUrl: string,
    token: string
  ): Promise<IGetUsersSuccessPayload> {
    return new Promise<IGetUsersSuccessPayload>((resolve, reject) => {
      this.cancelTokenUsers = axios.CancelToken.source();

      axios
        .get(FormIoApi.getUsersUrl(formUrl, entryId), {
          cancelToken: this.cancelTokenUsers.token,
          headers: {
            'x-jwt-token': token,
          },
        })
        .then(getData)
        .then((data) => {
          return data;
        })
        .then((data: IGetUsersSuccessResponse[]) => {
          const body: IGetUsersSuccessPayload = {
            totalCount: 0,
            users: data.map((user, index) => {
              return {
                email: user.data.page2Enterform.data.email || '',
                firstName: user.data.page2Enterform.data.firstname || '',
                id: String(index + 1),
                lastName: user.data.page2Enterform.data.lastname || '',
              };
            }),
          };

          resolve(body);
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public getFormIoSubmissions(
    entryToken: string,
    formId: string
  ): Promise<IGetUsersSuccessPayload> {
    return new Promise<IGetUsersSuccessPayload>((resolve, reject) => {
      axios
        .get(FormIoApi.getSubmissionsUrl(), {
          params: { formId, entryToken },
        })
        .then(getData)
        .then(getData)
        .then((response: IFormIoSubmissionsResponse) => {
          const body: IGetUsersSuccessPayload = {
            totalCount: 0,
            users:
              (!!response.length &&
                response[0]?.submission?.map((user) => {
                  return {
                    age: user.submissions[0].data.page2enterform.data
                      .dataurodzenia,
                    email:
                      user.submissions[0].data.page2enterform.data.email || '',
                    firstName: user.submissions[0].data.firstname || '',
                    id: user.submissions[0].submissionId,
                    lastName:
                      user.submissions[0].data.page2enterform.data.lastname ||
                      '',
                  };
                })) ||
              [],
          };
          resolve(body);
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public getSingleUser(
    formHash: string,
    id: number
  ): Promise<ISingleUserResponse> {
    return new Promise<ISingleUserResponse>((resolve, reject) => {
      this.cancelTokenUsers = axios.CancelToken.source();

      axios
        .get(FormIoApi.getSingleUserUrl(formHash, id), {
          cancelToken: this.cancelTokenUsers.token,
          headers: {
            'auth-token': config.api.formioAuthToken,
          },
        })
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(
            !!response.length && {
              ...response[0],
              lastSubmissionData: {
                data: response[0].lastSubmissionData,
              },
            }
          );
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public updateSingleUser(id: number, data: ISingleUser): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenUsers = axios.CancelToken.source();

      axios
        .put(FormIoApi.updateSingleUserUrl(id), data, {
          cancelToken: this.cancelTokenUsers.token,
          headers: {
            'auth-token': config.api.formioAuthToken,
          },
        })
        .then(getData)
        .then(() => {
          resolve([]);
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public getAvailableUsersSearch(
    formHash: string,
    email: string,
    size: number,
    firstName: string,
    lastName: string,
    page: number = 0
  ): Promise<IGetUsersFromOwnDatabaseSuccess> {
    return new Promise<IGetUsersFromOwnDatabaseSuccess>((resolve, reject) => {
      this.cancelTokenUsers = axios.CancelToken.source();

      axios
        .get(FormIoApi.getAvailableUsersSearch(formHash), {
          cancelToken: this.cancelTokenUsers.token,
          headers: {
            'auth-token': config.api.formioAuthToken,
          },
          params: {
            email: email || undefined,
            firstName: firstName || undefined,
            lastName: lastName || undefined,
            page,
            size,
          },
        })
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public getAvailableUsers(
    formUrl: string,
    token: string,
    email: string,
    limit: number,
    page: number = 0
  ): Promise<IGetUsersSuccessPayload> {
    return new Promise<IGetUsersSuccessPayload>((resolve, reject) => {
      this.cancelTokenUsers = axios.CancelToken.source();
      axios
        .get(FormIoApi.getAvailableUsersUrl(formUrl, email, limit, page), {
          cancelToken: this.cancelTokenUsers.token,
          headers: {
            'x-jwt-token': token,
          },
        })
        .then((response) => {
          resolve({
            totalCount: Number(
              response.headers['content-range'].substring(
                response.headers['content-range'].indexOf('/') + 1
              )
            ),
            users: response.data,
          });
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public getAllEntryTokens(
    formUrl: string,
    token: string,
    email: string
  ): Promise<IGetUsersSmsEnterFormSuccessPayload> {
    return new Promise<IGetUsersSmsEnterFormSuccessPayload>(
      (resolve, reject) => {
        this.cancelTokenUsers = axios.CancelToken.source();
        axios
          .get(FormIoApi.getAllEntryTokensUrl(formUrl, email), {
            cancelToken: this.cancelTokenUsers.token,
            headers: {
              'x-jwt-token': token,
            },
          })
          .then(getData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            this.reportBug(error);
            reject(catchHttpError(error));
          });
      }
    );
  }

  public getEntryForm(
    entryId: string,
    formUrl: string,
    token: string
  ): Promise<IGetEntryFormSuccessPayload> {
    return new Promise<IGetEntryFormSuccessPayload>((resolve, reject) => {
      axios
        .get(FormIoApi.getEntryFormUrl(formUrl, entryId), {
          headers: {
            'x-jwt-token': token,
          },
        })
        .then(getData)
        .then((data) => {
          return data;
        })
        .then((data: IGetEntryFormSuccessPayload) => {
          resolve(data);
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public getSearchData(
    formId: string,
    email: string,
    limit: number,
    firstName: string,
    lastName: string,
    date: string,
    page: number = 0,
    isNewForm?: boolean
  ) {
    const getParamsByString = () => {
      const orValue = [];
      if (email && email.length) {
        orValue.push({
          'data.email': {
            $options: 'i',
            $regex: email,
          },
        });
      }

      if (firstName && firstName.length) {
        if (isNewForm) {
          orValue.push({
            'data.firstname': {
              $options: 'i',
              $regex: firstName,
            },
          });
        } else {
          orValue.push({
            'data.imie': {
              $options: 'i',
              $regex: firstName,
            },
          });
        }
      }

      if (lastName && lastName.length) {
        if (isNewForm) {
          orValue.push({
            'data.lastname': {
              $options: 'i',
              $regex: lastName,
            },
          });
        } else {
          orValue.push({
            'data.nazwisko': {
              $options: 'i',
              $regex: lastName,
            },
          });
        }
      }

      // TODO: keep it
      // if (date && date.length) {
      //   orValue.push({
      //     'data.dataurodzenia': {
      //       $options: 'i',
      //       $regex: date,
      //     },
      //   });
      // }

      return orValue;
    };

    return [
      {
        $match: {
          $and: getParamsByString(),
          form: {
            $in: [formId],
          },
        },
      },
      { $skip: page * limit },
      { $limit: limit },
      { $sort: { created: -1 } },
    ];
  }

  public updateUserEntryId(
    formHash: string,
    userId: number,
    entryToken: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.cancelTokenUsers = axios.CancelToken.source();

      axios
        .post(
          FormIoApi.addEntryCodeUrl(),
          { formHash, userId, entryToken },
          {
            cancelToken: this.cancelTokenUsers.token,
            headers: {
              'auth-token': config.api.formioAuthToken,
            },
          }
        )
        .then(() => resolve())
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public updateUserData(
    formUrl: string,
    newUserData: IData,
    user: IFormIoUser,
    submissionId: string,
    token: string
  ): Promise<IGetUsersSmsEnterFormSuccessPayload> {
    return new Promise<IGetUsersSmsEnterFormSuccessPayload>(
      (resolve, reject) => {
        this.cancelTokenUsers = axios.CancelToken.source();

        const data = {
          data: {
            agreement: true,
            dataurodzenia:
              newUserData.dataurodzenia || user.data?.dataurodzenia,
            email: newUserData.email || user.data?.email,
            entryId: user.data?.entryId,
            imie: newUserData.imie || user.data?.imie,
            nazwisko: newUserData.nazwisko || user.data?.nazwisko,
            redirectAfterSubmitFormPath: 'none',
          },
          state: 'submitted',
        };

        axios
          .put(FormIoApi.updateUserEntryIdUrl(formUrl, submissionId), data, {
            cancelToken: this.cancelTokenUsers.token,
            headers: {
              'x-jwt-token': token,
            },
          })
          .then(getData)
          .then(resolve)
          .catch((error) => {
            this.reportBug(error);
            reject(catchHttpError(error));
          });
      }
    );
  }

  public saveUsersReservation(
    formUrl: string,
    submission: ISubmission,
    token: string
  ): Promise<IFormIoSaveUsersResponse> {
    return new Promise<IFormIoSaveUsersResponse>((resolve, reject) => {
      this.cancelTokenUsers = axios.CancelToken.source();
      axios
        .post(FormIoApi.submitReservation(formUrl), submission, {
          cancelToken: this.cancelTokenUsers.token,
          headers: {
            'x-jwt-token': token,
          },
        })
        .then(getData)
        .then(resolve)
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public saveSubmissionsAttach(
    entryToken: string,
    formId: string,
    submissionId: string
  ): Promise<IFormIoSaveUsersResponse> {
    return new Promise<IFormIoSaveUsersResponse>((resolve, reject) => {
      axios
        .post(FormIoApi.getSubmissionsAttachUrl(), {
          entryToken,
          formId,
          submissionId,
        })
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public getFormIoUserWhenExist(
    formId: string,
    userEmail: string,
    userFirstName: string
  ): Promise<ISingleUser> {
    return new Promise<ISingleUser>((resolve, reject) => {
      axios
        .get(FormIoApi.getFormIoUserWhenExistUrl(), {
          params: {
            formId,
            userEmail,
            userFirstName,
          },
        })
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(this.normalizeFormioUser(response));
        })
        .catch((error) => {
          this.reportBug(error);
          reject(error);
        });
    });
  }

  public checkUserExist(
    url: string,
    userEmail: string,
    userFirstName: string
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      axios
        .get(FormIoApi.checkOldBaseUrl(url, userEmail, userFirstName))
        .then(getData)
        .then((response) => {
          resolve(response._id);
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public getOldUserData(
    url: string,
    submissionId: string
  ): Promise<IOldSubmission> {
    return new Promise<IOldSubmission>((resolve, reject) => {
      axios
        .get(FormIoApi.getSingleSubmissionUrl(url, submissionId))
        .then(getData)
        .then((response) => {
          resolve(this.normalizeOldData(response));
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public getSingleSubmission(
    url: string,
    submissionId: string,
    token: string
  ): Promise<IOldSubmission> {
    return new Promise<IOldSubmission>((resolve, reject) => {
      axios
        .get(FormIoApi.getSingleSubmissionUrl(url, submissionId), {
          headers: {
            'x-jwt-token': token,
          },
        })
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public updateSingleSubmission(
    url: string,
    token: string,
    data: ISingleUser,
    oldSubmission: INewSubmission
  ): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      if (!oldSubmission?.data?.page4Numbergen) {
        resolve([]);
        return;
      }

      const formattedData: INewSubmission = {
        ...oldSubmission,
        data: {
          ...oldSubmission.data,
          email: data.email,
          firstname: data.firstName,
          page2Enterform: {
            ...oldSubmission.data.page2Enterform,
            data: {
              ...oldSubmission.data.page2Enterform?.data,
              dataurodzenia: data.birthday,
              email: data.email,
              firstname: data.firstName,
              lastname: data.lastName,
            },
          },
        },
      };

      axios
        .post(FormIoApi.updateUserEntryIdUrl(url), formattedData, {
          headers: {
            'x-jwt-token': token,
          },
        })
        .then(getData)
        .then(() => {
          resolve([]);
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public deleteSubmission(
    entryToken: string,
    submissionId: string
  ): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      axios
        .post(FormIoApi.getDeleteSubmissionUrl(), {
          entryToken,
          submissionId,
        })
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public attachGroupToTicketCode(
    entryToken: string,
    groupSlug: string
  ): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      axios
        .post(FormIoApi.getEntryGroupLinkUrl(), { entryToken, groupSlug })
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          this.reportBug(error);
          reject(catchHttpError(error));
        });
    });
  }

  public normalizeSubmission(data: IOldSubmission): ISingleSubmission {
    const {
      data: { page2Enterform },
    } = data;
    return {
      birthday: page2Enterform?.data.dataurodzenia || '',
    };
  }

  public normalizeOldData(data: IOldSubmission): INewSubmission {
    const {
      data: {
        page1Email,
        page1EnterFormFormUrl,
        page1Imi,
        page1Kodrezerwacji,
        page1RedirectAfterSubmit,
        page1SmsGateWay,
        page1SmsTekst,
        page2Enterform,
        page3parentfirstname,
        page3parentlastname,
        page3phone,
        page4Numbergen,
        iframe,
        nextPage,
        autoRedirectFormPath,
        userFormUrl,
      },
    } = data;
    return {
      data: {
        autoRedirectFormPath,
        email: page1Email,
        entryToken: page1Kodrezerwacji,
        firstname: page1Imi,
        form: {
          data: {
            autoRedirectFormPath,
            iframe,
            nextPage,
            page1Email,
            page1EnterFormFormUrl,
            page1Imi,
            page1Kodrezerwacji,
            page1RedirectAfterSubmit,
            page1SmsGateWay,
            page1SmsTekst,
            page2Enterform,
            page3parentfirstname,
            page3parentlastname,
            page3phone,
            page4Numbergen,
            userFormUrl,
          },
        },
        iframe,
        nextPage: 'page5',
        page1EnterFormFormUrl,
        page1RedirectAfterSubmit,
        page1SmsGateWay,
        page1SmsTekst,
        page2Enterform: page2Enterform
          ? {
              data: {
                ...page2Enterform.data,
                autocomplete: 'true',
                dataurodzenia2: moment(
                  page2Enterform.data.dataurodzenia
                ).format('YYYY-MM-DD'),
                firstname: page2Enterform ? page2Enterform.data.imie : '',
                lastname: page2Enterform ? page2Enterform.data.nazwisko : '',
              },
            }
          : page2Enterform,
        page3parentfirstname,
        page3parentlastname,
        page3phone,
        page4Numbergen,
        userFormUrl,
      },
    };
  }

  public normalizeFormioUser(user: IFormIoUserWhenExistResponse): ISingleUser {
    return {
      birthday: user[0].submission[0]?.data.page2enterform?.data.dataurodzenia,
      email: user[0].submission[0]?.data.page2enterform?.data.email || '',
      firstName: user[0].submission[0]?.data.firstname || '',
      id: user[0].submission[0]?.submissionId,
      lastName: user[0].submission[0]?.data.page2enterform?.data.lastname || '',
    };
  }

  public reportBug(error: Error) {
    const airbrake = new Notifier({
      projectId: 286058,
      projectKey: '5d35eca83ab06ad6e874519161d2fb6c',
    });
    airbrake.notify({
      context: {
        component: '$formio-api',
        environment: config.cms.environment,
      },
      environment: { environment: process.env.NODE_ENV },
      error,
      params: { error },
    });
  }
}

export default new FormIoApi();
