import { connect } from 'react-redux';

import _Store from '@Store';

import { getRundateStats } from '@Model/going/rundates/selectors';

import SalesReport from './SalesReport.component';
import { ISalesReportFromState } from './SalesReport.types';

const mapStateToProps = (state: _Store.IState): ISalesReportFromState => ({
  stats: getRundateStats(state),
});

export default connect<ISalesReportFromState, {}, {}, _Store.IState>(
  mapStateToProps
)(SalesReport);
