import { createSelector } from 'reselect';

import _Store from '@Store';

import { IMonitorType } from '@Services/$going-rundate-api/types';

import { IRundateSingleReducer } from '../types';
import getSingleRundate from './getSingleRundate';

const getMonitorTypes = createSelector<
  _Store.IState,
  IRundateSingleReducer,
  IMonitorType[]
>([getSingleRundate], (rundate) => rundate.monitorTypes);

export default getMonitorTypes;
