import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import {
  fetchRundateReport,
  getRundateReport,
} from '@Model/going/rundates/actions';
import { captureReport, handleActivePage } from '@Model/reports/actions';
import { REPORTS } from '@Model/state/constants/constants';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { resetError, resetLoading, setLoading } from '../actions';

export const setUpStateWhenGetReportsFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(
      isActionOf([captureReport.request, handleActivePage, fetchRundateReport])
    ),
    mergeMap$(() => [resetError(REPORTS), setLoading(REPORTS)])
  );
};

export const setLoaderWhenGetReportsFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          captureReport.success,
          captureReport.failure,
          getRundateReport.success,
          getRundateReport.failure,
        ],
        action
      )
    ),
    map$(() => resetLoading(REPORTS))
  );
};

export const setErrorOnReportsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([captureReport.failure, getRundateReport.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
