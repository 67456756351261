export const SMALL_MOBILE = 'smallMobile';
export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const DESKTOP = 'desktop';
export const BIG_DESKTOP = 'bigDesktop';

// iPhone 5 dimensions as it's one of the smallest popular mobiles
export const MOBILE_HEIGHT = 568;
export const MOBILE_WIDTH = 320;

// iPad dimensions cause it's width is our mobile/desktop switch point
export const DESKTOP_HEIGHT = 1024;
export const DESKTOP_WIDTH = 768;
