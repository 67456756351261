import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import _Store from '@Store';

import { get } from '@Model/authorization/selectors';
import { checkDiscount } from '@Model/discount/actions';
import { getValue } from '@Model/discount/selectors';
import { getPrice } from '@Model/price/actions';
import {
  getReservations,
  getTickets,
  getTotalPrice,
} from '@Model/price/selectors';
import {
  removeProduct,
  setProductCount,
  setProductStorage,
} from '@Model/products/actions';
import { getProducts } from '@Model/products/selectors';
import { clearPrintData, saveReservation } from '@Model/reservation/actions';
import { getSelectedExtendedDuration } from '@Model/reservation/selectors';
import { allPermissions } from '@Model/state/constants';
import { PRICE } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import Basket from './Basket.component';
import { IBasketFromDispatch, IBasketFromState } from './Basket.types';

const mapStateToProps = (state: _Store.IState): IBasketFromState => {
  const {
    userInfo: { session, permissions },
  } = get(state);

  return {
    isLoading: getLoading(PRICE)(state),
    isMdagUser: permissions.includes(allPermissions.mdag_user),
    isSaleWithoutSession: permissions.includes(
      allPermissions.access_sale_without_opened_session
    ),
    isSession: !!(session && session.session_id),
    products: getProducts(state).items,
    reservations: getReservations(state),
    selectedExtendedSlot: getSelectedExtendedDuration(state),
    tickets: getTickets(state),
    totalPrice: getTotalPrice(state),
    valueAfterDiscount: getValue(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IBasketFromDispatch => ({
  checkDiscount: (body) => dispatch(checkDiscount(body)),
  clearPrintData: () => dispatch(clearPrintData()),
  getPrice: (body) => dispatch(getPrice(body)),
  removeProduct: (product) => dispatch(removeProduct(product)),
  saveReservation: (body) => dispatch(saveReservation(body)),
  setProductCount: (product, count) =>
    dispatch(setProductCount({ product, count })),
  setProductStorage: (product, storageHouseId) =>
    dispatch(setProductStorage({ product, storageHouseId })),
});

export default connect<
  IBasketFromState,
  IBasketFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Basket);
