import { Dispatch } from 'react';

import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import _Store from '@Store';

import {
  closeModal,
  handleActivePageConsentsList,
  setEntryIdEdit,
} from '@Model/formio/actions';
import {
  getFormIoAvailableUsers,
  getFormIoConsentsList,
  getFormIoConsentsListShowModal,
} from '@Model/formio/selectors';

import ConsentsListTable from './ConsentsListTable.component';
import {
  IConsentsListTableFromDispatch,
  IConsentsListTableFromState,
} from './ConsentsListTable.types';

const mapStateToProps = (state: _Store.IState): IConsentsListTableFromState => {
  const { activePage, totalCount, perPage } = getFormIoConsentsList(state);

  return {
    activePage,
    perPage,
    showModal: getFormIoConsentsListShowModal(state),
    totalCount,
    users: getFormIoAvailableUsers(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IConsentsListTableFromDispatch => ({
  closeModal: () => {
    dispatch(closeModal());
  },
  handleActivePage: (e, page) =>
    dispatch(handleActivePageConsentsList(page + 1)),
  setEntryIdEdit: (id) => dispatch(setEntryIdEdit(id)),
});

export default connect<
  IConsentsListTableFromState,
  IConsentsListTableFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ConsentsListTable);
