import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { checkPrice } from '@Model/price/actions';
import _Store from '@Store';
import { resetError, resetLoading, setLoading } from './../actions';
import { PRICE } from './../constants/constants';

export const setUpStateWhenPriceFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(checkPrice.request)),
    mergeMap$(() => [resetError(PRICE), setLoading(PRICE)])
  );
};

export const setLoaderWhenPriceFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([checkPrice.success, checkPrice.failure], action)
    ),
    map$(() => resetLoading(PRICE))
  );
};
