import { createSelector } from 'reselect';

import _Store from '@Store';
import { IMeta, IProductsReducer } from '../types';
import get from './get';

const getMeta = createSelector<_Store.IState, IProductsReducer, IMeta>(
  [get],
  (products) => products.meta
);

export default getMeta;
