import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { updateSingleTicket } from '@Model/going/rundates/actions';
import { getSelectedSingleTicket } from '@Model/going/rundates/selectors';

import Form from './Form.component';
import { IFormFromDispatch, IFormFromState } from './Form.types';

const mapStateToProps = (state: _Store.IState): IFormFromState => ({
  initialValues: getSelectedSingleTicket(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormFromDispatch => ({
  save: (values) => dispatch(updateSingleTicket(values)),
});

export default connect<IFormFromState, IFormFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Form);
