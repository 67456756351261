import React from 'react';

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { IImageUploadProps } from './ImageUpload.types';

const useStyles = makeStyles((theme) => ({
  content: {
    alignItems: 'center',
    display: 'flex',
    height: 256,
    justifyContent: 'center',
    width: '100%',
  },

  media: {
    backgroundSize: 'contain',
    height: 256,
    width: '100%',
  },
  spacingBottom2: {
    marginBottom: theme.spacing(2),
  },
}));
const ImageUpload = ({
  imageUrl,
  title,
  onAdd,
  onRemove,
}: IImageUploadProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reusable.ImageUpload',
  });

  const classes = useStyles();
  return (
    <>
      <Typography variant="body1" className={classes.spacingBottom2}>
        {title}
      </Typography>
      <Card className={classes.spacingBottom2}>
        {imageUrl && <CardMedia image={imageUrl} className={classes.media} />}
        {!imageUrl && (
          <CardContent className={classes.content}>
            <Typography variant="body1">{t('imagePlaceholder')}</Typography>
          </CardContent>
        )}
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            {/* TODO: translate */}
            Zwróć uwagę na to, jak ikona układa się na podglądzie. Aby uzyskać
            najlepsze efekty, wykorzystaj grafikę o kształcie kwadratu.
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={onRemove}
            variant="text"
            size="small"
            color="primary"
          >
            {t('remove')}
          </Button>
          <Button onClick={onAdd} variant="text" size="small" color="primary">
            {t('refresh')}
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default ImageUpload;
