/* tslint:disable:jsx-no-lambda */
import React, { Component } from 'react';

import goTrough from '@Misc/helpers/goTrough';
import cn from 'classnames';
import styles from './Duration.module.scss';
import { IDurationProps, IDurationState } from './Duration.types';

class Duration extends Component<IDurationProps, IDurationState> {
  constructor(props: IDurationProps) {
    super(props);

    this.state = {
      dragging: false,
      positions: [...props.position],
      which: 0,
    };

    if (props.blockLeft) {
      this.state.positions[0] = 0;
    }

    if (props.blockRight) {
      this.state.positions[1] = 24 * props.q;
    }

    this.startDraging = this.startDraging.bind(this);
    this.onEnter = this.onEnter.bind(this);
    this.stop = this.stop.bind(this);
  }

  public render() {
    return (
      <div>
        <div className={styles.wrapper}>
          <div className={styles.duration}>
            <div
              className={styles.marker}
              style={{
                left: `${this.state.positions[0] * this.props.w}%`,
                width: `${(this.state.positions[1] - this.state.positions[0]) *
                  this.props.w}%`,
              }}
            />

            {goTrough(this.props.n, 0).map((i) => (
              <div
                key={i}
                className={cn(styles.hour, !(i % 8) && styles.even)}
                style={{
                  width: `${this.props.w}%`,
                }}
                onMouseEnter={() => this.onEnter(i)}
                data-title={
                  i % this.props.q === 0 ? `${i / this.props.q}:00` : ''
                }
              />
            ))}

            {!this.props.blockLeft && (
              <div
                className={styles.point}
                onMouseDown={() => {
                  this.startDraging(0);
                }}
                style={{
                  left: `${(this.state.positions[0] + 0.5) * this.props.w}%`,
                }}
              />
            )}
            {!this.props.blockRight && (
              <div
                className={styles.point}
                onMouseDown={() => {
                  this.startDraging(1);
                }}
                style={{
                  left: `${(this.state.positions[1] + 0.5) * this.props.w}%`,
                }}
              />
            )}

            <div className={styles.next} />
          </div>
        </div>
      </div>
    );
  }

  protected onEnter(id: number) {
    if (!this.state.dragging) {
      return;
    }

    const np = [...this.state.positions];
    np[this.state.which] = id;

    if (np[1] <= np[0]) {
      return;
    }

    this.setState({
      positions: np,
    });
  }

  protected startDraging(id: number) {
    window.addEventListener('mouseup', this.stop, false);

    this.setState({
      dragging: true,
      which: id,
    });
  }

  protected stop() {
    window.removeEventListener('mouseup', this.stop, false);

    this.setState(
      {
        dragging: false,
      },
      () => {
        this.propagateChanges();
      },
    );
  }

  protected propagateChanges() {
    if (this.state.which === 0) {
      return this.props.onChangeStart(this.state.positions[0]);
    }

    this.props.onChangeStop(this.state.positions[1]);
  }
}

export default Duration;
