import moment from 'moment';
import { createSelector } from 'reselect';

import _Store from '@Store';

import { IRundatePartner } from '@Services/$metadata-api/types';

import { IMetadataReducer } from './../types';
import get from './get';

const getRundatesPartner = createSelector<
  _Store.IState,
  IMetadataReducer,
  IRundatePartner[]
>([get], (metadata) => {
  return metadata.rundates.map((rundates) => {
    return {
      ...rundates,
      date: moment(rundates.rundate).format('DD.MM.YYYY'),
      rundate: moment(rundates.rundate).format('DD.MM.YYYY HH:mm'),
      time: moment(rundates.rundate).format('HH:mm'),
    };
  });
});

export default getRundatesPartner;
