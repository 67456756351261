import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import uuid from 'uuid';

import { ISpaceLinked } from '@Compo/BetterManager/Manager/Manager.types';

import useStyles from './LinkedSpaces.styles';
import { ILinkedSpacesProps } from './LinkedSpaces.types';

const MINUTE_IN_SECONDS = 60;
const DEFAULT_SLAVE_ID = -1;

const LinkedSpaces = ({ onChange, data, spaces }: ILinkedSpacesProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'BetterManager.Space.components.LinkedSpaces',
  });

  const classes = useStyles();
  const [linkedSpaces, setLinkedSpaces] = useState<ISpaceLinked[]>([]);

  useEffect(() => {
    if (data.spaceLinked) {
      setLinkedSpaces(
        data.spaceLinked.map((linkedSpace) => ({
          ...linkedSpace,
          timeShift: linkedSpace.timeShift / MINUTE_IN_SECONDS,
          uuid: uuid(),
        }))
      );
    }
  }, []);

  useEffect(() => {
    onChange(
      'spaceLinked',
      // tslint:disable-next-line: no-shadowed-variable
      linkedSpaces.map(({ uuid, ...attrs }) => ({
        ...attrs,
        timeShift: attrs.timeShift * MINUTE_IN_SECONDS,
      }))
    );
  }, [linkedSpaces]);

  const addNewLinkedSpace = () => {
    setLinkedSpaces([
      ...linkedSpaces,
      { uuid: uuid(), slaveId: DEFAULT_SLAVE_ID, timeShift: 0 },
    ]);
  };

  const removeLinkedSpace = (id: string) => {
    setLinkedSpaces(linkedSpaces.filter((space) => space.uuid !== id));
  };

  const modifyLinkedSpace = (id: string, field: string, value: number) => {
    setLinkedSpaces(() => {
      const newState = linkedSpaces.map((space, spaceIndex) => {
        if (space.uuid === id) {
          return { ...space, [field]: value };
        } else {
          return space;
        }
      });

      return newState;
    });
  };

  return (
    <Grid container={true} spacing={2} className={classes.mainContainer}>
      {linkedSpaces.map((linkedSpace, index) => (
        <Grid key={linkedSpace.uuid} item={true} xs={12}>
          <Grid container={true} className={classes.itemContainer}>
            <Grid
              container={true}
              spacing={2}
              wrap="nowrap"
              alignItems="center"
            >
              <Grid item={true} xs={6}>
                <FormControl variant="standard">
                  <InputLabel>{t('dependentHappening')}</InputLabel>
                  <Select
                    variant="standard"
                    value={linkedSpace.slaveId}
                    onChange={(e) =>
                      modifyLinkedSpace(
                        linkedSpace.uuid,
                        'slaveId',
                        Number(e.target.value)
                      )
                    }
                    defaultValue={-1}
                    fullWidth={true}
                    name="spaceLinked"
                  >
                    <MenuItem value={-1}>-</MenuItem>
                    {spaces.map((space) => (
                      <MenuItem key={space.id} value={space.id}>
                        {space.metadata[0].title}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {t('dependentHappeningHelper')}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item={true} xs={6}>
                <TextField
                  variant="standard"
                  id="timeShift"
                  label={t('timeShift')}
                  helperText={t('timeShiftHelper')}
                  onChange={(e) =>
                    modifyLinkedSpace(
                      linkedSpace.uuid,
                      'timeShift',
                      Number(e.target.value)
                    )
                  }
                  value={linkedSpace.timeShift}
                  name="timeShift"
                />
              </Grid>
              <Grid item={true}>
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => removeLinkedSpace(linkedSpace.uuid)}
                >
                  <CloseIcon fontSize="medium" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid item={true}>
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          onClick={addNewLinkedSpace}
        >
          {t('addButton')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default LinkedSpaces;
