import React from 'react';

import Grid from '@Compo/layout/Grid';
import Transactions from '@Compo/Transactions';

const TransactionsPage = () => (
  <Grid>
    <Transactions />
  </Grid>
);

export default TransactionsPage;
