import React from 'react';

import { Grid } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import AddSubtract from '@Compo/reusable/AddSubtract';
import { INumberBoxProps } from './NumberBox.types';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1),
  },
  count: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minWidth: theme.spacing(3),
    textAlign: 'center',
  },
}));

const NumberBox = ({ value, onChange, max }: INumberBoxProps) => {
  const classes = useStyles();

  const up = () => {
    if (value < max) {
      onChange(value + 1);
    }
  };
  const down = () => {
    if (value > 1) {
      onChange(value - 1);
    }
  };
  return (
    <Grid container={true} className={classes.container}>
      <AddSubtract
        subtract={down}
        add={up}
        count={value}
        variant="withoutSidePaddings"
        size="medium"
        fontSize="large"
      />
    </Grid>
  );
};

export default NumberBox;
