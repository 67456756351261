import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.text.primary,
  },
  image: {
    height: 284,
    width: 284,
  },
  tooltip: {
    backgroundColor: theme.palette.text.primary,
  },
}));

export default useStyles;
