import routes from '@/routes/routes';
import {
  createMatchSelector,
  LOCATION_CHANGE,
  push,
} from 'connected-react-router';
import format from 'date-fns/format';
import moment from 'moment';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  take as take$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import config from '@Config';
import { isElectron } from '@Misc/helpers/isElectron';
import { getUserInfo } from '@Model/authorization/selectors';
import { getHappening, resetState } from '@Model/happening/actions';
import {
  getAvailabilities,
  getHappening as getHappeningSelector,
} from '@Model/happening/selectors';
import { getHappenings } from '@Model/happenings/actions';
import { getHappenings as selectHappenings } from '@Model/happenings/selectors';
import {
  getPrinters,
  getPrintersPinging,
  printReceipt,
  startPingingPrinter,
} from '@Model/printer/actions';
import { getPinging } from '@Model/printer/selectors';
import { printBoca } from '@Model/printerBoca/actions';
import { getProducts } from '@Model/products/selectors';
import {
  getAdd,
  getAdvancePaymentId,
  getSelectedSlot,
} from '@Model/reservation/selectors';
import { resetSelection } from '@Model/reservations/actions';
import { allPermissions } from '@Model/state/constants';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR, TYPE_SUCCESS } from '@Model/toasts/constants/constants';
import { ITicket } from '@Services/$price-api/types';
import {
  ICompanyInfoResponse,
  IReservation,
  ISelectedProduct,
} from '@Services/$reservations-api/types';

import {
  IEmptyReservationSaveRequestPayload,
  IMatch,
  IReservationPrintData,
  ITransactionDetailsResponse,
} from '../types';
import {
  addMounted,
  cancelAutoTransaction,
  cancelTransaction,
  captureTransactionsDetailsRequest,
  captureTransactionsEmpikDetailsRequest,
  catchCompanyData,
  completeAdvancePayment,
  getCompanyData,
  getReservationPrintData,
  postAdvancePaymentReservation,
  postEmptyReservation,
  postReservation,
  reset,
  retryBocaPrint,
  saveBill,
  saveReservation,
  selectAdvanceTransaction,
  selectHappening,
  selectPeopleCount,
  selectTimeSlot,
  setCompanyData,
  setDate,
  setDurationTimeAfterMidnight,
  setTimeSlot,
  summaryMounted,
  transactionSave,
} from './../actions';
import { getDependencyTicket } from './../actions/index';
import getPrices from './../selectors/getPrices';
import { IAdvancePaymentRequestPayload } from './../types';

const HAPPENING_DOESNT_EXIST_TEXT = 'Błędne wydarzenie';
const SAVE_BILL_ERROR_TEXT = 'Paragon został juz wydrukowany';
const RECEIPT_HAS_BEEN_SAVED = 'Paragon został zapisany w systemie';
const RESERVATION_WITHOUT_TRANSACTION_DONE_TEXT =
  'Rezerwacja bez transakcji dodana';
const RESERVATION_ADD_TEXT = 'Rezerwacja dodana';
const CARNET_NOT_FOUND_TEXT = 'Karnetu nie znaleziono';

const CHECK_PRICE = false;

export interface IPriceCheckBodyUpSell {
  configurationId: number;
}

// TODO get from shaved librart
export interface IPriceCheckBodyDiscount {
  code: string | null;
}

// TODO get from shaved librart
export interface IPriceCheckBody {
  dateTime: string;
  discount?: IPriceCheckBodyDiscount;
  numberOfPeople?: number;
  price?: number;
  spaceId: number | null;
  upsell?: IPriceCheckBodyUpSell;
  products?: ISelectedProduct[];
  rulePriceId?: number;
  priceType?: string;
  happeningId?: number;
}

export const getPriceReduction = (
  // TODO get from shaved librart
  discountCode: string,
  upSellSelected: boolean,
  price: number,
  dateTime: string,
  numberOfPeople: number,
  products: ISelectedProduct[],
  spaceId: number,
  configurationId: number | null,
  priceType: string,
  happeningId?: number
): IPriceCheckBody | undefined => {
  if ((discountCode && discountCode.length) || upSellSelected) {
    const priceReduction: IPriceCheckBody = {
      dateTime,
      numberOfPeople,
      price,
      products,
      spaceId,
    };

    if (discountCode) {
      priceReduction.discount = {
        code: discountCode,
      };
    }

    if (upSellSelected) {
      priceReduction.upsell = {
        configurationId: configurationId || 0,
      };
    }

    if (happeningId) {
      priceReduction.happeningId = happeningId;
      delete priceReduction.price;
      delete priceReduction.products;
    }

    if (config.cms.showNewRules) {
      priceReduction.rulePriceId = configurationId || 0;
      priceReduction.priceType = priceType;

      if (upSellSelected) {
        priceReduction.upsell = true as any; // TODO: nomalize
      }
    }

    return priceReduction;
  }

  return undefined;
};

export const requestForHappeningsWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(addMounted)),
    mergeMap$(() => {
      return of$(getHappenings.request(), reset());
    })
  );
};

export const requestForHappeningWhenSelected: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(selectHappening)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const happenings = selectHappenings(state);
      const happeningId = action.payload;

      if (happenings && happeningId) {
        const happening = happenings.find((item) => item.id === happeningId);

        if (happening) {
          return of$(getHappening.request(happening.metadata.slug));
        }
      } else if (happeningId === null) {
        return of$(resetState());
      }

      return EMPTY$;
    })
  );
};

export const handleSettingSlotWhenRequested: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(selectTimeSlot)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedSlot = action.payload;
      const currentSlot = getSelectedSlot(state);
      const happening = getHappeningSelector(state);

      if (selectedSlot === null) {
        return EMPTY$;
      }

      if (
        selectedSlot &&
        currentSlot &&
        selectedSlot.startTime === currentSlot.startTime
      ) {
        return of$(setTimeSlot(null));
      }

      return of$(
        setTimeSlot({
          ...selectedSlot,
          spaceId:
            happening && happening.spaces.length === 1
              ? happening.spaces[0].id
              : null,
        })
      );
    })
  );
};

export const requestForPostReservationWhenSave: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveReservation)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const addingModel = getAdd(state);
      const { session, email, permissions } = getUserInfo(state);
      const availabilities = getAvailabilities(state);
      const values = action.payload;
      const products = getProducts(state);
      const happening = getHappeningSelector(state);
      const paymentAdvanceTransactionId = getAdvancePaymentId(state);
      const prices = getPrices(state);
      let price = 0;
      let configurationId = -1;
      let priceType = '';

      const getNumberOfPeople = (): number | undefined => {
        if (
          values.numberOfPeople &&
          values.numberOfPeople > 0 &&
          happening &&
          happening.calculatePricePerPerson
        ) {
          return values.numberOfPeople;
        } else if (happening) {
          const selectedSpace = happening.spaces.find(
            (space) => space.id === values.spaceId
          );
          if (selectedSpace && selectedSpace.maxNumberOfPeople) {
            return selectedSpace.maxNumberOfPeople;
          }
        } else if (!values.happeningId && !values.spaceId) {
          return undefined;
        }
        return 0;
      };

      const selectedProducts = (): ISelectedProduct[] => {
        const DEFAULT_SELECTED_PRODUCT_COUNT = 1;

        if (products && products.items && products.items.length) {
          const { items } = products;

          return items
            .filter((item) => item && item.count && item.count > 0)
            .map((item) => ({
              id: Number(item.id),
              quantity: item.count || DEFAULT_SELECTED_PRODUCT_COUNT,
              storageHouseId: item.storageHouseId,
            }));
        }
        return [];
      };

      let mergedDays = availabilities.currentDay;

      if (availabilities.otherDays) {
        mergedDays = {
          ...availabilities.currentDay,
          ...availabilities.otherDays,
        };
      }

      if (addingModel.selectedSlot && addingModel.selectedSlot.startTime) {
        const space = mergedDays[addingModel.selectedSlot.startTime].find(
          (_space) => _space.spaceId === values.spaceId
        );

        if (space) {
          configurationId = space.configurationId || -1;
          price = space.price;

          if (
            config.cms.showUnfinishedFeatures &&
            space &&
            space.prices &&
            space.prices.length
          ) {
            configurationId = space.rulePriceId;

            const defaultPriceKey = space.prices.findIndex(
              (_price) => _price.type === 'default'
            );

            if (defaultPriceKey !== -1) {
              priceType = space.prices[defaultPriceKey].type;
            }

            priceType = space.prices[0].type;
          }
        }
      }

      if (
        !values.spaceId ||
        (values.happeningId && values.happeningId === -1)
      ) {
        values.spaceId = undefined;
      }

      if (
        !values.happeningId ||
        !values.reservationCheckbox ||
        (values.happeningId && values.happeningId === -1)
      ) {
        values.happeningId = undefined;
      }

      if (
        (!values.happeningId && !values.spaceId) ||
        !values.reservationCheckbox
      ) {
        values.dateTime = undefined;
      }

      const getCheckTransactionLinks = () => {
        const { baseUrl, newBaseUrl, newCms } = config.cms;
        const { clientAppUrl } = config.api;
        const { location } = window;
        if (values.delayedTransaction) {
          return {
            linkFail: `${clientAppUrl}?transactionId={transactionId}`,
            linkOk: `${clientAppUrl}?transactionId={transactionId}`,
          };
        }

        if (isElectron()) {
          return {
            linkCancel: `${location.protocol}//${location.host}${location.pathname}?transactionId={transactionId}&error=500`,
            linkFail: `${location.protocol}//${location.host}${location.pathname}?transactionId={transactionId}&error=401`,
            linkOk: `${location.protocol}//${location.host}${location.pathname}?transactionId={transactionId}`,
          };
        }

        return {
          linkCancel: `${
            newCms ? newBaseUrl : baseUrl
          }?transactionId={transactionId}&error=500`,
          linkFail: `${
            newCms ? newBaseUrl : baseUrl
          }?transactionId={transactionId}&error=401`,
          linkOk: `${
            newCms ? newBaseUrl : baseUrl
          }?transactionId={transactionId}`,
        };
      };

      const getSelectedDayIncludeTimeSlot = () => {
        if (
          values.startTime &&
          values.date &&
          availabilities.otherDays &&
          availabilities.otherDays[values.startTime]
        ) {
          const day = moment(values.date).add('days', 1).toDate();

          const dateTime = `${format(day || values.dateTime, 'yyyy-MM-dd')} ${
            values.startTime
          }`;

          return {
            dateTime,
          };
        }

        return {
          dateTime: `${format(moment(values.date).toDate(), 'yyyy-MM-dd')} ${
            values.startTime
          }`,
        };
      };

      const getReservations = (): IReservation[] => {
        const getCurrentReservation = (): IReservation[] => {
          if (happening && happening.id && values.spaceId) {
            const getDuration = (): number | undefined => {
              if (happening) {
                const selectedSpace = happening.spaces.find(
                  (space) => space.id === values.spaceId
                );
                if (selectedSpace && selectedSpace.timeSlot) {
                  return selectedSpace.timeSlot;
                }
              }
              return undefined;
            };

            const currentReservation: IReservation = {
              ...getSelectedDayIncludeTimeSlot(),
              duration: getDuration(),
              happeningId: happening.id,
              numberOfPeople: getNumberOfPeople(),
              priceReduction: getPriceReduction(
                values.discount || '',
                !!values.upsell,
                price ? price : 0,
                getSelectedDayIncludeTimeSlot().dateTime,
                getNumberOfPeople() || 0,
                [],
                values.spaceId ? values.spaceId : 0,
                configurationId || null,
                priceType
              ),
              priceType,
              products: [],
              spaceId: values.spaceId,
            };
            return [currentReservation];
          }
          return [];
        };

        const getReservationsFromBasket = (): IReservation[] => {
          const { basketItems } = action.payload;

          const itemsIReservation: IReservation[] = [];

          if (basketItems && basketItems.length) {
            basketItems.forEach((item) => {
              if (!item.isEvent) {
                if (item.selectedPrices && item.selectedPrices.length) {
                  const grouperSelectedPrices: { [_: string]: number } = {};

                  if (item.calculatePricePerPerson) {
                    item.selectedPrices.forEach((selectedPrice) => {
                      if (grouperSelectedPrices[selectedPrice]) {
                        grouperSelectedPrices[selectedPrice] += 1;
                      } else {
                        grouperSelectedPrices[selectedPrice] = 1;
                      }
                    });

                    Object.keys(grouperSelectedPrices).map((property) => {
                      itemsIReservation.push({
                        dateTime: item.dateTime,
                        duration: item.duration,
                        extendedDuration: item.extendedDuration || undefined,
                        happeningId: item.happeningId,
                        numberOfPeople: grouperSelectedPrices[property],
                        priceReduction: getPriceReduction(
                          values.discount || '',
                          !!item.isUpSellSelected,
                          item.price,
                          item.dateTime || '',
                          item.numberOfPeople,
                          [],
                          item.spaceId,
                          item.configurationId,
                          property
                        ),
                        priceType: property,
                        products: [],
                        spaceId: item.spaceId,
                      });
                    });
                  } else {
                    itemsIReservation.push({
                      dateTime: item.dateTime,
                      duration: item.duration,
                      extendedDuration: item.extendedDuration || undefined,
                      happeningId: item.happeningId,
                      numberOfPeople: item.numberOfPeople,
                      priceReduction: getPriceReduction(
                        values.discount || '',
                        !!item.isUpSellSelected,
                        item.price,
                        item.dateTime || '',
                        item.numberOfPeople,
                        [],
                        item.spaceId,
                        item.configurationId,
                        item.selectedPrices[0] || item.priceType
                      ),
                      priceType: item.selectedPrices[0] || item.priceType,
                      products: [],
                      spaceId: item.spaceId,
                    });
                  }
                } else {
                  itemsIReservation.push({
                    dateTime: item.dateTime,
                    duration: item.duration,
                    extendedDuration: item.extendedDuration || undefined,
                    happeningId: item.happeningId,
                    numberOfPeople: item.numberOfPeople,
                    priceReduction: getPriceReduction(
                      values.discount || '',
                      !!item.isUpSellSelected,
                      item.price,
                      item.dateTime || '',
                      item.numberOfPeople,
                      [],
                      item.spaceId,
                      item.configurationId,
                      item.priceType
                    ),
                    priceType: item.priceType,
                    products: [],
                    spaceId: item.spaceId,
                  });
                }
              }
            });
          }
          return itemsIReservation;
        };

        return [...getReservationsFromBasket(), ...getCurrentReservation()];
      };

      const getDelayProps = () => {
        if (values.delayedTransaction) {
          return {
            delay: 604800,
          };
        }
        return {};
      };

      const getUserData = () => {
        const sendEmailWithTransaction = permissions.includes(
          allPermissions.access_send_mail_with_transaction
        );

        if (action.payload.user) {
          return action.payload.user;
        }

        if (email && sendEmailWithTransaction) {
          return {
            email,
            firstName: '-',
            lastName: '-',
            phone: '-',
            terms: true,
          };
        }

        return {
          email: 'sprzedaz@goingapp.pl',
          firstName: '-',
          lastName: '-',
          phone: '-',
          terms: true,
        };
      };

      const getInvoiceData = () => {
        if (action.payload.invoice) {
          return action.payload.invoice;
        } else if (action.payload.shortenedInvoice) {
          return { nip: action.payload.shortenedInvoice };
        }
      };

      const getPaymentAdvance = () => {
        if (values.paymentAdvanceAmount) {
          return {
            paymentAdvance: {
              amount: values.paymentAdvanceAmount,
            },
          };
        }
      };

      const getTicketsFromBasket = () => {
        const itemsIReservation: ITicket[] = [];
        const { basketItems } = action.payload;
        basketItems?.forEach((item) => {
          if (item.isEvent && item.poolId) {
            itemsIReservation.push({
              poolId: item.poolId,
              seats: item.seats,
              ticketsNum: item.numberOfPeople,
            });
          }
        });

        return itemsIReservation;
      };

      const payload = {
        products: selectedProducts(),
        ...values,
        ...getCheckTransactionLinks(),
        ...getDelayProps(),
        ...getPaymentAdvance(),
        agent: values.delayedTransaction ? 'zagrywki-web' : 'zagrywki-onsite',
        discountCode: values.discountCode || null,
        idempotencyKey: values.idempotencyKey || '',
        invoice: getInvoiceData(),
        numberOfPeople: getNumberOfPeople(),
        onDone: action.payload.onDone,

        paymentOperator: values.delayedTransaction ? 3 : 2,
        prepaidCard: values.prepaidCard || undefined,
        reservations: getReservations(),
        salesChannelId: 12,
        sessionIdentifier: session?.session_external_id,
        tickets: getTicketsFromBasket(),
        user: getUserData(),
      };

      delete payload.date;
      delete payload.basketItems;
      delete payload.happeningId;
      delete payload.spaceId;
      delete payload.discount;
      delete payload.shortenedInvoice;

      if (values.delayedTransaction) {
        delete payload.linkCancel;
      }

      if (
        values.emptyReservation &&
        payload.reservations &&
        payload.reservations.length
      ) {
        const currentHappening = payload.reservations[0];

        const getPriceType = () => {
          if (prices.includes('default')) {
            return 'default';
          }

          return prices[0];
        };

        const emptyPayload: IEmptyReservationSaveRequestPayload = {
          ...getSelectedDayIncludeTimeSlot(),
          agent: 'zagrywki-onsite',
          description: '',
          happeningId: currentHappening.happeningId,
          numberOfPeople: getNumberOfPeople(),
          onDone: action.payload.onDone,
          paymentOperator: 3,

          priceReduction: getPriceReduction(
            values.discount || '',
            !!action.payload.basketItems?.[0].isUpSellSelected,
            price ? price : 0,
            getSelectedDayIncludeTimeSlot().dateTime,
            getNumberOfPeople() || 0,
            [],
            values.spaceId ? values.spaceId : 0,
            configurationId || null,
            getPriceType()
          ),
          priceType: getPriceType(),
          salesChannelId: 12,
          spaceId: currentHappening.spaceId,
          user: getUserData(),
        };

        return [postEmptyReservation.request(emptyPayload)];
      }

      if (
        !session &&
        !permissions.includes(allPermissions.access_sale_without_opened_session)
      ) {
        return EMPTY$;
      }

      if (
        values.paymentAdvanceAmount &&
        paymentAdvanceTransactionId &&
        session
      ) {
        const advancePayload: IAdvancePaymentRequestPayload = {
          amount: values.paymentAdvanceAmount,
          sessionIdentifier: session.session_external_id,
        };
        return [postAdvancePaymentReservation.request(advancePayload)];
      }

      if (action.payload.discountCode) {
        return of$(getDependencyTicket.request(payload));
      }

      return [postReservation.request(payload)];
    })
  );
};

export const postEmptyReservationWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi, iframeProvider }
) => {
  return action$.pipe(
    filter$(isActionOf(postEmptyReservation.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const {
        payload,
        payload: { onDone },
      } = action;

      return from$(reservationsApi.saveEmptyReservation(payload)).pipe(
        mergeMap$((data) => {
          onDone();
          return [
            addToast(RESERVATION_WITHOUT_TRANSACTION_DONE_TEXT, TYPE_SUCCESS),
            push(routes.calendar),
          ];
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => reservationsApi.cancelReservationSave())
          )
        ),
        catchError$((error: Error) => {
          return of$(postReservation.failure(error));
        })
      );
    })
  );
};
export const postAdvancePaymentReservationWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(postAdvancePaymentReservation.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const paymentAdvanceTransactionId = getAdvancePaymentId(state);
      const { payload } = action;
      if (paymentAdvanceTransactionId) {
        return from$(
          reservationsApi.saveAdvancePayment(
            payload,
            paymentAdvanceTransactionId
          )
        ).pipe(
          mergeMap$((data) => {
            return [
              transactionSave(data.payment),
              addToast(RESERVATION_WITHOUT_TRANSACTION_DONE_TEXT, TYPE_SUCCESS),
              push(routes.checking),
            ];
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => reservationsApi.cancelReservationSave())
            )
          ),
          catchError$((error: Error) => {
            return of$(postAdvancePaymentReservation.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};
export const postReservationWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(postReservation.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const {
        payload,
        payload: { onDone },
      } = action;

      return from$(reservationsApi.saveNewReservation(payload)).pipe(
        mergeMap$((data) => {
          onDone();

          if (data && data.payment && data.payment.redirect) {
            return [
              transactionSave(data.payment),
              addToast(RESERVATION_ADD_TEXT, TYPE_SUCCESS),
              push(routes.checking),
            ];
          }
          if (data && data.payment && data.payment.formUrl) {
            if (isElectron()) {
              window.location.replace(data.payment.formUrl);
            } else {
              window.location.replace(
                `${window.location.origin}${
                  new URL(data.payment.formUrl).search
                }`
              );
            }
            return of$(addToast(RESERVATION_ADD_TEXT, TYPE_SUCCESS));
          }

          return EMPTY$;
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => reservationsApi.cancelReservationSave())
          )
        ),
        catchError$((error: Error) => {
          return of$(postReservation.failure(error));
        })
      );

      return EMPTY$;
    })
  );
};

export const capturePrintersList: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf([summaryMounted])),
    take$(1),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return of$(getPrinters.request());
    })
  );
};

export const caputeTransactionDetailsWhenSummaryMounted: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf([getPrinters.success, retryBocaPrint])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector(routes.summarySuccess);
      const match: IMatch | null = matchSelector(state);

      if (match && match.params && match.params.id) {
        const { id } = match.params;

        return of$(captureTransactionsEmpikDetailsRequest.request(id));
      }

      return EMPTY$;
    })
  );
};

export const startPingingWhenPrintersFetch: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(getPrintersPinging.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const userData = getUserInfo(state);
      const pinging = getPinging(state);
      if (userData && userData.session && !pinging) {
        return of$(
          startPingingPrinter({
            printerId: userData.session.printer_id,
            startCash: userData.session.start_cash,
            userId: userData.id,
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const captureTransactionEmpikDetailsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi, linksProvider }
) => {
  return action$.pipe(
    filter$(isActionOf(captureTransactionsEmpikDetailsRequest.request)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      return from$(
        reservationsApi.getTransactionEmpikDetails(action.payload)
      ).pipe(
        mergeMap$((data: IReservationPrintData) => {
          if (
            data.transactionItems.length &&
            data.transactionItems.some((item) => item.bookingIdentifier)
          ) {
            return from$(
              reservationsApi.getTransactionItemsCalculatedByPerson(
                action.payload
              )
            ).pipe(
              mergeMap$((response) => {
                const formattedDataItems = data.transactionItems.map(
                  (item) => ({
                    ...item,
                    calculatePricePerPerson:
                      response.items.find(
                        (value) =>
                          value.bookingIdentifier === item.bookingIdentifier
                      )?.calculatedPerPerson || false,
                  })
                );
                return of$(
                  getReservationPrintData.success({
                    ...data,
                    transactionItems: formattedDataItems,
                  }),
                  printBoca.request()
                );
              }),
              catchError$(() => {
                return EMPTY$;
              })
            );
          }
          return of$(
            getReservationPrintData.success(data),
            printBoca.request()
          );
        }),
        catchError$(() => {
          const redirectUrl = linksProvider.buildSummaryFailLink(
            action.payload
          );

          return of$(
            push(redirectUrl),
            captureTransactionsDetailsRequest.failure(
              new Error(HAPPENING_DOESNT_EXIST_TEXT)
            )
          );
        })
      );
    })
  );
};

export const fetchTransactionEmpikDetailsWhenSelectAdvanceTransaction: _Store.IEpic =
  (action$, state$, { reservationsApi, linksProvider }) => {
    return action$.pipe(
      filter$(isActionOf(selectAdvanceTransaction)),
      withLatestFrom$(state$),
      mergeMap$(([action]) => {
        return from$(
          reservationsApi.getTransactionEmpikDetails(action.payload)
        ).pipe(
          mergeMap$((response) => {
            return of$(getReservationPrintData.success(response));
          }),
          catchError$(() => {
            const redirectUrl = linksProvider.buildSummaryFailLink(
              action.payload
            );

            return of$(
              push(redirectUrl),
              captureTransactionsDetailsRequest.failure(
                new Error(HAPPENING_DOESNT_EXIST_TEXT)
              )
            );
          })
        );
      })
    );
  };

export const cancelTransactionWhenPayload: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(cancelTransaction)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      if (action.payload) {
        return from$(reservationsApi.cancelTransaction(action.payload)).pipe(
          mergeMap$(() => {
            return of$(resetSelection(), getHappenings.request());
          }),
          catchError$(() => {
            return EMPTY$;
          })
        );
      }

      const matchSelectorFail = createMatchSelector(routes.summaryFail);
      const matchSelectorCanceled = createMatchSelector(routes.summaryCanceled);

      const matchFail: IMatch | null = matchSelectorFail(state);
      const matchCanceled: IMatch | null = matchSelectorCanceled(state);

      const match = matchFail || matchCanceled;

      if (match && match.params && match.params.id) {
        const { id } = match.params;

        return from$(reservationsApi.cancelTransaction(id)).pipe(
          mergeMap$(() => {
            return EMPTY$;
          }),
          catchError$(() => {
            return EMPTY$;
          })
        );
      }
      return EMPTY$;
    }),
    catchError$(() => {
      return EMPTY$;
    })
  );
};

export const cancelAutoTransactionWhenPayload: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(cancelAutoTransaction)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      if (action.payload) {
        return from$(
          reservationsApi.cancelAutoTransaction(action.payload)
        ).pipe(
          mergeMap$(() => {
            return of$(resetSelection(), getHappenings.request());
          })
        );
      }
      return EMPTY$;
    })
  );
};

export const saveBillWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveBill)),
    mergeMap$((action) => {
      return from$(reservationsApi.saveBill(action.payload)).pipe(
        mergeMap$(() => {
          return [addToast(RECEIPT_HAS_BEEN_SAVED, TYPE_SUCCESS)];
        }),
        catchError$((error: Error) => [
          addToast(SAVE_BILL_ERROR_TEXT, TYPE_ERROR),
        ])
      );
    }),
    catchError$((error: Error) => [addToast(SAVE_BILL_ERROR_TEXT, TYPE_ERROR)])
  );
};

export const getReceiptPrintDataWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getReservationPrintData.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector(routes.summarySuccess);
      const match: IMatch | null = matchSelector(state);

      if (match && match.params && match.params.id) {
        const { id } = match.params;

        return from$(reservationsApi.getTransactionPrintDetails(id)).pipe(
          mergeMap$((data: IReservationPrintData) => {
            return from$(
              reservationsApi.getTransactionItemsCalculatedByPerson(id)
            ).pipe(
              mergeMap$((response) => {
                const formattedDataItems = data.transactionItems.map(
                  (item) => ({
                    ...item,
                    calculatePricePerPerson:
                      response.items.find(
                        (value) =>
                          value.bookingIdentifier === item.bookingIdentifier
                      )?.calculatedPerPerson || false,
                  })
                );
                return of$(
                  getReservationPrintData.success({
                    ...data,
                    transactionItems: formattedDataItems,
                  })
                );
              }),
              catchError$(() => {
                return EMPTY$;
              })
            );
          }),
          catchError$(() => {
            return EMPTY$;
          })
        );
      }
      return EMPTY$;
    })
  );
};

export const getReceiptWhenRequestWhentPrindDataSuccess: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(getReservationPrintData.success)),
    mergeMap$((action) => {
      const { billId } = action.payload;

      if (billId) {
        return of$(addToast(SAVE_BILL_ERROR_TEXT, TYPE_ERROR));
      }
      return of$(printReceipt.request());
    })
  );
};

export const catchCompanyDataWhenRequest: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchCompanyData)),
    mergeMap$((action) => {
      return of$(getCompanyData.request(action.payload));
    })
  );
};

export const getCompanyDataWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getCompanyData.request)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      return from$(reservationsApi.getCompanyData(action.payload)).pipe(
        mergeMap$((response: ICompanyInfoResponse) => {
          const {
            apartmentNumber,
            city,
            propertyNumber,
            name: companyName,
            postCode: zipCode,
            street,
            taxNumber: nip,
          } = response;
          const getPropertyNumber = (): string => {
            if (apartmentNumber) {
              return `${propertyNumber}/${apartmentNumber}`;
            }
            return propertyNumber;
          };

          const data = {
            city,
            companyName,
            facture: true,
            houseNumber: '',
            nip,
            propertyNumber: getPropertyNumber(),
            street,
            zipCode,
          };

          return of$(setCompanyData(data), getCompanyData.success());
        }),
        catchError$(() => {
          return of$(getCompanyData.failure());
        })
      );
    }),
    catchError$(() => {
      return EMPTY$;
    })
  );
};

export const whenGetHappeningCheckTimeIsBetweenMidnightAndTime: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(getHappening.success)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const happening = getHappeningSelector(state);

      if (!happening || !happening.startShowingSlotsAt) {
        return EMPTY$;
      }

      const { startShowingSlotsAt } = happening;

      const slotDateTime = moment();
      const time = moment(startShowingSlotsAt, 'HH:mm:ss');

      slotDateTime.set({
        hour: time.get('hour'),
        minute: time.get('minute'),
        second: time.get('second'),
      });

      const midnightTime = moment().set({
        hour: 0,
        minute: 0,
        second: 0,
      });
      const now = moment();

      const isBetweenMidnightAndTime: boolean = now.isBetween(
        midnightTime,
        slotDateTime
      );

      if (isBetweenMidnightAndTime) {
        return [
          setDate(moment().subtract(1, 'days').toDate()),
          setDurationTimeAfterMidnight(),
        ];
      }

      return [setDate(moment().toDate())];
    })
  );
};

export const deselectTimeSlotWhenPeopleCountChange: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(selectPeopleCount)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      return of$(setTimeSlot(null));
    })
  );
};

export const runRedirectCompleteAdvancePayment: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(completeAdvancePayment)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const advancePaymentId = getAdvancePaymentId(state);
      if (advancePaymentId) {
        return of$(
          push(routes.addReservation),
          captureTransactionsEmpikDetailsRequest.request(advancePaymentId)
        );
      }
      return EMPTY$;
    })
  );
};

export const getDependencyTicketWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getDependencyTicket.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      if (action.payload.discountCode) {
        return from$(
          ticketsApi.getTicketByCode(action.payload.discountCode)
        ).pipe(
          mergeMap$((response) => {
            if (response) {
              const user = {
                email: response.email,
                firstName: response.firstname,
                lastName: response.lastname,
                phone: '-',
                terms: true,
              };
              return of$(
                getDependencyTicket.success({ ...action.payload, user })
              );
            }

            return of$(addToast(CARNET_NOT_FOUND_TEXT, TYPE_ERROR));
          }),
          catchError$((error: Error) => {
            return of$(getDependencyTicket.failure(error));
          })
        );
      }
      return EMPTY$;
    })
  );
};

export const postReservationWithDependencyUserWhenRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(getDependencyTicket.success)),
    mergeMap$((action) => {
      return of$(postReservation.request(action.payload));
    })
  );
};
