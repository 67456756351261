import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import { getPermissions } from '@Model/users/actions';

import { resetError, resetLoading, setLoading } from './../actions';
import { PERMISSIONS } from './../constants/constants';

export const setUpStateWhenPermissionsFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([getPermissions.request])),
    mergeMap$(() => {
      return [resetError(PERMISSIONS), setLoading(PERMISSIONS)];
    })
  );
};

export const setLoaderWhenPermissionsFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getPermissions.success, getPermissions.failure], action)
    ),
    map$(() => resetLoading(PERMISSIONS))
  );
};

export const setErrorOnPermissionsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getPermissions.failure)),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
