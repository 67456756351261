import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  catchDeleteSettlement,
  catchEditSettlement,
  catchGenerateReport,
  catchGenerateReportFile,
  catchSettlementStatusChange,
  resetSingleSettlement,
} from '@Model/settlements/actions';
import {
  getSingleSettlement,
  getSingleSettlementToEdit,
} from '@Model/settlements/selectors';
import { SETTLEMENTS_GENERATE } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import SingleSettlementModal from './SingleSettlementModal.component';
import {
  ISingleSettlementModalFromDispatch,
  ISingleSettlementModalFromState,
} from './SingleSettlementModal.types';

const mapStateToProps = (
  state: _Store.IState
): ISingleSettlementModalFromState => ({
  editSettlement: getSingleSettlementToEdit(state),
  isLoading: getLoading(SETTLEMENTS_GENERATE)(state),
  singleSettlement: getSingleSettlement(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISingleSettlementModalFromDispatch => ({
  catchDeleteSettlement: (id) => dispatch(catchDeleteSettlement(id)),
  catchEditSettlement: (body) => dispatch(catchEditSettlement(body)),
  catchGenerateReportFile: (id, fileName) =>
    dispatch(catchGenerateReportFile({ reportId: id, fileName })),
  catchSettlementStatusChange: (body) =>
    dispatch(catchSettlementStatusChange(body)),
  generateReport: (typeId) => dispatch(catchGenerateReport(typeId)),
  resetSingleSettlement: () => dispatch(resetSingleSettlement()),
});

export default connect<
  ISingleSettlementModalFromState,
  ISingleSettlementModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(SingleSettlementModal);
