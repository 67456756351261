import { getType } from 'typesafe-actions';
import { getEvents, resetSelection } from './../actions';
import { IAction, IEventsReducer } from './../types';

const initialState: IEventsReducer = [];

const eventsReducer = (
  state: IEventsReducer = initialState,
  action: IAction
): IEventsReducer => {
  switch (action.type) {
    case getType(getEvents.success):
      return action.payload;
    case getType(resetSelection):
      return initialState;
    default:
      return state;
  }
};

export default eventsReducer;
