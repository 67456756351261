import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  addSubtractContainer: {
    [theme.breakpoints.up('lg')]: {
      alignItems: 'flex-start',
    },
  },
  addSubtractContainerPerRoom: {
    alignItems: 'flex-start',
  },
  button: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  buttonsWrapper: {
    marginBottom: theme.spacing(1),
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
    },
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  item: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  itemContainer: {
    marginBottom: theme.spacing(3),
  },
  loading: {
    opacity: 0.5,
  },
  normalPrice: {
    textDecoration: 'line-through',
  },
  select: {
    width: '100%',
  },
  summary: {
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
