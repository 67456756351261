import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    textTransform: 'uppercase',
  },
  buttonWithoutPadding: {
    paddingRight: theme.spacing(1.25),
  },
}));

export default useStyles;
