import { connect } from 'react-redux';

import { getProducts } from '@Model/products/selectors';
import _Store from '@Store';
import Products from './Products.component';
import { IProductsFromState } from './Products.types';

const mapStateToProps = (state: _Store.IState): IProductsFromState => {
  const { allProducts: products } = getProducts(state);

  return {
    products,
  };
};

export default connect<IProductsFromState, void, {}, _Store.IState>(
  mapStateToProps,
)(Products);
