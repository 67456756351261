import { createSelector } from 'reselect';

import _Store from '@Store';
import { IPartnerReportFiltersResponse, ISettingsReducer } from './../types';
import get from './get';

const getFilters = createSelector<
  _Store.IState,
  ISettingsReducer,
  IPartnerReportFiltersResponse | null
>([get], (settings) => settings.reportFilters);

export default getFilters;
