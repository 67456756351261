import React from 'react';

import { TableBody, TableRow } from '@mui/material';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import { ISortableItemProps } from './Sortable.types';

export const SortableItem = SortableElement(
  ({ children, onClick, isItemSelected, ...rest }: ISortableItemProps) => (
    <TableRow
      hover={true}
      role="checkbox"
      onClick={onClick}
      selected={isItemSelected}
      tabIndex={-1}
      {...rest}
    >
      {children}
    </TableRow>
  )
);

export const SortableBody = SortableContainer(
  ({ children, className }: { children: any; className?: string }) => {
    return <TableBody className={className}>{children}</TableBody>;
  }
);
