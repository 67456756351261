const PRINTING_ERROR_TEXT = 'PrintingError';

class PrintingError extends Error {
  constructor(...args: any[]) {
    super(...args);
    this.name = PRINTING_ERROR_TEXT;
  }

  public toString() {
    return PRINTING_ERROR_TEXT;
  }

  get [Symbol.toStringTag]() {
    return PRINTING_ERROR_TEXT;
  }
}

export default PrintingError;
