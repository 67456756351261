import { getType } from 'typesafe-actions';

import { IFormIoUserAdmin } from '@Services/$formio-api/types';

import { setUser } from '../actions';
import { IAction } from '../types';

const initialState: IFormIoUserAdmin = {};

const adminUser = (
  state: IFormIoUserAdmin = initialState,
  action: IAction
): IFormIoUserAdmin => {
  switch (action.type) {
    case getType(setUser):
      return action.payload;
    default:
      return state;
  }
};

export default adminUser;
