import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import getCurrencyFromCode from '@Misc/helpers/getCurrencyFromCode';

import { IPoolsResponse } from './types';

class PoolsApi {
  private static getPoolsUrl(eventSlug: string, rundateSlug: string) {
    return `${config.api.baseEmpikTicketUrl}rundate/${eventSlug}/${rundateSlug}/pools`;
  }

  private cancelTokenPools?: CancelTokenSource;

  public getPools(
    eventSlug: string,
    rundateSlug: string
  ): Promise<IPoolsResponse> {
    return new Promise<IPoolsResponse>((resolve, reject) => {
      this.cancelTokenPools = axios.CancelToken.source();

      return axios
        .get(PoolsApi.getPoolsUrl(eventSlug, rundateSlug), {
          cancelToken: this.cancelTokenPools.token,
        })
        .then(getData)
        .then((data: IPoolsResponse) => {
          resolve(this.normalizePool(data));
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public normalizePool(pools: IPoolsResponse): IPoolsResponse {
    return {
      ...pools,
      pools: pools.pools.map((pool) => ({
        ...pool,
        currency: getCurrencyFromCode(pool.currency),
      })),
    };
  }

  public cancelPools() {
    if (this.cancelTokenPools) {
      this.cancelTokenPools.cancel();
      this.cancelTokenPools = undefined;
    }
  }
}

export default new PoolsApi();
