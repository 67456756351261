import moment from 'moment-timezone';
import { createSelector } from 'reselect';

import _Store from '@Store';

import compareTimes from '@Misc/helpers/compareTimes';
import { getAvailabilities, getHappening } from '@Model/happening/selectors';
import {
  IAvailabilitiesReducer,
  IHappeningReducer,
} from '@Model/happening/types';

import { ITimeSlot } from '../types';
import getSelectedDate from './getSelectedDate';
import getSelectedPeopleCount from './getSelectedPeopleCount';
import getSelectedSlot from './getSelectedSlot';
import getSelectedSlotOverbooking from './getSelectedSlotOverbooking';

const BACK_TIME_VALUE_FOR_SALE = -4;

const getTimeSlots = createSelector<
  _Store.IState,
  IHappeningReducer,
  ITimeSlot | null,
  number,
  IAvailabilitiesReducer,
  Date | null,
  number | null,
  ITimeSlot[]
>(
  [
    getHappening,
    getSelectedSlot,
    getSelectedSlotOverbooking,
    getAvailabilities,
    getSelectedDate,
    getSelectedPeopleCount,
  ],
  (
    happening,
    selectedSlot,
    overbooking,
    availabilities,
    selectedDate,
    selectedPeopleCount
  ) => {
    const result: ITimeSlot[] = [];
    const isCurrentDay = moment(selectedDate || new Date()).isSame(
      moment(),
      'day'
    );

    const keys = Object.keys(availabilities).sort((timeA, timeB) =>
      compareTimes(timeA, timeB)
    );

    const isSelected = (startTime: string, keyIndex: number): boolean => {
      let selected = false;

      if (selectedSlot) {
        selected = selectedSlot.startTime === startTime;
        if (!selected && overbooking > 0) {
          for (let i: number = 1; i <= overbooking; i += 1) {
            const prevStartTime = keys[keyIndex - i];
            selected = selectedSlot.startTime === prevStartTime;

            if (selected) {
              break;
            }
          }
        }
      }

      return selected;
    };

    let mergedDays = availabilities.currentDay;
    let hours = Object.keys(availabilities.currentDay || {}).sort((a, b) =>
      a.localeCompare(b)
    );

    const dayAsString = moment(selectedDate || Date.now()).format('YYYY-MM-DD');
    if (availabilities.otherDays) {
      hours = [
        ...hours,
        ...Object.keys(availabilities.otherDays).sort((a, b) =>
          a.localeCompare(b)
        ),
      ];

      mergedDays = {
        ...availabilities.currentDay,
        ...availabilities.otherDays,
      };
    }

    const now = moment.utc().add('hours', BACK_TIME_VALUE_FOR_SALE).valueOf();

    hours.forEach((key: string, index: number) => {
      const spaces = mergedDays[key];

      const slotDateTime = moment(`${dayAsString} ${key}`).utc().valueOf();

      const disableByTime =
        now > slotDateTime &&
        availabilities.currentDay &&
        !!availabilities.currentDay[key];

      const disableByCapacity =
        spaces[0].capacityLeft &&
        selectedPeopleCount &&
        !happening?.allowNextSlotWhenOverbooked
          ? selectedPeopleCount > spaces[0].capacityLeft
          : false;

      const isDisabled =
        spaces.find((space) => {
          return space.available && !space.closed;
        }) === undefined;

      const canReserved = spaces.reduce(
        (prev, curr) => prev || curr.reserved,
        false
      );

      const canBook = spaces.some((space) => {
        if (
          selectedPeopleCount &&
          space.capacityLeft &&
          !happening?.allowNextSlotWhenOverbooked
        ) {
          const isNumberOfPeopleIsAllowed =
            space.capacityLeft && selectedPeopleCount <= space.capacityLeft;

          return isNumberOfPeopleIsAllowed && space.available;
        }

        return space.available;
      });

      if (isDisabled || disableByTime || disableByCapacity) {
        return;
      }

      result.push({
        calculatedDuration: spaces[0].duration,
        duration: moment(key, 'HH:mm')
          .add(spaces[0].duration, 'seconds')
          .format('HH:mm'),
        isDisabled: !canBook || isDisabled,
        isReserved: canReserved,
        isSelected: isSelected(key, index),
        slotDateTime,
        startTime: key,
      });
    });

    if (
      happening &&
      happening.allowNextSlotWhenOverbooked &&
      happening.spaces &&
      happening.spaces.length > 0 &&
      selectedPeopleCount
    ) {
      const maxNumberOfPeople = happening.spaces[0].maxNumberOfPeople;

      const howManySlots = Math.ceil(selectedPeopleCount / maxNumberOfPeople);

      if (howManySlots < 2) {
        return result;
      }

      return result.map((_slot, idx) => {
        if (_slot.isDisabled) {
          return _slot;
        }

        let isEnabled = true;

        for (let slot = 0; slot < howManySlots; slot++) {
          const slotIndex = idx + slot;

          if (slotIndex >= result.length) {
            isEnabled = false;

            break;
          }

          isEnabled = isEnabled && !result[slotIndex].isReserved;
        }

        return {
          ..._slot,
          isDisabled: !isEnabled,
        };
      });
    }

    return result;
  }
);

export default getTimeSlots;
