import React, { FC } from 'react';

import { Button, DialogActions, DialogContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import GenericTable from '@Compo/reusable/GenericTable';
import TableUi from '@Compo/reusable/TableUi';
import { IPrinter } from '@Model/printer/types';

import { IDPrinterTableProps } from './PrinterTable.types';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    paddingRight: theme.spacing(3),
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
    },
  },
  buttonWithoutPadding: {
    paddingRight: theme.spacing(1.25),
  },
}));

const PrinterTable: FC<IDPrinterTableProps> = ({
  Printers,
  printReport,
  close,
}) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'happenings.List.components.ReportModal.PrinterTable',
  });

  const classes = useStyles();

  const confirmModal = (
    event: React.FormEvent<HTMLButtonElement>,
    printer: IPrinter
  ) => {
    event.preventDefault();

    if (window.confirm(`${t('areYouSure')} ${printer.id}?`) && printReport) {
      printReport(printer);
    }
  };
  const columns = [
    { id: 'id', label: t('id') },
    { id: 'printerId', label: t('idPrinter') },
    { id: 'saleUrl', label: t('saleUrl') },
    { id: 'actions', label: t('action') },
  ];

  const rows = Printers.map((printer) => ({
    ...printer,
    actions: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        className={cn(classes.button, classes.buttonWithoutPadding)}
        onClick={(e) => {
          e.stopPropagation();
          confirmModal(e, printer);
        }}
      >
        {t('print')}
      </Button>
    ),
  }));

  return (
    <>
      <DialogContent>
        <GenericTable>
          <TableUi
            rows={rows}
            columns={columns}
            total={Printers.length}
            activePage={0}
            rowsPerPage={Printers.length}
            handleChangePage={() => {}}
            disableCheckBox={true}
          />
        </GenericTable>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={close}
          variant="contained"
          size="large"
          color="primary"
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </>
  );
};

export default PrinterTable;
