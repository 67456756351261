/* tslint:disable:jsx-no-lambda */
import React, { ChangeEvent, Component } from 'react';

import moment from 'moment';
import { withTranslation } from 'react-i18next';

import TimePicker from '@Compo/reusable/TimePicker';

import makeDurationFromPosition from './../../../helpers/makeDurationFromPosition';
import makeHourFromPosition from './../../../helpers/makeHourFromPosition';
import Duration from './../Duration';
import styles from './../Duration/Duration.module.scss';
import {
  IMultiDurationProps,
  IMultiDurationState,
} from './MultiDuration.types';

const BLOCKED_START_TIME_DURATION = 25;

class MultiDuration extends Component<
  IMultiDurationProps,
  IMultiDurationState
> {
  protected n: number;
  protected w: number;
  protected q: number;
  protected translationPrefix: string;

  constructor(props: IMultiDurationProps) {
    super(props);

    this.q = 60; // 60 minutes;
    this.n = 24 * this.q;
    this.w = 100.5 / this.n;
    this.translationPrefix =
      'components:BetterManager.AddingConfiguration.components.MultiDuration.';

    const nextDate = moment().toDate();
    const currentDate = props.values[0].split(':');

    nextDate.setHours(Number(currentDate[0]));
    nextDate.setMinutes(Number(currentDate[1]));

    this.state = this.makeStateFromProps(props.values);

    this.changeDays = this.changeDays.bind(this);
    this.changeStart = this.changeStart.bind(this);
    this.changeStop = this.changeStop.bind(this);
  }

  public render() {
    const { timeErrorMessage } = this.state;
    const firstTimeValue = makeHourFromPosition(
      this.state.positionsFirst[0],
      this.q
    );
    const secondTimeValue = makeHourFromPosition(
      this.state.days > 0
        ? this.state.positionsSecond[1]
        : this.state.positionsFirst[1],
      this.q
    );

    return (
      <>
        <div className={styles.wrapper}>
          <Duration
            onChangeStart={this.changeStart}
            onChangeStop={this.changeStop}
            position={this.state.positionsFirst}
            blockRight={this.state.days > 0}
            w={this.w}
            n={this.n}
            q={this.q}
            key={`first-${this.state.positionsFirst[0]}second-${this.state.positionsFirst[1]}`}
          />

          {this.state.days > 0 && (
            <Duration
              onChangeStart={this.changeStart}
              onChangeStop={this.changeStop}
              position={this.state.positionsSecond}
              key={`first-${this.state.positionsSecond[0]}second-${this.state.positionsSecond[1]}q`}
              blockLeft={true}
              w={this.w}
              n={this.n}
              q={this.q}
            />
          )}
        </div>

        <div className={styles.info}>
          <div>
            <h4>{this.props.t(`${this.translationPrefix}startTime`)}</h4>

            <TimePicker
              className={styles.formGroup}
              invalid={false}
              name="startShowingSlotsAt"
              onChange={(fieldName: string, value: string) => {
                this.changeStartTimePicker(value);
              }}
              timeInterval={1}
              value={firstTimeValue}
              key={firstTimeValue}
            />
          </div>
          <div>
            <input
              type="button"
              className={styles.button}
              onClick={() => {
                this.changeDays(-1);
              }}
              value="-"
            />
            <span className={styles.days}>{this.renderText()}</span>
            <input
              type="button"
              className={styles.button}
              onClick={() => {
                this.changeDays(1);
              }}
              value="+"
            />
          </div>
          <div>
            <h4>{this.props.t(`${this.translationPrefix}endTime`)}</h4>

            <TimePicker
              className={styles.formGroup}
              invalid={false}
              name="startShowingSlotsAt"
              onChange={(fieldName: string, value: string) => {
                this.changeEndTimePicker(value);
              }}
              timeInterval={1}
              value={secondTimeValue}
              key={secondTimeValue}
            />
          </div>
        </div>

        {timeErrorMessage && (
          <div className={styles.timeErrorMessage}>{timeErrorMessage}</div>
        )}
      </>
    );
  }

  protected changeStartTimePicker = (time: string) => {
    const { positionsFirst } = this.makeStateFromProps([time, '0']);

    const isTimeValid = this.checkStartTimeIsValid(
      makeHourFromPosition(positionsFirst[0], this.q),
      makeHourFromPosition(
        this.state.days > 0
          ? this.state.positionsSecond[1]
          : this.state.positionsFirst[1],
        this.q
      )
    );

    if (isTimeValid) {
      this.setState(
        { positionsFirst: [positionsFirst[0], this.state.positionsFirst[1]] },
        () => {
          this.propagateChanges();
        }
      );
    }
  };

  protected checkStartTimeIsValid = (
    pastTime: string,
    featureTIme: string
  ): boolean => {
    const firstDate = moment().toDate();
    const secondDate = moment().toDate();

    const firstTime = pastTime.split(':');
    const secondTime = featureTIme.split(':');

    if (this.state.days > 0) {
      return true;
    }

    if (firstTime && firstTime.length && secondTime && secondTime.length) {
      firstDate.setHours(Number(firstTime[0]));
      firstDate.setMinutes(Number(firstTime[1]));

      secondDate.setHours(Number(secondTime[0]));
      secondDate.setMinutes(Number(secondTime[1]));

      const results = firstDate.getTime() < secondDate.getTime();

      if (results) {
        this.setState({
          timeErrorMessage: '',
        });
      } else {
        this.setState({
          timeErrorMessage: this.props.t(`${this.translationPrefix}timeError`),
        });
      }

      return results;
    }

    return true;
  };

  protected changeEndTimePicker = (time: string) => {
    const parts = time.split(':');
    const position =
      parseInt(parts[0], 10) * this.q +
      Math.floor((parseInt(parts[1], 10) / 60) * this.q);

    const isTimeValid = this.checkStartTimeIsValid(
      makeHourFromPosition(this.state.positionsFirst[0], this.q),
      makeHourFromPosition(this.state.days > 0 ? position : position, this.q)
    );

    if (isTimeValid) {
      if (this.state.days > 0) {
        this.setState(
          {
            positionsFirst: [
              this.state.positionsFirst[0],
              BLOCKED_START_TIME_DURATION * this.q,
            ],
            positionsSecond: [this.state.positionsFirst[0], position],
          },
          () => {
            this.propagateChanges();
          }
        );
      } else {
        this.setState(
          { positionsFirst: [this.state.positionsFirst[0], position] },
          () => {
            this.propagateChanges();
          }
        );
      }
    }
  };

  protected renderText() {
    if (this.state.days === 0) {
      return 'tego samego dnia';
    }

    if (this.state.days === 1) {
      return 'następnego dnia';
    }

    return `${this.state.days} dni później`;
  }

  protected changeDays(n: number) {
    this.setState(
      (state) => {
        const newDays = Math.max(0, state.days + n);

        if (newDays > 0) {
          return {
            ...state,
            days: newDays,
            positionsFirst: [
              this.state.positionsFirst[0],
              BLOCKED_START_TIME_DURATION * this.q,
            ],
          };
        }

        return {
          ...state,
          days: 0,
          positionsFirst: [8 * this.q, 16 * this.q],
          positionsSecond: [0, 16 * this.q],
        };
      },
      () => {
        this.propagateChanges();
      }
    );
  }

  protected changeStart(position: number) {
    this.setState(
      (state) => ({
        positionsFirst: [position, state.positionsFirst[1]],
      }),
      () => {
        this.propagateChanges();
      }
    );
  }

  protected changeStop(position: number) {
    this.setState(
      (state) => {
        if (state.days > 0) {
          return {
            ...state,
            positionsSecond: [state.positionsSecond[0], position],
          };
        }

        return {
          ...state,
          positionsFirst: [state.positionsFirst[0], position],
        };
      },
      () => {
        this.propagateChanges();
      }
    );
  }

  protected propagateChanges() {
    const times = [
      makeHourFromPosition(this.state.positionsFirst[0], this.q),
      makeDurationFromPosition(
        this.state.positionsFirst[0],
        this.state.days > 0
          ? this.state.positionsSecond[1]
          : this.state.positionsFirst[1],
        this.state.days,
        this.q
      ),
    ];

    let which = 0;

    /*
     * This is ugly solution, but we dont have time to do it better.
     * Maybe later we will provide function to change props in sync way.
     */

    this.props.onChange({
      target: {
        name: this.props.names[which],
        value: times[which],
      },
    } as ChangeEvent<any>);

    which += 1;

    setTimeout(() => {
      this.props.onChange({
        target: {
          name: this.props.names[which],
          value: times[which],
        },
      } as ChangeEvent<any>);
    }, 100);
  }

  protected makeStateFromProps(values: string[]) {
    const fromTime = values[0];
    const duration = values[1];

    const parts = fromTime.split(':');

    const position =
      parseInt(!Number.isNaN(Number(parts[0])) ? parts[0] : '8', 10) * this.q +
      Math.floor(
        (parseInt(!Number.isNaN(Number(parts[1])) ? parts[1] : '16', 10) / 60) *
          this.q
      );

    const time = Math.floor((Number(duration) / 3600) * this.q);

    const nextTime = position + time;

    const qPerDay = 24 * this.q;

    const days = Math.floor(nextTime / qPerDay);
    const secondPosition = Math.floor(nextTime % qPerDay);

    return {
      days,
      positionsFirst: [position, secondPosition],
      positionsSecond: [0, secondPosition],
      timeErrorMessage: '',
    };
  }
}

export default withTranslation()(MultiDuration);
