import { LOCATION_CHANGE } from 'connected-react-router';
import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';
import { getStatics, mounted } from '../actions';
import { IGetStaticsSuccessPayload } from '../types';

export const requestStaticsUrlWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    mergeMap$(() => {
      return of$(getStatics.request());
    }),
  );
};

export const fetchStaticsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { staticsApi },
) => {
  return action$.pipe(
    filter$(isActionOf(getStatics.request)),
    mergeMap$(() => {
      return from$(staticsApi.getStaticsUrl()).pipe(
        map$((data: IGetStaticsSuccessPayload) => {
          return getStatics.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => staticsApi.cancelStatics()),
          ),
        ),
        catchError$((error: Error) => {
          return of$(getStatics.failure(error));
        }),
      );
    }),
  );
};
