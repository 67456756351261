// Permissions
export const allPermissions: { [key in TPermissions]: TPermissions } = {
  access_add_reservations_view: 'access_add_reservations_view',
  access_analytics_read: 'access_analytics_read',
  access_analytics_view: 'access_analytics_view',
  access_apartments_read: 'access_apartments_read',
  access_apartments_view: 'access_apartments_view',
  access_apartments_write: 'access_apartments_write',
  access_cashiers_read: 'access_cashiers_read',
  access_cashiers_view: 'access_cashiers_view',
  access_cashiers_write: 'access_cashiers_write',
  access_cashiersessions_read: 'access_cashiersessions_read',
  access_cashiersessions_view: 'access_cashiersessions_view',
  access_cashiersessions_write: 'access_cashiersessions_write',
  access_cashiersessions_write_without_printer:
    'access_cashiersessions_write_without_printer',
  access_cloudinary_delete: 'access_cloudinary_delete',
  access_configurations_read: 'access_configurations_read',
  access_configurations_write: 'access_configurations_write',
  access_discounts_read: 'access_discounts_read',
  access_discounts_view: 'access_discounts_view',
  access_discounts_write: 'access_discounts_write',
  access_event_discounts_read: 'access_event_discounts_read',
  access_event_discounts_refund_going_write:
    'access_event_discounts_refund_going_write',
  access_event_discounts_refund_partner_write:
    'access_event_discounts_refund_partner_write',
  access_event_discounts_write: 'access_event_discounts_write',
  access_event_mailing_read: 'access_event_mailing_read',
  access_event_mailing_send: 'access_event_mailing_send',
  access_event_mailing_write: 'access_event_mailing_write',
  access_happening_discounts_read: 'access_happening_discounts_read',
  access_happenings_read: 'access_happenings_read',
  access_happenings_view: 'access_happenings_view',
  access_happenings_write: 'access_happenings_write',
  access_list_users_form_io_read: 'access_list_users_form_io_read',
  access_open_drawer_write: 'access_open_drawer_write',
  access_partner_read: 'access_partner_read',
  access_partner_write: 'access_partner_write',
  access_permission_delayed_transactions:
    'access_permission_delayed_transactions',
  access_pixel_read: 'access_pixel_read',
  access_pixel_write: 'access_pixel_write',
  access_pool_descriptions_read: 'access_pool_descriptions_read',
  access_pool_descriptions_write: 'access_pool_descriptions_write',
  access_printers_read: 'access_printers_read',
  access_printers_view: 'access_printers_view',
  access_printers_write: 'access_printers_write',
  access_products_read: 'access_products_read',
  access_refund_registration_read: 'access_refund_registration_read',
  access_refunds_write: 'access_refunds_write',
  access_report_bug_view: 'access_report_bug_view',
  access_reports_read: 'access_reports_read',
  access_reservations_read: 'access_reservations_read',
  access_reservations_view: 'access_reservations_view',
  access_reservations_write: 'access_reservations_write',
  access_rules_read: 'access_rules_read',
  access_rules_view: 'access_rules_view',
  access_rundate_read: 'access_rundate_read',
  access_rundate_view: 'access_rundate_view',
  access_rundate_write: 'access_rundate_write',
  access_sale_without_opened_session: 'access_sale_without_opened_session',
  access_seatsio_key_write: 'access_seatsio_key_write',
  access_send_mail_with_transaction: 'access_send_mail_with_transaction',
  access_settlements_filters_read: 'access_settlements_filters_read',
  access_settlements_read: 'access_settlements_read',
  access_settlements_view: 'access_settlements_view',
  access_settlements_write: 'access_settlements_write',
  access_spaces_read: 'access_spaces_read',
  access_spaces_view: 'access_spaces_view',
  access_spaces_write: 'access_spaces_write',
  access_tickets: 'access_tickets',
  access_tickets_scan: 'access_tickets_scan',
  access_transactionless_reservation: 'access_transactionless_reservation',
  access_users_read: 'access_users_read',
  access_users_view: 'access_users_view',
  access_users_write: 'access_users_write',
  events_with_date_range: 'events_with_date_range',
  mdag_user: 'mdag_user',
};

export interface IPermissions {
  access_add_reservations_view: 'access_add_reservations_view';
  access_analytics_view: 'access_analytics_view';
  access_apartments_view: 'access_apartments_view';
  access_apartments_write: 'access_apartments_write';
  access_cashiers_read: 'access_cashiers_read';
  access_cashiers_view: 'access_cashiers_view';
  access_cashiers_write: 'access_cashiers_write';
  access_cashiersessions_read: 'access_cashiersessions_read';
  access_cashiersessions_view: 'access_cashiersessions_view';
  access_cashiersessions_write: 'access_cashiersessions_write';
  access_cashiersessions_write_without_printer: 'access_cashiersessions_write_without_printer';
  access_cloudinary_delete: 'access_cloudinary_delete';
  access_configurations_write: 'access_configurations_write';
  access_discounts_view: 'access_discounts_view';
  access_discounts_write: 'access_discounts_write';
  access_happenings_read: 'access_happenings_read';
  access_happenings_view: 'access_happenings_view';
  access_happenings_write: 'access_happenings_write';
  access_list_users_form_io_read: 'access_list_users_form_io_read';
  access_permission_delayed_transactions: 'access_permission_delayed_transactions';
  access_pixel_read: 'access_pixel_read';
  access_pixel_write: 'access_pixel_write';
  access_printers_read: 'access_printers_read';
  access_printers_view: 'access_printers_view';
  access_printers_write: 'access_printers_write';
  access_refunds_write: 'access_refunds_write';
  access_reports_read: 'access_reports_read';
  access_reservations_view: 'access_reservations_view';
  access_reservations_write: 'access_reservations_write';
  access_rules_view: 'access_rules_view';
  access_spaces_read: 'access_spaces_read';
  access_spaces_view: 'access_spaces_view';
  access_spaces_write: 'access_spaces_write';
  access_rundate_read: 'access_rundate_read';
  access_rundate_view: 'access_rundate_view';
  access_rundate_write: 'access_rundate_write';
  access_settlements_read: 'access_settlements_read';
  access_settlements_view: 'access_settlements_view';
  access_settlements_write: 'access_settlements_write';
  access_tickets: 'access_tickets';
  access_transactionless_reservation: 'access_transactionless_reservation';
  access_event_mailing_read: 'access_event_mailing_read';
  access_event_mailing_send: 'access_event_mailing_send';
  access_event_mailing_write: 'access_event_mailing_write';
  access_event_discounts_write: 'access_event_discounts_write';
  access_event_discounts_read: 'access_event_discounts_read';
  access_happening_discounts_read: 'access_happening_discounts_read';
  access_send_mail_with_transaction: 'access_send_mail_with_transaction';
  access_sale_without_opened_session: 'access_sale_without_opened_session';
  mdag_user: 'mdag_user';
  access_report_bug_view: 'access_report_bug_view';
  events_with_date_range: 'events_with_date_range';
  access_seatsio_key_write: 'access_seatsio_key_write';
  access_settlements_filters_read: 'access_settlements_filters_read';
  access_event_discounts_refund_going_write: 'access_event_discounts_refund_going_write';
  access_event_discounts_refund_partner_write: 'access_event_discounts_refund_partner_write';
  access_tickets_scan: 'access_tickets_scan';
  access_pool_descriptions_write: 'access_pool_descriptions_write';
  access_pool_descriptions_read: 'access_pool_descriptions_read';
  access_users_read: 'access_users_read';
  access_users_view: 'access_users_view';
  access_users_write: 'access_users_write';
  access_apartments_read: 'access_apartments_read';
  access_discounts_read: 'access_discounts_read';
  access_reservations_read: 'access_reservations_read';
  access_rules_read: 'access_rules_read';
  access_analytics_read: 'access_analytics_read';
  access_configurations_read: 'access_configurations_read';
  access_refund_registration_read: 'access_refund_registration_read';
  access_open_drawer_write: 'access_open_drawer_write';
  access_partner_read: 'access_partner_read';
  access_partner_write: 'access_partner_write';
}

export type TPermissions =
  | 'access_analytics_view'
  | 'access_apartments_view'
  | 'access_apartments_write'
  | 'access_cashiers_read'
  | 'access_cashiers_view'
  | 'access_cashiers_write'
  | 'access_cashiersessions_read'
  | 'access_cashiersessions_view'
  | 'access_cashiersessions_write'
  | 'access_cashiersessions_write_without_printer'
  | 'access_cloudinary_delete'
  | 'access_configurations_write'
  | 'access_discounts_view'
  | 'access_discounts_write'
  | 'access_happenings_read'
  | 'access_happenings_view'
  | 'access_happenings_write'
  | 'access_list_users_form_io_read'
  | 'access_permission_delayed_transactions'
  | 'access_pixel_read'
  | 'access_pixel_write'
  | 'access_printers_read'
  | 'access_printers_view'
  | 'access_printers_write'
  | 'access_products_read'
  | 'access_refunds_write'
  | 'access_reports_read'
  | 'access_reservations_view'
  | 'access_reservations_write'
  | 'access_rules_view'
  | 'access_spaces_read'
  | 'access_spaces_view'
  | 'access_spaces_write'
  | 'access_rundate_read'
  | 'access_rundate_view'
  | 'access_rundate_write'
  | 'access_settlements_read'
  | 'access_settlements_view'
  | 'access_settlements_write'
  | 'access_tickets'
  | 'access_transactionless_reservation'
  | 'access_event_mailing_read'
  | 'access_event_mailing_send'
  | 'access_event_mailing_write'
  | 'access_event_discounts_write'
  | 'access_event_discounts_read'
  | 'access_send_mail_with_transaction'
  | 'access_sale_without_opened_session'
  | 'mdag_user'
  | 'access_report_bug_view'
  | 'events_with_date_range'
  | 'access_seatsio_key_write'
  | 'access_happening_discounts_read'
  | 'access_settlements_filters_read'
  | 'access_event_discounts_refund_going_write'
  | 'access_event_discounts_refund_partner_write'
  | 'access_tickets_scan'
  | 'access_pool_descriptions_write'
  | 'access_pool_descriptions_read'
  | 'access_users_read'
  | 'access_users_view'
  | 'access_users_write'
  | 'access_add_reservations_view'
  | 'access_apartments_read'
  | 'access_discounts_read'
  | 'access_reservations_read'
  | 'access_rules_read'
  | 'access_analytics_read'
  | 'access_configurations_read'
  | 'access_refund_registration_read'
  | 'access_open_drawer_write'
  | 'access_partner_read'
  | 'access_partner_write';
