import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import selectColors from '@Misc/helpers/selectColors';

import { IFormLayoutProps } from './FormLayout.types';

const useStyles = makeStyles((theme) => ({
  form: {
    overflowY: 'auto',
  },
  input: {
    marginTop: theme.spacing(8),
  },
  select: {
    alignItems: 'center',
    display: 'flex',
  },
  skeleton: {
    marginRight: theme.spacing(1),
  },
  spacing2: {
    marginTop: theme.spacing(2),
  },
}));

const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Products.CategoryModal.FormLayout',
  });

  const classes = useStyles();

  const {
    closeModal,
    handleSubmit,
    submitForm,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
  } = props;

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent>
          <TextField
            id="name"
            label={(touched.name && errors.name) || t('name')}
            helperText={t('nameHelper')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            name="name"
            error={!!(touched.name && errors.name)}
            fullWidth={true}
          />
          <FormControl
            variant="standard"
            onChange={handleChange}
            defaultValue={'#FF6641'}
            fullWidth={true}
            className={classes.input}
            component="p"
          >
            <InputLabel>
              {(touched.color && errors.color) || t('color')}
            </InputLabel>
            <Select
              value={values.color}
              onChange={handleChange}
              defaultValue={'#FF6641'}
              fullWidth={true}
              name="color"
              onBlur={handleBlur}
              classes={{ select: classes.select }}
            >
              {selectColors.map((color) => (
                <MenuItem key={color.name} value={color.color}>
                  <Skeleton
                    variant="circular"
                    animation={false}
                    width={24}
                    height={24}
                    style={{ backgroundColor: color.color }}
                    className={classes.skeleton}
                  />
                  {color.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
