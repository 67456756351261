import React from 'react';

import { Dialog } from '@mui/material';

import TicketForm from './../TicketForm';
import { ITicketModalProps } from './TicketModal.types';

const TicketModal = ({ showModal, closeModal }: ITicketModalProps) => {
  return (
    <Dialog
      open={showModal}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <TicketForm />
    </Dialog>
  );
};
export default TicketModal;
