import { getType } from 'typesafe-actions';

import { getSeatsIoReport } from '../actions';
import { IAction, ISeatsIoReportReducer } from '../types';

const initialState: ISeatsIoReportReducer = [];

const seatsIoReportReducer = (
  state: ISeatsIoReportReducer = initialState,
  action: IAction
): ISeatsIoReportReducer => {
  switch (action.type) {
    case getType(getSeatsIoReport.success):
      return action.payload;
    default:
      return state;
  }
};

export default seatsIoReportReducer;
