import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPartner } from '@Services/$metadata-api/types';

import { IMetadataReducer } from './../types';
import get from './get';

const getPartners = createSelector<_Store.IState, IMetadataReducer, IPartner[]>(
  [get],
  (metadata) => {
    return metadata.partners;
  }
);

export default getPartners;
