import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { selectReservation } from '@Model/reservations/actions';
import {
  isReservationDetailsListSelected,
  isReservationDetailsSelected,
} from '@Model/reservations/selectors';
import {
  RESERVATIONS_LIST_MODAL,
  TICKETS,
} from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import { mounted, search } from '@Model/tickets/actions';
import { getData, getMeta, getSearchText } from '@Model/tickets/selectors';
import Tickets from './Tickets.component';
import { ITicketsFromDispatch, ITicketsFromState } from './Tickets.types';

const mapStateToProps = (state: _Store.IState): ITicketsFromState => {
  const { size, page, total } = getMeta(state);
  return {
    activePage: page - 1,
    isLoading: getLoading(TICKETS)(state),
    isModalLoading: getLoading(RESERVATIONS_LIST_MODAL)(state),
    isReservationListSelected: isReservationDetailsListSelected(state),
    isReservationSelected: isReservationDetailsSelected(state),
    perPage: size,
    searchText: getSearchText(state),
    tickets: getData(state),
    totalCount: total,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITicketsFromDispatch => {
  return {
    handleActivePage: (text, page) => dispatch(search({ search: text, page })),
    mounted: () => dispatch(mounted()),
    search: (text, page) => dispatch(search({ search: text, page: 1 })),
    selectReservation: (reservationId) =>
      dispatch(selectReservation(reservationId)),
  };
};

export default connect<
  ITicketsFromState,
  ITicketsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Tickets);
