import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { selectTicketList } from '@Model/going/rundates/actions';
import { getSelectedTicketList } from '@Model/going/rundates/selectors';

import TicketsListModal from './TicketsListModal.component';
import {
  ITicketsListModalFromDispatch,
  ITicketsListModalFromState,
} from './TicketsListModal.types';

const mapStateToProps = (state: _Store.IState): ITicketsListModalFromState => ({
  showModal: !!getSelectedTicketList(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITicketsListModalFromDispatch => ({
  closeModal: () => dispatch(selectTicketList(null)),
});

export default connect<
  ITicketsListModalFromState,
  ITicketsListModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(TicketsListModal);
