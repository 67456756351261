import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import { resetState } from '@Model/happening/actions';
import {
  checkReservation,
  checkReservationDate,
  resetCheck,
  resetEmpikReservationDetails,
  resetSelection,
} from '@Model/reservations/actions';
import { getDetails } from '@Model/reservations/selectors';
import _Store from '@Store';

export const requestCheckWhenDateCheckRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(checkReservationDate)),
    map$((action) => checkReservation.request(action.payload))
  );
};

export const fetchDataCheckWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(checkReservation.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const details = getDetails(state);

      if (!details || typeof details === 'number') {
        return EMPTY;
      }

      return from$(
        reservationsApi.checkReservationDate(details.id, action.payload)
      ).pipe(
        map$((data) => {
          return checkReservation.success({
            availability: data.availabilityError,
            price: data.priceWarning,
          });
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => reservationsApi.cancelCheck())
          )
        ),
        catchError$((error: Error) => {
          return of$(checkReservation.failure(error));
        })
      );
    })
  );
};

export const resetCheckWhenResetSelection: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(resetSelection)),
    mergeMap$(() => {
      return of$(resetCheck(), resetState(), resetEmpikReservationDetails());
    })
  );
};
