import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { selectPool } from '@Model/going/rundates/actions';
import { getSelectedPool } from '@Model/going/rundates/selectors';

import PoolsModal from './PoolsModal.component';
import {
  IPoolsModalFromDispatch,
  IPoolsModalFromState,
} from './PoolsModal.types';

const mapStateToProps = (state: _Store.IState): IPoolsModalFromState => ({
  showModal: !!getSelectedPool(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPoolsModalFromDispatch => ({
  closeModal: () => dispatch(selectPool(null)),
});

export default connect<
  IPoolsModalFromState,
  IPoolsModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PoolsModal);
