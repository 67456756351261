import _Store from '@Store';
import { createSelector } from 'reselect';
import { IReservationAddReducer } from './../types';
import getAdd from './getAdd';

const getSelectedPeopleCount = createSelector<
  _Store.IState,
  IReservationAddReducer,
  number | null
>(
  getAdd,
  (add) => add.selectedPeopleCount,
);

export default getSelectedPeopleCount;
