import { Dispatch } from 'react';

import { connect } from 'react-redux';
import { AnyAction } from 'redux';

import _Store from '@Store';

import {
  getAppTitle,
  isMobile,
  isSmallMobile,
  isTablet,
} from '@Model/app/selectors';
import { logout } from '@Model/authorization/actions';
import { get } from '@Model/authorization/selectors';
import { setPartnerID } from '@Model/happenings/actions';
import { get as getHappenings } from '@Model/happenings/selectors';
import { allPermissions } from '@Model/state/constants';

import Grid from './Grid.component';
import { IGridFromDispatch, IGridFromState } from './Grid.types';

const mapStateToProps = (state: _Store.IState): IGridFromState => {
  const mobile = isMobile(state);
  const smallMobile = isSmallMobile(state);
  const tablet = isTablet(state);
  const pageTitle = getAppTitle(state);

  const mobileView = mobile || smallMobile || tablet;

  const {
    userInfo: { permissions, partners },
  } = get(state);

  const { partnerId } = getHappenings(state);

  return {
    permissionDelayedTransactionsView: permissions.includes(
      allPermissions.access_permission_delayed_transactions
    ),
    permissionDiscountsView: permissions.includes(
      allPermissions.access_discounts_read
    ),
    permissionHappeningsView: permissions.includes(
      allPermissions.access_happenings_read
    ),
    permissionPrintersRead: permissions.includes(
      allPermissions.access_printers_read
    ),
    permissionPrintersView: permissions.includes(
      allPermissions.access_printers_read
    ),
    permissionPrintersWrite: permissions.includes(
      allPermissions.access_printers_write
    ),
    permissionReportsRead: permissions.includes(
      allPermissions.access_reports_read
    ),
    permissionReservationView: permissions.includes(
      allPermissions.access_reservations_read
    ),
    permissionRulesView: permissions.includes(allPermissions.access_rules_read),
    permissionStaticsView: permissions.includes(
      allPermissions.access_analytics_view
    ),

    mobileView,
    pageTitle,
    partnerId,
    partners,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IGridFromDispatch => {
  return {
    logout: () => dispatch(logout()),
    setPartnerID: (partnerId: string) => {
      dispatch(setPartnerID(partnerId));
    },
  };
};

export default connect<IGridFromState, IGridFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Grid);
