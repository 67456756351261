import React from 'react';

import { Formik } from 'formik';

import Loader from '@Compo/layout/Loader/Loader';
import FormLayout from '../FormLayout';
import useStyles from './EventDiscountForm.styles';
import {
  IEventDiscountFormProps,
  IEventDiscountValues,
} from './EventDiscountForm.types';
import { EventDiscountSchema } from './EventDiscountForm.validation';

const EventDiscountForm = ({
  save,
  initialValues,
  isLoading,
}: IEventDiscountFormProps) => {
  const handleSubmit = (values: IEventDiscountValues) => {
    save(values);
  };

  const classes = useStyles();

  if (isLoading || !initialValues) {
    return (
      <div className={classes.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EventDiscountSchema}
      onSubmit={handleSubmit}
      component={FormLayout}
    />
  );
};

export default EventDiscountForm;
