import { isActionOf } from 'typesafe-actions';

import { endSessionByPrinterId } from '@Model/authorization/actions';
import {
  endPrinterSession,
  endPrinterSessionById,
  endPrinterSessionWithOutCashState,
  endPrinterSessionWithOutCashStateById,
  getPaymentSession,
  printerPayment,
  printReceipt,
  startSession,
} from '@Model/printer/actions';
import _Store from '@Store';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { resetError, resetLoading, setLoading } from '../actions';
import { PRINTER } from '../constants/constants';

export const setUpStateWhenPrintingRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        printReceipt.request,
        startSession.request,
        endPrinterSessionById.request,
        endPrinterSessionWithOutCashStateById.request,
        endPrinterSessionWithOutCashState.request,
        endPrinterSession.request,
        getPaymentSession.request,

        endSessionByPrinterId,
      ]),
    ),
    mergeMap$(() => {
      return [resetError(PRINTER), setLoading(PRINTER)];
    }),
  );
};

export const setUpStateWhenPrintingFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          printReceipt.failure,
          printReceipt.success,

          startSession.failure,
          startSession.success,

          endPrinterSessionById.failure,
          endPrinterSessionById.success,

          endPrinterSessionWithOutCashStateById.failure,
          endPrinterSessionWithOutCashStateById.success,

          endPrinterSessionWithOutCashState.failure,
          endPrinterSessionWithOutCashState.success,

          endPrinterSession.failure,
          endPrinterSession.success,

          printerPayment.failure,
          getPaymentSession.failure,
        ],
        action,
      ),
    ),
    map$(() => resetLoading(PRINTER)),
  );
};
