import { getType } from 'typesafe-actions';

import { setDurationTimeAfterMidnight } from '../actions';
import { IAction } from '../types';

const initialState: boolean = false;

const durationAfterMidnightReducer = (
  state: boolean = initialState,
  action: IAction,
): boolean => {
  switch (action.type) {
    case getType(setDurationTimeAfterMidnight):
      return true;
    default:
      return state;
  }
};

export default durationAfterMidnightReducer;
