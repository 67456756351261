import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GenericTable from '@Compo/reusable/GenericTable';
import TableUi from '@Compo/reusable/TableUi';

import { IDetailsModalProps } from './ListModal';

const useStyles = makeStyles(() => ({
  loader: { display: 'flex', justifyContent: 'center', marginTop: '5rem' },
}));

const ListModal = ({
  cancel,
  reservations,
  selectReservation,
  isLoading,
}: IDetailsModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Calendar.components.ListModal',
  });

  const classes = useStyles();

  const timezoneStartDate = moment(
    String(reservations?.[0].start).split('+')[0]
  ).format('YYYY-MM-DD HH:mm:ss');

  const timezoneEndDate = moment(
    String(reservations?.[0].end).split('+')[0]
  ).format('YYYY-MM-DD HH:mm:ss');

  const catchEdit = (name: string) => {
    selectReservation(Number(name));
  };

  const columns = [
    { id: 'id', label: t('reservationId') },
    { id: 'title', label: t('reservationTitle') },
  ];

  return (
    <Dialog
      open={!!(reservations && reservations.length) || isLoading}
      maxWidth="md"
      fullWidth={true}
      onClose={cancel}
      scroll="paper"
    >
      {isLoading ? (
        <DialogContent>
          <div className={classes.loader}>
            <Loader />
          </div>
        </DialogContent>
      ) : (
        <>
          <DialogTitle>{`${t(
            'reservationDate'
          )} ${timezoneStartDate} - ${timezoneEndDate}`}</DialogTitle>
          <DialogContent>
            <GenericTable>
              {reservations && (
                <TableUi
                  rows={reservations}
                  columns={columns}
                  total={reservations.length}
                  activePage={0}
                  rowsPerPage={reservations.length}
                  handleChangePage={() => {}}
                  disableCheckBox={true}
                  onRowClick={catchEdit}
                />
              )}
            </GenericTable>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClick={cancel}
            >
              {t('cancel')}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ListModal;
