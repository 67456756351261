import moment from 'moment';

import PrintingError from '@Misc/classes/PrintingError';
import { ITransactionItem } from '@Model/reservation/types';
import { IParameters, ITicket } from '@Services/$boca-printer/types';

import Printer from './classes/Printer';
import TicketGenerator from './classes/TicketGenerator';
import { IPrinting } from './types';

class BocaPrinter {
  private printer: IPrinting;

  constructor() {
    this.printer = new Printer();
  }

  public printTickets = async (
    tickets: ITransactionItem[]
  ): Promise<string | PrintingError> => {
    const printerTickets = tickets.map((ticket) => {
      const parameters: IParameters = { ...ticket };

      const ticketConfigData = ticket.printerConfig?.config;
      if (ticketConfigData) {
        return TicketGenerator.generateTicket(
          ticketConfigData,
          parameters,
          true,
          ticket.printerConfig?.attachmentContent || ''
        );
      }
    });
    const buffers = printerTickets.map((item) => item?.buffer);
    const tempBuffers = [];

    for (const buffer of buffers) {
      if (buffer) {
        tempBuffers.push(buffer);
      }
    }

    return this.printer.print(Buffer.concat(tempBuffers));
  };

  public printTestRawTicket = async (ticketConfigData: ITicket) => {
    const parameters = {
      eventDateTime: moment().format('DD-MM-YYYY hh:mm'),
      eventName: 'Test event name',
      eventNameVertical: 'Test event, vertical name',
      eventPostalCodeCity: '00-000 Test City',
      eventSpotName: 'Test spot name',
      eventStreet: 'Test street 1/2',
      price: '100',
      seats: 'I-1, I-2, I-3',
      ticketCode: '14725JWUQAX',
      title: 'Test print',
    };

    const ticket = TicketGenerator.generateTicket(
      ticketConfigData,
      parameters,
      true,
      ''
    );

    const data = ticket?.buffer;

    return this.printer.print(data);
  };
}

export default new BocaPrinter();
