import { getType } from 'typesafe-actions';

import { MetadataType } from '@Services/$metadata-api/types';

import {
  changeIsFilterByActivePartner,
  getEventsPartner,
  getPartners,
  getPoolsPartner,
  getRundatesPartner,
  handleActivePage,
  resetState,
} from '../actions';
import { IAction, IMetadataReducer } from '../types';

const initialState: IMetadataReducer = {
  events: [],
  filterByActivePartner: false,
  meta: {
    page: 0,
    size: 0,
    total: 0,
  },
  partners: [],
  pools: [],
  rundates: [],
  searchText: '',
  showModal: false,
};

const discountReducer = (
  state: IMetadataReducer = initialState,
  action: IAction
): IMetadataReducer => {
  switch (action.type) {
    case getType(getPartners.success):
      return {
        ...state,
        meta: action.payload.meta,
        partners: action.payload.data,
      };

    case getType(getEventsPartner.success):
      return {
        ...state,
        events: action.payload.data,
        meta: action.payload.meta,
      };

    case getType(getRundatesPartner.success):
      return {
        ...state,
        meta: action.payload.meta,
        rundates: action.payload.data.map((value) => ({
          ...value,
          nameInterface: MetadataType.Rundate,
        })),
      };

    case getType(getPoolsPartner.success):
      return {
        ...state,
        meta: action.payload.meta,
        pools: action.payload.data.map((value) => ({
          ...value,
          nameInterface: MetadataType.Pool,
        })),
      };
    case getType(handleActivePage):
      return {
        ...state,
        meta: {
          ...state.meta,
          page: action.payload.page,
        },
        searchText: action.payload.searchText,
      };
    case getType(changeIsFilterByActivePartner):
      return {
        ...state,
        filterByActivePartner: action.payload,
      };

    case getType(resetState):
      return initialState;
    default:
      return state;
  }
};

export default discountReducer;
