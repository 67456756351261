import axios, { CancelTokenSource } from 'axios';
import qs from 'qs';

import config from '@Config';
import catchHttpError, {
  catchNewErrorMessage,
} from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';

import {
  IChangeRefundsStatus,
  IRefundsRegistration,
  IRefundsRegistrationFilters,
  IRefundsRegistrationMetadataResponse,
  IRefundsRegistrationResponse,
} from './types';

class RefundsRegistrationApi {
  private static getRefundsRegistrationUrl() {
    return `${config.api.cmsServices}refunds-registration`;
  }

  private static getRefundsRegistrationMetadataUrl() {
    return `${this.getRefundsRegistrationUrl()}/metadata`;
  }

  private static getSingleRefundsRegistrationUrl(id: number): string {
    return `${this.getRefundsRegistrationUrl()}/${id}`;
  }

  private static getMultipleRefundsStatusUrl() {
    return `${this.getRefundsRegistrationUrl()}/statuses`;
  }

  private cancelTokenRefundsRegistration?: CancelTokenSource;

  public getRefundsRegistrations(
    page: number,
    filter?: IRefundsRegistrationFilters
  ): Promise<IRefundsRegistrationResponse> {
    return new Promise<IRefundsRegistrationResponse>((resolve, reject) => {
      this.cancelTokenRefundsRegistration = axios.CancelToken.source();

      axios
        .get(RefundsRegistrationApi.getRefundsRegistrationUrl(), {
          cancelToken: this.cancelTokenRefundsRegistration.token,
          params: {
            filter,
            page,
          },
          paramsSerializer(params) {
            return qs.stringify(params, { encode: false });
          },
        })
        .then(getData)
        .then(({ data, meta }) => resolve({ data, meta }))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getRefundsRegistrationsMetadata(): Promise<IRefundsRegistrationMetadataResponse> {
    return new Promise<IRefundsRegistrationMetadataResponse>(
      (resolve, reject) => {
        this.cancelTokenRefundsRegistration = axios.CancelToken.source();
        axios
          .get(RefundsRegistrationApi.getRefundsRegistrationMetadataUrl(), {
            cancelToken: this.cancelTokenRefundsRegistration.token,
          })
          .then(getData)
          .then(getData)
          .then((response) => resolve(response[0]))
          .catch((error) => reject(catchHttpError(error)));
      }
    );
  }

  public getSingleRefundsRegistration(
    id: number
  ): Promise<IRefundsRegistration> {
    return new Promise<IRefundsRegistration>((resolve, reject) => {
      this.cancelTokenRefundsRegistration = axios.CancelToken.source();

      axios
        .get(RefundsRegistrationApi.getSingleRefundsRegistrationUrl(id), {
          cancelToken: this.cancelTokenRefundsRegistration.token,
        })
        .then(getData)
        .then(getData)
        .then((data) => {
          resolve(data[0]);
        })
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public editRefundsRegistration(body: IRefundsRegistration): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenRefundsRegistration = axios.CancelToken.source();
      const { id, bankAccount, refundType, status, supportInfo } = body;

      axios
        .put(
          RefundsRegistrationApi.getSingleRefundsRegistrationUrl(id),
          {
            bankAccount,
            refundType: refundType.code,
            status: status.code,
            supportInfo,
          },
          {
            cancelToken: this.cancelTokenRefundsRegistration.token,
          }
        )
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public editMultipleStatues(body: IChangeRefundsStatus): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenRefundsRegistration = axios.CancelToken.source();

      axios
        .put(RefundsRegistrationApi.getMultipleRefundsStatusUrl(), body, {
          cancelToken: this.cancelTokenRefundsRegistration.token,
        })
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public cancelRefundsRegistration() {
    if (this.cancelTokenRefundsRegistration) {
      this.cancelTokenRefundsRegistration.cancel();
      this.cancelTokenRefundsRegistration = undefined;
    }
  }
}

export default new RefundsRegistrationApi();
