import { getType } from 'typesafe-actions';
import { addToast, removeToast } from './../actions';
import { IAction, IToastReducer } from './../types';

const initialToastsState: IToastReducer = [];

export function toastsReducer(state = initialToastsState, action: IAction) {
  switch (action.type) {
    // add or replace
    case getType(addToast):
      const index = state.findIndex((toast) => toast.id === action.payload.id);

      if (index >= 0) {
        const copiedState = [...state];

        copiedState[index] = action.payload;

        return copiedState;
      }

      return [...state, action.payload];

    case getType(removeToast):
      return state.filter((toast) => toast.id !== action.payload.id);

    default:
      return state;
  }
}

export default toastsReducer;
