import { createSelector } from 'reselect';

import _Store from '@Store';
import { IDiscountReducer } from './../types';
import get from './get';

const getSearchText = createSelector<_Store.IState, IDiscountReducer, string>(
  [get],
  (discount) => {
    return discount.searchText;
  }
);

export default getSearchText;
