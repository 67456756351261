import React from 'react';

import Grid from '@Compo/layout/Grid';
import PriceTypes from '@Compo/PriceTypes';

const PriceTypesPage = () => {
  return (
    <Grid>
      <PriceTypes />
    </Grid>
  );
};

export default PriceTypesPage;
