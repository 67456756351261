import { Selector } from 'reselect';

import _Store from '@Store';

const getEmpikReservationTicketValidated: Selector<
  _Store.IState,
  boolean | null
> = (state) => {
  if (
    state.reservations &&
    state.reservations.empikReservationDetails &&
    state.reservations.empikReservationDetails.transactionItems &&
    state.reservations.empikReservationDetails.transactionItems.length
  ) {
    return state.reservations.empikReservationDetails.transactionItems[0]
      .validated;
  }
  return null;
};

export default getEmpikReservationTicketValidated;
