import React from 'react';

import { Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';

import { IFormLayoutProps } from './EditFormLayout.types';

const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Settlements.SingleSettlementModal.components.EditFormLayout',
  });

  const { handleSubmit, values, handleChange, handleBlur, touched, errors } =
    props;

  return (
    <form onSubmit={handleSubmit}>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <LabelContentContainer>
            <TextField
              variant="standard"
              id="name"
              label={(touched.name && errors.name) || t('name')}
              helperText={t('nameHelper')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              name="name"
              error={!!(touched.name && errors.name)}
              fullWidth={true}
            />
          </LabelContentContainer>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormLayout;
