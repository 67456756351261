import React from 'react';

import NewDefinedConfigurations from '@Compo/BetterManager/NewDefinedConfigurations';
import ConfigurationModal from './components/ConfigurationModal';

const NewAddingConfiguration = () => {
  return (
    <>
      <ConfigurationModal />
      <NewDefinedConfigurations />
    </>
  );
};

export default NewAddingConfiguration;
