import { createSelector } from 'reselect';

import _Store from '@Store';
import { IRundate, ISettlementsReducer } from './../types';
import get from './get';

const getSettlementRundates = createSelector<
  _Store.IState,
  ISettlementsReducer,
  IRundate[]
>([get], (settlement) => settlement.settlementRundates);

export default getSettlementRundates;
