import { Notifier } from '@airbrake/browser';
import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError, {
  catchNewErrorMessage,
} from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { ISession } from '@Model/authorization/types';
import {
  IGetPrintersSuccessPayload,
  IPrinter,
  IPrinterReceiptPayload,
  IPrinterResponseCashStatus,
  IPrinterStatus,
  IStartPrinterSessionPayload,
} from '@Model/printer/types/index';

class PrinterApi {
  private static getSavePrinterUrl(): string {
    return `${config.api.baseUrl}admin/printers`;
  }
  private static getUpdateSinglePrinterUrl(): string {
    return `${config.api.baseUrl}admin/printers`;
  }
  private static getPrintersUrl(): string {
    return `${config.api.baseUrl}admin/printers`;
  }
  private static geRemovePrinterUrl(id: number): string {
    return `${config.api.baseUrl}admin/printers/${id}/delete`;
  }
  private static getCommandUrl(url: string): string {
    return `${url}command`;
  }
  private static getReportUrl(url: string): string {
    return `${url}raporty/dobowy`;
  }
  private static getPrinterStatusUrl(url: string): string {
    return `${url}status`;
  }

  private cancelTokenGetPrinters?: CancelTokenSource;

  public addSinglePrinter(printer: IPrinter): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (printer.edit) {
        const url = PrinterApi.getUpdateSinglePrinterUrl();
        axios
          .put(url, JSON.stringify(printer))
          .then(getData)
          .then(() => {
            resolve();
          });
      } else {
        const url = PrinterApi.getSavePrinterUrl();
        axios
          .post(url, JSON.stringify(printer))
          .then(() => {
            resolve();
          })
          .catch((error) => reject(catchHttpError(error)));
      }
    });
  }

  public removePrinter(id: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      axios
        .post(PrinterApi.geRemovePrinterUrl(id))
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getPrinters(partnerId?: string): Promise<IGetPrintersSuccessPayload> {
    return new Promise<IGetPrintersSuccessPayload>((resolve, reject) => {
      this.cancelTokenGetPrinters = axios.CancelToken.source();
      axios
        .get(PrinterApi.getPrintersUrl(), {
          cancelToken: this.cancelTokenGetPrinters.token,
          params: {
            partnerIds: partnerId ? [partnerId] : null,
          },
        })
        .then(getData)
        .then((response: IGetPrintersSuccessPayload) => {
          resolve(response);
        })
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public printReceipt(
    url: string,
    printData: IPrinterReceiptPayload,
    session?: ISession
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const http = new XMLHttpRequest();
      http.open('POST', url, true);

      // Send the proper header information along with the request
      http.setRequestHeader('Content-type', 'application/json');

      http.onreadystatechange = () => {
        if (http.readyState === 4) {
          if (http.status === 200) {
            const parsedResponse = JSON.parse(http.response);
            if (parsedResponse) {
              if (parsedResponse.ok) {
                resolve();
              } else {
                this.reportBug(
                  new Error(JSON.stringify(http.response)),
                  JSON.stringify(printData),
                  url,
                  JSON.stringify(session)
                );
              }
            } else {
              this.reportBug(
                new Error(JSON.stringify(http.response)),
                JSON.stringify(printData),
                url,
                JSON.stringify(session)
              );

              reject(http.response);
            }
          } else {
            this.reportBug(
              new Error(
                http.response.length
                  ? JSON.stringify(http.response)
                  : 'Empty response (CONNECTION_REFUSED)'
              ),
              JSON.stringify(printData),
              url,
              JSON.stringify(session)
            );

            reject(http.response);
          }
        }
      };

      http.ontimeout = () => {
        this.reportBug(
          new Error('Timeout'),
          JSON.stringify(printData),
          url,
          JSON.stringify(session)
        );
      };

      http.send(JSON.stringify(printData));
    });
  }

  public startSession(
    url: string,
    printData: IStartPrinterSessionPayload,
    session?: ISession,
    abort?: boolean
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const http = new XMLHttpRequest();
      http.open('POST', PrinterApi.getCommandUrl(url), true);
      if (abort) {
        http.abort();
      }

      // Send the proper header information along with the request
      http.setRequestHeader('Content-type', 'application/json');

      http.onreadystatechange = () => {
        if (http.readyState === 4) {
          if (http.status === 200) {
            resolve();
          } else {
            this.reportBug(
              new Error(http.response),
              JSON.stringify(printData),
              url,
              JSON.stringify(session)
            );

            reject();
          }
        }
      };

      http.onabort = () => {
        this.reportBug(
          new Error(http.response),
          JSON.stringify(printData),
          url,
          JSON.stringify(session),
          '[ABORT] Print start or end session'
        );
      };

      http.onerror = () => {
        this.reportBug(
          new Error(http.response),
          JSON.stringify(printData),
          url,
          JSON.stringify(session),
          '[ERROR] Print start or end session'
        );
      };

      http.send(JSON.stringify(printData));
    });
  }

  public printTemplate(
    url: string,
    printData: IStartPrinterSessionPayload,
    session?: ISession
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const http = new XMLHttpRequest();
      http.open('POST', PrinterApi.getCommandUrl(url), true);

      // Send the proper header information along with the request
      http.setRequestHeader('Content-type', 'application/json');

      http.onreadystatechange = () => {
        if (http.readyState === 4) {
          if (http.status === 200) {
            resolve();
          } else {
            this.reportBug(
              new Error(http.response),
              JSON.stringify(printData),
              url,
              JSON.stringify(session)
            );

            reject();
          }
        }
      };
      http.send(JSON.stringify(printData));
    });
  }

  public pullDrawer(url: string, session?: ISession): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const http = new XMLHttpRequest();

      const command = [{ cmd: 'opendrwr' }];

      http.open('POST', PrinterApi.getCommandUrl(url), true);

      // Send the proper header information along with the request
      http.setRequestHeader('Content-type', 'application/json');

      http.onreadystatechange = () => {
        if (http.readyState === 4) {
          if (http.status === 200) {
            resolve();
          } else {
            this.reportBug(
              new Error(http.response),
              JSON.stringify(command),
              url,
              JSON.stringify(session)
            );
            reject();
          }
        }
      };
      http.send(JSON.stringify(command));
    });
  }

  public getCashStatus(
    url: string,
    printData: IStartPrinterSessionPayload,
    session?: ISession
  ): Promise<IPrinterResponseCashStatus> {
    return new Promise<IPrinterResponseCashStatus>((resolve, reject) => {
      const http = new XMLHttpRequest();
      http.open('POST', PrinterApi.getCommandUrl(url), true);

      // Send the proper header information along with the request
      http.setRequestHeader('Content-type', 'application/json');

      http.onreadystatechange = (data) => {
        const response = data as any;

        if (http.readyState === 4) {
          if (
            http.status === 200 &&
            response &&
            response.currentTarget &&
            response.currentTarget.status === 200 &&
            response.currentTarget.response
          ) {
            resolve(JSON.parse(response.currentTarget.response));
          } else {
            this.reportBug(
              new Error(http.response),
              JSON.stringify(printData),
              url,
              JSON.stringify(session)
            );
            reject();
          }
        }
      };
      http.send(JSON.stringify(printData));
    });
  }
  public printReport(
    url: string,
    session?: ISession
  ): Promise<IPrinterResponseCashStatus> {
    return new Promise<IPrinterResponseCashStatus>((resolve, reject) => {
      const http = new XMLHttpRequest();
      http.open('GET', PrinterApi.getReportUrl(url), true);

      // Send the proper header information along with the request
      http.setRequestHeader('Content-type', 'application/json');

      http.onreadystatechange = (data) => {
        const response = data as any;

        if (http.readyState === 4) {
          if (
            http.status === 200 &&
            response &&
            response.currentTarget &&
            response.currentTarget.status === 200 &&
            response.currentTarget.response
          ) {
            resolve(JSON.parse(response.currentTarget.response));
          } else {
            this.reportBug(
              new Error(http.response),
              JSON.stringify('GET REPORT'),
              url,
              JSON.stringify(session)
            );
            reject();
          }
        }
      };
      http.send();
    });
  }

  public getPrinterStatus(url: string): Promise<IPrinterStatus> {
    return new Promise<IPrinterStatus>((resolve, reject) => {
      const http = new XMLHttpRequest();
      http.open('GET', PrinterApi.getPrinterStatusUrl(url), true);

      // Send the proper header information along with the request
      http.setRequestHeader('Content-type', 'application/json');

      http.onreadystatechange = (data) => {
        const response = data as any;

        if (http.readyState === 4) {
          if (
            http.status === 200 &&
            response &&
            response.currentTarget &&
            response.currentTarget.status === 200 &&
            response.currentTarget.response
          ) {
            resolve(JSON.parse(response.currentTarget.response));
          } else {
            reject();
          }
        }
      };
      http.send();
    });
  }

  public cancelPrint() {
    if (this.cancelTokenGetPrinters) {
      this.cancelTokenGetPrinters.cancel();
      this.cancelTokenGetPrinters = undefined;
    }
  }

  public cancelGetPrinters() {
    if (this.cancelTokenGetPrinters) {
      this.cancelTokenGetPrinters.cancel();
      this.cancelTokenGetPrinters = undefined;
    }
  }

  public reportBug(
    error: Error,
    printingRequest: string,
    url: string,
    session: string,
    errorCode?: string
  ) {
    const airbrake = new Notifier({
      projectId: 286058,
      projectKey: '5d35eca83ab06ad6e874519161d2fb6c',
    });
    airbrake.notify({
      context: {
        component: '$printer-api',
        environment: config.cms.environment,
      },
      environment: { environment: process.env.NODE_ENV },
      error,
      params: { printingRequest, url, error, session, errorCode },
      session: { session },
    });
  }
}

export default new PrinterApi();
