// Private
export const _GET_REQUEST = 'Zagrywki/tickets/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/tickets/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/tickets/_GET_FAILURE';

export const _TICKET_STATUS_REQUEST = 'Zagrywki/tickets/_TICKET_STATUS_REQUEST';
export const _TICKET_STATUS_SUCCESS = 'Zagrywki/tickets/_TICKET_STATUS_SUCCESS';
export const _TICKET_STATUS_FAILURE = 'Zagrywki/tickets/_TICKET_STATUS_FAILURE';

export const _TICKET_STATUS_DELETION_REQUEST =
  'Zagrywki/tickets/_TICKET_STATUS_DELETION_REQUEST';
export const _TICKET_STATUS_DELETION_SUCCESS =
  'Zagrywki/tickets/_TICKET_STATUS_DELETION_SUCCESS';
export const _TICKET_STATUS_DELETION_FAILURE =
  'Zagrywki/tickets/_TICKET_STATUS_DELETION_FAILURE';

// Public
export const MOUNTED = 'Zagrywki/tickets/MOUNTED';
export const SEARCH = 'Zagrywki/tickets/SEARCH';
export const SEARCH_TICKET = 'Zagrywki/tickets/SEARCH_TICKET';
