import React, { FC, useEffect, useRef } from 'react';

import { IClickOutsideContainerProps } from './ClickOutsideContainer';

const ClickOutsideContainer: FC<IClickOutsideContainerProps> = ({
  children,
  className,
  onClickOutside,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const handleClickOutside = (event: any) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      onClickOutside();
    }
  };

  return (
    <div className={className} ref={containerRef}>
      {children}
    </div>
  );
};

export default ClickOutsideContainer;
