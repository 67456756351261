import routes from '@/routes/routes';
import {
  createMatchSelector,
  LOCATION_CHANGE,
  RouterRootState,
} from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getInitialValues as getInitialSingleTicketValues } from '@Compo/Rundates/TicketsList/components/FormLayout/components/PreviewTicket/Form/Form.helpers';
import { getInitialValues } from '@Compo/Rundates/TicketsList/components/TicketsListForm/TicketsListForm.helper';
import {
  getSearchTicketText,
  getSelectedTicketList,
} from '@Model/going/rundates/selectors';
import { showSuccessModal } from '@Model/modal/actions';

import {
  deleteMultipleTickets,
  deleteSingleTicket,
  deleteTicket,
  deleteTickets,
  editTicketList,
  fetchRundateReport,
  fetchSingleTicket,
  fetchTickets,
  getRundateReport,
  getRundateStats,
  getSingleTicket,
  getTicket,
  getTickets,
  getTicketsList,
  mounted,
  revertSingleTicket,
  revertTicket,
  saveRundateTicket,
  saveRundateTicketList,
  saveTicket,
  saveTicketList,
  selectTicket,
  updateSingleTicket,
  updateTicket,
} from '../actions';
import { selectSingleTicket, selectTicketList } from './../actions/index';

export const fetchTicketsWhenMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;

        return of$(getTicketsList.request(Number(id)));
      }

      return EMPTY$;
    })
  );
};

export const getTicketsListWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getTicketsList.request)),
    mergeMap$((action) => {
      return from$(goingRundateApi.getTicketsList(action.payload)).pipe(
        mergeMap$((response) => {
          return of$(getTicketsList.success(response));
        }),
        catchError$((error: Error) => {
          return of$(getTicketsList.failure(error));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => goingRundateApi.cancelEvents())
          )
        )
      );
    })
  );
};

export const getSingleTicketWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf([editTicketList, getSingleTicket.request])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;
        return from$(
          goingRundateApi.getSingleTicket(Number(id), action.payload)
        ).pipe(
          mergeMap$((response) => {
            return of$(selectTicketList(getInitialValues(response)));
          }),
          catchError$((error: Error) => {
            return of$(getSingleTicket.failure(error));
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          )
        );
      }
      return EMPTY$;
    })
  );
};

export const saveTicketsListWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveTicketList)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;
        return from$(
          goingRundateApi.saveTicketsList(Number(id), action.payload)
        ).pipe(
          mergeMap$((response) => {
            return of$(
              saveRundateTicketList.success(response),
              getTicketsList.request(Number(id)),
              showSuccessModal(),
              selectTicketList(null)
            );
          }),
          catchError$((error: Error) => {
            return of$(saveRundateTicketList.failure(error));
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          )
        );
      }
      return EMPTY$;
    })
  );
};

export const saveTicketWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveTicket)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const body = {
        ...action.payload,
        invitationsNumber:
          Number(action.payload.invitationsNumber) || undefined,
        ticketsNum: action.payload.ticketsNum
          ? Number(action.payload.ticketsNum)
          : !!action.payload.seats?.length
          ? action.payload.seats?.length
          : undefined,
      };

      delete body.isSeats;
      delete body.isMultiple;

      if (action.payload.isSeats) {
        delete body.ticketsNum;
        delete body.title;
        delete body.mailTemplate;
        delete body.pdfTemplate;
        delete body.sendMail;

        body.invitationsNumber = body.seats?.length;

        return from$(ticketsApi.saveSeatsTicket(body)).pipe(
          mergeMap$((response) => {
            return of$(
              saveRundateTicket.success(response),
              showSuccessModal(),
              selectTicket(null),
              getTickets.request({
                id: body.ticketListId,
                isDeleted: false,
                page: 1,
                size: 20,
              }),
              getRundateStats.request()
            );
          }),
          catchError$((error: Error) => {
            return of$(saveRundateTicket.failure(error));
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => ticketsApi.cancelTickets())
            )
          )
        );
      }

      if (!action.payload.isMultiple) {
        delete body.invitationsNumber;
        return from$(ticketsApi.saveTicket(body)).pipe(
          mergeMap$((response) => {
            return of$(
              saveRundateTicket.success(response),
              showSuccessModal(),
              selectTicket(null),
              getTickets.request({
                id: body.ticketListId,
                isDeleted: false,
                page: 1,
                size: 20,
              }),
              getRundateStats.request()
            );
          }),
          catchError$((error: Error) => {
            return of$(saveRundateTicket.failure(error));
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => ticketsApi.cancelTickets())
            )
          )
        );
      }

      delete body.seats;
      delete body.sendMail;
      delete body.title;
      delete body.mailTemplate;
      delete body.pdfTemplate;

      return from$(ticketsApi.saveGenerateTicket(body)).pipe(
        mergeMap$((response) => {
          return of$(
            saveRundateTicket.success(response),
            showSuccessModal(),
            selectTicket(null),
            getTickets.request({
              id: body.ticketListId,
              isDeleted: false,
              page: 1,
              size: 20,
            }),
            getRundateStats.request()
          );
        }),
        catchError$((error: Error) => {
          return of$(saveRundateTicket.failure(error));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => ticketsApi.cancelTickets())
          )
        )
      );
    })
  );
};

export const getTicketListTicketsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { goingRundateApi }
) => {
  return action$.pipe(
    filter$(isActionOf([fetchTickets, getTickets.request])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);
      const searchText = getSearchTicketText(state);

      if (match && match.params) {
        const { id } = match.params;
        return from$(
          goingRundateApi.getTicketsListTickets(
            Number(id),
            action.payload.id,
            action.payload.page,
            action.payload.size,
            action.payload.isDeleted,
            searchText
          )
        ).pipe(
          mergeMap$((response) => {
            return of$(getTickets.success(response));
          }),
          catchError$((error: Error) => {
            return of$(getTickets.failure(error));
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => goingRundateApi.cancelEvents())
            )
          )
        );
      }
      return EMPTY$;
    })
  );
};

export const getTicketWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(fetchSingleTicket)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(ticketsApi.getSingleTicket(action.payload)).pipe(
        mergeMap$((response) => {
          return of$(
            getTicket.success(response),
            selectSingleTicket(getInitialSingleTicketValues(response))
          );
        }),
        catchError$((error: Error) => {
          return of$(getTicket.failure(error));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => ticketsApi.cancelTickets())
          )
        )
      );
    })
  );
};

export const deleteTicketWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(deleteSingleTicket)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedTicketList = getSelectedTicketList(state);

      return from$(ticketsApi.deleteSingleTicket(action.payload)).pipe(
        mergeMap$((response) => {
          if (selectedTicketList && selectedTicketList.id) {
            return of$(
              deleteTicket.success(response),
              showSuccessModal(),
              getTickets.request({
                id: selectedTicketList.id,
                isDeleted: false,
                page: 1,
                size: 20,
              })
            );
          }

          return of$(
            deleteTicket.success(response),
            showSuccessModal(),
            getRundateStats.request()
          );
        }),
        catchError$((error: Error) => {
          return of$(deleteTicket.failure(error));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => ticketsApi.cancelTickets())
          )
        )
      );
    })
  );
};

export const deleteMultipleTicketsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(deleteMultipleTickets)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedTicketList = getSelectedTicketList(state);

      return from$(ticketsApi.deleteMultipleTickets(action.payload)).pipe(
        mergeMap$((response) => {
          if (selectedTicketList && selectedTicketList.id) {
            return of$(
              deleteTickets.success(response),
              showSuccessModal(),
              getTickets.request({
                id: selectedTicketList.id,
                isDeleted: false,
                page: 1,
                size: 20,
              }),
              getRundateStats.request()
            );
          }

          return of$(deleteTickets.success(response), showSuccessModal());
        }),
        catchError$((error: Error) => {
          return of$(deleteTickets.failure(error));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => ticketsApi.cancelTickets())
          )
        )
      );
    })
  );
};

export const revertTicketWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(revertSingleTicket)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedTicketList = getSelectedTicketList(state);

      return from$(ticketsApi.revertSingleTicket(action.payload)).pipe(
        mergeMap$((response) => {
          if (selectedTicketList && selectedTicketList.id) {
            return of$(
              revertTicket.success(response),
              showSuccessModal(),
              getTickets.request({
                id: selectedTicketList.id,
                isDeleted: true,
                page: 1,
                size: 20,
              }),
              getRundateStats.request()
            );
          }

          return of$(revertTicket.success(response), showSuccessModal());
        }),
        catchError$((error: Error) => {
          return of$(revertTicket.failure(error));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => ticketsApi.cancelTickets())
          )
        )
      );
    })
  );
};

export const updateTicketWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(updateSingleTicket)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const body = { ...action.payload };
      const selectedTicketList = getSelectedTicketList(state);

      delete body.code;

      if (action.payload.code) {
        return from$(ticketsApi.updateTicket(action.payload.code, body)).pipe(
          mergeMap$((response) => {
            if (selectedTicketList && selectedTicketList.id) {
              return of$(
                updateTicket.success(response),
                showSuccessModal(),
                selectSingleTicket(null),
                getTickets.request({
                  id: selectedTicketList.id,
                  isDeleted: false,
                  page: 1,
                  size: 20,
                })
              );
            }

            return of$(
              updateTicket.success(response),
              showSuccessModal(),
              selectSingleTicket(null)
            );
          }),
          catchError$((error: Error) => {
            return of$(updateTicket.failure(error));
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => ticketsApi.cancelTickets())
            )
          )
        );
      }

      return EMPTY$;
    })
  );
};

export const getRundateReportWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { ticketsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(fetchRundateReport)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const matchSelector = createMatchSelector<
        RouterRootState,
        { id: string }
      >(routes.rundateEdit);
      const match = matchSelector(state);

      if (match && match.params) {
        const { id } = match.params;
        return from$(
          ticketsApi.getRundateReport(Number(id), action.payload)
        ).pipe(
          mergeMap$((response) => {
            const file = new Blob([response.data]);
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = response.fileName;
            link.click();

            return of$(
              getRundateReport.success(response),
              showSuccessModal({ message: 'Plik wygenerowano pomyślnie.' })
            );
          }),
          catchError$((error: Error) => {
            return of$(getRundateReport.failure(error));
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => ticketsApi.cancelTickets())
            )
          )
        );
      }
      return EMPTY$;
    })
  );
};
