import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  version: {
    bottom: 0,
    position: 'fixed',
    right: 0,
  },
}));

export default useStyles;
