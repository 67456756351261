import _Store from '@Store';
import { createSelector } from 'reselect';
import { ITicketsReducer } from '../types';
import get from './get';

const getSearchText = createSelector<_Store.IState, ITicketsReducer, string>(
  [get],
  (tickets) => tickets.searchText
);

export default getSearchText;
