import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { TRANSACTIONS } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';
import {
  changeFilter,
  changeSize,
  handleActivePage,
  mounted,
  setActiveTransaction,
} from '@Model/transactions/actions';
import {
  getFilters,
  getMeta,
  getTransactions,
} from '@Model/transactions/selectors';
import i18next from '@Translations/i18n';

import Transactions from './Transactions.component';
import { normalizeTransactionsToTable } from './Transactions.helpers';
import {
  ITransactionsFromDispatch,
  ITransactionsFromState,
} from './Transactions.types';

const mapStateToProps = (state: _Store.IState): ITransactionsFromState => ({
  filters: getFilters(state),
  isLoading: getLoading(TRANSACTIONS)(state),
  meta: getMeta(state),
  normalizedTransactions: normalizeTransactionsToTable(
    getTransactions(state),
    i18next.t
  ),
  transactions: getTransactions(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITransactionsFromDispatch => ({
  changeFilter: (filter) => dispatch(changeFilter(filter)),
  changeSize: (size) => dispatch(changeSize(size)),
  handleActivePage: (page) => dispatch(handleActivePage(page)),
  mounted: () => dispatch(mounted()),
  setActiveTransaction: (transaction) =>
    dispatch(setActiveTransaction(transaction)),
});

export default connect<
  ITransactionsFromState,
  ITransactionsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Transactions);
