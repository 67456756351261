import React, { MouseEvent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { arrayMove } from 'react-sortable-hoc';

import ConfirmModal from '@Compo/reusable/ConfirmModal';
import DonutChart from '@Compo/reusable/DonutChart';
import { IChartData } from '@Compo/reusable/DonutChart/DonutChart.types';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import { TypographyVariant } from '@Constants/Variants/Typography';

import PoolsModal from './components/PoolsModal';
import useStyles from './Pools.styles';
import { INormalizedPool, IPoolsProps } from './Pools.types';

const Pools = ({
  pools,
  deletePool,
  addNewPool,
  editPool,
  reorderPools,
  isLoading,
}: IPoolsProps) => {
  const { t, i18n } = useTranslation('components', {
    keyPrefix: 'Rundates.Pools',
  });

  const [isSortable, setIsSortable] = useState(false);
  const [poolsState, setPoolsState] = useState<INormalizedPool[]>([]);
  const [disableEdit, setDisableEdit] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPoolId, setSelectedPoolId] = useState<number | null>(null);

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedPoolId(null);
  };

  const handleConfirmDeleteModal = () => {
    if (selectedPoolId) {
      deletePool(selectedPoolId);
    }
    setSelectedPoolId(null);
    handleCloseDeleteModal();
  };

  const columns = [
    { id: 'id', label: t('poolId') },
    { id: 'name', label: t('poolDescription'), width: 200 },
    {
      id: i18n.language === 'pl' ? 'poolGroupPL' : 'poolGroupEN',
      label: t('groupName'),
    },
    { id: 'isActive', label: t('poolIsActive') },
    { id: 'price', label: t('poolPrice') },
    { id: 'sales', label: t('poolSale') },
    { id: 'actions', label: '' },
  ];

  const handleDeletePool = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    id: number
  ) => {
    event.stopPropagation();
    setSelectedPoolId(id);
    setShowDeleteModal(true);
  };

  const catchEditPool = (id: string) => {
    editPool(Number(id));
  };

  const toggleSortable = () => {
    setIsSortable((oldState) => !oldState);
    if (isSortable) {
      reorderPools(poolsState.map((pool) => pool.id));
    }
  };

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    setPoolsState(arrayMove(poolsState, oldIndex, newIndex));
  };

  const onMouseEnter = () => {
    setDisableEdit(true);
  };

  const onMouseLeave = () => {
    setDisableEdit(false);
  };

  useEffect(() => {
    const normalizedPools = pools.map((pool) => {
      const poolMaxStats = pool.emissionSize || 0;
      const poolStats: IChartData[] = [
        {
          color: theme.palette.warning.light,
          label: t('sold'),
          value: pool.sold,
        },
      ];

      if (pool.emissionSize) {
        poolStats.push({
          color: theme.palette.success.light,
          label: t('available'),
          value: pool.emissionSize - pool.sold,
        });
      }

      return {
        ...pool,
        actions: (
          <div
            onMouseEnter={pool.isActive ? onMouseEnter : undefined}
            onMouseLeave={onMouseLeave}
          >
            <Button
              variant="outlined"
              size="small"
              color="primary"
              className={classes.buttonWithoutPadding}
              disabled={pool.isActive}
              onClick={(event) => {
                handleDeletePool(event, pool.id);
              }}
            >
              {t('delete')}
            </Button>
          </div>
        ),
        sales: (
          <DonutChart
            isActive={!!pool.emissionSize}
            data={poolStats}
            maxValue={poolMaxStats}
            hideMobile={true}
          />
        ),
      };
    });

    setPoolsState(normalizedPools);
  }, [JSON.stringify(pools)]);

  return (
    <Grid container={true} spacing={3}>
      <ConfirmModal
        open={showDeleteModal}
        title={t('deleteModalTitle')}
        question={t('deleteModalDescription')}
        handleClose={handleCloseDeleteModal}
        onCancel={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteModal}
      />
      <Grid item={true} xs={12}>
        <Typography variant={TypographyVariant.h6}>
          {t('poolsTitle')}
        </Typography>
      </Grid>
      <Grid item={true} xs={12}>
        <GenericTable>
          <PoolsModal />
          <TableActions>
            <Grid container={true} spacing={2} justifyContent="flex-end">
              <Grid item={true}>
                <Button
                  variant={isSortable ? 'contained' : 'outlined'}
                  size="small"
                  color={isSortable ? 'primary' : 'secondary'}
                  startIcon={<ImportExportIcon />}
                  className={classes.button}
                  onClick={toggleSortable}
                >
                  {isSortable ? t('saveOrder') : t('changeOrder')}
                </Button>
              </Grid>
              <Grid item={true}>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  startIcon={<AddIcon />}
                  className={classes.button}
                  onClick={addNewPool}
                >
                  {t('add')}
                </Button>
              </Grid>
            </Grid>
          </TableActions>
          <TableUi
            rows={poolsState}
            columns={columns}
            disableCheckBox={true}
            onRowClick={catchEditPool}
            isSortable={isSortable}
            onSortEnd={onSortEnd}
            isLoading={isLoading}
            disableOnRowClick={disableEdit}
          />
        </GenericTable>
      </Grid>
    </Grid>
  );
};

export default Pools;
