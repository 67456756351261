import {
  resetError,
  resetLoading,
  setError,
  setLoading,
} from '@Model/state/actions';
import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { _ERROR_MESSAGE, _LOADING } from './../constants/constants';
import { IAction, ISubState } from './../types';

export const initialSubState: ISubState = {
  /*
   * When API asked and waits to response.
   */
  [_LOADING]: false,

  /*
   * When API error occurred save it's message.
   */
  [_ERROR_MESSAGE]: '',
};

const createSubStateReducer = (key: string): Reducer<ISubState, any> => (
  state = initialSubState,
  action: IAction
): ISubState => {
  if (action.payload && action.payload.module === key) {
    switch (action.type) {
      case getType(resetLoading):
        return { ...state, [_LOADING]: false };

      case getType(setLoading):
        return { ...state, [_LOADING]: true };

      case getType(resetError):
        return { ...state, [_ERROR_MESSAGE]: '' };

      case getType(setError):
        return { ...state, [_ERROR_MESSAGE]: action.payload.message };

      default:
        return state;
    }
  }

  return state;
};

export default createSubStateReducer;
