import _Store from '@Store';
import { createSelector } from 'reselect';
import { IAvailablePoolsReducer, IPool } from './../types';
import getAvailablePools from './getAvailablePools';

const getPools = createSelector<_Store.IState, IAvailablePoolsReducer, IPool[]>(
  [getAvailablePools],
  (all) => (all ? all.pools : [])
);

export default getPools;
