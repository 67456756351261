// Private
export const _GET_REQUEST = 'Zagrywki/products/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/products/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/products/_GET_FAILURE';
// Private
export const _GET_ALL_REQUEST = 'Zagrywki/products/_GET_ALL_REQUEST';
export const _GET_ALL_SUCCESS = 'Zagrywki/products/_GET_ALL_SUCCESS';
export const _GET_ALL_FAILURE = 'Zagrywki/products/_GET_ALL_FAILURE';

export const _GET_ALL_LOCAL_PRODUCTS_REQUEST =
  'Zagrywki/products/_GET_ALL_LOCAL_PRODUCTS_REQUEST';
export const _GET_ALL_LOCAL_PRODUCTS_SUCCESS =
  'Zagrywki/products/_GET_ALL_LOCAL_PRODUCTS_SUCCESS';
export const _GET_ALL_LOCAL_PRODUCTS_FAILURE =
  'Zagrywki/products/_GET_ALL_LOCAL_PRODUCTS_FAILURE';

export const _GET_ALL_CATEGORIES_REQUEST =
  'Zagrywki/products/_GET_ALL_CATEGORIES_REQUEST';
export const _GET_ALL_CATEGORIES_SUCCESS =
  'Zagrywki/products/_GET_ALL_CATEGORIES_SUCCESS';
export const _GET_ALL_CATEGORIES_FAILURE =
  'Zagrywki/products/_GET_ALL_CATEGORIES_FAILURE';

export const _GET_ALL_SHIPMENT_FEE_REQUEST =
  'Zagrywki/products/_GET_ALL_SHIPMENT_FEE_REQUEST';
export const _GET_ALL_SHIPMENT_FEE_SUCCESS =
  'Zagrywki/products/_GET_ALL_SHIPMENT_FEE_SUCCESS';
export const _GET_ALL_SHIPMENT_FEE_FAILURE =
  'Zagrywki/products/_GET_ALL_SHIPMENT_FEE_FAILURE';

export const _GET_ALL_ZOHO_PRODUCTS_REQUEST =
  'Zagrywki/products/_GET_ALL_ZOHO_PRODUCTS_REQUEST';
export const _GET_ALL_ZOHO_PRODUCTS_SUCCESS =
  'Zagrywki/products/_GET_ALL_ZOHO_PRODUCTS_SUCCESS';
export const _GET_ALL_ZOHO_PRODUCTS_FAILURE =
  'Zagrywki/products/_GET_ALL_ZOHO_PRODUCTS_FAILURE';

export const _GET_ALL_INVOICE_PRODUCTS_REQUEST =
  'Zagrywki/products/_GET_ALL_INVOICE_PRODUCTS_REQUEST';
export const _GET_ALL_INVOICE_PRODUCTS_SUCCESS =
  'Zagrywki/products/_GET_ALL_INVOICE_PRODUCTS_SUCCESS';
export const _GET_ALL_INVOICE_PRODUCTS_FAILURE =
  'Zagrywki/products/_GET_ALL_INVOICE_PRODUCTS_FAILURE';

export const _GET_ALL_PRODUCTS_WITH_CATEGORIES_REQUEST =
  'Zagrywki/products/_GET_ALL_PRODUCTS_WITH_CATEGORIES_REQUEST';
export const _GET_ALL_PRODUCTS_WITH_CATEGORIES_SUCCESS =
  'Zagrywki/products/_GET_ALL_PRODUCTS_WITH_CATEGORIES_SUCCESS';
export const _GET_ALL_PRODUCTS_WITH_CATEGORIES_FAILURE =
  'Zagrywki/products/_GET_ALL_PRODUCTS_WITH_CATEGORIES_FAILURE';

export const _GET_ALL_PRODUCTS_BY_CATEGORY_REQUEST =
  'Zagrywki/products/_GET_ALL_PRODUCTS_BY_CATEGORY_REQUEST';
export const _GET_ALL_PRODUCTS_BY_CATEGORY_SUCCESS =
  'Zagrywki/products/_GET_ALL_PRODUCTS_BY_CATEGORY_SUCCESS';
export const _GET_ALL_PRODUCTS_BY_CATEGORY_FAILURE =
  'Zagrywki/products/_GET_ALL_PRODUCTS_BY_CATEGORY_FAILURE';

export const _GET_SINGLE_PRODUCT_REQUEST =
  'Zagrywki/products/_GET_SINGLE_PRODUCT_REQUEST';
export const _GET_SINGLE_PRODUCT_SUCCESS =
  'Zagrywki/products/_GET_SINGLE_PRODUCT_SUCCESS';
export const _GET_SINGLE_PRODUCT_FAILURE =
  'Zagrywki/products/_GET_SINGLE_PRODUCT_FAILURE';

export const ADD_PRODUCT = 'Zagrywki/products/ADD_PRODUCT';
export const REMOVE_PRODUCT = 'Zagrywki/products/REMOVE_PRODUCT';
export const SELECT_PRODUCT = 'Zagrywki/products/SELECT_PRODUCT';
export const UN_SELECT_PRODUCT = 'Zagrywki/products/UN_SELECT_PRODUCT';
export const UPDATE_LIST = 'Zagrywki/products/UPDATE_LIST';
export const SET_PRODUCT_COUNT = 'Zagrywki/products/SET_PRODUCT_COUNT';
export const SET_PRODUCTS_VIEW = 'Zagrywki/products/SET_PRODUCTS_VIEW';
export const MOUNTED = 'Zagrywki/products/MOUNTED';

export const ADD_CATEGORY = 'Zagrywki/products/ADD_CATEGORY';
export const HANDLE_ACTIVE_CATEGORY =
  'Zagrywki/products/HANDLE_ACTIVE_CATEGORY';
export const ADD_LOCAL_PRODUCT = 'Zagrywki/products/ADD_LOCAL_PRODUCT';
export const HANDLE_ACTIVE_LOCAL_PRODUCT =
  'Zagrywki/products/HANDLE_ACTIVE_LOCAL_PRODUCT';
export const ADD_SHIPMENT_FEE = 'Zagrywki/products/ADD_SHIPMENT_FEE';
export const HANDLE_ACTIVE_SHIPMENT_FEE =
  'Zagrywki/products/HANDLE_ACTIVE_SHIPMENT_FEE';
export const HANDLE_ACTIVE_PAGE = 'Zagrywki/products/HANDLE_ACTIVE_PAGE';
export const HANDLE_ACTIVE_ZOHO_PRODUCT =
  'Zagrywki/products/HANDLE_ACTIVE_ZOHO_PRODUCT';
export const HANDLE_ACTIVE_INVOICE_PRODUCT =
  'Zagrywki/products/HANDLE_ACTIVE_INVOICE_PRODUCT';

export const SAVE_CATEGORY = 'Zagrywki/products/SAVE_CATEGORY';
export const SAVE_PRODUCT = 'Zagrywki/products/SAVE_PRODUCT';
export const REMOVE_LOCAL_PRODUCT = 'Zagrywki/products/REMOVE_LOCAL_PRODUCT';
export const REMOVE_CATEGORY = 'Zagrywki/products/REMOVE_CATEGORY';
export const PRODUCTS_LIST_MOUNTED = 'Zagrywki/products/PRODUCTS_LIST_MOUNTED';
export const SET_PRODUCT_STORAGE = 'Zagrywki/products/SET_PRODUCT_STORAGE';
