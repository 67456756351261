import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = ({
  errors,
  touched,
  handleSubmit,
  closeModal,
  handleChange,
  handleBlur,
  values,
  submitForm,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'Mail.components.FormLayout.components.TestEmailModal.FormLayout',
  });

  const classes = useStyles();

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <TextField
                id="password"
                label={(touched.email && errors.email) || t('email')}
                helperText={t('emailHelper')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                name="email"
                error={!!(touched.email && errors.email)}
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('send')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
