import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  content: {
    overflow: 'hidden',
  },
  form: {
    overflowY: 'auto',
  },
}));

export default useStyles;
