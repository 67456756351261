import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IFormValuesProps } from '@Compo/Settings/ReportFilterModal/FormLayout/FormLayout.types';

import {
  _DELETE_FILTER_FAILURE,
  _DELETE_FILTER_REQUEST,
  _DELETE_FILTER_SUCCESS,
  _GET_PARTNER_FAILURE,
  _GET_PARTNER_FILTERS_REPORT_FAILURE,
  _GET_PARTNER_FILTERS_REPORT_PARAMETERS_FAILURE,
  _GET_PARTNER_FILTERS_REPORT_PARAMETERS_REQUEST,
  _GET_PARTNER_FILTERS_REPORT_PARAMETERS_SUCCESS,
  _GET_PARTNER_FILTERS_REPORT_REQUEST,
  _GET_PARTNER_FILTERS_REPORT_SUCCESS,
  _GET_PARTNER_REQUEST,
  _GET_PARTNER_SUCCESS,
  _GET_PIXEL_FAILURE,
  _GET_PIXEL_REQUEST,
  _GET_PIXEL_SUCCESS,
  _SAVE_FILTER_FAILURE,
  _SAVE_FILTER_REQUEST,
  _SAVE_FILTER_SUCCESS,
  _SAVE_PARTNER_FAILURE,
  _SAVE_PARTNER_REQUEST,
  _SAVE_PARTNER_SUCCESS,
  _SAVE_PIXEL_FAILURE,
  _SAVE_PIXEL_REQUEST,
  _SAVE_PIXEL_SUCCESS,
  CATCH_CHANGE_PAGE_FILTER,
  CATCH_DELETE_FILTER,
  CATCH_SAVE_FILTER,
  CATCH_SAVE_PARTNER,
  CATCH_SAVE_PIXEL,
  HANDLE_ACTIVE_FILTER,
  MOUNTED,
} from '../constants/actions';
import {
  IActiveFilter,
  IPartnerReportFiltersBody,
  IPartnerReportFiltersParamsResponse,
  IPartnerReportFiltersResponse,
  IPartnerResponse,
  IPartnerSavePayload,
  IPixelResponse,
  IPixelSavePayload,
} from '../types';

export const mounted = createStandardAction(MOUNTED)();

export const handleActiveFilter =
  createStandardAction(HANDLE_ACTIVE_FILTER)<IActiveFilter>();

export const catchSaveFilter =
  createStandardAction(CATCH_SAVE_FILTER)<IFormValuesProps>();

export const catchDeleteFilter =
  createStandardAction(CATCH_DELETE_FILTER)<number>();

export const catchSavePixel =
  createStandardAction(CATCH_SAVE_PIXEL)<IPixelSavePayload>();

export const catchSavePartner =
  createStandardAction(CATCH_SAVE_PARTNER)<IPartnerSavePayload>();

export const changeFilterPage = createStandardAction(
  CATCH_CHANGE_PAGE_FILTER
)<number>();

export const getPartnerReportFilters = createAsyncAction(
  _GET_PARTNER_FILTERS_REPORT_REQUEST,
  _GET_PARTNER_FILTERS_REPORT_SUCCESS,
  _GET_PARTNER_FILTERS_REPORT_FAILURE
)<IPartnerReportFiltersBody, IPartnerReportFiltersResponse, Error>();

export const getPartnerReportFiltersParameters = createAsyncAction(
  _GET_PARTNER_FILTERS_REPORT_PARAMETERS_REQUEST,
  _GET_PARTNER_FILTERS_REPORT_PARAMETERS_SUCCESS,
  _GET_PARTNER_FILTERS_REPORT_PARAMETERS_FAILURE
)<undefined, IPartnerReportFiltersParamsResponse, Error>();

export const getPixel = createAsyncAction(
  _GET_PIXEL_REQUEST,
  _GET_PIXEL_SUCCESS,
  _GET_PIXEL_FAILURE
)<undefined, IPixelResponse, Error>();

export const getPartner = createAsyncAction(
  _GET_PARTNER_REQUEST,
  _GET_PARTNER_SUCCESS,
  _GET_PARTNER_FAILURE
)<undefined, IPartnerResponse, Error>();

export const saveFilter = createAsyncAction(
  _SAVE_FILTER_REQUEST,
  _SAVE_FILTER_SUCCESS,
  _SAVE_FILTER_FAILURE
)<IFormValuesProps, undefined, Error>();

export const deleteFilter = createAsyncAction(
  _DELETE_FILTER_REQUEST,
  _DELETE_FILTER_SUCCESS,
  _DELETE_FILTER_FAILURE
)<number, undefined, Error>();

export const savePixel = createAsyncAction(
  _SAVE_PIXEL_REQUEST,
  _SAVE_PIXEL_SUCCESS,
  _SAVE_PIXEL_FAILURE
)<IFormValuesProps, undefined, Error>();

export const savePartner = createAsyncAction(
  _SAVE_PARTNER_REQUEST,
  _SAVE_PARTNER_SUCCESS,
  _SAVE_PARTNER_FAILURE
)<IFormValuesProps, undefined, Error>();
