import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import {
  catchGenerateReport,
  generateReport,
} from '@Model/settlements/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { resetError, resetLoading, setLoading } from './../actions';
import { SETTLEMENTS_GENERATE } from './../constants/constants';

export const setUpStateWhenSettlementsGenerateFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([catchGenerateReport, generateReport.request])),
    mergeMap$(() => [
      resetError(SETTLEMENTS_GENERATE),
      setLoading(SETTLEMENTS_GENERATE),
    ])
  );
};

export const setLoaderWhenSettlementsGenerateFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([generateReport.success, generateReport.failure], action)
    ),
    map$(() => resetLoading(SETTLEMENTS_GENERATE))
  );
};

export const setErrorOnSettlementsGenerateFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([generateReport.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
