import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.text.primary,
  },
  button: {
    borderRadius: 4,
  },
  image: {
    height: 284,
    width: 284,
  },
  planContainer: {
    backgroundColor: theme.palette.action.focus,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
  },
  tooltip: {
    backgroundColor: theme.palette.text.primary,
  },
}));

export default useStyles;
