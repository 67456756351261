import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import { add } from '@Model/happening/actions';
import { HAPPENING } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import Add from './Add.component';
import { IAddFromDispatch, IAddFromState } from './Add.types';

const mapStateToProps = (state: _Store.IState): IAddFromState => {
  return {
    isLoading: getLoading(HAPPENING)(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IAddFromDispatch => {
  return {
    save: (happening) => {
      dispatch(add(happening));
    },
  };
};

export default withRouter(
  connect<IAddFromState, IAddFromDispatch, {}, _Store.IState>(
    mapStateToProps,
    mapDispatchToProps
  )(Add)
);
