/* tslint:disable:jsx-no-lambda */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import mapHappeningToStateAndInitialValues, {
  normalizeValuesToSend,
} from '@Compo/BetterManager/Manager/Manager.helpers';
import { ManagerConfigurationSchema } from '@Compo/BetterManager/Manager/Manager.validation';

import FormLayout from './components/FormLayout';
import {
  IManagerProps,
  IManagerRef,
  IManagerValues,
  ISpace,
} from './Manager.types';

const Manager = forwardRef<IManagerRef, IManagerProps>((props, ref) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'BetterManager.Manager',
  });

  const { happening, handleSave, mounted, errorMessage, mountedProducts } =
    props;
  const formRef = useRef<any>();
  const [initialValues, setInitialValues] = useState(
    mapHappeningToStateAndInitialValues(happening)
  );

  const [spaces, setSpaces] = useState<ISpace[]>(initialValues.spaces);
  useEffect(() => {
    mounted();
    mountedProducts();
    setInitialValues(mapHappeningToStateAndInitialValues(happening));
  }, [happening]);

  useEffect(() => {
    setSpaces(initialValues.spaces);
  }, [initialValues]);

  const send = (values: IManagerValues) => {
    handleSave(normalizeValuesToSend(values));
  };

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (formRef.current) {
        formRef.current.validateForm().then((res: any) => {
          const fields = Object.keys(res);
          fields.forEach((field) =>
            formRef.current?.setFieldTouched(field, true)
          );
          if (fields.length) {
            errorMessage(t('error'));
          }

          formRef.current?.submitForm();
        });
      }
    },
  }));

  return (
    <Formik
      ref={formRef}
      initialValues={initialValues}
      validationSchema={ManagerConfigurationSchema}
      onSubmit={(values) => {
        send({ ...values, spaces });
      }}
      children={(propsFormik) => (
        <FormLayout {...propsFormik} spaces={spaces} setSpaces={setSpaces} />
      )}
      enableReinitialize={true}
    />
  );
});

export default Manager;
