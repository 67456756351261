import React, { FC, useEffect, useState } from 'react';

import Check from '@mui/icons-material/Check';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import GoingPaper from '@Compo/layout/Paper/GoingPaper';

import styles from './Stepper.module.scss';
import {
  IQontoStepIcon,
  IStep,
  IStepper,
  IStepperSlugs,
} from './Stepper.types';

let prevSlugs: IStepperSlugs[] = [];

const useQontoStepIconStyles = makeStyles({
  active: {
    color: '#784af4',
  },
  circle: {
    backgroundColor: 'currentColor',
    borderRadius: '50%',
    height: 8,
    width: 8,
  },
  completed: {
    color: '#784af4',
    fontSize: 18,
    zIndex: 1,
  },
  root: {
    alignItems: 'center',
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
  },
});

function QontoStepIcon(props: IQontoStepIcon) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  active: {
    alignItems: 'center',
    backgroundColor: '#FF6641',
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    height: 20,
    justifyContent: 'center',
    width: 20,
    zIndex: 1,
  },
  circle: {
    backgroundColor: '#474250',
    borderRadius: '50%',
    height: 8,
    width: 8,
  },
  completed: {
    backgroundColor: '#FF6641',
    borderRadius: '50%',
    height: 20,
    width: 20,
  },

  customMessage: {
    background: 'transparent',
    border: '2px solid #FF6641',
    borderRadius: '3px',
    bottom: '100%',
    display: 'block',
    fontSize: '12px',
    height: '33px',
    left: '50%',
    padding: '5px 10px',
    position: 'absolute',
    textAlign: 'center',
    top: '-53px',
    transform: 'translate(-50%, 0)',
    width: 'max-content',

    '&::after': {
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent ',
      borderTop: 'solid #262A35 6px',
      bottom: '-6px',
      content: '""',
      height: 0,
      left: 'calc(50% + 7px)',
      marginLeft: '-13px',
      position: 'absolute',
      width: 0,
    },

    '&::before': {
      borderColor: '#FF6641 transparent transparent transparent',
      borderStyle: 'solid',
      borderWidth: '9px',
      bottom: '-20px',
      content: '""',
      display: 'block',
      height: '20px',
      left: 'calc(50% + 7px)',
      marginLeft: '-16px',
      position: 'absolute',
    },
  },
  pointer: {
    background: 'ivory',
    borderRadius: '0 0 12px 0',
    height: '40px',
    left: 'calc(0.5vw - 50px)',
    margin: '0 auto',
    marginTop: '-22px',
    position: 'relative',
    transform: 'rotate(45deg)',
    width: '40px',
  },
  reletive: {
    position: 'relative',
  },
  root: {
    alignItems: 'center',
    backgroundColor: '#FF6641',
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    height: 20,
    justifyContent: 'center',
    width: 20,
    zIndex: 1,
  },
});

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    'align-items': 'flex-end',
    display: 'flex',
    'justify-content': 'flex-end',
  },
  instructions: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  nextButton: {
    backgroundColor: '#FF6641',
    borderRadius: '24px',
    color: 'white',
    fontSize: '16px',
    marginRight: theme.spacing(1),
    textTransform: 'capitalize',
    width: '200px',
  },
  root: {
    backgroundColor: 'transparent',
    color: 'white',
    marginTop: 0,
    width: '100%',
  },
  stepper: {
    backgroundColor: 'transparent',
    paddingBottom: '10px',
    paddingLeft: 0,
    paddingTop: '40px',
  },
}));

export const CustomizedSteppers: FC<{
  activeStep?: number;
  stepSlug: IStepperSlugs;
  steps: IStepper;
  hideStepper?: boolean;
  onDone?: () => void;
  canBeDonne?: boolean;
  setStepSlug?: (slug: IStepperSlugs) => void;
  prevSlug?: IStepperSlugs;
  onNext?: () => void;
  nextText?: string;
  prevText?: string;
  renderHeader?: JSX.Element;
}> = ({
  activeStep = 0,
  stepSlug,
  steps,
  children,
  hideStepper,
  setStepSlug,
  prevSlug,
  onNext,
  nextText,
  prevText,
  renderHeader,
}) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reusable.Stepper',
  });

  const classes = useStyles();

  const [isBack, setIsBack] = useState(false);

  const currentStep = steps[stepSlug];

  const handleBack = () => {
    if (setStepSlug && activeStep > 0) {
      setStepSlug(prevSlugs[activeStep - 1]);
      prevSlugs.pop();
      setIsBack(true);
    }
  };

  useEffect(() => {
    if (activeStep === 0) {
      setIsBack(false);
      prevSlugs = [];
    }
    if (prevSlug && !isBack && activeStep !== 0) {
      prevSlugs.push(prevSlug);
    }
  }, [activeStep]);

  const handleNext = () => {
    setIsBack(false);
    if (onNext) {
      onNext();
    }
  };

  const stepsKeys: IStepperSlugs[] = Object.keys(steps) as IStepperSlugs[];

  const stepsArray: IStep[] = [];

  stepsKeys.forEach((v) => {
    stepsArray.push(steps[v]);
  });

  const renderButtons = () => (
    <Grid container={true} justifyContent="flex-end" spacing={2}>
      {prevSlug && activeStep !== 0 && (
        <Grid item={true} xs={12} lg="auto">
          <Button
            color="secondary"
            variant="outlined"
            size="large"
            onClick={handleBack}
            fullWidth={true}
          >
            {prevText || t('prev')}
          </Button>
        </Grid>
      )}
      {onNext && (
        <Grid item={true} xs={12} lg="auto">
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleNext}
            fullWidth={true}
          >
            {nextText}
          </Button>
        </Grid>
      )}
    </Grid>
  );

  return (
    <GoingPaper
      title={currentStep.title}
      description={currentStep.description}
      buttons={renderButtons()}
      header={renderHeader}
    >
      {children}
    </GoingPaper>
  );
};

export default CustomizedSteppers;
