import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getTicketsList } from '@Model/going/rundates/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { resetError, resetLoading, setLoading } from '../actions';
import { GOING_TICKETS } from '../constants/constants';

export const setUpStateWhenGoingTicketsFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(getTicketsList.request)),
    mergeMap$(() => [resetError(GOING_TICKETS), setLoading(GOING_TICKETS)])
  );
};

export const setLoaderWhenGoingTicketsFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getTicketsList.success, getTicketsList.failure], action)
    ),
    map$(() => resetLoading(GOING_TICKETS))
  );
};

export const setErrorOnGoingTicketsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getTicketsList.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
