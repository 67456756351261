import { captureTransactionsDetailsRequest } from '@Model/reservation/actions';
import { getType } from 'typesafe-actions';
import {
  IAction,
  IReservationDetailsReducer,
  ITransactionDetails,
} from '../types';

const transactionDetailInitialState: ITransactionDetails = {
  bookingIdentifier: '',
  description: '',
  happening: {
    activityTimeInSlots: 0,
    allowNextSlotWhenOverbooked: false,
    calculatePricePerPerson: false,
    color: '',
    endDate: '',
    id: 0,
    isPublic: false,
    maxNumberOfPeople: 0,
    metadata: [
      {
        description: '',
        id: 0,
        language: '',
        slug: '',
        title: '',
      },
    ],
    startDate: '',
    vat: 0,
  },
  lockCode: '',
  numberOfPeople: 0,
  paymentType: '',
  price: 0,
  space: {
    apartmentId: 0,
    configurations: [
      {
        closed: false,
        duration: 0,
        id: 0,
        prices: [{}],
        rrule: '',
        title: '',
        upsell: {},
      },
    ],
    id: 0,
    maxNumberOfPeople: 0,
    metadata: [
      {
        description: '',
        id: 0,
        language: '',
        slug: '',
        title: '',
      },
    ],
    timeSlot: 0,
    url: '',
  },
};

const initialState: IReservationDetailsReducer = {
  bookingIdentifier: '',
  details: transactionDetailInitialState,
  duration: 0,
  end: '',
  happeningId: 0,
  id: 0,
  lockCode: '',
  spaceId: 0,
  start: '',
};

const reservationDetailsReducer = (
  state: IReservationDetailsReducer = initialState,
  action: IAction,
): IReservationDetailsReducer => {
  switch (action.type) {
    case getType(captureTransactionsDetailsRequest.success):
      return action.payload;

    default:
      return state;
  }
};

export default reservationDetailsReducer;
