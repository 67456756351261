import { createSelector } from 'reselect';

import _Store from '@Store';

import { IMeta } from '@Services/$seats-io-api/types';

import { ISeatsIoReducer } from './../types';
import get from './get';

const getPageMeta = createSelector<_Store.IState, ISeatsIoReducer, IMeta>(
  [get],
  (seats) => seats.pageMeta
);

export default getPageMeta;
