import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  chip: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
  },
  chipBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  form: {
    width: '100%',
  },
}));

export default useStyles;
