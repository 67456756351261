import _Store from '@Store';
import { Selector } from 'reselect';
import { IGroupedReservationsReducer } from './../types';

const getGroupedReservations: Selector<
  _Store.IState,
  IGroupedReservationsReducer
> = (state) => state.reservations.groupedReservations;

export default getGroupedReservations;
