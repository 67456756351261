import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import translateDefaultPriceType from '@Misc/helpers/strings/translateDefaultPrice';
import { IRuleType } from '@Model/priceTypes/types';

import AddNewPriceTypeModal from './../AddNewPriceTypeModal';
import { IFormValuesProps } from './../AddNewPriceTypeModal/AddNewPriceTypeModal.types';
import { IFormLayoutProps } from './FormLayout.types';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    paddingRight: theme.spacing(3),
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
    },
  },
  buttonFirst: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      marginRight: 0,
    },
  },

  buttonWithoutPadding: {
    paddingRight: theme.spacing(1.25),
  },

  buttonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
  },

  item: {
    marginBottom: 0,
    marginTop: 0,
  },
}));

const FormLayout = ({
  priceTypes,
  values,
  setFieldValue,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'BetterManager.NewDefinedConfigurations.components.PriceRule.FormLayout',
  });

  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState<IRuleType | undefined>();
  const [types, setTypes] = useState<IRuleType[]>(
    values.prices.map((price) => ({
      counter: price.counter,
      currency: price.currency,
      name: translateDefaultPriceType(price.title),
      online: price.online,
      onsite: price.onsite,
      type: price.type,
      value: price.value,
    }))
  );

  const columns = [
    { id: 'name', label: t('name') },
    { id: 'value', label: t('value') },
    { id: 'actions', label: t('action') },
  ];

  const catchEdit = (id: string) => {
    const idx = Number(id);
    if (types[idx]) {
      setData(types[idx]);
      setOpenModal(true);
    }
  };

  const getNameByType = (type: string) => {
    return priceTypes?.find((price) => price.type === type)?.title;
  };

  const modify = (oldState: IRuleType[], formData: IFormValuesProps) => {
    if (oldState.some((v) => v.type === formData.type)) {
      return oldState.map((v) =>
        v.type === formData.type ? { ...v, ...formData } : v
      );
    }

    return [
      ...oldState,
      {
        currency: 'PLN',
        name: translateDefaultPriceType(getNameByType(formData.type)),
        ...formData,
      },
    ];
  };

  const saveType = (formData: IFormValuesProps, name?: string) => {
    setOpenModal(false);
    setData(undefined);
    setTypes((oldState) => modify(oldState, formData));
  };

  useEffect(() => {
    setFieldValue(
      'prices',
      types.map((type) => ({
        counter: type.counter,
        currency: type.currency,
        online: type.online,
        onsite: type.onsite,
        type: type.type,
        value: Number(type.value),
      }))
    );
  }, [types]);

  const removeType = (id: string) => {
    setTypes(types.filter((type) => type.type !== id));
  };

  const formattedTypes = types.map((type, index) => ({
    ...type,
    actions: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        className={cn(
          classes.button,
          classes.buttonFirst,
          classes.buttonWithoutPadding
        )}
        onClick={(e) => {
          e.stopPropagation();
          if (type.type) {
            removeType(type.type);
          }
        }}
      >
        {t('remove')}
      </Button>
    ),
    id: index,
  }));

  return (
    <>
      <AddNewPriceTypeModal
        open={openModal}
        closeModal={() => {
          setOpenModal(false);
          setData(undefined);
        }}
        saveType={saveType}
        data={data}
      />
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <GenericTable>
            <TableActions>
              <div />
              <div className={classes.buttonsContainer}>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  startIcon={<AddIcon />}
                  className={cn(classes.button, classes.buttonFirst)}
                  onClick={() => setOpenModal(true)}
                >
                  {t('add')}
                </Button>
              </div>
            </TableActions>
            <TableUi
              rows={formattedTypes}
              columns={columns}
              total={types.length}
              activePage={0}
              rowsPerPage={types.length}
              handleChangePage={() => {}}
              disableCheckBox={true}
              onRowClick={catchEdit}
            />
          </GenericTable>
        </Grid>
      </Grid>
    </>
  );
};

export default FormLayout;
