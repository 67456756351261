import React, { useEffect } from 'react';

import {
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import { TypographyVariant } from '@Constants/Variants/Typography';

import FormActions from './components/FormActions';
import GeneralSettings from './components/GeneralSettings';
import PoolGrouping from './components/PoolGrouping';
import PoolSwitch from './components/PoolSwitch';
import SalesAvailability from './components/SalesAvailability';
import Seats from './components/Seats';
import Tickets from './components/Tickets';
import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates.Pools.components.FormLayout',
  });

  const classes = useStyles();

  const {
    handleSubmit,
    values,
    handleChange,
    fetchPoolDescriptions,
    fetchCategories,
    rundate,
  } = props;

  useEffect(() => {
    fetchPoolDescriptions('');
    if (rundate.seatsIoChart?.key) {
      fetchCategories(rundate.seatsIoChart.key);
    }
  }, []);

  return (
    <>
      <DialogTitle>{values.id ? t('editTitle') : t('addTitle')}</DialogTitle>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <Grid
                  container={true}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item={true}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          onChange={handleChange}
                          name="isActive"
                          color="primary"
                        />
                      }
                      label={t('isActive')}
                    />
                  </Grid>
                  {values.id && (
                    <Grid item={true}>
                      <TextField
                        id="id"
                        label={t('id')}
                        value={values.id}
                        name="id"
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>
                  )}
                </Grid>
              </LabelContentContainer>
            </Grid>

            <Grid item={true} xs={12}>
              <Typography variant={TypographyVariant.h6}>
                {t('generalSettings')}
              </Typography>
            </Grid>
            <GeneralSettings {...props} />

            <Grid item={true} xs={12}>
              <Typography variant={TypographyVariant.h6}>
                {t('salesAvailability')}
              </Typography>
            </Grid>
            <SalesAvailability {...props} />

            <Grid item={true} xs={12}>
              <Typography variant={TypographyVariant.h6}>
                {t('poolSwitch')}
              </Typography>
            </Grid>
            <PoolSwitch {...props} />

            <Grid item={true} xs={12}>
              <Typography variant={TypographyVariant.h6}>
                {t('poolGroup')}
              </Typography>
            </Grid>
            <PoolGrouping {...props} />

            <Grid item={true} xs={12}>
              <Typography variant={TypographyVariant.h6}>
                {t('seats')}
              </Typography>
            </Grid>
            <Seats {...props} />

            {values.id && (
              <>
                <Grid item={true} xs={12}>
                  <Typography variant={TypographyVariant.h6}>
                    {t('tickets')}
                  </Typography>
                </Grid>
                <Tickets {...props} />
              </>
            )}
          </Grid>
        </DialogContent>
      </form>
      <FormActions {...props} />
    </>
  );
};

export default FormLayout;
