import React from 'react';

import { useTranslation } from 'react-i18next';

import GenericTable from '@Compo/reusable/GenericTable';
import TableUi from '@Compo/reusable/TableUi';

import ConsentsListModal from './../ConsentsListModal';
import { IConsentsListTableProps } from './ConsentsListTable.types';

const ConsentsListTable = ({
  users,
  setEntryIdEdit,
  showModal,
  totalCount,
  activePage,
  perPage,
  handleActivePage,
  closeModal,
}: IConsentsListTableProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'ConsentsList.components.ConsentsListTable',
  });

  const columns = [
    { id: 'email', label: t('email') },
    { id: 'firstName', label: t('name') },
    { id: 'lastName', label: t('lastName') },
  ];

  const normalizedUsers = users.map((user) => ({
    email: user.email,
    firstName: user.firstName,
    id: user.id,
    lastName: user.lastName,
  }));

  const catchEdit = (id: string) => {
    setEntryIdEdit(Number(id));
  };
  return (
    <>
      {showModal && <ConsentsListModal open={showModal} onClose={closeModal} />}
      <GenericTable>
        <TableUi
          rows={normalizedUsers}
          columns={columns}
          total={totalCount}
          activePage={activePage - 1}
          rowsPerPage={perPage}
          handleChangePage={handleActivePage}
          disableCheckBox={true}
          onRowClick={catchEdit}
        />
      </GenericTable>
    </>
  );
};

export default ConsentsListTable;
