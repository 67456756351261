import { getType } from 'typesafe-actions';

import { getPlaces, resetSearch, searchPlace } from '../actions';
import { IAction, IPlacesReducer } from '../types';

const initialState: IPlacesReducer = {
  data: [],
  meta: {
    page: 1,
    size: 50,
    total: 0,
  },
  searchText: '',
};

const goingPlacesReducer = (
  state: IPlacesReducer = initialState,
  action: IAction
): IPlacesReducer => {
  switch (action.type) {
    case getType(getPlaces.success):
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
      };
    case getType(searchPlace):
      return {
        ...state,
        searchText: action.payload,
      };
    case getType(resetSearch):
      return {
        ...state,
        searchText: '',
      };
    default:
      return state;
  }
};

export default goingPlacesReducer;
