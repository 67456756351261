import React from 'react';

import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { IFormLayoutProps } from './FormLayout.types';

const useStyles = makeStyles((theme) => ({
  form: {
    overflowY: 'auto',
  },
  input: {
    marginTop: theme.spacing(8),
  },
}));

const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Products.ShipmentFeeModal.FormLayout',
  });

  const classes = useStyles();

  const {
    closeModal,
    handleSubmit,
    submitForm,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
  } = props;

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent>
          <TextField
            id="name"
            label={(touched.name && errors.name) || t('name')}
            helperText={t('nameHelper')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            name="name"
            error={!!(touched.name && errors.name)}
            fullWidth={true}
          />
          <TextField
            id="price"
            label={(touched.price && errors.price) || t('price')}
            helperText={t('priceHelper')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.price}
            name="price"
            error={!!(touched.price && errors.price)}
            className={classes.input}
            type="number"
            inputProps={{ step: '0.01', min: '0' }}
            fullWidth={true}
          />
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
