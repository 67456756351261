import { getAvailableUsers, mounted } from '@Model/formio/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetError, resetLoading, setLoading } from './../actions';
import { FORM_IO } from './../constants/constants';

export const setUpStateWhenFormIoUsersFetchRequested: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$(isActionOf([mounted, getAvailableUsers.request])),
    mergeMap$(() => {
      return [resetError(FORM_IO), setLoading(FORM_IO)];
    }),
  );
};

export const setLoaderWhenFormIoUsersFetchFinished: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [getAvailableUsers.success, getAvailableUsers.failure],
        action,
      ),
    ),
    map$(() => resetLoading(FORM_IO)),
  );
};

export const setErrorOnFormIoUsersFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getAvailableUsers.failure)),
    map$((action) => addToast(action.payload.message, TYPE_ERROR)),
  );
};
