// Private
export const _RESET_STATE = 'Zagrywki/discount/_RESET_STATE';

export const _SAVE_REQUEST = 'Zagrywki/discount/_SAVE_REQUEST';
export const _SAVE_SUCCESS = 'Zagrywki/discount/_SAVE_SUCCESS';
export const _SAVE_FAILURE = 'Zagrywki/discount/_SAVE_FAILURE';

export const _CHECK_DISCOUNT_CODE_REQUEST =
  'Zagrywki/discount/_CHECK_DISCOUNT_CODE_REQUEST';
export const _CHECK_DISCOUNT_CODE_SUCCESS =
  'Zagrywki/discount/_CHECK_DISCOUNT_CODE_SUCCESS';
export const _CHECK_DISCOUNT_CODE_FAILURE =
  'Zagrywki/discount/_CHECK_DISCOUNT_CODE_FAILURE';

export const _GET_PARTNERS_REQUEST = 'Zagrywki/discount/_GET_PARTNERS_REQUEST';
export const _GET_PARTNERS_SUCCESS = 'Zagrywki/discount/_GET_PARTNERS_SUCCESS';
export const _GET_PARTNERS_FAILURE = 'Zagrywki/discount/_GET_PARTNERS_FAILURE';

export const _GET_EVENTS_PARTNER_REQUEST =
  'Zagrywki/discount/_GET_EVENTS_PARTNER_REQUEST';
export const _GET_EVENTS_PARTNER_SUCCESS =
  'Zagrywki/discount/_GET_EVENTS_PARTNER_SUCCESS';
export const _GET_EVENTS_PARTNER_FAILURE =
  'Zagrywki/discount/_GET_EVENTS_PARTNER_FAILURE';

export const _GET_RUNDATES_PARTNER_REQUEST =
  'Zagrywki/discount/_GET_RUNDATES_PARTNER_REQUEST';
export const _GET_RUNDATES_PARTNER_SUCCESS =
  'Zagrywki/discount/_GET_RUNDATES_PARTNER_SUCCESS';
export const _GET_RUNDATES_PARTNER_FAILURE =
  'Zagrywki/discount/_GET_RUNDATES_PARTNER_FAILURE';

export const _GET_POOLS_PARTNER_REQUEST =
  'Zagrywki/discount/_GET_POOLS_PARTNER_REQUEST';
export const _GET_POOLS_PARTNER_SUCCESS =
  'Zagrywki/discount/_GET_POOLS_PARTNER_SUCCESS';
export const _GET_POOLS_PARTNER_FAILURE =
  'Zagrywki/discount/_GET_POOLS_PARTNER_FAILURE';

export const _SAVE_NEW_DISCOUNT_REQUEST =
  'Zagrywki/discount/_SAVE_NEW_DISCOUNT_REQUEST';
export const _SAVE_NEW_DISCOUNT_SUCCESS =
  'Zagrywki/discount/_SAVE_NEW_DISCOUNT_SUCCESS';
export const _SAVE_NEW_DISCOUNT_FAILURE =
  'Zagrywki/discount/_SAVE_NEW_DISCOUNT_FAILURE';

export const _GET_SINGLE_EVENT_DISCOUNT_REQUEST =
  'Zagrywki/discount/_GET_SINGLE_EVENT_DISCOUNT_REQUEST';
export const _GET_SINGLE_EVENT_DISCOUNT_SUCCESS =
  'Zagrywki/discount/_GET_SINGLE_EVENT_DISCOUNT_SUCCESS';
export const _GET_SINGLE_EVENT_DISCOUNT_FAILURE =
  'Zagrywki/discount/_GET_SINGLE_EVENT_DISCOUNT_FAILURE';

// Public
export const EDIT = 'Zagrywki/discount/EDIT';
export const ADD = 'Zagrywki/discount/ADD';
export const SAVE = 'Zagrywki/discount/SAVE';
export const REMOVE = 'Zagrywki/discount/REMOVE';
export const CHECK = 'Zagrywki/discount/CHECK';
export const RESET_VALUE = 'Zagrywki/discount/RESET_VALUE';

export const EVENT_ADD = 'Zagrywki/discount/EVENT_ADD';
export const CLOSE_EVENT_ADD = 'Zagrywki/discount/CLOSE_EVENT_ADD';
export const CATCH_DEPENDENCY_VALUES =
  'Zagrywki/discount/CATCH_DEPENDENCY_VALUES';
export const CATCH_SAVE_NEW_DISCOUNT =
  'Zagrywki/discount/CATCH_SAVE_NEW_DISCOUNT';
export const HANDLE_ACTIVE_PAGE = 'Zagrywki/discount/HANDLE_ACTIVE_PAGE';
export const EVENT_DISCOUNT_EDIT = 'Zagrywki/discount/EVENT_DISCOUNT_EDIT';
