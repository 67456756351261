import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import {
  catchSaveSettlement,
  saveSettlement,
} from '@Model/settlements/actions';
import { SETTLEMENTS_CREATE } from '@Model/state/constants/constants';

import { resetError, resetLoading, setLoading } from '../actions';

export const setLoaderWhenSettlementIsCreated: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchSaveSettlement)),
    mergeMap$(() => {
      return [resetError(SETTLEMENTS_CREATE), setLoading(SETTLEMENTS_CREATE)];
    })
  );
};

export const setLoaderWhenSettlementIsFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([saveSettlement.success, saveSettlement.failure], action)
    ),
    map$(() => resetLoading(SETTLEMENTS_CREATE))
  );
};
