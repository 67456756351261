import React, { ChangeEvent, useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';

import { columns, dialogTitles } from './AttachModal.constants';
import useStyles from './AttachModal.styles';
import { IAttachDependencies, IAttachModalProps } from './AttachModal.types';

const AttachModal = ({
  selectedType,
  closeModal,
  catchDependencyValues,
  meta,
  dependencies,
  selectedItems,
  setSelected,
  handleActivePage,
  searchText,
  isLoading,
}: IAttachModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Discounts.components.EventDiscountModal.components.AttachModal',
  });

  const [searchParam, setSearchParam] = useState(searchText);
  const [localSelected, setLocalSelected] = useState<IAttachDependencies>([]);
  const [searchTimeOut, setSearchTimeOut] = useState<NodeJS.Timeout | null>(
    null
  );

  const catchActivePageChange = (e: unknown, activePage: number) => {
    if (selectedType) {
      handleActivePage(selectedType, activePage + 1, searchParam);
    }
  };
  const addAttachmentToFormValues = () => {
    if (selectedType) {
      setSelected(selectedType, localSelected);
      setLocalSelected([]);
      closeModal();
    }
  };

  const catchHandleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchParam(e.target.value);
    searchFunc(e.target.value);
  };

  const searchFunc = (text: string) => {
    if (meta && selectedType) {
      if (searchTimeOut) {
        clearTimeout(searchTimeOut);
      }
      setSearchTimeOut(
        setTimeout(() => {
          handleActivePage(selectedType, meta.page, text);
        }, 1000)
      );
    }
  };

  const setOwnSelected = (selected: IAttachDependencies) => {
    setLocalSelected(selected);
  };

  const classes = useStyles();

  useEffect(() => {
    if (selectedType) {
      catchDependencyValues(selectedType);
      setLocalSelected(selectedItems[selectedType]);
    }
  }, [selectedType]);

  useEffect(() => {
    setSearchParam(searchText);
  }, [searchText]);

  if (!selectedType || !meta) {
    return null;
  }
  const { size, page, total } = meta;

  return (
    <Dialog
      open={!!selectedType}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle>{dialogTitles[selectedType]}</DialogTitle>
      <DialogContent>
        <GenericTable>
          <TableActions>
            <TextField
              variant="standard"
              className={classes.searchInput}
              name="search"
              helperText={t('search')}
              value={searchParam}
              onChange={catchHandleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
            />
          </TableActions>

          <TableUi
            rows={dependencies[selectedType]}
            columns={columns[selectedType]}
            total={total}
            activePage={page - 1}
            rowsPerPage={size}
            handleChangePage={catchActivePageChange}
            setOwnSelected={setOwnSelected}
            selectedItems={localSelected}
            isLoading={isLoading}
          />
        </GenericTable>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={addAttachmentToFormValues}
        >
          {t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttachModal;
