import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';

import {
  IPoolDescriptionBody,
  IPoolDescriptionResponse,
  ISinglePoolDescriptionResponse,
} from './types';

class PoolDescriptionsApi {
  private static getPoolDescriptionsUrl() {
    return `${config.api.cmsServices}pool-descriptions`;
  }
  private static getSavePoolDescriptionsUrl(id?: number) {
    return id
      ? `${config.api.cmsServices}pool-descriptions/${id}`
      : `${config.api.cmsServices}pool-descriptions`;
  }
  private static getSinglePoolDescriptionUrl(id: number) {
    return `${config.api.cmsServices}pool-descriptions/${id}`;
  }

  private cancelTokenPoolDescriptions?: CancelTokenSource;

  public getPoolDescriptions(
    page: number,
    count: number,
    partnerId?: number,
    search?: string
  ): Promise<IPoolDescriptionResponse> {
    return new Promise<IPoolDescriptionResponse>((resolve, reject) => {
      this.cancelTokenPoolDescriptions = axios.CancelToken.source();

      return axios
        .get(PoolDescriptionsApi.getPoolDescriptionsUrl(), {
          cancelToken: this.cancelTokenPoolDescriptions.token,
          params: {
            count,
            'filter[search]': search?.length ? search : undefined,
            page,
            partnerIds: [partnerId],
          },
        })
        .then(getData)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getSinglePoolDescription(
    id: number
  ): Promise<ISinglePoolDescriptionResponse> {
    return new Promise<ISinglePoolDescriptionResponse>((resolve, reject) => {
      this.cancelTokenPoolDescriptions = axios.CancelToken.source();

      return axios
        .get(PoolDescriptionsApi.getSinglePoolDescriptionUrl(id), {
          cancelToken: this.cancelTokenPoolDescriptions.token,
        })
        .then(getData)
        .then(getData)
        .then((data) => {
          resolve(data[0]);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public savePoolDescriptions(body: IPoolDescriptionBody): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenPoolDescriptions = axios.CancelToken.source();

      if (body.id) {
        return axios
          .put(PoolDescriptionsApi.getSavePoolDescriptionsUrl(body.id), body, {
            cancelToken: this.cancelTokenPoolDescriptions.token,
          })
          .then(getData)
          .then((data) => {
            resolve(data);
          })
          .catch((error) => reject(catchHttpError(error)));
      }

      return axios
        .post(PoolDescriptionsApi.getSavePoolDescriptionsUrl(), body, {
          cancelToken: this.cancelTokenPoolDescriptions.token,
        })
        .then(getData)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelPoolDescriptions() {
    if (this.cancelTokenPoolDescriptions) {
      this.cancelTokenPoolDescriptions.cancel();
      this.cancelTokenPoolDescriptions = undefined;
    }
  }
}

export default new PoolDescriptionsApi();
