import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
    },
  },

  buttonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
  },

  buttonWithoutPadding: {
    paddingRight: theme.spacing(1.25),
  },
  loader: {
    zIndex: 0,
  },

  filtersContainer: {
    backgroundColor: theme.palette.action.focus,
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },

  popper: {
    zIndex: 1050,
  },
}));

export default useStyles;
