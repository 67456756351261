import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getHappeningPartnerId } from '@Model/happenings/selectors';
import getFilterByActivePartner from '@Model/metadata/selectors/getFilterByActivePartner';

import {
  catchValues,
  getEventsPartner,
  getPartners,
  getPoolsPartner,
  getRundatesPartner,
  handleActivePage,
} from '../actions';
import { getMeta, getSearchText } from '../selectors';

const PER_PAGE = 15;

export const requestCatchMetadataValuesAction: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchValues)),
    mergeMap$((action) => {
      return of$(
        handleActivePage({ type: action.payload, page: 1, searchText: '' })
      );
    })
  );
};

export const fetchMetadataValuesWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(handleActivePage)),
    mergeMap$((action) => {
      switch (action.payload.type) {
        case 'partner':
          return of$(getPartners.request());
        case 'event':
          return of$(getEventsPartner.request());
        case 'rundate':
          return of$(getRundatesPartner.request());
        case 'pool':
          return of$(getPoolsPartner.request());
        default:
          return EMPTY$;
      }
    })
  );
};

export const fetchMetadataPartnersWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { metadataApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPartners.request)),
    withLatestFrom$(state$),
    mergeMap$(([, state]) => {
      const { page } = getMeta(state);
      const searchText = getSearchText(state);

      return from$(metadataApi.getPartners(page, PER_PAGE, searchText)).pipe(
        mergeMap$((data) => {
          return of$(getPartners.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => metadataApi.cancelMetadata())
          )
        ),
        catchError$((error: Error) => {
          return of$(getPartners.failure(error));
        })
      );
    })
  );
};

export const fetchMetadataEventsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { metadataApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getEventsPartner.request)),
    withLatestFrom$(state$),
    mergeMap$(([, state]) => {
      const { page } = getMeta(state);
      const searchText = getSearchText(state);
      const partnerId = getFilterByActivePartner(state)
        ? getHappeningPartnerId(state)
        : undefined;

      return from$(
        metadataApi.getEventsPartner(page, PER_PAGE, searchText, partnerId)
      ).pipe(
        mergeMap$((data) => {
          return of$(getEventsPartner.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => metadataApi.cancelMetadata())
          )
        ),
        catchError$((error: Error) => {
          return of$(getEventsPartner.failure(error));
        })
      );
    })
  );
};

export const fetchMetadataRundatesWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { metadataApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getRundatesPartner.request)),
    withLatestFrom$(state$),
    mergeMap$(([, state]) => {
      const { page } = getMeta(state);
      const searchText = getSearchText(state);
      const partnerId = getFilterByActivePartner(state)
        ? getHappeningPartnerId(state)
        : undefined;

      return from$(
        metadataApi.getRundatesPartner(page, PER_PAGE, searchText, partnerId)
      ).pipe(
        mergeMap$((data) => {
          return of$(getRundatesPartner.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => metadataApi.cancelMetadata())
          )
        ),
        catchError$((error: Error) => {
          return of$(getRundatesPartner.failure(error));
        })
      );
    })
  );
};

export const fetchMetadataPoolsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { metadataApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPoolsPartner.request)),
    withLatestFrom$(state$),
    mergeMap$(([, state]) => {
      const { page } = getMeta(state);
      const searchText = getSearchText(state);
      const partnerId = getFilterByActivePartner(state)
        ? getHappeningPartnerId(state)
        : undefined;

      return from$(
        metadataApi.getPoolsPartner(page, PER_PAGE, searchText, partnerId)
      ).pipe(
        mergeMap$((data) => {
          return of$(getPoolsPartner.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => metadataApi.cancelMetadata())
          )
        ),
        catchError$((error: Error) => {
          return of$(getPoolsPartner.failure(error));
        })
      );
    })
  );
};
