import { IToastActionRemovePayload, IToastType } from '@Model/toasts/types';
import { Action, createAction, createStandardAction } from 'typesafe-actions';
import uuid from 'uuid/v4';
import { ADD, REMOVE } from './../constants/actions';

const getId = (id: string | null) => {
  return id || uuid();
};

export const addToast = createAction(ADD, (action) => {
  return (text: string, type: IToastType, id: string | null = null) =>
    action({
      id: getId(id),
      text,
      type,
    });
});

export const addToastWithAction = createAction(ADD, (action) => {
  return (
    text: string,
    type: IToastType,
    actionObject: {
      action: Action;
      text: string;
    },
    id: string | null = null,
  ) =>
    action({
      action: actionObject,
      id: getId(id),
      text,
      type,
    });
});

export const removeToast = createStandardAction(REMOVE)<
  IToastActionRemovePayload
>();
