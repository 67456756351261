import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  header: {
    backgroundColor: '#EEEEEE',
  },
  row: {
    borderBottomWidth: 1,
    borderColor: '#E0E0E0',
    display: 'flex',
    flexDirection: 'row',

    '&:last-child': {
      borderBottomWidth: 0,
    },
  },
});

export default styles;
