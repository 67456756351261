import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPoolsResponse } from '@Services/$going-rundate-api/types';

import { IPoolsReducer } from '../types';
import getPools from './getPools';

const getTablePools = createSelector<
  _Store.IState,
  IPoolsReducer,
  IPoolsResponse[]
>([getPools], (pools) => pools.pools);

export default getTablePools;
