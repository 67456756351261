interface IMetadata {
  language: ILanguage;
}

const DEFAULT_LANGUAGE = 'pl';

const getMetadataForLanguage = <T extends IMetadata>(
  metadata: T[],
  language = DEFAULT_LANGUAGE,
): T => {
  return metadata.find(
    (_metadata) => _metadata.language.toLowerCase() === language,
  ) as T;
};

export default getMetadataForLanguage;
