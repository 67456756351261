import { ISettlementsAddModalValues } from './SettlementsAddModal.types';

export const defaultValues: ISettlementsAddModalValues = {
  endDate: null,
  freeTickets: true,
  percent: null,
  rundateIds: [],
  settlementTypeId: 1,
  startDate: null,
  transactionType: 'any',
  withSettledTickets: false,
};

export const prepareInitialValues = (): ISettlementsAddModalValues => {
  return defaultValues;
};
