import React, { FC, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';

import AddProductModal from './AddProductModal';
import useStyles from './Products.styles';
import { IProductProps } from './Products.types';

const Products: FC<IProductProps> = ({ onChange, data }) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'BetterManager.Space.components.Products',
  });

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setOpen(false);
  };

  const deleteProduct = (id: number) => {
    onChange(
      'products',
      data?.filter((product) => product.id !== id)
    );
  };

  const columns = [
    { id: 'name', label: t('name') },
    { id: 'storageHouseName', label: t('stock') },
    { id: 'actions', label: '' },
  ];

  const normalizedProducts =
    data?.map((product) => ({
      ...product,
      actions: (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={cn(
            classes.button,
            classes.buttonFirst,
            classes.buttonWithoutPadding
          )}
          onClick={(e) => {
            e.stopPropagation();
            deleteProduct(product.id);
          }}
        >
          {t('remove')}
        </Button>
      ),
      storageHouseName: product.stock.find(
        (stock) => stock.storageHouseId === product.assignedStorageHouseId
      )?.storageHouseName,
    })) || [];

  return (
    <GenericTable>
      <AddProductModal
        onChange={onChange}
        open={open}
        onClose={closeModal}
        selected={data}
      />
      <TableActions>
        <div />
        <div className={classes.buttonsContainer}>
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            startIcon={<AddIcon />}
            className={cn(classes.button, classes.buttonFirst)}
            onClick={() => setOpen(true)}
          >
            {t('add')}
          </Button>
        </div>
      </TableActions>
      <TableUi
        rows={normalizedProducts}
        columns={columns}
        disableCheckBox={true}
      />
    </GenericTable>
  );
};

export default Products;
