import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getHappeningPartnerId } from '@Model/happenings/selectors';
import {
  handleActiveInvoiceProduct,
  saveProduct,
} from '@Model/products/actions';
import { getActiveInvoiceProduct } from '@Model/products/selectors';

import InvoiceModal from './InvoiceModal.component';
import { prepareInitialValues } from './InvoiceModal.helpers';
import {
  IZohoModalFromDispatch,
  IZohoModalFromState,
} from './InvoiceModal.types';

const mapStateToProps = (state: _Store.IState): IZohoModalFromState => {
  return {
    initialValues: prepareInitialValues(getActiveInvoiceProduct(state)),
    partnerId: getHappeningPartnerId(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IZohoModalFromDispatch => {
  return {
    closeModal: () => dispatch(handleActiveInvoiceProduct(null)),
    saveProduct: (body) => dispatch(saveProduct(body)),
  };
};

export default connect<
  IZohoModalFromState,
  IZohoModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceModal);
