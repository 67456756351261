import { Selector } from 'reselect';

import { IOldSubmission } from '@Services/$formio-api/types';
import _Store from '@Store';

const getOldDataUser: Selector<_Store.IState, IOldSubmission | null> = (
  state
) => state.formIo.oldDataUser;

export default getOldDataUser;
