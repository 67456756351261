import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import { Button, Tooltip } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import { MetadataType } from '@Services/$metadata-api/types';

import { columns } from './../AttachModal/AttachModal.constants';
import useStyles from './DependencyTable.styles';
import { IDependencyTableProps } from './DependencyTable.types';

const DependencyTable = ({
  dependencies,
  handleChangeType,
  selectedType,
  handleDeleteDependency,
  buttonDisabled,
}: IDependencyTableProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reusable.MetadataModal.components.DependencyTable',
  });

  const classes = useStyles();

  const columnsWithActions = [
    ...columns[selectedType],
    { id: 'actions', label: '' },
  ];

  const getRundateMoment = (rundate: string) =>
    moment(rundate).isValid()
      ? moment(rundate)
      : moment(rundate, 'DD.MM.YYYY HH:mm');

  const dependencyWithActions = dependencies.map((dependency) => ({
    ...dependency,
    actions: (
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={() => handleDeleteDependency(selectedType, dependency.id)}
        disabled={buttonDisabled}
      >
        {t('delete')}
      </Button>
    ),
    ...(selectedType === MetadataType.Rundate &&
      'rundate' in dependency &&
      typeof dependency.rundate === 'string' && {
        date: getRundateMoment(dependency.rundate).format('DD.MM.YYYY'),
        time: getRundateMoment(dependency.rundate).format('HH:mm'),
      }),
    ...(selectedType === MetadataType.Pool &&
      'poolDescription' in dependency && {
        poolDescription: {
          ...dependency.poolDescription,
          ...(dependency.poolDescription?.titlePL && {
            titlePLFormatted: (
              <>
                {dependency.poolDescription?.titlePL}
                {dependency.poolDescription?.descriptionPL && (
                  <Tooltip
                    title={dependency.poolDescription?.descriptionPL}
                    placement="bottom"
                    arrow={true}
                  >
                    <InfoIcon
                      color={'secondary'}
                      fontSize="small"
                      className={classes.tooltipIcon}
                    />
                  </Tooltip>
                )}
              </>
            ),
          }),
        },
      }),
    ...(selectedType === MetadataType.Pool &&
      'price' in dependency && {
        priceFormatted: dependency.price + ' zł',
      }),
  }));

  return (
    <>
      {!dependencies.length ? (
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          startIcon={<AddIcon />}
          className={classes.button}
          onClick={() => handleChangeType(selectedType)}
          disabled={buttonDisabled}
        >
          {t('attach')}
        </Button>
      ) : (
        <GenericTable>
          <TableActions>
            <div />
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              startIcon={<AddIcon />}
              className={classes.button}
              onClick={() => handleChangeType(selectedType)}
              disabled={buttonDisabled}
            >
              {t('attach')}
            </Button>
          </TableActions>
          <TableUi
            rows={dependencyWithActions}
            columns={columnsWithActions}
            disableCheckBox={true}
          />
        </GenericTable>
      )}
    </>
  );
};

export default DependencyTable;
