import React from 'react';

import { Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';

import { IFormLayoutProps } from './../../FormLayout.types';

const PoolGrouping = ({
  errors,
  touched,
  handleChange,
  values,
  handleBlur,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates.Pools.components.FormLayout.components.PoolGrouping',
  });

  return (
    <Grid item={true} xs={12}>
      <Grid container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <LabelContentContainer>
            <TextField
              variant="standard"
              id="poolGroupPL"
              label={
                (touched.poolGroupPL && errors.poolGroupPL) || t('poolGroupPL')
              }
              helperText={t('poolGroupPLHelper')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.poolGroupPL}
              name="poolGroupPL"
              error={!!(touched.poolGroupPL && errors.poolGroupPL)}
              fullWidth={true}
            />
          </LabelContentContainer>
        </Grid>

        <Grid item={true} xs={12}>
          <LabelContentContainer>
            <TextField
              variant="standard"
              id="poolGroupEN"
              label={
                (touched.poolGroupEN && errors.poolGroupEN) || t('poolGroupEN')
              }
              helperText={t('poolGroupENHelper')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.poolGroupEN}
              name="poolGroupEN"
              error={!!(touched.poolGroupEN && errors.poolGroupEN)}
              fullWidth={true}
            />
          </LabelContentContainer>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PoolGrouping;
