import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  addPermissions: {
    paddingLeft: theme.spacing(2),
  },
  button: {
    borderRadius: 4,
  },
  chip: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
  },
  chipBox: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  content: {
    overflow: 'hidden',
  },
  form: {
    overflowY: 'auto',
  },
}));

export default useStyles;
