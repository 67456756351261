import { catchSession } from '@Model/printer/actions';
import { PRINTER } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import { ISessionData } from '@Services/$authorization-api/types';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import SessionForm from './SessionForm.component';
import {
  IPrinterFormDispatch,
  IPrinterFormFromState,
} from './SessionForm.types';

const defaultValues: ISessionData = {
  printerId: null,
  startCash: null,
};

const mapStateToProps = (state: _Store.IState): IPrinterFormFromState => {
  return {
    initialValues: defaultValues,
    isLoading: getLoading(PRINTER)(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IPrinterFormDispatch => {
  return {
    startSession: (sessionData: ISessionData) => {
      dispatch(catchSession(sessionData));
    },
  };
};

export default connect<
  IPrinterFormFromState,
  IPrinterFormDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(SessionForm);
