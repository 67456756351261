import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IError } from '@Misc/helpers/api/catchHttpError';
import { ITransaction } from '@Model/reservations/types';
import { ITransactionsResponse } from '@Services/$transactions-api/types';

import {
  _GET_TRANSACTIONS_FAILURE,
  _GET_TRANSACTIONS_REQUEST,
  _GET_TRANSACTIONS_SUCCESS,
  CHANGE_FILTER,
  CHANGE_SIZE,
  HANDLE_ACTIVE_PAGE,
  MOUNTED,
  SET_ACTIVE_TRANSACTION,
} from '../constants/actions';
import { ITransactionsFilters } from '../types';

export const mounted = createStandardAction(MOUNTED)();
export const changeFilter =
  createStandardAction(CHANGE_FILTER)<ITransactionsFilters>();
export const handleActivePage =
  createStandardAction(HANDLE_ACTIVE_PAGE)<number>();
export const changeSize = createStandardAction(CHANGE_SIZE)<number>();
export const setActiveTransaction = createStandardAction(
  SET_ACTIVE_TRANSACTION
)<ITransaction | null>();

export const getTransactions = createAsyncAction(
  _GET_TRANSACTIONS_REQUEST,
  _GET_TRANSACTIONS_SUCCESS,
  _GET_TRANSACTIONS_FAILURE
)<undefined, ITransactionsResponse, IError>();
