import { IReservationsDetailsReducer } from '@Model/reservations/types';
import _Store from '@Store';
import { createSelector } from 'reselect';
import getDetails from './getDetails';

const isReservationDetailsSelected = createSelector<
  _Store.IState,
  IReservationsDetailsReducer,
  boolean
>(
  getDetails,
  (details) => details !== null,
);

export default isReservationDetailsSelected;
