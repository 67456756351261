import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import FormLayout from './FormLayout';
import { IFormValuesProps } from './FormLayout/FormLayout.types';
import { IReportFilterModalProps } from './ReportFilterModal.types';
import { AddingReportFilterSchema } from './ReportFilterModal.validation';

const ReportFilterModal = ({
  activeReportFilter,
  handleActiveReportFilter,
  catchSaveFilter,
}: IReportFilterModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Settings.ReportFilterModal',
  });

  const catchHandleSubmit = (body: IFormValuesProps) => {
    catchSaveFilter(body);
  };
  const handleClose = () => handleActiveReportFilter(null);

  return (
    <Dialog
      open={activeReportFilter ? true : false}
      maxWidth="md"
      fullWidth={true}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      {activeReportFilter && (
        <Formik
          initialValues={activeReportFilter}
          onSubmit={catchHandleSubmit}
          validationSchema={AddingReportFilterSchema}
          component={FormLayout}
        />
      )}
    </Dialog>
  );
};

export default ReportFilterModal;
