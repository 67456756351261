import React from 'react';

import Grid from '@Compo/layout/Grid';
import PrinterAdmin from '@Compo/PrinterAdmin';
import UserAdmin from '@Compo/UserAdmin';

const AdminPage = () => (
  <Grid>
    {/* <UserAdmin /> */}
    <PrinterAdmin />
  </Grid>
);

export default AdminPage;
