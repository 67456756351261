import routes from '@/routes/routes';
import { getLocation, LOCATION_CHANGE } from 'connected-react-router';
import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getPlaces, mounted, resetSearch, searchPlace } from './../actions';
import { getPlacesMeta, getPlacesSearchText } from './../selectors';

export const fetchPlacesWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([mounted, searchPlace])),
    map$(() => getPlaces.request())
  );
};

export const getPlacesWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { placesApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPlaces.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const searchText = getPlacesSearchText(state);
      const meta = getPlacesMeta(state);

      return from$(placesApi.getPlaces(meta.page, meta.size, searchText)).pipe(
        map$((data) => getPlaces.success(data)),
        catchError$((error) => of$(getPlaces.failure(error))),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => placesApi.cancelPlaces())
          )
        )
      );
    })
  );
};

export const resetSearchWhenLocationChanged: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    withLatestFrom$(state$),
    filter$(
      ([_, state]) =>
        getLocation(state).pathname.split('/')[0] ===
        routes.rundateEdit.split('/')[0]
    ),
    map$(() => resetSearch())
  );
};
