import GoingRundateApi from '@/services/$going-rundate-api';
import RuleTypesApi from '@/services/$price-type-api';

import AuthorizationApi from '@Services/$authorization-api';
import BocaPrinterApi from '@Services/$boca-printer';
import ConfigurationsApi from '@Services/$configurations-api';
import DeviceDetector from '@Services/$device-detector';
import DiscountApi from '@Services/$discount-api';
import DiscountsApi from '@Services/$discounts-api';
import EmailApi from '@Services/$email-api';
import EventsApi from '@Services/$events-api';
import FirebaseApi from '@Services/$firebase-api';
import FormIoApi from '@Services/$formio-api';
import GoingCloudinaryApi from '@Services/$going-cloudinary-api';
import HappeningsApi from '@Services/$happenings-api';
import IframeProvider from '@Services/$iframe-provider';
import LinksProvider from '@Services/$links-provider';
import MetadataApi from '@Services/$metadata-api';
import PlacesApi from '@Services/$places-api';
import PoolDescriptionsApi from '@Services/$pool-descriptions-api';
import PoolsApi from '@Services/$pools-api';
import PriceApi from '@Services/$price-api';
import PrinterApi from '@Services/$printer-api';
import ProductsApi from '@Services/$products-api';
import RefundsRegistrationApi from '@Services/$refunds-registration-api';
import ReportsApi from '@Services/$reports-api';
import RequestProvider from '@Services/$request-provider';
import ReservationsApi from '@Services/$reservations-api';
import SeatsIoApi from '@Services/$seats-io-api';
import SettingsApi from '@Services/$settings-api';
import SettlementsApi from '@Services/$settlements-api';
import SmarporterApi from '@Services/$smartporter-api';
import StaticsApi from '@Services/$statics-api';
import TicketsApi from '@Services/$tickets-api';
import TransactionsApi from '@Services/$transactions-api';
import UserApi from '@Services/$user-api';
import UsersApi from '@Services/$users-api';

const services = {
  authorizationApi: AuthorizationApi,
  bocaPrinterApi: BocaPrinterApi,
  configurationsApi: ConfigurationsApi,
  deviceDetector: DeviceDetector,
  discountApi: DiscountApi,
  discountsApi: DiscountsApi,
  emailApi: EmailApi,
  eventsApi: EventsApi,
  firebaseApi: FirebaseApi,
  formIoApi: FormIoApi,
  goingCloudinaryApi: GoingCloudinaryApi,
  goingRundateApi: GoingRundateApi,
  happeningsApi: HappeningsApi,
  iframeProvider: IframeProvider,
  linksProvider: LinksProvider,
  metadataApi: MetadataApi,
  placesApi: PlacesApi,
  poolDescriptionsApi: PoolDescriptionsApi,
  poolsApi: PoolsApi,
  priceApi: PriceApi,
  printerApi: PrinterApi,
  productsApi: ProductsApi,
  refundsRegistrationApi: RefundsRegistrationApi,
  reportsApi: ReportsApi,
  requestProvider: RequestProvider,
  reservationsApi: ReservationsApi,
  ruleTypesApi: RuleTypesApi,
  seatsIoApi: SeatsIoApi,
  settingsApi: SettingsApi,
  settlementsApi: SettlementsApi,
  smarporterApi: SmarporterApi,
  staticsApi: StaticsApi,
  ticketsApi: TicketsApi,
  transactionsApi: TransactionsApi,
  userApi: UserApi,
  usersApi: UsersApi,
};

export type IServices = typeof services;

export default services;
