import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { get } from '@Model/authorization/selectors';
import {
  eventAddDiscount,
  eventEditDiscount,
  remove,
} from '@Model/discount/actions';
import { getActiveEventDiscount } from '@Model/discount/selectors';
import {
  handleActivePage,
  mountedEventsDiscounts,
  updateSearchEventText,
} from '@Model/discounts/actions';
import { getEventsDiscounts } from '@Model/discounts/selectors';
import getDiscountsInfo from '@Model/discounts/selectors/get';
import { allPermissions, DISCOUNTS } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';

import EventsDiscountTable from './EventsDiscountTable.component';
import {
  IEventsDiscountTableFromDispatch,
  IEventsDiscountTableFromState,
} from './EventsDiscountTable.types';

const mapStateToProps = (
  state: _Store.IState
): IEventsDiscountTableFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  const { activePage, totalCount, perPage, searchText } =
    getDiscountsInfo(state);

  return {
    activeEventDiscount: getActiveEventDiscount(state),
    activePage,
    discounts: getEventsDiscounts(state),
    isLoading: getLoading(DISCOUNTS)(state),
    perPage,
    permissionEventDiscountWrite: permissions.includes(
      allPermissions.access_event_discounts_write
    ),
    searchText,
    totalCount,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IEventsDiscountTableFromDispatch => {
  return {
    addDiscount: (values) => {
      dispatch(eventAddDiscount(values));
    },
    edit: (id) => {
      dispatch(eventEditDiscount(id));
    },
    handleActivePage: (page: number) =>
      dispatch(handleActivePage({ type: 'event', page })),
    mounted: () => dispatch(mountedEventsDiscounts()),
    remove: (discount) => {
      dispatch(remove(discount));
    },
    updateSearchText: (search: string) => {
      dispatch(updateSearchEventText(search));
    },
  };
};

export default connect<
  IEventsDiscountTableFromState,
  IEventsDiscountTableFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(EventsDiscountTable);
