import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import FormLayout from './FormLayout';
import {
  ISettlementsAddModalProps,
  ISettlementsAddModalValues,
} from './SettlementsAddModal.types';
import { SettlementSchema } from './SettlementsAddModal.validation';

const SettlementsAddModal = ({
  catchSaveSettlement,
  handleActiveSettlement,
  initialValues,
  settlement,
}: ISettlementsAddModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Settlements.SettlementsAddModal',
  });

  const closeModal = () => {
    handleActiveSettlement(null);
  };

  const catchHandleSubmit = (values: ISettlementsAddModalValues) => {
    catchSaveSettlement(values);
  };

  return (
    <Dialog
      open={!!settlement}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={catchHandleSubmit}
        validationSchema={SettlementSchema}
        component={FormLayout}
      />
    </Dialog>
  );
};

export default SettlementsAddModal;
