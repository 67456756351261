import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { arrayMove } from 'react-sortable-hoc';
import  { RRule,Weekday } from 'rrule';

import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import config from '@Config';
import checkIsEndAfterMidnight from '@Misc/helpers/checkIsEndAfterMidnight';
import { getRuleType } from '@Misc/helpers/getRuleType';
import { IConfiguration, TRuleType } from '@Model/configurations/types';

import { makeTimeFromDuration } from '../AddingConfiguration/AddingConfiguration.helpers';
import ConfigurationModal from '../AddingConfiguration/components/ConfigurationModal';
import ConfigurationList from '../ConfigurationList/ConfigurationList.component';
import NewConfigurationModal from '../NewAddingConfiguration/components/ConfigurationModal';
import { daysTranslator } from './../AddingConfiguration/components/ConfigurationModal/ConfigurationModal.helpers';
import ImagesLibrary from './../ImagesLibrary';
import LinkedSpaces from './components/LinkedSpaces';
import Products from './components/Products';
import useStyles from './Space.styles';
import { ISpaceProps } from './Space.types';

const Space = ({
  apartments,
  data,
  handleModify,
  permissionApartmentsView,
  handleActiveConfiguration,
  handleRemove,
  permissionSpaceWrite,
  spaces,
}: ISpaceProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'BetterManager.Space',
  });

  const columns = [
    { id: 'name', label: t('name') },
    { id: 'type', label: t('type') },
    { id: 'days', label: t('days') },
    { id: 'time', label: t('time') },
    { id: 'price', label: t('price') },
    { id: 'upsell', label: t('upsell') },
    { id: 'group', label: t('group') },
    { id: 'maxPeople', label: t('maxPeople') },
    { id: 'actions', label: t('action') },
  ];

  const classes = useStyles();
  const [shouldShowLibrary, showLibrary] = useState(false);
  const [timeBreakError, setTimeBreakError] = useState('');
  const [addingRule, setAddingRule] = useState(false);
  const [search, setSearch] = useState('');

  const catchHandleSearch = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.currentTarget;
    setSearch(value);
  };

  const normalizedConfigurations = data.configurations.map((configuration) => {
    const {
      title,
      prices,
      closed,
      maxNumberOfPeople,
      upsell,
      duration,
      fromNumberOfPeopleModifier,
      name,
      validFrom,
      validTo,
      isClosed,
      capacity,
      hash,
    } = configuration;

    const getUpSell = () => {
      return `${
        upsell && (upsell.value || upsell.percentageValue)
          ? `${upsell.value || upsell.percentageValue} ${
              upsell.percentageValue ? '%' : 'zł'
            }`
          : '-'
      }`;
    };

    const getFromNumberOfPeopleModifier = () => {
      if (fromNumberOfPeopleModifier) {
        const { value, priceGoesUp, fromNumberOfPeople, percentageValue } =
          fromNumberOfPeopleModifier;

        return ` ${`od: ${fromNumberOfPeople} ${
          priceGoesUp ? t('up') : t('down')
        } o ${value || percentageValue} ${percentageValue ? '%' : 'zł'}`}
                `;
      }
      return '-';
    };

    const getDays = () => {
      const options = RRule.fromString(configuration.rrule).origOptions;

      if (options.byweekday) {
        const arrayDays = options.byweekday as Weekday[];
        const daysString = arrayDays
          .map((day) => daysTranslator(day.toString()))
          .join(',');

        return `${daysString}`;
      } else if (options.bymonth && options.bymonthday) {
        return `${
          checkIsEndAfterMidnight(configuration.rrule, duration, null, null)
            ? moment(validTo).subtract(2, 'day').format('DD-MM-YYYY')
            : moment(validTo).subtract(1, 'day').format('DD-MM-YYYY')
        }`;
      }
    };

    const getMaxPeople = () => {
      return `${maxNumberOfPeople ? maxNumberOfPeople : '-'}`;
    };
    const getClosed = () => {
      return isClosed || closed ? t('yes') : t('no');
    };

    const getType = () => {
      if (prices && prices.length && prices[0].type) {
        return 'Cenowa';
      } else if (isClosed) {
        return 'Zamknięcia';
      } else if (isClosed !== undefined) {
        return 'Otwarcia';
      } else if (capacity !== undefined) {
        return 'Pojemnościowa';
      }

      return '-';
    };

    const getTime = () => {
      const options = RRule.fromString(configuration.rrule).origOptions;

      if (
        typeof options.byhour === 'number' &&
        typeof options.byminute === 'number'
      ) {
        const nextDate = moment().toDate();

        nextDate.setHours(options.byhour);
        nextDate.setMinutes(options.byminute);
        const helpDate = moment(nextDate).add(duration, 'seconds');
        const isSameDay = moment(nextDate).isSame(helpDate, 'day');

        return `${moment(nextDate).format('H:m')}-${helpDate.format('H:m')} ${
          !isSameDay ? 'n. d.' : ''
        }`;
      }
    };

    const isActive = (): boolean => {
      if (validFrom && validTo) {
        return moment().isBetween(moment(validFrom), moment(validTo));
      } else if (validFrom && !validTo) {
        return moment().isAfter(moment(validFrom));
      }
      return true;
    };

    return {
      actions: (
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={cn(
            classes.button,
            classes.buttonFirst,
            classes.buttonWithoutPadding
          )}
          onClick={(e) => {
            e.stopPropagation();
            if (configuration.hash) {
              removeConfiguration(configuration.hash);
            }
          }}
        >
          {t('remove')}
        </Button>
      ),
      closed: getClosed(),
      days: getDays(),
      group: getFromNumberOfPeopleModifier(),
      id: hash,
      maxPeople: getMaxPeople(),
      name: name || title,
      price: prices?.find((price) => price.type === 'default')?.value || '-',
      time: getTime(),
      type: getType(),
      upsell: getUpSell(),
    };
  });

  const add = (rule: IConfiguration) => {
    const { configurations } = data;

    const foundedIndex = configurations.findIndex(
      (configuration) => configuration.id === rule.id
    );

    let newConfigurations: IConfiguration[] = [];

    if (foundedIndex > -1) {
      const inComingRuleType: TRuleType | undefined = getRuleType(rule);
      const currentRuleType: TRuleType | undefined = getRuleType(
        configurations[foundedIndex]
      );

      if (inComingRuleType === currentRuleType) {
        newConfigurations = [...configurations];
        newConfigurations.splice(foundedIndex, 1, rule);
      } else {
        newConfigurations = [...configurations, rule];
      }
    } else {
      newConfigurations = [...configurations, rule];
    }

    setAddingRule(false);
    onChange('configurations', newConfigurations);
  };

  const removeConfiguration = (id: string) => {
    onChange(
      'configurations',
      data.configurations.filter((configuration) => configuration.hash !== id)
    );
  };

  const handleBreakChange = (key: string, value: any) => {
    if (value && data.slotTime) {
      const slotTimeNumber = makeTimeFromDuration(data.slotTime);
      const breakTimeNumber = makeTimeFromDuration(value);

      if (
        slotTimeNumber &&
        breakTimeNumber &&
        breakTimeNumber > slotTimeNumber
      ) {
        setTimeBreakError(t('timeBreakErrorMessage'));
        return;
      }
    }

    setTimeBreakError('');
    onChange(key, value);
  };

  const onChange = (key: string, value: any) => {
    handleModify(data.hash, key, value);
  };
  const filteredConfigurations = normalizedConfigurations.filter(
    (configuration) => {
      return (
        configuration.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    }
  );

  const onRowClick = (id: string) => {
    const configurationFounded = data.configurations.find(
      (configuration) => id === configuration.hash
    );

    if (configurationFounded) {
      handleActiveConfiguration(configurationFounded);
    }
  };

  const onSortEnd = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    onChange(
      'configurations',
      arrayMove(data.configurations, oldIndex, newIndex)
    );
  };

  return (
    <>
      {config.cms.showNewRules ? (
        <NewConfigurationModal />
      ) : (
        <ConfigurationModal />
      )}
      <Grid item={true} className={classes.spacing8}>
        <ImagesLibrary
          key={new Date().toISOString()}
          url={data.url}
          setUrl={(_url) => {
            onChange('url', _url);
            showLibrary(false);
          }}
          close={() => {
            showLibrary(false);
          }}
          open={shouldShowLibrary}
        />
        <TextField
          label={t('spaceName')}
          helperText={t('spaceNameHelper')}
          onChange={(e) => onChange('name', e.currentTarget.value)}
          value={data.name}
          fullWidth={true}
        />
      </Grid>
      <Grid
        sm={12}
        md={6}
        item={true}
        className={cn(classes.spacing8, classes.basisFix)}
      >
        <Typography variant="body1" className={classes.spacingBottom2}>
          {t('image')}
        </Typography>
        <Card>
          {data.url && <CardMedia image={data.url} className={classes.media} />}
          {!data.url && (
            <CardContent className={classes.content}>
              <Typography variant="body1">{t('imagePlaceholder')}</Typography>
            </CardContent>
          )}
          <CardActions className={classes.buttonsContainer}>
            <Button
              onClick={() => onChange('url', '')}
              variant="text"
              size="small"
              color="primary"
            >
              {t('remove')}
            </Button>
            <Button
              onClick={() => {
                showLibrary(true);
              }}
              variant="text"
              size="small"
              color="primary"
            >
              {t('add')}
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item={true} className={classes.spacing8}>
        <TextField
          label={t('description')}
          helperText={t('descriptionHelper')}
          onChange={(e) => onChange('description', e.currentTarget.value)}
          value={data.description}
          fullWidth={true}
        />
      </Grid>
      {permissionApartmentsView && (
        <Grid item={true} className={classes.spacing8}>
          <FormControl variant="standard" fullWidth={true}>
            <Select
              onChange={(e) => onChange('apartmentId', e.target.value)}
              defaultValue={data.apartmentId}
              fullWidth={true}
              label={t('blockade')}
            >
              {apartments.map((apartment) => (
                <MenuItem key={apartment.id} value={apartment.id.toString()}>
                  {apartment.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{t('blockadeHelper')}</FormHelperText>
          </FormControl>
        </Grid>
      )}
      <Grid item={true} className={classes.spacing8}>
        <TextField
          label={(timeBreakError.length && timeBreakError) || t('timeBreak')}
          helperText={t('timeBreakHelper')}
          onChange={(e) =>
            handleBreakChange('timeBreak', e.currentTarget.value)
          }
          value={data.timeBreak}
          error={!!(timeBreakError.length && timeBreakError)}
          fullWidth={true}
        />
      </Grid>
      <Grid container={true} className={classes.spacing8} spacing={4}>
        <Grid item={true} sm={12} md={4}>
          <TextField
            label={t('maxPeople')}
            helperText={t('maxPeopleHelper')}
            onChange={(e) => onChange('numberOfPeople', e.currentTarget.value)}
            value={data.numberOfPeople}
            fullWidth={true}
          />
        </Grid>
        <Grid item={true} sm={12} md={4}>
          <TextField
            label={t('maxCapacityLive')}
            helperText={t('maxCapacityLiveHelper')}
            onChange={(e) =>
              onChange('maxCapacityLive', Number(e.currentTarget.value))
            }
            value={data.maxCapacityLive}
            fullWidth={true}
          />
        </Grid>
        <Grid item={true} sm={12} md={4}>
          <TextField
            label={t('timeSlot')}
            helperText={t('timeSlotHelper')}
            onChange={(e) => onChange('slotTime', e.currentTarget.value)}
            value={data.slotTime}
            fullWidth={true}
          />
        </Grid>
      </Grid>
      <Grid item={true} className={cn(classes.spacing8, classes.ruleContainer)}>
        <Typography variant="body1" className={classes.spacingBottom2}>
          {t('rule')}
        </Typography>
        <GenericTable>
          <TableActions>
            <TextField
              name="search"
              helperText={t('search')}
              value={search}
              onChange={catchHandleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
            />
            <div className={classes.buttonsContainerRule}>
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                startIcon={<AddIcon />}
                className={cn(classes.button, classes.buttonFirst)}
                onClick={() => setAddingRule(true)}
              >
                {t('add')}
              </Button>
            </div>
          </TableActions>
          <TableUi
            rows={filteredConfigurations}
            columns={columns}
            total={filteredConfigurations.length}
            activePage={0}
            rowsPerPage={filteredConfigurations.length}
            handleChangePage={() => {}}
            disableCheckBox={true}
            onRowClick={onRowClick}
            isSortable={true}
            onSortEnd={onSortEnd}
            disableSelect={true}
          />
        </GenericTable>
      </Grid>
      <Grid item={true} className={classes.spacing8}>
        <Typography variant="body1" className={classes.spacingBottom2}>
          {t('product')}
        </Typography>
        <Products onChange={onChange} data={data.products} />
      </Grid>
      <Grid item={true} className={classes.spacing8}>
        <Typography
          variant="body1"
          className={classes.spacingBottom1}
          color="textSecondary"
        >
          {t('linkingSpace')}
        </Typography>
        <Typography
          variant="caption"
          className={classes.spacingBottom2}
          color="textSecondary"
        >
          {t('linkingSpaceDesc')}
        </Typography>
        <LinkedSpaces onChange={onChange} data={data} spaces={spaces} />
      </Grid>
      <Grid item={true} className={classes.spacing8}>
        <FormControl>
          <FormLabel className={classes.label}>{t('timeSlotsView')}</FormLabel>
          <RadioGroup
            value={data.isScopeView}
            onChange={(e) => {
              onChange('isScopeView', e.currentTarget.value === 'true');
            }}
          >
            <FormControlLabel
              value="false"
              checked={data.isScopeView === false}
              control={<Radio color="primary" />}
              label={t('onlyStartHour')}
            />
            <FormControlLabel
              value="true"
              checked={data.isScopeView === true}
              control={<Radio color="primary" />}
              label={t('rangeFromTo')}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {permissionSpaceWrite && (
        <Grid
          container={true}
          className={classes.spacing8}
          justifyContent={'flex-end'}
        >
          <Button
            size="large"
            color="primary"
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={() => {
              const isConfirmed = confirm('Czy usunąć?');

              if (isConfirmed) {
                handleRemove(data.hash);
              }
            }}
          >
            {t('deleteSpace')}
          </Button>
        </Grid>
      )}
      <Grid item={true} className={classes.spacing8}>
        <Divider />
      </Grid>
      {addingRule && (
        <ConfigurationList
          handleAdd={add}
          handleActiveConfiguration={handleActiveConfiguration}
          handleClose={() => setAddingRule(false)}
        />
      )}
    </>
  );
};

export default Space;
