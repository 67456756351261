import React from 'react';

import ConsentsList from '@Compo/ConsentsList';
import Grid from '@Compo/layout/Grid';

const ConsentsListPage = () => (
  <Grid>
    <ConsentsList />
  </Grid>
);

export default ConsentsListPage;
