import React from 'react';

import { Formik } from 'formik';

import FormLayout from './../FormLayout';
import { ITicketsListFormProps } from './TicketsListForm.types';
import { TicketListSchema } from './TicketsListForm.validation';

const TicketListForm = ({ initialValues, save }: ITicketsListFormProps) => {
  if (initialValues) {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={TicketListSchema}
        onSubmit={save}
        component={FormLayout}
      />
    );
  }
  return null;
};

export default TicketListForm;
