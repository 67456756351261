import { save } from '@Model/discount/actions';
import getActiveDiscount from '@Model/discount/selectors/getActiveDiscount';
import { IDiscount } from '@Model/discount/types';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import DiscountForm from './DiscountForm.component';
import {
  IDiscountFormDispatch,
  IDiscountFormFromState,
} from './DiscountForm.types';

const defaultValues: IDiscount = {
  code: null,
  endDate: null,
  happeningId: null,
  partnerId: null,
  percentageValue: null,
  priceTypeId: null,
  startDate: null,
  transactionsLimit: 1,
  value: null,
};

const mapStateToProps = (state: _Store.IState): IDiscountFormFromState => {
  const activeDiscount = getActiveDiscount(state);

  if (activeDiscount && activeDiscount.id) {
    return {
      initialValues: {
        ...activeDiscount,
        happeningId:
          (activeDiscount &&
            activeDiscount.happening &&
            activeDiscount.happening.id) ||
          null,
        isPercentage: activeDiscount.percentageValue ? 1 : 0,
        partnerId:
          (activeDiscount.partner && Number(activeDiscount.partner.id)) || null,
        priceTypeId: activeDiscount.priceType?.id,
        value: activeDiscount.value || activeDiscount.percentageValue,
      },
    };
  }

  return {
    initialValues: defaultValues,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IDiscountFormDispatch => {
  return {
    save: (discount) => {
      dispatch(save(discount));
    },
  };
};

export default connect<
  IDiscountFormFromState,
  IDiscountFormDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(DiscountForm);
