import React from 'react';

import Grid from '@Compo/layout/Grid';
import ReportBug from '@Compo/ReportBug';

const ReportBugPage = () => (
  <Grid>
    <ReportBug />
  </Grid>
);

export default ReportBugPage;
