export const HANDLE_ACTIVE_USER = 'Zagrywki/users/HANDLE_ACTIVE_USER';
export const HANDLE_ACTIVE_GROUP = 'Zagrywki/users/HANDLE_ACTIVE_GROUP';
export const HANDLE_ACTIVE_FILTER = 'Zagrywki/users/HANDLE_ACTIVE_FILTER';
export const MOUNTED_USERS = 'Zagrywki/users/MOUNTED_USERS';
export const MOUNTED_GROUPS = 'Zagrywki/users/MOUNTED_GROUPS';
export const MOUNTED_PERMISSIONS = 'Zagrywki/users/MOUNTED_PERMISSIONS';
export const CATCH_SAVE_GROUP = 'Zagrywki/users/CATCH_SAVE_GROUP';
export const CATCH_SAVE_USER = 'Zagrywki/users/CATCH_SAVE_USER';
export const GET_ALL_GROUPS = 'Zagrywki/users/GET_ALL_GROUPS';
export const GET_ALL_PARTNERS = 'Zagrywki/users/GET_ALL_PARTNERS';
export const CATCH_DELETE_GROUP = 'Zagrywki/users/CATCH_DELETE_GROUP';
export const CATCH_GET_SINGLE_GROUP = 'Zagrywki/users/CATCH_GET_SINGLE_GROUP';
export const CATCH_GET_SINGLE_USER = 'Zagrywki/users/CATCH_GET_SINGLE_USER';
export const CATCH_USER_STATUS_CHANGE =
  'Zagrywki/users/CATCH_USER_STATUS_CHANGE';
export const CATCH_USER_PASSWORD_CHANGE =
  'Zagrywki/users/CATCH_USER_PASSWORD_CHANGE';
export const HANDLE_ACTIVE_PAGE_USERS =
  'Zagrywki/users/HANDLE_ACTIVE_PAGE_USERS';
export const HANDLE_ACTIVE_PAGE_GROUPS =
  'Zagrywki/users/HANDLE_ACTIVE_PAGE_GROUPS';
export const HANDLE_ACTIVE_SIZE_USERS =
  'Zagrywki/users/HANDLE_ACTIVE_SIZE_USERS';
export const HANDLE_ACTIVE_SIZE_GROUPS =
  'Zagrywki/users/HANDLE_ACTIVE_SIZE_GROUPS';
export const HANDLE_SEARCH_USERS = 'Zagrywki/users/HANDLE_SEARCH_USERS';
export const HANDLE_SEARCH_GROUPS = 'Zagrywki/users/HANDLE_SEARCH_GROUPS';

export const _GET_USERS_REQUEST = 'Zagrywki/users/_GET_USERS_REQUEST';
export const _GET_USERS_SUCCESS = 'Zagrywki/users/_GET_USERS_SUCCESS';
export const _GET_USERS_FAILURE = 'Zagrywki/users/_GET_USERS_FAILURE';

export const _GET_GROUPS_REQUEST = 'Zagrywki/users/_GET_GROUPS_REQUEST';
export const _GET_GROUPS_SUCCESS = 'Zagrywki/users/_GET_GROUPS_SUCCESS';
export const _GET_GROUPS_FAILURE = 'Zagrywki/users/_GET_GROUPS_FAILURE';

export const _GET_PARTNERS_REQUEST = 'Zagrywki/users/_GET_PARTNERS_REQUEST';
export const _GET_PARTNERS_SUCCESS = 'Zagrywki/users/_GET_PARTNERS_SUCCESS';
export const _GET_PARTNERS_FAILURE = 'Zagrywki/users/_GET_PARTNERS_FAILURE';

export const _GET_PERMISSIONS_REQUEST =
  'Zagrywki/users/_GET_PERMISSIONS_REQUEST';
export const _GET_PERMISSIONS_SUCCESS =
  'Zagrywki/users/_GET_PERMISSIONS_SUCCESS';
export const _GET_PERMISSIONS_FAILURE =
  'Zagrywki/users/_GET_PERMISSIONS_FAILURE';

export const _SAVE_GROUP_REQUEST = 'Zagrywki/users/_SAVE_GROUP_REQUEST';
export const _SAVE_GROUP_SUCCESS = 'Zagrywki/users/_SAVE_GROUP_SUCCESS';
export const _SAVE_GROUP_FAILURE = 'Zagrywki/users/_SAVE_GROUP_FAILURE';

export const _DELETE_GROUP_REQUEST = 'Zagrywki/users/_DELETE_GROUP_REQUEST';
export const _DELETE_GROUP_SUCCESS = 'Zagrywki/users/_DELETE_GROUP_SUCCESS';
export const _DELETE_GROUP_FAILURE = 'Zagrywki/users/_DELETE_GROUP_FAILURE';

export const _GET_SINGLE_GROUP_REQUEST =
  'Zagrywki/users/_GET_SINGLE_GROUP_REQUEST';
export const _GET_SINGLE_GROUP_SUCCESS =
  'Zagrywki/users/_GET_SINGLE_GROUP_SUCCESS';
export const _GET_SINGLE_GROUP_FAILURE =
  'Zagrywki/users/_GET_SINGLE_GROUP_FAILURE';

export const _GET_SINGLE_USER_REQUEST =
  'Zagrywki/users/_GET_SINGLE_USER_REQUEST';
export const _GET_SINGLE_USER_SUCCESS =
  'Zagrywki/users/_GET_SINGLE_USER_SUCCESS';
export const _GET_SINGLE_USER_FAILURE =
  'Zagrywki/users/_GET_SINGLE_USER_FAILURE';

export const _SAVE_USER_REQUEST = 'Zagrywki/users/_SAVE_USER_REQUEST';
export const _SAVE_USER_SUCCESS = 'Zagrywki/users/_SAVE_USER_SUCCESS';
export const _SAVE_USER_FAILURE = 'Zagrywki/users/_SAVE_USER_FAILURE';

export const _CHANGE_USER_STATUS_REQUEST =
  'Zagrywki/users/_CHANGE_USER_STATUS_REQUEST';
export const _CHANGE_USER_STATUS_SUCCESS =
  'Zagrywki/users/_CHANGE_USER_STATUS_SUCCESS';
export const _CHANGE_USER_STATUS_FAILURE =
  'Zagrywki/users/_CHANGE_USER_STATUS_FAILURE';

export const _CHANGE_USER_PASSWORD_REQUEST =
  'Zagrywki/users/_CHANGE_USER_PASSWORD_REQUEST';
export const _CHANGE_USER_PASSWORD_SUCCESS =
  'Zagrywki/users/_CHANGE_USER_PASSWORD_SUCCESS';
export const _CHANGE_USER_PASSWORD_FAILURE =
  'Zagrywki/users/_CHANGE_USER_PASSWORD_FAILURE';
