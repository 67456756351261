import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IPriceBody, IPriceResponse } from '@Services/$price-api/types';
import {
  _CHECK_PRICE_FAILURE,
  _CHECK_PRICE_REQUEST,
  _CHECK_PRICE_SUCCESS,
  GET_PRICE,
} from '../constants/actions';
import { IPricePayload } from '../types';

export const checkPrice = createAsyncAction(
  _CHECK_PRICE_REQUEST,
  _CHECK_PRICE_SUCCESS,
  _CHECK_PRICE_FAILURE
)<IPriceBody, IPriceResponse, Error>();

export const getPrice = createStandardAction(GET_PRICE)<IPricePayload>();
