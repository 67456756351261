import { createSelector } from 'reselect';

import _Store from '@Store';

import { ITransactionsFilters, ITransactionsReducer } from '../types';
import get from './get';

const getFilters = createSelector<
  _Store.IState,
  ITransactionsReducer,
  ITransactionsFilters
>([get], (transactions) => transactions.filters);

export default getFilters;
