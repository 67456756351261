import React from 'react';

import Add from '@Compo/happenings/Add';
import Grid from '@Compo/layout/Grid';

const HappeningAddPage = () => (
  <Grid>
    <Add />
  </Grid>
);

export default HappeningAddPage;
