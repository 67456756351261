import React from 'react';

import { Formik } from 'formik';
import FormLayout from '../FormLayout';
import { IPrinterFormProps } from './PrinterForm.types';
import { PrinterSchema } from './PrinterForm.validation';

const PrinterForm = ({ initialValues, save }: IPrinterFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PrinterSchema}
      onSubmit={save}
      component={FormLayout}
    />
  );
};

export default PrinterForm;
