import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  resetExternalTickets,
  saveExternalTickets,
} from '@Model/going/rundates/actions';
import { getExternalWarnings } from '@Model/going/rundates/selectors';

import Check from './Check.component';
import {
  ICheckFromDispatch,
  ICheckFromState,
  ICheckOwnProps,
} from './Check.types';

const mapStateToProps = (state: _Store.IState): ICheckFromState => ({
  externalWarnings: getExternalWarnings(state),
  open: !!getExternalWarnings(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ICheckFromDispatch => ({
  closeModal: () => dispatch(resetExternalTickets()),
  save: (payload) => dispatch(saveExternalTickets(payload)),
});

export default connect<
  ICheckFromState,
  ICheckFromDispatch,
  ICheckOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Check);
