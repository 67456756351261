import React, { ChangeEvent } from 'react';

import CloseIcon from '@mui/icons-material//Close';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import config from '@Config';
import useUpdateEffect from '@Misc/hooks/useUpdateEffect';

import useStyles from './ExtendedUpsell.styles';
import { IExtendedUpsellProps } from './ExtendedUpsell.types';

const ExtendedUpsell = ({
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  errors,
  touched,
}: IExtendedUpsellProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'BetterManager.ExtendedUpsell',
  });

  const discountTypes = [
    { name: t('valuePrice'), key: 'value' },
    { name: t('percent'), key: 'percentageValue' },
  ];

  const slotsIndex = [
    { name: t('first'), key: 1 },
    { name: t('second'), key: 2 },
    { name: t('third'), key: 3 },
    { name: t('fourth'), key: 4 },
    { name: t('fifth'), key: 5 },
    { name: t('sixth'), key: 6 },
  ];

  const classes = useStyles();

  const toggleField = (e: SelectChangeEvent<string>, slotIndex: number) => {
    if (e.target.value === 'value') {
      setFieldValue(`upsellExtended.[${slotIndex}].value`, 0);
      setFieldValue(`upsellExtended.[${slotIndex}].percentageValue`, null);
    } else {
      setFieldValue(`upsellExtended.[${slotIndex}].value`, null);
      setFieldValue(`upsellExtended.[${slotIndex}].percentageValue`, 0);
    }
  };

  const addNextSlot = () => {
    setFieldValue('upsellExtended', [
      ...values.upsellExtended,
      {
        percentageValue: null,
        slotIndex: values.upsellExtended.length + 1,
        value: 0,
      },
    ]);
  };

  const removeSlot = (index: number) => {
    setFieldValue(
      'upsellExtended',
      values.upsellExtended.filter((_, idx) => idx !== index)
    );
  };

  useUpdateEffect(() => {
    if (values.isExtendedUpsell) {
      setFieldValue('upsellExtended', [
        { slotIndex: 1, value: 0, percentageValue: null },
      ]);
      setFieldValue('isUpsell', false);
    } else {
      setFieldValue('upsellExtended', []);
    }
  }, [values.isExtendedUpsell]);

  return (
    <>
      <Grid item={true} xs={12}>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body1" color="textSecondary">
              {t('extendedSlotsTitle')}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {t('extendedSlotsDesc')}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Switch
              checked={values.isExtendedUpsell}
              onChange={handleChange}
              color="primary"
              size="medium"
              name="isExtendedUpsell"
            />
          </Grid>
        </Grid>
      </Grid>

      {values.isExtendedUpsell && (
        <>
          {values.upsellExtended.map((upsell, index) => (
            <Grid key={index} item={true} xs={12}>
              <Grid container={true} wrap="nowrap" spacing={2}>
                <Grid item={true} xs={true}>
                  <Grid container={true} spacing={1}>
                    <Grid item={true} xs={12}>
                      <LabelContentContainer>
                        <FormControl variant="standard" fullWidth={true}>
                          <InputLabel>{t('slot')}</InputLabel>
                          <Select
                            value={values.upsellExtended[index].slotIndex}
                            name={`upsellExtended.[${index}].slotIndex`}
                            fullWidth={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              !!(
                                touched.upsellExtended?.[index]?.slotIndex &&
                                errors.upsellExtended?.[index]?.slotIndex
                              )
                            }
                          >
                            {slotsIndex.map((slot) => (
                              <MenuItem
                                key={slot.key}
                                value={slot.key}
                                disabled={values.upsellExtended.some(
                                  (value) => value.slotIndex === slot.key
                                )}
                              >
                                {slot.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>{t('slotHelper')}</FormHelperText>
                        </FormControl>
                      </LabelContentContainer>
                    </Grid>

                    <Grid item={true} xs={12}>
                      <LabelContentContainer>
                        <FormControl variant="standard" fullWidth={true}>
                          <InputLabel>{t('discountType')}</InputLabel>
                          <Select
                            value={
                              upsell.value !== null
                                ? 'value'
                                : 'percentageValue'
                            }
                            onChange={(e) => toggleField(e, index)}
                            fullWidth={true}
                            onBlur={handleBlur}
                          >
                            {discountTypes.map((type) => (
                              <MenuItem key={type.key} value={type.key}>
                                {type.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </LabelContentContainer>
                    </Grid>

                    {upsell.value !== null && (
                      <Grid item={true} xs={12}>
                        <LabelContentContainer>
                          <TextField
                            label={
                              (touched.upsellExtended?.[index]?.value &&
                                errors.upsellExtended?.[index]?.value) ||
                              t('discountValue')
                            }
                            error={
                              !!(
                                touched.upsellExtended?.[index]?.value &&
                                errors.upsellExtended?.[index]?.value
                              )
                            }
                            helperText={t('discountValueHelper')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.upsellExtended[index].value}
                            name={`upsellExtended.[${index}].value`}
                            fullWidth={true}
                          />
                        </LabelContentContainer>
                      </Grid>
                    )}
                    {upsell.percentageValue !== null && (
                      <Grid item={true} xs={12}>
                        <LabelContentContainer>
                          <TextField
                            label={
                              (touched.upsellExtended?.[index]
                                ?.percentageValue &&
                                errors.upsellExtended?.[index]
                                  ?.percentageValue) ||
                              t('discountValue')
                            }
                            error={
                              !!(
                                touched.upsellExtended?.[index]
                                  ?.percentageValue &&
                                errors.upsellExtended?.[index]?.percentageValue
                              )
                            }
                            helperText={t('discountValueHelper')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.upsellExtended[index].percentageValue}
                            name={`upsellExtended.[${index}].percentageValue`}
                            fullWidth={true}
                          />
                        </LabelContentContainer>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {values.upsellExtended.length > 1 && (
                  <Grid item={true}>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={() => removeSlot(index)}
                      className={classes.closeButton}
                    >
                      <CloseIcon fontSize="medium" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
          <Grid item={true} xs={12}>
            <Button
              variant="outlined"
              size="medium"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={addNextSlot}
              className={classes.button}
              disabled={
                values.upsellExtended.length === config.cms.maxExtendedSlots
              }
            >
              {t('addNextSlot')}
            </Button>
          </Grid>
        </>
      )}
    </>
  );
};

export default ExtendedUpsell;
