import { IDeviceSizes, ISizes } from './types';

class DeviceDetector {
  private readonly sizes: IDeviceSizes = {
    smallMobile: 576,
    mobile: 768, // tslint:disable-line
    tablet: 992,
    desktop: 1200,
  };

  private readonly sortedKeysByResolution = [
    'desktop',
    'tablet',
    'mobile',
    'smallMobile',
  ];

  public getDevice({ width }: ISizes) {
    let device = 'bigDesktop';

    this.sortedKeysByResolution.forEach((_device: string) => {
      if (width < this.sizes[_device]) {
        device = _device;
      }
    });

    return device;
  }
}

export default new DeviceDetector();
