import React, { useEffect } from 'react';

import ReportTable from './components/ReportsTable';
import { ISummaryProps } from './Reports.types';

export interface IOwnFilters {
  id: number;
}

const Reports = ({ getReport, permissionReportsRead }: ISummaryProps) => {
  useEffect(() => {
    getReport();
  }, []);

  if (!permissionReportsRead) {
    return null;
  }

  return <ReportTable />;
};

export default Reports;
