import { createSelector } from 'reselect';

import _Store from '@Store';
import { ISettlementMetadata, ISettlementsReducer } from './../types';
import get from './get';

const getSettlementMetadata = createSelector<
  _Store.IState,
  ISettlementsReducer,
  ISettlementMetadata
>([get], (settlement) => settlement.settlementMetadata);

export default getSettlementMetadata;
