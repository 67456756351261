import { getType } from 'typesafe-actions';

import {
  getMonitorTypes,
  getRundate,
  getRundateStats,
  getSingleEvent,
  resetActiveRundate,
} from '../actions';
import { IAction, IRundateSingleReducer } from '../types';

const initialState: IRundateSingleReducer = {
  data: {
    changeMonitorType: {
      id: 0,
      name: '',
    },
    emissionSize: null,
    event: {
      descriptionPL: '',
      id: 0,
      isVisible: false,
      titlePL: '',
    },
    hasSeatsIo: false,
    id: 0,
    isForSale: false,
    rundateDescription: '',
    seatsIoChart: null,
    slug: '',
  },
  monitorTypes: [],
  saleReport: {
    available: 0,
    soldBoxOffice: 0,
    soldOnline: 0,
    ticketLists: 0,
  },
};

const goingRundateSingleReducer = (
  state: IRundateSingleReducer = initialState,
  action: IAction
): IRundateSingleReducer => {
  switch (action.type) {
    case getType(getRundate.success):
      return {
        ...state,
        data: action.payload.data,
      };
    case getType(getSingleEvent.success):
      return {
        ...state,
        data: {
          ...state.data,
          event: action.payload,
        },
      };
    case getType(getRundateStats.success):
      return {
        ...state,
        saleReport: action.payload,
      };
    case getType(getMonitorTypes.success):
      return {
        ...state,
        monitorTypes: action.payload,
      };

    case getType(resetActiveRundate):
      return initialState;
    default:
      return state;
  }
};

export default goingRundateSingleReducer;
