import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { removeToast } from '@Model/toasts/actions';
import { get } from '@Model/toasts/selectors';
import _Store from '@Store';
import { IToastsFromDispatch, IToastsFromState } from './Toast.types';
import ToastsComponent from './Toasts.component';

const mapStateToProps = (state: _Store.IState): IToastsFromState => {
  return {
    toasts: get(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IToastsFromDispatch => {
  return {
    dispatch,
    remove: (id) => {
      dispatch(removeToast({ id }));
    },
  };
};

const Toasts = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToastsComponent);

export default Toasts;
