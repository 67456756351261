import { array, number, object } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const PRICE_TO_LOW = 'Wartość za niska';
const MAX_VALIDATOR_TEXT = 'Maksymalna wartość dla tego pola to 100.';
const NOT_NUMBER_VALIDATOR_TEXT = 'Podaj liczbę';

const configuration = {
  price: number().min(0, PRICE_TO_LOW).required(REQUIRED_VALIDATOR_TEXT),

  upsellExtended: array().when('isExtendedUpsell', {
    is: true,
    then: array()
      .of(
        object().shape({
          percentageValue: number()
            .nullable()
            .min(0, PRICE_TO_LOW)
            .max(100, MAX_VALIDATOR_TEXT)
            .typeError(NOT_NUMBER_VALIDATOR_TEXT),
          slotIndex: number(),
          value: number()
            .nullable()
            .min(0, PRICE_TO_LOW)
            .typeError(NOT_NUMBER_VALIDATOR_TEXT),
        })
      )
      .required(REQUIRED_VALIDATOR_TEXT),
  }),
};

export const PriceRuleSchema = object().shape(configuration);
