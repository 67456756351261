import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
    },
  },
  buttonFirst: {
    marginRight: theme.spacing(2),
  },
  buttonWithoutPadding: {
    paddingRight: theme.spacing(1.25),
  },
  buttonsContainer: {
    float: 'right',
  },
  doneIcon: {
    color: theme.palette.success.light,
    height: 24,
    width: 24,
  },
  item: {
    backgroundColor: theme.palette.action.focus,
  },
  loader: {
    marginBottom: 50,
    position: 'relative',
  },
  marginRemoveButton: {
    marginRight: theme.spacing(1),
  },
  typographyDisabled: {
    color: theme.palette.text.disabled,
  },
}));

export default useStyles;
