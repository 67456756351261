import { connect } from 'react-redux';

import { isMobile, isSmallMobile, isTablet } from '@Model/app/selectors';
import { get } from '@Model/authorization/selectors';
import { allPermissions } from '@Model/state/constants';
import _Store from '@Store';
import VerticalMenu from './VerticalMenu.component';
import { IVerticalMenuFromState } from './VerticalMenu.types';

const LOGIN_PARAM = 'form_io_transaction_form_login';
const PASS_PARAM = 'form_io_transaction_form_pass';
const LOGIN_FORM_PARAM = 'form_io_transaction_form_login_url';

const mapStateToProps = (state: _Store.IState): IVerticalMenuFromState => {
  const mobile = isMobile(state);
  const smallMobile = isSmallMobile(state);
  const tablet = isTablet(state);

  const mobileView = mobile || smallMobile || tablet;

  const {
    userInfo: { permissions, parameters },
  } = get(state);

  const loginObject = parameters?.find((param) =>
    param.hasOwnProperty(LOGIN_PARAM)
  );
  const passObject = parameters?.find((param) =>
    param.hasOwnProperty(PASS_PARAM)
  );
  const loginFormUrlObject = parameters?.find((param) =>
    param.hasOwnProperty(LOGIN_FORM_PARAM)
  );

  const permissionConsentListView = !!(
    loginObject &&
    passObject &&
    loginFormUrlObject
  );

  return {
    mobileView,
    permissionConsentListView,
    permissions,
  };
};

export default connect<IVerticalMenuFromState, void, {}, _Store.IState>(
  mapStateToProps
)(VerticalMenu);
