import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  _SAVE_PRICE_TYPE_FAILURE,
  _SAVE_PRICE_TYPE_REQUEST,
  _SAVE_PRICE_TYPE_SUCCESS,
  CATCH_GET_RULE_TYPES,
  CATCH_GET_RULE_TYPES_WHEN_PAGE_CHANGED,
  CATCH_SAVE_PRICE_TYPE,
  DELETE_PRICE_TYPE,
  MOUNTED,
  SELECT_PRICE_TYPE,
} from '../constants/actions';
import { IAddPriceType, IMeta, IRuleTypeSuccessPayload } from '../types';

export const catchGetRuleType = createStandardAction(CATCH_GET_RULE_TYPES)();
export const catchSavePriceType = createStandardAction(CATCH_SAVE_PRICE_TYPE)<
  IAddPriceType
>();
export const selectPriceType = createStandardAction(
  SELECT_PRICE_TYPE
)<IAddPriceType | null>();
export const mounted = createStandardAction(MOUNTED)();
export const changePageOrCount = createStandardAction(
  CATCH_GET_RULE_TYPES_WHEN_PAGE_CHANGED
)<IMeta>();
export const deletePriceType = createStandardAction(DELETE_PRICE_TYPE)<
  number
>();

export const getRuleTypes = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE
)<number | undefined, IRuleTypeSuccessPayload, Error>();

export const savePriceType = createAsyncAction(
  _SAVE_PRICE_TYPE_REQUEST,
  _SAVE_PRICE_TYPE_SUCCESS,
  _SAVE_PRICE_TYPE_FAILURE
)<IAddPriceType, [], Error>();
