import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import {
  getRundate,
  getRundates,
  getSingleEvent,
} from '@Model/going/rundates/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { resetError, resetLoading, setLoading } from '../actions';
import { GOING_RUNDATES } from '../constants/constants';

export const setUpStateWhenGoingRundatesFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getRundates.request,
        getSingleEvent.request,
        getRundate.request,
      ])
    ),
    mergeMap$(() => {
      return [resetError(GOING_RUNDATES), setLoading(GOING_RUNDATES)];
    })
  );
};

export const setLoaderWhenGoingRundatesFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          getRundates.success,
          getRundates.failure,
          getSingleEvent.success,
          getSingleEvent.failure,
          getRundate.success,
          getRundate.failure,
        ],
        action
      )
    ),
    map$(() => resetLoading(GOING_RUNDATES))
  );
};

export const setErrorOnGoingRundatesFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getRundates.failure,
        getSingleEvent.failure,
        getRundate.failure,
      ])
    ),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
