import { createSelector } from 'reselect';

import { getHappening } from '@Model/happening/selectors';
import { IHappeningReducer } from '@Model/happening/types';
import _Store from '@Store';
import { ISelectedHappeningSpace } from './../../happenings/types/index';
import getSelectedSpace from './getSelectedSpace';

const getSelectedSpaceIsScopeView = createSelector<
  _Store.IState,
  IHappeningReducer,
  ISelectedHappeningSpace | null,
  boolean
>([getHappening, getSelectedSpace], (happening, selectedSpace) => {
  const selectedHappeningSpace = happening?.spaces.find(
    (space) => space.id === selectedSpace?.id
  );
  if (selectedHappeningSpace) {
    return selectedHappeningSpace.isScopeView || false;
  }

  return false;
});

export default getSelectedSpaceIsScopeView;
