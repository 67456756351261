import React, { useState } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MailOutlinedIcon from '@mui/icons-material/MailOutline';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Button, DialogActions, Grid } from '@mui/material';
import { setNestedObjectValues } from 'formik';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '@Compo/reusable/ConfirmModal';

import { IFormLayoutProps } from './../../FormLayout.types';
import TestEmailModal from './../TestEmailModal';
import useStyles from './FormActions.styles';

const FormActions = ({
  values,
  submitForm,
  permissionEventWrite,
  permissionEventSend,
  deleteMail,
  closeModal,
  setFieldValue,
  validateForm,
  errors,
  setTouched,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Mail.components.FormLayout.components.FormActions',
  });

  const classes = useStyles();
  const [openConfirmation, setOpenConfirmation] = useState({
    delete: false,
    send: false,
    sendTest: false,
  });

  type IConfirmationType = keyof typeof openConfirmation;

  const isSaveButtonDisabled =
    values.isSent || !values.dependencies.length || !permissionEventWrite;

  const isSendButtonDisabled = isSaveButtonDisabled || !permissionEventSend;

  const handleDeleteMail = () => {
    if (values.id) {
      deleteMail(values.id);
      setOpenConfirmation((oldState) => ({ ...oldState, delete: false }));
    }
  };

  const handleOpenConfirmation = (type: IConfirmationType) => {
    setOpenConfirmation((oldState) => ({ ...oldState, [type]: true }));
  };

  const handleCloseConfirmation = (type: IConfirmationType) => {
    setOpenConfirmation((oldState) => ({ ...oldState, [type]: false }));
  };

  const submitWithoutSend = () => {
    setFieldValue('saveAndSend', false, false);
    submitForm();
  };

  const submitWithSend = () => {
    setFieldValue('saveAndSend', true, false);
    setOpenConfirmation((oldState) => ({ ...oldState, send: false }));
    submitForm();
  };

  const openTestEmailModal = () => {
    setTouched(setNestedObjectValues(errors, true));
    validateForm(values);
    if (!Object.keys(errors).length) {
      handleOpenConfirmation('sendTest');
    }
  };

  return (
    <DialogActions>
      <ConfirmModal
        open={openConfirmation.delete}
        onCancel={() => handleCloseConfirmation('delete')}
        handleClose={() => handleCloseConfirmation('delete')}
        onConfirm={handleDeleteMail}
        question={t('deleteDesc')}
        title={t('deleteTitle')}
      />
      <ConfirmModal
        open={openConfirmation.send}
        onCancel={() => handleCloseConfirmation('send')}
        handleClose={() => handleCloseConfirmation('send')}
        onConfirm={submitWithSend}
        question={t('sendDesc')}
        title={t('sendTitle')}
      />
      <TestEmailModal
        open={openConfirmation.sendTest}
        closeModal={() => handleCloseConfirmation('sendTest')}
        values={values}
      />
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} sm={'auto'}>
          {values.id && (
            <Button
              variant="text"
              size="large"
              color="secondary"
              onClick={() => handleOpenConfirmation('delete')}
              startIcon={<DeleteForeverIcon />}
              fullWidth={true}
            >
              {t('delete')}
            </Button>
          )}
        </Grid>
        <Grid item={true} xs={12} sm={'auto'}>
          <Button
            variant="text"
            size="large"
            color="secondary"
            onClick={submitWithoutSend}
            disabled={isSaveButtonDisabled}
            type="submit"
            className={classes.saveDraft}
            startIcon={<SaveAltIcon />}
            fullWidth={true}
          >
            {t('saveDraft')}
          </Button>
        </Grid>

        <Grid item={true} xs={12} sm={'auto'}>
          {values.id && (
            <Button
              variant="text"
              size="large"
              color="secondary"
              onClick={openTestEmailModal}
              startIcon={<MailOutlinedIcon />}
              type="submit"
              fullWidth={true}
            >
              {t('sendTest')}
            </Button>
          )}
        </Grid>
        <Grid item={true} xs={12} sm={true}>
          <Grid
            container={true}
            spacing={2}
            justifyContent={{
              sm: 'flex-end',
              xs: 'center',
            }}
            flexWrap="nowrap"
          >
            <Grid item={true}>
              <Button
                variant="outlined"
                size="large"
                color="secondary"
                onClick={closeModal}
              >
                {t('cancel')}
              </Button>
            </Grid>
            <Grid item={true}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                onClick={() => handleOpenConfirmation('send')}
                disabled={isSendButtonDisabled}
              >
                {values.isSent
                  ? t('sended')
                  : values.mailShipment
                  ? t('plan')
                  : t('send')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </DialogActions>
  );
};

export default FormActions;
