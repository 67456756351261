import format from 'date-fns/format';
import moment from 'moment';
import { createSelector } from 'reselect';

import config from '@Config';
import getMetadataForLanguage from '@Misc/helpers/getMetadataForLanguage';
import { addTimeWithMoment } from '@Misc/helpers/strings/addTime';
import { IZagrywkiBasket } from '@Model/basket/types';
import { getHappening } from '@Model/happening/selectors';
import getSelectedConfigurationId from '@Model/happening/selectors/getSelectedConfigurationId';
import {
  IHappeningMetadataItem,
  ISpaceMetadata,
} from '@Model/happenings/types';

import getPricesAsObjects from './getPricesAsObjects';
import getSelectedDate from './getSelectedDate';
import getSelectedDayIncludeTimeSlot from './getSelectedDayIncludeTimeSlot';
import getSelectedExtendedDuration from './getSelectedExtendedDuration';
import getSelectedPeopleCount from './getSelectedPeopleCount';
import getSelectedPrices from './getSelectedPrices';
import getSelectedSlot from './getSelectedSlot';
import getSelectedSpace from './getSelectedSpace';
import getSelectedUpSell from './getSelectedUpSell';

const SECONDS_IN_MINUTE = 60;

const getBasketData = createSelector(
  [
    getSelectedSpace,
    getSelectedDate,
    getSelectedSlot,
    getHappening,
    getSelectedPeopleCount,
    getSelectedConfigurationId,
    getSelectedUpSell,
    getPricesAsObjects,
    getSelectedPrices,
    getSelectedDayIncludeTimeSlot,
    getSelectedExtendedDuration,
  ],
  (
    selectedSpace,
    selectedDate,
    selectedSlot,
    selectedHappening,
    selectedPeopleCount,
    selectedConfigurationId,
    selectedUpSell,
    pricesAsObjects,
    selectedPrices,
    day,
    extendedDuration
  ): IZagrywkiBasket | null => {
    if (!selectedHappening || !selectedSpace) {
      return null;
    }

    const dateTime = selectedDate
      ? `${format(day || selectedDate, 'yyyy-MM-dd')} ${
          selectedSlot && selectedSlot.startTime ? selectedSlot.startTime : ''
        }`
      : null;

    const space = selectedHappening.spaces.find(
      (spaceItem) => spaceItem.id === selectedSpace.id
    );

    const getNumberOfPeople = (): number => {
      if (selectedHappening && selectedSpace) {
        if (selectedHappening.calculatePricePerPerson && selectedPeopleCount) {
          return selectedPeopleCount;
        } else if (space) {
          return space.maxNumberOfPeople;
        }
      }

      return 0;
    };

    if (selectedHappening && selectedSpace) {
      const spaceMetadata = getMetadataForLanguage<ISpaceMetadata>([
        selectedSpace.metadata,
      ]).title;

      const happeningMetaData = getMetadataForLanguage<IHappeningMetadataItem>(
        selectedHappening.metadata
      ).title;

      const getConfigurationId = (): number => {
        if (config.cms.showTicketsTypes && selectedConfigurationId) {
          return selectedConfigurationId.rulePriceId;
        } else if (selectedConfigurationId) {
          return selectedConfigurationId.configurationId;
        }
        return -1;
      };

      const getPriceType = (): string => {
        if (
          config.cms.showTicketsTypes &&
          selectedConfigurationId &&
          selectedConfigurationId.prices &&
          selectedConfigurationId.prices.length
        ) {
          const defaultPriceKey = selectedConfigurationId.prices.findIndex(
            (price) => price.type === 'default'
          );

          if (defaultPriceKey !== -1) {
            return selectedConfigurationId.prices[defaultPriceKey].type;
          }

          return selectedConfigurationId.prices[0].type;
        }
        return '';
      };

      const getPrice = (): number => {
        const getPriceValue = (type: string): number => {
          if (getSelectedPrices && getSelectedPrices.length) {
            const currentObject = pricesAsObjects.find(
              (price) => price.type === type
            );

            if (currentObject) {
              return currentObject.value;
            }
          }
          return 0;
        };

        if (
          config.cms.showTicketsTypes &&
          selectedConfigurationId &&
          selectedConfigurationId.prices &&
          selectedConfigurationId.prices.length
        ) {
          const defaultPriceKey = selectedConfigurationId.prices.findIndex(
            (price) => price.type === 'default'
          );

          const numberOfPeople = getNumberOfPeople();

          if (selectedPrices && selectedPrices.length) {
            let price = 0;

            if (!selectedHappening.calculatePricePerPerson) {
              price += getPriceValue(selectedPrices[0]);
            } else {
              if (selectedPrices.length === numberOfPeople) {
                selectedPrices.forEach((_price) => {
                  price += getPriceValue(_price);
                });
              }
            }
            return price;
          }

          if (defaultPriceKey !== -1) {
            return selectedConfigurationId.prices[defaultPriceKey].value;
          }

          return selectedConfigurationId.prices[0].value;
        } else if (selectedConfigurationId && selectedConfigurationId.price) {
          return selectedConfigurationId.price;
        }
        return 0;
      };

      const getTimeRange = () => {
        const timezoneStartDate = moment
          .utc(dateTime)
          .format('YYYY-MM-DD HH:mm:ss');

        const startDate = moment(timezoneStartDate).toDate();

        const eventStartTime = moment(startDate).format('HH:mm');

        if (space) {
          return `${eventStartTime} - ${addTimeWithMoment(
            startDate,
            space.timeSlot / SECONDS_IN_MINUTE +
              (extendedDuration?.duration || 0)
          )}`;
        }
        return '';
      };

      const getUpSellTimeRange = () => {
        const timezoneStartDate = moment
          .utc(dateTime)
          .format('YYYY-MM-DD HH:mm:ss');

        const startDate = moment(timezoneStartDate).toDate();

        const eventStartTime = moment(startDate).format('HH:mm');

        if (space) {
          return `${addTimeWithMoment(
            startDate,
            space.timeSlot / SECONDS_IN_MINUTE
          )} - ${addTimeWithMoment(
            startDate,
            (space.timeSlot / SECONDS_IN_MINUTE) * 2
          )}`;
        }
        return '';
      };
      const getUpSellPrice = (): number => {
        if (
          selectedConfigurationId &&
          selectedConfigurationId.upsell &&
          selectedConfigurationId.upsell.prices &&
          selectedConfigurationId.upsell.prices.length
        ) {
          const defaultPriceKey =
            selectedConfigurationId.upsell.prices.findIndex(
              (price) => price.type === 'default'
            );

          if (defaultPriceKey !== -1) {
            return selectedConfigurationId.upsell.prices[defaultPriceKey].value;
          }
          return selectedConfigurationId.upsell.prices[0].value;
        }

        return 0;
      };

      return {
        calculatePricePerPerson: selectedHappening.calculatePricePerPerson,
        configurationId: getConfigurationId(),
        currency: 'zł',
        dateTime,
        duration: space && space.timeSlot ? space.timeSlot : -1,
        extendedDuration:
          space && space.timeSlot && extendedDuration
            ? extendedDuration.duration * 60 + space?.timeSlot
            : null,
        friendlyExtendedDuration: extendedDuration
          ? `+ ${extendedDuration.duration} min`
          : null,
        happeningId: selectedHappening.id || -1,
        id: Math.random(),
        isUpSellSelected: selectedUpSell,
        maxNumberOfPeople: selectedHappening.maxNumberOfPeople,
        numberOfPeople: getNumberOfPeople(),
        price: getPrice(),
        priceType: getPriceType(),
        priceTypeName: '',
        selectedExtendedSlot: extendedDuration,
        selectedPrices,
        space: spaceMetadata,
        spaceId: selectedSpace.id,
        timeRange: getTimeRange(),
        title: happeningMetaData,
        upSellPice: getUpSellPrice(),
        upSellTimeRange: getUpSellTimeRange(),
      };
    }
    return null;
  }
);

export default getBasketData;
