import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import _Store from '@Store';

import { isSmallMobile } from '@Model/app/selectors';
import { addProduct, productsListMounted } from '@Model/products/actions';
import {
  getAllProducts,
  getCategories,
  getProducts,
  getProductsCategories,
} from '@Model/products/selectors';
import { PRODUCTS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import getUserDefaultStorage from '@Model/user/selectors/getUserDefaultStorage';

import ProductsList from './ProductsList.component';
import {
  IProductListFromDispatch,
  IProductsListFromState,
} from './ProductsList.types';

const mapStateToProps = (state: _Store.IState): IProductsListFromState => {
  return {
    allProducts: getAllProducts(state),
    categories: getCategories(state),
    defaultStorage: getUserDefaultStorage(state),
    isLoading: getLoading(PRODUCTS)(state),
    products: getProducts(state).items,
    productsCategories: getProductsCategories(state),
    smallMobile: isSmallMobile(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IProductListFromDispatch => ({
  addProduct: (product) => dispatch(addProduct(product)),
  mounted: () => dispatch(productsListMounted()),
});

export default connect<
  IProductsListFromState,
  IProductListFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ProductsList);
