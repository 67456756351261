import React from 'react';

import configureStore, { history } from '@/store';
import preLoadedState from '@/store/preLoadedState';
import { ConnectedRouter as Router } from 'connected-react-router';
import { render } from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import App from '@Compo/App';
import AppClassName from '@Compo/functional/AppClassName';
import PrinterLoader from '@Compo/reusable/PrinterLoader';

import i18next from './../translations/i18n';

// Configure store
const store = configureStore(preLoadedState);

render(
  <Provider store={store}>
    <AppClassName>
      <Router history={history}>
        <I18nextProvider i18n={i18next}>
          <PrinterLoader />
          <App />
        </I18nextProvider>
      </Router>
    </AppClassName>
  </Provider>,
  document.getElementById('app')
);

////////////////////////////////////////////////////////////////////////////////
//                           DEVELOPMENT MODE ONLY                            //
////////////////////////////////////////////////////////////////////////////////
if (process.env.NODE_ENV === 'development') {
  if (module.hot && localStorage) {
    const callback = () => {
      const state = store.getState();

      localStorage.setItem('@@hmr', JSON.stringify(state));

      document.location.reload();
    };

    module.hot.accept('./../components/App', callback);
    module.hot.accept('./../store/index.ts', callback);
  }
}
////////////////////////////////////////////////////////////////////////////////
