import { Selector } from 'reselect';

import _Store from '@Store';

import { ISingleUser } from '../types';

const getFormIoUsers: Selector<_Store.IState, ISingleUser[]> = (state) =>
  state.formIo.users.users;

export default getFormIoUsers;
