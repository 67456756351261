import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import MuiDatePicker from '@Compo/reusable/MuiDatePicker';
import { FORM_IO_BIRTHDAY } from '@Model/formio/constants/constants';
import { IData, ISingleUser } from '@Model/formio/types';

import { IFormLayoutProps } from './FormLayout.types';
import { FomIoUserSchema } from './FormLayout.validation';

// TODO:
export const CLOSE_TEXT = 'Zamknij';

const FormLayout = ({
  closeModal,
  user,
  addEntryCode,
  updateUser,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'ConsentsList.components.FormLayout',
  });

  const [entryCode, setEntryCode] = useState('');
  const [isEditing, handleEdit] = useState(false);

  const handleCodeChange = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEntryCode(event.currentTarget.value);
  };

  const handleAddCode = () => {
    addEntryCode(entryCode);
    setEntryCode('');
  };

  const calculateAge = (birthDate?: string) => {
    if (birthDate) {
      return moment().diff(birthDate, 'years', false);
    }
    return '';
  };

  const handleCloseModal = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    closeModal();
  };

  const normalizeDataToSend = (data: IData): ISingleUser | undefined => {
    if (user) {
      return {
        birthday: data.dataurodzenia,
        email: data.email || '',
        firstName: data.firstname || '',
        id: user.user.id,
        lastName: data.lastname || '',
      };
    }
  };

  const handleSendForm = (data: IData) => {
    const normalizedData = normalizeDataToSend(data);
    if (normalizedData) {
      updateUser(normalizedData);
    }
  };

  if (!user) {
    return null;
  }

  const initialValues: IData = {
    dataurodzenia: user.retrivedBirthDay,
    email: user.user.email,
    firstname: user.user.firstName,
    lastname: user.user.lastName,
  };

  const hasFormSubmission = () =>
    !!user.lastSubmissionData?.data?.page4Numbergen;

  return (
    <Formik
      enableReinitialize={true}
      onSubmit={handleSendForm}
      initialValues={initialValues}
      validationSchema={FomIoUserSchema}
    >
      {({
        values,
        handleChange,
        touched,
        handleBlur,
        errors,
        setFieldValue,
      }) => (
        <>
          <DialogContent>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <TextField
                  variant="standard"
                  id="_id"
                  label={t('id')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={user.lastSubmissionId}
                  name="_id"
                  fullWidth={true}
                  disabled={true}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <TextField
                  id="firstname"
                  label={(touched.firstname && errors.firstname) || t('name')}
                  helperText={t('nameHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstname}
                  name="firstname"
                  error={!!(touched.firstname && errors.firstname)}
                  fullWidth={true}
                  disabled={!isEditing}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <TextField
                  id="lastname"
                  label={(touched.lastname && errors.lastname) || t('lastName')}
                  helperText={t('lastNameHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastname}
                  name="lastname"
                  error={!!(touched.lastname && errors.lastname)}
                  fullWidth={true}
                  disabled={!isEditing}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <TextField
                  id="email"
                  label={(touched.email && errors.email) || t('email')}
                  helperText={t('emailHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  name="email"
                  error={!!(touched.email && errors.email)}
                  fullWidth={true}
                  disabled={!isEditing}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <MuiDatePicker
                  name={FORM_IO_BIRTHDAY}
                  disableToolbar={true}
                  fullWidth={true}
                  variant="standard"
                  format="DD.MM.yyyy"
                  label={
                    (touched[FORM_IO_BIRTHDAY] && errors[FORM_IO_BIRTHDAY]) ||
                    t('birthday')
                  }
                  helperText={t('birthdayHelper')}
                  value={values[FORM_IO_BIRTHDAY]}
                  onChange={(date) => setFieldValue(FORM_IO_BIRTHDAY, date)}
                  disabled={!isEditing || !hasFormSubmission()}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <TextField
                  variant="standard"
                  id="age"
                  label={t('age')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={calculateAge(values[FORM_IO_BIRTHDAY])}
                  name="age"
                  fullWidth={true}
                  disabled={true}
                />
              </Grid>
              <Grid item={true} xs={12}>
                <Typography variant="body1">{t('entryCodes')}</Typography>
                {user.transactionPositions.map((position, index) => (
                  <Typography key={index} variant="body2" color="textSecondary">
                    {position.code}
                  </Typography>
                ))}
              </Grid>
              <Grid item={true} xs={12}>
                <Grid
                  container={true}
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item={true} xs={8}>
                    <TextField
                      variant="standard"
                      id="addcode"
                      label={t('addCode')}
                      helperText={t('addCodeHelper')}
                      onChange={handleCodeChange}
                      value={entryCode}
                      name="addcode"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item={true} xs={3}>
                    <Button
                      onClick={handleAddCode}
                      variant="outlined"
                      size="small"
                      color="primary"
                      startIcon={<AddIcon />}
                    >
                      {t('addEntryCode')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClick={handleCloseModal}
            >
              {CLOSE_TEXT}
            </Button>
            <Button
              onClick={() => handleEdit(!isEditing)}
              variant="outlined"
              size="large"
              color="secondary"
            >
              {isEditing ? t('cancel') : t('editData')}
            </Button>
            {isEditing && (
              <Button
                onClick={() => handleSendForm(values)}
                variant="contained"
                size="large"
                color="primary"
              >
                {t('save')}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

export default FormLayout;
