import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  captureTransactionsEmpikDetailsRequest,
  getReservationPrintData,
  selectAdvanceTransaction,
} from '@Model/reservation/actions/index';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import { resetError, resetLoading, setLoading } from '../actions';
import { TRANSACTION_DETAILS } from '../constants/constants';

export const setUpStateWhenTransactionDetailsFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(
      isActionOf([
        captureTransactionsEmpikDetailsRequest.request,
        selectAdvanceTransaction,
      ])
    ),
    mergeMap$(() => [
      resetError(TRANSACTION_DETAILS),
      setLoading(TRANSACTION_DETAILS),
    ])
  );
};

export const setLoaderWhenTransactionDetailsFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          getReservationPrintData.success,
          captureTransactionsEmpikDetailsRequest.failure,
        ],
        action
      )
    ),
    map$(() => resetLoading(TRANSACTION_DETAILS))
  );
};

export const setErrorOnTransactionDetailsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([captureTransactionsEmpikDetailsRequest.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
