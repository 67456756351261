import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    backgroundColor: theme.palette.action.focus,
    padding: theme.spacing(6),
  },
  form: {
    width: '100%',
  },
}));

export default useStyles;
