import { IConfiguration, TRuleType } from '@Model/configurations/types';

export const getRuleType = (
  configuration: IConfiguration,
): TRuleType | undefined => {
  if (
    configuration.prices &&
    configuration.prices.length &&
    configuration.prices[0].type
  ) {
    return 'price';
  } else if (configuration.isClosed !== undefined) {
    return 'time';
  } else if (configuration.capacity !== undefined) {
    return 'capacity';
  }
  return undefined;
};
