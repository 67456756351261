import { ITicket } from '@Services/$tickets-api/types';
import _Store from '@Store';
import { createSelector } from 'reselect';
import { ITicketsReducer } from '../types';
import get from './get';

const getData = createSelector<_Store.IState, ITicketsReducer, ITicket[]>(
  [get],
  (tickets) => tickets.data
);

export default getData;
