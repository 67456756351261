import { createSelector } from 'reselect';

import _Store from '@Store';
import { IProductsReducer, IShipmentFee } from '../types';
import get from './get';

const getShipmentFees = createSelector<
  _Store.IState,
  IProductsReducer,
  IShipmentFee[]
>([get], (products) => products.shipmentFees);

export default getShipmentFees;
