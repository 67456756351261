import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.action.focus,
    marginBottom: theme.spacing(6),
  },
  flexBasis: {
    flexBasis: 'auto',
  },
}));

export default useStyles;
