import React from 'react';

import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { IMuiDatePickerProps } from './MuiDatePicker.types';

const MuiDatePicker = (props: IMuiDatePickerProps) => {
  const handleOnChange = (date: any) => {
    props.onChange(date);
  };

  return (
    <DatePicker
      {...props}
      onChange={handleOnChange}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth={props.fullWidth}
          helperText={props.helperText}
          variant={props.variant}
          onBlur={props.onBlur}
          label={props.label}
          error={props.error}
          name={props.name}
        />
      )}
    />
  );

  // return (
  //   <KeyboardDatePicker
  //     {...props}
  //     onChange={handleOnChange}
  //     open={wasOpened}
  //     onFocus={onFocus}
  //     onOpen={handleOnOpen}
  //     onClose={handleOnClose}
  //   />
  // );
};

export default MuiDatePicker;
