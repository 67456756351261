import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import DefinedConfigurations from '@Compo/BetterManager/DefinedConfigurations';

import { IConfigurationListOwnProps } from './ConfigurationList.types';

export const ConfigurationList = ({
  handleAdd,
  handleClose,
  handleActiveConfiguration,
}: IConfigurationListOwnProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'BetterManager.ConfigurationList',
  });

  return (
    <Dialog
      open={true}
      maxWidth="md"
      fullWidth={true}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <DefinedConfigurations
          handleAdd={handleAdd}
          modal={true}
          handleEditConfiguration={handleActiveConfiguration}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigurationList;
