import moment from 'moment';
import { createSelector } from 'reselect';
import { RRule, Weekday } from 'rrule';

import _Store from '@Store';

import { daysTranslator } from '@Compo/BetterManager/AddingConfiguration/components/ConfigurationModal/ConfigurationModal.helpers';
import checkIsEndAfterMidnight from '@Misc/helpers/checkIsEndAfterMidnight';
import { getConfigurations } from '@Model/configurations/selectors';
import {
  IConfiguration,
  INormalizedConfiguration,
} from '@Model/configurations/types';

const UP_TEXT = 'podwyż';
const DOWN_TEXT = 'obniż';
const YES_TEXT = 'tak';
const NO_TEXT = 'nie';

const getNormalizedConfigurations = createSelector<
  _Store.IState,
  IConfiguration[],
  INormalizedConfiguration[]
>([getConfigurations], (configurations) => {
  return configurations.map((configuration, index) => {
    const {
      id,
      title,
      prices,
      closed,
      maxNumberOfPeople,
      upsell,
      duration,
      fromNumberOfPeopleModifier,
      validFrom,
      validTo,
      isClosed,
      capacity,
    } = configuration;

    const getUpSell = () => {
      return upsell && (upsell.value || upsell.percentageValue)
        ? `${upsell.value} ${upsell.percentageValue ? '%' : ''}`
        : undefined;
    };

    const getFromNumberOfPeopleModifier = () => {
      if (fromNumberOfPeopleModifier) {
        const { value, priceGoesUp, fromNumberOfPeople, percentageValue } =
          fromNumberOfPeopleModifier;

        return ` ${`od: ${fromNumberOfPeople} ${
          priceGoesUp ? UP_TEXT : DOWN_TEXT
        } o ${value} ${percentageValue ? '%' : 'zł'}`}
              `;
      }
      return undefined;
    };

    const getDays = () => {
      const options = RRule.fromString(configuration.rrule).origOptions;

      if (options.byweekday) {
        const arrayDays = options.byweekday as Weekday[];
        const daysString = arrayDays
          .map((day) => daysTranslator(day.toString()))
          .join(',');

        return `${daysString}`;
      } else if (options.bymonth && options.bymonthday) {
        return `${
          checkIsEndAfterMidnight(configuration.rrule, duration, null, null)
            ? moment(validTo).subtract(2, 'day').format('DD-MM-YYYY')
            : moment(validTo).subtract(1, 'day').format('DD-MM-YYYY')
        }`;
      }
    };

    const getMaxPeople = () => {
      return `${maxNumberOfPeople ? maxNumberOfPeople : '-'}`;
    };
    const getClosed = () => {
      return isClosed || closed ? YES_TEXT : NO_TEXT;
    };

    const getType = () => {
      if (prices && prices.length && prices[0].type) {
        return 'Cenowa';
      } else if (isClosed !== undefined) {
        return 'Czasowa';
      } else if (capacity !== undefined) {
        return 'Pojemnościowa';
      }

      return '-';
    };

    const getTime = () => {
      const options = RRule.fromString(configuration.rrule).origOptions;

      if (
        typeof options.byhour === 'number' &&
        typeof options.byminute === 'number'
      ) {
        const nextDate = moment().toDate();

        nextDate.setHours(options.byhour);
        nextDate.setMinutes(options.byminute);
        const helpDate = moment(nextDate).add(duration, 'seconds');
        const isSameDay = moment(nextDate).isSame(helpDate, 'day');

        return `${moment(nextDate).format('H:m')}-${helpDate.format('H:m')} ${
          !isSameDay ? 'n. d.' : ''
        }`;
      }
    };

    const isActive = (): boolean => {
      if (validFrom && validTo) {
        return moment().isBetween(moment(validFrom), moment(validTo));
      } else if (validFrom && !validTo) {
        return moment().isAfter(moment(validFrom));
      }
      return true;
    };

    const getDate = () => {
      if (validFrom && validTo) {
        return `${moment(validFrom).format('DD.MM.YYYY')} - ${moment(validTo)
          .subtract(1, 'day')
          .format('DD.MM.YYYY')}`;
      } else if (validFrom) {
        return moment(validFrom).format('DD.MM.YYYY');
      }
      return '-';
    };

    return {
      closed: getClosed(),
      date: getDate(),
      days: getDays(),
      group: getFromNumberOfPeopleModifier(),
      id,
      maxPeople: getMaxPeople(),
      name: title,
      price: prices?.find((price) => price.type === 'default')?.value || '-',
      prices,
      time: getTime(),
      type: getType(),
      upsell: getUpSell(),
    };
  });
});

export default getNormalizedConfigurations;
