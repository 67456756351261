import { Selector } from 'reselect';

import _Store from '@Store';
import { IReservationPrintDataReducer } from '../types';

const getReservationPrintData: Selector<
  _Store.IState,
  IReservationPrintDataReducer
> = (state) => state.reservation.reservationPrintData;

export default getReservationPrintData;
