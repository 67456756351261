import {
  getReservationDetails,
  resetSelection,
  selectReservation,
} from '@Model/reservations/actions';
import { getType } from 'typesafe-actions';
import { IAction, IReservationsDetailsReducer } from './../types';

const initialState: IReservationsDetailsReducer = null;

const detailsReducer = (
  state: IReservationsDetailsReducer = initialState,
  action: IAction,
): IReservationsDetailsReducer => {
  switch (action.type) {
    case getType(selectReservation):
      return action.payload;
    case getType(getReservationDetails.success):
      return action.payload;
    case getType(resetSelection):
      return initialState;
    default:
      return state;
  }
};

export default detailsReducer;
