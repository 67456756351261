import { createSelector } from 'reselect';

import _Store from '@Store';

import { IMetadataReducer } from './../types';
import get from './get';

const getFilterByActivePartner = createSelector<
  _Store.IState,
  IMetadataReducer,
  boolean
>([get], (metadata) => {
  return metadata.filterByActivePartner;
});

export default getFilterByActivePartner;
