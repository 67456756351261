import { useEffect, useState } from 'react';

import { Cloudinary } from '@cloudinary/url-gen';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';

import config from '@Config';
import fillUrlWithValues from '@Misc/helpers/fillUrlWithValues';

import { ICloudinaryList } from './useCloudinaryApi.types';

export const useCloudinaryApi = (
  slug?: string,
  prefix?: string
): { cloudinaryImagesUrls: string[] } => {
  const [cloudinaryImagesUrls, setCloudinaryImagesUrls] = useState<string[]>(
    []
  );

  const { urlCloudinaryList, cloudName } = config.cloudinary;

  const url = slug
    ? prefix
      ? fillUrlWithValues(
          urlCloudinaryList,
          [':cloudName', ':prefix', ':slug'],
          [cloudName, prefix, slug]
        )
      : fillUrlWithValues(
          urlCloudinaryList,
          [':cloudName', ':slug'],
          [cloudName, slug]
        )
    : undefined;

  const cloudinary = new Cloudinary({
    cloud: {
      cloudName,
    },
  });

  useEffect(() => {
    if (url) {
      const subscription = ajax
        .getJSON<ICloudinaryList>(url)
        .pipe(
          map((response) => response.resources),
          catchError(() => [])
        )
        .subscribe((data) => {
          const imagesUrls = data.map((image) =>
            cloudinary.image(image.public_id).toURL()
          );
          setCloudinaryImagesUrls(imagesUrls);
        });

      return () => subscription.unsubscribe();
    }
  }, [slug]);

  return { cloudinaryImagesUrls };
};

export default useCloudinaryApi;
