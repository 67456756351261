import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { ITextEditorStyle } from './TextEditor.types';

const useStyles = makeStyles<Theme, ITextEditorStyle>((theme) =>
  createStyles({
    disabled: {
      opacity: 0.6,
      pointerEvents: 'none',
    },
    editor: {
      overflow: 'initial',
      paddingBottom: 18.5,
      paddingLeft: 14,
      paddingRight: 14,
      paddingTop: 18.5,
      position: 'relative',

      '& .public-DraftEditor-content': {
        border: ({ color }) =>
          color ? `1px solid ${color}` : '1px solid #181A22',
        minHeight: 300,
      },

      '&::before': {
        backgroundColor: ({ color }) => (color ? color : '#181A22'),
        color: ({ error }) =>
          error ? theme.palette.error.main : theme.palette.text.secondary,
        content: ({ label }) => `"${label}"`,
        display: 'block',
        fontSize: 16,
        left: 0,
        paddingLeft: 10,
        paddingRight: 10,
        position: 'absolute',
        top: 0,
        transform: 'translate(-10.5px, -16px) scale(0.75)',
        zIndex: 1,
      },
    },
    focused: {
      borderColor: `${theme.palette.primary.main}!important`,
      boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
    },
    labelFocused: {
      '&::before': {
        color: `${theme.palette.primary.main}!important`,
      },
    },
    toolbar: {
      backgroundColor: 'transparent',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'inherit!important',
      borderRadius: 0,
      color: 'black',
      fontFamily: 'NoirPro',
      paddingBottom: 10,

      '& .rdw-option-wrapper': {
        background: 'transparent',
        border: 'none',
        filter:
          'brightness(0) saturate(100%) invert(74%) sepia(24%) saturate(16%) hue-rotate(314deg) brightness(95%) contrast(94%);',
        margin: 0,
        padding: 0,

        '&:hover': {
          boxShadow: 'none',
        },
      },

      '& .rdw-dropdown-wrapper': {
        background: 'transparent',
        borderColor: theme.palette.other.stroke,
        borderRadius: 4,
        color: theme.palette.text.primary,
      },

      '& .rdw-dropdown-carettoopen': {
        borderTopColor: theme.palette.other.stroke,
      },

      '& .rdw-dropdown-carettoclose': {
        borderBottomColor: theme.palette.other.stroke,
      },

      '& .rdw-dropdown-optionwrapper': {
        backgroundColor: ({ color }) => (color ? color : '#181A22'),

        '&:hover': {
          backgroundColor: ({ color }) => (color ? color : '#181A22'),
        },
      },

      '& .rdw-dropdownoption-highlighted, & .rdw-dropdownoption-active': {
        backgroundColor: theme.palette.primary.main,
      },

      '& .rdw-inline-wrapper,& .rdw-colorpicker-wrapper': {
        borderRight: '1px solid #272B35',
        paddingRight: 8,
      },

      '& .rdw-fontfamily-optionwrapper': {
        overflowY: 'auto',
      },

      '& .rdw-text-align-wrapper, & .rdw-list-wrapper, & .rdw-image-wrapper': {
        borderRight: '1px solid #272B35',
        paddingLeft: 8,
        paddingRight: 8,
      },

      '& .rdw-link-wrapper,& .rdw-history-wrapper': {
        paddingLeft: 8,
      },

      '& .rdw-colorpicker-modal .rdw-option-wrapper': {
        filter: 'none',
      },

      '& .rdw-option-active': {
        boxShadow: 'none',
        filter: 'none',
      },

      '& .imagePopup, & .linkPopup,& .colorPopup': {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        borderRadius: 16,
        boxShadow: 'none',
        color: theme.palette.text.primary,
        paddingBottom: 16,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 24,
      },

      '& .imagePopup': {
        '& .rdw-image-modal-header': {
          display: 'none',
        },

        '& .rdw-image-modal-size': {
          color: 'transparent',
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: 0,
          position: 'relative',

          '&::before': {
            color: theme.palette.text.primary,
            content: '"Wysokość"',
            fontSize: 12,
            left: 16,
            position: 'absolute',
            top: 23,
          },

          '&::after': {
            color: theme.palette.text.primary,
            content: '"Szerokość"',
            fontSize: 12,
            left: 16,
            position: 'absolute',
            top: 97,
          },
        },

        '& .rdw-image-modal-url-section': {
          '&::before': {
            content: '"Link do obrazka"',
            fontSize: 12,
            left: 38,
            position: 'absolute',
            top: 31,
          },
        },

        '& .rdw-image-modal-url-input,& .rdw-image-modal-size-input': {
          backgroundColor: ({ color }) => (color ? color : '#181A22'),
          border: ({ color }) =>
            color ? `16px solid ${color}` : `16px solid #181A22`,
          borderRadius: 8,
          boxShadow: `0 -1px 0 ${theme.palette.text.primary} inset`,
          color: theme.palette.text.primary,
          margin: 0,
          paddingTop: 4,
        },

        '& .rdw-image-modal-size-input': {
          width: '100%',
        },

        '& .rdw-image-mandatory-sign': {
          display: 'none',
        },

        '& .rdw-image-modal-btn-section': {
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'flex-start',
          margin: 0,

          '& button': {
            border: 'none',
            borderRadius: 16,
            transition:
              'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },

          '& button:nth-of-type(1)': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,

            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              boxShadow: 'none',
            },
          },

          '& button:nth-of-type(2)': {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,

            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              boxShadow: 'none',
            },
          },
        },
      },

      '& .colorPopup': {
        '& .rdw-colorpicker-modal-style-label-active': {
          borderColor: theme.palette.primary.main,
        },
      },

      '& .linkPopup': {
        height: 'auto',
        width: 'max-content',

        '& .rdw-link-modal-label': {
          cursor: 'text',
          height: 8,
          margin: 0,
          transform: 'translateY(12px) translateX(-6px) scale(0.8)',
        },

        '& .rdw-link-modal-buttonsection': {
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'flex-start',
          margin: 0,

          '& button': {
            border: 'none',
            borderRadius: 16,
            transition:
              'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },

          '& button:nth-of-type(1)': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,

            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              boxShadow: 'none',
            },
          },

          '& button:nth-of-type(2)': {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,

            '&:hover': {
              backgroundColor: theme.palette.primary.dark,
              boxShadow: 'none',
            },
          },
        },

        '& .rdw-link-modal-input': {
          backgroundColor: ({ color }) => (color ? color : '#181A22'),
          border: ({ color }) =>
            color ? `16px solid ${color}` : `16px solid #181A22`,
          borderRadius: 8,
          boxShadow: `0 -1px 0 ${theme.palette.text.primary} inset`,
          color: theme.palette.text.primary,
          height: 70,
          margin: 0,
          paddingTop: 4,
        },

        '& #linkTarget': {
          marginBottom: 8,
        },

        '& #openLinkInNewWindow': {
          appearance: 'none',
          backgroundColor: 'transparent',
          border: `2px solid ${theme.palette.text.secondary}`,
          borderRadius: 4,
          margin: 9,
          minHeight: 18,
          minWidth: 18,

          '&:checked': {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
          },

          '&::after': {
            borderBottomWidth: 2,
            borderColor: ({ color }) => (color ? `${color}` : '#181A22'),
            borderRightWidth: 2,
            borderStyle: 'solid',
            content: '""',
            display: 'none',
            height: 14,
            left: '50%',
            position: 'relative',
            top: '50%',
            transform: 'rotate(45deg) translate(-50%, 10%)',
            width: 7,
          },

          '&:checked::after': {
            borderLeftWidth: 0,
            borderTopWidth: 0,
            display: 'block',
          },
        },

        '& .rdw-link-modal-target-option': {
          alignItems: 'center',
          display: 'flex',
        },
      },
    },
    wrapper: {
      border: '1px solid',
      borderColor: ({ error }) =>
        error ? theme.palette.error.main : theme.palette.other.stroke,
      borderRadius: 4,

      '&:hover': {
        borderColor: ({ error }) =>
          error ? theme.palette.error.main : theme.palette.text.primary,
      },

      '& .rdw-link-decorator-wrapper': {
        '& .rdw-link-decorator-icon': {
          display: 'none',
        },
      },
    },
  })
);

export default useStyles;
