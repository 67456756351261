// Private
export const _GET_REQUEST = 'Zagrywki/discounts/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/discounts/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/discounts/_GET_FAILURE';

export const _GET_EVENTS_DISCOUNT_REQUEST =
  'Zagrywki/discounts/_GET_EVENTS_DISCOUNT_REQUEST';
export const _GET_EVENTS_DISCOUNT_SUCCESS =
  'Zagrywki/discounts/_GET_EVENTS_DISCOUNT_SUCCESS';
export const _GET_EVENTS_DISCOUNT_FAILURE =
  'Zagrywki/discounts/_GET_EVENTS_DISCOUNT_FAILURE';

// Public
export const MOUNTED = 'Zagrywki/discounts/MOUNTED';
export const MOUNTED_EVENTS = 'Zagrywki/discounts/MOUNTED_EVENTS';
export const UPDATE_SEARCH_TEXT = 'Zagrywki/discounts/UPDATE_SEARCH_TEXT';
export const SET_ACTIVE_FILTER = 'Zagrywki/discounts/SET_ACTIVE_FILTER';
export const HANDLE_DISCOUNT_ACTIVE_PAGE =
  'Zagrywki/discounts/HANDLE_DISCOUNT_ACTIVE_PAGE';
export const _RESET_STATE = 'Zagrywki/discounts/_RESET_STATE';
export const UPDATE_SEARCH_EVENT_TEXT =
  'Zagrywki/discounts/UPDATE_SEARCH_EVENT_TEXT';
