import moment from 'moment';
import { createSelector } from 'reselect';

import { getAvailabilities } from '@Model/happening/selectors';
import { IAvailabilitiesReducer } from '@Model/happening/types';
import _Store from '@Store';
import { ITimeSlot } from './../types';
import getSelectedDate from './getSelectedDate';
import getSelectedSlot from './getSelectedSlot';

const getSelectedDayIncludeTimeSlot = createSelector<
  _Store.IState,
  Date | null,
  ITimeSlot | null,
  IAvailabilitiesReducer,
  Date | null
>(
  [getSelectedDate, getSelectedSlot, getAvailabilities],
  (selectedDate, selectedSlot, availabilities) => {
    if (
      selectedDate &&
      selectedSlot &&
      availabilities.otherDays &&
      availabilities.otherDays[selectedSlot.startTime]
    ) {
      return moment(selectedDate)
        .add('days', 1)
        .toDate();
    }
    return selectedDate;
  }
);

export default getSelectedDayIncludeTimeSlot;
