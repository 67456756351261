import * as Yup from 'yup';

import config from '@Config';

export const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
export const NOT_NUMBER_VALIDATOR_TEXT = 'Podaj liczbę';
export const DURATION_VALIDATOR_TEXT = 'Niepoprawny format';
const MAX_HOURS_VALIDATOR_TEXT = 'Liczba godzin nie powinna przekraczać 23.';
const MAX_EXTENDED_SLOT_TEXT =
  'Liczba wydłużenia slotów nie powinna przekraczać 6.';

const reDuration = /^(\d{1,2}h|\d{1,2}m|\d{1,2}h\d{1,2}m)$/i;
const MAX_HOURS = 23;

export const durationValidator = Yup.string().matches(
  reDuration,
  DURATION_VALIDATOR_TEXT
);

export const numberValidator = Yup.number()
  .typeError(NOT_NUMBER_VALIDATOR_TEXT)
  .required(REQUIRED_VALIDATOR_TEXT);

const configuration = {
  canBeBookedMinutesMin: Yup.number()
    .typeError(NOT_NUMBER_VALIDATOR_TEXT)
    .max(MAX_HOURS, MAX_HOURS_VALIDATOR_TEXT)
    .nullable(),
  multipleSlotReservation: Yup.number()
    .when('multipleSlotReservationChecked', {
      is: true,
      then: Yup.number()
        .typeError(NOT_NUMBER_VALIDATOR_TEXT)
        .required(REQUIRED_VALIDATOR_TEXT),
    })
    .max(config.cms.maxExtendedSlots, MAX_EXTENDED_SLOT_TEXT)
    .nullable(),
  name: Yup.string().required(REQUIRED_VALIDATOR_TEXT),
  startDate: Yup.string().nullable().required(REQUIRED_VALIDATOR_TEXT),
};

export const ManagerConfigurationSchema = Yup.object().shape(configuration);
