import React from 'react';

import Grid from '@Compo/layout/Grid';
import RefundsRegistration from '@Compo/RefundsRegistration';

const RefundsRegistrationPage = () => (
  <Grid>
    <RefundsRegistration />
  </Grid>
);

export default RefundsRegistrationPage;
