import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import GenericTable from '@Compo/reusable/GenericTable';
import TableUi from '@Compo/reusable/TableUi';
import config from '@Config';
import {
  IPayment,
  ITransaction,
  TTransactionType,
} from '@Model/reservations/types';

import MixPaymentInDetails from './components/MixPaymentInDetails';
import { IConsentsListProps } from './TransactionsDelayed.types';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    paddingRight: theme.spacing(3),
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
    },
  },
  buttonWithoutPadding: {
    paddingRight: theme.spacing(1.25),
  },
  container: {
    marginBottom: theme.spacing(2),
    width: 226,
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5rem',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  typography: {
    marginBottom: theme.spacing(1),
  },
}));

const TransactionDelayed = ({
  mounted,
  delayedTransactions,
  cancelTransaction,
  limit,
  activePage,
  handleActivePage,
  totalCount,
  handleRowsPerPage,
  transactionType,
  handleTransactionType,
  completeAdvancePayment,
  selectAdvanceTransaction,
  isTransactionDetailsLoading,
  isLoading,
}: IConsentsListProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'TransactionsDelayed',
  });

  useEffect(() => {
    mounted();
  }, []);

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] =
    useState<ITransaction | null>(null);

  const calculateLeftToPay = (data: IPayment[], revenue: number) => {
    let total = 0;
    data.forEach((payment) => {
      total += payment.amount;
    });
    return `${revenue - total} ${t('currency')}`;
  };

  const columns = [
    { id: 'id', label: t('id') },
    { id: 'link', label: t('link') },
    { id: 'delayedAt', label: t('delayedAt') },
    { id: 'actions', label: t('action') },
  ];

  const advanceColumns = [
    { id: 'id', label: t('id') },
    { id: 'firstName', label: t('firstName') },
    { id: 'lastName', label: t('lastName') },
    { id: 'date', label: t('date') },
    { id: 'leftToPay', label: t('leftToPay') },
    { id: 'actions', label: t('action') },
  ];

  const getLink = (uuid: string): string => {
    return config.api.clientAppUrl + '?extendedPayment=' + uuid;
  };

  const getTimezoneDate = (date?: string): string => {
    if (date) {
      const timezoneDate = moment.utc(date).format('YYYY-MM-DD HH:mm:ss');
      return moment(timezoneDate).format('DD.MM.YYYY HH:mm');
    }
    return '';
  };

  const getDelayedTime = (seconds: number): string => {
    if (seconds > 86400) {
      return moment
        .utc(seconds * 1000)
        .subtract(1, 'days')
        .format('D [dni] HH:mm:ss');
    }
    return moment.utc(seconds * 1000).format('HH:mm:ss');
  };

  const catchChangeRowsPerPage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleRowsPerPage(Number(e.target.value));
  };

  const normalizedAdvanceTransaction =
    delayedTransactions?.map((transaction) => ({
      actions: (
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={cn(classes.button, classes.buttonWithoutPadding)}
          onClick={(e) => {
            e.stopPropagation();
            completeAdvancePayment(transaction.transactionUUID);
          }}
        >
          {t('pay')}
        </Button>
      ),
      date: transaction.items[0]?.startDate
        ? moment(transaction.items[0].startDate.split('+')[0]).format(
            'DD.MM.YYYY, HH:mm'
          )
        : '-',
      firstName: transaction.user.firstName,
      id: transaction.transactionUUID,
      lastName: transaction.user.lastName,
      leftToPay: calculateLeftToPay(transaction.payments, transaction.revenue),
    })) || [];

  const normalizedDelayedTransaction =
    delayedTransactions?.map((transaction) => ({
      actions: (
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={cn(classes.button, classes.buttonWithoutPadding)}
          onClick={(e) => {
            e.stopPropagation();
            cancelTransaction(transaction.transactionUUID);
          }}
        >
          {t('remove')}
        </Button>
      ),
      delayedAt: getDelayedTime(transaction.secondsToExpire),
      id: transaction.transactionUUID,
      link: getLink(transaction.transactionUUID),
    })) || [];

  const catchRowClicked = (id: string) => {
    const foundedTransaction = delayedTransactions?.find(
      (transaction) => transaction.transactionUUID === id
    );
    if (foundedTransaction) {
      setSelectedTransaction(foundedTransaction);
      selectAdvanceTransaction(id);
      setOpen(true);
    }
  };

  const catchTransactionType = (event: SelectChangeEvent<TTransactionType>) => {
    const { value } = event.target;

    handleTransactionType(value as TTransactionType);
  };

  const renderHeader = () => {
    return (
      <div className={classes.container}>
        <FormControl variant="standard" fullWidth={true}>
          <Select
            value={transactionType}
            onChange={catchTransactionType}
            variant="filled"
            data-testid="transactionDelayedSelect"
          >
            <MenuItem data-testid="transactionDelayed" value={'delay'}>
              {t('delayed')}
            </MenuItem>
            <MenuItem
              data-testid="transactionPaymentAdvance"
              value={'paymentAdvance'}
            >
              {t('paymentAdvance')}
            </MenuItem>
          </Select>
          <FormHelperText>{t('transactionSelectHelper')}</FormHelperText>
        </FormControl>
      </div>
    );
  };

  const checkIsFinalized = () => {
    return !selectedTransaction?.payments.length;
  };

  if (isLoading) {
    return (
      <GoingPaper header={renderHeader()}>
        <div className={classes.loader}>
          <Loader />
        </div>
      </GoingPaper>
    );
  }

  return (
    <GoingPaper header={renderHeader()}>
      <GenericTable>
        <TableUi
          data-testid="transactionDelayedTable"
          rows={
            transactionType === 'delay'
              ? normalizedDelayedTransaction
              : normalizedAdvanceTransaction
          }
          columns={transactionType === 'delay' ? columns : advanceColumns}
          total={totalCount}
          activePage={activePage}
          rowsPerPage={limit}
          handleChangePage={handleActivePage}
          handleChangeRowsPerPage={catchChangeRowsPerPage}
          disableCheckBox={true}
          onRowClick={catchRowClicked}
        />
      </GenericTable>

      <Dialog
        open={open}
        maxWidth="md"
        fullWidth={true}
        onClose={() => setOpen(false)}
        scroll="paper"
      >
        <DialogTitle>{t('transactionDetails')}</DialogTitle>
        <DialogContent>
          {isTransactionDetailsLoading && (
            <div className={classes.loader}>
              <Loader />
            </div>
          )}
          {!isTransactionDetailsLoading &&
            selectedTransaction?.items.map((item, index) => (
              <Grid
                container={true}
                key={`reservation-${index}`}
                direction="column"
              >
                <Typography variant="body1" className={classes.title}>
                  {`Rezerwacja ${index + 1}`}
                </Typography>
                <Grid container={true}>
                  <Grid item={true} xs={true}>
                    <Typography
                      component="p"
                      variant="caption"
                      color="textSecondary"
                      className={classes.typography}
                    >
                      {t('bookingId')}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      color="textSecondary"
                      className={classes.typography}
                    >
                      {t('happeningId')}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      color="textSecondary"
                      className={classes.typography}
                    >
                      {t('spaceId')}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      color="textSecondary"
                      className={classes.typography}
                    >
                      {t('firstName')}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      color="textSecondary"
                      className={classes.typography}
                    >
                      {t('lastName')}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      color="textSecondary"
                      className={classes.typography}
                    >
                      {t('start')}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      color="textSecondary"
                      className={classes.typography}
                    >
                      {t('end')}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      color="textSecondary"
                      className={classes.typography}
                    >
                      {t('numberOfPeople')}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      color="textSecondary"
                      className={classes.typography}
                    >
                      {t('lockCode')}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={true}>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.typography}
                    >
                      {item.bookingIdentifier}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.typography}
                    >
                      {item.productName}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.typography}
                    >
                      {item.rundateName}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.typography}
                    >
                      {selectedTransaction.user.firstName}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.typography}
                    >
                      {selectedTransaction.user.lastName}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.typography}
                    >
                      {getTimezoneDate(item.startDate)}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.typography}
                    >
                      {getTimezoneDate(item.endDate)}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.typography}
                    >
                      {item.quantity}
                    </Typography>
                    <Typography
                      component="p"
                      variant="caption"
                      className={classes.typography}
                    >
                      {item.lockCode || '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          {!isTransactionDetailsLoading &&
            !!selectedTransaction?.products.length && (
              <Grid container={true} item={true} xs={12} spacing={2}>
                {selectedTransaction.products.map((product, index) => (
                  <>
                    <Grid item={true} xs={12}>
                      <Typography variant="body1">
                        {t('products')} {index + 1}
                      </Typography>
                    </Grid>
                    <Grid container={true} item={true} xs={12} spacing={1}>
                      <Grid item={true} xs={6}>
                        <Typography
                          component="p"
                          variant="caption"
                          color="textSecondary"
                        >
                          {t('productName')}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography component="p" variant="caption">
                          {product.productName}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography
                          component="p"
                          variant="caption"
                          color="textSecondary"
                        >
                          {t('quantity')}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography component="p" variant="caption">
                          {product.quantity}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography
                          component="p"
                          variant="caption"
                          color="textSecondary"
                        >
                          {t('price')}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={6}>
                        <Typography component="p" variant="caption">
                          {product.amount} {t('currency')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                ))}
              </Grid>
            )}
          {!isTransactionDetailsLoading && !checkIsFinalized() && (
            <Grid item={true} xs={12}>
              <MixPaymentInDetails />
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            onClick={() => setOpen(false)}
          >
            {t('close')}
          </Button>
          {!isTransactionDetailsLoading && !checkIsFinalized() && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => {
                if (selectedTransaction) {
                  completeAdvancePayment(selectedTransaction.transactionUUID);
                }
              }}
            >
              {t('completePayment')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </GoingPaper>
  );
};

export default TransactionDelayed;
