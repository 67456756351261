import { StyleSheet } from '@react-pdf/renderer';

import colors from '@Compo/App/colors';

const styles = StyleSheet.create({
  booked: {
    backgroundColor: colors.seats.taken,
  },
  duble: {
    backgroundColor: colors.error.light,
  },
  freeDisabled: {
    backgroundColor: colors.action.disabled,
  },
  table: {
    borderColor: '#000',
    borderRadius: 16,
    borderWidth: 1,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 37,
    overflow: 'hidden',
  },
});

export default styles;
