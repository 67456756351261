import { IExampleTicket } from './Tickets.types';

export const exampleTicket: IExampleTicket[] = [
  {
    id: 1,
    isBoxOffice: false,
    languageId: 1,
    name: 'Pokaż przykładowy bilet [PL]',
    salesChannelId: 10,
  },
  {
    id: 2,
    isBoxOffice: false,
    languageId: 2,
    name: 'Pokaż przykładowy bilet [EN]',
    salesChannelId: 10,
  },
  {
    id: 3,
    isBoxOffice: false,
    languageId: 1,
    name: 'Pokaż przykładowy bilet Empik Bilety [PL]',
    salesChannelId: 11,
  },
  {
    id: 4,
    isBoxOffice: false,
    languageId: 2,
    name: 'Pokaż przykładowy bilet Empik Bilety [EN]',
    salesChannelId: 11,
  },
  {
    id: 5,
    isBoxOffice: true,
    languageId: 1,
    name: 'Pokaż przykładowy bilet kasa biletowa [PL]',
    salesChannelId: 10,
  },
  {
    id: 6,
    isBoxOffice: true,
    languageId: 1,
    name: 'Pokaż przykładowy bilet kasa biletowa Empik Bilety [PL]',
    salesChannelId: 11,
  },
];
