import React, { useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GenericTable from '@Compo/reusable/GenericTable';
import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import ReportFilterModal from '@Compo/Settings/ReportFilterModal';
import config from '@Config';
import { TypographyVariant } from '@Constants/Variants/Typography';

import UserSettings from './../UserSettings';
import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

export const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Settings.FormLayout',
  });

  const {
    deleteReportFilter,
    handleSubmit,
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    mounted,
    handleActiveReportFilter,
    isLoading,
    permissionSettlements,
    partnerFilters,
    permissionPartnerRead,
    permissionPartnerWrite,
    permissionPixelRead,
    permissionPixelWrite,
    changePageFilter,
  } = props;

  const classes = useStyles();

  useEffect(() => {
    mounted();
  }, []);

  const totalCount = partnerFilters?.meta.total || 0;
  const activePage = partnerFilters?.meta.page
    ? partnerFilters?.meta.page - 1
    : 0;
  const perPage = partnerFilters?.meta.limit || 20;

  const normalizedFilters =
    partnerFilters?.data.map((filter) => ({
      actions: (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={classes.buttonWithoutPadding}
          onClick={(e) => {
            e.stopPropagation();
            deleteReportFilter(filter.id);
          }}
        >
          {t('delete')}
        </Button>
      ),
      agent: filter.agent.name,
      happening: filter.event?.titlePL,
      id: filter.id,
      product: filter.product?.name,
      salesChannel: filter.salesChannel.name,
      type: filter.type.name,
    })) || [];

  const columns = [
    { id: 'id', label: t('id') },
    { id: 'happening', label: t('happening') },
    { id: 'product', label: t('product') },
    { id: 'salesChannel', label: t('salesChannel') },
    { id: 'agent', label: t('agent') },
    { id: 'type', label: t('type') },
    { id: 'actions', label: '' },
  ];

  const catchEditRule = (name: string) => {
    const selectedFilter = partnerFilters?.data.find((filter) => {
      return filter.id === Number(name);
    });

    if (selectedFilter) {
      handleActiveReportFilter({
        agentId: selectedFilter.agent.id,
        eventId: selectedFilter.event?.id,
        id: selectedFilter.id,
        productId: selectedFilter.product?.id,
        salesChannelId: selectedFilter.salesChannel.id,
        type: selectedFilter.type.code,
      });
    }
  };
  const catchChangePage = (event: unknown, page: number) => {
    changePageFilter(page);
  };

  const addNewFilter = () => handleActiveReportFilter({ eventId: 0 });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container={true} spacing={5}>
          {config.cms.showLanguageSwitch && (
            <Grid item={true} xs={12}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <Typography variant={TypographyVariant.h6}>
                    {t('userSettingsTitle')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                  <UserSettings />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item={true} xs={12}>
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12}>
                <Typography variant={TypographyVariant.h6}>
                  {t('analyticsTitle')}
                </Typography>
              </Grid>
              {permissionPixelRead && (
                <>
                  <Grid item={true} xs={12}>
                    <Grid container={true} spacing={2}>
                      <Grid item={true} xs={12}>
                        <Typography variant={TypographyVariant.body1}>
                          {t('pixelTitle')}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Grid container={true} spacing={2}>
                          <Grid item={true} xs={6}>
                            <LabelContentContainer>
                              <TextField
                                id="fbPixel"
                                variant="standard"
                                label={
                                  (touched.fbPixel && errors.fbPixel) ||
                                  t('fbPixel')
                                }
                                helperText={t('fbPixelHelper')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.fbPixel}
                                name="fbPixel"
                                error={!!(touched.fbPixel && errors.fbPixel)}
                                fullWidth={true}
                                disabled={!permissionPixelWrite}
                              />
                            </LabelContentContainer>
                          </Grid>
                          <Grid item={true} xs={6}>
                            <LabelContentContainer>
                              <TextField
                                id="tokenPixel"
                                label={
                                  (touched.tokenPixel && errors.tokenPixel) ||
                                  t('tokenPixel')
                                }
                                helperText={t('tokenPixelHelper')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.tokenPixel}
                                name="tokenPixel"
                                error={
                                  !!(touched.tokenPixel && errors.tokenPixel)
                                }
                                fullWidth={true}
                                disabled={!permissionPixelWrite}
                              />
                            </LabelContentContainer>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                </>
              )}
              {permissionPartnerRead && (
                <>
                  <Grid item={true} xs={12}>
                    <Grid container={true} spacing={2}>
                      <Grid item={true} xs={12}>
                        <Typography variant={TypographyVariant.body1}>
                          {t('gtm.title')}
                        </Typography>
                      </Grid>
                      <Grid item={true} xs={12}>
                        <Grid container={true} spacing={2}>
                          <Grid item={true} xs={6}>
                            <LabelContentContainer>
                              <TextField
                                id="gtmId"
                                label={
                                  (touched.gtmId && errors.gtmId) ||
                                  t('gtm.label')
                                }
                                helperText={t('gtm.helper')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.gtmId}
                                name="gtmId"
                                error={!!(touched.gtmId && errors.gtmId)}
                                fullWidth={true}
                                disabled={!permissionPartnerWrite}
                              />
                            </LabelContentContainer>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Divider className={classes.divider} />
                  </Grid>
                </>
              )}
              {(permissionPixelWrite || permissionPartnerWrite) && (
                <Grid item={true}>
                  <Button
                    variant={'outlined'}
                    size={'small'}
                    color={'primary'}
                    type={'submit'}
                  >
                    {t('submitButton')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          {permissionSettlements && (
            <Grid item={true} xs={12}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12}>
                  <Typography variant={TypographyVariant.h6}>
                    {t('filterTitle')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={12}>
                  <GenericTable>
                    <TableActions>
                      <div />
                      <div className={classes.buttonsContainer}>
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          startIcon={<AddIcon />}
                          className={cn(classes.button, classes.buttonFirst)}
                          onClick={addNewFilter}
                        >
                          {t('add')}
                        </Button>
                      </div>
                    </TableActions>
                    <TableUi
                      rows={normalizedFilters}
                      handleChangePage={catchChangePage}
                      columns={columns}
                      disableCheckBox={true}
                      onRowClick={catchEditRule}
                      total={totalCount}
                      activePage={activePage}
                      rowsPerPage={perPage}
                    />
                  </GenericTable>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
      <ReportFilterModal />
    </>
  );
};

export default FormLayout;
