import React, { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import config from '@Config';
import groupBy from '@Misc/helpers/groupBy';
import { IGroup } from '@Services/$users-api/types';

import CustomAccessModal from './../../CustomAccessModal';
import { ICustomAccessModalValues } from './../../CustomAccessModal/CustomAccessModal.types';
import useStyles from './../FormLayout.styles';
import { IFormLayoutProps } from './../FormLayout.types';

export const OTHER_PARTNER_ID = -5;
export const DEFAULT_ACCESS_ID = -1;
export const CUSTOM_ACCESS_ID = -2;

const Access = ({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
  setFieldValue,
  getAllGroups,
  getAllPartners,
  groups,
  selectedPartner,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Users.components.UsersTable.FormLayout',
  });

  const [openCustomAccess, setOpenCustomAccess] = useState(false);

  const classes = useStyles();

  const normalizedGroups: Map<number, IGroup[]> = groupBy(
    [
      ...groups,
      {
        cmsPartnerId: OTHER_PARTNER_ID,
        id: CUSTOM_ACCESS_ID,
        name: t('custom'),
        permissions: [],
      },
    ],
    (group) => group.cmsPartnerId
  );

  const handleCloseCustomAccess = () => {
    setOpenCustomAccess(false);
  };

  const addCustomAccess = (accessValues: ICustomAccessModalValues) => {
    setFieldValue('individualPermissionsIds', accessValues.permissionsIds);
  };

  const addAccessField = (partnerId: number) => {
    setFieldValue('access', {
      ...values.access,
      [partnerId]: [...values.access[partnerId], DEFAULT_ACCESS_ID],
    });
  };

  const removeAccessField = (
    index: number,
    partnerId: number,
    individual?: boolean
  ) => {
    setFieldValue('access', {
      ...values.access,
      [partnerId]: values.access[partnerId].filter((value, i) => i !== index),
    });
    if (individual) {
      setFieldValue('individualPermissionsIds', []);
    }
  };

  useEffect(() => {
    const cmsPartners = values.cmsPartners.map((partner) => Number(partner.id));
    getAllGroups(
      config.cms.superPartnerId === Number(selectedPartner)
        ? [...cmsPartners, config.cms.superPartnerId]
        : cmsPartners
    );
  }, [values.cmsPartners]);

  useEffect(() => {
    getAllPartners();
  }, [groups, values.access]);

  return (
    <>
      <CustomAccessModal
        open={openCustomAccess}
        handleClose={handleCloseCustomAccess}
        submit={addCustomAccess}
        permissionsIds={values.individualPermissionsIds}
      />
      {values.cmsPartners.map((partner, key) => (
        <>
          <Grid item={true} xs={12} key={key}>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <Typography key={partner.id}>{partner.name}</Typography>
              </Grid>
              {values.access?.[partner.id]?.map((_, index) => (
                <Grid item={true} xs={12} key={index}>
                  <Grid container={true} spacing={1} alignItems="center">
                    <Grid item={true} xs={true}>
                      <LabelContentContainer>
                        <Grid
                          container={true}
                          spacing={1}
                          alignItems={'center'}
                        >
                          <Grid
                            item={true}
                            xs={12}
                            sm={
                              values.access?.[partner.id]?.[index] ===
                              CUSTOM_ACCESS_ID
                                ? 9
                                : 12
                            }
                          >
                            <FormControl
                              variant="standard"
                              fullWidth={true}
                              error={
                                !!(
                                  touched.access?.[partner.id]?.[index] &&
                                  errors.access?.[partner.id]?.[index]
                                )
                              }
                            >
                              <InputLabel>
                                {(touched.access?.[partner.id]?.[index] &&
                                  errors.access?.[partner.id]?.[index]) ||
                                  t('access')}
                              </InputLabel>
                              <Select
                                variant="standard"
                                value={
                                  values.access[partner.id][index] ||
                                  DEFAULT_ACCESS_ID
                                }
                                onChange={handleChange}
                                defaultValue={DEFAULT_ACCESS_ID}
                                fullWidth={true}
                                name={`access[${partner.id}][${index}]`}
                                onBlur={handleBlur}
                                disabled={
                                  partner.id === String(OTHER_PARTNER_ID) &&
                                  values.access?.[partner.id]?.[index] !==
                                    CUSTOM_ACCESS_ID &&
                                  values.access?.[partner.id]?.[index] !==
                                    DEFAULT_ACCESS_ID
                                }
                              >
                                <MenuItem value={DEFAULT_ACCESS_ID}>-</MenuItem>

                                {Number(partner.id) !== 5 &&
                                  normalizedGroups
                                    .get(Number(partner.id))
                                    ?.map((group) => (
                                      <MenuItem
                                        disabled={values.access[
                                          partner.id
                                        ]?.includes(group.id)}
                                        key={group.id}
                                        value={group.id}
                                      >
                                        {group.name}
                                      </MenuItem>
                                    ))}

                                {normalizedGroups
                                  .get(config.cms.superPartnerId)
                                  ?.map((group) => (
                                    <MenuItem
                                      disabled={values.access[
                                        partner.id
                                      ]?.includes(group.id)}
                                      key={group.id}
                                      value={group.id}
                                    >
                                      {group.cmsPartnerId === 5
                                        ? `${t('template')} ${group.name}`
                                        : group.name}
                                    </MenuItem>
                                  ))}
                                {partner.id === String(OTHER_PARTNER_ID) &&
                                  values.groups[index] && (
                                    <MenuItem
                                      key={values.groups[index].id}
                                      value={values.groups[index].id}
                                    >
                                      {values.groups[index].name}
                                    </MenuItem>
                                  )}
                              </Select>
                              <FormHelperText>
                                {t('accessHelper')}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          {values.access?.[partner.id]?.[index] ===
                            CUSTOM_ACCESS_ID && (
                            <Grid item={true} xs={12} sm={3}>
                              <Button
                                variant="outlined"
                                size="small"
                                color="secondary"
                                fullWidth={true}
                                className={classes.button}
                                onClick={() => setOpenCustomAccess(true)}
                              >
                                {t('addCustomAccess')}
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                      </LabelContentContainer>
                    </Grid>
                    {values.access?.[partner.id]?.length > 1 &&
                      (partner.id !== String(OTHER_PARTNER_ID) ||
                        values.access?.[partner.id]?.[index] ===
                          CUSTOM_ACCESS_ID ||
                        values.access?.[partner.id]?.[index] ===
                          DEFAULT_ACCESS_ID) && (
                        <Grid item={true}>
                          <IconButton
                            color="secondary"
                            size="small"
                            onClick={() =>
                              removeAccessField(
                                index,
                                Number(partner.id),
                                values.access?.[partner.id]?.[index] ===
                                  CUSTOM_ACCESS_ID
                              )
                            }
                          >
                            <CloseIcon fontSize="medium" />
                          </IconButton>
                        </Grid>
                      )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {values.access[partner.id][
            values.access?.[partner.id]?.length - 1
          ] !== DEFAULT_ACCESS_ID &&
            values.access[partner.id]?.length && (
              <Grid item={true} xs={12}>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  fullWidth={true}
                  startIcon={<AddIcon />}
                  className={classes.button}
                  onClick={() => addAccessField(Number(partner.id))}
                >
                  {t('addAccess')}
                </Button>
              </Grid>
            )}
        </>
      ))}
    </>
  );
};

export default Access;
