import React from 'react';

import { Formik } from 'formik';

import FormLayout from './../FormLayout';
import {
  IImportListFormProps,
  IImportListValues,
} from './ImportListForm.types';
import { ImportListSchema } from './ImportListForm.validation';

const ImportListForm = ({ check, closeModal }: IImportListFormProps) => {
  const initialValues: IImportListValues = {
    entryTokens: '',
    name: '',
  };

  if (initialValues) {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={ImportListSchema}
        onSubmit={check}
        component={(childProps) => (
          <FormLayout {...childProps} closeModal={closeModal} />
        )}
      />
    );
  }
  return null;
};

export default ImportListForm;
