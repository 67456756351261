import { combineEpics } from 'redux-observable';

// Import modules
import * as App from '@Model/app/epics';
import * as Authorization from '@Model/authorization/epics';
import * as Configurations from '@Model/configurations/epics';
import * as Discount from '@Model/discount/epics';
import * as Discounts from '@Model/discounts/epics';
import * as Events from '@Model/events/epics';
import * as FormIo from '@Model/formio/epics';
import * as GoingCloudinary from '@Model/going/cloudinary/epics';
import * as Places from '@Model/going/places/epics';
import * as ReportSeatsIo from '@Model/going/reportSeatsIo/epics/index';
import * as GoingRundates from '@Model/going/rundates/epics';
import * as Happening from '@Model/happening/epics';
import * as Happenings from '@Model/happenings/epics';
import * as Iframe from '@Model/iframe/epics';
import * as Mailing from '@Model/mailing/epics';
import * as Metadata from '@Model/metadata/epics';
import * as PoolDescriptions from '@Model/poolDescriptions/epics';
import * as Price from '@Model/price/epics';
import * as PriceTypes from '@Model/priceTypes/epics';
import * as Printer from '@Model/printer/epics';
import * as PrinterBoca from '@Model/printerBoca/epics';
import * as Products from '@Model/products/epics';
import * as RefundsRegistration from '@Model/refundsRegistration/epics';
import * as Reports from '@Model/reports/epics';
import * as Reservation from '@Model/reservation/epics';
import * as Reservations from '@Model/reservations/epics';
import * as SeatsIo from '@Model/seatsIo/epics';
import * as Settings from '@Model/settings/epics';
import * as Settlements from '@Model/settlements/epics';
import * as Smartporter from '@Model/smartporter/epics';
import * as State from '@Model/state/epics';
import * as Statics from '@Model/statics/epics';
import * as Tickets from '@Model/tickets/epics';
import * as Transactions from '@Model/transactions/epics';
import * as User from '@Model/user/epics';
import * as Users from '@Model/users/epics';

export default combineEpics(
  // Authorization
  Authorization.checkTokenWhenLocationChanged,
  Authorization.removeAuthorizationHeaderWhenRequest,
  Authorization.getTokenWhenRequest,
  Authorization.setAuthorizationHeaderWhenRequest,
  Authorization.setStartStateWhenAppMounted,
  Authorization.setTokenAndRedirectWhenGetTokenSuccess,
  Authorization.encodeJwtTokenAndSetUserStateWhenRequest,
  Authorization.handleUsersPermissionsWhenRequest,
  Authorization.fetchAuthorisationCasherInfoWhenRequested,
  Authorization.checkSessionTokenInWhenRequest,
  Authorization.showRefreshMessageWhenSessionOf,
  Authorization.runSessionWhenRequest,
  Authorization.clearSessionWhenRequest,
  Authorization.getSessionWhenRequest,
  Authorization.updatePaymentSessionWhenRequest,
  Authorization.clearSessionByPrinterIdWhenRequest,
  Authorization.signInWhenRequest,
  Authorization.setTokenWhenSigInSuccess,
  Authorization.redirectToIndexWhenSigInSuccess,
  Authorization.logoutWhenRequest,
  Authorization.getTokenRefreshWhenRequest,
  Authorization.setTokenWhenRefreshTokenSuccess,
  Authorization.setPartnerParamsWhenSignInSuccess,
  Authorization.fetchSessionByPrinterIdWhenRequest,
  Authorization.signInWithFirebaseWhenRequest,
  Authorization.encodeJwtTokenAndSetPartnerIdWhenRequested,

  // App
  App.setDeviceWhenSizesChangedEpic,

  // Configurations
  Configurations.requestConfigurationsWhenMounted,
  Configurations.fetchConfigurationsWhenRequested,
  Configurations.saveNewConfigurationWhenAction,
  Configurations.saveNewConfigurationWhenActionV2,
  Configurations.requestToGetConfigurationsWhenAction,
  Configurations.requestToDeleteConfigurationsWhenAction,

  // Discounts
  Discounts.fetchDiscountsWhenRequested,
  Discounts.requestDiscountsWhenMounted,
  Discounts.getDiscountsWhenSetFilter,
  Discounts.requestEventsDiscountsWhenMounted,
  Discounts.fetchEventsDiscountsWhenRequested,
  Discounts.fetchDiscountsWhenPageChanged,
  Discounts.getDiscountsWhenSearchEventTextChanged,

  // Discount
  Discount.requestSaveDiscountOnSaveAction,
  Discount.saveDiscountWhenRequested,
  Discount.removeDiscountWhenRequested,
  Discount.requestCheckDiscount,
  Discount.checkDiscountCodeWhenRequest,
  Discount.requestCatchDependencyValuesAction,
  Discount.fetchPartnersWhenRequested,
  Discount.fetchEventsPartnerWhenRequested,
  Discount.fetchRundatesPartnerWhenRequested,
  Discount.fetchPoolsPartnerWhenRequested,
  Discount.saveNewDiscountWhenRequested,
  Discount.fetchDependencyValuesWhenRequested,
  Discount.fetchSingleEventDiscountWhenRequested,
  Discount.editSingleEventDiscountWhenRequested,
  Discount.fetchPriceTypesWhenAddDiscountCodeMounted,

  // FormIo
  FormIo.catchUsersFromFormIoWhenGetUserSuccess,
  FormIo.getEntryFormWhenGotToken,
  FormIo.getFormAvailableUsers,
  FormIo.getFormAvailableUsersByEmail,
  FormIo.getFormAvailableUsersWhenPageChange,
  FormIo.resetStateWhenMounted,
  FormIo.resetStateWhenSelectReservation,
  FormIo.loginFormIoWhenSelectReservation,
  FormIo.getEnterFormUserTokensWhenRequest,
  FormIo.getEnterFormUserTokens,
  FormIo.addEntryCodeWhenRequest,
  FormIo.saveUserWhenEntryCodeAdded,
  FormIo.saveUsersReservationWhenRequest,
  FormIo.catchSaveUserWhenRequest,
  FormIo.catchUsersFromFormioBackend,
  FormIo.saveUsersAttachWhenRequest,
  FormIo.catchUserFromFormioBackend,
  FormIo.checkUserExistWhenRequest,
  FormIo.getOldDataUserWhenRequest,
  FormIo.detachUserSubmissionWhenRequest,
  FormIo.attachEntryGroupWhenRequest,
  FormIo.getSingleUserWhenRequested,
  FormIo.updateSingleUserWhenRequested,

  // Happening
  Happening.addHappeningWhenRequested,
  Happening.editHappeningWhenRequested,
  Happening.fetchHappeningWhenRequested,
  Happening.requestHappeningWhenEditFormMounted,
  Happening.requestHappeningWhenRuleHasBeenEdited,
  Happening.resetStateWhenEditFormUnmount,
  Happening.fetchAvailabilitiesWhenRequested,
  Happening.requestAvailabilitiesWhenDateSelectionChanges,

  // Happenings
  Happenings.fetchHappeningsWhenRequested,
  Happenings.removeHappeningWhenRequested,
  Happenings.requestHappeningsWhenListMounted,

  // Iframe
  Iframe.runResizeMethodWhenSetDevice,
  Iframe.refreshPageWhenRequest,
  Iframe.setIframeSessionWhenRequest,
  Iframe.runRedirectBuyingContinue,

  // Price
  Price.requestCheckPrice,
  Price.checkPriceWhenRequest,

  // Printer
  Printer.requestSavePrinterOnSaveAction,
  Printer.savePrinterWhenRequested,
  Printer.endSessionWithOutCashState,
  Printer.endSessionByIdWithoutCasStateWhenRequest,
  Printer.requestFetchPrintersOnMountedAction,
  Printer.removePrinterWhenRequested,
  Printer.fetchPrinterWhenRequested,
  Printer.printReceiptWhenRequest,
  Printer.startSessionWhenRequest,
  Printer.catchStartSession,
  Printer.catchEndSessionWhenRequest,
  Printer.catchPrintReceiptWhenRequest,
  Printer.endSessionWhenRequest,
  Printer.endSessionByIdWhenRequest,
  Printer.printReportPrinterWhenRequest,
  Printer.clearPrinterWhenRequest,
  Printer.pullDrawerWhenRequest,
  Printer.printerPaymentWhenRequest,
  Printer.startPingingPrinterWhenRequest,
  Printer.fetchPrinterStatusWhenRequest,
  Printer.fetchPrinterPingingWhenRequested,
  Printer.printTemplateWhenRequest,
  Printer.catchStartSessionV2,
  Printer.startSessionV2WhenRequest,
  Printer.endSessionV2WhenRequest,
  Printer.endSessionByPrinterIdV2WhenRequest,

  // PrinterBoca
  PrinterBoca.printBocaTicketWhenRequested,
  PrinterBoca.resetIsTicketPrintedWhenLocationChanged,

  // Reservation
  Reservation.requestForHappeningsWhenMounted,
  Reservation.requestForHappeningWhenSelected,
  Reservation.handleSettingSlotWhenRequested,
  Reservation.requestForPostReservationWhenSave,
  Reservation.postReservationWhenRequested,
  Reservation.postEmptyReservationWhenRequested,
  Reservation.caputeTransactionDetailsWhenSummaryMounted,
  Reservation.captureTransactionEmpikDetailsWhenRequest,
  Reservation.cancelTransactionWhenPayload,
  Reservation.cancelAutoTransactionWhenPayload,
  Reservation.saveBillWhenRequest,
  Reservation.getCompanyDataWhenRequest,
  Reservation.catchCompanyDataWhenRequest,
  Reservation.getReceiptPrintDataWhenRequest,
  Reservation.getReceiptWhenRequestWhentPrindDataSuccess,
  Reservation.whenGetHappeningCheckTimeIsBetweenMidnightAndTime,
  Reservation.capturePrintersList,
  Reservation.startPingingWhenPrintersFetch,
  Reservation.deselectTimeSlotWhenPeopleCountChange,
  Reservation.runRedirectCompleteAdvancePayment,
  Reservation.postAdvancePaymentReservationWhenRequested,
  Reservation.fetchTransactionEmpikDetailsWhenSelectAdvanceTransaction,
  Reservation.getDependencyTicketWhenRequested,
  Reservation.postReservationWithDependencyUserWhenRequested,

  // Reservations
  Reservations.requestHappeningsWhenCalendarMounted,
  Reservations.requestReservationsWhenHappeningsFetched,
  Reservations.fetchReservationsWhenRequested,
  Reservations.setFiltersWhenFilterSwitched,
  Reservations.unselectAllWhenRequest,
  Reservations.selectAllWhenRequest,
  Reservations.requestReservationsWhenReloadRequested,
  Reservations.requestReservationDetailsWhenSelected,
  Reservations.fetchReservationDetailsWhenRequested,
  Reservations.setActiveHappeningWhenDetailsSuccess,
  Reservations.getAvailableWhenChangeDate,
  Reservations.requestCheckWhenDateCheckRequested,
  Reservations.fetchDataCheckWhenRequested,
  Reservations.resetCheckWhenResetSelection,
  Reservations.requestUpdateReservationWhenSaveRequested,
  Reservations.updateReservationWhenRequested,
  Reservations.requestFormEmpikReservationDetailsReuestWhenSelectedReservation,
  Reservations.getEmpikReservationDetailsWhenRequest,
  Reservations.resendTicketWhenRequestWhenRequest,
  Reservations.getTicketWhenRequestWhenRequest,
  Reservations.whenDelayedIsMountedCatch,
  Reservations.getTransactionDetailsWhenRequest,
  Reservations.catchCancelTransactionWhenRequest,
  Reservations.cancelTransactionWhenRequest,
  Reservations.displayMessageWhenRequest,
  Reservations.fetchGroupedReservationsWhenRequested,
  Reservations.selectReservationWhenOnlyOne,

  // State
  State.setErrorOnDiscountsFailure,
  State.setErrorOnAvailabilitiesFailure,
  State.setErrorOnConfigurationsFailure,
  State.setErrorOnHappeningsFailure,
  State.setErrorOnReservationCheckFailure,
  State.setErrorOnReservationFailure,
  State.setErrorOnReservationsFailure,
  State.setErrorOnSingleDiscountFailure,
  State.setErrorOnSingleHappeningFailure,
  State.setErrorOnFormIoUsersFailure,
  State.setLoaderWhenDiscountsFetchFinished,
  State.setLoaderWhenFormIoUsersFetchFinished,
  State.setLoaderWhenAvailabilitiesFetchFinished,
  State.setLoaderWhenConfigurationsFetchFinished,
  State.setLoaderWhenHappeningsFetchFinished,
  State.setLoaderWhenReservationCheckFetchFinished,
  State.setLoaderWhenReservationFetchFinished,
  State.setLoaderWhenReservationsFetchFinished,
  State.setLoaderWhenSingleDiscountFetchFinished,
  State.setLoaderWhenSingleHappeningFetchFinished,
  State.setUpStateWhenFormIoUsersFetchRequested,
  State.setUpStateWhenDiscountsFetchRequested,
  State.setUpStateWhenAvailabilitiesFetchRequested,
  State.setUpStateWhenConfigurationsFetchRequested,
  State.setUpStateWhenHappeningsFetchRequested,
  State.setUpStateWhenReservationCheckFetchRequested,
  State.setUpStateWhenReservationFetchRequested,
  State.setUpStateWhenReservationsFetchRequested,
  State.setUpStateWhenSingleDiscountFetchRequested,
  State.setUpStateWhenSingleHappeningFetchRequested,
  State.setUpStateStartWhenStartMounted,
  State.setLoaderStartAppSetFinished,
  State.setUpStateWhenPrintingRequested,
  State.setUpStateWhenPrintingFinished,
  State.setLoaderWhenCompanyDataFinished,
  State.setUpStateWhenCompanyDataRequested,
  State.setUpStateWhenGetReportsFetchRequested,
  State.setLoaderWhenGetReportsFetchFinished,
  State.setUpStateWhenTicketsFetchRequested,
  State.setLoaderWhenTicketsFetchFinished,
  State.setErrorOnTicketsFailure,
  State.setUpStateWhenReservationPostFetchRequested,
  State.setLoaderWhenReservationPostFetchFinished,
  State.setUpStateStartWhenSummaryMounted,
  State.setLoaderSummarySetFinished,
  State.setUpStateWhenProductsFetchRequested,
  State.setLoaderWhenProductsFetchFinished,
  State.setUpStateWhenTransactionDetailsFetchRequested,
  State.setLoaderWhenTransactionDetailsFetchFinished,
  State.setErrorOnTransactionDetailsFailure,
  State.setUpStateWhenDelayedTransactionsFetchRequested,
  State.setLoaderWhenDelayedTransactionsFetchFinished,
  State.setErrorOnDelayedTransactionsFailure,
  State.setUpStateWhenReservationsModalFetchRequested,
  State.setLoaderWhenReservationsModalFetchFinished,
  State.setUpStateWhenPriceFetchRequested,
  State.setLoaderWhenPriceFetchFinished,
  State.setUpStateWhenPriceTypesFetchRequested,
  State.setLoaderWhenPriceTypesFetchFinished,
  State.setUpStateWhenEventsPoolsFetchRequested,
  State.setLoaderWhenEventsPoolsFetchFinished,
  State.setErrorOnEventsPoolsFailure,
  State.setUpStateWhenEventsFetchRequested,
  State.setLoaderWhenEventsFetchFinished,
  State.setErrorOnEventsFailure,
  State.setUpStateWhenSettingsFetchRequested,
  State.setLoaderWhenSettingsFetchFinished,
  State.setErrorOnSettingsFailure,
  State.setUpStateWhenSettlementsFetchRequested,
  State.setLoaderWhenSettlementIsCreated,
  State.setLoaderWhenSettlementIsFinished,
  State.setLoaderWhenSettlementsFetchFinished,
  State.setErrorOnSettlementsFailure,
  State.setUpStateWhenSettlementsRundatesFetchRequested,
  State.setLoaderWhenSettlementsRundatesFetchFinished,
  State.setErrorOnSettlementsRundatesFailure,
  State.setUpStateWhenSettlementsGenerateFetchRequested,
  State.setLoaderWhenSettlementsGenerateFetchFinished,
  State.setErrorOnSettlementsGenerateFailure,
  State.setUpStateWhenGoingRundatesFetchRequested,
  State.setLoaderWhenGoingRundatesFetchFinished,
  State.setErrorOnGoingRundatesFailure,
  State.setUpStateWhenDependenciesFetchRequested,
  State.setLoaderWhenDependenciesFetchFinished,
  State.setErrorOnDependenciesFailure,
  State.setUpStateWhenMailingFetchRequested,
  State.setLoaderWhenMailingFetchFinished,
  State.setErrorOnMailingFailure,
  State.setUpStateWhenGoingTicketsFetchRequested,
  State.setLoaderWhenGoingTicketsFetchFinished,
  State.setErrorOnGoingTicketsFailure,
  State.setUpStateWhenGoingTicketListTicketsFetchRequested,
  State.setLoaderWhenGoingTicketListTicketsFetchFinished,
  State.setErrorOnGoingTicketListTicketsFailure,
  State.setUpStateWhenEventsPlacesFetchRequested,
  State.setLoaderWhenEventsPlacesFetchFinished,
  State.setErrorOnEventsPlacesFailure,
  State.setUpStateWhenGoingPoolsFetchRequested,
  State.setLoaderWhenGoingPoolsFetchFinished,
  State.setErrorOnGoingPoolsFailure,
  State.setUpStateWhenSeatsIoReportFetchRequested,
  State.setLoaderWhenSeatsIoReportFetchFinished,
  State.setErrorOnSeatsIoReportFailure,
  State.setUpStateWhenPoolDescriptionsFetchRequested,
  State.setLoaderWhenPoolDescriptionsFetchFinished,
  State.setErrorOnPoolDescriptionsFailure,
  State.setUpStateWhenEventsUsersFetchRequested,
  State.setLoaderWhenEventsUsersFetchFinished,
  State.setErrorOnEventsUsersFailure,
  State.setUpStateWhenSeatsIoChartsFetchRequested,
  State.setLoaderWhenSeatsIoChartsFetchFinished,
  State.setErrorOnSeatsIoChartsFailure,
  State.setUpStateWhenPermissionsFetchRequested,
  State.setLoaderWhenPermissionsFetchFinished,
  State.setErrorOnPermissionsFailure,
  State.setUpStateWhenRefundsRegistrationsFetchRequested,
  State.setLoaderWhenRefundsRegistrationsFetchFinished,
  State.setErrorOnRefundsRegistrationsFailure,
  State.setUpStateWhenEmpikDetailsFetchRequested,
  State.setLoaderWhenEmpikDetailsFetchFinished,
  State.setErrorOnEmpikDetailsFailure,
  State.setUpStateWhenTransactionsFetchRequested,
  State.setLoaderWhenTransactionsFetchFinished,
  State.setErrorOnTransactionsFailure,

  // Smartporter
  Smartporter.refreshListWhenRequested,
  Smartporter.requestGetApartmentsWhenMounted,
  Smartporter.fetchApartmentsWhenRequest,
  // User
  User.requestSaveUserOnSaveAction,
  User.saveUserWhenRequested,
  User.requestFetchUsersOnMountedAction,
  User.fetchUsersWhenRequested,
  User.removeUserWhenRequested,
  User.requestAdminUsersOnMountedAction,
  User.fetchAdminUsersWhenRequested,
  User.fetchUserWhenRequested,

  // Products
  Products.getProductsWhenRequest,
  Products.selectProductWhenRequest,
  Products.unSelectProductWhenRequest,
  Products.addProductWhenRequest,
  Products.setProductCountWhenRequest,
  Products.resetDiscountValueWhenUpdateList,
  Products.getProductsDataWhenViewChanged,
  Products.whenProductsMountedGetAllLocalProducts,
  Products.getAllLocalProductsWhenRequest,
  Products.getAllCategoriesWhenRequest,
  Products.getAllShipmentFeesWhenRequest,
  Products.getProductsWhenPageChanged,
  Products.getAllZohoProductsWhenRequest,
  Products.saveCategoryWhenRequest,
  Products.getAllCategoriesWhenHandleActiveLocalProduct,
  Products.getAllInvoiceProductsWhenRequest,
  Products.saveProductWhenRequest,
  Products.removeProductWhenRequest,
  Products.removeCategoryWhenRequest,
  Products.whenProductsListMountedGetAllProductsWithCategories,
  Products.whenCategoriesFetchedGetAllProducts,
  Products.whenProductsListMounted,
  Products.fetchSingleProductWhenRequested,
  Products.getSingleProductWhenRequest,
  Products.setProductStorageWhenRequest,

  // Reports
  Reports.captureReportWhenRequest,
  Reports.getReportWhenRequest,
  Reports.setSelectedKeysWhenAction,
  Reports.addNewValueToGroupWhenRequest,

  // Statics
  Statics.requestStaticsUrlWhenMounted,
  Statics.fetchStaticsWhenRequested,

  // PriceTypes
  PriceTypes.catchGetProductsWhenRequest,
  PriceTypes.getProductsWhenRequest,
  PriceTypes.catchSavePriceTypeWhenRequest,
  PriceTypes.savePriceTypeWhenRequest,
  PriceTypes.deletePriceTypeWhenRequest,
  PriceTypes.catchGetRuleTypeWhenRequest,

  // Tickets
  Tickets.requestTicketsWhenMounted,
  Tickets.fetchTicketsWhenRequested,
  Tickets.requestTicketsWhenSearch,
  Tickets.requestTicketsStatusWhenSearch,
  Tickets.fetchTicketStatusWhenRequested,
  Tickets.fetchTicketStatusDeletionWhenRequested,

  // Going Cloudinary
  GoingCloudinary.deleteAssetsWhenRequested,

  // GoingRundates
  GoingRundates.fetchRundatesWhenMounted,
  GoingRundates.fetchRundatesWhenPayloadChanged,
  GoingRundates.requestRundatesWhenRequested,
  GoingRundates.requestRundateWhenEditFormMounted,
  GoingRundates.editRundateWhenRequested,
  GoingRundates.fetchPoolsWhenRequested,
  GoingRundates.deleteRundatePoolWhenRequested,
  GoingRundates.getSinglePoolWhenRequested,
  GoingRundates.fetchSinglePoolWhenRequested,
  GoingRundates.savePoolWhenRequested,
  GoingRundates.saveRundatePoolWhenRequested,
  GoingRundates.fetchPoolDescriptionsWhenRequested,
  GoingRundates.getPoolDescriptionsWhenRequested,
  GoingRundates.generateExampleTicketWhenAction,
  GoingRundates.fetchTicketsWhenMounted,
  GoingRundates.getTicketsListWhenRequested,
  GoingRundates.getSingleTicketWhenRequested,
  GoingRundates.saveTicketsListWhenRequested,
  GoingRundates.saveTicketWhenRequested,
  GoingRundates.getTicketListTicketsWhenRequested,
  GoingRundates.getTicketWhenRequested,
  GoingRundates.deleteTicketWhenRequested,
  GoingRundates.revertTicketWhenRequested,
  GoingRundates.reorderPoolsWhenAction,
  GoingRundates.updateTicketWhenRequested,
  GoingRundates.changeRundateSaleWhenRequested,
  GoingRundates.changeRundateVisibilityWhenRequested,
  GoingRundates.changeRundateHasExternalImageWhenRequested,
  GoingRundates.getRundateReportWhenRequested,
  GoingRundates.deleteMultipleTicketsWhenRequested,
  GoingRundates.saveRundateWhenRequested,
  GoingRundates.fetchSingleEventWhenRequested,
  GoingRundates.getRundateStatsWhenRequested,
  GoingRundates.changeRundateMonitorTypeWhenRequested,
  GoingRundates.fetchMonitorTypesWhenRequested,
  GoingRundates.resetActiveRundateWhenLocationChange,
  GoingRundates.submitCheckExternalTicketLists,
  GoingRundates.checkExternalTicketListsWhenRequested,
  GoingRundates.submitSaveExternalTicketLists,
  GoingRundates.saveExternalTicketListsWhenRequested,

  // Events
  Events.fetchPoolsWhenRequest,
  Events.getPoolsWhenSelectEvent,
  Events.updateSelectedSeatsWhenSeatDeselected,
  Events.updateSelectedSeatsWhenSeatSelected,
  Events.getEventsWhenMounted,
  Events.fetchEventsWhenRequest,
  Events.fetchEventsWhenSearchTextChanged,

  // Settings
  Settings.requestPartnerReportFiltersWhenMounted,
  Settings.fetchPartnerReportFiltersWhenRequested,
  Settings.saveNewReportFilterWhenAction,
  Settings.fetchPixelWhenRequested,
  Settings.savePixelWhenAction,
  Settings.fetchPartnerReportFiltersParametersWhenRequested,
  Settings.deleteReportFilterWhenAction,
  Settings.fetchPartnerReportFiltersWhenPageChanged,
  Settings.fetchPartnerWhenRequested,
  Settings.savePartnerWhenAction,

  // Settlements
  Settlements.requestSettlementsWhenMounted,
  Settlements.saveSettlementWhenAction,
  Settlements.editSettlementWhenAction,
  Settlements.fetchSettlementsWhenRequested,
  Settlements.fetchSettlementsWhenPageChanged,
  Settlements.fetchSingleSettlementWhenRequested,
  Settlements.deleteSettlementWhenAction,
  Settlements.updateSettlementStatusWhenAction,
  Settlements.generateSettlementReportWhenAction,
  Settlements.generateSettlementGroupReportWhenAction,
  Settlements.generateSettlementReportFileWhenAction,
  Settlements.fetchSettlementMetadataWhenRequested,
  Settlements.fetchSettlementRundatesWhenRequested,
  Settlements.downloadSettlementReportAfterGenerated,
  Settlements.changeFiltersSettlementsWhenRequested,

  // Mailing
  Mailing.fetchEventMailingWhenRequested,
  Mailing.getEventMailingWhenMounted,
  Mailing.saveMailingWhenRequested,
  Mailing.fetchSingleMailingWhenRequested,
  Mailing.deleteMailingWhenRequested,
  Mailing.sendMailingWhenRequested,
  Mailing.getEventMailingWhenPageChange,
  Mailing.changeRouteWhenSend,
  Mailing.sendTestMailingWhenRequested,

  // Places
  Places.fetchPlacesWhenMounted,
  Places.getPlacesWhenRequested,
  Places.resetSearchWhenLocationChanged,

  // ReportSeatsIo
  ReportSeatsIo.fetchSeatsIoReportWhenMounted,
  ReportSeatsIo.getSeatsIoReportWhenRequested,
  ReportSeatsIo.generateSeatsIoReportWhenRequested,

  // Users
  Users.requestUsersWhenMounted,
  Users.requestGroupsWhenMounted,
  Users.fetchUsersWhenRequested,
  Users.fetchGroupsWhenRequested,
  Users.fetchPartnersWhenRequested,
  Users.requestPermissionsWhenMounted,
  Users.fetchPermissionsWhenRequested,
  Users.catchSaveGroupWhenRequested,
  Users.saveGroupWhenRequest,
  Users.getAllGroupsWhenRequested,
  Users.getAllPartnersWhenRequested,
  Users.saveUserWhenRequest,
  Users.catchSaveUserWhenRequested,
  Users.catchDeleteGroupWhenRequested,
  Users.deleteGroupWhenRequest,
  Users.catchGetSingleGroupWhenRequested,
  Users.getSingleGroupWhenRequest,
  Users.catchChangeUserStatusWhenRequested,
  Users.changeUserStatusWhenRequest,
  Users.catchGetSingleUserWhenRequested,
  Users.getSingleUserWhenRequest,
  Users.catchChangeUserPasswordWhenRequested,
  Users.changeUserPasswordWhenRequest,
  Users.showConfirmationModalWhenSaveUserFailure,

  // PoolDescriptions
  PoolDescriptions.catchGetPoolDescriptionsWhenMounted,
  PoolDescriptions.getPoolDescriptionsWhenRequest,
  PoolDescriptions.savePoolDescriptionsWhenRequest,
  PoolDescriptions.catchSavePoolDescriptionsWhenRequested,
  PoolDescriptions.catchGetSinglePoolDescriptionWhenAction,
  PoolDescriptions.getSinglePoolDescriptionWhenRequest,

  // Metadata
  Metadata.requestCatchMetadataValuesAction,
  Metadata.fetchMetadataValuesWhenRequested,
  Metadata.fetchMetadataPartnersWhenRequested,
  Metadata.fetchMetadataEventsWhenRequested,
  Metadata.fetchMetadataRundatesWhenRequested,
  Metadata.fetchMetadataPoolsWhenRequested,

  // SeatsIo
  SeatsIo.fetchSeatsChartsWhenMounted,
  SeatsIo.getSeatsChartsWhenRequest,
  SeatsIo.catchSaveSeatChartWhenRequested,
  SeatsIo.saveSeatChartWhenRequest,
  SeatsIo.catchDeleteSeatChartWhenRequested,
  SeatsIo.deleteSeatChartWhenRequest,
  SeatsIo.catchFetchSeatCategoriesWhenRequested,
  SeatsIo.getSeatCategoriesWhenRequest,

  // RefundsRegistration
  RefundsRegistration.requestRefundsRegistrationsWhenMounted,
  RefundsRegistration.fetchRefundsRegistrationsWhenRequested,
  RefundsRegistration.fetchRefundsRegistrationsWhenPageChanged,
  RefundsRegistration.fetchRefundsRegistrationsMetadataWhenRequested,
  RefundsRegistration.changeRefundsRegistrationsFiltersWhenRequested,
  RefundsRegistration.catchGetSingleRefundsRegistrationWhenRequested,
  RefundsRegistration.getSingleRefundsRegistrationWhenRequest,
  RefundsRegistration.catchEditRefundsRegistrationWhenRequested,
  RefundsRegistration.editRefundRegistrationWhenRequest,
  RefundsRegistration.catchEditMultipleRefundsRegistrationStatusesWhenRequested,
  RefundsRegistration.editMultipleRefundRegistrationStatusesWhenRequest,

  // Transactions
  Transactions.fetchTransactionsWhenRequested,
  Transactions.requestTransactionsWhenMounted
);
/* @@STORE_COMPONENT@@ */
