import { mounted } from '@Model/authorization/actions';
import { captureIframeEvent } from '@Model/iframe/actions';
import { IEvent } from '@Model/iframe/types';
import { AUTHORIZATION } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import Start from './Start.component';
import { IStartFromDispatch, IStartFromState } from './Start.types';

const mapStateToProps = (state: _Store.IState): IStartFromState => ({
  isLoading: getLoading(AUTHORIZATION)(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IStartFromDispatch => ({
  captureIframeEvent: (event: IEvent) => dispatch(captureIframeEvent(event)),
  mounted: () => dispatch(mounted()),
});

export default connect<IStartFromState, IStartFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps,
)(Start);
