import React from 'react';

import { Button, Grid } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { IExtendedSlot } from '@Model/reservation/types';

import useStyles from './ExtendedSlots.styles';
import { IExtendedSlotsProps } from './ExtendedSlots.types';

const ExtendedSlots = ({
  extendedSlots,
  selectExtendedSlot,
  selectedExtendedSlot,
}: IExtendedSlotsProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Add.components.FormLayout.ExtendedSlots',
  });

  const classes = useStyles();

  const selectSlot = (slot: IExtendedSlot) => {
    if (slot.duration === selectedExtendedSlot?.duration) {
      selectExtendedSlot(null);
    } else {
      selectExtendedSlot(slot);
    }
  };

  return (
    <Grid container={true} spacing={1}>
      {extendedSlots.map((slot) => (
        <Grid key={slot.duration} item={true} xs={12} md={3}>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            fullWidth={true}
            onClick={() => selectSlot(slot)}
            className={cn(
              selectedExtendedSlot?.duration === slot.duration &&
                classes.activeButton
            )}
          >
            {`+${slot.duration} ${t('min')}`}
            {slot.upsellValue && (
              <span className={classes.value}>(-{slot.upsellValue}zł)</span>
            )}
            {slot.upsellPercentageValue && (
              <span className={classes.value}>
                (-{slot.upsellPercentageValue}%)
              </span>
            )}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default ExtendedSlots;
