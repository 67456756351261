import { createSelector } from 'reselect';

import _Store from '@Store';
import { ICategory, IProductsReducer } from '../types';
import get from './get';

const getActiveCategory = createSelector<
  _Store.IState,
  IProductsReducer,
  ICategory | null
>([get], (products) => products.activeCategory);

export default getActiveCategory;
