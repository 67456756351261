import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  clearFiltersRequest,
  mounted,
  reload,
  selectAllFilters,
  selectReservationList,
  setFilters,
} from '@Model/reservations/actions';
import {
  getDateRange,
  getGroupedReservationsFormatted,
  isReservationDetailsListSelected,
  isReservationDetailsSelected,
} from '@Model/reservations/selectors';
import {
  HAPPENINGS,
  RESERVATIONS,
  RESERVATIONS_LIST_MODAL,
} from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';

import { ICalendarFromDispatch, ICalendarFromState } from './Calendar';
import Calendar from './Calendar.component';

const mapStateToProps = (state: _Store.IState): ICalendarFromState => {
  return {
    isLoading: getLoading(RESERVATIONS)(state) || getLoading(HAPPENINGS)(state),
    isModalLoading: getLoading(RESERVATIONS_LIST_MODAL)(state),
    isReservationListSelected: isReservationDetailsListSelected(state),
    isReservationSelected: isReservationDetailsSelected(state),
    range: getDateRange(state),
    reservations: getGroupedReservationsFormatted(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ICalendarFromDispatch => {
  return {
    clearFilters: () => dispatch(clearFiltersRequest()),
    dateChanged: (dateRange) => dispatch(reload(dateRange)),
    mounted: () => dispatch(mounted()),
    selectAllFilters: () => dispatch(selectAllFilters()),
    selectReservationList: (reservations) =>
      dispatch(selectReservationList(reservations)),
    setFilters: (filters) => dispatch(setFilters(filters)),
  };
};

export default connect<
  ICalendarFromState,
  ICalendarFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Calendar);
