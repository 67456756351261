import { createSelector } from 'reselect';

import _Store from '@Store';

import { IEventPartner } from '@Services/$metadata-api/types';

import { IMetadataReducer } from './../types';
import get from './get';

const getEventsPartner = createSelector<
  _Store.IState,
  IMetadataReducer,
  IEventPartner[]
>([get], (metadata) => {
  return metadata.events;
});

export default getEventsPartner;
