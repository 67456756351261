import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  form: {
    overflowY: 'auto',
  },
  infoContainer: {
    backgroundColor: theme.palette.action.focus,
  },
  input: {
    marginTop: theme.spacing(8),
  },
  spacing1: {
    marginTop: theme.spacing(1),
  },
  spacing2: {
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
