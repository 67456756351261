import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
  catchSaveConfigurationV2,
  handleActiveConfiguration,
} from '@Model/configurations/actions';
import { get as getConfigurations } from '@Model/configurations/selectors';
import { IConfiguration } from '@Model/configurations/types';
import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { catchGetRuleType } from '@Model/priceTypes/actions';
import _Store from '@Store';
import ConfigurationModal from './ConfigurationModal.component';
import { prepareInitialValues } from './ConfigurationModal.helpers';
import {
  IConfigurationModalFromDispatch,
  IConfigurationModalFromState,
} from './ConfigurationModal.types';

const mapStateToProps = (
  state: _Store.IState
): IConfigurationModalFromState => {
  const { activeConfiguration } = getConfigurations(state);

  return {
    activeConfiguration,
    initialValues: prepareInitialValues(activeConfiguration),
    partnerId: getHappeningPartnerId(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IConfigurationModalFromDispatch => {
  return {
    catchGetRuleType: () => {
      dispatch(catchGetRuleType());
    },
    catchSaveConfiguration: (configurations: {
      configuration: IConfiguration;
      type: string;
    }) => {
      dispatch(catchSaveConfigurationV2(configurations));
    },
    handleActiveConfiguration: (configurations) => {
      dispatch(handleActiveConfiguration(configurations));
    },
  };
};

export default connect<
  IConfigurationModalFromState,
  IConfigurationModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ConfigurationModal);
