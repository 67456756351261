import _Store from '@Store';
import { createSelector } from 'reselect';
import getAvailabilities from '../../happening/selectors/getAvailabilities';
import { IAvailabilitiesReducer } from '../../happening/types';
import { ISelectedHappeningSpace } from '../../happenings/types/index';
import { ITimeSlot } from '../types';
import getSelectedPeopleCount from './getSelectedPeopleCount';
import getSelectedSlot from './getSelectedSlot';
import getSelectedSpace from './getSelectedSpace';
import isPricePerPersonCalculating from './isPricePerPersonCalculating';

const getSelectedSpacePriceWithOutDiscount = createSelector<
  _Store.IState,
  ITimeSlot | null,
  ISelectedHappeningSpace | null,
  IAvailabilitiesReducer,
  number | null,
  boolean,
  number
>(
  [
    getSelectedSlot,
    getSelectedSpace,
    getAvailabilities,
    getSelectedPeopleCount,
    isPricePerPersonCalculating,
  ],
  (
    selectedSlot,
    selectedSpace,
    availabilities,
    selectedPeopleCount,
    calculatePricePerPerson,
  ) => {
    let result = 0;
    if (selectedSlot === null || selectedSpace === null) {
      return 0;
    }

    const { startTime } = selectedSlot;
    const { id } = selectedSpace;

    let mergedDays = availabilities.currentDay;

    if (availabilities.otherDays) {
      mergedDays = {
        ...availabilities.currentDay,
        ...availabilities.otherDays,
      };
    }

    if (mergedDays[startTime]) {
      const found = mergedDays[startTime].find(
        (availability) => availability.spaceId === id,
      );

      if (found) {
        result = found.price;
      }
    }

    if (result < 0) {
      return 0;
    }

    return result;
  },
);

export default getSelectedSpacePriceWithOutDiscount;
