import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import _Store from '@Store';

import { handleActiveRefundRegistration } from '@Model/refundsRegistration/actions';
import { getRefundsRegistrationsMetadata } from '@Model/refundsRegistration/selectors';

import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => ({
  metadata: getRefundsRegistrationsMetadata(state),
});

const mapDispatchToProps = (dispatch: Dispatch): IFormLayoutFromDispatch => ({
  handleClose: () => dispatch(handleActiveRefundRegistration(null)),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
