import React from 'react';

// Route components
import RundateEditPage from '@/components/pages/RundateEdit';
import { Route, Switch } from 'react-router';

import AddEventPage from '@Compo/pages/AddEvent';
import AddRundatePage from '@Compo/pages/AddRundate';
import AdminPage from '@Compo/pages/Admin';
import CalendarPage from '@Compo/pages/Calendar';
import Checking from '@Compo/pages/Checking';
import ConsentsList from '@Compo/pages/ConsentsList';
import DiscountsPage from '@Compo/pages/Discounts';
import FindTicketPage from '@Compo/pages/FindTicket';
import HappeningAddPage from '@Compo/pages/HappeningAdd';
import HappeningEditPage from '@Compo/pages/HappeningEdit';
import Happenings from '@Compo/pages/Happenings';
import LoginPage from '@Compo/pages/Login';
import MailPage from '@Compo/pages/Mail';
import MainPage from '@Compo/pages/Main';
import NewRules from '@Compo/pages/NewRules';
import PoolDescriptionsPage from '@Compo/pages/PoolDescriptions';
import PriceTypesPage from '@Compo/pages/PriceTypes';
import ProductsPage from '@Compo/pages/Products';
import RefundsRegistrationPage from '@Compo/pages/RefundsRegistration';
import ReportBugPage from '@Compo/pages/ReportBug';
import ReportsPage from '@Compo/pages/Reports';
import ReportSeatsIoPage from '@Compo/pages/ReportSeatsIo';
import ReservationAdd from '@Compo/pages/ReservationAdd';
import Rules from '@Compo/pages/Rules';
import RundatesPage from '@Compo/pages/Rundates';
import SettingsPage from '@Compo/pages/Settings';
import SettlementsPage from '@Compo/pages/Settlements';
import StartPage from '@Compo/pages/Start';
import StaticsPage from '@Compo/pages/Statics';
import Summary from '@Compo/pages/Summary';
import SummaryCanceled from '@Compo/pages/SummaryCanceled';
import SummaryFail from '@Compo/pages/SummaryFail';
import TicketsPage from '@Compo/pages/Tickets';
import TransactionsPage from '@Compo/pages/Transactions';
import TransactionsDelayed from '@Compo/pages/TransactionsDelayed';
import UsersPage from '@Compo/pages/Users';
import config from '@Config';

import routes from './routes';

const Routes = () => (
  <>
    <Switch>
      <Route exact={true} path={routes.index} component={MainPage} />
      <Route exact={true} path={routes.happenings} component={Happenings} />
      <Route
        exact={true}
        path={routes.addHappening}
        component={HappeningAddPage}
      />
      <Route
        exact={true}
        path={routes.editHappening}
        component={HappeningEditPage}
      />
      <Route exact={false} path={routes.calendar} component={CalendarPage} />
      <Route exact={false} path={routes.reports} component={ReportsPage} />
      <Route exact={true} path={routes.start} component={StartPage} />
      <Route
        exact={true}
        path={routes.addReservation}
        component={ReservationAdd}
      />
      <Route exact={true} path={routes.discounts} component={DiscountsPage} />
      <Route exact={true} path={routes.summarySuccess} component={Summary} />
      <Route exact={true} path={routes.summaryFail} component={SummaryFail} />
      <Route
        exact={true}
        path={routes.summaryCanceled}
        component={SummaryCanceled}
      />
      <Route exact={true} path={routes.admin} component={AdminPage} />
      <Route exact={true} path={routes.checking} component={Checking} />
      <Route exact={true} path={routes.rules} component={Rules} />
      <Route exact={true} path={routes.newRules} component={NewRules} />
      <Route exact={true} path={routes.consentsList} component={ConsentsList} />
      <Route exact={true} path={routes.statics} component={StaticsPage} />
      <Route exact={true} path={routes.login} component={LoginPage} />
      <Route
        exact={true}
        path={routes.transactionsDelayed}
        component={TransactionsDelayed}
      />
      <Route exact={true} path={routes.tickets} component={TicketsPage} />
      <Route
        exact={true}
        path={routes.findTickets}
        component={FindTicketPage}
      />
      {config.cms.showGoingRundates && (
        <Route exact={true} path={routes.rundates} component={RundatesPage} />
      )}
      {config.cms.showGoingRundates && (
        <Route
          exact={true}
          path={routes.rundateEdit}
          component={RundateEditPage}
        />
      )}
      <Route exact={true} path={routes.products} component={ProductsPage} />
      <Route exact={true} path={routes.priceTypes} component={PriceTypesPage} />
      <Route exact={true} path={routes.settings} component={SettingsPage} />
      <Route
        exact={true}
        path={routes.settlements}
        component={SettlementsPage}
      />
      <Route exact={true} path={routes.mail} component={MailPage} />
      <Route
        exact={true}
        path={routes.rundateSeatsIoReport}
        component={ReportSeatsIoPage}
      />
      <Route exact={true} path={routes.reportBug} component={ReportBugPage} />
      <Route exact={true} path={routes.users} component={UsersPage} />
      <Route
        exact={true}
        path={routes.poolDescriptions}
        component={PoolDescriptionsPage}
      />
      <Route exact={true} path={routes.addEvent} component={AddEventPage} />
      <Route exact={true} path={routes.addRundate} component={AddRundatePage} />
      <Route
        exact={true}
        path={routes.refundsRegistration}
        component={RefundsRegistrationPage}
      />
      <Route
        exact={true}
        path={routes.transactions}
        component={TransactionsPage}
      />
    </Switch>
  </>
);

export default Routes;
