import React from 'react';

import { Chip, DialogTitle, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IFormLayoutProps } from './../../FormLayout.types';

const FormTitle = ({ values }: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Mail.components.FormLayout.components.FormTitle',
  });

  const TITLE_TEXT = values.id ? t('editMailing') : t('addMailing');

  return (
    <DialogTitle>
      <Grid container={true} justifyContent="space-between">
        <Grid item={true}>
          <Typography variant="h6">{TITLE_TEXT}</Typography>
        </Grid>
        {(values.isSent || values.isDraft) && (
          <Grid item={true}>
            <Chip
              label={values.isDraft ? t('messageDraft') : t('messageSended')}
              variant="outlined"
              size="medium"
              color="secondary"
            />
          </Grid>
        )}
      </Grid>
    </DialogTitle>
  );
};

export default FormTitle;
