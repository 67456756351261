import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import MuiDatePicker from '@Compo/reusable/MuiDatePicker';

import Days from '../../../Days';
import MultiDuration from '../../../MultiDuration';
import Preview from '../../../Preview';
import { makeName, makeRRule } from './FormLayout.helpes';
import { IFormLayoutProps } from './FormLayout.types';

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(8),
  },
  leftItem: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      paddingRight: 0,
    },
  },
  rightItem: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
    },
  },
  spacing2: {
    marginBottom: theme.spacing(2),
  },
  spacing4: {
    marginTop: theme.spacing(4),
  },

  form: {
    overflowY: 'auto',
  },
}));

export const FormLayout = (props: IFormLayoutProps) => {
  const classes = useStyles();
  const { t } = useTranslation('components', {
    keyPrefix:
      'BetterManager.AddingConfiguration.components.ConfigurationModal.Components.FormLayout',
  });

  const {
    handleSubmit,
    submitForm,
    values,
    dirty,
    handleActiveConfiguration,
    handleChange,
    setFieldValue,
    handleBlur,
    partners,
    touched,
    errors,
  } = props;

  const {
    advanced,
    fromTime,
    duration,
    id,
    title,
    closed,
    cyclic,
    isUpsell,
    days,
    until,
    newConfiguration,
    isPeopleCount,
    validTo,
    validFrom,
  } = values;

  const rrule = makeRRule(values);
  const rruleName = makeName(values, rrule);

  const catchHandleClose = () => {
    handleActiveConfiguration(null);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent>
          <FormControl
            variant="standard"
            fullWidth={true}
            error={!!(touched.partnerId && errors.partnerId)}
          >
            <InputLabel>
              {(touched.partnerId && errors.partnerId) || t('partner')}
            </InputLabel>
            <Select
              variant="standard"
              value={values.partnerId || -1}
              onChange={handleChange}
              defaultValue={-1}
              fullWidth={true}
              name="partnerId"
              onBlur={handleBlur}
            >
              <MenuItem value={-1}>-</MenuItem>
              {partners &&
                partners.map((partner) => (
                  <MenuItem key={partner.id} value={partner.id}>
                    {partner.name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{t('partnerHelper')}</FormHelperText>
          </FormControl>
          <TextField
            variant="standard"
            id="title"
            label={(touched.title && errors.title) || t('rulesName')}
            helperText={t('rulesNameHelper')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            name="title"
            error={!!(touched.title && errors.title)}
            fullWidth={true}
            className={classes.input}
          />
          <FormControlLabel
            className={classes.input}
            control={
              <Switch
                checked={values.closed}
                onChange={handleChange}
                name="closed"
                color="primary"
              />
            }
            label={t('placeClosed')}
          />
          <TextField
            variant="standard"
            id="price"
            type="number"
            label={(touched.price && errors.price) || t('price')}
            helperText={t('priceHelper')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.price}
            name="price"
            error={!!(touched.price && errors.price)}
            fullWidth={true}
            className={classes.input}
          />
          <TextField
            variant="standard"
            id="maxNumberOfPeople"
            label={
              (touched.maxNumberOfPeople && errors.maxNumberOfPeople) ||
              t('maxNumberOfPeople')
            }
            helperText={t('maxNumberOfPeopleHelper')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.maxNumberOfPeople}
            name="maxNumberOfPeople"
            error={!!(touched.maxNumberOfPeople && errors.maxNumberOfPeople)}
            fullWidth={true}
            className={classes.input}
          />
          <Grid container={true} className={classes.input}>
            <Grid item={true} sm={12} md={6} className={classes.leftItem}>
              <MuiDatePicker
                name="validFrom"
                disableToolbar={true}
                fullWidth={true}
                variant="standard"
                format="DD.MM.yyyy"
                label={
                  (touched.validFrom && errors.validFrom) || t('startDate')
                }
                helperText={t('startDateHelper')}
                value={values.validFrom}
                onChange={(date) => setFieldValue('validFrom', date)}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item={true} sm={12} md={6} className={classes.rightItem}>
              <MuiDatePicker
                name="validTo"
                disableToolbar={true}
                fullWidth={true}
                variant="standard"
                format="DD.MM.yyyy"
                label={(touched.validTo && errors.validTo) || t('endDate')}
                helperText={t('endDateHelper')}
                value={values.validTo}
                onChange={(date) => setFieldValue('validTo', date)}
                onBlur={handleBlur}
              />
            </Grid>
          </Grid>
          <Grid className={classes.input}>
            <MultiDuration
              onChange={handleChange}
              names={['fromTime', 'durationToString']}
              values={[fromTime, duration.toString()]}
            />
          </Grid>
          <Grid item={true} className={classes.input}>
            <Typography variant="body1" className={classes.spacing2}>
              {t('ruleType')}
            </Typography>
            <RadioGroup
              name="cyclic"
              value={values.cyclic}
              onChange={(e) => {
                setFieldValue(
                  'cyclic',
                  e.currentTarget.value === 'true' ? true : false
                );
              }}
            >
              <FormControlLabel
                value="true"
                checked={values.cyclic === true}
                control={<Radio color="primary" />}
                label={t('cyclic')}
              />
              <FormControlLabel
                value="false"
                checked={values.cyclic === false}
                control={<Radio color="primary" />}
                label={t('oneTime')}
              />
            </RadioGroup>
          </Grid>
          {cyclic && (
            <Grid className={classes.input}>
              <Typography variant="body1" className={classes.spacing2}>
                {t('days')}
              </Typography>
              <Days name="days" onChange={handleChange} value={days} />
            </Grid>
          )}
          <Grid className={classes.input}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.isUpsell}
                  onChange={handleChange}
                  name="isUpsell"
                  color="primary"
                />
              }
              label={t('addUpsell')}
            />
            {isUpsell && (
              <Grid container={true} className={classes.spacing4}>
                <Grid item={true} sm={12} md={6}>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    className={classes.leftItem}
                  >
                    <InputLabel>
                      {(touched.upsellType && errors.upsellType) ||
                        t('discountType')}
                    </InputLabel>
                    <Select
                      variant="standard"
                      value={values.upsellType || 0}
                      onChange={handleChange}
                      defaultValue={0}
                      fullWidth={true}
                      name="upsellType"
                      onBlur={handleBlur}
                    >
                      <MenuItem value={0}>{t('valuePrice')}</MenuItem>
                      <MenuItem value={1}>{t('percent')}</MenuItem>
                    </Select>
                    <FormHelperText>{t('discountTypeHelper')}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} sm={12} md={6}>
                  <TextField
                    variant="standard"
                    id="upsellValue"
                    label={
                      (touched.upsellValue && errors.upsellValue) ||
                      t('discountValue')
                    }
                    helperText={t('discountValueHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.upsellValue}
                    name="upsellValue"
                    error={!!(touched.upsellValue && errors.upsellValue)}
                    fullWidth={true}
                    className={classes.rightItem}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid className={classes.input}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.isPeopleCount}
                  onChange={handleChange}
                  name="isPeopleCount"
                  color="primary"
                />
              }
              label={t('rulePerPerson')}
            />
            {isPeopleCount && (
              <>
                <Grid container={true} className={classes.spacing4}>
                  <Grid item={true} sm={12} md={6}>
                    <TextField
                      variant="standard"
                      id="fromNumberOfPeople"
                      label={
                        (touched.fromNumberOfPeople &&
                          errors.fromNumberOfPeople) ||
                        t('fromNumberOfPeople')
                      }
                      helperText={t('fromNumberOfPeopleHelper')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fromNumberOfPeople}
                      name="fromNumberOfPeople"
                      error={
                        !!(
                          touched.fromNumberOfPeople &&
                          errors.fromNumberOfPeople
                        )
                      }
                      fullWidth={true}
                      className={classes.leftItem}
                    />
                  </Grid>

                  <Grid item={true} sm={12} md={6}>
                    <FormControl
                      variant="standard"
                      fullWidth={true}
                      className={classes.rightItem}
                    >
                      <InputLabel>
                        {(touched.upsellType && errors.upsellType) ||
                          t('ticketGroupType')}
                      </InputLabel>
                      <Select
                        variant="standard"
                        value={values.priceGoesUp || 'down'}
                        onChange={handleChange}
                        defaultValue={'down'}
                        fullWidth={true}
                        name="priceGoesUp"
                        onBlur={handleBlur}
                      >
                        <MenuItem value={'down'}>{t('down')}</MenuItem>
                        <MenuItem value={'up'}>{t('up')}</MenuItem>
                      </Select>
                      <FormHelperText>
                        {t('ticketGroupTypeHelper')}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container={true} className={classes.spacing4}>
                  <Grid item={true} sm={12} md={6}>
                    <FormControl
                      variant="standard"
                      fullWidth={true}
                      className={classes.leftItem}
                    >
                      <InputLabel>
                        {(touched.perPersonType && errors.perPersonType) ||
                          t('ticketGroupPriceType')}
                      </InputLabel>
                      <Select
                        variant="standard"
                        value={values.perPersonType || 'value'}
                        onChange={handleChange}
                        defaultValue={'value'}
                        fullWidth={true}
                        name="perPersonType"
                        onBlur={handleBlur}
                      >
                        <MenuItem value={'value'}>{t('valuePrice')}</MenuItem>
                        <MenuItem value={'percentageValue'}>
                          {t('percent')}
                        </MenuItem>
                      </Select>
                      <FormHelperText>
                        {t('ticketGroupPriceTypeHelper')}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item={true} sm={12} md={6}>
                    <TextField
                      variant="standard"
                      id="perPersonValue"
                      label={
                        (touched.perPersonValue && errors.perPersonValue) ||
                        t('discountValue')
                      }
                      helperText={t('discountValueHelper')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.perPersonValue}
                      name="perPersonValue"
                      error={
                        !!(touched.perPersonValue && errors.perPersonValue)
                      }
                      fullWidth={true}
                      className={classes.rightItem}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body1">{t('show')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Preview rrule={rrule} />
            </AccordionDetails>
          </Accordion>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={catchHandleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('saveRule')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
