import React, { useEffect } from 'react';

import {
  FormControl,
  FormHelperText,
  ListItem,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import GridUi from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import colors from '@Compo/App/colors';
import { IDropDownItem } from '@Compo/reusable/DropDown/DropDown.types';
import config from '@Config';
import { getSpellNumberOfPeople } from '@Misc/helpers/strings/peopleCount';
import translateDefaultPriceType from '@Misc/helpers/strings/translateDefaultPrice';

import { IFormLayoutProps } from '../FormLayout';
import styles from './TicketTypeStepper.module.scss';

const useStyles = makeStyles((theme) => ({
  item: {
    backgroundColor: colors.background.paper,
    paddingBottom: theme.spacing(1.75),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.75),
  },
}));

const TicketTypeStepper = ({
  basketData,
  prices,
  pricesAsObjects,
  selectPrice,
  selectedPrices,
  priceTypes,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Add.components.FormLayout.TicketTypeStepper',
  });

  const classes = useStyles();

  const numberOfSelectBoxes = basketData.calculatePricePerPerson
    ? basketData.numberOfPeople
    : 1;

  useEffect(() => {
    const getDefaultPrice = (): string[] => {
      if (prices && prices.length) {
        const defaultPrices: string[] = [];
        if (prices.indexOf('default') > -1) {
          [...new Array(numberOfSelectBoxes)].forEach((price) => {
            defaultPrices.push('default');
          });
        } else {
          [...new Array(numberOfSelectBoxes)].forEach((price) => {
            defaultPrices.push(prices[0]);
          });
        }

        return defaultPrices;
      }
      return [];
    };

    selectPrice(getDefaultPrice());
  }, [numberOfSelectBoxes]);

  const getPriceValue = (type: string): number => {
    if (prices && prices.length) {
      const currentObject = pricesAsObjects.find(
        (price) => price.type === type
      );

      if (currentObject && basketData.isUpSellSelected) {
        return currentObject.value + basketData.upSellPice;
      }

      if (currentObject) {
        return currentObject.value;
      }
    }
    return 0;
  };

  const catchSelectedPrice = (value: string, key: number) => {
    const newList = [...selectedPrices];
    newList[key] = value;

    selectPrice(newList);
  };

  const getSummaryPrice = () => {
    const pricesValues: number[] = [];
    selectedPrices.forEach((price) => {
      pricesValues.push(getPriceValue(price));
    });
    if (pricesValues.length) {
      return basketData.isUpSellSelected
        ? pricesValues.reduce((pPrice, cPrice) => {
            return pPrice + cPrice;
          })
        : pricesValues.reduce((pPrice, cPrice) => {
            return pPrice + cPrice;
          });
    }

    return 0;
  };

  const dropDownItems: IDropDownItem[] = prices.map((price, key) => ({
    key: key.toString(),
    name: price,
  }));

  const getNameByType = (type: string) => {
    return priceTypes?.find((price) => price.type === type)?.title;
  };

  const getNumberOfPeople = () => {
    return `${basketData.title} ${
      basketData.calculatePricePerPerson
        ? `(${basketData.numberOfPeople} ${getSpellNumberOfPeople(
            basketData.numberOfPeople
          )})`
        : ''
    }`;
  };

  const getPrice = () => {
    return `${t('summary')} ${getSummaryPrice()} ${t('localCurrency')}`;
  };

  const getDate = () => {
    return basketData.extendedDuration
      ? `${moment(basketData.dateTime).format('DD.MM.YYYY HH:mm')} ${
          basketData.friendlyExtendedDuration
        }`
      : moment(basketData.dateTime).format('DD.MM.YYYY HH:mm');
  };

  return (
    <>
      <ListItem className={classes.item}>
        <GridUi
          container={true}
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <GridUi item={true} className={styles.steeperGrid}>
            <Typography variant="body1">{getNumberOfPeople()}</Typography>
            <Typography variant="body2" color="textSecondary">
              {getDate()}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {basketData.space}
            </Typography>
            {basketData.isUpSellSelected && (
              <Typography variant="body2" color="textSecondary">{`${t(
                'upsell'
              )} (${basketData.upSellPice} ${t('localCurrency')})`}</Typography>
            )}
          </GridUi>
        </GridUi>
      </ListItem>
      <GridUi container={true} justifyContent="flex-end" alignItems="center">
        <Typography variant="body1">{getPrice()}</Typography>
      </GridUi>
      {config.cms.showTicketsTypes && (
        <>
          <GridUi container={true} spacing={3}>
            <GridUi item={true} xs={12}>
              {t('ticketType')}
            </GridUi>

            <GridUi item={true} xs={12}>
              {selectedPrices.length &&
                [...new Array(numberOfSelectBoxes)].map((value, key) => (
                  <GridUi container={true} key={key}>
                    <GridUi item={true} xs={12}>
                      <ListItem className={classes.item}>
                        {selectedPrices[key] && (
                          <FormControl variant="standard" fullWidth={true}>
                            <Select
                              value={selectedPrices[key]}
                              onChange={(e) =>
                                catchSelectedPrice(
                                  e.target.value as string,
                                  key
                                )
                              }
                              fullWidth={true}
                            >
                              {dropDownItems.map((item) => (
                                <MenuItem key={item.name} value={item.name}>
                                  {translateDefaultPriceType(
                                    getNameByType(item.name)
                                  )}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText>
                              {`${t('ticket')} ${key + 1}`}
                            </FormHelperText>
                          </FormControl>
                        )}
                      </ListItem>
                    </GridUi>
                    <GridUi item={true} xs={12}>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        align="right"
                      >
                        {t('priceTicket')} {getPriceValue(selectedPrices[key])}{' '}
                        {t('localCurrency')}
                      </Typography>
                    </GridUi>
                  </GridUi>
                ))}
            </GridUi>
          </GridUi>
        </>
      )}
    </>
  );
};

export default TicketTypeStepper;
