import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    fontWeight: 500,
  },
  closeButton: {
    transform: 'translate(0, 100%)',
  },
}));

export default useStyles;
