import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { get } from '@Model/authorization/selectors';
import {
  deleteMail,
  edit,
  handleActivePage,
  handleSearchText,
  mounted,
  selectEventMail,
  send,
} from '@Model/mailing/actions';
import { getMailing, getMeta, getSearchText } from '@Model/mailing/selectors';
import { allPermissions, MAILING } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';

import { getInitialValues } from './components/MailForm/MailForm.helpers';
import Mail from './Mail.component';
import { IMailFromDispatch, IMailFromState } from './Mail.types';

const mapStateToProps = (state: _Store.IState): IMailFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    isLoading: getLoading(MAILING)(state),
    mails: getMailing(state),
    meta: getMeta(state),
    permissionEventSend: permissions.includes(
      allPermissions.access_event_mailing_send
    ),
    permissionEventWrite: permissions.includes(
      allPermissions.access_event_mailing_write
    ),
    searchText: getSearchText(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IMailFromDispatch => ({
  addMailing: () => dispatch(selectEventMail(getInitialValues())),
  deleteMail: (id) => dispatch(deleteMail(id)),
  edit: (id) => dispatch(edit(id)),
  handleActivePage: (page) => dispatch(handleActivePage(page)),
  handleSearchText: (text) => dispatch(handleSearchText(text)),
  mounted: () => dispatch(mounted()),
  sendMail: (id) => dispatch(send(id)),
});

export default connect<IMailFromState, IMailFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Mail);
