import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getUserInfo } from '@Model/authorization/selectors';
import { resetValue } from '@Model/discount/actions';
import { mounted } from '@Model/discounts/actions';
import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { showSuccessModal } from '@Model/modal/actions';
import { allPermissions } from '@Model/state/constants';

import {
  addLocalProduct,
  addProduct,
  getAllCategories,
  getAllLocalProducts,
  getAllProducts,
  getAllProductsWithCategories,
  getAllShipmentFee,
  getAllZohoProducts,
  getProducts,
  getProductsByCategory,
  getSingleProduct,
  handleActiveCategory,
  handleActiveLocalProduct,
  handleActivePage,
  handleActiveShipmentFee,
  handleActiveZohoProduct,
  mountedProducts,
  productsListMounted,
  removeCategory,
  removeLocalProduct,
  saveCategory,
  saveProduct,
  selectProduct,
  setProductCount,
  setProductStorage,
  setProductView,
  unSelectProduct,
  updateList,
} from '../actions';
import { HANDLE_ACTIVE_LOCAL_PRODUCT } from '../constants/actions';
import {
  getMeta,
  getProducts as getProductsSelector,
  getView,
} from '../selectors';
import {
  IGetLocalProductsSuccessPayload,
  IGetProductsByCategorySuccessPayload,
  IGetProductsPayloadSuccess,
} from '../types';
import {
  getAllInvoiceProducts,
  handleActiveInvoiceProduct,
} from './../actions/index';

export const whenProductsListMounted: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(productsListMounted)),
    mergeMap$(() => {
      return of$(
        getAllProducts.request(),
        getAllProductsWithCategories.request({ page: 1, count: 200 })
      );
    })
  );
};

export const whenProductsListMountedGetAllProductsWithCategories: _Store.IEpic =
  (action$, state$, { productsApi }) => {
    return action$.pipe(
      filter$(isActionOf(getAllProductsWithCategories.request)),
      withLatestFrom$(state$),
      mergeMap$(([action, state]) => {
        const partnerId = getHappeningPartnerId(state);

        return from$(
          productsApi.getCategories(
            action.payload.count,
            action.payload.page,
            partnerId
          )
        ).pipe(
          map$((data) => {
            return getAllProductsWithCategories.success({
              data: data.data,
              meta: data.meta,
            });
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => productsApi.cancelProducts())
            )
          ),
          catchError$((error: Error) =>
            of$(getAllProductsWithCategories.failure(error))
          )
        );
      })
    );
  };

export const whenCategoriesFetchedGetAllProducts: _Store.IEpic = (
  action$,
  state$,
  { productsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getAllProductsWithCategories.success)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      const categoriesPromises: Array<
        Promise<IGetLocalProductsSuccessPayload>
      > = [];

      action.payload.data.forEach((category) => {
        categoriesPromises.push(
          productsApi.getProductsByCategory(category.id, partnerId)
        );
      });

      categoriesPromises.push(productsApi.getProductsByCategory(0, partnerId));
      return from$(Promise.all(categoriesPromises)).pipe(
        map$((data) => {
          const category: IGetProductsByCategorySuccessPayload = {};

          data.forEach((response) => {
            if (response.data[0]?.category?.id) {
              category[response.data[0].category.id] = response.data;
            } else {
              category[0] = response.data;
            }
          });

          return getProductsByCategory.success(category);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts())
          )
        ),
        catchError$((error: Error) => of$(getProductsByCategory.failure(error)))
      );
    })
  );
};

export const whenProductsMountedGetAllLocalProducts: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(mountedProducts)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedView = getView(state);
      switch (selectedView) {
        case 'products':
          return of$(getAllLocalProducts.request({ page: 1, count: 10 }));
        case 'categories':
          return of$(getAllCategories.request({ page: 1, count: 10 }));
        case 'courier':
          return of$(getAllShipmentFee.request({ page: 1, count: 10 }));
        case 'zohoProducts':
          return of$(getAllZohoProducts.request({ page: 1, count: 10 }));
        case 'invoiceProducts':
          return of$(getAllInvoiceProducts.request({ page: 1, count: 10 }));
        default:
          return EMPTY$;
      }
    })
  );
};

export const getProductsDataWhenViewChanged: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(setProductView)),
    mergeMap$((action) => {
      switch (action.payload) {
        case 'allProducts':
          return of$(
            getAllLocalProducts.request({
              count: 10,
              page: 1,
              withoutProvider: true,
            })
          );
        case 'products':
          return of$(getAllLocalProducts.request({ page: 1, count: 10 }));
        case 'categories':
          return of$(getAllCategories.request({ page: 1, count: 10 }));
        case 'courier':
          return of$(getAllShipmentFee.request({ page: 1, count: 10 }));
        case 'zohoProducts':
          return of$(getAllZohoProducts.request({ page: 1, count: 10 }));
        case 'invoiceProducts':
          return of$(getAllInvoiceProducts.request({ page: 1, count: 10 }));
        default:
          return EMPTY$;
      }
    })
  );
};

export const getProductsWhenPageChanged: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(handleActivePage)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const selectedView = getView(state);
      const { page, count, text } = action.payload;
      switch (selectedView) {
        case 'allProducts':
          return of$(
            getAllLocalProducts.request({
              count,
              page,
              text,
              withoutProvider: true,
            })
          );
        case 'products':
          return of$(getAllLocalProducts.request({ page, count }));
        case 'categories':
          return of$(getAllCategories.request({ page, count }));
        case 'courier':
          return of$(getAllShipmentFee.request({ page, count }));
        case 'zohoProducts':
          return of$(getAllZohoProducts.request({ page, count }));
        case 'invoiceProducts':
          return of$(getAllInvoiceProducts.request({ page, count }));
        default:
          return EMPTY$;
      }
    })
  );
};

export const getProductsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { productsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getProducts.request)),
    mergeMap$((action) => {
      return from$(productsApi.getProducts(action.payload)).pipe(
        map$((data: IGetProductsPayloadSuccess) => {
          return getProducts.success(data.items);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts())
          )
        ),
        catchError$((error: Error) => of$(getProducts.failure(error)))
      );
    })
  );
};

export const getAllLocalProductsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { productsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getAllLocalProducts.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      return from$(
        productsApi.getLocalProducts(
          action.payload.count,
          action.payload.page,
          partnerId,
          action.payload.withoutProvider,
          action.payload.text
        )
      ).pipe(
        map$((data) => {
          return getAllLocalProducts.success({
            data: data.data,
            meta: data.meta,
          });
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts())
          )
        ),
        catchError$((error: Error) => of$(getAllLocalProducts.failure(error)))
      );
    })
  );
};
export const getAllCategoriesWhenHandleActiveLocalProduct: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(
      isActionOf([
        handleActiveLocalProduct,
        addLocalProduct,
        handleActiveZohoProduct,
        handleActiveInvoiceProduct,
      ])
    ),
    mergeMap$((action) => {
      if (action.type === HANDLE_ACTIVE_LOCAL_PRODUCT) {
        if (action.payload === null) {
          return EMPTY$;
        }
      }
      return of$(getAllCategories.request({ count: 100, page: 1 }));
    })
  );
};
export const getAllCategoriesWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { productsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getAllCategories.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      const selectedView = getView(state);
      const meta = getMeta(state);

      return from$(
        productsApi.getCategories(
          action.payload.count,
          action.payload.page,
          partnerId
        )
      ).pipe(
        map$((data) => {
          return getAllCategories.success({
            data: data.data,
            meta: selectedView === 'categories' ? data.meta : meta,
          });
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts())
          )
        ),
        catchError$((error: Error) => of$(getAllCategories.failure(error)))
      );
    })
  );
};
export const getAllShipmentFeesWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { productsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getAllShipmentFee.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);

      return from$(
        productsApi.getShipmentFees(
          action.payload.count,
          action.payload.page,
          partnerId
        )
      ).pipe(
        map$((data) => {
          return getAllShipmentFee.success({
            data: data.data,
            meta: data.meta,
          });
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts())
          )
        ),
        catchError$((error: Error) => of$(getAllCategories.failure(error)))
      );
    })
  );
};

export const getAllZohoProductsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { productsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getAllZohoProducts.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);

      return from$(
        productsApi.getZohoProducts(
          action.payload.count,
          action.payload.page,
          partnerId
        )
      ).pipe(
        map$((data) => {
          return getAllZohoProducts.success({
            data: data.data,
            meta: data.meta,
          });
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts())
          )
        ),
        catchError$((error: Error) => of$(getAllZohoProducts.failure(error)))
      );
    })
  );
};

export const getAllInvoiceProductsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { productsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getAllInvoiceProducts.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);

      return from$(
        productsApi.getInvoiceProducts(
          action.payload.count,
          action.payload.page,
          partnerId
        )
      ).pipe(
        map$((data) => {
          return getAllInvoiceProducts.success({
            data: data.data,
            meta: data.meta,
          });
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts())
          )
        ),
        catchError$((error: Error) => of$(getAllInvoiceProducts.failure(error)))
      );
    })
  );
};

export const fetchSingleProductWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(handleActiveInvoiceProduct)),
    map$((action) => {
      if (action.payload) {
        return getSingleProduct.request(action.payload);
      }

      return getSingleProduct.success(null);
    })
  );
};

export const getSingleProductWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { productsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSingleProduct.request)),
    mergeMap$((action) => {
      return from$(productsApi.getSingleProduct(action.payload)).pipe(
        map$((data) => {
          return getSingleProduct.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts())
          )
        ),
        catchError$((error: Error) => of$(getSingleProduct.failure(error)))
      );
    })
  );
};

export const saveCategoryWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { productsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveCategory)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { page, size } = getMeta(state);
      return from$(productsApi.addSingleCategory(action.payload)).pipe(
        mergeMap$((data) => {
          return of$(
            showSuccessModal(),
            handleActiveCategory(null),
            getAllCategories.request({ page, count: size })
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts())
          )
        ),
        catchError$((error: Error) => of$(getAllCategories.failure(error)))
      );
    })
  );
};

export const saveProductWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { productsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveProduct)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { page, size } = getMeta(state);
      const selectedView = getView(state);

      if (action.payload.categoryId === 0) {
        delete action.payload.categoryId;
      }

      return from$(productsApi.addSingleProduct(action.payload)).pipe(
        mergeMap$((data) => {
          if (selectedView === 'products') {
            return of$(
              showSuccessModal(),
              handleActiveLocalProduct(null),
              getAllLocalProducts.request({ page, count: size })
            );
          }
          if (selectedView === 'zohoProducts') {
            return of$(
              showSuccessModal(),
              handleActiveZohoProduct(null),
              getAllZohoProducts.request({ page, count: size })
            );
          }
          if (selectedView === 'invoiceProducts') {
            return of$(
              showSuccessModal(),
              handleActiveInvoiceProduct(null),
              getAllInvoiceProducts.request({ page, count: size })
            );
          }
          return of$(
            showSuccessModal(),
            handleActiveLocalProduct(null),
            handleActiveShipmentFee(null),
            handleActiveZohoProduct(null),
            getAllShipmentFee.request({ page, count: size })
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts())
          )
        ),
        catchError$((error: Error) => of$(getAllCategories.failure(error)))
      );
    })
  );
};

export const removeProductWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { productsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(removeLocalProduct)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { page, size } = getMeta(state);
      const selectedView = getView(state);

      return from$(productsApi.removeSingleProduct(action.payload)).pipe(
        mergeMap$(() => {
          if (selectedView === 'products') {
            return of$(
              showSuccessModal(),
              getAllLocalProducts.request({ page, count: size })
            );
          }
          return of$(
            showSuccessModal(),
            getAllShipmentFee.request({ page, count: size })
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts())
          )
        ),
        catchError$((error: Error) => of$(getAllLocalProducts.failure(error)))
      );
    })
  );
};

export const removeCategoryWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { productsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(removeCategory)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { page, size } = getMeta(state);
      const selectedView = getView(state);

      return from$(productsApi.removeSingleCategory(action.payload)).pipe(
        mergeMap$(() => {
          return of$(
            showSuccessModal(),
            getAllCategories.request({ page, count: size })
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => productsApi.cancelProducts())
          )
        ),
        catchError$((error: Error) => of$(getAllLocalProducts.failure(error)))
      );
    })
  );
};

export const selectProductWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(selectProduct)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { items } = getProductsSelector(state);

      return of$(
        updateList(
          items.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                selected: true,
              };
            } else {
              return item;
            }
          })
        )
      );
    })
  );
};
export const addProductWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(addProduct)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { items } = getProductsSelector(state);
      const finedProduct = items.find(
        (product) => product.id === action.payload.id
      );

      if (finedProduct) {
        return of$(
          updateList(
            items.map((item) => {
              if (item.id === action.payload.id) {
                return {
                  ...item,
                  count: (item.count || 1) + (action.payload.count || 1),
                  selected: true,
                };
              } else {
                return item;
              }
            })
          )
        );
      } else {
        return of$(
          updateList([
            ...items,
            {
              ...action.payload,
              count: action.payload.count || 1,
              selected: true,
            },
          ])
        );
      }
    })
  );
};

export const unSelectProductWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(unSelectProduct)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { items } = getProductsSelector(state);

      return of$(
        updateList(
          items.map((item) => {
            if (item.id === action.payload.id) {
              return {
                ...item,
                selected: false,
              };
            } else {
              return item;
            }
          })
        )
      );
    })
  );
};

export const setProductCountWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(setProductCount)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { items } = getProductsSelector(state);
      const {
        product: { id },
        count,
      } = action.payload;

      return of$(
        updateList(
          items.map((item) => {
            if (item.id === id) {
              if (count) {
                return {
                  ...item,
                  count,
                  selected: true,
                };
              }
              return {
                ...item,
                count: 0,
                selected: false,
              };
            }
            return item;
          })
        )
      );
    })
  );
};

export const setProductStorageWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(setProductStorage)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { items } = getProductsSelector(state);
      const {
        product: { id },
        storageHouseId,
      } = action.payload;

      return of$(
        updateList(
          items.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                storageHouseId,
              };
            }
            return item;
          })
        )
      );
    })
  );
};

export const resetDiscountValueWhenUpdateList: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(updateList)),
    mergeMap$(() => {
      return of$(resetValue());
    })
  );
};
