import _Store from '@Store';
import { Selector } from 'reselect';
import { IReservationDetailsReducer } from '../types';

const getReservationDetails: Selector<
  _Store.IState,
  IReservationDetailsReducer
> = (state) => state.reservation.reservationDetails;

export default getReservationDetails;
