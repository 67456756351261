import { object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';

const configuration = {
  descriptionPL: string(),
  ticketInfo: string(),
  titlePL: string().required(REQUIRED_VALIDATOR_TEXT),
};

export const AddPoolDescriptionSchema = object().shape(configuration);
