import React, { ChangeEvent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Button, InputAdornment, TextField } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import GoingPaper from '@Compo/layout/Paper/GoingPaper';

import GenericTable from '../reusable/GenericTable';
import TableActions from '../reusable/TableActions';
import TableUi from '../reusable/TableUi';
import AddPoolDescriptionModal from './components/AddPoolDescriptionModal';
import mapInitialValues from './components/AddPoolDescriptionModal/AddPoolDescriptionModal.helpers';
import useStyles from './PoolDescriptions.styles';
import { IPoolDescriptionsProps } from './PoolDescriptions.types';

const PoolDescriptions = ({
  setActivePoolDescription,
  mounted,
  isLoading,
  poolDescriptions,
  meta,
  handleActivePage,
  handleChangeRowsPerPage,
  catchGetSinglePoolDescription,
  handleSearch,
}: IPoolDescriptionsProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'PoolDescriptions',
  });

  const [searchParam, setSearchParam] = useState('');
  const [searchTimeOut, setSearchTimeOut] = useState<NodeJS.Timeout | null>(
    null
  );

  const classes = useStyles();
  const columns = [
    { id: 'titlePL', label: t('name') },
    { id: 'descriptionPL', label: t('description') },
    { id: 'ticketInfo', label: t('ticketInfo') },
  ];

  const catchEditPoolDescription = (id: string) => {
    catchGetSinglePoolDescription(Number(id));
  };

  const catchHandlePageChanged = (e: unknown, page: number) => {
    handleActivePage(page + 1);
  };

  const catchChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChangeRowsPerPage(Number(event.target.value));
  };

  const catchHandleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchParam(e.target.value);
    searchFunc(e.target.value);
  };

  const searchFunc = (text: string) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        handleSearch(text);
      }, 1000)
    );
  };

  useEffect(() => {
    mounted();
  }, []);

  return (
    <GoingPaper>
      <AddPoolDescriptionModal />
      <GenericTable>
        <TableActions>
          <TextField
            variant="standard"
            name="search"
            helperText={t('search')}
            value={searchParam}
            onChange={catchHandleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.buttonsContainer}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              startIcon={<AddIcon />}
              className={cn(classes.button, classes.buttonFirst)}
              onClick={() => setActivePoolDescription(mapInitialValues(null))}
            >
              {t('add')}
            </Button>
          </div>
        </TableActions>
        <TableUi
          rows={poolDescriptions}
          columns={columns}
          total={meta.total}
          activePage={meta.page - 1}
          rowsPerPage={meta.size}
          handleChangePage={catchHandlePageChanged}
          handleChangeRowsPerPage={catchChangeRowsPerPage}
          disableCheckBox={true}
          onRowClick={catchEditPoolDescription}
          isLoading={isLoading}
          forceMobileLayout={true}
        />
      </GenericTable>
    </GoingPaper>
  );
};

export default PoolDescriptions;
