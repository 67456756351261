import { createSelector } from 'reselect';

import _Store from '@Store';

import { IRuleTypesReducer } from '../types';

import get from './get';

const getTotalCount = createSelector<_Store.IState, IRuleTypesReducer, number>(
  [get],
  (products) => products.totalCount
);

export default getTotalCount;
