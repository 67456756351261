import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  checkboxBox: {
    paddingLeft: theme.spacing(1),
  },
  content: {
    overflow: 'hidden',
  },
  form: {
    overflowY: 'auto',
  },
  loader: {
    marginBottom: 50,
    position: 'relative',
  },
}));

export default useStyles;
