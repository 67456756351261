import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPartnerSettings, ISettingsReducer } from './../types';
import get from './get';

const getPartner = createSelector<
  _Store.IState,
  ISettingsReducer,
  IPartnerSettings
>([get], (settings) => settings.partner);

export default getPartner;
