import {
  captureTransactionsEmpikDetailsRequest,
  getReservationPrintData,
  summaryMounted,
} from '@Model/reservation/actions/index';
import _Store from '@Store';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetError, resetLoading, setLoading } from '../actions';
import { SUMMARY } from '../constants/constants';

export const setUpStateStartWhenSummaryMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        captureTransactionsEmpikDetailsRequest.request,
        getReservationPrintData.request,
        summaryMounted,
      ])
    ),
    mergeMap$(() => [resetError(SUMMARY), setLoading(SUMMARY)])
  );
};

export const setLoaderSummarySetFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          captureTransactionsEmpikDetailsRequest.success,
          captureTransactionsEmpikDetailsRequest.failure,
          getReservationPrintData.success,
          getReservationPrintData.failure,
        ],
        action
      )
    ),
    map$(() => resetLoading(SUMMARY))
  );
};
