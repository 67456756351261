import _Store from '@Store';
import { Selector } from 'reselect';
import { IReservationsDetailsListReducer } from './../types';

const getDetailsList: Selector<
  _Store.IState,
  IReservationsDetailsListReducer
> = (state) => state.reservations.detailsList;

export default getDetailsList;
