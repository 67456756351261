import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { get } from '@Model/authorization/selectors';
import { eventAddDiscount } from '@Model/discount/actions';
import {
  getEventsPartner,
  getPartners,
  getPoolsPartner,
  getRundatesPartner,
} from '@Model/discount/selectors';
import { allPermissions } from '@Model/state/constants';

import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    dependencies: {
      event: getEventsPartner(state),
      partner: getPartners(state),
      pool: getPoolsPartner(state),
      rundate: getRundatesPartner(state),
    },
    permissionEventDiscountsRefundGoing: permissions.includes(
      allPermissions.access_event_discounts_refund_going_write
    ),
    permissionEventDiscountsRefundPartner: permissions.includes(
      allPermissions.access_event_discounts_refund_partner_write
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormLayoutFromDispatch => ({
  closeModal: () => dispatch(eventAddDiscount(null)),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
