import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';

import { columns } from './../../../AttachModal/AttachModal.constants';
import useStyles from './DependencyTable.styles';
import { IDependencyTableProps } from './DependencyTable.types';

const DependencyTable = ({
  dependencies,
  handleChangeType,
  selectedType,
  handleDeleteDependency,
}: IDependencyTableProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'Discounts.components.EventDiscountModal.components.FormLayout.components.DependencyTable',
  });

  const classes = useStyles();

  const columnsWithActions = [
    ...columns[selectedType],
    { id: 'actions', label: '' },
  ];

  const dependencyWithActions = dependencies.map((dependency) => ({
    ...dependency,
    actions: (
      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={(e) => handleDeleteDependency(selectedType, dependency.id)}
      >
        {t('delete')}
      </Button>
    ),
  }));

  return (
    <>
      {!dependencies.length ? (
        <Button
          variant="outlined"
          size="small"
          color="secondary"
          startIcon={<AddIcon />}
          className={classes.button}
          onClick={() => handleChangeType(selectedType)}
        >
          {t('attach')}
        </Button>
      ) : (
        <GenericTable>
          <TableActions>
            <div />
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              startIcon={<AddIcon />}
              className={classes.button}
              onClick={() => handleChangeType(selectedType)}
            >
              {t('attach')}
            </Button>
          </TableActions>
          <TableUi
            rows={dependencyWithActions}
            columns={columnsWithActions}
            disableCheckBox={true}
          />
        </GenericTable>
      )}
    </>
  );
};

export default DependencyTable;
