import React from 'react';

import { Grid } from '@mui/material';

import FilterItem from '../FilterItem';
import { IFiltersProps } from './Filters';

const Filters = ({ availableHappenings, switchFilter }: IFiltersProps) => {
  const onFilterChange = (happeningId: number, spaceId?: number) => {
    switchFilter(happeningId, spaceId);

    if (localStorage) {
      localStorage.setItem('filters', JSON.stringify(availableHappenings));
    }
  };

  return (
    <Grid container={true}>
      {availableHappenings.map((happening) => (
        <FilterItem
          happening={happening}
          key={happening.id}
          switchFilter={onFilterChange}
        />
      ))}
    </Grid>
  );
};

export default Filters;
