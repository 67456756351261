import React from 'react';

import { Typography } from '@mui/material';

import config from '@Config';
import { isElectron } from '@Misc/helpers/isElectron';

import { version } from '../../../../../package.json';
import useStyles from './Version.styles';

const Version = () => {
  const classes = useStyles();

  if (!isElectron()) {
    return null;
  }

  return (
    <Typography
      className={classes.version}
      variant={'caption'}
      color={'textSecondary'}
    >
      {config.cms.environment === 'develop'
        ? `${version} Wersja testowa`
        : version}
    </Typography>
  );
};

export default Version;
