import React from 'react';

import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';

import { TypographyVariant } from '@Constants/Variants/Typography';

import useStyles from './PermissionSegment.styles';
import { IPermissionSegmentProps } from './PermissionSegment.types';

const PermissionSegment = ({
  data,
  values,
  handleAddAccess,
  isLastElement,
}: IPermissionSegmentProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.container} container={true} spacing={2}>
        <Grid item={true} xs={12}>
          <Typography variant={TypographyVariant.body1}>
            {data.name}:
          </Typography>
        </Grid>
        <Grid item={true} xs={12}>
          <FormGroup role="group">
            {data.permissions.map((permission) => (
              <FormControlLabel
                key={permission.id}
                value={permission.id}
                onChange={() => handleAddAccess(permission.id)}
                name="access"
                control={
                  <Checkbox
                    checked={values.permissionsIds.includes(permission.id)}
                    name="access"
                    color="primary"
                    size="small"
                  />
                }
                label={
                  <Typography variant="caption" color="textPrimary">
                    {permission.name}
                  </Typography>
                }
              />
            ))}
          </FormGroup>
        </Grid>
      </Grid>
      {!isLastElement && <Divider className={classes.divider} />}
    </>
  );
};

export default PermissionSegment;
