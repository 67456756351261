import { ICategory, ICategoryFromValuesProps } from '@Model/products/types';

export const prepareInitialValues = (
  category: ICategory | null
): ICategoryFromValuesProps => {
  const defaultValues: ICategoryFromValuesProps = {
    color: '#FF6641',
    name: '',
  };

  if (category) {
    const { name, color, id } = category;

    return {
      ...defaultValues,
      color,
      id,
      name,
    };
  }

  return defaultValues;
};
