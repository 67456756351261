import makeStyles from '@mui/styles/makeStyles';

import colors from '@Compo/App/colors';

const useStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(3),
  },
  container: {
    marginBottom: theme.spacing(6),
  },
  icon: {
    color: colors.success.light,
    marginRight: theme.spacing(2),
  },
  link: {
    marginBottom: theme.spacing(1),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  successful: {
    marginBottom: theme.spacing(3),
  },
  summary: {
    marginBottom: theme.spacing(8),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
