export enum RefundStatus {
  init = 'init',
  accepted = 'accepted',
  rejectedEvent = 'rejectedEvent',
  rejectedTicket = 'rejectedTicket',
  toRefund = 'toRefund',
  clarify = 'clarify',
  manualRefund = 'manualRefund',
  refundDone = 'refundDone',
  voucherToGenerate = 'voucherToGenerate',
  voucherGenerated = 'voucherGenerated',
  refundCompleted = 'refundCompleted',
}

export interface IEnum {
  code: RefundStatus;
  name: string;
}

interface ICity {
  name: string;
}

interface IPlace {
  city?: ICity;
}

interface IEvent {
  titlePL: string;
  place?: IPlace;
}

interface IChangeMonitor {
  refundEnabled: boolean;
  refundMaxTime?: number;
  info?: string;
}

interface IRundate {
  rundate: string;
  event: IEvent;
  lastChangeMonitor?: IChangeMonitor;
}

interface IPool {
  rundate: IRundate;
}

interface ITransaction {
  id: number;
  purchaseTime: string;
}

interface ITransactionItem {
  id: number;
  amount: number;
  serviceFee: number;
  ticketsNum: number;
  pool: IPool;
  transaction: ITransaction;
  isSplit?: boolean;
  isUsedVoucher?: boolean;
}

interface ITicket {
  firstname: string;
  lastname: string;
  email: string;
  entryToken: string;
  validated: boolean;
  transactionItem?: ITransactionItem;
}

export interface IRefundsRegistration {
  id: number;
  initDate: string;
  refundType: IEnum;
  status: IEnum;
  ticket?: ITicket;
  isRefundAvailable: boolean;
  changeMonitor?: IChangeMonitor;
  supportInfo?: string;
  bankAccount?: string;
  payuStatus?: string;
}

export interface IChangeRefundsStatus {
  refundRegistrations: IRefundRegistrations[];
}

export interface IRefundRegistrations {
  id: number;
  status: string;
}

export interface IMeta {
  size: number;
  page: number;
  total: number;
}

export interface IRefundsRegistrationResponse {
  data: IRefundsRegistration[];
  meta: IMeta;
}

export interface IRefundsRegistrationMetadata {
  refundStatusTypes: IEnum[];
  refundTypes: IEnum[];
  refundMaxTimes: number[];
}

export interface IRefundsRegistrationFilters {
  search?: string;
  status: string[];
  refundType: string[];
  refundMaxTime: number[];
}

export type IRefundsRegistrationMetadataResponse = IRefundsRegistrationMetadata;
