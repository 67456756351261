import React, { useState } from 'react';

import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useScript from '@Misc/hooks/useScript';
import { TYPE_ERROR, TYPE_SUCCESS } from '@Model/toasts/constants/constants';

import {
  createUploadWidget,
  prepareInitialValues,
} from './CloudinaryUploadWidget.helpers';
import {
  ICloudinaryMyWidgetProps,
  ICloudinaryUploadWidgetProps,
} from './CloudinaryUploadWidget.types';

const CloudinaryUploadWidget = ({
  buttonText,
  setCloudinaryImagesUrl,
  assetType,
  assetFolderName,
  slug,
  addToast,
  disabled,
}: ICloudinaryUploadWidgetProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reusable.CloudinaryUploadWidget',
  });

  const [isCloudinaryUploadWidgetOpen, setCloudinaryUploadWidgetOpen] =
    useState<boolean>(false);

  const configMyWidget = prepareInitialValues(assetType, assetFolderName, slug);
  useScript('https://widget.cloudinary.com/v2.0/global/all.js');

  configMyWidget.onSuccess = ({ info }) => {
    setCloudinaryImagesUrl(info.url);

    addToast(t('successUpload'), TYPE_SUCCESS);
  };

  configMyWidget.onFailure = () => {
    addToast(t('failureUpload'), TYPE_ERROR);
  };

  const openCreateUploadWidget = (config: ICloudinaryMyWidgetProps) => {
    setCloudinaryUploadWidgetOpen(true);

    createUploadWidget(config, setCloudinaryUploadWidgetOpen);
  };

  return (
    <Button
      color="primary"
      size="small"
      onClick={() => openCreateUploadWidget(configMyWidget)}
      disabled={isCloudinaryUploadWidgetOpen || disabled}
    >
      {buttonText || t('defaultButton')}
    </Button>
  );
};

export default CloudinaryUploadWidget;
