import React from 'react';

import { View } from '@react-pdf/renderer';

import { ISeats } from '@Services/$seats-io-api/types';

import { SeatStatus } from '../../ReportSeatsIo.types';
import TableBody from './components/TableBody';
import TableCell from './components/TableCell';
import TableHead from './components/TableHead';
import TableRow from './components/TableRow';
import styles from './Table.styles';
import { ITableProps } from './Table.types';

const Table = ({ columns, rows, categoryReport, title }: ITableProps) => {
  const getRowClass = (row: ISeats) => {
    if (row.countSold > 1 && row.statusLabel !== SeatStatus.free) {
      return styles.duble;
    }
    if (row.status === 'booked') {
      return styles.booked;
    }
    if (row.statusLabel === SeatStatus.freeDisabled) {
      return styles.freeDisabled;
    }

    return undefined;
  };

  return (
    <View wrap={rows.length > 30 ? true : false}>
      <View style={styles.table}>
        <TableHead categoryReport={categoryReport} title={title} />
        <TableBody>
          <TableRow isHeader={true}>
            {columns.map((col) => (
              <TableCell isHeader={true} key={col.id}>
                {col.label}
              </TableCell>
            ))}
          </TableRow>
          {rows.map((row, index) => (
            <TableRow style={getRowClass(row)} key={index}>
              {columns.map((col, i) => (
                <TableCell key={`cell-${i}`}>{row[col.id]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </View>
    </View>
  );
};

export default Table;
