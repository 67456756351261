import { IMeta, IUser } from '@Services/$users-api/types';

export enum UserStatus {
  disable = 'disable',
  enable = 'enable',
}

export interface IUsersTableFromState {
  users: IUser[];
  meta: IMeta;
  searchText: string;
  isLoading: boolean;
}

export interface IUsersTableFromDispatch {
  addNewUser: () => void;
  changeUserStatus: (id: number, status: string) => void;
  edit: (id: number) => void;
  mounted: () => void;
  handleActivePage: (page: number) => void;
  handleChangeRowsPerPage: (size: number) => void;
  search: (text: string) => void;
}

export type IUsersTableProps = IUsersTableFromState & IUsersTableFromDispatch;
