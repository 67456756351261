// Create history
import { createBrowserHistory, createHashHistory, History } from 'history';

import { isElectron } from '@Misc/helpers/isElectron';

const history: History = isElectron()
  ? createHashHistory()
  : createBrowserHistory();

export default history;
