import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import mapInitialValues from './AddPriceTypeModal.helpers';
import {
  IAddPriceTypeInitialValues,
  IAddPriceTypeProps,
} from './AddPriceTypeModal.types';
import { AddPriceTypeSchema } from './AddPriceTypeModal.validation';
import FormLayout from './FormLayout';

const AddNewPriceTypeModal = ({
  savePriceType,
  selectedPriceType,
  selectPriceType,
}: IAddPriceTypeProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'PriceTypes.AddPriceTypeModal',
  });

  const catchHandleSubmit = (data: IAddPriceTypeInitialValues) => {
    savePriceType(data);
  };

  const closeModal = () => {
    selectPriceType(null);
  };

  const initialValues = mapInitialValues(selectedPriceType);

  return (
    <Dialog
      open={!!selectedPriceType}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={catchHandleSubmit}
        validationSchema={AddPriceTypeSchema}
        children={(childProps) => (
          <FormLayout {...childProps} closeModal={closeModal} />
        )}
      />
    </Dialog>
  );
};

export default AddNewPriceTypeModal;
