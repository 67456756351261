import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    paddingRight: theme.spacing(3),
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
    },
  },
  buttonFirst: {
    [theme.breakpoints.down('lg')]: {
      marginRight: theme.spacing(2),
    },
  },
  buttonWithoutPadding: {
    paddingRight: theme.spacing(1.25),
  },
  buttonsContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
    },
  },
  loader: {
    zIndex: 0,
  },
}));

export default useStyles;
