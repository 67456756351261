import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { save } from '@Model/mailing/actions';
import { getSelectedEventMail } from '@Model/mailing/selectors';
import _Store from '@Store';
import MailForm from './MailForm.component';
import { IMailFormFromDispatch, IMailFormFromState } from './MailForm.types';

const mapStateToProps = (state: _Store.IState): IMailFormFromState => ({
  initialValues: getSelectedEventMail(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IMailFormFromDispatch => ({
  save: (values) => dispatch(save(values)),
});

export default connect<
  IMailFormFromState,
  IMailFormFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(MailForm);
