import React, { useState } from 'react';

import ClickOutsideContainer from '@Compo/reusable/ClickOutsideContainer';
import getPartFromTimeString from '@Misc/helpers/getPartFromTimeString';
import { Form } from 'tabler-react';
import { ITimePickerProps } from './/TimePicker';
import Picker from './components/Picker';
import styles from './TimePicker.module.scss';

const TimePicker = ({
  disabled,
  feedback,
  invalid,
  label,
  name,
  onBlur,
  onChange,
  placeholder,
  timeInterval,
  value,
  className,
}: ITimePickerProps) => {
  let initialHour = '00';
  let initialMinute = '00';
  if (value && value.length > 0) {
    initialHour = getPartFromTimeString(value, 0);
    initialMinute = getPartFromTimeString(value, 1);
  }

  const [showPicker, setShowPicker] = useState(false);
  const currentTime = value ? `${initialHour}:${initialMinute}` : '';

  const handleShowPicker = () => {
    if (!disabled) {
      setShowPicker(!showPicker);
    }
  };

  const handleTimeChange = (time: string) => {
    if (!disabled) {
      onChange(name, time);
    }
  };

  const handleClickOutside = () => {
    if (!disabled) {
      setShowPicker(false);
    }
  };

  return (
    <ClickOutsideContainer
      className={styles.container}
      onClickOutside={handleClickOutside}
    >
      <Form.Group className={className || styles.group} label={label}>
        <span onClick={handleShowPicker} role="button">
          <Form.Input
            disabled={disabled}
            feedback={feedback}
            invalid={invalid}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            value={currentTime}
          />
        </span>
      </Form.Group>

      {showPicker && (
        <Picker
          initialHour={parseInt(initialHour, 10)}
          initialMinute={parseInt(initialMinute, 10)}
          minutesInterval={timeInterval}
          onTimeChanged={handleTimeChange}
        />
      )}
    </ClickOutsideContainer>
  );
};

export default TimePicker;
