import { IHappeningReducer } from '@Model/happening/types';
import _Store from '@Store';
import { createSelector } from 'reselect';
import getHappening from './getHappening';

const getHappeningMaxNumberOfPeople = createSelector<
  _Store.IState,
  IHappeningReducer,
  number
>([getHappening], (happening) => {
  if (happening) {
    return happening.maxNumberOfPeople || 0;
  }

  return 0;
});

export default getHappeningMaxNumberOfPeople;
