import { createSelector } from 'reselect';

import { IEventDiscount } from '@Model/discounts/types';
import _Store from '@Store';
import { IDiscountsReducer } from '../types';
import get from './get';

const getEventsDiscounts = createSelector<
  _Store.IState,
  IDiscountsReducer,
  IEventDiscount[]
>([get], (discounts) => discounts.eventsDiscounts);

export default getEventsDiscounts;
