import { Selector } from 'reselect';

import _Store from '@Store';

import { ISingleUser } from '@Model/formio/types/index';

const getFormIoAvailableUsers: Selector<_Store.IState, ISingleUser[]> = (
  state
) => state.formIo.availableUsers.data;

export default getFormIoAvailableUsers;
