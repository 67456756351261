import { object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';

const configuration = {
  entryTokens: string().required(REQUIRED_VALIDATOR_TEXT),
  name: string().required(REQUIRED_VALIDATOR_TEXT),
};

export const ImportListSchema = object().shape(configuration);
