import _Store from '@Store';
import { createSelector } from 'reselect';
import { IAuthorizationReducer, IUserInfo } from '../types';
import get from './get';

const getUserInfo = createSelector<
  _Store.IState,
  IAuthorizationReducer,
  IUserInfo
>(
  get,
  (add) => add.userInfo,
);

export default getUserInfo;
