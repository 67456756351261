import { createSelector } from 'reselect';

import _Store from '@Store';

import { IUsersReducer } from '../types';
import get from './get';

const getSearchTextUsers = createSelector<_Store.IState, IUsersReducer, string>(
  [get],
  (users) => users.search.users
);

export default getSearchTextUsers;
