import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IFilter, IReport } from '@Compo/Reports/Reports.types';
import {
  _CAPTURE_REPORT_FAILURE,
  _CAPTURE_REPORT_REQUEST,
  _CAPTURE_REPORT_SUCCESS,
  ADD_NEW_ACTIVE_INPUT_TO_GROUP,
  CATCH_SELECTED_KEYS,
  GET_REPORT,
  HANDLE_ACTIVE_PAGE,
  SET_AVAILABLE_REPORTS,
  SET_COUNTS,
  SET_FILTERS,
  SET_REPORT_KEYS,
  SET_REPORT_TYPE,
  SET_SELECTED_KEYS,
  SET_TOTAL_COUNT,
} from '../constants/actions';
import { IReportRequest, ISetAvailableReports } from '../types';

export const captureReport = createAsyncAction(
  _CAPTURE_REPORT_REQUEST,
  _CAPTURE_REPORT_SUCCESS,
  _CAPTURE_REPORT_FAILURE,
)<undefined, IReportRequest[], Error>();

export const handleActivePage = createStandardAction(HANDLE_ACTIVE_PAGE)<
  number
>();
export const setFilters = createStandardAction(SET_FILTERS)<IFilter[]>();
export const setAvailableReports = createStandardAction(SET_AVAILABLE_REPORTS)<
  IReport[]
>();
export const setTotalCount = createStandardAction(SET_TOTAL_COUNT)<number>();
export const setReportType = createStandardAction(SET_REPORT_TYPE)<IReport>();
export const setCounts = createStandardAction(SET_COUNTS)<IReportRequest>();
export const getReport = createStandardAction(GET_REPORT)();
export const setReportKeys = createStandardAction(SET_REPORT_KEYS)<string[]>();
export const catchSelectedKeys = createStandardAction(CATCH_SELECTED_KEYS)<
  string
>();
export const addNewActiveInputToGroup = createStandardAction(
  ADD_NEW_ACTIVE_INPUT_TO_GROUP,
)<ISetAvailableReports>();
export const setSelectedKeys = createStandardAction(SET_SELECTED_KEYS)<
  string[]
>();
