import _Store from '@Store';
import { createSelector } from 'reselect';
import { IPriceReducer } from './../types';
import get from './get';

const getTotalPrice = createSelector<
  _Store.IState,
  IPriceReducer,
  number | null
>([get], (price) => price && price.totalPrice);

export default getTotalPrice;
