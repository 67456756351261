import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  keyboardButton: {
    bottom: '1.5rem',
    position: 'fixed',
    right: '1.5rem',
    zIndex: 999999,
  },
  keyboardIcon: {
    fontSize: 50,
  },
}));

export default useStyles;
