import React, { ChangeEvent, Component } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@mui/material';
import { withTranslation } from 'react-i18next';

import styles from './AddressUrl.module.scss';
import { IAddressUrlProps, IAddressUrlState } from './AddressUrl.types';

class AdressUrl extends Component<IAddressUrlProps, IAddressUrlState> {
  protected translationPrefix: string;
  private img = new Image();

  constructor(props: IAddressUrlProps) {
    super(props);

    this.translationPrefix =
      'components:BetterManager.ImagesLibrary.components.AddressUrl.';

    this.state = {
      url: props.url || null,
    };

    this.change = this.change.bind(this);
    this.onError = this.onError.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.save = this.save.bind(this);

    this.img.onload = this.onLoad;
    this.img.onerror = this.onError;
  }

  public render() {
    const { url } = this.state;
    const { close } = this.props;

    return (
      <>
        <DialogContent>
          <TextField
            variant="standard"
            id="title"
            label={this.props.t(`${this.translationPrefix}imageUrl`)}
            helperText={this.props.t(`${this.translationPrefix}imageUrlHelper`)}
            onChange={this.change}
            value={this.state.url || ''}
            name="title"
            fullWidth={true}
          />

          <div className={styles.preview}>
            <Typography variant="caption">
              {this.props.t(`${this.translationPrefix}preview`)}
            </Typography>

            <div className={styles.image}>
              {url && <img src={url} />}

              {!url && (
                <Typography variant="body1">
                  {this.props.t(`${this.translationPrefix}loadingImage`)}
                  <br />
                  <br />
                  {this.props.t(`${this.translationPrefix}correctImage`)}
                </Typography>
              )}
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            size="large"
            color="secondary"
            onClick={close}
          >
            {this.props.t(`${this.translationPrefix}cancel`)}
          </Button>
          <Button
            variant="contained"
            size="large"
            color="primary"
            onClick={this.save}
          >
            {this.props.t(`${this.translationPrefix}choseImage`)}
          </Button>
        </DialogActions>
      </>
    );
  }

  private save() {
    this.props.setUrl(this.state.url);
  }

  private change(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;

    this.img.src = value;
  }

  private onLoad() {
    this.setState({
      url: this.img.src,
    });
  }

  private onError() {
    this.setState({
      url: null,
    });
  }
}

export default withTranslation()(AdressUrl);
