import React, { useEffect } from 'react';

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';

import { IFormLayoutProps } from './../../FormLayout.types';

const PoolSwitch = ({
  touched,
  errors,
  values,
  handleBlur,
  handleChange,
  pools,
  setFieldValue,
  selectedPool,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates.Pools.components.FormLayout.components.PoolSwitch',
  });

  const filteredPools = pools.filter((pool) => pool.id !== selectedPool?.id);

  useEffect(() => {
    if (values.isActive) {
      setFieldValue('parentId', -1);
    }
  }, [values.isActive]);

  return (
    <Grid item={true} xs={12}>
      <LabelContentContainer>
        <FormControl
          variant="standard"
          fullWidth={true}
          error={!!(touched.parentId && errors.parentId)}
          disabled={values.isActive}
        >
          <InputLabel>
            {(touched.parentId && errors.parentId) || t('poolSwitch')}
          </InputLabel>
          <Select
            variant="standard"
            value={values.parentId}
            onChange={handleChange}
            defaultValue={-1}
            fullWidth={true}
            name="parentId"
            onBlur={handleBlur}
          >
            {/* TODO: translate */}
            <MenuItem value={-1}>Brak</MenuItem>
            {filteredPools.map((pool) => (
              <MenuItem key={pool.id} value={pool.id}>
                {pool.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{t('poolSwitchHelper')}</FormHelperText>
        </FormControl>
      </LabelContentContainer>
    </Grid>
  );
};

export default PoolSwitch;
