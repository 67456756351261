import React, { FC } from 'react';

import useStyles from './TableActions.styles';

const TableActions: FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

export default TableActions;
