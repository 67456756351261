const selectColors = [
  { color: '#E0E0E0', name: 'Szary' },
  { color: '#996520', name: 'Brązowy' },
  { color: '#55CC55', name: 'Zielony' },
  { color: '#E31B0C', name: 'Czerwony' },
  { color: '#FFB547', name: 'Żółty' },
  { color: '#109B95', name: 'Morski' },
  { color: '#885989', name: 'Fioletowy' },
  { color: '#6087EF', name: 'Jasno niebieski' },
  { color: '#C5C6FE', name: 'Jasno fioletowy' },
];

export const getColorNameByHex = (hex: string) => {
  return selectColors.find((color) => color.color === hex)?.name || '-';
};

export default selectColors;
