import { number, object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';

const configuration = {
  agentId: number().required(REQUIRED_VALIDATOR_TEXT),
  eventId: number().when('filterType', {
    is: 'event',
    then: number().required(REQUIRED_VALIDATOR_TEXT),
  }),
  productId: number().when('filterType', {
    is: 'product',
    then: number().required(REQUIRED_VALIDATOR_TEXT),
  }),
  salesChannelId: number().required(REQUIRED_VALIDATOR_TEXT),
  type: string().required(REQUIRED_VALIDATOR_TEXT),
};

export const AddingReportFilterSchema = object().shape(configuration);
