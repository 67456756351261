import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IAddPoolDescriptionModalValues } from '@Compo/PoolDescriptions/components/AddPoolDescriptionModal/AddPoolDescriptionModal.types';
import {
  IPoolDescriptionResponse,
  ISinglePoolDescriptionResponse,
} from '@Services/$pool-descriptions-api/types';

import {
  _GET_POOL_DESCRIPTIONS_FAILURE,
  _GET_POOL_DESCRIPTIONS_REQUEST,
  _GET_POOL_DESCRIPTIONS_SUCCESS,
  _GET_SINGLE_POOL_DESCRIPTIONS_FAILURE,
  _GET_SINGLE_POOL_DESCRIPTIONS_REQUEST,
  _GET_SINGLE_POOL_DESCRIPTIONS_SUCCESS,
  _SAVE_POOL_DESCRIPTIONS_FAILURE,
  _SAVE_POOL_DESCRIPTIONS_REQUEST,
  _SAVE_POOL_DESCRIPTIONS_SUCCESS,
  CATCH_GET_SINGLE_POOL_DESCRIPTION,
  CATCH_SAVE_POOL_DESCRIPTION,
  HANDLE_ACTIVE_PAGE,
  HANDLE_CHANGE_ROWS_PER_PAGE,
  HANDLE_SEARCH,
  MOUNTED,
  SET_POOL_DESCRIPTION,
} from './../constants/actions';

export const mounted = createStandardAction(MOUNTED)();
export const handleActivePage =
  createStandardAction(HANDLE_ACTIVE_PAGE)<number>();
export const handleChangeRowsPerPage = createStandardAction(
  HANDLE_CHANGE_ROWS_PER_PAGE
)<number>();
export const handleSearch = createStandardAction(HANDLE_SEARCH)<string>();

export const setPoolDescription = createStandardAction(
  SET_POOL_DESCRIPTION
)<IAddPoolDescriptionModalValues | null>();

export const catchSavePoolDescription = createStandardAction(
  CATCH_SAVE_POOL_DESCRIPTION
)<IAddPoolDescriptionModalValues>();

export const catchGetSinglePoolDescription = createStandardAction(
  CATCH_GET_SINGLE_POOL_DESCRIPTION
)<number>();

export const getPoolDescriptions = createAsyncAction(
  _GET_POOL_DESCRIPTIONS_REQUEST,
  _GET_POOL_DESCRIPTIONS_SUCCESS,
  _GET_POOL_DESCRIPTIONS_FAILURE
)<undefined, IPoolDescriptionResponse, Error>();

export const getSinglePoolDescription = createAsyncAction(
  _GET_SINGLE_POOL_DESCRIPTIONS_REQUEST,
  _GET_SINGLE_POOL_DESCRIPTIONS_SUCCESS,
  _GET_SINGLE_POOL_DESCRIPTIONS_FAILURE
)<number, ISinglePoolDescriptionResponse, Error>();

export const savePoolDescription = createAsyncAction(
  _SAVE_POOL_DESCRIPTIONS_REQUEST,
  _SAVE_POOL_DESCRIPTIONS_SUCCESS,
  _SAVE_POOL_DESCRIPTIONS_FAILURE
)<IAddPoolDescriptionModalValues, [], Error>();
