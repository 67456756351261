import routes from '@/routes/routes';

class LinksProvider {
  public buildSummaryFailLink(transactionId: string): string {
    const paymentFailRoute = routes.summaryFail.split('/');

    return `/${paymentFailRoute[1]}/${transactionId}/${paymentFailRoute[3]}`;
  }

  public buildSummaryCanceledLink(transactionId: string): string {
    const paymentCanceledRoute = routes.summaryCanceled.split('/');

    return `/${paymentCanceledRoute[1]}/${transactionId}/${paymentCanceledRoute[3]}`;
  }

  public buildSummaryLink(transactionId: string): string {
    const summaryRoute = routes.summarySuccess.split('/');

    return `${summaryRoute[1]}/${transactionId}`;
  }

  public buildCalendalPopUpLink(reservationId: string): string {
    const calendarRoute = routes.calendarPopUp.split('/');

    return `${calendarRoute[1]}/${reservationId}`;
  }

  public compareCalendarPopUpLinkWithCalendarLink(
    calendarPopUpPathName: string,
  ): boolean {
    if (!calendarPopUpPathName) {
      return false;
    }

    const calendarRoute = calendarPopUpPathName.split('/');

    return routes.calendar === `/${calendarRoute[1]}`;
  }
}

export default new LinksProvider();
