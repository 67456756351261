import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { deleteSeatChart, getSeatsCharts } from '@Model/seatsIo/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { resetError, resetLoading, setLoading } from '../actions';
import { SEATS_IO_CHARTS } from '../constants/constants';

export const setUpStateWhenSeatsIoChartsFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([getSeatsCharts.request])),
    mergeMap$(() => {
      return [resetError(SEATS_IO_CHARTS), setLoading(SEATS_IO_CHARTS)];
    })
  );
};

export const setLoaderWhenSeatsIoChartsFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getSeatsCharts.success, getSeatsCharts.failure], action)
    ),
    map$(() => resetLoading(SEATS_IO_CHARTS))
  );
};

export const setErrorOnSeatsIoChartsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getSeatsCharts.failure, deleteSeatChart.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
