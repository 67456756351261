import { createSelector } from 'reselect';

import _Store from '@Store';

import { IMeta } from '@Services/$users-api/types';

import { IUsersReducer } from '../types';
import get from './get';

const getMetaGroups = createSelector<_Store.IState, IUsersReducer, IMeta>(
  [get],
  (users) => users.meta.groups
);

export default getMetaGroups;
