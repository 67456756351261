import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import format from 'date-fns/format';
import FileDownload from 'js-file-download';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import {
  IBook,
  ICalculatedPriceByPersonResponse,
  IEmptyReservationSaveRequestPayload,
  IReservationPrintData,
  ITransactionEmpikResposne,
} from '@Model/reservation/types';
import {
  IDelayedTransactionsSuccessPayload,
  TTransactionType,
} from '@Model/reservations/types';
import {
  IAdvancePaymentBody,
  ICompanyInfoResponse,
  IGroupedReservationsResponse,
  INormalizedReservation,
  IReservationDateCheckResponse,
  IReservationFullResponse,
  IReservationPriceRequest,
  IReservationPriceResponse,
  IReservationSaveBody,
  IReservationsResponse,
  IReservationsResponseItem,
  IReservationUpdateBody,
} from '@Services/$reservations-api/types';

class ReservationsApi {
  private static checkReservationDateUrl(reservationId: number) {
    if (config.cms.showNewRules) {
      return `${config.api.baseUrlV2}admin/reservations/${reservationId}/check`;
    }
    return `${config.api.baseUrl}admin/reservations/${reservationId}/check`;
  }

  private static getReservationsUrl(
    happeningSlug: string,
    spaceSlug: string
  ): string {
    return `${config.api.baseUrl}admin/happenings/${happeningSlug}/${spaceSlug}/reservations`;
  }

  private static getGroupedReservationsUrl(
    happeningSlug: string,
    spaceSlug: string
  ) {
    return `${config.api.baseUrl}admin/happenings/${happeningSlug}/${spaceSlug}/reservations-groups`;
  }

  private static getReservationDetailsUrl(reservationId: number): string {
    return `${config.api.baseUrl}admin/reservations/${reservationId}`;
  }

  private static getReservationDetailsPutUrl(reservationId: number): string {
    if (config.cms.showNewRules) {
      return `${config.api.baseUrlV2}admin/reservations/${reservationId}`;
    }
    return `${config.api.baseUrl}admin/reservations/${reservationId}`;
  }

  private static getPriceCheckUrl(): string {
    return `${config.api.baseUrl}price`;
  }

  private static getTransactionEmpikDetailsUrl(id: string): string {
    return `${
      config.api.baseEmpikTicketUrl
    }transaction-details/${id.toLowerCase()}`;
  }
  private static getTransactionPrintDetailsUrl(uuid: string): string {
    return `${config.api.baseEmpikTicketUrl}transaction-item-details/${uuid}`;
  }

  private static getTransactionItemsCalculatedByPersonUrl(
    uuid: string
  ): string {
    return `${config.api.baseUrl}transactions/${uuid}`;
  }

  private static getResentTicketUrl(id: string): string {
    return `${config.api.baseUrl}admin/reservations/${id}/ticket-resend`;
  }

  private static getGetTicketUrl(id: string): string {
    return `${config.api.baseUrl}admin/reservations/${id}/ticket`;
  }

  private static getTransactionCancelUrl(id: string): string {
    return `${config.api.baseUrl}admin/reservations/${id.toLowerCase()}/cancel`;
  }

  private static getTransactionCancelUrlAutoReturn(id: string): string {
    return `${
      config.api.baseUrl
    }admin/reservations/${id.toLowerCase()}/cancel?autoreturn=true`;
  }

  private static getSaveBillUrl(id: string): string {
    return `${
      config.api.baseEmpikTicketUrl
    }transaction-bill/${id.toLowerCase()}`;
  }
  private static getCompanyDataUrl(nip: string): string {
    return `${config.api.baseInvoiceUrl}company-info/${nip}`;
  }

  private static getSaveReservationUrl(): string {
    return `${config.api.baseEmpikTicketUrl}transaction`;
  }
  private static getSaveReservationAdvanceUrl(uuid: string): string {
    return `${config.api.baseEmpikTicketUrl}transaction-payment-advance/${uuid}`;
  }
  private static getEmptyReservationUrl(): string {
    if (config.cms.showNewRules) {
      return `${config.api.baseUrlV2}admin/reserve`;
    }
    return `${config.api.baseUrl}admin/reserve`;
  }

  private static getDelayedTransactionUrl(
    page: number,
    perPage: number
  ): string {
    return `${config.api.cmsServices}transactions?count=${perPage}&page=${page}`;
  }
  private static cancelDelayedTransactionUrl(transactionUuid: string): string {
    return `${config.api.baseEmpikTicketUrl}transaction-status/${transactionUuid}/expire-delayed`;
  }

  private cancelTokenCheck?: CancelTokenSource;
  private cancelTokenReservations?: CancelTokenSource;
  private cancelTokenReservationDetails?: CancelTokenSource;
  private cancelTokenReservationUpdate?: CancelTokenSource;
  private cancelTokenReservationSave?: CancelTokenSource;
  private cancelTokenTransaction?: CancelTokenSource;

  public getTransactionEmpikDetails(
    id: string
  ): Promise<IReservationPrintData> {
    return new Promise<IReservationPrintData>((resolve, reject) => {
      this.cancelTokenTransaction = axios.CancelToken.source();
      return axios
        .get(ReservationsApi.getTransactionEmpikDetailsUrl(id))
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelTransaction(id: string) {
    return new Promise<void>((resolve, reject) => {
      this.cancelTokenTransaction = axios.CancelToken.source();

      return axios
        .post(ReservationsApi.getTransactionCancelUrl(id))
        .then(getData)
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getTransactionPrintDetails(
    uuid: string
  ): Promise<IReservationPrintData> {
    return new Promise<IReservationPrintData>((resolve, reject) => {
      this.cancelTokenTransaction = axios.CancelToken.source();

      return axios
        .get(ReservationsApi.getTransactionPrintDetailsUrl(uuid))
        .then(getData)
        .then((response: IReservationPrintData) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getTransactionItemsCalculatedByPerson(
    uuid: string
  ): Promise<ICalculatedPriceByPersonResponse> {
    return new Promise<ICalculatedPriceByPersonResponse>((resolve, reject) => {
      this.cancelTokenTransaction = axios.CancelToken.source();

      return axios
        .get(ReservationsApi.getTransactionItemsCalculatedByPersonUrl(uuid))
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public resentTicket(id: string): Promise<IReservationPrintData> {
    return new Promise<IReservationPrintData>((resolve, reject) => {
      this.cancelTokenTransaction = axios.CancelToken.source();

      return axios
        .post(ReservationsApi.getResentTicketUrl(id))
        .then(getData)
        .then((response: IReservationPrintData) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }
  public getTicket(id: string): Promise<IReservationPrintData> {
    return new Promise<IReservationPrintData>((resolve, reject) => {
      this.cancelTokenTransaction = axios.CancelToken.source();

      return axios
        .get(ReservationsApi.getGetTicketUrl(id), {
          responseType: 'blob',
        })
        .then((response) => {
          FileDownload(response.data, 'ticket.pdf');
          return response;
        })
        .then(getData)
        .then((response: IReservationPrintData) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelAutoTransaction(id: string) {
    const body = {
      autoreturn: true,
    };

    return new Promise<void>((resolve, reject) => {
      this.cancelTokenTransaction = axios.CancelToken.source();

      return axios
        .post(ReservationsApi.getTransactionCancelUrlAutoReturn(id), body)
        .then(getData)
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public checkReservationDate(
    reservationId: number,
    date: Date
  ): Promise<IReservationDateCheckResponse> {
    return new Promise<IReservationDateCheckResponse>((resolve, reject) => {
      axios
        .get(
          ReservationsApi.checkReservationDateUrl(reservationId),
          this.getCheckConfig(date)
        )
        .then(getData)
        .then((data: IReservationDateCheckResponse) => {
          resolve(data);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getReservations(
    happeningSlug: string,
    spaceSlug: string,
    startDate: string,
    endDate: string,
    duration: number
  ): Promise<INormalizedReservation[]> {
    return new Promise<INormalizedReservation[]>((resolve, reject) => {
      axios
        .get(
          ReservationsApi.getReservationsUrl(happeningSlug, spaceSlug),
          this.getReservationsConfig(startDate, endDate, duration)
        )
        .then(getData)
        .then((data: IReservationsResponse) => {
          resolve(data.items.map((item) => this.normalizeReservation(item)));
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getReservationDetails(
    reservationId: number
  ): Promise<IReservationFullResponse> {
    return new Promise<IReservationFullResponse>((resolve, reject) => {
      this.cancelTokenReservationDetails = axios.CancelToken.source();

      axios
        .get(ReservationsApi.getReservationDetailsUrl(reservationId), {
          cancelToken: this.cancelTokenReservationDetails.token,
        })
        .then(getData)
        .then((data: IReservationFullResponse) => {
          resolve(data);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public updateReservationDetails(
    reservationId: number,
    body: IReservationUpdateBody
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.cancelTokenReservationUpdate = axios.CancelToken.source();

      axios
        .put(ReservationsApi.getReservationDetailsPutUrl(reservationId), body, {
          cancelToken: this.cancelTokenReservationUpdate.token,
        })
        .then(getData)
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public saveNewReservation(body: IReservationSaveBody): Promise<IBook> {
    return new Promise<IBook>((resolve, reject) => {
      this.cancelTokenReservationSave = axios.CancelToken.source();

      axios
        .post(ReservationsApi.getSaveReservationUrl(), body, {
          cancelToken: this.cancelTokenReservationSave.token,
        })
        .then(getData)
        .then((data) => {
          if (data.status === 1 && data.errors && data.errors.length) {
            const { message } = data.errors[0];

            reject(catchHttpError({ message }));
          }
          return data;
        })
        .then((action) => {
          resolve(action);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public saveAdvancePayment(
    body: IAdvancePaymentBody,
    uuid: string
  ): Promise<IBook> {
    return new Promise<IBook>((resolve, reject) => {
      this.cancelTokenReservationSave = axios.CancelToken.source();

      axios
        .post(ReservationsApi.getSaveReservationAdvanceUrl(uuid), body, {
          cancelToken: this.cancelTokenReservationSave.token,
        })
        .then(getData)
        .then((data) => {
          if (data.status === 1 && data.errors && data.errors.length) {
            const { message } = data.errors[0];

            reject(catchHttpError({ message }));
          }
          return data;
        })
        .then((action) => {
          resolve(action);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public saveEmptyReservation(
    body: IEmptyReservationSaveRequestPayload
  ): Promise<IBook> {
    return new Promise<IBook>((resolve, reject) => {
      this.cancelTokenReservationSave = axios.CancelToken.source();

      axios
        .post(ReservationsApi.getEmptyReservationUrl(), body, {
          cancelToken: this.cancelTokenReservationSave.token,
        })
        .then(getData)
        .then((data) => {
          if (data.status === 1 && data.errors && data.errors.length) {
            const { message } = data.errors[0];

            reject(catchHttpError({ message }));
          }
          return data;
        })
        .then((action) => {
          resolve(action);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getGroupedReservations(
    happeningSlug: string,
    spaceSlug: string,
    startDate: string,
    endDate: string
  ): Promise<IGroupedReservationsResponse> {
    return new Promise<IGroupedReservationsResponse>((resolve, reject) => {
      axios
        .get(
          ReservationsApi.getGroupedReservationsUrl(happeningSlug, spaceSlug),
          this.getReservationsConfig(startDate, endDate)
        )
        .then(getData)
        .then((data: IGroupedReservationsResponse) => {
          resolve(data);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public normalizeReservation(
    response: IReservationsResponseItem
  ): INormalizedReservation {
    return {
      duration: response.duration,
      end: response.end,
      id: response.id,
      linkedTo: response.linkedTo,
      numberOfPeople: response.numberOfPeople,
      start: response.start,
      title: `${response.firstName} ${response.lastName}`,
    };
  }

  public normalizeTransaction(response: ITransactionEmpikResposne): any {
    return {
      ...response,
      details: {
        firstName: response.user.userFirstName,
        lastName: response.user.userLastName,

        happening: {
          metadata: [
            {
              description: '',
              id: 0,
              language: '',
              slug: '',
              title: '',
            },
          ],
        },
        space: {
          metadata: [
            {
              description: '',
              id: 0,
              language: '',
              slug: '',
              title: '',
            },
          ],
        },
      },

      bookingIdentifier: '',
      end: '',
      lockCode: '',
      start: '',
    };
  }

  public cancelCheck() {
    if (this.cancelTokenCheck) {
      this.cancelTokenCheck.cancel();
      this.cancelTokenCheck = undefined;
    }
  }

  public cancelReservations() {
    if (this.cancelTokenReservations) {
      this.cancelTokenReservations.cancel();
      this.cancelTokenReservations = undefined;
    }
  }

  public cancelReservationDetails() {
    if (this.cancelTokenReservationDetails) {
      this.cancelTokenReservationDetails.cancel();
      this.cancelTokenReservationDetails = undefined;
    }
  }

  public cancelReservationUpdate() {
    if (this.cancelTokenReservationUpdate) {
      this.cancelTokenReservationUpdate.cancel();
      this.cancelTokenReservationUpdate = undefined;
    }
  }

  public cancelReservationSave() {
    if (this.cancelTokenReservationSave) {
      this.cancelTokenReservationSave.cancel();
      this.cancelTokenReservationSave = undefined;
    }
  }

  public checkPrice(
    payload: IReservationPriceRequest
  ): Promise<IReservationPriceResponse> {
    return new Promise<IReservationPriceResponse>((resolve, reject) => {
      axios
        .post(ReservationsApi.getPriceCheckUrl(), payload)
        .then(getData)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public saveBill(idTransaction: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = ReservationsApi.getSaveBillUrl(idTransaction);
      resolve();
      axios
        .post(url)
        .then(() => {
          resolve();
        })
        .catch((error) => reject(error));
    });
  }

  public getCompanyData(nip: string): Promise<ICompanyInfoResponse> {
    return new Promise<ICompanyInfoResponse>((resolve, reject) => {
      const instance = axios.create({});
      delete instance.defaults.headers.common.Authorization;

      return instance
        .get(ReservationsApi.getCompanyDataUrl(nip))
        .then(getData)
        .then((response: ICompanyInfoResponse) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getDelayedTransactions(
    page: number,
    perPage: number,
    type: TTransactionType,
    partnerId?: string
  ): Promise<IDelayedTransactionsSuccessPayload> {
    return new Promise<IDelayedTransactionsSuccessPayload>(
      (resolve, reject) => {
        return axios
          .get(ReservationsApi.getDelayedTransactionUrl(page, perPage), {
            params: {
              [type]: true,
              partnerIds: [partnerId],
            },
          })
          .then(getData)
          .then((response: IDelayedTransactionsSuccessPayload) => {
            resolve(response);
          })
          .catch((error) => reject(catchHttpError(error)));
      }
    );
  }

  public cancelDelayedTransaction(
    uuid: string
  ): Promise<IDelayedTransactionsSuccessPayload> {
    return new Promise<IDelayedTransactionsSuccessPayload>(
      (resolve, reject) => {
        return axios
          .post(ReservationsApi.cancelDelayedTransactionUrl(uuid))
          .then(getData)
          .then((response: IDelayedTransactionsSuccessPayload) => {
            resolve(response);
          })
          .catch((error) => reject(catchHttpError(error)));
      }
    );
  }

  private getCheckConfig(date: Date): AxiosRequestConfig {
    this.cancelTokenCheck = axios.CancelToken.source();

    return {
      cancelToken: this.cancelTokenCheck.token,
      params: {
        date: format(date, 'yyyy-MM-dd'),
        time: format(date, 'HH:mm:ss'),
      },
    };
  }

  private getReservationsConfig(
    startDate: string,
    endDate: string,
    duration?: number
  ): AxiosRequestConfig {
    this.cancelTokenReservations = axios.CancelToken.source();

    return {
      cancelToken: this.cancelTokenReservations.token,
      params: {
        dateFrom: startDate,
        dateTo: endDate,
        duration,
      },
    };
  }
}

export default new ReservationsApi();
