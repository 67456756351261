import { IReservationCheckReducer } from '@Model/reservations/types';
import _Store from '@Store';
import { createSelector } from 'reselect';
import getCheck from './getCheck';

const isSelectedDateAvailable = createSelector<
  _Store.IState,
  IReservationCheckReducer,
  boolean
>(
  getCheck,
  (check) => check.availability === null,
);

export default isSelectedDateAvailable;
