import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = ({
  handleSubmit,
  submitForm,
  values,
  touched,
  errors,
  handleChange,
  closeModal,
  permissionPrintersWrite,
  partners,
  handleBlur,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'PrinterAdmin.components.FormLayout',
  });
  const classes = useStyles();

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          <TextField
            variant="standard"
            id="printerId"
            label={(touched.printerId && errors.printerId) || t('printerId')}
            helperText={t('printerIdHelper')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.printerId || ''}
            name="printerId"
            error={!!(touched.printerId && errors.printerId)}
            fullWidth={true}
          />
          <TextField
            variant="standard"
            id="saleUrl"
            label={(touched.saleUrl && errors.saleUrl) || t('saleUrl')}
            helperText={t('saleUrlHelper')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.saleUrl || ''}
            name="saleUrl"
            error={!!(touched.saleUrl && errors.saleUrl)}
            className={classes.input}
            fullWidth={true}
          />
          <FormControl
            variant="standard"
            fullWidth={true}
            className={classes.input}
          >
            <InputLabel>
              {(touched.firmware && errors.firmware) || t('firmware')}
            </InputLabel>
            <Select
              name="firmware"
              value={values.firmware}
              onChange={handleChange}
              onBlur={handleBlur}
              defaultValue={'-1'}
              error={!!(touched.firmware && errors.firmware)}
              disabled={!permissionPrintersWrite}
            >
              <MenuItem value={'-1'}>-</MenuItem>
              <MenuItem value={'1.0.0'}>1.0.0</MenuItem>
              <MenuItem value={'1.0.1'}>1.0.1</MenuItem>
              <MenuItem value={'2.0.0'}>2.0.0</MenuItem>
              <MenuItem value={'2.0.1'}>2.0.1</MenuItem>
            </Select>
            <FormHelperText>{t('firmwareHelper')}</FormHelperText>
          </FormControl>
          <FormControl
            variant="standard"
            fullWidth={true}
            className={classes.input}
          >
            <InputLabel>
              {(touched.partnerId && errors.partnerId) || t('partner')}
            </InputLabel>
            <Select
              name="partnerId"
              value={values.partnerId}
              onChange={handleChange}
              onBlur={handleBlur}
              defaultValue={-1}
              error={!!(touched.partnerId && errors.partnerId)}
              disabled={!permissionPrintersWrite}
            >
              <MenuItem value={-1}>-</MenuItem>

              {partners &&
                partners.map((partner) => (
                  <MenuItem key={partner.id} value={partner.id}>
                    {partner.name || ''}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl variant="standard" fullWidth={true}>
            <FormControlLabel
              className={cn(classes.input, classes.switch)}
              disabled={!permissionPrintersWrite}
              control={
                <Switch
                  checked={values.drawer}
                  onChange={handleChange}
                  name="drawer"
                  color="primary"
                  size="small"
                />
              }
              label={
                <Typography variant="body1">{t('containDrawer')}</Typography>
              }
            />
          </FormControl>
          <FormControl variant="standard" fullWidth={true}>
            <FormControlLabel
              className={cn(classes.input, classes.switch)}
              disabled={!permissionPrintersWrite}
              control={
                <Switch
                  checked={values.reservationDetails}
                  onChange={handleChange}
                  name="reservationDetails"
                  color="primary"
                  size="small"
                />
              }
              label={
                <>
                  <Typography variant="body1">
                    {t('additionalInfoTitle')}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {t('additionalInfoDescription')}
                  </Typography>
                </>
              }
            />
          </FormControl>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={() => closeModal()}
        >
          {t('cancel')}
        </Button>
        {permissionPrintersWrite && (
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            onClick={() => {
              submitForm();
              closeModal();
            }}
          >
            {t('save')}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default FormLayout;
