import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import axios, { CancelTokenSource } from 'axios';
import {
  IPriceBody,
  IPriceCheckBody,
  IPriceCheckResponse,
  IPriceResponse,
} from './types';

class PriceApi {
  private static getUrl() {
    return `${config.api.baseUrlV2}price`;
  }

  private static getCheckPriceUrl() {
    return `${config.api.baseEmpikTicketUrl}price`;
  }

  private cancelTokenPriceCheck?: CancelTokenSource;

  public checkPrice(body: IPriceCheckBody): Promise<IPriceCheckResponse> {
    return new Promise<IPriceCheckResponse>((resolve, reject) => {
      this.cancelTokenPriceCheck = axios.CancelToken.source();

      return axios
        .post(PriceApi.getUrl(), body, {
          cancelToken: this.cancelTokenPriceCheck.token,
        })
        .then(getData)
        .then((data: IPriceCheckResponse) => {
          if (!data.value && data.message) {
            reject(data.message);
          } else if (
            data.errors &&
            data.errors.length &&
            data.errors[0] &&
            data.errors[0].message
          ) {
            const { message } = data.errors[0];
            const path = data.errors[0].path || '';

            reject(`${path} ${message}`);
          } else {
            resolve(data);
          }
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getPrice(body: IPriceBody): Promise<IPriceResponse> {
    return new Promise<IPriceResponse>((resolve, reject) => {
      this.cancelTokenPriceCheck = axios.CancelToken.source();

      return axios
        .post(PriceApi.getCheckPriceUrl(), body, {
          cancelToken: this.cancelTokenPriceCheck.token,
        })
        .then(getData)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelCheckPrice() {
    if (this.cancelTokenPriceCheck) {
      this.cancelTokenPriceCheck.cancel();
      this.cancelTokenPriceCheck = undefined;
    }
  }
}

export default new PriceApi();
