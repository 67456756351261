import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { mounted as mountedPlaces } from '@Model/going/places/actions';
import { edit, mounted, save } from '@Model/going/rundates/actions';
import { getSingleRundateData } from '@Model/going/rundates/selectors';
import { GOING_RUNDATES } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';

import Edit from './Edit.component';
import { prepareInitialValues } from './Edit.helpers';
import { IRundateFromDispatch, IRundateFromState } from './Edit.types';

const mapStateToProps = (state: _Store.IState): IRundateFromState => ({
  initialValues: prepareInitialValues(getSingleRundateData(state)),
  isLoading: getLoading(GOING_RUNDATES)(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IRundateFromDispatch => {
  return {
    edit: (rundateEditPayload) => dispatch(edit(rundateEditPayload)),
    mounted: () => dispatch(mounted()),
    mountedPlaces: () => dispatch(mountedPlaces()),
    save: (rundateEditPayload) => dispatch(save(rundateEditPayload)),
  };
};

export default connect<
  IRundateFromState,
  IRundateFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(Edit);
