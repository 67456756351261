import React, { FC } from 'react';

import { Grid } from '@mui/material';

import AvailabilityCard from './components/AvailabilityCard';

import { IAvailabilitiesCardsProps } from './AvailabilitiesCards.types';

const AvailabilitiesCards: FC<IAvailabilitiesCardsProps> = ({
  availabilities,
  onClick,
}) => {
  return (
    <Grid container={true} spacing={4}>
      {availabilities.map((availability, index) => (
        <AvailabilityCard
          key={`availability-${index}`}
          availability={availability}
          onClick={() => onClick && onClick(availability.slug)}
        />
      ))}
    </Grid>
  );
};

export default AvailabilitiesCards;
