import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getHappeningPartnerId } from '@Model/happenings/selectors';
import {
  cancelDelayedTransactions,
  catchCancelDelayedTransaction,
  catchDelayedTransactions,
  getDelayedTransactions,
  handleActivePage,
  handleRowsPerPage,
  handleTransactionType,
} from '@Model/reservations/actions';
import getDelayedTransactionsSelector from '@Model/reservations/selectors/getDelayedTransactions';
import { TYPE_SUCCESS } from '@Model/toasts/constants/constants';

import { addToast } from './../../toasts/actions/index';
import { getTransactionsType } from './../selectors';
import { IDelayedTransactionsSuccessPayload } from './../types';

const CANCEL_SUCCESS_TEXT = 'Transakcja anulowana pomyślnie';

export const whenDelayedIsMountedCatch: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(catchDelayedTransactions)),
    mergeMap$(() => {
      return of$(getDelayedTransactions.request());
    })
  );
};

export const getTransactionDetailsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi }
) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getDelayedTransactions.request,
        handleActivePage,
        handleRowsPerPage,
        handleTransactionType,
      ])
    ),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const { page, perPage } = getDelayedTransactionsSelector(state);
      const type = getTransactionsType(state);
      const partnerId = getHappeningPartnerId(state);

      return from$(
        reservationsApi.getDelayedTransactions(
          page + 1,
          perPage,
          type,
          partnerId
        )
      ).pipe(
        map$((data: IDelayedTransactionsSuccessPayload) => {
          return getDelayedTransactions.success(data);
        }),
        catchError$((error: Error) => {
          return of$(getDelayedTransactions.failure(error));
        })
      );
    })
  );
};
export const catchCancelTransactionWhenRequest: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(catchCancelDelayedTransaction)),
    mergeMap$((action) => {
      return of$(cancelDelayedTransactions.request(action.payload));
    })
  );
};

export const cancelTransactionWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(cancelDelayedTransactions.request)),
    mergeMap$((action) => {
      return from$(
        reservationsApi.cancelDelayedTransaction(action.payload)
      ).pipe(
        map$(() => {
          return cancelDelayedTransactions.success();
        }),
        catchError$((error: Error) => {
          return of$(cancelDelayedTransactions.failure(error));
        })
      );
    })
  );
};

export const displayMessageWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(cancelDelayedTransactions.success)),
    mergeMap$((action) => {
      return of$(
        addToast(CANCEL_SUCCESS_TEXT, TYPE_SUCCESS),
        getDelayedTransactions.request()
      );
    })
  );
};
