import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getEventMailing } from '@Model/mailing/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import { resetError, resetLoading, setLoading } from '../actions';
import { MAILING } from '../constants/constants';

export const setUpStateWhenMailingFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getEventMailing.request])),
    mergeMap$(() => [resetError(MAILING), setLoading(MAILING)])
  );
};

export const setLoaderWhenMailingFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getEventMailing.success, getEventMailing.failure], action)
    ),
    map$(() => resetLoading(MAILING))
  );
};

export const setErrorOnMailingFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getEventMailing.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
