import { getPrinters } from '@/models/printer/selectors';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { get } from '@Model/authorization/selectors';
import { pullDrawer } from '@Model/printer/actions';
import { allPermissions } from '@Model/state/constants';

import DrawerOpen from './DrawerOpen.component';
import {
  IDrawerOpenFromDispatch,
  IDrawerOpenFromState,
} from './DrawerOpen.types';

const mapStateToProps = (state: _Store.IState): IDrawerOpenFromState => {
  const {
    userInfo: { permissions, session },
  } = get(state);

  const printers = getPrinters(state);

  const hasDrawer = printers.find(
    (printer) => printer.id === session?.printer_id
  )?.drawer;

  return {
    showButton:
      permissions.includes(allPermissions.access_open_drawer_write) &&
      !!hasDrawer,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IDrawerOpenFromDispatch => ({
  pullDrawer: () => dispatch(pullDrawer()),
});

export default connect<
  IDrawerOpenFromState,
  IDrawerOpenFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(DrawerOpen);
