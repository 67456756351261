import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { savePool } from '@Model/going/rundates/actions';
import { getSelectedPool } from '@Model/going/rundates/selectors';

import PoolsForm from './PoolsForm.component';
import { IPoolsFormFromDispatch, IPoolsFormFromState } from './PoolsForm.types';

const mapStateToProps = (state: _Store.IState): IPoolsFormFromState => ({
  initialValues: getSelectedPool(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPoolsFormFromDispatch => ({
  save: (values) => dispatch(savePool(values)),
});

export default connect<
  IPoolsFormFromState,
  IPoolsFormFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PoolsForm);
