import { FirebaseError } from '@firebase/util';

import { IMessagesIndex } from '@Services/$firebase-api/types';

const WRONG_DATA_TEXT = 'Błędne dane logowania, spróbuj jeszcze raz.';
const WRONG_DATA_CONTACT_WITH_ADMINISTRATOR_TEXT =
  'Błędne dane logowania, skontaktuj się z Administratorem.';
const DEFAULT_TEXT = 'Wystąpił nieznany błąd! Kod błędu: ';

const params: IMessagesIndex = {
  default: DEFAULT_TEXT,
  'invalid-email': WRONG_DATA_TEXT,
  'user-disabled': WRONG_DATA_CONTACT_WITH_ADMINISTRATOR_TEXT,
  'user-not-found': WRONG_DATA_TEXT,
  'wrong-password': WRONG_DATA_TEXT,
};

export const printErrorByCode = (error: FirebaseError) => {
  const code = error.code.split('/')[1];

  return {
    message: params[code] !== undefined ? params[code] : params.default + code,
    statusCode: 403,
  };
};
