import React, { useEffect } from 'react';

import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {
  Button,
  CircularProgress,
  Grid,
  ListItem,
  Typography,
} from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import GenericTable from '@Compo/reusable/GenericTable';
import SeatsIo from '@Compo/reusable/SeatsIo';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import config from '@Config';
import { TypographyVariant } from '@Constants/Variants/Typography';
import { ISeats } from '@Services/$seats-io-api/types';

import colors from '../App/colors';
import PdfDocument from './components/PdfDocument';
import useStyles from './ReportSeatsIo.styles';
import { IReportSeatsIoProps, SeatStatus } from './ReportSeatsIo.types';

const ReportSeatsIo = ({
  mounted,
  rundate,
  rundateMounted,
  reports,
  isLoading,
}: IReportSeatsIoProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'ReportSeatsIo',
  });

  const classes = useStyles();

  const columns = [
    { id: 'label', label: t('place') },
    { id: 'section', label: t('sector') },
    { id: 'categoryLabel', label: t('category') },
    { id: 'row', label: t('row') },
    { id: 'seat', label: t('seat') },
    { id: 'statusLabel', label: t('status') },
    { id: 'countSold', label: t('ticketsSold') },
  ];

  const getColorFromStatus = (seat: ISeats) => {
    if (seat.countSold > 1 && seat.statusLabel !== SeatStatus.free) {
      return colors.error.light;
    }
    if (seat.statusLabel === SeatStatus.taken) {
      return colors.seats.taken;
    }

    if (seat.statusLabel === SeatStatus.freeDisabled) {
      return colors.action.disabled;
    }
  };

  const getNormalizedCategories = (seats: ISeats[]) => {
    return seats.map((seat) => ({
      ...seat,
      color: getColorFromStatus(seat),
    }));
  };

  useEffect(() => {
    mounted();
    rundateMounted();
  }, []);

  if (isLoading) {
    return (
      <GoingPaper>
        <Loader />
      </GoingPaper>
    );
  }

  if (!reports.length) {
    return null;
  }

  return (
    <GoingPaper>
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={true}>
          <Typography variant={TypographyVariant.h5}>
            {rundate.event.titlePL}
          </Typography>
        </Grid>
        <Grid item={true}>
          <PDFDownloadLink
            document={<PdfDocument rundate={rundate} reports={reports} />}
            fileName={`Raport_${rundate.event.titlePL}_${moment().format(
              'YYYY-MM-DD_hh:mm:ss'
            )}.pdf`}
            className={classes.button}
          >
            {({ loading }) => (
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                startIcon={
                  loading ? (
                    <CircularProgress
                      className={classes.loading}
                      disableShrink={true}
                    />
                  ) : (
                    <SaveAltIcon />
                  )
                }
                className={classes.button}
                disabled={loading}
              >
                {t('downloadReport')}
              </Button>
            )}
          </PDFDownloadLink>
        </Grid>

        {reports.map((report) => (
          <>
            <Grid item={true} xs={12}>
              <ListItem className={classes.item}>
                <Grid container={true}>
                  <Grid item={true} xs={true}>
                    <Typography variant={TypographyVariant.body1}>
                      {t('total')}
                    </Typography>
                  </Grid>

                  <Grid item={true}>
                    <Grid container={true} spacing={1}>
                      <Grid item={true}>
                        <Typography variant={TypographyVariant.body1}>{`${t(
                          'occupied'
                        )}${report.summary.booked + report.summary.reserved} (${
                          report.summary.booked
                        }/${report.summary.reserved})`}</Typography>
                      </Grid>

                      <Grid item={true}>
                        <Typography variant={TypographyVariant.body1}>{`${t(
                          'free'
                        )}${report.summary.free}`}</Typography>
                      </Grid>

                      <Grid item={true}>
                        <Typography variant={TypographyVariant.body1}>{`${t(
                          'notForSale'
                        )}${report.summary.notForSale}`}</Typography>
                      </Grid>

                      <Grid item={true}>
                        <Typography variant={TypographyVariant.body1}>{`${t(
                          'duble'
                        )}${report.summary.overbooking}`}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
            </Grid>

            {!!report.categories.length &&
              report.categories.map((category) => (
                <>
                  <Grid item={true} xs={12}>
                    <SeatsIo
                      seatsIoPublicKey={config.seats.publicKey}
                      rundateId={String(category.rundate.id)}
                      mode="static"
                      isReport={true}
                      categoryKey={category.categoryKey}
                    />
                  </Grid>

                  <Grid item={true} xs={12}>
                    <GenericTable>
                      <TableActions>
                        <Grid
                          container={true}
                          justifyContent="space-between"
                          className={classes.tableHeader}
                        >
                          <Grid item={true}>
                            <Typography
                              variant={TypographyVariant.h6}
                            >{`Raport ${rundate.event.titlePL} ID${category.categoryKey}`}</Typography>
                          </Grid>
                          <Grid item={true}>
                            <Grid container={true} spacing={1}>
                              <Grid item={true}>
                                <Typography
                                  variant={TypographyVariant.body1}
                                >{`${t('occupied')}${
                                  category.summary.booked +
                                  category.summary.reserved
                                } (${category.summary.booked}/${
                                  category.summary.reserved
                                })`}</Typography>
                              </Grid>

                              <Grid item={true}>
                                <Typography
                                  variant={TypographyVariant.body1}
                                >{`${t('free')}${
                                  category.summary.free
                                }`}</Typography>
                              </Grid>

                              <Grid item={true}>
                                <Typography
                                  variant={TypographyVariant.body1}
                                >{`${t('notForSale')}${
                                  category.summary.notForSale
                                }`}</Typography>
                              </Grid>

                              <Grid item={true}>
                                <Typography
                                  variant={TypographyVariant.body1}
                                >{`${t('duble')}${
                                  category.summary.overbooking
                                }`}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </TableActions>
                      <TableUi
                        rows={getNormalizedCategories(category.seats)}
                        columns={columns}
                        disableCheckBox={true}
                        useColor={true}
                      />
                    </GenericTable>
                  </Grid>
                </>
              ))}
          </>
        ))}
      </Grid>
    </GoingPaper>
  );
};

export default ReportSeatsIo;
