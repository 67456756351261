import React, { FC } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';

import { IDPrinterTableProps } from './PrinterTable.types';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    paddingRight: theme.spacing(3),
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
    },
  },
  buttonFirst: {
    marginRight: theme.spacing(2),
  },
  buttonWithoutPadding: {
    paddingRight: theme.spacing(1.25),
  },

  buttonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

const PrinterTable: FC<IDPrinterTableProps> = ({
  Printers,
  edit,
  endSession,
  remove,
  permissionPrintersWrite,
  addPrinter,
}) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'PrinterAdmin.components.PrinterTable',
  });

  const classes = useStyles();

  const columns = [
    { id: 'id', label: t('id') },
    { id: 'printerId', label: t('idPrinter') },
    { id: 'saleUrl', label: t('saleUrl') },
    { id: 'firmware', label: t('firmware') },
  ];

  if (permissionPrintersWrite) {
    columns.push(
      { id: 'openSession', label: t('session') },
      { id: 'actions', label: t('action') }
    );
  }

  const catchRowClicked = (id: string) => {
    const foundedPrinter = Printers.find(
      (printer) => Number(id) === printer.id
    );
    if (foundedPrinter) {
      edit({ ...foundedPrinter, edit: true });
    }
  };

  const catchEndSession = (id: number | null) => {
    if (id) {
      endSession(id);
    }
  };

  const normalizedPrinters = Printers.map((printer) => ({
    ...printer,
    actions: (
      <Button
        variant="contained"
        size="small"
        color="primary"
        className={cn(
          classes.button,
          classes.buttonFirst,
          classes.buttonWithoutPadding
        )}
        onClick={(e) => {
          e.stopPropagation();
          confirmModal(printer.id);
        }}
      >
        {t('remove')}
      </Button>
    ),
    openSession: printer.openSession ? (
      <Button
        variant="contained"
        size="small"
        color="primary"
        className={cn(
          classes.button,
          classes.buttonFirst,
          classes.buttonWithoutPadding
        )}
        onClick={(e) => {
          e.stopPropagation();
          catchEndSession(printer.id);
        }}
      >
        {t('endSession')}
      </Button>
    ) : null,
  }));

  const confirmModal = (id: number | null) => {
    if (window.confirm(t('areYouSure')) && id) {
      remove(id);
    }
  };

  return (
    <GenericTable>
      <TableActions>
        <div />
        <div className={classes.buttonsContainer}>
          {permissionPrintersWrite && (
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              startIcon={<AddIcon />}
              className={cn(classes.button, classes.buttonFirst)}
              onClick={addPrinter}
            >
              {t('add')}
            </Button>
          )}
        </div>
      </TableActions>
      <TableUi
        rows={normalizedPrinters}
        columns={columns}
        total={Printers.length}
        activePage={0}
        rowsPerPage={Printers.length}
        handleChangePage={() => {}}
        disableCheckBox={true}
        onRowClick={catchRowClicked}
      />
    </GenericTable>
  );
};

export default PrinterTable;
