import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getEvents } from '@Model/events/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import { resetError, resetLoading, setLoading } from './../actions';
import { EVENTS } from './../constants/constants';

export const setUpStateWhenEventsFetchRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getEvents.request])),
    mergeMap$(() => {
      return [resetError(EVENTS), setLoading(EVENTS)];
    })
  );
};

export const setLoaderWhenEventsFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getEvents.success, getEvents.failure], action)
    ),
    map$(() => resetLoading(EVENTS))
  );
};

export const setErrorOnEventsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getEvents.failure)),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
