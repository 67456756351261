import { date, number, object, ref, string } from 'yup';

export const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const EXPIRE_DATE_VALIDATOR_TEXT =
  'Data zakończenia nie może być mniejsza niż data rozpoczęcia.';
const MATCH_VALIDATOR_TEXT =
  'Podaj ciąg alfanumeryczny lub znaki specjalne (-, _)';

const configuration = {
  code: string()
    .matches(/^[a-zA-Z0-9_-]*$/, MATCH_VALIDATOR_TEXT)
    .required(REQUIRED_VALIDATOR_TEXT),
  expireDate: date()
    .min(ref('startDate'), EXPIRE_DATE_VALIDATOR_TEXT)
    .nullable(),
  itemLimit: number().nullable(),
  startDate: date().nullable(),
};

export const EventDiscountSchema = object().shape(configuration);
