import React from 'react';

import Grid from '@Compo/layout/Grid';
import Users from '@Compo/Users';

const UsersPage = () => (
  <Grid>
    <Users />
  </Grid>
);

export default UsersPage;
