import {
  getReservations,
  selectReservationList,
} from '@Model/reservations/actions';
import _Store from '@Store';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetError, resetLoading, setLoading } from './../actions';
import { RESERVATIONS_LIST_MODAL } from './../constants/constants';

export const setUpStateWhenReservationsModalFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(selectReservationList)),
    mergeMap$(() => [
      resetError(RESERVATIONS_LIST_MODAL),
      setLoading(RESERVATIONS_LIST_MODAL),
    ])
  );
};

export const setLoaderWhenReservationsModalFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getReservations.success, getReservations.failure], action)
    ),
    map$(() => resetLoading(RESERVATIONS_LIST_MODAL))
  );
};
