import React from 'react';

import { Formik } from 'formik';

import FormLayout from './../FormLayout';
import { ITicketFormProps } from './TicketForm.types';
import { TicketSchema } from './TicketForm.validation';

const TicketForm = ({ initialValues, save }: ITicketFormProps) => {
  if (initialValues) {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={TicketSchema}
        onSubmit={save}
        component={FormLayout}
      />
    );
  }
  return null;
};

export default TicketForm;
