import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './ProductsView.styles';
import { ISummaryProps } from './ProductsView.types';

const ProductsView = ({ transactionProducts }: ISummaryProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Summary.components.ProductsView',
  });

  const classes = useStyles();
  if (!transactionProducts) {
    return null;
  }

  return (
    <>
      {transactionProducts.map(({ productName, quantity, price }) => {
        return (
          <Grid
            container={true}
            key={productName}
            direction="column"
            className={classes.container}
            spacing={2}
          >
            <Grid xs={12} item={true} className={classes.flexBasis}>
              <Typography variant="h6">{productName}</Typography>
            </Grid>
            <Grid xs={12} item={true} className={classes.flexBasis}>
              <Grid container={true} spacing={1}>
                <Grid item={true} xs={6}>
                  <Typography
                    component="p"
                    variant="body1"
                    color="textSecondary"
                  >
                    {t('quantity')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography component="p" variant="body1">
                    {quantity}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography
                    component="p"
                    variant="body1"
                    color="textSecondary"
                  >
                    {t('price')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography component="p" variant="body1">
                    {(price * quantity).toFixed(2)} {t('currency')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default ProductsView;
