import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ISuccessModalProps } from './SuccessModal.types';

const SuccessModal = ({
  showModal,
  closeModal,
  message,
  title,
  onConfirm,
  dispatch,
}: ISuccessModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reusable.SuccessModal',
  });

  const confirm = () => {
    if (onConfirm) {
      dispatch(onConfirm);
    }
    closeModal();
  };

  return (
    <Dialog
      open={showModal}
      maxWidth="sm"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
      style={{ zIndex: 9999 }}
    >
      <DialogTitle>{title || t('title')}</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message || t('success')}</Typography>
      </DialogContent>
      <DialogActions>
        {onConfirm ? (
          <>
            <Button
              onClick={closeModal}
              variant="outlined"
              size="large"
              color="secondary"
            >
              {t('no')}
            </Button>
            <Button
              onClick={confirm}
              variant="contained"
              size="large"
              color="primary"
            >
              {t('yes')}
            </Button>
          </>
        ) : (
          <Button
            onClick={closeModal}
            variant="contained"
            size="large"
            color="primary"
          >
            {t('ok')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SuccessModal;
