import { createSelector } from 'reselect';

import _Store from '@Store';

import { ISettlementFilters, ISettlementsReducer } from './../types';
import get from './get';

const getFilterSettlement = createSelector<
  _Store.IState,
  ISettlementsReducer,
  ISettlementFilters
>([get], (settlement) => settlement.filter);

export default getFilterSettlement;
