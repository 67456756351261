export const getTouchedObj = (errors: any) => {
  const touched: any = {};
  Object.keys(errors).map((key) => {
    if (Array.isArray(errors[key])) {
      errors[key].map((val: string, index: number) => {
        if (index === 0) {
          touched[key] = [];
        }
        touched[key].push(getTouchedObj(val));
      });
    } else {
      touched[key] = true;
    }
  });

  return touched;
};
