import React from 'react';

import { Formik } from 'formik';

import FormLayout from './../FormLayout';
import { IPoolsFormProps } from './PoolsForm.types';
import { PoolsSchema } from './PoolsForm.validation';

const PoolsForm = ({ initialValues, save }: IPoolsFormProps) => {
  if (initialValues) {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={PoolsSchema}
        onSubmit={save}
        component={FormLayout}
      />
    );
  }
  return null;
};

export default PoolsForm;
