import { createSelector } from 'reselect';

import _Store from '@Store';
import { IProductsReducer, IViews } from '../types';
import get from './get';

const getView = createSelector<_Store.IState, IProductsReducer, IViews>(
  [get],
  (products) => products.view
);

export default getView;
