import { array, lazy, mixed, object, ref, string } from 'yup';

import {
  DEFAULT_ACCESS_ID,
  OTHER_PARTNER_ID,
} from './../FormLayout/Access/Access.component';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const MIN_PASSWORD_VALIDATOR_TEXT =
  'Podane hasło musi zawierać co najmniej 7 znaków.';
const MATCH_PASSWORD_VALIDATOR_TEXT = 'Podane hasło nie jest takie samo.';
const EMAIL_VALIDATOR_TEXT = 'Błędny format maila.';

const configuration = {
  access: lazy((value: { [key: string]: number[] }) => {
    if (value) {
      const validationObject = array().of(
        string().notOneOf([String(DEFAULT_ACCESS_ID)], REQUIRED_VALIDATOR_TEXT)
      );
      const newEntries = Object.keys(value).reduce(
        (acc, val) => ({
          ...acc,
          [val]:
            val === String(OTHER_PARTNER_ID)
              ? array().of(string())
              : validationObject,
        }),
        {}
      );

      return object().shape(newEntries);
    }
    return mixed().notRequired();
  }),
  cmsPartners: array().min(1, REQUIRED_VALIDATOR_TEXT),
  email: string().email(EMAIL_VALIDATOR_TEXT).required(REQUIRED_VALIDATOR_TEXT),
  firstName: string().required(REQUIRED_VALIDATOR_TEXT),
  lastName: string().required(REQUIRED_VALIDATOR_TEXT),
  password: string().when('id', {
    is: (id) => id,
    otherwise: string()
      .min(7, MIN_PASSWORD_VALIDATOR_TEXT)
      .required(REQUIRED_VALIDATOR_TEXT),
    then: string().nullable(),
  }),
  repeatPassword: string().when('id', {
    is: (id) => id,
    otherwise: string()
      .required(REQUIRED_VALIDATOR_TEXT)
      .oneOf([ref('password')], MATCH_PASSWORD_VALIDATOR_TEXT),
    then: string().nullable(),
  }),
};

export const UsersSchema = object().shape(configuration);
