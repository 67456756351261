import React, { useState } from 'react';

import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import GoingPaper from '@Compo/layout/Paper/GoingPaper';

import GroupsTable from './components/GroupsTable';
import UsersTable from './components/UsersTable';
import useStyles from './Users.styles';
import { IUsersProps } from './Users.types';

const Users = ({}: IUsersProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Users',
  });

  const [selected, setSelected] = useState('users');
  const classes = useStyles();

  const catchChangeUsersType = (e: SelectChangeEvent<string>) => {
    setSelected(e.target.value);
  };

  const renderUsersListHeader = () => {
    return (
      <div className={classes.container}>
        <FormControl variant="standard" fullWidth={true}>
          <Select
            value={selected}
            onChange={catchChangeUsersType}
            variant="filled"
          >
            <MenuItem value={'users'}>{t('users')}</MenuItem>
            <MenuItem value={'groups'}>{t('groups')}</MenuItem>
          </Select>
          <FormHelperText>{t('usersSelectHelper')}</FormHelperText>
        </FormControl>
      </div>
    );
  };
  return (
    <GoingPaper header={renderUsersListHeader()}>
      {selected === 'users' ? <UsersTable /> : <GroupsTable />}
    </GoingPaper>
  );
};

export default Users;
