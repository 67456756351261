import { number, object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const AVAILABILITY_TO_LOW_TEXT = 'Za niski stan magazynowy';
const PRICE_TO_LOW_TEXT = 'Cena musi być wartością dodatnią';

const product = {
  availableToSale: number()
    .nullable()
    .when('isAvailabilityChecked', {
      is: true,
      then: number()
        .min(0, AVAILABILITY_TO_LOW_TEXT)
        .required(REQUIRED_VALIDATOR_TEXT),
    }),
  categoryId: number(),
  description: string(),
  name: string().required(REQUIRED_VALIDATOR_TEXT),
  price: number()
    .min(0, PRICE_TO_LOW_TEXT)
    .required(REQUIRED_VALIDATOR_TEXT),
};

export const ProductSchema = object().shape(product);
