import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IRundatePartner } from '@Services/$discounts-api/types';

import {
  IEventMailing,
  IEventMailingBody,
  IEventMailingResponse,
  IEventSaveSuccessPayload,
} from './types';

class EmailApi {
  private static getEmailsUrl(): string {
    return `${config.api.cmsServices}event-mailing`;
  }

  private static getSingleEmailUrl(id: number): string {
    return `${config.api.cmsServices}event-mailing/${id}`;
  }

  private static getDeleteEmailUrl(id: number): string {
    return `${config.api.cmsServices}event-mailing/${id}`;
  }

  private static getSendEmailUrl(id: number): string {
    return `${config.api.cmsServices}event-mailing/${id}/send`;
  }

  private static getEventRundatesUrl(): string {
    return `${config.api.cmsServices}rundates-partner`;
  }

  private static getSendTestMail(id: number): string {
    return `${config.api.cmsServices}event-mailing/${id}/test`;
  }

  private cancelTokenEmail?: CancelTokenSource;

  public getEventMailing(
    page: number,
    count: number,
    searchText: string
  ): Promise<IEventMailingResponse> {
    return new Promise<IEventMailingResponse>((resolve, reject) => {
      this.cancelTokenEmail = axios.CancelToken.source();

      axios
        .get(EmailApi.getEmailsUrl(), {
          cancelToken: this.cancelTokenEmail.token,
          params: {
            count,
            'filter[all]': searchText,
            page,
          },
        })
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getEventRundates(
    partnerIds: string,
    eventId: number
  ): Promise<IRundatePartner[]> {
    return new Promise<IRundatePartner[]>((resolve, reject) => {
      this.cancelTokenEmail = axios.CancelToken.source();

      axios
        .get(EmailApi.getEventRundatesUrl(), {
          cancelToken: this.cancelTokenEmail.token,
          params: {
            'filter[eventIds]': [eventId],
            partnerIds: [partnerIds],
          },
        })
        .then(getData)
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getSingleEventMailing(id: number): Promise<IEventMailing> {
    return new Promise<IEventMailing>((resolve, reject) => {
      this.cancelTokenEmail = axios.CancelToken.source();

      axios
        .get(EmailApi.getSingleEmailUrl(id), {
          cancelToken: this.cancelTokenEmail.token,
        })
        .then(getData)
        .then(getData)
        .then((response) => resolve(response[0]))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public deleteEventMail(id: number): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenEmail = axios.CancelToken.source();

      axios
        .delete(EmailApi.getDeleteEmailUrl(id), {
          cancelToken: this.cancelTokenEmail.token,
        })
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public saveMailing(
    body: IEventMailingBody
  ): Promise<IEventSaveSuccessPayload> {
    return new Promise<IEventSaveSuccessPayload>((resolve, reject) => {
      this.cancelTokenEmail = axios.CancelToken.source();

      if (body.id) {
        axios
          .put(EmailApi.getEmailsUrl(), body, {
            cancelToken: this.cancelTokenEmail.token,
          })
          .then(getData)
          .then(getData)
          .then((data) => resolve(data[0]))
          .catch((error) => reject(catchHttpError(error)));
      } else {
        axios
          .post(EmailApi.getEmailsUrl(), body, {
            cancelToken: this.cancelTokenEmail.token,
          })
          .then(getData)
          .then(getData)
          .then((data) => resolve(data[0]))
          .catch((error) => reject(catchHttpError(error)));
      }
    });
  }

  public sendEventMailing(id: number): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenEmail = axios.CancelToken.source();

      axios
        .get(EmailApi.getSendEmailUrl(id), {
          cancelToken: this.cancelTokenEmail.token,
        })
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public sendEventTestMailing(id: number, email: string): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenEmail = axios.CancelToken.source();

      axios
        .post(
          EmailApi.getSendTestMail(id),
          {},
          {
            cancelToken: this.cancelTokenEmail.token,
            params: {
              email,
            },
          }
        )
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelEmail() {
    if (this.cancelTokenEmail) {
      this.cancelTokenEmail.cancel();
      this.cancelTokenEmail = undefined;
    }
  }
}

export default new EmailApi();
