import React, { ChangeEvent, useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import { TypographyVariant } from '@Constants/Variants/Typography';
import { IProduct } from '@Model/products/types';

import { IAddProductModalProps } from './AddProductModal.types';

const AddProductModal = ({
  allProducts,
  meta,
  handleActivePage,
  isLoading,
  open,
  onClose,
  selected,
  onChange,
}: IAddProductModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'BetterManager.Space.components.Products.AddProductModal',
  });

  const [searchParam, setSearchParam] = useState('');
  const [localSelected, setLocalSelected] = useState<IProduct[]>(
    selected || []
  );
  const [searchTimeOut, setSearchTimeOut] = useState<NodeJS.Timeout | null>(
    null
  );

  const columns = [{ id: 'name', label: 'Nazwa' }];

  const onPageChanged = (event: unknown, page: number) => {
    handleActivePage(page + 1, meta.size);
  };

  const setOwnSelected = (selectedProducts: IProduct[]) => {
    const lastElement = selectedProducts[selectedProducts.length - 1];
    if (!lastElement?.assignedStorageHouseId && lastElement?.stock.length) {
      setLocalSelected(
        selectedProducts.map((product) =>
          product.id === lastElement.id
            ? {
                ...product,
                assignedStorageHouseId: lastElement.stock[0].storageHouseId,
              }
            : product
        )
      );
    } else {
      setLocalSelected(selectedProducts);
    }
  };

  const choseStorage = (storageHouseId: number, productId?: number) => {
    setLocalSelected(
      localSelected.map((product) => {
        if (productId === product.id) {
          return {
            ...product,
            assignedStorageHouseId: storageHouseId,
          };
        }
        return product;
      })
    );
  };

  const assignProducts = () => {
    onChange('products', localSelected);
    onClose();
  };

  const catchHandleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchParam(e.target.value);
    searchFunc(e.target.value);
  };

  const searchFunc = (text: string) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        handleActivePage(1, meta.size, text);
      }, 1000)
    );
  };

  const normalizedProducts = allProducts.map((product) => {
    const assignedStorageHouseId = localSelected.find(
      (value) => value.id === product.id
    )?.assignedStorageHouseId;

    return {
      ...product,
      expand: !!(product.stock && product.stock.length) && (
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <Typography variant={TypographyVariant.body2}>
              {t('stock')}
            </Typography>
          </Grid>
          <Grid item={true} xs={12}>
            <RadioGroup
              row={true}
              defaultValue={
                assignedStorageHouseId
                  ? String(assignedStorageHouseId)
                  : String(product.stock[0].storageHouseId)
              }
              onChange={(e, value) => choseStorage(Number(value), product.id)}
            >
              {product.stock.map((stock, index) => (
                <FormControlLabel
                  key={index}
                  value={String(stock.storageHouseId)}
                  control={<Radio size="medium" color="secondary" />}
                  label={
                    <Typography variant={TypographyVariant.body2}>
                      {stock.storageHouseName}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>
      ),
    };
  });

  useEffect(() => {
    if (selected) {
      setLocalSelected(selected);
    }
  }, [selected]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={onClose}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <DialogContent>
        <GenericTable>
          <TableActions>
            <TextField
              variant="standard"
              name="search"
              helperText={t('search')}
              value={searchParam}
              onChange={catchHandleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
            />
            <div />
          </TableActions>
          <TableUi
            rows={normalizedProducts}
            columns={columns}
            isLoading={isLoading}
            total={meta.total}
            activePage={meta.page - 1}
            rowsPerPage={meta.size}
            handleChangePage={onPageChanged}
            setOwnSelected={setOwnSelected}
            selectedItems={localSelected}
          />
        </GenericTable>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={assignProducts}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProductModal;
