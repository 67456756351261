import { createSelector } from 'reselect';

import { getHappening } from '@Model/happening/selectors';
import getSelectedConfigurationId from '@Model/happening/selectors/getSelectedConfigurationId';
import getSelectedSpace from './getSelectedSpace';

const LOCAL_CURRENCY = 'PLN';

const getUpSellValue = createSelector(
  [getSelectedSpace, getHappening, getSelectedConfigurationId],
  (selectedSpace, selectedHappening, selectedConfigurationId) => {
    if (!selectedHappening || !selectedSpace) {
      return null;
    }

    if (
      selectedConfigurationId &&
      selectedConfigurationId.upsell &&
      (selectedConfigurationId.upsell.percentageValue ||
        selectedConfigurationId.upsell.value)
    ) {
      if (selectedConfigurationId.upsell.value) {
        return `-${selectedConfigurationId.upsell.value} ${LOCAL_CURRENCY}`;
      }

      return `-${selectedConfigurationId.upsell.percentageValue} %`;
    }

    return null;
  }
);

export default getUpSellValue;
