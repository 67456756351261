import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { IConfiguration, IFormValuesProps } from '@Model/configurations/types';

import * as helpers from '../../AddingConfiguration.helpers';
import FormLayout from './Components/FormLayout';
import { makeName, makeRRule } from './Components/FormLayout/FormLayout.helpes';
import { IConfigurationModalProps } from './ConfigurationModal.types';
import { AddingConfigurationSchema } from './ConfigurationModal.validation';

export const ConfigurationModal = ({
  activeConfiguration,
  catchSaveConfiguration,
  initialValues,
  handleActiveConfiguration,
}: IConfigurationModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'BetterManager.AddingConfiguration.components.ConfigurationModal',
  });

  if (!activeConfiguration) {
    return null;
  }

  const catchHandleSubmit = (values: IFormValuesProps) => {
    const {
      price,
      durationToString,
      durationAdvanced,
      duration,
      advanced,
      title,
      isUpsell,
      closed,
      upsellValue,
      isPeopleCount,
      partnerId,
      validTo,
      validFrom,
    } = values;

    const getDuration = () => {
      if (!advanced) {
        return {
          duration: durationNewValue || duration,
        };
      }
      return {
        duration: durationAdvanced,
      };
    };

    const getTitle = (): { title: string } => {
      if (title && title.length > 0) {
        return {
          title,
        };
      }

      return {
        title: makeName(values, makeRRule(values).toUpperCase()),
      };
    };

    const getUpsalle = () => {
      const value = upsellValue || 0;

      if (isUpsell && !closed) {
        if (values.upsellType === 0) {
          return {
            upsell: {
              percentageValue: null,
              value,
            },
          };
        }
        return {
          upsell: {
            percentageValue: value,
            value: null,
          },
        };
      }

      return {};
    };

    const getPrice = () => {
      return {
        prices: [
          {
            currency: 'PLN',
            value: Number(price),
          },
        ],
      };
    };

    const getPeopleCount = () => {
      const { fromNumberOfPeople, perPersonValue, perPersonType, priceGoesUp } =
        values;

      const getValue = () => {
        if (perPersonType === 'value') {
          return {
            percentageValue: null,
            value: Number(perPersonValue),
          };
        } else {
          return {
            percentageValue: Number(perPersonValue),
            value: null,
          };
        }
      };

      if (isPeopleCount) {
        return {
          fromNumberOfPeopleModifier: {
            fromNumberOfPeople: Number(fromNumberOfPeople),
            priceGoesUp: priceGoesUp === 'up',
            ...getValue(),
          },
        };
      }
      return {};
    };

    const getNumberOfPeople = () => {
      return {
        maxNumberOfPeople: values.maxNumberOfPeople
          ? Number(values.maxNumberOfPeople)
          : null,
      };
    };

    const durationNewValue = helpers.makeTimeFromDuration(durationToString);

    const answer: IConfiguration = {
      closed: values.closed,
      id: values.id || undefined,
      maxNumberOfPeople: Number(values.maxNumberOfPeople) || null,
      partnerId:
        partnerId && Number(partnerId) !== -1 && Number(partnerId) !== 0
          ? Number(partnerId)
          : null,
      price,
      rrule: makeRRule(values).toUpperCase(),
      validFrom,
      validTo,
      ...getNumberOfPeople(),
      ...getDuration(),
      ...getUpsalle(),
      ...getTitle(),
      ...getPrice(),
      ...getPeopleCount(),
    };

    if (values.closed) {
      answer.closed = true;
    }

    if (values.addNewConfiguration) {
      answer.shouldSave = true;
    }

    catchSaveConfiguration(answer);
  };

  const TITLE_TEXT = initialValues.id ? t('titleEdit') : t('addTitle');

  return (
    <Dialog
      open={activeConfiguration ? true : false}
      maxWidth="md"
      fullWidth={true}
      onClose={() => handleActiveConfiguration(null)}
      scroll="paper"
    >
      <DialogTitle>{TITLE_TEXT}</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={catchHandleSubmit}
        validationSchema={AddingConfigurationSchema}
        component={FormLayout}
      />
    </Dialog>
  );
};

export default ConfigurationModal;
