// Private
export const _RESET_STATE = 'Zagrywki/metadata/_RESET_STATE';

export const _GET_PARTNERS_REQUEST = 'Zagrywki/metadata/_GET_PARTNERS_REQUEST';
export const _GET_PARTNERS_SUCCESS = 'Zagrywki/metadata/_GET_PARTNERS_SUCCESS';
export const _GET_PARTNERS_FAILURE = 'Zagrywki/metadata/_GET_PARTNERS_FAILURE';

export const _GET_EVENTS_PARTNER_REQUEST =
  'Zagrywki/metadata/_GET_EVENTS_PARTNER_REQUEST';
export const _GET_EVENTS_PARTNER_SUCCESS =
  'Zagrywki/metadata/_GET_EVENTS_PARTNER_SUCCESS';
export const _GET_EVENTS_PARTNER_FAILURE =
  'Zagrywki/metadata/_GET_EVENTS_PARTNER_FAILURE';

export const _GET_RUNDATES_PARTNER_REQUEST =
  'Zagrywki/metadata/_GET_RUNDATES_PARTNER_REQUEST';
export const _GET_RUNDATES_PARTNER_SUCCESS =
  'Zagrywki/metadata/_GET_RUNDATES_PARTNER_SUCCESS';
export const _GET_RUNDATES_PARTNER_FAILURE =
  'Zagrywki/metadata/_GET_RUNDATES_PARTNER_FAILURE';

export const _GET_POOLS_PARTNER_REQUEST =
  'Zagrywki/metadata/_GET_POOLS_PARTNER_REQUEST';
export const _GET_POOLS_PARTNER_SUCCESS =
  'Zagrywki/metadata/_GET_POOLS_PARTNER_SUCCESS';
export const _GET_POOLS_PARTNER_FAILURE =
  'Zagrywki/metadata/_GET_POOLS_PARTNER_FAILURE';

export const CATCH_VALUES = 'Zagrywki/metadata/CATCH_VALUES';
export const HANDLE_ACTIVE_PAGE = 'Zagrywki/metadata/HANDLE_ACTIVE_PAGE';
export const CHANGE_IS_FILTER_BY_ACTIVE_PARTNER =
  'Zagrywki/metadata/CHANGE_IS_FILTER_BY_ACTIVE_PARTNER';
