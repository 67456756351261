import { getType } from 'typesafe-actions';
import { getAvailabilities, resetState } from './../actions';
import { IAction, IAvailabilitiesReducer } from './../types';

const initialState: IAvailabilitiesReducer = { currentDay: {} as any };

const availabilitiesReducer = (
  state: IAvailabilitiesReducer = initialState,
  action: IAction
): IAvailabilitiesReducer => {
  switch (action.type) {
    case getType(getAvailabilities.success):
      return action.payload || [];
    case getType(resetState):
      return initialState;
    default:
      return state;
  }
};

export default availabilitiesReducer;
