import { useTheme } from '@mui/material/styles';
import axios, { AxiosRequestConfig } from 'axios';

import { languagePl } from '@Compo/reusable/CloudinaryUploadWidget/CloudinaryUploadWidget.language';
import config from '@Config';

import {
  IAdditionalParamsToSign,
  ICloudinaryMyWidgetProps,
  IGetterFunctionProps,
  IParamsToSign,
} from './CloudinaryUploadWidget.types';

// tslint:disable: no-unused-expression

const instance = axios.create();

instance.interceptors.response.use((response) => response.data);

export const defaultValues: ICloudinaryMyWidgetProps = {
  apiKey: '',
  autoClose: true,
  cloudName: null,
  cropping: true,
  croppingAspectRatio: 1.157,
  croppingShowDimensions: true,
  customPublicId: null,
  destroy: true,
  eager: null,
  folder: null,
  generateSignatureUrl: '',
  multiple: false,
  // tslint:disable-next-line: no-empty
  onFailure: () => {},
  // tslint:disable-next-line: no-empty
  onSuccess: () => {},
  resourceType: 'auto',
  showSkipCropButton: false,
  sourceKeys: null,
  sources: ['local', 'url', 'dropbox', 'facebook', 'google_drive'],
  tags: [],
  unique_filename: false,
  uploadPreset: 'going_panel',
  use_filename: true,
  widgetStyles: null,
};

export const prepareInitialValues = (
  prefix: string,
  folder: string,
  slug: string
): ICloudinaryMyWidgetProps => {
  const { apiKey, cloudName } = config.cloudinary;
  const theme = useTheme();

  const configMyWidget = defaultValues;
  configMyWidget.apiKey = apiKey;
  configMyWidget.cloudName = cloudName;
  configMyWidget.generateSignatureUrl = `${config.api.cmsServices}cloudinary/signature`;
  configMyWidget.folder = `${prefix}/${folder}`;
  configMyWidget.tags = [`${prefix}/${slug}`];
  configMyWidget.widgetStyles = {
    fonts: {
      inherit: null,
    },
    palette: {
      action: theme.palette.primary.main,
      complete: theme.palette.success.main,
      error: theme.palette.error.main,
      inProgress: theme.palette.text.primary,
      inactiveTabIcon: theme.palette.text.primary,
      link: theme.palette.primary.main,
      menuIcons: theme.palette.primary.main,
      sourceBg: theme.palette.background.paper,
      tabIcon: theme.palette.primary.main,
      textDark: theme.palette.text.primary,
      textLight: theme.palette.text.primary,
      window: theme.palette.background.paper,
      windowBorder: theme.palette.text.primary,
    },
  };

  return configMyWidget;
};

export const createUploadWidget = (
  {
    multiple,
    autoClose,
    widgetStyles,
    sources,
    sourceKeys,
    cloudName,
    uploadPreset,
    folder,
    cropping,
    showSkipCropButton,
    croppingAspectRatio,
    croppingShowDimensions,
    resourceType,
    generateSignatureUrl,
    use_filename,
    unique_filename,
    apiKey,
    customPublicId,
    eager,
    destroy,
    tags,
    onSuccess,
    onFailure,
  }: ICloudinaryMyWidgetProps,
  setCloudinaryUploadWidgetOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const widget =
    !!window.cloudinary &&
    window.cloudinary.createUploadWidget(
      {
        cloudName,
        cropping,
        ...(cropping && { croppingAspectRatio }),
        ...(cropping && { croppingShowDimensions }),
        folder,
        multiple,
        resourceType,
        showAdvancedOptions: true,
        showCompletedButton: true,
        showPoweredBy: false,
        ...(cropping && { showSkipCropButton }),
        singleUploadAutoClose: autoClose,
        sources,
        styles: widgetStyles,
        tags,
        uploadPreset,
        ...(sourceKeys && sourceKeys),
        ...(generateSignatureUrl && { use_filename }),
        ...(generateSignatureUrl && { unique_filename }),
        ...(generateSignatureUrl && {
          prepareUploadParams: async (cb: any, params: any) =>
            await generateSignature(cb, params, {
              apiKey,
              customPublicId,
              eager,
              generateSignatureUrl,
              resourceType,
            }),
        }),
        language: 'pl',
        text: {
          pl: languagePl,
        },
      },
      (error: any, result: { event: string; info: string }) => {
        if (!error && result && result.event === 'success') {
          setCloudinaryUploadWidgetOpen(false);
          onSuccess(result);
        } else if (!error && result && result.event === 'close') {
          setCloudinaryUploadWidgetOpen(false);
          destroy && widget.destroy();
        } else if (!!error) {
          setCloudinaryUploadWidgetOpen(false);
          onFailure(error);

          destroy && widget.destroy();
        }
      }
    );
  widget.open();
};

const generateSignature = (
  cb: (arg0: any) => void,
  params: any,
  {
    generateSignatureUrl,
    customPublicId,
    eager,
    apiKey,
    resourceType,
  }: IAdditionalParamsToSign
) => {
  params = [].concat(params); // params can be a single object or an array of objects
  Promise.all(
    params.map((req: IParamsToSign) => {
      const uploadParams = req;
      const {
        custom_coordinates,
        filename_override,
        headers,
        source,
        timestamp,
        unique_filename,
        upload_preset,
        use_filename,
        folder,
        tags,
      } = req;

      return getterFunction({
        data: {
          params_to_sign: {
            timestamp,
            unique_filename,
            ...(custom_coordinates && { custom_coordinates }),
            ...(filename_override && { filename_override }),
            ...(headers && { headers }),
            ...(eager && { eager }),
            ...(customPublicId && { public_id: customPublicId }),
            ...(source && { source }),
            ...(upload_preset && { upload_preset }),
            ...(use_filename && { use_filename }),
            ...(folder && { folder }),
            ...(tags && { tags }),
          },
        },
        url: generateSignatureUrl,
      }).then((response) => {
        return Object.assign(
          {
            api_key: apiKey,
            resource_type: resourceType,
            signature: response,
          },
          uploadParams
        );
      });
    })
  ).then((results) => {
    cb(results[0]);
  });
};

const getterFunction = ({ url, data }: IGetterFunctionProps) => {
  instance.defaults.headers.common.Accept = 'application/json';
  instance.defaults.headers.common['Content-Type'] = 'application/json';
  instance.defaults.withCredentials = false;
  const options: AxiosRequestConfig = {
    method: 'get',
    params: data,
    url: url + '?nocache=' + new Date().getTime(),
  };

  return instance(options);
};
