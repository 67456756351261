import { createSelector } from 'reselect';

import _Store from '@Store';

import { IUserInfo } from '../types';
import getUserInfo from './getUserInfo';

const getPartnerIds = createSelector<
  _Store.IState,
  IUserInfo,
  number[] | undefined
>(getUserInfo, (user) => user.partners?.map((partner) => Number(partner.id)));

export default getPartnerIds;
