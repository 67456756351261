import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Colors } from '@Constants/Colors';
import { TypographyVariant } from '@Constants/Variants/Typography';

import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Products.InvoiceModal.FormLayout',
  });

  const classes = useStyles();

  const {
    handleSubmit,
    submitForm,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    closeModal,
    categories,
    activeInvoiceProduct,
  } = props;

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent>
          <Grid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <Typography variant={TypographyVariant.body1}>
                {t('invoiceData')}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} className={classes.infoContainer}>
              <Grid container={true} spacing={1}>
                <Grid item={true} xs={6}>
                  <Typography
                    variant={TypographyVariant.body1}
                    color={Colors.textSecondary}
                  >
                    {t('name')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant={TypographyVariant.body1}>
                    {activeInvoiceProduct?.name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container={true} spacing={1}>
                <Grid item={true} xs={6}>
                  <Typography
                    variant={TypographyVariant.body1}
                    color={Colors.textSecondary}
                  >
                    {t('price')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant={TypographyVariant.body1}>
                    {`${activeInvoiceProduct?.price.toFixed(2)} ${t(
                      'currency'
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container={true} spacing={1}>
              <Grid item={true} xs={6}>
                <Typography
                  variant={TypographyVariant.body1}
                  color={Colors.textSecondary}
                >
                  {t('vat')}
                </Typography>
              </Grid>
              <Grid item={true} xs={6}>
                <Typography variant={TypographyVariant.body1}>
                  {`${activeInvoiceProduct?.vat} ${t('percentage')}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container={true} className={classes.input} spacing={2}>
            <Grid item={true} xs={12}>
              <Typography variant={TypographyVariant.body1}>
                {t('stockState')}
              </Typography>
            </Grid>
            <Grid item={true} xs={12} className={classes.infoContainer}>
              {activeInvoiceProduct?.stock?.map((stock) => (
                <Grid container={true} key={stock.storageHouseId} spacing={1}>
                  <Grid item={true} xs={6}>
                    <Typography
                      variant={TypographyVariant.body1}
                      color={Colors.textSecondary}
                    >
                      {stock.storageHouseName}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <Typography variant={TypographyVariant.body1}>
                      {stock.quantity}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid container={true} className={classes.input}>
            <FormControl variant="standard" fullWidth={true}>
              <InputLabel>
                {(touched.categoryId && errors.categoryId) || t('category')}
              </InputLabel>
              <Select
                value={values.categoryId || 0}
                onChange={handleChange}
                defaultValue={0}
                fullWidth={true}
                name="categoryId"
                onBlur={handleBlur}
              >
                <MenuItem value={0}>-</MenuItem>
                {categories &&
                  categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>{t('categoryHelper')}</FormHelperText>
            </FormControl>

            <TextField
              id="description"
              label={
                (touched.description && errors.description) || t('description')
              }
              helperText={t('descriptionHelper')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              name="description"
              error={!!(touched.description && errors.description)}
              className={classes.input}
              fullWidth={true}
            />

            <Grid container={true} spacing={2} className={classes.input}>
              <Grid item={true} xs={12}>
                <Grid container={true} spacing={1}>
                  <Grid item={true} xs={12}>
                    <Typography variant="body1" color="textSecondary">
                      {t('emailConfirmation')}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {t('emailConfirmationDesc')}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Switch
                      checked={values.emailConfirmation}
                      onChange={handleChange}
                      name="emailConfirmation"
                      color="primary"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <FormControl
            variant="standard"
            fullWidth={true}
            className={classes.input}
          >
            <InputLabel>
              {(touched.autocomplete && errors.autocomplete) ||
                t('autocomplete')}
            </InputLabel>
            <Select
              value={values.autocomplete}
              onChange={handleChange}
              fullWidth={true}
              name="autocomplete"
              onBlur={handleBlur}
            >
              <MenuItem value={0}>{t('no')}</MenuItem>
              <MenuItem value={1}>{t('yes')}</MenuItem>
            </Select>
            <FormHelperText>{t('autocompleteHelper')}</FormHelperText>
          </FormControl>

          <FormControl
            variant="standard"
            onChange={handleChange}
            fullWidth={true}
            className={classes.input}
          >
            <InputLabel>
              {(touched.fiscal && errors.fiscal) || t('fiscal')}
            </InputLabel>
            <Select
              value={values.fiscal}
              onChange={handleChange}
              fullWidth={true}
              name="fiscal"
              onBlur={handleBlur}
              defaultValue={values.fiscal}
            >
              <MenuItem value={0}>{t('no')}</MenuItem>
              <MenuItem value={1}>{t('yes')}</MenuItem>
            </Select>
            <FormHelperText>{t('fiscalHelper')}</FormHelperText>
          </FormControl>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
