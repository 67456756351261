import { createSelector } from 'reselect';

import _Store from '@Store';

import { IRundatesReducer } from '../types';

import getRundates from './getRundates';

const getRundatesPage = createSelector<
  _Store.IState,
  IRundatesReducer,
  number | undefined
>([getRundates], (rundates) => rundates.meta.page);

export default getRundatesPage;
