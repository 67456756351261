import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import uuid from 'uuid';

import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import config from '@Config';
import { TRuleType } from '@Model/configurations/types';

import styles from './DefinedConfigurations.module.scss';
import { IDefinedConfigurationsProps } from './DefinedConfigurations.types';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    paddingRight: theme.spacing(3),
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
    },
  },
  buttonFirst: {
    marginRight: theme.spacing(2),
  },

  buttonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
  rulesSelect: {
    marginBottom: theme.spacing(2),
  },

  item: {
    marginBottom: 0,
    marginTop: 0,
  },

  buttonWithoutPadding: {
    paddingRight: theme.spacing(1.25),
  },
}));

const DefinedConfigurations = ({
  mounted,
  isLoading,
  configurations,
  handleActiveConfiguration,
  addNewConfiguration,
  handleAdd,
  handleSearchParam,
  searchParam,
  getConfiguration,
  modal,
  handleRuleTypeParam,
  selectedRuleType,
  activePage,
  handleActivePage,
  perPage,
  totalCount,
  handleRowsPerPage,
  normalizedConfigurations,
  onlyTable,
  handleDeleteConfiguration,
}: IDefinedConfigurationsProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'BetterManager.DefinedConfigurations',
  });

  useEffect(() => {
    mounted();
  }, []);

  const classes = useStyles();
  const [timeOut, setTimeOut] = useState<number>(0);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const catchHandleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.currentTarget;
    handleSearchParam(value);

    if (timeOut) {
      clearTimeout(timeOut as any);
    }
    setTimeOut(
      setTimeout(() => {
        getConfiguration();
      }, 1000) as any
    );
  };

  const showNoResultInfo = !isLoading && configurations.length < 1;

  const clickAction = handleAdd || handleActiveConfiguration;

  const NAME_TEXT = 'Nazwa';
  const TYPE_TEXT = 'Typ';
  const DAYS_TEXT = 'Dni tygodnia';
  const TIME_TEXT = 'Godziny';
  const PRICE_TEXT = 'Cena domyślna';
  const UPSELL_TEXT = 'Upsell';
  const GROUP_TEXT = 'Bilety grupowe';
  const DATE_TEXT = 'Data/Okres';
  const PRICES_TEXT = 'Dod. typy biletów';
  const YES_BUTTON = 'Tak (zobacz)';
  const NO_BUTTON = 'Nie';
  const ACTION_TEXT = 'Akcje';
  const DELETE_TEXT = 'Usuń';

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const catchEditRule = (id: string) => {
    const configurationFounded = configurations.find(
      (configuration) => id === configuration.id
    );

    if (configurationFounded) {
      clickAction({
        ...configurationFounded,
        hash: configurationFounded.hash || uuid(),
      });
    }
  };

  const catchRuleType = (event: SelectChangeEvent<TRuleType>) => {
    const { value } = event.target;

    handleRuleTypeParam(value as TRuleType);
    getConfiguration();
  };

  const catchChangeRowsPerPage = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleRowsPerPage(Number(e.target.value));
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const columns = [
    { id: 'name', label: NAME_TEXT },
    { id: 'type', label: TYPE_TEXT },
    { id: 'time', label: TIME_TEXT },
    { id: 'days', label: DAYS_TEXT },
    { id: 'date', label: DATE_TEXT },
    { id: 'price', label: PRICE_TEXT },
    { id: 'prices', label: PRICES_TEXT },
    { id: 'upsell', label: UPSELL_TEXT },
    { id: 'group', label: GROUP_TEXT },
    { id: 'actions', label: ACTION_TEXT },
  ];

  if (isLoading) {
    return onlyTable ? (
      <div className={styles.loader}>
        <Loader />
      </div>
    ) : (
      <GoingPaper title={t('title')}>
        <div className={styles.loader}>
          <Loader />
        </div>
      </GoingPaper>
    );
  }

  const handleDeleteRule = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    type: string,
    id?: string
  ) => {
    event.stopPropagation();
    if (id) {
      handleDeleteConfiguration(selectedRuleType, id);
    }
  };

  const normalizedConfigurationsWithButtons = normalizedConfigurations.map(
    (configuration) => ({
      ...configuration,
      actions: (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={classes.buttonWithoutPadding}
          onClick={(e) =>
            handleDeleteRule(e, configuration.type, configuration.id)
          }
        >
          {DELETE_TEXT}
        </Button>
      ),
      group: configuration.group ? (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={classes.buttonWithoutPadding}
        >
          {YES_BUTTON}
        </Button>
      ) : (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          disabled={true}
          className={classes.buttonWithoutPadding}
        >
          {NO_BUTTON}
        </Button>
      ),
      prices: configuration.prices?.length ? (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={classes.buttonWithoutPadding}
        >
          {YES_BUTTON}
        </Button>
      ) : (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          disabled={true}
          className={classes.buttonWithoutPadding}
        >
          {NO_BUTTON}
        </Button>
      ),
      upsell: configuration.upsell ? (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          className={classes.buttonWithoutPadding}
        >
          {YES_BUTTON}
        </Button>
      ) : (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          disabled={true}
          className={classes.buttonWithoutPadding}
        >
          {NO_BUTTON}
        </Button>
      ),
    })
  );

  const renderBody = () => {
    return (
      <>
        {config.cms.showNewRules && !onlyTable && (
          <Grid container={true} className={classes.rulesSelect}>
            <Grid item={true} xs={12}>
              <FormControl variant="standard" fullWidth={true}>
                <InputLabel>{t('selectRule')}</InputLabel>
                <Select
                  variant="standard"
                  value={selectedRuleType}
                  onChange={catchRuleType}
                  fullWidth={true}
                  name="partnerId"
                >
                  <MenuItem value={'price'}>{t('priceRule')}</MenuItem>
                  <MenuItem value={'time'}>{t('openTimeRule')}</MenuItem>
                  <MenuItem value={'close'}>{t('closeTimeRule')}</MenuItem>
                  <MenuItem value={'capacity'}>{t('capacityRule')}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <GenericTable>
          <TableActions>
            <TextField
              variant="standard"
              name="search"
              helperText={t('search')}
              value={searchParam}
              onChange={catchHandleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
            />
            <div className={classes.buttonsContainer}>
              {!config.cms.showNewRules && (
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  startIcon={<AddIcon />}
                  className={cn(classes.button, classes.buttonFirst)}
                  onClick={addNewConfiguration}
                >
                  {t('add')}
                </Button>
              )}
            </div>
          </TableActions>
          <TableUi
            rows={normalizedConfigurationsWithButtons}
            columns={columns}
            total={totalCount}
            activePage={activePage}
            rowsPerPage={perPage}
            handleChangePage={handleActivePage}
            handleChangeRowsPerPage={catchChangeRowsPerPage}
            disableCheckBox={true}
            onRowClick={catchEditRule}
          />
        </GenericTable>
      </>
    );
  };

  return modal || onlyTable ? (
    renderBody()
  ) : (
    <GoingPaper title={t('title')}>{renderBody()}</GoingPaper>
  );
};

export default DefinedConfigurations;
