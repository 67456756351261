import { getType } from 'typesafe-actions';

import {
  addPrinter,
  editPrinter,
  getPrinters,
  getPrintersPinging,
  getPrinterStatus,
  printReceipt,
  resetState,
  stopPingingPrinter,
} from '../actions';
import { IAction, IPrinterReducer } from '../types';

const initialState: IPrinterReducer = {
  activePrinter: null,
  pinging: false,
  printers: [],
  printingReceiptHasError: false,
  showModal: false,
};

const printerReducer = (
  state: IPrinterReducer = initialState,
  action: IAction,
): IPrinterReducer => {
  switch (action.type) {
    case getType(editPrinter):
      return {
        ...state,
        activePrinter: action.payload,
        showModal: true,
      };

    case getType(getPrinters.success):
      return {
        ...state,
        printers: action.payload.items,
      };

    case getType(getPrintersPinging.success):
      return {
        ...state,
        printers: action.payload.items,
      };

    case getType(addPrinter):
      return {
        ...state,
        activePrinter: null,
        showModal: true,
      };

    case getType(resetState):
      return {
        ...state,
        activePrinter: null,
        showModal: false,
      };
    case getType(printReceipt.request):
      return {
        ...state,
        printingReceiptHasError: false,
      };
    case getType(printReceipt.failure):
      return {
        ...state,
        printingReceiptHasError: true,
      };
    case getType(getPrinterStatus.request):
      return {
        ...state,
        pinging: true,
      };
    case getType(stopPingingPrinter):
      return {
        ...state,
        pinging: false,
      };
    default:
      return state;
  }
};

export default printerReducer;
