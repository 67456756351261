import { getType } from 'typesafe-actions';

import { changeMetaData, getSeatsCategories, getSeatsCharts } from '../actions';
import { IAction, ISeatsIoReducer } from '../types';

const initialState: ISeatsIoReducer = {
  categories: [],
  data: [],
  meta: {
    count: 20,
    nextPageStartsAfter: undefined,
    previousPageEndsBefore: undefined,
    search: '',
  },
  pageMeta: {
    nextPageStartsAfter: 0,
    previousPageEndsBefore: 0,
  },
};

const priceReducer = (
  state: ISeatsIoReducer = initialState,
  action: IAction
): ISeatsIoReducer => {
  switch (action.type) {
    case getType(getSeatsCharts.success):
      return {
        ...state,
        data: action.payload.data,
        pageMeta: action.payload.meta,
      };
    case getType(getSeatsCategories.success):
      return {
        ...state,
        categories: action.payload,
      };
    case getType(changeMetaData):
      return {
        ...state,
        meta: action.payload,
      };
    default:
      return state;
  }
};

export default priceReducer;
