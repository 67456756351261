import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import _Store from '@Store';

import {
  catchGetSingleRefundRegistration,
  changeRefundsRegistrationsFilter,
  changeRefundsRegistrationsPage,
  changeRefundsRegistrationsSearch,
  mounted,
} from '@Model/refundsRegistration/actions';
import { catchEditMultipleRefundRegistrationStatuses } from '@Model/refundsRegistration/actions/index';
import {
  getRefundsRegistrationsFilter,
  getRefundsRegistrationsList,
  getRefundsRegistrationsMeta,
} from '@Model/refundsRegistration/selectors';
import { REFUNDS_REGISTRATION } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import RefundsRegistration from './RefundsRegistration.component';
import {
  IRefundsRegistrationFromDispatch,
  IRefundsRegistrationFromState,
} from './RefundsRegistration.types';

const mapStateToProps = (
  state: _Store.IState
): IRefundsRegistrationFromState => ({
  filterValues: getRefundsRegistrationsFilter(state),
  isLoading: getLoading(REFUNDS_REGISTRATION)(state),
  meta: getRefundsRegistrationsMeta(state),
  refundsRegistrations: getRefundsRegistrationsList(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch
): IRefundsRegistrationFromDispatch => ({
  changeRefundsRegistrationsFilter: (payload) =>
    dispatch(changeRefundsRegistrationsFilter(payload)),
  changeRefundsRegistrationsSearch: (payload) =>
    dispatch(changeRefundsRegistrationsSearch(payload)),
  edit: (id) => dispatch(catchGetSingleRefundRegistration(id)),
  editMultipleStatuses: (data) =>
    dispatch(catchEditMultipleRefundRegistrationStatuses(data)),
  handleChangePage: (page) => dispatch(changeRefundsRegistrationsPage(page)),
  mounted: () => dispatch(mounted()),
});

export default connect<
  IRefundsRegistrationFromState,
  IRefundsRegistrationFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(RefundsRegistration);
