import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  deleteMultipleTickets,
  deleteSingleTicket,
  fetchSingleTicket,
  fetchTickets,
  revertSingleTicket,
  searchTickets,
  selectTicket,
} from '@Model/going/rundates/actions';
import {
  getSearchTicketText,
  getTicketListTickets,
} from '@Model/going/rundates/selectors';
import { GOING_TICKET_LIST_TICKETS } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';

import { getInitialValues } from './../TicketForm/TicketForm.helpers';
import TicketsTable from './TicketsTable.component';
import {
  ITicketsTableFromDispatch,
  ITicketsTableFromState,
  ITicketsTableOwnProps,
} from './TicketsTable.types';

const mapStateToProps = (state: _Store.IState): ITicketsTableFromState => ({
  isLoading: getLoading(GOING_TICKET_LIST_TICKETS)(state),
  meta: getTicketListTickets(state).meta,
  searchText: getSearchTicketText(state),
  tickets: getTicketListTickets(state).data,
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITicketsTableFromDispatch => ({
  addTicket: (id) => dispatch(selectTicket(getInitialValues(id))),
  deleteMultipleTickets: (ids) => dispatch(deleteMultipleTickets(ids)),
  deleteTicket: (id) => dispatch(deleteSingleTicket(id)),
  fetchSingleTicket: (id) => dispatch(fetchSingleTicket(id)),
  fetchTickets: (body) => dispatch(fetchTickets(body)),
  handleSearchText: (text) => dispatch(searchTickets(text)),
  revertTicket: (id) => dispatch(revertSingleTicket(id)),
});

export default connect<
  ITicketsTableFromState,
  ITicketsTableFromDispatch,
  ITicketsTableOwnProps,
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(TicketsTable);
