import _Store from '@Store';
import { createSelector } from 'reselect';
import { IConfiguration, IConfigurationsReducer } from './../types';
import get from './get';

const getConfigurations = createSelector<
  _Store.IState,
  IConfigurationsReducer,
  IConfiguration[]
>(
  [get],
  (reducer) => (reducer ? reducer.items : []),
);

export default getConfigurations;
