import { createSelector } from 'reselect';

import _Store from '@Store';
import { IPartnerReportFiltersParamsResponse, ISalesChannel } from './../types';
import getReportParameters from './getReportParameters';

const getSalesChannels = createSelector<
  _Store.IState,
  IPartnerReportFiltersParamsResponse | null,
  ISalesChannel[]
>([getReportParameters], (params) => (params ? params.salesChannels : []));

export default getSalesChannels;
