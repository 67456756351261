import { Selector } from 'reselect';

import _Store from '@Store';

import { IMetadataReducer } from '../types';

const get: Selector<_Store.IState, IMetadataReducer> = (state) =>
  state.metadata;

export default get;
