import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { showSuccessModal } from '@Model/modal/actions';
import { getPartnerReportFiltersParameters } from '@Model/settings/actions';
import {
  catchDeleteSettlement,
  catchEditSettlement,
  catchGenerateGroupReport,
  catchGenerateReport,
  catchGenerateReportFile,
  catchSaveSettlement,
  catchSettlementStatusChange,
  changeFilterSettlement,
  changeSettlementsPage,
  changeSettlementStatus,
  clearFilterSettlement,
  deleteSettlement,
  editSettlement,
  fetchSettlementRundates,
  fetchSingleSettlement,
  generateGroupReport,
  generateReport,
  generateReportFile,
  getSettlementMetadata,
  getSettlementRundates,
  getSettlements,
  getSingleSettlement,
  handleActiveSettlement,
  mounted,
  resetSingleSettlement,
  saveSettlement,
  searchSettlement,
} from '@Model/settlements/actions';
import { getSingleSettlement as getSettlement } from '@Model/settlements/selectors';

import getFilterSettlement from '../selectors/getFiltersSettlement';

export const requestSettlementsWhenMounted: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    withLatestFrom$(state$),
    mergeMap$(() => {
      return of$(
        getPartnerReportFiltersParameters.request(),
        getSettlements.request(1),
        getSettlementMetadata.request()
      );
    })
  );
};

export const saveSettlementWhenAction: _Store.IEpic = (
  action$,
  state$,
  { settlementsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchSaveSettlement)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);

      if (partnerId) {
        return from$(
          settlementsApi.saveSettlement(
            { ...action.payload, percent: 0 },
            partnerId
          )
        ).pipe(
          mergeMap$((data) => {
            return of$(
              saveSettlement.success(),
              showSuccessModal({ message: data.message }),
              handleActiveSettlement(null),
              getSettlements.request(1),
              fetchSingleSettlement(data.uuid)
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => settlementsApi.cancelSettlements())
            )
          ),
          catchError$((error: Error) => {
            return of$(saveSettlement.failure(error));
          })
        );
      }
      return EMPTY$;
    })
  );
};

export const editSettlementWhenAction: _Store.IEpic = (
  action$,
  state$,
  { settlementsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchEditSettlement)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(settlementsApi.editSettlement(action.payload)).pipe(
        mergeMap$((data) => {
          return of$(
            editSettlement.success(),
            showSuccessModal({ message: data.message }),
            getSettlements.request(1),
            resetSingleSettlement()
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => settlementsApi.cancelSettlements())
          )
        ),
        catchError$((error: Error) => {
          return of$(editSettlement.failure(error));
        })
      );
    })
  );
};

export const fetchSettlementsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { settlementsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSettlements.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);

      if (partnerId) {
        const filter = getFilterSettlement(state);

        return from$(
          settlementsApi.getSettlements(partnerId, action.payload, filter)
        ).pipe(
          map$((data) => {
            return getSettlements.success(data);
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => settlementsApi.cancelSettlements())
            )
          ),
          catchError$((error: Error) => {
            return of$(getSettlements.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const fetchSettlementsWhenPageChanged: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(changeSettlementsPage)),
    mergeMap$((action) => {
      return of$(getSettlements.request(action.payload));
    })
  );
};

export const fetchSingleSettlementWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { settlementsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(fetchSingleSettlement)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(settlementsApi.getSingleSettlement(action.payload)).pipe(
        mergeMap$((data) => {
          return of$(getSingleSettlement.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => settlementsApi.cancelSettlements())
          )
        ),
        catchError$((error: Error) => {
          return of$(getSingleSettlement.failure(error));
        })
      );
    })
  );
};

export const deleteSettlementWhenAction: _Store.IEpic = (
  action$,
  state$,
  { settlementsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchDeleteSettlement)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(settlementsApi.deleteSettlement(action.payload)).pipe(
        mergeMap$(() => {
          return of$(
            deleteSettlement.success(),
            showSuccessModal(),
            resetSingleSettlement(),
            getSettlements.request(1)
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => settlementsApi.cancelSettlements())
          )
        ),
        catchError$((error: Error) => {
          return of$(deleteSettlement.failure(error));
        })
      );
    })
  );
};

export const updateSettlementStatusWhenAction: _Store.IEpic = (
  action$,
  state$,
  { settlementsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchSettlementStatusChange)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(settlementsApi.updateSettlementStatus(action.payload)).pipe(
        mergeMap$(() => {
          return of$(
            fetchSingleSettlement(action.payload.id),
            changeSettlementStatus.success(),
            showSuccessModal(),
            getSettlements.request(1)
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => settlementsApi.cancelSettlements())
          )
        ),
        catchError$((error: Error) => {
          return of$(changeSettlementStatus.failure(error));
        })
      );
    })
  );
};

export const generateSettlementReportWhenAction: _Store.IEpic = (
  action$,
  state$,
  { settlementsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchGenerateReport)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const settlement = getSettlement(state);
      if (settlement) {
        return from$(
          settlementsApi.generateSettlementReport(
            settlement.uuid,
            action.payload
          )
        ).pipe(
          mergeMap$((data) => {
            return of$(
              fetchSingleSettlement(settlement.uuid),
              generateReport.success(data),
              showSuccessModal()
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => settlementsApi.cancelSettlements())
            )
          ),
          catchError$((error: Error) => {
            return of$(generateReport.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const generateSettlementGroupReportWhenAction: _Store.IEpic = (
  action$,
  state$,
  { settlementsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchGenerateGroupReport)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(
        settlementsApi.generateSettlementGroupReport(action.payload)
      ).pipe(
        mergeMap$((data) => {
          return of$(generateGroupReport.success(data), showSuccessModal());
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => settlementsApi.cancelSettlements())
          )
        ),
        catchError$((error: Error) => {
          return of$(generateGroupReport.failure(error));
        })
      );
    })
  );
};

export const generateSettlementReportFileWhenAction: _Store.IEpic = (
  action$,
  state$,
  { settlementsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchGenerateReportFile)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const settlement = getSettlement(state);
      if (settlement) {
        return from$(
          settlementsApi.generateSettlementReportFile(
            settlement.uuid,
            action.payload.reportId
          )
        ).pipe(
          mergeMap$((data) => {
            const file = new Blob([data]);
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = `${action.payload.fileName}`;
            link.click();

            return of$(generateReportFile.success(data));
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => settlementsApi.cancelSettlements())
            )
          ),
          catchError$((error: Error) => {
            return of$(generateReportFile.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const downloadSettlementReportAfterGenerated: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf([generateReport.success, generateGroupReport.success])),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      const file = new Blob([action.payload.data]);
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = fileURL;
      link.download = action.payload.fileName;
      link.click();

      return EMPTY$;
    })
  );
};

export const fetchSettlementMetadataWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { settlementsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSettlementMetadata.request)),
    mergeMap$(() => {
      return from$(settlementsApi.getSettlementsMetadata()).pipe(
        mergeMap$((data) => {
          return of$(getSettlementMetadata.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => settlementsApi.cancelSettlements())
          )
        ),
        catchError$((error: Error) => {
          return of$(getSettlementMetadata.failure(error));
        })
      );
    })
  );
};

export const fetchSettlementRundatesWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { settlementsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(fetchSettlementRundates)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      if (partnerId) {
        return from$(
          settlementsApi.getSettlementsRundates(action.payload, partnerId)
        ).pipe(
          mergeMap$((data) => {
            return of$(getSettlementRundates.success(data));
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => settlementsApi.cancelSettlements())
            )
          ),
          catchError$((error: Error) => {
            return of$(getSettlementRundates.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const changeFiltersSettlementsWhenRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(
      isActionOf([
        changeFilterSettlement,
        clearFilterSettlement,
        searchSettlement,
      ])
    ),
    mergeMap$(() => {
      return of$(getSettlements.request(1));
    })
  );
};
