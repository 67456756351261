import { Dispatch } from 'react';

import { AnyAction } from 'redux';

import { logout } from '@Model/authorization/actions';
import { get } from '@Model/authorization/selectors';
import { setPartnerID } from '@Model/happenings/actions';
import { get as getHappenings } from '@Model/happenings/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import AppBarBooked from './AppBarBooked.component';
import {
  IAppBarBookedFromDispatch,
  IAppBarBookedFromState,
} from './AppBarBooked.types';

const mapStateToProps = (state: _Store.IState): IAppBarBookedFromState => {
  const {
    userInfo: { partners },
  } = get(state);

  const { partnerId } = getHappenings(state);

  return {
    partnerId,
    partners,
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IAppBarBookedFromDispatch => {
  return {
    logout: () => dispatch(logout()),
    setPartnerID: (partnerId: string) => {
      dispatch(setPartnerID(partnerId));
    },
  };
};

export default connect<
  IAppBarBookedFromState,
  IAppBarBookedFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(AppBarBooked);
