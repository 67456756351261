import { createSelector } from 'reselect';

import { INormalizedEvent } from '@Services/$events-api/types';
import _Store from '@Store';
import { ISelectedReducer } from './../types';
import getSelected from './getSelected';

const getSelectedEvent = createSelector<
  _Store.IState,
  ISelectedReducer,
  INormalizedEvent | null
>([getSelected], (selected) => selected.event);

export default getSelectedEvent;
