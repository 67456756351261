import { getType } from 'typesafe-actions';

import {
  checkExternalTicketsList,
  getTicket,
  getTickets,
  getTicketsList,
  searchTickets,
  selectSingleTicket,
  selectTicket,
  selectTicketList,
} from '../actions';
import { IAction, ITicketsReducer } from '../types';
import { resetExternalTickets } from './../actions/index';

const initialState: ITicketsReducer = {
  externalTicketsWarning: null,
  selectedSingleTicket: null,
  selectedTicket: null,
  selectedTicketList: null,
  singleTicket: null,
  tickets: {
    data: [],
    meta: {
      page: 1,
      size: 20,
      total: 0,
    },
  },
  ticketsList: {
    data: [],
    meta: {
      page: 1,
      size: 20,
      total: 0,
    },
  },
  ticketsSearchText: '',
};

const goingTicketsReducer = (
  state: ITicketsReducer = initialState,
  action: IAction
): ITicketsReducer => {
  switch (action.type) {
    case getType(getTickets.success):
      return {
        ...state,
        tickets: action.payload,
      };
    case getType(getTicket.success):
      return {
        ...state,
        singleTicket: action.payload,
      };
    case getType(selectTicketList):
      return {
        ...state,
        selectedTicketList: action.payload,
      };
    case getType(selectTicket):
      return {
        ...state,
        selectedTicket: action.payload,
      };
    case getType(selectSingleTicket):
      return {
        ...state,
        selectedSingleTicket: action.payload,
      };
    case getType(getTicketsList.success):
      return {
        ...state,
        ticketsList: action.payload,
      };
    case getType(searchTickets):
      return {
        ...state,
        ticketsSearchText: action.payload,
      };
    case getType(checkExternalTicketsList.success):
      return {
        ...state,
        externalTicketsWarning: action.payload,
      };
    case getType(resetExternalTickets):
      return {
        ...state,
        externalTicketsWarning: null,
      };
    default:
      return state;
  }
};

export default goingTicketsReducer;
