import React, { useEffect, useState } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import SummaryMix from '@Compo/SummaryMix';
import config from '@Config';
import { isElectron } from '@Misc/helpers/isElectron';

import ProductsView from './components/ProductsView';
import TransactionView from './components/TransactionView';
import styles from './Summary.module.scss';
import useStyles from './Summary.styles';
import { ISummaryProps } from './Summary.types';

const Summary = ({
  mounted,
  reservationPrintData,
  continueBuying,
  printingReceiptHasError,
  printReceipt,
  isLoading,
  attachEntryGroup,
  showEntryGroupInput,
  retryBocaPrint,
}: ISummaryProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Summary',
  });

  const [bindingCode, setBindingCode] = useState('');

  useEffect(() => {
    mounted();
  }, []);

  const handleBindingCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBindingCode(e.target.value);
  };

  const handleEntryGroupSubmit = () => {
    attachEntryGroup(bindingCode);
    setBindingCode('');
  };

  const classes = useStyles();

  const showAdditionalLinkToPayment =
    reservationPrintData.status &&
    reservationPrintData.status === 'init' &&
    reservationPrintData.transactionUuid;

  const printingTicketBocaHasError =
    isElectron() &&
    reservationPrintData.transactionItems.some((item) => !item.entryToken);

  const ZAGRYWKI_URL = config.api.clientAppUrl + '?extendedPayment=';

  if (isLoading) {
    return (
      <GoingPaper>
        <div className={styles.loader}>
          <Loader />
        </div>
      </GoingPaper>
    );
  }

  const Buttons = () => (
    <Grid container={true} justifyContent="flex-end">
      <Grid item={true}>
        <Grid container={true} spacing={1}>
          {printingTicketBocaHasError && (
            <Grid item={true}>
              <Button
                variant="outlined"
                size="large"
                color="secondary"
                onClick={retryBocaPrint}
              >
                {t('printAgainBoca')}
              </Button>
            </Grid>
          )}
          {printingReceiptHasError && (
            <Grid item={true}>
              <Button
                variant="outlined"
                size="large"
                color="secondary"
                onClick={printReceipt}
              >
                {t('printAgain')}
              </Button>
            </Grid>
          )}
          <Grid item={true}>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClick={continueBuying}
            >
              {t('countinueBuying')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  if (reservationPrintData.transactionPayments?.length) {
    return <SummaryMix />;
  }

  return (
    <GoingPaper className={styles.paper} title={t('title')} buttons={Buttons()}>
      <Grid
        container={true}
        direction="row"
        alignItems="center"
        className={classes.successful}
      >
        <DoneIcon className={classes.icon} />
        <Typography variant="body1">{t('bookingSuccessful')}</Typography>
      </Grid>
      <Typography component="p" variant="caption" className={classes.summary}>
        {t('summary')}
      </Typography>

      <TransactionView
        transactionItems={reservationPrintData.transactionItems}
        details={reservationPrintData.details}
      />

      <ProductsView
        transactionProducts={reservationPrintData.transactionProducts}
      />

      {config.cms.showNewEnterList &&
        showEntryGroupInput &&
        reservationPrintData.transactionItems.length === 1 && (
          <Grid container={true} spacing={1} className={classes.container}>
            <Grid item={true} xs={12}>
              <TextField
                variant="standard"
                id="bindingCode"
                label={t('binding')}
                helperText={t('bindingHelper')}
                onChange={handleBindingCodeChange}
                value={bindingCode}
                name="bindingCode"
                fullWidth={true}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleEntryGroupSubmit}
              >
                {t('connect')}
              </Button>
            </Grid>
          </Grid>
        )}

      {showAdditionalLinkToPayment && (
        <>
          <Typography variant="body1" className={classes.title}>
            {t('link')}
          </Typography>
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.link}
            component="p"
          >
            {ZAGRYWKI_URL + reservationPrintData.transactionUuid}
          </Typography>
          <Grid container={true}>
            <CopyToClipboard
              text={ZAGRYWKI_URL + reservationPrintData.transactionUuid}
            >
              <Button
                variant="outlined"
                size="small"
                color="primary"
                className={classes.button}
              >
                {t('copy')}
              </Button>
            </CopyToClipboard>
          </Grid>
        </>
      )}
    </GoingPaper>
  );
};

export default Summary;
