import _Store from '@Store';
import { createSelector } from 'reselect';
import { IUserInfo } from '../types';
import getUserInfo from './getUserInfo';

const getPartnerId = createSelector<
  _Store.IState,
  IUserInfo,
  string | undefined
>(getUserInfo, (user) => user.partners?.[0].id);

export default getPartnerId;
