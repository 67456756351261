import { Selector } from 'reselect';

import _Store from '@Store';

import { IPlacesReducer } from '../types';

const getPlaces: Selector<_Store.IState, IPlacesReducer> = (state) =>
  state.places;

export default getPlaces;
