import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import {
  deleteRundatePool,
  getRundatePools,
} from '@Model/going/rundates/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { resetError, resetLoading, setLoading } from '../actions';
import { GOING_POOLS } from '../constants/constants';

export const setUpStateWhenGoingPoolsFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([getRundatePools.request])),
    mergeMap$(() => {
      return [resetError(GOING_POOLS), setLoading(GOING_POOLS)];
    })
  );
};

export const setLoaderWhenGoingPoolsFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([getRundatePools.success, getRundatePools.failure], action)
    ),
    map$(() => resetLoading(GOING_POOLS))
  );
};

export const setErrorOnGoingPoolsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getRundatePools.failure, deleteRundatePool.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
