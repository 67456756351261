import { getType } from 'typesafe-actions';
import { reload } from './../actions';
import { IAction, IReservationsDateRangeReducer } from './../types';

const initialState: IReservationsDateRangeReducer = null;

const dateRangeReducer = (
  state: IReservationsDateRangeReducer = initialState,
  action: IAction,
): IReservationsDateRangeReducer => {
  switch (action.type) {
    case getType(reload):
      return action.payload;
    default:
      return state;
  }
};

export default dateRangeReducer;
