import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';
import qs from 'qs';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import parseStringToBool from '@Misc/helpers/parseStringToBool';
import {
  IGenerateGroupReportPayload,
  ISettlementEdit,
  ISettlementFilters,
  ISettlementRundatesPayload,
} from '@Model/settlements/types';

import {
  IGenerateReportResponse,
  IRundatesResponse,
  ISaveSettlementResponse,
  ISettlementMetadataResponse,
  ISettlementSaveBody,
  ISettlementsResponse,
  ISingleSettlementResponse,
  IStatusBody,
} from './types';

class SettlementsApi {
  private static getSettlementsUrl() {
    return `${config.api.cmsServices}settlements`;
  }

  private static getSingleSettlementUrl(id: string) {
    return `${config.api.cmsServices}settlements/${id}`;
  }

  private static getDeleteSettlementUrl(id: string) {
    return `${config.api.cmsServices}settlements/delete/${id}`;
  }

  private static getUpdateStatusSettlementUrl(id: string) {
    return `${config.api.cmsServices}settlements/${id}/status`;
  }

  private static getGenerateReportSettlementUrl(settlementId: string) {
    return `${config.api.cmsServices}settlements/${settlementId}/report`;
  }

  private static getGenerateGroupReportSettlementUrl() {
    return `${config.api.cmsServices}settlements/group/report`;
  }

  private static getGenerateReportFileUrl(
    settlementId: string,
    reportId: string
  ) {
    return `${config.api.cmsServices}settlements/${settlementId}/report/${reportId}`;
  }

  private static getSettlementsMetadataUrl() {
    return `${config.api.cmsServices}settlements/metadata`;
  }

  private static getSettlementsRundatesUrl() {
    return `${config.api.cmsServices}settlements/rundates`;
  }

  private cancelTokenSettlements?: CancelTokenSource;

  public getSettlements(
    partnerId: string,
    page: number,
    filters?: ISettlementFilters
  ): Promise<ISettlementsResponse> {
    return new Promise<ISettlementsResponse>((resolve, reject) => {
      this.cancelTokenSettlements = axios.CancelToken.source();

      if (filters?.rundates) {
        filters.rundatesIds = filters.rundates.map((rundate) => rundate.id!);
      }

      const { rundates, ...filter } = { rundates: [], ...filters };

      axios
        .get(SettlementsApi.getSettlementsUrl(), {
          cancelToken: this.cancelTokenSettlements.token,
          params: {
            ...(filter && { filter }),
            page,
            partnerIds: [Number(partnerId)],
          },
          paramsSerializer(params) {
            const filterNormalized = {
              ...Object.fromEntries(
                Object.entries(params.filter)
                  .filter(
                    ([_, value]) =>
                      value !== null && value !== 'undefined' && value !== 0
                  )
                  .map(([k, v]) => {
                    if (moment.isMoment(v)) {
                      return [k, v.format('YYYY-MM-DD')];
                    } else {
                      return [k, v];
                    }
                  })
              ),
              accepted: parseStringToBool(params.filter.accepted),
            };

            return qs.stringify(
              { ...params, filter: filterNormalized },
              { encode: false }
            );
          },
        })
        .then(getData)
        .then((response) =>
          resolve({ data: response.data, meta: response.meta })
        )
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getSingleSettlement(id: string): Promise<ISingleSettlementResponse> {
    return new Promise<ISingleSettlementResponse>((resolve, reject) => {
      this.cancelTokenSettlements = axios.CancelToken.source();
      axios
        .get(SettlementsApi.getSingleSettlementUrl(id), {
          cancelToken: this.cancelTokenSettlements.token,
        })
        .then(getData)
        .then(getData)
        .then((response) => resolve(response[0]))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public saveSettlement(
    body: ISettlementSaveBody,
    partnerId: string
  ): Promise<ISaveSettlementResponse> {
    return new Promise<ISaveSettlementResponse>((resolve, reject) => {
      this.cancelTokenSettlements = axios.CancelToken.source();
      axios
        .post(
          SettlementsApi.getSettlementsUrl(),
          {
            ...body,
            partnerId: Number(partnerId),
          },
          { cancelToken: this.cancelTokenSettlements.token }
        )
        .then(getData)
        .then((response) =>
          resolve({ uuid: response.data.uuid, message: response.message })
        )
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public editSettlement(
    payload: ISettlementEdit
  ): Promise<ISaveSettlementResponse> {
    return new Promise<ISaveSettlementResponse>((resolve, reject) => {
      this.cancelTokenSettlements = axios.CancelToken.source();
      const { uuid, ...body } = payload;

      axios
        .put(SettlementsApi.getSingleSettlementUrl(uuid), body, {
          cancelToken: this.cancelTokenSettlements.token,
        })
        .then(getData)
        .then((response) => resolve({ uuid, message: response.message }))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public deleteSettlement(id: string): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenSettlements = axios.CancelToken.source();
      axios
        .delete(SettlementsApi.getDeleteSettlementUrl(id), {
          cancelToken: this.cancelTokenSettlements.token,
        })
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public updateSettlementStatus(body: IStatusBody): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenSettlements = axios.CancelToken.source();
      axios
        .put(
          SettlementsApi.getUpdateStatusSettlementUrl(body.id),
          {
            invoice: body.invoice,
            isAccepted: body.isAccepted,
          },
          { cancelToken: this.cancelTokenSettlements.token }
        )
        .then(getData)
        .then((response) => resolve(response))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public generateSettlementReport(
    settlementId: string,
    reportTypeId: number
  ): Promise<IGenerateReportResponse> {
    return new Promise<IGenerateReportResponse>((resolve, reject) => {
      this.cancelTokenSettlements = axios.CancelToken.source();
      axios
        .post(
          SettlementsApi.getGenerateReportSettlementUrl(settlementId),
          {
            reportTypeId,
          },
          {
            cancelToken: this.cancelTokenSettlements.token,
            responseType: 'arraybuffer',
          }
        )
        .then((response) => {
          resolve({
            data: response.data,
            fileName: response.headers['content-disposition']
              .split('filename=')[1]
              .split(';')[0]
              .slice(1, -1),
          });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public generateSettlementGroupReport(
    payload: IGenerateGroupReportPayload
  ): Promise<IGenerateReportResponse> {
    return new Promise<IGenerateReportResponse>((resolve, reject) => {
      this.cancelTokenSettlements = axios.CancelToken.source();
      axios
        .post(SettlementsApi.getGenerateGroupReportSettlementUrl(), payload, {
          cancelToken: this.cancelTokenSettlements.token,
          responseType: 'arraybuffer',
        })
        .then((response) => {
          resolve({
            data: response.data,
            fileName: response.headers['content-disposition']
              .split('filename=')[1]
              .split(';')[0]
              .slice(1, -1),
          });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public generateSettlementReportFile(
    settlementId: string,
    reportId: string
  ): Promise<BlobPart> {
    return new Promise<BlobPart>((resolve, reject) => {
      this.cancelTokenSettlements = axios.CancelToken.source();
      axios
        .get(SettlementsApi.getGenerateReportFileUrl(settlementId, reportId), {
          cancelToken: this.cancelTokenSettlements.token,
          responseType: 'arraybuffer',
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getSettlementsMetadata(): Promise<ISettlementMetadataResponse> {
    return new Promise<ISettlementMetadataResponse>((resolve, reject) => {
      this.cancelTokenSettlements = axios.CancelToken.source();
      axios
        .get(SettlementsApi.getSettlementsMetadataUrl(), {
          cancelToken: this.cancelTokenSettlements.token,
        })
        .then(getData)
        .then(getData)
        .then((response) => resolve(response[0]))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getSettlementsRundates(
    body: ISettlementRundatesPayload,
    partnerId: string
  ): Promise<IRundatesResponse> {
    return new Promise<IRundatesResponse>((resolve, reject) => {
      this.cancelTokenSettlements = axios.CancelToken.source();
      axios
        .get(SettlementsApi.getSettlementsRundatesUrl(), {
          cancelToken: this.cancelTokenSettlements.token,
          params: {
            'filter[endDate]': moment(body.endDate).format('YYYY-MM-DD'),
            'filter[partnerId]': partnerId,
            'filter[settlementTypeId]': body.settlementTypeId,
            'filter[startDate]': moment(body.startDate).format('YYYY-MM-DD'),
          },
        })
        .then(getData)
        .then(getData)
        .then((response) => resolve(response[0].rundates))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelSettlements() {
    if (this.cancelTokenSettlements) {
      this.cancelTokenSettlements.cancel();
      this.cancelTokenSettlements = undefined;
    }
  }
}

export default new SettlementsApi();
