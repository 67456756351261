import { createSelector } from 'reselect';

import _Store from '@Store';

import { IMeta } from '@Services/$users-api/types';

import { IUsersReducer } from '../types';
import get from './get';

const getMetaUsers = createSelector<_Store.IState, IUsersReducer, IMeta>(
  [get],
  (users) => users.meta.users
);

export default getMetaUsers;
