import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IAttachTypes } from '@Compo/Discounts/components/EventDiscountModal/components/AttachModal/AttachModal.types';
import { IEventDiscountValues } from '@Compo/Discounts/components/EventDiscountModal/components/EventDiscountForm/EventDiscountForm.types';
import { IDiscountBody } from '@Compo/reservations/Add/components/FormLayout/Basket/Basket.types';
import { IGetSingleEventDiscountResponse } from '@Services/$discount-api/types';
import {
  IEventsPartnerResponse,
  IPartnersResponse,
  IPoolsPartnerResponse,
  IRundatesPartnerResponse,
} from '@Services/$discounts-api/types';
import {
  _CHECK_DISCOUNT_CODE_FAILURE,
  _CHECK_DISCOUNT_CODE_REQUEST,
  _CHECK_DISCOUNT_CODE_SUCCESS,
  _GET_EVENTS_PARTNER_FAILURE,
  _GET_EVENTS_PARTNER_REQUEST,
  _GET_EVENTS_PARTNER_SUCCESS,
  _GET_PARTNERS_FAILURE,
  _GET_PARTNERS_REQUEST,
  _GET_PARTNERS_SUCCESS,
  _GET_POOLS_PARTNER_FAILURE,
  _GET_POOLS_PARTNER_REQUEST,
  _GET_POOLS_PARTNER_SUCCESS,
  _GET_RUNDATES_PARTNER_FAILURE,
  _GET_RUNDATES_PARTNER_REQUEST,
  _GET_RUNDATES_PARTNER_SUCCESS,
  _GET_SINGLE_EVENT_DISCOUNT_FAILURE,
  _GET_SINGLE_EVENT_DISCOUNT_REQUEST,
  _GET_SINGLE_EVENT_DISCOUNT_SUCCESS,
  _RESET_STATE,
  _SAVE_FAILURE,
  _SAVE_NEW_DISCOUNT_FAILURE,
  _SAVE_NEW_DISCOUNT_REQUEST,
  _SAVE_NEW_DISCOUNT_SUCCESS,
  _SAVE_REQUEST,
  _SAVE_SUCCESS,
  ADD,
  CATCH_DEPENDENCY_VALUES,
  CATCH_SAVE_NEW_DISCOUNT,
  CHECK,
  CLOSE_EVENT_ADD,
  EDIT,
  EVENT_ADD,
  EVENT_DISCOUNT_EDIT,
  HANDLE_ACTIVE_PAGE,
  REMOVE,
  RESET_VALUE,
  SAVE,
} from '../constants/actions';
import { IDiscount, IHandlePagePayload } from '../types';

export const resetState = createStandardAction(_RESET_STATE)();
export const saveDiscount = createAsyncAction(
  _SAVE_REQUEST,
  _SAVE_SUCCESS,
  _SAVE_FAILURE
)<IDiscount, void, Error>();

export const checkDiscountCode = createAsyncAction(
  _CHECK_DISCOUNT_CODE_REQUEST,
  _CHECK_DISCOUNT_CODE_SUCCESS,
  _CHECK_DISCOUNT_CODE_FAILURE
)<IDiscountBody, any, Error>();

export const getPartners = createAsyncAction(
  _GET_PARTNERS_REQUEST,
  _GET_PARTNERS_SUCCESS,
  _GET_PARTNERS_FAILURE
)<undefined, IPartnersResponse, Error>();

export const getEventsPartner = createAsyncAction(
  _GET_EVENTS_PARTNER_REQUEST,
  _GET_EVENTS_PARTNER_SUCCESS,
  _GET_EVENTS_PARTNER_FAILURE
)<undefined, IEventsPartnerResponse, Error>();

export const getRundatesPartner = createAsyncAction(
  _GET_RUNDATES_PARTNER_REQUEST,
  _GET_RUNDATES_PARTNER_SUCCESS,
  _GET_RUNDATES_PARTNER_FAILURE
)<undefined, IRundatesPartnerResponse, Error>();

export const getPoolsPartner = createAsyncAction(
  _GET_POOLS_PARTNER_REQUEST,
  _GET_POOLS_PARTNER_SUCCESS,
  _GET_POOLS_PARTNER_FAILURE
)<undefined, IPoolsPartnerResponse, Error>();
export const saveNewDiscount = createAsyncAction(
  _SAVE_NEW_DISCOUNT_REQUEST,
  _SAVE_NEW_DISCOUNT_SUCCESS,
  _SAVE_NEW_DISCOUNT_FAILURE
)<IEventDiscountValues, [], Error>();
export const getSingleEventDiscount = createAsyncAction(
  _GET_SINGLE_EVENT_DISCOUNT_REQUEST,
  _GET_SINGLE_EVENT_DISCOUNT_SUCCESS,
  _GET_SINGLE_EVENT_DISCOUNT_FAILURE
)<number, IGetSingleEventDiscountResponse, Error>();

export const addDiscount = createStandardAction(ADD)();
export const eventAddDiscount = createStandardAction(
  EVENT_ADD
)<IEventDiscountValues | null>();
export const eventEditDiscount = createStandardAction(EVENT_DISCOUNT_EDIT)<
  number
>();
export const closeEventAddDiscount = createStandardAction(CLOSE_EVENT_ADD)();
export const checkDiscount = createStandardAction(CHECK)<IDiscountBody>();
export const editDiscount = createStandardAction(EDIT)<IDiscount>();
export const remove = createStandardAction(REMOVE)<string | null>();
export const save = createStandardAction(SAVE)<IDiscount>();
export const resetValue = createStandardAction(RESET_VALUE)();
export const catchDependencyValues = createStandardAction(
  CATCH_DEPENDENCY_VALUES
)<IAttachTypes>();

export const catchSaveNewDiscount = createStandardAction(
  CATCH_SAVE_NEW_DISCOUNT
)<IEventDiscountValues>();
export const handleActivePage = createStandardAction(HANDLE_ACTIVE_PAGE)<
  IHandlePagePayload
>();
