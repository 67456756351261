import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  _GET_EVENTS_FAILURE,
  _GET_EVENTS_REQUEST,
  _GET_EVENTS_SUCCESS,
  _GET_POOLS_FAILURE,
  _GET_POOLS_REQUEST,
  _GET_POOLS_SUCCESS,
  _UPDATE_SELECTED_SEATS,
  DESELECT_SEAT,
  MOUNTED,
  RESET_SELECTION,
  SEARCH_EVENT,
  SELECT_EVENT,
  SELECT_POOL,
  SELECT_SEAT,
} from '@Model/events/constants/actions';
import { INormalizedEvent } from '@Services/$events-api/types';
import { IPoolsResponse } from '@Services/$pools-api/types';

import {
  ISelectEventPayload,
  ISelectPoolPayload,
  ISelectSeatPayload,
  IUpdateSelectedSeatsPayload,
} from './../types';

export const mounted = createStandardAction(MOUNTED)();

export const selectEvent =
  createStandardAction(SELECT_EVENT)<ISelectEventPayload>();

export const selectPool =
  createStandardAction(SELECT_POOL)<ISelectPoolPayload>();

export const selectSeat =
  createStandardAction(SELECT_SEAT)<ISelectSeatPayload>();
export const deselectSeat =
  createStandardAction(DESELECT_SEAT)<ISelectSeatPayload>();
export const updateSelectedSeats = createStandardAction(
  _UPDATE_SELECTED_SEATS
)<IUpdateSelectedSeatsPayload>();
export const handleSearch = createStandardAction(SEARCH_EVENT)<string>();

export const resetSelection = createStandardAction(RESET_SELECTION)();

export const getPools = createAsyncAction(
  _GET_POOLS_REQUEST,
  _GET_POOLS_SUCCESS,
  _GET_POOLS_FAILURE
)<undefined, IPoolsResponse | null, Error>();

export const getEvents = createAsyncAction(
  _GET_EVENTS_REQUEST,
  _GET_EVENTS_SUCCESS,
  _GET_EVENTS_FAILURE
)<string | undefined, INormalizedEvent[], Error>();
