import React, { useState } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '@Compo/reusable/ConfirmModal';
import config from '@Config';
import { Colors } from '@Constants/Colors';
import { TypographyVariant } from '@Constants/Variants/Typography';

import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const FormLayout = ({
  handleSubmit,
  values,
  handleChange,
  submitForm,
  closeModal,
  singleTicket,
  deleteTicket,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'Rundates.TicketsList.components.FormLayout.components.PreviewTicket.FormLayout',
  });

  const classes = useStyles();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  if (!singleTicket) {
    return null;
  }

  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const openDeleteModal = () => setShowDeleteModal(true);
  const handleConfirmDeleteModal = () => {
    deleteTicket(singleTicket.id);
    setShowDeleteModal(false);
    closeModal();
  };

  return (
    <>
      <ConfirmModal
        open={showDeleteModal}
        title={t('deleteModalTitle')}
        question={
          singleTicket.isInvitationSeatsIo
            ? t('deleteModalDescriptionSeats')
            : t('deleteModalDescription')
        }
        handleClose={handleCloseDeleteModal}
        onCancel={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteModal}
      />
      <DialogTitle>{t('ticketPreview')}</DialogTitle>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={6}>
            <Grid item={true} xs={12}>
              <Grid container={true} spacing={1} className={classes.infoItem}>
                <Grid item={true} xs={6}>
                  <Typography
                    variant={TypographyVariant.body1}
                    color={Colors.textSecondary}
                  >
                    {t('ticketCode')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant={TypographyVariant.body1}>
                    {singleTicket.entryToken}
                  </Typography>
                </Grid>

                <Grid item={true} xs={6}>
                  <Typography
                    variant={TypographyVariant.body1}
                    color={Colors.textSecondary}
                  >
                    {t('ticketCount')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant={TypographyVariant.body1}>
                    {singleTicket.ticketsNum}
                  </Typography>
                </Grid>

                <Grid item={true} xs={6}>
                  <Typography
                    variant={TypographyVariant.body1}
                    color={Colors.textSecondary}
                  >
                    {t('deliveryStatus')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant={TypographyVariant.body1}>
                    {singleTicket.deliveryStatus}
                  </Typography>
                </Grid>

                <Grid item={true} xs={6}>
                  <Typography
                    variant={TypographyVariant.body1}
                    color={Colors.textSecondary}
                  >
                    {t('firstName')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant={TypographyVariant.body1}>
                    {singleTicket.firstname}
                  </Typography>
                </Grid>

                <Grid item={true} xs={6}>
                  <Typography
                    variant={TypographyVariant.body1}
                    color={Colors.textSecondary}
                  >
                    {t('lastName')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant={TypographyVariant.body1}>
                    {singleTicket.lastname}
                  </Typography>
                </Grid>

                <Grid item={true} xs={6}>
                  <Typography
                    variant={TypographyVariant.body1}
                    color={Colors.textSecondary}
                  >
                    {t('email')}
                  </Typography>
                </Grid>
                <Grid item={true} xs={6}>
                  <Typography variant={TypographyVariant.body1}>
                    {singleTicket.email}
                  </Typography>
                </Grid>

                {singleTicket.additionalInfo && (
                  <>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('additionalInfo')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      {singleTicket.additionalInfo
                        .split('<br>')
                        .map((text, index) => (
                          <Typography
                            key={index}
                            variant={TypographyVariant.body1}
                          >
                            {text}
                          </Typography>
                        ))}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item={true} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={values.verified}
                    onChange={handleChange}
                    color="primary"
                    size="medium"
                    name="verified"
                  />
                }
                label={
                  <>
                    <Typography variant={TypographyVariant.body1}>
                      {t('validated')}
                    </Typography>
                    <Typography
                      variant={TypographyVariant.caption}
                      color={Colors.textSecondary}
                    >
                      {t('validatedHelper')}
                    </Typography>
                  </>
                }
              />
            </Grid>

            {config.cms.showResendTicketTicketList && (
              <Grid item={true} xs={12}>
                <Button
                  className={classes.button}
                  variant="outlined"
                  size="small"
                  color="secondary"
                >
                  {t('resendTicket')}
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <Grid container={true}>
          <Grid item={true} xs={true} className={classes.buttonsContainer}>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClick={openDeleteModal}
            >
              {t('delete')}
            </Button>
          </Grid>
          <Grid item={true}>
            <Grid container={true} spacing={2}>
              <Grid item={true}>
                <Button
                  variant="outlined"
                  size="large"
                  color="secondary"
                  onClick={closeModal}
                  type="submit"
                >
                  {t('cancel')}
                </Button>
              </Grid>
              <Grid item={true}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={submitForm}
                >
                  {t('save')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default FormLayout;
