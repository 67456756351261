import _ from 'lodash';

import { ISingleUser } from '@Model/formio/types';

const customizer = (objValue: ISingleUser, othValue: ISingleUser) => {
  return _.isEqual(objValue, othValue);
};

export const uniqueUsers = (arr: ISingleUser[]) => {
  return _.uniqWith(arr, (arrVal, othVal) =>
    _.isEqualWith(arrVal, othVal, customizer)
  );
};
