import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import FormLayout from './FormLayout';
import { ITestEmailModalProps, ITestEmailValues } from './TestEmailModal.types';
import { TestEmailSchema } from './TestEmailModal.validation';

const TestEmailModal = ({
  open,
  closeModal,
  values,
  sendTestMailing,
}: ITestEmailModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Mail.components.FormLayout.components.TestEmailModal',
  });

  const catchHandleSubmit = (formValues: ITestEmailValues) => {
    if (values.id) {
      sendTestMailing({ id: values.id, email: formValues.email });
      closeModal();
    }
  };

  const initialValues: ITestEmailValues = {
    email: '',
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={catchHandleSubmit}
        validationSchema={TestEmailSchema}
        children={(props) => <FormLayout {...props} closeModal={closeModal} />}
      />
    </Dialog>
  );
};

export default TestEmailModal;
