import { createSelector } from 'reselect';

import _Store from '@Store';

import { IMeta } from '@Services/$discounts-api/types';

import { IDiscountReducer } from './../types';
import get from './get';

const getMeta = createSelector<_Store.IState, IDiscountReducer, IMeta>(
  [get],
  (discount) => {
    return discount.meta;
  }
);

export default getMeta;
