// Public
export const ADD_MOUNTED = 'Zagrywki/reservation/MOUNTED';
export const SELECT_HAPPENING = 'Zagrywki/reservation/SELECT_HAPPENING';
export const SELECT_SPACE = 'Zagrywki/reservation/SELECT_SPACE';
export const SELECT_PEOPLE_COUNT = 'Zagrywki/reservation/SELECT_PEOPLE_COUNT';
export const SELECT_TIME_SLOT = 'Zagrywki/reservation/SELECT_TIME_SLOT';
export const SET_DATE = 'Zagrywki/reservation/SET_DATE';
export const SELECT_DISCOUNT = 'Zagrywki/reservation/SELECT_DISCOUNT';
export const RESET = 'Zagrywki/reservation/RESET';
export const SAVE_RESERVATION = 'Zagrywki/reservation/SAVE_RESERVATION';
export const SUMMARY_MOUNTED = 'Zagrywki/reservation/SUMMARY_MOUNTED';
export const SAVE_BILL = 'Zagrywki/reservation/SAVE_BILL';

export const TRANSACTION_RESET = 'Zagrywki/reservation/TRANSACTION_RESET';
export const TRANSACTION_SAVE = 'Zagrywki/reservation/TRANSACTION_SAVE';
export const GET_COMPANY_DATA = 'Zagrywki/reservation/GET_COMPANY_DATA';
export const SET_COMPANY_DATA = 'Zagrywki/reservation/SET_COMPANY_DATA';
export const SET_FORM_DATA = 'Zagrywki/reservation/SET_FORM_DATA';
export const SET_UP_SELL = 'Zagrywki/reservation/SET_UP_SELL';
export const SET_PRICE = 'Zagrywki/reservation/SET_PRICE';
export const CLEAR_PRINT_DATA = 'Zagrywki/reservation/CLEAR_PRINT_DATA';
export const SELECT_ADVANCE_TRANSACTION =
  'Zagrywki/reservation/SELECT_ADVANCE_TRANSACTION';
export const RETRY_BOCA_PRINT = 'Zagrywki/reservation/RETRY_BOCA_PRINT';

export const SET_DURATION_TIME_AFTER_MIDNIGHT =
  'Zagrywki/reservation/SET_DURATION_TIME_AFTER_MIDNIGHT';
export const COMPLETE_ADVANCE_PAYMENT =
  'Zagrywki/reservation/COMPLETE_ADVANCE_PAYMENT';
export const CLEAR_ADVANCE_PAYMENT =
  'Zagrywki/reservation/CLEAR_ADVANCE_PAYMENT';

// Private
export const _SET_TIME_SLOT = 'Zagrywki/reservation/_SET_TIME_SLOT';
export const SET_EXTENDED_SLOT = 'Zagrywki/reservation/SET_EXTENDED_SLOT';
export const _POST_RESERVATION_REQUEST =
  'Zagrywki/reservation/_POST_RESERVATION_REQUEST';
export const _POST_RESERVATION_SUCCESS =
  'Zagrywki/reservation/_POST_RESERVATION_SUCCESS';
export const _POST_RESERVATION_FAILURE =
  'Zagrywki/reservation/_POST_RESERVATION_FAILURE';
export const _POST_ADVANCE_PAYMENT_RESERVATION_REQUEST =
  'Zagrywki/reservation/_POST_ADVANCE_PAYMENT_RESERVATION_REQUEST';
export const _POST_ADVANCE_PAYMENT_RESERVATION_SUCCESS =
  'Zagrywki/reservation/_POST_ADVANCE_PAYMENT_RESERVATION_SUCCESS';
export const _POST_ADVANCE_PAYMENT_RESERVATION_FAILURE =
  'Zagrywki/reservation/_POST_ADVANCE_PAYMENT_RESERVATION_FAILURE';
export const CANCEL_TRANSACTION = 'Zagrywki/reservation/CANCEL_TRANSACTION';
export const CANCEL_AUTO_TRANSACTION =
  'Zagrywki/reservation/CANCEL_AUTO_TRANSACTION';

export const _POST_EMPTY_RESERVATION_REQUEST =
  'Zagrywki/reservation/_POST_EMPTY_RESERVATION_REQUEST';
export const _POST_EMPTY_RESERVATION_SUCCESS =
  'Zagrywki/reservation/_POST_EMPTY_RESERVATION_SUCCESS';
export const _POST_EMPTY_RESERVATION_FAILURE =
  'Zagrywki/reservation/_POST_EMPTY_RESERVATION_FAILURE';

export const _CAPTURE_TRANSACTION_DETAILS_REQUEST =
  'Zagrywki/reservation/_CAPTURE_TRANSACTION_DETAILS_REQUEST';
export const _CAPTURE_TRANSACTION_DETAILS_SUCCESS =
  'Zagrywki/reservation/_CAPTURE_TRANSACTION_DETAILS_SUCCESS';
export const _CAPTURE_TRANSACTION_DETAILS_FAILURE =
  'Zagrywki/reservation/_CAPTURE_TRANSACTION_DETAILS_FAILURE';

export const _CAPTURE_TRANSACTION_EMPIK_DETAILS_REQUEST =
  'Zagrywki/reservation/_CAPTURE_TRANSACTION_EMPIK_DETAILS_REQUEST';
export const _CAPTURE_TRANSACTION_EMPIK_DETAILS_SUCCESS =
  'Zagrywki/reservation/_CAPTURE_TRANSACTION_EMPIK_DETAILS_SUCCESS';
export const _CAPTURE_TRANSACTION_EMPIK_DETAILS_FAILURE =
  'Zagrywki/reservation/_CAPTURE_TRANSACTION_EMPIK_DETAILS_FAILURE';

export const _GET_COMPANY_DATA_REQUEST =
  'Zagrywki/reservation/_GET_COMPANY_DATA_REQUEST';
export const _GET_COMPANY_DATA_SUCCESS =
  'Zagrywki/reservation/_GET_COMPANY_DATA_SUCCESS';
export const _GET_COMPANY_DATA_FAILURE =
  'Zagrywki/reservation/_GET_COMPANY_DATA_FAILURE';

export const _GET_RESERVATION_PRINT_DATA_REQUEST =
  'Zagrywki/printer/_GET_RESERVATION_PRINT_DATA_REQUEST';
export const _GET_RESERVATION_PRINT_DATA_SUCCESS =
  'Zagrywki/printer/_GET_RESERVATION_PRINT_DATA_SUCCESS';
export const _GET_RESERVATION_PRINT_DATA_FAILURE =
  'Zagrywki/printer/_GET_RESERVATION_PRINT_DATA_FAILURE';

export const _GET_DEPENDENCY_TICKET_REQUEST =
  'Zagrywki/printer/_GET_DEPENDENCY_TICKET_REQUEST';
export const _GET_DEPENDENCY_TICKET_SUCCESS =
  'Zagrywki/printer/_GET_DEPENDENCY_TICKET_SUCCESS';
export const _GET_DEPENDENCY_TICKET_FAILURE =
  'Zagrywki/printer/_GET_DEPENDENCY_TICKET_FAILURE';
