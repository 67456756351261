import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import useStyles from './BooleanLabel.styles';
import { IBooleanLabelProps } from './BooleanLabel.types';

const BooleanLabel = ({ isTrue, isColorDisabled }: IBooleanLabelProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reusable.BooleanLabel',
  });

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {isTrue ? (
        <DoneIcon
          className={cn(
            classes.doneIcon,
            isColorDisabled ? classes.disabledIconColor : classes.doneIconColor
          )}
        />
      ) : (
        <CloseIcon
          className={cn(
            classes.closeIcon,
            isColorDisabled ? classes.disabledIconColor : classes.closeIconColor
          )}
        />
      )}
      {isTrue ? (
        <Typography
          component={'span'}
          variant={'body2'}
          color={isColorDisabled ? 'disabled' : 'textPrimary'}
        >
          {t('yes')}
        </Typography>
      ) : (
        <Typography
          component={'span'}
          variant={'body2'}
          color={isColorDisabled ? 'disabled' : 'textSecondary'}
        >
          {t('no')}
        </Typography>
      )}
    </div>
  );
};

export default BooleanLabel;
