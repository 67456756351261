import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { checkPrice, getPrice } from '@Model/price/actions';
import { getItems } from '@Model/products/selectors';
import { getPriceReduction } from '@Model/reservation/epics';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import {
  IPriceBody,
  IReservationCheckPrice,
  ITicket,
} from '@Services/$price-api/types';

export const requestCheckPrice: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(getPrice)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const getReservationsFromBasket = (): IReservationCheckPrice[] => {
        const { basketItems, discountCode } = action.payload;

        const itemsIReservation: IReservationCheckPrice[] = [];

        if (basketItems && basketItems.length) {
          basketItems.forEach((item) => {
            if (!item.isEvent) {
              if (item.selectedPrices && item.selectedPrices.length) {
                const grouperSelectedPrices: { [_: string]: number } = {};

                if (item.calculatePricePerPerson) {
                  item.selectedPrices.forEach((selectedPrice) => {
                    if (grouperSelectedPrices[selectedPrice]) {
                      grouperSelectedPrices[selectedPrice] += 1;
                    } else {
                      grouperSelectedPrices[selectedPrice] = 1;
                    }
                  });

                  Object.keys(grouperSelectedPrices).map((property) => {
                    itemsIReservation.push({
                      dateTime: item.dateTime,
                      extendedDuration: item.extendedDuration || undefined,
                      happeningId: item.happeningId,
                      numberOfPeople: grouperSelectedPrices[property],
                      priceReduction: getPriceReduction(
                        discountCode || '',
                        !!item.isUpSellSelected,
                        item.price,
                        item.dateTime || '',
                        item.numberOfPeople,
                        [],
                        item.spaceId,
                        item.configurationId,
                        property,
                        item.happeningId
                      ),
                      priceType: property,
                      spaceId: item.spaceId,
                    });
                  });
                } else {
                  itemsIReservation.push({
                    dateTime: item.dateTime,
                    extendedDuration: item.extendedDuration || undefined,
                    happeningId: item.happeningId,
                    numberOfPeople: item.numberOfPeople,
                    priceReduction: getPriceReduction(
                      discountCode || '',
                      !!item.isUpSellSelected,
                      item.price,
                      item.dateTime || '',
                      item.numberOfPeople,
                      [],
                      item.spaceId,
                      item.configurationId,
                      item.selectedPrices[0] || item.priceType,
                      item.happeningId
                    ),
                    priceType: item.selectedPrices[0] || item.priceType,
                    spaceId: item.spaceId,
                  });
                }
              } else {
                itemsIReservation.push({
                  dateTime: item.dateTime,
                  extendedDuration: item.extendedDuration || undefined,
                  happeningId: item.happeningId,
                  numberOfPeople: item.numberOfPeople,
                  priceReduction: getPriceReduction(
                    discountCode || '',
                    !!item.isUpSellSelected,
                    item.price,
                    item.dateTime || '',
                    item.numberOfPeople,
                    [],
                    item.spaceId,
                    item.configurationId,
                    item.priceType,
                    item.happeningId
                  ),
                  priceType: item.priceType,
                  spaceId: item.spaceId,
                });
              }
            }
          });
        }
        return itemsIReservation;
      };

      const getTicketsFromBasket = () => {
        const itemsIReservation: ITicket[] = [];
        const { basketItems } = action.payload;
        basketItems.forEach((item) => {
          if (item.isEvent && item.poolId) {
            itemsIReservation.push({
              poolId: item.poolId,
              seats: item.seats,
              ticketsNum: item.numberOfPeople,
            });
          }
        });

        return itemsIReservation;
      };

      const body: IPriceBody = {
        agent: 'zagrywki-onsite',
        products: getItems(state).map((item) => ({
          id: item.id,
          quantity: item.count || 0,
        })),
        reservations: getReservationsFromBasket(),
        salesChannelId: 12,
        tickets: getTicketsFromBasket(),
        user: {
          email: 'sprzedaz@goingapp.pl',
          empikCardNumber: null,
          empikPremiumJWT: null,
          facebookId: null,
          firstName: '-',
          lastName: '-',
          terms: true,
        },
      };
      return of$(checkPrice.request(body));
    })
  );
};

export const checkPriceWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { priceApi }
) => {
  return action$.pipe(
    filter$(isActionOf(checkPrice.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(priceApi.getPrice(action.payload)).pipe(
        mergeMap$((data) => {
          if (data.status !== 0) {
            return of$(addToast(data.message, TYPE_ERROR));
          }
          return of$(checkPrice.success(data));
        }),
        catchError$((error: Error) => {
          return of$(checkPrice.failure(error));
        })
      );
    })
  );
};
