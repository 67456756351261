import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { selectTicket } from '@Model/going/rundates/actions';
import { getSelectedTicket } from '@Model/going/rundates/selectors';

import TicketModal from './TicketModal.component';
import {
  ITicketModalFromDispatch,
  ITicketModalFromState,
} from './TicketModal.types';

const mapStateToProps = (state: _Store.IState): ITicketModalFromState => ({
  showModal: !!getSelectedTicket(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITicketModalFromDispatch => ({
  closeModal: () => dispatch(selectTicket(null)),
});

export default connect<
  ITicketModalFromState,
  ITicketModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(TicketModal);
