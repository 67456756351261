import React, { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Grid, IconButton, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Events } from '@Constants/Events';
import { useActiveElement } from '@Misc/hooks';

import { ISearchFieldProps } from './SearchField.types';

const SearchField = ({
  handleSearch,
  selectEvent,
  searchParam,
  setSearchParam,
}: ISearchFieldProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'reservations.Add.components.FormLayout.EventsList.components.SearchField',
  });

  const ref = useRef<HTMLInputElement | null>(null);
  const { active: focusedElement } = useActiveElement();

  const onSearchChanged = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchParam(e.target.value);
  };

  const handleSearchClicked = () => {
    selectEvent(null);
    handleSearch(searchParam);
  };

  const handleElectronKeyboardSearch = () => {
    if (focusedElement === ref.current) {
      selectEvent(null);
      handleSearch(searchParam);
    }
  };

  const handleSearchEnterDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchClicked();
    }
  };

  useEffect(() => {
    document.body.addEventListener(
      Events.onElectronKeyboardEnterClicked,
      handleElectronKeyboardSearch
    );

    return () => {
      document.body.removeEventListener(
        Events.onElectronKeyboardEnterClicked,
        handleElectronKeyboardSearch
      );
    };
  }, [searchParam, focusedElement]);

  return (
    <Grid item={true} xs={12}>
      <Grid container={true} justifyContent="flex-end">
        <TextField
          variant="standard"
          name="search"
          helperText={t('search')}
          value={searchParam}
          onChange={onSearchChanged}
          onKeyDown={handleSearchEnterDown}
          inputRef={ref}
          InputProps={{
            endAdornment: (
              <IconButton
                color="secondary"
                size="small"
                onClick={handleSearchClicked}
              >
                <SearchIcon fontSize="medium" color="secondary" />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SearchField;
