import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IConfirmModalProps } from './ConfirmModal';

const ConfirmModal = ({
  onCancel,
  onConfirm,
  question,
  title,
  handleClose,
  open,
}: IConfirmModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reusable.ConfirmModal',
  });

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={'sm'}
      fullWidth={true}
      scroll={'paper'}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{question}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={'outlined'}
          size={'large'}
          color={'secondary'}
          onClick={onCancel}
        >
          {t('no')}
        </Button>
        <Button
          variant={'contained'}
          size={'large'}
          color={'primary'}
          onClick={onConfirm}
        >
          {t('yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
