import React from 'react';

import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TypographyVariant } from '@Constants/Variants/Typography';

import useStyles from './Filters.styles';
import { IFiltersProps } from './Filters.types';

export const Filters = ({
  handleSubmit,
  values,
  handleChange,
}: IFiltersProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Transactions.components.Filters',
  });

  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container={true} spacing={4}>
        <Grid item={true}>
          <FormControlLabel
            control={
              <Switch
                checked={values.billExist}
                onChange={handleChange}
                name="billExist"
                color="primary"
              />
            }
            label={
              <Typography variant={TypographyVariant.body1}>
                {t('showBillExist')}
              </Typography>
            }
          />
        </Grid>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12}>
            <Divider className={classes.divider} />
          </Grid>
          <Grid item={true} xs={12}>
            <Grid container={true} justifyContent="flex-end">
              <Button
                type="submit"
                size="large"
                variant="contained"
                color="primary"
              >
                {t('submit')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Filters;
