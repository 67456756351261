import moment from 'moment';
import { RRule } from 'rrule';

const checkIsEndAfterMidnight = (
  rrule: string,
  duration: number,
  validTo: Date | null,
  initialValidTo: Date | null
) => {
  const options = RRule.fromString(rrule).origOptions;

  if (
    initialValidTo &&
    validTo &&
    moment(initialValidTo).toDate().getTime() ===
      moment(validTo).toDate().getTime()
  ) {
    return false;
  }

  if (
    typeof options.byhour === 'number' &&
    typeof options.byminute === 'number'
  ) {
    const nextDate = moment().toDate();

    nextDate.setHours(options.byhour);
    nextDate.setMinutes(options.byminute);

    const helpDate = moment(nextDate).add(duration, 'seconds');
    if (moment(helpDate).isSame(moment(validTo), 'day')) {
      return false;
    }
    const isSameDay = moment(nextDate).isSame(helpDate, 'day');
    return !isSameDay;
  }

  return false;
};

export default checkIsEndAfterMidnight;
