import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { deselectSeat, selectSeat } from '@Model/events/actions';
import { getAvailablePools, getPricing } from '@Model/events/selectors';
import _Store from '@Store';
import EventsSeats from './EventsSeats.component';
import {
  IEventsSeatsFromDispatch,
  IEventsSeatsFromState,
} from './EventsSeats.types';

const mapStateToProps = (state: _Store.IState): IEventsSeatsFromState => ({
  pools: getAvailablePools(state),
  pricing: getPricing(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IEventsSeatsFromDispatch => ({
  onSeatsDeselected: (seat) => dispatch(deselectSeat(seat)),
  onSeatsSelected: (seat) => dispatch(selectSeat(seat)),
});

export default connect<
  IEventsSeatsFromState,
  IEventsSeatsFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(EventsSeats);
