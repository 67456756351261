import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { catchSaveConfigurationV2 } from '@Model/configurations/actions';
import { get as getConfigurations } from '@Model/configurations/selectors';
import { IConfiguration } from '@Model/configurations/types';
import { setFormRulesData } from '@Model/formRules/actions';
import { getFormRules } from '@Model/formRules/selectors';
import { getHappeningPartnerId } from '@Model/happenings/selectors';
import _Store from '@Store';
import TimeRule from './TimeRule.component';
import { prepareInitialValues } from './TimeRule.helpers';
import { ITimeRuleFromDispatch, ITimeRuleFromState } from './TimeRule.types';

const mapStateToProps = (state: _Store.IState): ITimeRuleFromState => {
  const { activeConfiguration } = getConfigurations(state);
  const formValues = getFormRules(state);

  return {
    activeConfiguration,
    initialValues: prepareInitialValues(activeConfiguration || formValues),
    partnerId: getHappeningPartnerId(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITimeRuleFromDispatch => {
  return {
    catchSaveConfiguration: (configurations: {
      configuration: IConfiguration;
      type: string;
    }) => {
      dispatch(catchSaveConfigurationV2(configurations));
    },
    setFormRulesData: (values) => {
      dispatch(setFormRulesData(values));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TimeRule);
