import { createSelector } from 'reselect';

import { IEventMailing } from '@Services/$email-api/types';
import _Store from '@Store';
import { IMailingReducer } from './../types';
import get from './get';

const getMailing = createSelector<
  _Store.IState,
  IMailingReducer,
  IEventMailing[]
>([get], (mailing) => mailing.data);

export default getMailing;
