import { IThermalPrinter } from './../types';

class BocaThermalPrinter implements IThermalPrinter {
  public buffer: Buffer;

  constructor() {
    this.buffer = Buffer.from('                              ');

    this.setRotation(0);
    this.setPosition(0, 0);
    this.setFont('13');
  }

  public append(buff: string | Buffer) {
    if (typeof buff === 'string') {
      this.buffer = Buffer.concat([this.buffer, Buffer.from(buff)]);
    } else {
      this.buffer = Buffer.concat([this.buffer, buff]);
    }
  }

  public printBMP(imageBuffer: Buffer, x = 0, y = 0) {
    this.printImage(imageBuffer, 'bmp', x, y);
  }

  public printPCX(imageBuffer: Buffer, x: number = 0, y: number = 0) {
    this.printImage(imageBuffer, 'pcx', x, y);
  }

  public printImage(
    imageBuffer: Buffer,
    type: string,
    x: number = 0,
    y: number = 0
  ) {
    this.append(`<SP${x},${y}><${type}><G${imageBuffer.length}>`);
    this.append(imageBuffer);
  }

  public printQR(text: string, x?: number, y?: number, options = {}) {
    this.setPosition(x, y);
    const defaults = {
      encodeMode: 0,
      errorCorrection: 0,
      pointSize: 6,
      tilde: 0,
      version: 2,
    };

    const settings = Object.assign({}, defaults, options);
    // tslint:disable-next-line: no-bitwise
    const tilde = settings.tilde & 1;

    this.append(
      `<QRV${settings.version}><QR${settings.pointSize},${tilde},${settings.encodeMode},${settings.errorCorrection}>{${text}}`
    );
  }

  public setPosition(x?: number, y?: number) {
    if (x === null || y === null) {
      return;
    }

    this.append(`<RC${y},${x}>`);
  }

  public setRotation(rotation?: number) {
    switch (rotation) {
      case 0:
        this.append('<NR>');
        break;
      case 90:
        this.append('<RR>');
        break;
      case 180:
        this.append('<RU>');
        break;
      case 270:
        this.append('<RL>');
        break;
      default:
        break;
    }
  }

  public setFont(font?: string) {
    this.append(`<F${font}>`);
  }

  public printText(
    text: string,
    x?: number,
    y?: number,
    rotation?: number,
    font?: string
  ) {
    this.setPosition(x, y);
    this.setRotation(rotation);
    this.setFont(font);
    this.append(text);
    this.setRotation(0);
  }

  public cut() {
    this.append('<p>');
  }

  public nextPage() {
    this.append('<q>');
  }
}

export default BocaThermalPrinter;
