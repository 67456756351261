import React from 'react';

import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import MetadataModal from '@Compo/reusable/MetadataModal';
import { ISelectedItems } from '@Compo/reusable/MetadataModal/components/AttachModal/AttachModal.types';
import MuiDatePicker from '@Compo/reusable/MuiDatePicker';

import useStyles from './Filters.styles';
import { IFiltersProps } from './Filters.types';

export const Filters = ({
  handleSubmit,
  values,
  handleChange,
  setFieldValue,
  handleBlur,
  touched,
  errors,
  settlementMetadata,
  clearFilterSettlement,
}: IFiltersProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Settlements.Filters',
  });

  const classes = useStyles();

  const handleChangeAutocomplete = (
    name: keyof typeof values,
    value: string[] | null
  ) => {
    if (name === 'createdBy' || name === 'acceptedBy') {
      setFieldValue(name, value);
    }
  };

  const setValueRundate = (selectedItems: ISelectedItems) => {
    setFieldValue('rundates', selectedItems.rundate);
  };

  const acceptedValues = [
    { value: 'undefined', label: t('acceptedValuesUndefined') },
    { value: 'true', label: t('acceptedValuesTrue') },
    { value: 'false', label: t('acceptedValuesFalse') },
  ];

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12}>
          <FormControl
            variant="standard"
            fullWidth={true}
            error={!!(touched.accepted && errors.accepted)}
          >
            <InputLabel>{touched.accepted && errors.accepted}</InputLabel>
            <Select
              variant="standard"
              value={values.accepted}
              onChange={handleChange}
              defaultValue={values.accepted}
              fullWidth={true}
              name="accepted"
              onBlur={handleBlur}
            >
              {acceptedValues.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{t('acceptedHelper')}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item={true} xs={12}>
          <Typography variant="body1">{t('scopeOfCreationTitle')}</Typography>
        </Grid>
        <Grid item={true} xs={12} lg={6}>
          <MuiDatePicker
            name="createdAtFrom"
            disableToolbar={true}
            fullWidth={true}
            variant="standard"
            format="DD.MM.yyyy"
            label={
              (touched.createdAtFrom && errors.createdAtFrom) || t('startDate')
            }
            helperText={t('createdAtFromHelper')}
            value={values.createdAtFrom}
            onBlur={handleBlur}
            onChange={(date) => setFieldValue('createdAtFrom', date)}
            error={!!(touched.createdAtFrom && errors.createdAtFrom)}
          />
        </Grid>
        <Grid item={true} xs={12} lg={6}>
          <MuiDatePicker
            name="createdAtTo"
            disableToolbar={true}
            fullWidth={true}
            variant="standard"
            format="DD.MM.yyyy"
            label={(touched.createdAtTo && errors.createdAtTo) || t('endDate')}
            helperText={t('createdAtToHelper')}
            value={values.createdAtTo}
            onBlur={handleBlur}
            onChange={(date) => setFieldValue('createdAtTo', date)}
            error={!!(touched.createdAtTo && errors.createdAtTo)}
          />
        </Grid>
        <Grid item={true} xs={12} lg={6}>
          <FormControl
            variant="standard"
            fullWidth={true}
            error={!!(touched.createdBy && errors.createdBy)}
          >
            <Autocomplete
              multiple={true}
              disableCloseOnSelect={true}
              options={settlementMetadata?.filters.createdByEmails || []}
              // defaultValue={values.createdBy}
              onChange={(event, value) =>
                handleChangeAutocomplete('createdBy', value)
              }
              onBlur={handleBlur}
              fullWidth={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  error={!!(touched.createdBy && errors.createdBy)}
                  label={
                    (touched.createdBy && errors.createdBy) || t('createdBy')
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="secondary"
                    label={option}
                    {...getTagProps({ index })}
                    className={classes.chip}
                    key={index}
                  />
                ))
              }
            />
            <FormHelperText>{t('createdByHelper')}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item={true} xs={12} lg={6}>
          <FormControl
            variant="standard"
            fullWidth={true}
            error={!!(touched.acceptedBy && errors.acceptedBy)}
          >
            <Autocomplete
              multiple={true}
              disableCloseOnSelect={true}
              options={settlementMetadata?.filters.acceptedByEmails || []}
              // defaultValue={values.acceptedBy}
              onChange={(event, value) =>
                handleChangeAutocomplete('acceptedBy', value)
              }
              onBlur={handleBlur}
              fullWidth={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  error={!!(touched.acceptedBy && errors.acceptedBy)}
                  label={
                    (touched.acceptedBy && errors.acceptedBy) || t('acceptedBy')
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="secondary"
                    label={option}
                    {...getTagProps({ index })}
                    className={classes.chip}
                    key={index}
                  />
                ))
              }
            />
            <FormHelperText>{t('acceptedByHelper')}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item={true} xs={12}>
          <Typography variant="body1">
            {t('scopeOfSettlementsTitle')}
          </Typography>
        </Grid>
        <Grid item={true} xs={12} lg={6}>
          <MuiDatePicker
            name="startDate"
            disableToolbar={true}
            fullWidth={true}
            variant="standard"
            format="DD.MM.yyyy"
            label={(touched.startDate && errors.startDate) || t('startDate')}
            helperText={t('startDateHelper')}
            value={values.startDate}
            onBlur={handleBlur}
            onChange={(date) => setFieldValue('startDate', date)}
            error={!!(touched.startDate && errors.startDate)}
          />
        </Grid>
        <Grid item={true} xs={12} lg={6}>
          <MuiDatePicker
            name="endDate"
            disableToolbar={true}
            fullWidth={true}
            variant="standard"
            format="DD.MM.yyyy"
            label={(touched.endDate && errors.endDate) || t('endDate')}
            helperText={t('endDateHelper')}
            value={values.endDate}
            onBlur={handleBlur}
            onChange={(date) => setFieldValue('endDate', date)}
            error={!!(touched.endDate && errors.endDate)}
          />
        </Grid>
        <Grid item={true} xs={12} lg={6}>
          <FormControl
            variant="standard"
            fullWidth={true}
            error={!!(touched.settlementType && errors.settlementType)}
          >
            <InputLabel>
              {(touched.settlementType && errors.settlementType) ||
                t('settlementsType')}
            </InputLabel>
            <Select
              variant="standard"
              value={values.settlementType}
              onChange={handleChange}
              defaultValue={'undefined'}
              fullWidth={true}
              name="settlementType"
              onBlur={handleBlur}
            >
              <MenuItem key={'undefined'} value={'undefined'}>
                {t('settlementsTypeUndefinedValue')}
              </MenuItem>
              {settlementMetadata &&
                settlementMetadata.settlementTypes.map((settlementType) => (
                  <MenuItem key={settlementType.id} value={settlementType.id}>
                    {settlementType.name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{t('settlementsTypeHelper')}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item={true} xs={12} lg={6}>
          <FormControl
            variant="standard"
            fullWidth={true}
            error={!!(touched.salesChannel && errors.salesChannel)}
          >
            <InputLabel>
              {(touched.salesChannel && errors.salesChannel) ||
                t('salesChannel')}
            </InputLabel>
            <Select
              variant="standard"
              value={values.salesChannel || 0}
              onChange={handleChange}
              defaultValue={0}
              fullWidth={true}
              name="salesChannel"
              onBlur={handleBlur}
            >
              {settlementMetadata &&
                settlementMetadata.salesChannels.map((salesChannel) => (
                  <MenuItem key={salesChannel.id} value={salesChannel.id}>
                    {salesChannel.name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{t('salesChannelHelper')}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item={true} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.freeTickets}
                onChange={handleChange}
                name="freeTickets"
                color="primary"
                size="small"
              />
            }
            label={
              <Typography variant={'body1'} color="textPrimary">
                {t('freeTickets')}
              </Typography>
            }
          />
        </Grid>
        <Grid item={true} xs={12}>
          <MetadataModal
            getSelectedItems={(selectedItems: ISelectedItems) =>
              setValueRundate(selectedItems)
            }
            selectedItems={{ rundate: values.rundates }}
            usingMetadata={{ rundate: true }}
            isFilterByActivePartner={true}
          />
        </Grid>
        <Grid item={true} xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item={true} xs={12}>
          <Grid container={true} justifyContent="flex-end">
            <Button
              onClick={() => clearFilterSettlement()}
              type="button"
              size="large"
              variant="text"
              color="primary"
            >
              {t('clear')}
            </Button>
            <Button
              type="submit"
              size="large"
              variant="contained"
              color="primary"
            >
              {t('submit')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default Filters;
