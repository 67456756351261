import React, { useEffect } from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import checkIsEndAfterMidnight from '@Misc/helpers/checkIsEndAfterMidnight';
import { IConfiguration, IFormValuesProps } from '@Model/configurations/types';

import * as helpers from '../../NewAddingConfiguration.helpers';
import FormLayout from './Components/FormLayout';
import { makeName, makeRRule } from './Components/FormLayout/FormLayout.helpes';
import { IConfigurationModalProps } from './ConfigurationModal.types';
import { AddingConfigurationSchema } from './ConfigurationModal.validation';

export const ConfigurationModal = ({
  activeConfiguration,
  catchSaveConfiguration,
  initialValues,
  catchGetRuleType,
  handleActiveConfiguration,
  partnerId,
}: IConfigurationModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'BetterManager.NewAddingConfiguration.components.ConfigurationModal',
  });

  useEffect(() => {
    if (initialValues.ruleType === 'price') {
      catchGetRuleType();
    }
  }, []);

  if (!activeConfiguration) {
    return null;
  }

  const catchHandleSubmit = (values: IFormValuesProps) => {
    const {
      id,
      price,
      durationToString,
      durationAdvanced,
      duration,
      advanced,
      title,
      isUpsell,
      closed,
      upsellValue,
      isPeopleCount,
      validTo,
      validFrom,
      ruleType,
      capacity,
      prices,
      until,
      cyclic,
      upsellExtended,
    } = values;

    const getDuration = () => {
      if (!advanced) {
        return {
          duration: durationNewValue || duration,
        };
      }
      return {
        duration: durationAdvanced,
      };
    };

    const getTitle = (): { title: string } => {
      if (title && title.length > 0) {
        return {
          title,
        };
      }

      return {
        title: makeName(values, makeRRule(values).toUpperCase()),
      };
    };

    const getPeopleCount = () => {
      const { fromNumberOfPeople, perPersonValue, perPersonType, priceGoesUp } =
        values;

      const getValue = () => {
        if (perPersonType === 'value') {
          return {
            percentageValue: null,
            value: Number(perPersonValue),
          };
        } else {
          return {
            percentageValue: Number(perPersonValue),
            value: null,
          };
        }
      };

      if (isPeopleCount) {
        return {
          fromNumberOfPeopleModifier: {
            fromNumberOfPeople: Number(fromNumberOfPeople),
            priceGoesUp: priceGoesUp === 'up',
            ...getValue(),
          },
        };
      }
      return {};
    };

    const durationNewValue = helpers.makeTimeFromDuration(durationToString);
    const rrule = makeRRule(values);

    const getPropsByPriceRule = () => {
      if (ruleType === 'price') {
        const getUpsalle = () => {
          const value = upsellValue || 0;

          if (isUpsell) {
            if (values.upsellType === 0) {
              return {
                upsell: {
                  percentageValue: null,
                  value,
                },
              };
            }
            return {
              upsell: {
                percentageValue: value,
                value: null,
              },
            };
          }

          return {};
        };

        return {
          prices,
          ...getUpsalle(),
          ...getPeopleCount(),
        };
      }
      return {};
    };

    const getPropsByTimeRule = () => {
      if (ruleType === 'time') {
        return {
          closed,
        };
      }
      return {};
    };

    const getPropsByCapacityRule = () => {
      if (ruleType === 'capacity') {
        return {
          capacity,
        };
      }
      return {};
    };

    const answer = {
      id: id || undefined,
      partnerId:
        partnerId && Number(partnerId) !== -1 && Number(partnerId) !== 0
          ? Number(partnerId)
          : null,
      rrule: makeRRule(values).toUpperCase(),
      upsellExtended: upsellExtended.map((upsell) => ({
        ...upsell,
        percentageValue: upsell.percentageValue
          ? Number(upsell.percentageValue)
          : null,
        slotIndex: Number(upsell.slotIndex),
        value: upsell.value ? Number(upsell.value) : null,
      })),
      validFrom,
      validTo:
        checkIsEndAfterMidnight(
          rrule,
          duration,
          validTo,
          initialValues.validTo
        ) && !cyclic
          ? initialValues.until
            ? moment(until).add(1, 'day')
            : moment(validTo).add(1, 'day')
          : validTo,
      ...getPropsByPriceRule(),
      ...getPropsByTimeRule(),
      ...getDuration(),
      ...getPropsByCapacityRule(),
      ...getTitle(),
    };

    if (values.closed) {
      answer.closed = true;
    }

    catchSaveConfiguration({
      configuration: answer as IConfiguration,
      type: ruleType,
    });
  };

  return (
    <Dialog
      open={activeConfiguration ? true : false}
      maxWidth="md"
      fullWidth={true}
      onClose={() => handleActiveConfiguration(null)}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={catchHandleSubmit}
        validationSchema={AddingConfigurationSchema}
        component={FormLayout}
      />
    </Dialog>
  );
};

export default ConfigurationModal;
