import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
  changePageOrCount,
  deletePriceType,
  mounted,
  selectPriceType,
} from '@Model/priceTypes/actions';
import {
  getCount,
  getPage,
  getPriceTypes,
  getTotalCount,
} from '@Model/priceTypes/selectors';
import { PRICE_TYPES } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import PriceTypes from './PriceTypes.component';

import {
  IPriceTypesFromDispatch,
  IPriceTypesFromState,
} from './PriceTypes.types';

const mapStateToProps = (state: _Store.IState): IPriceTypesFromState => {
  return {
    count: getCount(state),
    isLoading: getLoading(PRICE_TYPES)(state),
    page: getPage(state),
    priceTypes: getPriceTypes(state),
    totalCount: getTotalCount(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPriceTypesFromDispatch => {
  return {
    changePageOrCount: (body) => dispatch(changePageOrCount(body)),
    deletePriceType: (id) => dispatch(deletePriceType(id)),
    mounted: () => dispatch(mounted()),
    selectPriceType: (body) => dispatch(selectPriceType(body)),
  };
};

export default connect<
  IPriceTypesFromState,
  IPriceTypesFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(PriceTypes);
