import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { catchSaveFilter, handleActiveFilter } from '@Model/settings/actions';
import { getActiveFilter } from '@Model/settings/selectors';
import { _Store } from '@Store';
import ReportFilterModal from './ReportFilterModal.component';
import {
  IReportFilterModalFromDispatch,
  IReportFilterModalFromState,
} from './ReportFilterModal.types';

const mapStateToProps = (
  state: _Store.IState
): IReportFilterModalFromState => ({
  activeReportFilter: getActiveFilter(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IReportFilterModalFromDispatch => ({
  catchSaveFilter: (body) => dispatch(catchSaveFilter(body)),
  handleActiveReportFilter: (filter) => dispatch(handleActiveFilter(filter)),
});

export default connect<
  IReportFilterModalFromState,
  IReportFilterModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ReportFilterModal);
