import React from 'react';

import Grid from '@Compo/layout/Grid';
import Calendar from '@Compo/reservations/Calendar';

const CalendarPage = () => (
  <Grid>
    <Calendar />
  </Grid>
);

export default CalendarPage;
