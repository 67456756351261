import routes, { routesTitle } from '@/routes/routes';
import { Selector } from 'reselect';

import _Store from '@Store';

import i18next from './../../../translations/i18n';

const getAppTitle: Selector<_Store.IState, string> = (state) => {
  function getKeyByValue(object: { [_: string]: string }, value: string) {
    return Object.keys(object).find((ObjectKey) => {
      return object[ObjectKey].split('/')[1] === value.split('/')[1];
    });
  }

  const key = getKeyByValue(routes, state.router.location.pathname);

  if (key) {
    const title = routesTitle(i18next.t);
    return title[key as keyof typeof title] || '';
  }

  return '';
};

export default getAppTitle;
