import { getType } from 'typesafe-actions';

import { resetState, setFormRulesData } from '../actions';
import { IAction, IFormRulesReducer } from '../types';

const initialState: IFormRulesReducer = {
  closed: false,
  duration: 0,
  partnerId: null,
  prices: [],
  rrule: '',
  title: '',
  validFrom: null,
  validTo: null,
};

const formRulesReducer = (
  state: IFormRulesReducer = initialState,
  action: IAction
): IFormRulesReducer => {
  switch (action.type) {
    case getType(setFormRulesData):
      return {
        ...state,
        ...action.payload,
      };
    case getType(resetState):
      return initialState;

    default:
      return state;
  }
};

export default formRulesReducer;
