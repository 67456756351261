export const _GET_REQUEST = 'Zagrywki/configurations/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/configurations/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/configurations/_GET_FAILURE';

export const _SAVE_REQUEST = 'Zagrywki/configurations/_SAVE_REQUEST';
export const _SAVE_SUCCESS = 'Zagrywki/configurations/_SAVE_SUCCESS';
export const _SAVE_FAILURE = 'Zagrywki/configurations/_SAVE_FAILURE';

export const _DELETE_REQUEST = 'Zagrywki/configurations/_DELETE_REQUEST';
export const _DELETE_SUCCESS = 'Zagrywki/configurations/_DELETE_SUCCESS';
export const _DELETE_FAILURE = 'Zagrywki/configurations/_DELETE_FAILURE';

export const CATCH_SAVE = 'Zagrywki/configurations/CATCH_SAVE';
export const CATCH_SAVE_V2 = 'Zagrywki/configurations/CATCH_SAVE_V2';

export const GET_CONFIGURATIONS = 'Zagrywki/configurations/GET_CONFIGURATIONS';

export const HANDLE_SEARCH_PARAM =
  'Zagrywki/configurations/HANDLE_SEARCH_PARAM';

export const MOUNTED = 'Zagrywki/configurations/MOUNTED';

export const HANDLE_RULE_TYPE_PARAM =
  'Zagrywki/configurations/HANDLE_RULE_TYPE_PARAM';

export const HANDLE_CONFIGURATIONS_ACTIVE_PAGE =
  'Zagrywki/configurations/HANDLE_CONFIGURATIONS_ACTIVE_PAGE';

export const HANDLE_CONFIGURATIONS_ROWS_PER_PAGE =
  'Zagrywki/configurations/HANDLE_CONFIGURATIONS_ROWS_PER_PAGE';

export const HANDLE_ACTIVE_CONFIGURATION =
  'Zagrywki/configurations/HANDLE_ACTIVE_CONFIGURATION';

export const RESET_STATE = 'Zagrywki/configurations/RESET_STATE';

export const DELETE_CONFIGURATION =
  'Zagrywki/configurations/DELETE_CONFIGURATION';

export const ADD_NEW_CONFIGURATION =
  'Zagrywki/configurations/ADD_NEW_CONFIGURATION';
