import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { ILabelContentContainerStyle } from './LabelContentContainer.types';

const useStyles = makeStyles<Theme, ILabelContentContainerStyle>((theme) => ({
  container: {
    backgroundColor: ({ backgroundColor }) =>
      backgroundColor || theme.palette.action.focus,
    borderRadius: 8,
    height: '100%',
    padding: 16,
  },
}));

export default useStyles;
