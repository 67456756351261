import React, { FC, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Button, InputAdornment, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import { dateFormated } from '@Misc/helpers/dateFormated';

import styles from './../../Discounts.module.scss';
import { IDiscountsProps } from './DiscountTable.types';

export const ARE_YOU_SURE_TEXT = 'Czy na pewno usunąć obniżkę?';
export const CODE_TEXT = 'Kod';
export const DURATION_TEXT = 'Czas trwania';
export const EDIT_TEXT = 'Edytuj';
export const FROM_PRESENT_TEXT = 'o procent';
export const FROM_PRICE_TEXT = 'o kwotę';
export const HAPPENING_TEXT = 'Wydarzenie';
export const INFORMATION_TEXT = 'Informacje';
export const LIMIT_TEXT = 'Limit';
export const USAGE_TEXT = 'Zużyto';
export const NO_BOUNDARIES_TEXT = '(bez ograniczeń)';
export const NO_TEXT = 'Nie';
export const REMOVE_TEXT = 'Usuń';
export const SUBTRACT_A_PERCENTAGE_TEXT = 'Obniż cenę';
export const SUBTRACT_AMOUNT_TEXT = 'Odejmnij kwotę';
export const TYPE_TEXT = 'Typ';
export const VALUE_TEXT = 'Wartość';
export const YES_TEXT = 'Tak';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
    paddingRight: theme.spacing(3),
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]: {
      flexGrow: 1,
    },
  },

  buttonFirst: {
    marginRight: theme.spacing(2),
  },

  buttonWithoutPadding: {
    paddingRight: theme.spacing(1.25),
  },

  buttonsContainer: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

const DiscountTable: FC<IDiscountsProps> = ({
  discounts,
  remove,
  edit,
  permissionDiscountWrite,
  activePage,
  perPage,
  totalCount,
  updateSearchText,
  searchText,
  handleActivePage,
  addDiscount,
  discountsMounted,
  isLoading,
}) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Discounts.components.DiscountTable',
  });

  const [searchTimeOut, setSearchTimeOut] = useState<number>(0);
  const [inputText, setInputText] = useState(searchText);
  const classes = useStyles();

  useEffect(() => {
    discountsMounted();
  }, []);

  const confirmModal = (discountCode: string | null) => {
    if (window.confirm(t('areYouSure')) && discountCode) {
      remove(discountCode);
    }
  };

  const catchHandleSearch = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.currentTarget;
    setInputText(value);
    searchFunc(value);
  };

  const searchFunc = (text: string) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        updateSearchText(text);
      }, 1000) as any
    );
  };

  const catchChangePage = (e: unknown, page: number) => {
    handleActivePage(page);
  };
  const catchRowClick = (code: string) => {
    const foundedDiscount = discounts.find(
      (discount) => code === discount.code
    );
    if (foundedDiscount) {
      edit(foundedDiscount);
    }
  };

  const columns = [
    { id: 'id', label: t('code') },
    { id: 'time', label: t('duration') },
    { id: 'isActive', label: t('active') },
    { id: 'type', label: t('type') },
    { id: 'value', label: t('value') },
    { id: 'limit', label: t('limit') },
    { id: 'usage', label: t('usage') },
    { id: 'userName', label: t('author') },
    { id: 'actions', label: t('action') },
  ];

  const normalizedCodes = discounts.map((discount) => {
    const timezoneStartDate = moment
      .utc(discount.startDate || new Date())
      .format('YYYY-MM-DD HH:mm:ss');

    const timezoneEndDate = moment
      .utc(discount.endDate || new Date())
      .format('YYYY-MM-DD HH:mm:ss');

    const startDate = moment(timezoneStartDate)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toDate();

    const endDate = moment(timezoneEndDate)
      .set({ hour: 24, minute: 0, second: 0, millisecond: 0 })
      .toDate();

    const isActive =
      discount.startDate &&
      moment(Date.now()).isBetween(moment(startDate), moment(endDate));

    const percentageValue = discount.percentageValue || 0;
    const startDateFormated = startDate ? dateFormated(startDate) : null;
    const endDateFormated = timezoneEndDate
      ? dateFormated(moment(timezoneEndDate).toDate())
      : null;
    return {
      actions: (
        <Button
          variant="contained"
          size="small"
          color="primary"
          className={cn(
            classes.button,
            classes.buttonFirst,
            classes.buttonWithoutPadding
          )}
          onClick={(e) => {
            e.stopPropagation();
            confirmModal(discount.code);
          }}
        >
          {t('remove')}
        </Button>
      ),
      id: discount.code,
      isActive:
        !(
          discount.usageCount &&
          discount.transactionsLimit &&
          discount.usageCount >= discount.transactionsLimit
        ) && isActive
          ? t('yes')
          : t('no'),
      limit: discount.transactionsLimit || '-',
      time: `${startDateFormated}${
        discount.endDate ? `- ${endDateFormated}` : `- ${t('noBoundaries')}`
      }`,
      type: `${t('subtractPercentage')} ${
        percentageValue > 0 ? t('fromPercent') : t('fromPrice')
      }`,
      usage: discount.usageCount || '-',
      userName: discount.author?.userName || '-',
      value: `${discount.value || discount.percentageValue}${
        discount.value ? 'zł' : '%'
      }`,
    };
  });

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <GenericTable>
      <TableActions>
        <TextField
          variant="standard"
          name="search"
          helperText={t('search')}
          value={inputText}
          onChange={catchHandleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="secondary" />
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.buttonsContainer}>
          {permissionDiscountWrite && (
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              startIcon={<AddIcon />}
              className={cn(classes.button, classes.buttonFirst)}
              onClick={addDiscount}
            >
              {t('add')}
            </Button>
          )}
        </div>
      </TableActions>
      <TableUi
        rows={normalizedCodes}
        columns={columns}
        total={totalCount}
        activePage={activePage}
        rowsPerPage={perPage}
        handleChangePage={catchChangePage}
        disableCheckBox={true}
        onRowClick={catchRowClick}
      />
    </GenericTable>
  );
};

export default DiscountTable;
