import { createSelector } from 'reselect';

import _Store from '@Store';

import { getHappening } from '@Model/happening/selectors';
import getSelectedConfigurationId from '@Model/happening/selectors/getSelectedConfigurationId';

import {
  IAvailabilitiesResponseSlot,
  IHappeningReducer,
} from '../../happening/types';
import { IExtendedSlot, ITimeSlot } from './../types';
import getSelectedSlot from './getSelectedSlot';
import getTimeSlots from './getTimeSlots';

const getExtendedSlots = createSelector<
  _Store.IState,
  IHappeningReducer,
  ITimeSlot | null,
  ITimeSlot[],
  IAvailabilitiesResponseSlot | null,
  IExtendedSlot[]
>(
  [getHappening, getSelectedSlot, getTimeSlots, getSelectedConfigurationId],
  (data, selectedSlot, availableSlots, selectedConfiguration) => {
    if (data?.multipleSlotReservation && selectedSlot) {
      const filteredSlots: ITimeSlot[] = [];
      const filteredAvailableSlots = availableSlots.filter(
        (slot) => !slot.isDisabled
      );

      const index = filteredAvailableSlots.findIndex(
        (slot) => slot.slotDateTime === selectedSlot.slotDateTime
      );

      const slicedArray = filteredAvailableSlots.slice(index);

      slicedArray.some((element, i, array) => {
        filteredSlots.push(element);

        return (
          ((array[i + 1]?.slotDateTime || 0) - element.slotDateTime) / 1000 !==
          selectedSlot.calculatedDuration
        );
      });

      const arrayWithoutSelectedSlot = filteredSlots.slice(1);

      const getArrayLength = () => {
        if (data.multipleSlotReservation) {
          if (arrayWithoutSelectedSlot.length > data.multipleSlotReservation) {
            return data.multipleSlotReservation;
          }

          return arrayWithoutSelectedSlot.length;
        }

        return 0;
      };

      const slots = Array.from({ length: getArrayLength() }, (_, i) => {
        const upsellExtended = selectedConfiguration?.upsellExtended;
        const foundedSlot = upsellExtended?.find(
          (upsell) => upsell.slotIndex === i + 1
        );

        return {
          duration: (i + 1) * (selectedSlot.calculatedDuration / 60),
          priceMultiplier: i + 2,
          upsellPercentageValue: foundedSlot?.percentageValue,
          upsellValue: foundedSlot?.value,
        };
      });

      return slots;
    }

    return [];
  }
);

export default getExtendedSlots;
