import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import config from '@Config';

import useStyles from './UsersList.styles';
import { IUsersListProps } from './UsersList.types';

const UsersList = ({ removeUser, users, setOpen }: IUsersListProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'reservations.Calendar.components.FormLayout.components.UsersList',
  });

  const classes = useStyles();

  const calculateAge = (birthDate?: string) => {
    if (birthDate) {
      return moment().diff(birthDate, 'years', false);
    }
    return '';
  };

  const normalizedUsers = users.map((user, index) => ({
    actions: (
      <Button
        color="primary"
        variant="contained"
        onClick={() => removeUser(user.id)}
        className={cn(
          classes.button,
          classes.buttonFirst,
          classes.buttonWithoutPadding
        )}
      >
        {t('delete')}
      </Button>
    ),
    age: calculateAge(user.birthday),
    email: user.email,
    id: index + 1,
    lastname: user.lastName,
    name: user.firstName,
  }));

  const columns = [
    { id: 'id', label: t('id') },
    { id: 'email', label: t('email') },
    { id: 'name', label: t('name') },
    { id: 'lastname', label: t('lastName') },
    { id: 'age', label: t('age') },
    { id: 'actions', label: t('actions') },
  ];

  return (
    <GenericTable>
      {config.cms.showNewEnterList && (
        <TableActions>
          <div />
          <div className={classes.buttonsContainer}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              startIcon={<AddIcon />}
              className={cn(classes.button, classes.buttonFirst)}
              onClick={() => setOpen(true)}
            >
              {t('add')}
            </Button>
          </div>
        </TableActions>
      )}
      <TableUi
        rows={normalizedUsers}
        columns={columns}
        total={users.length}
        activePage={0}
        rowsPerPage={users.length}
        handleChangePage={() => {}}
        disableCheckBox={true}
      />
    </GenericTable>
  );
};

export default UsersList;
