import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IGroupsModalValues } from '@Compo/Users/components/GroupsTable/components/GroupsModal/GroupsModal.types';
import { IChangePasswordModalValues } from '@Compo/Users/components/UsersTable/components/ChangePasswordModal/ChangePasswordModal.types';
import { IUsersModalValues } from '@Compo/Users/components/UsersTable/components/UsersModal/UsersModal.types';
import { IError } from '@Misc/helpers/api/catchHttpError';
import { IPartner } from '@Model/authorization/types';
import {
  IGroup,
  IGroupsPayload,
  IGroupsResponse,
  IPermissionsResponse,
  IUser,
  IUsersResponse,
} from '@Services/$users-api/types';

import {
  _CHANGE_USER_PASSWORD_FAILURE,
  _CHANGE_USER_PASSWORD_REQUEST,
  _CHANGE_USER_PASSWORD_SUCCESS,
  _CHANGE_USER_STATUS_FAILURE,
  _CHANGE_USER_STATUS_REQUEST,
  _CHANGE_USER_STATUS_SUCCESS,
  _DELETE_GROUP_FAILURE,
  _DELETE_GROUP_REQUEST,
  _DELETE_GROUP_SUCCESS,
  _GET_GROUPS_FAILURE,
  _GET_GROUPS_REQUEST,
  _GET_GROUPS_SUCCESS,
  _GET_PARTNERS_FAILURE,
  _GET_PARTNERS_REQUEST,
  _GET_PARTNERS_SUCCESS,
  _GET_PERMISSIONS_FAILURE,
  _GET_PERMISSIONS_REQUEST,
  _GET_PERMISSIONS_SUCCESS,
  _GET_SINGLE_GROUP_FAILURE,
  _GET_SINGLE_GROUP_REQUEST,
  _GET_SINGLE_GROUP_SUCCESS,
  _GET_SINGLE_USER_FAILURE,
  _GET_SINGLE_USER_REQUEST,
  _GET_SINGLE_USER_SUCCESS,
  _GET_USERS_FAILURE,
  _GET_USERS_REQUEST,
  _GET_USERS_SUCCESS,
  _SAVE_GROUP_FAILURE,
  _SAVE_GROUP_REQUEST,
  _SAVE_GROUP_SUCCESS,
  _SAVE_USER_FAILURE,
  _SAVE_USER_REQUEST,
  _SAVE_USER_SUCCESS,
  CATCH_DELETE_GROUP,
  CATCH_GET_SINGLE_GROUP,
  CATCH_GET_SINGLE_USER,
  CATCH_SAVE_GROUP,
  CATCH_SAVE_USER,
  CATCH_USER_PASSWORD_CHANGE,
  CATCH_USER_STATUS_CHANGE,
  GET_ALL_GROUPS,
  GET_ALL_PARTNERS,
  HANDLE_ACTIVE_FILTER,
  HANDLE_ACTIVE_GROUP,
  HANDLE_ACTIVE_PAGE_GROUPS,
  HANDLE_ACTIVE_PAGE_USERS,
  HANDLE_ACTIVE_SIZE_GROUPS,
  HANDLE_ACTIVE_SIZE_USERS,
  HANDLE_ACTIVE_USER,
  HANDLE_SEARCH_GROUPS,
  HANDLE_SEARCH_USERS,
  MOUNTED_GROUPS,
  MOUNTED_PERMISSIONS,
  MOUNTED_USERS,
} from './../constants/actions';
import { ISaveUserFailure, IStatusPayload } from './../types/index';

export const handleActiveUser = createStandardAction(
  HANDLE_ACTIVE_USER
)<IUsersModalValues | null>();

export const handleActiveGroup = createStandardAction(
  HANDLE_ACTIVE_GROUP
)<IGroupsModalValues | null>();

export const mountedUsers = createStandardAction(MOUNTED_USERS)();
export const mountedGroups = createStandardAction(MOUNTED_GROUPS)();
export const getAllGroups = createStandardAction(GET_ALL_GROUPS)<number[]>();
export const getAllPartners = createStandardAction(GET_ALL_PARTNERS)();
export const mountedPermissions = createStandardAction(MOUNTED_PERMISSIONS)();
export const catchSaveGroup =
  createStandardAction(CATCH_SAVE_GROUP)<IGroupsModalValues>();
export const catchSaveUser =
  createStandardAction(CATCH_SAVE_USER)<IUsersModalValues>();
export const catchDeleteGroup =
  createStandardAction(CATCH_DELETE_GROUP)<number>();
export const catchGetSingleGroup = createStandardAction(
  CATCH_GET_SINGLE_GROUP
)<number>();
export const catchGetSingleUser = createStandardAction(
  CATCH_GET_SINGLE_USER
)<number>();
export const catchChangeUserStatus = createStandardAction(
  CATCH_USER_STATUS_CHANGE
)<IStatusPayload>();
export const catchChangeUserPassword = createStandardAction(
  CATCH_USER_PASSWORD_CHANGE
)<IChangePasswordModalValues>();
export const handleActivePageUsers = createStandardAction(
  HANDLE_ACTIVE_PAGE_USERS
)<number>();
export const handleActiveSizeUsers = createStandardAction(
  HANDLE_ACTIVE_SIZE_USERS
)<number>();
export const handleActivePageGroups = createStandardAction(
  HANDLE_ACTIVE_PAGE_GROUPS
)<number>();
export const handleActiveSizeGroups = createStandardAction(
  HANDLE_ACTIVE_SIZE_GROUPS
)<number>();
export const handleActiveFilter = createStandardAction(HANDLE_ACTIVE_FILTER)<
  string | undefined
>();
export const handleSearchUsers =
  createStandardAction(HANDLE_SEARCH_USERS)<string>();
export const handleSearchGroups =
  createStandardAction(HANDLE_SEARCH_GROUPS)<string>();

export const getUsers = createAsyncAction(
  _GET_USERS_REQUEST,
  _GET_USERS_SUCCESS,
  _GET_USERS_FAILURE
)<undefined, IUsersResponse, IError>();

export const getGroups = createAsyncAction(
  _GET_GROUPS_REQUEST,
  _GET_GROUPS_SUCCESS,
  _GET_GROUPS_FAILURE
)<IGroupsPayload, IGroupsResponse, IError>();

export const getPartners = createAsyncAction(
  _GET_PARTNERS_REQUEST,
  _GET_PARTNERS_SUCCESS,
  _GET_PARTNERS_FAILURE
)<number | undefined, IPartner[], Error>();

export const getPermissions = createAsyncAction(
  _GET_PERMISSIONS_REQUEST,
  _GET_PERMISSIONS_SUCCESS,
  _GET_PERMISSIONS_FAILURE
)<undefined, IPermissionsResponse, Error>();

export const saveGroup = createAsyncAction(
  _SAVE_GROUP_REQUEST,
  _SAVE_GROUP_SUCCESS,
  _SAVE_GROUP_FAILURE
)<IGroupsModalValues, [], Error>();

export const deleteGroup = createAsyncAction(
  _DELETE_GROUP_REQUEST,
  _DELETE_GROUP_SUCCESS,
  _DELETE_GROUP_FAILURE
)<number, [], Error>();

export const getSingleGroup = createAsyncAction(
  _GET_SINGLE_GROUP_REQUEST,
  _GET_SINGLE_GROUP_SUCCESS,
  _GET_SINGLE_GROUP_FAILURE
)<number, IGroup, Error>();

export const saveUser = createAsyncAction(
  _SAVE_USER_REQUEST,
  _SAVE_USER_SUCCESS,
  _SAVE_USER_FAILURE
)<IUsersModalValues, string | null, ISaveUserFailure>();

export const changeUserStatus = createAsyncAction(
  _CHANGE_USER_STATUS_REQUEST,
  _CHANGE_USER_STATUS_SUCCESS,
  _CHANGE_USER_STATUS_FAILURE
)<IStatusPayload, [], Error>();

export const changeUserPassword = createAsyncAction(
  _CHANGE_USER_PASSWORD_REQUEST,
  _CHANGE_USER_PASSWORD_SUCCESS,
  _CHANGE_USER_PASSWORD_FAILURE
)<IChangePasswordModalValues, [], Error>();

export const getSingleUser = createAsyncAction(
  _GET_SINGLE_USER_REQUEST,
  _GET_SINGLE_USER_SUCCESS,
  _GET_SINGLE_USER_FAILURE
)<number, IUser, Error>();
