import { Selector } from 'reselect';

import { ISelectedReducer } from './../types';

import _Store from '@Store';

const get: Selector<_Store.IState, ISelectedReducer> = (state) =>
  state.events.selected;

export default get;
