import { getAppTitle } from '@Model/app/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import PageTitle from './PageTitle.component';
import { IPageFromState } from './PageTitle.types';

const mapStateToProps = (state: _Store.IState): IPageFromState => ({
  title: getAppTitle(state),
});

export default connect<IPageFromState, void, {}, _Store.IState>(
  mapStateToProps,
)(PageTitle);
