import React, { useRef } from 'react';

import { Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import Manager from '@Compo/BetterManager/Manager';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';

import { IAddProps } from './Add.types';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(2),
  },
}));

const Add = ({ save, history }: IAddProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'happenings.Add',
  });

  const classes = useStyles();
  const managerRef = useRef<any>(null);
  const Buttons = () => (
    <Grid container={true} justifyContent="flex-end">
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        className={classes.button}
        onClick={() => history.goBack()}
      >
        {t('back')}
      </Button>
      <Button
        variant="outlined"
        size="large"
        color="secondary"
        onClick={() => managerRef?.current?.submitForm()}
      >
        {t('save')}
      </Button>
    </Grid>
  );
  return (
    <GoingPaper title={t('title')} buttons={Buttons()}>
      <Manager ref={managerRef} handleSave={save} />
    </GoingPaper>
  );
};

export default Add;
