import { FormikProps } from 'formik';
import { RouteComponentProps } from 'react-router';

import { ISeatsIoReportReducer } from '@Model/going/reportSeatsIo/types';
import {
  IMonitorType,
  IPoolsResponse,
  IRundate,
} from '@Services/$going-rundate-api/types';
import { IPlace } from '@Services/$places-api/types';
import { IFileType } from '@Services/$tickets-api/types';

export interface IRundateValues {
  event: IEventValues;
  rundate: moment.Moment | string;
  runtime: string;
  emissionSize: number | null;
  placeId: number | null;
  place?: IPlace;
  startTime: string;
  endDate: moment.Moment | string | null;
  endTime: string;
}

export interface IEventValues {
  id?: number;
  titlePL: string;
  descriptionPL: string;
}

export interface IFormLayoutFromState {
  isLoading: boolean;
  isPlacesLoading: boolean;
  isReportLoading: boolean;
  isReportSeatsLoading: boolean;
  rundate: IRundate;
  permissionAssetsDelete: boolean;
  permissionSeatsIoKeyWrite: boolean;
  places: IPlace[];
  selectedPlace?: IPlace;
  reports: ISeatsIoReportReducer;
  pools: IPoolsResponse[];
  monitorTypes: IMonitorType[];
  permissionEventWrite: boolean;
}

export interface IFormLayoutOwnProps {
  addEvent?: boolean;
  addRundate?: boolean;
}

export enum PopoverOptions {
  EVENT_PREVIEW = 'eventPreview',
  SEATS_IO = 'seatsIo',
  TICKET_LIST = 'ticketList',
  CHANGE_STATUS = 'changeStatus',
}

export interface ISinglePopover {
  hide?: boolean;
  isLoading?: boolean;
  isActive?: boolean;
  name: string;
  options: IMenuOptions[];
}

export interface IMenuOptions {
  name: string;
  value: string;
  onClick?: () => void;
}

export type IPopover = {
  [key in PopoverOptions]: ISinglePopover;
};

export type IPopoverState = {
  [key in PopoverOptions]: boolean;
};

export type IPopoverRef = {
  [key in PopoverOptions]: HTMLButtonElement | null;
};

export interface IFormLayoutFromDispatch {
  deleteAssets: (slug: string) => void;
  addMailing: (eventId: number, rundateId: number) => void;
  searchPlace: (text: string) => void;
  changeRundateHasExternalImage: (hasExternalImage: boolean) => void;
  changeSaleStatus: (forSale: boolean) => void;
  changeVisibilityStatus: (visible: boolean) => void;
  generatePdf: (rundateId: number, poolId?: number) => void;
  fetchRundateReport: (type: IFileType) => void;
  changeRundateMonitorType: (id: number | null) => void;
}

export type IFormLayoutProps = IFormLayoutFromState &
  IFormLayoutFromDispatch &
  FormikProps<IRundateValues> &
  RouteComponentProps &
  IFormLayoutOwnProps;
