import React, { FC } from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import AddressUrl from './components/AddressUrl';
import { IImagesLibraryProps } from './ImagesLibrary.types';

const ImagesLibrary: FC<IImagesLibraryProps> = ({
  close,
  url,
  setUrl,
  open,
}) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'BetterManager.ImagesLibrary',
  });

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={close}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <AddressUrl url={url} setUrl={setUrl} close={close} />
    </Dialog>
  );
};

export default ImagesLibrary;
