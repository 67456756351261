import React from 'react';

import Grid from '@Compo/layout/Grid';
import Settlements from '@Compo/Settlements';

const SettlementsPage = () => (
  <Grid>
    <Settlements />
  </Grid>
);

export default SettlementsPage;
