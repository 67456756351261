import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  IGetTicketsSuccessPayload,
  ITicketStatusPayload,
} from '@Services/$tickets-api/types';

import {
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  _TICKET_STATUS_DELETION_FAILURE,
  _TICKET_STATUS_DELETION_REQUEST,
  _TICKET_STATUS_DELETION_SUCCESS,
  _TICKET_STATUS_FAILURE,
  _TICKET_STATUS_REQUEST,
  _TICKET_STATUS_SUCCESS,
  MOUNTED,
  SEARCH,
  SEARCH_TICKET,
} from '../constants/actions';

import { IGetTicketsPayload, ISearchTicketPayload } from '../types';

export const getTickets = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE
)<IGetTicketsPayload | undefined, IGetTicketsSuccessPayload, Error>();

export const ticketStatus = createAsyncAction(
  _TICKET_STATUS_REQUEST,
  _TICKET_STATUS_SUCCESS,
  _TICKET_STATUS_FAILURE
)<string, ITicketStatusPayload, Error>();

export const ticketStatusDeletion = createAsyncAction(
  _TICKET_STATUS_DELETION_REQUEST,
  _TICKET_STATUS_DELETION_SUCCESS,
  _TICKET_STATUS_DELETION_FAILURE
)<string, ITicketStatusPayload, Error>();

export const mounted = createStandardAction(MOUNTED)();
export const search = createStandardAction(SEARCH)<IGetTicketsPayload>();
export const searchTicket = createStandardAction(SEARCH_TICKET)<
  ISearchTicketPayload
>();
