import { createSelector } from 'reselect';

import _Store from '@Store';

import { ILocalProduct, IProductsReducer } from '../types';
import get from './get';

const getInvoiceProducts = createSelector<
  _Store.IState,
  IProductsReducer,
  ILocalProduct[]
>([get], (products) => products.invoiceProducts);

export default getInvoiceProducts;
