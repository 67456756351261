import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { saveTicketList } from '@Model/going/rundates/actions';
import { getSelectedTicketList } from '@Model/going/rundates/selectors';

import TicketsListForm from './TicketsListForm.component';
import {
  ITicketsListFormFromDispatch,
  ITicketsListFormFromState,
} from './TicketsListForm.types';

const mapStateToProps = (state: _Store.IState): ITicketsListFormFromState => ({
  initialValues: getSelectedTicketList(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITicketsListFormFromDispatch => ({
  save: (values) => dispatch(saveTicketList(values)),
});

export default connect<
  ITicketsListFormFromState,
  ITicketsListFormFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(TicketsListForm);
