import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { IShipmentFeeFromValuesProps } from '@Model/products/types';

import FormLayout from './FormLayout';
import { IShipmentFeeModalProps } from './ShipmentFeeModal.types';
import { ShipmentFeeSchema } from './ShipmentFeeModal.validation';

const ShipmentFeeModal = ({
  initialValues,
  open,
  isEditing,
  closeModal,
  saveShipmentFee,
  partnerId,
}: IShipmentFeeModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Products.ShipmentFeeModal',
  });

  const TITLE_TEXT = isEditing ? t('editTitle') : t('addTitle');
  const catchHandleSubmit = (values: IShipmentFeeFromValuesProps) => {
    saveShipmentFee({
      ...values,
      cmsPartnerId: Number(partnerId),
      description: t('empty'),
      type: 'transport',
      vat: 23,
    });
  };
  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle>{TITLE_TEXT}</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={catchHandleSubmit}
        validationSchema={ShipmentFeeSchema}
        component={FormLayout}
      />
    </Dialog>
  );
};

export default ShipmentFeeModal;
