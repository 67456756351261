import { createSelector } from 'reselect';

import _Store from '@Store';

import { ITicketsReducer, ITicketsSuccessPayload } from '../types';
import getTickets from './getTickets';

const getTicketListTickets = createSelector<
  _Store.IState,
  ITicketsReducer,
  ITicketsSuccessPayload
>([getTickets], (tickets) => tickets.tickets);

export default getTicketListTickets;
