import { createSelector } from 'reselect';

import _Store from '@Store';

import { ITicketsListValues } from '@Compo/Rundates/TicketsList/components/TicketsListForm/TicketsListForm.types';

import { ITicketsReducer } from '../types';
import getTickets from './getTickets';

const getSelectedTicketList = createSelector<
  _Store.IState,
  ITicketsReducer,
  ITicketsListValues | null
>([getTickets], (tickets) => tickets.selectedTicketList);

export default getSelectedTicketList;
