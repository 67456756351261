import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  deletePool,
  fetchReorderPools,
  fetchSinglePool,
  selectPool,
} from '@Model/going/rundates/actions';
import {
  getSingleRundateData,
  getTablePools,
} from '@Model/going/rundates/selectors';
import { GOING_POOLS } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import { getInitialValues } from './components/PoolsForm/PoolsForm.helpers';
import Pools from './Pools.component';
import { IPoolsFromDispatch, IPoolsFromState } from './Pools.types';

const mapStateToProps = (state: _Store.IState): IPoolsFromState => ({
  isLoading: getLoading(GOING_POOLS)(state),
  pools: getTablePools(state),
  rundate: getSingleRundateData(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IPoolsFromDispatch => ({
  addNewPool: () => dispatch(selectPool(getInitialValues())),
  deletePool: (id) => dispatch(deletePool(id)),
  editPool: (id) => dispatch(fetchSinglePool(id)),
  reorderPools: (poolsIds) => dispatch(fetchReorderPools(poolsIds)),
});

export default connect<IPoolsFromState, IPoolsFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Pools);
