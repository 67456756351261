import { LOCATION_CHANGE } from 'connected-react-router';
import moment from 'moment';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getInitialValues } from '@Compo/Discounts/components/EventDiscountModal/components/EventDiscountForm/EventDiscountForm.helpers';
import { getDiscounts, getEventsDiscounts } from '@Model/discounts/actions';
import { get } from '@Model/discounts/selectors';
import { getHappenings } from '@Model/happenings/actions';
import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { showSuccessModal } from '@Model/modal/actions';
import { getRuleTypes } from '@Model/priceTypes/actions';
import { getProducts } from '@Model/products/selectors';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR, TYPE_SUCCESS } from '@Model/toasts/constants/constants';
import { IEventDiscountBody } from '@Services/$discounts-api/types';
import {
  IReservationPriceRequest,
  ISelectedProduct,
} from '@Services/$reservations-api/types';

import {
  addDiscount,
  catchDependencyValues,
  catchSaveNewDiscount,
  checkDiscount,
  checkDiscountCode,
  closeEventAddDiscount,
  editDiscount,
  eventAddDiscount,
  eventEditDiscount,
  getEventsPartner,
  getPartners,
  getPoolsPartner,
  getRundatesPartner,
  getSingleEventDiscount,
  handleActivePage,
  remove,
  resetState,
  save,
  saveDiscount,
  saveNewDiscount,
} from '../actions';
import { getMeta, getSearchText } from '../selectors';
import { IDiscount, IDiscountBody } from '../types';

const CHECK_DISCOUNT_SUCCESS_TEXT = 'Podany kod zniżkowy jest prawidłowy!';
const PER_PAGE = 15;

export const requestSaveDiscountOnSaveAction: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(save)),
    mergeMap$((action) => {
      const payload: IDiscount = {
        ...action.payload,
        priceTypeId:
          action.payload.priceTypeId !== -1 ? action.payload.priceTypeId : null,
      };
      return of$(resetState(), saveDiscount.request(payload));
    })
  );
};

export const saveDiscountWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { discountApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveDiscount.request)),
    mergeMap$((action) => {
      const {
        code,
        endDate,
        startDate,
        happeningId,
        transactionsLimit,
        value,
        isPercentage,
        id,
        partnerId,
        priceTypeId,
      } = action.payload;

      const body: IDiscountBody = {
        code,
        dateRange: {
          endDate: new Date(moment(endDate).format('YYYY-MM-DD')),
          startDate: new Date(moment(startDate).format('YYYY-MM-DD')),
        },
        endDate,
        happeningId:
          happeningId && Number(happeningId) !== -1
            ? Number(happeningId)
            : null,
        id,
        partnerId: Number(partnerId),
        percentageValue: null,
        priceTypeId,
        startDate,
        transactionsLimit: transactionsLimit ? Number(transactionsLimit) : null,
        value: null,
      };

      if (isPercentage) {
        body.percentageValue = Number(value);
      } else {
        body.value = Number(value);
      }

      return from$(discountApi.addSingleDiscount(body)).pipe(
        mergeMap$(() => {
          return of$(
            resetState(),
            saveDiscount.success(),
            getDiscounts.request(),
            getHappenings.request(),
            showSuccessModal()
          );
        }),
        catchError$((error: Error) => {
          return of$(getDiscounts.failure(error), saveDiscount.failure(error));
        })
      );
    })
  );
};

export const removeDiscountWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { discountApi }
) => {
  return action$.pipe(
    filter$(isActionOf(remove)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(discountApi.removeDiscount(action.payload || '')).pipe(
        mergeMap$(() => {
          const discounts = get(state);
          const discountCode = action.payload;

          if (discounts && discounts.items) {
            const items = discounts.items.filter((item) => {
              return item.code !== discountCode;
            });

            return [
              getDiscounts.success({
                items,
                totalCount: items.length,
              }),
            ];
          }

          return EMPTY$;
        }),
        catchError$((error: Error) => {
          return of$(getDiscounts.failure(error));
        })
      );
    })
  );
};

export const requestCheckDiscount: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(checkDiscount)),
    map$((action) => {
      return checkDiscountCode.request(action.payload);
    })
  );
};

export const checkDiscountCodeWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reservationsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(checkDiscountCode.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const products = getProducts(state);
      const selectedProducts = (): ISelectedProduct[] => {
        const DEFAULT_SELECTED_PRODUCT_COUNT = 1;

        if (products && products.items && products.items.length) {
          const { items } = products;

          return items
            .filter((item) => item && item.count && item.count > 0)
            .map((item) => ({
              id: Number(item.id),
              quantity: item.count || DEFAULT_SELECTED_PRODUCT_COUNT,
            }));
        }
        return [];
      };

      const body: IReservationPriceRequest = {
        dateTime: action.payload.basketItems[0].dateTime,
        discount: {
          code: action.payload.discount,
        },
        numberOfPeople: action.payload.basketItems[0].numberOfPeople,
        price: 0,
        products: selectedProducts(),
        spaceId: action.payload.basketItems[0].spaceId,
      };
      return from$(reservationsApi.checkPrice(body)).pipe(
        mergeMap$((data) => {
          if (data.message) {
            return of$(
              checkDiscountCode.failure(new Error(data.message)),
              addToast(data.message, TYPE_ERROR)
            );
          }
          return of$(
            checkDiscountCode.success(data.value),
            addToast(CHECK_DISCOUNT_SUCCESS_TEXT, TYPE_SUCCESS)
          );
        }),
        catchError$((error: Error) => {
          return of$(checkDiscountCode.failure(error));
        })
      );
    })
  );
};

export const requestCatchDependencyValuesAction: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchDependencyValues)),
    mergeMap$((action) => {
      return of$(
        handleActivePage({ type: action.payload, page: 1, searchText: '' })
      );
    })
  );
};

export const fetchDependencyValuesWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(handleActivePage)),
    mergeMap$((action) => {
      switch (action.payload.type) {
        case 'partner':
          return of$(getPartners.request());
        case 'event':
          return of$(getEventsPartner.request());
        case 'rundate':
          return of$(getRundatesPartner.request());
        case 'pool':
          return of$(getPoolsPartner.request());
        default:
          return EMPTY$;
      }
    })
  );
};

export const fetchPartnersWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { discountsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPartners.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { page } = getMeta(state);
      const searchText = getSearchText(state);

      return from$(discountsApi.getPartners(page, PER_PAGE, searchText)).pipe(
        mergeMap$((data) => {
          return of$(getPartners.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => discountsApi.cancelDiscounts())
          )
        ),
        catchError$((error: Error) => {
          return of$(getPartners.failure(error));
        })
      );
    })
  );
};

export const fetchEventsPartnerWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { discountsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getEventsPartner.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { page } = getMeta(state);
      const searchText = getSearchText(state);

      return from$(
        discountsApi.getEventsPartner(page, PER_PAGE, searchText)
      ).pipe(
        mergeMap$((data) => {
          return of$(getEventsPartner.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => discountsApi.cancelDiscounts())
          )
        ),
        catchError$((error: Error) => {
          return of$(getEventsPartner.failure(error));
        })
      );
    })
  );
};

export const fetchRundatesPartnerWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { discountsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getRundatesPartner.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { page } = getMeta(state);
      const searchText = getSearchText(state);

      return from$(
        discountsApi.getRundatesPartner(page, PER_PAGE, searchText)
      ).pipe(
        mergeMap$((data) => {
          return of$(getRundatesPartner.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => discountsApi.cancelDiscounts())
          )
        ),
        catchError$((error: Error) => {
          return of$(getRundatesPartner.failure(error));
        })
      );
    })
  );
};

export const fetchPoolsPartnerWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { discountsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPoolsPartner.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { page } = getMeta(state);
      const searchText = getSearchText(state);

      return from$(
        discountsApi.getPoolsPartner(page, PER_PAGE, searchText)
      ).pipe(
        mergeMap$((data) => {
          return of$(getPoolsPartner.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => discountsApi.cancelDiscounts())
          )
        ),
        catchError$((error: Error) => {
          return of$(getPoolsPartner.failure(error));
        })
      );
    })
  );
};

export const saveNewDiscountWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { discountsApi }
) => {
  return action$.pipe(
    filter$(isActionOf(catchSaveNewDiscount)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      const {
        id,
        code,
        value,
        valuePercentage,
        startDate,
        startTime,
        expireDate,
        expireTime,
        itemLimit,
        dependencies,
        refundType,
        ticketLimit,
      } = action.payload;
      const timeStart = moment(startTime, 'HH:mm');
      const timeExpire = moment(expireTime, 'HH:mm');

      const getExpireDate = () => {
        if (expireDate && expireTime) {
          return moment(expireDate)
            .set({
              hour: timeExpire.get('hour'),
              minute: timeExpire.get('minute'),
            })
            .format('YYYY-MM-DD HH:mm:ss');
        }

        if (expireDate) {
          return moment(expireDate)
            .set({
              hour: 0,
              minute: 0,
            })
            .format('YYYY-MM-DD HH:mm:ss');
        }

        return null;
      };

      const getStartDate = () => {
        if (startDate && startTime) {
          return moment(startDate)
            .set({
              hour: timeStart.get('hour'),
              minute: timeStart.get('minute'),
            })
            .format('YYYY-MM-DD HH:mm:ss');
        }

        if (expireDate) {
          return moment(startDate)
            .set({
              hour: 0,
              minute: 0,
            })
            .format('YYYY-MM-DD HH:mm:ss');
        }

        return null;
      };

      const body: IEventDiscountBody = {
        code,
        complimentary: false,
        dependencies: dependencies.map((dependency) => ({
          ...dependency,
          ids: dependency.ids
            .map((data) => data.id)
            .filter((item): item is number => !!item),
        })),
        expireDate: getExpireDate(),
        id,
        itemLimit,
        multiDiscount: null,
        multiSize: null,
        partnerId: Number(partnerId),
        refundType,
        startDate: getStartDate(),
        ticketLimit,
        value: value || null,
        valuePercentage: valuePercentage || null,
      };
      return from$(discountsApi.saveEventDiscount(body)).pipe(
        mergeMap$((data) => {
          return of$(
            saveNewDiscount.success(data),
            closeEventAddDiscount(),
            showSuccessModal(),
            getEventsDiscounts.request()
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => discountsApi.cancelDiscounts())
          )
        ),
        catchError$((error: Error) => {
          return of$(saveNewDiscount.failure(error));
        })
      );
    })
  );
};

export const editSingleEventDiscountWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(eventEditDiscount)),
    mergeMap$((action) => {
      return of$(getSingleEventDiscount.request(action.payload));
    })
  );
};

export const fetchSingleEventDiscountWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { discountApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSingleEventDiscount.request)),
    mergeMap$((action) => {
      return from$(discountApi.getSingleEventDiscount(action.payload)).pipe(
        mergeMap$((data) => {
          return of$(
            eventAddDiscount(getInitialValues(data)),
            getSingleEventDiscount.success(data)
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => discountApi.cancelDiscount())
          )
        ),
        catchError$((error: Error) => {
          return of$(getSingleEventDiscount.failure(error));
        })
      );
    })
  );
};

export const fetchPriceTypesWhenAddDiscountCodeMounted: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([addDiscount, editDiscount])),
    mergeMap$(() => {
      return of$(getRuleTypes.request(undefined));
    })
  );
};
