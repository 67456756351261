import moment from 'moment';

import { Currency } from '@Constants/Currency';
import { ISinglePool } from '@Services/$going-rundate-api/types';

import { IPoolsFormValues } from './PoolsForm.types';

export const getInitialValues = (values?: ISinglePool): IPoolsFormValues => {
  if (values) {
    return {
      currency: values.currency,
      emissionSize: values.emissionSize,
      endDate: values.endDate,
      endTime: values.endDate
        ? moment(values.endDate).format('HH:mm')
        : undefined,
      id: values.id,
      isActive: values.isActive,
      parentId: values.parent?.id || -1,
      poolDescriptionId: values.poolDescription?.id,
      poolGroupEN: values.poolGroupEN || '',
      poolGroupPL: values.poolGroupPL || '',
      price: values.price,
      seatsIoCategoryKey: values.seatsIoCategoryKey,
      startDate: values.startDate,
      startTime: values.startDate
        ? moment(values.startDate).format('HH:mm')
        : undefined,
      vat: values.vat,
    };
  }

  return {
    currency: Currency.PLN,
    emissionSize: null,
    endDate: null,
    isActive: true,
    parentId: -1,
    poolDescriptionId: null,
    price: 0,
    seatsIoCategoryKey: null,
    startDate: null,
    vat: 8,
  };
};
