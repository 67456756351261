const colors = {
  action: {
    background: '#FF6641',
    disabled: '#B9B7B7',
    disabledBg: '#5F6168',
    focus: 'rgba(0,0,0,0.12)',
  },
  background: {
    paper: '#1A1E27',
  },
  error: {
    light: '#F88078',
  },
  info: {
    light: '#64B6F7',
  },
  other: {
    backdrop: '#1A1E27',
    divider: '#E0E0E0',
    stroke: '#B9B7B7',
  },
  primary: {
    background: '#B2472D',
    border: '#FF6641',
    contrastText: '#1A1E27',
    dark: '#B2472D',
    light: '#FF8467',
    main: '#FF6641',
  },
  seats: {
    taken: '#012FFF',
  },
  secondary: {
    border: '#FF6641',
    contrastText: '#111111',
    dark: '#B2472D',
    light: '#FF8467',
    main: '#E0E0E0',
  },
  success: {
    light: '#7BC67E',
  },
  text: {
    disabled: 'rgba(255,255,255,0.3)',
    hint: 'rgba(0,0,0,0.38)',
    primary: '#FFFFFF',
    secondary: '#B9B7B7',
  },
  warning: {
    light: '#FFB547',
  },
};

export default colors;
