import React, { useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  IMetadataDependencies,
  IMetadataTypes,
  MetadataType,
} from '@Services/$metadata-api/types';

import AttachModal from './components/AttachModal';
import { ISelectedItems } from './components/AttachModal/AttachModal.types';
import DependencyTable from './components/DependencyTable';
import { IMetadataModalProps } from './MetadataModal.types';

const MetadataModal = ({
  getSelectedItems,
  selectedItems,
  usingMetadata,
  isFilterByActivePartner,
  changeIsFilterByActivePartner,
  buttonDisabled,
}: IMetadataModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reusable.MetadataModal',
  });

  const [selectedType, setSelectedType] = useState<IMetadataTypes | null>(null);
  const [selected, setSelected] = useState<ISelectedItems>({
    event: [],
    partner: [],
    pool: [],
    rundate: [],
  });

  const handleChangeType = (type: IMetadataTypes) => {
    setSelectedType(type);
  };
  const closeAttachModal = () => {
    setSelectedType(null);
  };

  const setSelectedItem = (
    type: IMetadataTypes,
    value: IMetadataDependencies
  ) => {
    setSelected({ ...selected, [type]: value });
  };

  const handleDeleteDependency = (type: IMetadataTypes, id?: number) => {
    if (id) {
      setSelected({
        ...selected,
        [type]: (selected[type] as IMetadataDependencies).filter(
          (value) => value.id !== id
        ),
      });
    }
  };

  useEffect(() => {
    setSelected({ ...selected, ...selectedItems });

    changeIsFilterByActivePartner(isFilterByActivePartner || false);
  }, []);

  useEffect(() => {
    getSelectedItems(selected);
  }, [selected]);

  return (
    <>
      <AttachModal
        setSelected={setSelectedItem}
        selectedItems={selected}
        closeModal={closeAttachModal}
        selectedType={selectedType}
      />
      {usingMetadata.partner && (
        <Grid item={true} xs={12}>
          <Typography variant={'body1'}>{t('partners')}</Typography>
          <DependencyTable
            selectedType={MetadataType.Partner}
            handleChangeType={handleChangeType}
            dependencies={selected.partner}
            handleDeleteDependency={handleDeleteDependency}
            buttonDisabled={buttonDisabled}
          />
        </Grid>
      )}

      {usingMetadata.event && (
        <Grid item={true} xs={12}>
          <Typography variant={'body1'}>{t('events')}</Typography>
          <DependencyTable
            selectedType={MetadataType.Event}
            handleChangeType={handleChangeType}
            dependencies={selected.event}
            handleDeleteDependency={handleDeleteDependency}
            buttonDisabled={buttonDisabled}
          />
        </Grid>
      )}

      {usingMetadata.rundate && (
        <Grid item={true} xs={12}>
          <Typography variant={'body1'}>{t('rundates')}</Typography>
          <DependencyTable
            selectedType={MetadataType.Rundate}
            handleChangeType={handleChangeType}
            dependencies={selected.rundate}
            handleDeleteDependency={handleDeleteDependency}
            buttonDisabled={buttonDisabled}
          />
        </Grid>
      )}

      {usingMetadata.pool && (
        <Grid item={true} xs={12}>
          <Typography variant={'body1'}>{t('pools')}</Typography>
          <DependencyTable
            selectedType={MetadataType.Pool}
            handleChangeType={handleChangeType}
            dependencies={selected.pool}
            handleDeleteDependency={handleDeleteDependency}
            buttonDisabled={buttonDisabled}
          />
        </Grid>
      )}
    </>
  );
};

export default MetadataModal;
