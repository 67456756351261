import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { get } from '@Model/authorization/selectors';
import { getHappenings } from '@Model/happenings/selectors';
import { setProductView } from '@Model/products/actions';
import { mounted } from '@Model/smartporter/actions';
import { getApartments } from '@Model/smartporter/selectors';
import { allPermissions } from '@Model/state/constants';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import Manager from './Manager.component';
import { IManagerFromDispatch, IManagerFromState } from './Manager.types';

const mapStateToProps = (state: _Store.IState): IManagerFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    apartments: getApartments(state),
    happenings: getHappenings(state),
    permissionSpaceRead: permissions.includes(
      allPermissions.access_spaces_read
    ),
    permissionSpaceView: permissions.includes(
      allPermissions.access_spaces_read
    ),
    permissionSpaceWrite: permissions.includes(
      allPermissions.access_spaces_write
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IManagerFromDispatch => {
  return {
    errorMessage: (message) => dispatch(addToast(message, TYPE_ERROR)),
    mounted: () => {
      dispatch(mounted());
    },
    mountedProducts: () => dispatch(setProductView('allProducts')),
  };
};

export default connect<
  IManagerFromState,
  IManagerFromDispatch,
  {},
  _Store.IState
>(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Manager);
