import React, { FC, useState } from 'react';

import { Drawer, Grid, Hidden } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import 'tabler-react/dist/Tabler.css';

import colors from '@Compo/App/colors';
import PageTitle from '@Compo/layout/PageTitle';

import AppBarBooked from './../AppBarBooked';
import VerticalMenu from './../VerticalMenu';
import styles from './Grid.module.scss';
import { IGridProps } from './Grid.types';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colors.other.backdrop,
    borderBottomRightRadius: 64,
    borderTopRightRadius: 64,
    boxShadow: theme.shadows[16],
    marginTop: theme.spacing(4),
    overflowX: 'hidden',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    position: 'fixed',
  },
  menuWrapper: {
    minWidth: 240,
    [theme.breakpoints.down('lg')]: {
      minWidth: 0,
    },
  },
  wrapper: {
    width: 'calc(100% - 240px)',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      margin: '0 auto',
      maxWidth: 1440,
    },
  },
}));

const GridView: FC<IGridProps> = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const classes = useStyles();

  return (
    <>
      <AppBarBooked toggleMenu={() => setOpenMenu(!openMenu)} />
      <Grid container={true} spacing={0} className={styles.container}>
        <div className={classes.menuWrapper}>
          <Grid item={true} className={classes.container}>
            <Hidden only={['lg', 'xl']}>
              <Drawer
                anchor="left"
                open={openMenu}
                onClose={() => setOpenMenu(false)}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                <VerticalMenu />
              </Drawer>
            </Hidden>
            <Hidden lgDown={true}>
              <VerticalMenu />
            </Hidden>
          </Grid>
        </div>
        <Grid
          item={true}
          xs={true}
          className={cn(styles.wrapper, classes.wrapper)}
        >
          <div className={styles.paper}>
            <PageTitle>{children}</PageTitle>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default GridView;
