import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { get, getFormioAccess } from '@Model/authorization/selectors';
import { attachEntryGroup } from '@Model/formio/actions';
import { getAvailabilities } from '@Model/happening/selectors';
import {
  cancelAutoTransaction,
  cancelTransaction,
} from '@Model/reservation/actions';
import { getTimeSlots } from '@Model/reservation/selectors';
import {
  changeReservationDate,
  checkReservationDate,
  getTicket,
  resentTicket,
  resetSelection,
} from '@Model/reservations/actions';
import {
  getCheckAvailabilityMessage,
  getCheckPriceMessage,
  getDetails,
  getEmpikReservationDetails,
  getEmpikReservationTicketValidated,
  isSelectedDateAvailable,
} from '@Model/reservations/selectors';
import {
  allPermissions,
  EMPIK_DETAILS,
  RESERVATION,
  RESERVATION_CHECK,
} from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';

import { IFormLayoutFromDispatch, IFormLayoutFromState } from './FormLayout';
import FormLayout from './FormLayout.component';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => {
  const {
    userInfo: { session, permissions },
  } = get(state);

  return {
    availabilities: getAvailabilities(state),
    availabilityMessage: getCheckAvailabilityMessage(state),
    empikReservationDetails: getEmpikReservationDetails(state),
    isChecking: getLoading(RESERVATION_CHECK)(state),
    isDateAvailable: isSelectedDateAvailable(state),
    isLoading:
      getLoading(RESERVATION)(state) || getLoading(EMPIK_DETAILS)(state),
    isSession: !!(session && session.session_id),
    isValidated: getEmpikReservationTicketValidated(state),
    permissionFormIoAccessListRead: permissions.includes(
      allPermissions.access_list_users_form_io_read
    ),
    permissionRefundsWrite: permissions.includes(
      allPermissions.access_refunds_write
    ),
    permissionReservationWrite: permissions.includes(
      allPermissions.access_reservations_write
    ),
    priceMessage: getCheckPriceMessage(state),
    reservationDetails: getDetails(state),
    showEntryGroupInput: getFormioAccess(state),
    timeSlots: getTimeSlots(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormLayoutFromDispatch => ({
  attachEntryGroup: (groupSlug) => dispatch(attachEntryGroup(groupSlug)),
  cancel: () => dispatch(resetSelection()),
  cancelAutoTransaction: (transactionId) =>
    dispatch(cancelAutoTransaction(transactionId)),
  cancelTransaction: (transactionId) =>
    dispatch(cancelTransaction(transactionId)),
  changeReservationDate: (date) => dispatch(changeReservationDate(date)),
  checkDate: (date) => dispatch(checkReservationDate(date)),
  getTicket: (id) => dispatch(getTicket(id)),
  resentTicket: (id) => dispatch(resentTicket(id)),
});

export default withRouter(
  connect<IFormLayoutFromState, IFormLayoutFromDispatch, {}, _Store.IState>(
    mapStateToProps,
    mapDispatchToProps
  )(FormLayout)
);
