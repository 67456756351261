import { LOCATION_CHANGE } from 'connected-react-router';
import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { showSuccessModal } from '@Model/modal/actions';

import {
  catchGetRuleType,
  catchSavePriceType,
  changePageOrCount,
  deletePriceType,
  getRuleTypes,
  mounted,
  savePriceType,
  selectPriceType,
} from '../actions';
import { getCount, getPage } from '../selectors';
import { IGetPriceTypesSuccessPayload } from '../types';

const MAX_RESULTS_PRICE_TYPES_NUM = 99;

export const catchGetProductsWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf([mounted, changePageOrCount])),
    mergeMap$(() => {
      return of$(getRuleTypes.request(undefined));
    })
  );
};

export const catchGetRuleTypeWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(catchGetRuleType)),
    mergeMap$(() => {
      return of$(getRuleTypes.request(MAX_RESULTS_PRICE_TYPES_NUM));
    })
  );
};

export const getProductsWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { ruleTypesApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getRuleTypes.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      const page = getPage(state);
      const count = action.payload || getCount(state);
      return from$(ruleTypesApi.getPriceTypes(partnerId, page, count)).pipe(
        map$((data: IGetPriceTypesSuccessPayload) => {
          return getRuleTypes.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => ruleTypesApi.cancelProductsTypes())
          )
        ),
        catchError$((error: Error) => of$(getRuleTypes.failure(error)))
      );
    })
  );
};

export const catchSavePriceTypeWhenRequest: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isActionOf(catchSavePriceType)),
    mergeMap$((action) => {
      return of$(savePriceType.request(action.payload));
    })
  );
};

export const savePriceTypeWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { ruleTypesApi }
) => {
  return action$.pipe(
    filter$(isActionOf(savePriceType.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);

      delete action.payload.translation;

      return from$(
        ruleTypesApi.savePriceType(action.payload, Number(partnerId))
      ).pipe(
        mergeMap$((data) => {
          return of$(
            savePriceType.success(data),
            selectPriceType(null),
            getRuleTypes.request(undefined),
            showSuccessModal()
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => ruleTypesApi.cancelProductsTypes())
          )
        ),
        catchError$((error: Error) => of$(getRuleTypes.failure(error)))
      );
    })
  );
};

export const deletePriceTypeWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { ruleTypesApi }
) => {
  return action$.pipe(
    filter$(isActionOf(deletePriceType)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      return from$(ruleTypesApi.deletePriceType(action.payload)).pipe(
        mergeMap$((data) => {
          return of$(getRuleTypes.request(undefined), showSuccessModal());
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => ruleTypesApi.cancelProductsTypes())
          )
        ),
        catchError$((error: Error) => of$(getRuleTypes.failure(error)))
      );
    })
  );
};
