import React, { FC } from 'react';

import makeStyles from '@mui/styles/makeStyles';

import colors from '@Compo/App/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colors.background.paper,
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[8],
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    marginBottom: theme.spacing(3),
  },
}));

const GenericTable: FC = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.container}>{children}</div>;
};

export default GenericTable;
