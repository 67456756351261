import { Selector } from 'reselect';

import _Store from '@Store';

import { IPoolDescriptionsReducer } from '../types';

const get: Selector<_Store.IState, IPoolDescriptionsReducer> = (state) =>
  state.poolDescriptions;

export default get;
