import { date, number, object, string } from 'yup';

export const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const MATCH_VALIDATOR_TEXT =
  'Podaj ciąg alfanumeryczny lub znaki specjalne (-, _)';

const configuration = {
  code: string()
    .matches(/^[a-zA-Z0-9_-]*$/, MATCH_VALIDATOR_TEXT)
    .nullable()
    .required(REQUIRED_VALIDATOR_TEXT),
  endDate: date().nullable(),
  happeningId: number().nullable(),
  partnerId: string().required(REQUIRED_VALIDATOR_TEXT).nullable(),
  percentageValue: number().nullable(),
  priceTypeId: number().nullable(),
  startDate: date().nullable().required(REQUIRED_VALIDATOR_TEXT),
  transactionsLimit: number().nullable(),
  value: number().nullable(),
};

export const DiscountSchema = object().shape(configuration);
