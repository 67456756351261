import React from 'react';

import Grid from '@Compo/layout/Grid';
import Edit from '@Compo/Rundates/Edit';

const AddEventPage = () => (
  <Grid>
    <Edit addEvent={true} />
  </Grid>
);

export default AddEventPage;
