import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPoolsFormValues } from '@Compo/Rundates/Pools/components/PoolsForm/PoolsForm.types';

import { IPoolsReducer } from '../types';
import getPools from './getPools';

const getSelectedPool = createSelector<
  _Store.IState,
  IPoolsReducer,
  IPoolsFormValues | null
>([getPools], (pools) => pools.selectedPool);

export default getSelectedPool;
