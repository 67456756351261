import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import {
  resetSelectionList,
  selectReservation,
} from '@Model/reservations/actions';
import { getDetailsList } from '@Model/reservations/selectors';
import _Store from '@Store';
import { IDetailsModalFromDispatch, IDetailsModalFromState } from './ListModal';
import ListModal from './ListModal.component';

const mapStateToProps = (state: _Store.IState): IDetailsModalFromState => ({
  reservations: getDetailsList(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IDetailsModalFromDispatch => ({
  cancel: () => dispatch(resetSelectionList()),
  selectReservation: (reservationId) => {
    dispatch(resetSelectionList());
    dispatch(selectReservation(reservationId));
  },
});

export default connect<
  IDetailsModalFromState,
  IDetailsModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(ListModal);
