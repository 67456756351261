import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ImportListModalForm from './components/ImportListForm';
import { IImportListModalProps } from './ImportListModal.types';

const ImportListModal = ({ open, closeModal }: IImportListModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates.TicketsList.components.ImportListModal',
  });

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <ImportListModalForm closeModal={closeModal} />
    </Dialog>
  );
};
export default ImportListModal;
