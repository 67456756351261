import _Store from '@Store';
import { createSelector } from 'reselect';
import { IPrinter, IPrinterReducer } from '../types';
import get from './get';

const getActivePrinter = createSelector<
  _Store.IState,
  IPrinterReducer,
  IPrinter | null
>(
  [get],
  (state) => state.activePrinter,
);

export default getActivePrinter;
