import moment from 'moment';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import _Store from '@Store';

import { get as getSession } from '@Model/authorization/selectors';
import { mounted } from '@Model/discounts/actions';
import { resetSelection } from '@Model/events/actions';
import {
  getAvailablePools,
  getSelectedEvent,
  getSelectedSeats,
} from '@Model/events/selectors';
import { resetState } from '@Model/happening/actions';
import { get, getHappenings } from '@Model/happenings/selectors';
import { getTotalPrice } from '@Model/price/selectors';
import { catchGetRuleType } from '@Model/priceTypes/actions';
import { getPriceTypes } from '@Model/priceTypes/selectors';
import { getProducts } from '@Model/products/selectors';
import {
  catchCompanyData,
  clearAdvancePayment,
  saveReservation,
  selectDiscount,
  selectHappening,
  selectPeopleCount,
  selectPrice,
  selectSpace,
  selectTimeSlot,
  selectUpSell,
  setDate,
  setFormData,
} from '@Model/reservation/actions';
import {
  calculateMixPayment,
  getAdd,
  getAdvancePaymentId,
  getDurationAfterMidnight,
  getExtendedSlots,
  getFormData,
  getSelectedExtendedDuration,
  getSelectedSlot,
  getSpacesOfSelectedHappening,
} from '@Model/reservation/selectors';
import getBasketData from '@Model/reservation/selectors/getBasketData';
import getPrices from '@Model/reservation/selectors/getPrices';
import getPricesAsObjects from '@Model/reservation/selectors/getPricesAsObjects';
import getSelectedPrices from '@Model/reservation/selectors/getSelectedPrices';
import prepareDataForFormLayout from '@Model/reservation/selectors/prepareDataForFormLayout';
import {
  allPermissions,
  COMPANY_DATA,
  POST_RESERVATION,
  TRANSACTION_DETAILS,
} from '@Model/state/constants';
import { getLoading, isAnyLoading } from '@Model/state/selectors';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { IFormLayoutFromDispatch, IFormLayoutFromState } from './FormLayout';
import FormLayout from './FormLayout.component';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => {
  const { partnerId } = get(state);
  const durationAfterMidnight = getDurationAfterMidnight(state);

  const {
    userInfo: { permissions, session },
  } = getSession(state);

  return {
    basketData: getBasketData(state),
    controls: prepareDataForFormLayout(state),
    extendedSlots: getExtendedSlots(state),
    formData: getFormData(state),
    happenings: getHappenings(state),
    isCompanyDataLoading: getLoading(COMPANY_DATA)(state),
    isLoading: isAnyLoading(state),
    isMdagUser: permissions.includes(allPermissions.mdag_user),
    isPostReservationLoading: getLoading(POST_RESERVATION)(state),
    isSession: !!(session && session.session_id),
    isTransactionDetailsLoading: getLoading(TRANSACTION_DETAILS)(state),
    minDate: durationAfterMidnight
      ? moment().subtract(1, 'days').toDate()
      : moment().toDate(),
    partnerId,
    paymentAdvanceId: getAdvancePaymentId(state),
    permissionFormIoAccessEmptyTransaction: permissions.includes(
      allPermissions.access_transactionless_reservation
    ),
    pools: getAvailablePools(state),
    priceTypes: getPriceTypes(state),
    prices: getPrices(state),
    pricesAsObjects: getPricesAsObjects(state),
    products: getProducts(state).items,
    selectedDate: getAdd(state).selectedDate,
    selectedEvent: getSelectedEvent(state),
    selectedExtendedSlot: getSelectedExtendedDuration(state),
    selectedPrices: getSelectedPrices(state),
    selectedSeatsIo: getSelectedSeats(state),
    selectedSlot: getSelectedSlot(state),
    spaces: getSpacesOfSelectedHappening(state),
    totalPrice: calculateMixPayment(state).leftToPay || getTotalPrice(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IFormLayoutFromDispatch => ({
  addToast: (text) => dispatch(addToast(text, TYPE_ERROR)),
  catchGetRuleType: () => dispatch(catchGetRuleType()),
  clearAdvancePayment: () => dispatch(clearAdvancePayment()),
  getCompanyData: (nip) => dispatch(catchCompanyData(nip)),
  mounted: () => dispatch(mounted()),
  resetSelection: () => dispatch(resetSelection()),
  resetState: () => dispatch(resetState()),
  saveReservation: (body) => dispatch(saveReservation(body)),
  selectDate: (date) => dispatch(setDate(date)),
  selectDiscount: (space) => dispatch(selectDiscount(space)),
  selectHappening: (happeningId) => dispatch(selectHappening(happeningId)),
  selectPeopleCount: (count) => dispatch(selectPeopleCount(count)),
  selectPrice: (data) => dispatch(selectPrice(data)),
  selectSlot: (slot) => dispatch(selectTimeSlot(slot)),
  selectSpace: (space) => dispatch(selectSpace(space)),
  selectUpSell: (data) => dispatch(selectUpSell(data)),
  setFormData: (data) => dispatch(setFormData(data)),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
