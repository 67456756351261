import colors from '@Compo/App/colors';
import { IEventMailStatus } from '@Services/$email-api/types';

export const getColorFromStatus = (status: IEventMailStatus): string => {
  switch (status) {
    case 'roboczy':
      return colors.text.disabled;
    case 'wysłany':
      return colors.success.light;
    case 'w trakcie wysyłki':
      return colors.warning.light;
    case 'zlecono do wysyłki':
      return colors.info.light;

    default:
      return colors.text.disabled;
  }
};
