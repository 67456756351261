import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IGetStaticsSuccessPayload } from '@Model/statics/types';

class StaticsApi {
  private static getStaticsUrl(): string {
    return `${config.api.baseUrl}admin/get-dashboard-url`;
  }

  private cancelTokenStatics?: CancelTokenSource;

  public getStaticsUrl(): Promise<IGetStaticsSuccessPayload> {
    return new Promise<IGetStaticsSuccessPayload>((resolve, reject) => {
      this.cancelTokenStatics = axios.CancelToken.source();
      axios
        .get(StaticsApi.getStaticsUrl(), {
          cancelToken: this.cancelTokenStatics.token,
        })
        .then(getData)
        .then((response: IGetStaticsSuccessPayload) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelStatics() {
    if (this.cancelTokenStatics) {
      this.cancelTokenStatics.cancel();
      this.cancelTokenStatics = undefined;
    }
  }
}

export default new StaticsApi();
