import { Selector } from 'reselect';

import _Store from '@Store';
import { IEmpikReservationsDetailsReducer } from '../types';

const getEmpikReservationDetails: Selector<
  _Store.IState,
  IEmpikReservationsDetailsReducer
> = (state) => state.reservations.empikReservationDetails;

export default getEmpikReservationDetails;
