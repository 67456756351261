import { combineReducers } from 'redux';

import goingPoolsReducer from './pools';
import goingRundateSingleReducer from './rundate';
import goingRundatesReducer from './rundates';
import goingTicketsReducer from './tickets';

const reducer = combineReducers({
  pools: goingPoolsReducer,
  rundates: goingRundatesReducer,
  singleRundate: goingRundateSingleReducer,
  tickets: goingTicketsReducer,
});

export default reducer;
