import React, { FC } from 'react';

import { View } from '@react-pdf/renderer';

import styles from './TableRow.styles';
import { ITableRowProps } from './TableRow.types';

const TableRow: FC<ITableRowProps> = ({ children, isHeader, style }) => {
  return (
    <View
      style={
        style
          ? [styles.row, style]
          : [styles.row, isHeader ? styles.header : {}]
      }
    >
      {children}
    </View>
  );
};

export default TableRow;
