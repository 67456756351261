import { getType } from 'typesafe-actions';
import { getPools, resetSelection } from './../actions';
import { IAction, IAvailablePoolsReducer } from './../types';

const initialState: IAvailablePoolsReducer = null;

const availabilitiesReducer = (
  state: IAvailablePoolsReducer = initialState,
  action: IAction
): IAvailablePoolsReducer => {
  switch (action.type) {
    case getType(getPools.success):
      return action.payload;
    case getType(resetSelection):
      return initialState;
    default:
      return state;
  }
};

export default availabilitiesReducer;
