import React, { useEffect, useState } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import MuiDatePicker from '@Compo/reusable/MuiDatePicker';
import { Colors } from '@Constants/Colors';
import { TypographyVariant } from '@Constants/Variants/Typography';

import AttachModal from './../AttachModal';
import {
  IAttachDependencies,
  IAttachTypes,
  ISelectedItems,
} from './../AttachModal/AttachModal.types';
import DependencyTable from './components/DependencyTable';
import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const DATE_FORMAT = 'DD.MM.YYYY HH:mm';

const FormLayout = ({
  submitForm,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  closeModal,
  initialValues,
  setFieldTouched,
  permissionEventDiscountsRefundGoing,
  permissionEventDiscountsRefundPartner,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Discounts.components.EventDiscountModal.components.FormLayout',
  });

  const [selectedType, setSelectedType] = useState<IAttachTypes | null>(null);
  const [selected, setSelected] = useState<ISelectedItems>({
    event: [],
    partner: [],
    pool: [],
    rundate: [],
  });

  const classes = useStyles();

  const handleChangeType = (type: IAttachTypes) => {
    setSelectedType(type);
  };
  const closeAttachModal = () => {
    setSelectedType(null);
  };

  const setSelectedItem = (type: IAttachTypes, value: IAttachDependencies) => {
    setSelected({ ...selected, [type]: value });
  };

  const handleDeleteDependency = (type: IAttachTypes, id?: number) => {
    if (id) {
      setSelected({
        ...selected,
        [type]: (selected[type] as IAttachDependencies).filter(
          (value) => value.id !== id
        ),
      });
    }
  };

  useEffect(() => {
    const selectedDependencies = Object.keys(selected)
      .map((key) => ({
        ids: selected[key as IAttachTypes],
        type: key,
      }))
      .filter((dependency) => !!dependency.ids.length);

    setFieldValue('dependencies', selectedDependencies);
  }, [selected]);

  useEffect(() => {
    const initialSelected: ISelectedItems = {} as ISelectedItems;
    initialValues.dependencies.forEach((dependency) => {
      (initialSelected[dependency.type] as IAttachDependencies) =
        dependency.ids;
    });

    setSelected((state) => ({ ...state, ...initialSelected }));
  }, [initialValues.dependencies]);

  useEffect(() => {
    if (values.discountType) {
      setFieldValue('value', 0);
    } else {
      setFieldValue('valuePercentage', 0);
    }
  }, [values.discountType]);

  return (
    <>
      <AttachModal
        setSelected={setSelectedItem}
        selectedItems={selected}
        closeModal={closeAttachModal}
        selectedType={selectedType}
      />
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogTitle>{values.id ? t('editTitle') : t('addTitle')}</DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={3}>
            {values.id ? (
              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <Grid container={true}>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('id')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography variant={TypographyVariant.body1}>
                        {values.id}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container={true}>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('code')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography variant={TypographyVariant.body1}>
                        {values.code}
                      </Typography>
                    </Grid>
                  </Grid>
                </LabelContentContainer>
              </Grid>
            ) : (
              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <TextField
                    variant="standard"
                    id="code"
                    label={(touched.code && errors.code) || t('code')}
                    helperText={t('codeHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.code}
                    name="code"
                    error={!!(touched.code && errors.code)}
                    fullWidth={true}
                  />
                </LabelContentContainer>
              </Grid>
            )}

            <Grid item={true} xs={12}>
              <Grid container={true} spacing={4}>
                <Grid item={true} xs={12} md={4}>
                  <LabelContentContainer>
                    <FormControl
                      variant="standard"
                      fullWidth={true}
                      error={!!(touched.discountType && errors.discountType)}
                    >
                      <InputLabel>
                        {(touched.discountType && errors.discountType) ||
                          t('discountType')}
                      </InputLabel>
                      <Select
                        variant="standard"
                        value={values.discountType || 0}
                        onChange={handleChange}
                        defaultValue={0}
                        fullWidth={true}
                        name="discountType"
                        onBlur={handleBlur}
                      >
                        <MenuItem value={0}>{t('valuePrice')}</MenuItem>
                        <MenuItem value={1}>{t('percent')}</MenuItem>
                      </Select>
                      <FormHelperText>{t('discountTypeHelper')}</FormHelperText>
                    </FormControl>
                  </LabelContentContainer>
                </Grid>

                <Grid item={true} xs={12} md={4}>
                  <LabelContentContainer>
                    {!values.discountType ? (
                      <TextField
                        variant="standard"
                        id="value"
                        label={(touched.value && errors.value) || t('value')}
                        helperText={t('valueHelper')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.value}
                        name="value"
                        error={!!(touched.value && errors.value)}
                        fullWidth={true}
                        type="number"
                      />
                    ) : (
                      <TextField
                        variant="standard"
                        id="valuePercentage"
                        label={
                          (touched.valuePercentage && errors.valuePercentage) ||
                          t('value')
                        }
                        helperText={t('valueHelper')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.valuePercentage}
                        name="valuePercentage"
                        error={
                          !!(touched.valuePercentage && errors.valuePercentage)
                        }
                        fullWidth={true}
                        type="number"
                      />
                    )}
                  </LabelContentContainer>
                </Grid>

                <Grid item={true} xs={12} md={4}>
                  <LabelContentContainer>
                    <FormControl
                      variant="standard"
                      fullWidth={true}
                      error={!!(touched.refundType && errors.refundType)}
                    >
                      <InputLabel>
                        {(touched.refundType && errors.refundType) ||
                          t('refundType')}
                      </InputLabel>
                      <Select
                        variant="standard"
                        value={
                          values.refundType ||
                          !permissionEventDiscountsRefundGoing
                            ? 2
                            : 1
                        }
                        onChange={handleChange}
                        defaultValue={-1}
                        fullWidth={true}
                        name="refundType"
                        onBlur={handleBlur}
                      >
                        {permissionEventDiscountsRefundGoing && (
                          <MenuItem value={1}>{t('goingRefundType')}</MenuItem>
                        )}
                        {permissionEventDiscountsRefundPartner && (
                          <MenuItem value={2}>
                            {t('partnerRefundType')}
                          </MenuItem>
                        )}
                      </Select>
                      <FormHelperText>{t('refundTypeHelper')}</FormHelperText>
                    </FormControl>
                  </LabelContentContainer>
                </Grid>
              </Grid>
            </Grid>

            <Grid item={true} xs={12} md={6}>
              <LabelContentContainer>
                <MuiDatePicker
                  name="startDate"
                  disableToolbar={true}
                  fullWidth={true}
                  variant="standard"
                  format="DD.MM.yyyy"
                  label={
                    (touched.startDate && errors.startDate) || t('startDate')
                  }
                  onBlur={handleBlur}
                  error={!!(touched.startDate && errors.startDate)}
                  helperText={t('startDateHelper')}
                  value={values.startDate}
                  onChange={(date) => setFieldValue('startDate', date)}
                />
              </LabelContentContainer>
            </Grid>

            <Grid item={true} xs={12} md={6}>
              <LabelContentContainer>
                <TextField
                  variant="standard"
                  type="time"
                  id="startTime"
                  label={
                    (touched.startTime && errors.startTime) || t('startTime')
                  }
                  helperText={t('startTimeHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.startTime}
                  name="startTime"
                  error={!!(touched.startTime && errors.startTime)}
                  fullWidth={true}
                  InputProps={{
                    className: cn(classes.defaultTimeInput, classes.time),
                  }}
                />
              </LabelContentContainer>
            </Grid>

            <Grid item={true} xs={12} md={6}>
              <LabelContentContainer>
                <MuiDatePicker
                  name="expireDate"
                  disableToolbar={true}
                  fullWidth={true}
                  variant="standard"
                  format="DD.MM.yyyy"
                  label={
                    (touched.expireDate && errors.expireDate) || t('expireDate')
                  }
                  onBlur={handleBlur}
                  error={!!(touched.expireDate && errors.expireDate)}
                  helperText={t('expireDateHelper')}
                  value={values.expireDate}
                  onChange={(date) => {
                    setFieldValue('expireDate', date);
                    setFieldTouched('expireDate', true);
                  }}
                />
              </LabelContentContainer>
            </Grid>

            <Grid item={true} xs={12} md={6}>
              <LabelContentContainer>
                <TextField
                  variant="standard"
                  type="time"
                  id="expireTime"
                  label={
                    (touched.expireTime && errors.expireTime) || t('expireTime')
                  }
                  helperText={t('expireTimeHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.expireTime}
                  name="expireTime"
                  error={!!(touched.expireTime && errors.expireTime)}
                  fullWidth={true}
                  InputProps={{
                    className: cn(classes.defaultTimeInput, classes.time),
                  }}
                />
              </LabelContentContainer>
            </Grid>

            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <TextField
                  variant="standard"
                  id="itemLimit"
                  label={
                    (touched.itemLimit && errors.itemLimit) ||
                    t('transactionLimit')
                  }
                  helperText={t('transactionLimitHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.itemLimit}
                  name="itemLimit"
                  error={!!(touched.itemLimit && errors.itemLimit)}
                  fullWidth={true}
                  type="number"
                  inputProps={{
                    numeric: true,
                    pattern: '[0-9]*',
                  }}
                />
              </LabelContentContainer>
            </Grid>

            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <TextField
                  variant="standard"
                  id="ticketLimit"
                  label={
                    (touched.ticketLimit && errors.ticketLimit) ||
                    t('ticketsLimit')
                  }
                  helperText={t('ticketsLimitHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ticketLimit}
                  name="ticketLimit"
                  error={!!(touched.ticketLimit && errors.ticketLimit)}
                  fullWidth={true}
                  type="number"
                  inputProps={{
                    numeric: true,
                    pattern: '[0-9]*',
                  }}
                />
              </LabelContentContainer>
            </Grid>

            <Grid item={true} xs={12}>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <Grid container={true} spacing={1}>
                    <Grid item={true} xs={12}>
                      <Typography variant={TypographyVariant.body1}>
                        {t('assignDiscountCodeTitle')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <Typography
                        variant={TypographyVariant.caption}
                        color="textSecondary"
                      >
                        {t('assignDiscountCodeDesc')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item={true} xs={12}>
                  <Typography variant={TypographyVariant.body1}>
                    {t('partners')}
                  </Typography>
                  <DependencyTable
                    selectedType={'partner'}
                    handleChangeType={handleChangeType}
                    dependencies={selected.partner}
                    handleDeleteDependency={handleDeleteDependency}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <Typography variant={TypographyVariant.body1}>
                    {t('events')}
                  </Typography>
                  <DependencyTable
                    selectedType={'event'}
                    handleChangeType={handleChangeType}
                    dependencies={selected.event}
                    handleDeleteDependency={handleDeleteDependency}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <Typography variant={TypographyVariant.body1}>
                    {t('rundates')}
                  </Typography>
                  <DependencyTable
                    selectedType={'rundate'}
                    handleChangeType={handleChangeType}
                    dependencies={selected.rundate.map((rundate) => ({
                      ...rundate,
                      rundate: moment(rundate.rundate).format(DATE_FORMAT),
                    }))}
                    handleDeleteDependency={handleDeleteDependency}
                  />
                </Grid>

                <Grid item={true} xs={12}>
                  <Typography variant={TypographyVariant.body1}>
                    {t('pools')}
                  </Typography>
                  <DependencyTable
                    selectedType={'pool'}
                    handleChangeType={handleChangeType}
                    dependencies={selected.pool.map((pool) => ({
                      ...pool,
                      rundate: {
                        ...pool.rundate,
                        rundate: moment(pool.rundate?.rundate).format(
                          DATE_FORMAT
                        ),
                      },
                    }))}
                    handleDeleteDependency={handleDeleteDependency}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {values.id ? t('update') : t('add')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
