import { setToken } from '@Model/app/actions';
import { authorizationFail, getToken } from '@Model/authorization/actions';
import _Store from '@Store';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetError, resetLoading, setLoading } from '../actions';
import { AUTHORIZATION } from '../constants/constants';

export const setUpStateStartWhenStartMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getToken.request)),
    mergeMap$(() => [resetError(AUTHORIZATION), setLoading(AUTHORIZATION)]),
  );
};

export const setLoaderStartAppSetFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf([setToken, authorizationFail, getToken.failure], action),
    ),
    map$(() => resetLoading(AUTHORIZATION)),
  );
};
