import { createSelector } from 'reselect';

import _Store from '@Store';

import { ICheckExternalResponse } from '@Services/$going-rundate-api/types';

import { ITicketsReducer } from '../types';
import getTickets from './getTickets';

const getExternalWarnings = createSelector<
  _Store.IState,
  ITicketsReducer,
  ICheckExternalResponse | null
>([getTickets], (tickets) => tickets.externalTicketsWarning);

export default getExternalWarnings;
