import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,
  },
  buttonsContainer: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: theme.spacing(2),
    },
  },
  content: {
    overflow: 'hidden',
  },
  form: {
    overflowY: 'auto',
  },
  infoItem: {
    backgroundColor: theme.palette.action.focus,
    padding: theme.spacing(2),
  },
}));

export default useStyles;
