import React, { useEffect } from 'react';

import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import MuiDatePicker from '@Compo/reusable/MuiDatePicker';
import { TypographyVariant } from '@Constants/Variants/Typography';

import { ISettlementsAddModalValues } from './../SettlementsAddModal.types';
import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

export const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Settlements.SettlementsAddModal.FormLayout',
  });

  const {
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    handleActiveSettlement,
    touched,
    errors,
    submitForm,
    settlementRundates,
    settlementMetadata,
    fetchSettlementRundates,
    isLoading,
    isLoadingAfterCreate,
  } = props;

  const classes = useStyles();

  const { startDate, endDate, settlementTypeId } = values;

  const catchHandleClose = () => {
    handleActiveSettlement(null);
  };

  const handleMultipleCheckboxChange = (
    name: keyof ISettlementsAddModalValues,
    value: string
  ) => {
    if (Array.isArray(values[name]) && values[name]) {
      const index = (values[name] as any).indexOf(value);
      if (index === -1) {
        (values[name] as any).push(value);
      } else {
        (values[name] as any).splice(index, 1);
      }
      setFieldValue(name, values[name]);
    }
  };

  const checkRundateId = (id: number) => {
    return values.rundateIds?.map(Number).some((rundate) => rundate === id);
  };

  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (startDate && endDate && settlementTypeId) {
      fetchSettlementRundates({ startDate, endDate, settlementTypeId });
    }
  }, [settlementTypeId, startDate, endDate]);

  if (!settlementMetadata) {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          {isLoadingAfterCreate ? (
            <Loader />
          ) : (
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <Grid container={true} spacing={matches ? 3 : 4}>
                  <Grid item={true} xs={12} lg={6}>
                    <LabelContentContainer>
                      <MuiDatePicker
                        name="startDate"
                        disableToolbar={true}
                        fullWidth={true}
                        variant="standard"
                        format="DD.MM.yyyy"
                        label={
                          (touched.startDate && errors.startDate) ||
                          t('startDate')
                        }
                        helperText={t('startDateHelper')}
                        value={values.startDate}
                        onBlur={handleBlur}
                        onChange={(date) =>
                          setFieldValue(
                            'startDate',
                            moment(date).format('YYYY-MM-DDTHH:mm:ssZ')
                          )
                        }
                        error={!!(touched.startDate && errors.startDate)}
                      />
                    </LabelContentContainer>
                  </Grid>
                  <Grid item={true} xs={12} lg={6}>
                    <LabelContentContainer>
                      <MuiDatePicker
                        name="endDate"
                        disableToolbar={true}
                        fullWidth={true}
                        variant="standard"
                        format="DD.MM.yyyy"
                        label={
                          (touched.endDate && errors.endDate) || t('endDate')
                        }
                        helperText={t('endDateHelper')}
                        value={values.endDate}
                        onBlur={handleBlur}
                        onChange={(date) =>
                          setFieldValue(
                            'endDate',
                            moment(date).format('YYYY-MM-DDTHH:mm:ssZ')
                          )
                        }
                        error={!!(touched.endDate && errors.endDate)}
                      />
                    </LabelContentContainer>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    error={
                      !!(touched.settlementTypeId && errors.settlementTypeId)
                    }
                  >
                    <InputLabel>
                      {(touched.settlementTypeId && errors.settlementTypeId) ||
                        t('settlementsType')}
                    </InputLabel>
                    <Select
                      value={values.settlementTypeId}
                      onChange={handleChange}
                      defaultValue={1}
                      fullWidth={true}
                      name="settlementTypeId"
                      onBlur={handleBlur}
                    >
                      {settlementMetadata.settlementTypes.map(
                        (transactionType) => (
                          <MenuItem
                            key={transactionType.id}
                            value={transactionType.id}
                          >
                            {transactionType.name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    <FormHelperText>
                      {t('settlementsTypeHelper')}
                    </FormHelperText>
                  </FormControl>
                </LabelContentContainer>
              </Grid>
              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    error={!!(touched.salesChannelId && errors.salesChannelId)}
                  >
                    <InputLabel>
                      {(touched.salesChannelId && errors.salesChannelId) ||
                        t('salesChannel')}
                    </InputLabel>
                    <Select
                      value={values.salesChannelId || 0}
                      onChange={handleChange}
                      defaultValue={0}
                      fullWidth={true}
                      name="salesChannelId"
                      onBlur={handleBlur}
                    >
                      {settlementMetadata.salesChannels.map((salesChannel) => (
                        <MenuItem key={salesChannel.id} value={salesChannel.id}>
                          {salesChannel.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{t('salesChannelHelper')}</FormHelperText>
                  </FormControl>
                </LabelContentContainer>
              </Grid>
              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <FormControl
                    variant="standard"
                    fullWidth={true}
                    error={
                      !!(touched.transactionType && errors.transactionType)
                    }
                  >
                    <InputLabel>
                      {(touched.transactionType && errors.transactionType) ||
                        t('transactionType')}
                    </InputLabel>
                    <Select
                      value={values.transactionType}
                      onChange={handleChange}
                      defaultValue={values.transactionType}
                      fullWidth={true}
                      name="transactionType"
                      onBlur={handleBlur}
                    >
                      {settlementMetadata.transactionTypes.map((type) => (
                        <MenuItem key={type.code} value={type.code}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      {t('transactionTypeHelper')}
                    </FormHelperText>
                  </FormControl>
                </LabelContentContainer>
              </Grid>

              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <TextField
                    id="limit"
                    label={(touched.limit && errors.limit) || t('limit')}
                    helperText={t('limitHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.limit}
                    name="limit"
                    error={!!(touched.limit && errors.limit)}
                    fullWidth={true}
                    type="number"
                  />
                </LabelContentContainer>
              </Grid>
              <Grid item={true} xs={12}>
                <FormControlLabel
                  className={classes.checkboxBox}
                  control={
                    <Checkbox
                      checked={values.freeTickets}
                      onChange={handleChange}
                      name="freeTickets"
                      color="primary"
                      size="small"
                    />
                  }
                  label={
                    <Typography
                      variant={TypographyVariant.body1}
                      color="textPrimary"
                    >
                      {t('freeTickets')}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item={true} xs={12}>
                <FormControlLabel
                  className={classes.checkboxBox}
                  control={
                    <Checkbox
                      checked={values.withSettledTickets}
                      onChange={handleChange}
                      name="withSettledTickets"
                      color="primary"
                      size="small"
                    />
                  }
                  label={
                    <Typography
                      variant={TypographyVariant.body1}
                      color="textPrimary"
                    >
                      {t('withSettledTickets')}
                    </Typography>
                  }
                />
              </Grid>

              {!!settlementRundates.length && !isLoading && (
                <Grid item={true} xs={12}>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12}>
                      <Typography variant={TypographyVariant.body1}>
                        {t('chooseEvent')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={12}>
                      <LabelContentContainer>
                        <FormControl component="fieldset">
                          <FormGroup>
                            <Grid container={true} spacing={2}>
                              {settlementRundates.map((rundate) => (
                                <Grid item={true} xs={12} key={rundate.id}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={(e) =>
                                          handleMultipleCheckboxChange(
                                            'rundateIds',
                                            e.target.value
                                          )
                                        }
                                        name="rundateIds"
                                        value={rundate.id}
                                        color="primary"
                                        size="small"
                                        defaultChecked={checkRundateId(
                                          rundate.id
                                        )}
                                      />
                                    }
                                    label={
                                      <Typography
                                        variant={TypographyVariant.body1}
                                        color="textPrimary"
                                      >
                                        {rundate.name}
                                      </Typography>
                                    }
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </FormGroup>
                        </FormControl>
                      </LabelContentContainer>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {isLoading && (
                <Grid item={true} xs={12}>
                  <Loader className={classes.loader} />
                </Grid>
              )}
            </Grid>
          )}
        </DialogContent>
      </form>

      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={catchHandleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
          disabled={isLoadingAfterCreate}
        >
          {t('add')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
