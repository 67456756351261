import { createSelector } from 'reselect';

import _Store from '@Store';

import { IPoolDescription } from '@Services/$pool-descriptions-api/types';

import { IPoolDescriptionsReducer } from './../types';
import get from './get';

const getPoolDescriptions = createSelector<
  _Store.IState,
  IPoolDescriptionsReducer,
  IPoolDescription[]
>([get], (poolDescription) => poolDescription.poolDescriptions);

export default getPoolDescriptions;
