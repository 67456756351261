import moment from 'moment';
import { createSelector } from 'reselect';

import { IRundatePartner } from '@Services/$discounts-api/types';
import _Store from '@Store';
import { IDiscountReducer } from './../types';
import get from './get';

const getRundatesPartner = createSelector<
  _Store.IState,
  IDiscountReducer,
  IRundatePartner[]
>([get], (discount) => {
  return discount.rundates.map((rundates) => {
    return {
      ...rundates,
      rundate: moment(rundates.rundate).format('DD.MM.YYYY HH:mm'),
    };
  });
});

export default getRundatesPartner;
