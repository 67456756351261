export enum MetadataType {
  Partner = 'partner',
  Event = 'event',
  Rundate = 'rundate',
  Pool = 'pool',
}

export type IMetadataTypes =
  | MetadataType.Partner
  | MetadataType.Event
  | MetadataType.Rundate
  | MetadataType.Pool;

export type IDependencyTypes =
  | IPartner
  | IEventPartner
  | IRundatePartner
  | IPoolPartner;

export type IMetadataDependencies = IDependencyTypes[];

export interface IPartnersResponse {
  data: IPartner[];
  meta: IMeta;
}

export interface IPartner {
  nameInterface: MetadataType.Partner;
  id: number;
  name: string;
}

export interface IEventsPartnerResponse {
  data: IEventPartner[];
  meta: IMeta;
}

export interface IEventPartner extends IEvent {
  partner?: IPartner;
}

export interface IRundatesPartnerResponse {
  data: IRundatePartner[];
  meta: IMeta;
}

export interface IRundatePartner extends IRundate {
  partner?: IPartner;
}

export interface IEvent {
  nameInterface: MetadataType.Event;
  id?: number;
  titlePL?: string;
  isCalendarEvent?: boolean;
}

export interface IPoolsPartnerResponse {
  data: IPoolPartner[];
  meta: IMeta;
}

export interface IPoolPartner {
  nameInterface: MetadataType.Pool;
  id?: number;
  name?: string;
  price?: number;
  rundate?: IRundate;
  poolDescription?: IPoolDescription;
  partner?: IPartner;
}

export interface IPoolDescription {
  id?: number;
  titlePL?: string;
  descriptionPL?: string;
}

export interface IRundate {
  nameInterface: MetadataType.Rundate;
  id?: number;
  rundateDescription?: string;
  rundate?: string;
  date?: string;
  time?: string;
  event?: IEvent;
}

export interface IMeta {
  size?: number;
  page: number;
  total?: number;
}
