import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { prepareInitialValues } from '@Compo/Users/components/GroupsTable/components/GroupsModal/GroupsModal.helpers';
import { prepareInitialValues as prepareUserValues } from '@Compo/Users/components/UsersTable/components/UsersModal/UsersModal.helpers';
import { getPartnerIds } from '@Model/authorization/selectors';
import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { showSuccessModal } from '@Model/modal/actions';
import {
  changeUserPassword,
  changeUserStatus,
  getAllPartners,
  getPartners,
  handleActiveUser,
} from '@Model/users/actions';
import { IChangePasswordBody, IUserBody } from '@Services/$users-api/types';
import i18next from '@Translations/i18n';

import {
  catchChangeUserPassword,
  catchChangeUserStatus,
  catchDeleteGroup,
  catchGetSingleGroup,
  catchGetSingleUser,
  catchSaveGroup,
  catchSaveUser,
  deleteGroup,
  getAllGroups,
  getGroups,
  getPermissions,
  getSingleGroup,
  getSingleUser,
  getUsers,
  handleActiveFilter,
  handleActiveGroup,
  handleActivePageGroups,
  handleActivePageUsers,
  handleActiveSizeGroups,
  handleActiveSizeUsers,
  handleSearchGroups,
  handleSearchUsers,
  mountedGroups,
  mountedPermissions,
  mountedUsers,
  saveGroup,
  saveUser,
} from './../actions';
import {
  getActiveFilter,
  getMetaGroups,
  getMetaUsers,
  getSearchTextGroups,
  getSearchTextUsers,
} from './../selectors';

const MAX_COUNT = 9999;
const ADD_USER_TEXT = 'Użytkownik został dodany.';

export const requestUsersWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        mountedUsers,
        handleActivePageUsers,
        handleActiveSizeUsers,
        handleSearchUsers,
      ])
    ),
    mergeMap$(() => {
      return of$(getUsers.request());
    })
  );
};

export const fetchUsersWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getUsers.request)),
    withLatestFrom$(state$),
    mergeMap$(([, state]) => {
      const meta = getMetaUsers(state);
      const searchText = getSearchTextUsers(state);
      const partnerId = getHappeningPartnerId(state);

      return from$(
        usersApi.getUsers(meta.size, meta.page, searchText, Number(partnerId))
      ).pipe(
        map$((data) => {
          return getUsers.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => usersApi.cancelGetUsers())
          )
        ),
        catchError$((error) => {
          return of$(getUsers.failure(error));
        })
      );
    })
  );
};

export const requestGroupsWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        handleActivePageGroups,
        handleActiveSizeGroups,
        mountedGroups,
        handleSearchGroups,
      ])
    ),
    mergeMap$(() => {
      return of$(getGroups.request({}));
    })
  );
};

export const getAllGroupsWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getAllGroups)),
    mergeMap$((action) => {
      return of$(
        getGroups.request({ count: MAX_COUNT, partnerIds: action.payload })
      );
    })
  );
};

export const getAllPartnersWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getAllPartners)),
    mergeMap$(() => {
      return of$(getPartners.request(MAX_COUNT));
    })
  );
};

export const fetchGroupsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getGroups.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const meta = getMetaGroups(state);
      const searchText = getSearchTextGroups(state);
      const partnerIds = action.payload.partnerIds
        ? getPartnerIds(state)?.filter((x) =>
            action.payload.partnerIds?.includes(x)
          )
        : [Number(getHappeningPartnerId(state))];

      return from$(
        usersApi.getGroups(
          action.payload.count
            ? action.payload.count
            : meta.size !== MAX_COUNT
            ? meta.size
            : 20,
          meta.page,
          searchText,
          partnerIds
        )
      ).pipe(
        map$((data) => {
          return getGroups.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => usersApi.cancelGetUsers())
          )
        ),
        catchError$((error) => {
          return of$(getGroups.failure(error));
        })
      );
    })
  );
};

export const fetchPartnersWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { metadataApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPartners.request)),
    mergeMap$((action) => {
      return from$(
        metadataApi.getPartners(1, action.payload || MAX_COUNT)
      ).pipe(
        map$((data) => {
          return getPartners.success(
            data.data.map((partner) => ({
              id: partner.id.toString(),
              name: partner.name,
            }))
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => metadataApi.cancelMetadata())
          )
        ),
        catchError$((error) => {
          return of$(getPartners.failure(error));
        })
      );
    })
  );
};

export const requestPermissionsWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([mountedPermissions, handleActiveFilter])),
    mergeMap$(() => {
      return of$(getPermissions.request());
    })
  );
};

export const fetchPermissionsWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getPermissions.request)),
    withLatestFrom$(state$),
    mergeMap$(([, state]) => {
      const appFilter = getActiveFilter(state);

      return from$(usersApi.getPermissions(appFilter)).pipe(
        map$((data) => {
          return getPermissions.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => usersApi.cancelGetUsers())
          )
        ),
        catchError$((error) => {
          return of$(getPermissions.failure(error));
        })
      );
    })
  );
};

export const catchSaveGroupWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchSaveGroup)),
    mergeMap$((action) => {
      return of$(saveGroup.request(action.payload));
    })
  );
};

export const saveGroupWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveGroup.request)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);

      return from$(usersApi.saveGroup(action.payload, Number(partnerId))).pipe(
        mergeMap$((data) => {
          return of$(
            showSuccessModal(),
            getGroups.request({}),
            saveGroup.success(data),
            handleActiveGroup(null)
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => usersApi.cancelGetUsers())
          )
        ),
        catchError$((error: Error) => of$(saveGroup.failure(error)))
      );
    })
  );
};

export const catchDeleteGroupWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchDeleteGroup)),
    mergeMap$((action) => {
      return of$(deleteGroup.request(action.payload));
    })
  );
};

export const deleteGroupWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter$(isActionOf(deleteGroup.request)),
    mergeMap$((action) => {
      return from$(usersApi.deleteGroup(action.payload)).pipe(
        mergeMap$((data) => {
          return of$(
            showSuccessModal(),
            getGroups.request({}),
            deleteGroup.success(data)
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => usersApi.cancelGetUsers())
          )
        ),
        catchError$((error: Error) => of$(deleteGroup.failure(error)))
      );
    })
  );
};

export const catchGetSingleGroupWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchGetSingleGroup)),
    mergeMap$((action) => {
      return of$(getSingleGroup.request(action.payload));
    })
  );
};

export const getSingleGroupWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSingleGroup.request)),
    mergeMap$((action) => {
      return from$(usersApi.getSingleGroup(action.payload)).pipe(
        mergeMap$((data) => {
          return of$(
            getSingleGroup.success(data),
            handleActiveGroup(prepareInitialValues(data))
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => usersApi.cancelGetUsers())
          )
        ),
        catchError$((error: Error) => of$(getSingleGroup.failure(error)))
      );
    })
  );
};

export const catchSaveUserWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchSaveUser)),
    mergeMap$((action) => {
      return of$(saveUser.request(action.payload));
    })
  );
};

export const saveUserWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveUser.request)),
    mergeMap$((action) => {
      const {
        firstName,
        lastName,
        email,
        password,
        cmsPartners,
        access,
        id,
        individualPermissionsIds,
        force,
      } = action.payload;

      const flatAccess = Object.keys(access)
        .map((key) => access[key])
        .flatMap((num) => num);

      const body: IUserBody = {
        cmsPartnerIds: cmsPartners.map((partner) => Number(partner.id)),
        email,
        firstName,
        force,
        groupIds: flatAccess.filter((group) => group !== -2),
        id,
        individualPermissionsIds,
        lastName,
        password: password?.length ? password : undefined,
      };

      return from$(usersApi.saveUser(body)).pipe(
        mergeMap$((data) => {
          return of$(
            showSuccessModal({ message: data || ADD_USER_TEXT }),
            getUsers.request(),
            saveUser.success(data),
            handleActiveUser(null)
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => usersApi.cancelGetUsers())
          )
        ),
        catchError$((error: Error) =>
          of$(saveUser.failure({ ...error, values: action.payload }))
        )
      );
    })
  );
};

export const showConfirmationModalWhenSaveUserFailure: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(saveUser.failure)),
    mergeMap$((action) => {
      if (action.payload.statusCode === 426) {
        return of$(
          showSuccessModal({
            message: action.payload.message,
            onConfirm: catchSaveUser({ ...action.payload.values, force: true }),
            title: i18next.t('components:reusable.SuccessModal.confirmTitle'),
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const catchChangeUserStatusWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchChangeUserStatus)),
    mergeMap$((action) => {
      return of$(changeUserStatus.request(action.payload));
    })
  );
};

export const changeUserStatusWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter$(isActionOf(changeUserStatus.request)),
    mergeMap$((action) => {
      return from$(
        usersApi.changeUserStatus(action.payload.id, action.payload.status)
      ).pipe(
        mergeMap$((data) => {
          return of$(
            showSuccessModal(),
            getUsers.request(),
            changeUserStatus.success(data)
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => usersApi.cancelGetUsers())
          )
        ),
        catchError$((error: Error) => of$(changeUserStatus.failure(error)))
      );
    })
  );
};

export const catchGetSingleUserWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchGetSingleUser)),
    mergeMap$((action) => {
      return of$(getSingleUser.request(action.payload));
    })
  );
};

export const getSingleUserWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getSingleUser.request)),
    mergeMap$((action) => {
      return from$(usersApi.getSingleUser(action.payload)).pipe(
        mergeMap$((data) => {
          return of$(
            getSingleUser.success(data),
            handleActiveUser(prepareUserValues(data))
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => usersApi.cancelGetUsers())
          )
        ),
        catchError$((error: Error) => of$(getSingleUser.failure(error)))
      );
    })
  );
};

export const catchChangeUserPasswordWhenRequested: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(catchChangeUserPassword)),
    mergeMap$((action) => {
      return of$(changeUserPassword.request(action.payload));
    })
  );
};

export const changeUserPasswordWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { usersApi }
) => {
  return action$.pipe(
    filter$(isActionOf(changeUserPassword.request)),
    mergeMap$((action) => {
      const body: IChangePasswordBody = {
        _password: action.payload.password,
        _userId: action.payload.id,
      };

      return from$(usersApi.changeUserPassword(body)).pipe(
        mergeMap$((data) => {
          return of$(showSuccessModal(), changeUserPassword.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => usersApi.cancelGetUsers())
          )
        ),
        catchError$((error: Error) => of$(changeUserPassword.failure(error)))
      );
    })
  );
};
