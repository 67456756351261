import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './MixPaymentInDetails.styles';
import { IMixPaymentInDetailsProps } from './MixPaymentInDetails.types';

const MixPaymentInDetails = ({ mixPayment }: IMixPaymentInDetailsProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'TransactionsDelayed.components.MixPaymentInDetails',
  });

  const classes = useStyles();
  return (
    <Grid container={true} className={classes.container} spacing={2}>
      <Grid item={true} xs={12}>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={6}>
            <Typography variant="body1" color="textSecondary">
              {t('payment')}
            </Typography>
          </Grid>
          <Grid item={true} xs={6}>
            <Typography variant="body1" color="textSecondary" align="right">
              {mixPayment.total} {t('currency')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12}>
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={6}>
            <Typography variant="body1" color="primary">
              {t('letfToPey')}
            </Typography>
          </Grid>
          <Grid item={true} xs={6}>
            <Typography variant="h5" color="primary" align="right">
              {mixPayment.leftToPay} {t('currency')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MixPaymentInDetails;
