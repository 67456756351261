import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import { Colors } from '@Constants/Colors';
import { TypographyVariant } from '@Constants/Variants/Typography';
import { IPartner } from '@Model/authorization/types';

import ChangePasswordModal from './../ChangePasswordModal';
import Access from './Access';
import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const PASSWORD_PLACEHOLDER_TEXT = '••••••••••••';
const DEFAULT_ACCESS_ID = -1;

const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Users.components.UsersTable.FormLayout',
  });

  const {
    handleSubmit,
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    submitForm,
    setFieldValue,
    handleClose,
    getAllPartners,
    partners,
  } = props;

  const [openPassword, setOpenPassword] = useState(false);

  const classes = useStyles();

  const handleChangeAutocomplete = (
    name: keyof typeof values,
    value: IPartner[] | null
  ) => {
    setFieldValue(name, value);
  };

  const getOptionLabel = (partner: IPartner) => partner.name;

  const onAutoCompleteChange = (
    event: React.ChangeEvent<{}>,
    value: IPartner[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<IPartner>
  ) => {
    handleChangeAutocomplete('cmsPartners', value);

    if (details) {
      if (reason === 'selectOption') {
        setFieldValue('access', {
          ...values.access,
          [details.option.id]: [DEFAULT_ACCESS_ID],
        });
      }
      if (reason === 'removeOption' && details) {
        delete values.access[details.option.id];
        setFieldValue('access', values.access);
      }
    }
  };

  const Option = (
    optionProps: React.HTMLAttributes<HTMLLIElement>,
    option: IPartner
  ) => {
    return (
      <li {...optionProps} key={option.id}>
        {option.name}
      </li>
    );
  };

  useEffect(() => {
    getAllPartners();
  }, []);

  return (
    <>
      <ChangePasswordModal open={openPassword} setOpen={setOpenPassword} />
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <TextField
                  variant="standard"
                  id="email"
                  label={(touched.email && errors.email) || t('email')}
                  helperText={t('emailHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  name="email"
                  error={!!(touched.email && errors.email)}
                  fullWidth={true}
                />
              </LabelContentContainer>
            </Grid>
            {!values.id ? (
              <>
                <Grid item={true} xs={12}>
                  <LabelContentContainer>
                    <TextField
                      variant="standard"
                      id="password"
                      label={
                        (touched.password && errors.password) || t('password')
                      }
                      helperText={t('passwordHelper')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      name="password"
                      type="password"
                      error={!!(touched.password && errors.password)}
                      fullWidth={true}
                    />
                  </LabelContentContainer>
                </Grid>
                <Grid item={true} xs={12}>
                  <LabelContentContainer>
                    <TextField
                      variant="standard"
                      id="repeatPassword"
                      label={
                        (touched.repeatPassword && errors.repeatPassword) ||
                        t('repeatPassword')
                      }
                      helperText={t('repeatPasswordHelper')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.repeatPassword}
                      name="repeatPassword"
                      error={
                        !!(touched.repeatPassword && errors.repeatPassword)
                      }
                      fullWidth={true}
                      type="password"
                    />
                  </LabelContentContainer>
                </Grid>
              </>
            ) : (
              <Grid item={true} xs={12} sm={6} md={4}>
                <LabelContentContainer>
                  <Grid
                    container={true}
                    spacing={1}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Grid item={true} xs={8}>
                      <Grid container={true}>
                        <Grid item={true} xs={12}>
                          <Typography
                            variant={TypographyVariant.caption}
                            color={Colors.textSecondary}
                          >
                            {t('password')}
                          </Typography>
                        </Grid>
                        <Grid item={true} xs={12}>
                          <Typography>{PASSWORD_PLACEHOLDER_TEXT}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item={true}>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        className={classes.button}
                        onClick={() => setOpenPassword(true)}
                      >
                        {t('changePassword')}
                      </Button>
                    </Grid>
                  </Grid>
                </LabelContentContainer>
              </Grid>
            )}
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <TextField
                  variant="standard"
                  id="firstName"
                  label={
                    (touched.firstName && errors.firstName) || t('firstName')
                  }
                  helperText={t('firstNameHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  name="firstName"
                  error={!!(touched.firstName && errors.firstName)}
                  fullWidth={true}
                />
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <TextField
                  variant="standard"
                  id="lastName"
                  label={(touched.lastName && errors.lastName) || t('lastName')}
                  helperText={t('lastNameHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  name="lastName"
                  error={!!(touched.lastName && errors.lastName)}
                  fullWidth={true}
                />
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <FormControl
                  variant="standard"
                  fullWidth={true}
                  error={!!(touched.cmsPartners && errors.cmsPartners)}
                >
                  <Autocomplete
                    multiple={true}
                    disableCloseOnSelect={true}
                    options={partners || []}
                    defaultValue={values.cmsPartners}
                    onChange={onAutoCompleteChange}
                    onBlur={handleBlur}
                    fullWidth={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        error={!!(touched.cmsPartners && errors.cmsPartners)}
                        label={
                          (touched.cmsPartners && errors.cmsPartners) ||
                          t('partner')
                        }
                      />
                    )}
                    renderOption={Option}
                    getOptionLabel={getOptionLabel}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          color="secondary"
                          label={option.name}
                          {...getTagProps({ index })}
                          className={classes.chip}
                          key={index}
                        />
                      ))
                    }
                  />
                  <FormHelperText>{t('partnerHelper')}</FormHelperText>
                </FormControl>
              </LabelContentContainer>
            </Grid>

            <Access {...props} />
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={handleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
