import { object, string } from 'yup';

export const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const EMAIL_ADDRESS_IS_REQUIRED_VALIDATOR = 'Adres e-mail jest wymagany';
const EMAIL_ADDRESS_IS_INCORRECT_VALIDATOR = 'Adres e-mail jest niepoprawny';
const MIN_LENGTH_TEXT = 'Minimalna długość to 2 znaki';

const configuration = {
  email: string()
    .required(EMAIL_ADDRESS_IS_REQUIRED_VALIDATOR)
    .email(EMAIL_ADDRESS_IS_INCORRECT_VALIDATOR)
    .min(2, MIN_LENGTH_TEXT),
  imie: string()
    .required(REQUIRED_VALIDATOR_TEXT)
    .min(2, MIN_LENGTH_TEXT),
  nazwisko: string()
    .required(REQUIRED_VALIDATOR_TEXT)
    .min(2, MIN_LENGTH_TEXT),
};

export const FomIoUserSchema = object().shape(configuration);
