import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import { getPoolDescriptions } from '@Model/poolDescriptions/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { resetError, resetLoading, setLoading } from './../actions';
import { POOL_DESCRIPTIONS } from './../constants/constants';

export const setUpStateWhenPoolDescriptionsFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([getPoolDescriptions.request])),
    mergeMap$(() => {
      return [resetError(POOL_DESCRIPTIONS), setLoading(POOL_DESCRIPTIONS)];
    })
  );
};

export const setLoaderWhenPoolDescriptionsFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [getPoolDescriptions.success, getPoolDescriptions.failure],
        action
      )
    ),
    map$(() => resetLoading(POOL_DESCRIPTIONS))
  );
};

export const setErrorOnPoolDescriptionsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getPoolDescriptions.failure)),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
