import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import GenericTable from '@Compo/reusable/GenericTable';
import config from '@Config';
import loadDOMScript from '@Misc/helpers/loadDOMScript';
import priceFormatter from '@Misc/helpers/priceFormatter';

import useStyles from './EventsSeats.styles';
import { IEventsSeatsProps } from './EventsSeats.types';

const SEATS_CONTAINER_ID = 'seats-container';

const EventsSeats = ({
  pricing,
  pools,
  onSeatsDeselected,
  onSeatsSelected,
  setSeatsValid,
}: IEventsSeatsProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Add.components.FormLayout.EventsSeats',
  });

  const classes = useStyles();

  let seatingChart: any;

  const onSelectionInvalid = () => {
    setSeatsValid(false);
  };

  const onSelectionValid = () => {
    setSeatsValid(true);
  };

  const loadChart = () => {
    seatingChart = new window.seatsio.SeatingChart({
      divId: SEATS_CONTAINER_ID,
      event: String(pools?.id),
      language: 'pl',
      maxSelectedObjects: pools?.ticketsNumLimit,
      onObjectDeselected: onSeatsDeselected,
      onObjectSelected: onSeatsSelected,
      onSelectionInvalid,
      onSelectionValid,
      priceFormatter: (price: number) => `${priceFormatter(price)} zł`,
      pricing,
      publicKey: pools?.seatsIoPublicKey,
      selectionValidators: [{ type: 'noOrphanSeats' }],
      unavailableCategories: [
        t('unavailable'),
        t('unavailable').toUpperCase(),
        `${t('unavailable').toLowerCase().charAt(0).toUpperCase()}${t(
          'unavailable'
        ).slice(1)}`,
      ],
    }).render();
  };

  useEffect(() => {
    if (!window.seatsio) {
      loadDOMScript(config.seats.url).then(loadChart);
    } else {
      loadChart();
    }

    return () => {
      seatingChart?.destroy();
    };
  }, [pools?.id]);

  return (
    <div className={classes.container}>
      <GenericTable>
        <div id={SEATS_CONTAINER_ID} />
      </GenericTable>
    </div>
  );
};

export default EventsSeats;
