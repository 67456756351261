import React, { ChangeEvent, useEffect, useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import GenericTable from '@Compo/reusable/GenericTable';
import useStyles from '@Compo/reusable/MetadataModal/components/AttachModal/AttachModal.styles';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import {
  IMetadataDependencies,
  IMetadataTypes,
  MetadataType,
} from '@Services/$metadata-api/types';

import { columns, dialogTitles } from './AttachModal.constants';
import { IAttachModalProps } from './AttachModal.types';

const AttachModal = ({
  selectedType,
  closeModal,
  catchMetadataValues,
  meta,
  dependencies,
  selectedItems,
  setSelected,
  handleActivePage,
  searchText,
  isLoading,
}: IAttachModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reusable.MetadataModal.components.AttachModal',
  });

  const classes = useStyles();
  const [searchParam, setSearchParam] = useState(searchText);
  const [localSelected, setLocalSelected] = useState<IMetadataDependencies>([]);
  const [searchTimeOut, setSearchTimeOut] = useState<NodeJS.Timeout | null>(
    null
  );

  const catchActivePageChange = (e: unknown, activePage: number) => {
    if (selectedType) {
      handleActivePage(selectedType, activePage + 1, searchParam);
    }
  };

  const addAttachmentToFormValues = () => {
    if (selectedType) {
      const selected = localSelected.map((dependency) => ({
        ...dependency,
        ...(selectedType === MetadataType.Pool &&
          'poolDescription' in dependency && {
            poolDescription: {
              ...dependency.poolDescription,
              ...(dependency.poolDescription &&
                'titlePLFormatted' in dependency.poolDescription && {
                  titlePLFormatted: null,
                }),
            },
          }),
      }));

      setSelected(selectedType, selected);
      setLocalSelected([]);
      closeModal();
    }
  };

  const catchHandleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchParam(e.target.value);
    searchFunc(e.target.value);
  };

  const searchFunc = (text: string) => {
    if (meta && selectedType) {
      if (searchTimeOut) {
        clearTimeout(searchTimeOut);
      }
      setSearchTimeOut(
        setTimeout(() => {
          handleActivePage(selectedType, meta.page, text);
        }, 1000)
      );
    }
  };

  const setOwnSelected = (selected: IMetadataDependencies) => {
    setLocalSelected(selected);
  };

  const normalizedDependencies = (type: IMetadataTypes) => {
    if (type === MetadataType.Pool) {
      return dependencies[type].map((value) => ({
        ...value,
        poolDescription: {
          ...value.poolDescription,
          ...(value.poolDescription?.titlePL && {
            titlePLFormatted: (
              <>
                {value.poolDescription?.titlePL}
                {value.poolDescription?.descriptionPL && (
                  <Tooltip
                    title={value.poolDescription?.descriptionPL}
                    placement={'bottom'}
                    arrow={true}
                  >
                    <InfoIcon
                      color={'secondary'}
                      fontSize={'small'}
                      className={classes.tooltipIcon}
                    />
                  </Tooltip>
                )}
              </>
            ),
          }),
        },
        priceFormatted: value.price + ' zł',
      }));
    }

    return dependencies[type];
  };

  useEffect(() => {
    if (selectedType) {
      catchMetadataValues(selectedType);
      setLocalSelected(selectedItems[selectedType]);
    }
  }, [selectedType]);

  useEffect(() => {
    setSearchParam(searchText);
  }, [searchText]);

  if (!selectedType || !meta) {
    return null;
  }

  const { size, page, total } = meta;

  return (
    <Dialog
      open={!!selectedType}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle>{dialogTitles[selectedType]}</DialogTitle>
      <DialogContent>
        <GenericTable>
          <TableActions>
            <TextField
              variant="standard"
              name="search"
              helperText={t('search')}
              value={searchParam}
              onChange={catchHandleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
            />
          </TableActions>

          <TableUi
            rows={normalizedDependencies(selectedType)}
            columns={columns[selectedType]}
            total={total}
            activePage={page - 1}
            rowsPerPage={size}
            handleChangePage={catchActivePageChange}
            setOwnSelected={setOwnSelected}
            selectedItems={localSelected}
            isLoading={isLoading}
          />
        </GenericTable>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={addAttachmentToFormValues}
        >
          {t('add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttachModal;
