import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { USERS } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';
import {
  catchChangeUserStatus,
  catchGetSingleUser,
  handleActivePageUsers,
  handleActiveSizeUsers,
  handleActiveUser,
  handleSearchUsers,
  mountedUsers,
} from '@Model/users/actions';
import {
  getMetaUsers,
  getSearchTextUsers,
  getUsers,
} from '@Model/users/selectors';

import { prepareInitialValues } from './components/UsersModal/UsersModal.helpers';
import UsersTable from './UsersTable.component';
import {
  IUsersTableFromDispatch,
  IUsersTableFromState,
} from './UsersTable.types';

const mapStateToProps = (state: _Store.IState): IUsersTableFromState => ({
  isLoading: getLoading(USERS)(state),
  meta: getMetaUsers(state),
  searchText: getSearchTextUsers(state),
  users: getUsers(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IUsersTableFromDispatch => ({
  addNewUser: () => dispatch(handleActiveUser(prepareInitialValues(null))),
  changeUserStatus: (id, status) =>
    dispatch(catchChangeUserStatus({ id, status })),
  edit: (id) => dispatch(catchGetSingleUser(id)),
  handleActivePage: (page) => dispatch(handleActivePageUsers(page)),
  handleChangeRowsPerPage: (size) => dispatch(handleActiveSizeUsers(size)),
  mounted: () => dispatch(mountedUsers()),
  search: (text) => dispatch(handleSearchUsers(text)),
});

export default connect<
  IUsersTableFromState,
  IUsersTableFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(UsersTable);
