import axios, { CancelTokenSource } from 'axios';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';

import {
  IEventsPartnerResponse,
  IPartnersResponse,
  IPoolsPartnerResponse,
  IRundatesPartnerResponse,
} from './types';

class MetadataApi {
  private static getPartnersUrl(): string {
    return `${config.api.cmsServices}partners`;
  }

  private static getEventsPartnerUrl(): string {
    return `${config.api.cmsServices}events-partner`;
  }

  private static getRundatesPartnerUrl(): string {
    return `${config.api.cmsServices}rundates-partner`;
  }

  private static getPoolsPartnerUrl(): string {
    return `${config.api.cmsServices}pools-partner`;
  }

  private cancelTokenMetadata?: CancelTokenSource;

  public getPartners(
    page: number,
    count: number,
    searchText?: string
  ): Promise<IPartnersResponse> {
    return new Promise<IPartnersResponse>((resolve, reject) => {
      this.cancelTokenMetadata = axios.CancelToken.source();
      axios
        .get(MetadataApi.getPartnersUrl(), {
          cancelToken: this.cancelTokenMetadata.token,
          params: {
            count,
            'filter[all]': searchText,
            page,
          },
        })
        .then(getData)
        .then((response) => {
          resolve({ data: response.data, meta: response.meta });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getEventsPartner(
    page: number,
    count: number,
    searchText?: string,
    partnerId?: string
  ): Promise<IEventsPartnerResponse> {
    return new Promise<IEventsPartnerResponse>((resolve, reject) => {
      this.cancelTokenMetadata = axios.CancelToken.source();
      axios
        .get(MetadataApi.getEventsPartnerUrl(), {
          cancelToken: this.cancelTokenMetadata.token,
          params: {
            count,
            'filter[all]': searchText,
            page,
            'partnerIds[]': partnerId,
          },
        })
        .then(getData)
        .then((response) => {
          resolve({ data: response.data, meta: response.meta });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getRundatesPartner(
    page: number,
    count: number,
    searchText?: string,
    partnerId?: string
  ): Promise<IRundatesPartnerResponse> {
    return new Promise<IRundatesPartnerResponse>((resolve, reject) => {
      this.cancelTokenMetadata = axios.CancelToken.source();
      axios
        .get(MetadataApi.getRundatesPartnerUrl(), {
          cancelToken: this.cancelTokenMetadata.token,
          params: {
            count,
            'filter[all]': searchText || undefined,
            page,
            'partnerIds[]': partnerId,
            withExpired: true,
          },
        })
        .then(getData)
        .then((response) => {
          resolve({ data: response.data, meta: response.meta });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getPoolsPartner(
    page: number,
    count: number,
    searchText?: string,
    partnerId?: string
  ): Promise<IPoolsPartnerResponse> {
    return new Promise<IPoolsPartnerResponse>((resolve, reject) => {
      this.cancelTokenMetadata = axios.CancelToken.source();
      axios
        .get(MetadataApi.getPoolsPartnerUrl(), {
          cancelToken: this.cancelTokenMetadata.token,
          params: {
            count,
            'filter[all]': searchText,
            page,
            'partnerIds[]': partnerId,
          },
        })
        .then(getData)
        .then((response) => {
          resolve({ data: response.data, meta: response.meta });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelMetadata() {
    if (this.cancelTokenMetadata) {
      this.cancelTokenMetadata.cancel();
      this.cancelTokenMetadata = undefined;
    }
  }
}

export default new MetadataApi();
