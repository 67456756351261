import { number, object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane.';
const NOT_NUMBER_VALIDATOR_TEXT = 'Podaj liczbę';

const event = {
  descriptionPL: string().required(REQUIRED_VALIDATOR_TEXT),
  titlePL: string().required(REQUIRED_VALIDATOR_TEXT),
};

const rundate = {
  emissionSize: number().typeError(NOT_NUMBER_VALIDATOR_TEXT).nullable(),
  event: object().shape(event),
  placeId: number().nullable().required(REQUIRED_VALIDATOR_TEXT),
  rundate: string().required(REQUIRED_VALIDATOR_TEXT),
  runtime: string().required(REQUIRED_VALIDATOR_TEXT),
};

export const RundateSchema = object().shape(rundate);
