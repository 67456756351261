import { createSelector } from 'reselect';

import config from '@Config';
import getSelectedConfigurationId from '@Model/happening/selectors/getSelectedConfigurationId';

const getPricesAsObjects = createSelector(
  [getSelectedConfigurationId],
  (selectedConfigurationId) => {
    if (
      config.cms.showTicketsTypes &&
      selectedConfigurationId &&
      selectedConfigurationId.prices &&
      selectedConfigurationId.prices.length
    ) {
      return selectedConfigurationId.prices;
    }
    return [];
  }
);

export default getPricesAsObjects;
