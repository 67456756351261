import React, { FC } from 'react';

import { Text, View } from '@react-pdf/renderer';

import styles from './TableCell.styles';
import { ITableCellProps } from './TableCell.types';

const TableCell: FC<ITableCellProps> = ({ children, isHeader }) => {
  return (
    <View style={styles.cell}>
      <Text style={[styles.text, isHeader ? styles.header : {}]}>
        {children}
      </Text>
    </View>
  );
};

export default TableCell;
