import React, { ChangeEvent, useEffect, useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import DetailsModal from '@Compo/reservations/Calendar/components/DetailsModal';
import ListModal from '@Compo/reservations/Calendar/components/ListModal';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';

import styles from './Tickets.module.scss';
import { ITicketsProps } from './Tickets.types';

const Tickets = ({
  mounted,
  tickets,
  isLoading,
  search,
  searchText,
  selectReservation,
  isReservationListSelected,
  isReservationSelected,
  activePage,
  perPage,
  totalCount,
  handleActivePage,
  isModalLoading,
}: ITicketsProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Tickets',
  });

  useEffect(() => {
    mounted();
  }, []);

  const [searchTimeOut, setSearchTimeOut] = useState<number>(0);
  const [inputText, setInputText] = useState(searchText);

  const showNoResultInfo = !isLoading && tickets.length < 1;

  const searchFunc = (text: string) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        search(text);
      }, 1000) as any
    );
  };

  const catchHandleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.currentTarget.value);
    searchFunc(e.currentTarget.value);
  };

  const catchChangePage = (e: unknown, page: number) => {
    handleActivePage(inputText, page + 1);
  };

  const getReservationId = (str: string) => {
    return Number(str.match(/\d/g)?.join(''));
  };

  const catchRowClick = (id: string) => {
    const foundedTicket = tickets.find((ticket) => ticket.id === Number(id));
    if (foundedTicket) {
      selectReservation(getReservationId(foundedTicket.bookedId));
    }
  };

  const columns = [
    { id: 'id', label: t('id') },
    { id: 'code', label: t('ticketCode') },
    { id: 'quantity', label: t('ticketsAmount') },
    { id: 'verified', label: t('validated') },
    { id: 'email', label: t('receiver') },
    { id: 'firstName', label: t('name') },
    { id: 'lastName', label: t('lastName') },
    { id: 'event', label: t('event') },
    { id: 'saleDate', label: t('dateSale') },
    { id: 'space', label: t('space') },
    { id: 'bookedId', label: t('reservationId') },
    { id: 'startDate', label: t('dateStart') },
  ];

  const getDate = (date: string) => {
    const utcValue = date.split('+')[1];
    if (utcValue === '02:00') {
      return moment(date).add('hours', 2).format('YYYY-MM-DD HH:mm:ss');
    }
    return moment(date).add('hours', 1).format('YYYY-MM-DD HH:mm:ss');
  };

  const normalizedTickets = tickets.map((ticket) => {
    return {
      ...ticket,
      saleDate: getDate(ticket.saleDate),
      verified: ticket.verified ? t('yes') : t('no'),
    };
  });

  return (
    <div className={styles.list}>
      {isLoading && (
        <GoingPaper>
          <div className={styles.loader}>
            <Loader />
          </div>
        </GoingPaper>
      )}
      {!isLoading && (
        <GoingPaper>
          <GenericTable>
            <TableActions>
              <TextField
                variant="standard"
                name="search"
                helperText={t('search')}
                value={inputText}
                onChange={catchHandleSearch}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
              />
            </TableActions>
            <TableUi
              rows={normalizedTickets}
              columns={columns}
              total={totalCount}
              activePage={activePage}
              rowsPerPage={perPage}
              handleChangePage={catchChangePage}
              disableCheckBox={true}
              onRowClick={catchRowClick}
            />
          </GenericTable>
        </GoingPaper>
      )}

      {(isReservationListSelected || isModalLoading) && (
        <ListModal isLoading={isModalLoading} />
      )}

      <DetailsModal isReservationSelected={isReservationSelected} />
    </div>
  );
};

export default Tickets;
