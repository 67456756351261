import React from 'react';

import { Formik } from 'formik';
import FormLayout from '../FormLayout';
import { IDiscountFormProps } from './DiscountForm.types';
import { DiscountSchema } from './DiscountForm.validation';

const DiscountForm = ({ initialValues, save }: IDiscountFormProps) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={DiscountSchema}
      onSubmit={save}
      component={FormLayout}
    />
  );
};

export default DiscountForm;
