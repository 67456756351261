import React, { useState } from 'react';

import getTimeAsString from '@Misc/helpers/getTimeAsString';
import goTrough from '@Misc/helpers/goTrough';
import mapNumberToString from '@Misc/helpers/mapNumberToString';
import Scroller from './../Scroller';
import { IPickerProps } from './Picker';
import styles from './Picker.module.scss';

const Picker = ({
  initialHour,
  initialMinute,
  minutesInterval,
  onTimeChanged,
}: IPickerProps) => {
  const hours = goTrough(24)
    .map((item) => item - 1)
    .map(mapNumberToString);
  const minutes = goTrough(60)
    .map((item) => item - 1)
    .filter((minute) => minute % minutesInterval === 0)
    .map(mapNumberToString);

  const [time, setTime] = useState({
    hour: initialHour,
    minute: initialMinute,
  });

  const handleHourSelection = (hour: string) => {
    const hourAsInt = parseInt(hour, 10);

    setTime({
      ...time,
      hour: hourAsInt,
    });

    onTimeChanged(getTimeAsString(hourAsInt, time.minute));
  };

  const handleMinutesSelection = (minute: string) => {
    const minuteAsInt = parseInt(minute, 10);

    setTime({
      ...time,
      minute: minuteAsInt,
    });

    onTimeChanged(getTimeAsString(time.hour, minuteAsInt));
  };

  return (
    <div className={`${styles.picker} timePicker`}>
      <Scroller values={hours} onSelect={handleHourSelection} header="Godz:" />
      <Scroller
        values={minutes}
        onSelect={handleMinutesSelection}
        header="Min:"
      />
    </div>
  );
};

export default Picker;
