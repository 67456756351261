import { createSelector } from 'reselect';

import _Store from '@Store';

import { IAddPoolDescriptionModalValues } from '@Compo/PoolDescriptions/components/AddPoolDescriptionModal/AddPoolDescriptionModal.types';

import { IPoolDescriptionsReducer } from './../types';
import get from './get';

const getActivePoolDescription = createSelector<
  _Store.IState,
  IPoolDescriptionsReducer,
  IAddPoolDescriptionModalValues | null
>([get], (poolDescription) => poolDescription.activePoolDescription);

export default getActivePoolDescription;
