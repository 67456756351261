import { createSelector } from 'reselect';

import _Store from '@Store';

import { IFilter } from '@Services/$going-rundate-api/types';

import { IRundatesReducer } from '../types';
import getRundates from './getRundates';

const getRundatesFilter = createSelector<
  _Store.IState,
  IRundatesReducer,
  IFilter
>([getRundates], (rundates) => rundates.filter);

export default getRundatesFilter;
