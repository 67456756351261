import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  deleteSingleTicket,
  selectSingleTicket,
} from '@Model/going/rundates/actions';
import { getSingleTicket } from '@Model/going/rundates/selectors';

import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => ({
  singleTicket: getSingleTicket(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormLayoutFromDispatch => ({
  closeModal: () => dispatch(selectSingleTicket(null)),
  deleteTicket: (id) => dispatch(deleteSingleTicket(id)),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
