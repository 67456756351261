import React, { useEffect, useState } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { makeDurationFromTime } from '@Compo/BetterManager/NewDefinedConfigurations/components/TimeRule/Components/FormLayout/FormLayout.helpes';
import MuiDatePicker from '@Compo/reusable/MuiDatePicker';
import checkIsEndAfterMidnight from '@Misc/helpers/checkIsEndAfterMidnight';

import Days from '../../../Days';
import { CapacityField } from './Components/CapacityField';
import { PriceField } from './Components/PriceField';
import { makeRRule } from './FormLayout.helpes';
import { IFormLayoutProps } from './FormLayout.types';

const useStyles = makeStyles((theme) => ({
  content: {
    overflow: 'hidden',
  },
  form: {
    overflowY: 'auto',
  },
  inputTime: {
    '&::-webkit-calendar-picker-indicator': {
      filter:
        'invert(98%) sepia(1%) saturate(656%) hue-rotate(197deg) brightness(122%) contrast(100%)',
    },
  },
  label: {
    marginBottom: theme.spacing(2),
  },
}));

export const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'BetterManager.NewAddingConfiguration.components.ConfigurationModal.Components.FormLayout',
  });

  const {
    handleSubmit,
    values,

    handleActiveConfiguration,
    handleChange,
    setFieldValue,
    handleBlur,

    touched,
    errors,
    submitForm,
  } = props;

  const { fromTime, endTime, duration, cyclic, days, until } = values;

  const rrule = makeRRule(values);

  const [endAfterMidnight, setEndAfterMidnight] = useState(
    checkIsEndAfterMidnight(rrule, duration, null, null)
  );

  const classes = useStyles();

  const catchHandleClose = () => {
    handleActiveConfiguration(null);
  };

  const renderPriceRuleExtraFields = () => {
    if (values && values.ruleType === 'price') {
      return <PriceField {...props} />;
    }
    return null;
  };

  const renderCapacityRuleExtraFields = () => {
    if (values && values.ruleType === 'capacity') {
      return <CapacityField {...props} />;
    }
    return null;
  };

  useEffect(() => {
    setFieldValue(
      'duration',
      makeDurationFromTime(fromTime, endTime, endAfterMidnight)
    );
  }, [endTime, fromTime, endAfterMidnight]);

  useEffect(() => {
    if (!endAfterMidnight && until) {
      setFieldValue('validTo', until);
    }
  }, [endAfterMidnight]);

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <TextField
                variant="standard"
                id="title"
                label={(touched.title && errors.title) || t('rulesName')}
                helperText={t('rulesNameHelper')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
                name="title"
                error={!!(touched.title && errors.title)}
                fullWidth={true}
              />
            </Grid>

            <Grid item={true} xs={12}>
              <FormControl variant="standard" component="fieldset">
                <FormLabel component="legend" className={classes.label}>
                  {t('ruleType')}
                </FormLabel>
                <RadioGroup
                  name="cyclic"
                  value={values.cyclic}
                  onChange={(e) => {
                    setFieldValue(
                      'cyclic',
                      e.currentTarget.value === 'true' ? true : false
                    );
                  }}
                >
                  <FormControlLabel
                    value="true"
                    checked={values.cyclic === true}
                    control={<Radio size="small" color="primary" />}
                    label={t('cyclic')}
                  />
                  <FormControlLabel
                    value="false"
                    checked={values.cyclic === false}
                    control={<Radio size="small" color="primary" />}
                    label={t('oneTime')}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item={true} xs={12}>
              <FormControl
                variant="standard"
                component="fieldset"
                fullWidth={true}
              >
                <FormLabel component="legend" className={classes.label}>
                  {t('ruleHours')}
                </FormLabel>
                <Grid container={true} spacing={4}>
                  <Grid item={true} xs={12} lg={6}>
                    <TextField
                      variant="standard"
                      id="fromTime"
                      label={
                        (touched.fromTime && errors.fromTime) ||
                        (touched.fromTime && errors.duration) ||
                        t('startLabel')
                      }
                      helperText={t('startLabelHelper')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.fromTime}
                      name="fromTime"
                      error={
                        !!(touched.fromTime && errors.fromTime) ||
                        !!(touched.fromTime && errors.duration)
                      }
                      fullWidth={true}
                      type="time"
                      className={classes.inputTime}
                      inputProps={{ className: classes.inputTime }}
                    />
                  </Grid>
                  <Grid item={true} xs={12} lg={6}>
                    <TextField
                      variant="standard"
                      id="endTime"
                      label={
                        (touched.endTime && errors.endTime) ||
                        (touched.endTime && errors.duration) ||
                        t('endLabel')
                      }
                      helperText={t('endLabelHelper')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.endTime}
                      name="endTime"
                      error={
                        !!(touched.endTime && errors.endTime) ||
                        !!(touched.endTime && errors.duration)
                      }
                      fullWidth={true}
                      type="time"
                      inputProps={{ className: classes.inputTime }}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={endAfterMidnight}
                          onClick={() => setEndAfterMidnight(!endAfterMidnight)}
                          color="primary"
                          size="medium"
                        />
                      }
                      label={
                        <Typography variant="body1">
                          {t('endAfterMidnight')}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            {cyclic && (
              <Grid item={true} xs={12}>
                <FormControl
                  variant="standard"
                  component="fieldset"
                  fullWidth={true}
                >
                  <FormLabel component="legend" className={classes.label}>
                    {t('ruleDays')}
                  </FormLabel>
                  <Days name="days" onChange={handleChange} value={days} />
                  <input type="hidden" defaultValue={days} />
                </FormControl>
              </Grid>
            )}

            <Grid item={true} xs={12}>
              <FormControl
                variant="standard"
                component="fieldset"
                fullWidth={true}
              >
                <FormLabel component="legend" className={classes.label}>
                  {cyclic ? t('ruleTime') : t('ruleOneTime')}
                </FormLabel>
                <Grid container={true} spacing={4}>
                  {cyclic ? (
                    <>
                      <Grid item={true} xs={12} lg={6}>
                        <MuiDatePicker
                          name="validFrom"
                          disableToolbar={true}
                          fullWidth={true}
                          variant="standard"
                          format="DD.MM.yyyy"
                          label={
                            (touched.validFrom && errors.validFrom) ||
                            t('startDate')
                          }
                          helperText={t('startDateHelper')}
                          value={values.validFrom}
                          onChange={(date) => setFieldValue('validFrom', date)}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item={true} xs={12} lg={6}>
                        <MuiDatePicker
                          name="validTo"
                          disableToolbar={true}
                          fullWidth={true}
                          variant="standard"
                          format="DD.MM.yyyy"
                          label={
                            (touched.validTo && errors.validTo) || t('endDate')
                          }
                          helperText={t('endDateHelper')}
                          value={values.validTo}
                          onChange={(date) => setFieldValue('validTo', date)}
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item={true} xs={12}>
                      <MuiDatePicker
                        name="until"
                        disableToolbar={true}
                        fullWidth={true}
                        variant="standard"
                        format="DD.MM.yyyy"
                        label={
                          (touched.until && errors.until) ||
                          t('ruleOneTimeInput')
                        }
                        helperText={t('ruleOneTimeInputHelper')}
                        value={values.until}
                        onChange={(date) => {
                          setFieldValue('until', date);
                          setFieldValue('validTo', date);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </FormControl>
            </Grid>
            {renderPriceRuleExtraFields()}

            {renderCapacityRuleExtraFields()}
          </Grid>
        </DialogContent>
      </form>

      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={catchHandleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('saveRule')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
