import {
  getReservations,
  resetSelectionList,
} from '@Model/reservations/actions';
import { getType } from 'typesafe-actions';
import { IAction, IReservationsDetailsListReducer } from './../types';

const initialState: IReservationsDetailsListReducer = null;

const detailsListReducer = (
  state: IReservationsDetailsListReducer = initialState,
  action: IAction
): IReservationsDetailsListReducer => {
  switch (action.type) {
    case getType(getReservations.success):
      return action.payload;
    case getType(resetSelectionList):
      return initialState;
    default:
      return state;
  }
};

export default detailsListReducer;
