import { connect } from 'react-redux';

import _Store from '@Store';

import { get } from '@Model/authorization/selectors';
import { getHappenings } from '@Model/happenings/selectors';
import { getApartments } from '@Model/smartporter/selectors';
import { allPermissions } from '@Model/state/constants';

import FormLayout from './FormLayout.component';
import { IFormLayoutFromState } from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    apartments: getApartments(state),
    happenings: getHappenings(state),
    permissionSpaceView: permissions.includes(
      allPermissions.access_spaces_read
    ),
    permissionSpaceWrite: permissions.includes(
      allPermissions.access_spaces_write
    ),
  };
};

export default connect<IFormLayoutFromState, {}, {}, _Store.IState>(
  mapStateToProps
)(FormLayout);
