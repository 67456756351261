import _Store from '@Store';
import { createSelector } from 'reselect';
import { IHappeningsReducer } from '../types';
import get from './get';

const getHappeningPartnerId = createSelector<
  _Store.IState,
  IHappeningsReducer,
  string | undefined
>([get], (happening) => happening.partnerId);

export default getHappeningPartnerId;
