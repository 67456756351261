import React, { useContext, useEffect, useState } from 'react';

import MuGrid from '@mui/material/Grid';
import { Card } from 'tabler-react';

import { clearContext } from '@Compo/Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';

import Basket from './Basket/Basket.container';
import { IFormLayoutProps } from './FormLayout';
import styles from './FormLayout.module.scss';
import ReservationStepper from './ReservationStepper';
import TypeStepper from './TypeStepper';

const FormLayout = (props: IFormLayoutProps) => {
  const [withReservation, setWithReservation] = useState(false);
  const [showBasket, setShowBasket] = useState(true);
  const [transactionType, setTransactionType] = useState('');
  const [discountCode, setDiscountCode] = useState('');
  const [prepaidCard, setPrepaidCard] = useState('');
  const [showMonit, setShowMonit] = useState(false);
  const [delayedTransaction, setDelayedTransaction] = useState(false);
  const [mixPayment, setMixPayment] = useState(false);

  const BagState = useContext(clearContext);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    mounted,
    setFieldValue,
    values,
    setValues,
    isPostReservationLoading,
    touched,
    errors,
    setFieldTouched,
  } = props;

  useEffect(() => {
    mounted();

    if (BagState) {
      BagState.readLocalStorage();

      if (BagState.userData) {
        setValues(BagState.userData);

        restFormValues();
      }
    }
  }, []);

  const restFormValues = () => {
    setFieldValue('happeningId', -1);
    setFieldValue('slot', null);
    setFieldValue('upsell', false);
    setFieldValue('reservationCheckbox', false);
    setFieldValue('numberOfPeople', 1);
    setFieldValue('date', new Date());
  };

  const goToReservationSteps = () => {
    setWithReservation(true);
  };

  const goToFirstStepAndDisplayMessage = (message: string) => {
    setWithReservation(false);
  };

  if (isPostReservationLoading) {
    return <Card body={<Loader />} />;
  }

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className={styles.wrapper}
        autoComplete="nope"
      >
        <>
          <MuGrid container={true} spacing={2}>
            <MuGrid item={true} xs={12} sm={showBasket ? 8 : 12}>
              <GoingPaper>
                {!withReservation && (
                  <TypeStepper
                    {...props}
                    goToReservationSteps={goToReservationSteps}
                    setShowBasket={setShowBasket}
                    transactionType={transactionType}
                    discount={discountCode}
                    setTransactionType={(key: string) =>
                      setTransactionType(key)
                    }
                    showMonit={showMonit}
                    setShowMonit={setShowMonit}
                    delayed={delayedTransaction}
                    mixPayment={mixPayment}
                    setMixPayment={setMixPayment}
                  />
                )}
                {withReservation && (
                  <ReservationStepper
                    {...props}
                    onDone={goToFirstStepAndDisplayMessage}
                    setShowBasket={setShowBasket}
                    transactionType={transactionType}
                    discount={discountCode}
                    setTransactionType={(key: string) =>
                      setTransactionType(key)
                    }
                    showMonit={showMonit}
                    setShowMonit={setShowMonit}
                    delayed={delayedTransaction}
                    mixPayment={mixPayment}
                    setMixPayment={setMixPayment}
                  />
                )}
              </GoingPaper>
            </MuGrid>
            <MuGrid item={true} xs={12} sm={4}>
              {showBasket && (
                <GoingPaper className={styles.sticky}>
                  <Basket
                    setTransactionType={(key: string) =>
                      setTransactionType(key)
                    }
                    setDiscountCode={(discount: string) =>
                      setDiscountCode(discount)
                    }
                    setDelayedTransaction={() => setDelayedTransaction(true)}
                    discount={discountCode}
                    prepaidCard={prepaidCard}
                    setPrepaidCard={setPrepaidCard}
                    setMixPayment={() => setMixPayment(true)}
                    handleChange={handleChange}
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleBlur={handleBlur}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                  />
                </GoingPaper>
              )}
            </MuGrid>
          </MuGrid>
        </>
      </form>
    </>
  );
};

export default FormLayout;
