import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import {
  IChangeRefundsStatus,
  IRefundsRegistration,
  IRefundsRegistrationFilters,
  IRefundsRegistrationMetadataResponse,
  IRefundsRegistrationResponse,
} from '@Services/$refunds-registration-api/types';

import {
  _GET_REFUNDS_REGISTRATION_FAILURE,
  _GET_REFUNDS_REGISTRATION_METADATA_FAILURE,
  _GET_REFUNDS_REGISTRATION_METADATA_REQUEST,
  _GET_REFUNDS_REGISTRATION_METADATA_SUCCESS,
  _GET_REFUNDS_REGISTRATION_REQUEST,
  _GET_REFUNDS_REGISTRATION_SUCCESS,
  _GET_SINGLE_REFUNDS_REGISTRATION_FAILURE,
  _GET_SINGLE_REFUNDS_REGISTRATION_REQUEST,
  _GET_SINGLE_REFUNDS_REGISTRATION_SUCCESS,
  _PUT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES_FAILURE,
  _PUT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES_REQUEST,
  _PUT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES_SUCCESS,
  _PUT_REFUNDS_REGISTRATION_FAILURE,
  _PUT_REFUNDS_REGISTRATION_REQUEST,
  _PUT_REFUNDS_REGISTRATION_SUCCESS,
  CATCH_CHANGE_REFUNDS_REGISTRATION_PAGE,
  CATCH_EDIT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES,
  CATCH_EDIT_REFUNDS_REGISTRATION,
  CATCH_GET_SINGLE_REFUNDS_REGISTRATION,
  CHANGE_CHANGE_REFUNDS_REGISTRATION_FILTER,
  CHANGE_CHANGE_REFUNDS_REGISTRATION_SEARCH,
  CLEAR_REFUNDS_REGISTRATION_FILTER,
  HANDLE_ACTIVE_REFUNDS_REGISTRATION,
  MOUNTED,
} from './../constants/actions';

export const mounted = createStandardAction(MOUNTED)();

export const changeRefundsRegistrationsPage = createStandardAction(
  CATCH_CHANGE_REFUNDS_REGISTRATION_PAGE
)<number>();

export const changeRefundsRegistrationsSearch = createStandardAction(
  CHANGE_CHANGE_REFUNDS_REGISTRATION_SEARCH
)<IRefundsRegistrationFilters>();

export const changeRefundsRegistrationsFilter = createStandardAction(
  CHANGE_CHANGE_REFUNDS_REGISTRATION_FILTER
)<IRefundsRegistrationFilters>();

export const clearRefundsRegistrationsFilter = createStandardAction(
  CLEAR_REFUNDS_REGISTRATION_FILTER
)();

export const catchGetSingleRefundRegistration = createStandardAction(
  CATCH_GET_SINGLE_REFUNDS_REGISTRATION
)<number>();

export const handleActiveRefundRegistration = createStandardAction(
  HANDLE_ACTIVE_REFUNDS_REGISTRATION
)<IRefundsRegistration | null>();

export const catchEditRefundRegistration = createStandardAction(
  CATCH_EDIT_REFUNDS_REGISTRATION
)<IRefundsRegistration>();

export const catchEditMultipleRefundRegistrationStatuses = createStandardAction(
  CATCH_EDIT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES
)<IChangeRefundsStatus>();

export const getRefundsRegistrations = createAsyncAction(
  _GET_REFUNDS_REGISTRATION_REQUEST,
  _GET_REFUNDS_REGISTRATION_SUCCESS,
  _GET_REFUNDS_REGISTRATION_FAILURE
)<number, IRefundsRegistrationResponse, Error>();

export const getRefundsRegistrationMetadata = createAsyncAction(
  _GET_REFUNDS_REGISTRATION_METADATA_REQUEST,
  _GET_REFUNDS_REGISTRATION_METADATA_SUCCESS,
  _GET_REFUNDS_REGISTRATION_METADATA_FAILURE
)<undefined, IRefundsRegistrationMetadataResponse, Error>();

export const getSingleRefundsRegistration = createAsyncAction(
  _GET_SINGLE_REFUNDS_REGISTRATION_REQUEST,
  _GET_SINGLE_REFUNDS_REGISTRATION_SUCCESS,
  _GET_SINGLE_REFUNDS_REGISTRATION_FAILURE
)<number, IRefundsRegistration, Error>();

export const editRefundRegistration = createAsyncAction(
  _PUT_REFUNDS_REGISTRATION_REQUEST,
  _PUT_REFUNDS_REGISTRATION_SUCCESS,
  _PUT_REFUNDS_REGISTRATION_FAILURE
)<IRefundsRegistration, [], Error>();

export const editMultipleRefundRegistrationStatuses = createAsyncAction(
  _PUT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES_REQUEST,
  _PUT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES_SUCCESS,
  _PUT_MULTIPLE_REFUNDS_REGISTRATION_STATUSES_FAILURE
)<IChangeRefundsStatus, [], Error>();
