import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { PERMISSIONS } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';
import {
  handleActiveFilter,
  handleActiveGroup,
  mountedPermissions,
} from '@Model/users/actions';
import { getActiveFilter, getPermissions } from '@Model/users/selectors';

import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => ({
  activeFilter: getActiveFilter(state),
  isLoading: getLoading(PERMISSIONS)(state),
  permissions: getPermissions(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormLayoutFromDispatch => ({
  handleActiveFilter: (filter) => dispatch(handleActiveFilter(filter)),
  handleClose: () => dispatch(handleActiveGroup(null)),
  mounted: () => dispatch(mountedPermissions()),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
