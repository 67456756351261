import routes from '@/routes/routes';
import { getLocation, LOCATION_CHANGE, push } from 'connected-react-router';
import moment from 'moment';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getInitialValues } from '@Compo/Mail/components/MailForm/MailForm.helpers';
import { getHappeningPartnerId } from '@Model/happenings/selectors';
import { showSuccessModal } from '@Model/modal/actions';
import i18next from '@Translations/i18n';

import {
  deleteEventMailing,
  deleteMail,
  edit,
  getEventMailing,
  getSingleEventMailing,
  handleActivePage,
  mounted,
  save,
  saveMail,
  selectEventMail,
  send,
  sendEventMailing,
} from './../actions';
import { sendEventTestMailing, sendTest } from './../actions/index';
import { getMeta, getSearchText } from './../selectors';

const SAVE_EMAIL_SUCCESS_TITLE_TEXT = 'Zapiszano szkic';
const SAVE_EMAIL_SUCCESS_DESCRIPTION_TEXT =
  'Szkic został zapisany w Skrzynce nadawczej.';
const SEND_EMAIL_SUCCESS_TITLE_TEXT = 'Wysłano!';
const SEND_EMAIL_SUCCESS_DESCRIPTION_TEXT = 'Wiadomość została wysłana.';

export const getEventMailingWhenMounted: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    mergeMap$(() => {
      return of$(handleActivePage(1));
    })
  );
};

export const getEventMailingWhenPageChange: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(handleActivePage)),
    mergeMap$(() => {
      return of$(getEventMailing.request());
    })
  );
};

export const fetchEventMailingWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { emailApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getEventMailing.request)),
    withLatestFrom$(state$),
    mergeMap$(([, state]) => {
      const meta = getMeta(state);
      const text = getSearchText(state);

      return from$(emailApi.getEventMailing(meta.page, meta.size, text)).pipe(
        mergeMap$((data) => {
          return of$(getEventMailing.success(data));
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => emailApi.cancelEmail())
          )
        ),
        catchError$((error) => {
          return of$(getEventMailing.failure(error));
        })
      );
    })
  );
};

export const saveMailingWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { emailApi }
) => {
  return action$.pipe(
    filter$(isActionOf([saveMail.request, save])),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const partnerId = getHappeningPartnerId(state);
      if (partnerId) {
        let toSendAt = null;
        if (action.payload.mailShipment) {
          const startDate = moment(action.payload.startDate).format(
            'YYYY-MM-DD'
          );
          const startTime = moment(action.payload.startTime, 'HH:mm').format(
            'HH:mm:ss'
          );
          toSendAt = `${startDate} ${startTime}`;
        }

        delete action.payload.startTime;
        delete action.payload.startDate;

        return from$(
          emailApi.saveMailing({
            ...action.payload,
            buttonLink: action.payload.buttonLink || null,
            buttonText: action.payload.buttonText || null,
            dependencies: action.payload.dependencies.map((dependency) => ({
              id: dependency.id,
              type: dependency.type,
            })),
            partnerId: Number(partnerId),
            picture: action.payload.picture || null,
            toSendAt,
          })
        ).pipe(
          mergeMap$((data) => {
            if (getLocation(state).pathname === routes.mail) {
              if (action.payload.saveAndSend) {
                return of$(
                  saveMail.success(data),
                  selectEventMail(null),
                  getEventMailing.request(),
                  showSuccessModal({
                    message: SEND_EMAIL_SUCCESS_DESCRIPTION_TEXT,
                    title: SEND_EMAIL_SUCCESS_TITLE_TEXT,
                  }),
                  sendEventMailing.request(data.id)
                );
              }
              return of$(
                saveMail.success(data),
                selectEventMail(null),
                getEventMailing.request(),
                showSuccessModal({
                  message: SAVE_EMAIL_SUCCESS_DESCRIPTION_TEXT,
                  title: SAVE_EMAIL_SUCCESS_TITLE_TEXT,
                })
              );
            }

            if (action.payload.saveAndSend) {
              return of$(
                showSuccessModal({
                  message: SEND_EMAIL_SUCCESS_DESCRIPTION_TEXT,
                  title: SEND_EMAIL_SUCCESS_TITLE_TEXT,
                }),
                saveMail.success(data),
                selectEventMail(null),
                sendEventMailing.request(data.id)
              );
            }
            return of$(
              showSuccessModal({
                message: SAVE_EMAIL_SUCCESS_DESCRIPTION_TEXT,
                title: SAVE_EMAIL_SUCCESS_TITLE_TEXT,
              }),
              saveMail.success(data),
              selectEventMail(null),
              push(routes.mail)
            );
          }),
          takeUntil$(
            action$.pipe(
              filter$(isOfType(LOCATION_CHANGE)),
              tap$(() => emailApi.cancelEmail())
            )
          ),
          catchError$((error) => {
            return of$(saveMail.failure(error));
          })
        );
      }

      return EMPTY$;
    })
  );
};

export const fetchSingleMailingWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { emailApi }
) => {
  return action$.pipe(
    filter$(isActionOf([getSingleEventMailing.request, edit])),
    mergeMap$((action) => {
      return from$(emailApi.getSingleEventMailing(action.payload)).pipe(
        mergeMap$((data) => {
          return of$(
            getSingleEventMailing.success(data),
            selectEventMail(getInitialValues(data))
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => emailApi.cancelEmail())
          )
        ),
        catchError$((error) => {
          return of$(getSingleEventMailing.failure(error));
        })
      );
    })
  );
};

export const deleteMailingWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { emailApi }
) => {
  return action$.pipe(
    filter$(isActionOf([deleteEventMailing.request, deleteMail])),
    mergeMap$((action) => {
      return from$(emailApi.deleteEventMail(action.payload)).pipe(
        mergeMap$((data) => {
          return of$(
            deleteEventMailing.success(data),
            getEventMailing.request(),
            selectEventMail(null),
            showSuccessModal()
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => emailApi.cancelEmail())
          )
        ),
        catchError$((error) => {
          return of$(deleteEventMailing.failure(error));
        })
      );
    })
  );
};

export const sendMailingWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { emailApi }
) => {
  return action$.pipe(
    filter$(isActionOf([sendEventMailing.request, send])),
    mergeMap$((action) => {
      return from$(emailApi.sendEventMailing(action.payload)).pipe(
        mergeMap$((data) => {
          return of$(
            sendEventMailing.success(data),
            getEventMailing.request(),
            selectEventMail(null),
            showSuccessModal({
              message: SEND_EMAIL_SUCCESS_DESCRIPTION_TEXT,
              title: SEND_EMAIL_SUCCESS_TITLE_TEXT,
            })
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => emailApi.cancelEmail())
          )
        ),
        catchError$((error) => {
          return of$(sendEventMailing.failure(error));
        })
      );
    })
  );
};

export const sendTestMailingWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { emailApi }
) => {
  return action$.pipe(
    filter$(isActionOf([sendEventTestMailing.request, sendTest])),
    mergeMap$((action) => {
      return from$(
        emailApi.sendEventTestMailing(action.payload.id, action.payload.email)
      ).pipe(
        mergeMap$((data) => {
          return of$(
            sendEventTestMailing.success(data),
            showSuccessModal({
              message: i18next.t(
                'components:models.mailing.testMail.description'
              ),
              title: i18next.t('components:models.mailing.testMail.title'),
            })
          );
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => emailApi.cancelEmail())
          )
        ),
        catchError$((error) => {
          return of$(sendEventTestMailing.failure(error));
        })
      );
    })
  );
};

export const changeRouteWhenSend: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(sendEventMailing.success)),
    withLatestFrom$(state$),
    filter$(([_, state]) => getLocation(state).pathname !== routes.mail),
    mergeMap$(() => {
      return of$(push(routes.mail));
    })
  );
};
