import { IDiscount } from '@Model/discount/types';
import getDiscounts from '@Model/discounts/selectors/getDiscounts';
import _Store from '@Store';
import { createSelector } from 'reselect';
import { IReservationAddReducer } from '../types';
import getAdd from './getAdd';

const getSelectedDiscount = createSelector<
  _Store.IState,
  IReservationAddReducer,
  IDiscount[],
  IDiscount | null
>(
  getAdd,
  getDiscounts,
  (add, discounts) => {
    const discount = discounts.find((item) => item.id === add.selectedDiscount);
    if (discount) {
      return discount;
    }

    return null;
  },
);

export default getSelectedDiscount;
