import axios, { CancelTokenSource } from 'axios';
import moment from 'moment';

import config from '@Config';
import catchHttpError, {
  catchNewErrorMessage,
} from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IConfiguration, TRuleType } from '@Model/configurations/types';

import { IConfigurationsResponse } from './types';

class ConfigurationsApi {
  private static getConfigurationsUrl(
    page: number,
    count: number,
    searchParam: string,
    selectedRuleType: TRuleType
  ): string {
    if (selectedRuleType && config.cms.showNewRules) {
      if (selectedRuleType === 'close') {
        return `${config.api.baseUrlV2}admin/rules/time?page=${page}&count=${count}&q=${searchParam}&closed=true`;
      }
      return `${config.api.baseUrlV2}admin/rules/${selectedRuleType}?page=${page}&count=${count}&q=${searchParam}&closed=false`;
    }
    return `${config.api.baseUrl}admin/configurations?page=${page}&count=${count}&q=${searchParam}`;
  }

  private static deleteConfigurationsUrl(
    type: string,
    configurationId: string
  ): string {
    return `${config.api.baseUrlV2}admin/rules/${type}/${configurationId}/delete`;
  }

  private static saveConfigurationsUrl(v2?: string): string {
    if (v2) {
      return `${config.api.baseUrlV2}admin/rules/${v2}`;
    }
    return `${config.api.baseUrl}admin/configurations`;
  }

  private cancelTokenConfigurations?: CancelTokenSource;

  public getConfigurations<T extends IConfigurationsResponse>(
    page: number,
    count: number = 20,
    searchParam: string = '',
    selectedRuleType: TRuleType,
    partnerId?: string
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenConfigurations = axios.CancelToken.source();

      axios
        .get(
          ConfigurationsApi.getConfigurationsUrl(
            page,
            count,
            searchParam,
            selectedRuleType
          ),
          {
            cancelToken: this.cancelTokenConfigurations.token,
            params: { 'partnerIds[]': partnerId },
          }
        )
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public deleteConfiguration<T extends IConfigurationsResponse>(
    type: string,
    configurationId: string
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.cancelTokenConfigurations = axios.CancelToken.source();

      axios
        .post(
          ConfigurationsApi.deleteConfigurationsUrl(type, configurationId),
          {
            cancelToken: this.cancelTokenConfigurations.token,
          }
        )
        .then(getData)
        .then((response: T) => {
          resolve(response);
        })
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public saveConfiguration(configuration: IConfiguration, v2?: string) {
    return new Promise<void>((resolve, reject) => {
      this.cancelTokenConfigurations = axios.CancelToken.source();

      const method = configuration.id ? 'put' : 'post';

      axios[method](ConfigurationsApi.saveConfigurationsUrl(v2), {
        ...configuration,
        validFrom: configuration.validFrom
          ? moment(configuration.validFrom).format('YYYY-MM-DDT00:00:00[.000Z]')
          : null,
        validTo: configuration.validTo
          ? moment(configuration.validTo).format('YYYY-MM-DDT00:00:00[.000Z]')
          : null,
      })
        .then(getData)
        .then(() => {
          resolve();
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelConfigurations() {
    if (this.cancelTokenConfigurations) {
      this.cancelTokenConfigurations.cancel();
      this.cancelTokenConfigurations = undefined;
    }
  }
}

export default new ConfigurationsApi();
