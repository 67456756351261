// Public
export const ADD = 'Zagrywki/happening/ADD';
export const EDIT = 'Zagrywki/happening/EDIT';
export const MOUNTED = 'Zagrywki/happening/MOUNTED';
export const UNMOUNT = 'Zagrywki/happening/UNMOUNT';

// Private
export const _GET_FAILURE = 'Zagrywki/happening/_GET_FAILURE';
export const _GET_REQUEST = 'Zagrywki/happening/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/happening/_GET_SUCCESS';

export const _RESET_STATE = 'Zagrywki/happening/_RESET_STATE';

export const _SAVE_FAILURE = 'Zagrywki/happening/_SAVE_FAILURE';
export const _SAVE_REQUEST = 'Zagrywki/happening/_SAVE_REQUEST';
export const _SAVE_SUCCESS = 'Zagrywki/happening/_SAVE_SUCCESS';

export const _GET_AVAILABILITIES_REQUEST =
  'Zagrywki/reservation/_GET_AVAILABILITIES_REQUEST';
export const _GET_AVAILABILITIES_SUCCESS =
  'Zagrywki/reservation/_GET_AVAILABILITIES_SUCCESS';
export const _GET_AVAILABILITIES_FAILURE =
  'Zagrywki/reservation/_GET_AVAILABILITIES_FAILURE';
