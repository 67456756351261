import { getType } from 'typesafe-actions';

import {
  getPoolDescriptions,
  handleActivePage,
  handleChangeRowsPerPage,
  handleSearch,
  setPoolDescription,
} from '../actions';
import { IAction, IPoolDescriptionsReducer } from '../types';

const initialState: IPoolDescriptionsReducer = {
  activePoolDescription: null,
  meta: {
    page: 1,
    size: 20,
    total: 0,
  },
  poolDescriptions: [],
  searchText: '',
};

const poolDescriptionsReducer = (
  state: IPoolDescriptionsReducer = initialState,
  action: IAction
): IPoolDescriptionsReducer => {
  switch (action.type) {
    case getType(getPoolDescriptions.success):
      return {
        ...state,
        meta: action.payload.meta,
        poolDescriptions: action.payload.data,
      };
    case getType(handleActivePage):
      return {
        ...state,
        meta: {
          ...state.meta,
          page: action.payload,
        },
      };
    case getType(handleChangeRowsPerPage):
      return {
        ...state,
        meta: {
          ...state.meta,
          size: action.payload,
        },
      };
    case getType(handleSearch):
      return {
        ...state,
        searchText: action.payload,
      };
    case getType(setPoolDescription):
      return {
        ...state,
        activePoolDescription: action.payload,
      };
    default:
      return state;
  }
};

export default poolDescriptionsReducer;
