import { createSelector } from 'reselect';

import { IRundate } from '@Services/$going-rundate-api/types';
import _Store from '@Store';

import { IRundatesReducer } from '../types';

import getRundates from './getRundates';

const getRundatesData = createSelector<
  _Store.IState,
  IRundatesReducer,
  IRundate[]
>([getRundates], (rundates) =>
  rundates && rundates.data ? rundates.data : []
);

export default getRundatesData;
