import React, { useState } from 'react';

import InfoIcon from '@mui/icons-material/InfoOutlined';
import MapPinIcon from '@mui/icons-material/PinDrop';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import { ColorPicker } from 'material-ui-color';
import { useTranslation } from 'react-i18next';
import uuid from 'uuid';

import ImagesLibrary from '@Compo/BetterManager/ImagesLibrary';
import Space from '@Compo/BetterManager/Space';
import MuiDatePicker from '@Compo/reusable/MuiDatePicker';
import goTrough from '@Misc/helpers/goTrough';

import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const DEFAULT_COLOR = '#000000';

const FormLayout = (props: IFormLayoutProps) => {
  const {
    apartments,
    handleBlur,
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
    permissionSpaceView,
    permissionSpaceWrite,
    happenings,
    spaces,
    setSpaces,
  } = props;

  const { t } = useTranslation('components', {
    keyPrefix: 'BetterManager.Manager.components.FormLayout',
  });

  const classes = useStyles();
  const [shouldShowLibrary, showLibrary] = useState(false);
  const [open, setOpen] = useState(false);

  const addSpace = () => {
    setSpaces([
      ...spaces,
      {
        apartmentId: 0,
        configurations: [],
        description: '',
        hash: uuid(),
        id: undefined,
        isScopeView: false,
        maxCapacityLive: undefined,
        name: `Miejsce bez nazwy`,
        numberOfPeople: 10,
        products: [],
        slotTime: '15m',
        spaceLinked: [],
        timeBreak: null,
        url: null,
      },
    ]);
  };

  const modifySpace = (hash: string, key: string, value: any) => {
    setSpaces(() => {
      const newState = spaces.map((space) => {
        if (space.hash === hash) {
          return { ...space, [key]: value };
        } else {
          return space;
        }
      });

      return newState;
    });
  };

  const remove = (hash: string) => {
    setSpaces(() => spaces.filter((space) => space.hash !== hash));
  };

  return (
    <Grid container={true} direction="column">
      <Grid item={true} className={classes.spacing5}>
        <ImagesLibrary
          key={new Date().toISOString()}
          url={values.imageUrl}
          setUrl={(_url) => {
            setFieldValue('imageUrl', _url);
            showLibrary(false);
          }}
          close={() => {
            showLibrary(false);
          }}
          open={shouldShowLibrary}
        />
        <TextField
          variant="standard"
          id="name"
          label={(touched.name && errors.name) || t('happeningName')}
          helperText={t('happeningNameHelper')}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          name="name"
          error={!!(touched.name && errors.name)}
          fullWidth={true}
        />
      </Grid>
      <Grid
        sm={12}
        md={6}
        item={true}
        className={cn(classes.spacing8, classes.basisFix)}
      >
        <Typography variant="body1" className={classes.spacingBottom2}>
          {t('image')}
        </Typography>
        <Card>
          {values.imageUrl && (
            <CardMedia image={values.imageUrl} className={classes.media} />
          )}
          {!values.imageUrl && (
            <CardContent className={classes.content}>
              <Typography variant="body1">{t('imagePlaceHolder')}</Typography>
            </CardContent>
          )}
          <CardActions className={classes.buttonsContainer}>
            <Button
              onClick={() => setFieldValue('imageUrl', '')}
              variant="text"
              size="small"
              color="primary"
            >
              {t('remove')}
            </Button>
            <Button
              onClick={() => {
                showLibrary(true);
              }}
              variant="text"
              size="small"
              color="primary"
            >
              {t('add')}
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid
        item={true}
        className={cn(classes.spacing8, classes.colorContainer)}
      >
        <ColorPicker
          hideTextfield={true}
          onChange={(color) => setFieldValue('color', `#${color.hex}`)}
          value={values.color || DEFAULT_COLOR}
        />
        <TextField
          variant="standard"
          id="color"
          label={(touched.color && errors.color) || t('color')}
          helperText={t('colorHelper')}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.color || DEFAULT_COLOR}
          name="color"
          error={!!(touched.color && errors.color)}
          fullWidth={true}
        />
      </Grid>
      <Grid item={true} className={classes.spacing8}>
        <TextField
          variant="standard"
          id="description"
          label={
            (touched.description && errors.description) || t('description')
          }
          helperText={t('descriptionHelper')}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.description}
          name="description"
          error={!!(touched.description && errors.description)}
          fullWidth={true}
        />
      </Grid>
      <Grid container={true} className={classes.spacing8} spacing={4}>
        <Grid item={true} sm={12} md={6}>
          <MuiDatePicker
            name="startDate"
            disableToolbar={true}
            fullWidth={true}
            variant="standard"
            format="DD.MM.yyyy"
            error={!!(touched.startDate && errors.startDate)}
            label={(touched.startDate && errors.startDate) || t('dateStart')}
            helperText={t('dateStartHelper')}
            value={values.startDate}
            onChange={(date) => setFieldValue('startDate', date)}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item={true} sm={12} md={6}>
          <MuiDatePicker
            name="endDate"
            disableToolbar={true}
            fullWidth={true}
            variant="standard"
            format="DD.MM.yyyy"
            error={!!(touched.endDate && errors.endDate)}
            label={(touched.endDate && errors.endDate) || t('dateEnd')}
            helperText={t('dateEndHelper')}
            value={values.endDate}
            onChange={(date) => setFieldValue('endDate', date)}
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
      <Grid container={true} className={classes.spacing8} spacing={4}>
        <Grid item={true} sm={12} md={6}>
          <TextField
            variant="standard"
            id="canBeBookedMin"
            label={
              (touched.canBeBookedMin && errors.canBeBookedMin) || t('min')
            }
            helperText={t('minHelper')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.canBeBookedMin}
            name="canBeBookedMin"
            error={!!(touched.canBeBookedMin && errors.canBeBookedMin)}
            fullWidth={true}
          />
        </Grid>
        <Grid item={true} sm={12} md={6}>
          <TextField
            variant="standard"
            id="canBeBookedMax"
            label={
              (touched.canBeBookedMax && errors.canBeBookedMax) || t('max')
            }
            helperText={t('maxHelper')}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.canBeBookedMax}
            name="canBeBookedMax"
            error={!!(touched.canBeBookedMax && errors.canBeBookedMax)}
            fullWidth={true}
          />
        </Grid>
      </Grid>

      <Grid item={true} className={classes.spacing8}>
        <TextField
          variant="standard"
          id="canBeBookedMinutesMin"
          label={
            (touched.canBeBookedMinutesMin && errors.canBeBookedMinutesMin) ||
            t('hoursLimit')
          }
          helperText={t('hoursLimitHelper')}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.canBeBookedMinutesMin}
          name="canBeBookedMinutesMin"
          error={
            !!(touched.canBeBookedMinutesMin && errors.canBeBookedMinutesMin)
          }
          fullWidth={true}
        />
      </Grid>

      <Grid item={true} className={classes.spacing8}>
        <TextField
          variant="standard"
          type="time"
          id="startShowingSlotsAt"
          label={
            (touched.startShowingSlotsAt && errors.startShowingSlotsAt) ||
            t('moveHours')
          }
          helperText={t('moveHoursHelper')}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.startShowingSlotsAt}
          name="startShowingSlotsAt"
          error={!!(touched.startShowingSlotsAt && errors.startShowingSlotsAt)}
          fullWidth={true}
          InputProps={{
            className: cn(classes.defaultTimeInput, classes.time),
          }}
        />
        <FormControlLabel
          className={classes.spacing2}
          control={
            <Checkbox
              checked={values.startShowingSlotsAtActive}
              onChange={handleChange}
              name="startShowingSlotsAtActive"
              color="primary"
              size="small"
            />
          }
          label={
            <Typography variant="caption" color="textPrimary">
              {t('moveHoursActive')}
            </Typography>
          }
        />
      </Grid>

      <Grid item={true} className={classes.spacing8}>
        <FormControl variant="standard" fullWidth={true}>
          <Select
            variant="standard"
            value={values.activityTimeInSlots}
            onChange={handleChange}
            defaultValue={1}
            fullWidth={true}
            name="activityTimeInSlots"
            onBlur={handleBlur}
            label={
              (touched.activityTimeInSlots && errors.activityTimeInSlots) ||
              t('duration')
            }
          >
            {goTrough(20, 1).map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{t('durationHelper')}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item={true} className={classes.spacing8}>
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.multipleSlotReservationChecked}
                  onChange={handleChange}
                  name="multipleSlotReservationChecked"
                  color="primary"
                  size="small"
                />
              }
              label={
                <Typography variant="caption" color="textPrimary">
                  {t('multipleSlotReservationCheckbox')}
                </Typography>
              }
            />
          </Grid>
          <Grid item={true} xs={12}>
            {values.multipleSlotReservationChecked && (
              <TextField
                variant="standard"
                id="multipleSlotReservation"
                label={
                  (touched.multipleSlotReservation &&
                    errors.multipleSlotReservation) ||
                  t('multipleSlotReservation')
                }
                helperText={t('multipleSlotReservationHelper')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.multipleSlotReservation}
                name="multipleSlotReservation"
                error={
                  !!(
                    touched.multipleSlotReservation &&
                    errors.multipleSlotReservation
                  )
                }
                fullWidth={true}
              />
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item={true} className={classes.spacing8}>
        <TextField
          variant="standard"
          id="vat"
          label={(touched.vat && errors.vat) || t('vat')}
          helperText={t('vatHelper')}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.vat}
          name="vat"
          error={!!(touched.vat && errors.vat)}
          fullWidth={true}
        />
      </Grid>
      <Grid item={true} className={classes.spacing8}>
        <Typography variant="body1" className={classes.spacing2}>
          {t('typePayment')}
        </Typography>
        <RadioGroup
          name="paymentType"
          value={values.paymentType}
          onChange={handleChange}
        >
          <FormControlLabel
            value="person"
            control={<Radio color="primary" />}
            label={t('perPerson')}
          />
          <FormControlLabel
            value="room"
            control={<Radio color="primary" />}
            label={t('perRoom')}
          />
        </RadioGroup>
      </Grid>
      {values.paymentType === 'person' && (
        <Grid container={true} className={classes.spacing8} spacing={4}>
          <Grid item={true} sm={12} md={6}>
            <TextField
              variant="standard"
              id="sliderLength"
              label={
                (touched.sliderLength && errors.sliderLength) || t('maxPeople')
              }
              helperText={t('maxPeopleHelper')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.sliderLength}
              name="sliderLength"
              error={!!(touched.sliderLength && errors.sliderLength)}
              fullWidth={true}
            />
          </Grid>
          {values.allowNextSlotWhenOverbooked && (
            <Grid item={true} sm={12} md={6}>
              <TextField
                variant="standard"
                id="numberOfPeople"
                value={values.sliderLength}
                name="numberOfPeople"
                hidden={true}
              />
              <TextField
                variant="standard"
                id="slotTime"
                label={(touched.slotTime && errors.slotTime) || t('timeSlot')}
                helperText={t('timeSlotHelper')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.slotTime}
                name="slotTime"
                error={!!(touched.slotTime && errors.slotTime)}
                fullWidth={true}
              />
            </Grid>
          )}
          <Grid item={true} sm={12}>
            <FormControlLabel
              className={cn(classes.spacing2, classes.overbook)}
              control={
                <Checkbox
                  checked={values.allowNextSlotWhenOverbooked}
                  onChange={handleChange}
                  name="allowNextSlotWhenOverbooked"
                  color="primary"
                  size="small"
                />
              }
              label={
                <>
                  <Typography variant="caption" color="textPrimary">
                    {t('overbooking')}
                  </Typography>
                  <Tooltip
                    title={
                      'Zaznaczenie tej opcji spowoduje, że po wybraniu większej liczby osób niż jest dostępna w danej grze w pojedynczym slocie/miejscu, rezerwowane będą kolejne sloty.'
                    }
                    open={open}
                    placement="top"
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    className={classes.tooltip}
                  >
                    <InfoIcon fontSize="small" />
                  </Tooltip>
                </>
              }
            />
          </Grid>
        </Grid>
      )}
      <Grid item={true} className={classes.spacing8}>
        <FormControlLabel
          control={
            <Checkbox
              checked={values.isPublic}
              onChange={handleChange}
              name="isPublic"
              color="primary"
              size="small"
            />
          }
          label={
            <Typography variant="caption" color="textPrimary">
              {t('active')}
            </Typography>
          }
        />
      </Grid>
      <Grid item={true} className={classes.spacing8}>
        <Typography variant="h5" className={classes.spacingBottom2}>
          {t('spaceManagement')}
        </Typography>
        <Typography variant="body1" className={classes.spacingBottom2}>
          {t('spaceManagementDesc')}
        </Typography>
        <Divider />
      </Grid>
      {!!permissionSpaceView &&
        spaces.map((space) => (
          <Space
            apartments={apartments}
            data={space}
            handleModify={modifySpace}
            handleRemove={remove}
            key={space.hash}
            overbooking={props.values.allowNextSlotWhenOverbooked}
            perRoom={props.values.paymentType === 'room'}
          />
        ))}
      {permissionSpaceWrite && (
        <Grid
          container={true}
          className={cn(classes.spacing8, classes.spacingBottom8)}
          justifyContent="flex-end"
        >
          {permissionSpaceWrite && (
            <Button
              onClick={addSpace}
              size="large"
              color="primary"
              variant="outlined"
              startIcon={<MapPinIcon />}
            >
              Dodaj nowe miejsce
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default FormLayout;
