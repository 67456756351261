import React from 'react';

import Happenings from '@Compo/happenings/List';
import Grid from '@Compo/layout/Grid';

const MainAdminPage = () => (
  <Grid>
    <Happenings />
  </Grid>
);

export default MainAdminPage;
