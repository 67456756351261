import React, { useState } from 'react';

import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import ImageUpload from '@Compo/reusable/ImageUpload';
import config from '@Config';

import { IFormLayoutProps } from './FormLayout.types';

const useStyles = makeStyles((theme) => ({
  form: {
    overflowY: 'auto',
  },
  input: {
    marginTop: theme.spacing(8),
  },
  spacing2: {
    marginTop: theme.spacing(2),
  },
}));

const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Products.ProductsModal.FormLayout',
  });

  const classes = useStyles();

  const {
    handleSubmit,
    submitForm,
    handleChange,
    handleBlur,
    touched,
    errors,
    values,
    setFieldValue,
    closeModal,
    categories,
  } = props;

  const [imageUrl, setImageUrl] = useState(values.imageUrl);

  const catchAddImage = () => {
    setImageUrl(values.imageUrl);
  };

  const catchRemoveImage = () => {
    setFieldValue('imageUrl', '');
    setImageUrl('');
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent>
          <Grid container={true}>
            <TextField
              id="name"
              label={(touched.name && errors.name) || t('name')}
              helperText={t('nameHelper')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              name="name"
              error={!!(touched.name && errors.name)}
              fullWidth={true}
            />
            <TextField
              id="availableToSale"
              label={
                (touched.availableToSale && errors.availableToSale) ||
                t('availability')
              }
              helperText={t('availabilityHelper')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.availableToSale}
              name="availableToSale"
              error={!!(touched.availableToSale && errors.availableToSale)}
              className={classes.input}
              type="number"
              fullWidth={true}
            />
            <FormControlLabel
              className={classes.spacing2}
              control={
                <Checkbox
                  checked={values.isAvailabilityChecked}
                  onChange={handleChange}
                  name="isAvailabilityChecked"
                  color="primary"
                  size="small"
                />
              }
              label={
                <Typography variant="caption" color="textPrimary">
                  {t('hasAvailability')}
                </Typography>
              }
            />
            <TextField
              id="price"
              label={(touched.price && errors.price) || t('price')}
              helperText={t('priceHelper')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.price}
              name="price"
              error={!!(touched.price && errors.price)}
              className={classes.input}
              type="number"
              inputProps={{ step: '0.01', min: '0' }}
              fullWidth={true}
            />
            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.input}
            >
              <InputLabel>
                {(touched.categoryId && errors.categoryId) || t('category')}
              </InputLabel>
              <Select
                value={values.categoryId || 0}
                onChange={handleChange}
                defaultValue={0}
                fullWidth={true}
                name="categoryId"
                onBlur={handleBlur}
              >
                <MenuItem value={0}>-</MenuItem>
                {categories &&
                  categories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>{t('categoryHelper')}</FormHelperText>
            </FormControl>
            <TextField
              id="description"
              label={
                (touched.description && errors.description) || t('description')
              }
              helperText={t('descriptionHelper')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              name="description"
              error={!!(touched.description && errors.description)}
              className={classes.input}
              fullWidth={true}
            />

            <Grid container={true} spacing={2} className={classes.input}>
              <Grid item={true} xs={12}>
                <Grid container={true} spacing={1}>
                  <Grid item={true} xs={12}>
                    <Typography variant="body1" color="textSecondary">
                      {t('emailConfirmation')}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {t('emailConfirmationHelper')}
                    </Typography>
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Switch
                      checked={values.emailConfirmation}
                      onChange={handleChange}
                      name="emailConfirmation"
                      color="primary"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {config.cms.showEmailTemplate && (
              <FormControl
                variant="standard"
                fullWidth={true}
                className={classes.input}
              >
                <InputLabel>
                  {(touched.emailTemplateId && errors.emailTemplateId) ||
                    t('emailTemplate')}
                </InputLabel>

                <Select
                  value={values.emailTemplateId || -1}
                  onChange={handleChange}
                  defaultValue={-1}
                  fullWidth={true}
                  name="emailTemplateId"
                  onBlur={handleBlur}
                >
                  <MenuItem value={-1}>-</MenuItem>
                </Select>
                <FormHelperText>{t('emailTemplateHelper')}</FormHelperText>
              </FormControl>
            )}
            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.input}
            >
              <InputLabel>
                {(touched.autocomplete && errors.autocomplete) ||
                  t('autocomplete')}
              </InputLabel>
              <Select
                value={values.autocomplete}
                onChange={handleChange}
                fullWidth={true}
                name="autocomplete"
                onBlur={handleBlur}
              >
                <MenuItem value={0}>{t('no')}</MenuItem>
                <MenuItem value={1}>{t('yes')}</MenuItem>
              </Select>
              <FormHelperText>{t('autocompleteHelper')}</FormHelperText>
            </FormControl>
            <TextField
              id="vat"
              label={(touched.vat && errors.vat) || t('vat')}
              helperText={t('vatHelper')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.vat}
              name="vat"
              error={!!(touched.vat && errors.vat)}
              className={classes.input}
              fullWidth={true}
              type="number"
            />
            <FormControl
              variant="standard"
              fullWidth={true}
              className={classes.input}
            >
              <InputLabel>
                {(touched.fiscal && errors.fiscal) || t('fiscal')}
              </InputLabel>
              <Select
                value={values.fiscal}
                onChange={handleChange}
                fullWidth={true}
                name="fiscal"
                onBlur={handleBlur}
                defaultValue={values.fiscal}
              >
                <MenuItem value={0}>{t('no')}</MenuItem>
                <MenuItem value={1}>{t('yes')}</MenuItem>
              </Select>
              <FormHelperText>{t('fiscalHelper')}</FormHelperText>
            </FormControl>
            {values.slug && (
              <TextField
                id="slug"
                label={(touched.slug && errors.slug) || t('slug')}
                helperText={t('slugHelper')}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.slug}
                name="slug"
                error={!!(touched.slug && errors.slug)}
                className={classes.input}
                fullWidth={true}
                disabled={true}
              />
            )}
            <Grid item={true} xs={12} sm={6} md={4} className={classes.input}>
              <ImageUpload
                title={t('iconTitle')}
                imageUrl={imageUrl}
                onAdd={catchAddImage}
                onRemove={catchRemoveImage}
              />
            </Grid>
            <TextField
              id="imageUrl"
              label={(touched.imageUrl && errors.imageUrl) || t('icon')}
              helperText={t('iconHelper')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.imageUrl}
              name="imageUrl"
              error={!!(touched.imageUrl && errors.imageUrl)}
              fullWidth={true}
            />
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('save')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
