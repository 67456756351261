import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  backButton: {
    marginRight: theme.spacing(2),
  },
  button: {
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'flex-start',

    '&:hover': {
      textDecoration: 'initial',
    },
  },
  buttonText: {
    display: 'block',
    width: '100%',
  },
  cardContent: {
    alignItems: 'center',
    display: 'flex',
    height: 256,
    justifyContent: 'center',
    width: '100%',
  },
  center: {
    justifyContent: 'center',
  },
  defaultTimeInput: {
    '&>input:focus': {
      color: `${theme.palette.text.primary}!important`,
    },
    '&>input:not([value])': {
      color: 'transparent',
    },
    '&>input[value=""]': {
      color: 'transparent',
    },
  },
  loader: {
    zIndex: 0,
  },
  loading: {
    height: '1em!important',
    width: '1em!important',
  },
  media: {
    height: 0,
    paddingTop: '86.42%',
    width: '100%',
  },
  menuItem: {
    color: theme.palette.text.primary,
    marginTop: 0,
    width: '100%',
  },
  popper: {
    right: 0,
    zIndex: 999,
  },
  popperContainer: {
    position: 'relative',
  },
  rundateId: {
    width: 188,
  },
  secondButton: {
    width: 'auto',
  },
  startSaleButton: {
    backgroundColor: theme.palette.success.light,

    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    },
  },
  time: {
    '&>input::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)',
    },
  },
}));

export default useStyles;
