import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import {
  catchSavePoolDescription,
  setPoolDescription,
} from '@Model/poolDescriptions/actions';
import { getActivePoolDescription } from '@Model/poolDescriptions/selectors';

import AddPoolDescriptionModal from './AddPoolDescriptionModal.component';
import {
  IAddPoolDescriptionModalFromDispatch,
  IAddPoolDescriptionModalFromState,
} from './AddPoolDescriptionModal.types';

const mapStateToProps = (
  state: _Store.IState
): IAddPoolDescriptionModalFromState => ({
  activePoolDescription: getActivePoolDescription(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IAddPoolDescriptionModalFromDispatch => ({
  catchSavePoolDescription: (values) =>
    dispatch(catchSavePoolDescription(values)),
  closeModal: () => dispatch(setPoolDescription(null)),
});

export default connect<
  IAddPoolDescriptionModalFromState,
  IAddPoolDescriptionModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(AddPoolDescriptionModal);
