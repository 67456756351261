import React from 'react';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  Grid,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import cn from 'classnames';

import { TypographyVariant } from '@Constants/Variants/Typography';

import useStyles from './DonutChart.styles';
import { IDonutChartProps } from './DonutChart.types';

const DonutChart = ({
  borderWidth = 12,
  width = 64,
  height = 64,
  data,
  maxValue,
  isActive,
  hideMobile,
  centerNumber,
  stretchLabels,
}: IDonutChartProps) => {
  const theme = useTheme();
  const classes = useStyles();

  const radius = width / 2.46;
  const circuit = radius * 2 * Math.PI;
  let offset = 0;
  const stats = data.slice().reverse();
  const matches = useMediaQuery(() => {
    return theme.breakpoints.down('lg');
  });

  const [element, setElement] = React.useState('');

  return (
    <Grid
      container={true}
      spacing={2}
      wrap={matches ? 'wrap' : 'nowrap'}
      justifyContent="center"
    >
      <Grid
        className={cn(hideMobile && classes.chart, classes.circleWrapper)}
        item={true}
      >
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          className={classes.svgContainer}
        >
          {stats.map((value, index, arr) => {
            offset = index
              ? offset - (arr[index - 1].value / maxValue) * 100
              : 0;

            return (
              <circle
                r={radius}
                key={`circle-${index}`}
                cy="50%"
                cx="50%"
                stroke-width={
                  element === value.color
                    ? borderWidth + borderWidth / 3
                    : borderWidth
                }
                stroke={
                  isActive
                    ? value.color
                    : theme.palette.action.disabledBackground
                }
                fill="none"
                stroke-dashoffset={offset * (radius / 15.9)}
                stroke-dasharray={`${
                  (value.value / maxValue) * 100 * (radius / 15.9)
                } ${100 * (radius / 15.9)}`}
                transform={`rotate(-90 ${width / 2} ${height / 2})`}
              />
            );
          })}
        </svg>

        {centerNumber !== undefined && (
          <Typography
            className={classes.centerNumber}
            variant={TypographyVariant.h6}
          >
            {centerNumber}
          </Typography>
        )}
      </Grid>
      <Grid item={true} xs={12} sm={true}>
        {stats.map((value, index) => (
          <div
            key={`statsItem-${index}`}
            onMouseEnter={() => {
              setElement(value.color);
            }}
            onMouseLeave={() => {
              setElement('');
            }}
          >
            <ListItem className={classes.listItem}>
              <Grid
                container={true}
                alignItems="center"
                spacing={1}
                wrap="nowrap"
              >
                <Grid item={true} className={classes.iconContainer}>
                  <FiberManualRecordIcon
                    fontSize="inherit"
                    htmlColor={
                      isActive
                        ? value.color
                        : theme.palette.action.disabledBackground
                    }
                  />
                </Grid>

                <Grid item={true} xs={stretchLabels}>
                  <Typography
                    variant={TypographyVariant.body2}
                    className={cn(!isActive && classes.disabled)}
                  >
                    {value.label}
                  </Typography>
                </Grid>

                <Grid item={true}>
                  <Typography
                    variant={TypographyVariant.body2}
                    className={cn(!isActive && classes.disabled)}
                  >
                    {value.value}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          </div>
        ))}
      </Grid>
    </Grid>
  );
};

export default DonutChart;
