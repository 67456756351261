import { getHappenings } from '@Model/happenings/selectors';
import {
  ISelectedHappening,
  ISelectedHappeningSpace,
} from '@Model/happenings/types';
import _Store from '@Store';
import { createSelector } from 'reselect';
import { IReservationAddReducer } from './../types';
import getAdd from './getAdd';

const getSelectedSpace = createSelector<
  _Store.IState,
  ISelectedHappening[],
  IReservationAddReducer,
  ISelectedHappeningSpace | null
>(
  getHappenings,
  getAdd,
  (happenings, add) => {
    const happening = happenings.find((item) => item.id === add.happeningId);

    if (happening) {
      const space = happening.spaces.find((item) => item.id === add.spaceId);
      if (space) {
        return space;
      }
    }

    return null;
  },
);

export default getSelectedSpace;
