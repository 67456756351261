import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { IReportsResponse } from '@Services/$reports-api/types';
import _Store from '@Store';
import {
  addNewActiveInputToGroup,
  captureReport,
  getReport,
  setAvailableReports,
  setTotalCount,
} from '../actions';
import { getReports } from '../selectors';
import { IReportRequest } from '../types';
import {
  catchSelectedKeys,
  handleActivePage,
  setCounts,
  setReportKeys,
  setReportType,
  setSelectedKeys,
} from './../actions/index';

export const captureReportWhenRequest: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(getReport)),
    mergeMap$((action) => {
      return of$(captureReport.request());
    }),
  );
};

export const setSelectedKeysWhenAction: _Store.IEpic = (action$, state$) => {
  return action$.pipe(
    filter$(isActionOf(catchSelectedKeys)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { selectedKeys } = getReports(state);
      const newKey = action.payload;

      if (selectedKeys.includes(newKey)) {
        return of$(
          setSelectedKeys(selectedKeys.filter((key) => key !== newKey)),
        );
      } else {
        return of$(setSelectedKeys([...selectedKeys, newKey]));
      }
    }),
  );
};

export const addNewValueToGroupWhenRequest: _Store.IEpic = (
  action$,
  state$,
) => {
  return action$.pipe(
    filter$(isActionOf(addNewActiveInputToGroup)),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const { availableReports, selectedReport } = getReports(state);
      const { slug, value } = action.payload;

      const newList = availableReports.map((report) => {
        if (report.id === selectedReport.id) {
          if (report.filters && report.filters.length) {
            return {
              ...report,
              filters: report.filters.map((filter) => {
                if (filter.slug === slug && filter.type === 'group') {
                  if (filter.values && filter.values.length) {
                    return {
                      ...filter,
                      values: [...filter.values, { name: value, value }],
                    };
                  }
                  return {
                    ...filter,
                    values: [{ name: value, value }],
                  };
                }
                return filter;
              }),
            };
          }
        }
        return report;
      });

      return of$(setAvailableReports(newList));
    }),
  );
};

export const getReportWhenRequest: _Store.IEpic = (
  action$,
  state$,
  { reportsApi },
) => {
  return action$.pipe(
    filter$(
      isActionOf([captureReport.request, handleActivePage, setReportType]),
    ),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const {
        activePage,
        filters,
        selectedReport: { slug },
      } = getReports(state);

      const request: IReportRequest = {};

      filters.forEach((selectedFilter) => {
        request[selectedFilter.slug] = selectedFilter.value;
      });

      request.page = activePage + 1;

      return from$(reportsApi.getReport(request, slug || '')).pipe(
        mergeMap$((response: IReportsResponse) => {
          const { items, count } = response;

          if (items && items.length) {
            const prepareCounts = (): IReportRequest => {
              const prepareObject: IReportRequest = {};
              if (count && count.length) {
                Object.keys(count[0]).map((key) => {
                  const newKey = key.replace('Sum ', '');
                  prepareObject[newKey] = count[0][key];
                });

                return prepareObject;
              }
              return {};
            };

            const keys = Object.keys(items[0]);

            return of$(
              captureReport.success(items),
              setReportKeys(keys),
              setCounts(prepareCounts()),
              setTotalCount(
                count && count.length && count[0].Ids
                  ? Number(count[0].Ids)
                  : 0,
              ),
            );
          }

          return of$(captureReport.success([]));
        }),
        catchError$((error: Error) => {
          return of$(captureReport.failure(error));
        }),
      );
    }),
  );
};
