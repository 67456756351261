import { Selector } from 'reselect';

import _Store from '@Store';

import { ITicketsReducer } from '../types';

const getTickets: Selector<_Store.IState, ITicketsReducer> = (state) =>
  state.goingRundates.tickets;

export default getTickets;
