import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  basisFix: {
    flexBasis: 'auto',
  },
  buttonsContainer: {
    justifyContent: 'flex-end',
  },
  colorContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    height: 200,
    justifyContent: 'center',
    width: '100%',
  },
  defaultTimeInput: {
    '&>input:focus': {
      color: `${theme.palette.text.primary}!important`,
    },
    '&>input:not([value])': {
      color: 'transparent',
    },
    '&>input[value=""]': {
      color: 'transparent',
    },
  },
  media: {
    height: 200,
    width: '100%',
  },
  overbook: {
    position: 'relative',
  },
  spacing2: {
    marginTop: theme.spacing(2),
  },
  spacing5: {
    marginTop: theme.spacing(5),
  },
  spacing8: {
    marginTop: theme.spacing(8),
  },
  spacingBottom2: {
    marginBottom: theme.spacing(2),
  },
  spacingBottom8: {
    marginBottom: theme.spacing(8),
  },
  time: {
    '&>input::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)',
    },
  },
  tooltip: {
    marginLeft: theme.spacing(1),
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)',
  },
}));

export default useStyles;
