import React from 'react';

import Grid from '@Compo/layout/Grid';
import Summary from '@Compo/Summary';

const SummaryPage = () => (
  <Grid>
    <Summary />
  </Grid>
);

export default SummaryPage;
