import React, { useEffect, useState } from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import CloudinaryUploadWidget from '@Compo/reusable/CloudinaryUploadWidget';
import { useCloudinaryApi } from '@Misc/hooks';

import { IFormLayoutProps } from './../../FormLayout.types';
import useStyles from './FormImageUpload.styles';

const ASSET_TYPE = 'mailing';

const FormImageUpload = ({
  setFieldValue,
  permissionAssetsDelete,
  deleteAssets,
  values,
  errors,
  touched,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Mail.components.FormLayout.components.FormImageUpload',
  });

  const classes = useStyles();

  const [cloudinaryImagesUrl, setCloudinaryImagesUrl] = useState<string>('');
  const [cloudinaryImagesSlug, setCloudinaryImagesSlug] = useState<string>('');

  const slug = values.cloudinaryId || '';
  const assetsFolderName = '';

  const { cloudinaryImagesUrls } = useCloudinaryApi(slug, ASSET_TYPE);

  const handleDeleteAssets = () => {
    deleteAssets(cloudinaryImagesSlug);
    setCloudinaryImagesUrl('');
  };

  useEffect(() => {
    setFieldValue('picture', cloudinaryImagesUrl);
  }, [cloudinaryImagesUrl]);

  useEffect(() => {
    if (slug) {
      setCloudinaryImagesUrl(cloudinaryImagesUrls[0] || '');

      setCloudinaryImagesSlug(`${ASSET_TYPE}/${slug}`);
    }
  }, [cloudinaryImagesUrls[0], slug]);

  return (
    <Grid item={true} xs={12}>
      <Card>
        {cloudinaryImagesUrl ? (
          <CardMedia image={cloudinaryImagesUrl} className={classes.media} />
        ) : (
          <CardContent className={classes.cardContent}>
            <Typography
              variant="body1"
              color={
                touched.picture && errors.picture ? 'error' : 'textSecondary'
              }
            >
              {(touched.picture && errors.picture) || t('imagePlaceholder')}
            </Typography>
          </CardContent>
        )}
        {cloudinaryImagesSlug && (
          <CardActions>
            <Grid container={true} spacing={2} justifyContent={'space-between'}>
              <Grid container={true} item={true} xs={2}>
                {permissionAssetsDelete && !!cloudinaryImagesUrl.length && (
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={handleDeleteAssets}
                  >
                    <DeleteForeverIcon fontSize="small" />
                  </IconButton>
                )}
              </Grid>

              <Grid
                container={true}
                item={true}
                xs={3}
                justifyContent={'flex-end'}
              >
                <CloudinaryUploadWidget
                  setCloudinaryImagesUrl={setCloudinaryImagesUrl}
                  assetType={ASSET_TYPE}
                  assetFolderName={assetsFolderName}
                  slug={slug}
                  disabled={values.isSent}
                />
              </Grid>
            </Grid>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};

export default FormImageUpload;
