import React from 'react';

import Grid from '@Compo/layout/Grid';
import Edit from '@Compo/Rundates/Edit';

const AddRundatePage = () => (
  <Grid>
    <Edit addRundate={true} />
  </Grid>
);

export default AddRundatePage;
