import { getType } from 'typesafe-actions';

import { IAction, IProductsReducer } from '../types';
import {
  addCategory,
  addLocalProduct,
  addShipmentFee,
  getAllCategories,
  getAllInvoiceProducts,
  getAllLocalProducts,
  getAllProducts,
  getAllProductsWithCategories,
  getAllShipmentFee,
  getAllZohoProducts,
  getProducts,
  getProductsByCategory,
  getSingleProduct,
  handleActiveCategory,
  handleActiveLocalProduct,
  handleActiveShipmentFee,
  handleActiveZohoProduct,
  removeProduct,
  setProductView,
  updateList,
} from './../actions/index';

const initialState: IProductsReducer = {
  activeCategory: null,
  activeInvoiceProduct: null,
  activeProduct: null,
  activeShipmentFee: null,
  activeZohoProduct: null,
  allProducts: [],
  categories: [],
  defaultCategory: {
    color: '#FF6641',
    name: '',
  },
  defaultProduct: {
    autocomplete: false,
    availableToSale: undefined,
    categoryId: 0,
    description: '',
    emailConfirmation: false,
    emailTemplateId: -1,
    fiscal: true,
    imageUrl: '',
    isAvailabilityChecked: false,
    name: '',
    price: 0,
    slug: undefined,
    stock: [],
    vat: 0,
  },
  defaultShipmentFee: {
    name: '',
    price: 0,
  },
  invoiceProducts: [],
  items: [],
  localProducts: [],
  meta: { size: 10, total: 0, page: 1 },
  productsCategories: null,
  shipmentFees: [],
  view: 'products',
  zohoProducts: [],
};

const productsReducer = (
  state: IProductsReducer = initialState,
  action: IAction
): IProductsReducer => {
  switch (action.type) {
    case getType(getProducts.success):
      return {
        ...state,
        items: action.payload,
      };
    case getType(updateList):
      return {
        ...state,
        items: action.payload,
      };

    case getType(setProductView):
      return {
        ...state,
        view: action.payload,
      };

    case getType(addCategory):
      return {
        ...state,
        activeCategory: state.defaultCategory,
      };

    case getType(addLocalProduct):
      return {
        ...state,
        activeProduct: state.defaultProduct,
      };

    case getType(addShipmentFee):
      return {
        ...state,
        activeShipmentFee: state.defaultShipmentFee,
      };

    case getType(handleActiveShipmentFee):
      return {
        ...state,
        activeShipmentFee: action.payload,
      };

    case getType(getSingleProduct.success):
      return {
        ...state,
        activeInvoiceProduct: action.payload,
      };

    case getType(handleActiveLocalProduct):
      return {
        ...state,
        activeProduct: action.payload,
      };

    case getType(handleActiveCategory):
      return {
        ...state,
        activeCategory: action.payload,
      };

    case getType(handleActiveZohoProduct):
      return {
        ...state,
        activeZohoProduct: action.payload,
      };

    case getType(removeProduct):
      const arrayItems = [...state.items];
      const index = arrayItems.indexOf(action.payload);
      if (index > -1) {
        arrayItems.splice(index, 1);
      }
      return {
        ...state,
        items: arrayItems,
      };
    case getType(getAllProducts.success):
      return {
        ...state,
        allProducts: action.payload,
      };
    case getType(getAllLocalProducts.success):
      return {
        ...state,
        localProducts: action.payload.data,
        meta: action.payload.meta,
      };
    case getType(getAllCategories.success):
      return {
        ...state,
        categories: action.payload.data,
        meta: action.payload.meta,
      };
    case getType(getAllProductsWithCategories.success):
      return {
        ...state,
        categories: action.payload.data,
        meta: action.payload.meta,
      };
    case getType(getAllShipmentFee.success):
      return {
        ...state,
        meta: action.payload.meta,
        shipmentFees: action.payload.data,
      };
    case getType(getAllZohoProducts.success):
      return {
        ...state,
        meta: action.payload.meta,
        zohoProducts: action.payload.data,
      };
    case getType(getAllInvoiceProducts.success):
      return {
        ...state,
        invoiceProducts: action.payload.data,
        meta: action.payload.meta,
      };
    case getType(getProductsByCategory.success):
      return {
        ...state,
        productsCategories: action.payload,
      };

    default:
      return state;
  }
};

export default productsReducer;
