import axios, { CancelTokenSource } from 'axios';
import path from 'path';

import config from '@Config';
import catchHttpError from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';

import {
  IFileType,
  IGetTicketsSuccessPayload,
  IRundateReport,
  ISingleTicketByCode,
  ISingleTicketResponse,
  ITicketBody,
  ITicketGenerateBody,
  ITicketStatusPayload,
  IUpdateTicketBody,
} from './types';

class TicketsApi {
  private static getTicketsUrl(): string {
    return `${config.api.cmsServices}tickets`;
  }
  private static ticketStatusUrl(ticketCode: string): string {
    return `${config.api.cmsServices}ticket-status/${ticketCode}`;
  }
  private static deletionTicketStatusUrl(ticketCode: string): string {
    return `${config.api.cmsServices}ticket-live-status/${ticketCode}`;
  }
  private static getTicketsGenerateUrl(): string {
    return `${config.api.cmsServices}tickets/generate`;
  }
  private static getSingleTicketUrl(id: number): string {
    return `${config.api.cmsServices}tickets/${id}`;
  }
  private static deleteSingleTicketUrl(id: number): string {
    return `${config.api.cmsServices}tickets/${id}`;
  }
  private static revertSingleTicketUrl(id: number): string {
    return `${config.api.cmsServices}tickets/${id}/revert`;
  }
  private static getGenerateReportUrl(rundateId: number): string {
    return `${config.api.cmsServices}tickets/report/${rundateId}`;
  }
  private static getSingleTicketByCodeUrl(code: string): string {
    return `${config.api.cmsServices}tickets/code/${code}`;
  }
  private static getTicketsSeatsUrl(): string {
    return `${config.api.cmsServices}tickets/generate`;
  }

  private cancelTokenTickets?: CancelTokenSource;

  public getTickets(
    token: string,
    partnerId?: string,
    search?: string,
    page?: number
  ): Promise<IGetTicketsSuccessPayload> {
    return new Promise<IGetTicketsSuccessPayload>((resolve, reject) => {
      this.cancelTokenTickets = axios.CancelToken.source();
      axios
        .get(TicketsApi.getTicketsUrl(), {
          cancelToken: this.cancelTokenTickets.token,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            'filter[all]': search,
            page,
            'partnerIds[]': partnerId,
            sort: 'id:desc',
          },
        })
        .then(getData)
        .then((response: IGetTicketsSuccessPayload) => {
          resolve({ data: response.data, meta: response.meta });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getTicketByCode(code: string): Promise<ISingleTicketByCode> {
    return new Promise<ISingleTicketByCode>((resolve, reject) => {
      this.cancelTokenTickets = axios.CancelToken.source();

      axios
        .get(TicketsApi.getSingleTicketByCodeUrl(code), {
          cancelToken: this.cancelTokenTickets.token,
        })
        .then(getData)
        .then(getData)
        .then((response) => resolve(response[0]))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public ticketStatus(ticketCode: string): Promise<ITicketStatusPayload> {
    return new Promise<ITicketStatusPayload>((resolve, reject) => {
      this.cancelTokenTickets = axios.CancelToken.source();

      axios
        .post(
          TicketsApi.ticketStatusUrl(ticketCode),
          {},
          {
            cancelToken: this.cancelTokenTickets.token,
          }
        )
        .then(getData)
        .then((response: ITicketStatusPayload) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });
  }

  public ticketStatusDeletion(
    ticketCode: string
  ): Promise<ITicketStatusPayload> {
    return new Promise<ITicketStatusPayload>((resolve, reject) => {
      this.cancelTokenTickets = axios.CancelToken.source();

      axios
        .post(
          TicketsApi.deletionTicketStatusUrl(ticketCode),
          {},
          {
            cancelToken: this.cancelTokenTickets.token,
            params: {
              liveStatus: false,
            },
          }
        )
        .then(getData)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response?.data);
        });
    });
  }

  public saveTicket(body: ITicketBody): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenTickets = axios.CancelToken.source();

      axios
        .post(TicketsApi.getTicketsUrl(), body, {
          cancelToken: this.cancelTokenTickets.token,
        })
        .then(getData)
        .then(resolve)
        .catch((error) => catchHttpError(error));
    });
  }

  public saveSeatsTicket(body: ITicketBody): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenTickets = axios.CancelToken.source();

      axios
        .post(TicketsApi.getTicketsSeatsUrl(), body, {
          cancelToken: this.cancelTokenTickets.token,
        })
        .then(getData)
        .then(resolve)
        .catch((error) => catchHttpError(error));
    });
  }

  public saveGenerateTicket(body: ITicketGenerateBody): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenTickets = axios.CancelToken.source();

      axios
        .post(TicketsApi.getTicketsGenerateUrl(), body, {
          cancelToken: this.cancelTokenTickets.token,
        })
        .then(getData)
        .then(resolve)
        .catch((error) => catchHttpError(error));
    });
  }

  public getSingleTicket(id: number): Promise<ISingleTicketResponse> {
    return new Promise<ISingleTicketResponse>((resolve, reject) => {
      this.cancelTokenTickets = axios.CancelToken.source();

      axios
        .get(TicketsApi.getSingleTicketUrl(id))
        .then(getData)
        .then(getData)
        .then((response) => resolve(response[0]))
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public deleteSingleTicket(id: number): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenTickets = axios.CancelToken.source();

      axios
        .delete(TicketsApi.deleteSingleTicketUrl(id))
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public deleteMultipleTickets(ticketIds: number[]): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenTickets = axios.CancelToken.source();

      axios
        .delete(TicketsApi.getTicketsUrl(), {
          params: {
            ticketIds,
          },
        })
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public revertSingleTicket(id: number): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenTickets = axios.CancelToken.source();

      axios
        .put(TicketsApi.revertSingleTicketUrl(id))
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public updateTicket(
    ticketCode: string,
    body: IUpdateTicketBody
  ): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenTickets = axios.CancelToken.source();

      axios
        .post(
          TicketsApi.ticketStatusUrl(ticketCode),
          {},
          {
            cancelToken: this.cancelTokenTickets.token,
            params: { withLiveStatus: false, verified: body.verified },
          }
        )
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public getRundateReport(
    rundateId: number,
    fileType: IFileType
  ): Promise<IRundateReport> {
    return new Promise<IRundateReport>((resolve, reject) => {
      this.cancelTokenTickets = axios.CancelToken.source();

      axios
        .get(TicketsApi.getGenerateReportUrl(rundateId), {
          headers: {
            'Access-Control-Expose-Headers': 'Content-Disposition',
          },
          params: { fileType },
          responseType: 'arraybuffer',
        })
        .then((response) => {
          const filename = response.headers['content-disposition']
            .split('filename=')[1]
            .split('.')[0];
          const extension = path
            .extname(response.headers['content-disposition'])
            .split(';')[0];

          resolve({
            data: response.data,
            fileName: `${filename}${extension}`,
          });
        })
        .catch((error) => reject(catchHttpError(error)));
    });
  }

  public cancelTickets() {
    if (this.cancelTokenTickets) {
      this.cancelTokenTickets.cancel();
      this.cancelTokenTickets = undefined;
    }
  }
}

export default new TicketsApi();
