import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { mounted } from '@Model/going/reportSeatsIo/actions';
import { getReportSeatsIo } from '@Model/going/reportSeatsIo/selectors';
import { mounted as rundateMounted } from '@Model/going/rundates/actions';
import { getSingleRundateData } from '@Model/going/rundates/selectors';
import { SEATS_IO_REPORT } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import ReportSeatsIo from './ReportSeatsIo.component';
import {
  IReportSeatsIoFromDispatch,
  IReportSeatsIoFromState,
} from './ReportSeatsIo.types';

const mapStateToProps = (state: _Store.IState): IReportSeatsIoFromState => ({
  isLoading: getLoading(SEATS_IO_REPORT)(state),
  reports: getReportSeatsIo(state),
  rundate: getSingleRundateData(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IReportSeatsIoFromDispatch => ({
  mounted: () => dispatch(mounted()),
  rundateMounted: () => dispatch(rundateMounted()),
});

export default connect<
  IReportSeatsIoFromState,
  IReportSeatsIoFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(ReportSeatsIo);
