import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { catchSaveNewDiscount } from '@Model/discount/actions';
import { getActiveEventDiscount } from '@Model/discount/selectors';
import { DISCOUNT } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';
import _Store from '@Store';
import EventDiscountForm from './EventDiscountForm.component';
import {
  IEventDiscountFormFromDispatch,
  IEventDiscountFromState,
} from './EventDiscountForm.types';

const mapStateToProps = (state: _Store.IState): IEventDiscountFromState => ({
  initialValues: getActiveEventDiscount(state),
  isLoading: getLoading(DISCOUNT)(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IEventDiscountFormFromDispatch => ({
  save: (values) => {
    dispatch(catchSaveNewDiscount(values));
  },
});

export default connect<
  IEventDiscountFromState,
  IEventDiscountFormFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(EventDiscountForm);
