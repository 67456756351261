import React from 'react';

import { Dialog } from '@mui/material';

import MailForm from './../MailForm';
import { IMailModalProps } from './MailModal.types';

const MailModal = ({
  showModal,
  closeModal,
  isRundateEdit,
}: IMailModalProps) => {
  return (
    <Dialog
      open={showModal}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <MailForm isRundateEdit={isRundateEdit} />
    </Dialog>
  );
};
export default MailModal;
