// Private
export const _GET_REQUEST = 'Zagrywki/priceTypes/_GET_REQUEST';
export const _GET_SUCCESS = 'Zagrywki/priceTypes/_GET_SUCCESS';
export const _GET_FAILURE = 'Zagrywki/priceTypes/_GET_FAILURE';

export const _SAVE_PRICE_TYPE_REQUEST =
  'Zagrywki/priceTypes/_SAVE_PRICE_TYPE_REQUEST';
export const _SAVE_PRICE_TYPE_SUCCESS =
  'Zagrywki/priceTypes/_SAVE_PRICE_TYPE_SUCCESS';
export const _SAVE_PRICE_TYPE_FAILURE =
  'Zagrywki/priceTypes/_SAVE_PRICE_TYPE_AILURE';

// Public
export const CATCH_GET_RULE_TYPES = 'Zagrywki/priceTypes/CATCH_GET_RULE_TYPES';
export const CATCH_SAVE_PRICE_TYPE =
  'Zagrywki/priceTypes/CATCH_SAVE_PRICE_TYPE';
export const MOUNTED = 'Zagrywki/priceTypes/MOUNTED';
export const SELECT_PRICE_TYPE = 'Zagrywki/priceTypes/SELECT_PRICE_TYPE';
export const CATCH_GET_RULE_TYPES_WHEN_PAGE_CHANGED =
  'Zagrywki/priceTypes/CATCH_GET_RULE_TYPES_WHEN_PAGE_CHANGED';
export const DELETE_PRICE_TYPE = 'Zagrywki/priceTypes/DELETE_PRICE_TYPE';
