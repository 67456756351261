import { object, string } from 'yup';

const REQUIRED_VALIDATOR_TEXT = 'Pole wymagane';
const EMAIL_VALIDATOR_TEXT = 'Błędny format maila.';

const configuration = {
  email: string().email(EMAIL_VALIDATOR_TEXT).required(REQUIRED_VALIDATOR_TEXT),
};

export const TestEmailSchema = object().shape(configuration);
