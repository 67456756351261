import { routes } from '@/routes/routes';
import { TFunction } from 'i18next';

import config from '@Config';
import {
  allPermissions,
  TPermissions,
} from '@Model/state/constants/permissions';

import i18next from './../../../translations/i18n';

const getMenuItems = (
  permissions: TPermissions[],
  permissionConsentListView: boolean,
  t: TFunction
) => {
  return [
    {
      access: permissions.includes(allPermissions.access_add_reservations_view),
      icon: 'add',
      link: routes.addReservation,
      name: t('addReservation'),
    },
    {
      access: permissions.includes(allPermissions.access_reservations_read),
      icon: 'calendar',
      link: routes.calendar,
      name: t('calendar'),
    },
    {
      access: permissions.includes(allPermissions.access_tickets_scan),
      icon: 'scanTicket',
      link: routes.findTickets,
      name: t('scanTicket'),
    },
    {
      access: permissions.includes(allPermissions.access_tickets),
      icon: 'transactions',
      link: routes.transactions,
      name: t('transactions'),
    },
    {
      access: permissions.includes(allPermissions.access_tickets),
      icon: 'tickets',
      link: routes.tickets,
      name: t('tickets'),
    },
    {
      access: permissions.includes(
        allPermissions.access_permission_delayed_transactions
      ),
      icon: 'delayedTransactions',
      link: routes.transactionsDelayed,
      name: t('delayedTransactions'),
    },
    {
      access: permissions.includes(allPermissions.access_happenings_read),
      icon: 'grid',
      link: routes.happenings,
      name: t('happenings'),
    },
    {
      access:
        permissions.includes(allPermissions.access_rundate_read) &&
        config.cms.showGoingRundates,
      icon: 'events',
      link: routes.rundates,
      name: t('events'),
    },
    {
      access: permissions.includes(allPermissions.access_discounts_read),
      icon: 'discount',
      link: routes.discounts,
      name: t('discount'),
    },
    {
      access: permissions.includes(
        allPermissions.access_pool_descriptions_read
      ),
      icon: 'poolsDescriptions',
      link: routes.poolDescriptions,
      name: t('poolsDescriptions'),
    },
    {
      access: permissions.includes(allPermissions.access_happenings_write),
      icon: 'rules',
      link: routes.newRules,
      name: t('rules'),
    },
    {
      access: permissions.includes(allPermissions.access_configurations_write),
      icon: 'ticketTypes',
      link: routes.priceTypes,
      name: t('ticketTypes'),
    },
    {
      access: permissions.includes(allPermissions.access_analytics_view),
      icon: 'statistic',
      link: routes.statics,
      name: t('statistic'),
    },
    {
      access: permissions.includes(
        allPermissions.access_printers_read ||
          allPermissions.access_printers_write ||
          allPermissions.access_printers_view
      ),
      icon: 'printers',
      link: routes.admin,
      name: t('printers'),
    },
    {
      access: permissions.includes(allPermissions.access_reports_read),
      icon: 'reports',
      link: routes.reports,
      name: t('reports'),
    },
    {
      access: permissions.includes(allPermissions.access_products_read),
      icon: 'products',
      link: routes.products,
      name: t('products'),
    },
    {
      access: permissions.includes(allPermissions.access_settlements_read),
      icon: 'settlements',
      link: routes.settlements,
      name: t('settlements'),
    },
    {
      access: permissions.includes(allPermissions.access_event_mailing_read),
      icon: 'mailing',
      link: routes.mail,
      name: t('mailing'),
    },
    {
      access: permissions.includes(
        allPermissions.access_pixel_read ||
          allPermissions.access_settlements_read
      ),
      icon: 'accountSettings',
      link: routes.settings,
      name: t('accountSettings'),
    },
    {
      access: permissionConsentListView,
      icon: 'consentsList',
      link: routes.consentsList,
      name: t('consentsList'),
    },
    {
      access: permissions.includes(allPermissions.access_report_bug_view),
      icon: 'reportBug',
      link: routes.reportBug,
      name: t('reportBug'),
    },
    {
      access: permissions.includes(allPermissions.access_users_read),
      icon: 'people',
      link: routes.users,
      name: t('users'),
    },
    {
      access: permissions.includes(
        allPermissions.access_refund_registration_read
      ),
      icon: 'refundsRegistration',
      link: routes.refundsRegistration,
      name: t('refundsRegistration'),
    },
  ];
};

export const getFirstRoute = (
  permissions: TPermissions[],
  permissionConsentListView: boolean
) => {
  const menuItems = getMenuItems(
    permissions,
    permissionConsentListView,
    i18next.t
  );

  return menuItems.find((item) => item.access)?.link;
};

export default getMenuItems;
