import { getType } from 'typesafe-actions';

import {
  getEventMailing,
  handleActivePage,
  handleSearchText,
  selectEventMail,
} from './../actions';
import { IAction, IMailingReducer } from './../types';

const initialState: IMailingReducer = {
  data: [],
  meta: {
    page: 1,
    size: 20,
    total: 0,
  },
  searchText: '',
  selectedMail: null,
};

const mailingReducer = (
  state: IMailingReducer = initialState,
  action: IAction
) => {
  switch (action.type) {
    case getType(getEventMailing.success):
      return {
        ...state,
        data: action.payload.data,
        meta: action.payload.meta,
      };
    case getType(selectEventMail):
      const dependencies = action.payload?.dependencies.map((dependency) => ({
        ...dependency,
        meta: {
          ...dependency.meta,
          id: dependency.typeIdentity,
          nameInterface: dependency.type,
        },
      }));

      return {
        ...state,
        selectedMail: action.payload && {
          ...action.payload,
          ...(dependencies && { dependencies }),
        },
      };
    case getType(handleActivePage):
      return {
        ...state,
        meta: {
          ...state.meta,
          page: action.payload,
        },
      };
    case getType(handleSearchText):
      return {
        ...state,
        searchText: action.payload,
      };
    default:
      return state;
  }
};

export default mailingReducer;
