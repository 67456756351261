import React, { FC } from 'react';

import useStyles from './LabelContentContainer.styles';
import { ILabelContentContainerProps } from './LabelContentContainer.types';

const LabelContentContainer: FC<ILabelContentContainerProps> = ({
  children,
  backgroundColor,
}) => {
  const classes = useStyles({ backgroundColor });

  return <div className={classes.container}>{children}</div>;
};

export default LabelContentContainer;
