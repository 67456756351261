import React, { FC, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Button, InputAdornment, TextField } from '@mui/material';
import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';

import styles from './../../Discounts.module.scss';
import EventDiscountModal from './../EventDiscountModal';
import { getInitialValues } from './../EventDiscountModal/components/EventDiscountForm/EventDiscountForm.helpers';
import useStyles from './EventsDiscountTable.styles';
import { IEventsDiscountsProps } from './EventsDiscountTable.types';

// TODO:
const VALUE_TEXT = 'Wartość';

const DiscountTable: FC<IEventsDiscountsProps> = ({
  discounts,
  edit,
  permissionEventDiscountWrite,
  activePage,
  perPage,
  totalCount,
  updateSearchText,
  searchText,
  handleActivePage,
  addDiscount,
  isLoading,
  mounted,
  activeEventDiscount,
}) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Discounts.components.EventsDiscountTable',
  });

  const [searchTimeOut, setSearchTimeOut] = useState<number>(0);
  const [inputText, setInputText] = useState(searchText);
  const classes = useStyles();

  const catchHandleSearch = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.currentTarget;
    setInputText(value);
    searchFunc(value);
  };

  const searchFunc = (text: string) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        updateSearchText(text);
      }, 1000) as any
    );
  };

  const catchChangePage = (e: unknown, page: number) => {
    handleActivePage(page);
  };
  const catchRowClick = (code: string) => {
    if (permissionEventDiscountWrite) {
      edit(Number(code));
    }
  };

  const addEventDiscount = () => {
    addDiscount(getInitialValues());
  };

  const closeModal = () => {
    addDiscount(null);
  };

  const columns = [
    { id: 'id', label: t('id') },
    { id: 'code', label: t('code') },
    { id: 'value', label: VALUE_TEXT },
    { id: 'valuePercentage', label: t('percent') },
    { id: 'startDate', label: t('startDate') },
    { id: 'expireDate', label: t('endDate') },
    { id: 'itemLimit', label: t('limit') },
  ];

  const normalizedDiscounts = discounts.map((discount) => ({
    ...discount,
    expireDate: moment(discount.expireDate).format('DD.MM.YYYY HH:mm'),
    startDate: moment(discount.startDate).format('DD.MM.YYYY HH:mm'),
  }));

  useEffect(() => {
    mounted();
  }, []);

  if (isLoading) {
    return (
      <div className={styles.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <GenericTable>
      <EventDiscountModal
        closeModal={closeModal}
        showModal={!!activeEventDiscount}
      />
      <TableActions>
        <TextField
          variant="standard"
          name="search"
          helperText={t('search')}
          value={inputText}
          onChange={catchHandleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="secondary" />
              </InputAdornment>
            ),
          }}
        />
        <div className={classes.buttonsContainer}>
          {permissionEventDiscountWrite && (
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              startIcon={<AddIcon />}
              className={cn(classes.button, classes.buttonFirst)}
              onClick={addEventDiscount}
            >
              {t('add')}
            </Button>
          )}
        </div>
      </TableActions>
      <TableUi
        rows={normalizedDiscounts}
        columns={columns}
        total={totalCount}
        activePage={activePage}
        rowsPerPage={perPage}
        handleChangePage={catchChangePage}
        disableCheckBox={true}
        onRowClick={catchRowClick}
      />
    </GenericTable>
  );
};

export default DiscountTable;
