import React, { ChangeEvent, useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '@Compo/reusable/ConfirmModal';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import { IColumns } from '@Compo/reusable/TableUi/TableUi.types';

import GroupsModal from './components/GroupsModal';
import useStyles from './GroupsTable.styles';
import { IGroupsTableProps } from './GroupsTable.types';

const GroupsTable = ({
  addNewGroup,
  mounted,
  groups,
  deleteGroup,
  edit,
  meta,
  handleActivePage,
  handleChangeRowsPerPage,
  search,
  searchText,
  isLoading,
}: IGroupsTableProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Users.components.GroupsTable',
  });

  const [searchParam, setSearchParam] = useState(searchText);
  const [searchTimeOut, setSearchTimeOut] = useState<NodeJS.Timeout | null>(
    null
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [groupId, setGroupId] = useState<number>();

  const classes = useStyles();

  const columns: IColumns[] = [
    { id: 'name', label: t('name') },
    { id: 'actions', label: t('actions'), width: 200 },
  ];

  const onPageChanged = (event: unknown, page: number) => {
    handleActivePage(page + 1);
  };

  const catchChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChangeRowsPerPage(Number(event.target.value));
  };

  const handleDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => {
    event.stopPropagation();

    setGroupId(id);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setGroupId(undefined);
  };

  const handleConfirmDeleteModal = () => {
    handleCloseDeleteModal();

    if (groupId) {
      deleteGroup(groupId);
    }
  };

  const catchEdit = (id: string) => {
    edit(Number(id));
  };

  const catchHandleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchParam(e.target.value);
    searchFunc(e.target.value);
  };

  const searchFunc = (text: string) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        search(text);
      }, 1000)
    );
  };

  const normalizedGroups = groups.map((group) => ({
    ...group,
    actions: (
      <Grid container={true} spacing={2}>
        <Grid item={true}>
          <Button variant="outlined" size="small" color="primary">
            {t('edit')}
          </Button>
        </Grid>
        <Grid item={true}>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={(e) => handleDelete(e, group.id)}
          >
            {t('delete')}
          </Button>
        </Grid>
      </Grid>
    ),
  }));

  useEffect(() => {
    mounted();
  }, []);

  return (
    <>
      <ConfirmModal
        open={showDeleteModal}
        title={t('deleteModalTitle')}
        question={t('deleteModalDescription')}
        handleClose={handleCloseDeleteModal}
        onCancel={handleCloseDeleteModal}
        onConfirm={handleConfirmDeleteModal}
      />
      <GenericTable>
        <GroupsModal />
        <TableActions>
          <TextField
            name="search"
            helperText={t('search')}
            value={searchParam}
            onChange={catchHandleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
          <div className={classes.buttonsContainer}>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              startIcon={<AddIcon />}
              className={cn(classes.button, classes.buttonFirst)}
              onClick={addNewGroup}
            >
              {t('add')}
            </Button>
          </div>
        </TableActions>
        <TableUi
          rows={normalizedGroups}
          columns={columns}
          total={meta.total}
          activePage={meta.page - 1}
          rowsPerPage={meta.size}
          handleChangePage={onPageChanged}
          handleChangeRowsPerPage={catchChangeRowsPerPage}
          disableCheckBox={true}
          onRowClick={catchEdit}
          isLoading={isLoading}
        />
      </GenericTable>
    </>
  );
};

export default GroupsTable;
