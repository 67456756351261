import { createSelector } from 'reselect';

import { IModalReducer } from '@Model/modal/types';
import _Store from '@Store';
import get from './get';

const getSuccessModal = createSelector<_Store.IState, IModalReducer, boolean>(
  [get],
  (modal) => modal.showSuccessModal
);

export default getSuccessModal;
