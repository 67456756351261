import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { setActiveTransaction } from '@Model/transactions/actions';
import { getActiveTransaction } from '@Model/transactions/selectors';

import TransactionModal from './TransactionModal.component';
import {
  ITransactionModalFromDispatch,
  ITransactionModalFromState,
} from './TransactionModal.types';

const mapStateToProps = (state: _Store.IState): ITransactionModalFromState => ({
  activeTransaction: getActiveTransaction(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ITransactionModalFromDispatch => ({
  closeModal: () => dispatch(setActiveTransaction(null)),
});

export default withRouter(
  connect<
    ITransactionModalFromState,
    ITransactionModalFromDispatch,
    {},
    _Store.IState
  >(
    mapStateToProps,
    mapDispatchToProps
  )(TransactionModal)
);
