import { getAvailabilities } from '@Model/happening/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetError, resetLoading, setLoading } from './../actions';
import { HAPPENING_AVAILABILITY } from './../constants/constants';

export const setUpStateWhenAvailabilitiesFetchRequested: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$(isActionOf(getAvailabilities.request)),
    mergeMap$(() => [
      resetError(HAPPENING_AVAILABILITY),
      setLoading(HAPPENING_AVAILABILITY),
    ]),
  );
};

export const setLoaderWhenAvailabilitiesFetchFinished: _Store.IEpic = (
  action$,
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [getAvailabilities.success, getAvailabilities.failure],
        action,
      ),
    ),
    map$(() => resetLoading(HAPPENING_AVAILABILITY)),
  );
};

export const setErrorOnAvailabilitiesFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(getAvailabilities.failure)),
    map$((action) => addToast(action.payload.message, TYPE_ERROR)),
  );
};
