import { ITicketValues } from './TicketForm.types';

export const getInitialValues = (
  ticketListId: number,
  values?: any
): ITicketValues => {
  if (values) {
    return {
      email: values.email,
      firstname: values.firstname,
      id: values.id,
      invitationsNumber: values.invitationsNumber,
      isMultiple: !!values.invitationsNumber,
      isSeats: !!values.seats.length,
      languageId: values.languageId,
      lastname: values.lastname,
      mailTemplate: values.mailTemplate,
      pdfTemplate: values.pdfTemplate,
      seats: values.seats,
      sendMail: values.sendMail,
      ticketListId: values.ticketListId,
      ticketsNum: values.ticketsNum,
      title: values.title,
    };
  }

  return {
    email: '',
    firstname: '',
    invitationsNumber: null,
    isMultiple: false,
    isSeats: false,
    languageId: 1,
    lastname: '',
    mailTemplate: null,
    pdfTemplate: null,
    seats: [],
    sendMail: true,
    ticketListId,
    ticketsNum: null,
    title: null,
  };
};
