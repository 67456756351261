import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IEventsDiscountsResponse } from '@Services/$discounts-api/types';
import {
  _GET_EVENTS_DISCOUNT_FAILURE,
  _GET_EVENTS_DISCOUNT_REQUEST,
  _GET_EVENTS_DISCOUNT_SUCCESS,
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  _RESET_STATE,
  HANDLE_DISCOUNT_ACTIVE_PAGE,
  MOUNTED,
  MOUNTED_EVENTS,
  SET_ACTIVE_FILTER,
  UPDATE_SEARCH_EVENT_TEXT,
  UPDATE_SEARCH_TEXT,
} from '../constants/actions';
import {
  IGetDiscountsSuccessPayload,
  IHandleActivePagePayload,
} from '../types';

export const getDiscounts = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE
)<undefined, IGetDiscountsSuccessPayload, Error>();

export const getEventsDiscounts = createAsyncAction(
  _GET_EVENTS_DISCOUNT_REQUEST,
  _GET_EVENTS_DISCOUNT_SUCCESS,
  _GET_EVENTS_DISCOUNT_FAILURE
)<undefined, IEventsDiscountsResponse, Error>();

export const handleActivePage = createStandardAction(
  HANDLE_DISCOUNT_ACTIVE_PAGE
)<IHandleActivePagePayload>();

export const resetState = createStandardAction(_RESET_STATE)();

export const mounted = createStandardAction(MOUNTED)();

export const mountedEventsDiscounts = createStandardAction(MOUNTED_EVENTS)();

export const updateSearchText = createStandardAction(UPDATE_SEARCH_TEXT)<
  string
>();

export const updateSearchEventText = createStandardAction(
  UPDATE_SEARCH_EVENT_TEXT
)<string>();

export const setActiveFilter = createStandardAction(SET_ACTIVE_FILTER)<
  string
>();
