import React from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import MuiDatePicker from '@Compo/reusable/MuiDatePicker';
import { Colors } from '@Constants/Colors';
import { TypographyVariant } from '@Constants/Variants/Typography';
import translateDefaultPrice from '@Misc/helpers/strings/translateDefaultPrice';

import useStyles from './FormLayout.styles';
import { IFormLayoutProps } from './FormLayout.types';

const DEFAULT_PRICE_TYPE_VALUE = -1;

const FormLayout = ({
  handleSubmit,
  submitForm,
  values,
  touched,
  errors,
  activeDiscount,
  handleChange,
  happenings,
  setFieldValue,
  handleBlur,
  closeModal,
  partners,
  priceTypes,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Discounts.components.FormLayout',
  });
  const classes = useStyles();

  const isEditing = activeDiscount && activeDiscount.code;
  const TITLE_TEXT = isEditing ? t('editTitle') : t('addTitle');

  const getTransactionLimit = () => {
    if (isEditing) {
      return values.transactionsLimit || '';
    } else if (typeof values.transactionsLimit !== 'undefined') {
      return values.transactionsLimit;
    }
    return 1;
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogTitle>{TITLE_TEXT}</DialogTitle>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={3}>
            {values.id ? (
              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <Grid container={true}>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('id')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography variant={TypographyVariant.body1}>
                        {values.id}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container={true}>
                    <Grid item={true} xs={6}>
                      <Typography
                        variant={TypographyVariant.body1}
                        color={Colors.textSecondary}
                      >
                        {t('code')}
                      </Typography>
                    </Grid>
                    <Grid item={true} xs={6}>
                      <Typography variant={TypographyVariant.body1}>
                        {values.code}
                      </Typography>
                    </Grid>
                  </Grid>
                </LabelContentContainer>
              </Grid>
            ) : (
              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <TextField
                    id="code"
                    label={(touched.code && errors.code) || t('discountCode')}
                    helperText={t('discountCodeHelper')}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.code}
                    name="code"
                    error={!!(touched.code && errors.code)}
                    fullWidth={true}
                  />
                </LabelContentContainer>
              </Grid>
            )}
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <FormControl variant="standard" fullWidth={true}>
                  <InputLabel>
                    {(touched.happeningId && errors.happeningId) ||
                      t('happening')}
                  </InputLabel>
                  <Select
                    value={values.happeningId || -1}
                    onChange={handleChange}
                    defaultValue={-1}
                    fullWidth={true}
                    name="happeningId"
                    onBlur={handleBlur}
                  >
                    <MenuItem value={-1}>{t('selectHappening')}</MenuItem>
                    {happenings.map((happening) => (
                      <MenuItem value={happening.id} key={happening.id}>
                        {happening.metadata.title}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{t('happeningHelper')}</FormHelperText>
                </FormControl>
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <FormControl variant="standard" fullWidth={true}>
                  <InputLabel>
                    {(touched.partnerId && errors.partnerId) || t('partner')}
                  </InputLabel>
                  <Select
                    value={values.partnerId || -1}
                    onChange={handleChange}
                    defaultValue={-1}
                    fullWidth={true}
                    name="partnerId"
                    onBlur={handleBlur}
                  >
                    <MenuItem value={-1}>-</MenuItem>
                    {partners &&
                      partners.map((partner) => (
                        <MenuItem key={partner.id} value={partner.id}>
                          {partner.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>{t('partnerHelper')}</FormHelperText>
                </FormControl>
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <FormControl variant="standard" fullWidth={true}>
                  <InputLabel>
                    {(touched.priceTypeId && errors.priceTypeId) ||
                      t('priceType')}
                  </InputLabel>
                  <Select
                    value={values.priceTypeId || DEFAULT_PRICE_TYPE_VALUE}
                    onChange={handleChange}
                    defaultValue={DEFAULT_PRICE_TYPE_VALUE}
                    fullWidth={true}
                    name="priceTypeId"
                    onBlur={handleBlur}
                  >
                    <MenuItem value={DEFAULT_PRICE_TYPE_VALUE}>
                      {t('selectPriceType')}
                    </MenuItem>
                    {priceTypes.map((price) => (
                      <MenuItem value={price.id} key={price.id}>
                        {translateDefaultPrice(price.title)}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{t('priceTypeHelper')}</FormHelperText>
                </FormControl>
              </LabelContentContainer>
            </Grid>

            <Grid item={true} xs={12}>
              <Grid container={true} spacing={4}>
                <Grid item={true} xs={12} sm={6}>
                  <LabelContentContainer>
                    <FormControl variant="standard" fullWidth={true}>
                      <InputLabel>
                        {(touched.isPercentage && errors.isPercentage) ||
                          t('discountType')}
                      </InputLabel>
                      <Select
                        value={values.isPercentage}
                        onChange={handleChange}
                        defaultValue={0}
                        fullWidth={true}
                        name="isPercentage"
                        onBlur={handleBlur}
                      >
                        <MenuItem value={0}>{t('valuePrice')}</MenuItem>
                        <MenuItem value={1}>{t('percent')}</MenuItem>
                      </Select>
                      <FormHelperText>{t('discountTypeHelper')}</FormHelperText>
                    </FormControl>
                  </LabelContentContainer>
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <LabelContentContainer>
                    <TextField
                      id="value"
                      label={
                        (touched.value && errors.value) || t('discountValue')
                      }
                      helperText={t('discountValueHelper')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.value}
                      name="value"
                      error={!!(touched.value && errors.value)}
                      fullWidth={true}
                    />
                  </LabelContentContainer>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} xs={12}>
              <Grid container={true} spacing={4}>
                <Grid item={true} xs={12} sm={6}>
                  <LabelContentContainer>
                    <MuiDatePicker
                      name="startDate"
                      disableToolbar={true}
                      fullWidth={true}
                      variant="standard"
                      format="DD.MM.yyyy"
                      label={
                        (touched.startDate && errors.startDate) ||
                        t('dateStart')
                      }
                      helperText={t('dateStartHelper')}
                      value={values.startDate}
                      onChange={(date) => setFieldValue('startDate', date)}
                    />
                  </LabelContentContainer>
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <LabelContentContainer>
                    <MuiDatePicker
                      name="endDate"
                      disableToolbar={true}
                      fullWidth={true}
                      variant="standard"
                      format="DD.MM.yyyy"
                      label={
                        (touched.endDate && errors.endDate) || t('dateEnd')
                      }
                      helperText={t('dateEndHelper')}
                      value={values.endDate}
                      onChange={(date) => setFieldValue('endDate', date)}
                    />
                  </LabelContentContainer>
                </Grid>
              </Grid>
            </Grid>

            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <TextField
                  id="transactionsLimit"
                  label={
                    (touched.transactionsLimit && errors.transactionsLimit) ||
                    t('transactionLimit')
                  }
                  helperText={t('transactionLimitHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={getTransactionLimit()}
                  name="transactionsLimit"
                  error={
                    !!(touched.transactionsLimit && errors.transactionsLimit)
                  }
                  fullWidth={true}
                />
              </LabelContentContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={closeModal}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('saveDiscount')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
