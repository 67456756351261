import _Store from '@Store';
import { connect } from 'react-redux';

import { calculateMixPayment } from '@Model/reservation/selectors';
import MixPaymentInDetails from './MixPaymentInDetails.component';
import { IMixPaymentInDetailsFromState } from './MixPaymentInDetails.types';

const mapStateToProps = (
  state: _Store.IState
): IMixPaymentInDetailsFromState => {
  return {
    mixPayment: calculateMixPayment(state),
  };
};

export default connect<IMixPaymentInDetailsFromState, null, {}, _Store.IState>(
  mapStateToProps
)(MixPaymentInDetails);
