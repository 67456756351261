import { getType } from 'typesafe-actions';

import { IOldSubmission } from '@Services/$formio-api/types';
import { addOldUser, resetOldUserData } from '../actions';
import { IAction } from '../types';

const initialState: IOldSubmission | null = null;

const oldDataUserReducer = (
  state: IOldSubmission | null = initialState,
  action: IAction
): IOldSubmission | null => {
  switch (action.type) {
    case getType(addOldUser):
      return action.payload;
    case getType(resetOldUserData):
      return initialState;
    default:
      return state;
  }
};

export default oldDataUserReducer;
