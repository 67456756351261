import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';

import { Formik } from 'formik';

import FormLayout from './FormLayout';
import { PriceRuleSchema } from './PriceRule.validation';

import { IPriceRuleProps, IPriceRuleRef } from './PriceRule.types';

const PriceRule = forwardRef<IPriceRuleRef, IPriceRuleProps>((props, ref) => {
  const formRef = useRef<any>();

  useEffect(() => {
    props.mounted();
  }, []);

  const { initialValues, setStepSlug, setFormRulesData, partnerId } = props;

  useImperativeHandle(ref, () => ({
    submitForm: (slug) => {
      if (formRef.current) {
        formRef.current.validateForm().then((res: any) => {
          const fields = Object.keys(res);
          fields.forEach((field) =>
            formRef.current?.setFieldTouched(field, true)
          );

          if (Object.keys(res).length === 0) {
            if (formRef.current.state.values.prices.length > 0) {
              setFormRulesData({
                ...formRef.current.state.values,
                partnerId,
                prices: formRef.current.state.values.prices,
              });
              setStepSlug(slug);
            }
          }
        });
      }
    },
  }));

  const catchHandleSubmit = () => {};

  return (
    <Formik
      ref={formRef}
      initialValues={initialValues}
      onSubmit={catchHandleSubmit}
      validationSchema={PriceRuleSchema}
      children={(childProps) => (
        <FormLayout priceTypes={props.priceTypes} {...childProps} />
      )}
    />
  );
});

export default PriceRule;
