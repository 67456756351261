import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 4,

    '&:hover': {
      textDecoration: 'initial',
    },
  },
  item: {
    backgroundColor: theme.palette.background.paper,
    marginBottom: 1,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  loading: {
    height: '1em!important',
    width: '1em!important',
  },
  tableHeader: {
    paddingBottom: 28,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 28,
  },
}));

export default useStyles;
