export const languagePl = {
  actions: {
    clear_all: 'Wyczyść wszystko',
    log_out: 'Wyloguj się',
    next: 'Dalej',
    upload: 'Prześlij',
  },
  back: 'Wróć',
  crop: {
    close_btn: 'Tak',
    close_prompt: 'Zamknięcie anuluje wszystkie przesyłanie. Czy na pewno?',
    corner_tooltip: 'Przeciągnij róg, aby zmienić rozmiar',
    crop_btn: 'Przytnij',
    handle_tooltip: 'Przeciągnij uchwyt, aby zmienić rozmiar',
    image_error: 'Błąd ładowania obrazu',
    reset_btn: 'Resetuj',
    skip_btn: 'Prześlij',
    title: 'Przytnij',
  },
  dropbox: {
    authenticating: 'Uwierzytelnianie...',
    list_headers: {
      modified: 'Zmodyfikowane',
      name: 'Nazwa',
      select: 'Wybierz',
    },
    menu: {
      browse: 'Przeglądaj',
      recent: 'Najnowsze',
    },
    no_auth_action: 'Połącz z Dropbox',
    no_auth_title: 'Prześlij pliki ze swojego konta Dropbox.',
    no_files: 'Brak plików',
    no_photos: 'Brak zdjęć',
  },
  facebook: {
    album_subtitle: '{{count}} zdjęć',
    menu: {
      albums: 'Albumy',
      tagged: 'Twoje zdjęcia',
      uploaded: 'Twoje zdjęcia',
    },
    no_auth_action: "Połącz z Facebook'iem",
    no_auth_statement: 'Nie opublikujemy niczego bez Twojej zgody.',
    no_auth_title: 'Prześlij pliki ze swojego konta na Facebooku.',
    no_photos: 'Brak zdjęć...',
  },
  google_drive: {
    grid: {
      empty_folder: 'Ten folder jest pusty.',
      files: 'Pliki',
      folders: 'Foldery',
    },
    my_drive: 'Mój dysk',
    name: 'Nazwa',
    no_auth_action: 'Połącz z Dyskiem Google',
    no_auth_title: 'Prześlij pliki ze swojego Dysku Google.',
    notifications: {
      retrieve_failed: 'Nie udało się pobrać danych z Dysku Google.',
    },
    recent: 'Najnowsze',
    search: {
      placeholder: 'Szukaj...',
      reset: 'Zresetuj wyszukiwanie',
    },
    search_results: 'Wyniki wyszukiwania',
    seenByMeTime: 'Ostatnio otwarte przeze mnie',
    shared_drive: 'Dyski współdzielone',
    shared_with_me: 'Udostępnione dla mnie',
    starred: 'Oznaczone gwiazdką',
    updatedByMeTime: 'Ostatnio modyfikowane przeze mnie',
    updatedTime: 'Ostatnia modyfikacja',
  },
  local: {
    browse: 'Przeglądaj',
    dd_title_single: 'Przeciągnij i upuść grafikę w rozmiarze 810px x 700px',
  },
  menu: {
    files: 'Moje pliki',
    web: 'Adres internetowy',
  },
  or: 'Lub',
  queue: {
    abort_all: 'Przerwij wszystko',
    done: 'Gotowe',
    mini_failed: '{{num}} nie powiodło się',
    mini_title: 'Przesłane',
    mini_title_uploading: 'Przesyłanie',
    mini_upload_count: 'Przesłano {{num}}',
    retry_failed: 'Ponów przesyłanie błędnych',
    show_completed: 'Pokaż zakończone',
    statuses: {
      aborted: 'Przerwane',
      error: 'Błąd',
      uploaded: 'Gotowe',
      uploading: 'Przesyłanie...',
    },
    title: 'Kolejka przesyłania',
    title_uploading: 'Przesyłanie zasobów',
    title_uploading_with_counter: 'Przesyłanie {{num}} zasobów',
    upload_more: 'Prześlij więcej',
  },
  selection_counter: {
    selected: 'Wybrane',
  },
  url: {
    inner_title: 'Publiczny adres URL pliku do przesłania:',
  },
};
