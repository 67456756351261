import { getType } from 'typesafe-actions';
import { checkReservation, resetCheck } from './../actions';
import { IAction, IReservationCheckReducer } from './../types';

const initialState: IReservationCheckReducer = {
  availability: null,
  price: null,
};

const checkReducer = (
  state: IReservationCheckReducer = initialState,
  action: IAction,
): IReservationCheckReducer => {
  switch (action.type) {
    case getType(checkReservation.success):
      return action.payload;
    case getType(resetCheck):
      return initialState;
    default:
      return state;
  }
};

export default checkReducer;
