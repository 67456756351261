import {
  getSpacesOfSelectedHappening,
  getTimeSlots,
} from '@Model/reservation/selectors';
import _Store from '@Store';
import { connect } from 'react-redux';
import TimeSlots from './SpacesSlots.component';
import { ITimeSlotsFromState } from './SpacesSlots.types';

const mapStateToProps = (state: _Store.IState): ITimeSlotsFromState => {
  return {
    spaces: getSpacesOfSelectedHappening(state),
  };
};

export default connect<ITimeSlotsFromState, null, {}, _Store.IState>(
  mapStateToProps,
)(TimeSlots);
