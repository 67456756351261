import React, { useEffect } from 'react';

import { Grid, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';

import { IFormLayoutProps } from './FormLayout';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5rem',
  },
}));

const PaymentMixStepper = ({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
  setShowBasket,
  totalPrice,
  isTransactionDetailsLoading,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Add.components.FormLayout.PaymentMix',
  });

  const classes = useStyles();
  useEffect(() => {
    setShowBasket(false);

    return () => {
      setShowBasket(true);
    };
  }, []);

  if (isTransactionDetailsLoading) {
    return (
      <div className={classes.loader}>
        <Loader />
      </div>
    );
  }

  const getTotalPrice = () => {
    if (errors.paymentAdvanceAmount) {
      return totalPrice?.toFixed(2);
    }

    return ((totalPrice || 0) - (values.paymentAdvanceAmount || 0)).toFixed(2);
  };

  return (
    <Grid container={true} className={classes.container}>
      <Grid item={true} xs={12}>
        <Grid container={true} direction="column" spacing={2}>
          <Grid item={true} xs={12}>
            <Typography variant="body1" color="textSecondary">
              {t('payment')}
            </Typography>
          </Grid>
          <Grid item={true}>
            <Grid container={true} spacing={4}>
              <Grid item={true} xs={12} md={6}>
                <TextField
                  label={t('advanceAmount')}
                  helperText={
                    (touched.paymentAdvanceAmount &&
                      errors.paymentAdvanceAmount) ||
                    t('advanceAmountHelper')
                  }
                  onBlur={handleBlur}
                  onChange={handleChange}
                  name="paymentAdvanceAmount"
                  value={values.paymentAdvanceAmount || 0}
                  type="number"
                  fullWidth={true}
                  inputProps={{ step: 0.01 }}
                  error={
                    !!(
                      touched.paymentAdvanceAmount &&
                      errors.paymentAdvanceAmount
                    )
                  }
                  data-testid="paymentMixInput"
                />
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <Typography variant="body1" color="textPrimary">
                  {t('leftToPay')}
                </Typography>
                <Typography variant="h6" color="primary">
                  {getTotalPrice()} {t('localCurrency')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentMixStepper;
