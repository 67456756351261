import { connect } from 'react-redux';

import { getPriceTypes } from '@Model/priceTypes/selectors';
import { _Store } from '@Store';
import FormLayout from './FormLayout.component';
import { IFormLayoutFromState } from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => ({
  priceTypes: getPriceTypes(state),
});

export default connect<IFormLayoutFromState, {}, {}, _Store.IState>(
  mapStateToProps
)(FormLayout);
