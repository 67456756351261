import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { ISeatsIoReportResponse } from '@Services/$seats-io-api/types';

import { IReportSeatsIoBody } from '../types';
import {
  _GET_SEATS_IO_REPORT_FAILURE,
  _GET_SEATS_IO_REPORT_REQUEST,
  _GET_SEATS_IO_REPORT_SUCCESS,
  GENERATE_PDF,
  MOUNTED,
} from './../constants/actions';

export const mounted = createStandardAction(MOUNTED)();

export const getSeatsIoReport = createAsyncAction(
  _GET_SEATS_IO_REPORT_REQUEST,
  _GET_SEATS_IO_REPORT_SUCCESS,
  _GET_SEATS_IO_REPORT_FAILURE
)<IReportSeatsIoBody, ISeatsIoReportResponse[], Error>();

export const generatePdf =
  createStandardAction(GENERATE_PDF)<IReportSeatsIoBody>();
