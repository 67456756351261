import { transactionReset, transactionSave } from '@Model/reservation/actions';
import { getType } from 'typesafe-actions';
import { IAction, ITransactionReducer } from '../types';

const initialState: ITransactionReducer = {
  expired: false,
  failed: false,
  payment: null,
};

const transactionReducer = (
  state: ITransactionReducer = initialState,
  action: IAction,
): ITransactionReducer => {
  switch (action.type) {
    case getType(transactionSave):
      return {
        expired: false,
        failed: false,
        payment: action.payload,
      };

    case getType(transactionReset):
      return initialState;

    default:
      return state;
  }
};

export default transactionReducer;
