import { TFunction } from 'i18next';

const payment = {
  checking: '/podsumowanie-sprawdz',
  summaryCanceled: '/podsumowanie/:id/transakcja-anulowana',
  summaryFail: '/podsumowanie/:id/blad-platnosci',
  summarySuccess: '/podsumowanie/:id',
};

export const routes = {
  ...payment,
  addEvent: '/dodaj-wydarzenie',
  addHappening: '/dodaj-zasob',
  addReservation: '/dodaj-rezerwacje',
  addRundate: '/dodaj-rundate/:eventId',
  admin: '/administracja',
  calendar: '/kalendarz',
  calendarPopUp: '/kalendarz/:id',
  consentsList: '/lista-zgod',
  discounts: '/kody-rabatowe',
  editHappening: '/edytuj-zasob/:slug',
  findTickets: '/find-tickets',
  happenings: '/zasoby',
  index: '/',
  login: '/login',
  mail: '/skrzynka-nadawcza',
  newRules: '/newRules',
  poolDescriptions: '/opisy-pul',
  priceTypes: '/typy-biletow',
  products: '/produkty',
  refundsRegistration: '/zwroty-biletow',
  reportBug: '/zglos-problem',
  reports: '/reports',
  rules: '/rules',
  rundateEdit: '/edytuj-wydarzenie/:id',
  rundateSeatsIoReport: '/raport-seats/:id/:poolId?',
  rundates: '/wydarzenia',
  settings: '/ustawienia',
  settlements: '/rozliczenia',
  start: '/start',
  statics: '/statystyka',
  tickets: '/tickets',
  transactions: '/transakcje',
  transactionsDelayed: '/transakcje-opoznione',
  users: '/zarzadzanie-uzytkownikami',
};

export const routesTitle = (t: TFunction) => ({
  addEvent: t('components:routes.addEvent'),
  addHappening: t('components:routes.addHappening'),
  addReservation: t('components:routes.addReservation'),
  addRundate: t('components:routes.addRundate'),
  admin: t('components:routes.admin'),
  calendar: t('components:routes.calendar'),
  discounts: t('components:routes.discounts'),
  editHappening: t('components:routes.editHappening'),
  findTickets: t('components:routes.findTickets'),
  happenings: t('components:routes.happenings'),
  index: t('components:routes.index'),
  mail: t('components:routes.mail'),
  newRules: t('components:routes.newRules'),
  poolDescriptions: t('components:routes.poolDescriptions'),
  priceTypes: t('components:routes.priceTypes'),
  products: t('components:routes.products'),
  refundsRegistration: t('components:routes.refundsRegistration'),
  reportBug: t('components:routes.reportBug'),
  reports: t('components:routes.reports'),
  rules: t('components:routes.rules'),
  rundateEdit: t('components:routes.rundateEdit'),
  rundateSeatsIoReport: t('components:routes.rundateSeatsIoReport'),
  rundates: t('components:routes.rundates'),
  settings: t('components:routes.settings'),
  settlements: t('components:routes.settlements'),
  statics: t('components:routes.statics'),
  summaryCanceled: t('components:routes.summaryCanceled'),
  summaryFail: t('components:routes.summaryFail'),
  summarySuccess: t('components:routes.summarySuccess'),
  tickets: t('components:routes.tickets'),
  transactions: t('components:routes.transactions'),
  transactionsDelayed: t('components:routes.transactionsDelayed'),
  users: t('components:routes.users'),
});

export default routes;
