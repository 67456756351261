import { printReport } from '@Model/printer/actions';
import getPrinters from '@Model/printer/selectors/getPrinters';
import _Store from '@Store';
import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';
import PrinterTable from './PrinterTable.component';
import {
  IPrinterTableFromDispatch,
  IPrinterTableFromState,
} from './PrinterTable.types';

const mapStateToProps = (state: _Store.IState): IPrinterTableFromState => {
  return {
    Printers: getPrinters(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>,
): IPrinterTableFromDispatch => {
  return {
    printReport: (Printer) => {
      dispatch(printReport(Printer));
    },
  };
};

export default connect<
  IPrinterTableFromState,
  IPrinterTableFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps,
)(PrinterTable);
