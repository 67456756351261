import _Store from '@Store';
import { createSelector } from 'reselect';
import { IReservationAddReducer } from './../types';
import getAdd from './getAdd';

const isHappeningSelected = createSelector<
  _Store.IState,
  IReservationAddReducer,
  boolean
>(
  getAdd,
  (add) => add.happeningId !== null,
);

export default isHappeningSelected;
