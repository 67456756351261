import { createSelector } from 'reselect';

import _Store from '@Store';

import { IUsersReducer } from '../types';
import get from './get';

const getActiveFilter = createSelector<
  _Store.IState,
  IUsersReducer,
  string | undefined
>([get], (users) => users.activeFilter);

export default getActiveFilter;
