import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

// App reducers
import appReducer from '@Model/app/reducer';
import authorizationReducer from '@Model/authorization/reducer';
import configurationsReducer from '@Model/configurations/reducer';
import discountReducer from '@Model/discount/reducer';
import discountsReducer from '@Model/discounts/reducer';
import eventsReducer from '@Model/events/reducer';
import formIoReducer from '@Model/formio/reducer';
import formRulesReducer from '@Model/formRules/reducer';
import goingPlacesReducer from '@Model/going/places/reducer';
import reportSeatsIoReducer from '@Model/going/reportSeatsIo/reducer';
import goingRundatesReducer from '@Model/going/rundates/reducer';
import happeningReducer from '@Model/happening/reducer';
import happeningsReducer from '@Model/happenings/reducer';
import mailingReducer from '@Model/mailing/reducer';
import metadataReducer from '@Model/metadata/reducer';
import modalReducer from '@Model/modal/reducer';
import poolDescriptionsReducer from '@Model/poolDescriptions/reducer';
import priceReducer from '@Model/price/reducer';
import ruleTypesReducer from '@Model/priceTypes/reducer';
import printerReducer from '@Model/printer/reducer';
import productsReducer from '@Model/products/reducer';
import refundsRegistrationReducer from '@Model/refundsRegistration/reducer';
import reportsReducer from '@Model/reports/reducer';
import reservationReducer from '@Model/reservation/reducer';
import reservationsReducer from '@Model/reservations/reducer';
import seatsIoReducer from '@Model/seatsIo/reducer';
import settingsReducer from '@Model/settings/reducer';
import settlementsReducer from '@Model/settlements/reducer';
import smartporterReducer from '@Model/smartporter/reducer';
import stateReducer from '@Model/state/reducer';
import staticsReducer from '@Model/statics/reducer';
import ticketsReducer from '@Model/tickets/reducer';
import toastsReducer from '@Model/toasts/reducer';
import transactionsReducer from '@Model/transactions/reducer';
import userReducer from '@Model/user/reducer';
import usersReducer from '@Model/users/reducer';

import history from './history';

const rootReducer = combineReducers({
  // Router
  router: connectRouter(history),

  // App reducers
  app: appReducer,
  authorization: authorizationReducer,
  configurations: configurationsReducer,
  discount: discountReducer,
  discounts: discountsReducer,
  events: eventsReducer,
  formIo: formIoReducer,
  formRules: formRulesReducer,
  goingRundates: goingRundatesReducer,
  happening: happeningReducer,
  happenings: happeningsReducer,
  mailing: mailingReducer,
  metadata: metadataReducer,
  modal: modalReducer,
  places: goingPlacesReducer,
  poolDescriptions: poolDescriptionsReducer,
  price: priceReducer,
  printer: printerReducer,
  products: productsReducer,
  refundsRegistration: refundsRegistrationReducer,
  reportSeatsIo: reportSeatsIoReducer,
  reports: reportsReducer,
  reservation: reservationReducer,
  reservations: reservationsReducer,
  ruleTypes: ruleTypesReducer,
  seatsIo: seatsIoReducer,
  settings: settingsReducer,
  settlements: settlementsReducer,
  smartporter: smartporterReducer,
  state: stateReducer,
  statics: staticsReducer,
  tickets: ticketsReducer,
  toasts: toastsReducer,
  transactions: transactionsReducer,
  user: userReducer,
  users: usersReducer,
});

export default rootReducer;
