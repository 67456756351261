import { getType } from 'typesafe-actions';

import {
  addDiscount,
  checkDiscountCode,
  closeEventAddDiscount,
  editDiscount,
  eventAddDiscount,
  getEventsPartner,
  getPartners,
  getPoolsPartner,
  getRundatesPartner,
  handleActivePage,
  resetState,
  resetValue,
} from '../actions';
import { IAction, IDiscountReducer } from '../types';

const initialState: IDiscountReducer = {
  activeDiscount: null,
  activeEventDiscount: null,
  events: [],
  meta: {
    page: 0,
    size: 0,
    total: 0,
  },
  partners: [],
  pools: [],
  rundates: [],
  searchText: '',
  showModal: false,
  value: null,
};

const discountReducer = (
  state: IDiscountReducer = initialState,
  action: IAction
): IDiscountReducer => {
  switch (action.type) {
    case getType(editDiscount):
      return {
        ...state,
        activeDiscount: action.payload,
        showModal: true,
      };

    case getType(addDiscount):
      return {
        ...state,
        activeDiscount: null,
        showModal: true,
      };

    case getType(eventAddDiscount):
      return {
        ...state,
        activeEventDiscount: action.payload,
      };
    case getType(closeEventAddDiscount):
      return {
        ...state,
        activeEventDiscount: null,
        meta: initialState.meta,
      };

    case getType(checkDiscountCode.success):
      return {
        ...state,
        value: action.payload,
      };

    case getType(resetValue):
      return {
        ...state,
        value: null,
      };

    case getType(getPartners.success):
      return {
        ...state,
        meta: action.payload.meta,
        partners: action.payload.data,
      };

    case getType(getEventsPartner.success):
      return {
        ...state,
        events: action.payload.data,
        meta: action.payload.meta,
      };

    case getType(getRundatesPartner.success):
      return {
        ...state,
        meta: action.payload.meta,
        rundates: action.payload.data,
      };

    case getType(getPoolsPartner.success):
      return {
        ...state,
        meta: action.payload.meta,
        pools: action.payload.data,
      };
    case getType(handleActivePage):
      return {
        ...state,
        meta: {
          ...state.meta,
          page: action.payload.page,
        },
        searchText: action.payload.searchText,
      };

    case getType(resetState):
      return initialState;
    default:
      return state;
  }
};

export default discountReducer;
