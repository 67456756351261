import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { get } from '@Model/authorization/selectors';
import {
  catchDeleteFilter,
  changeFilterPage,
  handleActiveFilter,
  mounted,
} from '@Model/settings/actions';
import { getFilters } from '@Model/settings/selectors';
import { allPermissions, SETTINGS } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';

import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    isLoading: getLoading(SETTINGS)(state),
    partnerFilters: getFilters(state),
    permissionPartnerRead: permissions.includes(
      allPermissions.access_partner_read
    ),
    permissionPartnerWrite: permissions.includes(
      allPermissions.access_partner_write
    ),
    permissionPixelRead: permissions.includes(allPermissions.access_pixel_read),
    permissionPixelWrite: permissions.includes(
      allPermissions.access_pixel_write
    ),
    permissionSettlements: permissions.includes(
      allPermissions.access_settlements_filters_read
    ),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormLayoutFromDispatch => ({
  changePageFilter: (page) => dispatch(changeFilterPage(page)),
  deleteReportFilter: (id) => dispatch(catchDeleteFilter(id)),
  handleActiveReportFilter: (filter) => dispatch(handleActiveFilter(filter)),
  mounted: () => dispatch(mounted()),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
