import React from 'react';

import { Dialog, DialogTitle, Grid, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { getInitialValues } from './AddNewPriceTypeModal.helpers';
import {
  IAddNewPriceTypeModalProps,
  IFormValuesProps,
} from './AddNewPriceTypeModal.types';
import { AddingPriceTypeSchema } from './AddNewPriceTypeModal.validation';
import FormLayout from './FormLayout';

const AddNewPriceTypeModal = ({
  open,
  closeModal,
  saveType,
  data,
}: IAddNewPriceTypeModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'BetterManager.NewDefinedConfigurations.components.PriceRule.AddNewPriceTypeModal',
  });

  const initialValues: IFormValuesProps = getInitialValues(data);

  const catchHandleSubmit = (values: IFormValuesProps) => {
    saveType(values);
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <DialogTitle>
        <Grid container={true} justifyContent="space-between">
          <Grid item={true}>
            <Typography variant="h6">{t('title')}</Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={catchHandleSubmit}
        validationSchema={AddingPriceTypeSchema}
        children={(propsFormik) => (
          <FormLayout {...propsFormik} closeModal={closeModal} />
        )}
      />
    </Dialog>
  );
};

export default AddNewPriceTypeModal;
