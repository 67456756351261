import groupBy from '@Misc/helpers/groupBy';
import { IUser } from '@Services/$users-api/types';

import {
  CUSTOM_ACCESS_ID,
  DEFAULT_ACCESS_ID,
  OTHER_PARTNER_ID,
} from './../FormLayout/Access/Access.component';
import { IUsersModalValues } from './UsersModal.types';

export const prepareInitialValues = (
  configuration: IUser | null
): IUsersModalValues => {
  let defaultValues: IUsersModalValues = {
    access: { [OTHER_PARTNER_ID]: [DEFAULT_ACCESS_ID] },
    cmsPartners: [],
    email: '',
    firstName: '',
    groups: [],
    individualPermissionsIds: [],
    lastName: '',
    password: '',
    repeatPassword: '',
  };

  if (configuration) {
    const {
      groups,
      partners,
      email,
      firstName,
      lastName,
      id,
      individualPermissions,
    } = configuration;
    const groupByPartner = Object.fromEntries(
      groupBy(groups, (group) => group.cmsPartnerId)
    );
    const accessByPartner: { [key: string]: number[] } = {};
    partners.forEach((partner) => {
      accessByPartner[partner.id] = [-1];
    });

    Object.keys(groupByPartner).forEach((key) => {
      accessByPartner[key] = groupByPartner[key].map((group) => group.id);
    });

    defaultValues = {
      access: groups.length
        ? { ...accessByPartner, ...defaultValues.access }
        : { ...accessByPartner, [OTHER_PARTNER_ID]: [DEFAULT_ACCESS_ID] },
      cmsPartners: partners,
      email,
      firstName,
      groups,
      id,
      individualPermissionsIds: individualPermissions.map(
        (permission) => permission.id
      ),
      lastName,
    };

    if (individualPermissions.length) {
      defaultValues.access = groups.length
        ? { ...defaultValues.access, [OTHER_PARTNER_ID]: [CUSTOM_ACCESS_ID] }
        : { ...accessByPartner, [OTHER_PARTNER_ID]: [CUSTOM_ACCESS_ID] };
    }
  }

  return defaultValues;
};
