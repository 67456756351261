import { createSelector } from 'reselect';

import getSelectedConfigurationId from '@Model/happening/selectors/getSelectedConfigurationId';
import {
  IAvailabilitiesResponseSlot,
  IPriceTypeWithPrice,
} from '@Model/happening/types';
import { getPriceTypes } from '@Model/priceTypes/selectors';
import { IRuleType } from '@Model/priceTypes/types';
import _Store from '@Store';
import getPrices from './getPrices';

const getPriceTypesWithPrice = createSelector<
  _Store.IState,
  IRuleType[],
  IAvailabilitiesResponseSlot | null,
  string[],
  IPriceTypeWithPrice[]
>(
  [getPriceTypes, getSelectedConfigurationId, getPrices],
  (priceTypes, selectedConfiguration, prices) => {
    if (selectedConfiguration && selectedConfiguration.prices) {
      const priceTypesWithPrice = selectedConfiguration.prices
        .filter((price) => prices.includes(price.type))
        .map((selected) => {
          const priceType = priceTypes.find(
            (type) => type.type === selected.type
          );
          return { name: priceType?.title, price: selected.value };
        });

      return priceTypesWithPrice;
    }
    return [];
  }
);

export default getPriceTypesWithPrice;
