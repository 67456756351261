import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { handleSearch, selectEvent } from '@Model/events/actions';

import SearchField from './SearchField.component';
import {
  ISearchFieldFromDispatch,
  ISearchFieldOwnProps,
} from './SearchField.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISearchFieldFromDispatch => ({
  handleSearch: (text) => dispatch(handleSearch(text)),
  selectEvent: (event) => dispatch(selectEvent(event)),
});

export default connect<
  {},
  ISearchFieldFromDispatch,
  ISearchFieldOwnProps,
  _Store.IState
>(
  null,
  mapDispatchToProps
)(SearchField);
