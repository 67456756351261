import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import _Store from '@Store';

import { get } from '@Model/authorization/selectors';
import { deleteAssets } from '@Model/going/cloudinary/actions';
import { getSingleRundateData } from '@Model/going/rundates/selectors';
import { deleteMail, selectEventMail } from '@Model/mailing/actions';
import { allPermissions, MAILING } from '@Model/state/constants';
import { getLoading } from '@Model/state/selectors';

import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    isLoading: getLoading(MAILING)(state),
    permissionAssetsDelete: permissions.includes(
      allPermissions.access_cloudinary_delete
    ),
    permissionEventSend: permissions.includes(
      allPermissions.access_event_mailing_send
    ),
    permissionEventWrite: permissions.includes(
      allPermissions.access_event_mailing_write
    ),
    rundate: getSingleRundateData(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch): IFormLayoutFromDispatch => ({
  closeModal: () => dispatch(selectEventMail(null)),
  deleteAssets: (slug) => dispatch(deleteAssets(slug)),
  deleteMail: (id) => dispatch(deleteMail(id)),
});

export default connect<
  IFormLayoutFromState,
  IFormLayoutFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(FormLayout);
