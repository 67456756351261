import React from 'react';

import Grid from '@Compo/layout/Grid';
import TransactionsDelayed from '@Compo/TransactionsDelayed';

const StaticsPage = () => (
  <Grid>
    <TransactionsDelayed />
  </Grid>
);

export default StaticsPage;
