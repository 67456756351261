import { getType } from 'typesafe-actions';

import {
  getDelayedTransactions,
  handleActivePage,
  handleRowsPerPage,
  handleTransactionType,
} from '../actions';
import { IAction, IDelayedTransactionsReducer } from '../types';

const initialState: IDelayedTransactionsReducer = {
  items: null,
  page: 0,
  perPage: 10,
  selectedTransactionType: 'delay',
  totalCount: 0,
};

const delayedTransactionsReducer = (
  state: IDelayedTransactionsReducer = initialState,
  action: IAction
): IDelayedTransactionsReducer => {
  switch (action.type) {
    case getType(getDelayedTransactions.success):
      return {
        ...state,
        items: action.payload.data,
        totalCount: action.payload.meta.total,
      };
    case getType(handleActivePage):
      return {
        ...state,
        page: action.payload,
      };
    case getType(handleRowsPerPage):
      return {
        ...state,
        perPage: action.payload,
      };
    case getType(handleTransactionType):
      return {
        ...state,
        selectedTransactionType: action.payload,
      };
    default:
      return state;
  }
};

export default delayedTransactionsReducer;
