import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  _CLEAR_STATE,
  _GET_FAILURE,
  _GET_REQUEST,
  _GET_SUCCESS,
  _RESET_STATE,
  MOUNTED,
  REMOVE,
  SET_ACTIVE_FILTER,
  SET_PARTNER_ID,
} from './../constants/actions';
import {
  IGetHappeningsSuccessPayload,
  IHappeningRemovePayload,
} from './../types';

export const getHappenings = createAsyncAction(
  _GET_REQUEST,
  _GET_SUCCESS,
  _GET_FAILURE
)<undefined, IGetHappeningsSuccessPayload, Error>();

export const setPartnerID = createStandardAction(SET_PARTNER_ID)<string>();

export const resetState = createStandardAction(_RESET_STATE)();

export const clearState = createStandardAction(_CLEAR_STATE)();

export const mounted = createStandardAction(MOUNTED)();

export const remove = createStandardAction(REMOVE)<IHappeningRemovePayload>();
