import { getDiscounts, getEventsDiscounts } from '@Model/discounts/actions';

import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { resetError, resetLoading, setLoading } from '../actions';
import { DISCOUNTS } from '../constants/constants';

export const setUpStateWhenDiscountsFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([getDiscounts.request, getEventsDiscounts.request])),
    mergeMap$(() => [resetError(DISCOUNTS), setLoading(DISCOUNTS)])
  );
};

export const setLoaderWhenDiscountsFetchFinished: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          getDiscounts.success,
          getDiscounts.failure,
          getEventsDiscounts.success,
          getEventsDiscounts.failure,
        ],
        action
      )
    ),
    map$(() => resetLoading(DISCOUNTS))
  );
};

export const setErrorOnDiscountsFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf([getDiscounts.failure, getEventsDiscounts.failure])),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
