import { useEffect, useState } from 'react';

const useActiveElement = () => {
  const [active, setActive] = useState<HTMLInputElement | null>(null);
  const [lastActiveElement, setLastActiveElement] =
    useState<HTMLInputElement | null>(null);

  const [top, setTop] = useState(0);
  const [currentValue, setCurrentValue] = useState('');
  const [isNumber, setIsNumber] = useState(false);

  const handleFocusIn = () => {
    const element = document.activeElement as HTMLInputElement;
    if (
      element?.tagName === 'INPUT' &&
      (element?.type === 'text' ||
        element?.type === 'number' ||
        element?.type === 'password')
    ) {
      const bodyRect = document.body.getBoundingClientRect();
      const elemRect = element.getBoundingClientRect();
      const offset = elemRect.top - bodyRect.top;
      setTop(offset);
      setCurrentValue(element.value);
      setActive(element);
      setLastActiveElement(element);
      if (element?.type === 'number') {
        setIsNumber(true);
      }
    }
  };

  const handleFocusOut = () => {
    setActive(null);
    setTop(0);
    setCurrentValue('');
    setIsNumber(false);
  };

  useEffect(() => {
    document.addEventListener('focusin', handleFocusIn);
    document.addEventListener('focusout', handleFocusOut);
    return () => {
      document.removeEventListener('focusin', handleFocusIn);
      document.removeEventListener('focusout', handleFocusOut);
    };
  }, []);

  return {
    active,
    currentValue,
    isNumber,
    lastActiveElement,
    setLastActiveElement,
    top,
  };
};

export default useActiveElement;
