import React, { useEffect, useState } from 'react';

import {
  Button,
  ButtonGroup,
  DialogActions,
  DialogContent,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import { TypographyVariant } from '@Constants/Variants/Typography';

import useStyles from './FormLayout.styles';
import { IFilterTypes, IFormLayoutProps } from './FormLayout.types';

export const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Settings.ReportFilterModal.FormLayout',
  });

  const {
    handleSubmit,
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    submitForm,
    handleActiveReportFilter,
    reportParameters,
  } = props;

  const classes = useStyles();

  const [filterType, setFilterType] = useState<IFilterTypes>('event');

  const handleHappeningFilter = () => {
    setFilterType('event');
    setFieldValue('productId', undefined);
    setFieldValue('filterType', 'event');
  };

  const handleProductFilter = () => {
    setFilterType('product');
    setFieldValue('eventId', undefined);
    setFieldValue('filterType', 'product');
  };

  const catchHandleClose = () => {
    handleActiveReportFilter(null);
  };

  const isFilterEvent = filterType === 'event';

  useEffect(() => {
    if (values.productId) {
      setFilterType('product');
    }
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit} className={classes.form}>
        <DialogContent className={classes.content}>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12}>
              <Typography variant={TypographyVariant.body1}>
                {t('description')}
              </Typography>
            </Grid>
            <Grid item={true} xs={12}>
              <ButtonGroup
                color="primary"
                variant="outlined"
                size="medium"
                fullWidth={true}
              >
                <Button
                  className={cn(isFilterEvent && classes.active)}
                  onClick={handleHappeningFilter}
                >
                  {t('happeningFilter')}
                </Button>
                <Button
                  className={cn(!isFilterEvent && classes.active)}
                  onClick={handleProductFilter}
                >
                  {t('productFilter')}
                </Button>
              </ButtonGroup>
            </Grid>
            {isFilterEvent && (
              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <FormControl variant="standard" fullWidth={true}>
                    <FormLabel>
                      {(touched.eventId && errors.eventId) ||
                        t('happeningSelect')}
                    </FormLabel>
                    <Select
                      value={values.eventId}
                      onChange={handleChange}
                      defaultValue={0}
                      fullWidth={true}
                      name="eventId"
                      onBlur={handleBlur}
                      error={!!(touched.eventId && errors.eventId)}
                    >
                      {reportParameters?.events.map((event) => (
                        <MenuItem key={event.id} value={event.id}>
                          {event.titlePL}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </LabelContentContainer>
              </Grid>
            )}
            {!isFilterEvent && (
              <Grid item={true} xs={12}>
                <LabelContentContainer>
                  <FormControl variant="standard" fullWidth={true}>
                    <FormLabel>
                      {(touched.productId && errors.productId) ||
                        t('productSelect')}
                    </FormLabel>
                    <Select
                      value={values.productId}
                      onChange={handleChange}
                      defaultValue={0}
                      fullWidth={true}
                      name="productId"
                      onBlur={handleBlur}
                      error={!!(touched.productId && errors.productId)}
                    >
                      {reportParameters?.products.map((product) => (
                        <MenuItem key={product.id} value={product.id}>
                          {product.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </LabelContentContainer>
              </Grid>
            )}
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <FormControl variant="standard" fullWidth={true}>
                  <FormLabel>
                    {(touched.salesChannelId && errors.salesChannelId) ||
                      t('salesChannelSelect')}
                  </FormLabel>
                  <Select
                    value={values.salesChannelId}
                    onChange={handleChange}
                    defaultValue={0}
                    fullWidth={true}
                    name="salesChannelId"
                    onBlur={handleBlur}
                    error={!!(touched.productId && errors.productId)}
                  >
                    {reportParameters?.salesChannels.map((salesChannel) => (
                      <MenuItem key={salesChannel.id} value={salesChannel.id}>
                        {salesChannel.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <FormControl variant="standard" fullWidth={true}>
                  <FormLabel>
                    {(touched.agentId && errors.agentId) || t('agentSelect')}
                  </FormLabel>
                  <Select
                    value={values.agentId}
                    onChange={handleChange}
                    defaultValue={0}
                    fullWidth={true}
                    name="agentId"
                    onBlur={handleBlur}
                    error={!!(touched.agentId && errors.agentId)}
                  >
                    {reportParameters?.agents.map((agent) => (
                      <MenuItem key={agent.id} value={agent.id}>
                        {agent.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </LabelContentContainer>
            </Grid>
            <Grid item={true} xs={12}>
              <LabelContentContainer>
                <FormControl variant="standard" fullWidth={true}>
                  <FormLabel>
                    {(touched.type && errors.type) || t('typeSelect')}
                  </FormLabel>
                  <Select
                    value={values.type}
                    onChange={handleChange}
                    fullWidth={true}
                    name="type"
                    onBlur={handleBlur}
                    error={!!(touched.type && errors.type)}
                  >
                    {reportParameters?.types.map((type) => (
                      <MenuItem key={type.code} value={type.code}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </LabelContentContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </form>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          color="secondary"
          onClick={catchHandleClose}
        >
          {t('cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          size="large"
          color="primary"
          onClick={submitForm}
        >
          {t('add')}
        </Button>
      </DialogActions>
    </>
  );
};

export default FormLayout;
