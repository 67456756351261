import makeStyles from '@mui/styles/makeStyles';

import colors from '@Compo/App/colors';

const useStyles = makeStyles((theme) => ({
  actions: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  buttonsCell: {
    paddingBottom: theme.spacing(1.25),
    paddingTop: theme.spacing(1.25),
  },
  disableLastElementBorder: {
    '&:last-child td': {
      borderBottom: 'none',
    },
  },
  disableOnRowClick: {
    cursor: 'default',
  },
  disableSelect: {
    userSelect: 'none',
  },
  disableSelectSameField: {
    opacity: 0.3,
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  expand: {
    '& td': {
      borderBottom: 'none',
    },
    '&.active': {
      backgroundColor: `${colors.action.background}!important`,
    },
    '&:hover + tr': {
      backgroundColor: `${colors.action.background}!important`,
    },
  },
  hideBorder: {
    borderBottomWidth: '0px !important',
  },
  hoverRow: {
    backgroundColor: `${colors.primary.background}!important`,
  },
  label: {
    fontWeight: 500,
  },
  loader: {
    minHeight: theme.loader.minHeight,
    position: 'relative',
  },
  tableBody: {
    display: 'block',
  },
  tableCheckbox: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',

    '& span': {
      padding: 0,
    },
  },
  tableContainer: {
    width: '100%',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'column',

    '& td': {
      borderWidth: 0,
      padding: 8,
      paddingLeft: 16,
      paddingRight: 16,
    },

    '&:first-child td:first-child': {
      borderTopStyle: 'solid',
      borderTopWidth: 1,
    },

    '& td:first-child': {
      paddingTop: 16,
    },

    '& td:last-child': {
      borderBottomWidth: 1,
      paddingBottom: 16,
    },
  },
  tableWithoutPagination: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    paddingBottom: 12,
  },
}));

export default useStyles;
