import { getType } from 'typesafe-actions';
import { getGroupedReservations } from './../actions';
import { IAction, IGroupedReservationsReducer } from './../types';

const initialState: IGroupedReservationsReducer = null;

const groupedReservations = (
  state: IGroupedReservationsReducer = initialState,
  action: IAction
): IGroupedReservationsReducer => {
  switch (action.type) {
    case getType(getGroupedReservations.success):
      return action.payload;
    default:
      return state;
  }
};

export default groupedReservations;
