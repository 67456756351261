import React, { useEffect, useState } from 'react';

import { Divider, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import { IFormLayoutProps } from './FormLayout';

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: theme.spacing(3),
  },
  titleWrapper: {
    marginTop: theme.spacing(3),
    width: '100%',
  },
  typography: {
    marginBottom: theme.spacing(2),
  },
}));

const InvoiceDataStepper = ({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
  getCompanyData,
  setFieldValue,
  setFormData,
  setShowBasket,
}: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Add.components.FormLayout.InvoiceDataStepper',
  });

  const [timeOut, setTimeOut] = useState<number>(0);
  const classes = useStyles();
  useEffect(() => {
    setShowBasket(false);
    setFieldValue('invoiceCheckbox', true);

    return () => {
      setShowBasket(true);
      setFieldValue('invoiceCheckbox', false);
    };
  }, []);

  const setNipValue = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.currentTarget;

    setFieldValue('nip', value);

    if (timeOut) {
      clearTimeout(timeOut as any);
    }
    setTimeOut(
      setTimeout(() => {
        getCompanyData(value);
        setFormData({ ...values, nip: value });
      }, 2000) as any
    );
  };

  return (
    <>
      <div className={classes.titleWrapper}>
        <Typography variant="h5" className={classes.typography}>
          {t('title')}
        </Typography>
        <Typography variant="body1" className={classes.typography}>
          {t('desc')}
        </Typography>
        <Divider />
      </div>
      <TextField
        id="nip"
        label={(touched.nip && errors.nip) || t('nip')}
        helperText={t('nipHelper')}
        onChange={setNipValue}
        onBlur={handleBlur}
        value={values.nip}
        name="nip"
        error={!!(touched.nip && errors.nip)}
        className={classes.input}
        fullWidth={true}
        autoComplete="nope"
      />

      <TextField
        id="companyName"
        label={(touched.companyName && errors.companyName) || t('companyName')}
        helperText={t('companyNameHelper')}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.companyName}
        name="companyName"
        error={!!(touched.companyName && errors.companyName)}
        className={classes.input}
        fullWidth={true}
        autoComplete="nope"
      />

      <TextField
        id="street"
        label={
          (touched.street && errors.street) || t('companyOfficeAddressStreet')
        }
        helperText={t('companyOfficeAddressStreetHelper')}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.street}
        name="street"
        error={!!(touched.street && errors.street)}
        className={classes.input}
        fullWidth={true}
        autoComplete="nope"
      />

      <TextField
        id="houseNumber"
        label={
          (touched.houseNumber && errors.houseNumber) ||
          t('companyOfficeAddressNumber')
        }
        helperText={t('companyOfficeAddressNumberHelper')}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.houseNumber}
        name="houseNumber"
        error={!!(touched.houseNumber && errors.houseNumber)}
        className={classes.input}
        fullWidth={true}
        autoComplete="nope"
      />

      <TextField
        id="zipCode"
        label={
          (touched.zipCode && errors.zipCode) ||
          t('companyOfficeAddressZipCode')
        }
        helperText={t('companyOfficeAddressZipCodeHelper')}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.zipCode}
        name="zipCode"
        error={!!(touched.zipCode && errors.zipCode)}
        className={classes.input}
        fullWidth={true}
        autoComplete="nope"
      />

      <TextField
        id="city"
        label={(touched.city && errors.city) || t('companyOfficeCity')}
        helperText={t('companyOfficeCityHelper')}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.city}
        name="city"
        error={!!(touched.city && errors.city)}
        className={classes.input}
        fullWidth={true}
        autoComplete="nope"
      />
    </>
  );
};

export default InvoiceDataStepper;
