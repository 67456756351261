import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  centerNumber: {
    left: '50%',
    padding: 'inherit',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  chart: {
    [theme.breakpoints.down('lg')]: {
      display: 'none!important',
    },
  },
  circleWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  iconContainer: {
    display: 'flex',
    fontSize: 12,
  },
  listItem: {
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      padding: 0,
    },
  },
  svgContainer: {
    overflow: 'visible!important',
  },
}));

export default useStyles;
