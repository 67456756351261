import { getType } from 'typesafe-actions';

import {
  changeFilterSettlement,
  clearFilterSettlement,
  getSettlementMetadata,
  getSettlementRundates,
  getSettlements,
  getSingleSettlement,
  handleActiveSettlement,
  resetSingleSettlement,
  searchSettlement,
} from '../actions';
import { IAction, ISettlementsReducer } from '../types';

const initialState: ISettlementsReducer = {
  activeSettlement: null,
  filter: {
    accepted: 'undefined',
    acceptedBy: [],
    createdAtFrom: null,
    createdAtTo: null,
    createdBy: [],
    endDate: null,
    rundates: [],
    rundatesIds: [],
    salesChannel: 0,
    settlementType: 'undefined',
    startDate: null,
  },
  settlementMetadata: null,
  settlementRundates: [],
  settlements: {
    data: [],
    meta: {
      page: 1,
      size: 20,
      total: 0,
    },
  },
  singleSettlement: null,
};

const settlementsReducer = (
  state: ISettlementsReducer = initialState,
  action: IAction
): ISettlementsReducer => {
  switch (action.type) {
    case getType(handleActiveSettlement):
      return {
        ...state,
        activeSettlement: action.payload,
      };
    case getType(getSettlements.success):
      return {
        ...state,
        settlements: action.payload,
      };
    case getType(getSingleSettlement.success):
      return {
        ...state,
        singleSettlement: action.payload,
      };
    case getType(getSettlementMetadata.success):
      return {
        ...state,
        settlementMetadata: action.payload,
      };
    case getType(getSettlementRundates.success):
      return {
        ...state,
        settlementRundates: action.payload,
      };
    case getType(resetSingleSettlement):
      return {
        ...state,
        singleSettlement: null,
      };
    case getType(changeFilterSettlement):
      return {
        ...state,
        filter: action.payload,
      };
    case getType(clearFilterSettlement):
      return {
        ...state,
        filter: initialState.filter,
      };
    case getType(searchSettlement):
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
};

export default settlementsReducer;
