import { createSelector } from 'reselect';

import _Store from '@Store';
import { IProduct, IProductsReducer } from '../types';
import get from './get';

const getAllProducts = createSelector<
  _Store.IState,
  IProductsReducer,
  IProduct[]
>([get], (products) => products.allProducts);

export default getAllProducts;
