import { Selector } from 'reselect';

import { IEventDiscountValues } from '@Compo/Discounts/components/EventDiscountModal/components/EventDiscountForm/EventDiscountForm.types';
import _Store from '@Store';

const getActiveEventDiscount: Selector<
  _Store.IState,
  IEventDiscountValues | null
> = (state) => state.discount.activeEventDiscount;

export default getActiveEventDiscount;
