import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import {
  getSingleEventDiscount,
  saveDiscount,
  saveNewDiscount,
} from '@Model/discount/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';
import _Store from '@Store';
import { resetError, resetLoading, setLoading } from '../actions';
import { DISCOUNT } from '../constants/constants';

export const setUpStateWhenSingleDiscountFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf([saveDiscount.request, getSingleEventDiscount.request])),
    mergeMap$(() => [resetError(DISCOUNT), setLoading(DISCOUNT)])
  );
};

export const setLoaderWhenSingleDiscountFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          saveDiscount.success,
          saveDiscount.failure,
          getSingleEventDiscount.success,
          getSingleEventDiscount.failure,
        ],
        action
      )
    ),
    map$(() => resetLoading(DISCOUNT))
  );
};

export const setErrorOnSingleDiscountFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        saveDiscount.failure,
        getSingleEventDiscount.failure,
        saveNewDiscount.failure,
      ])
    ),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
