import React from 'react';

import { Dialog, DialogTitle } from '@mui/material';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import FormLayout from './../../../GroupsTable/components/FormLayout';
import {
  ICustomAccessModalProps,
  ICustomAccessModalValues,
} from './CustomAccessModal.types';

const CustomAccessModal = ({
  open,
  handleClose,
  submit,
  permissionsIds,
}: ICustomAccessModalProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Users.components.UsersTable.CustomAccessModal',
  });

  const catchHandleSubmit = (values: ICustomAccessModalValues) => {
    submit(values);
    handleClose();
  };

  const initialValues = { permissionsIds };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitle>{t('title')}</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={catchHandleSubmit}
        children={(props) => (
          <FormLayout
            {...props}
            closeHandler={handleClose}
            isCustomAccess={true}
          />
        )}
      />
    </Dialog>
  );
};

export default CustomAccessModal;
