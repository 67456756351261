import { getType } from 'typesafe-actions';
import { getReservations } from './../actions';
import { IAction, IReservationsReducer } from './../types';

const initialState: IReservationsReducer = null;

const happeningsReducer = (
  state: IReservationsReducer = initialState,
  action: IAction,
): IReservationsReducer => {
  switch (action.type) {
    case getType(getReservations.success):
      return action.payload;
    default:
      return state;
  }
};

export default happeningsReducer;
