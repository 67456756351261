import axios, { CancelTokenSource } from 'axios';

import { IGroupsModalValues } from '@Compo/Users/components/GroupsTable/components/GroupsModal/GroupsModal.types';
import config from '@Config';
import { catchNewErrorMessage } from '@Misc/helpers/api/catchHttpError';
import getData from '@Misc/helpers/api/getData';
import { IChangePasswordBody, IGroup } from '@Services/$users-api/types';

import {
  IGroupsResponse,
  IPermissionsResponse,
  IUser,
  IUserBody,
  IUsersResponse,
} from './types';

class UsersApi {
  private static getUserUrl(): string {
    return `${config.api.cmsServices}user`;
  }

  private static getUsersUrl(): string {
    return `${config.api.cmsServices}users`;
  }

  private static getSingleUserUrl(id: number): string {
    return `${config.api.cmsServices}user/${id}`;
  }

  private static getUserStatusUrl(id: number, status: string): string {
    return `${config.api.cmsServices}user-status/${id}/${status}`;
  }

  private static getUserPasswordUrl(): string {
    return `${config.api.cmsServices}user-password`;
  }

  private static getGroupUrl(): string {
    return `${config.api.cmsServices}group`;
  }

  private static getGroupDeleteUrl(id: number): string {
    return `${config.api.cmsServices}group/${id}`;
  }

  private static getSingleGroupUrl(id: number): string {
    return `${config.api.cmsServices}group/${id}`;
  }

  private static getGroupsUrl(): string {
    return `${config.api.cmsServices}groups`;
  }

  private static getPermissionsUrl(): string {
    return `${config.api.cmsServices}permissions-segments`;
  }

  private cancelTokenGetUsers?: CancelTokenSource;

  public getGroups(
    count?: number,
    page?: number,
    searchText?: string,
    partnerIds?: number[]
  ): Promise<IGroupsResponse> {
    return new Promise<IGroupsResponse>((resolve, reject) => {
      this.cancelTokenGetUsers = axios.CancelToken.source();

      axios
        .get(UsersApi.getGroupsUrl(), {
          cancelToken: this.cancelTokenGetUsers.token,
          params: {
            count,
            'filter[all]': searchText?.length ? searchText : undefined,
            page,
            partnerIds,
          },
        })
        .then(getData)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public getSingleGroup(id: number): Promise<IGroup> {
    return new Promise<IGroup>((resolve, reject) => {
      this.cancelTokenGetUsers = axios.CancelToken.source();

      axios
        .get(UsersApi.getSingleGroupUrl(id), {
          cancelToken: this.cancelTokenGetUsers.token,
        })
        .then(getData)
        .then(getData)
        .then((data) => {
          resolve(data[0]);
        })
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public saveGroup(body: IGroupsModalValues, partnerId: number): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenGetUsers = axios.CancelToken.source();

      if (body.id) {
        axios
          .put(
            UsersApi.getGroupUrl(),
            { ...body, cmsPartnerId: partnerId },
            {
              cancelToken: this.cancelTokenGetUsers.token,
            }
          )
          .then(getData)
          .then(resolve)
          .catch((error) => reject(catchNewErrorMessage(error)));
      } else {
        axios
          .post(
            UsersApi.getGroupUrl(),
            { ...body, cmsPartnerId: partnerId },
            {
              cancelToken: this.cancelTokenGetUsers.token,
            }
          )
          .then(getData)
          .then(resolve)
          .catch((error) => reject(catchNewErrorMessage(error)));
      }
    });
  }

  public deleteGroup(id: number): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenGetUsers = axios.CancelToken.source();

      axios
        .delete(UsersApi.getGroupDeleteUrl(id), {
          cancelToken: this.cancelTokenGetUsers.token,
        })
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public getUsers(
    count?: number,
    page?: number,
    searchText?: string,
    partnerId?: number
  ): Promise<IUsersResponse> {
    return new Promise<IUsersResponse>((resolve, reject) => {
      this.cancelTokenGetUsers = axios.CancelToken.source();

      axios
        .get(UsersApi.getUsersUrl(), {
          cancelToken: this.cancelTokenGetUsers.token,
          params: {
            count,
            'filter[all]': searchText?.length ? searchText : undefined,
            page,
            partnerIds: [partnerId],
          },
        })
        .then(getData)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public saveUser(body: IUserBody): Promise<string | null> {
    return new Promise<string | null>((resolve, reject) => {
      this.cancelTokenGetUsers = axios.CancelToken.source();
      const force = body.force;

      delete body.force;

      if (body.id) {
        axios
          .put(UsersApi.getUserUrl(), body, {
            cancelToken: this.cancelTokenGetUsers.token,
            params: {
              force,
            },
          })
          .then(getData)
          .then((response) => resolve(response.message))
          .catch((error) => reject(catchNewErrorMessage(error)));
      } else {
        axios
          .post(UsersApi.getUserUrl(), body, {
            cancelToken: this.cancelTokenGetUsers.token,
            params: {
              force,
            },
          })
          .then(getData)
          .then((response) => resolve(response.message))
          .catch((error) => reject(catchNewErrorMessage(error)));
      }
    });
  }

  public changeUserStatus(id: number, status: string): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenGetUsers = axios.CancelToken.source();

      axios
        .put(
          UsersApi.getUserStatusUrl(id, status),
          {},
          {
            cancelToken: this.cancelTokenGetUsers.token,
          }
        )
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public getSingleUser(id: number): Promise<IUser> {
    return new Promise<IUser>((resolve, reject) => {
      this.cancelTokenGetUsers = axios.CancelToken.source();

      axios
        .get(UsersApi.getSingleUserUrl(id), {
          cancelToken: this.cancelTokenGetUsers.token,
        })
        .then(getData)
        .then(getData)
        .then((data) => {
          resolve(data[0]);
        })
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public changeUserPassword(body: IChangePasswordBody): Promise<[]> {
    return new Promise<[]>((resolve, reject) => {
      this.cancelTokenGetUsers = axios.CancelToken.source();

      axios
        .post(UsersApi.getUserPasswordUrl(), body, {
          cancelToken: this.cancelTokenGetUsers.token,
        })
        .then(getData)
        .then(resolve)
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public getPermissions(appType?: string): Promise<IPermissionsResponse> {
    return new Promise<IPermissionsResponse>((resolve, reject) => {
      this.cancelTokenGetUsers = axios.CancelToken.source();

      axios
        .get(UsersApi.getPermissionsUrl(), {
          cancelToken: this.cancelTokenGetUsers.token,
          params: {
            count: 9999,
            'filter[appType]': appType,
          },
        })
        .then(getData)
        .then((data) => {
          resolve(data);
        })
        .catch((error) => reject(catchNewErrorMessage(error)));
    });
  }

  public cancelGetUsers() {
    if (this.cancelTokenGetUsers) {
      this.cancelTokenGetUsers.cancel();
      this.cancelTokenGetUsers = undefined;
    }
  }
}

export default new UsersApi();
