import { createSelector } from 'reselect';

import _Store from '@Store';
import { ILocalProduct, IProductsReducer } from '../types';
import get from './get';

const getActiveZohoProduct = createSelector<
  _Store.IState,
  IProductsReducer,
  ILocalProduct | null
>([get], (products) => products.activeZohoProduct);

export default getActiveZohoProduct;
