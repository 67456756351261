import React from 'react';

import { Document, Page, Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { IColumn } from '../Table/Table.types';
import Table from './../Table';
import styles from './PdfDocument.styles';
import { IPdfDocumentProps } from './PdfDocument.types';

const PdfDocument = ({ rundate, reports }: IPdfDocumentProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'ReportSeatsIo.components.PdfDocument',
  });

  const columns: IColumn[] = [
    { id: 'label', label: t('place') },
    { id: 'section', label: t('sector') },
    { id: 'categoryLabel', label: t('category') },
    { id: 'row', label: t('row') },
    { id: 'seat', label: t('seat') },
    { id: 'statusLabel', label: t('status') },
    { id: 'countSold', label: t('ticketsSold') },
  ];

  return (
    <Document>
      <Page style={styles.pageContainer}>
        <View style={styles.page}>
          <View style={styles.section}>
            <View style={styles.titleContainer}>
              <Text style={styles.title}>{rundate.event.titlePL}</Text>
            </View>
            {reports.map((report) => (
              <>
                <View style={styles.container}>
                  <View>
                    <Text style={styles.total}>{t('total')}</Text>
                  </View>
                  <View style={styles.flex}>
                    <Text style={styles.subtitle}>{`${t('occupied')}${
                      report.summary.booked + report.summary.reserved
                    } (${report.summary.booked}/${
                      report.summary.reserved
                    })`}</Text>
                    <Text style={styles.subtitle}>{`${t('free')}${
                      report.summary.free
                    }`}</Text>
                    <Text style={styles.subtitle}>{`${t('notForSale')}${
                      report.summary.notForSale
                    }`}</Text>
                    <Text style={styles.subtitle}>{`${t('duble')}${
                      report.summary.overbooking
                    }`}</Text>
                  </View>
                </View>
                {report.categories.map((category) => (
                  <Table
                    key={category.categoryKey}
                    columns={columns}
                    rows={category.seats}
                    categoryReport={category}
                    title={`Raport ${rundate.event.titlePL} ID${category.categoryKey}`}
                  />
                ))}
              </>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfDocument;
