import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import styles from './FilterItem.module.scss';
import { IFilterItemProps } from './FilterItem.types';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 0,
  },
  spaceControl: {
    marginBottom: theme.spacing(1),
  },
  summary: {
    alignItems: 'center',
  },
}));

const FilterItem = ({ happening, switchFilter }: IFilterItemProps) => {
  const classes = useStyles();

  const switchAllHappening = () => {
    switchFilter(happening.id);
  };

  return (
    <Grid item={true} xs={12} sm={6}>
      <Accordion square={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.summary}
        >
          <div
            className={styles.color}
            style={{ background: happening.color || '' }}
          />
          <FormControlLabel
            className={classes.formControl}
            control={
              <Checkbox
                checked={
                  happening.spaces &&
                  happening.spaces.every((space) => space.selected)
                }
                onChange={switchAllHappening}
                color="primary"
              />
            }
            label={<Typography variant="body1">{happening.title}</Typography>}
          />
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {happening.spaces.map((space) => (
              <FormControlLabel
                key={space.id}
                className={classes.spaceControl}
                control={
                  <Checkbox
                    checked={space.selected}
                    onChange={() => switchFilter(happening.id, space.id)}
                    color="primary"
                  />
                }
                label={<Typography variant="body1">{space.title}</Typography>}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default FilterItem;
