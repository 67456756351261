import { getType } from 'typesafe-actions';

import { removeDuplicatesArray } from '@Misc/helpers/removeDuplicatesArray';

import {
  addEntryCodeRequest,
  closeModal,
  getAvailableUsers,
  getEntryTokens,
  getSingleUser,
  handleActivePageConsentsList,
  setDate,
  setEmail,
  setFirstName,
  setLastName,
} from './../actions';
import { FORM_IO_RESERVATION_CODE } from './../constants/constants';
import { IAction, IConsentsListReducer } from './../types';

const DEFAULT_PER_PAGE = 10;
const DEFAULT_ACTIVE_PAGE = 1;

const initialState: IConsentsListReducer = {
  activePage: DEFAULT_ACTIVE_PAGE,
  date: '',
  email: '',
  entryTokens: [],
  firstName: '',
  formSmsToken: '',
  lastName: '',
  perPage: DEFAULT_PER_PAGE,
  selectedUser: null,
  showModal: false,
  totalCount: 0,
};

const consentsListReducer = (
  state: IConsentsListReducer = initialState,
  action: IAction
): IConsentsListReducer => {
  switch (action.type) {
    case getType(handleActivePageConsentsList):
      return {
        ...state,
        activePage: action.payload,
      };

    case getType(getAvailableUsers.success):
      return {
        ...state,
        totalCount: action.payload.meta.total,
      };

    case getType(getSingleUser.success):
      return {
        ...state,
        selectedUser: action.payload,
        showModal: true,
      };

    case getType(closeModal):
      return {
        ...state,
        entryTokens: [],
        selectedUser: null,
        showModal: false,
      };

    case getType(getEntryTokens.success):
      return {
        ...state,
        entryTokens: action.payload.length
          ? removeDuplicatesArray(
              action.payload.map((user) => user.data[FORM_IO_RESERVATION_CODE]),
              (entryToken: string) => entryToken
            )
          : [],
        formSmsToken:
          action.payload &&
          action.payload.length &&
          action.payload[0] &&
          action.payload[0].form
            ? action.payload[0].form
            : state.formSmsToken,
      };
    case getType(addEntryCodeRequest.success):
      return {
        ...state,
        entryTokens: [...state.entryTokens, action.payload],
      };

    case getType(setEmail):
      return {
        ...state,
        activePage: DEFAULT_ACTIVE_PAGE,
        email: action.payload,
      };
    case getType(setFirstName):
      return {
        ...state,
        activePage: DEFAULT_ACTIVE_PAGE,
        firstName: action.payload,
      };
    case getType(setLastName):
      return {
        ...state,
        activePage: DEFAULT_ACTIVE_PAGE,
        lastName: action.payload,
      };
    case getType(setDate):
      return {
        ...state,
        activePage: DEFAULT_ACTIVE_PAGE,
        date: action.payload,
      };
    default:
      return state;
  }
};

export default consentsListReducer;
