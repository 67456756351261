import React, { FC } from 'react';

import { Divider, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import cn from 'classnames';
import 'tabler-react/dist/Tabler.css';

import useStyles from './GoingPaper.styles';
import { IGoingPaperProps } from './GoingPaper.types';

const GoingPaper: FC<IGoingPaperProps> = ({
  children,
  className,
  title,
  description,
  buttons,
  header,
  disableBottomDivider,
}) => {
  const classes = useStyles();
  return (
    <Paper className={cn(classes.paper, className)} square={true}>
      {(title || header) && (
        <div className={classes.header}>
          {title && (
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
          )}
          {description && (
            <Typography variant="body1" className={classes.description}>
              {description}
            </Typography>
          )}
          {header}
          <Divider />
        </div>
      )}
      {children}
      {title && !disableBottomDivider && (
        <Divider className={classes.divider} />
      )}
      {buttons && <div className={classes.buttons}>{buttons}</div>}
    </Paper>
  );
};

export default GoingPaper;
