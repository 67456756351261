import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { catchSaveGroup, handleActiveGroup } from '@Model/users/actions';
import { getActiveGroup } from '@Model/users/selectors';

import GroupsModal from './GroupsModal.component';
import {
  IGroupsModalFromDispatch,
  IGroupsModalFromState,
} from './GroupsModal.types';

const mapStateToProps = (state: _Store.IState): IGroupsModalFromState => ({
  activeGroup: getActiveGroup(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IGroupsModalFromDispatch => ({
  handleClose: () => dispatch(handleActiveGroup(null)),
  submit: (values) => dispatch(catchSaveGroup(values)),
});

export default connect<
  IGroupsModalFromState,
  IGroupsModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(GroupsModal);
