import React, { useContext, useEffect, useRef, useState } from 'react';

import {
  Alert,
  AlertTitle,
  Button,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import GridUi from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import colors from '@Compo/App/colors';
import { clearContext } from '@Compo/Basket/ZagrywkiBasketProvider/ZagrywkiBasketProvider';
import Filters from '@Compo/happenings/List/components/Filters';
import EventsList from '@Compo/reservations/Add/components/FormLayout/EventsList';
import BigButton from '@Compo/reusable/BigButton';
import { getTouchedObj } from '@Misc/helpers/getTouchedObj';
import { IZagrywkiBasket } from '@Model/basket/types';
import { IReservationSaveBody } from '@Services/$reservations-api/types';

import ClientDataStepper from './ClientDataStepper';
import EventsSeats from './EventsSeats';
import { IFormLayoutProps, IStep } from './FormLayout';
import styles from './FormLayout.module.scss';
import PaymentMixStepper from './PaymentMixStepper';
import ProductsList from './ProductsList';
import Stepper from './Stepper/Stepper';

type IReservationType =
  | 'reservation'
  | 'carnet'
  | 'merchandise'
  | 'withoutTransaction'
  | 'bocaTest'
  | 'events'
  | null;

const WITH_DATA_KEY = 'withData';
const WITH_INVOICE_DATA_KEY = 'withInvoiceData';

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: colors.secondary.dark,
  },

  stepperItem: {
    borderRadius: 20,
    boxShadow:
      '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
    height: '100%',
    minHeight: 128,
    padding: theme.spacing(4),
    width: '100%',
  },

  subtitle: {
    color: colors.text.secondary,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },

  tooltip: {
    maxWidth: 'none',
  },

  closeButton: {
    margin: theme.spacing(1),
  },
}));

const TypeStepper = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'reservations.Add.components.FormLayout.TypeStepper',
  });

  const {
    errors,
    happenings,
    selectHappening,
    setFieldValue,
    values,
    goToReservationSteps,
    partnerId,
    transactionType,
    saveReservation,
    discount,
    setTransactionType,
    showMonit,
    setShowMonit,
    delayed,
    mixPayment,
    setMixPayment,
    clearAdvancePayment,
    paymentAdvanceId,
    setTouched,
    resetSelection,
    selectedSeatsIo,
    pools,
    selectedEvent,
    isMdagUser,
  } = props;

  const {
    nip,
    street,
    zipCode,
    city,
    houseNumber,
    companyName,
    email,
    emptyReservation,
    paymentAdvanceAmount,
  } = values;

  const state = useContext(clearContext);

  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const [seatsIo, setSeatsIo] = useState(false);
  const [isSeatsValid, setSeatsValid] = useState(false);

  useEffect(() => {
    if (paymentAdvanceId) {
      setMixPayment(true);
    }
    return () => {
      clearAdvancePayment();
    };
  }, [paymentAdvanceId]);

  useEffect(() => {
    if (isMdagUser) {
      activeType('events');
    }
  }, []);

  const prepareInvoiceData = () => {
    if (
      transactionType === WITH_INVOICE_DATA_KEY &&
      companyName &&
      zipCode &&
      city
    ) {
      const address =
        street && houseNumber
          ? `${street} ${houseNumber}`
          : houseNumber
          ? houseNumber
          : '';

      return {
        invoice: {
          address,
          city,
          email,
          name: companyName,
          nip: nip || '',
          post: zipCode,
        },
      };
    } else {
      return {};
    }
  };

  const getUser = () => {
    if (
      transactionType === WITH_DATA_KEY ||
      transactionType === WITH_INVOICE_DATA_KEY
    ) {
      return {
        user: {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          phone: values.phone,
          terms: true,
        },
      };
    } else {
      return {
        user: {
          email: 'sprzedaz@goingapp.pl',
          firstName: '-',
          lastName: '-',
          phone: '-',
          terms: true,
        },
      };
    }
  };

  const body = {
    basketItems: state && state.basketItems ? state.basketItems : [],
    // date,
    emptyReservation,
    idempotencyKey: state && state.idempotencyKey ? state.idempotencyKey : '',
    // startTime: slot && slot.startTime ? slot.startTime : null,

    delayedTransaction: delayed,
    // description: formValues.description
    //   ? formValues.description
    //   : undefined,
    discount,
    // happeningId: Number(formValues.happeningId),
    // numberOfPeople: formValues.numberOfPeople,
    onDone: state ? state.clearBasket : () => null,
    paymentAdvanceAmount,
    paymentOperator: 2,
    // prepaidCard: formValues.prepaidCard,
    // reservationCheckbox,
    // showDiscountForm,
    // spaceId: Number(formValues.spaceId),
    // upsell,
    shortenedInvoice: values.shortenedInvoice || undefined,
    ...getUser(),
    ...prepareInvoiceData(),
  };

  const [selectedType, activeType] = React.useState<IReservationType>(null);

  const handleHappeningChange = (id: number) => {
    setFieldValue('happeningId', id);

    if (id === values.happeningId && goToReservationSteps) {
      goToReservationSteps();
    } else if (id) {
      selectHappening(id);
    } else {
      selectHappening(null);
    }
  };

  const addSelectedSeatsToBag = () => {
    if (pools) {
      selectedSeatsIo.forEach((seat) => {
        const pool = pools.pools.find((item) => {
          if (seat.selectedTicketType) {
            return (
              String(item.seatsIoCategories[0].id) === seat.selectedTicketType
            );
          }

          return item.seatsIoCategories[0].categoryKey === seat.category.key;
        });
        if (pool) {
          state?.addToBag({
            calculatePricePerPerson: false,
            dateTime: pools.runDate,
            id: Math.random(),
            isEvent: true,
            numberOfPeople: 1,
            poolId: pool.id,
            price: pool.price,
            priceTypeName: pool.poolDescription.titlePL,
            seats: [seat.label],
            title: selectedEvent?.titlePl || '',
          } as IZagrywkiBasket);
          setSeatsIo(false);
          resetSelection();
        }
      });
    }
  };

  const steps: IStep[] = [
    {
      description: t('addReservationDesc'),
      render: () => undefined,
      stepName: '',
      title: t('addReservationTitle'),
    },
  ];

  const clientDataSteps: IStep[] = [
    {
      description: t('clientDataDesc'),
      render: () => renderFormData(),
      stepName: '',
      title: t('clientDataTitle'),
    },
  ];

  const renderFirstStep = () => {
    const availabilities: Array<{
      title: string;
      type: IReservationType;
    }> = [
      {
        title: t('reservation'),
        type: 'reservation',
      },
      {
        title: t('products'),
        type: 'merchandise',
      },
      {
        title: t('carnet'),
        type: 'carnet',
      },
      {
        title: t('withoutTransaction'),
        type: 'withoutTransaction',
      },
      {
        title: t('events'),
        type: 'events',
      },
    ];

    const handleActiveType = (type: IReservationType) => {
      if (type === 'withoutTransaction') {
        setFieldValue('emptyReservation', true);
      } else {
        setFieldValue('emptyReservation', false);
      }
      if (type === selectedType) {
        scrollToHappeningsList();
      } else {
        activeType(type);
      }
    };

    if (isMdagUser) {
      return null;
    }

    return (
      <>
        <Typography
          variant="body1"
          className={classes.subtitle}
          data-testid="whatYouWantToSellText"
        >
          {t('whatYouWantToSell')}
        </Typography>
        <GridUi
          container={true}
          spacing={2}
          className={cn(styles.stepperReservation)}
        >
          {availabilities.map((available) => (
            <GridUi
              item={true}
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={available.title}
            >
              <Tooltip
                title={t('clickAgain') as string}
                open={selectedType === available.type}
                placement="top"
                classes={{ tooltip: classes.tooltip }}
                key={available.title}
              >
                <BigButton
                  variant="contained"
                  size="large"
                  color="secondary"
                  selected={selectedType === available.type}
                  onClick={() => handleActiveType(available.type)}
                  data-testid={available.type}
                >
                  {available.title}
                </BigButton>
              </Tooltip>
            </GridUi>
          ))}
        </GridUi>
      </>
    );
  };

  const happeningsListRef = useRef<HTMLDivElement>(null);

  const scrollToHappeningsList = () => {
    if (happeningsListRef && happeningsListRef.current) {
      happeningsListRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const renderHappenings = () => {
    if (
      (selectedType === 'reservation' ||
        selectedType === 'withoutTransaction') &&
      !!partnerId
    ) {
      return (
        <>
          <Typography
            variant="body1"
            className={classes.subtitle}
            data-testid="happeningText"
          >
            {t('happening')}
          </Typography>
          <GridUi
            container={true}
            spacing={2}
            className={cn(styles.stepperReservation, styles.BitTop)}
            ref={happeningsListRef}
            data-testid="happeningsContainer"
          >
            {happenings.map((happening) => (
              <GridUi
                item={true}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={happening.id}
              >
                <Tooltip
                  title={t('clickAgain') as string}
                  open={values.happeningId === happening.id}
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    className={cn(
                      classes.stepperItem,
                      values.happeningId === happening.id && classes.selected
                    )}
                    onClick={() => handleHappeningChange(happening.id)}
                  >
                    {happening.metadata.title}
                  </Button>
                </Tooltip>
              </GridUi>
            ))}
          </GridUi>
        </>
      );
    }
  };

  const renderProducts = () => {
    if (selectedType === 'merchandise') {
      return (
        <div
          className={cn(styles.stepperReservation, styles.BitTop)}
          ref={happeningsListRef}
        >
          <Typography variant="body1" className={classes.subtitle}>
            {t('categories')}
          </Typography>
          <ProductsList handleShowProducts={() => null} />
        </div>
      );
    }
  };

  const renderEvents = () => {
    if (selectedType === 'events') {
      return (
        <div
          className={cn(styles.stepperReservation, styles.BitTop)}
          ref={happeningsListRef}
        >
          <Typography variant="body1" className={classes.subtitle}>
            {t('events')}
          </Typography>
          <EventsList setSeatsIo={setSeatsIo} />
        </div>
      );
    }
  };

  const renderFormData = () => {
    if (transactionType.length > 0) {
      return <ClientDataStepper {...props} />;
    }
  };

  const paymentDataSteps: IStep[] = [
    {
      description: t('paymentDataDesc'),
      render: () => renderMixPayment(),
      stepName: '',
      title: t('paymentDataTitle'),
    },
  ];

  const seatsIoSteps: IStep[] = [
    {
      description: t('seatsIoDesc'),
      render: () => renderSeatsIo(),
      stepName: '',
      title: t('seatsIoTitle'),
    },
  ];

  if (mixPayment) {
    clientDataSteps.push(paymentDataSteps[0]);
  }

  const renderMixPayment = () => {
    if (mixPayment) {
      return <PaymentMixStepper {...props} />;
    }
  };

  const renderSeatsIo = () => {
    if (seatsIo) {
      return <EventsSeats setSeatsValid={setSeatsValid} />;
    }
  };

  const onBack = () => {
    const currentStep = activeStep - 1;

    if (currentStep >= 0) {
      setActiveStep(currentStep);
    } else {
      setActiveStep(0);
      setTransactionType('');
      setMixPayment(false);
    }
  };

  const handleSubmitAction = () => {
    setTouched(getTouchedObj(errors));
    if (!Object.keys(errors).length) {
      saveReservation(body as IReservationSaveBody);
    }
  };

  const renderStepper = () => {
    if (transactionType.length > 0) {
      return (
        <Stepper
          steps={clientDataSteps}
          activeStep={activeStep}
          setActiveStep={(page) => setActiveStep(page)}
          hideStepper={true}
          onDone={handleSubmitAction}
          showButtons={true}
          buttonText={t('clientDataDone')}
          canBeDonne={true}
          onBack={onBack}
        >
          {clientDataSteps[activeStep].render()}
        </Stepper>
      );
    } else if (mixPayment) {
      return (
        <Stepper
          steps={paymentDataSteps}
          activeStep={0}
          setActiveStep={() => null}
          hideStepper={true}
          onDone={() => saveReservation(body as IReservationSaveBody)}
          showButtons={true}
          buttonText={t('paymentMixDone')}
          canBeDonne={true}
          onBack={() => {
            setMixPayment(false);
            setFieldValue('paymentAdvanceAmount', null);
            clearAdvancePayment();
          }}
        >
          {renderMixPayment()}
        </Stepper>
      );
    } else if (seatsIo) {
      return (
        <Stepper
          steps={seatsIoSteps}
          activeStep={0}
          setActiveStep={() => null}
          hideStepper={true}
          onDone={addSelectedSeatsToBag}
          showButtons={true}
          buttonText={t('addToCart')}
          canBeDonne={!!selectedSeatsIo.length && isSeatsValid}
          onBack={() => {
            setSeatsIo(false);
            resetSelection();
          }}
        >
          {renderSeatsIo()}
        </Stepper>
      );
    } else {
      return (
        <Stepper
          steps={steps}
          activeStep={0}
          setActiveStep={() => null}
          hideStepper={true}
        >
          {renderFirstStep()}
          {renderHappenings()}
          {renderProducts()}
          {renderEvents()}
        </Stepper>
      );
    }
  };

  return (
    <div className={styles.stepperWrapper}>
      <Snackbar
        open={showMonit}
        autoHideDuration={3000}
        onClose={() => setShowMonit(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert variant="filled" severity="success">
          <AlertTitle>{t('addedToBasket')}</AlertTitle>
          {t('addedToBasketContent')}
        </Alert>
      </Snackbar>
      <div className={styles.wrapper}>
        <Filters autoSet={true} />
        {renderStepper()}
      </div>
    </div>
  );
};

export default TypeStepper;
