import React from 'react';

import Grid from '@Compo/layout/Grid';
import Add from '@Compo/reservations/Add';

const ReservationAdd = () => (
  <Grid>
    <Add />
  </Grid>
);

export default ReservationAdd;
