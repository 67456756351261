import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { checkExternalTickets } from '@Model/going/rundates/actions';

import ImportListForm from './ImportListForm.component';
import { IImportListFormFromDispatch } from './ImportListForm.types';

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IImportListFormFromDispatch => ({
  check: (values) =>
    dispatch(
      checkExternalTickets({
        entryTokens: values.entryTokens.split(/\r|\n/),
        name: values.name,
      })
    ),
});

export default connect<{}, IImportListFormFromDispatch, {}, _Store.IState>(
  null,
  mapDispatchToProps
)(ImportListForm);
