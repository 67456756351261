import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TypographyVariant } from '@Constants/Variants/Typography';

import AssignModal from './components/AssignModal';
import useStyles from './SeatsIo.styles';
import { ISeatsIoProps } from './SeatsIo.types';

const SeatsIo = ({ rundate, catchDeleteSeatChart }: ISeatsIoProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates.SeatsIo',
  });

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const theme = useTheme();

  const isMobile = useMediaQuery(() => {
    return theme.breakpoints.down('lg');
  });

  const closeTooltip = () => {
    setOpenTooltip(false);
  };

  return (
    <Grid container={true} spacing={2}>
      {open && <AssignModal open={open} handleClose={() => setOpen(false)} />}
      <Grid item={true} xs={12}>
        <Typography variant={TypographyVariant.h6}>{t('title')}</Typography>
      </Grid>
      <Grid item={true} xs={12}>
        {rundate.seatsIoChart ? (
          <div className={classes.planContainer}>
            <Grid
              container={true}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item={true}>
                <Typography variant={TypographyVariant.body1}>
                  {rundate.seatsIoChart.name}
                </Typography>
              </Grid>
              <Grid item={true}>
                <Grid container={true} spacing={3} alignItems="center">
                  <Grid item={true}>
                    <ClickAwayListener
                      mouseEvent="onMouseDown"
                      touchEvent="onTouchStart"
                      onClickAway={closeTooltip}
                    >
                      <Tooltip
                        classes={{
                          arrow: classes.arrow,
                          tooltip: classes.tooltip,
                        }}
                        title={
                          <img
                            className={classes.image}
                            src={
                              rundate.seatsIoChart.publishedVersionThumbnailUrl
                            }
                          />
                        }
                        placement="bottom"
                        arrow={true}
                        open={openTooltip}
                      >
                        {isMobile ? (
                          <IconButton
                            onClick={() =>
                              setOpenTooltip((oldState) => !oldState)
                            }
                            size="small"
                            color="primary"
                          >
                            <RemoveRedEyeIcon />
                          </IconButton>
                        ) : (
                          <Button
                            size="small"
                            variant="text"
                            color="primary"
                            startIcon={<RemoveRedEyeIcon />}
                            onClick={() =>
                              setOpenTooltip((oldState) => !oldState)
                            }
                          >
                            {t('preview')}
                          </Button>
                        )}
                      </Tooltip>
                    </ClickAwayListener>
                  </Grid>
                  <Grid item={true}>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={catchDeleteSeatChart}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : (
          <Button
            variant="outlined"
            size="medium"
            color="secondary"
            startIcon={<AddIcon />}
            className={classes.button}
            onClick={() => setOpen(true)}
          >
            {t('assignSeats')}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default SeatsIo;
