import { getType } from 'typesafe-actions';

import { getStatics } from '../actions';
import { IAction, IStaticsReducer } from '../types';

const initialState: IStaticsReducer = {
  url: '',
};

const happeningsReducer = (
  state: IStaticsReducer = initialState,
  action: IAction,
): IStaticsReducer => {
  switch (action.type) {
    case getType(getStatics.success):
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default happeningsReducer;
