/* tslint:disable:jsx-no-lambda */
import React, { ChangeEvent, Component } from 'react';

import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { withTranslation } from 'react-i18next';
import { RRule, Weekday } from 'rrule';

import { IDay, IDaysProps, IDaysState } from './Days.types';

class Days extends Component<IDaysProps, IDaysState> {
  constructor(props: IDaysProps) {
    super(props);

    const initialState: IDay[] = [
      {
        code: RRule.MO,
        name: this.props.t(
          'BetterManager.NewAddingConfiguration.components.Days.monday'
        ),
        selected: false,
      },
      {
        code: RRule.TU,
        name: this.props.t(
          'BetterManager.NewAddingConfiguration.components.Days.tuesday'
        ),
        selected: false,
      },
      {
        code: RRule.WE,
        name: this.props.t(
          'BetterManager.NewAddingConfiguration.components.Days.wednesday'
        ),
        selected: false,
      },
      {
        code: RRule.TH,
        name: this.props.t(
          'BetterManager.NewAddingConfiguration.components.Days.thursday'
        ),
        selected: false,
      },
      {
        code: RRule.FR,
        name: this.props.t(
          'BetterManager.NewAddingConfiguration.components.Days.friday'
        ),
        selected: false,
      },
      {
        code: RRule.SA,
        name: this.props.t(
          'BetterManager.NewAddingConfiguration.components.Days.saturday'
        ),
        selected: false,
      },
      {
        code: RRule.SU,
        name: this.props.t(
          'BetterManager.NewAddingConfiguration.components.Days.sunday'
        ),
        selected: false,
      },
    ];

    this.state = {
      allSelected: false,
      days: [...initialState],
    };

    /*
     * We want to replace days before mounting component.
     */
    // @ts-ignore
    this.state.days = this.prepareNewDays(props.value);

    this.select = this.select.bind(this);
    this.selectAll = this.selectAll.bind(this);
  }

  public componentDidUpdate(prevProps: IDaysProps) {
    if (this.props.value !== prevProps.value) {
      this.setNewDays(this.props.value);
    }
  }

  public render() {
    return (
      <Grid container={true} spacing={1}>
        {this.state.days.map((day) => (
          <Grid item={true} xs={6} key={day.code.toString()}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={day.selected}
                  onChange={() => this.select(day.code)}
                  color="primary"
                />
              }
              label={<Typography variant="body1">{day.name}</Typography>}
            />
          </Grid>
        ))}
        <Grid item={true} xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.allSelected}
                onChange={() => this.setAllSelected()}
                color="primary"
              />
            }
            label={
              <Typography variant="body1">
                {this.props.t(
                  'BetterManager.NewAddingConfiguration.components.Days.all'
                )}
              </Typography>
            }
          />
        </Grid>
      </Grid>
    );
  }

  protected select(code: Weekday) {
    const days = [...this.state.days].map((day) => {
      if (day.code.weekday === code.weekday) {
        return {
          ...day,
          selected: !day.selected,
        };
      }

      return {
        ...day,
      };
    });

    const daysString = this.makeStringFromDays(days);

    this.props.onChange({
      target: {
        name: this.props.name,
        value: daysString,
      },
    } as ChangeEvent<HTMLInputElement>);
  }

  protected setNewDays(days: string) {
    this.setState({
      days: this.prepareNewDays(days),
    });
  }

  protected setAllSelected() {
    this.setState((prevState) => {
      this.selectAll(!prevState.allSelected);
      return {
        allSelected: !prevState.allSelected,
      };
    });
  }

  protected prepareNewDays(days: string) {
    const splittedDays = days.split(',');

    const newDaysForState = this.state.days.map((day) => {
      const stringCode = day.code.toString();

      if (splittedDays.find((code) => code === stringCode)) {
        return {
          ...day,
          selected: true,
        };
      }

      return {
        ...day,
        selected: false,
      };
    });

    return newDaysForState;
  }

  protected makeStringFromDays(days: IDay[]) {
    return days
      .map((day) => {
        if (day.selected) {
          return day.code.toString();
        }

        return null;
      })
      .filter(Boolean)
      .join(',');
  }

  protected selectAll(selected: boolean) {
    const days = [...this.state.days].map((day) => {
      return {
        ...day,
        selected,
      };
    });

    const daysString = this.makeStringFromDays(days);

    this.props.onChange({
      target: {
        name: this.props.name,
        value: daysString,
      },
    } as ChangeEvent<HTMLInputElement>);
  }
}

export default withTranslation('components')(Days);
