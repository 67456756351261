import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { getInitialValues } from '@Compo/Mail/components/MailForm/MailForm.helpers';
import { get } from '@Model/authorization/selectors';
import { deleteAssets } from '@Model/going/cloudinary/actions';
import { searchPlace } from '@Model/going/places/actions';
import { getPlacesData } from '@Model/going/places/selectors';
import { generatePdf } from '@Model/going/reportSeatsIo/actions';
import { getReportSeatsIo } from '@Model/going/reportSeatsIo/selectors';
import {
  changeRundateHasExternalImage,
  changeRundateMonitorType,
  changeRundateSale,
  changeRundateVisibility,
  fetchRundateReport,
} from '@Model/going/rundates/actions';
import {
  getMonitorTypes,
  getSingleRundateData,
  getSingleRundatePlace,
  getTablePools,
} from '@Model/going/rundates/selectors';
import { selectEventMail } from '@Model/mailing/actions';
import {
  allPermissions,
  GOING_PLACES,
  GOING_RUNDATES,
} from '@Model/state/constants';
import { REPORTS, SEATS_IO_REPORT } from '@Model/state/constants/constants';
import { getLoading } from '@Model/state/selectors';

import FormLayout from './FormLayout.component';
import {
  IFormLayoutFromDispatch,
  IFormLayoutFromState,
} from './FormLayout.types';

const mapStateToProps = (state: _Store.IState): IFormLayoutFromState => {
  const {
    userInfo: { permissions },
  } = get(state);

  return {
    isLoading: getLoading(GOING_RUNDATES)(state),
    isPlacesLoading: getLoading(GOING_PLACES)(state),
    isReportLoading: getLoading(REPORTS)(state),
    isReportSeatsLoading: getLoading(SEATS_IO_REPORT)(state),
    monitorTypes: getMonitorTypes(state),
    permissionAssetsDelete: permissions.includes(
      allPermissions.access_cloudinary_delete
    ),
    permissionEventWrite: permissions.includes(
      allPermissions.access_event_mailing_write
    ),
    permissionSeatsIoKeyWrite: permissions.includes(
      allPermissions.access_seatsio_key_write
    ),
    places: getPlacesData(state),
    pools: getTablePools(state),
    reports: getReportSeatsIo(state),
    rundate: getSingleRundateData(state),
    selectedPlace: getSingleRundatePlace(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IFormLayoutFromDispatch => {
  return {
    addMailing: (eventId, rundateId) =>
      dispatch(
        selectEventMail(getInitialValues(undefined, eventId, rundateId))
      ),
    changeRundateHasExternalImage: (hasExternalImage) =>
      dispatch(changeRundateHasExternalImage(hasExternalImage)),
    changeRundateMonitorType: (id) => dispatch(changeRundateMonitorType(id)),
    changeSaleStatus: (forSale) => dispatch(changeRundateSale(forSale)),
    changeVisibilityStatus: (visible) =>
      dispatch(changeRundateVisibility(visible)),
    deleteAssets: (slug) => dispatch(deleteAssets(slug)),
    fetchRundateReport: (type) => dispatch(fetchRundateReport(type)),
    generatePdf: (rundateId, poolId) =>
      dispatch(generatePdf({ rundateId, poolId, download: true })),
    searchPlace: (text) => dispatch(searchPlace(text)),
  };
};

export default withRouter(
  connect<IFormLayoutFromState, IFormLayoutFromDispatch, {}, _Store.IState>(
    mapStateToProps,
    mapDispatchToProps
  )(FormLayout)
);
