import { createSelector } from 'reselect';

import _Store from '@Store';

import { IRefundsRegistration } from '@Services/$refunds-registration-api/types';

import { IRefundsRegistrationReducer } from './../types';
import get from './get';

const getRefundsRegistrationsList = createSelector<
  _Store.IState,
  IRefundsRegistrationReducer,
  IRefundsRegistration[]
>(
  [get],
  (refundsRegistration) => refundsRegistration.refundsRegistrations.data
);

export default getRefundsRegistrationsList;
