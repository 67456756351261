import React, { useEffect, useState } from 'react';

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

import Days from '@Compo/BetterManager/NewAddingConfiguration/components/Days';
import MuiDatePicker from '@Compo/reusable/MuiDatePicker';

import { makeDurationFromTime } from './FormLayout.helpes';
import { IFormLayoutProps } from './FormLayout.types';

const useStyles = makeStyles((theme) => ({
  inputTime: {
    '&::-webkit-calendar-picker-indicator': {
      filter:
        'invert(98%) sepia(1%) saturate(656%) hue-rotate(197deg) brightness(122%) contrast(100%)',
    },
  },
  label: {
    marginBottom: theme.spacing(2),
  },
}));

export const FormLayout = (props: IFormLayoutProps) => {
  const { t } = useTranslation('components', {
    keyPrefix:
      'BetterManager.NewDefinedConfigurations.components.TimeRule.Components.FormLayout',
  });

  const [endAfterMidnight, setEndAfterMidnight] = useState(false);
  const {
    handleSubmit,
    values,
    handleChange,
    setFieldValue,
    handleBlur,
    touched,
    errors,
    isCapacity,
  } = props;

  const { endTime, fromTime, id, cyclic, days } = values;

  const classes = useStyles();

  useEffect(() => {
    setFieldValue(
      'duration',
      makeDurationFromTime(fromTime, endTime, endAfterMidnight)
    );
  }, [endTime, fromTime, endAfterMidnight]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container={true}>
          <Grid item={true} xs={12} lg={6}>
            <Grid container={true} spacing={3}>
              <Grid item={true} xs={12}>
                <TextField
                  variant="standard"
                  id="title"
                  label={(touched.title && errors.title) || t('ruleName')}
                  helperText={t('rulesNameHelper')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  name="title"
                  error={!!(touched.title && errors.title)}
                  fullWidth={true}
                />
              </Grid>

              <Grid item={true} xs={12}>
                <FormControl variant="standard" component="fieldset">
                  <FormLabel component="legend" className={classes.label}>
                    {t('ruleType')}
                  </FormLabel>
                  <RadioGroup
                    name="cyclic"
                    value={values.cyclic}
                    onChange={(e) => {
                      setFieldValue(
                        'cyclic',
                        e.currentTarget.value === 'true' ? true : false
                      );
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      checked={values.cyclic === true}
                      control={<Radio size="small" color="primary" />}
                      label={t('cyclic')}
                    />
                    <FormControlLabel
                      value="false"
                      checked={values.cyclic === false}
                      control={<Radio size="small" color="primary" />}
                      label={t('oneTime')}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item={true} xs={12}>
                <FormControl
                  variant="standard"
                  component="fieldset"
                  fullWidth={true}
                >
                  <FormLabel component="legend" className={classes.label}>
                    {t('ruleHours')}
                  </FormLabel>
                  <Grid container={true} spacing={4}>
                    <Grid item={true} xs={12} lg={6}>
                      <TextField
                        variant="standard"
                        id="fromTime"
                        label={
                          (touched.fromTime && errors.fromTime) ||
                          (touched.fromTime && errors.duration) ||
                          t('startLabel')
                        }
                        helperText={t('startLabelHelper')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.fromTime}
                        name="fromTime"
                        error={
                          !!(touched.fromTime && errors.fromTime) ||
                          !!(touched.fromTime && errors.duration)
                        }
                        fullWidth={true}
                        type="time"
                        className={classes.inputTime}
                        inputProps={{ className: classes.inputTime }}
                      />
                    </Grid>
                    <Grid item={true} xs={12} lg={6}>
                      <TextField
                        variant="standard"
                        id="endTime"
                        label={
                          (touched.endTime && errors.endTime) ||
                          (touched.endTime && errors.duration) ||
                          t('endLabel')
                        }
                        helperText={t('endLabelHelper')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.endTime}
                        name="endTime"
                        error={
                          !!(touched.endTime && errors.endTime) ||
                          !!(touched.endTime && errors.duration)
                        }
                        fullWidth={true}
                        type="time"
                        inputProps={{ className: classes.inputTime }}
                      />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={endAfterMidnight}
                            onClick={() =>
                              setEndAfterMidnight(!endAfterMidnight)
                            }
                            color="primary"
                            size="medium"
                          />
                        }
                        label={
                          <Typography variant="body1">
                            {t('endAfterMidnight')}
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>

              {cyclic && (
                <Grid item={true} xs={12}>
                  <FormControl
                    variant="standard"
                    component="fieldset"
                    fullWidth={true}
                  >
                    <FormLabel component="legend" className={classes.label}>
                      {t('ruleDays')}
                    </FormLabel>
                    <Days name="days" onChange={handleChange} value={days} />
                    <input type="hidden" defaultValue={days} />
                  </FormControl>
                </Grid>
              )}

              <Grid item={true} xs={12}>
                <FormControl
                  variant="standard"
                  component="fieldset"
                  fullWidth={true}
                >
                  <FormLabel component="legend" className={classes.label}>
                    {cyclic ? t('ruleTime') : t('ruleOneTime')}
                  </FormLabel>
                  <Grid container={true} spacing={4}>
                    {cyclic ? (
                      <>
                        <Grid item={true} xs={12} lg={6}>
                          <MuiDatePicker
                            name="validFrom"
                            disableToolbar={true}
                            fullWidth={true}
                            variant="standard"
                            format="DD.MM.yyyy"
                            label={
                              (touched.validFrom && errors.validFrom) ||
                              t('startDate')
                            }
                            helperText={t('startDateHelper')}
                            value={values.validFrom}
                            onChange={(date) =>
                              setFieldValue('validFrom', date)
                            }
                          />
                        </Grid>
                        <Grid item={true} xs={12} lg={6}>
                          <MuiDatePicker
                            name="validTo"
                            disableToolbar={true}
                            fullWidth={true}
                            variant="standard"
                            format="DD.MM.yyyy"
                            label={
                              (touched.validTo && errors.validTo) ||
                              t('endDate')
                            }
                            helperText={t('endDateHelper')}
                            value={values.validTo}
                            onChange={(date) => setFieldValue('validTo', date)}
                            onBlur={handleBlur}
                          />
                        </Grid>
                      </>
                    ) : (
                      <Grid item={true} xs={12}>
                        <MuiDatePicker
                          name="until"
                          disableToolbar={true}
                          fullWidth={true}
                          variant="standard"
                          format="DD.MM.yyyy"
                          label={
                            (touched.until && errors.until) ||
                            t('ruleOneTimeInput')
                          }
                          helperText={t('ruleOneTimeInputHelper')}
                          value={values.until}
                          onChange={(date) => {
                            setFieldValue('until', date);
                            setFieldValue('validTo', date);
                          }}
                          onBlur={handleBlur}
                        />
                      </Grid>
                    )}
                  </Grid>
                </FormControl>
              </Grid>
              {isCapacity && (
                <Grid item={true} xs={12}>
                  <FormControl
                    variant="standard"
                    component="fieldset"
                    fullWidth={true}
                  >
                    <FormLabel component="legend" className={classes.label}>
                      {t('ruleCapacityLabel')}
                    </FormLabel>
                    <TextField
                      variant="standard"
                      id="capacity"
                      label={
                        (touched.capacity && errors.capacity) ||
                        t('ruleCapacity')
                      }
                      helperText={t('ruleCapacityHelper')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.capacity}
                      name="capacity"
                      error={!!(touched.capacity && errors.capacity)}
                      type="number"
                      fullWidth={true}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FormLayout;
