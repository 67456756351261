import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import {
  getEventsPartner,
  getPartners,
  getPoolsPartner,
  getRundatesPartner,
} from '@Model/discount/actions';
import {
  getEventsPartner as getEventsPartnerMetadata,
  getPartners as getPartnersMetadata,
  getPoolsPartner as getPoolsPartnerMetadata,
  getRundatesPartner as getRundatesPartnerMetadata,
} from '@Model/metadata/actions';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { resetError, resetLoading, setLoading } from '../actions';
import { DEPENDENCIES } from '../constants/constants';

export const setUpStateWhenDependenciesFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getPartners.request,
        getEventsPartner.request,
        getRundatesPartner.request,
        getPoolsPartner.request,
        getPartnersMetadata.request,
        getEventsPartnerMetadata.request,
        getRundatesPartnerMetadata.request,
        getPoolsPartnerMetadata.request,
      ])
    ),
    mergeMap$(() => [resetError(DEPENDENCIES), setLoading(DEPENDENCIES)])
  );
};

export const setLoaderWhenDependenciesFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [
          getPartners.success,
          getPartners.failure,
          getEventsPartner.success,
          getEventsPartner.failure,
          getRundatesPartner.success,
          getRundatesPartner.failure,
          getPoolsPartner.success,
          getPoolsPartner.failure,
          getPartnersMetadata.success,
          getPartnersMetadata.failure,
          getEventsPartnerMetadata.success,
          getEventsPartnerMetadata.failure,
          getRundatesPartnerMetadata.success,
          getRundatesPartnerMetadata.failure,
          getPoolsPartnerMetadata.success,
          getPoolsPartnerMetadata.failure,
        ],
        action
      )
    ),
    map$(() => resetLoading(DEPENDENCIES))
  );
};

export const setErrorOnDependenciesFailure: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getPartners.failure,
        getEventsPartner.failure,
        getRundatesPartner.failure,
        getPoolsPartner.failure,
        getPartnersMetadata.failure,
        getEventsPartnerMetadata.failure,
        getRundatesPartnerMetadata.failure,
        getPoolsPartnerMetadata.failure,
      ])
    ),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
