// Private
export const _GET_TOKEN_REQUEST = 'Zagrywki/start/_GET_TOKEN_REQUEST';
export const _GET_TOKEN_SUCCESS = 'Zagrywki/start/_GET_TOKEN_SUCCESS';
export const _GET_TOKEN_FAILURE = 'Zagrywki/start/_GET_TOKEN_FAILURE';

export const _SET_SESSION_REQUEST = 'Zagrywki/start/_SET_SESSION_REQUEST';
export const _SET_SESSION_SUCCESS = 'Zagrywki/start/_SET_SESSION_SUCCESS';
export const _SET_SESSION_FAILURE = 'Zagrywki/start/_SET_SESSION_FAILURE';

export const _END_SESSION_REQUEST = 'Zagrywki/start/_END_SESSION_REQUEST';
export const _END_SESSION_SUCCESS = 'Zagrywki/start/_END_SESSION_SUCCESS';
export const _END_SESSION_FAILURE = 'Zagrywki/start/_END_SESSION_FAILURE';

export const _SIGN_IN_REQUEST = 'Zagrywki/start/_SIGN_IN_REQUEST';
export const _SIGN_IN_SUCCESS = 'Zagrywki/start/_SIGN_IN_SUCCESS';
export const _SIGN_IN_FAILURE = 'Zagrywki/start/_SIGN_IN_FAILURE';

export const _SIGN_IN_WITH_FIREBASE_REQUEST =
  'Zagrywki/start/_SIGN_IN_WITH_FIREBASE_REQUEST';
export const _SIGN_IN_WITH_FIREBASE_SUCCESS =
  'Zagrywki/start/_SIGN_IN_WITH_FIREBASE_SUCCESS';
export const _SIGN_IN_WITH_FIREBASE_FAILURE =
  'Zagrywki/start/_SIGN_IN_WITH_FIREBASE_FAILURE';

export const _FETCH_AUTHORIZATION_CASHER_INFO_REQUEST =
  'Zagrywki/start/_FETCH_AUTHORIZATION_CASHER_INFO_REQUEST';
export const _FETCH_AUTHORIZATION_CASHER_INFO_SUCCESS =
  'Zagrywki/start/_FETCH_AUTHORIZATION_CASHER_INFO_SUCCESS';
export const _FETCH_AUTHORIZATION_CASHER_INFO_FAILURE =
  'Zagrywki/start/_FETCH_AUTHORIZATION_CASHER_INFO_FAILURE';

export const _CHECK_AUTHORIZATION_REQUEST =
  'Zagrywki/start/_CHECK_AUTHORIZATION_REQUEST';
export const _CHECK_AUTHORIZATION_SUCCESS =
  'Zagrywki/start/_CHECK_AUTHORIZATION_SUCCESS';
export const _CHECK_AUTHORIZATION_FAILURE =
  'Zagrywki/start/_CHECK_AUTHORIZATION_FAILURE';

export const _REFRESH_TOKEN_REQUEST = 'Zagrywki/start/_REFRESH_TOKEN_REQUEST';
export const _REFRESH_TOKEN_SUCCESS = 'Zagrywki/start/_REFRESH_TOKEN_SUCCESS';
export const _REFRESH_TOKEN_FAILURE = 'Zagrywki/start/_REFRESH_TOKEN_FAILURE';

export const _FETCH_SESSION_REQUEST = 'Zagrywki/start/_FETCH_SESSION_REQUEST';
export const _FETCH_SESSION_SUCCESS = 'Zagrywki/start/_FETCH_SESSION_SUCCESS';
export const _FETCH_SESSION_FAILURE = 'Zagrywki/start/_FETCH_SESSION_FAILURE';

// Public
export const AUTHORIZATION_FAIL = 'Zagrywki/start/AUTHORIZATION_FAIL';
export const HANDLE_USER_PERMISSIONS = 'Zagrywki/start/HANDLE_USER_PERMISSIONS';
export const RUN_SESSION = 'Zagrywki/start/RUN_SESSION';
export const END_SESSION = 'Zagrywki/start/END_SESSION';
export const END_USER_SESSION = 'Zagrywki/start/END_USER_SESSION';
export const END_SESSION_BY_PRINTER_ID =
  'Zagrywki/start/END_SESSION_BY_PRINTER_ID';
export const SHOW_MODAL = 'Zagrywki/start/SHOW_MODAL';
export const CLOSE_MODAL = 'Zagrywki/start/CLOSE_MODAL';
export const ENCODE_JWT_TOKEN = 'Zagrywki/start/ENCODE_JWT_TOKEN';
export const SET_AUTHORIZATION_STATE = 'Zagrywki/start/SET_AUTHORIZATION_STATE';
export const MOUNTED = 'Zagrywki/start/MOUNTED';
export const SET_AUTHORIZATION_HEADER =
  'Zagrywki/start/SET_AUTHORIZATION_HEADER';
export const REMOVE_AUTHORIZATION_HEADER =
  'Zagrywki/start/REMOVE_AUTHORIZATION_HEADER';
export const HANDLE_PAYMENT_MODAL = 'Zagrywki/start/HANDLE_PAYMENT_MODAL';

export const LOGOUT = 'Zagrywki/start/LOGOUT';
export const SET_PARAMS = 'Zagrywki/start/SET_PARAMS';
