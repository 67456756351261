import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import _Store from '@Store';

import {
  catchEditRefundRegistration,
  handleActiveRefundRegistration,
} from '@Model/refundsRegistration/actions';
import { getRefundsRegistrationSingle } from '@Model/refundsRegistration/selectors';

import RefundsRegistrationModal from './RefundsRegistrationModal.component';
import {
  IRefundsRegistrationModalFromDispatch,
  IRefundsRegistrationModalFromState,
} from './RefundsRegistrationModal.types';

const mapStateToProps = (
  state: _Store.IState
): IRefundsRegistrationModalFromState => ({
  activeRefundsRegistration: getRefundsRegistrationSingle(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch
): IRefundsRegistrationModalFromDispatch => ({
  handleClose: () => dispatch(handleActiveRefundRegistration(null)),
  submit: (values) => dispatch(catchEditRefundRegistration(values)),
});

export default connect<
  IRefundsRegistrationModalFromState,
  IRefundsRegistrationModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(RefundsRegistrationModal);
