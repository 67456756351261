import { getType } from 'typesafe-actions';
import { clearFilters, setFilters } from './../actions';
import { IAction, IReservationsFiltersReducer } from './../types';

const initialState: IReservationsFiltersReducer = [];

const filtersReducer = (
  state: IReservationsFiltersReducer = initialState,
  action: IAction,
): IReservationsFiltersReducer => {
  switch (action.type) {
    case getType(setFilters):
      return action.payload;
    case getType(clearFilters):
      return action.payload;
    default:
      return state;
  }
};

export default filtersReducer;
