import {
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import _Store from '@Store';

import {
  editMultipleRefundRegistrationStatuses,
  editRefundRegistration,
  getRefundsRegistrations,
} from '@Model/refundsRegistration/actions';
import { REFUNDS_REGISTRATION } from '@Model/state/constants';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR } from '@Model/toasts/constants/constants';

import { resetError, resetLoading, setLoading } from './../actions';

export const setUpStateWhenRefundsRegistrationsFetchRequested: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(isActionOf(getRefundsRegistrations.request)),
    mergeMap$(() => [
      resetError(REFUNDS_REGISTRATION),
      setLoading(REFUNDS_REGISTRATION),
    ])
  );
};

export const setLoaderWhenRefundsRegistrationsFetchFinished: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$((action: _Store.IAction) =>
      isActionOf(
        [getRefundsRegistrations.success, getRefundsRegistrations.failure],
        action
      )
    ),
    map$(() => resetLoading(REFUNDS_REGISTRATION))
  );
};

export const setErrorOnRefundsRegistrationsFailure: _Store.IEpic = (
  action$
) => {
  return action$.pipe(
    filter$(
      isActionOf([
        getRefundsRegistrations.failure,
        editRefundRegistration.failure,
        editMultipleRefundRegistrationStatuses.failure,
      ])
    ),
    map$((action) => addToast(action.payload.message, TYPE_ERROR))
  );
};
