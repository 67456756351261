import React from 'react';

import { Text, View } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import styles from './TableHead.styles';
import { ITableHeadProps } from './TableHead.types';

const TableHead = ({ categoryReport, title }: ITableHeadProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'ReportSeatsIo.components.PdfDocument',
  });

  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View style={styles.flex}>
        <Text style={styles.subtitle}>{`${t('occupied')}${
          categoryReport.summary.booked + categoryReport.summary.reserved
        } (${categoryReport.summary.booked}/${
          categoryReport.summary.reserved
        })`}</Text>
        <Text style={styles.subtitle}>{`${t('free')}${
          categoryReport.summary.free
        }`}</Text>
        <Text style={styles.subtitle}>{`${t('notForSale')}${
          categoryReport.summary.notForSale
        }`}</Text>
        <Text style={styles.subtitle}>{`${t('duble')}${
          categoryReport.summary.overbooking
        }`}</Text>
      </View>
    </View>
  );
};

export default TableHead;
