import { createAsyncAction, createStandardAction } from 'typesafe-actions';

import { IFormIoUserAdmin, IOldSubmission } from '@Services/$formio-api/types';

import * as ACTIONS from '../constants/actions';
import {
  IData,
  IFormIoSaveUsersResponse,
  IGetUsersFromOwnDatabaseSuccess,
  IGetUsersSmsEnterFormSuccessPayload,
  IGetUsersSuccessPayload,
  ISingleUser,
  ISingleUserResponse,
  ISubmission,
} from '../types';

export const getToken = createAsyncAction(
  ACTIONS._GET_TOKEN_REQUEST,
  ACTIONS._GET_TOKEN_SUCCESS,
  ACTIONS._GET_TOKEN_FAILURE
)<undefined, string, Error>();

export const getUsers = createAsyncAction(
  ACTIONS._GET_REQUEST,
  ACTIONS._GET_SUCCESS,
  ACTIONS._GET_FAILURE
)<undefined, IGetUsersSuccessPayload, Error>();

export const getSingleUser = createAsyncAction(
  ACTIONS._GET_SINGLE_USER_REQUEST,
  ACTIONS._GET_SINGLE_USER_SUCCESS,
  ACTIONS._GET_SINGLE_USER_FAILURE
)<number, ISingleUserResponse, Error>();

export const updateSingleUser = createAsyncAction(
  ACTIONS._UPDATE_SINGLE_USER_REQUEST,
  ACTIONS._UPDATE_SINGLE_USER_SUCCESS,
  ACTIONS._UPDATE_SINGLE_USER_FAILURE
)<ISingleUser, [], Error>();

export const getAvailableUsers = createAsyncAction(
  ACTIONS._GET_AVAILABLE_USERS_REQUEST,
  ACTIONS._GET_AVAILABLE_USERS_SUCCESS,
  ACTIONS._GET_AVAILABLE_USERS_FAILURE
)<undefined, IGetUsersFromOwnDatabaseSuccess, Error>();

export const getEntryTokens = createAsyncAction(
  ACTIONS._GET_ENTRY_TOKENS_REQUEST,
  ACTIONS._GET_ENTRY_TOKENS_SUCCESS,
  ACTIONS._GET_ENTRY_TOKENS_FAILURE
)<undefined, IGetUsersSmsEnterFormSuccessPayload, Error>();

export const addEntryCodeRequest = createAsyncAction(
  ACTIONS._ADD_ENTRY_CODE_REQUEST,
  ACTIONS._ADD_ENTRY_CODE_SUCCESS,
  ACTIONS._ADD_ENTRY_CODE_FAILURE
)<string, string, Error>();

export const editUserData = createAsyncAction(
  ACTIONS._EDIT_USER_REQUEST,
  ACTIONS._EDIT_USER_SUCCESS,
  ACTIONS._EDIT_USER_FAILURE
)<IData, string, Error>();

export const getEntryFormsFormIo = createAsyncAction(
  ACTIONS._GET_ENTRY_FORM_REQUEST,
  ACTIONS._GET_ENTRY_FORM_SUCCESS,
  ACTIONS._GET_ENTRY_FORM_FAILURE
)<undefined, ISubmission, Error>();

export const saveUserReservation = createAsyncAction(
  ACTIONS._SAVE_USER_RESERVATION_REQUEST,
  ACTIONS._SAVE_USER_RESERVATION_SUCCESS,
  ACTIONS._SAVE_USER_RESERVATION_FAILURE
)<undefined, IFormIoSaveUsersResponse, Error>();

export const searchUser = createAsyncAction(
  ACTIONS._SEARCH_USER_REQUEST,
  ACTIONS._SEARCH_USER_SUCCESS,
  ACTIONS._SEARCH_USER_FAILURE
)<undefined, ISingleUser, Error>();

export const checkUser = createAsyncAction(
  ACTIONS._CHECK_USER_EXIST_REQUEST,
  ACTIONS._CHECK_USER_EXIST_SUCCESS,
  ACTIONS._CHECK_USER_EXIST_FAILURE
)<undefined, string, Error>();

export const detachUser = createAsyncAction(
  ACTIONS._DETACH_USER_REQUEST,
  ACTIONS._DETACH_USER_SUCCESS,
  ACTIONS._DETACH_USER_FAILURE
)<undefined, string, Error>();

export const attachEntryGroupUser = createAsyncAction(
  ACTIONS._ATTACH_ENTRY_GROUP_REQUEST,
  ACTIONS._ATTACH_ENTRY_GROUP_SUCCESS,
  ACTIONS._ATTACH_ENTRY_GROUP_FAILURE
)<undefined, string, Error>();

export const getReservationUsers = createStandardAction(
  ACTIONS.GET_RESERVATION_USERS
)();

export const addOldUser = createStandardAction(
  ACTIONS.ADD_OLD_DATA_USER
)<IOldSubmission>();
export const getFormioUser = createStandardAction(ACTIONS.GET_FORMIO_USER)();
export const getFormIOUsers = createStandardAction(
  ACTIONS.GET_AVAILABLE_USERS_BY_EMAIL
)();
export const resetState = createStandardAction(ACTIONS.RESET_STATE)();
export const loginFormIo = createStandardAction(ACTIONS.LOGIN_FORM_IO)();
export const resetOldUserData = createStandardAction(
  ACTIONS.RESET_OLD_DATA_USER
)();
export const addUser = createStandardAction(ACTIONS.ADD_USER)<ISingleUser>();
export const removeUser = createStandardAction(ACTIONS.REMOVE_USER)<string>();
export const setUser = createStandardAction(
  ACTIONS.SET_USER
)<IFormIoUserAdmin>();
export const mounted = createStandardAction(ACTIONS.MOUNTED)<number>();
export const handleActivePageConsentsList = createStandardAction(
  ACTIONS.HANDLE_ACTIVE_PAGE_CONSENTS_LIST
)<number>();
export const setEntryIdEdit = createStandardAction(
  ACTIONS.SET_ENTRY_ID_EDIT
)<number>();
export const closeModal = createStandardAction(ACTIONS.CLOSE_MODAL)();
export const addEntryCode = createStandardAction(
  ACTIONS.ADD_ENTRY_CODE
)<string>();
export const catchSaveUser = createStandardAction(
  ACTIONS.CATCH_SAVE_USER
)<IData>();

export const setEmail = createStandardAction(ACTIONS.SET_EMAIL)<string>();
export const setFirstName = createStandardAction(
  ACTIONS.SET_FIRST_NAME
)<string>();
export const setLastName = createStandardAction(
  ACTIONS.SET_LAST_NAME
)<string>();
export const setDate = createStandardAction(ACTIONS.SET_DATE)<string>();

export const attachEntryGroup = createStandardAction(
  ACTIONS.ATTACH_ENTRY_GROUP
)<string>();

export const updateUser = createStandardAction(
  ACTIONS.ATTACH_ENTRY_GROUP
)<ISingleUser>();
