const makeDurationFromPosition = (
  start: number,
  stop: number,
  days: number,
  q: number,
) => {
  let duration;

  if (days > 0) {
    duration = 24 * q - start + stop + (days - 1) * 24 * q;
  } else {
    duration = stop - start;
  }

  return `${Math.floor(duration / q)}H${(duration % q) * (60 / q)}M`;
};

export default makeDurationFromPosition;
