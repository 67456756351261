import _Store from '@Store';
import { createSelector } from 'reselect';
import getApp from './getApp';

const getToken = createSelector(
  [getApp],
  (app) => app.token,
);

export default getToken;
