import { createSelector } from 'reselect';

import { IPartner } from '@Services/$discounts-api/types';
import _Store from '@Store';
import { IDiscountReducer } from './../types';
import get from './get';

const getPartners = createSelector<_Store.IState, IDiscountReducer, IPartner[]>(
  [get],
  (discount) => {
    return discount.partners;
  }
);

export default getPartners;
