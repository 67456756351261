import { createSelector } from 'reselect';

import _Store from '@Store';
import { ILocalProduct, IProductsReducer } from '../types';
import get from './get';

const getZohoProducts = createSelector<
  _Store.IState,
  IProductsReducer,
  ILocalProduct[]
>([get], (products) => products.zohoProducts);

export default getZohoProducts;
