import { combineReducers } from 'redux';

import availabilitiesReducer from './availabilities';
import happeningReducer from './happening';

const reducer = combineReducers({
  availabilities: availabilitiesReducer,
  happening: happeningReducer,
});

export default reducer;
