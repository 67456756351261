import { createSelector } from 'reselect';

import _Store from '@Store';

import { IMeta } from '@Services/$pool-descriptions-api/types';

import { IPoolDescriptionsReducer } from './../types';
import get from './get';

const getMeta = createSelector<_Store.IState, IPoolDescriptionsReducer, IMeta>(
  [get],
  (poolDescription) => poolDescription.meta
);

export default getMeta;
