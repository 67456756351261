import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  item: {
    backgroundColor: theme.palette.action.focus,
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
