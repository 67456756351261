import config from '@Config';
import { Currency } from '@Constants/Currency';

const getCurrencyFromCode = (currencyCode: string): string => {
  switch (currencyCode) {
    case Currency.PLN:
      return 'zł';
    case Currency.EUR:
      return '€';
    case Currency.GBP:
      return '£';
    case Currency.USD:
      return '$';
    default:
      return config.cms.defaultCurrency;
  }
};

export default getCurrencyFromCode;
