import { ITickets } from '@Services/$going-rundate-api/types';

import { IFormValues } from './Form.types';

export const getInitialValues = (values?: ITickets): IFormValues => {
  if (values) {
    return {
      code: values.entryToken,
      verified: values.validated,
    };
  }

  return {
    code: '',
    verified: false,
  };
};
