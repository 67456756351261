export const _GET_TRANSACTIONS_REQUEST =
  'Places/transactions/_GET_TRANSACTIONS_REQUEST';
export const _GET_TRANSACTIONS_SUCCESS =
  'Places/transactions/_GET_TRANSACTIONS_SUCCESS';
export const _GET_TRANSACTIONS_FAILURE =
  'Places/transactions/_GET_TRANSACTIONS_FAILURE';

export const MOUNTED = 'Places/transactions/MOUNTED';
export const CHANGE_FILTER = 'Places/transactions/CHANGE_FILTER';
export const HANDLE_ACTIVE_PAGE = 'Places/transactions/HANDLE_ACTIVE_PAGE';
export const CHANGE_SIZE = 'Places/transactions/CHANGE_SIZE';
export const SET_ACTIVE_TRANSACTION = 'Places/transactions/SET_ACTIVE_TRANSACTION';
