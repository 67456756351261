import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import cn from 'classnames';
import { Formik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Loader from '@Compo/layout/Loader/Loader';
import GoingPaper from '@Compo/layout/Paper/GoingPaper';
import GenericTable from '@Compo/reusable/GenericTable';
import TableActions from '@Compo/reusable/TableActions';
import TableUi from '@Compo/reusable/TableUi';
import parseStringToBool from '@Misc/helpers/parseStringToBool';
import { ISettlement, ISettlementFilters } from '@Model/settlements/types';

import Filters from './Filters';
import useStyles from './Settlements.styles';
import { ISettlementsProps } from './Settlements.types';
import SettlementsAddModal from './SettlementsAddModal';
import { defaultValues } from './SettlementsAddModal/SettlementsAddModal.helpers';
import SingleSettlementModal from './SingleSettlementModal';

const Settlements = ({
  fetchSettlement,
  handleActiveSettlement,
  handleChangePage,
  mounted,
  settlements,
  meta,
  isLoading,
  filterInitialValues,
  filterValues,
  changeFilterSettlement,
  searchSettlement,
  catchGenerateGroupReport,
}: ISettlementsProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Settlements',
  });

  const classes = useStyles();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [filtersActive, setFiltersActive] = useState(false);
  const [showGroupReports, setShowGroupReports] = useState(false);
  const [searchTimeOut, setSearchTimeOut] = useState<NodeJS.Timeout | null>(
    null
  );
  const [inputText, setInputText] = useState(filterValues.invoice);
  const [open, setOpen] = useState(false);
  const [localSelected, setLocalSelected] = useState<ISettlement[]>([]);

  useEffect(() => {
    mounted();
  }, []);

  useEffect(() => {
    setShowGroupReports(parseStringToBool(filterValues.accepted) || false);
  }, [filterValues.accepted]);

  useEffect(() => {
    setFiltersActive(
      JSON.stringify(filterValues) !== JSON.stringify(filterInitialValues)
    );

    setInputText(filterValues.invoice);

    setShowFilters(false);
  }, [filterValues]);

  useEffect(() => {
    if (!showGroupReports) {
      setLocalSelected([]);
    }
  }, [showGroupReports]);

  const catchChangePage = (event: unknown, page: number) => {
    handleChangePage(page + 1);
  };

  const catchEditSettlement = (id: string) => {
    fetchSettlement(id);
  };

  const searchFunc = (text: string) => {
    if (searchTimeOut) {
      clearTimeout(searchTimeOut);
    }
    setSearchTimeOut(
      setTimeout(() => {
        searchSettlement({ ...filterValues, invoice: text || undefined });
      }, 1000)
    );
  };

  const catchHandleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setInputText(e.currentTarget.value);
    searchFunc(e.currentTarget.value);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const setOwnSelected = (selected: ISettlement[]) => {
    setLocalSelected(selected);
  };

  const generateGroupReport = (reportTypeId: number) => {
    const settlementUuids = localSelected.map((settlement) => settlement.uuid);

    catchGenerateGroupReport({
      reportTypeId,
      settlementUuids,
    });
  };

  // TODO: translate
  const availableReportGroupTypes = [
    { id: 4, name: 'Grupowy raport rozliczeniowy' },
    { id: 6, name: 'Grupowy raport rozliczeniowy Places' },
  ];

  const columns = [
    { id: 'id', label: t('id') },
    { id: 'name', label: t('name') },
    { id: 'typeId', label: t('settlementsType') },
    { id: 'startDate', label: t('startDate') },
    { id: 'endDate', label: t('endDate') },
    { id: 'transactionType', label: t('transactionType') },
    { id: 'salesChannel', label: t('salesChannel') },
    { id: 'isAccepted', label: t('accept') },
    { id: 'settlementAmount', label: t('settlementsAmount') },
    { id: 'invoice', label: t('invoiceNumber') },
    { id: 'updatedAt', label: t('updatedDate') },
    { id: 'createdAt', label: t('createdDate') },
    { id: 'createdBy', label: t('createdBy') },
    { id: 'acceptedBy', label: t('acceptedBy') },
    { id: 'actions', label: t('actions') },
  ];

  const PLZlotyLocale = Intl.NumberFormat('pl', {
    currency: 'PLN',
    style: 'currency',
  });

  const normalizedSettlements = settlements.map((settlement) => ({
    ...settlement,
    actions: (
      <Button
        variant="outlined"
        size="small"
        color="primary"
        className={cn(classes.button, classes.buttonWithoutPadding)}
        onClick={(e) => {
          e.stopPropagation();
          fetchSettlement(settlement.uuid);
        }}
      >
        {t('edit')}
      </Button>
    ),
    createdAt: moment(settlement.updatedAt).format('DD.MM.YYYY'),
    endDate: moment(settlement.endDate).format('DD.MM.YYYY'),
    id: settlement.uuid,
    salesChannel: settlement.salesChannel.name,
    settlementAmount:
      settlement.settlementAmount &&
      PLZlotyLocale.format(settlement.settlementAmount / 100),
    startDate: moment(settlement.startDate).format('DD.MM.YYYY'),
    transactionType: settlement.transactionType.name,
    typeId: settlement.settlementType.name,
    updatedAt: moment(settlement.updatedAt).format('DD.MM.YYYY'),
  }));

  const addNewSettlement = () => {
    handleActiveSettlement(defaultValues);
  };

  const catchHandleFiltersSubmit = (filter: ISettlementFilters) => {
    changeFilterSettlement(filter);
    setShowFilters(false);
  };

  return (
    <GoingPaper>
      <SettlementsAddModal />
      <SingleSettlementModal />
      {isLoading ? (
        <Loader className={classes.loader} />
      ) : (
        <GenericTable>
          <TableActions>
            <TextField
              variant="standard"
              name="search"
              helperText={t('search')}
              value={inputText}
              onChange={catchHandleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="secondary" />
                  </InputAdornment>
                ),
              }} />
            <Grid container={true} justifyContent="flex-end" spacing={2}>
              <Grid item={true}>
                {showGroupReports && (
                  <>
                    <ButtonGroup>
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        className={classes.button}
                        ref={anchorRef}
                        onClick={handleToggle}
                        disabled={localSelected.length <= 0}
                      >
                        {t('generateButton')}
                      </Button>
                      <Button
                        size="small"
                        color="secondary"
                        variant="outlined"
                        className={classes.button}
                        onClick={handleToggle}
                        disabled={localSelected.length <= 0}
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </Button>
                    </ButtonGroup>
                    <Popper
                      open={open}
                      transition={true}
                      disablePortal={true}
                      anchorEl={anchorRef.current}
                      placement="bottom-start"
                      className={classes.popper}
                    >
                      {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList>
                                {availableReportGroupTypes.map((type) => (
                                  <MenuItem
                                    key={type.id}
                                    onClick={() => generateGroupReport(type.id)}
                                  >
                                    {type.name}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                )}
              </Grid>
              <Grid item={true}>
                <Button
                  variant="outlined"
                  size="small"
                  color={filtersActive ? 'primary' : 'secondary'}
                  startIcon={<TuneIcon />}
                  className={cn(classes.button)}
                  onClick={() => setShowFilters(!showFilters)}
                >
                  {t('filter')}
                </Button>
              </Grid>
              <Grid item={true}>
                <Button
                  variant="outlined"
                  size="small"
                  color="secondary"
                  startIcon={<AddIcon />}
                  className={cn(classes.button)}
                  onClick={addNewSettlement}
                >
                  {t('add')}
                </Button>
              </Grid>
            </Grid>
          </TableActions>
          {showFilters && (
            <Grid
              container={true}
              justifyContent="flex-end"
              className={classes.filtersContainer}
            >
              <Formik
                component={Filters}
                onSubmit={catchHandleFiltersSubmit}
                initialValues={filterValues}
              />
            </Grid>
          )}
          <TableUi
            rows={normalizedSettlements}
            handleChangePage={catchChangePage}
            columns={columns}
            disableCheckBox={!showGroupReports}
            onRowClick={catchEditSettlement}
            total={meta.total}
            activePage={meta.page - 1}
            rowsPerPage={meta.size}
            setOwnSelected={setOwnSelected}
            selectedItems={localSelected}
            forceCheckboxSelection={true}
          />
        </GenericTable>
      )}
    </GoingPaper>
  );
};

export default Settlements;
