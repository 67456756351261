import { getType } from 'typesafe-actions';
import { getApartments } from '../actions';
import { IAction, ISmarporterReducer } from '../types';

const initialState: ISmarporterReducer = {
  apartments: [],
};

const apartmentReducer = (
  state: ISmarporterReducer = initialState,
  action: IAction,
): ISmarporterReducer => {
  switch (action.type) {
    case getType(getApartments.success):
      return {
        ...state,
        apartments: action.payload.items,
      };
    default:
      return state;
  }
};

export default apartmentReducer;
