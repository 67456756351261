import { createSelector } from 'reselect';

import _Store from '@Store';

import { IMeta } from '@Services/$tickets-api/types';

import { ITransactionsReducer } from '../types';
import get from './get';

const getMeta = createSelector<_Store.IState, ITransactionsReducer, IMeta>(
  [get],
  (transactions) => transactions.meta
);

export default getMeta;
