import { createSelector } from 'reselect';

import { IMeta } from '@Services/$going-rundate-api/types';
import _Store from '@Store';

import { IRundatesReducer } from '../types';

import getRundates from './getRundates';

const getRundatesMeta = createSelector<_Store.IState, IRundatesReducer, IMeta>(
  [getRundates],
  (rundates) => rundates.meta
);

export default getRundatesMeta;
