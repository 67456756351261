import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { hideSuccessModal } from '@Model/modal/actions';
import {
  getSuccessMessageModal,
  getSuccessModal,
  getSuccessOnConfirmModal,
  getSuccessTitleModal,
} from '@Model/modal/selectors';

import SuccessModal from './SuccessModal.component';
import {
  ISuccessModalFromDispatch,
  ISuccessModalFromState,
} from './SuccessModal.types';

const mapStateToProps = (state: _Store.IState): ISuccessModalFromState => {
  return {
    message: getSuccessMessageModal(state),
    onConfirm: getSuccessOnConfirmModal(state),
    showModal: getSuccessModal(state),
    title: getSuccessTitleModal(state),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): ISuccessModalFromDispatch => ({
  closeModal: () => dispatch(hideSuccessModal()),
  dispatch,
});

export default connect<
  ISuccessModalFromState,
  ISuccessModalFromDispatch,
  {},
  _Store.IState
>(
  mapStateToProps,
  mapDispatchToProps
)(SuccessModal);
