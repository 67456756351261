const ID_TEXT = 'ID';
const PARTNER_TEXT = 'Partner';
const EVENT_TEXT = 'Wydarzenie';
const RUNDATE_TEXT = 'Rundate';
const EVENT_ID_TEXT = 'ID Wydarzenia';
const EVENT_NAME_TEXT = 'Wydarzenie';
const POOL_TEXT = 'Pula';

export const dialogTitles = {
  event: 'Dodaj wydarzenia',
  partner: 'Dodaj partnera',
  pool: 'Dodaj pulę',
  rundate: 'Dodaj rundate',
};

export const columns = {
  event: [
    { id: 'id', label: ID_TEXT },
    { id: 'titlePL', label: EVENT_TEXT },
  ],
  partner: [
    { id: 'id', label: ID_TEXT },
    { id: 'name', label: PARTNER_TEXT },
  ],
  pool: [
    { id: 'id', label: ID_TEXT },
    { id: 'name', label: POOL_TEXT },
    { id: 'rundate.rundate', label: RUNDATE_TEXT },
    { id: 'rundate.event.id', label: EVENT_ID_TEXT },
    { id: 'rundate.event.titlePL', label: EVENT_NAME_TEXT },
  ],
  rundate: [
    { id: 'id', label: ID_TEXT },
    { id: 'rundate', label: RUNDATE_TEXT },
    { id: 'event.id', label: EVENT_ID_TEXT },
    { id: 'event.titlePL', label: EVENT_NAME_TEXT },
  ],
};
