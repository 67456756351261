import React from 'react';

import { Dialog } from '@mui/material';
import EventDiscountForm from './components/EventDiscountForm';

import { IEventDiscountModalProps } from './EventDiscountModal.types';

const EventDiscountModal = ({
  showModal,
  closeModal,
}: IEventDiscountModalProps) => {
  return (
    <Dialog
      open={showModal}
      maxWidth="md"
      fullWidth={true}
      onClose={closeModal}
      scroll="paper"
    >
      <EventDiscountForm />
    </Dialog>
  );
};

export default EventDiscountModal;
