import { LOCATION_CHANGE } from 'connected-react-router';
import { from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  map as map$,
  mergeMap as mergeMap$,
  takeUntil as takeUntil$,
  tap as tap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { getUserInfo } from '@Model/authorization/selectors';

import {
  getAdminUsers,
  getUser,
  getUsers,
  mounted,
  remove,
  resetState,
  save,
  saveUser,
} from '../actions';
import {
  IGetAdminUsersSuccessPayload,
  IGetUsersSuccessPayload,
} from '../types';

export const requestSaveUserOnSaveAction: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(save)),
    mergeMap$((action) => {
      return of$(saveUser.request(action.payload));
    })
  );
};

export const saveUserWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { userApi }
) => {
  return action$.pipe(
    filter$(isActionOf(saveUser.request)),
    mergeMap$((action) => {
      const {
        payload: { userId, printerId, id, edit },
      } = action;

      const user = {
        edit,
        id: Number(id),
        printerId: Number(printerId),
        userId: Number(userId),
      };

      if (edit) {
        return from$(userApi.editSingleUser(user)).pipe(
          mergeMap$(() => {
            return of$(saveUser.success(), resetState(), getUsers.request());
          }),

          catchError$((error: Error) => {
            return of$(saveUser.failure(error));
          })
        );
      }

      return from$(userApi.addSingleUser(user)).pipe(
        mergeMap$(() => {
          return of$(saveUser.success(), resetState(), getUsers.request());
        }),

        catchError$((error: Error) => {
          return of$(saveUser.failure(error));
        })
      );
    })
  );
};

export const removeUserWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { userApi }
) => {
  return action$.pipe(
    filter$(isActionOf(remove)),
    withLatestFrom$(state$),
    mergeMap$(([action]) => {
      return from$(userApi.removeUser(action.payload)).pipe(
        mergeMap$(() => {
          return [getUsers.request()];
        }),
        catchError$((error: Error) => {
          return of$(getUsers.failure(error));
        })
      );
    })
  );
};

export const requestFetchUsersOnMountedAction: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    mergeMap$(() => {
      return of$(getUsers.request());
    })
  );
};

export const requestAdminUsersOnMountedAction: _Store.IEpic = (action$) => {
  return action$.pipe(
    filter$(isActionOf(mounted)),
    mergeMap$(() => {
      return of$(getAdminUsers.request());
    })
  );
};

export const fetchAdminUsersWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { userApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getAdminUsers.request)),
    mergeMap$(() => {
      return from$(userApi.getAdminUsers()).pipe(
        map$((data: IGetAdminUsersSuccessPayload) => {
          return getAdminUsers.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => userApi.cancelGetUsers())
          )
        ),
        catchError$((error: Error) => {
          return of$(getAdminUsers.failure(error));
        })
      );
    })
  );
};

export const fetchUsersWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { userApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getUsers.request)),
    mergeMap$(() => {
      return from$(userApi.getUsers()).pipe(
        map$((data: IGetUsersSuccessPayload) => {
          return getUsers.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => userApi.cancelGetUsers())
          )
        ),
        catchError$((error: Error) => {
          return of$(getUsers.failure(error));
        })
      );
    })
  );
};

export const fetchUserWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { userApi }
) => {
  return action$.pipe(
    filter$(isActionOf(getUser.request)),
    withLatestFrom$(state$),
    mergeMap$(([_, state]) => {
      const userInfo = getUserInfo(state);

      return from$(userApi.getUser(userInfo.id)).pipe(
        map$((data) => {
          return getUser.success(data);
        }),
        takeUntil$(
          action$.pipe(
            filter$(isOfType(LOCATION_CHANGE)),
            tap$(() => userApi.cancelGetUsers())
          )
        ),
        catchError$((error: Error) => {
          return of$(getUser.failure(error));
        })
      );
    })
  );
};
