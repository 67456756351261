import { createSelector } from 'reselect';

import _Store from '@Store';

import { IUserReducer } from '../types';
import get from './get';

const getUserDefaultStorage = createSelector<
  _Store.IState,
  IUserReducer,
  number | undefined
>([get], (users) => users.user?.storageHouses[0]);

export default getUserDefaultStorage;
