import React from 'react';

import { Grid, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import DonutChart from '@Compo/reusable/DonutChart';
import { IChartData } from '@Compo/reusable/DonutChart/DonutChart.types';
import LabelContentContainer from '@Compo/reusable/LabelContentContainer';
import { TypographyVariant } from '@Constants/Variants/Typography';

import { ISalesReportProps } from './SalesReport.types';

const SalesReport = ({ stats }: ISalesReportProps) => {
  const { t } = useTranslation('components', {
    keyPrefix: 'Rundates.SalesReport',
  });

  const theme = useTheme();

  const { soldBoxOffice, soldOnline, available, ticketLists } = stats;

  const salesMaxStats = soldBoxOffice + soldOnline + available + ticketLists;
  const summary = soldBoxOffice + soldOnline + ticketLists;

  const salesStats: IChartData[] = [
    {
      color: theme.palette.warning.light,
      label: t('soldBoxOffice'),
      value: soldBoxOffice,
    },
    {
      color: theme.palette.error.light,
      label: t('ticketLists'),
      value: ticketLists,
    },
    {
      color: theme.palette.info.light,
      label: t('soldOnline'),
      value: soldOnline,
    },
    {
      color: theme.palette.success.light,
      label: t('available'),
      value: available,
    },
  ];

  return (
    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12}>
        <Typography variant={TypographyVariant.h6}>{t('totalSale')}</Typography>
      </Grid>

      <Grid item={true} xs={12}>
        <LabelContentContainer>
          <DonutChart
            isActive={true}
            data={salesStats}
            maxValue={salesMaxStats}
            hideMobile={false}
            width={138}
            height={138}
            borderWidth={18}
            centerNumber={summary}
            stretchLabels={true}
          />
        </LabelContentContainer>
      </Grid>
    </Grid>
  );
};

export default SalesReport;
