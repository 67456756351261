import React from 'react';

import { Formik } from 'formik';

import GoingPaper from './../layout/Paper/GoingPaper';
import FormLayout from './FormLayout';
import { IFormValuesProps, ISettingsProps } from './Settings.types';
import { SettingsSchema } from './Settings.validation';

const Settings = ({
  initialValues,
  permissionPartnerWrite,
  permissionPixelWrite,
  savePixel,
  savePartner,
}: ISettingsProps) => {
  const catchHandleSubmit = (values: IFormValuesProps) => {
    if (
      permissionPixelWrite &&
      (values.fbPixel !== initialValues.fbPixel ||
        values.tokenPixel !== initialValues.tokenPixel)
    ) {
      savePixel(values.fbPixel, values.tokenPixel);
    }

    if (permissionPartnerWrite && values.gtmId !== initialValues.gtmId) {
      savePartner(values.gtmId);
    }
  };

  return (
    <GoingPaper>
      <Formik
        initialValues={initialValues}
        onSubmit={catchHandleSubmit}
        validationSchema={SettingsSchema}
        component={FormLayout}
        enableReinitialize={true}
      />
    </GoingPaper>
  );
};

export default Settings;
