import React from 'react';

import { Button } from '@mui/material';
import GridUi from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';

import colors from '@Compo/App/colors';

import { ITimeSlotsProps } from './SpacesSlots.types';

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: 20,
    width: '100%',
  },
  selected: {
    backgroundColor: colors.secondary.dark,
  },
}));

const TimeSlots = ({ onChange, spaces, selectedSpaceId }: ITimeSlotsProps) => {
  const classes = useStyles();
  return (
    <GridUi container={true} spacing={2} data-testid="spacesSlots">
      {[...spaces].map((space) => {
        return (
          <GridUi item={true} xs={12} md={3} key={space.id}>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => onChange(space.id)}
              className={cn(
                classes.button,
                space.id === selectedSpaceId && classes.selected
              )}
            >
              {space.metadata.title}
            </Button>
          </GridUi>
        );
      })}
    </GridUi>
  );
};

export default TimeSlots;
