import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import _Store from '@Store';

import { selectSingleTicket } from '@Model/going/rundates/actions';
import { getSelectedSingleTicket } from '@Model/going/rundates/selectors';

import Modal from './Modal.component';
import { IModalFromDispatch, IModalFromState } from './Modal.types';

const mapStateToProps = (state: _Store.IState): IModalFromState => ({
  showModal: !!getSelectedSingleTicket(state),
});

const mapDispatchToProps = (
  dispatch: Dispatch<AnyAction>
): IModalFromDispatch => ({
  closeModal: () => dispatch(selectSingleTicket(null)),
});

export default connect<IModalFromState, IModalFromDispatch, {}, _Store.IState>(
  mapStateToProps,
  mapDispatchToProps
)(Modal);
