import React from 'react';

import { Grid, ListItem, Typography } from '@mui/material';

import BooleanLabel from '@Compo/reusable/BooleanLabel';
import { Colors } from '@Constants/Colors';
import { TypographyVariant } from '@Constants/Variants/Typography';

import useStyles from './ListItemWithLabel.styles';
import { IListItemWithLabelProps } from './ListItemWithLabel.types';

const ListItemWithLabel = ({
  label,
  value,
  valueElement,
}: IListItemWithLabelProps) => {
  const classes = useStyles();

  const paragraph = (text?: string | number | boolean | null) => {
    return typeof text === 'boolean' ? (
      <BooleanLabel isTrue={text} />
    ) : (
      <Typography variant={TypographyVariant.body1} className={classes.text}>
        {text}
      </Typography>
    );
  };

  return (
    <ListItem className={classes.item}>
      <Grid container={true} alignItems={'center'}>
        <Grid item={true} xs={12} sm={6}>
          <Typography
            variant={TypographyVariant.body1}
            color={Colors.textSecondary}
          >
            {label}
          </Typography>
        </Grid>
        <Grid item={true} xs={12} sm={6}>
          <Grid container={true} spacing={2} alignItems={'center'}>
            <Grid item={true}>
              {Array.isArray(value)
                ? (value as any[]).map((value1) => paragraph(value1))
                : paragraph(value)}
            </Grid>
            {valueElement && <Grid item={true}>{valueElement}</Grid>}
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default ListItemWithLabel;
