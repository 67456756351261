import { LOCATION_CHANGE } from 'connected-react-router';
import { EMPTY as EMPTY$, from as from$, of as of$ } from 'rxjs';
import {
  catchError as catchError$,
  filter as filter$,
  mergeMap as mergeMap$,
  withLatestFrom as withLatestFrom$,
} from 'rxjs/operators';
import { isActionOf, isOfType } from 'typesafe-actions';

import _Store from '@Store';

import { isElectron } from '@Misc/helpers/isElectron';
import { printBoca } from '@Model/printerBoca/actions';
import { getReservationPrintData } from '@Model/reservation/selectors';
import { addToast } from '@Model/toasts/actions';
import { TYPE_ERROR, TYPE_SUCCESS } from '@Model/toasts/constants/constants';

const PRINTING_ERROR_TEXT =
  'Wystąpił błąd podczas drukowania biletu. Spróbuj ponownie.';

export const printBocaTicketWhenRequested: _Store.IEpic = (
  action$,
  state$,
  { bocaPrinterApi }
) => {
  return action$.pipe(
    filter$(isActionOf(printBoca.request)),
    filter$(() => isElectron()),
    filter$(
      () =>
        localStorage && localStorage.getItem('GOING_TICKET_PRINTED') !== 'true'
    ),
    withLatestFrom$(state$),
    mergeMap$(([action, state]) => {
      const tickets = getReservationPrintData(state);
      const printingBocaHasError = tickets.transactionItems.some(
        (item) => !item.entryToken
      );

      if (printingBocaHasError) {
        return of$(addToast(PRINTING_ERROR_TEXT, TYPE_ERROR));
      }

      return from$(bocaPrinterApi.printTickets(tickets.transactionItems)).pipe(
        mergeMap$((data) => {
          localStorage.setItem('GOING_TICKET_PRINTED', 'true');
          return of$(
            printBoca.success(data),
            addToast(String(data), TYPE_SUCCESS)
          );
        }),

        catchError$((error: Error) => {
          return of$(
            printBoca.failure(error),
            addToast(String(error), TYPE_ERROR)
          );
        })
      );
    })
  );
};

export const resetIsTicketPrintedWhenLocationChanged: _Store.IEpic = (
  action$,
  state$
) => {
  return action$.pipe(
    filter$(isOfType(LOCATION_CHANGE)),
    filter$(() => isElectron()),
    withLatestFrom$(state$),
    filter$(
      () =>
        localStorage && localStorage.getItem('GOING_TICKET_PRINTED') === 'true'
    ),
    mergeMap$(() => {
      localStorage.removeItem('GOING_TICKET_PRINTED');
      return EMPTY$;
    })
  );
};
